import { useSupportConfigForCurrentOrganization } from 'obt-common';
import { GenesysCloudConfig } from 'obt-common/types/api/v2/obt/model/genesys-cloud-config';
import { useLayoutEffect } from 'react';
import './adjustPosition.css';
import { AuthError, AuthProviderType, GetAuthCodeCbInput } from './type';

export function loadGenesys(scriptId: string, config: GenesysCloudConfig) {
  if (document.getElementById(scriptId)) {
    return;
  }
  const { genesysScriptUrl, deploymentId, environment } = config;
  // Below script is generated in Genesys management dashbaord and provided by TMC.
  // This script is expected to be stable except 3 variables change based on different TMC.
  // Example: https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/genesysgf
  const str = `(function(g, e, n, es, ys) {
    g['_genesysJs'] = e;
    g[e] = g[e] || function() {
        (g[e].q = g[e].q || []).push(arguments)
    };
    g[e].t = 1 * new Date();
    g[e].c = es;
    ys = document.createElement('script');
    ys.async = 1;
    ys.src = n;
    ys.charset = 'utf-8';
    document.head.appendChild(ys);
  })(window, 'Genesys', '${genesysScriptUrl}', {
    environment: '${environment}',
    deploymentId: '${deploymentId}',
    debug: false });
  `;

  const scriptElement = document.createElement('script');

  scriptElement.id = scriptId;
  scriptElement.textContent = str;
  document.head.appendChild(scriptElement);
}

export function makeAuthProvider(config: GenesysCloudConfig) {
  return function registerAuthProvider(AuthProvider: AuthProviderType) {
    /* Register Commands */
    AuthProvider.registerCommand('getAuthCode', (e: GetAuthCodeCbInput) => {
      const { forceUpdate } = e.data || {};
      if (forceUpdate) {
        e.resolve();
      } else {
        const sData = {
          deploymentId: config.deploymentId,
          authCode: window.genesysAuthCode,
          redirectUri: window.location.origin,
        };
        e.resolve(sData);
      }
    });

    AuthProvider.subscribe('Auth.error', (error: AuthError) => {
      const { message } = error.data || { message: '' };
      console.error('Auth Error', message);
    });

    /* Handlers */
    // Tell CXBus your plugin is ready (mandatory)
    AuthProvider.ready();
  };
}

export function setupGenesys(config: GenesysCloudConfig) {
  window.Genesys('registerPlugin', 'AuthProvider', makeAuthProvider(config));
}

const InitializeAuthenticatedGenesysChat = () => {
  const { data: supportConfig } = useSupportConfigForCurrentOrganization();
  useLayoutEffect(() => {
    loadGenesys('genesys-script', supportConfig?.genesysCloudConfig as GenesysCloudConfig);
    setupGenesys(supportConfig?.genesysCloudConfig as GenesysCloudConfig);
  }, [supportConfig?.genesysCloudConfig]);

  return null;
};

export default InitializeAuthenticatedGenesysChat;
