import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelectConfig } from '../../../SelectRoot/SelectConfigContext';
import {
  autocomplete_loading,
  cancel_button,
  chips_list,
  focused_input_container,
  hidden_input,
  visible_input,
} from '../FocusedInput/FocusedInput.styles';
import { input_wrapper } from '../../../Input/Input.styles';
import { clsx } from '../../../utils';

import { CircularProgress } from '../../../CircularProgress';
import { IconButton } from '../../../IconButton';

interface IFocusedInputProps {
  /**
   * These are event handlers from Combobox, to be passed on to the InputBase
   */
  inputProps: Omit<React.HTMLAttributes<HTMLElement>, 'color'> & { disabled?: boolean };
  /**
   * Whether to show the loader
   */
  showLoader: boolean | undefined;
  /**
   * Start `InputAdornment` for this component.
   */
  startAdornment?: React.ReactElement;
  /**
   * End `InputAdornment` for this component.
   */
  endAdornment?: React.ReactElement;
  /**
   * Callback when cancel is clicked
   */
  onCancel?: () => void;
}

/**
 * This component is rendered when the user focuses on the AsyncAutocomplete Component.
 * This shows a list of already selected options in AsyncAutocomplete (since its already across search it contains stuff which is not in available options)
 * User can also unselect an option here (hence the setSelectedOptions)
 *
 * A quick note on the implementation - This component has a chip list (a list of selectedOptions as a chip) and 2 input elements.
 * 2 inputs? because Combobox attaches the dropdown to the last rendered input.
 * Requirement is to show a list of chips and an input as flex-wrap. ie. the input flows alongside the list of chips.
 * Since Dropdown is rendered with the input, this would mean the dropdown would not full width and keep jumping around with the input
 *
 * To solve this, there are 2 inputs. The first one is the input that the user interacts with and is a part of the chips_list.
 * The second input has 0 height, hence hidden to the user.
 * You can experiment with height:0 in the hidden_input's css (or try setting a width) to see how it works.
 *
 */
export function FocusedSingleInput({
  inputProps,
  showLoader,
  startAdornment,
  endAdornment,
  onCancel,
}: IFocusedInputProps) {
  const { t: tt } = useTranslation('WEB');
  const { comboboxStore, labelId, placeholder } = useSelectConfig<'combobox'>();
  const currentSearchText = comboboxStore.useState('value');
  const subInputElement = useRef<HTMLInputElement>(null);

  /**
   * Why is this useEffect needed?
   * Since we have 2 input elements, on rendering the focusedInput, we need to switch user focus to the input element that we show (not the hidden one)
   */
  useEffect(() => {
    subInputElement.current?.focus();
  }, [subInputElement]);

  return (
    <button
      aria-labelledby={labelId}
      type="button"
      placeholder={placeholder}
      className={clsx('BlocksSelect-SearchField', 'MuiInputBase-root')}
      css={[focused_input_container, input_wrapper]}
      data-testid={`${labelId}_input_focused`}
      onClick={() => {
        /**
         * When we click anywhere on the input element, we need to set focus to the visible input
         */
        subInputElement.current?.focus();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          e.stopPropagation();
          comboboxStore.setState('open', false);
        }
      }}
    >
      <div className="chips-list" css={chips_list}>
        {startAdornment}
        <input
          {...inputProps}
          value={currentSearchText}
          css={visible_input}
          placeholder={placeholder}
          ref={subInputElement}
        />
        {!showLoader && currentSearchText && onCancel && (
          <div css={cancel_button}>
            <IconButton
              icon="CloseCross"
              aria-label={tt('Cancel')}
              title={tt('Cancel')}
              onClick={() => {
                onCancel();
                comboboxStore.setValue('');
              }}
            />
          </div>
        )}
        {showLoader && (
          <div css={autocomplete_loading}>
            <CircularProgress size={20} />
          </div>
        )}
        {endAdornment}
      </div>
      <input {...inputProps} css={hidden_input} />
    </button>
  );
}
