import { TravelerBasicInfo } from 'obt-common';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';

import { css } from '@emotion/react';
import { flex, paddings } from '@spotnana/blocks/src/utils/styling';
import { AutocompleteExpandDirections } from '../../../constants/autocomplete';

import { AutocompleteProps } from '../Autocomplete';

type GetAutocompleteContainerBoxPropsFn = (
  popupOpen: boolean,
  expandDirection: AutocompleteExpandDirections,
  relativePositioning?: boolean,
) => ReturnType<AutocompleteProps<TravelerBasicInfo>['getContainerBoxProps']>;

export const getAutocompleteContainerBoxProps: GetAutocompleteContainerBoxPropsFn = (_, expandDirection) => ({
  top: 0,
  left: expandDirection === AutocompleteExpandDirections.Right ? 0 : undefined,
  right: expandDirection === AutocompleteExpandDirections.Left ? 0 : undefined,
  height: '3.5rem',
  width: '25rem',
});

export const getAutocompleteInputStylingProps: AutocompleteProps<TravelerBasicInfo>['getInputStylingProps'] = () => ({
  variation: 'large',
  hideLabel: true,
  label: '',
  style: {
    fontSize: spotnanaTheme.fontSizes.small,
  },
});

export const getAutocompletePopoverProps: AutocompleteProps<TravelerBasicInfo>['getPopoverProps'] = (_) => ({
  style: {
    width: '25rem',
  },
});

export const add_traveler_container = css`
  width: 100%;
  ${flex.init}
  ${flex.align.center}
  ${flex.row}
  ${paddings.y.point25}
`;

export const create_new_traveler_button = css`
  width: 100%;
  ${flex.init}
  ${flex.justify.start}
`;

export const add_traveler_row = css`
  width: 100%;
  gap: 10px;
  ${flex.init}
  ${flex.row}
`;

export const large_icon = css`
  font-size: 18px;
`;

export const no_result_found_text = css`
  ${paddings.x.p1}
  ${paddings.y.p1}
`;
