import { useTranslation } from 'react-i18next';
import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Grid from '@spotnana/pixel-react/dist/Grid';
import { Typography } from '@spotnana/blocks/src/Typography';

import { ExpensePartnerDetail } from '@spotnana/types/openapi/models/expense-partner-detail';
import { ExpensePartner } from '@spotnana/types/openapi/models/expense-partner';
import { getFullAssetPath } from 'obt-common';
import { POPOVER_WIDTH, TabPopover } from './TabPopover';
import { header_nav_element } from '../AppHeader/AppHeader.styles';

const ExpenseOptions = ({ details }: { details: ExpensePartnerDetail[] }): JSX.Element => {
  const imageMap: Partial<Record<ExpensePartner, string>> = {
    [ExpensePartner.Brex]: getFullAssetPath('/assets/images/integrations/brex', 'png'),
    [ExpensePartner.EmburseChromeriver]: getFullAssetPath('/assets/images/integrations/chromeriver', 'png'),
    [ExpensePartner.Expensify]: getFullAssetPath('/assets/images/integrations/expensify', 'png'),
  };

  return (
    <Box width={POPOVER_WIDTH} data-testid="tooltip-analytics_content">
      <Grid.Row spacing={0}>
        <Grid.Col xs={12}>
          <Box position="relative" pb={24} height="full">
            <Flex>
              <Box p={16} pb={0}>
                {details.map(({ partner, displayName, url }) => (
                  <a href={url} key={partner} css={header_nav_element} target="_blank" rel="noreferrer">
                    <Flex>
                      {Boolean(imageMap[partner]) && (
                        <Box pr={2}>
                          <img src={imageMap[partner]} alt="" height="24" width="24" />
                        </Box>
                      )}
                      <Typography kind="regular" variant="body3" color="primary">
                        {displayName}
                      </Typography>
                    </Flex>
                  </a>
                ))}
              </Box>
            </Flex>
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Box>
  );
};

interface IExpenseTabProps {
  details: ExpensePartnerDetail[];
}
export const ExpenseTab: React.FC<IExpenseTabProps> = ({ details }: IExpenseTabProps) => {
  const { t: tt } = useTranslation('WEB');

  if (!details || details.length === 0) {
    return null;
  }

  if (details.length === 1) {
    const { url } = details[0];
    return (
      <a css={header_nav_element} href={url} target="_blank" rel="noreferrer">
        {tt('Expense')}
      </a>
    );
  }

  return (
    <TabPopover active={false} label={tt('Expense')}>
      <ExpenseOptions details={details} />
    </TabPopover>
  );
};
