import { ReactElement } from 'react';
import { useMultipleAirSelectedItineraryQuery, Fare } from 'obt-common';
import { Loader } from 'src/v2-components/flights/shared/Loader';
import PreloadErrorModal from 'src/app/flights/checkout/shared/components/PreloadErrorModal/PreloadErrorModal';
import { AirSelectedItineraryRequest } from 'obt-common/types/api/v1/obt/air/air_selected_itinerary';
import { AirSearchResponse } from 'obt-common/types/api/v1/obt/air/air_search_response';
import { useGetAirSearchResultsFeatures } from 'obt-common/hooks/Flights/useGetAirSearchResultsFeatures';
import { IFareListItinerariesAllApiResponses } from '../FareListItinerariesApiProvider/types';

interface IProps {
  children: (
    props: Pick<IFareListItinerariesAllApiResponses, 'airSelectedItineraries' | 'airSearchResultsFeatures'>,
  ) => ReactElement;
  fares: Fare[];
  onClose: VoidFunction;
  itineraryOwnerId: string;
}

export function FareListItinerariesPreLoadContainer({ children, fares, onClose, itineraryOwnerId }: IProps) {
  const airSelectedItineraryRequests: AirSelectedItineraryRequest[] = fares.map(({ rateOptionId, searchId }) => ({
    rateOptionId,
    searchId,
  }));

  const airSelectedItineraries = useMultipleAirSelectedItineraryQuery(airSelectedItineraryRequests);
  const { airSearchResultsFeatures } = useGetAirSearchResultsFeatures(itineraryOwnerId);

  const areAllItinerariesFetched = airSelectedItineraries.every((r) => r.data);
  const airSelectedItinerariesResponses = areAllItinerariesFetched
    ? airSelectedItineraries.map((r) => r.data).filter((r): r is AirSearchResponse => !!r)
    : undefined;

  const allQueries = [...airSelectedItineraries];

  const firstQueryWithError = allQueries.find((query) => query.error);

  if (firstQueryWithError?.error) {
    if (firstQueryWithError.isFetching) {
      return <Loader />;
    }

    return (
      <PreloadErrorModal
        error={firstQueryWithError.error}
        refetch={{ enabled: true, onRefetch: firstQueryWithError.refetch }}
        onBack={onClose}
      />
    );
  }

  /* istanbul ignore next */
  if (airSelectedItinerariesResponses && airSearchResultsFeatures) {
    return children({
      airSelectedItineraries: airSelectedItinerariesResponses,
      airSearchResultsFeatures,
    });
  }

  return <Loader />;
}
