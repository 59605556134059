import { Theme, useTheme as useEmotionThemeContext } from '@mui/material';
import { useMemo } from 'react';
import { INJECTED_THEME_PROPERTYNAME } from '../../BlocksThemeProvider/constants';
import type { InternalBlocksTheme } from '../../BlocksThemeProvider/blocksTheme';
import type { BlocksTheme } from '../../types';

export function useBlocksTheme(): BlocksTheme {
  const emotionTheme = useEmotionThemeContext() as Theme;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const blocksTheme = (emotionTheme as any)[INJECTED_THEME_PROPERTYNAME] as InternalBlocksTheme;

  const theme = useMemo(
    () => ({
      ...blocksTheme,
      palette: blocksTheme.palette[emotionTheme.palette.mode],
    }),
    [blocksTheme, emotionTheme.palette.mode],
  );

  return theme;
}

export function useInternalMuiTheme() {
  const emotionTheme = useEmotionThemeContext() as Theme;
  return emotionTheme;
}
