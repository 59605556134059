import styled from 'styled-components';
import Box from '@spotnana/pixel-react/dist/Box';
import Typography from '@spotnana/pixel-react/dist/Typography';

export const Container = styled(Box).attrs({ maxWidth: [600, 640, 768, 1024], border: 'thinSolid' })`
  background: ${({ theme }) => theme.colors.bg.regular};
  border-color: ${({ theme }) => theme.colors.border.disabled};
  padding: 16px 20px;
  margin-bottom: 8px;
  border-radius: 16px;
`;

export const ErrorText = styled(Typography).attrs({ as: 'div', variation: 'small', lineHeight: 1.5 })`
  color: ${({ theme }) => theme.colors.error};
`;

export const UserNotFoundContainer = styled(Box)`
  padding: 40px;
`;

export const UserNotFoundErrorMessage = styled(Typography).attrs({ variation: 'h4', fontWeight: 'strong' })``;
