import type { QueryKey, UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query';
import type { AxiosError } from 'axios';
import type { MoneyUtil } from '../utils/Money';
import type SpotnanaError from '../api/SpotnanaError';
import type { Length } from './api/v1/common/length';
import type { Money } from './api/v1/common/money';
import type { DateTimeRange } from './api/v1/common/range';
import type { SortOptionSortOrderEnum } from './api/v1/obt/air/air_search_request';
import type { OrganizationNodeBasicInfoLegalEntityNodeBasicInfo } from './api/v1/obt/common/organization';
import type { TermsAndConditions } from './api/v1/obt/common/terms_and_conditions';
import type { PaymentInfo } from './api/v1/obt/common/user';
import type { SearchTravelerRequest } from './api/v1/obt/profile/search_traveler_request';
import type { CurrencyExchangeRates } from './api/v1/obt/supplier/currency_exchange_rates';
import type { ErrorParameter, InternalAPIError, InternalAPIErrorMessage } from './internal-api-error';
import type { TripIdUrlParam } from './tripid-url-param';
import type { Weight } from './api/v1/obt/common/weight';

export type { ImageV1 } from './api/v1/common/image_group';
export { PaymentMethodEnum } from './api/v1/common/money';
export type { PaymentMethod } from './api/v1/common/money';
export type { TripIdUrlParam, ErrorParameter, InternalAPIError, InternalAPIErrorMessage };
export type APIError = AxiosError<InternalAPIError>;

export interface ILinkData {
  id: string | number;
  labelKey: string;
  pathname: string;
  disabled?: boolean;
}

export type DeepRequired<T> = Required<{
  [P in keyof T]: DeepRequired<T[P]>;
}>;

export interface IPopoverOrigins {
  vertical: 'top' | 'center' | 'bottom' | number;
  horizontal: 'left' | 'center' | 'right' | number;
}

export interface IKeyLabelPair<BackendKeyType = number> {
  backendKey: BackendKeyType;
  labelKey: string;
}

export interface IKeyLabelPairWithVisibilityInfo extends IKeyLabelPair {
  isHidden?: boolean;
}

export interface IKeyLabelPairWithMetaInfo extends IKeyLabelPair {
  metaInfo?: Record<string, unknown>;
  sortOrder?: SortOptionSortOrderEnum;
}

export interface ILabelValuePair {
  label: string;
  value: string;
}

export interface Int32Range {
  min: number;
  max: number;
}

export interface IResponseData<T> {
  responseData: T;
  loading: boolean;
}

export enum BookingTypeEnum {
  AIR = 'AIR',
  CAR = 'CAR',
  HOTEL = 'HOTEL',
  LIMO = 'LIMO',
  MISC = 'MISC',
  RAIL = 'RAIL',
}

export enum ScreenTypeEnum {
  AIR = 'AIR',
  RAIL = 'RAIL',
  HOTEL = 'HOTEL',
  CAR = 'CAR',
  PROFILE = 'PROFILE',
}

export type ILength = Length;

export type IMoney = Money;

export type IDateTimeRange = DateTimeRange;

export interface DateRangeGeneric<T> {
  min: T;
  max: T;
}

export interface SourceErrorMessageItem {
  message: string;
  api?: string;
}

export type ProcessedError = {
  name?: string;
  errorMessage?: string;
  errorCode?: string;
  errorParameters?: ErrorParameter[];
  sourceErrorMessages?: SourceErrorMessageItem[];
  debugToolLink?: string;
  rawError?: any;
  errorDetail?: string;
  originalErrorMessage?: string;
};

export interface ILabelValuePairGeneric<ValueType = string> {
  label: string;
  value: ValueType;
}

export interface ISelectOption<T> extends ILabelValuePairGeneric<T> {
  isExpired?: boolean;
  isDisabled?: boolean;
}

export type SpotnanaQueryOptions<APIResponse, TQueryKey extends QueryKey, TError = SpotnanaError> = UseQueryOptions<
  APIResponse,
  TError,
  APIResponse,
  TQueryKey
>;

export type SpotnanaQueryResult<T, TError = SpotnanaError> = UseQueryResult<T, TError>;

export type SpotnanaQueryMutationResult<T, U> = UseMutationResult<T, SpotnanaError, U, SpotnanaError>;

export type IOrganizationNodeBasicInfoLegalEntityNodeBasicInfo = OrganizationNodeBasicInfoLegalEntityNodeBasicInfo;

export enum FlightBookingType {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
  MULTI_CITY = 'MULTI_CITY',
}

export type ISearchTravelerRequest = SearchTravelerRequest;

export type CurrencyExchangeRatesResponse = CurrencyExchangeRates;

export type ILabelValuePairWithMetaInfo = ILabelValuePair & { metaInfo?: Record<string, unknown> };

export enum UserAgent {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export enum IApiStatus {
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export interface PaymentInfoWithSupportedStatus extends PaymentInfo {
  isNotSupported?: boolean;
}

export type Option = {
  label: string;
  value: string;
};

export enum BookingFlowEnum {
  ADHOC = 'adhoc',
  TRAVELER = 'traveler',
}

export type BookingFlow = BookingFlowEnum.ADHOC | BookingFlowEnum.TRAVELER;

export enum VirtualCardResendCommunicationDestinationAddressOptions {
  FAX = 'Fax',
  EMAIL = 'Email',
}

export type SharedSearchParams = {
  tripId?: TripIdUrlParam;
  bookingFlow?: BookingFlow;
  isPreselectedTrip?: boolean;
  registrarId?: string;
  userId?: string;
  'session-id'?: string;
};

export enum DeviceType {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

export type ITermsAndConditions = TermsAndConditions;

export type MakeNullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface IUsePartnerFlags {
  isUserProfilePersonalDisabled: boolean;
  isUserProfileWorkDisabled: boolean;
  isCompanyLegalEntitiesDisabled: boolean;
  isCompanyLegalEntitiesDeleteDisabled: boolean;
  isCompanyLegalEntitiesNameReadOnly: boolean;
  isCompanyLegalEntitiesAddDisabled: boolean;
  isCompanyServiceFeeConfigDisabled: boolean;
  isCompanyDepartmentsDisabled: boolean;
  isCompanyOfficesDisabled: boolean;
  isCompanyCostCentersDisabled: boolean;
  isCompanyUserUploadDisabled: boolean;
  isCompanyTravelerDeleteDisabled: boolean;
  isCompanyTravelerEmailReadOnly: boolean;
  isCompanyTravelerAddDisabled: boolean;
  isCompanyAuthenticationDisabled: boolean;
  isCompanyGeneralDisabled: boolean;
  isCompanySiteMessagingDisabled: boolean;
  isCompanyIntegrationsDisabled: boolean;
  isCentralisedPaymentsDisabled: boolean;
  isAnalyticsDisabled: boolean;
  isProgramUsersDisabled: boolean;
  isUsersRoleDisabled: boolean;
}

export enum IFlightSearchRedesign {
  ENABLED = 'ENABLED',
  ENABLED_WITH_SWITCH = 'ENABLED_WITH_SWITCH',
  DISABLED = 'DISABLED',
}

export interface IPersonalBookingFlags {
  enable_personal_self: boolean;
  enable_personal_relative: boolean;
}

export interface BrexControls {
  BREX_TMC_ID: string;
  BREX_REWARD_USER_CONSENT_ENABLED: boolean;
  BREX_REWARDS_ENABLED_IN_TRAVEL_FLOW: boolean;
}

export interface LegEmission {
  departureAirportCode: string;
  arrivalAirportCode: string;
  co2EmissionDeltaInPercentage: number | null;
  emissions: Weight;
  averageEmissions: Weight;
}

export interface ICarbonEmissionsConfig {
  totalCo2EmissionDeltaInPercentage: number | null;
  totalEmissions: Weight;
  totalAverageEmissions: Weight;
  totalCarbonPrice: MoneyUtil;
  legEmissions: LegEmission[];
}

export interface MobAppUpdateConfig {
  minAppVersion: string;
  undismissibleMinorVersion: number;
  repetitiveMinorVersion: number;
}

export interface ThemeOptions {
  enableTheme: boolean;
  selfServe: boolean;
  darkMode: boolean;
}

type ColorPalette = {
  border: {
    radiusRegular: string;
    radiusSmall: string;
    radiusXSmall: string;
    light: string;
    medium: string;
    dark: string;
    hover: string;
    active: string;
    keyboardFocus: string;
    successSubtle: string;
    warningSubtle: string;
    error: string;
    errorSubtle: string;
    infoSubtle: string;
    brandSubtle: string;
    success: string;
    warning: string;
    brand: string;
    info: string;
  };
  line: {
    light: string;
    dark: string;
  };
  icon: {
    primary: string;
    secondary: string;
    disabled: string;
    success: string;
    warning: string;
    error: string;
    infoLink: string;
    brand: string;
    starRating: string;
    inverse: string;
    buttonIcon: string;
  };
  surface: {
    base: string;
    background: string;
    menu: string;
    overlay: string;
  };
  bg: {
    hover: string;
    disabled: string;
    successSubtle: string;
    success: string;
    warningSubtle: string;
    warning: string;
    errorSubtle: string;
    error: string;
    infoSubtle: string;
    info: string;
    brand: string;
    brandSubtle: string;
    brandHover: string;
    badgeActive: string;
    badgeDisabled: string;
    buttonDisabled: string;
    tooltip: string;
  };
  shadow: {
    cardShadow: string;
    scrollTopShadow: string;
    scrollBottomShadow: string;
    componentShadow: string;
  };
  text: {
    brand: string;
    primary: string;
    secondary: string;
    disabled: string;
    error: string;
    warning: string;
    success: string;
    focus: string;
    link: string;
    linkHover: string;
    inverse: string;
    secondaryInverse: string;
    tertiary: string;
    buttonLabel: string;
  };
  inputs: {
    borderRadius: string;
    bgDefault: string;
    bgHover: string;
    bgDisabled: string;
    borderDefault: string;
    borderHover: string;
    borderFocus: string;
    borderDisabled: string;
    borderExpanded: string;
    borderSelected: string;
    borderError: string;
    borderWarning: string;
    borderSuccess: string;
    text: string;
    textLabel: string;
    textPlaceholder: string;
    textDisabled: string;
    textHint: string;
    textError: string;
  };
  mode: 'light' | 'dark';
};

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export interface BlocksPalette {
  light: RecursivePartial<ColorPalette>;
  dark: RecursivePartial<ColorPalette>;
}
