/* eslint-disable no-restricted-syntax, @typescript-eslint/ban-ts-comment */

import type { AxiosRequestConfig, CancelTokenSource } from 'axios';
import axios from 'axios';

// import { AXIOS_CANCELLED_CALL } from '../constants/common';
import Config from '../utils/Config';
import adapter from '../utils/axios-fetch-adapter';
import type { IApiRequestMethods, IApiRequestSubType, IApiRequestTypes, IRequestData, IResponseData } from './apiTypes';
import ApiCache from './apiCache';
import getUrl from './endpoints';
import type { IApiRequestOptions, ICustomAxiosRequestConfig } from './types';
import injectAppHeaders from './interceptors/injectAppHeaders';
import injectApplicationIdHeader from './interceptors/injectApplicationIdHeader';
import injectHandleRedirectOn409 from './interceptors/injectHandleRedirectOn409';
import injectImpersonationTokenHeader from './interceptors/injectImpersonationTokenHeader';
import injectSabreSimulatorRedirectHeader from './interceptors/injectSabreSimulatorRedirectHeader';
import injectXSessionIdHeader from './interceptors/injectXSessionIdHeader';
import { injectTmcIdHeader } from './interceptors/injectTmcIdHeader';
import { injectOrgIdHeader } from './interceptors/injectOrgIdHeader/injectOrgIdHeader';

const typeToCancelSourceMap = new Map<string, Map<IApiRequestSubType, CancelTokenSource>>();

interface IConfig extends Partial<AxiosRequestConfig> {
  data?: IRequestData;
  urlParam?: string;
}

async function makeRequest(
  method: IApiRequestMethods,
  type: IApiRequestTypes,
  config?: IConfig,
  options?: IApiRequestOptions,
): Promise<IResponseData | undefined> {
  const url = getUrl(type, config?.urlParam);
  const requestBody: IRequestData | undefined = config?.data ?? undefined;

  const cachedData: IResponseData | null = ApiCache.get({ url, requestBody });
  if (cachedData) {
    return cachedData;
  }
  let cancelTokenSource;
  const subTypeMap = typeToCancelSourceMap.get(url);
  if (subTypeMap) {
    cancelTokenSource = subTypeMap.get(options?.subType);
    if (!options?.allowParallelRequests && cancelTokenSource) {
      // cancelTokenSource.cancel(AXIOS_CANCELLED_CALL);
    }
  }

  cancelTokenSource = axios.CancelToken.source();
  typeToCancelSourceMap.set(
    url,
    new Map<IApiRequestSubType, CancelTokenSource>([[options?.subType, cancelTokenSource]]),
  );

  const customRequestConfig: ICustomAxiosRequestConfig = {
    /**
     * Axios works on XHR, not fetch. Most modern tooling depends on fetch, so for example,
     * it's really hard to get MSW to work properly in a NodeJS environment with Axios.
     * This workaround enables axios to use fetch API instead of XHR allowing us to use MSW in tests.
     */
    // @ts-ignore
    adapter:
      // @ts-ignore
      typeof process !== 'undefined' && process?.env?.NODE_ENV === 'test' && process?.env?.DISABLE_AXIOS_MOCK
        ? adapter
        : undefined,
    url,
    method,
    baseURL: Config.VITE_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    cancelToken: cancelTokenSource.token,
    ...config,
    // this is undocumented: we can pass any data from config to the interceptors
    // even if it is not documented axios team is supporting this ability
    // https://github.com/axios/axios/issues/2295
    shouldNotShowErrorForNotFound: !!options?.shouldNotShowErrorForNotFound,
  };

  const axiosRequestConfig = customRequestConfig as unknown as AxiosRequestConfig;

  const response = await axios(axiosRequestConfig);

  ApiCache.set({ url, requestBody }, response.data);

  return response.data;
}

// Init request/response interceptors
injectHandleRedirectOn409();
injectAppHeaders();
injectApplicationIdHeader();
injectTmcIdHeader();
injectOrgIdHeader();
injectSabreSimulatorRedirectHeader();
injectImpersonationTokenHeader();
injectXSessionIdHeader();

export default makeRequest;
export { getUrl };
