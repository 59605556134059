import Typography from '@spotnana/pixel-react/dist/Typography';
import styled from 'styled-components';

import { css } from '@emotion/react';
import { themed } from '@spotnana/blocks/src/utils/styling/themed';

import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';
import { flex, themeSelector } from '@spotnana/blocks/src/utils/styling/utilityClasses';

export const CabinName = styled(Typography).attrs({
  as: 'div',
  variation: 'body',
  lineHeight: '24px',
  fontWeight: 'semiBold',
  color: 'text.semiBold',
  textAlign: 'center',
})``;

export const CabinCodes = styled(Typography).attrs({
  variation: 'small',
  color: 'text.kashmirBlue',
  lineHeight: '16px',
  textAlign: 'center',
})`
  margin-top: 6px;
`;

export const banner_container = themed(
  ({ palette }) => css`
    border-radius: 8px;
    background: ${palette.bg.warningSubtle};
    padding: 12px;
    margin-top: 16px;
    border: 1px solid ${palette.border.warningSubtle};
    position: relative;
  `,
);

export const filled_box = themed(
  ({ palette }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 5px solid ${palette.border.warning};
    border-radius: 8px 0 0 8px;
  `,
);

export const filled_icon = themed(
  ({ palette }) => css`
    color: ${palette.icon.warning};
    margin-right: 8px;
  `,
);

export const header_container = css`
  display: flex;
  align-items: center;
`;

export const icon_container = css`
  flex-shrink: 1;
`;

export const header_text = css`
  margin-left: '12px';
`;

export const logo_image = css`
  height: 2rem;
  width: 2rem;
`;

export const airline_image_container = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.justify.center}
    ${flex.align.center}
    ${paddings.y.superTight}
    ${paddings.x.superTight}
    border-radius: ${palette.border.radiusXSmall};
    background-color: ${palette.icon.buttonIcon};
    ${themeSelector('dark')} {
      /* Will always have white background */
      ${palette.icon.buttonIcon}
    }
  `,
);
