let listeners: ((appId: string | undefined) => void)[] = [];

export const onApplicationIdChange = (callback: (appId: string | undefined) => void) => {
  listeners.push(callback);

  return () => {
    listeners = listeners.filter((listener) => listener !== callback);
  };
};

export const emitApplicationIdChange = (appId: string | undefined): void => {
  listeners.forEach((listener) => listener(appId));
};
