/* eslint-disable */
import type { RailCard } from '../../common/card';
import type { DateTime, Duration } from '../../common/date_time';
import type { Money, PaymentMethod } from '../../common/money';
import type { TravelClass } from '../common/common';
import type { PolicyInfo } from '../common/policy_info';
import type {
  AppliedPromotion,
  DeliveryOption,
  FareType,
  RailAmenity,
  StationInfo,
  Vehicle,
  FareComposition,
  RailExchangeType,
} from './rail_common';

/** Train change for a given journey. */
export interface Leg {
  /** Information about the train including train number, operator etc. */
  vehicle?: Vehicle;
  /**
   * Originating station name.
   *
   * @deprecated
   */
  origin: string;
  /**
   * Destination station name.
   *
   * @deprecated
   */
  destination: string;
  /** Departure date time. */
  departure?: DateTime;
  /** Arrival date time. */
  arrival?: DateTime;
  /** Duration of travel. */
  duration?: Duration;
  /** Origin Platform information, if available. */
  originPlatform: string;
  /** Destination platform information, if available. */
  destinationPlatform: string;
  /** Originating station info. */
  originInfo?: StationInfo;
  /** Destination station info. */
  destinationInfo?: StationInfo;
  /** CO2 emission for the current leg in gram for a single passenger. */
  co2EmissionGramsPerPassenger: number;
}

/** Different Fare options for a part of journey which is served by single carrier. */
export interface Section {
  alternatives: SectionAlternative[];
}

/** Fare option related to this section. */
export interface SectionAlternative {
  /** Rate key for this alternative. */
  rateKey: string;
  /** Total price for this alternative. */
  totalPrice?: Money;
  /** Price after discounts. */
  discountedPrice?: Money;
  /** Flexibility description like flexi, semi flexi etc. */
  flexibility: string;
  /** Delivery options. */
  deliveryOptions: DeliveryOption[];
  fares: SectionAlternativeFare[];
  category: SectionAlternativeCategory;
  /** Travel class for the alternative. */
  travelClass: TravelClass;
  /** Type of Alternative. (Off Peak, Anytime) */
  type: string;
  /** List of allowed forms of payment allowed for this rate option. */
  allowedFop: PaymentMethod[];
  /** The policy Info corresponding to this travel option. */
  policyInfo?: PolicyInfo;
  /** CO2 emission for the current fare in gram for a single passenger. */
  co2EmissionGramsPerPassenger: number;
  /** List of amenities available for the alternative. */
  amenities: RailAmenity[];
  /** Indicates whether the fare is through or split. */
  fareComposition: FareComposition;
  /** Non-split price for the alternative. */
  nonSplitPrice?: Money;
}

/** Single/Return Alternative. */
export enum SectionAlternativeCategory {
  UNKNOWN_CATEGORY = 0,
  SINGLE = 1,
  RETURN = 2,
  UNRECOGNIZED = -1,
}

/** This represents how the journey is structured. This will contain only one fare for now. */
export interface SectionAlternativeFare {
  /** FareType represents how the fare is represented in journey. */
  fareType?: FareType;
  fareLegs: SectionAlternativeFareFareLeg[];
  /** Route restrictions for this travel. */
  routeRestriction: string;
  /** Validity for outward journey. */
  outwardValidity?: SectionAlternativeFareValidity;
  /** Validity for inward journey. Applicable for anytime returns. */
  inwardValidity?: SectionAlternativeFareValidity;
  /** Allowed origin stations. */
  originStations: string[];
  /** Allowed destination stations. */
  destinationStations: string[];
  /** Rail discount cards for this fare. */
  discountCards: RailCard[];
  /** Fare origin station info. */
  originInfo?: StationInfo;
  /** Fare destination station info. */
  destinationInfo?: StationInfo;
  /** Price for the fare. */
  price?: Money;
  /** Promotions applied on the fare. */
  appliedPromotions: AppliedPromotion[];
}

/** Information about the leg this fare represents. */
export interface SectionAlternativeFareFareLeg {
  /** Index of leg in journey. */
  legIndex: number;
  /** Travel class for the leg. */
  travelClass: TravelClass;
  /** Comfort class for the leg. */
  comfortClass: string;
  /** Description of the comfort class. */
  comfortClassDescription: string;
}

/** Time validity for journey. */
export interface SectionAlternativeFareValidity {
  /** Starting time of validity. */
  validFrom?: DateTime;
  /** End time of validity. */
  validUntil?: DateTime;
}

/** Journey structure to define one journey. */
export interface Journey {
  /** Time of departure of the journey. */
  departAt?: DateTime;
  /** Time of arrival of the journey. */
  arriveAt?: DateTime;
  /** Duration of the journey. */
  duration?: Duration;
  /** List of legs for this journey. */
  legs: Leg[];
  /**
   * List of sections comprising different fare options. Each entry corresponds to fare options by
   * each different carrier. For booking an itinerary, one fare alternative id needs to be passed from
   * each section.
   */
  sections: Section[];
}

/** Proto for Rail search response. */
export interface RailSearchResponse {
  /** Search key for this response. */
  searchKey: string;
  /** List of journeys. */
  journeys: Journey[];
  /** Summary of outward journey selected. This is applicable only for inward search results. */
  outwardJourneySummary?: RailSearchSummary;
  /** Metadata about the response. */
  metadata?: Metadata;
  /** Navigation params for the search response. */
  navigationParams?: NavigationParams;
}

/** Summary of selected journey. */
export interface RailSearchSummary {
  /** Selected journey and selected alternatives. */
  journey?: Journey;
}

/** Metadata about the rail search response. */
export interface Metadata {
  /** List of travel classes. */
  travelClasses: TravelClass[];
  /** Type of the exchange */
  exchangeInfo?: {
    exchangeType: RailExchangeType;
  };
}

export interface NavigationParams {
  /** Indicates whether the next page is available. */
  hasNext: boolean;
  /** Indicates whether the previous page is available. */
  hasPrevious: boolean;
}
