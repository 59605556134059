import { TravelerBasicInfo } from 'obt-common';
import { FC } from 'react';
import { Typography } from '@spotnana/blocks/src/Typography';
import { IconButton } from '@spotnana/blocks/src/IconButton';
import { traveler_info_container } from './styles';

interface TravelerItemProps {
  traveler: TravelerBasicInfo;
  onRemoveTraveler: VoidFunction;
}

export const TravelerItem: FC<TravelerItemProps> = ({ traveler, onRemoveTraveler }) => {
  return (
    <div css={traveler_info_container}>
      <div>
        <Typography variant="body2" kind="medium">
          {traveler.fullName}
        </Typography>
        <Typography variant="body3" color="secondary">
          {traveler.email}
        </Typography>
      </div>
      <IconButton icon="Delete" onClick={onRemoveTraveler} data-testid="remove_traveler_icon" />
    </div>
  );
};
