/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { AutocompleteFilter } from './autocomplete-filter';
// May contain unused imports in some cases
// @ts-ignore
import { PaginationRequestParams } from './pagination-request-params';

/**
 * Autocomplete entity search request, you can search across various entities
 * @export
 * @interface AutocompleteEntitySearchRequest
 */
export interface AutocompleteEntitySearchRequest {
  /**
   * Search string entered by the user
   * @type {string}
   * @memberof AutocompleteEntitySearchRequest
   */
  searchText: string;
  /**
   * List of searchable entities
   * @type {Array<string>}
   * @memberof AutocompleteEntitySearchRequest
   */
  entities: Array<AutocompleteEntitySearchRequestEntitiesEnum>;
  /**
   * List of filters to be applied on the response obtained from autocomplete search
   * @type {Array<AutocompleteFilter>}
   * @memberof AutocompleteEntitySearchRequest
   */
  filters?: Array<AutocompleteFilter>;
  /**
   *
   * @type {PaginationRequestParams}
   * @memberof AutocompleteEntitySearchRequest
   */
  paginationParams?: PaginationRequestParams;
}

export const AutocompleteEntitySearchRequestEntitiesEnum = {
  Traveler: 'TRAVELER',
  Pnr: 'PNR',
  Organization: 'ORGANIZATION',
  Airline: 'AIRLINE',
  HotelChain: 'HOTEL_CHAIN',
  HotelBrand: 'HOTEL_BRAND',
  Trip: 'TRIP'
} as const;

export type AutocompleteEntitySearchRequestEntitiesEnum =
  (typeof AutocompleteEntitySearchRequestEntitiesEnum)[keyof typeof AutocompleteEntitySearchRequestEntitiesEnum];
