/* eslint-disable */
export enum ServiceContentEnum {
  COMPLEX_TRIP = 0,
  AIRPORT_TRANSFER = 1,
  OTHERS = 2,
}
export enum FileUploadErrorsEnum {
  SIZE_ERROR = 0,
  FORMAT_ERROR = 1,
  FILE_LIMIT_ERROR = 2,
}
