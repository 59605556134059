import { Button } from '@spotnana/blocks/src/Button';
import { FareList } from 'obt-common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@spotnana/blocks/src/Tooltip';
import { modal_footer_contaienr } from './styles';
import { getExpiredFares } from '../../utils/getExpiredFares';

interface ShareItineraryFooterProps {
  selectedList?: FareList;
  onBack: VoidFunction;
  onShare: VoidFunction;
}

const ShareItineraryFooter: FC<ShareItineraryFooterProps> = ({ selectedList, onBack, onShare }) => {
  const { t: tt } = useTranslation('WEB');
  if (!selectedList) {
    return null;
  }
  const disabledShareButton = getExpiredFares(selectedList.fares).length > 0;
  return (
    <div css={modal_footer_contaienr}>
      <Button onClick={onBack} fullWidth variant="outline">
        {tt('Back')}
      </Button>
      <Tooltip
        title={tt('Please delete expired fares to share itinerary.')}
        disableHoverListener={!disabledShareButton}
        disableFocusListener={!disabledShareButton}
      >
        <Button onClick={onShare} disabled={disabledShareButton} fullWidth>
          {tt('Share')}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ShareItineraryFooter;
