import { REDIRECTION_NEEDED_FOR_BE } from '../../../constants';
import handleRedirectError from '../../handleRedirectError';
import addResponseInterceptor from '../addResponseInterceptor';

function injectHandleRedirectOn409() {
  return addResponseInterceptor({
    onRejected: (error) => {
      if (handleRedirectError(error)) {
        return new Error(REDIRECTION_NEEDED_FOR_BE);
      }

      return error;
    },
  });
}

export default injectHandleRedirectOn409;
