import { css } from '@emotion/react';
import { themed } from '@spotnana/blocks/src/utils';

export const modal_secondary_background = themed(
  ({ palette }) => css`
    .BlocksModal-content {
      background-color: ${palette.surface.background};
    }

    .BlocksModal-footer {
      background-color: ${palette.surface.background};
    }

    &.MuiDialog-root {
      background-color: transparent;
    }
  `,
);
