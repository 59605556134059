import { LimoDetailsCarTypeEnum } from '../../types';

export const EnumToCarMapper = (carType?: LimoDetailsCarTypeEnum | number): string | undefined => {
  switch (carType) {
    case LimoDetailsCarTypeEnum.BusinessVan:
      return 'Business van';
    case LimoDetailsCarTypeEnum.ExecutiveCar:
      return 'Executive car';
    case LimoDetailsCarTypeEnum.FirstClass:
      return 'First class';
    case LimoDetailsCarTypeEnum.SUV:
      return 'SUV';
    case LimoDetailsCarTypeEnum.StandardCar:
      return 'Standard car';
    default:
      return undefined;
  }
};
