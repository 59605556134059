var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { flex } from 'styled-system';
import { Grid, Col as LibCol, Row as LibRow } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';
import { makeBox } from '../Box';
import theme from '../utils';
/** @deprecated  Please use Blocks grid, message #ask-fe-infra for questions */
export const Container = (props) => React.createElement(Grid, Object.assign({}, props));
const StylableCol = makeBox(styled(LibCol)(flex));
/** @deprecated  Please use Blocks grid, message #ask-fe-infra for questions */
export const Col = (props) => React.createElement(StylableCol, Object.assign({}, props));
const localTheme = (spacing) => (Object.assign(Object.assign({}, theme), { flexboxgrid: Object.assign(Object.assign({}, theme.flexboxgrid), { gutterWidth: spacing !== null && spacing !== void 0 ? spacing : 1 }) }));
const StyledRow = styled(LibRow) `
  ${theme.mediaQueries.belowTablet} {
    width: 100%;
    margin: 0 0;
  }
`;
const StylableRow = makeBox(StyledRow);
/** @deprecated  Please use Blocks grid, message #ask-fe-infra for questions */
export const Row = (_a) => {
    var { spacing, fullWidth } = _a, restProps = __rest(_a, ["spacing", "fullWidth"]);
    return (React.createElement(ThemeProvider, { theme: localTheme(spacing) },
        React.createElement(StylableRow, Object.assign({}, restProps, { width: fullWidth ? 'full' : undefined }))));
};
/**
 * Please use Blocks grid, message #ask-fe-infra for questions
 * @deprecated
 * */
const DeprecatedGrid = { Container, Row, Col };
/**
 * @deprecated
 */
export default DeprecatedGrid;
