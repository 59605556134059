import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Beverage = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M10.32,13.44a6.11,6.11,0,0,1,6.1-6.11h0a6.11,6.11,0,0,1,6.11,6.11L20.41,27.37h-8Z", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", strokeMiterlimit: "10" }),
        React.createElement("line", { x1: "23.95", y1: "13.48", x2: "8.92", y2: "13.48", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.53,7.94l1.87-3.1A.42.42,0,0,1,22,4.69l4.21,2.5" }),
        React.createElement("path", { d: "M11.14,18.9l1.63.5a6.48,6.48,0,0,0,4.32-.16,6.4,6.4,0,0,1,2.4-.46,6.24,6.24,0,0,1,2.17.38", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", strokeLinecap: "round", strokeLinejoin: "round" })));
};
Beverage.defaultProps = defaultIconProps;
export default Beverage;
