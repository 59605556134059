/* eslint-disable */
import type { ImageV1 } from '../../common/image_group';
import type { PersonaEnum } from './user_org_id';

/** Auth Configuration for an organization */
export interface AuthConfig {
  /** Auth providers registered for this organization */
  authProviders: AuthConfigAuthProvider[];
  /**
   * The number of minutes post which the user’s session will be forcibly
   * evicted.
   */
  idleSessionTimeoutMin: number;
}

export interface AuthConfigAuthProvider {
  /** Type of auth provider */
  authProviderType: AuthConfigAuthProviderAuthProviderType;
  /** @deprecated */
  azure?: AuthConfigAuthProviderAzure | undefined;
  customProvider?: AuthConfigAuthProviderCustomProviderMetadata | undefined;
  redirectInfo?: ThirdPartyRedirectInfo | undefined;
  otpMetadata?: AuthConfigAuthProviderOTPMetadata | undefined;
  authCodeMetadata?: AuthConfigAuthProviderAuthCodeMetadata | undefined;
}

export enum AuthConfigAuthProviderAuthProviderType {
  UNKNOWN_AUTH_PROVIDER_TYPE = 0,
  USER_CREDENTIAL = 1,
  GOOGLE = 2,
  /** @deprecated */
  AZURE = 3,
  CUSTOM = 4,
  REDIRECT = 5,
  OTP = 6,
  AUTH_CODE = 7,
  UNRECOGNIZED = -1,
}

/** Azure Metadata */
export interface AuthConfigAuthProviderAzure {
  /**
   * Cognito Id of registered client
   * NOTE: This is named id and not cognitoid to prevent any implementation exposure.
   */
  id: string;
}

export enum CustomSSOType {
  OIDC = 2,
  SAML = 3,
}

export interface AuthConfigAuthProviderCustomProviderMetadata {
  /**
   * Cognito Id of registered client
   * NOTE: This is named id and not cognitoid to prevent any implementation
   * exposure.
   */
  id: string;
  /**
   * Custom provider might be SAML or OpenID SSO type.
   */
  ssoType: CustomSSOType;
  /**
   * Does the customer need to do some transformation on calls made by
   * cognito for "/token" endpoint.
   */
  requiresProxy: boolean;
  /**
   * The token endpoint provided by the customer. This is required only if
   * requires_proxy is true.
   */
  tokenEndpoint: string;
  clientAuthenticationMethod: AuthConfigAuthProviderCustomProviderMetadataClientAuthenticationMethod;
}

/** Different ways that client can call the token endpoint. */
export enum AuthConfigAuthProviderCustomProviderMetadataClientAuthenticationMethod {
  CLIENT_AUTHENTICATION_METHOD_UNKNOWN = 0,
  /**
   * CLIENT_SECRET_BASIC - Essentially HTTP basic authentication with a shared secret. The most
   * widely implemented because of its simplicity, but also with the
   * weakest security properties.
   */
  CLIENT_SECRET_BASIC = 1,
  /**
   * CLIENT_SECRET_POST - A variant of basic authentication where the credentials are passed as
   * form parameters instead of in the Authorization header.
   */
  CLIENT_SECRET_POST = 2,
  UNRECOGNIZED = -1,
}

/** Auth Configuration for an organization */
export interface AuthConfigResponse {
  /**
   * Auth Configuration for the organization
   *
   * @deprecated
   */
  authConfig?: AuthConfig;
  /** Login contexts available for the user. */
  loginContexts: LoginContext[];
}

/** OTP metadata */
export interface AuthConfigAuthProviderOTPMetadata {
  method: AuthConfigAuthProviderOTPMetadataOTPMethod;
}

export enum AuthConfigAuthProviderOTPMetadataOTPMethod {
  OTP_METHOD_UNKNOWN = 0,
  OTP_BY_EMAIL = 1,
  OTP_BY_PHONE = 2,
  UNRECOGNIZED = -1,
}

/** Auth code metadata */
export interface AuthConfigAuthProviderAuthCodeMetadata {
  /** Custom endpoint details for resolving the email for the auth_code. */
  userInfoEndpoint?: AuthConfigAuthProviderAuthCodeMetadataUserInfoEndpoint;
  /** Auth Redirect urls to get the user on authentication. */
  redirectInfo?: ThirdPartyRedirectInfo;
}

export enum AuthConfigAuthProviderAuthCodeMetadataHttpMethod {
  HTTP_METHOD_UNKNOWN = 0,
  /** GET - Http get method */
  GET = 1,
  /** POST - Http post method */
  POST = 2,
  UNRECOGNIZED = -1,
}

/** ParamValue determined how a UserInfoEndpoint's Param value is computed. */
export interface AuthConfigAuthProviderAuthCodeMetadataParamValue {
  /** Value for static attributes. */
  constValue?: AuthConfigAuthProviderAuthCodeMetadataParamValueConstValue | undefined;
  /** Value obtained from user query parameter. */
  queryParamValue?: AuthConfigAuthProviderAuthCodeMetadataParamValueQueryParamValue | undefined;
  /** Value of secret from aws in case of client secret. */
  awsSecretValue?: AuthConfigAuthProviderAuthCodeMetadataParamValueAwsSecretValue | undefined;
}

export interface AuthConfigAuthProviderAuthCodeMetadataParamValueConstValue {
  /** String constant value. */
  stringValue: string;
}

export interface AuthConfigAuthProviderAuthCodeMetadataParamValueQueryParamValue {
  /** The name of query parameter from the client request. */
  name: string;
}

export interface AuthConfigAuthProviderAuthCodeMetadataParamValueAwsSecretValue {
  /** The name aws secret. */
  clientSecretId: string;
}

export interface AuthConfigAuthProviderAuthCodeMetadataParam {
  /** Header parameters metadata. */
  header?: AuthConfigAuthProviderAuthCodeMetadataParamKVParam | undefined;
  /** Query parameters metadata. */
  query?: AuthConfigAuthProviderAuthCodeMetadataParamKVParam | undefined;
  /** Path parameters metadata. */
  path?: AuthConfigAuthProviderAuthCodeMetadataParamKVParam | undefined;
  /** Body params metadata. */
  body?: AuthConfigAuthProviderAuthCodeMetadataParamBodyParam | undefined;
}

export interface AuthConfigAuthProviderAuthCodeMetadataParamKVParam {
  /** The param key. */
  key: string;
  /** The param value. */
  value?: AuthConfigAuthProviderAuthCodeMetadataParamValue;
}

export interface AuthConfigAuthProviderAuthCodeMetadataParamBodyParam {
  /** The parameterized json body template. */
  jsonTemplate: string;
  /** List of placeholder values contains the parameters for the json_template. */
  placeholders: AuthConfigAuthProviderAuthCodeMetadataParamBodyParamPlaceholder[];
}

export interface AuthConfigAuthProviderAuthCodeMetadataParamBodyParamPlaceholder {
  /** Attribute/Parameter name. */
  name: string;
  /** Attribute/Parameter value. */
  value?: AuthConfigAuthProviderAuthCodeMetadataParamValue;
}

export interface AuthConfigAuthProviderAuthCodeMetadataResponseAttribute {
  /** Attribute name. */
  name: AuthConfigAuthProviderAuthCodeMetadataResponseAttributeAttribute;
  /** Json xpath of the Attribute/Parameter value location. */
  jsonXpath: string;
}

export enum AuthConfigAuthProviderAuthCodeMetadataResponseAttributeAttribute {
  ATTRIBUTE_UNKNOWN = 0,
  /** PID - The pid attribute of the user. */
  PID = 1,
  UNRECOGNIZED = -1,
}

export interface AuthConfigAuthProviderAuthCodeMetadataUserInfoEndpoint {
  /** List of parameters to be applied on the user info api. */
  params: AuthConfigAuthProviderAuthCodeMetadataParam[];
  /** The http method type for the api. */
  httpMethod: AuthConfigAuthProviderAuthCodeMetadataHttpMethod;
  /**
   * The third party api url to get the email from auth_code. If there are
   * any path variable use {path_variable_name}. e.g. v2/users/{userId}.
   */
  url: string;
  /** List of relevant attribute names & their json xpath from response. */
  responseAttributes: AuthConfigAuthProviderAuthCodeMetadataResponseAttribute[];
}

export interface LoginContextInfo {
  /** Context display name. */
  displayName: string;
  /** TMC ID for the login context. */
  tmcId: string;
  /** Option application id */
  applicationId: string;
  /** Organization id */
  orgId: string;
  /** Persona */
  persona: PersonaEnum;
  /** Organization name */
  orgName: string;
}

/** Auth Redirect urls to get the user authenticated. */
export interface ThirdPartyRedirectInfo {
  /** For web auth */
  authRedirectUrl: string;
  /** For mobile auth */
  mobileAuthRedirectUrl: string;
}

export interface LoginContext {
  /** Login context related information. */
  loginContextInfo?: LoginContextInfo;
  /** Context auth config */
  authConfig?: AuthConfig;
}

export interface UserRef {
  /**
   * Each user reference item would contain user_id, org_id, persona and related applications to login.
   * Id of found user profile.
   */
  userId: string;
  /** Org id of found user profile. */
  organizationId: string;
  /** Persona of found user profile. */
  persona: PersonaEnum;
  /** Supported applications by the org to login. */
  applications: ApplicationRef[];
}

export interface ApplicationRef {
  /** Id corresponding to the application. */
  id: string;
  /** Owner of this application. */
  ownerId: string;
  /** Name of the application. */
  name: string;
  /** Application logo */
  applicationLogo?: ImageV1;
  /** Redirect url to get the user authenticated. */
  authUrls?: AuthUrls | undefined;
  /** Auth config containing auth providers' details. */
  authConfig?: AuthConfig | undefined;
}

/** Auth Redirect urls to get the user authenticated. */
export interface AuthUrls {
  /** For web auth */
  authRedirectUrl: string;
  /** For mobile auth */
  mobileAuthRedirectUrl: string;
}
