import { PhoneNumber } from '../../../v1/common/phone_number';

export enum EmergencyContactRelationEnumV1 {
  RELATION_UNKNOWN = 0,
  SPOUSE = 1,
  PARENT = 2,
  SIBLING = 3,
  CHILD = 4,
  FRIEND = 5,
  RELATIVE = 6,
  COLLEAGUE = 7,
  OTHER = 8,
  UNRECOGNIZED = -1,
}

export interface EmergencyContact {
  /** Full name of contact */
  name: string;
  /** Primary & secondary phone numbers */
  phoneNumbers: PhoneNumber[];
  /** Email of contact */
  email: string;
  /** Relation of contact to user, if applicable */
  relation: EmergencyContactRelationEnumV1;
  /**
   * Job title of contact, if applicable
   *
   * @deprecated
   */
  designation: string;
  /** Preferred language of contact */
  preferredLanguage: string;
}
