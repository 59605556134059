var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FlightSeat = (_a) => {
    var { primaryColor, width, height, secondaryColor } = _a, props = __rest(_a, ["primaryColor", "width", "height", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 32 32", xmlns: "http://www.w3.org/2000/svg", "data-testid": "flight-seat-icon", fill: themeEnrichedPrimaryColor }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.923 4.5A2.93 2.93 0 007 7.436v5.816c0 .087.013.174.038.257l1.603 5.33a5.538 5.538 0 004.397 5.291v2.479a.89.89 0 00.887.891h7.41c.49 0 .887-.4.887-.892v-2.364h1.89c.49 0 .888-.4.888-.892v-4.65a.89.89 0 00-.887-.892h-8.101l1.595-2.098h4.577l.066-.003a.89.89 0 00.821-.889.89.89 0 00-.887-.891h-5.016l-.075.003a.887.887 0 00-.63.347l-2.143 2.818-1.475-4.357V7.436A2.93 2.93 0 009.923 4.5zm4.89 21.217v-1.473h5.633v1.473h-5.633zm.869-6.124a1.885 1.885 0 01-1.842 1.495c-.904 0-1.66-.64-1.841-1.495h-1.48l.024.09a3.748 3.748 0 003.502 2.776l.112.002h9.068v-2.868h-7.543zm-5.76-13.31a1.15 1.15 0 011.148 1.153v5.45l.003.074a.89.89 0 00.044.214l1.54 4.551a1.882 1.882 0 00-.096.084h-2.376l-1.41-4.689V7.436a1.15 1.15 0 011.148-1.153z" })));
};
FlightSeat.defaultProps = defaultIconProps;
export default FlightSeat;
