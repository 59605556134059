import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';
import { USER_ACTION_ROUTE } from './shared/constants';

const UserActionsHome = lazy(() => import('./actions/UserActionsPage'));

export const userRoutes: IRouteConfig[] = [
  {
    key: 'user-action-route',
    path: USER_ACTION_ROUTE,
    component: UserActionsHome,
  },
];

export { USER_ACTION_ALL_ROUTES } from './shared/constants';
