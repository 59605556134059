import type { UserOrgId } from '../../types/api/v1/obt/common/user_org_id';
import { useProfileReadQuery } from '../../queries/profile';
import { createUserOrgIdUsingUserId } from '../../utils/common';

interface IUseGetUserOrgIdFromUserIdReturn {
  userOrgId: UserOrgId | undefined;
  isLoading: boolean;
}

const useGetUserOrgIdFromUserId = (userId: string | undefined): IUseGetUserOrgIdFromUserIdReturn => {
  const userOrgId = createUserOrgIdUsingUserId(userId);
  const { data: traveler, isLoading } = useProfileReadQuery(userOrgId);

  return {
    userOrgId: traveler?.userOrgId,
    isLoading,
  };
};

export default useGetUserOrgIdFromUserId;
