import first from 'lodash/first';

import type { IRailLeg, IRailLegInfo } from '../../types';
import { US_RAIL_BOOKING_VENDOR_INVENTORY_NAME, US_RAIL_BOOKING_VENDOR_NAME } from '../../constants/rails';

export function getIsAmtrakLeg(leg: IRailLeg): boolean {
  return first(leg.originInfo?.sourceRefInfos)?.inventoryName === US_RAIL_BOOKING_VENDOR_INVENTORY_NAME;
}

export function getIsAmtrakLegInfo(legInfo: IRailLegInfo): boolean {
  const { vendorName } = legInfo;

  return vendorName === US_RAIL_BOOKING_VENDOR_NAME;
}
