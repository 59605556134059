import { useMemo } from 'react';
import { useLoggedInUser } from '../providers/AuthProvider';
import { useFetchCompanyBasicInfo } from '../queries/admin/index';
import type { RoleInfoType } from '../types/api/v1/obt/profile/role/roles_info.ts';
import { FeatureConfigNameEnum } from '../types/api/v2/obt/model/feature-config';
import { IRoleInfoTypeEnum } from '../types/traveler';

/**
 * Shape of JSON config for Companion View
 */
export interface ICompanionViewConfig {
  allowedRoles?: RoleInfoType[];
}

/**
 * Shape of JSON config for Companion View
 */
export interface UseCompanionViewConfigResult {
  /** Flag indicating if fetch operation is in progress */
  isLoading: boolean;
  /** List of roles for which configuration is to be done */
  allowedRoles: RoleInfoType[];
}

/**
 * Roles to be used during loading state
 */
const LOADING_ROLES: RoleInfoType[] = [];

/**
 * Default roles to be used if the feature is not enabled/configured
 */
const DEFAULT_ROLES: RoleInfoType[] = [
  IRoleInfoTypeEnum.GLOBAL_AGENT,
  IRoleInfoTypeEnum.TMC_AGENT,
  IRoleInfoTypeEnum.TMC_ADMIN,
  IRoleInfoTypeEnum.GLOBAL_ADMIN,
];

/**
 * Hook to get the Companion View Configuration
 * @returns
 */
export function useCompanionViewConfig({ enabled = true } = {}): UseCompanionViewConfigResult {
  // TODO: Consume using usePartnerFeatures when it has generic type support for feature values
  const { data: loggedInUser } = useLoggedInUser();
  const { data: basicinfo, isLoading } = useFetchCompanyBasicInfo(
    loggedInUser?.userOrgId?.organizationId?.id,
    enabled,
    undefined,
  );

  const features = basicinfo?.features;

  const allowedRoles = useMemo(() => {
    // during loading state, return a loading config
    if (isLoading) {
      return LOADING_ROLES;
    }

    const customizationFeature = features?.find(
      (feature) => feature.name === FeatureConfigNameEnum.CompanionViewConfig,
    );

    // If the feature is not enabled, return the default configuration
    if (!customizationFeature) {
      return DEFAULT_ROLES;
    }

    // try parsing the configuration
    try {
      const parsedConfig = JSON.parse(customizationFeature.value ?? '') as Partial<ICompanionViewConfig>;
      return parsedConfig.allowedRoles ?? DEFAULT_ROLES;
    } catch (error) {
      console.error('Invalid Configuration JSON:', error);
      return DEFAULT_ROLES;
    }
  }, [features, isLoading]);

  return {
    allowedRoles,
    isLoading,
  };
}
