import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const browse_more_container = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.column}
    ${flex.justify.center}
    ${flex.align.center}
    border: 1px solid ${palette.border.light};
    border-radius: 12px;
    ${paddings.x.cozy}
    ${paddings.y.cozy}
    ${spacings.medium}
    background-color: ${palette.surface.base};
    :hover {
      background-color: ${palette.bg.hover};
      cursor: pointer;
    }
  `,
);

export const browse_text = themed(
  ({ palette }) => css`
    ${flex.init}
    ${spacings.small}
    ${flex.align.center}
    color: ${palette.text.link};
  `,
);
