import * as React from 'react';

import { Typography } from '@spotnana/blocks/src/Typography';
import { Icon } from '@spotnana/blocks/src/Icon';
import { BlocksThemeProviderWhiteLabel } from 'src/app/theming/ThemeStyles';
import { error_icon, error_wrapper, success_icon, success_wrapper, wrapper } from './index.styles';

export enum ToastNotificationType {
  Error = 'Error',
  Success = 'Success',
}

interface IToastNotificationContentProps {
  type: ToastNotificationType;
  content: React.ReactNode;
}

/**
 * @param type - type of the toast notification
 * @param content - content of the toast notification
 * Wrapper for toast content in order to provide consistent styling
 */
export const ToastNotificationContent: React.FC<IToastNotificationContentProps> = ({
  type,
  content,
}: IToastNotificationContentProps) => {
  const isError = type === ToastNotificationType.Error;

  return (
    <BlocksThemeProviderWhiteLabel>
      <div css={[wrapper, isError ? error_wrapper : success_wrapper]} data-testid="toast-notification-content">
        <div>
          <Icon
            name={isError ? 'CloseCrossCircleFill' : 'CheckCircleFill'}
            css={[isError ? error_icon : success_icon]}
          />
        </div>
        <Typography variant="body2" kind="regular">
          {content}
        </Typography>
      </div>
    </BlocksThemeProviderWhiteLabel>
  );
};
