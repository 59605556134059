import React, { Ref, forwardRef } from 'react';
import { Modal, TModalProps } from '@spotnana/blocks/src/Modal';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions/transition';
import { useMediaQuery } from '@spotnana/blocks/src/utils/useMediaQuery';
import { breakpoints } from '@spotnana/blocks/src/utils';
import { side_drawer } from './styles';

interface SideDrawerProps extends TModalProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const SideDrawer: React.FC<SideDrawerProps> = ({ children, ...rest }) => {
  return (
    <Modal TransitionComponent={DynamicTransition} css={side_drawer} {...rest}>
      {children}
    </Modal>
  );
};

const DynamicTransition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  const isTabletPlus = useMediaQuery(breakpoints.from('tablet'));
  return <Slide direction={isTabletPlus ? 'left' : 'up'} {...props} ref={ref} />;
});
