import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Dropdown = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 7 4", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.87.13A.42.42 0 0 0 6.562 0H.438A.42.42 0 0 0 .13.13.42.42 0 0 0 0 .438a.42.42 0 0 0 .13.307l3.062 3.063a.42.42 0 0 0 .308.13.42.42 0 0 0 .308-.13L6.87.745A.42.42 0 0 0 7 .438.421.421 0 0 0 6.87.13Z", fill: themeEnrichedPrimaryColor })));
};
Dropdown.defaultProps = defaultIconProps;
export default Dropdown;
