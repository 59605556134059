import React from 'react';
import { defaultIconProps } from './common';
const Message = () => (React.createElement("svg", { width: "70", height: "70", viewBox: "0 0 70 70", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { opacity: "0.8", d: "M70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35Z", fill: "#E2E8ED" }),
    React.createElement("circle", { cx: "20.4783", cy: "22.4783", r: "7.47826", fill: "#88B9DA" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.5391 21.419L16.0849 22.8732L18.1138 24.902L18.1137 24.9021L19.5679 26.3563L24.8251 21.099L23.3709 19.6448L19.5679 23.4478L17.5391 21.419Z", fill: "white" }),
    React.createElement("circle", { cx: "48.5217", cy: "46.7826", r: "7.47826", fill: "#D33B47" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M48.6315 43C47.2816 43 46.1873 44.0943 46.1873 45.4442V48.2873L44.8125 49.2486H52.3438L51.0756 48.2873V45.4442C51.0756 44.0943 49.9813 43 48.6315 43ZM49.6661 50H47.5001C47.5001 50.5523 47.9478 51 48.5001 51H48.6661C49.2184 51 49.6661 50.5523 49.6661 50Z", fill: "white" }),
    React.createElement("path", { d: "M21.436 29.3403L21.4361 29.3401C23.2183 24.5587 27.7942 21.5336 32.6955 21.5336H38.1334C44.7568 21.5336 50.147 26.7139 50.0603 33.6249L50.0602 33.6249V33.6311C50.0602 40.5571 44.7508 45.7328 38.1334 45.7328H31.9778H31.7654L31.6179 45.8856L27.211 50.4538L26.9018 44.8008L26.8865 44.5197L26.6382 44.3871C24.0737 43.0172 22.1534 40.9328 21.1814 38.3681C20.2102 35.8056 20.1716 32.7263 21.436 29.3403Z", fill: "white", stroke: "#BFC8CE" }),
    React.createElement("path", { d: "M27.2138 33.7807H35.4658H43.7179", stroke: "#BFC8CE", strokeLinecap: "round" }),
    React.createElement("path", { d: "M27.2138 29.5316H35.4658H43.7179", stroke: "#BFC8CE", strokeLinecap: "round" }),
    React.createElement("path", { d: "M27.2138 38.0296H35.4658H43.7179", stroke: "#BFC8CE", strokeLinecap: "round" })));
Message.defaultProps = defaultIconProps;
export default Message;
