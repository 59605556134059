import { IOBTShopFeaturesConfig } from 'obt-common/hooks/useOBTShopFeaturesForUserId';
import { FC } from 'react';
import { AirSelectedItineraryResponseManager } from 'obt-common';
import { AirSearchResponse } from 'obt-common/types/api/v1/obt/air/air_search_response';
import { FareHeader } from '../FareHeader';
import ItineraryLegsSummary from '../../ItineraryLegsSummary';
import { FareFooter } from '../FareFooter';
import { expired_fare_container, itinerary_summary_conainer } from './styles';

interface FareSummaryContainerProps {
  fareIndex: number;
  airSearchResultsFeatures: IOBTShopFeaturesConfig;
  onRemoveFare: (fareRateOptionId: string) => void;
  airSelectedItinerariesResponse: AirSearchResponse[];
  isFareExpired: boolean;
  rateOptionId: string;
  onNavigateToFareCheckout: (fareIndex: number) => void;
}

export const FareSummaryContainer: FC<FareSummaryContainerProps> = ({
  fareIndex,
  airSearchResultsFeatures,
  onRemoveFare,
  airSelectedItinerariesResponse,
  rateOptionId,
  isFareExpired,
  onNavigateToFareCheckout,
}) => {
  const airSelectedItineraryResponse = airSelectedItinerariesResponse.find((r) =>
    r.itineraries.some((i) => i.rateOptions.some((ro) => ro.rateOptionId === rateOptionId)),
  );
  if (!airSelectedItineraryResponse) {
    return null;
  }
  const airSelectedItineraryResponseManager = new AirSelectedItineraryResponseManager(airSelectedItineraryResponse);

  const handleDeleteFare = () => {
    onRemoveFare(rateOptionId);
  };

  return (
    <div css={isFareExpired && expired_fare_container}>
      <FareHeader
        fareIndex={fareIndex}
        onRemoveFare={handleDeleteFare}
        isFareExpired={isFareExpired}
        onNavigateToFareCheckout={onNavigateToFareCheckout}
      />
      <div css={itinerary_summary_conainer}>
        <ItineraryLegsSummary
          airSearchResultsFeatures={airSearchResultsFeatures}
          airSelectedItineraryResponseManager={airSelectedItineraryResponseManager}
        />
      </div>
      <FareFooter airSelectedItineraryResponseManager={airSelectedItineraryResponseManager} />
    </div>
  );
};
