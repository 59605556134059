import type { TOptions } from 'i18next';
import { UserFacingStatus as UserFacingStatusV1, PnrStatusV1 } from '../types/api/v1/obt/pnr/pnr';
import { UserFacingStatus as UserFacingStatusV2 } from '../types/api/v2/obt/model/user-facing-status';
import type { ICommonI18nMessage } from '../translations/defineMessage';
import { defineCommonMessage } from '../translations/defineMessage';

/**
 * Icons are rendered only on web, this are the current used icon names
 */
export enum TripStatusIconName {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  QuestionCircled = 'QuestionCircled',
  Exchanged = 'BookingChange',
  None = 'None',
}

export type TripStatusProps = {
  i18nStatus: ICommonI18nMessage<object>;
  color: string;
  background: string;
  icon: TripStatusIconName;
  borderColor: string;
  i18nOptions?: TOptions<{ values: object }>;
};

export enum FlightUpdateStatus {
  DELAYED = 'DELAYED',
  CANCELLED = 'CANCELLED',
  GATE_CHANGED = 'GATE_CHANGED',
  TERMINAL_CHANGED = 'TERMINAL_CHANGED',
  CONNECTION_RISK = 'CONNECTION_RISK',
  REINSTATED = 'REINSTATED',
  MULTIPLE_UPDATES = 'MULTIPLE_UPDATES',
}

export enum FlightAlertStatus {
  DELAYED = 'DELAYED',
  GATE_CHANGED = 'GATE_CHANGED',
  CANCELLED = 'CANCELLED',
  REINSTATED = 'REINSTATED',
  CONNECTION_RISK = 'CONNECTION_RISK',
  MULTIPLE_UPDATES = 'MULTIPLE_UPDATES',
}

/**
 An object containing different color values represented as hexadecimal strings.
 WARNING: Shouldn't contain colors with opacity or transparent colors.
 On mobile colors with transparency look weird as we are using the floating badge there.
 */
export const tripStatusColors = {
  black: '#1D1D1F',
  white: '#FFFFFF',
  primary: '#D33B47',
  errorPale: '#FBEBED',
  orange: '#CC5C2B',
  orangeError: '#C73E10',
  darkSeaGreen: '#0B8254',
  yellow: '#FBBA0E',
  lightGreen: '#E9F2EE',
  lightYellow: '#FFF8EB',
};

export const defaultTripStatusConfig = {
  color: tripStatusColors.white,
  background: tripStatusColors.black,
  borderColor: tripStatusColors.black,
  icon: TripStatusIconName.QuestionCircled,
};

export const positiveTripStatusConfig = {
  color: tripStatusColors.darkSeaGreen,
  background: tripStatusColors.lightGreen,
  borderColor: tripStatusColors.lightGreen,
  icon: TripStatusIconName.Active,
};

export const pendingStatusConfig = {
  color: tripStatusColors.yellow,
  background: tripStatusColors.lightYellow,
  borderColor: tripStatusColors.lightYellow,
  icon: TripStatusIconName.Pending,
};

export const holdStatusConfig = {
  ...pendingStatusConfig,
  color: tripStatusColors.orangeError,
};

export const changedStatusConfig = {
  color: tripStatusColors.white,
  background: tripStatusColors.orange,
  borderColor: tripStatusColors.orange,
  icon: TripStatusIconName.Pending,
};

export const negativeStatusConfig = {
  color: tripStatusColors.primary,
  background: tripStatusColors.errorPale,
  borderColor: tripStatusColors.errorPale,
  icon: TripStatusIconName.Cancelled,
};

export const riskStatusConfig = {
  color: tripStatusColors.white,
  background: tripStatusColors.primary,
  borderColor: tripStatusColors.primary,
  icon: TripStatusIconName.Pending,
};

export const exchangedStatusConfig = {
  color: tripStatusColors.black,
  background: tripStatusColors.white,
  borderColor: tripStatusColors.white,
  icon: TripStatusIconName.Exchanged,
};

export const PENDING_AGENT_CONFIRMATION_TEXT = defineCommonMessage('Pending Agent Confirmation');
export const PENDING_SHELL_PNR_TEXT = defineCommonMessage('Pending Shell PNR');

/**
 * Colors returned from all the maps below are mapped to blocks colors using the useTripStatusColors hook
 * TODO (TMC infra pod) - Please remove the coupling that is here between mobile and web colors and write these maps
 * to use statuses and return colors/icons from blocks on web
 */
export const tripColorsForUserFacingStatusV1Map = new Map<UserFacingStatusV1, TripStatusProps>([
  [UserFacingStatusV1.UNKNOWN_STATUS, { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('Unknown') }],
  [UserFacingStatusV1.PENDING_STATUS, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Pending') }],
  [UserFacingStatusV1.CONFIRMED_STATUS, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Confirmed') }],
  [UserFacingStatusV1.ACTIVE_STATUS, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('In progress') }],
  [UserFacingStatusV1.COMPLETED_STATUS, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Completed') }],
  [UserFacingStatusV1.CANCELLED_STATUS, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Canceled') }],
  [UserFacingStatusV1.REFUNDED_STATUS, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Refunded') }],
  [UserFacingStatusV1.VOIDED_STATUS, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Voided') }],
  [
    UserFacingStatusV1.PROCESSING_STATUS,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Waiting for confirmation') },
  ],
  [UserFacingStatusV1.UNCONFIRMED_STATUS, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Unconfirmed') }],
  [
    UserFacingStatusV1.AIRLINE_CONTROL_STATUS,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Changed by airline') },
  ],
  [UserFacingStatusV1.INOPERATIVE_STATUS, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Inoperative') }],
  [
    UserFacingStatusV1.FLIGHT_UNCONFIRMED_STATUS,
    { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Unconfirmed') },
  ],

  [
    UserFacingStatusV1.PAYMENT_DECLINED_STATUS,
    { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Payment failure') },
  ],
  [
    UserFacingStatusV1.SCHEDULE_CHANGE_STATUS,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Schedule change') },
  ],
  [UserFacingStatusV1.HOLD_STATUS, { ...holdStatusConfig, i18nStatus: defineCommonMessage('Booking on hold') }],
  [
    UserFacingStatusV1.APPROVAL_REQUESTED_STATUS,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Pending approval') },
  ],
  [
    UserFacingStatusV1.APPROVAL_DENIED_STATUS,
    { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Approval denied') },
  ],
  [
    UserFacingStatusV1.CANCELLATION_IN_PROGRESS_STATUS,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Cancellation in progress') },
  ],
  [UserFacingStatusV1.UNRECOGNIZED, { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('Unrecognized') }],
]);

export const tripColorsForUserFacingStatusV2Map = new Map<UserFacingStatusV2, TripStatusProps>([
  [UserFacingStatusV2.UnknownStatus, { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('Unknown') }],
  [UserFacingStatusV2.PendingStatus, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Pending') }],
  [UserFacingStatusV2.ConfirmedStatus, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Confirmed') }],
  [UserFacingStatusV2.ActiveStatus, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('In progress') }],
  [UserFacingStatusV2.CompletedStatus, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Completed') }],
  [UserFacingStatusV2.CancelledStatus, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Canceled') }],
  [UserFacingStatusV2.RefundedStatus, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Refunded') }],
  [UserFacingStatusV2.VoidedStatus, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Voided') }],
  [
    UserFacingStatusV2.ProcessingStatus,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Waiting for confirmation') },
  ],
  [UserFacingStatusV2.UnconfirmedStatus, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Unconfirmed') }],
  [
    UserFacingStatusV2.FlightUnconfirmedStatus,
    { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Unconfirmed') },
  ],
  [UserFacingStatusV2.InoperativeStatus, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Inoperative') }],
  [
    UserFacingStatusV2.AirlineControlStatus,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Changed by airline') },
  ],
  [
    UserFacingStatusV2.PaymentDeclinedStatus,
    { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Payment failure') },
  ],
  [
    UserFacingStatusV2.ScheduleChangeStatus,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Schedule change') },
  ],
  [UserFacingStatusV2.HoldStatus, { ...holdStatusConfig, i18nStatus: defineCommonMessage('Booking on hold') }],
  [
    UserFacingStatusV2.ApprovalRequestedStatus,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Pending approval') },
  ],
  [
    UserFacingStatusV2.ApprovalDeniedStatus,
    { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Approval denied') },
  ],
  [
    UserFacingStatusV2.CancellationInProgressStatus,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Cancellation in progress') },
  ],
]);

export const tripColorsForPNRStatusMap = new Map<PnrStatusV1, TripStatusProps>([
  [PnrStatusV1.UNKNOWN, { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('Unknown') }],
  [PnrStatusV1.INITIATED, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Initiated') }],
  [PnrStatusV1.CANCELLED, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Canceled') }],
  [PnrStatusV1.CONFIRMED, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Confirmed') }],
  [
    PnrStatusV1.GROUP_BOOKING_ON_REQUEST,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Group booking on request') },
  ],
  [PnrStatusV1.WAITLISTED, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Waitlisted') }],
  [PnrStatusV1.PENDING, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Pending') }],
  [PnrStatusV1.AIRLINE_UPGRADE, { ...changedStatusConfig, i18nStatus: defineCommonMessage('Airline upgrade') }],
  [PnrStatusV1.WAITLIST_CONFIRMED, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Waitlist confirmed') }],
  [
    PnrStatusV1.BOOKING_DENIED_CONTACT_SUPPORT,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Booking denied contact support') },
  ],
  [PnrStatusV1.NO_SHOW, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('No show') }],
  [PnrStatusV1.CONTACT_SUPPORT, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Contact support') }],
  [
    PnrStatusV1.STATUS_CHANGED_CONTACT_SUPPORT,
    { ...changedStatusConfig, i18nStatus: defineCommonMessage('Status changed contact support') },
  ],
  [PnrStatusV1.SCHEDULE_CHANGE, { ...changedStatusConfig, i18nStatus: defineCommonMessage('Schedule change') }],
  [PnrStatusV1.SEGMENT_REQUEST, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Segment request') }],
  [
    PnrStatusV1.SCHEDULE_CHANGE_WAITLISTED_BOOKING,
    { ...changedStatusConfig, i18nStatus: defineCommonMessage('Schedule change waitlisted booking') },
  ],
  [PnrStatusV1.REQUEST_PENDING, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Request pending') }],
  [
    PnrStatusV1.WAITLISTED_NOT_CONFIRMED,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Waitlisted not confirmed') },
  ],
  [
    PnrStatusV1.SCHEDULE_CHANGE_NOT_CONFIRMED,
    { ...changedStatusConfig, i18nStatus: defineCommonMessage('Schedule change not confirmed') },
  ],
  [
    PnrStatusV1.SCHEDULE_CHANGE_PENDING_STATUS,
    { ...changedStatusConfig, i18nStatus: defineCommonMessage('Schedule change pending status') },
  ],
  [PnrStatusV1.MIS_CONNECTION, { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('Mis connection') }],
  [PnrStatusV1.REQUESTED, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Requested') }],
  [PnrStatusV1.TICKETED, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Ticketed') }],
  [PnrStatusV1.VOIDED, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Voided') }],
  [
    PnrStatusV1.CANCELLED_BY_VENDOR,
    { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('Canceled by vendor') },
  ],
  [
    PnrStatusV1.CANCELLATION_IN_PROGRESS,
    { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Cancellation in progress') },
  ],
  [PnrStatusV1.REINSTATED, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Reinstated') }],
  [PnrStatusV1.BOOKING_ON_HOLD, { ...holdStatusConfig, i18nStatus: defineCommonMessage('Booking on hold') }],
  [PnrStatusV1.AIRLINE_CONTROL, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Airline control') }],
  [PnrStatusV1.UNRECOGNIZED, { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('Unrecognized') }],
]);

export const tripColorsForFlightUpdatesMap = new Map<FlightUpdateStatus, TripStatusProps>([
  [FlightUpdateStatus.DELAYED, { ...changedStatusConfig, i18nStatus: defineCommonMessage('Delayed') }],
  [FlightUpdateStatus.CANCELLED, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Canceled') }],
  [FlightUpdateStatus.GATE_CHANGED, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Gate changed') }],
  [
    FlightUpdateStatus.TERMINAL_CHANGED,
    { ...changedStatusConfig, i18nStatus: defineCommonMessage('Terminal changed') },
  ],
  [FlightUpdateStatus.CONNECTION_RISK, { ...riskStatusConfig, i18nStatus: defineCommonMessage('Connection risk') }],
  [FlightUpdateStatus.REINSTATED, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Reinstated') }],
  [
    FlightUpdateStatus.MULTIPLE_UPDATES,
    { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('{{count}} update') },
  ],
]);

export const tripColorsForFlightAlertsMap = new Map<FlightAlertStatus, TripStatusProps>([
  [FlightAlertStatus.DELAYED, { ...changedStatusConfig, i18nStatus: defineCommonMessage('Delayed') }],
  [FlightAlertStatus.GATE_CHANGED, { ...pendingStatusConfig, i18nStatus: defineCommonMessage('Gate changed') }],
  [FlightAlertStatus.CANCELLED, { ...negativeStatusConfig, i18nStatus: defineCommonMessage('Canceled') }],
  [FlightAlertStatus.CONNECTION_RISK, { ...riskStatusConfig, i18nStatus: defineCommonMessage('Connection risk') }],
  [FlightAlertStatus.REINSTATED, { ...positiveTripStatusConfig, i18nStatus: defineCommonMessage('Reinstated') }],
  [
    FlightAlertStatus.MULTIPLE_UPDATES,
    { ...defaultTripStatusConfig, i18nStatus: defineCommonMessage('{{count}} update') },
  ],
]);
