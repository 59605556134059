import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CreditCard = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24" },
        React.createElement("path", { fillRule: "evenodd", d: "M17.25 6.375c1.243 0 2.25 1.007 2.25 2.25v6.693c0 1.243-1.007 2.25-2.25 2.25H6.75c-1.243 0-2.25-1.007-2.25-2.25V8.625c0-1.243 1.007-2.25 2.25-2.25zm.75 5.109H6v3.834c0 .38.282.693.648.743l.102.007h10.5c.414 0 .75-.336.75-.75v-3.834zm-6.962 1.972c.207 0 .375.224.375.5 0 .256-.145.468-.331.497l-.044.003H8.006c-.207 0-.375-.224-.375-.5 0-.256.144-.468.331-.497l.044-.003h3.032zm6.212-5.581H6.75c-.414 0-.75.336-.75.75v1.359h12V8.625c0-.38-.282-.693-.648-.743l-.102-.007z", fill: themeEnrichedPrimaryColor })));
};
CreditCard.defaultProps = defaultIconProps;
export default CreditCard;
