import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Wifi = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M21.744 7.681a9.99 9.99 0 010 16.384 1 1 0 11-1.148-1.637 7.988 7.988 0 003.417-6.554 7.987 7.987 0 00-3.417-6.555 1 1 0 011.148-1.638zm-10.082.245a1 1 0 01-.244 1.393A7.984 7.984 0 008 15.874c0 2.64 1.29 5.061 3.418 6.554a1 1 0 11-1.148 1.637A9.983 9.983 0 016 15.874a9.988 9.988 0 014.27-8.193 1 1 0 011.392.245zm7.826 2.978a6.06 6.06 0 010 9.94 1 1 0 01-1.149-1.637 4.062 4.062 0 000-6.666 1 1 0 011.149-1.637zm-5.57.244a.998.998 0 01-.244 1.393 4.063 4.063 0 000 6.665 1 1 0 11-1.148 1.637 6.062 6.062 0 010-9.94 1 1 0 011.393.245zm2.089 3.435a1.29 1.29 0 110 2.58 1.29 1.29 0 010-2.58z", fill: themeEnrichedPrimaryColor })));
};
Wifi.defaultProps = defaultIconProps;
export default Wifi;
