import type { IconName } from '@spotnana/blocks/src/Icon/types';

export enum AutocompleteExpandDirections {
  Left = 'left',
  Right = 'right',
}

export const AutoCompleteIconsMap: Record<string, IconName> = {
  HOME: 'HomeFill',
  OTHER: 'LocationFill',
  WORK: 'HotelBuildingFill',
};
