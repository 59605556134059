import { DEFAULT_PREFERRED_CURRENCY } from '../utils/Money/constants';
import { profileReadQueryFetch } from './profile';
import type { UserOrgId } from '../types/api/v1/obt/common/user_org_id';
import { fetchBrexBudgetInfoWithoutCard } from './useCustomFieldQuery';

interface IFetchTravelerPreferredCurrencyInput {
  primaryTravelerId: UserOrgId;
  brexJwtToken: string | undefined;
  reportBrexBudgetEmbedError?: (brexError: any) => void;
}

export const fetchTravelerPreferredCurrency = async ({
  primaryTravelerId,
  brexJwtToken,
  reportBrexBudgetEmbedError,
}: IFetchTravelerPreferredCurrencyInput) => {
  let newPreferredCurrency = DEFAULT_PREFERRED_CURRENCY;

  const hasUserOrgId = Boolean(primaryTravelerId);
  if (!hasUserOrgId) {
    return newPreferredCurrency;
  }

  const selectedTraveler = await profileReadQueryFetch(primaryTravelerId);
  const travelerPreferredCurrency = selectedTraveler?.travelerPersonalInfo?.travelPref?.preferredCurrency;
  if (travelerPreferredCurrency) {
    newPreferredCurrency = travelerPreferredCurrency;
  }

  const brexBudgetRequestEnabled = Boolean(brexJwtToken && selectedTraveler?.userBusinessInfo?.email);
  if (!brexBudgetRequestEnabled) {
    return newPreferredCurrency;
  }

  try {
    if (!brexJwtToken) {
      return newPreferredCurrency;
    }

    const brexBudgetInfo = await fetchBrexBudgetInfoWithoutCard({ brexJwtToken });

    if (brexBudgetInfo.BUDGET_CURRENCY) {
      newPreferredCurrency = brexBudgetInfo.BUDGET_CURRENCY;
    }
  } catch (brexError) {
    reportBrexBudgetEmbedError?.({ error: brexError, context: { token: brexJwtToken } });
  }

  return newPreferredCurrency;
};
