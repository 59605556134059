import { useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography';
import { traveler_picker_no_results } from './styles';

const TravelerPickerNoResults = (): JSX.Element => {
  const { t: tt } = useTranslation('WEB');
  return (
    <div css={traveler_picker_no_results}>
      <Typography variant="body1" kind="semibold">
        {tt('No results found')}
      </Typography>
    </div>
  );
};

export default TravelerPickerNoResults;
