export interface IAirlineLoyaltyObject {
  airlineName: string;
  airlineCode: string;
  loyaltyProgram: string;
  airlineLoyaltyProgram: string;
}
type IAirlineLoyaltyMap = IAirlineLoyaltyObject;

const airLoyaltyArray: IAirlineLoyaltyMap[] = [
  {
    airlineName: 'Aegean Airlines',
    airlineCode: 'A3',
    loyaltyProgram: 'Miles + Bonus',
    airlineLoyaltyProgram: 'Aegean Airlines Miles + Bonus',
  },
  {
    airlineName: 'Aer Lingus',
    airlineCode: 'EI',
    loyaltyProgram: 'AER Club',
    airlineLoyaltyProgram: 'Aer Lingus AER Club',
  },
  {
    airlineName: 'Aero Mongolia',
    airlineCode: 'M0',
    loyaltyProgram: 'Sky Miles',
    airlineLoyaltyProgram: 'Aero Mongolia Sky Miles',
  },
  {
    airlineName: 'Aeroflot',
    airlineCode: 'SU',
    loyaltyProgram: 'Aeroflot Bonus',
    airlineLoyaltyProgram: 'Aeroflot Aeroflot Bonus',
  },
  {
    airlineName: 'Aerolineas Argentinas',
    airlineCode: 'AR',
    loyaltyProgram: 'Aerolineas Plus',
    airlineLoyaltyProgram: 'Aerolineas Argentinas Aerolineas Plus',
  },
  {
    airlineName: 'Aeromexico',
    airlineCode: 'AM',
    loyaltyProgram: 'Club Premier',
    airlineLoyaltyProgram: 'Aeromexico Club Premier',
  },
  {
    airlineName: 'Aeropostal',
    airlineCode: 'VH',
    loyaltyProgram: 'Aeropass',
    airlineLoyaltyProgram: 'Aeropostal Aeropass',
  },
  {
    airlineName: 'Afriqiyah Airways',
    airlineCode: '8U',
    loyaltyProgram: 'Rahal',
    airlineLoyaltyProgram: 'Afriqiyah Airways Rahal',
  },
  {
    airlineName: 'Air Algerie SpA',
    airlineCode: 'AH',
    loyaltyProgram: 'Air Algerie Plus',
    airlineLoyaltyProgram: 'Air Algerie SpA Air Algerie Plus',
  },
  {
    airlineName: 'Air Astana',
    airlineCode: 'KC',
    loyaltyProgram: 'Nomad Club',
    airlineLoyaltyProgram: 'Air Astana Nomad Club',
  },
  {
    airlineName: 'Air Austral',
    airlineCode: 'UU',
    loyaltyProgram: 'Capricorne Program',
    airlineLoyaltyProgram: 'Air Austral Capricorne Program',
  },
  {
    airlineName: 'Air Bishkek',
    airlineCode: 'KR',
    loyaltyProgram: 'Belek Bonus',
    airlineLoyaltyProgram: 'Air Bishkek Belek Bonus',
  },
  {
    airlineName: 'Air Busan',
    airlineCode: 'BX',
    loyaltyProgram: 'Fly & Stamp',
    airlineLoyaltyProgram: 'Air Busan Fly & Stamp',
  },
  {
    airlineName: 'Air Calin',
    airlineCode: 'SB',
    loyaltyProgram: 'Flying Blue',
    airlineLoyaltyProgram: 'Air Calin Flying Blue',
  },
  {
    airlineName: 'Air Canada',
    airlineCode: 'AC',
    loyaltyProgram: 'Aeroplan',
    airlineLoyaltyProgram: 'Air Canada Aeroplan',
  },
  {
    airlineName: 'Air Caraibes',
    airlineCode: 'TX',
    loyaltyProgram: 'Preference Loyalty Program',
    airlineLoyaltyProgram: 'Air Caraibes Preference Loyalty Program',
  },
  {
    airlineName: 'Air China',
    airlineCode: 'CA',
    loyaltyProgram: 'Phoenix Miles',
    airlineLoyaltyProgram: 'Air China Phoenix Miles',
  },
  {
    airlineName: "Air Cote d'Ivoire",
    airlineCode: 'HF',
    loyaltyProgram: 'Smiles Program',
    airlineLoyaltyProgram: "Air Cote d'Ivoire Smiles Program",
  },
  {
    airlineName: 'Air Do',
    airlineCode: 'HD',
    loyaltyProgram: 'My AirDo',
    airlineLoyaltyProgram: 'Air Do My AirDo',
  },
  {
    airlineName: 'Air Europa',
    airlineCode: 'UX',
    loyaltyProgram: 'Suma',
    airlineLoyaltyProgram: 'Air Europa Suma',
  },
  {
    airlineName: 'Air France',
    airlineCode: 'AF',
    loyaltyProgram: 'Flying Blue',
    airlineLoyaltyProgram: 'Air France Flying Blue',
  },
  {
    airlineName: 'Air India',
    airlineCode: 'AI',
    loyaltyProgram: 'Flying Returns',
    airlineLoyaltyProgram: 'Air India Flying Returns',
  },
  {
    airlineName: 'Air Indus',
    airlineCode: 'I6',
    loyaltyProgram: 'Indus Miles',
    airlineLoyaltyProgram: 'Air Indus Indus Miles',
  },
  {
    airlineName: 'Air Inuit',
    airlineCode: '3H',
    loyaltyProgram: 'Isaruuk Rewards',
    airlineLoyaltyProgram: 'Air Inuit Isaruuk Rewards',
  },
  {
    airlineName: 'Air Jamaica',
    airlineCode: 'JM',
    loyaltyProgram: '7th Heaven',
    airlineLoyaltyProgram: 'Air Jamaica 7th Heaven',
  },
  {
    airlineName: 'Air Madagascar',
    airlineCode: 'MD',
    loyaltyProgram: 'Namako',
    airlineLoyaltyProgram: 'Air Madagascar Namako',
  },
  {
    airlineName: 'Air Malta',
    airlineCode: 'KM',
    loyaltyProgram: 'Flypass',
    airlineLoyaltyProgram: 'Air Malta Flypass',
  },
  {
    airlineName: 'Air Mauritius',
    airlineCode: 'MK',
    loyaltyProgram: 'Kestrel Flyer',
    airlineLoyaltyProgram: 'Air Mauritius Kestrel Flyer',
  },
  {
    airlineName: 'Air Moldova',
    airlineCode: '9U',
    loyaltyProgram: 'Air Moldova Club',
    airlineLoyaltyProgram: 'Air Moldova Air Moldova Club',
  },
  {
    airlineName: 'Air Namibia',
    airlineCode: 'SW',
    loyaltyProgram: 'Reward$',
    airlineLoyaltyProgram: 'Air Namibia Reward$',
  },
  {
    airlineName: 'Air New Zealand',
    airlineCode: 'NZ',
    loyaltyProgram: 'Airpoints',
    airlineLoyaltyProgram: 'Air New Zealand Airpoints',
  },
  {
    airlineName: 'Air Niugini',
    airlineCode: 'PX',
    loyaltyProgram: 'Destinations',
    airlineLoyaltyProgram: 'Air Niugini Destinations',
  },
  {
    airlineName: 'Air Tahiti Nui',
    airlineCode: 'TN',
    loyaltyProgram: 'Club Tiare',
    airlineLoyaltyProgram: 'Air Tahiti Nui Club Tiare',
  },
  {
    airlineName: 'Air Zimbabwe',
    airlineCode: 'UM',
    loyaltyProgram: 'Rainbow Club',
    airlineLoyaltyProgram: 'Air Zimbabwe Rainbow Club',
  },
  {
    airlineName: 'AirAsia',
    airlineCode: 'AK',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'AirAsia Big Rewards',
  },
  {
    airlineName: 'AirAsia India',
    airlineCode: 'I5',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'AirAsia India Big Rewards',
  },
  {
    airlineName: 'AirAsia X',
    airlineCode: 'D7',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'AirAsia X Big Rewards',
  },
  {
    airlineName: 'airBaltic',
    airlineCode: 'BT',
    loyaltyProgram: 'airBaltic Club',
    airlineLoyaltyProgram: 'airBaltic airBaltic Club',
  },
  {
    airlineName: 'Airblue',
    airlineCode: 'PA',
    loyaltyProgram: 'Blue Miles',
    airlineLoyaltyProgram: 'Airblue Blue Miles',
  },
  {
    airlineName: 'Alaska Airlines',
    airlineCode: 'AS',
    loyaltyProgram: 'Mileage Plan',
    airlineLoyaltyProgram: 'Alaska Airlines Mileage Plan',
  },
  {
    airlineName: 'ITA Airways',
    airlineCode: 'AZ',
    loyaltyProgram: 'MilleMiglia',
    airlineLoyaltyProgram: 'Alitalia MilleMiglia',
  },
  {
    airlineName: 'All Nippon Airways',
    airlineCode: 'NH',
    loyaltyProgram: 'ANA Mileage Club',
    airlineLoyaltyProgram: 'All Nippon Airways ANA Mileage Club',
  },
  {
    airlineName: 'Amakusa Airlines',
    airlineCode: 'MZ',
    loyaltyProgram: 'AMX Point Card',
    airlineLoyaltyProgram: 'Amakusa Airlines AMX Point Card',
  },
  {
    airlineName: 'American Airlines',
    airlineCode: 'AA',
    loyaltyProgram: 'AAdvantage',
    airlineLoyaltyProgram: 'American Airlines AAdvantage',
  },
  {
    airlineName: 'Ariana Afghan Airlines',
    airlineCode: 'FG',
    loyaltyProgram: 'Ariana Loyalty Club',
    airlineLoyaltyProgram: 'Ariana Afghan Airlines Ariana Loyalty Club',
  },
  {
    airlineName: 'Aserca Airlines',
    airlineCode: 'R7',
    loyaltyProgram: 'Privilege',
    airlineLoyaltyProgram: 'Aserca Airlines Privilege',
  },
  {
    airlineName: 'Asiana Airlines',
    airlineCode: 'OZ',
    loyaltyProgram: 'Asiana Club',
    airlineLoyaltyProgram: 'Asiana Airlines Asiana Club',
  },
  {
    airlineName: 'ASKY Airlines',
    airlineCode: 'KP',
    loyaltyProgram: 'ASKY Club',
    airlineLoyaltyProgram: 'ASKY Airlines ASKY Club',
  },
  {
    airlineName: 'Atlantic Airways',
    airlineCode: 'RC',
    loyaltyProgram: 'Sulubonus',
    airlineLoyaltyProgram: 'Atlantic Airways Sulubonus',
  },
  {
    airlineName: 'Aurigny',
    airlineCode: 'GR',
    loyaltyProgram: 'Aurigny Frequent Flyer',
    airlineLoyaltyProgram: 'Aurigny Aurigny Frequent Flyer',
  },
  {
    airlineName: 'Austrian Airlines',
    airlineCode: 'OS',
    loyaltyProgram: 'Miles & More',
    airlineLoyaltyProgram: 'Austrian Airlines Miles & More',
  },
  {
    airlineName: 'Avianca',
    airlineCode: 'AV',
    loyaltyProgram: 'LifeMiles',
    airlineLoyaltyProgram: 'Avianca LifeMiles',
  },
  {
    airlineName: 'Avior Airlines',
    airlineCode: '9V',
    loyaltyProgram: 'Avior Plus',
    airlineLoyaltyProgram: 'Avior Airlines Avior Plus',
  },
  {
    airlineName: 'Azerbaijan Airlines',
    airlineCode: 'J2',
    loyaltyProgram: 'AZAL Miles',
    airlineLoyaltyProgram: 'Azerbaijan Airlines AZAL Miles',
  },
  {
    airlineName: 'Azul Brazilian Airlines',
    airlineCode: 'AD',
    loyaltyProgram: 'TudoAzul',
    airlineLoyaltyProgram: 'Azul Brazilian Airlines TudoAzul',
  },
  {
    airlineName: 'BahamasAir',
    airlineCode: 'UP',
    loyaltyProgram: 'BahamasAir Flyer',
    airlineLoyaltyProgram: 'BahamasAir BahamasAir Flyer',
  },
  {
    airlineName: 'Bamboo Airways',
    airlineCode: 'QH',
    loyaltyProgram: 'Bamboo Club',
    airlineLoyaltyProgram: 'Bamboo Airways Bamboo Club',
  },
  {
    airlineName: 'Bangkok Airways',
    airlineCode: 'PG',
    loyaltyProgram: 'FlyerBonus',
    airlineLoyaltyProgram: 'Bangkok Airways FlyerBonus',
  },
  {
    airlineName: 'Batik Air',
    airlineCode: 'ID',
    loyaltyProgram: 'Batik Frequent Flyer',
    airlineLoyaltyProgram: 'Batik Air Batik Frequent Flyer',
  },
  {
    airlineName: 'Bearskin Airlines',
    airlineCode: 'JV',
    loyaltyProgram: 'Connecting Rewards',
    airlineLoyaltyProgram: 'Bearskin Airlines Connecting Rewards',
  },
  {
    airlineName: 'Belavia',
    airlineCode: 'B2',
    loyaltyProgram: 'Leader',
    airlineLoyaltyProgram: 'Belavia Leader',
  },
  {
    airlineName: 'Biman Bangladesh Airlines',
    airlineCode: 'BG',
    loyaltyProgram: 'Biman Loyalty Club',
    airlineLoyaltyProgram: 'Biman Bangladesh Airlines Biman Loyalty Club',
  },
  {
    airlineName: 'Binter Canarias',
    airlineCode: 'NT',
    loyaltyProgram: 'BinterMas',
    airlineLoyaltyProgram: 'Binter Canarias BinterMas',
  },
  {
    airlineName: 'Blue Air',
    airlineCode: '0B',
    loyaltyProgram: 'My Blue',
    airlineLoyaltyProgram: 'Blue Air My Blue',
  },
  {
    airlineName: 'BRA Braathens Regional Airlines',
    airlineCode: 'DC',
    loyaltyProgram: 'BRA Friends',
    airlineLoyaltyProgram: 'BRA Braathens Regional Airlines BRA Friends',
  },
  {
    airlineName: 'British Airways',
    airlineCode: 'BA',
    loyaltyProgram: 'Executive Club',
    airlineLoyaltyProgram: 'British Airways Executive Club',
  },
  {
    airlineName: 'Brussels Airlines',
    airlineCode: 'SN',
    loyaltyProgram: 'Miles & More',
    airlineLoyaltyProgram: 'Brussels Airlines Miles & More',
  },
  {
    airlineName: 'Bulgaria Air',
    airlineCode: 'FB',
    loyaltyProgram: 'Fly More',
    airlineLoyaltyProgram: 'Bulgaria Air Fly More',
  },
  {
    airlineName: 'Calm Air',
    airlineCode: 'MO',
    loyaltyProgram: 'Calm Rewards',
    airlineLoyaltyProgram: 'Calm Air Calm Rewards',
  },
  {
    airlineName: 'Cambodia Angkor Air',
    airlineCode: 'K6',
    loyaltyProgram: 'AngkorWards',
    airlineLoyaltyProgram: 'Cambodia Angkor Air AngkorWards',
  },
  {
    airlineName: 'Canadian North',
    airlineCode: '5T',
    loyaltyProgram: 'Aurora Rewards',
    airlineLoyaltyProgram: 'Canadian North Aurora Rewards',
  },
  {
    airlineName: 'Caribbean Airlines',
    airlineCode: 'BW',
    loyaltyProgram: 'Caribbean Miles',
    airlineLoyaltyProgram: 'Caribbean Airlines Caribbean Miles',
  },
  {
    airlineName: 'Carpatair',
    airlineCode: 'V3',
    loyaltyProgram: 'Carpatair Frequent Flyer',
    airlineLoyaltyProgram: 'Carpatair Carpatair Frequent Flyer',
  },
  {
    airlineName: 'Cathay Pacific',
    airlineCode: 'CX',
    loyaltyProgram: 'Asia Miles, The Marco Polo Club',
    airlineLoyaltyProgram: 'Cathay Pacific Asia Miles, The Marco Polo Club',
  },
  {
    airlineName: 'Cayman Airways',
    airlineCode: 'KX',
    loyaltyProgram: 'Sir Turtle Rewards',
    airlineLoyaltyProgram: 'Cayman Airways Sir Turtle Rewards',
  },
  {
    airlineName: 'Cebu Pacific',
    airlineCode: '5J',
    loyaltyProgram: 'Getgo',
    airlineLoyaltyProgram: 'Cebu Pacific Getgo',
  },
  {
    airlineName: 'China Airlines',
    airlineCode: 'CI',
    loyaltyProgram: 'Dynasty Flyer',
    airlineLoyaltyProgram: 'China Airlines Dynasty Flyer',
  },
  {
    airlineName: 'China Eastern Airlines',
    airlineCode: 'MU',
    loyaltyProgram: 'Eastern Miles',
    airlineLoyaltyProgram: 'China Eastern Airlines Eastern Miles',
  },
  {
    airlineName: 'China Southern Airlines',
    airlineCode: 'CZ',
    loyaltyProgram: 'Sky Pearl Club',
    airlineLoyaltyProgram: 'China Southern Airlines Sky Pearl Club',
  },
  {
    airlineName: 'Copa Airlines',
    airlineCode: 'CM',
    loyaltyProgram: 'ConnectMiles',
    airlineLoyaltyProgram: 'Copa Airlines ConnectMiles',
  },
  {
    airlineName: 'Corsair',
    airlineCode: 'SS',
    loyaltyProgram: 'Club Corsair',
    airlineLoyaltyProgram: 'Corsair Club Corsair',
  },
  {
    airlineName: 'Czech Airlines',
    airlineCode: 'OK',
    loyaltyProgram: 'OK Plus',
    airlineLoyaltyProgram: 'Czech Airlines OK Plus',
  },
  {
    airlineName: 'Delta Air Lines',
    airlineCode: 'DL',
    loyaltyProgram: 'SkyMiles',
    airlineLoyaltyProgram: 'Delta Air Lines SkyMiles',
  },
  {
    airlineName: 'Dniproavia Airlines',
    airlineCode: 'Z6',
    loyaltyProgram: 'Bonus Club',
    airlineLoyaltyProgram: 'Dniproavia Airlines Bonus Club',
  },
  {
    airlineName: 'Donghai Airlines',
    airlineCode: 'DZ',
    loyaltyProgram: 'Seagull Club',
    airlineLoyaltyProgram: 'Donghai Airlines Seagull Club',
  },
  {
    airlineName: 'Druk Air',
    airlineCode: 'KB',
    loyaltyProgram: 'HappinesSMiles',
    airlineLoyaltyProgram: 'Druk Air HappinesSMiles',
  },
  {
    airlineName: 'EasyJet',
    airlineCode: 'U2',
    loyaltyProgram: 'EasyJet Flight Club',
    airlineLoyaltyProgram: 'EasyJet EasyJet Flight Club',
  },
  {
    airlineName: 'EgyptAir',
    airlineCode: 'MS',
    loyaltyProgram: 'Egyptair Plus',
    airlineLoyaltyProgram: 'EgyptAir Egyptair Plus',
  },
  {
    airlineName: 'El Al',
    airlineCode: 'LY',
    loyaltyProgram: 'Matmid Club',
    airlineLoyaltyProgram: 'El Al Matmid Club',
  },
  {
    airlineName: 'Emirates Airlines',
    airlineCode: 'EK',
    loyaltyProgram: 'Skywards',
    airlineLoyaltyProgram: 'Emirates Airlines Skywards',
  },
  {
    airlineName: 'Ethiopian Airlines',
    airlineCode: 'ET',
    loyaltyProgram: 'Sheba Miles',
    airlineLoyaltyProgram: 'Ethiopian Airlines Sheba Miles',
  },
  {
    airlineName: 'Etihad Airlines',
    airlineCode: 'EY',
    loyaltyProgram: 'Etihad Guest',
    airlineLoyaltyProgram: 'Etihad Airlines Etihad Guest',
  },
  {
    airlineName: 'EVA Air',
    airlineCode: 'BR',
    loyaltyProgram: 'Infinity MileageLands',
    airlineLoyaltyProgram: 'EVA Air Infinity MileageLands',
  },
  {
    airlineName: 'Fiji Airways',
    airlineCode: 'FJ',
    loyaltyProgram: 'Tabua Club',
    airlineLoyaltyProgram: 'Fiji Airways Tabua Club',
  },
  {
    airlineName: 'Finnair',
    airlineCode: 'AY',
    loyaltyProgram: 'Finnair Plus',
    airlineLoyaltyProgram: 'Finnair Finnair Plus',
  },
  {
    airlineName: 'flydubai',
    airlineCode: 'FZ',
    loyaltyProgram: 'Emirates Skywards',
    airlineLoyaltyProgram: 'flydubai Emirates Skywards',
  },
  {
    airlineName: 'Flynas',
    airlineCode: 'XY',
    loyaltyProgram: 'Nasmiles',
    airlineLoyaltyProgram: 'Flynas Nasmiles',
  },
  {
    airlineName: 'Frontier Airlines',
    airlineCode: 'F9',
    loyaltyProgram: 'Frontier Miles',
    airlineLoyaltyProgram: 'Frontier Airlines Frontier Miles',
  },
  {
    airlineName: 'Garuda Indonesia',
    airlineCode: 'GA',
    loyaltyProgram: 'GarudaMiles',
    airlineLoyaltyProgram: 'Garuda Indonesia GarudaMiles',
  },
  {
    airlineName: 'Gol Transportes Aereos',
    airlineCode: 'G3',
    loyaltyProgram: 'Smiles',
    airlineLoyaltyProgram: 'Gol Transportes Aereos Smiles',
  },
  {
    airlineName: 'Gulf Air',
    airlineCode: 'GF',
    loyaltyProgram: 'Falconflyer',
    airlineLoyaltyProgram: 'Gulf Air Falconflyer',
  },
  {
    airlineName: 'Hainan Airlines',
    airlineCode: 'HU',
    loyaltyProgram: 'Fortune Wings Club',
    airlineLoyaltyProgram: 'Hainan Airlines Fortune Wings Club',
  },
  {
    airlineName: 'Hawaiian Airlines',
    airlineCode: 'HA',
    loyaltyProgram: 'HawaiianMiles',
    airlineLoyaltyProgram: 'Hawaiian Airlines HawaiianMiles',
  },
  {
    airlineName: 'Hong Kong Express',
    airlineCode: 'UO',
    loyaltyProgram: 'Asia Miles',
    airlineLoyaltyProgram: 'Hong Kong Express Asia Miles',
  },
  {
    airlineName: 'Hunnu Air',
    airlineCode: 'MR',
    loyaltyProgram: 'Hunnu Club',
    airlineLoyaltyProgram: 'Hunnu Air Hunnu Club',
  },
  {
    airlineName: 'Iberia',
    airlineCode: 'IB',
    loyaltyProgram: 'IBERA PLUS',
    airlineLoyaltyProgram: 'Iberia IBERA PLUS',
  },
  {
    airlineName: 'Icelandair',
    airlineCode: 'FI',
    loyaltyProgram: 'Saga Club',
    airlineLoyaltyProgram: 'Icelandair Saga Club',
  },
  {
    airlineName: 'Indonesia AirAsia',
    airlineCode: 'QZ',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'Indonesia AirAsia Big Rewards',
  },
  {
    airlineName: 'Indonesia AirAsia X',
    airlineCode: 'XT',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'Indonesia AirAsia X Big Rewards',
  },
  {
    airlineName: 'Interjet',
    airlineCode: '4O',
    loyaltyProgram: 'Club Interjet',
    airlineLoyaltyProgram: 'Interjet Club Interjet',
  },
  {
    airlineName: 'IranAir',
    airlineCode: 'IR',
    loyaltyProgram: 'SkyGift',
    airlineLoyaltyProgram: 'IranAir SkyGift',
  },
  {
    airlineName: 'Island Air',
    airlineCode: 'Fi',
    loyaltyProgram: 'Island Miles',
    airlineLoyaltyProgram: 'Island Air Island Miles',
  },
  {
    airlineName: 'Japan Airlines',
    airlineCode: 'JL',
    loyaltyProgram: 'JAL Mileage Bank',
    airlineLoyaltyProgram: 'Japan Airlines JAL Mileage Bank',
  },
  {
    airlineName: 'Jeju Air',
    airlineCode: '7C',
    loyaltyProgram: 'Refresh Point',
    airlineLoyaltyProgram: 'Jeju Air Refresh Point',
  },
  {
    airlineName: 'Jet2',
    airlineCode: 'LS',
    loyaltyProgram: 'MyJet2 rewards',
    airlineLoyaltyProgram: 'Jet2 MyJet2 rewards',
  },
  {
    airlineName: 'JetBlue Airways',
    airlineCode: 'B6',
    loyaltyProgram: 'TrueBlue',
    airlineLoyaltyProgram: 'JetBlue Airways TrueBlue',
  },
  {
    airlineName: 'Juneyao Airlines',
    airlineCode: 'HO',
    loyaltyProgram: 'Juneyao Air Club',
    airlineLoyaltyProgram: 'Juneyao Airlines Juneyao Air Club',
  },
  {
    airlineName: 'Kam Air',
    airlineCode: 'RQ',
    loyaltyProgram: 'Orange Miles',
    airlineLoyaltyProgram: 'Kam Air Orange Miles',
  },
  {
    airlineName: 'Kenya Airways',
    airlineCode: 'KQ',
    loyaltyProgram: 'BlueBiz',
    airlineLoyaltyProgram: 'Kenya Airways BlueBiz',
  },
  {
    airlineName: 'KLM',
    airlineCode: 'KL',
    loyaltyProgram: 'Flying Blue',
    airlineLoyaltyProgram: 'KLM Flying Blue',
  },
  {
    airlineName: 'Korean Air',
    airlineCode: 'KE',
    loyaltyProgram: 'SKYPASS',
    airlineLoyaltyProgram: 'Korean Air SKYPASS',
  },
  {
    airlineName: 'Kunming Airlines',
    airlineCode: 'KY',
    loyaltyProgram: 'Priority Club',
    airlineLoyaltyProgram: 'Kunming Airlines Priority Club',
  },
  {
    airlineName: 'Kuwait Airways',
    airlineCode: 'KU',
    loyaltyProgram: 'Oasis Club',
    airlineLoyaltyProgram: 'Kuwait Airways Oasis Club',
  },
  {
    airlineName: 'LAM Mozambique Airlines',
    airlineCode: 'TM',
    loyaltyProgram: 'Flamingo Club',
    airlineLoyaltyProgram: 'LAM Mozambique Airlines Flamingo Club',
  },
  {
    airlineName: 'Lao Airlines',
    airlineCode: 'QV',
    loyaltyProgram: 'Champa Muang Lao',
    airlineLoyaltyProgram: 'Lao Airlines Champa Muang Lao',
  },
  {
    airlineName: 'LATAM Airlines',
    airlineCode: 'LA',
    loyaltyProgram: 'LATAM Pass',
    airlineLoyaltyProgram: 'LATAM Airlines LATAM Pass',
  },
  {
    airlineName: 'Lion Air',
    airlineCode: 'JT',
    loyaltyProgram: 'Lion Passport',
    airlineLoyaltyProgram: 'Lion Air Lion Passport',
  },
  {
    airlineName: 'Loganair',
    airlineCode: 'LM',
    loyaltyProgram: 'Clan Loganair',
    airlineLoyaltyProgram: 'Loganair  Clan Loganair',
  },
  {
    airlineName: 'LOT Polish Airlines',
    airlineCode: 'LO',
    loyaltyProgram: 'Miles & More',
    airlineLoyaltyProgram: 'LOT Polish Airlines Miles & More',
  },
  {
    airlineName: 'Lufthansa',
    airlineCode: 'LH',
    loyaltyProgram: 'Miles & More',
    airlineLoyaltyProgram: 'Lufthansa Miles & More',
  },
  {
    airlineName: 'Mahan Air',
    airlineCode: 'W5',
    loyaltyProgram: 'Mahan & Miles',
    airlineLoyaltyProgram: 'Mahan Air Mahan & Miles',
  },
  {
    airlineName: 'Malaysia Airlines',
    airlineCode: 'MH',
    loyaltyProgram: 'Enrich',
    airlineLoyaltyProgram: 'Malaysia Airlines Enrich',
  },
  {
    airlineName: 'Malindo Air',
    airlineCode: 'OD',
    loyaltyProgram: 'Malindo Miles',
    airlineLoyaltyProgram: 'Malindo Air Malindo Miles',
  },
  {
    airlineName: 'Mango Airlines',
    airlineCode: 'JE',
    loyaltyProgram: 'Voyager',
    airlineLoyaltyProgram: 'Mango Airlines Voyager',
  },
  {
    airlineName: 'Meridiana',
    airlineCode: 'IG',
    loyaltyProgram: 'Meridiana Club',
    airlineLoyaltyProgram: 'Meridiana Meridiana Club',
  },
  {
    airlineName: 'MIAT Mongolian Airlines',
    airlineCode: 'OM',
    loyaltyProgram: 'Blue Sky Mongolia',
    airlineLoyaltyProgram: 'MIAT Mongolian Airlines Blue Sky Mongolia',
  },
  {
    airlineName: 'Middle East Airlines',
    airlineCode: 'ME',
    loyaltyProgram: 'Cedar Miles',
    airlineLoyaltyProgram: 'Middle East Airlines Cedar Miles',
  },
  {
    airlineName: 'Myanmar Airways International',
    airlineCode: '8M',
    loyaltyProgram: 'Sky Smiles Club',
    airlineLoyaltyProgram: 'Myanmar Airways International Sky Smiles Club',
  },
  {
    airlineName: 'Nok Air',
    airlineCode: 'DD',
    loyaltyProgram: 'Nok Fan Club',
    airlineLoyaltyProgram: 'Nok Air Nok Fan Club',
  },
  {
    airlineName: 'Nordavia Airlines',
    airlineCode: '5N',
    loyaltyProgram: 'Smartavia Miles',
    airlineLoyaltyProgram: 'Nordavia Airlines Smartavia Miles',
  },
  {
    airlineName: 'Norwegian Airlines',
    airlineCode: 'DY',
    loyaltyProgram: 'Norwegian Reward',
    airlineLoyaltyProgram: 'Norwegian Airlines Norwegian Reward',
  },
  {
    airlineName: 'NovoAir',
    airlineCode: 'VQ',
    loyaltyProgram: 'Smiles',
    airlineLoyaltyProgram: 'NovoAir Smiles',
  },
  {
    airlineName: 'Okay Airways',
    airlineCode: 'BK',
    loyaltyProgram: 'Lucky Clouds Club',
    airlineLoyaltyProgram: 'Okay Airways Lucky Clouds Club',
  },
  {
    airlineName: 'Oman Air',
    airlineCode: 'WY',
    loyaltyProgram: 'Sindbad',
    airlineLoyaltyProgram: 'Oman Air Sindbad',
  },
  {
    airlineName: 'Onur Air',
    airlineCode: '8Q',
    loyaltyProgram: 'Onur Extra',
    airlineLoyaltyProgram: 'Onur Air Onur Extra',
  },
  {
    airlineName: 'Pakistan International Airlines',
    airlineCode: 'PK',
    loyaltyProgram: 'PIA Awards Plus+',
    airlineLoyaltyProgram: 'Pakistan International Airlines PIA Awards Plus+',
  },
  {
    airlineName: 'Pegasus Airlines',
    airlineCode: 'PC',
    loyaltyProgram: 'Pegasus BolBol',
    airlineLoyaltyProgram: 'Pegasus Airlines Pegasus BolBol',
  },
  {
    airlineName: 'Philippine Airlines',
    airlineCode: 'PR',
    loyaltyProgram: 'Mabuhay Miles',
    airlineLoyaltyProgram: 'Philippine Airlines Mabuhay Miles',
  },
  {
    airlineName: 'Philippines AirAsia',
    airlineCode: 'Z2',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'Philippines AirAsia Big Rewards',
  },
  {
    airlineName: 'Porter Airlines',
    airlineCode: 'PD',
    loyaltyProgram: 'VIPorter',
    airlineLoyaltyProgram: 'Porter Airlines VIPorter',
  },
  {
    airlineName: 'Precision Air',
    airlineCode: 'PW',
    loyaltyProgram: 'PAA Royal',
    airlineLoyaltyProgram: 'Precision Air PAA Royal',
  },
  {
    airlineName: 'Qantas',
    airlineCode: 'QF',
    loyaltyProgram: 'Qantas Frequent Flyer',
    airlineLoyaltyProgram: 'Qantas Qantas Frequent Flyer',
  },
  {
    airlineName: 'Qatar Airways',
    airlineCode: 'QR',
    loyaltyProgram: 'Privilege Club',
    airlineLoyaltyProgram: 'Qatar Airways Privilege Club',
  },
  {
    airlineName: 'Qingdao Airlines',
    airlineCode: 'QW',
    loyaltyProgram: 'Tianhaizhiyun Club',
    airlineLoyaltyProgram: 'Qingdao Airlines Tianhaizhiyun Club',
  },
  {
    airlineName: 'Ravn Alaska',
    airlineCode: '7H',
    loyaltyProgram: 'FlyAway Rewards',
    airlineLoyaltyProgram: 'Ravn Alaska FlyAway Rewards',
  },
  {
    airlineName: 'Royal Air Maroc',
    airlineCode: 'AT',
    loyaltyProgram: 'Safar Flyer',
    airlineLoyaltyProgram: 'Royal Air Maroc Safar Flyer',
  },
  {
    airlineName: 'Royal Brunei Airlines',
    airlineCode: 'BI',
    loyaltyProgram: 'Royal skies',
    airlineLoyaltyProgram: 'Royal Brunei Airlines Royal skies',
  },
  {
    airlineName: 'Royal Jordanian',
    airlineCode: 'RJ',
    loyaltyProgram: 'Royal Club',
    airlineLoyaltyProgram: 'Royal Jordanian Royal Club',
  },
  {
    airlineName: 'RwandAir',
    airlineCode: 'WB',
    loyaltyProgram: 'Dream Miles',
    airlineLoyaltyProgram: 'RwandAir Dream Miles',
  },
  {
    airlineName: 'S7 Airlines',
    airlineCode: 'S7',
    loyaltyProgram: 'S7 Priority',
    airlineLoyaltyProgram: 'S7 Airlines S7 Priority',
  },
  {
    airlineName: 'Safi Airways',
    airlineCode: '4Q',
    loyaltyProgram: 'Saffron Rewards',
    airlineLoyaltyProgram: 'Safi Airways Saffron Rewards',
  },
  {
    airlineName: 'Saudia Airlines',
    airlineCode: 'SV',
    loyaltyProgram: 'Alfursan',
    airlineLoyaltyProgram: 'Saudia Airlines Alfursan',
  },
  {
    airlineName: 'SaudiGulf',
    airlineCode: '6S',
    loyaltyProgram: 'SaudiGulf Club',
    airlineLoyaltyProgram: 'SaudiGulf SaudiGulf Club',
  },
  {
    airlineName: 'Saurya Airlines',
    airlineCode: 'S1',
    loyaltyProgram: 'Saurya Saarathi',
    airlineLoyaltyProgram: 'Saurya Airlines Saurya Saarathi',
  },
  {
    airlineName: 'Scandinavian Airlines',
    airlineCode: 'SK',
    loyaltyProgram: 'EuroBonus',
    airlineLoyaltyProgram: 'Scandinavian Airlines EuroBonus',
  },
  {
    airlineName: 'Seaborne Airlines',
    airlineCode: 'BB',
    loyaltyProgram: 'Airmiles',
    airlineLoyaltyProgram: 'Seaborne Airlines Airmiles',
  },
  {
    airlineName: 'Shree Airlines',
    airlineCode: 'N9',
    loyaltyProgram: 'High Flyer Club',
    airlineLoyaltyProgram: 'Shree Airlines High Flyer Club',
  },
  {
    airlineName: 'Sichuan Airlines',
    airlineCode: '3U',
    loyaltyProgram: 'Golden Panda Club',
    airlineLoyaltyProgram: 'Sichuan Airlines Golden Panda Club',
  },
  {
    airlineName: 'Singapore Airlines',
    airlineCode: 'SQ',
    loyaltyProgram: 'KrisFlyer, PPS Club',
    airlineLoyaltyProgram: 'Singapore Airlines KrisFlyer, PPS Club',
  },
  {
    airlineName: 'Solaseed Air',
    airlineCode: '6J',
    loyaltyProgram: 'Solaseed Smile Club',
    airlineLoyaltyProgram: 'Solaseed Air Solaseed Smile Club',
  },
  {
    airlineName: 'Solomon Airlines',
    airlineCode: 'IE',
    loyaltyProgram: 'Belama Club',
    airlineLoyaltyProgram: 'Solomon Airlines Belama Club',
  },
  {
    airlineName: 'South African Airways',
    airlineCode: 'SA',
    loyaltyProgram: 'Voyager',
    airlineLoyaltyProgram: 'South African Airways Voyager',
  },
  {
    airlineName: 'Southwest Airlines',
    airlineCode: 'WN',
    loyaltyProgram: 'Rapid Rewards',
    airlineLoyaltyProgram: 'Southwest Airlines Rapid Rewards',
  },
  {
    airlineName: 'SpiceJet',
    airlineCode: 'SG',
    loyaltyProgram: 'Spice Club',
    airlineLoyaltyProgram: 'SpiceJet Spice Club',
  },
  {
    airlineName: 'Spirit Airlines',
    airlineCode: 'NK',
    loyaltyProgram: 'FREE SPIRIT',
    airlineLoyaltyProgram: 'Spirit Airlines FREE SPIRIT',
  },
  {
    airlineName: 'Spring Airlines',
    airlineCode: '9C',
    loyaltyProgram: 'Spring Pass',
    airlineLoyaltyProgram: 'Spring Airlines Spring Pass',
  },
  {
    airlineName: 'SriLankan Airlines',
    airlineCode: 'UL',
    loyaltyProgram: 'FlySmiLes',
    airlineLoyaltyProgram: 'SriLankan Airlines FlySmiLes',
  },
  {
    airlineName: 'StarFlyer',
    airlineCode: '7G',
    loyaltyProgram: 'Starlink',
    airlineLoyaltyProgram: 'StarFlyer Starlink',
  },
  {
    airlineName: 'Starlux Airlines',
    airlineCode: 'JX',
    loyaltyProgram: 'Cosmile',
    airlineLoyaltyProgram: 'Starlux Airlines Cosmile',
  },
  {
    airlineName: 'Sun Country Airlines',
    airlineCode: 'SY',
    loyaltyProgram: 'Sun Country Rewards',
    airlineLoyaltyProgram: 'Sun Country Airlines Sun Country Rewards',
  },
  {
    airlineName: 'SunExpress',
    airlineCode: 'XQ',
    loyaltyProgram: 'SunExpress Bonus',
    airlineLoyaltyProgram: 'SunExpress SunExpress Bonus',
  },
  {
    airlineName: 'Surinam Airways',
    airlineCode: 'PY',
    loyaltyProgram: 'Loyal Wings',
    airlineLoyaltyProgram: 'Surinam Airways Loyal Wings',
  },
  {
    airlineName: 'Swiss International Air Lines',
    airlineCode: 'LX',
    loyaltyProgram: 'Miles & More',
    airlineLoyaltyProgram: 'Swiss International Air Lines Miles & More',
  },
  {
    airlineName: 'Syrianair',
    airlineCode: 'RB',
    loyaltyProgram: 'SyrianAir Frequent Flyer',
    airlineLoyaltyProgram: 'Syrianair SyrianAir Frequent Flyer',
  },
  {
    airlineName: 'TAAG Angola Airlines',
    airlineCode: 'DT',
    loyaltyProgram: 'Umbi Umbi Club',
    airlineLoyaltyProgram: 'TAAG Angola Airlines Umbi Umbi Club',
  },
  {
    airlineName: 'TAP Portugal',
    airlineCode: 'TP',
    loyaltyProgram: 'TAP Miles&Go',
    airlineLoyaltyProgram: 'TAP Portugal TAP Miles&Go',
  },
  {
    airlineName: 'TAROM',
    airlineCode: 'RO',
    loyaltyProgram: 'Flying Blue',
    airlineLoyaltyProgram: 'TAROM Flying Blue',
  },
  {
    airlineName: 'Thai AirAsia',
    airlineCode: 'FD',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'Thai AirAsia Big Rewards',
  },
  {
    airlineName: 'Thai AirAsia X',
    airlineCode: 'XJ',
    loyaltyProgram: 'Big Rewards',
    airlineLoyaltyProgram: 'Thai AirAsia X Big Rewards',
  },
  {
    airlineName: 'Thai Airways',
    airlineCode: 'TG',
    loyaltyProgram: 'Royal Orchid Plus',
    airlineLoyaltyProgram: 'Thai Airways Royal Orchid Plus',
  },
  {
    airlineName: 'Transavia',
    airlineCode: 'HV',
    loyaltyProgram: 'Flying Blue',
    airlineLoyaltyProgram: 'Transavia Flying Blue',
  },
  {
    airlineName: 'TunisAir',
    airlineCode: 'UG',
    loyaltyProgram: 'Fidelys',
    airlineLoyaltyProgram: 'TunisAir Fidelys',
  },
  {
    airlineName: 'Turkish Airlines',
    airlineCode: 'TK',
    loyaltyProgram: 'Miles & Smiles',
    airlineLoyaltyProgram: 'Turkish Airlines Miles & Smiles',
  },
  {
    airlineName: 'Ukraine International Airlines',
    airlineCode: 'PS',
    loyaltyProgram: 'Panorama Club',
    airlineLoyaltyProgram: 'Ukraine International Airlines Panorama Club',
  },
  {
    airlineName: 'United Airlines',
    airlineCode: 'UA',
    loyaltyProgram: 'MileagePlus',
    airlineLoyaltyProgram: 'United Airlines MileagePlus',
  },
  {
    airlineName: 'Ural Airlines',
    airlineCode: 'U6',
    loyaltyProgram: 'Wings',
    airlineLoyaltyProgram: 'Ural Airlines Wings',
  },
  {
    airlineName: 'UTair',
    airlineCode: 'UT',
    loyaltyProgram: 'Status',
    airlineLoyaltyProgram: 'UTair Status',
  },
  {
    airlineName: 'Uzbekistan Airways',
    airlineCode: 'HY',
    loyaltyProgram: 'UzAirPlus',
    airlineLoyaltyProgram: 'Uzbekistan Airways UzAirPlus',
  },
  {
    airlineName: 'Vietjet Air',
    airlineCode: 'VJ',
    loyaltyProgram: 'Vietjet SkyClub',
    airlineLoyaltyProgram: 'Vietjet Air Vietjet SkyClub',
  },
  {
    airlineName: 'Vietnam Airlines',
    airlineCode: 'VN',
    loyaltyProgram: 'Lotusmiles',
    airlineLoyaltyProgram: 'Vietnam Airlines Lotusmiles',
  },
  {
    airlineName: 'Virgin Atlantic',
    airlineCode: 'VS',
    loyaltyProgram: 'Flying Club',
    airlineLoyaltyProgram: 'Virgin Atlantic Flying Club',
  },
  {
    airlineName: 'Virgin Australia',
    airlineCode: 'VA',
    loyaltyProgram: 'Velocity Frequent Flyer',
    airlineLoyaltyProgram: 'Virgin Australia Velocity Frequent Flyer',
  },
  {
    airlineName: 'Vistara',
    airlineCode: 'UK',
    loyaltyProgram: 'Club Vistara',
    airlineLoyaltyProgram: 'Vistara Club Vistara',
  },
  {
    airlineName: 'Vueling',
    airlineCode: 'VY',
    loyaltyProgram: 'Vueling Club',
    airlineLoyaltyProgram: 'Vueling Vueling Club',
  },
  {
    airlineName: 'WestJet',
    airlineCode: 'WS',
    loyaltyProgram: 'WestJet Rewards',
    airlineLoyaltyProgram: 'WestJet WestJet Rewards',
  },
  {
    airlineName: 'Wizz Air',
    airlineCode: 'W6',
    loyaltyProgram: 'Wizz Discount Club',
    airlineLoyaltyProgram: 'Wizz Air Wizz Discount Club',
  },
  {
    airlineName: 'Xiamen Airlines',
    airlineCode: 'MF',
    loyaltyProgram: 'Egret Club',
    airlineLoyaltyProgram: 'Xiamen Airlines Egret Club',
  },
  {
    airlineName: 'Yangon Airways',
    airlineCode: 'YH',
    loyaltyProgram: 'Elite Club',
    airlineLoyaltyProgram: 'Yangon Airways Elite Club',
  },
  {
    airlineName: 'Yemenia',
    airlineCode: 'IY',
    loyaltyProgram: 'Sama Club',
    airlineLoyaltyProgram: 'Yemenia Sama Club',
  },
  {
    airlineName: 'Yeti Airlines',
    airlineCode: 'YT',
    loyaltyProgram: 'SkyClub',
    airlineLoyaltyProgram: 'Yeti Airlines SkyClub',
  },
];

export const airlineLoyaltyMap: Record<string, string> = airLoyaltyArray.reduce(
  (acc, airline) => ({
    ...acc,
    [airline.airlineCode]: airline.airlineLoyaltyProgram || airline.airlineName,
  }),
  {},
);
