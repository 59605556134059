import axios from 'axios';

import { useQuery, UseQueryResult } from 'react-query';
import { Config } from 'obt-common';
import { captureException } from 'src/utils/errorTracking';

type IAppAvailability = {
  isAvailable: boolean;
  time: string;
};

const useAppAvailability = (): UseQueryResult<IAppAvailability> => {
  const url = `${Config.VITE_PUBLIC_URL}/assets/availability.json`;
  return useQuery('app-availability', async () => {
    try {
      const { data } = await axios.get(url);
      if ('isAvailable' in data) {
        return data;
      }
    } catch (err) {
      captureException(err, { source: 'useAppAvailability', extra: { url } });
    }
    return null;
  });
};

export default useAppAvailability;
