/**
 * READ ONLY FILE
 * These are base palette colors provided by UX
 * All colors in spotnana app will be used in combination of below.
 * Do not add or delete entries in this file without UX authorization.
 */

export const lightColorPalette = {
  surface: '#FFFFFF' as const,
  background: '#F7F7F8' as const,
  surfaceMenu: '#FFFFFF' as const,
  surfaceHover: '#EAEAEB' as const,
  surfaceInverse: '#1F1F1F' as const,
  surfaceHoverInverse: '#333333' as const,
  grey10: '#DFE0E2' as const,
  grey20: '#C9CBCF' as const,
  grey30: '#8C8F97' as const,
  grey40: '#767676' as const,
  grey50: '#4E5056' as const,
  grey60: '#18191B' as const,
  grey20Inverse: '#525252' as const,
  grey50Inverse: '#B8B8B8' as const,
  brand: '#CB333B' as const,
  brandHover: '#A0282F' as const,
  brandBorder: '#F2CCCE' as const,
  brandBackground: '#FAEBEB' as const,
  error: '#C73E10' as const,
  errorBorder: '#F2C3A6' as const,
  errorBackground: '#FCEFE9' as const,
  errorInverse: '#FA5B42' as const,
  success: '#18771F' as const,
  successBorder: '#ABE4B5' as const,
  successBackground: '#E4FAE7' as const,
  successInverse: '#53C38E' as const,
  warning: '#A85B00' as const,
  warningBorder: '#F5E4A3' as const,
  warningBackground: '#FFF6E3' as const,
  warningInverse: '#E5AC19' as const,
  link: '#0052CC' as const,
  linkHover: '#223C73' as const,
  linkBorder: '#ACC1EF' as const,
  linkBackground: '#E8EFFC' as const,
  linkInverse: '#93B3F2' as const,
  keyboardFocus: '#0052CC' as const,
  starRating: '#F0B505' as const,
  overlay: 'rgba(24, 25, 27, 0.40)' as const,
} as const;

export const darkColorPalette = {
  surface: '#1F1F1F',
  background: '#0D0D0D',
  surfaceMenu: '#292929',
  surfaceHover: '#333333',
  surfaceInverse: '#FFFFFF',
  surfaceHoverInverse: '#EAEAEB',
  grey10: '#3D3D3D',
  grey20: '#525252',
  grey30: '#616161',
  grey40: '#878787',
  grey50: '#B8B8B8',
  grey60: '#F2F2F2',
  grey20Inverse: '#C9CBCF' as const,
  grey50Inverse: '#4E5056' as const,
  brand: '#CB333B',
  brandHover: '#A0282F',
  brandBorder: '#561A1D',
  brandBackground: '#251819',
  error: '#FA5B42',
  errorBorder: '#552F32',
  errorBackground: '#341E1F',
  errorInverse: '#C73E10',
  success: '#53C38E',
  successBorder: '#17663A',
  successBackground: '#21342D',
  successInverse: '#18771F',
  warning: '#E5AC19',
  warningBorder: '#633F1C',
  warningBackground: '#42381F',
  warningInverse: '#A85B00',
  link: '#93B3F2',
  linkHover: '#97B0F7',
  linkBorder: '#1333A6',
  linkBackground: '#142552',
  linkInverse: '#0052CC',
  keyboardFocus: '#93B3F2',
  starRating: '#F0B505',
  overlay: 'rgba(0, 0, 0, 0.40)' as const,
} as const;

export type TUxColorSpec = typeof lightColorPalette;
export type TUxColorSpecKeys = {
  [K in keyof typeof lightColorPalette]: any;
};
