import type { AxiosInterceptorManager, AxiosRequestConfig } from 'axios';
import axios from 'axios';

type OnFulfilled = Parameters<AxiosInterceptorManager<AxiosRequestConfig>['use']>[0];
type OnRejected = Parameters<AxiosInterceptorManager<AxiosRequestConfig>['use']>[1];

interface ResponseInterceptorParams {
  onFulfilled?: OnFulfilled;
  onRejected?: OnRejected;
  shouldIgnoreError?: boolean;
}

type UnsubscribeFn = () => void;

/**
 * Docs: https://axios-http.com/docs/interceptors
 */
function addRequestInterceptor({
  onFulfilled = (requestConfig) => requestConfig,
  onRejected = (error) => error,
  shouldIgnoreError = true,
}: ResponseInterceptorParams): UnsubscribeFn {
  const id = axios.interceptors.request.use(
    async (requestConfig) => {
      try {
        const newRequestConfig = await onFulfilled(requestConfig);
        return newRequestConfig;
      } catch (err) {
        if (shouldIgnoreError) {
          return requestConfig;
        }

        throw err;
      }
    },
    async (error) => {
      try {
        const newError = await onRejected(error);

        return Promise.reject(newError);
      } catch (err) {
        if (shouldIgnoreError) {
          return Promise.reject(error);
        }

        throw err;
      }
    },
  );

  return () => {
    axios.interceptors.request.eject(id);
  };
}

export default addRequestInterceptor;
