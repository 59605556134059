import { FC } from 'react';
import { Typography } from '@spotnana/blocks/src/Typography';
import { useTranslation } from 'react-i18next';
import { Icon } from '@spotnana/blocks/src/Icon';
import {
  getTripTypeFromSegments,
  GroupIdInfo,
  IWebAirSearchUrlParams,
  TripBookingFlowEnum,
  TripIdInfo,
  useFeatureFlag,
  Fare,
} from 'obt-common';
import { stringifyParams } from 'obt-common/utils/urlUtils';

import { transformSegmentsToSegmentsGroupedByCity } from 'obt-common/utils/Flights/segmentHelpers';
import { getDefaultFilterState } from 'obt-common/utils/Flights/getDefaultFilterState';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATHS } from 'src/routes';
import { browse_more_container, browse_text } from './styles';
import { useItineraryOwnerId } from '../hooks/useItineraryOwnerId';

interface BrowseMoreOptionsProps {
  fare: Fare;
  onClose: VoidFunction;
}

const BrowseMoreOptions: FC<BrowseMoreOptionsProps> = ({ fare, onClose }) => {
  const { t: tt } = useTranslation('WEB');
  const history = useHistory();

  const isDefaultOopFilterEnabled = !!useFeatureFlag('FE_AIR_DEFAULT_OOP_FILTER_ENABLED');
  const isDefaultSeparateTicketFilterEnabled = !!useFeatureFlag('FE_AIR_DEFAULT_SEPARATE_TICKET_FILTER_ENABLED');
  const itineraryOwnerId = useItineraryOwnerId();

  const onBrowseMoreOptions = () => {
    const { segments, passengers, searchId, tripBookingFlow } = fare;

    const tripIdInfo: TripIdInfo | GroupIdInfo =
      tripBookingFlow === TripBookingFlowEnum.GROUP
        ? {
            groupId: '',
            tripBookingFlow: TripBookingFlowEnum.GROUP,
          }
        : {
            tripId: '',
            tripBookingFlow: TripBookingFlowEnum.REGULAR,
          };

    const tripType = getTripTypeFromSegments(segments);

    const segmentsGroupedByCity = transformSegmentsToSegmentsGroupedByCity(segments, tripType);

    const airSearchParams: IWebAirSearchUrlParams = {
      lastLegRateOptionId: '',
      tripIdInfo,
      segments: segmentsGroupedByCity,
      registrarId: itineraryOwnerId,
      sortOptions: new Array(segmentsGroupedByCity.length).fill([], 0),
      passengers,
      searchId,
      currLegNumber: 0,
      pageNumber: 1,
      pageSize: 50,
      filter: getDefaultFilterState({
        length: segmentsGroupedByCity.length,
        isDefaultOopFilterEnabled,
        isDefaultSeparateTicketFilterEnabled,
      }),
    };
    onClose();
    const airSearchQuery = stringifyParams(airSearchParams);
    history.push({
      pathname: ROUTE_PATHS.FLIGHTS_RESULTS,
      search: airSearchQuery,
    });
  };

  return (
    <button
      type="button"
      css={browse_more_container}
      onClick={onBrowseMoreOptions}
      aria-label={tt('Browse more options')}
    >
      <div css={browse_text}>
        <Icon name="AddPlus" />
        <Typography variant="body2" color="link" kind="medium">
          {tt('Browse more options')}
        </Typography>
      </div>
      <Typography variant="body3" color="secondary">
        {tt('Add more flight options for same trip.')}
      </Typography>
    </button>
  );
};

export default BrowseMoreOptions;
