var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
const InputGroupComp = styled.div({
    display: 'flex',
    width: '100%',
    '>div, >div .select__control,>button': {
        borderRadius: '0',
    },
    '>div,>button': {
        flex: 1,
    },
    // TODO (@Bhavan/@Himesh): refactor this bad css
    '>div:first-child,>div:first-child .select__control': {
        borderRadius: '8px 0 0 8px',
    },
    '>div:last-child,>div:last-child .select__control': {
        borderRadius: '0 8px 8px 0',
    },
    '>div:only-child,>div:only-child .select__control': {
        borderRadius: '8px',
    },
});
/**
 * Any combinations of Components under InputGroup to render in sequence<br/>
 * Inputs can be provided with maxWidth and other custom styling, it has <b>flex: 1</b> by default
 */
const InputGroup = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (React.createElement(InputGroupComp, Object.assign({}, rest), children));
};
/**
 * @deprecated
 */
export default InputGroup;
