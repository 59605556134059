import Grid from '@spotnana/pixel-react/dist/Grid';
import * as Icons from '@spotnana/pixel-react/dist/Icons';
import { IconButton } from '@spotnana/pixel-react/dist/Button';
import CloseIcon from '@spotnana/pixel-react/dist/Icons/Close';
import Snackbar from '@spotnana/pixel-react/dist/Snackbar';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import { useError, useHasUserAccess, userRolesByFeature } from 'obt-common';
import { Container, ErrorText } from './styles';
import useGetErrorMessageByCode from '../../../hooks/useGetErrorMessageByCode';

interface IProps {
  showAPIErrorCodes: boolean;
  apiErrorMappingFeatureEnabled?: boolean;
  apiErrorMappingV2FeatureEnabled?: boolean;
}

const GlobalError = ({ showAPIErrorCodes }: IProps): JSX.Element => {
  const errorInstance = useError();
  const getErrorMessage = useGetErrorMessageByCode();
  const errorMessage = getErrorMessage({ errorCode: errorInstance?.error?.name });
  const isCurrentUserRoleAgentOrAbove = useHasUserAccess(userRolesByFeature.adminPages);

  if (!errorInstance || !errorInstance.error) {
    return <></>;
  }
  const { error, resetError } = errorInstance;

  const clearAppError = (): void => {
    resetError();
  };

  return (
    <Snackbar
      open
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={clearAppError}
      autoHideDuration={null}
    >
      <Container>
        <Grid.Row middle="xs">
          <Grid.Col>
            <Icons.Warning width={32} height={32} primaryColor={spotnanaTheme.colors.error} />
          </Grid.Col>
          <Grid.Col xs>
            <ErrorText data-testid="handled-error-global">
              {errorMessage}
              {isCurrentUserRoleAgentOrAbove && showAPIErrorCodes && ` (${error?.name})`}
            </ErrorText>
          </Grid.Col>
          <Grid.Col>
            <IconButton data-testid="close-icon" onClick={clearAppError}>
              {() => <CloseIcon />}
            </IconButton>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </Snackbar>
  );
};

export default GlobalError;
