import { PropsWithChildren, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { parseParams } from 'obt-common/utils/urlUtils';
import pick from 'lodash/pick';
import EmbedContext from './EmbedContext';
import { EmbedEvents, EmbedParams, embedParamsKeys } from './types';
import { addBreadcrumb } from '../../utils/errorTracking';

interface EmbedProviderProps {
  isEmbed?: boolean;
  initialParams?: EmbedParams;
}

const EmbedProvider = ({
  isEmbed = false,
  initialParams,
  children,
}: PropsWithChildren<EmbedProviderProps>): JSX.Element => {
  const { search } = useLocation();
  const urlParams = useMemo(() => parseParams(search), [search]);
  const [embedParams, setEmbedParams] = useState<EmbedParams>(initialParams || {});

  const refVal = { isEmbed, initialParams };
  const breadcrumbRef = useRef(refVal);
  breadcrumbRef.current = refVal;

  const reportEvent = useCallback((event: EmbedEvents): void => {
    const message = { from: 'spotnana-embed', ...event };
    try {
      window.parent.postMessage(message, '*');

      addBreadcrumb({
        data: { event: message, ...breadcrumbRef.current },
        message: 'Post message to parent window',
        category: 'embed',
      });
    } catch (error) {
      console.error("Couldn't report the event:", error);

      addBreadcrumb({
        data: { event: message, ...breadcrumbRef.current },
        message: "Can't Post message to parent window",
        category: 'embed',
        level: 'error',
      });
    }
  }, []);

  useEffect(() => {
    setEmbedParams((prevEmbedParams) => {
      const updatedKeys = embedParamsKeys.filter(
        (key) =>
          key in urlParams &&
          // Don't allow to update segments (will be use initial segments only) see: ST-34868
          key !== 'segments' &&
          !(key in prevEmbedParams),
      );

      if (!updatedKeys.length) return prevEmbedParams;

      const updatedParams = pick(urlParams, updatedKeys);

      const newEmbedParams = { ...prevEmbedParams, ...updatedParams };

      addBreadcrumb({
        data: { newEmbedParams, ...breadcrumbRef.current },
        message: 'Embed params updated',
        category: 'embed',
      });

      return newEmbedParams;
    });
  }, [urlParams]);

  const embedStore = useMemo(
    () => ({
      isEmbed,
      embedParams,
      reportEvent,
    }),
    [isEmbed, embedParams, reportEvent],
  );

  return <EmbedContext.Provider value={embedStore}>{children}</EmbedContext.Provider>;
};

export default EmbedProvider;
