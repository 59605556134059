/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of the task.
 * @export
 * @enum {string}
 */

export const Status = {
  Active: 'ACTIVE',
  Completed: 'COMPLETED',
  OnHold: 'ON_HOLD',
  Scheduled: 'SCHEDULED',
  Open: 'OPEN',
  Unknown: 'UNKNOWN',
} as const;

export type Status = (typeof Status)[keyof typeof Status];
