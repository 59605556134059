/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Money } from './money';

/**
 * Extra charges for the car. In US if rider is less than 21 years old there is some extra charge
 * @export
 * @interface CarExta
 */
export interface CarExta {
  /**
   *
   * @type {string}
   * @memberof CarExta
   */
  carExtraType?: CarExtaCarExtraTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CarExta
   */
  carExtraRateUnit?: CarExtaCarExtraRateUnitEnum;
  /**
   *
   * @type {Money}
   * @memberof CarExta
   */
  amount: Money;
}

export const CarExtaCarExtraTypeEnum = {
  UnknownType: 'UNKNOWN_TYPE',
  RenterAge: 'RENTER_AGE',
  AdditionalDriver: 'ADDITIONAL-DRIVER',
  BabyStroller: 'BABY-STROLLER',
  ChildSeatBooster: 'CHILD-SEAT-BOOSTER',
  BicycleRack: 'BICYCLE-RACK',
  CargoBarrierBack: 'CARGO-BARRIER-BACK',
  CargoBarrierFront: 'CARGO-BARRIER-FRONT',
  BoosterSeatUpToAge12: 'BOOSTER-SEAT-UP-TO-AGE-12',
  CollisionDamageWaiver: 'COLLISION-DAMAGE-WAIVER',
  ChildSeatUpToAge3: 'CHILD-SEAT-UP-TO-AGE-3',
  ChildSeatInfant: 'CHILD-SEAT-INFANT',
  SatelliteRadio: 'SATELLITE-RADIO',
  ChildSeatToddler: 'CHILD-SEAT-TODDLER',
  RearSeatDvdPlayer: 'REAR-SEAT-DVD-PLAYER',
  FlagHolder: 'FLAG-HOLDER',
  HandControlLeft: 'HAND-CONTROL-LEFT',
  HandControlRight: 'HAND-CONTROL-RIGHT',
  MotorcycleHelmet: 'MOTORCYCLE-HELMET',
  SecurityDevices: 'SECURITY-DEVICES',
  JerryCan: 'JERRY-CAN',
  LossDamageWaiver: 'LOSS-DAMAGE-WAIVER',
  LuggageRoofCase: 'LUGGAGE-ROOF-CASE',
  LuggageTrailer: 'LUGGAGE-TRAILER',
  LuggageRack: 'LUGGAGE-RACK',
  NavigationalSystem: 'NAVIGATIONAL-SYSTEM',
  NavigationalSystemBuiltIn: 'NAVIGATIONAL-SYSTEM-BUILT-IN',
  PersonalAccidentInsurance: 'PERSONAL-ACCIDENT-INSURANCE',
  MobilePhone: 'MOBILE-PHONE',
  RoadCongestionPaymentScheme: 'ROAD-CONGESTION-PAYMENT-SCHEME',
  SeatBeltExtension: 'SEAT-BELT-EXTENSION',
  SnowBoardRacks: 'SNOW-BOARD-RACKS',
  SkiBox: 'SKI-BOX',
  SkiRackOnly: 'SKI-RACK-ONLY',
  SkiRackOrBox: 'SKI-RACK-OR-BOX',
  SkiEquippedVehicle: 'SKI-EQUIPPED-VEHICLE',
  SnowChains: 'SNOW-CHAINS',
  SnowPacks: 'SNOW-PACKS',
  SnowTires: 'SNOW-TIRES',
  SpinnerKnob: 'SPINNER-KNOB',
  SurfRack: 'SURF-RACK',
  ScooterTopCase: 'SCOOTER-TOP-CASE',
  SnowWinterTires: 'SNOW-WINTER-TIRES',
  TollPaymentTagPass: 'TOLL-PAYMENT-TAG-PASS',
  SpareTire: 'SPARE-TIRE',
  WheelChairAccessRamp: 'WHEEL-CHAIR-ACCESS-RAMP',
  WheelChair: 'WHEEL-CHAIR',
  WifiAccess: 'WIFI-ACCESS',
} as const;

export type CarExtaCarExtraTypeEnum = (typeof CarExtaCarExtraTypeEnum)[keyof typeof CarExtaCarExtraTypeEnum];
export const CarExtaCarExtraRateUnitEnum = {
  UnknownRateUnit: 'UNKNOWN_RATE_UNIT',
  Daily: 'DAILY',
  Weekend: 'WEEKEND',
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
  Bundled: 'BUNDLED',
  Total: 'TOTAL',
} as const;

export type CarExtaCarExtraRateUnitEnum =
  (typeof CarExtaCarExtraRateUnitEnum)[keyof typeof CarExtaCarExtraRateUnitEnum];
