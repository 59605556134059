import { useTranslation } from 'react-i18next';
import { WeightUnit } from '../../types/api/v1/obt/common/weight';

export const useWeightTranslator = () => {
  const { t: tt } = useTranslation('COMMON');

  return (gender: WeightUnit): string => {
    switch (gender) {
      case WeightUnit.KG:
        return tt('kg');
      case WeightUnit.LBS:
        return tt('lbs');
      default:
        return '';
    }
  };
};
