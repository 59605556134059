import { Avatar } from '@spotnana/blocks/src/Avatar';
import { Button } from '@spotnana/blocks/src/Button';
import { Typography } from '@spotnana/blocks/src/Typography';
import { localizeDateTime } from 'obt-common';
import type { UserBasicInfo } from 'obt-common/types/api/v1/obt/common/user_basic_info';
import { useTranslation } from 'react-i18next';
import { announcement } from './index.styles';

// Define the AnnouncementCardProps type representing the properties required for the AnnouncementCard component
type AnnouncementCardProps = {
  title?: string;
  description: string;
  author: UserBasicInfo;
  updatedAt: {
    iso8601: string;
  };
  documentLink?: string;
  layout?: 'vertical' | 'horizontal';
};

// AnnouncementCard component displays information about an announcement
const AnnouncementCard = ({
  title,
  description,
  author,
  updatedAt,
  documentLink,
  layout = 'horizontal',
}: AnnouncementCardProps): JSX.Element => {
  // Translation hook to get translation functions
  const { t: tt } = useTranslation('WEB');

  // Function to handle document download
  const handleDownload = () => {
    window.open(documentLink, '_blank');
  };

  return (
    <div css={announcement} className={layout}>
      {/* Card Header */}
      <div className="card_header">
        {/* Author Details */}
        <div className="author_details">
          {/* Author Image */}
          {author.profilePicture?.url && (
            <Avatar src={author.profilePicture?.url} alt={author.name?.given || ''} size="large" />
          )}
          {!author.profilePicture?.url && (
            <Avatar text={author.name?.given || ''} alt={author.name?.given || ''} size="large" />
          )}
          {/* Author Name and Position */}
          <div>
            <Typography variant="body1" kind="semibold">
              {author.name?.given}
            </Typography>
            {author.title && (
              <Typography variant="body2" color="tertiary">
                {author.title}
              </Typography>
            )}
          </div>
        </div>
        {/* Last Modified Time */}
        <Typography variant="body2" color="tertiary">
          {localizeDateTime(`${updatedAt.iso8601}Z`, { dateStyle: 'medium', timeStyle: 'short' })}
        </Typography>
      </div>
      {/* Card Body */}
      <div className="card_body">
        {/* Announcement Title */}
        <Typography variant="body1" kind="semibold">
          {title}
        </Typography>
        {/* Announcement Description */}
        <Typography variant="body2" className="description">
          {description}
        </Typography>
        {/* Display Document Link if available */}
        {documentLink && (
          <div>
            {/* Button with Link Icon */}
            <Button variant="outline" size="small" endIcon="NewTab" onClick={handleDownload}>
              {tt('Document link')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnnouncementCard;
