// Generated using yarn generate command
import { css } from '@emotion/react';
import { themed } from '../utils';

export const avatar_button = themed(
  ({ palette }) => css`
    /* insert styles here */
    &.MuiButtonBase-root {
      background-color: transparent;
      min-width: auto;
      height: auto;
      padding: 0px !important;
      border-radius: 100%;
    }
    .BlocksAvatarButton-adornment {
      position: absolute;
      height: 50%;
      width: 50%;
      right: -15%;
      top: -20%;
      border-radius: 100%;
      border: 1px solid ${palette.border.light};

      outline: 2px solid ${palette.surface.base};
      .MuiAvatar-root {
        height: 100% !important;
        width: 100% !important;
      }
    }
    :focus-visible {
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: 2px;
    }
  `,
);
