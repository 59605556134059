import { StorageKeys } from '../../types/storage';
import { saveStorageValue } from '../../hooks/useStorage';
import { emitApplicationIdChange } from './eventemitter';

async function setApplicationId(applicationId: string): Promise<void> {
  if (!applicationId) {
    // Prevent setting empty applicationId
    return;
  }

  await saveStorageValue(StorageKeys.APPLICATION_ID, applicationId).finally(() => {
    emitApplicationIdChange(applicationId);
  });
}

export default setApplicationId;
