import { useMemo } from 'react';

import type { THarnessFlags } from './hooks';
import { useFeatureFlag } from './hooks';
import { isValidJSON } from '../utils/common';

export default function useJSONFeatureFlag<Key extends keyof THarnessFlags>(flag: Key): THarnessFlags[Key] | undefined {
  const flagValue = useFeatureFlag(flag);

  const jsonFFValue = useMemo(() => {
    return typeof flagValue === 'string' && isValidJSON(flagValue)
      ? (JSON.parse(flagValue) as THarnessFlags[Key])
      : undefined;
  }, [flagValue]);

  return jsonFFValue;
}
