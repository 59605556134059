declare global {
  interface Window {
    Stripe: any;
  }
}

export const getStripe = ({ stripePK, stripeAccount }: { stripePK: string; stripeAccount?: string }) => {
  if (stripeAccount) {
    return window.Stripe(stripePK, {
      stripeAccount,
    });
  }
  if (stripePK) {
    return window.Stripe(stripePK);
  }

  throw new Error('Stripe Publishable Key is not provided');
};
