import { useBlocksTheme } from '@spotnana/blocks/src/utils/styling/useBlocksTheme';
import { getThemeShadows } from '@spotnana/pixel-react/dist/utils/themes/shadows';
import mapValues from 'lodash/mapValues';
// eslint-disable-next-line no-restricted-imports
import { rgba } from 'polished';

export const useMergePixelAndBlocksTheme = (primitiveOverrides = {}) => {
  const { palette, fontFamily } = useBlocksTheme();

  const primitiveColors = {
    primary: palette.bg.brand,
    primaryDark: palette.bg.brand,
    primaryLight: palette.bg.brand,
    primaryPale: palette.bg.brandSubtle,
    onPrimary: palette.surface.base,

    secondary: '',
    secondaryDark: '',
    secondaryLight: '',
    secondaryPale: '',
    onSecondary: palette.surface.base,

    error: palette.bg.error,
    errorDark: '',
    errorLight: '',
    errorPale: palette.bg.warningSubtle,
    onError: palette.surface.base,
  };

  const primitives = { ...primitiveColors, ...primitiveOverrides };

  const colors = {
    brand: {
      accent: palette.bg.warning,
    },
    ui: {
      tertiary: palette.surface.background,
      quaternary: palette.surface.base,
      disabled: palette.bg.buttonDisabled,
      error: palette.bg.error,
      success: palette.bg.success,
    },
    bg: {
      /**
       * TODO: replace following tokens
       * uxSpec.grey50
       * uxSpec.grey40
       * uxSpec.grey30
       * uxSpec.grey20
       *  */
      secondary: palette.surface.background,
      alabaster: palette.surface.background,
      blueGray: palette.surface.background,
      regular: palette.surface.base,
      divider: palette.bg.buttonDisabled,
      dividerAlpha50: palette.surface.background,
      opaqueWhite6: palette.surface.base,
      darkGray: palette.uxSpec.grey50,
      transparent: 'transparent',
      paleGray: palette.surface.background,
      greyBlue: palette.uxSpec.grey50,
      lightBlueGrey: palette.bg.buttonDisabled,
      lightBlueGrey2: palette.uxSpec.grey40,
      lightBlueGrey3: palette.uxSpec.grey30,
      grey20: palette.uxSpec.grey20,
      grey10: palette.bg.buttonDisabled,
      get lightBlueGrey3Alpha27(): string {
        return rgba(colors.bg.lightBlueGrey3, 0.27);
      },
      lightGreen: palette.bg.successSubtle,
      lightGreen2: palette.bg.successSubtle,
      white: palette.surface.base,
      dark: palette.bg.badgeActive,
      infoBackground: palette.bg.infoSubtle,
      floralWhite: palette.surface.background,
      lightYellow: palette.bg.warningSubtle,
      blueGrey2: palette.uxSpec.grey50,
      aquaHaze: palette.surface.background,
      navyBlue: palette.bg.info,
      opaqueLightGray: palette.surface.background,
      get lightGrey2(): string {
        return rgba(colors.bg.black, 0.1);
      },
      lightGrey3: palette.uxSpec.grey20,
      lightGrey4: palette.uxSpec.grey50,
      brightGrey: palette.uxSpec.grey40,
      kashmirBlue: palette.bg.info,
      black: palette.bg.badgeActive,
      get blackAlpha03(): string {
        return rgba(colors.bg.black, 0.03);
      },
      get blackAlpha08(): string {
        return rgba(colors.bg.black, 0.08);
      },
      get blackAlpha20(): string {
        return rgba(colors.bg.black, 0.2);
      },
      get blackAlpha30(): string {
        return rgba(colors.bg.black, 0.3);
      },
      get blackAlpha50(): string {
        return rgba(colors.bg.black, 0.5);
      },
      blue: palette.bg.info,
      neutral_N50: palette.surface.background,
      neutral_N100: palette.bg.buttonDisabled,
      warningTan: palette.bg.warningSubtle,
      lightYellow2: palette.bg.warningSubtle,
      lightYellowInfo: palette.icon.starRating,
      surfaceMenu: palette.surface.menu,
    },
    border: {
      /**
       * TODO: replace following tokens
       * uxSpec.grey60
       * */
      regular: palette.border.light,
      secondary: palette.surface.background,
      disabled: palette.border.light,
      cardActive: palette.border.hover,
      transparent: 'transparent',
      outline: palette.border.light,
      regularHover: palette.border.medium,
      dark: palette.uxSpec.grey60,
      grey: palette.border.medium,
      greyBlue: palette.border.active,
      blueGrey: palette.border.active,
      lightBlueGray: palette.border.medium,
      lightGray: palette.surface.background,
      lightGray2: palette.border.light,
      white: palette.surface.base,
      darkSeaGreen: palette.border.success,
      lightGrey: palette.border.light,
      darkGrey: palette.border.hover,
      silver: palette.border.light,
      blockedGrey: palette.border.medium,
      lightGrey2: palette.uxSpec.grey60,
      eucalyptus: palette.border.success,
      neutral_N100: palette.border.light,
      warningTan: palette.border.warningSubtle,
      infoBorder: palette.border.infoSubtle,
      lightYellow: palette.border.warningSubtle,
      grey10: palette.border.light,
    },
    text: {
      /**
       * TODO: replace following tokens
       * uxSpec.grey30
       * uxSpec.grey20
       * uxSpec.grey10
       * */
      secondary: palette.text.secondary,
      semiBold: palette.text.primary,
      body: palette.text.secondary,
      bodyLight: palette.text.secondary,
      disabled: palette.uxSpec.grey30,
      white: palette.surface.base,
      lightGray: palette.surface.background,
      gray: palette.uxSpec.grey20,
      grey30: palette.uxSpec.grey30,
      grey40: palette.text.tertiary,
      darkGray: palette.text.primary,
      lightRed: palette.text.error,
      darkBlue: palette.text.linkHover,
      label: palette.text.secondary,
      black: palette.text.primary,
      darkSeaGreen: palette.text.success,
      get darkSeaGreenAlpha10(): string {
        return rgba(colors.text.darkSeaGreen, 0.1);
      },
      blueGrey: palette.text.secondary,
      lightYellow: palette.text.warning,
      darkGreen: palette.text.success,
      navyBlue: palette.text.link,
      gold: palette.icon.starRating,
      blockedGrey: palette.text.secondary,
      prussianBlue: palette.text.linkHover,
      kashmirBlue: palette.text.link,
      lynch: palette.uxSpec.grey20,
      chineseSilver: palette.uxSpec.grey20,
      dark: palette.text.primary,
      lightGrey: palette.text.secondary,
      lightBlueGray: palette.uxSpec.grey10,
      deepLightGray: palette.uxSpec.grey30,
      seaGreen: palette.text.success,
      midGray: palette.text.tertiary,
      eucalyptus: palette.text.success,
      darkGrey: palette.text.tertiary,
      blackcurrant: palette.text.secondary,
      linkBlue: palette.text.link,
      success: palette.text.success,
      ceruleanBlue: palette.text.link,
      neutralN300: palette.uxSpec.grey30,
      newErrorRed: palette.text.error,
      newGray50: palette.text.secondary,
      blueLink: palette.text.link,
      green50: palette.text.success,
    },
    get highlights() {
      return {
        primaryHighlight: this.primary,
        primaryExtraHighlight: this.primary,
        bgHighlight: palette.surface.base,
      };
    },

    // App Background
    background: palette.surface.background,

    // Primary colors
    primary: primitives.primary,
    primaryActive: primitives.primaryDark,
    primaryHover: primitives.primaryLight,
    primaryDisabled: primitives.primaryPale, // TODO: this is used for errors in some places
    primaryEmphasis: primitives.primaryDark, // TODO: need a better name for this, used for icons, dividers, logo backgrounds, some texts
    onPrimary: primitives.onPrimary,

    // Secondary Colors
    secondary: primitives.secondary,
    secondaryActive: primitives.secondaryDark,
    secondaryHover: primitives.secondaryLight,
    secondaryDisabled: primitives.secondaryPale,
    onSecondary: primitives.onSecondary,

    // Surface Colors
    surface: palette.surface.base,
    onSurface: palette.uxSpec.grey60,
    surfaceBorder: palette.border.light,
    surfaceDividerLight: palette.border.light,
    surfaceDividerStrong: palette.border.light,
    surfaceShadow: 'rgba(202, 203, 221, 0.3)',

    // row colors (used in flight search results)
    rowBorder: palette.border.light,

    // input colors
    inputFocus: primitives.primaryLight,
    inputHighlight: palette.surface.background,
    inputBorder: palette.border.light,
    inputHighlightBorder: palette.uxSpec.grey60,
    inputShadow: 'rgba(202, 203, 221, 0.3)',

    // misc colors
    shimmerFrom: palette.surface.background,
    shimmerTo: palette.uxSpec.grey10,
    tooltipShadow: 'rgba(29, 29, 31, 0.15)',
    label: palette.uxSpec.grey50,
    mapDropShadow: palette.uxSpec.grey50,
    ceruleanBlue: palette.uxSpec.link,

    // Status colors
    error: primitives.error,
    errorBorder: primitives.errorPale,
    errorBackground: primitives.errorPale,
    onError: primitives.onError,

    success: '',
    onSuccess: '',

    warning: '',
    onWarning: '',

    info: palette.bg.infoSubtle,
    onInfo: '',

    // derivatives
    get primaryAlpha10(): string {
      return this.primary;
    },
    get surfaceAlpha30(): string {
      return this.surface;
    },
    get surfaceShadowAlpha15(): string {
      return this.surfaceShadow;
    },
    get surfaceShadowAlpha25(): string {
      return this.surfaceShadow;
    },
    get tooltipShadowAlpha08(): string {
      return this.tooltipShadow;
    },

    // additional colors (temporary) map these to existing
    get primary2Alpha20(): string {
      return this.primaryEmphasis;
    },

    surface2: palette.surface.background,
    surfaceDivider2: palette.uxSpec.grey20,
    surfaceDivider3: palette.uxSpec.grey10,
    onSurface2: palette.uxSpec.grey50,
    onSurface3: palette.uxSpec.grey60,
    surfaceShadow2: 'rgba(215, 216, 237, 0.15)',

    seatRed: palette.bg.brand,
    seatRedDark: palette.bg.brandHover,

    // NEUTRALS
    neutralN100: palette.uxSpec.grey10,
    neutralN200: palette.uxSpec.grey20,

    warningIconRed: palette.icon.error,
  };

  const lightThemeShadows = getThemeShadows();
  const darkThemeShadows = mapValues(lightThemeShadows, () => 'none');
  const shadows = palette.mode === 'dark' ? darkThemeShadows : lightThemeShadows;

  return { colors, fontFamily, shadows };
};
