import type { PaxInfo } from '../../../types/api/v1/obt/air/air_search_response';
import { MoneyUtil } from '../../Money';

export function getPaxBaseFareForPaxInfo(paxNumber: number, paxInfo: PaxInfo[]): MoneyUtil {
  const base = paxInfo[paxNumber].farePerPax?.base;
  return MoneyUtil.parse(base);
}

export function getPaxTaxFareForPaxInfo(paxNumber: number, paxInfo: PaxInfo[]): MoneyUtil {
  const tax = paxInfo[paxNumber].farePerPax?.tax;
  return MoneyUtil.parse(tax);
}
