import replace from 'lodash/replace';
import type { Length } from '../types/api/v1/common/length';
import { LengthUnitEnum } from '../types/api/v1/common/length';
import { kmToMiles, milesToKm } from './convertDistance';
import logger from './logger';

const LOCALES_PREFERRING_MILE = new Set<string>(
  // We are adding locales in lower case letter and '_' consider as a '-'
  /* This jira link https://spotnana.atlassian.net/browse/ST-8445 explains how imperial units are generating for the locale.
     Here is the list of locales that use the US system; also added UK locale as while its mostly a SI system except for distance. */
  [
    'chr',
    'chr-us',
    'en',
    'en-lr',
    'en-us',
    'en-us-posix',
    'es-us',
    'ff-adlm-lr',
    'ff-latn-lr',
    'haw',
    'haw-us',
    'lkt',
    'lkt-us',
    'vai',
    'vai-latn',
    'vai-latn-lr',
    'vai-vaii',
    'vai-vaii-lr',

    // While UK uses (mostly) SI units, for distance they still use miles.
    'en-gb',
  ],
);

// This block validates whether an inserted key into a "LOCALES_PREFERRING_MILE" is valid or not.
(function (): void {
  LOCALES_PREFERRING_MILE.forEach((key) => {
    for (let i = 0; i < key.length; i += 1) {
      if (key[i] === '_' || (key[i] !== '-' && key[i] === key[i].toUpperCase())) {
        console.warn(
          `The locale ${key} is invalid. Locale names should contain only lower case characters and hyphens.`,
        );
        return;
      }
    }
  });
})();

// The function accepts non normalized locale names (names with upper case characters and hyphen separators) and automatically normalizes them.
export const getPreferredLengthUnitForLocale = (locale: string): LengthUnitEnum => {
  const key: string = replace(locale.toLowerCase(), '_', '-');
  return LOCALES_PREFERRING_MILE.has(key) ? LengthUnitEnum.MILE : LengthUnitEnum.KM;
};

const formatDistance = (distance: Length, locale: string): string => {
  const lengthUnitForLocale = getPreferredLengthUnitForLocale(locale);

  if (distance.unit === LengthUnitEnum.UNKNOWN_UNIT || distance.unit === LengthUnitEnum.UNRECOGNIZED) {
    logger.error(
      new Error('INVALID_LENGTH_UNIT_ERROR: formatDistance', {
        cause: { message: 'invalid length unit' },
      }),
    );
  }

  let convertedDistance = distance.length;
  if (lengthUnitForLocale === LengthUnitEnum.KM && distance.unit === LengthUnitEnum.MILE) {
    convertedDistance = milesToKm(convertedDistance);
  } else if (lengthUnitForLocale === LengthUnitEnum.MILE && distance.unit === LengthUnitEnum.KM) {
    convertedDistance = kmToMiles(convertedDistance);
  }

  const displayedLength = new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(convertedDistance);
  const displayedUnit = lengthUnitForLocale === LengthUnitEnum.MILE ? 'mi' : 'km';

  return `${displayedLength}${displayedUnit}`;
};

export default formatDistance;
