/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
// Generated using yarn generate command
import { useMemo, useCallback, useDeferredValue, useRef } from 'react';
import {
  SelectStoreProps,
  SelectStore,
  ComboboxStore,
  useDisclosureStore,
  useComboboxStore,
  SelectStoreState,
  useSelectStore,
  SelectItemProps,
} from '@ariakit/react';

import { useDebounceFn } from 'ahooks';
import { noop, pipe } from '../utils/fp';
import { INPUT_CHANGE_DEBOUNCE_MS } from './constants';
import type { TSelectOption, TSelectOptionList } from './SelectRenderer';
import { OptionWithStringifiedValue, addTypeMetadata, indexByOptionValue, restoreOptionType } from './storeUtils';
import { isSearchControlled } from './componentUtils';

type TSelectOptionBaseCompat = TSelectOption;

type TAriakitSelectStoreProps<T extends string | string[]> = SelectStoreProps<T>;

type CustomSearchLogic<TItem extends TSelectOptionBaseCompat> = (
  query: string,
  /**
   * An list item that can be PARENT, CHILD, STANDALONE, TITLE
   */
  option: TSelectOptionList<TItem>[0],
  groupLabel?: string,
) => boolean;

export type APIControlledSearch = {
  type: 'CONTROLLED';
  /**
   *
   * does not propagate empty string by default, because for Select with search
   * most common use-case is to not affect the options until user types another query
   *
   * this is a conscious API choice to avoid if-else check in consumer code for most common use-case
   * of using Select with API-driven search
   *
   */
  onInputChange: (value: string) => void;
  /**
   * Pass this option as true in case you want the dropdown to be empty on empty query
   * @default false
   */
  shouldPropagateEmptyQuery?: boolean;
};

export type TSearchConfig<TItem extends TSelectOptionBaseCompat> =
  | boolean
  | {
      // temporarily optional to not break backwords compat
      type?: 'CUSTOM';
      matcher: CustomSearchLogic<OptionWithStringifiedValue<TItem>>;
    }
  | APIControlledSearch;

type TSelectStoreArgs<
  ComponentType extends 'select' | 'combobox',
  Variant extends 'single' | 'multiple',
  TItem extends TSelectOptionBaseCompat = TSelectOptionBaseCompat,
  Search extends TSearchConfig<TItem> = TSearchConfig<TItem>,
> = {
  controlledValue?: Variant extends 'single' ? TItem | undefined : TItem[] | undefined;
  componentType: ComponentType;
  variant: Variant;
  options: TSelectOptionList<TItem>;
  onChange: (currentValue: Variant extends 'single' ? TItem : TItem[]) => void;
  onClear?: () => void;
  customSetValueOnClick?: (
    event: React.MouseEvent<HTMLElement>,
    clickedItem: TItem,
    userDidCheck: boolean,
    optionsIndexedByValue: Record<string, TItem>,
  ) => boolean;
} & TAriakitSelectStoreProps<Variant extends 'single' ? string : string[]> & {
    search?: Search;
    searchPlaceholder?: string;
    /**
     * @deprecated
     */
    searchType?: 'LOCAL' | 'API_DRIVEN' | undefined;
  };

type TComboboxStore = ComboboxStore & { placeholder?: string };

export function useSelectStore_internal<
  ComponentType extends 'select' | 'combobox',
  Variant extends 'single' | 'multiple',
  TItem extends TSelectOptionBaseCompat = TSelectOptionBaseCompat,
  Search extends TSearchConfig<TItem> = TSearchConfig<TItem>,
>({
  setValue,
  onChange,
  onClear,
  variant,
  options,
  search,
  searchPlaceholder,
  searchType,
  customSetValueOnClick,
  componentType,
  controlledValue,
  ...rest
}: TSelectStoreArgs<ComponentType, Variant, TItem, Search>): {
  componentType: ComponentType;
  variant: Variant;
  selectStore: Variant extends 'single'
    ? SelectStore<string> & {
        getSelection: (value: string) => TItem | null;
      }
    : SelectStore<string[]> & {
        getSelection: (value: string[]) => [TItem[], Set<string>];
      };
  searchString?: string;
  options: TSelectOptionList<OptionWithStringifiedValue<TItem>>;
  comboboxStore: ComponentType extends 'select'
    ? Search extends false | undefined
      ? undefined
      : TComboboxStore
    : TComboboxStore;
  setValueOnClick?: SelectItemProps['setValueOnClick'];
} {
  const disclosure = useDisclosureStore();

  const { run: debouncedOnInputChange } = useDebounceFn(makeControlledSearchHandler(search) ?? noop, {
    wait: INPUT_CHANGE_DEBOUNCE_MS,
  });

  const comboboxStore = useComboboxStore({ resetValueOnHide: true, disclosure, setValue: debouncedOnInputChange });
  const searchFieldValue = comboboxStore.useState('value');
  const deferredSearchFieldValue = useDeferredValue(searchFieldValue);

  /**
   * we have optionsIndexedByValue, to get original option object from string value.
   * this is used to give correct data in onChange, and other places like to render selected items in the Select box
   *
   * In case of API-driven search, the options disappear on next search. optionsIndexedByValue doesn't have
   * all the items that the user's current selection might have.
   *
   * So, we need to maintain a separate map of selected values, which is updated on every controlledValue change.
   */
  // TODO: when there is no controlled value, this should work based on internal selection state
  const selectedValueMap = useRef(getValueMap<TItem>(controlledValue));
  selectedValueMap.current = getValueMap<TItem>(controlledValue);

  const [optionsWithTypeInfo, optionsIndexedByValue] = useMemo(
    () =>
      pipe(
        options,
        addTypeMetadata,
        (processedOptions) => [processedOptions, indexByOptionValue(processedOptions)] as const,
      ),
    [options],
  );

  const byValueWithOriginalType = useMemo(
    () =>
      pipe(
        optionsIndexedByValue,
        (x) => Object.entries(x),
        (entries) =>
          entries.map(([key, itemWithStringifiedValue]) => [key, restoreOptionType<TItem>(itemWithStringifiedValue)]),
        (x) => Object.fromEntries(x) as Record<string, TItem>,
      ),
    [optionsIndexedByValue],
  );

  const getItemFromOptionsOrSelectedValue = useCallback(
    // I'd rather have implicit undefined return than have a very hard to coverage-include a branch
    // eslint-disable-next-line consistent-return
    (value: string) => {
      if (optionsIndexedByValue[value]) {
        return restoreOptionType<TItem>(optionsIndexedByValue[value]);
      }
      if (selectedValueMap.current[value]) {
        return selectedValueMap.current[value];
      }
    },
    [optionsIndexedByValue],
  );

  const setValueChanged = useCallback<(newValue: Variant extends 'single' ? string : string[]) => void>(
    (newValue) => {
      setValue?.(newValue as SelectStoreState<Variant extends 'single' ? string : string[]>['value']);
      if (!newValue) return null;
      if (variant === 'multiple') {
        const selectedOptions = (newValue as string[])
          .map(getItemFromOptionsOrSelectedValue)
          .filter((x): x is TItem => Boolean(x));

        return (onChange as (i: TItem[]) => void)(selectedOptions);
      }
      const selectedOption = getItemFromOptionsOrSelectedValue(newValue as string);

      // intentionally left commented as we might want to bring this back in future based on feedback
      // comboboxStore.setValue(selectedOption.label);
      // setTimeout(() => {
      //   comboboxStore.item(newValue as string)?.element?.scrollIntoView({ block: 'nearest' });
      // }, 80);

      return (onChange as (i: TItem) => void)(selectedOption as TItem);
    },
    [getItemFromOptionsOrSelectedValue, onChange, setValue, variant],
  ) as unknown as SelectStoreProps<Variant extends 'single' ? string : string[]>['setValue'];

  const selectStore = useSelectStore({
    disclosure,
    combobox: search ? comboboxStore : undefined,
    ...rest,
    setValue: setValueChanged,
    // disabling animation as it was asked to be removed even in existing select design, also causes functionality bug
    animated: false,
  });

  const getSelection = useCallback(
    (currentValue: Variant extends 'single' ? string : string[]) => {
      if (variant === 'single') {
        return (currentValue?.length && getItemFromOptionsOrSelectedValue(currentValue as string)) ?? null;
      }

      const multiSelectionItems = currentValue?.length
        ? ((currentValue as string[])
            .map((x) => {
              return getItemFromOptionsOrSelectedValue(x);
            })

            .filter(Boolean) as TItem[])
        : ([] as TItem[]);
      const multiSelectionTuple = [multiSelectionItems, new Set(currentValue as string[])] as const;

      /* istanbul ignore if */
      // if (multiSelectionTuple[0].length !== multiSelectionTuple[1].size) {
      //   throw new Error(
      //     'Multiple selection in Select component is getting duplicate selection values, please sanitise the data or check component logic.',
      //   );
      // }
      return multiSelectionTuple;
    },
    [getItemFromOptionsOrSelectedValue, variant],
  ) as (
    value: Variant extends 'single' ? string : string[],
  ) => Variant extends 'single' ? TItem | null : [TItem[], Set<string>];

  const clearSelectionRef = useRef(onClear);
  clearSelectionRef.current = onClear;

  const modifiedStore = useMemo(
    () => ({
      ...selectStore,
      getSelection,
      clearSelection: () => clearSelectionRef.current?.(),
    }),
    [selectStore, getSelection, clearSelectionRef],
  );

  const filtered = useMemo(() => {
    /* istanbul ignore if */
    if (!search || searchType === 'API_DRIVEN' || isSearchControlled(search)) return optionsWithTypeInfo;
    /* istanbul ignore next */
    const filterer = (
      query: string,
      option: TSelectOptionList<OptionWithStringifiedValue<TItem>>[0],
      groupLabel?: string,
    ) => {
      return search === true
        ? includesLower(option.label, query) || includesLower(groupLabel, query)
        : search.matcher(query, option, groupLabel);
    };

    return optionsWithTypeInfo.reduce((filteredList, item) => {
      if ('list' in item) {
        const filteredChildren = item.list.filter((opt) =>
          filterer(deferredSearchFieldValue, opt, item.label as string),
        );
        if (filteredChildren.length) {
          return [...filteredList, { ...item, list: filteredChildren }];
        }
      }
      if (filterer(deferredSearchFieldValue, item)) {
        return [...filteredList, item];
      }
      return filteredList;
    }, [] as typeof optionsWithTypeInfo);
  }, [optionsWithTypeInfo, search, searchType, deferredSearchFieldValue]);

  const setValueOnClick: SelectItemProps['setValueOnClick'] = (event) => {
    if (customSetValueOnClick) {
      const clickedItem = getItemFromOptionsOrSelectedValue(
        (event.target as HTMLDivElement).getAttribute('data-value') as string,
      );
      const isChecked = (event.target as HTMLDivElement).getAttribute('data-checked') === 'true';
      if (clickedItem) {
        return customSetValueOnClick(
          event,
          clickedItem,
          !isChecked,
          // convert internal store value to original type
          { ...selectedValueMap.current, ...byValueWithOriginalType },
        );
      }
    }
    return true;
  };

  return {
    variant,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectStore: modifiedStore as unknown as any,
    options: filtered,
    ...(searchType === 'API_DRIVEN' ? { searchString: deferredSearchFieldValue } : {}),
    // eslint-disable-next-line no-nested-ternary
    comboboxStore: (componentType === 'select'
      ? search
        ? { ...comboboxStore, placeholder: searchPlaceholder }
        : undefined
      : { ...comboboxStore, placeholder: searchPlaceholder }) as unknown as ComponentType extends 'select'
      ? Search extends false | undefined
        ? undefined
        : TComboboxStore
      : TComboboxStore,
    componentType,
    setValueOnClick,
  };
}

export function includesLower(optionLabel: string | undefined, query: string) {
  if (!optionLabel) return false;
  return optionLabel.toLowerCase().includes(query.toLowerCase());
}

export function getValueMap<TItem>(controlledValue: any): Record<string, TItem> {
  return Array.isArray(controlledValue)
    ? indexByOptionValue(controlledValue)
    : controlledValue?.value
    ? {
        [controlledValue?.value]: controlledValue,
      }
    : {};
}

export function makeControlledSearchHandler<TItem extends TSelectOption>(search: TSearchConfig<TItem> | undefined) {
  return (s: string) => {
    if (isSearchControlled(search)) {
      // if empty string is not to be propagated, return early
      /* istanbul ignore if */
      if (!s.length && !search.shouldPropagateEmptyQuery) {
        return;
      }
      search.onInputChange?.(s);
    }
  };
}
