/* eslint-disable */
import { PolicyId, PolicyType } from '../../../v1/obt/policy/policy_common';
import { ConstValue } from '../../../v1/obt/policy/condition';
import { Action } from '../../../v1/obt/policy/action';

export interface PolicyInfo {
  /** @deprecated */
  violationMessage: string;
  /** @deprecated */
  flagMessage: string;
  /** Fields belonging to applied policy. */
  policyId?: PolicyId;
  policyType: PolicyType;
  policyName: string;
  ruleResultInfos: PolicyInfoRuleResultInfo[];
  version: number;
  /** Whether hard approval is required, or soft. */
  approvalType: PolicyInfoApprovalType;
}

export enum PolicyInfoApprovalType {
  UNKNOWN = 0,
  HARD_APPROVAL = 1,
  SOFT_APPROVAL = 2,
  UNRECOGNIZED = -1,
}

export enum Predicate {
  UNKNOWN_PREDICATE_STRING = 0,
  MAX_FARE_PER_TRAVELLER_VIOLATION = 1,
  FARE_MORE_THAN_MINIMUM = 2,
  FARE_MORE_THAN_MEDIAN = 3,
  FARE_LESS_THAN_MEDIAN = 4,
  MAX_FARE_PER_TRAVELLER_VIOLATION_INCLUDING_TAX = 5,
  MAX_FARE_PER_TRAVELLER_VIOLATION_EXCLUDING_TAX = 6,
  HOTEL_PAYMENT_OPTIONS_VIOLATION = 7,
  RAIL_BOOKING_WINDOW_GAP_VIOLATION = 8,
  RAIL_TRAVEL_CLASS_VIOLATION = 9,
  RAIL_TICKET_REFUNDABLE_VIOLATION = 10,
  RAIL_MAX_BOOKING_PRICE_VIOLATION_INCLUDING_TAX = 11,
  RAIL_MAX_BOOKING_PRICE_VIOLATION_EXCLUDING_TAX = 12,
  AIR_MAX_PRICE_MORE_THAN_LLF_VIOLATION_INCLUDING_TAX = 13,
  AIR_MAX_PRICE_MORE_THAN_LLF_VIOLATION_EXCLUDING_TAX = 14,
  FARE_MORE_THAN_LLF = 15,
  HOTEL_RESTRICTED_KEYWORDS_VIOLATION = 16,
  RESTRICTED_LOCATION_VIOLATION = 17,
  FLIGHT_ADVANCE_BOOKING_WINDOW_DOMESTIC_VIOLATION = 18,
  FLIGHT_ADVANCE_BOOKING_WINDOW_INTERNATIONAL_VIOLATION = 19,
  FLIGHT_ADVANCE_BOOKING_WINDOW_VIOLATION = 20,
  ITINERARY_WITHIN_EVENT_TRAVEL_WINDOW = 21,
  HOTEL_IN_ALLOWED_HOTEL_LIST = 22,
  PAYMENT_ACCESS_VIOLATION = 23,
  AIRPORT_IN_ALLOWED_AIRPORT_LIST = 24,
  ITINERARY_TYPE_IS_NOT_IN_ALLOWED_BOOKING_TYPES = 25,
  PAYMENT_AIR_ADDON_VIOLATION = 26,
  UNRECOGNIZED = -1,
}

export interface PolicyInfoRuleResultInfo {
  violationInfos: PolicyInfoRuleResultInfoViolationInfo[];
  /** In case of complex rules this will contain extra information as to how the rule was calculated. */
  subViolationInfos: PolicyInfoRuleResultInfoViolationInfo[];
  /** Followed actions if rule was satisfied else violated actions. */
  actions: Action[];
}

export interface PolicyInfoRuleResultInfoViolationInfo {
  predicateString: string;
  expectedValue?: ConstValue;
  actualValue?: ConstValue;
  /**
   * This contains the enum mentioning the policy rule which was violated.
   * Will be used by FE in display of violation infos.
   */
  predicate?: Predicate;
}
