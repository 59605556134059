import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

type LocationState = {
  resetScroll?: boolean;
};

const defaultScrollConfig = {
  resetScrollOnPush: true,
  resetScrollOnReplace: false,
};

const useScrollReset = (): void => {
  const history = useHistory<LocationState>();

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' as ScrollBehavior,
    });
  }, []);

  useEffect(() => {
    const unsubscribe = history.listen(({ pathname, search, state }, action) => {
      const { resetScroll } = state || {};
      const { resetScrollOnPush, resetScrollOnReplace } = defaultScrollConfig;

      /*
       * If the location state has a resetScroll property, use that value.
       * Otherwise, use the default behavior for the action.
       */
      if (action === 'PUSH' && (resetScroll ?? resetScrollOnPush)) {
        scrollToTop();
        return;
      }

      if (action === 'REPLACE' && (resetScroll ?? resetScrollOnReplace)) {
        scrollToTop();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [history, scrollToTop]);
};

export default useScrollReset;
