import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InternalAPIErrors, ProcessedError, getV2ApiErrorMessage } from 'obt-common';

type GetErrorMessageByCodeFnType = (errorDetails: Pick<ProcessedError, 'errorCode' | 'errorParameters'>) => string;

const useGetErrorMessageByCode = (): GetErrorMessageByCodeFnType => {
  const { t: tErrors, i18n } = useTranslation('ERRORS');
  const { t: tApiErrors } = useTranslation('API_ERRORS');

  return useCallback<GetErrorMessageByCodeFnType>(
    ({ errorCode, errorParameters }) => {
      const existsInApiErrors = errorCode && i18n.exists(errorCode, { ns: 'API_ERRORS' });
      const existsInBackendErrors = errorCode && i18n.exists(errorCode, { ns: 'BACKEND_ERRORS' });

      if (existsInBackendErrors) {
        return getV2ApiErrorMessage({
          errorCode,
          errorParameters,
        });
      }

      if (existsInApiErrors) {
        return tApiErrors(errorCode ?? '');
      }

      return tErrors([errorCode ?? '', InternalAPIErrors.GenericErrors.UNKNOWN_ERROR], {
        errorCode: errorCode?.toLowerCase(),
      });
    },
    [tErrors, tApiErrors, i18n],
  );
};

export default useGetErrorMessageByCode;
