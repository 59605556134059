import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Room = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 24 24", "data-testid": "room-icon" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "evenodd", d: "M14.353 4.688c2.273 0 4.115 1.842 4.115 4.114 0 2.273-1.842 4.115-4.115 4.115-.851 0-1.643-.259-2.3-.702L7.448 16.82l1.28 1.28 1.236-1.235-.1-.1-.044-.047c-.248-.295-.233-.736.044-1.013.293-.293.768-.293 1.061 0l.63.63.044.048c.248.295.234.735-.044 1.013L9.26 19.692l-.048.043c-.295.249-.736.234-1.013-.043L5.857 17.35l-.043-.047c-.249-.295-.234-.736.043-1.013l5.126-5.127c-.47-.668-.744-1.482-.744-2.36 0-2.273 1.842-4.116 4.114-4.116zm0 1.5c-1.444 0-2.614 1.17-2.614 2.614 0 1.445 1.17 2.615 2.614 2.615 1.445 0 2.615-1.17 2.615-2.615 0-1.444-1.17-2.614-2.615-2.614z" })));
};
Room.defaultProps = defaultIconProps;
export default Room;
