import { Typography } from '@spotnana/blocks/src/Typography';
import { getFirstFocusableIn } from '@spotnana/blocks/src/utils/a11y';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { skip_link } from './AppHeader.styles';

const TAB_INDEX_REMOVAL_DELAY = 100; // in ms

const isTabElement = (element: HTMLElement) => element.getAttribute('role')?.toLowerCase() === 'tab';

const findActiveTabElementFromTabElement = (tabElement: HTMLElement) => {
  return tabElement.closest('[role="tablist"]')?.querySelector('[role="tab"][aria-selected="true"]');
};

/**
 * Renders a Skip Link Button
 * 1. Focuses on the first focusable element in the main content
 * 2. If there is no focusable element, focuses on the main content itself programatically
 * @returns
 */
export const HeaderSkipLinkButton = () => {
  const { t: tt } = useTranslation('WEB');
  const handleClick = () => {
    const main = document.querySelector('main') as HTMLElement;
    const firstFocusableElement = getFirstFocusableIn(main);

    if (firstFocusableElement) {
      if (isTabElement(firstFocusableElement)) {
        const activeTabElement =
          findActiveTabElementFromTabElement(firstFocusableElement) ??
          /* istanbul ignore next: guard clause */ firstFocusableElement;
        (activeTabElement as HTMLElement)?.focus();
      } else {
        firstFocusableElement.focus();
      }
    } else {
      // add tabindex to focus on the main content
      main.setAttribute('tabindex', '-1');
      main.focus();

      // remove tabindex after a delay
      setTimeout(() => {
        main.removeAttribute('tabindex');
      }, TAB_INDEX_REMOVAL_DELAY);
    }
  };

  return ReactDOM.createPortal(
    <button type="button" onClick={handleClick} css={skip_link}>
      <Typography variant="body2" color="link" kind="medium">
        {tt('Skip to main content')}
      </Typography>
    </button>,
    document.getElementById('skip-link') as HTMLDivElement,
  );
};
