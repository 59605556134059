import {
  useLoggedInUserId,
  useGetMarriottBonvoyAccountInfo,
  usePartnerFeatures,
  useGetUserColorConfig,
  useLoggedInUserBasicInfo,
} from 'obt-common';
import { EliteLevel } from '@spotnana/types/openapi/models/elite-level';
import { ColorTitle } from '@spotnana/types/openapi/models/color-title';
import { IncompleteUserActionName } from 'obt-common/types/userActions';
import { hasIncompleteUserAction } from '../app/user/shared/utils';

const eliteLevelToTitledColorMapper: Record<EliteLevel, ColorTitle> = {
  [EliteLevel.Member]: ColorTitle.MemberColor,
  [EliteLevel.Silver]: ColorTitle.SilverColor,
  [EliteLevel.Gold]: ColorTitle.GoldColor,
  [EliteLevel.Platinum]: ColorTitle.PlatinumColor,
  [EliteLevel.Titanium]: ColorTitle.TitaniumColor,
  [EliteLevel.Ambassador]: ColorTitle.AmbassadorColor,
};

export const useGetUserRingColor = () => {
  const {
    features: { linkedMarriottBonvoyRequired },
  } = usePartnerFeatures();
  const loggedInUserId = useLoggedInUserId();
  const loggedInUserBasicInfo = useLoggedInUserBasicInfo();
  const { data: marriottBonvoyAccountInfo } = useGetMarriottBonvoyAccountInfo({
    userId: loggedInUserId.userId?.id as string,
    enabled:
      linkedMarriottBonvoyRequired &&
      Boolean(loggedInUserId.userId?.id) &&
      !hasIncompleteUserAction(loggedInUserBasicInfo, IncompleteUserActionName.MARRIOTT_BONVOY_LINK),
  });
  const { data: colorConfig } = useGetUserColorConfig({ userId: loggedInUserId.userId?.id as string, enabled: true });
  const { eliteLevel } = marriottBonvoyAccountInfo || {};

  const ringBorderColor = eliteLevel
    ? colorConfig?.profileColors?.find(
        (profileColor) => profileColor.title === eliteLevelToTitledColorMapper[eliteLevel],
      )?.color
    : undefined;

  return ringBorderColor;
};
