import type { PnrDetailsResponseWithId } from '../../types';
import { AirPnrV3Manager } from '../pnr/air/AirPnrV3Manager';
import { RailPnrV3Manager } from '../pnr/rail/RailPnrV3Manager';

export interface ITravelSegment {
  origin: string;
  destination: string;
}

export function isRoundTrip(travelSegments?: ITravelSegment[]) {
  if (!travelSegments || travelSegments.length < 2) {
    return false;
  }

  const isRoundtrip =
    travelSegments.length === 2 &&
    travelSegments[0].origin === travelSegments[1].destination &&
    travelSegments[0].destination === travelSegments[1].origin;

  return isRoundtrip;
}

export function getPnrTravelSegments(pnr: PnrDetailsResponseWithId): ITravelSegment[] {
  let travelSegments: ITravelSegment[] = [];

  if (!pnr.data || !pnr.pnrId) {
    return travelSegments;
  }

  if (pnr.data.airPnr) {
    travelSegments = new AirPnrV3Manager({ pnrData: pnr.data, pnrId: pnr.pnrId })
      .airDetails()
      .legsDetails.map((leg) => {
        return { origin: leg.originCode, destination: leg.destinationCode };
      });
  }

  if (pnr.data.railPnr) {
    const { outwardJourneyInfo, inwardJourneyInfo } = new RailPnrV3Manager({
      pnrData: pnr.data,
      pnrId: pnr.pnrId,
    }).railDetails();

    const outwardJourneyOrigin = outwardJourneyInfo.originName;
    const outwardJourneyDestination = outwardJourneyInfo.destinationName;
    if (outwardJourneyOrigin && outwardJourneyDestination) {
      travelSegments.push({ origin: outwardJourneyOrigin, destination: outwardJourneyDestination });
    }

    const inwardJourneyOrigin = inwardJourneyInfo.originName;
    const inwardJourneyDestination = inwardJourneyInfo.destinationName;
    if (inwardJourneyOrigin && inwardJourneyDestination) {
      travelSegments.push({ origin: inwardJourneyOrigin, destination: inwardJourneyDestination });
    }
  }

  return travelSegments;
}
