import { RoleInfoTypeEnum } from '../types';
import type { AuthenticatedUserBasicInfo } from '../types/api/v1/auth/services';
import type { OrganizationBasicInfo } from '../types/api/v1/obt/common/organization';
import type { Traveler } from '../types/api/v1/obt/common/traveler';

export const getUserRelatedAnalyticsData = (
  userData?: Traveler,
  userBasicInfo?: AuthenticatedUserBasicInfo,
  userOrgData?: OrganizationBasicInfo,
) => {
  let roles = userBasicInfo?.existingUser?.roleInfos?.map((x) => RoleInfoTypeEnum[x.type]);
  if (roles?.length === 0) roles = ['TRAVELER'];
  const uniqueRoles = [...new Set(roles)];
  return {
    role: uniqueRoles,
    email: userData?.userBusinessInfo?.email,
    officeId: userData?.userBusinessInfo?.office?.id?.id,
    departmentId: userData?.userBusinessInfo?.department?.id?.id,
    legalEntityId: userData?.userBusinessInfo?.legalEntityId?.id,
    costCenterId: userData?.userBusinessInfo?.userCostCenter?.id?.id,
    organizationName: userOrgData?.name,
  };
};

export const getOrgRelatedAnalyticsData = (userData?: Traveler, userOrgData?: OrganizationBasicInfo) => {
  return {
    organizationName: userOrgData?.name,
    contractingTmcId: userData?.userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id,
    contractingTmcName: userData?.userOrgId?.tmcBasicInfo?.contractingTmc?.name,
    bookingTmcId: userData?.userOrgId?.tmcBasicInfo?.bookingTmc?.id?.id,
    bookingTmcName: userData?.userOrgId?.tmcBasicInfo?.bookingTmc?.name,
  };
};
