export class SpotnanaError extends Error {
  public constructor(errorOrMessage?: Error | string) {
    if (errorOrMessage instanceof Error) {
      super(errorOrMessage.message);
      this.name = errorOrMessage.name;
    } else {
      super(errorOrMessage);
      this.name = 'SpotnanaError';
    }

    // Maintains proper stack trace for where our error was thrown (only available on V8)z
    // eslint-disable-next-line
    // @ts-ignore
    Error.captureStackTrace?.(this, SpotnanaError);
  }
}

export default SpotnanaError;
