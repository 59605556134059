import { FC } from 'react';
import Box from '@spotnana/pixel-react/dist/Box';
import CircularLoader from '@spotnana/pixel-react/dist/CircularLoader';

const Loader: FC = () => {
  return (
    <Box
      height="full"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      data-testid="loading-animation"
    >
      <CircularLoader size={50} />
    </Box>
  );
};

export { Loader };
