import { FC } from 'react';
import { createUserNameFromFullName, getFullName, ITraveler, useFeatureFlag, Fare } from 'obt-common';
import { Typography } from '@spotnana/blocks/src/Typography/Typography';
import { IconButton } from '@spotnana/blocks/src/IconButton/IconButton';
import { useTravelersResponse } from '../useTravelersResponse';
import { arrow_icon, fare_list_item, fare_list_summary } from './styles';
import { FareItemSegment } from '../FareItemSegment';

interface IFareListItemProps {
  onClick: (listId: string, travelerName: string) => void;
  travelerUserId: string;
  fares: Fare[];
  listId: string;
}

export const FareListItem: FC<IFareListItemProps> = ({ onClick, travelerUserId, fares, listId }) => {
  const travelers: ITraveler[] = useTravelersResponse();
  const isPreferredNameEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');

  const traveler = travelers.find((t) => t.userOrgId.userId?.id === travelerUserId);
  const travelerName = isPreferredNameEnabled
    ? createUserNameFromFullName(traveler?.user?.name)
    : getFullName(traveler?.user?.name);

  const handleClick = () => {
    onClick(listId, travelerName);
  };
  return (
    <button onClick={handleClick} type="button" css={fare_list_item} data-testid="clickable_card_fare_list">
      <div css={fare_list_summary}>
        <Typography variant="header4" kind="semibold">
          {travelerName}
        </Typography>
        {fares.map((fare) => {
          return <FareItemSegment key={fare.rateOptionId} segments={fare.segments} />;
        })}
      </div>
      <IconButton icon="ChevronRight" css={arrow_icon} />
    </button>
  );
};
