import useLatest from 'ahooks/lib/useLatest';
import { useEffect, useRef } from 'react';
import { useApplyAndFetchUserPreferredLanguageToApp } from '../useApplyAndFetchUserPreferredLanguageToApp';
import {
  FetchUserLanguageRequestEvent,
  FetchUserLanguageResponseEvent,
  UserLanguageEventTypes,
} from '../../components/EmbedContext/types';

function isUserLanguageFetchEvent(event: any): event is FetchUserLanguageRequestEvent {
  return event?.type === UserLanguageEventTypes.FETCH_USER_LANGUAGE_REQUEST;
}

const DEFAULT_USER_LANGUAGE = 'en-US';

/**
 * Sends a response to the parent window with the user's preferred language.
 * @param language
 */
function sendUserLanguageResponse(language: string) {
  const eventData: FetchUserLanguageResponseEvent = {
    type: UserLanguageEventTypes.FETCH_USER_LANGUAGE_RESPONSE,
    payload: {
      language,
    },
  };

  window.parent.postMessage({ from: 'spotnana-embed', ...eventData });
}

/**
 * Custom React hook that handles language events in an embedded context.
 * It listens for language-related events and responds with the user's preferred language.
 */
export function useEmbedLanguageEvents() {
  const { isPersonalInfoLoading, preferredLanguage } = useApplyAndFetchUserPreferredLanguageToApp();
  const isRequestPendingRef = useRef<boolean>(false);

  const listener = (event: MessageEvent) => {
    if (isUserLanguageFetchEvent(event.data)) {
      // fetch is in progress, wait for it to finish
      if (isPersonalInfoLoading) {
        // set a flag, so that when data is fetched we can respond later
        isRequestPendingRef.current = true;
        return;
      }

      // users preferred language is already fetched, respond
      sendUserLanguageResponse(preferredLanguage || DEFAULT_USER_LANGUAGE);
    }
  };

  const listenerEventRef = useLatest(listener);

  useEffect(() => {
    const callback = (event: MessageEvent) => listenerEventRef.current(event);
    window.addEventListener('message', callback);

    // remove listener on unmount
    return () => window.removeEventListener('message', callback);
  }, [listenerEventRef]);

  useEffect(() => {
    if (!isPersonalInfoLoading && isRequestPendingRef.current) {
      // respond to any pending requests
      // clear the flag
      isRequestPendingRef.current = false;

      // respond with users preferred language
      sendUserLanguageResponse(preferredLanguage || DEFAULT_USER_LANGUAGE);
    }
  }, [isPersonalInfoLoading, preferredLanguage]);
}
