import type { Dispatch, SetStateAction } from 'react';

import type { IconName } from '@spotnana/blocks/src/Icon/types';
import type {
  RailReserveItineraryRequestSeatPreference,
  RailReserveItineraryRequestSeatPreferenceSeatSelection,
  RailReserveItineraryRequest,
} from './api/v1/obt/rail/rail_reserve_itinerary_request';

import type { RailCard as ProfileRailCard } from './api/v1/common/card';
import type {
  Ancillary,
  FareTypeFareDetail,
  ItineraryDetails,
  Passenger,
  RailAmenity,
  SearchType,
  SeatPreferenceGroup,
  TravelCard,
  TravelClass,
  RailAmenity as RailAmenityV1,
  FareType,
  DeliveryOptionDetails,
  Vehicle,
  PassengerType,
  AppliedPromotion,
} from './api/v1/obt/rail/rail_common';
import type {
  RailSearchInwardRequest,
  RailSearchNavigationRequest,
  RailSearchOutwardRequest,
  RailSearchOutwardRequestJourneySearchDefinition,
  RailSearchOutwardRequestJourneySearchDefinitionLocation,
} from './api/v1/obt/rail/rail_search_request';
import type { Leg, SectionAlternative } from './api/v1/obt/rail/rail_search_response';
import type { DateTime } from './api/v1/common/date_time';
import type {
  ListRailCardsResponse,
  RailAllocatedSpace,
  ListRailCardsResponseRailCards as RailCard,
  Image,
} from './api/v2/obt/model';
import type { IRailSuggestion } from './autocomplete';
import type { MoneyUtil } from '../utils/Money';
import type { NonProfileUserInfo, UserOrgId } from './api/v1/obt/common/user_org_id';
import type { BookingFlow, TripIdUrlParam } from './common';
import type { RailThirdParty } from './api/v2/obt/model/rail-third-party';
import type {
  RailBookItineraryResponsePassengerDataRequirements,
  RailBookItineraryResponseSeatReservationOffer,
} from './api/v1/obt/rail/rail_book_itinerary_response';
import type { RailReserveItineraryResponseTermsAndConditions } from './api/v1/obt/rail/rail_reserve_itinerary_response';
import type { INationalDoc, IUserOrgId } from './traveler';
import type { RailCarriageDeck } from './api/v2/obt/model/rail-carriage-deck';
import type { RailLayoutItem, RailLayoutItemTypeEnum } from './api/v2/obt/model/rail-layout-item';
import type { RailModifySearchRequest } from './api/v2/obt/model/rail-modify-search-request';

export {
  VehicleType as RailVehicleType,
  DeliveryOption as IDeliveryOption,
  TravelClass as RailTravelClassV1,
  RailAmenityRailAmenityType as RailAmenityRailAmenityTypeV1,
  TicketAssetType as RailTicketAssetTypeV1,
} from './api/v1/obt/rail/rail_common';
export type {
  Ancillary as IRailAncillary,
  DeliveryOptionDetails as IDeliveryOptionDetails,
  ItineraryDetails as IRailItineraryDetails,
  JourneyInfo as IRailJourneyInfo,
  LegInfo as IRailLegInfo,
  LegInfoSeatPreferenceSelection as ILegInfoSeatPreferenceSelection,
  SeatPreferenceGroup as ISeatPreferenceGroup,
  SeatPreferenceGroupCarriage as ISeatPreferenceGroupCarriage,
  SeatPreferenceGroupDirection as ISeatPreferenceGroupDirection,
  SeatPreferenceGroupFacility as ISeatPreferenceGroupFacility,
  SeatPreferenceGroupPosition as ISeatPreferenceGroupPosition,
  TravelCard as IRailTravelCard,
  FareType as IRailFareType,
  RailAmenity as RailAmenityV1,
  TicketAsset as RailTicketAssetV1,
} from './api/v1/obt/rail/rail_common';

export type { RailBookItineraryResponseSeatReservationOffer as IRailBookItineraryResponseSeatReservationOffer } from './api/v1/obt/rail/rail_book_itinerary_response';
export type {
  RailReserveItineraryRequest as IRailReserveItineraryRequest,
  RailReserveItineraryRequestSeatPreference as IRailReserveItineraryRequestSeatPreference,
  RailReserveItineraryRequestSeatReservationDetail as IRailReserveItineraryRequestSeatReservationDetail,
  RailReserveItineraryRequestAmenitySelection as IRailReserveItineraryRequestAmenitySelection,
} from './api/v1/obt/rail/rail_reserve_itinerary_request';
export type { RailReserveItineraryResponseTermsAndConditions as IRailReserveItineraryResponseTermsAndConditions } from './api/v1/obt/rail/rail_reserve_itinerary_response';
export { SectionAlternativeCategory as ISectionAlternativeCategory } from './api/v1/obt/rail/rail_search_response';
export type { SectionAlternativeFare as ISectionAlternativeFare } from './api/v1/obt/rail/rail_search_response';

export type { RailCreatePnrRequest as IRailCreatePnrRequest } from './api/v1/obt/trip/rail_create_pnr_request';
export type { RailCreatePnrResponse as IRailCreatePnrResponse } from './api/v1/obt/trip/rail_create_pnr_response';
export type { RailLayoutItem } from './api/v2/obt/model/rail-layout-item';
export { RailLayoutItemTypeEnum, RailLayoutItemDirectionEnum } from './api/v2/obt/model/rail-layout-item';
export { RailCarriageDeckTypeEnum } from './api/v2/obt/model/rail-carriage-deck';

export type IRailSearchOutwardRequest = RailSearchOutwardRequest;
export type IRailSearchNavigationRequest = RailSearchNavigationRequest;
export type IListRailCardsResponse = ListRailCardsResponse;
export type IRailStationLocation = RailSearchOutwardRequestJourneySearchDefinitionLocation;
export type IRailCard = RailCard;
export type IRailSegment = RailSearchOutwardRequestJourneySearchDefinition;
export type IProfileRailCard = ProfileRailCard;
export type IPassengers = Passenger;

export type IRailThirdParty = RailThirdParty;

export type IRailLeg = Leg;

export interface ISectionAlternative extends Omit<SectionAlternative, 'totalPrice' | 'discountedPrice'> {
  totalPrice?: MoneyUtil;
  discountedPrice?: MoneyUtil;
}

export type OriginOrDestination = 'origin' | 'destination';
export type IRailSearchRequest = RailSearchOutwardRequest | RailSearchInwardRequest | RailSearchNavigationRequest;
export type IRailModifySearchRequest = RailModifySearchRequest | RailSearchInwardRequest | RailSearchNavigationRequest;

export type IDepartureOrArrival = 'departure' | 'arrival';
export type IJourneyType = 'inward' | 'outward';
export type IRailSearchOptionsType = 'earlier' | 'later';

export type IRailSearchType = keyof typeof SearchType;

export type { RailSearchResponse as IRailSearchResponse } from './api/v1/obt/rail/rail_search_response';

export interface RailBookItineraryData {
  searchKey: string;
  travelCards?: ITravelCard[];
  deliveryOptions?: DeliveryOptionDetails[];
  seatPreferences?: SeatPreferenceGroup[];
  itineraryDetails?: IItineraryDetails;
  ancillaries?: Ancillary[];
  seatReservationOffers: RailBookItineraryResponseSeatReservationOffer[];
  passengerDataRequirements: RailBookItineraryResponsePassengerDataRequirements[];
  availableAmenities: RailAmenity[];
}

export interface IWebRailSearchFormUrlParams {
  fromDate?: string;
  toDate?: string;
  tripId?: TripIdUrlParam;
  isPreselectedTrip?: boolean;
  isPersonalTrip?: boolean;
  selectedTab?: IRailSearchType;
}

export interface IRailSearchSegmentState<T> {
  origin: T;
  destination: T;
  date: string;
  time: string;
  via: IViaFilterState<T>;
  type?: string;
}

export type IRailSearchSegmentErrorState = Omit<IRailSearchSegmentState<string>, 'via'> & {
  via: string;
};

export interface IValidateRailSearchFormState {
  errors: IRailSearchSegmentErrorState[];
  isValid: boolean;
}

export interface IRailSearchUrlParams {
  discountCards: string[];
  /*
   * The split ticket discount and the old journey price are not represented by the MoneyUtil class
   * because we cannot carry over the whole class in the URL.
   * To use this, simply convert the object to MoneyUtil using MoneyUtil.create(amount, currency)
   */
  splitTicketDiscount?: {
    amount: number;
    currency: string;
  };
  outwardSplitTicketDiscount?: {
    amount: number;
    currency: string;
  };
  groupSaveDiscount?: {
    amount: number;
    currency: string;
  };
  outwardGroupSaveDiscount?: {
    amount: number;
    currency: string;
  };
  /** The 'railcards' prop will be discontinued when mutlipax and the new railcard picker are fully implemented */
  railcards?: IRailcardLoyaltyListItem[];
  primaryTravelerRailcards?: IRailcardLoyaltyListItem[];
  additionalTravelers?: RailAdditionalTraveler[];
  searchType: IRailSearchType;
  segments: IRailSearchSegmentState<IRailSuggestion>[];
  outwardSearchKey?: string;
  outwardRateKeys?: string[];
  searchKey?: string;
  next?: boolean;
  currLegNumber: number;
  travelerIds?: UserOrgId[];
  isPersonalTravel?: boolean;
  tripId?: TripIdUrlParam;
  isPreselectedTrip?: boolean;
  utm_source?: string;
  bookingFlow?: BookingFlow;
  nonProfileUserInfo?: NonProfileUserInfo;
  nationalDoc?: INationalDoc;
  disableSearchSummary?: boolean;
}

export interface IRailSearchPaxInfo {
  userId: string | undefined;
}

export interface IRailSearchMobileParams extends IRailSearchUrlParams {
  passengers: IRailSearchPaxInfo[];
  previousSearchKey?: string;
  previousSearchType?: IRailSearchOptionsType;
  outwardJourneyDate?: string;
  inwardJourneyDate?: string;
}

export interface IRailCheckoutUrlParams {
  discountCards: string[];
  railcards?: IRailcardLoyaltyListItem[];
  searchType: IRailSearchType;
  segments: IRailSearchSegmentState<IRailSuggestion>[];
  rateKeys: string[];
  searchKey: string;
  travelerIds?: UserOrgId[];
  isPersonalTravel?: boolean;
  tripId?: TripIdUrlParam;
  isPreselectedTrip?: boolean;
  nationalDoc?: INationalDoc;
  splitTicketDiscountedAmount?: string;
  groupSavingsDiscountedAmount?: string;
  appliedPromotions?: AppliedPromotion[];
  additionalTravelers?: RailAdditionalTraveler[];
}

export type IRailCheckoutMobileParams = IRailCheckoutUrlParams & {
  passengers: IRailSearchPaxInfo[];
};

export interface IRailSearchFormUrlParams {
  fromDate: string;
  toDate: string;
  tripId: string;
}

export interface IDateTimeInputProps {
  segments: IRailSearchSegmentState<IRailSuggestion>[];
  errors: IRailSearchSegmentErrorState[];
  updateSegments: (segments: IRailSearchSegmentState<IRailSuggestion>[]) => void;
  updateErrors: (updatedErrors: IRailSearchSegmentErrorState[]) => void;
  allowedBookingDateRange?: { from: Date; to: Date };
}

export interface IRailOverlayAndDuration {
  overlay: number;
  duration: string;

  /**
   * Indicates whether it's required to change stations during an overlay
   */
  isSameStation: boolean;
}

export interface IRailListItemSummary {
  departureAt?: string;
  arriveAt?: string;
  duration?: string;
  origin: string;
  originPlatform: string;
  legs: IRailLeg[];
  destination: string;
  destinationPlatform: string;
}

export type IFareTypeFareDetail = FareTypeFareDetail;

export interface ISectionAlternatives {
  singleFares: Record<string, ISectionAlternative[]>;
  returnFares: Record<string, ISectionAlternative[]>;
}

export interface ISectionTravelClassAlternatives {
  standardClass: ISectionAlternatives;
  firstClass: ISectionAlternatives;
}

export interface ISectionTravelClassAlternativesCreateMethod {
  sectionAlternative: ISectionAlternative;
  allFares: ISectionTravelClassAlternatives;
  className: keyof ISectionTravelClassAlternatives;
  categoryName: keyof ISectionAlternatives;
  key: string;
}

export interface ISelectedRailCardsProps {
  boxRef: React.RefObject<HTMLDivElement>;
  handleClick: () => void;
  selectedRailcards: string[];
  onReset?: () => void;
  handleMouseEnterClose: () => void;
  handleMouseLeaveClose: () => void;
  primaryColor: string;
  clicked: boolean;
  showAddEditButton?: boolean;
}

/** Rate for the itinerary. */
export interface IRateInfo {
  /** Base Amount. */
  base?: MoneyUtil;
  /** Fees and Taxes. */
  fees?: MoneyUtil;
  /** Rail Card Discount Amount */
  railCardDiscount?: MoneyUtil;
  /** Total Amount. */
  total?: MoneyUtil;
  /** Rate difference between new and old bookings (exchanges flow) */
  rateDifference?: MoneyUtil;
  /** Rate difference between new and old bookings (exchanges flow) including taxes & fees */
  totalRateDifference?: MoneyUtil;
  /** Refund */
  refund?: MoneyUtil;
  /** Voucher Refund */
  refundVoucher?: MoneyUtil;
  /** Date and time of the transaction. */
  transactionDateTime?: DateTime;
}

export interface ITravelCard extends Omit<TravelCard, 'rateInfo'> {
  rateInfo?: IRateInfo;
}

export interface IAncillary extends Omit<Ancillary, 'travelCard'> {
  /** Details of the travel cards. */
  travelCard?: ITravelCard;
}

export interface IItineraryDetails extends Omit<ItineraryDetails, 'rateInfo' | 'ancillaries'> {
  /** Rate Information of the itinerary. */
  rateInfo?: IRateInfo;
  /** Details of the selected ancillaries for the itinerary. */
  ancillaries: IAncillary[];
}

export interface IRailReserveItineraryResponse {
  /** Rail search key. */
  searchKey: string;
  /** Itinerary Details. */
  itineraryDetails?: IItineraryDetails;
  /** Terms and Conditions */
  termsAndConditions: RailReserveItineraryResponseTermsAndConditions[];
}

export type IRailCo2EmissionPerTravelType = Record<TravelClass, number>;

export enum RailContinentCodes {
  EUROPE = 'EU',
  NORTH_AMERICA = 'NA',
}

export enum RailSeatTypeEnum {
  PAID = 'PAID',
  FREE = 'FREE',
  UNAVAILABLE = 'UNAVAILABLE',
  SELECTED = 'SELECTED',
}

export type RailLoyaltySelectListItemProduct = RailCard & { cardNumber: string | undefined };

export interface IRailcardLoyaltyListItem {
  SPT__isDisabled: boolean;
  label: string;
  value: string;
  parentTitle: string;
  product: RailLoyaltySelectListItemProduct;
}

export interface IRailcardLoyalty {
  title: string;
  list: IRailcardLoyaltyListItem[];
}

export type EURailFlexibility = 'nonflexi' | 'semiflexi' | 'flexi';
export type RailFlexibility = EURailFlexibility | 'none';

export type RailBookingArea = 'UK' | 'US' | 'EU';

export type UKRailSeatReservationJourneyInfo = {
  type: 'outbound' | 'return';
  fareType: string | null;
  origin: string | null;
  destination: string | null;
  numberOfChanges: number;
  isMandatorySeatReservation: boolean;
  applicableSeatReservationOffer: RailBookItineraryResponseSeatReservationOffer | null;
};

export type EURailSeatReservationSectionInfo = {
  type: 'outbound' | 'return';
  fareType: string | null;
  origin: string | null;
  destination: string | null;
  numberOfChanges: number;
  vehicles: Vehicle[];
  vendorName: string;
  searchKey: string;
  itineraryId: string | undefined;
  amenities: RailAmenity[];
  seatPreferences: SeatPreferenceGroup[];
};

export type RailSeatPreferencesItem = {
  id: string;
  name: string;
};

export type SeatPreferenceOptions = {
  position: RailSeatPreferencesItem[];
  direction: RailSeatPreferencesItem[];
  coachType: RailSeatPreferencesItem[];
  deck: RailSeatPreferencesItem[];
  seatType: RailSeatPreferencesItem[];
};

export type RailSeatType = (typeof RailSeatTypeEnum)[keyof typeof RailSeatTypeEnum];
export type RailCarriageDeckWithCoach = RailCarriageDeck & { coachName: string };

export type RailSeatMapForACoach = (RailLayoutItem & { seatType: RailSeatType })[];
export type RailSeatLegend = [RailLayoutItemTypeEnum[], RailSeatType[]];

export enum IViaFilterType {
  VIA = 'via',
  NO_SELECTION = 'none',
}

export type IViaFilterState<T> = {
  type: IViaFilterType;
  location: T;
};

export type FareDetailsModalSectionInfo = {
  origin: string;
  destination: string;
  numOfChanges: number;
  vehicles: Vehicle[];
  isAmtrakCarrier: boolean;
  /*
   * FareType represents how the fare is represented in journey. (e.g. Advance Single)
   */
  fareTypes?: FareType[];
  /** Route restrictions for this travel. */
  routeRestrictions?: string[];
  /** Fare details description (e.g. travel restrictions) */
  fareDetails?: IFareTypeFareDetail[];
  amenities?: RailAmenityV1[];
  originStations: string[];
  destinationStations: string[];
  appliedPromotions: AppliedPromotion[];
};

export type FareDetailsModalConfig = {
  title: string;
  sectionInfos: FareDetailsModalSectionInfo[];
};

export interface SplitTicketDetailsModalSectionInfo extends FareDetailsModalSectionInfo {
  splitTicketType?: string;
  splitTicketTypeIcon?: IconName;
}

export type SplitTicketDetailsModalConfig = {
  title?: string;
  sectionInfos: SplitTicketDetailsModalSectionInfo[];
};

export type JourneyIdxSelectionState = number | null;
type JourneyIdxSelectionDispatch = Dispatch<SetStateAction<JourneyIdxSelectionState>>;

export interface WithJourneyIdxSelectionProps {
  selectedJourneyIdx: JourneyIdxSelectionState;
  onSelectedJourneyIdxChange: JourneyIdxSelectionDispatch;
}

export type TravelClassSelectionState = TravelClass | null;
type TravelClassSelectionDispatch = Dispatch<SetStateAction<TravelClassSelectionState>>;

export interface WithTravelClassSelectionProps {
  selectedTravelClass: TravelClassSelectionState;
  onSelectedTravelClassChange: TravelClassSelectionDispatch;
}

export type FlexibilitySelectionState = EURailFlexibility | null;
type FlexibilitySelectionDispatch = Dispatch<SetStateAction<FlexibilitySelectionState>>;

export interface WithFlexibilitySelectionProps {
  selectedFlexibility: FlexibilitySelectionState;
  onSelectedFlexibilityChange: FlexibilitySelectionDispatch;
}

export type AlternativesSelectionState = ISectionAlternative[];
type AlternativesSelectionDispatch = Dispatch<SetStateAction<AlternativesSelectionState>>;

export interface WithAlternativesSelectionProps {
  selectedAlternatives: AlternativesSelectionState;
  onSelectedAlternativesChange: AlternativesSelectionDispatch;
}

/** Rail Seat Preference and Reservation */

export type RailSeatReservationState = RailReserveItineraryRequest['seatReservationDetails'];
export type RailSeatReservationStateDispatch = Dispatch<SetStateAction<RailSeatReservationState>>;
export type WithRailSeatReservationState = {
  railSeatReservationState: RailSeatReservationState;
  changeRailSeatReservationState: RailSeatReservationStateDispatch;
};

export type IRailSeatSelections = RailReserveItineraryRequestSeatPreferenceSeatSelection & { seatPrice?: MoneyUtil };

export type IRailReserveItineraryRequestSeatPreferenceType = Omit<
  RailReserveItineraryRequestSeatPreference,
  'seatSelections'
> & { seatSelections: IRailSeatSelections[] };

export type RailSeatPreferencesState = IRailReserveItineraryRequestSeatPreferenceType[];
export type RailSeatPreferencesStateDispatch = Dispatch<SetStateAction<RailSeatPreferencesState>>;
export type WithRailSeatPreferencesState = {
  railSeatPreferencesState: RailSeatPreferencesState;
  changeRailSeatPreferencesState: RailSeatPreferencesStateDispatch;
};

export type RailAmenitySelectionsState = RailReserveItineraryRequest['amenitySelections'];
export type RailAmenitySelectionsStateDispatch = Dispatch<SetStateAction<RailAmenitySelectionsState>>;
export type WithRailAmenitySelectionsState = {
  railAmenitySelectionsState: RailAmenitySelectionsState;
  changeRailAmenitySelectionsState: RailAmenitySelectionsStateDispatch;
};

export interface EUSeatPreferencesOptionEntityIds {
  optionId: string;
  seatPreferencesGroupId: string;
}

export interface EUSeatPreferencesCheckOption extends RailSeatPreferencesItem {
  entityIds: EUSeatPreferencesOptionEntityIds[];
}

export interface EUSeatPreferencesSitNearOption {
  entityIds: EUSeatPreferencesOptionEntityIds[];
}

export type SeatReservationAndPreferencesPropsBase = {
  itineraryData: RailBookItineraryData;
};
export type UKSeatReservationAndPreferencesProps = SeatReservationAndPreferencesPropsBase;

interface IKeyValuePair {
  value: string;
  label: string;
}

export interface IGetSeatPreferencesOptions {
  position: IKeyValuePair[];
  direction: IKeyValuePair[];
  coachType: IKeyValuePair[];
  deck: IKeyValuePair[];
  seatType: IKeyValuePair[];
}

export interface IEUSeatPreferenceKeyValuePair {
  value: string;
  label: string;
  entityIds: EUSeatPreferencesOptionEntityIds[];
}

export interface IEUFacilitySeatPreferenceKeyValuePair {
  entityIds: EUSeatPreferencesOptionEntityIds[];
  id: string;
  name: string;
}

export interface IEUGetSeatPreferencesOptions {
  position: IEUSeatPreferenceKeyValuePair[];
  direction: IEUSeatPreferenceKeyValuePair[];
  coachType: IEUSeatPreferenceKeyValuePair[];
  deck: IEUSeatPreferenceKeyValuePair[];
  seatType: IEUSeatPreferenceKeyValuePair[];
}

export type RadioGroupValueTypes = 'auto' | 'choose-a-seat';

export interface RailSeatMapProps {
  sectionInfo: EURailSeatReservationSectionInfo;
  onClose: () => void;
  open: boolean;
  resetRadioGroup: () => void;
  handleSeatClear: () => void;
}

export type RailLocalSelectedSeatType = IRailSeatSelections | undefined;

export type RailRouteInfoLegProps = {
  leg: IRailLeg;
  hasPrevious: boolean;
  hasNext: boolean;
  allocatedSpaces?: RailAllocatedSpace[];

  /**
   * The following fields are added because of the differences between
   * IRailLeg (used during shopping) and IRailLegInfo (used during checkout).
   */

  /**
   * `duration` is not present in `IRailLegInfo`, hence passing this as a separate prop
   */
  duration?: string;
  /**
   * In case of `IRailLeg`, we should rely on `originInfo.sourceRefInfos`,
   * which should contain the correct `inventoryName`.
   *
   * In case of `IRailLegInfo`, we should rely on `vendorName`,
   * which should be either `Amtrak` (US), `ATOC` (UK) or anything else for EU.
   *
   * `vendorName` is not set in IRailLeg, hence passing it as a separate prop.
   */
  railBookingArea?: RailBookingArea;
};

export interface IRailTicket {
  idx: number; // Unique identifier for the ticket.
  legs: number[]; // An array of leg indices associated with the ticket.
  isCancelled: boolean; // Indicates whether the ticket is cancelled (true or false).
  isCompleted: boolean; // Indicates whether the ticket is completed (true or false).
}

export enum RailSmallChildType {
  ON_LAP = 'ON_LAP',
  ON_SEAT = 'ON_SEAT',
}

export interface RailAdditionalTraveler {
  /**
   *  Unique user identifier, similar to "key" in a list.
   *  Used for both named and unnamed travelers.
   */
  userUid: string;
  /** Passenger type, i.e. ADULT, CHILD, INFANT, YOUTH or SENIOR */
  paxType: PassengerType;
  /** Passenger group, i.e. Youth */
  paxGroup?: PassengerType.YOUTH | undefined;
  /**
   * Only applicable to children
   */
  paxAge?:
    | {
        years: number;
      }
    | undefined;
  /**
   * Only applicable to small children to accurately represent the age range in preselected fields
   */
  paxAgeRange?:
    | {
        min: number;
        max: number;
      }
    | undefined;
  /**
   * Only applicable to small children (0-5 years)
   *
   * ON_LAP | ON_SEAT
   */
  smallChildType?: RailSmallChildType | undefined;
  /** The label shown for unnamed travelers instead of the name (e.g. Adult guest 1) */
  guestLabel?: string;
  /** User's org id, applicable only to primary and named travelers */
  userOrgId?: IUserOrgId;
  /** Array of selected rail cards for a particular user */
  selectedRailcards: IRailcardLoyaltyListItem[];
  /** Profile picture (exclusive to named travelers) */
  profilePicture?: Image;
}
