import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth, useProcessAuthorization } from 'obt-common';

import { Typography } from '@spotnana/blocks/src/Typography';
import { CircularProgress } from '@spotnana/blocks/src/CircularProgress';
import { Button } from '@spotnana/blocks/src/Button';

import { useExternalAuthCodeContext } from 'src/components/ExternalAuthCodeProvider/ExternalAuthCodeProvider';
import { button_container, circular_progress_container } from './BonvoyLinkModal.styles';
import { BonvoyLoginModal } from './BonvoyLoginModal';

interface IBonvoyLinkModalProps {
  marriottAuthCode: string;
  userId: string;
}

export function BonvoyLinkModal({ marriottAuthCode, userId }: IBonvoyLinkModalProps) {
  const { t: tt } = useTranslation('WEB');
  const { refetchUserBasicInfo } = useAuth();
  const { resetAuthState } = useExternalAuthCodeContext();

  const { mutateAsync: processAuthorizationResponse, error: processAuthorizationResponseError } =
    useProcessAuthorization(userId);

  const handleLinkMarriottAccount = useCallback(
    async (code: string) => {
      await processAuthorizationResponse({ source: 'MARRIOTT', code, originDomain: window.location.origin });
      await refetchUserBasicInfo();
      resetAuthState();
    },
    [processAuthorizationResponse, refetchUserBasicInfo, resetAuthState],
  );

  const handleRetry = useCallback(async () => {
    resetAuthState();
  }, [resetAuthState]);

  useEffect(() => {
    if (marriottAuthCode) {
      handleLinkMarriottAccount(marriottAuthCode);
    }
  }, [handleLinkMarriottAccount, marriottAuthCode]);

  if (processAuthorizationResponseError) {
    return (
      <BonvoyLoginModal
        title={tt('An error has occurred.')}
        footer={
          <div css={button_container}>
            <div>
              <Button fullWidth variant="primary" onClick={handleRetry}>
                {tt('Try again')}
              </Button>
            </div>
          </div>
        }
      >
        <Typography variant="body2" kind="regular">
          {tt('Apologies, an error occurred. Let’s try again from beginning.')}
        </Typography>
      </BonvoyLoginModal>
    );
  }

  return (
    <BonvoyLoginModal title={tt('Please wait it’s processing now')}>
      <Typography variant="body2" kind="regular">
        {tt(
          'Currently linking your Marriott Bonvoy account with Business Access by Marriott Bonvoy. Your patience is appreciated.',
        )}
      </Typography>
      <div css={circular_progress_container}>
        <CircularProgress size={20} data-testid="linking-loader-indicator" />
      </div>
    </BonvoyLoginModal>
  );
}
