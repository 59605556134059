import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const ConfigurationsListPage = lazy(() => import('./ConfigurationsListPage'));
const ConfigurationFormPage = lazy(() => import('./ConfigurationFormPage'));

export const configurationRoutes: IRouteConfig[] = [
  {
    key: 'admin-configuration',
    path: '/admin/configurations/:configSlug',
    component: ConfigurationFormPage,
  },
  {
    key: 'admin-configurations',
    path: '/admin/configurations',
    component: ConfigurationsListPage,
  },
];
