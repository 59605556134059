import { useBlocksTheme } from '@spotnana/blocks/src/utils/styling/useBlocksTheme';
import type { BlocksTheme } from '@spotnana/blocks/src/types';

import { createContext, useContext, PropsWithChildren, ReactElement, useState, useEffect, useMemo } from 'react';

interface IPageContext {
  headerColor: string;
  setHeaderColor: (color: 'light' | 'regular') => void;
  footerColor: string;
  setFooterColor: (color: 'light' | 'regular') => void;
}

const PageConfigContext = createContext<IPageContext>({} as IPageContext);

type ColorMode = 'light' | 'regular';

/**
 * Reads Color From the Palette based on Mode
 * @param color
 * @param palette
 * @returns
 */
const getColorFromPalette = (color: ColorMode, palette: BlocksTheme['palette']) => {
  const colorConfig = {
    light: palette.surface.background,
    regular: palette.surface.base,
  };

  return colorConfig[color];
};

export const PageConfigProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const { palette } = useBlocksTheme();

  const [headerColor, _setHeaderColor] = useState<ColorMode>('regular');
  const [footerColor, _setFooterColor] = useState<ColorMode>('regular');

  const values = useMemo(
    () => ({
      headerColor: getColorFromPalette(headerColor, palette),
      footerColor: getColorFromPalette(footerColor, palette),
      setHeaderColor: (color: ColorMode) => _setHeaderColor(color),
      setFooterColor: (color: ColorMode) => _setFooterColor(color),
    }),
    [palette, headerColor, footerColor, _setHeaderColor, _setFooterColor],
  );

  return <PageConfigContext.Provider value={values}>{children}</PageConfigContext.Provider>;
};

export const usePageConfig = (): IPageContext => {
  const context = useContext(PageConfigContext);

  if (context === undefined) {
    throw new Error('`usePageConfig` hook must be used within a `PageConfigProvider` component');
  }
  return context;
};

export const useLightFooter = () => {
  const { setFooterColor } = usePageConfig();
  useEffect(() => {
    setFooterColor('light');
    return () => {
      setFooterColor('regular');
    };
  }, [setFooterColor]);
};

export const useLightHeader = () => {
  const { setHeaderColor } = usePageConfig();
  useEffect(() => {
    setHeaderColor('light');
    return () => {
      setHeaderColor('regular');
    };
  }, [setHeaderColor]);
};
