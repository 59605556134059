import { defineMessage } from '../../translations/defineMessage';
import type { ILabelValuePair, IUserPronoun } from '../../types';
import {
  CoachPref,
  ConditionalRate,
  DeckLevel,
  RailTravelClass,
  SeatPrefDirection,
  SeatPrefLocation,
  SeatPrefType,
} from '../../types';
import { PreferredPronounEnum } from '../../types/api/v1/obt/common/user';
import { HotelPrefAmenity } from '../../types/api/v2/obt/model/hotel-pref-amenity';
import {
  RoomPreferenceMostImportantFactEnum,
  RoomPreferenceRoomTypeEnum,
  RoomPreferenceBedCountTypeEnum,
  RoomPreferencePillowTypeEnum,
  RoomPreferenceRoomLocationEnum,
  RoomPreferenceRoomAmenityPrefsEnum,
} from '../../types/api/v2/obt/model/room-preference';

export const imageSizeLimit = 5000000; // 5 MB

export const imageFileConfig = {
  acceptFileTypes: 'image/*',
  sizeLimit: imageSizeLimit,
};

export const pictureEditorConfig = {
  MIN_SCALE: 0.2,
  MAX_SCALE: 1.8,
  STEP_SIZE: 0.1,
};

export const imageQuality = 0.8; // 80%

export const maxPictureSizes = {
  WIDTH: 1000,
  HEIGHT: 1000,
};

export const CARD_DEFAULT_EXPIRY_MONTH = 1;
export const CARD_DEFAULT_EXPIRY_YEAR = 2000;

export const preferredPronouns: IUserPronoun[] = [
  { value: PreferredPronounEnum.HE_HIM_HIS, label: defineMessage('He/him/his') },
  { value: PreferredPronounEnum.SHE_HER_HERS, label: defineMessage('She/her/hers') },
  { value: PreferredPronounEnum.THEY_THEM_THEIRS, label: defineMessage('They/them/theirs') },
];

export const hotelAmenitiesPreferences: ILabelValuePair[] = [
  { value: HotelPrefAmenity.Parking, label: defineMessage('Parking') },
  { value: HotelPrefAmenity.FreeParking, label: defineMessage('Free Parking') },
  { value: HotelPrefAmenity.FreeBreakfast, label: defineMessage('Free Breakfast') },
  { value: HotelPrefAmenity.Pool, label: defineMessage('Pool') },
  { value: HotelPrefAmenity.Wifi, label: defineMessage('Wifi') },
  { value: HotelPrefAmenity.FitnessCenter, label: defineMessage('Fitness center') },
  { value: HotelPrefAmenity.FamilyFriendly, label: defineMessage('Family friendly') },
  { value: HotelPrefAmenity.Reception, label: defineMessage('Reception') },
  { value: HotelPrefAmenity.Spa, label: defineMessage('Spa') },
  { value: HotelPrefAmenity.Restaurant, label: defineMessage('Restaurant') },
  { value: HotelPrefAmenity.Bar, label: defineMessage('Bar') },
  { value: HotelPrefAmenity.Transportation, label: defineMessage('Transportation') },
  { value: HotelPrefAmenity.PetFriendly, label: defineMessage('Pet friendly') },
  { value: HotelPrefAmenity.BusinessCenter, label: defineMessage('Business center') },
  { value: HotelPrefAmenity.AirConditioning, label: defineMessage('Air conditioning') },
  { value: HotelPrefAmenity.BeachAccess, label: defineMessage('Beach access') },
  { value: HotelPrefAmenity.LaundryServices, label: defineMessage('Laundry services') },
  { value: HotelPrefAmenity.RoomService, label: defineMessage('Room service') },
  { value: HotelPrefAmenity.Accessible, label: defineMessage('Accessible') },
];

export const railSeatPreferencesSeatType: ILabelValuePair[] = [
  { value: SeatPrefType.SleeperBed, label: defineMessage('Sleeper bed') },
  { value: SeatPrefType.Normal, label: defineMessage('Normal') },
  { value: SeatPrefType.TableSeat, label: defineMessage('Table seat') },
];

export const railSeatPreferencesSeatLocation: ILabelValuePair[] = [
  { value: SeatPrefLocation.Aisle, label: defineMessage('Aisle') },
  { value: SeatPrefLocation.Window, label: defineMessage('Window') },
  { value: SeatPrefLocation.Solo, label: defineMessage('Solo (For first class only)') },
];

export const railSeatPreferencesDeckLevels: ILabelValuePair[] = [
  { value: DeckLevel.LowerDeck, label: defineMessage('Lower deck') },
  { value: DeckLevel.UpperDeck, label: defineMessage('Upper deck') },
];

export const railSeatPreferencesSeatDirection: ILabelValuePair[] = [
  { value: SeatPrefDirection.Forward, label: defineMessage('Forward') },
  { value: SeatPrefDirection.Backward, label: defineMessage('Backward') },
];

export const railTravelClassOptions: ILabelValuePair[] = [
  { value: RailTravelClass.Standard, label: defineMessage('Standard / Coach') },
  { value: RailTravelClass.Business, label: defineMessage('Business') },
  { value: RailTravelClass.First, label: defineMessage('First class') },
  { value: RailTravelClass.Room, label: defineMessage('Room') },
  { value: RailTravelClass.Executive, label: defineMessage('Executive') },
];

export const railTravelCoachPreferences: ILabelValuePair[] = [
  { value: CoachPref.Smoking, label: defineMessage('Smoking') },
  { value: CoachPref.NonSmoking, label: defineMessage('Non-smoking') },
  { value: CoachPref.PetsAllowed, label: defineMessage('Pets allowed') },
  { value: CoachPref.Restaurant, label: defineMessage('Restaurant') },
  { value: CoachPref.Quiet, label: defineMessage('Quiet') },
];

export const roomPreferencesBedCountTypes: ILabelValuePair[] = [
  { label: defineMessage('1 Bed'), value: RoomPreferenceBedCountTypeEnum.OneBed },
  { label: defineMessage('2 Beds'), value: RoomPreferenceBedCountTypeEnum.TwoBeds },
  { label: defineMessage('No preference'), value: '' },
];

export const roomPreferencesRoomLocations: ILabelValuePair[] = [
  { label: defineMessage('High floor'), value: RoomPreferenceRoomLocationEnum.HighFloor },
  { label: defineMessage('Low floor'), value: RoomPreferenceRoomLocationEnum.LowFloor },
  { label: defineMessage('Near elevator'), value: RoomPreferenceRoomLocationEnum.NearElevator },
  { label: defineMessage('No preference'), value: '' },
];

export const roomPreferencesPillowTypes: ILabelValuePair[] = [
  { label: defineMessage('Foam pillows'), value: RoomPreferencePillowTypeEnum.Foam },
  { label: defineMessage('Extra foam pillows'), value: RoomPreferencePillowTypeEnum.ExtraFoam },
  { label: defineMessage('Extra feather pillows'), value: RoomPreferencePillowTypeEnum.ExtraFeather },
  { label: defineMessage('No preference'), value: '' },
];

export const roomPreferencesRoomAmenities: ILabelValuePair[] = [
  { label: defineMessage('Feather free room'), value: RoomPreferenceRoomAmenityPrefsEnum.FeatherFreeRoom },
  { label: defineMessage('Extra Towels'), value: RoomPreferenceRoomAmenityPrefsEnum.ExtraTowels },
  { label: defineMessage('Refrigerator'), value: RoomPreferenceRoomAmenityPrefsEnum.Refrigerator },
];

export const roomPreferencesRoomTypes: ILabelValuePair[] = [
  { label: defineMessage('Non smoking'), value: RoomPreferenceRoomTypeEnum.NonSmoking },
  { label: defineMessage('Smoking'), value: RoomPreferenceRoomTypeEnum.Smoking },
  { label: defineMessage('No preference'), value: '' },
];

export const roomPreferencesMostImportantFact: ILabelValuePair[] = [
  { label: defineMessage('Room type'), value: RoomPreferenceMostImportantFactEnum.RoomType },
  { label: defineMessage('Number of beds'), value: RoomPreferenceMostImportantFactEnum.BedCount },
  { label: defineMessage('Room location'), value: RoomPreferenceMostImportantFactEnum.RoomLocation },
  { label: defineMessage('No preference'), value: '' },
];

export const ConditionalRateOptions: ILabelValuePair[] = [
  { label: defineMessage('AAA/CAA'), value: ConditionalRate.Aaa },
  { label: defineMessage('Military'), value: ConditionalRate.Military },
  { label: defineMessage('Government'), value: ConditionalRate.Government },
];
