import dateUtil from '../dateUtil';
import hourMinuteString from '../hourMinuteString';

const getDurationString = (isoDurationStr: string): string => {
  const duration = dateUtil.duration(isoDurationStr);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return hourMinuteString(hours, minutes);
};

export default getDurationString;
