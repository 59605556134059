import type { OBTFeatureConfigObtFeatureTypeEnum } from '../../types/api/v2/obt/model/obtfeature-config';
import { OBTFeaturePreferencesPreferenceVisibilityEnum } from '../../types/api/v2/obt/model/obtfeature-preferences-preference';
import type { IOBTShopFeaturesConfig } from '../useOBTShopFeatures';
import { useOBTShopFeaturesForUserId } from '../useOBTShopFeaturesForUserId';

export type AirOBTShopFeaturesConfigMap = IOBTShopFeaturesConfig;
/**
 * @returns All the airSearchResults features for current air airSearchResults flow.
 */
export function useGetAirSearchResultsFeatures(userId: string): {
  isLoading: boolean;
  airSearchResultsFeatures: AirOBTShopFeaturesConfigMap;
} {
  const { isLoading, airOBTFeaturesConfig } = useOBTShopFeaturesForUserId(userId);
  return {
    isLoading,
    airSearchResultsFeatures: airOBTFeaturesConfig,
  };
}

export function getFeatureVisibility(
  featuresConfig: AirOBTShopFeaturesConfigMap | undefined,
  feature: OBTFeatureConfigObtFeatureTypeEnum,
) {
  if (!featuresConfig || Object.keys(featuresConfig).length === 0) {
    return false;
  }

  return featuresConfig[feature] === OBTFeaturePreferencesPreferenceVisibilityEnum.Enabled;
}

export function getFeatureHidden(
  featuresConfig: AirOBTShopFeaturesConfigMap | undefined,
  feature: OBTFeatureConfigObtFeatureTypeEnum,
) {
  if (!featuresConfig || Object.keys(featuresConfig).length === 0) {
    return false;
  }

  return featuresConfig[feature] === OBTFeaturePreferencesPreferenceVisibilityEnum.Hidden;
}
