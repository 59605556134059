/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const ThirdPartySource = {
  UnknownSource: 'UNKNOWN_SOURCE',
  Sabre: 'SABRE',
  TravelFusion: 'TRAVEL_FUSION',
  Avia: 'AVIA',
  Ndc: 'NDC',
  Trainline: 'TRAINLINE',
  AtpcoNdc: 'ATPCO_NDC',
  FarelogixNdc: 'FARELOGIX_NDC',
  Offline: 'OFFLINE',
  Connexus: 'CONNEXUS',
  Routehappy: 'ROUTEHAPPY',
  Southwest: 'SOUTHWEST',
} as const;

export type ThirdPartySource = (typeof ThirdPartySource)[keyof typeof ThirdPartySource];
