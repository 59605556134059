import { z } from 'zod';
import first from 'lodash/first';
import { defineMessage } from '../../translations/defineMessage';

const railSeatPreferencesSchema = z.array(
  z
    .object({
      seatPreferenceGroupId: z.string(),
      seatLocationTypeId: z.string(),
      seatSelections: z.array(
        z.object({
          coachNumber: z.string(),
          seatNumber: z.string(),
        }),
      ),
    })
    .superRefine((arg, ctx) => {
      const { seatPreferenceGroupId, seatLocationTypeId, seatSelections } = arg;

      if (seatLocationTypeId) {
        const seatSelection = first(seatSelections);

        if (!seatSelection?.coachNumber || !seatSelection?.seatNumber) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [seatPreferenceGroupId, 'sitNear'],
            message: defineMessage('Coach and seat numbers are required'),
          });
        }
      }
    }),
);

export const railSeatReservationAndPreferencesSchema = z.object({
  seatReservation: z.any(),
  seatPreferences: railSeatPreferencesSchema,
});
