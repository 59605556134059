import produce from 'immer';
import first from 'lodash/first';
import last from 'lodash/last';
import has from 'lodash/has';
import compact from 'lodash/compact';
import minBy from 'lodash/minBy';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import type {
  Journey,
  RailSearchResponse,
  Section,
  SectionAlternative,
} from '../types/api/v1/obt/rail/rail_search_response';
import { SectionAlternativeCategory } from '../types/api/v1/obt/rail/rail_search_response';
import type { RailAmenity, RailExchangeType, AppliedPromotion } from '../types/api/v1/obt/rail/rail_common';
import { TravelClass } from '../types/api/v1/obt/rail/rail_common';
import type {
  EURailFlexibility,
  IRailCo2EmissionPerTravelType,
  IRailListItemSummary,
  ISectionAlternative,
  ISectionAlternatives,
  ISectionTravelClassAlternatives,
  RailCard,
} from '../types';
import { RailTravelClassV1 } from '../types';
import { createRailSectionObjectArr, pushToRailSectionObjectArr } from '../transformers/rails';
import { MoneyUtil, getIsSplitTicket, titleCase, getIsGroupSave } from '../utils';
import { dateFormats, defaultCO2EmissionPerTravelClass, EU_RAIL_FLEXIBILITY_TYPES } from '../constants';
import { dateUtil } from '../date-utils';

export default class RailSearchResponseManager {
  constructor(readonly response: RailSearchResponse) {
    if (!response) {
      throw new Error('Invalid RailSearchResponse passed to RailSearchResponseManager');
    }
  }

  public GetSearchKey(): string {
    return this.response.searchKey;
  }

  public GetAllJourneys(): Journey[] {
    return this.response.journeys;
  }

  public GetJourney(journeyIdx: number): Journey {
    return this.response.journeys[journeyIdx];
  }

  public GetInventorySources(): string[] {
    const inventorySources = this.response.journeys
      .flatMap((journey) => journey.legs)
      .flatMap((leg) => leg.originInfo?.sourceRefInfos ?? [])
      .map((source) => source.inventoryName.toUpperCase());

    return Array.from(new Set(inventorySources));
  }

  /** Get outward journey summary when selecting for inward journey */
  public GetOutwardJourneySummary(): IRailListItemSummary | undefined {
    const journey = this.response.outwardJourneySummary?.journey;
    if (!journey) {
      return undefined;
    }
    return RailSearchResponseManager.GetJourneySummary(journey);
  }

  static GetAlternativeParsedPrices(alternative: SectionAlternative): {
    totalPrice: MoneyUtil | undefined;
    discountedPrice: MoneyUtil | undefined;
  } {
    const totalPrice = alternative.totalPrice ? MoneyUtil.parse(alternative.totalPrice) : undefined;
    const discountedPrice = alternative.discountedPrice ? MoneyUtil.parse(alternative.discountedPrice) : totalPrice;

    return { totalPrice, discountedPrice };
  }

  static GetAlternativeWithPrices(alternative: SectionAlternative): ISectionAlternative {
    return {
      ...alternative,
      ...RailSearchResponseManager.GetAlternativeParsedPrices(alternative),
    };
  }

  /**
   * Get outward journey alternative when selecting for inward journey
   *
   * @deprecated
   */
  public GetOutwardJourneyAlternative(): ISectionAlternative | undefined {
    const alternative = first(first(this.response.outwardJourneySummary?.journey?.sections)?.alternatives);
    if (!alternative) {
      return undefined;
    }
    const totalPrice = MoneyUtil.parse(alternative.totalPrice);
    const discountedPrice = MoneyUtil.parse(alternative.discountedPrice);
    return { ...alternative, totalPrice, discountedPrice };
  }

  public GetOutwardJourneyAlternatives(): ISectionAlternative[] | null {
    const sections = this.response.outwardJourneySummary?.journey?.sections;
    if (!sections) {
      return null;
    }

    return sections.flatMap((section) => section.alternatives.map(RailSearchResponseManager.GetAlternativeWithPrices));
  }

  /**
   * @deprecated
   */
  private GetMinimumFareAlternativeForClass(
    classAlternative: ISectionAlternative | null,
    alternative: SectionAlternative,
  ): ISectionAlternative | null {
    const totalPrice = MoneyUtil.parse(alternative.totalPrice);
    const discountedPrice = MoneyUtil.parse(alternative.discountedPrice);
    const discountedPriceToShow = alternative.discountedPrice ? discountedPrice : totalPrice;

    if (
      alternative.totalPrice?.convertedAmount &&
      (!classAlternative ||
        (classAlternative.totalPrice &&
          alternative.totalPrice.convertedAmount < classAlternative.totalPrice.getAmount()))
    ) {
      /** discountPrice is not set incase of Amtrak */
      return { ...alternative, totalPrice, discountedPrice: discountedPriceToShow };
    }
    return classAlternative;
  }

  /**
   * @deprecated
   */
  public GetMinimumFareAlternativeV2(journeyIdx: number): Record<TravelClass, ISectionAlternative | null> {
    const alternatives = first(this.response.journeys[journeyIdx].sections)?.alternatives;

    const minimumFareAlternatives: Record<TravelClass, ISectionAlternative | null> = {
      [TravelClass.FIRST]: null,
      [TravelClass.STANDARD]: null,
      [TravelClass.BUSINESS]: null,
      [TravelClass.COACH]: null,
      [TravelClass.UNRECOGNIZED]: null,
      [TravelClass.UNKNOWN]: null,
      [TravelClass.BUSINESS_PREMIUM]: null,
      [TravelClass.SLEEPER]: null,
      [TravelClass.STANDARD_PREMIUM]: null,
      [TravelClass.ROOM]: null,
      [TravelClass.EXECUTIVE]: null,
    };

    if (alternatives?.length === 0) {
      return minimumFareAlternatives;
    }

    alternatives?.forEach((alternative) => {
      const classAlternative = minimumFareAlternatives[alternative.travelClass];
      minimumFareAlternatives[alternative.travelClass] = this.GetMinimumFareAlternativeForClass(
        classAlternative,
        alternative,
      );
    });
    return minimumFareAlternatives;
  }

  public GetListItemSummary(journeyIdx: number, includeTimezone?: boolean): IRailListItemSummary | null {
    const journey = this.GetJourney(journeyIdx);
    if (!journey) {
      return null;
    }
    return RailSearchResponseManager.GetJourneySummary(journey, includeTimezone);
  }

  /**
   * @deprecated
   */
  public GetMinimumFareAlternative(journeyIdx: number): (ISectionAlternative | null)[] {
    const alternatives = first(this.response.journeys[journeyIdx].sections)?.alternatives;

    if (alternatives?.length === 0) {
      return [];
    }

    let firstClassAlternative: ISectionAlternative | null = null;
    let standardAlternative: ISectionAlternative | null = null;

    alternatives?.forEach((alternative) => {
      const isFirstClass = alternative.travelClass === TravelClass.FIRST;
      const isStandard = alternative.travelClass === TravelClass.STANDARD;

      const totalPrice = MoneyUtil.parse(alternative.totalPrice);
      const discountedPrice = MoneyUtil.parse(alternative.discountedPrice);

      if (
        isFirstClass &&
        alternative.totalPrice?.convertedAmount &&
        (!firstClassAlternative ||
          (firstClassAlternative.totalPrice &&
            alternative.totalPrice.convertedAmount < firstClassAlternative.totalPrice.getAmount()))
      ) {
        firstClassAlternative = { ...alternative, totalPrice, discountedPrice };
      }

      if (
        isStandard &&
        alternative.totalPrice?.convertedAmount &&
        (!standardAlternative ||
          (standardAlternative.totalPrice &&
            alternative.totalPrice.convertedAmount < standardAlternative.totalPrice.getAmount()))
      ) {
        standardAlternative = { ...alternative, totalPrice, discountedPrice };
      }
    });

    return [standardAlternative, firstClassAlternative];
  }

  /**
   * @deprecated
   */
  public GetSectionAlternatives(journeyIdx: number): ISectionAlternatives {
    const alternatives = first(this.response.journeys[journeyIdx].sections)?.alternatives;
    const emptyFares: ISectionAlternatives = { singleFares: {}, returnFares: {} };

    if (!alternatives || alternatives?.length === 0) {
      return emptyFares;
    }

    return alternatives.reduce((allFares: ISectionAlternatives, alternative) => {
      const isSingleFare = alternative.category === SectionAlternativeCategory.SINGLE;
      const isReturnFare = alternative.category === SectionAlternativeCategory.RETURN;

      const sectionAlternative = RailSearchResponseManager.GetAlternativeWithPrices(alternative);

      /** Comparing case-insensitive route restrictions to handle cases where
       * BE sends "Avanti West Coast only" vs "Avanti West Coast Only".
       */
      const routeRestriction = titleCase(alternative.fares[0].routeRestriction);

      /** Define alternatives by type and route restriction */
      const key = `${alternative.type}%${routeRestriction}`;

      if (isSingleFare) {
        if (has(allFares.singleFares, key)) {
          return produce(allFares, (draft) => {
            draft.singleFares[key].push(sectionAlternative);
          });
        }
        return produce(allFares, (draft) => {
          draft.singleFares[key] = [sectionAlternative];
        });
      }
      if (isReturnFare) {
        if (has(allFares.returnFares, key)) {
          return produce(allFares, (draft) => {
            draft.returnFares[key].push(sectionAlternative);
          });
        }
        return produce(allFares, (draft) => {
          draft.returnFares[key] = [sectionAlternative];
        });
      }
      return allFares;
    }, emptyFares);
  }

  static GetAlternativeFinalPrice(alternative: SectionAlternative): MoneyUtil | null {
    const { totalPrice, discountedPrice } = RailSearchResponseManager.GetAlternativeParsedPrices(alternative);
    return discountedPrice ?? totalPrice ?? null;
  }

  static GetAlternativeWithPriceFinalPrice(alternative: ISectionAlternative): MoneyUtil | null {
    const { totalPrice, discountedPrice } = alternative;

    return discountedPrice ?? totalPrice ?? null;
  }

  static GetAlternativesWithPricesCombinedTotalPrice(alternatives: ISectionAlternative[]): MoneyUtil | null {
    return reduce(compact(alternatives.map((item) => item.totalPrice)), (res, curr) => res.add(curr)) ?? null;
  }

  static GetAlternativesWithPricesCombinedDiscountedPrice(alternatives: ISectionAlternative[]): MoneyUtil | null {
    return reduce(compact(alternatives.map((item) => item.discountedPrice)), (res, curr) => res.add(curr)) ?? null;
  }

  static GetAlternativesWithPricesCombinedFinalPrice(alternatives: ISectionAlternative[]): MoneyUtil | null {
    return (
      reduce(compact(alternatives.map(RailSearchResponseManager.GetAlternativeWithPriceFinalPrice)), (res, curr) =>
        res.add(curr),
      ) ?? null
    );
  }

  static GetAlternativeSplitTicketDiscount(alternatives: ISectionAlternative[]): MoneyUtil | null {
    /*
     * Split ticket discount is the price difference between the cheapest non-split alternative of the same category and the provided split alternative
     * Currently, the non-split price is being provided by the back-end.
     */
    const splitTicketAlternativeFinalPrice =
      RailSearchResponseManager.GetAlternativesWithPricesCombinedFinalPrice(alternatives);
    const splitTicketAlternativeWithoutDiscount = MoneyUtil.parse(first(alternatives)?.nonSplitPrice);

    if (
      !splitTicketAlternativeFinalPrice ||
      !splitTicketAlternativeWithoutDiscount ||
      splitTicketAlternativeFinalPrice.isZero() ||
      splitTicketAlternativeWithoutDiscount.isZero() ||
      splitTicketAlternativeWithoutDiscount.compare(splitTicketAlternativeFinalPrice) === -1
    ) {
      return null;
    }

    return splitTicketAlternativeWithoutDiscount.subtract(splitTicketAlternativeFinalPrice);
  }

  static GetAlternativeGroupSaveDiscount(alternatives: ISectionAlternative[]): MoneyUtil | null {
    /*
     * Group save discount is the price difference between the combined discount price and the combined total price.
     */
    const eligibleAlternatives = alternatives.filter((alternative) => getIsGroupSave(alternative));

    const groupSaveAlternativeFinalPrice =
      RailSearchResponseManager.GetAlternativesWithPricesCombinedDiscountedPrice(eligibleAlternatives);
    const groupSaveAlternativeWithoutDiscount =
      RailSearchResponseManager.GetAlternativesWithPricesCombinedTotalPrice(eligibleAlternatives);

    /*
     * Checks whether:
     * 1. Both prices are valid
     * 2. Both prices are not zero
     * 3. The price without discount is not greater than the price with discount
     */
    const isInvalidPriceCondition =
      !groupSaveAlternativeFinalPrice ||
      !groupSaveAlternativeWithoutDiscount ||
      groupSaveAlternativeFinalPrice.isZero() ||
      groupSaveAlternativeWithoutDiscount.isZero() ||
      groupSaveAlternativeWithoutDiscount.compare(groupSaveAlternativeFinalPrice) === -1;

    if (isInvalidPriceCondition) {
      return null;
    }

    return groupSaveAlternativeWithoutDiscount.subtract(groupSaveAlternativeFinalPrice);
  }

  static GetCheapestAlternativePrice(alternatives: SectionAlternative[]): MoneyUtil | null {
    const prices = compact(alternatives.map(RailSearchResponseManager.GetAlternativeFinalPrice));
    return minBy(prices, (price) => price.getAmount()) ?? null;
  }

  static GetSectionsCombinedCheapestPrice(sections: Section[]): MoneyUtil | null {
    if (sections.some((section) => section.alternatives.length === 0)) {
      /**
       * If any section from the list doesn't contain alternatives,
       * then the combined cheapest price of sections will not be valid
       * -> return `null`
       */
      return null;
    }

    const cheapestPriceForEachSection = compact(
      sections.map((section) => RailSearchResponseManager.GetCheapestAlternativePrice(section.alternatives)),
    );

    return reduce(cheapestPriceForEachSection, (res, curr) => res.add(curr)) ?? null;
  }

  /**
   * Each section will contain alternatives applicable to specified category, e.g. SINGlE or RETURN.
   *
   * @example
   * const sections = [
   *  {
   *    alternatives: [{ category: 'SINGLE' }, { category: 'RETURN' }],
   *  },
   *  {
   *    alternatives: [{ category: 'SINGLE' }, { category: 'RETURN' }],
   *  },
   * ];
   *
   * const sectionsGroupedByCategory = RailSearchResponseManager.GetSectionsGroupedByCategory(sections);
   *
   * // Will result in:
   * {
   * [SectionAlternativeCategory.UNRECOGNIZED]: [],
   [SectionAlternativeCategory.UNKNOWN_CATEGORY]: [],
   [SectionAlternativeCategory.SINGLE]: [
   { alternatives: [{ category: 'SINGLE' }] },
   { alternatives: [{ category: 'SINGLE' }] },
   ],
   [SectionAlternativeCategory.RETURN]: [
   { alternatives: [{ category: 'RETURN' }] },
   { alternatives: [{ category: 'RETURN' }] },
   ],
   * };
   */
  static GetSectionsGroupedByCategory(sections: Section[]): Record<SectionAlternativeCategory, Section[]> {
    const categoryToSectionsMap: Record<SectionAlternativeCategory, Section[]> = {
      [SectionAlternativeCategory.UNRECOGNIZED]: [],
      [SectionAlternativeCategory.UNKNOWN_CATEGORY]: [],
      [SectionAlternativeCategory.SINGLE]: [],
      [SectionAlternativeCategory.RETURN]: [],
    };

    Object.keys(categoryToSectionsMap).forEach((categoryRaw) => {
      const category = Number(categoryRaw) as SectionAlternativeCategory;

      sections.forEach((section) => {
        const sectionResult: Section = { ...section, alternatives: [] };

        section.alternatives.forEach((alternative) => {
          if (alternative.category === category) {
            sectionResult.alternatives.push(alternative);
          }
        });

        categoryToSectionsMap[category].push(sectionResult);
      });
    });

    return categoryToSectionsMap;
  }

  /**
   * Each section will contain alternatives that have the specified `travel
   * class` only - the same way as in `RailSearchResponseManager.GetSectionsGroupedByCategory()`
   */
  static GetSectionsGroupedByTravelClass(sections: Section[]): Record<RailTravelClassV1, Section[]> {
    const travelClassToSectionsMap: Record<RailTravelClassV1, Section[]> = {
      [RailTravelClassV1.UNKNOWN]: [],
      [RailTravelClassV1.FIRST]: [],
      [RailTravelClassV1.STANDARD]: [],
      [RailTravelClassV1.BUSINESS]: [],
      [RailTravelClassV1.SLEEPER]: [],
      [RailTravelClassV1.STANDARD_PREMIUM]: [],
      [RailTravelClassV1.BUSINESS_PREMIUM]: [],
      [RailTravelClassV1.COACH]: [],
      [RailTravelClassV1.ROOM]: [],
      [RailTravelClassV1.EXECUTIVE]: [],
      [RailTravelClassV1.UNRECOGNIZED]: [],
    };

    Object.keys(travelClassToSectionsMap).forEach((travelClassRaw) => {
      const travelClass = Number(travelClassRaw) as RailTravelClassV1;

      sections.forEach((section) => {
        const sectionResult: Section = { ...section, alternatives: [] };

        section.alternatives.forEach((alternative) => {
          if (alternative.travelClass === travelClass) {
            sectionResult.alternatives.push(alternative);
          }
        });

        travelClassToSectionsMap[travelClass].push(sectionResult);
      });
    });

    return travelClassToSectionsMap;
  }

  /**
   * Transform sections grouped by flexibility such that:
   * 1. If all sections of flexibility have no alternatives -> skip it, no changes made
   * 2. If only some sections of flexibility have no alternatives
   *    - Find matching section from higher flexibility and copy it's alternatives
   *    - Repeat until some alternatives are copied or the highest flexibility reached
   *
   * @param sectionsGroupedByFlexibility prepared object with Section instances grouped by EURailFlexibility
   */
  static GetCompactSectionsGroupedByFlexibility(
    sectionsGroupedByFlexibility: Record<EURailFlexibility, Section[]>,
  ): Record<EURailFlexibility, Section[]> {
    const EU_RAIL_FLEXIBILITY_TYPES_REVERSED = [...EU_RAIL_FLEXIBILITY_TYPES].reverse();

    const getHigherFlexibilitySection = (
      sectionsByFlexibility: Record<EURailFlexibility, Section[]>,
      currentFlexibility: EURailFlexibility,
      sectionIdx: number,
    ): Section | null => {
      const currentFlexibilityIdx = EU_RAIL_FLEXIBILITY_TYPES_REVERSED.findIndex(
        (flexType) => flexType === currentFlexibility,
      );
      if (currentFlexibilityIdx < 1) {
        /**
         * If such flexibility is not found or it's the highest possible one -> skip,
         * since there's nothing we can do
         */
        return null;
      }

      const higherFlexibilityIdx = currentFlexibilityIdx - 1;
      const higherFlexibility = EU_RAIL_FLEXIBILITY_TYPES_REVERSED[higherFlexibilityIdx];
      const higherFlexibilitySection = sectionsByFlexibility[higherFlexibility][sectionIdx];

      if (!higherFlexibilitySection || higherFlexibilitySection.alternatives.length < 1) {
        return getHigherFlexibilitySection(sectionsByFlexibility, higherFlexibility, sectionIdx);
      }

      return higherFlexibilitySection;
    };

    return produce(sectionsGroupedByFlexibility, (draft) => {
      EU_RAIL_FLEXIBILITY_TYPES_REVERSED.forEach((flexibility, flexibilityIdx) => {
        if (flexibilityIdx === 0) {
          /**
           * If it's highest possible flexibility -> skip
           */
          return;
        }

        const flexibilitySections = draft[flexibility];
        const flexibilitySectionIndices = flexibilitySections.map((_, sectionIdx) => sectionIdx);

        if (flexibilitySections.every((section) => section.alternatives.length < 1)) {
          /**
           * If every section of flexibility is empty, then we should not show it (flexibility) at all
           */
          return;
        }

        flexibilitySectionIndices.forEach((sectionIdx) => {
          const currentFlexibilitySection = flexibilitySections[sectionIdx];
          if (currentFlexibilitySection.alternatives.length > 0) {
            /**
             * If there are alternatives in a section, there's no need to copy
             * from higher flexibilities -> skip
             */
            return;
          }

          const higherFlexibilitySection = getHigherFlexibilitySection(draft, flexibility, sectionIdx);
          if (!higherFlexibilitySection) {
            /**
             * If not valid section is found from higher flexibilities -> skip
             */
            return;
          }

          currentFlexibilitySection.alternatives.push(...higherFlexibilitySection.alternatives);
        });
      });
    });
  }

  /**
   * Each map entry will contain sections with alternatives with the same `flexibility`,
   * the same way as in `RailSearchResponseManager.GetSectionsGroupedByCategory()`
   */
  static GetSectionsGroupedByFlexibility(sections: Section[]): Record<EURailFlexibility, Section[]> {
    /**
     * Initialize flexibility/sections map
     */
    const flexibilityToSectionsMap: Record<EURailFlexibility, Section[]> = {
      nonflexi: [],
      semiflexi: [],
      flexi: [],
    };
    Object.keys(flexibilityToSectionsMap).forEach((flexType) => {
      flexibilityToSectionsMap[flexType as EURailFlexibility] = sections.map((section) => ({
        ...section,
        alternatives: section.alternatives.filter((item) => item.flexibility === flexType),
      }));
    });

    return RailSearchResponseManager.GetCompactSectionsGroupedByFlexibility(flexibilityToSectionsMap);
  }

  static GetCheapestFaresGroupedByTravelClass(journey: Journey) {
    const travelClassToCheapestFareMap: Record<RailTravelClassV1, MoneyUtil | null> = {
      [RailTravelClassV1.UNKNOWN]: null,
      [RailTravelClassV1.FIRST]: null,
      [RailTravelClassV1.STANDARD]: null,
      [RailTravelClassV1.BUSINESS]: null,
      [RailTravelClassV1.SLEEPER]: null,
      [RailTravelClassV1.STANDARD_PREMIUM]: null,
      [RailTravelClassV1.BUSINESS_PREMIUM]: null,
      [RailTravelClassV1.COACH]: null,
      [RailTravelClassV1.ROOM]: null,
      [RailTravelClassV1.EXECUTIVE]: null,
      [RailTravelClassV1.UNRECOGNIZED]: null,
    };

    const sectionAlternativesByTravelClass = RailSearchResponseManager.GetSectionsGroupedByTravelClass(
      journey.sections,
    );

    Object.keys(travelClassToCheapestFareMap).forEach((travelClassRaw) => {
      const travelClass = Number(travelClassRaw) as RailTravelClassV1;

      const travelClassSections = sectionAlternativesByTravelClass[travelClass];

      let cheapestFromGroups: MoneyUtil | undefined;
      const travelClassSectionsGroupingType = RailSearchResponseManager.GetSectionsGroupingType(travelClassSections);
      if (travelClassSectionsGroupingType === 'type') {
        const sectionsAlternativesGroupedByType =
          RailSearchResponseManager.GetSectionsAlternativesWithPricesGroupedByType(travelClassSections);
        /*
         * Temporary fix. The rest of the code doesn't match this approach.
         * The reason why we're getting the cheapest alternative out of all alternatives of the same type is because
         * there's currently no way of showing all of them without seemingly duplicated UI.
         * TODO: change this when we have a better way of showing all alternatives of the same type
         */
        const finalPricesByType = compact(
          Object.values(sectionsAlternativesGroupedByType).map((alternatives) =>
            minBy(
              alternatives.map(RailSearchResponseManager.GetAlternativeWithPriceFinalPrice),
              (price) => price?.getAmount() ?? 0,
            ),
          ),
        );

        cheapestFromGroups = minBy(finalPricesByType, (price) => price.getAmount());
      } else {
        const sectionsGroupedByFlexibility =
          RailSearchResponseManager.GetSectionsGroupedByFlexibility(travelClassSections);
        const cheapestByFlexibility = compact(
          Object.values(sectionsGroupedByFlexibility).map(RailSearchResponseManager.GetSectionsCombinedCheapestPrice),
        );
        cheapestFromGroups = minBy(cheapestByFlexibility, (price) => price.getAmount());
      }

      travelClassToCheapestFareMap[travelClass] = cheapestFromGroups ?? null;
    });

    return travelClassToCheapestFareMap;
  }

  static GetCheapestFareAcrossJourney(journey: Journey): MoneyUtil | null {
    const cheapestFaresGroupedByTravelClass = RailSearchResponseManager.GetCheapestFaresGroupedByTravelClass(journey);
    const allCheapestFares = compact(Object.values(cheapestFaresGroupedByTravelClass));

    return minBy(allCheapestFares, (fare) => fare.getAmount()) ?? null;
  }

  static GetCheapestFareAcrossAllJourneys(journeys: Journey[]): MoneyUtil | null {
    const journeysCheapestFares = compact(journeys.map(RailSearchResponseManager.GetCheapestFareAcrossJourney));

    return minBy(journeysCheapestFares, (fare) => fare.getAmount()) ?? null;
  }

  static GetSectionAlternativeKey(alternative: SectionAlternative): string {
    // Using titleCase to avoid 'only' vs `Only` being different results
    const routeRestriction = titleCase(first(alternative.fares)?.routeRestriction ?? '');

    return `${alternative.type}%${routeRestriction}`;
  }

  /**
   * Each map entry will contain sections with alternatives with the same `type`,
   * the same way as in `RailSearchResponseManager.GetSectionsGroupedByCategory()`
   */
  static GetSectionsAlternativesWithPricesGroupedByType(sections: Section[]): Record<string, ISectionAlternative[]> {
    const typeToAlternativesMap: Record<string, ISectionAlternative[]> = {};

    sections.forEach((section) => {
      section.alternatives.forEach((alternative) => {
        const alternativeKey = RailSearchResponseManager.GetSectionAlternativeKey(alternative);
        const alternativeWithPrices = RailSearchResponseManager.GetAlternativeWithPrices(alternative);

        if (has(typeToAlternativesMap, alternativeKey)) {
          typeToAlternativesMap[alternativeKey].push(alternativeWithPrices);
        } else {
          typeToAlternativesMap[alternativeKey] = [alternativeWithPrices];
        }
      });
    });

    return typeToAlternativesMap;
  }

  static GetAlternativesWithPricesSortedByFinalPriceAsc(alternatives: ISectionAlternative[]): ISectionAlternative[] {
    return sortBy(
      alternatives,
      (value) => RailSearchResponseManager.GetAlternativeWithPriceFinalPrice(value)?.getAmount() ?? 0,
    );
  }

  static GetSectionsWithPricesGroupedByFlexibility(
    sections: Section[],
  ): Record<EURailFlexibility, Array<ISectionAlternative[]>> {
    const sectionsGroupedByFlexibility = RailSearchResponseManager.GetSectionsGroupedByFlexibility(sections);

    const result: Record<EURailFlexibility, Array<ISectionAlternative[]>> = {
      nonflexi: [],
      semiflexi: [],
      flexi: [],
    };
    Object.entries(sectionsGroupedByFlexibility).forEach(([flexibility, flexibilitySections]) => {
      result[flexibility as EURailFlexibility] = flexibilitySections.map((section) => {
        const sectionAlternativesWithPrices = section.alternatives.map(
          RailSearchResponseManager.GetAlternativeWithPrices,
        );

        return RailSearchResponseManager.GetAlternativesWithPricesSortedByFinalPriceAsc(sectionAlternativesWithPrices);
      });
    });

    return result;
  }

  /**
   * Identify grouping type for the provided sections set:
   * 1. For EU Rail -> flexibility
   * 2. For UK/Amtrak -> type
   */
  static GetSectionsGroupingType(sections: Section[]): 'flexibility' | 'type' {
    const allAlternatives = reduce(sections, (res, curr) => res.concat(curr.alternatives), [] as SectionAlternative[]);
    const uniqueFlexibilityTypes = Array.from(new Set(allAlternatives.map((alternative) => alternative.flexibility)));
    const hasOnlyEURailFlexibilities = uniqueFlexibilityTypes.every((item) =>
      EU_RAIL_FLEXIBILITY_TYPES.includes(item as EURailFlexibility),
    );

    return hasOnlyEURailFlexibilities ? 'flexibility' : 'type';
  }

  /**
   * @deprecated
   */
  public GetSectionAlternativesWithTravelClass(journeyIdx: number): ISectionTravelClassAlternatives {
    const alternatives = first(this.response.journeys[journeyIdx].sections)?.alternatives;
    const emptyFares: ISectionTravelClassAlternatives = {
      standardClass: {
        singleFares: {},
        returnFares: {},
      },
      firstClass: {
        singleFares: {},
        returnFares: {},
      },
    };

    if (!alternatives || alternatives?.length === 0) {
      return emptyFares;
    }

    return alternatives.reduce((allFares: ISectionTravelClassAlternatives, alternative) => {
      const isSingleFare = alternative.category === SectionAlternativeCategory.SINGLE;
      const isStandardClass = alternative.travelClass === TravelClass.STANDARD;
      const className = isStandardClass ? 'standardClass' : 'firstClass';
      const categoryName = isSingleFare ? 'singleFares' : 'returnFares';

      /** For inward journey, totalPrice will be undefined */
      const totalPrice = alternative.totalPrice ? MoneyUtil.parse(alternative.totalPrice) : undefined;
      const discountedPrice = alternative.discountedPrice ? MoneyUtil.parse(alternative.discountedPrice) : undefined;
      const sectionAlternative = { ...alternative, totalPrice, discountedPrice };

      /** Comparing case-insensitive route restrictions to handle cases where
       * BE sends "Avanti West Coast only" vs "Avanti West Coast Only".
       */
      const routeRestriction = titleCase(alternative.fares[0].routeRestriction);

      /** Define alternatives by type and route restriction */
      const key = `${alternative.type}%${routeRestriction}`;

      if (has(allFares[className][categoryName], key)) {
        return pushToRailSectionObjectArr({
          sectionAlternative,
          allFares,
          className,
          categoryName,
          key,
        });
      }

      return createRailSectionObjectArr({
        allFares,
        sectionAlternative,
        className,
        categoryName,
        key,
      });
    }, emptyFares);
  }

  private static GetJourneySummary(journey: Journey, includeTimezone?: boolean): IRailListItemSummary {
    const { departAt, arriveAt, duration, legs } = journey;

    const departureAt = departAt?.iso8601;
    const arrivalAt = arriveAt?.iso8601;

    const originLeg = first(legs);
    const { origin, originInfo } = originLeg ?? {};
    const { cityName: originCityName, stateCode: originStateCode } = originInfo ?? {};

    let originText = origin ?? '';
    if (originCityName && originStateCode) {
      /**
       * In case `cityName` and `stateCode` are present -> use `City, State` as location text
       */
      originText = `${originCityName}, ${originStateCode}`;
    }

    const destinationLeg = last(legs);
    const { destination, destinationInfo } = destinationLeg ?? {};
    const { cityName: destinationCityName, stateCode: destinationStateCode } = destinationInfo ?? {};

    let destinationText = destination ?? '';
    if (destinationCityName && destinationStateCode) {
      /**
       * The same as for the origin text
       */
      destinationText = `${destinationCityName}, ${destinationStateCode}`;
    }

    return {
      // Stripping the timezone information to ensure the date and time are displayed as is on the UI.
      departureAt: departureAt && !includeTimezone ? dateUtil(departureAt, dateFormats.ISO).toISOString() : departureAt,
      arriveAt: arrivalAt && !includeTimezone ? dateUtil(arrivalAt, dateFormats.ISO).toISOString() : arrivalAt,
      duration: duration?.iso8601,
      origin: originText,
      originPlatform: legs[0]?.originPlatform,
      legs,
      destination: destinationText,
      destinationPlatform: legs[legs.length - 1]?.destinationPlatform,
    };
  }

  /**
   * @deprecated
   */
  public GetCheapestFare(): MoneyUtil | undefined {
    let cheapestFare: MoneyUtil | undefined;
    const { journeys } = this.response;

    journeys.forEach((journey) => {
      journey.sections.forEach((section) => {
        section.alternatives.forEach((alternative): void => {
          const price = MoneyUtil.parse(alternative.discountedPrice);
          if ((!cheapestFare && price) || (cheapestFare && price && price.getAmount() < cheapestFare.getAmount())) {
            cheapestFare = price;
          }
        });
      });
    });

    return cheapestFare;
  }

  public GetCheapestFareV2(): MoneyUtil | null {
    return RailSearchResponseManager.GetCheapestFareAcrossAllJourneys(this.response.journeys);
  }

  public GetCo2EmissionPerTravelClass(journeyIdx: number | null): IRailCo2EmissionPerTravelType {
    return produce(defaultCO2EmissionPerTravelClass, (draft) => {
      if (journeyIdx === null) {
        return draft;
      }

      const journey = this.GetJourney(journeyIdx);

      journey.sections.forEach((section) => {
        const { alternatives } = section;

        alternatives.forEach((alternative) => {
          const { travelClass, co2EmissionGramsPerPassenger } = alternative;
          const co2EmissionsInKg = co2EmissionGramsPerPassenger / 1000;

          draft[travelClass] = Math.round(co2EmissionsInKg * 10) / 10;
        });
      });

      return draft;
    });
  }

  static getTotalCo2ForLegSummary(legSummary: IRailListItemSummary): number {
    const co2EmissionValue = legSummary.legs.reduce((res, curr) => res + curr.co2EmissionGramsPerPassenger / 1000, 0);

    return Math.round(co2EmissionValue * 10) / 10;
  }

  getNavigationParams() {
    return this.response.navigationParams;
  }

  static GetAlternativeWithPricesAmenities(alternative: ISectionAlternative): RailAmenity[] {
    return alternative.amenities;
  }

  static GetAlternativesWithPricesAmenities(alternatives: ISectionAlternative[]): RailAmenity[] {
    return uniqBy(
      alternatives.flatMap(RailSearchResponseManager.GetAlternativeWithPricesAmenities),
      /**
       * Ideally, we should filter by `type`, but in that case
       * some of the amenities with similar types (UNKNOWN) will get lost
       */
      (item) => item.name,
    );
  }

  static CheckAlternativesForSplitTickets(alternatives: SectionAlternative[]): boolean {
    /*
     * The function checks if the cheapest alternative is a split ticketed alternative
     * If it is, then we need to show the split ticketed label
     * If it isn't - there is no point in showing the label because the user can't save money by buying a split ticket
     */
    const splitTicketedAlternatives = alternatives.filter((alternative) =>
      getIsSplitTicket(alternative.fareComposition),
    );
    const cheapestSplitTicketedAlternative =
      RailSearchResponseManager.GetCheapestAlternativePrice(splitTicketedAlternatives);
    const cheapestAlternative = RailSearchResponseManager.GetCheapestAlternativePrice(alternatives);
    if (!cheapestSplitTicketedAlternative || !cheapestAlternative) return false;

    return cheapestSplitTicketedAlternative?.getAmount() === cheapestAlternative?.getAmount();
  }

  static CheckAlternativesForSplitTicketsByTravelClass(journey: Journey): Record<RailTravelClassV1, boolean> {
    const travelClassToIsSplitTicketMap: Record<RailTravelClassV1, boolean> = {
      [RailTravelClassV1.UNKNOWN]: false,
      [RailTravelClassV1.FIRST]: false,
      [RailTravelClassV1.STANDARD]: false,
      [RailTravelClassV1.BUSINESS]: false,
      [RailTravelClassV1.SLEEPER]: false,
      [RailTravelClassV1.STANDARD_PREMIUM]: false,
      [RailTravelClassV1.BUSINESS_PREMIUM]: false,
      [RailTravelClassV1.COACH]: false,
      [RailTravelClassV1.ROOM]: false,
      [RailTravelClassV1.EXECUTIVE]: false,
      [RailTravelClassV1.UNRECOGNIZED]: false,
    };

    const sectionAlternativesByTravelClass = RailSearchResponseManager.GetSectionsGroupedByTravelClass(
      journey.sections,
    );

    Object.keys(travelClassToIsSplitTicketMap).forEach((travelClassRaw) => {
      /*
       * We convert the travelClassRaw string to a number and then to a RailTravelClassV1
       * After getting the correctly formatted travel class, we can use it as a key to get the correct section alternatives
       * We then check if any of the alternatives in the section are split ticketed and the cheapest available option
       * Then we just set the travelClassToIsSplitTicketMap to true or false depending on the result
       */
      const travelClass = Number(travelClassRaw) as RailTravelClassV1;
      const travelClassSections = sectionAlternativesByTravelClass[travelClass];
      travelClassToIsSplitTicketMap[travelClass] = travelClassSections.some((section) =>
        RailSearchResponseManager.CheckAlternativesForSplitTickets(section.alternatives),
      );
    });

    return travelClassToIsSplitTicketMap;
  }

  static getAppliedPromotions = (selectedAlternatives: ISectionAlternative[]): AppliedPromotion[] => {
    return selectedAlternatives.flatMap((alternative) => alternative.fares.flatMap((fare) => fare.appliedPromotions));
  };

  public GetPreAppliedRailCards(): RailCard[] {
    const discountCards = this.response.journeys
      .flatMap((journey) =>
        journey.sections.flatMap((section) =>
          section.alternatives.flatMap((alternative) => alternative.fares).flatMap((fare) => fare.discountCards),
        ),
      )
      .filter(Boolean);
    return uniqBy(discountCards, (card) => card.name);
  }

  public GetExchangeType(): RailExchangeType | undefined {
    return this.response.metadata?.exchangeInfo?.exchangeType;
  }
}
