import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Concierge = ({ primaryColor, width, height, title }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 40 40", version: "1.1" },
        React.createElement("title", null, title),
        React.createElement("g", { id: "Concierge", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M38.6666667,29.3333333 L37.3333333,29.3333333 C37.356436,21.296418 31.8268823,14.308168 24,12.4826667 L24,12 C23.9890589,9.79540886 22.2045911,8.0109411 20,8 C17.790861,8 16,9.790861 16,12 L16,12.4826667 C8.17311772,14.308168 2.64356399,21.296418 2.66666667,29.3333333 L1.33333333,29.3333333 C0.596953667,29.3333333 0,29.930287 0,30.6666667 C0,31.4030463 0.596953667,32 1.33333333,32 L38.6666667,32 C39.4030463,32 40,31.4030463 40,30.6666667 C40,29.930287 39.4030463,29.3333333 38.6666667,29.3333333 L38.6666667,29.3333333 Z M16.9986667,19.1706667 C14.4242366,20.0359832 12.3575421,21.9847758 11.3426667,24.504 C11.06324,25.1814885 10.2894145,25.5064706 9.61004641,25.2316453 C8.93067831,24.95682 8.6004554,24.1852164 8.87066667,23.504 C10.1785658,20.265594 12.8365201,17.7605804 16.1466667,16.6466667 C16.5992031,16.4944715 17.0985591,16.5952627 17.4566322,16.9110732 C17.8147053,17.2268836 17.9770955,17.7097342 17.8826322,18.1777398 C17.7881688,18.6457455 17.4512031,19.0278049 16.9986667,19.18 L16.9986667,19.1706667 Z", id: "Shape", fill: themeEnrichedPrimaryColor }))));
};
Concierge.defaultProps = Object.assign(Object.assign({}, defaultIconProps), { title: 'Concierge' });
export default Concierge;
