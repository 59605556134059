/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Car Extra Types.
 * @export
 * @enum {string}
 */

export const CarExtraType = {
  BicycleRack: 'BICYCLE_RACK',
  ChildSeatBooster: 'CHILD_SEAT_BOOSTER',
  ChildSeatInfant: 'CHILD_SEAT_INFANT',
  ChildSeatToddler: 'CHILD_SEAT_TODDLER',
  SatelliteRadio: 'SATELLITE_RADIO',
  LuggageRack: 'LUGGAGE_RACK',
  NavigationalSystem: 'NAVIGATIONAL_SYSTEM',
  SkiEquippedVehicle: 'SKI_EQUIPPED_VEHICLE',
  SnowChains: 'SNOW_CHAINS',
  WheelChairAccessRamp: 'WHEEL_CHAIR_ACCESS_RAMP',
} as const;

export type CarExtraType = (typeof CarExtraType)[keyof typeof CarExtraType];
