var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
const Plane = (_a) => {
    var { width, height, primaryColor, secondaryColor } = _a, restProps = __rest(_a, ["width", "height", "primaryColor", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 20 22", width: width, height: height, xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { d: "m7.785 19.104-.095-.095-.13-.034C3.493 17.91.5 14.25.5 9.9.5 4.714 4.75.5 10 .5s9.5 4.214 9.5 9.4c0 4.35-2.993 8.009-7.06 9.075l-.13.034-.095.095L10 21.296l-2.215-2.192Z", fill: "#fff", stroke: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "m7.173 5.751.041-.001h1.502c.305 0 .571.133.78.372l2.824 3.426h2.063c1.166 0 2.197.76 2.547 1.868l.02.07c.236.847-.398 1.68-1.301 1.703H4.976a2.498 2.498 0 0 1-2.424-1.892L2.02 9.17a.797.797 0 0 1 .777-.976h.825c.208 0 .407.084.554.232l1.138 1.123h2.554L6.606 6.664a.656.656 0 0 1 .567-.913Zm.55.99 1.359 3.104a.496.496 0 0 1-.455.694H5.271a.906.906 0 0 1-.574-.211l-.04-.035-1.121-1.108h-.489l.468 1.872a1.506 1.506 0 0 0 1.401 1.139l.06.001h10.66c.252-.006.42-.227.36-.442a1.681 1.681 0 0 0-1.613-1.216h-2.297a.496.496 0 0 1-.382-.18L8.74 6.764c-.019-.021-.02-.022-.024-.022h-.993Z", fill: themeEnrichedPrimaryColor })));
};
export default Plane;
