import Config from 'obt-common/utils/Config';
import { useEffect } from 'react';
import { injectHoneyTokenCode } from './injectHoneyTokenCode';

/**
 * Based on environment, this hook injects the honeytoken code once.
 * Naming has been made generic to avoid any discovery using react-dev-tools
 */
export function useSecureCode() {
  useEffect(() => {
    if (Config.VITE_ENVIRONMENT !== 'development') {
      injectHoneyTokenCode();
    }
  }, []);
}
