import { StorageKeys } from '../../types/storage';
import { fetchStorageValue } from '../../hooks/useStorage';
import { getFromMemoryCacheOrgId, saveInMemoryCacheOrgId } from './inMemoryUrlCache';
import { extractOrgIdFromUrl } from './extractOrgIdFromUrl';
import { setOrgId } from './setOrgId';

interface Params {
  orgIdFromUrl: string | null;
  orgIdFromStorage: string | null;
}

async function processOrgIds({ orgIdFromStorage, orgIdFromUrl }: Params): Promise<string | null | undefined> {
  // On every `getOrgId()` invoking app attempt to
  // save the orgId from the URL in memory
  if (!getFromMemoryCacheOrgId() && orgIdFromUrl) {
    saveInMemoryCacheOrgId(orgIdFromUrl);
  }

  // When the orgId is not saved in the storage
  // but was memoized from the URL, save it in the storage
  const initialorgIdFromUrl = getFromMemoryCacheOrgId();

  if (!orgIdFromStorage && initialorgIdFromUrl) {
    await setOrgId(initialorgIdFromUrl).catch(() => null);
  }

  return orgIdFromStorage || initialorgIdFromUrl;
}

export async function getOrgId(): Promise<string | undefined | null> {
  const orgIdFromUrl = extractOrgIdFromUrl();
  const orgIdFromStorage = await fetchStorageValue<string>(StorageKeys.ORG_ID).catch(() => null);
  return processOrgIds({ orgIdFromUrl, orgIdFromStorage });
}
