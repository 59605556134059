import { tryCatchExp } from '@spotnana/blocks/src/utils';
import {
  useIsLoggedIn,
  FeatureConfigNameEnum,
  useFetchCompanyBasicInfo,
  useLoggedInUser,
  usePartnerFeatures,
} from 'obt-common';
import { useMemo } from 'react';

export type HomeOrderConfigItem = {
  enabled: boolean;
  name: 'FLIGHTS' | 'HOTELS' | 'CARS' | 'RAILS' | 'OTHERS' | 'LIMO';
  order: number;
};
const empty: HomeOrderConfigItem[] = [];

export function useHomeOrder() {
  const { features, isLoading } = usePartnerFeatures();
  const { hideAir, hideHotel, hideCar, railBooking } = features;

  /**
   * obt-common useCompanyFeatures hook is not in sync with backend types, does not have support for string values
   * enhancing it is out of scope of this work. Once we have a proper hook, we can remove this api call.
   */
  const { data: loggedInUser } = useLoggedInUser();
  const {
    data: basicinfo,
    isLoading: isCompanyInfoLoading,
    isFetched,
  } = useFetchCompanyBasicInfo(loggedInUser?.userOrgId?.organizationId?.id, true, undefined);
  const isLoggedIn = useIsLoggedIn();

  const value = useMemo(
    () =>
      tryCatchExp(
        () => {
          const stringFlagValue = basicinfo?.features?.find(
            (f) => f.name === FeatureConfigNameEnum.ProductObtHomepageOrder,
          )?.value as string;

          return (
            JSON.parse(stringFlagValue) as {
              name: 'FLIGHTS' | 'HOTELS' | 'CARS' | 'RAILS' | 'OTHERS' | 'LIMO';
              order: number;
            }[]
          ).map((v) => ({
            ...v,
            enabled: (() => {
              /* istanbul ignore switch: sonar branch cov issue */
              switch (v.name) {
                /* istanbul ignore next: sonar branch cov issue */
                case 'FLIGHTS':
                  return !hideAir;
                /* istanbul ignore next: sonar branch cov issue */
                case 'HOTELS':
                  return !hideHotel;
                /* istanbul ignore next: sonar branch cov issue */
                case 'CARS':
                  return !hideCar;
                /* istanbul ignore next: sonar branch cov issue */
                case 'RAILS':
                  return railBooking;
                /* istanbul ignore next: sonar branch cov issue */
                default:
                  return true;
              }
            })(),
          }));
        },
        () => empty,
      ),
    [basicinfo?.features, hideAir, hideCar, hideHotel, railBooking],
  );

  /**
   * User should be logged in before we block the UI on the basis of company info
   */
  if (
    /* istanbul ignore next: instanbul cov issue, unit test for this case exists */
    isLoggedIn &&
    /**
     * Stay in loading state if isFetched is false, so that we don't have a blinking UI where:
     * page UI is visible => company info API call starts => page UI hidden => page UI visible again in few ms
     */
    (isCompanyInfoLoading || isLoading || !isFetched)
  ) {
    return 'NOT_FETCHED' as const;
  }
  return value;
}
