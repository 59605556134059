import type { AxiosError } from 'axios';
import type { ErrorParameter } from '../types/common';
import i18n from '../translations';
import { GenericErrors } from '../constants/internalAPIErrors';

type ParamsType = {
  errorCode: string;
  errorParameters?: ErrorParameter[];
};
export const getV2ApiErrorMessage = ({ errorCode, errorParameters }: ParamsType) => {
  const paramsObj = (errorParameters ?? []).reduce(
    (acc, param) => ({
      ...acc,
      [param.name]: param.value,
    }),
    {} as Record<string, string>,
  );
  return i18n.t(errorCode, { ns: 'BACKEND_ERRORS', ...paramsObj });
};

export const extractAxiosErrorDetailsForCodeTranslation = (axiosError: AxiosError) => {
  const errorResponseData = axiosError?.response?.data;
  const errorResponseHeaders = axiosError?.response?.headers;
  const errorDetail = errorResponseData?.errorMessages?.[0]?.errorDetail ?? '';

  const errorCode =
    errorResponseData?.errorMessages?.[0]?.errorCode ||
    errorResponseData?.errorCode ||
    errorResponseData?.code ||
    GenericErrors.MISSING_ERROR_CODE;
  const errorParameters = errorResponseData?.errorMessages?.[0]?.errorParameters;
  const errorResponseMessages = errorResponseData?.errorMessages;
  const debugToolLink = errorResponseData?.debugIdentifier || errorResponseHeaders?.['debug-tool-link'] || '';

  return {
    errorCode,
    errorParameters,
    errorResponseMessages,
    debugToolLink,
    errorDetail,
  };
};
