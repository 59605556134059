var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CarryOnBaggageV2 = (_a) => {
    var { primaryColor, width, height, secondaryColor } = _a, restProps = __rest(_a, ["primaryColor", "width", "height", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", height: height, width: width, viewBox: "0 0 24 24", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M0 0h24v24H0V0z", fill: "none" }),
        React.createElement("path", { d: "M20 7h-4V5l-2-2h-4L8 5v2H4c-1.1 0-2 .9-2 2v5c0 .75.4 1.38 1 1.73V19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-3.28c.59-.35 1-.99 1-1.72V9c0-1.1-.9-2-2-2zM10 5h4v2h-4V5zM4 9h16v5h-5v-3H9v3H4V9zm9 6h-2v-2h2v2zm6 4H5v-3h4v1h6v-1h4v3z" })));
};
CarryOnBaggageV2.defaultProps = defaultIconProps;
export default CarryOnBaggageV2;
