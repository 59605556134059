import getApplicationId from '../../../core/applicationId/getApplicationId';
import addRequestInterceptor from '../addRequestInterceptor';

function injectApplicationIdHeader() {
  return addRequestInterceptor({
    onFulfilled: async (config) => {
      const applicationId = await getApplicationId();

      if (applicationId) {
        config.headers['x-application-id'] = applicationId;
      }

      return config;
    },
  });
}

export default injectApplicationIdHeader;
