import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useUpdateUserAction } from 'obt-common';

import { Link } from '@spotnana/blocks/src/Link';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Button } from '@spotnana/blocks/src/Button';
import { CircularProgress } from '@spotnana/blocks/src/CircularProgress';

import { MARRIOTT_STATE_PARAM_VALUE } from 'src/components/ExternalAuthCodeProvider/constants';
import { showErrorNotification } from 'src/app/admin/shared/utils';
import { IncompleteUserActionName } from 'obt-common/types/userActions';
import { buttons_container, circular_progress_container, sign_in_link_text } from './BonvoyRedirectModal.styles';
import { BonvoyLoginModal } from './BonvoyLoginModal';

export const MARRIOTT_SIGN_UP_URL =
  'https://www.marriott.com/loyalty/createAccount/createAccountPage1.mi?enrollmentSourceCode=SPOT';

interface IBonvoyRedirectModalProps {
  userId: string;
}

export function BonvoyRedirectModal({ userId }: IBonvoyRedirectModalProps) {
  const [isSignInClicked, setIsSignInClicked] = useState(false);
  const { t: tt } = useTranslation('WEB');

  const redirectErrorMessage = tt('Could not redirect to Marriott sign-in page.');
  const { data, mutateAsync: updateUserActionRequest } = useUpdateUserAction(userId);
  const bonvoyLoginURL = useMemo(() => {
    try {
      if (data?.status === 'REDIRECT' && data?.redirectUrl) {
        const url = new URL(data?.redirectUrl);
        url.searchParams.set('state', MARRIOTT_STATE_PARAM_VALUE);
        return url.toString();
      }
    } catch (e) {
      setIsSignInClicked(false);
      showErrorNotification(redirectErrorMessage);
      console.error(e);
    }

    return null;
  }, [redirectErrorMessage, data]);

  useEffect(() => {
    if (bonvoyLoginURL) {
      setTimeout(() => {
        window.location.href = bonvoyLoginURL;
      }, 3000);
    }
  }, [bonvoyLoginURL]);

  const handleSignInClick = useCallback(async () => {
    setIsSignInClicked(true);

    try {
      let actionInput = 'login';

      if (window.location.origin) {
        // Pass the current origin as the redirect URL so Marriott know where to redirect back.
        // TODO: Should be improved handled by BE in future. Ref: ST-68181
        actionInput = `login@${window.location.origin}`;
      }
      await updateUserActionRequest({
        actionName: IncompleteUserActionName.MARRIOTT_BONVOY_LINK,
        input: actionInput,
      });
    } catch (e) {
      setIsSignInClicked(false);
      showErrorNotification(redirectErrorMessage);
      console.error(e);
    }
  }, [redirectErrorMessage, updateUserActionRequest]);

  if (isSignInClicked) {
    return (
      <BonvoyLoginModal title={tt('We are taking you to Marriott sign-in page now')}>
        <Typography variant="body2" kind="regular">
          {tt(
            'You are being redirected to Marriott to link with your Marriott Bonvoy account and get started with Business Access by Marriott Bonvoy.',
          )}
        </Typography>
        <div css={circular_progress_container}>
          <CircularProgress size={20} data-testid="redirect-loader-indicator" />
        </div>
        <div>
          {bonvoyLoginURL && (
            <Typography variant="body2" kind="regular" css={sign_in_link_text}>
              <Trans
                t={tt}
                i18nKey={tt("If you don't wish to wait, <a>click here</a>")}
                components={{ a: <Link href={bonvoyLoginURL} rel="noreferrer" /> }}
              />
            </Typography>
          )}
        </div>
      </BonvoyLoginModal>
    );
  }

  return (
    <BonvoyLoginModal
      title={tt('Sign in to link your Marriott account and begin')}
      footer={
        <div css={buttons_container}>
          <div>
            <Link target="_blank" href={MARRIOTT_SIGN_UP_URL} rel="noreferrer">
              <Button fullWidth variant="outline">
                {tt('Join & Register now')}
              </Button>
            </Link>
          </div>
          <div>
            <Button fullWidth variant="primary" onClick={handleSignInClick}>
              {tt('Sign in')}
            </Button>
          </div>
        </div>
      }
    >
      <Typography variant="body2" kind="regular">
        {tt('A Marriott Bonvoy account is required to proceed.')}
      </Typography>
    </BonvoyLoginModal>
  );
}
