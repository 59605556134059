import { useState, useLayoutEffect, useRef } from 'react';

import { onOrgIdChange } from './eventemitter';
import { removeOrgId } from './removeOrgId';
import { setOrgId } from './setOrgId';
import { getOrgId } from './getOrgId';

interface Return {
  orgId: string | undefined;
  setOrgId: (orgId: string) => Promise<void>;
  removeOrgId: () => Promise<void>;
  isOrgIdLoading: boolean;
}

export function useOrgId(): Return {
  const hasChangedRef = useRef(false);
  const [isOrgIdLoading, setIsOrgIdLoading] = useState(true);
  const [orgId, setLocalOrgId] = useState<string | undefined>();

  useLayoutEffect(() => {
    const unsubscribe = onOrgIdChange((newOrgId) => {
      setLocalOrgId(newOrgId);
      hasChangedRef.current = true;
    });
    return unsubscribe;
  }, []);

  useLayoutEffect(() => {
    getOrgId()
      .then((fetchedOrgId) => {
        if (!hasChangedRef.current) {
          // If onOrgIdChange is called before this promise callback,
          // don't overwrite local state.
          setLocalOrgId(fetchedOrgId || undefined);
        }
      })
      .finally(() => {
        setIsOrgIdLoading(false);
      });
  }, []);

  return {
    orgId,
    setOrgId,
    removeOrgId,
    isOrgIdLoading,
  };
}
