import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CheckedLugguage = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("rect", { strokeMiterlimit: "10", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x: "10.4", y: "10.01", width: "11.02", height: "14.95", rx: "2.64" }),
        React.createElement("rect", { strokeMiterlimit: "10", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x: "13.15", y: "5.82", width: "5.51", height: "2.87", transform: "translate(8.65 23.16) rotate(-90)" }),
        React.createElement("polyline", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", points: "13.42 25.5 13.42 24.99 18.39 24.99 18.39 25.5" }),
        React.createElement("line", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x1: "13.4", y1: "4.5", x2: "18.41", y2: "4.5" }),
        React.createElement("line", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x1: "14.01", y1: "13.45", x2: "14.01", y2: "21.53" }),
        React.createElement("line", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x1: "17.73", y1: "13.45", x2: "17.73", y2: "21.53" })));
};
CheckedLugguage.defaultProps = defaultIconProps;
export default CheckedLugguage;
