import dateUtil from './dateUtil';

const isSameOrBeforeWithTimezone = (
  date1: string,
  date2: string,
  format: string,
  timezone1: string,
  timezone2: string,
): boolean => {
  const isValid = Boolean(date1 && date2 && timezone1 && timezone2 && format);
  const date1InUTC = dateUtil.tz(date1, timezone1).utc();
  const date2InUTC = dateUtil.tz(date2, timezone2).utc();
  return isValid ? date1InUTC.diff(date2InUTC) <= 0 : false;
};

export default isSameOrBeforeWithTimezone;
