/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import type { IFlightSearchSegmentState, IAirSuggestion } from 'obt-common/types/air-segments';

export const embedParamsKeys = [
  'segments',
  'customFieldType',
  'customFieldValue',
  'hideHotelsExploreOther',
  'disableTripNameEditing',
  'showConcierge',
  'showCovidDisclaimer',
  'showRail',
  'showSherpa',
  'showCreditCards',
  'tripId',
  'bookingForUserId',
  'theme',
] as const;

export type EmbedParamsKeys = (typeof embedParamsKeys)[number];

type ValidateKeys<K, T extends [keyof T] extends [K] ? ([K] extends [keyof T] ? unknown : never) : never> = T;

export type EmbedParams = ValidateKeys<
  EmbedParamsKeys,
  {
    customFieldType?: string;
    customFieldValue?: string;
    hideHotelsExploreOther?: boolean;
    disableTripNameEditing?: boolean;
    showConcierge?: boolean;
    showCovidDisclaimer?: boolean;
    /**
     * Theme to be used in the embed mode, value is respected only if the feature is turned on for the user.
     */
    theme?: 'light' | 'dark';
    /** @deprecated */
    showRail?: boolean;
    showSherpa?: boolean;
    showCreditCards?: boolean;
    tripId?: string;
    bookingForUserId?: string;
    segments?: IFlightSearchSegmentState<IAirSuggestion>[];
  }
>;
