import { createContext, useContext } from 'react';
import { IAirlinesContext } from './types';

export const AirlinesContext = createContext<IAirlinesContext | null>(null);

export const useAirlinesContext = () => {
  const context = useContext(AirlinesContext);
  if (!context) {
    return { airlinesInfoCompleteMap: {}, airlinesNameMap: {}, airlinesSelectOptions: [] };
  }

  return context;
};
