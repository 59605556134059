import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import { Button } from '@spotnana/pixel-react/dist/Button';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { Ring } from 'obt-common';
import styled, { css, CSSObject } from 'styled-components';

const VerySmallLink = styled.a`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.label};
  cursor: pointer;
  text-decoration: underline;
`;

const VerySmallText = styled(Typography)(
  ({ theme }) =>
    css({
      fontSize: '10px',
      color: theme.colors.onSurface,
    }) as unknown as CSSObject,
);

interface IRefreshReminderProps {
  selectedRing: Ring;
  onConfirm: (proceed: boolean) => void;
}

const RefreshReminder = ({ selectedRing, onConfirm }: IRefreshReminderProps): JSX.Element => {
  const onChangeConfirm = (): void => {
    window.location.reload();
  };
  return (
    <Flex width="100%" height="100%" p={40} justifyContent="space-between">
      <Flex height={200} flexDirection="column" justifyContent="space-between">
        <Typography variation="small" lineHeight="20px" color="#687591">
          Your request to switch to {selectedRing.description} product version should have taken effect by now!
        </Typography>
        <VerySmallText lineHeight="16px">
          All features of the product version will be available only after refreshing this page. If you have other
          Spotnana tabs open in your browser window, please refresh them as well.
        </VerySmallText>
        <Flex justifyContent="space-between" alignItems="center">
          <Box width="325px">
            <Button variation="primary" p={15} fontSize="16px" block onClick={onChangeConfirm}>
              Refresh this page
            </Button>
          </Box>
          <VerySmallLink onClick={(): void => onConfirm(false)}>I have already refreshed</VerySmallLink>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RefreshReminder;
