import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Upcoming = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 24 25" },
        React.createElement("path", { d: "M14.878 4.875a.75.75 0 01.749.694l.002.055v.621h1.996c.986 0 1.795.762 1.87 1.73l.005.146v9.073c0 1.036-.84 1.875-1.875 1.875H6.375A1.875 1.875 0 014.5 17.194V8.121c0-1.036.84-1.875 1.875-1.875H8.37l.001-.622a.749.749 0 011.498-.054l.002.056-.001.62h4.259v-.62c0-.415.335-.75.75-.75zM8.37 7.745H6.375A.376.376 0 006 8.121v9.073c0 .207.168.375.375.375h11.25a.375.375 0 00.375-.375V8.121a.375.375 0 00-.375-.375H15.63l.001.703a.75.75 0 01-1.498.057l-.002-.056-.001-.705H9.869v.705a.75.75 0 01-1.498.055l-.002-.056v-.704zm4.956 2.78l.048.045 1.969 1.969c.025.025.05.053.07.081l-.07-.081a.815.815 0 01.129.173l.015.029c.01.02.02.041.027.063l.014.04c.007.02.012.04.016.06.004.014.006.029.009.043a.66.66 0 01.007.064l.002.04v.066l-.005.042.006-.09a.734.734 0 01-.015.15l-.012.049c-.004.016-.01.031-.015.047l-.02.054a.765.765 0 01-.113.181l-.001.002-.044.048-1.97 1.969a.749.749 0 01-1.104-1.013l.044-.048.688-.689H9.188a.75.75 0 01-.056-1.498l.056-.002h3.813l-.689-.689a.75.75 0 01-.044-1.013l.044-.047a.75.75 0 011.013-.044z", fill: themeEnrichedPrimaryColor })));
};
Upcoming.defaultProps = defaultIconProps;
export default Upcoming;
