import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TIconButtonProps } from '../IconButton';
import { useSelectConfig } from './SelectConfigContext';

type TClearButtonProps = Omit<TIconButtonProps, 'icon'>;

/**
 * Clear Button for Select
 */
export const ClearButton = React.forwardRef<HTMLButtonElement, TClearButtonProps>((props, ref) => {
  const { t: tt } = useTranslation('WEB');
  const { labelId } = useSelectConfig();
  /**
   * Hack for A11y, as IconButton does not accept a component prop due to configured types
   * In select, we need to render this button inside a button, which breaks HTML5 Semantics
   * So way-around is to render it using a div
   */
  const btnProps = {
    component: 'div',
    'aria-describedby': labelId,
  };

  return <IconButton tooltip={tt('Clear')} ref={ref} size="small" icon="CloseCross" {...props} {...btnProps} />;
});

ClearButton.displayName = 'ClearButton';
