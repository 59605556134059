import { defineMessage } from '../translations/defineMessage';
import { DocumentTypeV1 } from '../types/documents';

// 20 MB
export const MAX_DOCUMENT_UPLOAD_SIZE_IN_BYTES = 20 * 1024 * 1024;

export const uploadDocumentsCategoryToLabelMap: Record<DocumentTypeV1, string> = {
  [DocumentTypeV1.DOCUMENT_TYPE_UNKNOWN]: defineMessage('Unknown'),
  [DocumentTypeV1.BOARDING_PASS]: defineMessage('Boarding pass'),
  [DocumentTypeV1.INVOICE]: defineMessage('Invoice'),
  [DocumentTypeV1.VISA]: defineMessage('Visa'),
  [DocumentTypeV1.OTHERS]: defineMessage('Other'),
  [DocumentTypeV1.CONFIRMATION]: defineMessage('Confirmation'),
  [DocumentTypeV1.MISCELLANEOUS]: defineMessage('Miscellaneous'),
  [DocumentTypeV1.UNRECOGNIZED]: defineMessage('Unrecognized'),
};
