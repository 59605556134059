import { darken, rgba } from 'polished';
const primitiveColors = {
    primary: '#D33B47',
    primaryDark: '#D93D4A',
    primaryLight: '#D6515C',
    primaryPale: '#FCEBED',
    onPrimary: '#FFFFFF',
    secondary: '',
    secondaryDark: '',
    secondaryLight: '',
    secondaryPale: '',
    onSecondary: '#FFFFFF',
    error: '#DF4D0E',
    errorDark: '',
    errorLight: '',
    errorPale: '#FAE0D5',
    onError: '#FFFFFF',
};
export const getThemeColors = (primitiveOverrides = {}) => {
    const primitives = Object.assign(Object.assign({}, primitiveColors), primitiveOverrides);
    const colors = {
        brand: {
            secondary: '#0064D9',
            accent: '#DB7C00',
            muted: '#C6DAF7',
        },
        ui: {
            secondary: '#757575',
            tertiary: '#F1F1F1',
            quaternary: '#FFFFFF',
            disabled: '#DEDEDE',
            error: '#D0421B',
            success: '#138000',
        },
        bg: {
            secondary: '#F1F1F1',
            alabaster: '#F8F8F8',
            blueGray: '#F7F9FB',
            regular: '#FFFFFF',
            divider: 'rgba(235, 239, 243, 1)',
            dividerAlpha50: 'rgba(235, 239, 243, 0.5)',
            opaqueWhite6: 'rgba(255,255,255,0.6)',
            darkGray: '#687591',
            transparent: 'transparent',
            paleGray: '#F6F9FB',
            greyBlue: '#687591',
            lightBlueGrey: '#D7DFE8',
            lightBlueGrey2: '#d5deee',
            lightBlueGrey3: '#C4C4C4',
            grey20: '#C9CBCF',
            grey10: '#E4E5E7',
            get lightBlueGrey3Alpha27() {
                return rgba(colors.bg.lightBlueGrey3, 0.27);
            },
            lightGreen: 'rgba(11, 130, 84, 0.1)',
            lightGreen2: 'rgba(26, 159, 11, 0.06)',
            white: '#FFFFFF',
            dark: '#1d1d1f',
            infoBackground: '#E8EFFC',
            floralWhite: '#F9F8F6',
            lightYellow: 'rgba(251, 186, 15, 0.1)',
            magnolia: 'rgba(245, 243, 247, 0.4)',
            blueGrey2: '#838FA7',
            aquaHaze: '#F7F9FA',
            navyBlue: '#0E4A97',
            opaqueLightGray: 'rgba(104, 117, 145, 0.04)',
            lightGrey2: 'rgb(0,0,0,0.1)',
            lightGrey3: '#C4CED8',
            lightGrey4: '#546078',
            brightGrey: '#EEE',
            kashmirBlue: '#516CA5',
            black: '#000000',
            get blackAlpha03() {
                return rgba(colors.bg.black, 0.03);
            },
            get blackAlpha08() {
                return rgba(colors.bg.black, 0.08);
            },
            get blackAlpha20() {
                return rgba(colors.bg.black, 0.2);
            },
            get blackAlpha30() {
                return rgba(colors.bg.black, 0.3);
            },
            get blackAlpha50() {
                return rgba(colors.bg.black, 0.5);
            },
            blue: '#5991E4',
            neutral_N50: '#F5F7FA',
            neutral_N100: '#E6E9EF',
            fillRed: 'rgba(203, 51, 59, 0.05)',
            warningTan: '#FCF6EA',
            lightYellow2: '#FEF8E3',
            lightYellowInfo: '#F0B505',
            surfaceMenu: '#FFFFFF',
        },
        border: {
            regular: '#E4E6E8',
            secondary: '#F1F1F1',
            disabled: '#D7DFE8',
            cardActive: '#d5deee',
            transparent: 'transparent',
            outline: '#EBEFF3',
            regularHover: '#988f88',
            dark: '#1d1d1f',
            grey: '#EAECEE',
            greyBlue: '#687591',
            blueGrey: '#838fa7',
            lightBlueGray: '#B7C4D3',
            lightGray: '#F7F9FB',
            lightGray2: '#E5E7E9',
            white: '#FFFFFF',
            darkSeaGreen: '#0b8254',
            lightGrey: '#EDEFF1',
            darkGrey: '#838999',
            silver: '#C8C8C8',
            blockedGrey: '#AFB7C6',
            lightGrey2: 'rgb(0,0,0,0.1)',
            eucalyptus: '#248A65',
            neutral_N100: '#E6E9EF',
            warningTan: '#F2D497',
            infoBorder: '#C5D2F0',
            lightYellow: '#fbe89d',
            grey10: '#E4E5E7',
        },
        text: {
            secondary: '#757575',
            semiBold: '#1D1D1F',
            body: '#4d4d4d',
            bodyLight: '#2b2e2e',
            disabled: '#9C9C9C',
            white: '#FFFFFF',
            lightGray: '#FAFBFD',
            gray: '#CACCCD',
            grey30: '#8F9299',
            grey40: '#6F737B',
            darkGray: '#080808',
            lightRed: '#E72524',
            darkBlue: '#002F64',
            label: '#687591',
            black: '#000000',
            darkSeaGreen: '#0b8254',
            get darkSeaGreenAlpha10() {
                return rgba(colors.text.darkSeaGreen, 0.1);
            },
            blueGrey: '#838fa7',
            lightYellow: '#FBBA0F',
            darkGreen: '#01A88E',
            navyBlue: '#0E4A97',
            gold: '#ffb400',
            blockedGrey: '#AFB7C6',
            prussianBlue: '#003365',
            kashmirBlue: '#516CA5',
            lynch: '#677590',
            chineseSilver: '#CCCCCC',
            dark: '#2b2b2d',
            lightGrey: '#546078',
            lightBlueGray: '#D7DFE8',
            deepLightGray: '#898E9D',
            seaGreen: '#32916F',
            midGray: '#6C6C6D',
            eucalyptus: '#248A65',
            darkGrey: '#838999',
            blackcurrant: '#200E32',
            linkBlue: '#3376E0',
            success: '#208029',
            ceruleanBlue: '#2C5CC5',
            neutralN300: '#A3A9B5',
            newErrorRed: '#C24A0A',
            newGray50: '#4E5056',
            blueLink: '#3162C4',
            green50: '#01A88E',
        },
        get highlights() {
            return {
                primaryHighlight: darken(0.1, this.primary),
                primaryExtraHighlight: darken(0.2, this.primary),
                bgHighlight: darken(0.1, '#FFFFFF'),
            };
        },
        // App Background
        background: '#F7F9FB',
        // Primary colors
        primary: primitives.primary,
        primaryActive: primitives.primaryDark,
        primaryHover: primitives.primaryLight,
        primaryDisabled: primitives.primaryPale, // TODO: this is used for errors in some places
        primaryEmphasis: primitives.primaryDark, // TODO: need a better name for this, used for icons, dividers, logo backgrounds, some texts
        onPrimary: primitives.onPrimary,
        // Secondary Colors
        secondary: primitives.secondary,
        secondaryActive: primitives.secondaryDark,
        secondaryHover: primitives.secondaryLight,
        secondaryDisabled: primitives.secondaryPale,
        onSecondary: primitives.onSecondary,
        // Surface Colors
        surface: '#FFFFFF',
        onSurface: '#1D1D1F',
        surfaceBorder: '#E3E5E7',
        surfaceDividerLight: '#F3F2F1',
        surfaceDividerStrong: '#D7DFE8',
        surfaceShadow: 'rgba(202, 203, 221, 0.3)',
        // row colors (used in flight search results)
        rowBorder: '#D7DFE8',
        // input colors
        inputFocus: primitives.primaryLight,
        inputHighlight: '#F7F9FB',
        inputBorder: '#E4E6E8',
        inputHighlightBorder: '#1D1D1F',
        inputShadow: 'rgba(202, 203, 221, 0.3)',
        // misc colors
        shimmerFrom: '#F6F7F8',
        shimmerTo: '#E6E6E6',
        tooltipShadow: 'rgba(29, 29, 31, 0.15)',
        label: '#687591',
        mapDropShadow: '#687591',
        ceruleanBlue: '#2C5CC5',
        // Status colors
        error: primitives.error,
        errorBorder: primitives.errorPale,
        errorBackground: primitives.errorPale,
        onError: primitives.onError,
        success: '',
        onSuccess: '',
        warning: '',
        onWarning: '',
        info: '#e8effc',
        onInfo: '',
        // derivatives
        get primaryAlpha10() {
            return rgba(this.primary, 0.1);
        },
        get surfaceAlpha30() {
            return rgba(this.surface, 0.3);
        },
        get surfaceShadowAlpha15() {
            return rgba(this.surfaceShadow, 0.15);
        },
        get surfaceShadowAlpha25() {
            return rgba(this.surfaceShadow, 0.25);
        },
        get tooltipShadowAlpha08() {
            return rgba(this.tooltipShadow, 0.08);
        },
        // additional colors (temporary) map these to existing
        get primary2Alpha20() {
            return rgba(this.primaryEmphasis, 0.2);
        },
        surface2: '#FCFCFD',
        surfaceDivider2: '#EDEDED',
        surfaceDivider3: '#f0f3f6',
        onSurface2: '#717171',
        onSurface3: '#1F1F1F',
        surfaceShadow2: 'rgba(215, 216, 237, 0.15)',
        surfaceShadow3: 'rgba(79, 106, 167, 0.11)',
        seatRed: '#CB333B',
        seatRedDark: '#9E242B',
        // NEUTRALS
        neutralN100: '#E6E9EF',
        neutralN200: '#CED3DB',
        warningIconRed: '#C24A0A',
    };
    return colors;
};
