import difference from 'lodash/difference';

const TranslationNamespaces = {
  COMMON: 'COMMON',
  AUTH: 'AUTH',
  AGENT: 'AGENT',
  PROFILE: 'PROFILE',
  FLIGHTS: 'FLIGHTS',
  HOTELS: 'HOTELS',
  CARS: 'CARS',
  ADMIN: 'ADMIN',
  REPORTS_AND_ANALYTICS: 'REPORTS_AND_ANALYTICS',
  CONCIERGE: 'CONCIERGE',
  TRIPS: 'TRIPS',
  TRIP_DETAILS: 'TRIP_DETAILS',
  RAILS: 'RAILS',
  DUTY_OF_CARE: 'DUTY_OF_CARE',
  BOOKING_IMPORTER: 'BOOKING_IMPORTER',
  ERRORS: 'ERRORS',
  API_ERRORS: 'API_ERRORS',
  SUPPORT: 'SUPPORT',
  BACKEND_ERRORS: 'BACKEND_ERRORS',
  STENCIL: 'STENCIL',
  WEB: 'WEB',
  MOBILE: 'MOBILE',
};

export type TranslationNamespacesKey = keyof typeof TranslationNamespaces;

export const CommonTranslationNamespaces = difference(Object.keys(TranslationNamespaces), ['WEB', 'MOBILE', 'STENCIL']);

export const MobileTranslationNamespaces = ['MOBILE', ...CommonTranslationNamespaces];
export const StencilTranslationNamespaces = ['STENCIL', ...CommonTranslationNamespaces];
export const WebTranslationNamespaces = ['WEB', ...CommonTranslationNamespaces];

export type CustomResource = Partial<Record<TranslationNamespacesKey, Record<string, string>>>;
export type CustomResourceLoader = (langCode: string) => Promise<CustomResource>;

export default TranslationNamespaces;
