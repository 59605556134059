import MuiButton, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Avatar } from '../Avatar';
import { TTooltipProps, Tooltip } from '../Tooltip';
import { RemoveMuiProps } from '../types';

import { noop } from '../utils';
import { avatar_button } from './AvatarButton.styles';

type MuiButtonProps = RemoveMuiProps<ButtonProps, 'color'>;
export const AVATAR_BUTTON_ROOT = 'BlocksAvatarButton-root';

export type TAvatarButtonProps = {
  /** Tooltip text to display on hover (if needed)
   *  @default ""
   *  */
  tooltip?: string;
  /** Loading state of the button
   *  @default false
   *  */
  loading?: boolean;
  /**
   * Disabled state of the button
   * @default false
   * */
  disabled?: boolean;
  popperProps?: TTooltipProps['PopperProps'];
  avatarProps: React.ComponentProps<typeof Avatar>;
  adornment?: React.ReactNode;
} & MuiButtonProps;

export const AvatarButton = forwardRef<HTMLButtonElement, TAvatarButtonProps>(
  (
    {
      disabled,
      loading,
      type,
      className,
      tooltip = '',
      popperProps = {},
      avatarProps,
      onClick,
      adornment,
      ...variableProps
    }: TAvatarButtonProps,
    ref,
  ) => {
    const onClickHandler = disabled ? noop : onClick;
    return (
      <Tooltip
        variant="label"
        title={loading ? undefined : tooltip}
        PopperProps={{ disablePortal: true, popperOptions: { placement: 'top' }, ...popperProps }}
      >
        <MuiButton
          disableRipple
          onClick={onClickHandler}
          type={type}
          css={avatar_button}
          className={clsx(className, AVATAR_BUTTON_ROOT, (loading || disabled) && 'Mui-disabled')}
          color={undefined}
          aria-disabled={loading || disabled}
          ref={ref}
          {...variableProps}
        >
          <div className="BlocksAvatarButton-content">
            <Avatar {...avatarProps} />
            {adornment && <div className="BlocksAvatarButton-adornment">{adornment}</div>}
          </div>
        </MuiButton>
      </Tooltip>
    );
  },
);
