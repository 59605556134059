import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Arrow = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 11 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.41471 3.51319L6.42309 12.9719C6.42309 13.5373 5.9521 14 5.36467 14C4.78254 14 4.30626 13.5425 4.30626 12.9719L4.29787 3.50891L1.80839 5.92714C1.39561 6.32811 0.723517 6.32811 0.310735 5.92714C-0.102047 5.52617 -0.102047 4.87331 0.310735 4.47234L4.55279 0.356258C4.74641 0.138371 5.03284 2.48027e-07 5.35409 2.33985e-07C5.56726 2.24667e-07 5.76624 0.061351 5.93283 0.16714C5.9945 0.206172 6.05243 0.251684 6.10556 0.303296L10.3974 4.47234C10.8102 4.87331 10.8102 5.52617 10.3974 5.92714C9.98466 6.32811 9.31257 6.32811 8.89978 5.92714L6.41471 3.51319Z", fill: themeEnrichedPrimaryColor })));
};
Arrow.defaultProps = defaultIconProps;
export default Arrow;
