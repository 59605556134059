import { css } from '@emotion/react';
import { flex, margins, themed } from '@spotnana/blocks/src/utils/styling';
import { cornerRadii, paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const name_and_avatar_outer_container = css`
  ${flex.init}
  ${flex.column}
  ${flex.justify.center}
  overflow: hidden;
`;

export const name_and_avatar_container = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.small}
`;

export const input_label = css`
  font-size: 0.75rem;
  color: var(--text-label);
  cursor: pointer;

  ${paddings.xl.superTight}
  ${paddings.yb.superTight}
`;

export const subtext_container = css`
  ${flex.init}
  ${flex.align.center}
`;

export const company_line_container = css`
  ${flex.init}
  ${flex.align.center}
`;

export const company_logo_container = css`
  ${margins.xr.point25}
`;

export const company_name = css`
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const current_traveler_container = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.align.center}
    ${flex.justify.between}
    border: 1px solid ${palette.border.light};
    ${paddings.x.tight}
    ${paddings.y.tight}
    ${cornerRadii.outer}
    ${spacings.small}
  `,
);

export const name_labels = css`
  ${flex.init}
  ${spacings.small}
`;
