import { useAppBranding } from 'src/components/AppBrandingProvider/AppBrandingProvider';
import { useEmbedStore } from 'src/components/EmbedContext';
import PoweredBySpotnana from './PoweredBySpotnana';
import { powered_by_spotnana_container } from './PoweredBySpotnana.styles';

export default function IsTravelPoweredBySpotnana() {
  const { isEmbed } = useEmbedStore();
  const { poweredBySpotnana } = useAppBranding();

  return (
    <div css={powered_by_spotnana_container}>{isEmbed || poweredBySpotnana ? <PoweredBySpotnana /> : undefined}</div>
  );
}
