// Generated using yarn generate command
import { Theme, ComponentsOverrides, tooltipClasses } from '@mui/material';
import { css } from '@emotion/react';
import { getBlocksPaletteFromMui } from '../utils/general';

export const tooltip = css``;

type TooltipStyleOverrides = ComponentsOverrides<Theme>['MuiTooltip'];
export const muiStyleOverrides_Tooltip: TooltipStyleOverrides = {
  popper: ({ theme }) => ({
    backgroundColor: 'transparent', // todo Why background assigned previously ask Aviral
    borderRadius: '4px',
    zIndex: `${theme.zIndex.tooltip}`,
    // @NOTE: Remove this once Pixel is completely migrated
    // style from pixel conflicts with this since both are MUI Based
    // hence adding the same style with a higher score
    '&.Blocks-tooltip': {
      zIndex: `${theme.zIndex.tooltip}`,
    },
  }),
  arrow: ({ theme }) => ({
    color: getBlocksPaletteFromMui(theme).bg.tooltip,
  }),
  tooltip: ({ theme }) => ({
    borderRadius: '4px',
    backgroundColor: getBlocksPaletteFromMui(theme).bg.tooltip,
    boxShadow: getBlocksPaletteFromMui(theme).shadow.componentShadow,
    [`.${tooltipClasses.popper}[data-popper-placement*="bottom"] &`]: {
      margin: '8px 0',
    },
    [`.${tooltipClasses.popper}[data-popper-placement*="top"] &`]: {
      margin: '8px 0',
    },
    [`.${tooltipClasses.popper}[data-popper-placement*="right"] &`]: {
      margin: '0 8px',
    },
    [`.${tooltipClasses.popper}[data-popper-placement*="left"] &`]: {
      margin: '0 8px',
    },
  }),
};
