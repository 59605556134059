import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Revert = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 12 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.00033 3.33073V0.664062L2.66699 3.9974L6.00033 7.33073V4.66406C8.20699 4.66406 10.0003 6.4574 10.0003 8.66406C10.0003 10.8707 8.20699 12.6641 6.00033 12.6641C3.79366 12.6641 2.00033 10.8707 2.00033 8.66406H0.666992C0.666992 11.6107 3.05366 13.9974 6.00033 13.9974C8.94699 13.9974 11.3337 11.6107 11.3337 8.66406C11.3337 5.7174 8.94699 3.33073 6.00033 3.33073Z", fill: themeEnrichedPrimaryColor })));
};
Revert.defaultProps = defaultIconProps;
export default Revert;
