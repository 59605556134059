import { IncompleteUserAction } from 'obt-common/types/userActions';
import { generatePath } from 'react-router-dom';
import { ROUTE_PATHS } from 'src/routes';
import { parseParams } from 'obt-common/utils/urlUtils';
import { USER_ACTION_ROUTE } from '../../shared/constants';

/**
 * Returns the action ID for a user action.
 *
 * @param action - The incomplete user action
 * @returns The action ID.
 */
export const getActionId = (action: IncompleteUserAction) => action.actionName.toLowerCase();

/**
 * Returns the path for a user action.

 *
 * @param action - The incomplete user action.
 * @returns The path for the user action.
 */
export const getUserActionPath = (action: IncompleteUserAction) => {
  return generatePath(USER_ACTION_ROUTE, { actionId: getActionId(action) });
};

/**
 * Returns the redirect URL from the search query string.
 *
 * @param searchQueryString - The search query string.
 * @returns The redirect URL.
 */
export const getRedirectUrl = (searchQueryString: string) => {
  const { redirectUrl } = parseParams(searchQueryString ?? '') as { redirectUrl?: string };
  return redirectUrl ?? ROUTE_PATHS.HOME;
};

/**
 * Returns the display configuration for a user action.
 *
 * @param action - The incomplete user action.
 * @returns The display configuration.
 */
export const getDisplayConfig = (action: IncompleteUserAction) => {
  const { kvs } = action.displayConfig;
  return kvs.reduce((acc, { key, value }) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {} as Record<string, string>);
};
