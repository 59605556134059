let listeners: ((tmcId: string | undefined) => void)[] = [];

export const onTmcIdChange = (callback: (tmcId: string | undefined) => void) => {
  listeners.push(callback);

  return () => {
    listeners = listeners.filter((listener) => listener !== callback);
  };
};

export const emitTmcIdChange = (tmcId: string | undefined): void => {
  listeners.forEach((listener) => listener(tmcId));
};
