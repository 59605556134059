import { flex, themed } from '@spotnana/blocks/src/utils';
import { css } from '@emotion/react';

const container_width = 360;

export const content_container = themed(
  ({ palette }) => css`
    width: ${container_width}px;
    border-radius: ${palette.border.radiusRegular};
    overflow: hidden;
    background-color: ${palette.surface.base};
    border: solid 1px ${palette.border.medium};
    box-sizing: content-box;
  `,
);

export const popper_steps_container = css`
  ${flex.init}

  width: ${2 * container_width}px;

  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  will-change: transform;
  transform: translateX(calc(0px));
`;

export const popper_steps_container_step2 = css`
  transform: translateX(calc(-${container_width}px));
`;

export const popper_step_content = css`
  width: ${container_width}px;
`;
