import { breakpoints, useMediaQuery } from '@spotnana/blocks/src/utils';
import Image from '@spotnana/pixel-react/dist/Image';
import PixelTypography from '@spotnana/pixel-react/dist/Typography';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { useAppLogos } from 'src/components/AppBrandingProvider/AppBrandingProvider';
import { useTheme } from 'styled-components';
import { usePageConfig } from 'src/PageConfigProvider';
import IsTravelPoweredBySpotnana from 'src/v2-components/PoweredBySpotnana/IsTravelPoweredBySpotnana';
import { Link as BlocksLink } from '@spotnana/blocks/src/Link';
import { useFeatureFlag } from 'obt-common/feature-gating/hooks';
import { ROUTE_PATHS } from 'src/routes';
import useSupportWidget from '../../hooks/useSupportWidget';
import routesWithoutFooter from '../../routesWithoutFooter';
import {
  footer_text,
  footer_container,
  footer_left_section,
  footer_links,
  copyright_section,
} from './AppFooter.styles';

interface IProps {
  showPoweredBySpotnana: boolean;
  privacyLink: string;
  termsOfUseLink: string;
}

const CopyrightSection = () => {
  const currentYear = new Date().getFullYear();
  const { logoHeader } = useAppLogos();
  const theme = useTheme();
  const { t: tt } = useTranslation('WEB');

  return (
    <div css={copyright_section}>
      <Link to="/" title={tt('Go to Home page')} data-testid="footer-spotnana-icon" className="Logo">
        <Image
          containerStyles={{
            justifyContent: 'flex-start',
            display: 'inline-flex',
            padding: 12,
            paddingLeft: 0,
            verticalAlign: 'middle',
          }}
          fallback={
            <PixelTypography
              variation="h2"
              style={{ textShadow: `0px 1px 3px ${theme.colors.primary}, 0px -1px 3px ${theme.colors.primary}` }}
              color="#FFF"
            >
              S
            </PixelTypography>
          }
          style={{ maxWidth: 20, maxHeight: 20 }}
          src={logoHeader.imgSrc}
          alt={logoHeader.imgAlt}
          data-testid={`footer-${logoHeader.imgAlt}`}
        />
      </Link>
      <Typography variant="body2" color="secondary" css={footer_text}>
        <Trans t={tt} values={{ currentYear }}>
          Copyright © {{ currentYear }} Spotnana Technology Inc.
        </Trans>
      </Typography>
    </div>
  );
};

const LinkSection = ({ termsOfUseLink, privacyLink }: Omit<IProps, 'showPoweredBySpotnana'>) => {
  const isNewPrivacyNoticeEnabled = useFeatureFlag('FE_INFRA_PRIVACY_NOTICE');
  const { t: tt } = useTranslation('WEB');

  const linkProps = {
    component: Link,
    to: ROUTE_PATHS.PRIVACY_NOTICE,
  };

  return (
    <Typography variant="body2" kind="medium" css={footer_links}>
      {Boolean(termsOfUseLink) && (
        <BlocksLink
          variant="standard"
          href={termsOfUseLink}
          target="_blank"
          data-testid="footer-term-of-use"
          rel="noopener noreferrer"
        >
          {tt('Terms of Use')}
        </BlocksLink>
      )}

      {!isNewPrivacyNoticeEnabled && Boolean(privacyLink) && (
        <BlocksLink
          variant="standard"
          href={privacyLink}
          target="_blank"
          data-testid="footer-privacy-policy"
          rel="noopener noreferrer"
        >
          {tt('Privacy Policy')}
        </BlocksLink>
      )}
      {isNewPrivacyNoticeEnabled && <BlocksLink {...linkProps}>{tt('Privacy notice')}</BlocksLink>}
    </Typography>
  );
};

const Footer = ({ showPoweredBySpotnana, termsOfUseLink, privacyLink }: IProps) => {
  const { pathname } = useLocation();
  const isDesktop = useMediaQuery(breakpoints.from('desktop'));
  const { footerColor } = usePageConfig();

  const { hideChatWidget } = useSupportWidget();

  if (routesWithoutFooter.includes(pathname)) {
    hideChatWidget();
    return null;
  }

  return (
    <div css={footer_container} style={{ backgroundColor: footerColor }}>
      <div css={footer_left_section}>
        {!showPoweredBySpotnana && isDesktop && <CopyrightSection />}
        <LinkSection termsOfUseLink={termsOfUseLink} privacyLink={privacyLink} />
        {!showPoweredBySpotnana && !isDesktop && (
          <div>
            <CopyrightSection />
          </div>
        )}
      </div>
      {showPoweredBySpotnana && <IsTravelPoweredBySpotnana />}
    </div>
  );
};
export default Footer;
