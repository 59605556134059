import type { Dayjs } from 'dayjs';
import dateUtil from '../dateUtil';

const isFutureDate = (isoDateString: string | Dayjs, ignoreHours = false): boolean | undefined => {
  let date = dateUtil(isoDateString);

  if (ignoreHours) {
    date = date.hour(0).minute(0).second(0).millisecond(0);
  }

  if (!date.isValid()) return undefined;

  const today = dateUtil();

  return date.isAfter(today);
};

export default isFutureDate;
