import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MuiRadio, { RadioProps } from '@mui/material/Radio';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

import { useId } from 'react';
import { Typography } from '../Typography';
import type { RemoveMuiProps } from '../types';
import { clsx } from '../utils/classNames';
import { radio, radio_group, radio_label } from './Radio.styles';

type JustRadioProps = RemoveMuiProps<RadioProps>;

export type TRadioProps = {
  /**
   * Label text for the radio
   */
  label: string;
  /**
   * HTML value of radio
   */
  value: string;
  /**
   * Optional subtext
   */
  subtext?: string;
  /**
   * Inner radio props
   */
  radioProps?: Omit<JustRadioProps, 'value' | 'readOnly'>;
  /**
   * Small or Regular?
   */
  size?: 'small' | 'regular';

  /**
   * Renders it in readonly mode
   */
  readOnly?: boolean;
} & RemoveMuiProps<FormControlLabelProps, 'control' | 'disableTypography' | 'label'>;

type TPresentationalRadioProps = { label?: never; labelVariant?: never; size?: 'small' | 'regular' } & RemoveMuiProps<
  RadioProps,
  'size'
>;

export function Radio({ size = 'regular', ...props }: TRadioProps | TPresentationalRadioProps) {
  const labelId = useId();
  const sizeClassName = `size-${size}`;

  if (!('label' in props && typeof props.label === 'string')) {
    return <MuiRadio className={sizeClassName} css={radio} value={props.value} {...props} disableRipple />;
  }

  const { subtext, radioProps, value, disabled, label, readOnly = false, ...otherProps } = props;
  const descId = `${labelId}-description`;

  const isDisabled = disabled || readOnly;

  return (
    <FormControlLabel
      disableTypography
      className={`BlocksRadio-root ${sizeClassName}`}
      css={radio_label}
      control={
        <MuiRadio
          icon={isDisabled ? <CircleTwoToneIcon /> : <RadioButtonUncheckedIcon />}
          css={radio}
          value={value}
          {...radioProps}
          inputProps={{
            'aria-describedby': descId,
            'aria-labelledby': labelId,
            readOnly,
            ...(radioProps?.inputProps ?? {}),
          }}
          disableRipple
        />
      }
      disabled={isDisabled}
      label={
        <div className="Radio-Label">
          <Typography
            variant={size === 'small' ? 'body2' : 'body1'}
            as="span"
            className="BlocksRadio-label"
            id={labelId}
            color={disabled ? 'disabled' : 'primary'}
          >
            {label}
          </Typography>
          {subtext && (
            <Typography
              variant="body3"
              as="span"
              className="BlocksRadio-subtext"
              color={disabled ? 'disabled' : 'secondary'}
              id={descId}
            >
              {subtext}
            </Typography>
          )}
        </div>
      }
      {...otherProps}
    />
  );
}

export type TRadioGroupProps = {
  name: string;

  label: string;
  hideLabel?: boolean;

  block?: boolean;

  items: Omit<TRadioProps, 'size'>[];
  size?: TRadioProps['size'];
  defaultValue?: RadioGroupProps['defaultValue'];
  value?: RadioGroupProps['value'];
  onChange?: RadioGroupProps['onChange'];

  groupingDirection?: 'column' | 'row';
  disabled?: boolean;
  readOnly?: boolean;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
};

export function RadioGroup({
  name,
  size = 'regular',
  label,
  readOnly,
  hideLabel = false,
  block = false,
  items,
  defaultValue,
  value: groupValue,
  onChange,
  groupingDirection = 'column',
  disabled,
  labelPlacement = 'end',
}: TRadioGroupProps) {
  const radioLabelId = useId();

  return (
    <FormControl css={radio_group} className={clsx(`BlocksRadioGroup-root`, `size-${size}`, block && 'block')}>
      <FormLabel id={radioLabelId} className={clsx('BlocksRadioGroup-label', hideLabel && 'only-screenreader')}>
        <Typography variant={size === 'small' ? 'body2' : 'body1'} as="span" color={disabled ? 'disabled' : 'primary'}>
          {label}
        </Typography>
      </FormLabel>
      <MuiRadioGroup
        aria-labelledby={radioLabelId}
        defaultValue={defaultValue}
        name={name}
        value={groupValue}
        onChange={onChange}
        className={clsx('BlocksRadioGroup-controls', `grouping-${groupingDirection}`)}
      >
        {items.map(({ label: itemLabel, value, ...props }) => (
          <Radio
            key={itemLabel + value}
            label={itemLabel}
            value={value}
            size={size}
            disabled={disabled}
            readOnly={readOnly}
            labelPlacement={labelPlacement}
            {...props}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
