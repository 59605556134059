/* eslint-disable */
import { Employee } from '../../../v1/obt/common/employee';
import { UserOrgId } from '../../../v1/obt/common/user_org_id';
import { Relative } from '../../../v1/obt/common/relative';
import { Name } from '../../../v1/common/name';
import { AuthenticatedUserBasicInfo } from '../../../v1/auth/services';
import { LegalEntityId } from '../../../v1/obt/common/organization';
import { CreateTravelerRequest } from '../../../v1/obt/profile/create_traveler_request';
import { CreateTravelerResponse } from '../../../v1/obt/profile/create_traveler_response';
import { ReadTravelerRequest } from '../../../v1/obt/profile/read_traveler_request';
import { ReadTravelerResponse } from '../../../v1/obt/profile/read_traveler_response';
import { ReadTravelerBasicInfoResponse } from '../../../v1/obt/profile/read_traveler_basic_info_response';
import { FetchTravelerIdRequest } from '../../../v1/obt/profile/fetch_traveler_id_request';
import { FetchTravelerIdResponse } from '../../../v1/obt/profile/fetch_traveler_id_response';
import { GetUserIdsRequest, GetUserIdsResponse } from '../../../v1/obt/profile/get_user_ids';
import { SearchTravelerRequest } from '../../../v1/obt/profile/search_traveler_request';
import { SearchTravelerResponse } from '../../../v1/obt/profile/search_traveler_response';
import { UpdateTravelerRequest } from '../../../v1/obt/profile/update_traveler_request';
import { Empty } from '../../../google/protobuf/empty';
import {
  UploadTravelerRequest,
  ProcessHRFeedRequest,
  UploadHrFeedRequest,
} from '../../../v1/obt/profile/upload_traveler_request';
import { UploadTravelerResponse, UploadHRFeedResponse } from '../../../v1/obt/profile/upload_traveler_response';
import { CreateOrganizationRequest } from '../../../v1/obt/profile/create_organization_request';
import { CreateOrganizationResponse } from '../../../v1/obt/profile/create_organization_response';
import { CreateUpdateOrganizationRequest } from '../../../v1/obt/profile/create_update_organization_request';
import { CreateUpdateOrganizationResponse } from '../../../v1/obt/profile/create_update_organization_response';
import { FetchOrganizationRequest } from '../../../v1/obt/profile/fetch_organization_request';
import { FetchOrganizationResponse } from '../../../v1/obt/profile/fetch_organization_response';
import { UploadOrganizationRequest } from '../../../v1/obt/profile/upload_organization_request';
import { UploadOrganizationResponse } from '../../../v1/obt/profile/upload_organization_response';
import { AssignTravelArrangerRequest } from '../../../v1/obt/profile/role/add_remove_travel_arranger_request';
import {
  CardCreateRequest,
  CardCreateResponse,
  CardDeleteRequest,
  StripeWebhookRequest,
  CardConfirmRequest,
} from '../../../v1/obt/profile/payments/credit_cards_crud';
import { ChargeRequest, ChargeResponse } from '../../../v1/obt/profile/payments/charge';
import { DateTime } from '../../common/date_time';
import { Gender } from '../../common/gender';
import { PhoneNumber } from '../../common/phone_number';
import { Title } from '../common/title';
import { UserBasicInfo } from '../../../v1/obt/common/user_basic_info';

export interface CreateEmployeeRequest {
  employee?: Employee;
}

export interface CreateEmployeeResponse {
  userOrgId?: UserOrgId;
}

export interface UpdateEmployeeRequest {
  types: UpdateEmployeeRequestType[];
  employee?: Employee;
}

export interface ReadEmployeeRequest {
  userOrgId?: UserOrgId;
}

export interface ReadEmployeeResponse {
  employee: Employee;
}

export interface CreateRelativeRequest {
  relative?: Relative;
}

export interface CreateRelativeResponse {
  userOrgId?: UserOrgId;
}

export interface UpdateRelativeRequest {
  types: UpdateRelativeRequestType[];
  relative?: Relative;
}

export interface ReadRelativesRequest {
  userOrgId?: UserOrgId;
}

export interface ReadRelativesResponse {
  relatives: Relative[];
}

/** Create User with personal persona */
export interface CreatePersonalUserRequest {
  email: string;
  name?: Name;
  gender: Gender;
  dob?: DateTime;
  billingCurrency?: string;
  phoneNumbers: PhoneNumber[];
  title: Title;
  preferredLanguage: string;
}

export interface CreatePersonalUserResponse {
  userOrgId?: UserOrgId;
}

export interface DeleteUserRequest {
  userOrgId?: UserOrgId;
}

export interface ActivateUserRequest {
  userOrgId?: UserOrgId;
}

export interface AuthenticatedUserBasicInfoRequest {
  email: string;
}

export interface AuthenticatedUserBasicInfoResponse {
  authenticatedUserBasicInfo?: AuthenticatedUserBasicInfo;
}

export interface SelfSignUpRequest {
  email: string;
  legalEntityId?: LegalEntityId;
}

export interface SelfSignUpResponse {
  userOrgId?: UserOrgId;
}

export interface EnrollAirlineLoyaltyEligibilityRequest {
  searchId: string;
  rateOptionId: string;
  userOrgId?: UserOrgId;
}

export interface EnrollAirlineLoyaltyEligibilityResponse {
  /**
   * Returns true if the user is eligible to enroll
   *
   * @deprecated
   */
  isEligible: boolean;
  loyaltyProgramEnrollmentInfo: EnrollAirlineLoyaltyEligibilityResponseLoyaltyProgramEnrollmentInfo[];
}

/** Contains all the information required to sign up from the OBT. */
export interface EnrollAirlineLoyaltyEligibilityResponseSignUpInternally {
  /** Checks if we allow to sign up */
  isEligible: boolean;
}

/** Contains all the information required to sign up externally. */
export interface EnrollAirlineLoyaltyEligibilityResponseSignUpExternally {
  /** URL to sign up */
  link: string;
}

/** Contains information related to the Loyalty program of an airline */
export interface EnrollAirlineLoyaltyEligibilityResponseLoyaltyProgramEnrollmentInfo {
  /** Name of the airline */
  airline: string;
  /** Contains information required to sign up internally */
  signUpInternally?:
    | EnrollAirlineLoyaltyEligibilityResponseSignUpInternally
    | undefined;
  /** Contains information required to sign up externally */
  signUpExternally?: EnrollAirlineLoyaltyEligibilityResponseSignUpExternally | undefined;
}

export interface EnrollAirlineLoyaltyRequest {
  userOrgId?: UserOrgId;
  customerDetail?: EnrollAirlineLoyaltyRequestCustomerDetail;
  phoneContact?: EnrollAirlineLoyaltyRequestPhoneContact;
  address?: EnrollAirlineLoyaltyRequestAddress;
  email: string;
  airline: string;
  searchId: string;
}

export interface EnrollAirlineLoyaltyRequestAddress {
  postalCode: string;
  countryCode: string;
  cityName: string;
  houseNumber?: string;
  apartmentNumber?: string;
  streetName?: string;
  addressLine1: string;
  addressLine2?: string;
  stateProvince: string;
}

export interface EnrollAirlineLoyaltyRequestCustomerDetail {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  countryOfResidence: string;
  gender: string;
}

export interface EnrollAirlineLoyaltyRequestPhoneContact {
  phTypeCode: string;
  phNumber: string;
  phCountryCode: string;
}

export interface EnrollAirlineLoyaltyResponse {
  loyaltyAccountNumber: string;
}

export interface GetRelatedProfilesRequest {
  /** Employee profile userOrgId for which we need all his personal profiles. */
  userId: UserOrgId['userId'];
  organizationId?: UserOrgId['organizationId'];
}

export interface GetAllRelatedUserProfilesResponse {
  /** List of user basic infos belonging to user's different profiles (EMPLOYEE, PERSONAL and GUEST) */
  userBasicInfos: UserBasicInfo[];
}

export interface ProfileService {
  CreateTraveler(request: CreateTravelerRequest): Promise<CreateTravelerResponse>;

  ReadTraveler(request: ReadTravelerRequest): Promise<ReadTravelerResponse>;

  ReadTravelerBasicInfo(request: ReadTravelerRequest): Promise<ReadTravelerBasicInfoResponse>;

  /**
   *  This api is used to generate id token after authentication.
   *  Avoid using elsewhere in code
   */
  FetchTravelerId(request: FetchTravelerIdRequest): Promise<FetchTravelerIdResponse>;

  /**
   *  Bulk API to get ID for several users in a single request.
   */
  GetUserIds(request: GetUserIdsRequest): Promise<GetUserIdsResponse>;

  SearchTravelers(request: SearchTravelerRequest): Promise<SearchTravelerResponse>;

  UpdateTraveler(request: UpdateTravelerRequest): Promise<Empty>;

  UploadTraveler(request: UploadTravelerRequest): Promise<UploadTravelerResponse>;

  ProcessHrFeed(request: ProcessHRFeedRequest): Promise<Empty>;

  UploadHrFeed(request: UploadHrFeedRequest): Promise<UploadHRFeedResponse>;

  CreateOrganization(request: CreateOrganizationRequest): Promise<CreateOrganizationResponse>;

  UpdateOrganization(request: CreateUpdateOrganizationRequest): Promise<CreateUpdateOrganizationResponse>;

  FetchOrganization(request: FetchOrganizationRequest): Promise<FetchOrganizationResponse>;

  UploadOrganization(request: UploadOrganizationRequest): Promise<UploadOrganizationResponse>;

  AssignTravelArranger(request: AssignTravelArrangerRequest): Promise<Empty>;

  CreateCard(request: CardCreateRequest): Promise<CardCreateResponse>;

  DeleteCard(request: CardDeleteRequest): Promise<Empty>;

  HandleStripeWebhook(request: StripeWebhookRequest): Promise<Empty>;

  ConfirmCard(request: CardConfirmRequest): Promise<Empty>;

  ChargeCard(request: ChargeRequest): Promise<ChargeResponse>;

  CreateRelative(request: CreateRelativeRequest): Promise<CreateRelativeResponse>;

  UpdateRelative(request: UpdateRelativeRequest): Promise<Empty>;

  ReadRelatives(request: ReadRelativesRequest): Promise<ReadRelativesResponse>;

  CreateEmployee(request: CreateEmployeeRequest): Promise<CreateEmployeeResponse>;

  UpdateEmployee(request: UpdateEmployeeRequest): Promise<Empty>;

  ReadEmployee(request: ReadEmployeeRequest): Promise<ReadEmployeeResponse>;

  CreatePersonalUser(request: CreatePersonalUserRequest): Promise<CreatePersonalUserResponse>;

  DeleteUser(request: DeleteUserRequest): Promise<Empty>;

  ActivateUser(request: ActivateUserRequest): Promise<Empty>;

  GetAuthenticatedUserBasicInfo(
    request: AuthenticatedUserBasicInfoRequest,
  ): Promise<AuthenticatedUserBasicInfoResponse>;

  SelfSignUp(request: SelfSignUpRequest): Promise<SelfSignUpResponse>;
}

export enum UpdateEmployeeRequestTypeEnum {
  UNKNOWN_TYPE = 0,
  DESIGNATION = 1,
  NAME = 2,
  EMAIL = 3,
  PHONE_NUMBERS = 4,
  MANAGER_ID = 5,
  LEGAL_ENTITY_ID = 6,
  OFFICE_ID = 7,
  DEPARTMENT = 8,
  COST_CENTER = 9,
  GRADE = 10,
  TITLE = 11,
  CC_EMAILS = 12,
  TIER = 13,
  EMAIL_CONFIGURATION = 14,
  GENDER = 15,
  PERSONAL_EMAIL = 16,
  COUNTRY_CODE = 17,
  WORKER_TYPE = 18,
  ACCOUNTING_CODE = 19,
  EMPLOYEE_ID = 20,
  PREFERRED_LANGUAGE = 21,
  PREFERRED_PRONOUN = 22,
  UNRECOGNIZED = -1,
}

export type UpdateEmployeeRequestType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | -1;

export enum UpdateRelativeRequestTypeEnum {
  UNRECOGNIZED_TYPE = 0,
  NAME = 1,
  RELATION = 2,
  EMAIL = 3,
  UNRECOGNIZED = -1,
}

export type UpdateRelativeRequestType = 0 | 1 | 2 | 3 | -1;
