import React, { useMemo, useState } from 'react';
import intersectionBy from 'lodash/intersectionBy';
import differenceBy from 'lodash/differenceBy';
import { ComboboxPopoverProps } from '@ariakit/react';
import { AutocompleteSelectionModes, TAutocompleteOption } from '../Autocomplete/Autocomplete.types';
import { Autocomplete } from '../Autocomplete';
import { TSelectSlots } from '../SelectRoot';
import { getAsyncAutocompleteInput } from './AsyncAutocompleteInput';

// initialValueOptions is considered as an array of TValue now since we are only doing multi select with async.
// @aviral, we need to support TValue | TValue[] in the future for async autocomplete single

export interface IAsyncAutocompleteProps<TValue extends object> {
  /**
   * controlledValueOptions: This is the controlled value - In case we are rendering options which are prepopulated or doing something from the parent like a swap
   */
  controlledValueOptions?: TAutocompleteOption<TValue>[];
  /**
   * onChange: This sets the value in the parent component. Note: this is going to be values and not options
   */
  onChange: (newValue: TAutocompleteOption<TValue>[]) => void;
  /**
   * setSearchString: Sets search string in the parent
   */
  setSearchString?: (newSearchString: string) => void;
  /**
   * isLoading: To decide if loader has to be displayed
   */
  isLoading?: boolean;
  /**
   * availableOptions: Available options to the Autocomplete. initialValueOptions need not be a subset of this (because this is fetched from API)
   */
  availableOptions: TAutocompleteOption<TValue>[];
  /**
   * selectionMode: To pass relevant selected options back to parent. This can be ALL - All options, LEAF - Standalone and Children, ROOT - Standalone and Parents
   */
  selectionMode?: AutocompleteSelectionModes;
  /**
   * Slots: Parent controls for ItemPrefix, ItemSuffix, ItemDescription and Multivalue (input element)
   */
  slots?: TSelectSlots<TAutocompleteOption<TValue>>;
  /**
   * loadingContent: Component to be rendered in the dropdown when isLoading to be true
   */
  loadingContent?: React.ReactElement;
  /**
   * emptyContent: Component to be rendered in the dropdown when no options are found
   */
  emptyContent?: React.ReactElement | string;
  /**
   * OptionChip: Component to be rendered within a chip
   */
  OptionChip?: React.FC<{ option: TAutocompleteOption<TValue> }>;
  /**
   * placeholder: Placeholder text to be displayed in the Input
   */
  placeholder: string;
  /**
   * Content to be displayed. Candidate for slot. If not provided, just comma separated option.value would be displayed
   */
  UnfocusedInputContent?: React.FC<{ selectedOptions: TAutocompleteOption<TValue>[]; disabled: boolean }>;
  /**
   * disabled: If true, the input is disabled
   */
  disabled?: boolean;
  /**
   * errorMessage: To render error state
   */
  error?: boolean;
  /**
   * helperText: To render below the input
   */
  helperText?: string;
  /**
   * minimumSearchTextLength: The minimum character length required to fire a search
   */
  minimumSearchTextLength?: number;
  /**
   *  startAdornment: Start `InputAdornment` for this component.
   */
  startAdornment?: React.ReactElement;
  /**
   *  endAdornment: end `InputAdornment` for this component.
   */
  endAdornment?: React.ReactElement;
  /**
   * onCancel: Callback when cancel is clicked
   */
  onCancel?: () => void;
  /**
   * aria-label: Aria label for the Input component (Required for a11y)
   */
  'aria-label': string;
  /**
   * popoverProps: Props to be passed to the ComboboxPopover
   * @see https://ariakit.org/reference/combobox-popover
   */
  popoverProps?: ComboboxPopoverProps;
}

const RESTORE_FOCUS_DELAY = 200;
/**
 *
 * Though this component is called async autocomplete, the main purpose of this component is to store values that arent available in options
 *
 * onChange: When new option is selected. would return a subset of options based on selection mode.
 *
 * setSearchString: Just a passthrough for the context.
 */
export function AsyncAutocomplete<TValue extends object>({
  controlledValueOptions,
  onChange,
  setSearchString,
  isLoading,
  availableOptions,
  selectionMode = AutocompleteSelectionModes.LEAF, // This is set as leaf for now since thats the scenario we have completed
  slots,
  loadingContent,
  emptyContent,
  placeholder,
  OptionChip,
  UnfocusedInputContent,
  disabled,
  error = false,
  helperText,
  minimumSearchTextLength,
  startAdornment,
  endAdornment,
  onCancel,
  'aria-label': ariaLabel,
  popoverProps,
}: IAsyncAutocompleteProps<TValue>) {
  /**
   * This is a local state of the value options in case controlled Value Options is not needed
   */
  const [valueOptions, setValueOptions] = useState<TAutocompleteOption<TValue>[]>([]);
  const [visibleSearchText, setVisibleSearchText] = useState<string>('');

  const hasControlledInput = controlledValueOptions !== undefined;
  const selectedOptions = hasControlledInput ? controlledValueOptions : valueOptions;
  const comboboxRef = React.useRef<HTMLInputElement>(null);
  const firstOptionRef = React.useRef<HTMLDivElement>(null);

  /**
   * Why relevantSelectedOptions?
   * Async Autocomplete keeps tracks of selected options across multiple searches.
   * Autocomplete component has a requirement - value should be subset of available options
   * Say we search "LAX", select "LAX" and then search for "JFK"
   * Now available options wont have LAX, but our value has LAX.
   * So we pass only relevantSelectedOptions to the Autocomplete component, keeping it ignorant of all the options we have already selected
   */
  const relevantSelectedOptions = intersectionBy(selectedOptions, availableOptions, 'value');

  /**
   * This is the custom Multivalue slot that AsyncAutocomplete would use to render chips like MuiAutocomplete
   */
  const AutocompleteInput = useMemo(
    () =>
      getAsyncAutocompleteInput({
        selectedOptions,
        setSelectedOptions: onChange,
        visibleSearchText,
        setVisibleSearchText,
        OptionChip,
        UnfocusedInputContent,
        selectionMode,
        firstOptionRef,
        startAdornment,
        endAdornment,
        onCancel,
        comboboxRef,
      }),
    [
      onChange,
      selectedOptions,
      OptionChip,
      UnfocusedInputContent,
      selectionMode,
      visibleSearchText,
      setVisibleSearchText,
      firstOptionRef,
      startAdornment,
      endAdornment,
      onCancel,
      comboboxRef,
    ],
  );

  return (
    <Autocomplete
      aria-label={ariaLabel}
      selectionMode={selectionMode}
      options={availableOptions}
      value={relevantSelectedOptions}
      placeholder={placeholder}
      onChange={(newOptions) => {
        if (selectionMode === AutocompleteSelectionModes.SINGLE) {
          /**
           * This enforces the array of one element value format for single select
           */
          onChange([newOptions[0]]);
          setValueOptions([newOptions[0]]);
        } else {
          /**
           * Why are we keeping track of removed and added options?
           * Autocomplete Component is ignorant of all selected options across different searchText
           * For given available options, newOptions is always going to be a subset.
           * We need to check newOptions with relevantSelectedOptions and update selectedOptions accordingly
           */
          const optionsRemoved = differenceBy(relevantSelectedOptions, newOptions, 'value');
          const optionsAdded = differenceBy(newOptions, relevantSelectedOptions, 'value');
          const newSelectedOptions = [...differenceBy(selectedOptions, optionsRemoved, 'value'), ...optionsAdded];
          // Call onchange on parent and local state
          onChange(newSelectedOptions);
          setValueOptions(newSelectedOptions);
        }
        setVisibleSearchText('');

        // on option selection, restore focus back to the combo-box
        setTimeout(() => {
          comboboxRef.current?.focus();
        }, RESTORE_FOCUS_DELAY);
      }}
      setSearchString={setSearchString}
      isLoading={isLoading}
      loadingContent={loadingContent}
      emptyContent={emptyContent}
      slots={{ ...slots, MultiValue: AutocompleteInput }}
      error={error}
      helperText={helperText}
      disabled={disabled}
      minimumSearchTextLength={minimumSearchTextLength}
      firstOptionRef={firstOptionRef}
      popoverProps={popoverProps}
    />
  );
}
