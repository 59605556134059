import styled from 'styled-components';
import css from '@styled-system/css';
import theme from '../utils/themes';
export const Wrapper = styled.div(css({
    position: 'fixed',
    top: '50%',
    left: '50%',
    maxWidth: '100%',
    zIndex: 11,
    width: 'inherit',
    outline: 0,
    transform: 'translate(-50%,-50%)',
}));
export const Backdrop = styled.div(css({
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.45)',
    zIndex: 10,
}));
export const StyledModal = styled.div(css({
    zIndex: 'base',
    background: theme.colors.surface,
    position: 'relative',
    margin: 'auto',
    borderRadius: '24px',
}));
export const Header = styled.div(css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'bg.regular',
    minHeight: '80px',
    borderRadius: '24px 24px 0px 0px',
    px: '40px',
    py: '24px',
    paddingBottom: '40px',
    marginBottom: '-16px',
}));
export const Footer = styled.div(css({
    backgroundColor: 'bg.regular',
    minHeight: '80px',
    borderRadius: '0px 0px 24px 24px',
    px: '40px',
    py: '24px',
}));
export const Content = styled.div(css({
    padding: '10px',
    borderRadius: '24px',
    backgroundColor: 'bg.regular',
}));
export const CloseOutsideWrapper = styled.button({
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    zIndex: 2,
    marginTop: '0px',
    position: 'absolute',
    ':hover': {
        cursor: 'pointer',
    },
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
export const CloseInsideWrapper = styled.button({
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    marginTop: '12px',
    marginRight: '12px',
    position: 'absolute',
    ':hover': {
        cursor: 'pointer',
    },
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 'inherit',
    backgroundColor: 'inherit',
});
