import { css } from '@emotion/react';
import { margins, themed } from '../utils/styling';

type IconType = 'OFF' | 'ON';

const ICON_SIZES = {
  REGULAR: 16,
  SMALL: 14,
};

const getIcon = (type: IconType, size: number, color: string) => {
  if (type === 'OFF') {
    return `<svg width="${size}" height="${size}" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
      color,
    )}" d="M15.1407 6.55815C15.4766 6.22998 15.5352 5.67529 15.1641 5.29248C14.8087 4.97238 14.2813 4.94092 13.9024 5.31561L10.2266 8.99157L6.57035 5.33532C6.17983 4.94479 5.68346 4.90176 5.29293 5.29228C4.90241 5.68281 4.94936 6.1987 5.33989 6.58922L8.98832 10.2377L5.35938 13.8589C4.96484 14.23 4.91797 14.7769 5.29293 15.1712C5.66406 15.5386 6.23828 15.5034 6.60942 15.0933L10.2266 11.4759L13.8594 15.105C14.2539 15.5269 14.8047 15.5269 15.1797 15.1519C15.5391 14.7534 15.4922 14.2339 15.0938 13.8589L11.4649 10.2377L15.1407 6.55815Z"/></svg>`;
  }
  return `<svg width="${size}" height="${size}" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" fill="${encodeURIComponent(
    color,
  )}" d="M16.4187 5.46943C16.9739 6.00355 16.6346 6.58141 16.5154 6.70056L8.97189 14.2729C8.30392 14.8472 7.34689 14.855 6.65548 14.2651L3.35878 10.9418C3.05023 10.6294 2.98376 10.1099 3.34604 9.72204C3.73766 9.34815 4.24677 9.34967 4.66734 9.76991L7.82736 12.9449L15.2186 5.52515C15.6209 5.12644 16.1717 5.2318 16.4187 5.46943Z" /></svg>`;
};

const switch_style_mixin = (iconType: IconType, iconSize: number, thumbColor: string, trackColor: string) =>
  css`
    .MuiSwitch-thumb:before {
      background-image: url('data:image/svg+xml;utf8,${getIcon(iconType, iconSize, thumbColor)}');
    }

    ~ .MuiSwitch-track {
      background-color: ${trackColor};
      opacity: 1;
    }
  `;

type SwitchSizeTokens = {
  padding: number; // in px
  width: number; // in px
  height: number; // in px
  thumbDiameter: number; // in px
};

const switch_size_mixin = ({ padding, width, height, thumbDiameter }: SwitchSizeTokens) => css`
  padding: ${padding}px;
  width: ${2 * padding + width}px;
  height: ${2 * padding + height}px;
  & .MuiSwitch-switchBase {
    padding: ${padding + 2}px;

    &.Mui-checked {
      transform: translateX(${width / 2}px);
    }
  }
  & .MuiSwitch-thumb {
    width: ${thumbDiameter}px;
    height: ${thumbDiameter}px;
  }
`;

const switch_color_mixin = (iconSize: number) =>
  themed(
    ({ palette }) => css`
      .MuiSwitch-switchBase {
        ${switch_style_mixin('OFF', iconSize, palette.bg.tooltip, palette.icon.secondary)}

        &.Mui-checked {
          ${switch_style_mixin('ON', iconSize, palette.bg.brand, palette.bg.brand)}
        }

        &.Mui-disabled {
          .MuiSwitch-thumb {
            background-color: ${palette.icon.inverse};
          }
          ${switch_style_mixin('OFF', iconSize, palette.icon.disabled, palette.icon.disabled)}

          &.Mui-checked {
            ${switch_style_mixin('ON', iconSize, palette.icon.primary, palette.icon.primary)}
          }
        }
      }
    `,
  );

export const switch_root = themed(
  ({ palette }, { compose }) => css`
    .MuiSwitch-switchBase {
      .MuiSwitch-thumb {
        background-color: ${palette.icon.buttonIcon};

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-repeat: no-repeat;
          background-position: center;
          transition: background-image 0.1s cubic-bezier(0.33, 1, 0.68, 1);
        }
      }
    }

    .MuiSwitch-track {
      border-radius: 12px;
    }

    /* show outline on track on keyboard focus */
    &:has(input:focus-visible) {
      .MuiSwitch-track {
        outline: 2px solid ${palette.border.keyboardFocus};
        outline-offset: 2px;
      }
    }

    &:has(.Mui-disabled) {
      cursor: not-allowed;
    }

    &.BlocksSwitchSize-regular {
      ${switch_size_mixin({
        padding: 16,
        width: 48,
        height: 24,
        thumbDiameter: 20,
      })}

      ${compose(switch_color_mixin(ICON_SIZES.REGULAR))}
    }

    &.BlocksSwitchSize-small {
      ${switch_size_mixin({
        padding: 12,
        width: 40,
        height: 20,
        thumbDiameter: 16,
      })}

      ${compose(switch_color_mixin(ICON_SIZES.SMALL))}
    }
  `,
);

export const switchWithLabelBase = css`
  ${margins.x.m0}

  &.Mui-disabled {
    cursor: not-allowed;
  }
`;
