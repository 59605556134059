import Typography from '@spotnana/pixel-react/dist/Typography';
import Box from '@spotnana/pixel-react/dist/Box';
import IndeterminateLoader from '@spotnana/pixel-react/dist/IndeterminateLoader';
import User from '@spotnana/pixel-react/dist/Icons/User';
import { globalTranslationKeys } from 'obt-common';
import { useTranslation } from 'react-i18next';

interface IProps {
  loaderText?: string;
}

const TravelerLoader = ({ loaderText }: IProps): JSX.Element => {
  const { t } = useTranslation('COMMON');
  const { SEARCHING_PROFILES } = globalTranslationKeys;
  return (
    <Box data-testid="loader_search-traveler-list" overflow="hidden" maxWidth="full" position="relative">
      <Box padding={4} display="flex" alignItems="center">
        <Box
          borderRadius="sm"
          minWidth={28}
          height={28}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="bg.paleGray"
        >
          <User primaryColor="#D7DFE8" />
        </Box>
        <Box width="60%" mx={3} height={20} bg="bg.paleGray" />
      </Box>
      <Box textAlign="center" pb={16}>
        <Typography variation="body">
          <strong>{loaderText ?? t(SEARCHING_PROFILES)}...</strong>
        </Typography>
      </Box>
      <IndeterminateLoader />
    </Box>
  );
};

export default TravelerLoader;
