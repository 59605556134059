import { toast } from '@spotnana/blocks/src/Toast/Toast';
import { ProcessedError, usePrimaryTraveler } from 'obt-common';
import { useEffect } from 'react';

import ErrorBody from './ErrorBody';
import { toast_container } from './ErrorBody/styles';

interface IProps {
  snackbarError: ProcessedError;
  debugId: string;
  onClose: () => void;
}

const GlobalErrorToast = ({ snackbarError, debugId, onClose }: IProps) => {
  const { data: primaryTraveler } = usePrimaryTraveler();
  const showToast = () => {
    toast({
      variant: 'error',
      message: (
        <ErrorBody error={snackbarError} debugId={debugId} onClose={onClose} primaryTraveler={primaryTraveler} />
      ),
      iconName: 'ErrorCircleFill',
      position: 'bottom-center',
      duration: 3 * 60 * 1000,
      onClose,
      customCss: toast_container,
    });
    // clear global error
    onClose();
  };

  useEffect(() => {
    if (snackbarError.errorCode) {
      showToast();
    }

    // eslint-disable-next-line
  }, [snackbarError.errorCode, debugId]);

  return <></>;
};

export { GlobalErrorToast };
