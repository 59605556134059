import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const SeatPitch = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 32 32", "data-testid": "seat-pitch-icon" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M25.41,17.67a.89.89,0,0,0-.88-.78h-8.1L18,14.8h4.65A.89.89,0,0,0,22.6,13H17.51a.87.87,0,0,0-.63.34l-2.14,2.81L13.66,13l-.4-1.19V6.35a1.27,1.27,0,0,0,0-.28,2.61,2.61,0,0,0-.14-.55A2.75,2.75,0,0,0,12.86,5a2.84,2.84,0,0,0-.72-.82l-.21-.16h0a2.87,2.87,0,0,0-1.58-.47A2.93,2.93,0,0,0,7.42,6.52v5.9l0,.17L8.19,15l.87,2.88V18a5.53,5.53,0,0,0,4.39,5.18V25.8a.89.89,0,0,0,.88.78h7.52a.9.9,0,0,0,.78-.89V23.33h2a.89.89,0,0,0,.78-.88V17.67ZM10.15,15.38h0l-1-3.17V6.39a1.15,1.15,0,0,1,2.29.13V12l0,.22,1.21,3.57h0l.33,1h0l-.09.08H10.6ZM20.86,24.8H15.23V23.33h5.63Zm2.78-3.25H14.26A3.74,3.74,0,0,1,11,18.77l0-.09h1.48a1.88,1.88,0,0,0,3.68,0h7.54Z" })));
};
SeatPitch.defaultProps = defaultIconProps;
export default SeatPitch;
