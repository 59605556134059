var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { variant, padding, fontSize, margin } from 'styled-system';
import css from '@styled-system/css';
import ButtonAddon from './ButtonAddon';
import CircularLoader from '../CircularLoader';
import spotnanaTheme from '../utils';
const StyledButton = styled('button')(css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    border: 'thickSolid',
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    padding: 2,
    borderRadius: 'sm',
    ':disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
    },
    ':focus-visible': {
        // temporary adding Blocks colour to pixel so that old buttons are still keyboard accessible until they are replaced
        /* stylelint-disable-next-line color-no-hex */
        outline: '2px solid #3162C4',
    },
}), variant({
    prop: 'variation',
    variants: {
        primary: {
            color: 'text.white',
            bg: 'primary',
            borderColor: 'primary',
            '[data-mui-color-scheme="dark"] &': { color: 'text.black' },
            ':hover:not([disabled])': {
                bg: spotnanaTheme.colors.primaryHover,
            },
            ':active': {
                bg: spotnanaTheme.colors.primaryActive,
            },
            ' svg path': {
                fill: 'text.white',
            },
        },
        success: {
            color: 'text.success',
            bg: spotnanaTheme.colors.bg.regular,
            borderColor: spotnanaTheme.colors.success,
            ' svg path': {
                fill: 'text.success',
            },
        },
        secondary: {
            color: 'text.darkGray',
            bg: 'bg.transparent',
            borderColor: 'primary',
            ':hover:not([disabled])': {
                color: 'text.lightGray',
                bg: spotnanaTheme.colors.primaryHover,
            },
            ':active': {
                bg: spotnanaTheme.colors.primaryActive,
            },
            ' svg path': {
                fill: 'text.darkGray',
            },
        },
        tertiary: {
            color: 'primary',
            bg: 'bg.transparent',
            borderColor: 'bg.transparent',
            borderWidth: 1,
            ':hover:not([disabled])': {
                borderColor: 'border.regular',
                bg: 'bg.trasparent',
            },
            ':active': {
                bg: 'bg.blueGray',
            },
            ' svg path': {
                fill: 'primary',
            },
        },
        subtle: {
            color: 'text.black',
            bg: 'bg.white',
            borderColor: 'bg.transparent',
            borderWidth: 2,
            ':hover:not([disabled])': {
                borderColor: 'primary',
            },
            ':active': {
                bg: 'bg.blueGray',
            },
            ' svg path': {
                fill: 'primary',
            },
        },
        ghost: {
            color: 'bg.greyBlue',
            bg: 'bg.transparent',
            borderColor: 'border.disabled',
            borderWidth: 1,
            borderStyle: 'solid',
            ':hover:not([disabled])': {
                bg: 'bg.regular',
                color: 'primary',
                ' svg path': {
                    fill: 'primary',
                },
            },
            ':active': {
                borderColor: 'border.transparent',
                bg: 'bg.regular',
                color: 'primary',
                ' svg path': {
                    fill: 'primary',
                },
            },
            ' svg path': {
                fill: 'bg.greyBlue',
            },
        },
        text: {
            color: 'text.blueGrey',
            bg: 'bg.transparent',
            borderColor: 'border.transparent',
            borderWidth: 1,
            borderStyle: 'solid',
            ':hover:not(:disabled)': {
                color: 'primary',
                ' svg path': {
                    fill: 'primary',
                },
            },
            ' svg path': {
                fill: 'text.blueGrey',
            },
        },
        primaryText: {
            color: 'primary',
            bg: 'bg.transparent',
            borderColor: 'border.transparent',
            borderWidth: 1,
            borderStyle: 'solid',
        },
        infoText: {
            color: 'text.label',
            bg: 'bg.blueGray',
            borderColor: 'border.transparent',
            borderWidth: 1,
            borderStyle: 'solid',
            ' svg path': {
                fill: 'text.blueGrey',
            },
        },
        primaryTextBorder: {
            color: 'primary',
            backgroundColor: 'bg.regular',
            border: 'thinSolid',
            borderColor: spotnanaTheme.colors.primaryEmphasis,
            borderRadius: '8px',
        },
    },
}), (props) => css({
    width: props.block ? 'full' : undefined,
}), padding, fontSize, margin, ({ overrides }) => css(Object.assign({}, overrides)));
StyledButton.defaultProps = {
    variation: 'primary',
    type: 'button',
    disabled: false,
    block: false,
};
export const Button = React.forwardRef((props, ref) => {
    var _a;
    const { addonBefore, addonAfter, loading, disabled, children, addonContainerStyles } = props, restProps = __rest(props, ["addonBefore", "addonAfter", "loading", "disabled", "children", "addonContainerStyles"]);
    const isDisabled = loading || disabled;
    if (loading && (addonBefore || addonAfter)) {
        // console.warn('Pixel: Loading prop received by Button is true, Button addons are disabled');
    }
    const circularLoader = (React.createElement(CircularLoader, { size: 12, color: ((_a = restProps.variation) !== null && _a !== void 0 ? _a : 'primary') !== 'primary' ? spotnanaTheme.colors.primary : '#fff' }));
    const finalAddonBefore = loading ? circularLoader : addonBefore;
    const finalAddonAfter = loading ? undefined : addonAfter;
    return (React.createElement(StyledButton, Object.assign({ ref: ref, disabled: isDisabled }, restProps),
        finalAddonBefore && (React.createElement(ButtonAddon, { style: addonContainerStyles, variation: "left" }, finalAddonBefore)),
        children && React.createElement("span", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } }, children),
        finalAddonAfter && (React.createElement(ButtonAddon, { style: addonContainerStyles, variation: "right" }, finalAddonAfter))));
});
const StyledIconButton = styled.button(({ theme }) => css({
    padding: 2,
    bg: 'bg.transparent',
    border: 0,
    borderRadius: 'circle',
    height: 36,
    width: 36,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.2s ease-in-out',
    ':active': {
        outline: 0,
    },
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
    ':hover:not([disabled])': {
        backgroundColor: theme.colors.primaryDisabled,
    },
}));
export const IconButton = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return (React.createElement(StyledIconButton, Object.assign({}, restProps, { role: "button" }), children(spotnanaTheme.colors.primaryEmphasis)));
};
