import { REQUEST_HEADERS } from '../../../constants/common';
import { fetchStorageValue } from '../../../hooks/useStorage';
import { StorageKeys } from '../../../types/storage';
import addRequestInterceptor from '../addRequestInterceptor';

export function injectTmcIdHeader() {
  return addRequestInterceptor({
    onFulfilled: async (config) => {
      const tmcId = await fetchStorageValue<string>(StorageKeys.TMC_ID).catch(() => null);

      if (tmcId) {
        config.headers[REQUEST_HEADERS.X_TMC_ID] = tmcId;
      }

      return config;
    },
  });
}
