import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Delete = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.56 5.39H4.816v5.82a.5.5 0 00.462.5H10.635a.5.5 0 00.5-.462V5.39H6.562zm3.283-1h2.618a.5.5 0 01.037.999h-.362v5.822a1.5 1.5 0 01-1.444 1.499H5.315a1.5 1.5 0 01-1.5-1.443V5.39H3.46a.5.5 0 01-.037-.999l.037-.001H6.08a1.894 1.894 0 013.763 0zm-1.834-.678c.393.02.718.295.816.662l.004.016H7.094l.003-.016a.894.894 0 01.864-.663l.048.001zM9.812 6.5a.25.25 0 01.248.22l.002.03v3.603a.25.25 0 01-.498.03l-.002-.03V6.75a.25.25 0 01.25-.25zm-3.425.22a.25.25 0 00-.499.03v3.603l.002.03a.25.25 0 00.498-.03V6.75l-.001-.03zm1.592-.22a.25.25 0 01.249.22l.001.03v3.603a.25.25 0 01-.498.03l-.002-.03V6.75a.25.25 0 01.25-.25z", fill: themeEnrichedPrimaryColor })));
};
Delete.defaultProps = defaultIconProps;
export default Delete;
