import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Work = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 24 24" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "evenodd", d: "M13.379 5.25c1.214 0 2.204.962 2.248 2.166l.002.084v.079h3.281c.38 0 .694.282.744.648l.006.102V18c0 .414-.335.75-.75.75H5.09c-.415 0-.75-.336-.75-.75V8.33c0-.415.335-.75.75-.75l3.28-.001.001-.079c0-1.214.962-2.204 2.166-2.248l.084-.002h2.758zm4.781 7.67h-4.53v1.346c0 .193-.144.351-.33.373l-.045.002h-2.51c-.193 0-.351-.144-.373-.33l-.002-.045V12.92H5.839v4.33h12.322v-4.33zm-5.28 0h-1.76v.971h1.76v-.971zm5.28-3.84H5.84l-.002 2.84H18.16V9.08zM13.38 6.75H10.62c-.395 0-.72.306-.748.694l-.002.056v.079h4.258V7.5c0-.395-.306-.72-.694-.748l-.056-.002z" })));
};
Work.defaultProps = defaultIconProps;
export default Work;
