/* eslint-disable */
import { Money } from '../../../v1/common/money';
import { Int32Range } from '../../../v1/common/range';
import { Cabin } from '../../../v1/obt/air/air_common';
import { UserOrgId } from '../../../v1/obt/common/user_org_id';

export enum Location {
  UNKNOWN_LOCATION = 0,
  FRONT = 1,
  MIDDLE = 2,
  REAR = 3,
  LEFT = 4,
  LEFT_CENTER = 5,
  RIGHT = 6,
  RIGHT_CENTER = 7,
  CENTER = 8,
  UPPER_DECK = 9,
  LOWER_DECK = 10,
  MAIN_DECK = 11,
  UNRECOGNIZED = -1,
}

/** Facility type is used to represent row facilities and cabin facilities. */
export enum FacilityType {
  UNKNOWN_ROW_FACILITY = 0,
  BULKHEAD = 1,
  STAIRS = 2,
  TABLE = 3,
  LAVATORY = 4,
  BAR = 5,
  CLOSET = 6,
  AIR_PHONE = 7,
  EXIT_DOOR = 8,
  EMERGENCY_EXIT = 9,
  GALLEY = 10,
  LUGGAGE_STORAGE = 11,
  STORAGE_SPACE = 12,
  UNRECOGNIZED = -1,
}

/**
 * SeatSection represents a group of seats, within a row, which has the same properties.
 * The seats' column numbers might not be consecutive. For, eg 2 window seats on the left and right
 * side of the row which have same properties would become one SeatSection.
 */
export interface SeatSection {
  columnNumbers: string[];
  type: SeatSectionSeatType;
  location: SeatSectionSeatLocation[];
  limitations: SeatSectionSeatLimitation[];
  facilities: SeatSectionSeatFacility[];
  price?: SeatSectionPrice;
  /** Commercial name for this seat eg: Economy plus */
  commercialName: string;
}

export enum SeatSectionSeatType {
  SEAT = 0,
  NO_SEAT = 1,
  BULKHEAD = 2,
  STAIRS = 3,
  LAVATORY = 4,
  BAR = 5,
  CLOSET = 6,
  AIR_PHONE = 7,
  EXIT_DOOR = 8,
  EMERGENCY_EXIT = 9,
  GALLEY = 10,
  LUGGAGE_STORAGE = 11,
  STORAGE_SPACE = 12,
  TABLE = 13,
  UNRECOGNIZED = -1,
}

export enum SeatSectionSeatLimitation {
  UNKNOWN_SEAT_LIMITATION = 0,
  RESTRICTED_RECLINE = 1,
  NOT_ALLOWED_FOR_INFANT = 2,
  NOT_ALLOWED_FOR_UNACCOMPANIED_MINOR = 3,
  NOT_SUITABLE_FOR_CHILD = 4,
  WINDOW_SEAT_WITHOUT_WINDOW = 5,
  CREW_SEAT = 6,
  NOT_BOOKABLE_ON_OBT = 7,
  RESTRICTED_GENERAL = 8,
  NO_FARE_INFORMATION = 9,
  LOYALTY_LEVEL_REQUIRED = 10,
  UNRECOGNIZED = -1,
}

export enum SeatSectionSeatFacility {
  UNKNOWN_SEAT_FACILITY = 0,
  LEG_SPACE = 1,
  BASSINET = 2,
  SMOKING = 3,
  NON_SMOKING = 4,
  PET_IN_CABIN = 5,
  SUITABLE_FOR_ADULT_WITH_INFANT = 6,
  SUITABLE_FOR_UNACCOMPANIED_MINOR = 7,
  FACILITIES_FOR_HANDICAPPED_INCAPACITATED = 8,
  /**
   * PREFERRED_SEAT - Preferred seats are special seats in the front section of Economy Class, directly behind
   * Business or Premium Economy Class. Booking these seats customers will be among those
   * passengers who disembark first. Only some airlines offer these seats:
   * eg: https://www.united.com/ual/en/us/fly/products/travel-options/preferred-seating/FAQs.html
   */
  PREFERRED_SEAT = 9,
  UNRECOGNIZED = -1,
}

export enum SeatSectionSeatLocation {
  UNKNOWN_SEAT_LOCATION = 0,
  BULKHEAD_ROW = 1,
  EXIT_ROW = 2,
  LEFT_FACING_FLAT_BED = 3,
  RIGHT_FACING_FLAT_BED = 4,
  UNRECOGNIZED = -1,
}

export interface SeatSectionPrice {
  totalAmount?: Money;
  tax?: Money;
}

export interface FacilitySection {
  location: Location;
  facilities: FacilitySectionFacility[];
}

export interface FacilitySectionFacility {
  location: Location;
  facilityType: FacilityType;
}

/** RowSection represents a set of consecutive rows within a cabin which have same properties. */
export interface RowSection {
  rowNumbers?: Int32Range;
  /** Available seats for each row in this Row Section. */
  availableSeats: RowSectionAvailableSeats[];
  rowTypes: RowSectionRowType[];
  facilitySections: FacilitySection[];
  seatSections: SeatSection[];
}

export enum RowSectionRowType {
  ROW = 0,
  BUFFER = 1,
  EXIT = 2,
  EXTRA_LEG_ROOM = 3,
  NO_ROW = 4,
  UNRECOGNIZED = -1,
}

export interface RowSectionAvailableSeats {
  columnNumber: string[];
}

export interface ColumnSection {
  columns: ColumnSectionColumn[];
}

export enum ColumnSectionColumnPosition {
  UNKNOWN_COLUMN_POSITION = 0,
  AISLE = 1,
  WINDOW = 2,
  WINDOW_AISLE = 3,
  CENTER = 4,
  UNRECOGNIZED = -1,
}

export interface ColumnSectionColumn {
  columnNumber: string;
  position: ColumnSectionColumnPosition;
}

export interface CabinSection {
  cabin: Cabin;
  bookingCode: string;
  locations: Location[];
  facilitySections: FacilitySection[];
  columnSections: ColumnSection[];
  rowSections: RowSection[];
}

export interface FlightSeatMap {
  wingRows?: Int32Range;
  cabinSections: CabinSection[];
}

/** Seat maps for a single passenger type */
export interface PaxSeatMap {
  /** Pax ID provided in the request */
  userOrgId?: UserOrgId;
  /** Seat maps for the specified paxId. Indices refer to AirSeatMapResponse -> seatMaps */
  flightSeatMapIndices: number[];
}

export interface AirSeatMapResponse {
  /** Seat map data */
  seatMaps: FlightSeatMap[];
  /** Identifier used to get right seatmap out of store in revalidate and createPnr api */
  seatMapResponseId: string;
  /** Seat map for each paxType */
  paxSeatMaps: PaxSeatMap[];
}
