import { clsx } from '@spotnana/blocks/src/utils';
import { dot } from './index.styles';

interface IProps {
  hideBelowTablet?: boolean;
  hideBelowDesktop?: boolean;
  hideBelowLarge?: boolean;
}

export const Dot = ({ hideBelowTablet, hideBelowDesktop, hideBelowLarge }: IProps): JSX.Element => {
  return (
    <div
      className={clsx(
        'dot-root',
        hideBelowTablet && 'hide-below-tablet',
        hideBelowDesktop && 'hide-below-desktop',
        hideBelowLarge && 'hide-below-large',
      )}
      css={dot}
    />
  );
};
