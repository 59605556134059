/**
 * ECC is an Amazon specific custom field whose value they specify in their HR feed.
 * Since the UI is leveraging the user info fields patterns of key-value options in
 * a select field, we manually map the ECC value to an enum value we can use to determine
 * a user's ECC status. When handling ECC values directly from an API response, use
 * `getProfileEccKeyFromString` to get the corresponding enum value. This is necessary as
 * the ECC casing and encoding of the '=' character can vary.
 */

export enum ProfileECCLabels {
  ECC_ENABLED = 'ECC',
  ECC_DISABLED = 'NO_ECC', // Should never be displayed to the user.
  VIP_ENABLED = 'VIP',
}

export enum ProfileECCKeys {
  ECC_ENABLED = 'ECC=True',
  ECC_DISABLED = 'ECC=False',
  VIP_ENABLED = 'VIP=True',
}

export const profileECCOptions = [
  {
    label: ProfileECCLabels.ECC_ENABLED,
    value: ProfileECCKeys.ECC_ENABLED,
  },
  {
    label: ProfileECCLabels.ECC_DISABLED,
    value: ProfileECCKeys.ECC_DISABLED,
  },
  {
    label: ProfileECCLabels.VIP_ENABLED,
    value: ProfileECCKeys.VIP_ENABLED,
  },
];

export const getProfileEccKeyFromString = (eccRaw: string): ProfileECCKeys | undefined => {
  // Decode any unicode characters in the string (equal sign) and convert to lowercase.
  const normalizedEcc = decodeURIComponent(eccRaw).toLowerCase();

  switch (normalizedEcc) {
    case 'ecc=true':
      return ProfileECCKeys.ECC_ENABLED;
    case 'ecc=false':
      return ProfileECCKeys.ECC_DISABLED;
    case 'vip=true':
      return ProfileECCKeys.VIP_ENABLED;
    default:
      return undefined;
  }
};
