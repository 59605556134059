import { useTripDetailsQuery, TripIdUrlParam } from 'obt-common';

import { useEmitEmbedErrors } from '../components/EmbedContext';

const useTripDetails = (tripId: TripIdUrlParam = '') => {
  const reportError = useEmitEmbedErrors({ source: 'useTripDetails' });

  return useTripDetailsQuery(String(tripId), {
    enabled: !!tripId,
    onError: (error) =>
      reportError({
        error,
        context: { tripId },
      }),
  });
};

export default useTripDetails;
