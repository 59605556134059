// Generated using yarn generate command
import { css } from '@emotion/react';
import { themed, unit } from '../utils';

export const swipeable_drawer = css`
  &.MuiDrawer-root .MuiDrawer-paper {
    overflow-y: hidden;

    &.MuiDrawer-paperAnchorBottom {
      max-height: 80vh;
    }
  }
`;

export const rounded_drawer = themed(
  () => css`
    &.MuiDrawer-root .MuiDrawer-paper {
      border-top-left-radius: ${unit(1)};
      border-top-right-radius: ${unit(1)};
    }
  `,
);

export const children_container = css`
  overflow: auto;
`;

export const padding_over_footer = css`
  padding-bottom: 80px;
`;

export const drawer_close_button_container = css`
  position: absolute;
  top: ${unit(1)};
  right: ${unit(1.5)};
  z-index: 1;
`;

export const drawer_close_button = css`
  position: relative;
`;
