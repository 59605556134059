import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MUISwitch, { SwitchProps } from '@mui/material/Switch';
import omit from 'lodash/omit';
import { useId } from 'react';
import { Typography } from '../Typography';
import { RemoveMuiProps, RequireAtLeastOne } from '../types';
import { switchWithLabelBase, switch_root } from './Switch.styles';

/**
 * We are controlling the size of switch, omitting size from props.
 */
type BaseProps = Omit<SwitchProps, 'size' | 'aria-label' | 'aria-labelledby'> & {
  size?: 'small' | 'regular';
  /**
   * RequireAtLeastOne is used below to mandate at least one one of these aria props
   */
  'aria-label': string;
  'aria-labelledby': string;
};

type BaseSwitchProps = RequireAtLeastOne<BaseProps, 'aria-label' | 'aria-labelledby'>;

/**
 * Spotnana typography will be used in switch for consistency.
 * labelPlacement : limiting the prop value options
 * label: Will always be string
 * size: Size prop will be passed from parent to switch, switch props will not have size
 */
type WithLabelSwitchProps = RemoveMuiProps<
  FormControlLabelProps,
  'label' | 'control' | 'disableTypography' | 'labelPlacement'
> & {
  label: string;
  /**
   * Small or Regular?
   */
  size?: 'small' | 'regular';
  labelPlacement?: 'start' | 'end';
  readOnly?: boolean;
};

export type TSwitchProps = Pick<BaseSwitchProps, 'defaultChecked' | 'value'> & (BaseSwitchProps | WithLabelSwitchProps);

/**
 * Switch component.
 * @param props
 * @returns
 */
export const Switch = (props: TSwitchProps) => {
  if ('label' in props) {
    return <SwitchWithLabel {...props} />;
  }
  const inputProps = {
    'aria-label': props['aria-label'],
    'aria-labelledby': props['aria-labelledby'],
  };

  const { size = 'regular', ...restProps } = omit(props, ['aria-label', 'aria-labelledby']);

  return (
    <MUISwitch
      css={switch_root}
      className={`BlocksSwitchSize-${size}`}
      data-testid="blocks-Switch"
      {...restProps}
      inputProps={inputProps}
      disableRipple
    />
  );
};

/**
 * Switch with label component
 * @param param0
 * @returns
 */
function SwitchWithLabel({
  defaultChecked,
  value,
  labelPlacement,
  size = 'regular',
  disabled,
  readOnly,
  label,
  ...props
}: WithLabelSwitchProps) {
  const labelId = useId();
  const isDisabled = disabled || readOnly;
  return (
    <FormControlLabel
      css={switchWithLabelBase}
      data-testid="blocks-Switch-label"
      disableTypography
      control={<Switch {...{ size, 'aria-labelledby': labelId, defaultChecked, value, readOnly }} />}
      disabled={isDisabled}
      labelPlacement={labelPlacement}
      label={
        <Typography
          color={disabled ? 'disabled' : 'primary'}
          variant={size === 'small' ? 'body2' : 'body1'}
          as="span"
          id={labelId}
        >
          {label}
        </Typography>
      }
      {...props}
    />
  );
}
