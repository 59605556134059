import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import Close from '../Icons/Close';
import { Backdrop, CloseInsideWrapper, CloseOutsideWrapper, Content, Footer, Header, StyledModal, Wrapper, } from './modal.style';
export const useModal = (disableScrollLock = true, defaultVisibility = false) => {
    const [visible, setVisible] = useState(defaultVisibility);
    const toggle = () => {
        var _a, _b;
        setVisible(!visible);
        if (disableScrollLock) {
            if (visible) {
                (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList.remove('no-scroll');
            }
            else {
                (_b = document.documentElement) === null || _b === void 0 ? void 0 : _b.classList.add('no-scroll');
            }
        }
    };
    const open = () => {
        var _a;
        setVisible(true);
        if (disableScrollLock) {
            (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList.add('no-scroll');
        }
    };
    const close = () => {
        var _a;
        setVisible(false);
        if (disableScrollLock) {
            (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList.remove('no-scroll');
        }
    };
    useEffect(() => () => {
        var _a;
        if (disableScrollLock) {
            (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList.remove('no-scroll');
        }
    }, 
    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return {
        visible,
        toggle,
        open,
        close,
    };
};
const defaultContentStyles = { maxHeight: 'calc(90vh - 104px)', overflowY: 'auto' };
export const Modal = ({ visible, onClose, header, disableBackdropClick = false, disableEscPress = false, hideCloseMenu = false, children, backdropStyles, modalStyles, modalContentStyles, closeButtonStyles, dataTestId = '', footer, closeButtonOutside = false, modalHeaderStyles, modalFooterStyles, wrapperStyles, }) => {
    const { t: tt } = useTranslation('WEB');
    const onKeyDown = (event) => {
        const { key } = event;
        if (key === 'Escape' && visible && !disableEscPress) {
            onClose(event);
        }
    };
    useEffect(() => {
        var _a;
        document.addEventListener('keydown', onKeyDown, false);
        if (visible) {
            (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList.add('no-scroll');
        }
        return () => {
            var _a;
            document.removeEventListener('keydown', onKeyDown, false);
            (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList.remove('no-scroll');
        };
    }, 
    // TODO (Bhavan): Decide on correct implementation of dependecies to consider memory leaks
    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visible]);
    let closeButton = null;
    if (!hideCloseMenu) {
        if (!closeButtonOutside) {
            closeButton = (React.createElement(CloseInsideWrapper, { className: "modal-close-button", "aria-label": tt('Close'), "data-testid": "modal-close", style: closeButtonStyles, onClick: onClose },
                React.createElement(Close, null)));
        }
        else {
            closeButton = (React.createElement(CloseOutsideWrapper, { className: "modal-close-button", "aria-label": tt('Close'), "data-testid": "modal-close", style: closeButtonStyles, onClick: onClose },
                React.createElement(Close, null)));
        }
    }
    const modal = (React.createElement(React.Fragment, null,
        React.createElement(Backdrop, { "data-testid": "modal-backdrop", onClick: disableBackdropClick ? undefined : onClose, style: backdropStyles }),
        React.createElement(Wrapper, { style: wrapperStyles, "aria-modal": true, tabIndex: -1, role: "dialog", "data-testid": `modal_${dataTestId}` },
            closeButton,
            React.createElement(StyledModal, { style: modalStyles },
                header ? React.createElement(Header, { style: Object.assign({}, modalHeaderStyles) }, header) : null,
                React.createElement(Content, { style: Object.assign(Object.assign({}, defaultContentStyles), modalContentStyles) }, children),
                footer ? React.createElement(Footer, { style: Object.assign({}, modalFooterStyles) }, footer) : null))));
    return visible ? ReactDOM.createPortal(modal, document.body) : null;
};
