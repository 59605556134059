import React, { createContext, useContext } from 'react';

export interface ToastActions {
  dismissToast: () => void;
}

interface ToastActionContextProps {
  actions: ToastActions;
}

interface ToastActionContextData {
  dismissToast: () => void;
}

const ToastActionContext = createContext<ToastActionContextData | null>(null);

export const ToastActionProvider: React.FC<React.PropsWithChildren<ToastActionContextProps>> = ({
  actions,
  children,
}) => {
  return <ToastActionContext.Provider value={actions}>{children}</ToastActionContext.Provider>;
};

export const useToastActions = () => {
  const toastActions = useContext(ToastActionContext);
  if (!toastActions) {
    throw new Error('useToastActions must be used within a ToastActionProvider');
  }
  return toastActions;
};
