var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../utils/themes';
const rotation = keyframes `
  0% { transform: rotate(0deg) }
  100% { transform: rotate(270deg) }
`;
const StyledSVG = styled.svg `
  animation: ${rotation} 1.35s linear infinite;
`;
const turn = keyframes `
  0% { stroke-dashoffset: 180 }
  50% {
    stroke-dashoffset: 45;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 180;
    transform: rotate(450deg);
  }
`;
const StyledCircle = styled.circle `
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${turn} 1.35s ease-in-out infinite;
`;
const CircularLoader = (_a) => {
    var { color = theme.colors.primary, size = 65 } = _a, otherProps = __rest(_a, ["color", "size"]);
    return (React.createElement(StyledSVG, Object.assign({ width: size, height: size, stroke: color, strokeWidth: 6, strokeLinecap: "round", viewBox: "0 0 66 66", xmlns: "http://www.w3.org/2000/svg" }, otherProps),
        React.createElement(StyledCircle, { fill: "none", cx: 33, cy: 33, r: 30 })));
};
/**
 * @deprecated
 */
export default CircularLoader;
