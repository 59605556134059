import dateUtil from '../dateUtil';

/**
 *
 *
 * @param {number} duration
 * @param {('months' | 'days' | 'years')} durationFormat
 * @param {string} dateFormat
 * @param {string} [currentDate]
 * @return {*}  {string}
 */
const getFutureDate = (
  duration: number,
  durationFormat: 'months' | 'days' | 'years',
  dateFormat: string,
  currentDate?: string,
): string =>
  dateUtil(currentDate ?? dateUtil(), dateFormat)
    .add(duration, durationFormat)
    .format(dateFormat);

export default getFutureDate;
