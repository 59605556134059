import { defineMessage } from '../translations/defineMessage';
import { DeliveryOption, PassengerType, SearchType } from '../types/api/v1/obt/rail/rail_common';
import { RailSearchOutwardRequestJourneySearchDefinitionJourneySearchType } from '../types/api/v1/obt/rail/rail_search_request';
import { SectionAlternativeCategory } from '../types/api/v1/obt/rail/rail_search_response';
import { RailStationStationTypeEnum } from '../types/api/v2/obt/model';
import { RailLayoutItemTypeEnum } from '../types/api/v2/obt/model/rail-layout-item';
import { RailThirdParty } from '../types/api/v2/obt/model/rail-third-party';
import type { IRailSuggestion } from '../types/autocomplete';
import type { IKeyLabelPair, IKeyLabelPairWithVisibilityInfo } from '../types/common';
import type { EURailFlexibility, IViaFilterState, RailCarriageDeckTypeEnum } from '../types/rail';
import { IViaFilterType, RailSeatTypeEnum, RailTravelClassV1 } from '../types/rail';

export const INITIAL_TIME = { hour: 9, minute: 0 };
export const IRailStationStationTypeEnum = RailStationStationTypeEnum;
export const IRailTimeTypeEnum = RailSearchOutwardRequestJourneySearchDefinitionJourneySearchType;

export const railTranslationKeys = {
  RAIL_SEARCH_FORM_SEARCH_TYPE_ONE_WAY: 'RAIL_SEARCH_FORM_SEARCH_TYPE_ONE_WAY',
  RAIL_SEARCH_FORM_SEARCH_TYPE_ROUND_TRIP: 'RAIL_SEARCH_FORM_SEARCH_TYPE_ROUND_TRIP',
  RAIL_SEARCH_FORM_SEARCH_TYPE_OPEN_RETURN: 'RAIL_SEARCH_FORM_SEARCH_TYPE_OPEN_RETURN',
  SEARCH_RAIL: 'SEARCH_RAIL',
  RAIL: 'RAIL',
  WHERE_FROM: 'WHERE_FROM',
  WHERE_TO: 'WHERE_TO',
  DEPARTURE: 'DEPARTURE',
  RETURN: 'RETURN',
  OUTBOUND: 'OUTBOUND',
  OPEN_RETURN: 'OPEN_RETURN',
  LOADING_MESSAGE: 'LOADING_MESSAGE',
  ADD_RAIL_CARD: 'ADD_RAIL_CARD',
  SUGGESTIONS: 'SUGGESTIONS',
  NO_RAIL_CARDS_FOUND: 'NO_RAIL_CARDS_FOUND',
  NO_RAIL_CARD_IN_PROFILE: 'NO_RAIL_CARD_IN_PROFILE',
  TRY_DIFFERENT_SEARCH: 'TRY_DIFFERENT_SEARCH',
  ASSIGN_RAILCARD: 'ASSIGN_RAILCARD',
  LEAVING_AT: 'LEAVING_AT',
  ARRIVING_BY: 'ARRIVING_BY',
  RAIL_CARDS: 'RAIL_CARDS',
  ADD_NEW: 'ADD_NEW',
  STANDARD: 'STANDARD',
  FIRST_CLASS: 'FIRST_CLASS',
  ROUTE: 'ROUTE',
  SHOW_EARLIER_OPTIONS: 'SHOW_EARLIER_OPTIONS',
  SHOW_LATER_OPTIONS: 'SHOW_LATER_OPTIONS',
  NO_RESULTS_FOUND: 'NO_RESULTS_FOUND',
  OUTBOUND_FROM: 'OUTBOUND_FROM',
  TOTAL: 'TOTAL',
  SELECT_AND_CONTINUE: 'SELECT_AND_CONTINUE',
  ANY_VALID_TRAIN: 'ANY_VALID_TRAIN',
  TRAVELCARD: 'TRAVELCARD',
  TRAVELCARD_DESCRIPTION: 'TRAVELCARD_DESCRIPTION',
  TICKET_DELIVERY: 'TICKET_DELIVERY',
  SEAT_PREFERENCES: 'SEAT_PREFERENCES',
  SEAT_RESERVATION_PREFERENCES: 'SEAT_RESERVATION_PREFERENCES',
  IMPORTANT_INFORMATION: 'IMPORTANT_INFORMATION',
  IMPORTANT_INFORMATION_DESCRIPTION: 'IMPORTANT_INFORMATION_DESCRIPTION',
  TRAVELCARD_COLLECTION_RULES: 'TRAVELCARD_COLLECTION_RULES',
  E_TICKET: 'E_TICKET',
  E_TICKET_DESCRIPTION: 'E_TICKET_DESCRIPTION',
  E_TICKET_HELPER: 'E_TICKET_HELPER',
  KIOSK: 'KIOSK',
  KIOSK_DESCRIPTION: 'KIOSK_DESCRIPTION',
  KIOSK_HELPER: 'KIOSK_HELPER',
  UNRECOGNIZED: 'UNRECOGNIZED',
  UNRECOGNIZED_DESCRIPTION: 'UNRECOGNIZED_DESCRIPTION',
  UNKNOWN: 'UNKNOWN',
  UNKNOWN_DESCRIPTION: 'UNKNOWN_DESCRIPTION',
  SEAT_PREF_WARNING: 'SEAT_PREF_WARNING',
  POSITION: 'POSITION',
  DIRECTION: 'DIRECTION',
  CARRIAGE_TYPE: 'CARRIAGE_TYPE',
  RAIL_TICKETS: 'RAIL_TICKETS',
  FEES_AND_TAXES: 'FEES_AND_TAXES',
  RAIL_CARD: 'RAIL_CARD',
  TRAVELCARD_TICKET: 'TRAVELCARD_TICKET',
  TERMS: 'TERMS',
  CONFIRM_BTN_TEXT: 'CONFIRM_BTN_TEXT',
  NEXT: 'NEXT',
  SEE_FARE_DETAILS: 'SEE_FARE_DETAILS',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  FARE_DETAILS: 'FARE_DETAILS',
  ADD: 'ADD',
  EDIT: 'EDIT',
  RESET: 'RESET',
  NO_RAILCARD_SELECTED: 'NO_RAILCARD_SELECTED',
  TICKET_COLLECTION_REFERENCE: 'Ticket collection reference number',
  LOCATIONS: 'LOCATIONS',
  VENDING_MACHINES: 'VENDING_MACHINES',
  THINGS_NEEDED: 'THINGS_NEEDED',
  REFERENCE: 'REFERENCE',
  CARD: 'CARD',
  STEPS: 'STEPS',
  INSERT_CARD: 'INSERT_CARD',
  ENTER_REFERENCE: 'ENTER_REFERENCE',
  PRINTED_TICKETS_IN_TRAY: 'PRINTED_TICKETS_IN_TRAY',
  PRINT_TICKETS: 'PRINT_TICKETS',
  TICKET_COLLECTION_RULES_EXCEPT: 'TICKET_COLLECTION_RULES_EXCEPT',
  TICKET_COLLECTION_RULES: 'TICKET_COLLECTION_RULES',
};

export const IRailSearchTypeEnum = SearchType;

export const searchTypeOptions = (isOpenReturnHidden: boolean): IKeyLabelPairWithVisibilityInfo[] => [
  {
    backendKey: SearchType.RETURN,
    labelKey: railTranslationKeys.RAIL_SEARCH_FORM_SEARCH_TYPE_ROUND_TRIP,
    isHidden: false,
  },
  {
    backendKey: SearchType.ONE_WAY,
    labelKey: railTranslationKeys.RAIL_SEARCH_FORM_SEARCH_TYPE_ONE_WAY,
    isHidden: false,
  },
  {
    backendKey: SearchType.OPEN_RETURN,
    labelKey: railTranslationKeys.RAIL_SEARCH_FORM_SEARCH_TYPE_OPEN_RETURN,
    isHidden: isOpenReturnHidden,
  },
];

export const railTimeTypes: IKeyLabelPair[] = [
  {
    backendKey: IRailTimeTypeEnum.DEPARTS_AFTER,
    labelKey: railTranslationKeys.LEAVING_AT,
  },
  {
    backendKey: IRailTimeTypeEnum.ARRIVES_BEFORE,
    labelKey: railTranslationKeys.ARRIVING_BY,
  },
];

export const emptyRailSegmentLocation: IRailSuggestion = {
  type: '',
  name: '',
  location: '',
  state: '',
  country: '',
  data: '',
  sessionToken: '',
  stationReferenceId: '',
  cityName: '',
  stationType: IRailStationStationTypeEnum.Individual,
  thirdParty: RailThirdParty.UnknownThirdParty,
};

export const railAlternativesSortOrder = ['Advance', 'Anytime'];

export const sortedAvailableTravelClasses = [RailTravelClassV1.STANDARD, RailTravelClassV1.FIRST];

export const sortedAvailableTravelClassesForAmtrak = [
  RailTravelClassV1.COACH,
  RailTravelClassV1.BUSINESS,
  RailTravelClassV1.FIRST,
];

export const ISectionAlternativeCategoryEnum = SectionAlternativeCategory;
export const IRailThirdPartyEnum = RailThirdParty;
export const UNKNOWN_RAIL_OPERATOR = 'Unknown Operator';

// Could define keys and re-use in map definition too

export const ticketDeliveryDetails = {
  [DeliveryOption.ELECTRONIC_TICKET]: {
    title: defineMessage('E-Ticket'),
    description: defineMessage(
      'Your e-ticket(s) will be sent to your email and will be viewable on the mobile app, if available. You will also receive an itinerary confirmation separately.',
    ),
    helperText: defineMessage('How to use e-tickets'),
    icon: 'RailETicketCharacter',
  },
  [DeliveryOption.KIOSK]: {
    title: defineMessage('Ticket on demand'),
    description: defineMessage(
      'You will need your ticket collection reference and a valid payment card to collect your tickets from the station window or kiosk.',
    ),
    helperText: defineMessage('Ticket collections rules'),
  },
  [DeliveryOption.UNRECOGNIZED]: {
    title: defineMessage('Unrecognized option'),
    description: defineMessage('There is something wrong'),
    helperText: '',
  },
  [DeliveryOption.UNKNOWN_DELIVERY_OPTION]: {
    title: defineMessage('Unknown option'),
    description: defineMessage('Something went wrong'),
    helperText: '',
  },
};

export const amtrakTicketDeliveryDetails = {
  ...ticketDeliveryDetails,
  [DeliveryOption.ELECTRONIC_TICKET]: {
    title: defineMessage('E-Ticket'),
    description: defineMessage(
      'Amtrak will send your e-ticket(s) to your email. You will also receive a confirmation of the itinerary from us.',
    ),
    helperText: '',
    icon: 'RailETicketCharacter',
  },
};

/**
 * Please refer to the following ticket regarding latest updates
 * to Terms & Conditions links updates
 *
 * https://spotnana.atlassian.net/browse/ST-44506
 */
export const nationalRailConditions = 'https://www.nationalrail.co.uk/National%20Rail%20Conditions%20of%20Travel.pdf';
export const trainlineNationalRailConditions =
  'https://static.trainlinecontent.com/content/WEB/documents/NationalRail_ConditionsOfTravel.pdf';
export const amtrakTermsAndConditions = 'https://www.amtrak.com/terms-and-conditions.html';

export const amtrakModifyWithAgencyReferenceUrl = 'https://www.amtrak.com/home.html';

export const railETicketRulesUrl = 'https://www.thetrainline.com/information/digital-tickets';

export const ticketCollectionRulesUrl = 'https://support.thetrainline.com/en/support/solutions/articles/78000000556';

export type IRailTimeType = keyof typeof IRailTimeTypeEnum;

export const ACELA_RAIL_CARRIER_NAME = 'Acela';
export const AMTRAK_RAIL_CARRIER_NAME = 'Amtrak';
export const RENFE_RAIL_CARRIER_NAME = 'Renfe';
export const AVLO_RAIL_TRANSPORT_NAME = 'avlo';

export const EU_RAIL_FLEXIBILITY_TYPES: EURailFlexibility[] = ['nonflexi', 'semiflexi', 'flexi'];

export const US_RAIL_BOOKING_VENDOR_INVENTORY_NAME = 'Amtrak';
export const US_RAIL_BOOKING_VENDOR_NAME = 'Amtrak';

export const UK_RAIL_BOOKING_VENDOR_INVENTORY_NAME = 'Atoc';
export const UK_RAIL_BOOKING_VENDOR_NAME = 'ATOC';

export const layoutItems = {
  [RailLayoutItemTypeEnum.LuggageCompartment]: defineMessage('Luggage'),
  [RailLayoutItemTypeEnum.StairsDown]: defineMessage('Stairs down'),
  [RailLayoutItemTypeEnum.StairsUp]: defineMessage('Stairs up'),
  [RailLayoutItemTypeEnum.Table]: defineMessage('Table'),
  [RailLayoutItemTypeEnum.Toilet]: defineMessage('WC'),
  [RailLayoutItemTypeEnum.Seat]: defineMessage('Seat'),
  [RailSeatTypeEnum.PAID]: defineMessage('Paid'),
  [RailSeatTypeEnum.FREE]: defineMessage('Free'),
  [RailSeatTypeEnum.SELECTED]: defineMessage('Selected'),
  [RailSeatTypeEnum.UNAVAILABLE]: defineMessage('Unavailable'),
};

export const viaFilterEmptyState: IViaFilterState<IRailSuggestion> = {
  type: IViaFilterType.NO_SELECTION,
  location: emptyRailSegmentLocation,
};

export enum RailBookingAreaEnum {
  'UK' = 'UK',
  'GB' = 'GB',
  'US' = 'US',
  'EU' = 'EU',
}

export enum RadioGroupValueEnum {
  'auto' = 'auto',
  'choose-a-seat' = 'choose-a-seat',
}

export const VENDORS_DO_NOT_SUPPORT_AUTO_SEAT_RESERVATION = ['DB PST', 'DB'];

export const RAIL_MULTIPAX_AREAS: string[] = [RailBookingAreaEnum.GB];
export const RAIL_MULTIPAX_NAMED_TRAVELERS_AREAS: string[] = [RailBookingAreaEnum.EU];
export const RAIL_MULTIPAX_UNNAMED_TRAVELERS_AREAS: string[] = [RailBookingAreaEnum.GB];
export const RAIL_MULTIPAX_MAX_PAX = 9;
export const YOUTH_AGE_RANGE: Record<'min' | 'max', number> = {
  min: 0,
  max: 25,
};
export const ADULT_AGE_RANGE: Record<'min' | 'max', number> = {
  min: 26,
  max: 59,
};
export const INFANT_MAX_AGE = 2;
export const CHILD_MAX_AGE = 15;
export const SMALL_CHILD_MAX_AGE = 4;
export const MIN_RESERVED_SEAT_CHILD_AGE = 5;
export const RAIL_SEARCH_PRIMARY_TRAVELER_MIN_AGE = 12;

export const DeckTypeToTranslationMap: { [key in RailCarriageDeckTypeEnum]: string } = {
  UPPER_DECK: defineMessage('Upper Deck'),
  LOWER_DECK: defineMessage('Lower Deck'),
};

export const DeckTypeLabelToTranslationMap: { [key: string]: string } = {
  business: defineMessage('Business Première'),
  silent: defineMessage('Silent'),
};

/** Search error codes along with their corresponding titles and messages for the error modal */
export enum SearchAPIErrorCodes {
  DEFAULT = 'DEFAULT',
  ITINERARY_FARE_EXPIRED = 'ITINERARY_FARE_EXPIRED',
  TRAIN_TIMETABLE_NOT_PUBLISHED = 'TRAIN_TIMETABLE_NOT_PUBLISHED',
  RAIL_ROUTE_NOT_SUPPORTED = 'RAIL_ROUTE_NOT_SUPPORTED',
}

export const searchAPIErrorModalTitleMap: Record<string, string> = {
  [SearchAPIErrorCodes.DEFAULT]: defineMessage('Sorry, something went wrong'),
  [SearchAPIErrorCodes.ITINERARY_FARE_EXPIRED]: defineMessage('Sorry, your fare has expired'),
  [SearchAPIErrorCodes.TRAIN_TIMETABLE_NOT_PUBLISHED]: defineMessage("Sorry, the timetable hasn't been published yet!"),
  [SearchAPIErrorCodes.RAIL_ROUTE_NOT_SUPPORTED]: defineMessage("Sorry! This route isn't available yet"),
};

export const searchAPIErrorModalMessageMap: Record<string, string> = {
  [SearchAPIErrorCodes.DEFAULT]: defineMessage('Please try again later'),
  [SearchAPIErrorCodes.ITINERARY_FARE_EXPIRED]: defineMessage(
    'Your chosen rail itinerary has expired and may not be available now. Please refresh your rail search.',
  ),
  [SearchAPIErrorCodes.TRAIN_TIMETABLE_NOT_PUBLISHED]: defineMessage(
    "Sorry, tickets are not yet available as the timetable hasn't been published. Please modify your dates or check again at a later date.",
  ),
  [SearchAPIErrorCodes.RAIL_ROUTE_NOT_SUPPORTED]: defineMessage(
    'Your search is across regions that are not supported. Alter the origin or destination and search separately.',
  ),
};

/** Checkout error codes along with their corresponding titles and messages for the error modal */
export enum CheckoutAPIErrorCodes {
  DEFAULT = 'DEFAULT',
  ITINERARY_FARE_EXPIRED = 'ITINERARY_FARE_EXPIRED',
  CARD_DECLINED = 'CARD_DECLINED',
}

export const checkoutAPIErrorModalTitleMap: Record<string, string> = {
  [CheckoutAPIErrorCodes.DEFAULT]: defineMessage('Sorry, something went wrong'),
  [CheckoutAPIErrorCodes.ITINERARY_FARE_EXPIRED]: defineMessage('Sorry, your fare has expired'),
  [CheckoutAPIErrorCodes.CARD_DECLINED]: defineMessage('Sorry, your card has been declined'),
};

export const checkoutAPIErrorModalMessageMap: Record<string, string> = {
  [CheckoutAPIErrorCodes.DEFAULT]: defineMessage('Please try again later'),
  [CheckoutAPIErrorCodes.ITINERARY_FARE_EXPIRED]: defineMessage(
    'Your chosen rail itinerary has expired and may not be available now. Please refresh your rail search.',
  ),
};

export const RailTravelClassLabels = {
  [RailTravelClassV1.UNKNOWN]: defineMessage('Unkown'),
  [RailTravelClassV1.FIRST]: defineMessage('1st class'),
  [RailTravelClassV1.STANDARD]: defineMessage('Standard'),
  [RailTravelClassV1.BUSINESS]: defineMessage('Business'),
  [RailTravelClassV1.SLEEPER]: defineMessage('Sleeper'),
  [RailTravelClassV1.STANDARD_PREMIUM]: defineMessage('Premium standard'),
  [RailTravelClassV1.BUSINESS_PREMIUM]: defineMessage('Premium business'),
  [RailTravelClassV1.COACH]: defineMessage('Coach'),
  [RailTravelClassV1.ROOM]: defineMessage('Room'),
  [RailTravelClassV1.EXECUTIVE]: defineMessage('Executive'),
  [RailTravelClassV1.UNRECOGNIZED]: defineMessage('Unkown'),
};

export const RailPassengerTypeMap: Record<string, string> = {
  [PassengerType.PASSENGER_TYPE_UNKNOWN]: defineMessage('Unknown'),
  [PassengerType.UNRECOGNIZED]: defineMessage('Unknown'),
  [PassengerType.ADULT]: defineMessage('Adult'),
  [PassengerType.CHILD]: defineMessage('Child'),
  [PassengerType.INFANT]: defineMessage('Infant'),
  [PassengerType.YOUTH]: defineMessage('Youth'),
  [PassengerType.SENIOR]: defineMessage('Senior'),
};

export const splitTicketInfoWebPageUrl =
  'https://support.thetrainline.com/en/support/solutions/articles/78000000442-splitsave';
export const groupSaveInfoWebPageUrl =
  'https://support.thetrainline.com/en/support/solutions/articles/78000000475-how-do-i-apply-a-groupsave-discount-';
