import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FareBrandOptionRating = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 22 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.72537 5.9085L1.85535 6.7665L1.78006 6.78026C1.01719 6.94846 0.727566 7.90928 1.29994 8.47021L5.55087 12.636L4.54712 18.5198L4.53715 18.5936C4.45956 19.3742 5.28903 19.9479 6.00014 19.5721L11.2499 16.7975L16.4996 19.5721L16.5666 19.6046C17.2841 19.9218 18.0879 19.3126 17.9526 18.5198L16.9484 12.636L21.1998 8.47021L21.2525 8.41472C21.7697 7.82923 21.4374 6.88232 20.6444 6.7665L14.7739 5.9085L12.1475 0.559304C11.7814 -0.186435 10.7183 -0.186435 10.3522 0.559304L7.72537 5.9085ZM11.2499 3.26896L13.2107 7.26315L13.2498 7.3352C13.4032 7.59215 13.6649 7.76829 13.9639 7.81196L18.3584 8.45396L15.1749 11.5742L15.1191 11.6336C14.9241 11.8586 14.8386 12.1604 14.8891 12.4567L15.6394 16.855L11.7171 14.7823L11.6361 14.744C11.3617 14.6291 11.0484 14.6419 10.7826 14.7823L6.85987 16.855L7.61062 12.4567L7.62106 12.3758C7.64709 12.0792 7.53946 11.7846 7.3248 11.5742L4.14087 8.45396L8.53589 7.81196C8.86204 7.76432 9.14377 7.55903 9.28903 7.26315L11.2499 3.26896Z", fill: themeEnrichedPrimaryColor })));
};
FareBrandOptionRating.defaultProps = defaultIconProps;
export default FareBrandOptionRating;
