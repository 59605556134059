import { FC, ReactNode } from 'react';
import { Button } from '../../Button';
import { Surface } from '../../Surface';
import { clsx } from '../../utils';
import { SwipeableDrawerActionConfig } from '../SwipeableDrawer';
import { drawer_footer, footer_container, sticky_footer } from './styles';

export type SwipeableDrawerFooterConfig =
  | {
      primaryAction: SwipeableDrawerActionConfig;
      secondaryAction?: SwipeableDrawerActionConfig;
    }
  | {
      content: ReactNode;
    };

export const SwipeableDrawerFooter: FC<SwipeableDrawerFooterConfig> = (props) => {
  if ('content' in props) {
    return (
      <div css={drawer_footer} className={clsx('BlocksDrawer-footer')}>
        {props.content}
      </div>
    );
  }

  const { primaryAction, secondaryAction } = props;

  return (
    <Surface variant="base" shadow="scroll-bottom" css={sticky_footer} className={clsx('BlocksDrawer-footer')}>
      <div css={footer_container}>
        {!!secondaryAction && (
          <Button
            variant="outline"
            size="large"
            disabled={secondaryAction.disabled}
            loading={secondaryAction.loading}
            onClick={secondaryAction.onClick}
            fullWidth={secondaryAction.fullWidth}
          >
            {secondaryAction.text}
          </Button>
        )}
        <Button
          variant="primary"
          size="large"
          disabled={primaryAction.disabled}
          loading={primaryAction.loading}
          onClick={primaryAction.onClick}
          fullWidth={primaryAction.fullWidth}
        >
          {primaryAction.text}
        </Button>
      </div>
    </Surface>
  );
};
