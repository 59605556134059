/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Type of hotel amenity
 * @export
 * @interface HotelAmenities
 */
export interface HotelAmenities {
    /**
     * 
     * @type {string}
     * @memberof HotelAmenities
     */
    'type'?: HotelAmenitiesTypeEnum;
    /**
     * Amenity description
     * @type {string}
     * @memberof HotelAmenities
     */
    'additionalInfo'?: string;
    /**
     * Is Amenity complimentary
     * @type {boolean}
     * @memberof HotelAmenities
     */
    'complimentary'?: boolean;
}

export const HotelAmenitiesTypeEnum = {
    TwentyFourHourFrontDesk: 'TWENTY_FOUR_HOUR_FRONT_DESK',
    TwentyFourHourRoomService: 'TWENTY_FOUR_HOUR_ROOM_SERVICE',
    TwentyFourHourSecurity: 'TWENTY_FOUR_HOUR_SECURITY',
    AdjoiningRooms: 'ADJOINING_ROOMS',
    AirConditioning: 'AIR_CONDITIONING',
    AirlineDesk: 'AIRLINE_DESK',
    AtmCashMachine: 'ATM_CASH_MACHINE',
    BabySitting: 'BABY_SITTING',
    BbqPicnicArea: 'BBQ_PICNIC_AREA',
    BilingualStaff: 'BILINGUAL_STAFF',
    Bookstore: 'BOOKSTORE',
    BoutiquesStores: 'BOUTIQUES_STORES',
    BrailedElevators: 'BRAILED_ELEVATORS',
    BusinessLibrary: 'BUSINESS_LIBRARY',
    CarRentalDesk: 'CAR_RENTAL_DESK',
    Casino: 'CASINO',
    CheckCashingPolicy: 'CHECK_CASHING_POLICY',
    CheckInKiosk: 'CHECK_IN_KIOSK',
    CocktailLounge: 'COCKTAIL_LOUNGE',
    CoffeeShop: 'COFFEE_SHOP',
    CoinOperatedLaundry: 'COIN_OPERATED_LAUNDRY',
    ConciergeDesk: 'CONCIERGE_DESK',
    ConciergeFloor: 'CONCIERGE_FLOOR',
    ConferenceFacilities: 'CONFERENCE_FACILITIES',
    Courtyard: 'COURTYARD',
    CurrencyExchange: 'CURRENCY_EXCHANGE',
    DeskWithElectricalOutlet: 'DESK_WITH_ELECTRICAL_OUTLET',
    DoctorOnCall: 'DOCTOR_ON_CALL',
    DoorMan: 'DOOR_MAN',
    DrivingRange: 'DRIVING_RANGE',
    DrugstorePharmacy: 'DRUGSTORE_PHARMACY',
    DutyFreeShop: 'DUTY_FREE_SHOP',
    Elevators: 'ELEVATORS',
    ExecutiveFloor: 'EXECUTIVE_FLOOR',
    ExerciseGym: 'EXERCISE_GYM',
    ExpressCheckIn: 'EXPRESS_CHECK_IN',
    ExpressCheckOut: 'EXPRESS_CHECK_OUT',
    FamilyPlan: 'FAMILY_PLAN',
    Florist: 'FLORIST',
    Folios: 'FOLIOS',
    FreeAirportShuttle: 'FREE_AIRPORT_SHUTTLE',
    FreeParking: 'FREE_PARKING',
    FreeTransportation: 'FREE_TRANSPORTATION',
    GameRoom: 'GAME_ROOM',
    GiftNewsStand: 'GIFT_NEWS_STAND',
    HairdresserBarber: 'HAIRDRESSER_BARBER',
    AccessibleFacilities: 'ACCESSIBLE_FACILITIES',
    HealthClub: 'HEALTH_CLUB',
    HeatedPool: 'HEATED_POOL',
    HousekeepingDaily: 'HOUSEKEEPING_DAILY',
    HousekeepingWeekly: 'HOUSEKEEPING_WEEKLY',
    IceMachine: 'ICE_MACHINE',
    IndoorParking: 'INDOOR_PARKING',
    IndoorPool: 'INDOOR_POOL',
    Jacuzzi: 'JACUZZI',
    JoggingTrack: 'JOGGING_TRACK',
    Kennels: 'KENNELS',
    LaundryValetService: 'LAUNDRY_VALET_SERVICE',
    LiquorStore: 'LIQUOR_STORE',
    LiveEntertainment: 'LIVE_ENTERTAINMENT',
    MassageServices: 'MASSAGE_SERVICES',
    Nightclub: 'NIGHTCLUB',
    OffSiteParking: 'OFF_SITE_PARKING',
    OnSiteParking: 'ON_SITE_PARKING',
    OutdoorParking: 'OUTDOOR_PARKING',
    OutdoorPool: 'OUTDOOR_POOL',
    PackageParcelServices: 'PACKAGE_PARCEL_SERVICES',
    Parking: 'PARKING',
    PhotocopyCenter: 'PHOTOCOPY_CENTER',
    Playground: 'PLAYGROUND',
    Pool: 'POOL',
    PoolsideSnackBar: 'POOLSIDE_SNACK_BAR',
    PublicAddressSystem: 'PUBLIC_ADDRESS_SYSTEM',
    RampAccess: 'RAMP_ACCESS',
    RecreationalVehicleParking: 'RECREATIONAL_VEHICLE_PARKING',
    Restaurant: 'RESTAURANT',
    RoomService: 'ROOM_SERVICE',
    SafeDepositBox: 'SAFE_DEPOSIT_BOX',
    Sauna: 'SAUNA',
    Security: 'SECURITY',
    ShoeShineStand: 'SHOE_SHINE_STAND',
    ShoppingMall: 'SHOPPING_MALL',
    Solarium: 'SOLARIUM',
    Spa: 'SPA',
    SportsBar: 'SPORTS_BAR',
    SteamBath: 'STEAM_BATH',
    StorageSpace: 'STORAGE_SPACE',
    SundryConvenienceStore: 'SUNDRY_CONVENIENCE_STORE',
    TechnicalConcierge: 'TECHNICAL_CONCIERGE',
    TheatreDesk: 'THEATRE_DESK',
    TourSightseeingDesk: 'TOUR_SIGHTSEEING_DESK',
    TranslationServices: 'TRANSLATION_SERVICES',
    TravelAgency: 'TRAVEL_AGENCY',
    TruckParking: 'TRUCK_PARKING',
    ValetCleaning: 'VALET_CLEANING',
    DryCleaning: 'DRY_CLEANING',
    ValetParking: 'VALET_PARKING',
    VendingMachines: 'VENDING_MACHINES',
    VideoTapes: 'VIDEO_TAPES',
    WakeupService: 'WAKEUP_SERVICE',
    WheelchairAccess: 'WHEELCHAIR_ACCESS',
    Whirlpool: 'WHIRLPOOL',
    MultilingualStaff: 'MULTILINGUAL_STAFF',
    WeddingServices: 'WEDDING_SERVICES',
    BanquetFacilities: 'BANQUET_FACILITIES',
    BellStaffPorter: 'BELL_STAFF_PORTER',
    BeautyShopSalon: 'BEAUTY_SHOP_SALON',
    ComplimentarySelfServiceLaundry: 'COMPLIMENTARY_SELF_SERVICE_LAUNDRY',
    DirectDialTelephone: 'DIRECT_DIAL_TELEPHONE',
    FemaleTravelerRoomFloor: 'FEMALE_TRAVELER_ROOM_FLOOR',
    Pharmacy: 'PHARMACY',
    Stables: 'STABLES',
    OneTwentyAc: 'ONE_TWENTY_AC',
    OneTwentyDc: 'ONE_TWENTY_DC',
    TwoTwentyAc: 'TWO_TWENTY_AC',
    AccessibleParking: 'ACCESSIBLE_PARKING',
    TwoTwentyDc: 'TWO_TWENTY_DC',
    BarbequeGrills: 'BARBEQUE_GRILLS',
    WomensClothing: 'WOMENS_CLOTHING',
    MensClothing: 'MENS_CLOTHING',
    ChildrensClothing: 'CHILDRENS_CLOTHING',
    ShopsAndCommercialServices: 'SHOPS_AND_COMMERCIAL_SERVICES',
    VideoGames: 'VIDEO_GAMES',
    SportsBarOpenForLunch: 'SPORTS_BAR_OPEN_FOR_LUNCH',
    SportsBarOpenForDinner: 'SPORTS_BAR_OPEN_FOR_DINNER',
    RoomServiceFullMenu: 'ROOM_SERVICE_FULL_MENU',
    RoomServiceLimitedMenu: 'ROOM_SERVICE_LIMITED_MENU',
    RoomServiceLimitedHours: 'ROOM_SERVICE_LIMITED_HOURS',
    ValetSameDayDryCleaning: 'VALET_SAME_DAY_DRY_CLEANING',
    BodyScrub: 'BODY_SCRUB',
    BodyWrap: 'BODY_WRAP',
    PublicAreaAirConditioned: 'PUBLIC_AREA_AIR_CONDITIONED',
    EfolioAvailableToCompany: 'EFOLIO_AVAILABLE_TO_COMPANY',
    IndividualEfolioAvailable: 'INDIVIDUAL_EFOLIO_AVAILABLE',
    VideoReviewBilling: 'VIDEO_REVIEW_BILLING',
    ButlerService: 'BUTLER_SERVICE',
    ComplimentaryInRoomCoffeeOrTea: 'COMPLIMENTARY_IN_ROOM_COFFEE_OR_TEA',
    ComplimentaryBuffetBreakfast: 'COMPLIMENTARY_BUFFET_BREAKFAST',
    ComplimentaryCocktails: 'COMPLIMENTARY_COCKTAILS',
    ComplimentaryCoffeeInLobby: 'COMPLIMENTARY_COFFEE_IN_LOBBY',
    ComplimentaryContinentalBreakfast: 'COMPLIMENTARY_CONTINENTAL_BREAKFAST',
    ComplimentaryFullAmericanBreakfast: 'COMPLIMENTARY_FULL_AMERICAN_BREAKFAST',
    DinnerDeliveryServiceFromLocalRestaurant: 'DINNER_DELIVERY_SERVICE_FROM_LOCAL_RESTAURANT',
    ComplimentaryNewspaperDeliveredToRoom: 'COMPLIMENTARY_NEWSPAPER_DELIVERED_TO_ROOM',
    ComplimentaryNewspaperInLobby: 'COMPLIMENTARY_NEWSPAPER_IN_LOBBY',
    ComplimentaryShoeshine: 'COMPLIMENTARY_SHOESHINE',
    EveningReception: 'EVENING_RECEPTION',
    FrontDesk: 'FRONT_DESK',
    GroceryShoppingServiceAvailable: 'GROCERY_SHOPPING_SERVICE_AVAILABLE',
    HalalFoodAvailable: 'HALAL_FOOD_AVAILABLE',
    KosherFoodAvailable: 'KOSHER_FOOD_AVAILABLE',
    LimousineService: 'LIMOUSINE_SERVICE',
    ManagersReception: 'MANAGERS_RECEPTION',
    MedicalFacilitiesService: 'MEDICAL_FACILITIES_SERVICE',
    TelephoneJackAdaptorAvailable: 'TELEPHONE_JACK_ADAPTOR_AVAILABLE',
    AllInclusiveMealPlan: 'ALL_INCLUSIVE_MEAL_PLAN',
    BuffetBreakfast: 'BUFFET_BREAKFAST',
    CommunalBarArea: 'COMMUNAL_BAR_AREA',
    ContinentalBreakfast: 'CONTINENTAL_BREAKFAST',
    FullMealPlan: 'FULL_MEAL_PLAN',
    FullAmericanBreakfast: 'FULL_AMERICAN_BREAKFAST',
    MealPlanAvailable: 'MEAL_PLAN_AVAILABLE',
    ModifiedAmericanMealPlan: 'MODIFIED_AMERICAN_MEAL_PLAN',
    FoodAndBeverageOutlets: 'FOOD_AND_BEVERAGE_OUTLETS',
    LoungesBars: 'LOUNGES_BARS',
    BarberShop: 'BARBER_SHOP',
    VideoCheckout: 'VIDEO_CHECKOUT',
    OnsiteLaundry: 'ONSITE_LAUNDRY',
    TwentyFourHourFoodAndBeverageKiosk: 'TWENTY_FOUR_HOUR_FOOD_AND_BEVERAGE_KIOSK',
    ConciergeLounge: 'CONCIERGE_LOUNGE',
    ParkingFeeManagedByHotel: 'PARKING_FEE_MANAGED_BY_HOTEL',
    Transportation: 'TRANSPORTATION',
    BreakfastServedInRestaurant: 'BREAKFAST_SERVED_IN_RESTAURANT',
    LunchServedInRestaurant: 'LUNCH_SERVED_IN_RESTAURANT',
    DinnerServedInRestaurant: 'DINNER_SERVED_IN_RESTAURANT',
    FullServiceHousekeeping: 'FULL_SERVICE_HOUSEKEEPING',
    LimitedServiceHousekeeping: 'LIMITED_SERVICE_HOUSEKEEPING',
    HighSpeedInternetAccessForLaptopInPublicAreas: 'HIGH_SPEED_INTERNET_ACCESS_FOR_LAPTOP_IN_PUBLIC_AREAS',
    WirelessInternetConnectionInPublicAreas: 'WIRELESS_INTERNET_CONNECTION_IN_PUBLIC_AREAS',
    AdditionalServicesAmenitiesFacilitiesOnProperty: 'ADDITIONAL_SERVICES_AMENITIES_FACILITIES_ON_PROPERTY',
    TransportationServicesLocalArea: 'TRANSPORTATION_SERVICES_LOCAL_AREA',
    TransportationServicesLocalOffice: 'TRANSPORTATION_SERVICES_LOCAL_OFFICE',
    DvdVideoRental: 'DVD_VIDEO_RENTAL',
    ParkingLot: 'PARKING_LOT',
    ParkingDeck: 'PARKING_DECK',
    StreetSideParking: 'STREET_SIDE_PARKING',
    CocktailLoungeWithEntertainment: 'COCKTAIL_LOUNGE_WITH_ENTERTAINMENT',
    CocktailLoungeWithLightFare: 'COCKTAIL_LOUNGE_WITH_LIGHT_FARE',
    MotorcycleParking: 'MOTORCYCLE_PARKING',
    PhoneServices: 'PHONE_SERVICES',
    Ballroom: 'BALLROOM',
    BusParking: 'BUS_PARKING',
    ChildrensPlayArea: 'CHILDRENS_PLAY_AREA',
    ChildrensNursery: 'CHILDRENS_NURSERY',
    Disco: 'DISCO',
    EarlyCheckIn: 'EARLY_CHECK_IN',
    LockerRoom: 'LOCKER_ROOM',
    NonSmokingRoomsGeneric: 'NON_SMOKING_ROOMS_GENERIC',
    TrainAccess: 'TRAIN_ACCESS',
    AerobicsInstruction: 'AEROBICS_INSTRUCTION',
    BaggageHold: 'BAGGAGE_HOLD',
    BicycleRentals: 'BICYCLE_RENTALS',
    Dietician: 'DIETICIAN',
    LateCheckOutAvailable: 'LATE_CHECK_OUT_AVAILABLE',
    PetSittingServices: 'PET_SITTING_SERVICES',
    PrayerMats: 'PRAYER_MATS',
    SportsTrainer: 'SPORTS_TRAINER',
    TurndownService: 'TURNDOWN_SERVICE',
    DvdsVideosChildren: 'DVDS_VIDEOS_CHILDREN',
    Bank: 'BANK',
    LobbyCoffeeService: 'LOBBY_COFFEE_SERVICE',
    BankingServices: 'BANKING_SERVICES',
    Stairwells: 'STAIRWELLS',
    PetAmenitiesAvailable: 'PET_AMENITIES_AVAILABLE',
    ExhibitionConventionFloor: 'EXHIBITION_CONVENTION_FLOOR',
    LongTermParking: 'LONG_TERM_PARKING',
    ChildrenNotAllowed: 'CHILDREN_NOT_ALLOWED',
    ChildrenWelcome: 'CHILDREN_WELCOME',
    CourtesyCar: 'COURTESY_CAR',
    HotelDoesNotProvidePornographicFilmsTv: 'HOTEL_DOES_NOT_PROVIDE_PORNOGRAPHIC_FILMS_TV',
    Hotspots: 'HOTSPOTS',
    FreeHighSpeedInternetConnection: 'FREE_HIGH_SPEED_INTERNET_CONNECTION',
    InternetServices: 'INTERNET_SERVICES',
    PetsAllowed: 'PETS_ALLOWED',
    GourmetHighlights: 'GOURMET_HIGHLIGHTS',
    CateringServices: 'CATERING_SERVICES',
    ComplimentaryBreakfast: 'COMPLIMENTARY_BREAKFAST',
    BusinessCenter: 'BUSINESS_CENTER',
    BusinessServices: 'BUSINESS_SERVICES',
    SecuredParking: 'SECURED_PARKING',
    Racquetball: 'RACQUETBALL',
    SnowSports: 'SNOW_SPORTS',
    TennisCourt: 'TENNIS_COURT',
    WaterSports: 'WATER_SPORTS',
    ChildPrograms: 'CHILD_PROGRAMS',
    Golf: 'GOLF',
    HorsebackRiding: 'HORSEBACK_RIDING',
    Oceanfront: 'OCEANFRONT',
    Beachfront: 'BEACHFRONT',
    HairDryer: 'HAIR_DRYER',
    IroningBoard: 'IRONING_BOARD',
    HeatedGuestRooms: 'HEATED_GUEST_ROOMS',
    Toilet: 'TOILET',
    Parlor: 'PARLOR',
    VideoGamePlayer: 'VIDEO_GAME_PLAYER',
    Thalassotherapy: 'THALASSOTHERAPY',
    PrivateDiningForGroups: 'PRIVATE_DINING_FOR_GROUPS',
    HearingImpairedServices: 'HEARING_IMPAIRED_SERVICES',
    CarryoutBreakfast: 'CARRYOUT_BREAKFAST',
    DeluxeContinentalBreakfast: 'DELUXE_CONTINENTAL_BREAKFAST',
    HotContinentalBreakfast: 'HOT_CONTINENTAL_BREAKFAST',
    HotBreakfast: 'HOT_BREAKFAST',
    PrivatePool: 'PRIVATE_POOL',
    ConnectingRooms: 'CONNECTING_ROOMS',
    DataPort: 'DATA_PORT',
    ExteriorCorridors: 'EXTERIOR_CORRIDORS',
    GulfView: 'GULF_VIEW',
    AccessibleRooms: 'ACCESSIBLE_ROOMS',
    HighSpeedInternetAccess: 'HIGH_SPEED_INTERNET_ACCESS',
    InteriorCorridors: 'INTERIOR_CORRIDORS',
    HighSpeedWireless: 'HIGH_SPEED_WIRELESS',
    Kitchenette: 'KITCHENETTE',
    PrivateBathOrShower: 'PRIVATE_BATH_OR_SHOWER',
    FireSafetyCompliant: 'FIRE_SAFETY_COMPLIANT',
    WelcomeDrink: 'WELCOME_DRINK',
    BoardingPassPrintOutAvailable: 'BOARDING_PASS_PRINT_OUT_AVAILABLE',
    PrintingServicesAvailable: 'PRINTING_SERVICES_AVAILABLE',
    AllPublicAreasNonSmoking: 'ALL_PUBLIC_AREAS_NON_SMOKING',
    MeetingRooms: 'MEETING_ROOMS',
    MoviesInRoom: 'MOVIES_IN_ROOM',
    SecretarialService: 'SECRETARIAL_SERVICE',
    SnowSkiing: 'SNOW_SKIING',
    WaterSkiing: 'WATER_SKIING',
    FaxService: 'FAX_SERVICE',
    GreatRoom: 'GREAT_ROOM',
    Lobby: 'LOBBY',
    MultiplePhoneLinesBilledSeparately: 'MULTIPLE_PHONE_LINES_BILLED_SEPARATELY',
    Umbrellas: 'UMBRELLAS',
    GasStation: 'GAS_STATION',
    GroceryStore: 'GROCERY_STORE',
    TwentyFourHourCoffeeShop: 'TWENTY_FOUR_HOUR_COFFEE_SHOP',
    AirportShuttleService: 'AIRPORT_SHUTTLE_SERVICE',
    LuggageService: 'LUGGAGE_SERVICE',
    PianoBar: 'PIANO_BAR',
    VipSecurity: 'VIP_SECURITY',
    ComplimentaryWirelessInternet: 'COMPLIMENTARY_WIRELESS_INTERNET',
    ConciergeBreakfast: 'CONCIERGE_BREAKFAST',
    SameGenderFloor: 'SAME_GENDER_FLOOR',
    ChildrenPrograms: 'CHILDREN_PROGRAMS',
    BuildingMeetsLocalStateAndCountryBuildingCodes: 'BUILDING_MEETS_LOCAL_STATE_AND_COUNTRY_BUILDING_CODES',
    InternetBrowserOnTv: 'INTERNET_BROWSER_ON_TV',
    Newspaper: 'NEWSPAPER',
    ParkingControlledAccessGatesToEnterParkingArea: 'PARKING_CONTROLLED_ACCESS_GATES_TO_ENTER_PARKING_AREA',
    HotelSafeDepositBoxNotRoomSafeBox: 'HOTEL_SAFE_DEPOSIT_BOX_NOT_ROOM_SAFE_BOX',
    StorageSpaceAvailableFee: 'STORAGE_SPACE_AVAILABLE_FEE',
    TypeOfEntrancesToGuestRooms: 'TYPE_OF_ENTRANCES_TO_GUEST_ROOMS',
    BeverageCocktail: 'BEVERAGE_COCKTAIL',
    CellPhoneRental: 'CELL_PHONE_RENTAL',
    CoffeeTea: 'COFFEE_TEA',
    EarlyCheckInGuarantee: 'EARLY_CHECK_IN_GUARANTEE',
    FoodAndBeverageDiscount: 'FOOD_AND_BEVERAGE_DISCOUNT',
    LateCheckOutGuarantee: 'LATE_CHECK_OUT_GUARANTEE',
    RoomUpgradeConfirmed: 'ROOM_UPGRADE_CONFIRMED',
    RoomUpgradeOnAvailability: 'ROOM_UPGRADE_ON_AVAILABILITY',
    ShuttleToLocalBusinesses: 'SHUTTLE_TO_LOCAL_BUSINESSES',
    ShuttleToLocalAttractions: 'SHUTTLE_TO_LOCAL_ATTRACTIONS',
    SocialHour: 'SOCIAL_HOUR',
    VideoBilling: 'VIDEO_BILLING',
    WelcomeGift: 'WELCOME_GIFT',
    HypoallergenicRooms: 'HYPOALLERGENIC_ROOMS',
    RoomAirFiltration: 'ROOM_AIR_FILTRATION',
    SmokeFreeProperty: 'SMOKE_FREE_PROPERTY',
    WaterPurificationSystemInUse: 'WATER_PURIFICATION_SYSTEM_IN_USE',
    PoolsideService: 'POOLSIDE_SERVICE',
    ClothingStore: 'CLOTHING_STORE',
    ElectricCarChargingStations: 'ELECTRIC_CAR_CHARGING_STATIONS',
    OfficeRental: 'OFFICE_RENTAL',
    Piano: 'PIANO',
    IncomingFax: 'INCOMING_FAX',
    OutgoingFax: 'OUTGOING_FAX',
    SemiPrivateSpace: 'SEMI_PRIVATE_SPACE',
    LoadingDock: 'LOADING_DOCK',
    BabyKit: 'BABY_KIT',
    ChildrensBreakfast: 'CHILDRENS_BREAKFAST',
    CloakroomService: 'CLOAKROOM_SERVICE',
    CoffeeLounge: 'COFFEE_LOUNGE',
    EventsTicketService: 'EVENTS_TICKET_SERVICE',
    LateCheckIn: 'LATE_CHECK_IN',
    LimitedParking: 'LIMITED_PARKING',
    OutdoorSummerBarCafe: 'OUTDOOR_SUMMER_BAR_CAFE',
    NoParkingAvailable: 'NO_PARKING_AVAILABLE',
    BeerGarden: 'BEER_GARDEN',
    GardenLoungeBar: 'GARDEN_LOUNGE_BAR',
    SummerTerrace: 'SUMMER_TERRACE',
    WinterTerrace: 'WINTER_TERRACE',
    RoofTerrace: 'ROOF_TERRACE',
    BeachBar: 'BEACH_BAR',
    HelicopterService: 'HELICOPTER_SERVICE',
    Ferry: 'FERRY',
    TapasBar: 'TAPAS_BAR',
    CafeBar: 'CAFE_BAR',
    SnackBar: 'SNACK_BAR',
    GuestroomWiredInternet: 'GUESTROOM_WIRED_INTERNET',
    GuestroomWirelessInternet: 'GUESTROOM_WIRELESS_INTERNET',
    FitnessCenter: 'FITNESS_CENTER',
    AlcoholicBeverages: 'ALCOHOLIC_BEVERAGES',
    NonAlcoholicBeverages: 'NON_ALCOHOLIC_BEVERAGES',
    HealthAndBeautyServices: 'HEALTH_AND_BEAUTY_SERVICES',
    LocalCalls: 'LOCAL_CALLS',
    Minibar: 'MINIBAR',
    Refrigerator: 'REFRIGERATOR',
    InRoomSafe: 'IN_ROOM_SAFE',
    SmokingRoomsAvailble: 'SMOKING_ROOMS_AVAILBLE',
    MountainView: 'MOUNTAIN_VIEW',
    PoolView: 'POOL_VIEW',
    BeachView: 'BEACH_VIEW',
    OceanView: 'OCEAN_VIEW',
    RoomsWithBalcony: 'ROOMS_WITH_BALCONY',
    FamilyRoom: 'FAMILY_ROOM',
    CribCharge: 'CRIB_CHARGE',
    RollawayAdult: 'ROLLAWAY_ADULT',
    FreeWifiInMeetingRooms: 'FREE_WIFI_IN_MEETING_ROOMS',
    EcoFriendly: 'ECO_FRIENDLY',
    ExtraPerson: 'EXTRA_PERSON',
    StaySafe: 'STAY_SAFE',
    EnhancedHygieneCleanlinessProtocols: 'ENHANCED_HYGIENE_CLEANLINESS_PROTOCOLS'
} as const;

export type HotelAmenitiesTypeEnum = typeof HotelAmenitiesTypeEnum[keyof typeof HotelAmenitiesTypeEnum];


