import { getApplicationId, useFeatureFlag } from 'obt-common';
import { useEffect, useState } from 'react';

export const BREX_REWARDS_APP_ID = '752492fa-1488-4819-bde7-3c5baad0d187';

export function useDisableBooking() {
  const [applicationId, setApplicationId] = useState('');
  const isBookingDisableEnabled = !!useFeatureFlag('FE_TMC_DISABLE_BOOKING');

  useEffect(() => {
    getApplicationId().then((appId) => {
      setApplicationId(appId ?? '');
    });
  }, []);

  // we don't want to disable both bookings for Brex travel and rewards with the same userId.
  if (isBookingDisableEnabled && applicationId === BREX_REWARDS_APP_ID) {
    return true;
  }

  return false;
}
