import uniqWith from 'lodash/uniqWith';

interface IChainObject {
  chainCode: string;
  chainName: string;
}
type IChainsMap = IChainObject[];

// TODO (Himesh): Confirm with Backend/WTMC Team for the list
// Taken from https://wtools.itn.net/code/HotelCodes.jsp?crs=2
export const hotelChainsList: IChainsMap = [
  { chainCode: 'ar', chainName: 'AC Hotels by Marriott' },
  { chainCode: 'aq', chainName: 'ATAhotels' },
  { chainCode: 'if', chainName: 'Acc-Nifos Hotels' },
  { chainCode: 'rt', chainName: 'Accor Hotels' },
  { chainCode: 'go', chainName: 'Aha Hotels' },
  { chainCode: 'ok', chainName: 'Alesia Distributn' },
  { chainCode: 'lf', chainName: 'Alila Hotels and Resorts' },
  { chainCode: 'av', chainName: 'Allegiance Services' },
  { chainCode: 'al', chainName: 'Aloft Hotels' },
  { chainCode: 'oi', chainName: 'Amadeus Linkhotel' },
  { chainCode: 'am', chainName: 'Aman Hotels' },
  { chainCode: 'aa', chainName: 'Americinns' },
  { chainCode: 'ae', chainName: 'Amerihost' },
  { chainCode: 'gn', chainName: 'Ameris by Nobile' },
  { chainCode: 'aj', chainName: 'Amerisuites' },
  { chainCode: 'an', chainName: 'Ana Hotels' },
  { chainCode: 'ax', chainName: 'Anasazi Access Hotels' },
  { chainCode: 'qu', chainName: 'Aqua Hotels and Resorts' },
  { chainCode: 'oa', chainName: 'Archer Hotels' },
  { chainCode: 'ck', chainName: 'Arlo Hotels' },
  { chainCode: 'ai', chainName: 'Armani Hotels' },
  { chainCode: 'nz', chainName: 'Ascend Collection' },
  { chainCode: 'ah', chainName: 'Aston Hotels And Resorts' },
  { chainCode: 'ac', chainName: 'Atel Hotels Network' },
  { chainCode: 'ao', chainName: 'Atlantis Hotels' },
  { chainCode: 'au', chainName: 'Auberge Resorts' },
  { chainCode: 'ak', chainName: 'Autograph Collection' },
  { chainCode: 'bi', chainName: 'Baccarat and 1 Hotels' },
  { chainCode: 'bq', chainName: 'Bachelor Quarters' },
  { chainCode: 'by', chainName: 'Banyan Tree Htls' },
  { chainCode: 'bn', chainName: 'Barcelo Hotels' },
  { chainCode: 'bu', chainName: 'Baymont Inns And Suites' },
  { chainCode: 'jb', chainName: 'Beaches Resorts' },
  { chainCode: 'oe', chainName: 'Belmond' },
  { chainCode: 'be', chainName: 'Benchmark' },
  { chainCode: 'bv', chainName: 'Best Value Inns' },
  { chainCode: 'bw', chainName: 'Best Western' },
  { chainCode: 'yh', chainName: 'Bkg Svcs by Trust' },
  { chainCode: 'ba', chainName: 'Boscolo Hotels' },
  { chainCode: 'lt', chainName: 'Brazil Hospitality' },
  { chainCode: 'bg', chainName: 'Bulgari Hotels and Resorts' },
  { chainCode: 'bt', chainName: 'Business Traveler Advantage' },
  { chainCode: 'bz', chainName: 'CMNet Hotels' },
  { chainCode: 'zw', chainName: 'CWT Pvt Content' },
  { chainCode: 'cj', chainName: 'Caesar Park Hotels' },
  { chainCode: 'ez', chainName: 'Cambria Suites' },
  { chainCode: 'cm', chainName: 'Camino Real' },
  { chainCode: 'yo', chainName: 'Candlewood Suites' },
  { chainCode: 'py', chainName: 'Canopy by Hilton' },
  { chainCode: 'un', chainName: 'Carino Hotels' },
  { chainCode: 'cw', chainName: 'Carlson Hotel Brands' },
  { chainCode: 'ih', chainName: 'Central Individual Hotels' },
  { chainCode: 'cr', chainName: 'Chakela Hotels' },
  { chainCode: 'cu', chainName: 'Charming Hotels' },
  { chainCode: 'eu', chainName: 'Chateaux And Hotels Collection' },
  { chainCode: 'ff', chainName: 'Chic Collection' },
  { chainCode: 'ec', chainName: 'Choice Brands' },
  { chainCode: 'xe', chainName: 'City Express Hotels' },
  { chainCode: 'cg', chainName: 'City Lodge' },
  { chainCode: 'cc', chainName: 'Clarion Hotels' },
  { chainCode: 'cb', chainName: 'Classic British' },
  { chainCode: 'cq', chainName: 'Club Quarters' },
  { chainCode: 'kl', chainName: 'Clubhouse Inns' },
  { chainCode: 'ct', chainName: 'Cmnet' },
  { chainCode: 'wx', chainName: 'Coast Hotels' },
  { chainCode: 'uc', chainName: 'Coastal Hotels' },
  { chainCode: 'cs', chainName: 'Colony Resorts' },
  { chainCode: 'bx', chainName: 'Columbus Reservation Svc' },
  { chainCode: 'ci', chainName: 'Comfort Inn' },
  { chainCode: 'cz', chainName: 'Comfort Suites' },
  { chainCode: 'cv', chainName: 'Como Hotels' },
  { chainCode: 'cn', chainName: 'Conrad Hotels' },
  { chainCode: 'ia', chainName: 'Corinthia Hotels' },
  { chainCode: 'cl', chainName: 'Corus Hotels' },
  { chainCode: 'cx', chainName: 'Country Inns and Suites by Carlson' },
  { chainCode: 'cy', chainName: 'Courtyard By Marriott' },
  { chainCode: 'cp', chainName: 'Crowne Plaza Hotels and Resorts' },
  { chainCode: 'sx', chainName: 'Cumulus' },
  { chainCode: 'qq', chainName: 'Curio Collection' },
  { chainCode: 'dh', chainName: 'DOT Hotels' },
  { chainCode: 'di', chainName: 'Days Inn' },
  { chainCode: 'de', chainName: 'Delta Hotels' },
  { chainCode: 'ny', chainName: 'Denihan Hospitality Group' },
  { chainCode: 'dd', chainName: 'Derag Hotels' },
  { chainCode: 'ds', chainName: 'Design Hotels' },
  { chainCode: 'dn', chainName: 'Destination Hotels' },
  { chainCode: 'du', chainName: 'Destinations Unlimited' },
  { chainCode: 'dj', chainName: 'Divan Hotels' },
  { chainCode: 'dx', chainName: 'Dolce Hotels' },
  { chainCode: 'dm', chainName: 'Domina Hotels' },
  { chainCode: 'dl', chainName: 'Doral Resorts' },
  { chainCode: 'dc', chainName: 'Dorchester Collection' },
  { chainCode: 'do', chainName: 'Dorint Hotels' },
  { chainCode: 'dt', chainName: 'Doubletree Hotels' },
  { chainCode: 'jd', chainName: 'Doyle Collection' },
  { chainCode: 'dz', chainName: 'Dream Hotels' },
  { chainCode: 'dr', chainName: 'Drury Inns' },
  { chainCode: 'dw', chainName: 'Dusit Hotels and Resorts' },
  { chainCode: 'ed', chainName: 'EDS Lodging' },
  { chainCode: 'el', chainName: 'ELEMENT HOTELS' },
  { chainCode: 'eq', chainName: 'Eaton Hotels' },
  { chainCode: 'eo', chainName: 'Econo Lodge' },
  { chainCode: 'eb', chainName: 'Edition' },
  { chainCode: 'ej', chainName: 'El San Juan Hotels' },
  { chainCode: 'es', chainName: 'Embassy Suites' },
  { chainCode: 'ep', chainName: 'Epoque Hotels' },
  { chainCode: 'vn', chainName: 'Even Hotels' },
  { chainCode: 'xc', chainName: 'Excalibur Hotel' },
  { chainCode: 'ei', chainName: 'Executive Hotels And Resorts' },
  { chainCode: 'en', chainName: 'Expedia Affiliate' },
  { chainCode: 'rn', chainName: 'Expotel Hotel Reservations' },
  { chainCode: 'ea', chainName: 'Extended Stay America' },
  { chainCode: 'xh', chainName: 'Extra Holidays' },
  { chainCode: 'fw', chainName: 'FRHI Worldwide' },
  { chainCode: 'fn', chainName: 'Fairfield Inn' },
  { chainCode: 'fa', chainName: 'Fairmont Hotels' },
  { chainCode: 'fg', chainName: 'Fastbooking' },
  { chainCode: 'fe', chainName: 'Fen Hotels' },
  { chainCode: 'fh', chainName: 'Fiesta Americana' },
  { chainCode: 'fx', chainName: 'First Hotels' },
  { chainCode: 'fl', chainName: 'Flemings Hotels' },
  { chainCode: 'fb', chainName: 'Fontainbleau Resorts' },
  { chainCode: 'fp', chainName: 'Four Points' },
  { chainCode: 'fs', chainName: 'Four Seasons Hotels' },
  { chainCode: 'fi', chainName: 'Frasers Hospitality' },
  { chainCode: 'gl', chainName: 'GHA Brands' },
  { chainCode: 'gk', chainName: 'GHS Global Hospitality' },
  { chainCode: 'yp', chainName: 'Gamma Hotels' },
  { chainCode: 'gv', chainName: 'Gansevoort Hotels' },
  { chainCode: 'ge', chainName: 'Gaylord Hotels' },
  { chainCode: 'gz', chainName: 'Genares' },
  { chainCode: 'gq', chainName: 'Genre Hotels' },
  { chainCode: 'ga', chainName: 'Global Alliance' },
  { chainCode: 'gx', chainName: 'Global Conextions' },
  { chainCode: 'gf', chainName: 'Global Hospitality Services' },
  { chainCode: 'xn', chainName: 'Global Res' },
  { chainCode: 'gt', chainName: 'Golden Tulip Hotels' },
  { chainCode: 'gp', chainName: 'Graduate Hotels' },
  { chainCode: 'xg', chainName: 'Grand City Hotels' },
  { chainCode: 'gh', chainName: 'Grand Heritage' },
  { chainCode: 'gg', chainName: 'Grand Hotels NYC' },
  { chainCode: 'gs', chainName: 'GrandStay Hospitality' },
  { chainCode: 'ft', chainName: 'Grande Devere Hotels' },
  { chainCode: 'gw', chainName: 'Great Hotels Of The World' },
  { chainCode: 'iw', chainName: 'HOTELS AND PREFERENCE' },
  { chainCode: 'hq', chainName: 'HRS' },
  { chainCode: 'er', chainName: 'Hampshire Hotels' },
  { chainCode: 'hx', chainName: 'Hampton Inn' },
  { chainCode: 'hr', chainName: 'Harrahs Casinos' },
  { chainCode: 'hv', chainName: 'Harvey Hotels' },
  { chainCode: 'bh', chainName: 'Hawthorn Suites' },
  { chainCode: 'rm', chainName: 'Hetras' },
  { chainCode: 'eh', chainName: 'Hilton Brands' },
  { chainCode: 'gi', chainName: 'Hilton Garden Inn' },
  { chainCode: 'hh', chainName: 'Hilton Hotels' },
  { chainCode: 'hl', chainName: 'Hilton International' },
  { chainCode: 'he', chainName: 'Historic Hotels' },
  { chainCode: 'hi', chainName: 'Holiday Inn' },
  { chainCode: 'ht', chainName: 'Home2suites' },
  { chainCode: 'hs', chainName: 'HomeTowne Studios' },
  { chainCode: 'hg', chainName: 'Homewood Suites By Hilton' },
  { chainCode: 'gr', chainName: 'Hospitality Solutions' },
  { chainCode: 'hk', chainName: 'Hot Key International' },
  { chainCode: 'ha', chainName: 'HotUSA' },
  { chainCode: 'in', chainName: 'Hotel Indigo' },
  { chainCode: 'vx', chainName: 'Hotel System Support Svc.' },
  { chainCode: 'hw', chainName: 'Hotel World' },
  { chainCode: 'za', chainName: 'Hotel Zaza' },
  { chainCode: 'ho', chainName: 'HotelREZ Hotels & Resorts' },
  { chainCode: 'hd', chainName: 'HotelRed' },
  { chainCode: 'hm', chainName: 'Hoteles Mision' },
  { chainCode: 'bb', chainName: 'Hotelnet' },
  { chainCode: 'ws', chainName: 'Hotels Of The World' },
  { chainCode: 'xz', chainName: 'Hotelzon' },
  { chainCode: 'hj', chainName: 'Howard Johnson' },
  { chainCode: 'ul', chainName: 'Hualuxe Hotels' },
  { chainCode: 'hy', chainName: 'Hyatt Hotels And Resorts' },
  { chainCode: 'hp', chainName: 'Hyatt Place' },
  { chainCode: 'hu', chainName: 'Hyatt Vacations' },
  { chainCode: 'ni', chainName: 'INTRIGUE Hotels and Resorts' },
  { chainCode: 'is', chainName: 'Ian Schrager Hotels' },
  { chainCode: 'ir', chainName: 'Iberostar Hotels' },
  { chainCode: 'ty', chainName: 'Idiso Hotel Distribution' },
  { chainCode: 'ca', chainName: 'Ilunion Hotels' },
  { chainCode: 'sm', chainName: 'Innlink' },
  { chainCode: 'ip', chainName: 'Innpoints' },
  { chainCode: 'iv', chainName: 'Innvite' },
  { chainCode: '6c', chainName: 'InterContinental Hotels Group' },
  { chainCode: 'ic', chainName: 'InterContinental Hotels and Resorts' },
  { chainCode: 'ja', chainName: 'JA Resorts and Hotels' },
  { chainCode: 'jr', chainName: 'JR Hotel Group' },
  { chainCode: 'jj', chainName: 'Jin Jiang Hotels' },
  { chainCode: 'jv', chainName: 'Joie De Vivre Hotels' },
  { chainCode: 'jh', chainName: 'Jolly Hotels' },
  { chainCode: 'jt', chainName: 'Jumeirah' },
  { chainCode: 'ju', chainName: 'Jumer Hotels' },
  { chainCode: 'ji', chainName: 'Jurys Inns' },
  { chainCode: 'kh', chainName: 'K Hotels' },
  { chainCode: 'ko', chainName: 'KSL Resorts' },
  { chainCode: 'ki', chainName: 'Kempinski Hotels And Resorts' },
  { chainCode: 'ky', chainName: 'Keytel' },
  { chainCode: 'kc', chainName: 'Kimpton Hotels' },
  { chainCode: 'kg', chainName: 'Knights Inn' },
  { chainCode: 'kb', chainName: 'Krystal Hotels' },
  { chainCode: 'le', chainName: 'L.E.Hotels' },
  { chainCode: 'lq', chainName: 'La Quinta Inns Inc' },
  { chainCode: 'lo', chainName: 'Langham Hotels International' },
  { chainCode: 'ln', chainName: 'Lanyon' },
  { chainCode: 'md', chainName: 'Le Meridien' },
  { chainCode: 'lw', chainName: 'Leading Hotels Of The World' },
  { chainCode: 'lb', chainName: 'Lebua Hotels' },
  { chainCode: 'lr', chainName: 'Leela Hotels' },
  { chainCode: 'ld', chainName: 'Leonardo Hotels' },
  { chainCode: 'lp', chainName: 'Lexington Collection' },
  { chainCode: 'lv', chainName: 'Life Hotel' },
  { chainCode: 'lg', chainName: 'Lindner Hotels' },
  { chainCode: 'lz', chainName: 'Loews Hotels' },
  { chainCode: 'nn', chainName: 'Louvre Hotels' },
  { chainCode: 'la', chainName: 'Luxury Accommodations' },
  { chainCode: 'lc', chainName: 'Luxury Collection' },
  { chainCode: 'lu', chainName: 'Luxury Hotels Group' },
  { chainCode: 'qx', chainName: 'Luxury Lifestyle' },
  { chainCode: 'xo', chainName: 'Luxury Resorts' },
  { chainCode: 'mv', chainName: 'MGM Resorts International' },
  { chainCode: 'zx', chainName: 'MI Independent' },
  { chainCode: 'gb', chainName: 'Macdonald Hotels Group' },
  { chainCode: 'mg', chainName: 'Magnolia Hotels' },
  { chainCode: 'ms', chainName: 'Magnuson Hotels' },
  { chainCode: 'mz', chainName: 'MainStay Suites' },
  { chainCode: 'mi', chainName: 'Malmaison Hotel Group' },
  { chainCode: 'mo', chainName: 'Mandarin Oriental Hotels' },
  { chainCode: 'mp', chainName: 'Mantra Group' },
  { chainCode: 'mh', chainName: 'Marco Polo Hotels' },
  { chainCode: 'mm', chainName: 'Maritim Hotels' },
  { chainCode: 'em', chainName: 'Marriott Brands' },
  { chainCode: 'et', chainName: 'Marriott Conference Ctr' },
  { chainCode: 'ee', chainName: 'Marriott Execustay' },
  { chainCode: 'mc', chainName: 'Marriott Hotels' },
  { chainCode: 'vc', chainName: 'Marriott Vacation Club' },
  { chainCode: 'vy', chainName: 'Maybourne Hotel Group' },
  { chainCode: 'me', chainName: 'Melia Hotels' },
  { chainCode: 'ml', chainName: 'Melrose Hotels' },
  { chainCode: 'bk', chainName: 'Meristar Hotels/Resorts' },
  { chainCode: 'gm', chainName: 'Meritus Hotels' },
  { chainCode: 'mt', chainName: 'Microtel Inns and Suites' },
  { chainCode: 'mu', chainName: 'Millenium/Copthorne Hotels' },
  { chainCode: 'oh', chainName: 'Minor Hotels' },
  { chainCode: 'mn', chainName: 'Montage Hotels & Resorts' },
  { chainCode: 'mw', chainName: 'Moore Reservation Systems' },
  { chainCode: 'mr', chainName: 'Morgans Hotel Group' },
  { chainCode: 'mx', chainName: 'Motel 6' },
  { chainCode: 'mk', chainName: 'Movenpick Hotels And Resorts' },
  { chainCode: 'ox', chainName: 'Moxy Hotels' },
  { chainCode: 'my', chainName: 'My Personality Hotels' },
  { chainCode: 'iq', chainName: 'Myfidelio' },
  { chainCode: 'ns', chainName: 'N H Hotels' },
  { chainCode: 'nh', chainName: 'NH Hotel Group' },
  { chainCode: 'nd', chainName: 'National Hotels' },
  { chainCode: 'nl', chainName: 'Navy Lodging' },
  { chainCode: 'no', chainName: 'New Otani Hotels' },
  { chainCode: 'nw', chainName: 'New World Hotels' },
  { chainCode: 'ng', chainName: 'Night Hotels' },
  { chainCode: 'nk', chainName: 'Nikko Hotels International / Hotel JAL' },
  { chainCode: 'nc', chainName: 'Noble House Hotels' },
  { chainCode: 'nb', chainName: 'Nobu Hotels' },
  { chainCode: 'nu', chainName: 'Northwood Hospitality' },
  { chainCode: 'ob', chainName: 'Oberoi Hotels' },
  { chainCode: 'ot', chainName: 'Oetker Collection' },
  { chainCode: 'oc', chainName: 'Okura Hotels And Resorts' },
  { chainCode: 'om', chainName: 'Omni Hotels' },
  { chainCode: 'hb', chainName: 'Omnibees' },
  { chainCode: 'oo', chainName: 'One And Only Hotels' },
  { chainCode: 'or', chainName: 'Outrigger Hotels' },
  { chainCode: 'pf', chainName: 'Pan Pacific Hotels' },
  { chainCode: 'pd', chainName: 'Park Inn' },
  { chainCode: 'pk', chainName: 'Park Plaza Hotels and Resorts' },
  { chainCode: 'pl', chainName: 'Parkroyal Hotels' },
  { chainCode: 'ui', chainName: 'Pegasus Plus' },
  { chainCode: 'pt', chainName: 'Pentahotels' },
  { chainCode: 'pg', chainName: 'Philips Hotel Group' },
  { chainCode: 'pv', chainName: 'Preferred Brands' },
  { chainCode: 'ph', chainName: 'Preferred Hotels' },
  { chainCode: 'bc', chainName: 'Preferred Hotels Affiliate Htl' },
  { chainCode: 'pi', chainName: 'Premier Inn' },
  { chainCode: 'pj', chainName: 'Prince Resorts' },
  { chainCode: 'pa', chainName: 'Principal Haley' },
  { chainCode: 'pr', chainName: 'Protea' },
  { chainCode: 'pu', chainName: 'Pullman Hotels' },
  { chainCode: 'pq', chainName: 'Purple Hotels' },
  { chainCode: 'qh', chainName: 'QHotels' },
  { chainCode: 'qt', chainName: 'QT' },
  { chainCode: 'qi', chainName: 'Quality Inns and Suites' },
  { chainCode: 'qr', chainName: 'Quality Reservations' },
  { chainCode: 'qm', chainName: 'Queen Moat Houses' },
  { chainCode: 'ql', chainName: 'Queens Hotels Germany' },
  { chainCode: 'qg', chainName: 'Quest' },
  { chainCode: 'qc', chainName: 'Quorvus Collection' },
  { chainCode: 'rd', chainName: 'Radisson Hotels and Resorts' },
  { chainCode: 'yr', chainName: 'Raffles Hotels & Resorts' },
  { chainCode: 'nr', chainName: 'Ramada International' },
  { chainCode: 'ra', chainName: 'Ramada Worldwide' },
  { chainCode: 'on', chainName: 'Reconline' },
  { chainCode: 'rl', chainName: 'Red Lion Hotels' },
  { chainCode: 'rf', chainName: 'Red Roof Inns' },
  { chainCode: 'rq', chainName: 'Regal Hotels International' },
  { chainCode: 're', chainName: 'Regent International Hotels' },
  { chainCode: 'tr', chainName: 'Registry Collection' },
  { chainCode: 'wb', chainName: 'Relais Chateau' },
  { chainCode: 'br', chainName: 'Renaissance Hotels and Resorts' },
  { chainCode: 'rh', chainName: 'ResHub' },
  { chainCode: 'ta', chainName: 'Reservhotel International' },
  { chainCode: 'rc', chainName: 'Residence Inn' },
  { chainCode: 'id', chainName: 'Resnet Hotels' },
  { chainCode: 'rb', chainName: 'Resort Bookings' },
  { chainCode: 'rj', chainName: 'Resort Condominiums' },
  { chainCode: 'qv', chainName: 'ResortQuest International' },
  { chainCode: 'rk', chainName: 'Rezlink International' },
  { chainCode: 'rr', chainName: 'Rihga Royal Hotels' },
  { chainCode: 'zc', chainName: 'Ritz Carlton Club' },
  { chainCode: 'rz', chainName: 'Ritz Carlton Hotels' },
  { chainCode: 'rx', chainName: 'Rixos Hotels' },
  { chainCode: 'fc', chainName: 'Rocco Forte Hotels' },
  { chainCode: 'ri', chainName: 'Rodeway Inn' },
  { chainCode: 'rw', chainName: 'Rosewood Hotels' },
  { chainCode: 'ro', chainName: 'Rotana Hotels' },
  { chainCode: 'rg', chainName: 'Rydges Hotel Group' },
  { chainCode: 'og', chainName: 'SEH United Hotels' },
  { chainCode: 'ew', chainName: 'SRS Hotel Brands' },
  { chainCode: 'ps', chainName: 'Sandman Hotels' },
  { chainCode: 'sv', chainName: 'Sarova Hotels' },
  { chainCode: 'sh', chainName: 'Scandic Hotels' },
  { chainCode: 'sc', chainName: 'Sceptre Hospitality Resources' },
  { chainCode: 'se', chainName: 'Sercotel Hotels' },
  { chainCode: 'sg', chainName: 'Shangri La Hotels' },
  { chainCode: 'si', chainName: 'Sheraton Hotels and Resorts' },
  { chainCode: 'bp', chainName: 'Shilo Inns' },
  { chainCode: 'sd', chainName: 'SilverNeedle Hotels' },
  { chainCode: 'gd', chainName: 'SiteMinder' },
  { chainCode: 'sk', chainName: 'Sixty Hotels' },
  { chainCode: 'sz', chainName: 'Sleep Inn' },
  { chainCode: 'lx', chainName: 'Small Luxury Hotels' },
  { chainCode: 'sb', chainName: 'Sofitel Hotels' },
  { chainCode: 'so', chainName: 'Sokos Hotels' },
  { chainCode: 'sn', chainName: 'Sonesta Collection' },
  { chainCode: 'su', chainName: 'Southern Sun Hotels' },
  { chainCode: 'sp', chainName: 'Special Properties' },
  { chainCode: 'xv', chainName: 'SpringHill Suites' },
  { chainCode: 'ys', chainName: 'Stamford Hotels' },
  { chainCode: 'ap', chainName: 'Standard International' },
  { chainCode: 'sy', chainName: 'Starhotels' },
  { chainCode: 'yz', chainName: 'Staybridge Suites' },
  { chainCode: 'sr', chainName: 'Steigenberger Hotel Group' },
  { chainCode: 'ub', chainName: 'Suburban Hotels' },
  { chainCode: 'oz', chainName: 'Super 8' },
  { chainCode: 'ur', chainName: 'SureStay' },
  { chainCode: 'sf', chainName: 'Sutton Place' },
  { chainCode: 'uk', chainName: 'Swallow Hotels' },
  { chainCode: 'os', chainName: 'Sweden Hotels' },
  { chainCode: 'qo', chainName: 'Swiss Quality' },
  { chainCode: 'sl', chainName: 'Swissotel Hotels' },
  { chainCode: 'yx', chainName: 'SynXis' },
  { chainCode: 'tj', chainName: 'Taj Hotels' },
  { chainCode: 'up', chainName: 'Tapestry Collection' },
  { chainCode: 'at', chainName: 'The Address Hotels And Resorts' },
  { chainCode: 'az', chainName: 'The Ascott Limited' },
  { chainCode: 'lj', chainName: 'The Lalit' },
  { chainCode: 'pn', chainName: 'The Peninsula Hotels' },
  { chainCode: 'xr', chainName: 'The St Regis' },
  { chainCode: 'ti', chainName: 'Thistle Hotels' },
  { chainCode: 'tm', chainName: 'Thompson Hotels' },
  { chainCode: 'tf', chainName: 'Thon Hotels' },
  { chainCode: 'tn', chainName: 'Time Hotels' },
  { chainCode: 'td', chainName: 'Toga Hotels' },
  { chainCode: 'ie', chainName: 'Tommie Hotels' },
  { chainCode: 'tp', chainName: 'Top International' },
  { chainCode: 'to', chainName: 'TownPlace Suites' },
  { chainCode: 'tq', chainName: 'Trademark Collection' },
  { chainCode: 'xt', chainName: 'Travel Tripper' },
  { chainCode: 'wv', chainName: 'TravelClick' },
  { chainCode: 'tc', chainName: 'TravelClick Brands' },
  { chainCode: 'tl', chainName: 'Travelodge' },
  { chainCode: 'tg', chainName: 'Travelodge UK And Europe' },
  { chainCode: 'tu', chainName: 'Travelport CVV Properties' },
  { chainCode: 'tz', chainName: 'Travelport EX Hotels' },
  { chainCode: 'tx', chainName: 'Tribute Portfolio' },
  { chainCode: 'th', chainName: 'Trident Hotels' },
  { chainCode: 'ru', chainName: 'Tru by Hilton' },
  { chainCode: 'tw', chainName: 'Trump Hotels' },
  { chainCode: 'wt', chainName: 'Tryp Hotels' },
  { chainCode: 'it', chainName: 'Two Roads Hospitality' },
  { chainCode: 'ue', chainName: 'Universal Hotels Orlando' },
  { chainCode: 'us', chainName: 'Unscripted Hotels' },
  { chainCode: 'uz', chainName: 'Utell Connect' },
  { chainCode: 'vp', chainName: 'VIP Hotels' },
  { chainCode: 'vk', chainName: 'Vacation Click' },
  { chainCode: 'va', chainName: 'Vagabond Inns' },
  { chainCode: 'lm', chainName: 'Vantis Hotel Group' },
  { chainCode: 've', chainName: 'Vantis Hotels' },
  { chainCode: 'vu', chainName: 'Venu Hotels' },
  { chainCode: 'vg', chainName: 'Viceroy Hotel Group' },
  { chainCode: 'vi', chainName: 'Vienna House' },
  { chainCode: 'dv', chainName: 'Village Hotels' },
  { chainCode: 'vl', chainName: 'Virgin Hotels' },
  { chainCode: 'wh', chainName: 'W Hotels' },
  { chainCode: 'wa', chainName: 'Waldorf Astoria Hotels and Resorts' },
  { chainCode: 'wk', chainName: 'Warwick Hotels' },
  { chainCode: 'wf', chainName: 'Westcoast Family' },
  { chainCode: 'wi', chainName: 'Westin Hotels and Resorts' },
  { chainCode: 'wm', chainName: 'Westmark Hotels' },
  { chainCode: 'pw', chainName: 'Whiteboard Labs' },
  { chainCode: 'wg', chainName: 'Wingate Hotels' },
  { chainCode: 'ww', chainName: 'Worldhotels' },
  { chainCode: 'uu', chainName: 'Worldhotels United' },
  { chainCode: 'px', chainName: 'Wyndham Connections' },
  { chainCode: 'wo', chainName: 'Wyndham Extra Holidays' },
  { chainCode: 'wy', chainName: 'Wyndham Hotels' },
  { chainCode: 'wr', chainName: 'Wyndham Rewards' },
  { chainCode: 'st', chainName: 'Xenia Hotellerie' },
  { chainCode: 'yl', chainName: 'Yotel' },
];

const hotelChainsMap: { [k: string]: string } = {};

hotelChainsList.forEach((hotelChain: IChainObject) => {
  hotelChainsMap[hotelChain.chainCode.toUpperCase()] = hotelChain.chainName;
});

export const hotelChainsSelectOptions: { value: string; label: string }[] = uniqWith(
  hotelChainsList.map((hotelChain) => ({
    value: hotelChain.chainCode,
    label: hotelChain.chainName,
  })),
  (curr, next) => curr.label === next.label || curr.value === next.value,
);

export default hotelChainsMap;
