/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of PNR
 * @export
 * @enum {string}
 */

export const PnrType = {
  Air: 'AIR',
  Car: 'CAR',
  Rail: 'RAIL',
  Hotel: 'HOTEL',
  Limo: 'LIMO',
} as const;

export type PnrType = (typeof PnrType)[keyof typeof PnrType];
