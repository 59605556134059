import type { TSelectOption, TSelectOptionList } from './SelectRenderer';

export type OptionWithStringifiedValue<TItem extends TSelectOption = TSelectOption> = TItem & {
  $$__type: 'string' | 'number';
  value: string;
};

type TFIndexByValue = <TItem extends TSelectOption>(
  itemList: TSelectOptionList<TItem>,
) => {
  [index: string]: TItem;
};

export const indexByOptionValue: TFIndexByValue = <
  TItem extends TSelectOption = TSelectOption,
  Options extends TSelectOptionList<TItem> = TSelectOptionList<TItem>,
>(
  options: Options,
) => {
  return options.reduce((itemsByValue, item) => {
    // eslint-disable-next-line no-nested-ternary
    return 'list' in item
      ? {
          ...itemsByValue,
          ...indexByOptionValue(item.list),
        }
      : item.value
      ? {
          ...itemsByValue,
          [item.value]: item,
        }
      : itemsByValue;
  }, {} as Record<string, TItem>);
};

export function addTypeMetadata<TItem extends TSelectOption = TSelectOption>(
  options: TSelectOptionList<TItem>,
): TSelectOptionList<OptionWithStringifiedValue<TItem>> {
  if (!options.length) {
    return [];
  }
  return options.map((item) => {
    if ('list' in item) {
      return {
        ...item,
        list: addTypeMetadata(item.list),
      };
    }
    return {
      ...item,
      value: String(item.value),
      $$__type: typeof item.value as 'string' | 'number',
    };
  }) as TSelectOptionList<OptionWithStringifiedValue<TItem>>;
}

export function restoreOptionType<TItem extends TSelectOption = TSelectOption>({
  $$__type,
  value,
  ...rest
}: OptionWithStringifiedValue<TItem>): TItem {
  return { ...rest, value: $$__type === 'string' ? value : Number(value) } as unknown as TItem;
}
