import uniqWith from 'lodash/uniqWith';

export const carCompanyMap: Record<string, string> = {
  AC: 'Ace',
  AO: 'ACO',
  AD: 'Advantage',
  AL: 'Alamo',
  ZI: 'Avis',
  BV: 'Bidvest Car Rental',
  ZD: 'Budget',
  '1C': 'Car trawler',
  DS: 'Discount',
  ZR: 'Dollar Rent A Car',
  DF: 'Driving Force Car Rental',
  ES: 'Easirent',
  EY: 'Economy Rent a Car',
  ET: 'Enterprise',
  EM: 'EUROMOBIL AUTOVERMIETUNG GMBH /VW FS RENT-A-CAR',
  EP: 'Europcar',
  IM: 'Europcar SA',
  EZ: 'Ez Rent A Car',
  FF: 'Firefly',
  FR: 'First Car Rental',
  FX: 'Fox',
  GM: 'Green Motion',
  ZE: 'Hertz',
  LL: 'Localiza',
  MG: 'Maggiore',
  MX: 'MEX',
  MW: 'Midway',
  MO: 'Movida',
  ZL: 'National',
  NC: 'NextCar',
  NU: 'Nu Car Rentals',
  XX: 'Other',
  ZA: 'Payless',
  PR: 'Priceless Car Rental',
  RW: 'Rent a wreck',
  RO: 'Routes Car Rental',
  SX: 'Sixt',
  TI: 'Tempest',
  ZT: 'Thrifty',
  UN: 'Unidas Rent A Car',
  SV: 'U-Save',
  WF: 'Woodford',
};

export const getCarCompanyName = (code: keyof typeof carCompanyMap): string => carCompanyMap[code] ?? '';

export const carCompaniesSelectOptions: { value: string; label: string }[] = uniqWith(
  Object.entries(carCompanyMap).map((carCompany) => ({
    value: carCompany[0],
    label: carCompany[1],
  })),
  (curr, next) => curr.label === next.label || curr.value === next.value,
);
