import type { APIControlledSearch, TSearchConfig } from './useSelectStore_internal';

export function getStringifiedValueFromOption<T extends { value: unknown }>(option: T): string {
  return String(option.value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSearchControlled = (search: TSearchConfig<any> | undefined): search is APIControlledSearch => {
  return typeof search === 'object' && 'type' in search && search.type === 'CONTROLLED';
};
