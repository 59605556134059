var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FlightCredit = (_a) => {
    var { primaryColor } = _a, props = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 28 28", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.75 8.412l2.85.4c.6.05.85.75.45 1.2l-.05.05-2.05 1.95.5 2.75c.1.4-.15.7-.5.8v5.6c0 1.65-1.4 3-3.15 3H8.15c-1.75 0-3.15-1.35-3.15-3v-8.9c0-1.65 1.4-3 3.15-3h9.15c.1-.2.3-.4.55-.45h.05l2.9-.4 1.3-2.5c.25-.55 1.05-.55 1.35 0l1.3 2.5zm-1.25.95l-.75-1.45-.7 1.35-.05.15c-.15.2-.35.35-.6.4l-1.75.25 1.25 1.15c.05 0 .05.05.05.05.15.15.2.35.2.55v.05l-.3 1.65 1.55-.8c.2-.1.45-.1.65 0h.05l.7.35.85.45-.3-1.65c0-.2.05-.45.2-.6h.05l.75-.7.5-.5-1.15-.15-.6-.1c-.25-.05-.45-.15-.55-.35v-.05l-.05-.05zm-15.35 1.9c-.6 0-1.05.45-1.05 1v1.8h12.15l.35-2.05-.8-.75H8.15zm0 10.85h14.6c.6 0 1.05-.45 1.05-1h.05v-5.05H7.1v5.05c0 .55.45 1 1.05 1zm1.75-3.45h4.2c.3 0 .55.2.5.5 0 .25-.2.45-.45.5H9.9c-.25 0-.5-.25-.5-.5s.2-.5.45-.5h.05z", fill: themeEnrichedPrimaryColor })));
};
FlightCredit.defaultProps = defaultIconProps;
export default FlightCredit;
