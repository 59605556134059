/* eslint-disable */
export interface Name {
  given: string;
  middle: string;
  family1: string;
  /** @deprecated */
  family2: string;
  suffix: NameSuffix;
  /**
   * Informal preferred name added by traveler. This is not used on any
   * PNR or tickets.
   */
  preferred: string;
}

export enum NameSuffix {
  NAME_SUFFIX_UNKNOWN = 0,
  SR = 1,
  JR = 2,
  MD = 3,
  PHD = 4,
  II = 5,
  III = 6,
  IV = 7,
  DO = 8,
  ATTY = 9,
  UNRECOGNIZED = -1,
}
