import type { AxiosResponse } from 'axios';
import Config from '../../utils/Config';
import addResponseInterceptor from '../interceptors/addResponseInterceptor';

interface OnTraceIdEvent {
  traceId: string;
  /** @deprecated Move to debugToolLinkQueue */
  debugToolLink: string;
  zipkinLink: string;
  debugToolLinkQueue: Array<{ apiURL: string; didAPIErrorOut: boolean; link: string }>;
}

export const DEBUG_TOOL_LINK_QUEUE_SIZE = 5;

const debugToolLinkQueue: OnTraceIdEvent['debugToolLinkQueue'] = new Array(DEBUG_TOOL_LINK_QUEUE_SIZE).fill({
  apiURL: '',
  link: '',
});

const getTraceIdFromResponse = (
  response: AxiosResponse,
  listener: (event: OnTraceIdEvent) => void,
  didAPIErrorOut: boolean,
): void => {
  const apiURL = response?.config?.url ?? '';

  const link = response?.headers?.['debug-tool-link'] as string | undefined;

  if (!link) {
    return;
  }

  const lastEntry = debugToolLinkQueue[DEBUG_TOOL_LINK_QUEUE_SIZE - 1];
  if (lastEntry.apiURL !== apiURL && lastEntry.link !== link) {
    debugToolLinkQueue.shift();
    debugToolLinkQueue.push({ apiURL, didAPIErrorOut, link });
  }

  const url = link.split('?')[0];
  const tokens = url.split('/');
  const traceId = tokens[tokens.length - 1];

  if (!traceId) {
    return;
  }

  const zipkinLink = `${Config.VITE_ZIPKIN_BASE_URL}${traceId}`;

  listener({ traceId, debugToolLink: link, zipkinLink, debugToolLinkQueue });
};

function onApiTraceId(listener: (event: OnTraceIdEvent) => void) {
  return addResponseInterceptor({
    onRejected: (error) => {
      getTraceIdFromResponse(error.response, listener, true);

      return error;
    },
    onFulfilled: (response) => {
      getTraceIdFromResponse(response, listener, false);

      return response;
    },
  });
}

export default onApiTraceId;
