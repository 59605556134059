import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Flag = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.3125 19.8229C3.89829 19.8229 3.5625 19.4871 3.5625 19.0729C3.5625 18.6775 3.86845 18.3536 4.25653 18.3249L4.3125 18.3229L5.628 18.3228L5.62807 4.70607C5.62807 4.29186 5.96386 3.95607 6.37807 3.95607L6.65716 3.95288L6.93703 3.94675C9.5195 3.88811 11.1871 4.16538 13.1353 5.41781L15.0773 6.66246C16.46 7.53949 16.6194 7.59371 17.2685 7.81452L17.3418 7.83947C17.7756 7.98737 18.1893 8.06809 18.6124 8.0842L18.7661 8.08719L18.8104 8.08582C19.7044 8.0779 20.4589 8.75472 20.547 9.6414L20.553 9.72017L20.5559 9.81695L20.5529 9.94809C20.5248 10.7506 20.0587 11.416 19.0164 11.6208L18.9138 11.6392L18.4731 11.7321C18.2348 11.7838 17.9317 11.8503 17.6107 11.9208L17.6104 11.9209C16.909 12.0749 16.1218 12.2478 15.7367 12.325C13.1772 12.8378 10.5902 13.1379 7.44711 13.1928L7.128 13.1973V18.3228L8.44365 18.3229C8.85786 18.3229 9.19365 18.6587 9.19365 19.0729C9.19365 19.4683 8.88769 19.7922 8.49962 19.8208L8.44365 19.8229H4.3125ZM7.128 5.44307V11.6973L7.45141 11.6925C8.39303 11.6755 9.2821 11.636 10.1342 11.5747V5.70396C9.31834 5.49801 8.43396 5.43108 7.34772 5.44037L7.128 5.44307ZM10.8842 5.94011V11.5144C12.2474 11.393 13.5236 11.214 14.7818 10.9814V8.24928C14.2661 7.92803 13.5532 7.46974 12.4985 6.79167L12.3241 6.67958C11.8339 6.36444 11.3641 6.12271 10.8842 5.94011ZM15.5318 8.69939V10.8356L15.5918 10.8236C15.9783 10.7433 16.6113 10.6045 17.2077 10.4736L17.2077 10.4736L17.2077 10.4736C17.8474 10.3333 18.4451 10.2022 18.6515 10.1625C19.0002 10.1059 19.0493 10.0606 19.0539 9.90126L19.0556 9.82629C19.0559 9.82003 19.0559 9.81386 19.0554 9.80426C19.0491 9.68848 18.9586 9.59724 18.8462 9.58679L18.8119 9.58613L18.7715 9.58722C18.1816 9.58722 17.6111 9.49632 17.0318 9.316L16.8578 9.25922C16.8193 9.24611 16.7823 9.23355 16.7467 9.22142C16.5316 9.14836 16.3631 9.09111 16.1981 9.02506C15.9968 8.94448 15.8007 8.85081 15.5318 8.69939Z", fill: themeEnrichedPrimaryColor })));
};
Flag.defaultProps = defaultIconProps;
export default Flag;
