var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
// TODO: Keep one of the two Chevron
const Chevron = (_a) => {
    var { primaryColor, secondaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 32 32", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M.669 7.669a2.287 2.287 0 013.017-.19l.215.19L16 19.766l12.1-12.1a2.287 2.287 0 013.017-.19l.215.19a2.287 2.287 0 01.19 3.017l-.19.215-13.716 13.719a2.287 2.287 0 01-3.017.19l-.215-.19L.669 10.9a2.287 2.287 0 010-3.232z", fillRule: "evenodd" })));
};
Chevron.defaultProps = defaultIconProps;
export default Chevron;
