import { FC, PropsWithChildren, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { BlocksThemeProvider } from '@spotnana/blocks/src/BlocksThemeProvider/BlocksThemeProvider';
import { StrictlyNecessaryBlocksGlobalStyles } from '@spotnana/blocks/src/GlobalStyles';
import { SpriteProvider } from '@spotnana/blocks/src/Icon/Icon';
import getThemeStyles from '@spotnana/pixel-react/dist/utils/updateTheme';
import {
  WhiteLabelConfig,
  useWhiteLabelConfig,
  useUnauthWhiteLabelConfig,
  useIsLoggedIn,
  useFeatureFlag,
  useJSONFeatureFlag,
} from 'obt-common';
import { SPOTNANA_SUB_DOMAIN } from 'src/components/AppBrandingProvider/AppBrandingProvider';
import { useMergePixelAndBlocksTheme } from 'src/hooks/useMergePixelAndBlocksTheme';
import { ThemeOverrides } from '@spotnana/pixel-react/dist/utils/themes/types';
import { getThemeOverrides } from '@spotnana/pixel-react/dist/utils/themes';
import { IConfigValues } from '@spotnana/blocks/src/CustomizationProvider/CustomizationProvider';
import { generateTheme } from '../generateTheme';
import { generateBlocksTheme } from '../generateBlocksTheme';
import { ThemeModeInitializer } from '../ThemeModeInitializer/ThemeModeInitializer';

interface ThemeStyleProps {
  whiteLabelConfig: WhiteLabelConfig | undefined;
}

const ThemeStyle: FC<ThemeStyleProps> = ({ whiteLabelConfig }) => {
  const isDarkModeSupported = useFeatureFlag('FE_INFRA_DARK_MODE');
  const { colors, fontFamily, shadows } = useMergePixelAndBlocksTheme();
  const darkModeStylesStr: string = useMemo(
    () => getThemeStyles({ colors, shadows, fontFamily: { textFont: fontFamily.join(','), codeFont: 'monospace' } }),
    [colors, fontFamily, shadows],
  );

  const oldStylesStr: string = useMemo(
    () => getThemeStyles({ shadows, ...getThemeOverrides((whiteLabelConfig || {}) as ThemeOverrides) }),
    [whiteLabelConfig, shadows],
  );

  const stylesStr = isDarkModeSupported ? darkModeStylesStr : oldStylesStr;

  const fontFamilyCss = whiteLabelConfig?.fontFamilyCss;

  return (
    <>
      <StrictlyNecessaryBlocksGlobalStyles />

      <Helmet>
        {stylesStr ? <style data-testid="theme-variable-definitions">{stylesStr}</style> : null}
        {fontFamilyCss ? <link data-testid="theme-custom-fonts" rel="stylesheet" href={fontFamilyCss} /> : null}
      </Helmet>
    </>
  );
};

export const DefaultThemeStyle: FC = () => {
  const { data: whiteLabelConfig } = useWlConfig();
  return <ThemeStyle whiteLabelConfig={whiteLabelConfig} />;
};

const useWlConfig = () => {
  const isLoggedIn = useIsLoggedIn();
  const whiteLabelConfig = useWhiteLabelConfig();
  const unauthWLConfig = useUnauthWhiteLabelConfig(SPOTNANA_SUB_DOMAIN);
  return isLoggedIn
    ? whiteLabelConfig
    : { ...unauthWLConfig, data: unauthWLConfig.data === 'SPOTNANA_DEFAULT_CONFIG' ? undefined : unauthWLConfig.data };
};

export const ThemeStylesWithWhiteLabelConfig: FC = () => {
  const { data: whiteLabelConfig } = useWhiteLabelConfig();
  return <ThemeStyle whiteLabelConfig={whiteLabelConfig} />;
};

export const BlocksThemeProviderWhiteLabel: FC<PropsWithChildren<{ config?: IConfigValues }>> = ({
  config,
  children,
}) => {
  const { data: whiteLabelConfig, isLoading } = useWlConfig();
  const whitelabelConfigDriven = useJSONFeatureFlag('FE_INFRA_CONFIG_DRIVEN_WHITELABELLING');
  const themeOptions = useJSONFeatureFlag('FE_INFRA_THEME_OPTIONS');

  const theme = generateBlocksTheme({ isLoading, whiteLabelConfig });

  const updatedTheme = generateTheme({
    theme,
    enableThemeOverrides: themeOptions?.enableTheme ?? false,
    whitelabelConfigDriven,
  });

  return (
    <BlocksThemeProvider theme={updatedTheme} config={config}>
      <ThemeModeInitializer />
      <SpriteProvider url="">
        <StrictlyNecessaryBlocksGlobalStyles />
        {children}
      </SpriteProvider>
    </BlocksThemeProvider>
  );
};
