/* eslint-disable max-len */
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const More = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 4 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M0.5 18H3.5V16H0.5V18ZM0.5 13H3.5V11H0.5V13ZM0.5 6V8H3.5V6H0.5Z", fill: themeEnrichedPrimaryColor })));
};
More.defaultProps = defaultIconProps;
export default More;
