import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Cabin = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M9.923 4.5a2.928 2.928 0 012.917 2.763l.005.173v5.304l1.474 4.357 2.144-2.818a.887.887 0 01.63-.347l.075-.003h5.016a.891.891 0 01.066 1.781l-.066.002h-4.577l-1.596 2.097h8.101a.89.89 0 01.881.78l.007.112v4.651a.89.89 0 01-.776.885l-.112.007h-1.891v2.364a.89.89 0 01-.775.885l-.112.007h-7.408a.89.89 0 01-.881-.78l-.007-.112V24.13a5.539 5.539 0 01-4.394-5.187l-.002-.103-1.604-5.33a.925.925 0 01-.034-.171L7 13.25V7.436A2.929 2.929 0 019.923 4.5zm10.523 19.744h-5.634l.001 1.473h5.633v-1.473zm2.779-4.651h-7.543a1.886 1.886 0 01-1.842 1.495c-.904 0-1.66-.64-1.84-1.494l-1.48-.001.023.09a3.75 3.75 0 003.302 2.765l.2.011.112.002h9.068v-2.868zM9.923 6.283A1.15 1.15 0 008.782 7.31l-.007.126v5.685l1.41 4.688h2.376c.031-.029.064-.057.098-.085l-1.542-4.55-.028-.106-.016-.108-.003-.073V7.436a1.15 1.15 0 00-1.147-1.153z" })));
};
Cabin.defaultProps = defaultIconProps;
export default Cabin;
