import type { PostQueryParamsType } from '../../shared/types';
import { generateUrlInfo, usePostMutation, usePostQuery } from '../../shared/hooks';
import { defaultQueryClient } from '../../../queries';

export const useGetRefreshAirItinerary = (params: PostQueryParamsType<'/v1/refresh-air-itinerary'>) =>
  usePostQuery('/v1/refresh-air-itinerary', params);

export const useAirShareItineraryMutation = () => {
  return usePostMutation('/v2/air/share-itinerary', {
    onSuccess: (_, variables) => {
      const { queryKey } = generateUrlInfo({
        path: '/v2/air/share-itinerary',
        params: variables.requestBody,
      });

      defaultQueryClient.invalidateQueries(queryKey);
    },
  });
};
