import Box from '@spotnana/pixel-react/dist/Box';
import cssWrapper from '@spotnana/pixel-react/dist/utils/cssWrapper';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import { TaskAgentPriority } from 'obt-common';
import styled, { CSSObject, css } from 'styled-components';

export const priorityColorsMap: { [key in TaskAgentPriority]?: string } = {
  [TaskAgentPriority.High]: spotnanaTheme.colors.text.gold,
  [TaskAgentPriority.Low]: spotnanaTheme.colors.border.silver,
  [TaskAgentPriority.Medium]: spotnanaTheme.colors.ui.success,
  [TaskAgentPriority.Urgent]: spotnanaTheme.colors.ui.error,
};

export const getScrollbarStyles = (): CSSObject => ({
  '*': {
    scrollbarWidth: 'thin',
    scrollbarColor: spotnanaTheme.colors.bg.aquaHaze,
  },
  /* ::-webkit-scrollbar is for browsers : Chrome, Safari, Opera, Edge, all iOS browers and others */
  '*::-webkit-scrollbar-track': {
    bg: spotnanaTheme.colors.bg.aquaHaze,
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: spotnanaTheme.colors.bg.lightGrey3,
    borderRadius: '16px',
  },

  '*::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
});

export const overlayLoaderStyles = css`
  position: absolute;
  z-index: 1;
  background-color: ${spotnanaTheme.colors.bg.blackAlpha20};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OverlayLoader = styled(Box)`
  top: 90px;
  height: 480px;
  width: calc(100% - 36px);
  border-radius: 8px;
  ${overlayLoaderStyles}
`;

export const TaskExpander = styled(Box)`
  width: 90px;
`;
export const Type = styled(Box)`
  width: 130px;
`;
export const Priority = styled(Box)`
  width: 40px;
`;
export const TravelStart = styled(Box)`
  width: 122px;
`;
export const TransactionDate = styled(Box)`
  width: 142px;
`;
export const Affiliate = styled(Box)`
  width: 160px;
  margin-right: 10px;
`;
export const TravelerInfo = styled(Box)`
  width: 220px;
  margin-right: 10px;
`;
export const Organization = styled(Box)`
  width: 160px;
  margin-right: 10px;
`;
export const Source = styled(Box)`
  width: 120px;
`;
export const TripInfo = styled(Box)`
  width: 100px;
`;
export const AssignedGroup = styled(Box)`
  width: 170px;
`;
export const Assignee = styled(Box)`
  width: 220px;
`;
export const Status = styled(Box)`
  width: 200px;
  padding-right: 20px;
`;

export const AssigneeItem = styled(Box)(
  cssWrapper({
    borderRadius: '8px',
    padding: '4px 8px',
    marginBottom: '16px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'bg.blueGray',
    },
  }),
);

export const PopperContainer = styled(Box)(
  cssWrapper({
    borderRadius: '8px',
    padding: '16px 20px',
    height: '280px',
    width: '240px',
  }),
);

export const ScrollBox = styled(Box)(
  cssWrapper({
    overflowY: 'scroll',
    overflowX: 'hidden',
  }),
);

export const TypeContainer = styled(Box).attrs({ as: 'span' })`
  margin: 0 40px;
  white-space: nowrap;
  width: 300px;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;
