import { Dispatch } from 'react';
import { LinkAttributes, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { LexicalEditor } from 'lexical';
import { sanitizeUrl, DEFAULT_LINK_URL } from './url';

const DEFAULT_LINK_ATTRIBUTES: LinkAttributes = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const getLinkAttributes = (url: string) => {
  return { ...DEFAULT_LINK_ATTRIBUTES, url };
};

// Listener for CTRL-K to toggle link.
// Pulled out for easier testing.
export const getLinkKeyboardHandler = (
  activeEditor: LexicalEditor,
  isLink: boolean,
  setIsLinkEditMode: Dispatch<boolean>,
) => {
  return (payload: KeyboardEvent) => {
    const event: KeyboardEvent = payload;
    const { code, ctrlKey, metaKey } = event;

    if (code === 'KeyK' && (ctrlKey || metaKey)) {
      event.preventDefault();
      let url: string | null;
      if (!isLink) {
        setIsLinkEditMode(true);
        url = sanitizeUrl(DEFAULT_LINK_URL);
      } else {
        setIsLinkEditMode(false);
        url = null;
      }
      return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
    }
    return false;
  };
};
