import { BookingType } from 'obt-common/types/api/v2/obt/model/booking-type';
import { SiteMessageLocation } from 'obt-common/types/api/v2/obt/model/site-message-location';
import { ROUTE_PATHS } from '../../routes';

interface ISiteMessageWebRouteConfig {
  location: SiteMessageLocation;
  bookingType?: BookingType;
}

export const routeConfigMap: Record<string, ISiteMessageWebRouteConfig> = {
  [ROUTE_PATHS.FLIGHTS]: {
    location: SiteMessageLocation.SearchPage,
    bookingType: BookingType.Air,
  },
  [ROUTE_PATHS.HOTELS]: {
    location: SiteMessageLocation.SearchPage,
    bookingType: BookingType.Hotel,
  },
  [ROUTE_PATHS.CARS]: {
    location: SiteMessageLocation.SearchPage,
    bookingType: BookingType.Car,
  },
  '/rails': {
    location: SiteMessageLocation.SearchPage,
    bookingType: BookingType.Rail,
  },
  [ROUTE_PATHS.TRIPS]: {
    location: SiteMessageLocation.TripsPage,
  },
  '/trips/upcoming': {
    location: SiteMessageLocation.TripsPage,
  },
  '/trips/past': {
    location: SiteMessageLocation.TripsPage,
  },
  '/trips/cancelled': {
    location: SiteMessageLocation.TripsPage,
  },
  [ROUTE_PATHS.FLIGHTS_RESULTS]: {
    location: SiteMessageLocation.SearchResultPage,
    bookingType: BookingType.Air,
  },
  [ROUTE_PATHS.HOTELS_RESULTS]: {
    location: SiteMessageLocation.SearchResultPage,
    bookingType: BookingType.Hotel,
  },
  [ROUTE_PATHS.HOTELS_DETAILS]: {
    location: SiteMessageLocation.SearchResultPage,
    bookingType: BookingType.Hotel,
  },
  [ROUTE_PATHS.CARS_RESULTS]: {
    location: SiteMessageLocation.SearchResultPage,
    bookingType: BookingType.Car,
  },
  [ROUTE_PATHS.RAILS_RESULTS]: {
    location: SiteMessageLocation.SearchResultPage,
    bookingType: BookingType.Rail,
  },
  [ROUTE_PATHS.FLIGHTS_CHECKOUT_FINALIZE]: {
    location: SiteMessageLocation.CheckoutPage,
    bookingType: BookingType.Air,
  },
  [ROUTE_PATHS.HOTELS_CHECKOUT]: {
    location: SiteMessageLocation.CheckoutPage,
    bookingType: BookingType.Hotel,
  },
  [ROUTE_PATHS.CARS_CHECKOUT]: {
    location: SiteMessageLocation.CheckoutPage,
    bookingType: BookingType.Car,
  },
  [ROUTE_PATHS.RAILS_CHECKOUT_SUCCESS]: {
    location: SiteMessageLocation.CheckoutPage,
    bookingType: BookingType.Rail,
  },
  [ROUTE_PATHS.SUPPORT_TAB]: {
    location: SiteMessageLocation.SupportPage,
  },
};
