import {
  useFeatureFlag,
  useLoggedInUser,
  useLoggedInUserBasicInfo,
  useOrganizationReadBasicInfoQuery,
  Config,
} from 'obt-common';
import { getOrgRelatedAnalyticsData, getUserRelatedAnalyticsData } from 'obt-common/utils/analyticsData';
import { FullStory } from '@fullstory/browser';
import { useEffect } from 'react';
import noop from 'lodash/noop';

export const InitiateFullStoryCaptureInternal = () => {
  const isFullStoryCaptureEnabled = useFeatureFlag('FE_INFRA_FULL_STORY');
  const { data: userData } = useLoggedInUser();
  const userBasicInfo = useLoggedInUserBasicInfo();
  const { data: userOrgData } = useOrganizationReadBasicInfoQuery(
    userBasicInfo.existingUser?.userOrgId?.organizationId,
  );

  useEffect(() => {
    // Full story is not enabled, shutdown
    if (!isFullStoryCaptureEnabled) {
      FullStory('shutdown');

      // restart session capture if user logs out
      // doesn't matter for embed apps,
      // needed for normal app to resume capturing anonymous sessions on logout
      return () => {
        FullStory('restart');
      };
    }

    // For Embed, we delay session capture, so we need to manually start it
    // these calls are idempotent, so doesn't matter if it gets called twice in case of non-embed
    FullStory('start');

    // if user details are not available, start anonymous session
    if (!userBasicInfo || !userData || !userOrgData) {
      FullStory('setIdentity', {
        anonymous: true,
      });
      return noop;
    }

    // if user details are available, start session for this user
    const userId = userData?.userOrgId?.userId?.id || '';
    const accountId = userBasicInfo.existingUser?.userOrgId?.organizationId?.id;

    FullStory('setIdentity', {
      uid: userId,
      properties: {
        accountId,
        ...getUserRelatedAnalyticsData(userData, userBasicInfo, userOrgData),
        ...getOrgRelatedAnalyticsData(userData, userOrgData),
      },
    });
    return noop;
  }, [isFullStoryCaptureEnabled, userBasicInfo, userData, userOrgData]);

  return null;
};

export const InitiateFullStoryCapture = () => {
  // If org key is not available, it means full story was not installed
  if (!Config.VITE_FULL_STORY_ORG_KEY) {
    return null;
  }

  return <InitiateFullStoryCaptureInternal />;
};
