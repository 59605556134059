var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const LoyaltyProgram = (_a) => {
    var { primaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.968 4.251h8.95c1.059 0 1.918.817 1.918 1.826v5.43c0 1.008-.86 1.826-1.918 1.826h-8.95c-1.06 0-1.918-.818-1.918-1.826V6.077c0-1.009.859-1.826 1.918-1.826Zm-.64 7.256v-3.11h10.229v3.11c0 .336-.286.609-.64.609h-8.95c-.352 0-.639-.273-.639-.609Zm10.229-5.43V7.18H2.328V6.077c0-.336.287-.609.64-.609h8.95c.353 0 .639.273.639.609ZM6.942 10.3a.312.312 0 0 0-.32-.305H4.039l-.037.002a.31.31 0 0 0-.283.303c0 .168.144.304.32.304h2.585l.037-.002a.31.31 0 0 0 .282-.302Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "m10.81 3.865-1.836.255-.023.004c-.239.05-.33.336-.15.503l1.329 1.24-.314 1.752-.003.021c-.024.233.235.404.457.292l1.642-.826 1.641.826.021.01c.225.094.476-.088.434-.324l-.314-1.75 1.329-1.24.016-.017c.162-.174.058-.456-.19-.49l-1.835-.256-.821-1.592a.32.32 0 0 0-.562 0l-.821 1.592Z" }),
        React.createElement("path", { d: "m10.81 3.865.069.495.256-.035.12-.23-.445-.23Zm-1.836.255-.069-.495-.008.001-.008.002.085.492Zm-.023.004-.086-.492-.008.001-.009.002.103.49Zm-.15.503-.341.366.34-.366Zm1.329 1.24.492.089.048-.269-.2-.185-.34.365ZM9.816 7.62l-.492-.089-.002.01-.001.008.495.07Zm-.003.021-.495-.07-.001.01-.001.009.497.051Zm.457.292.225.446-.225-.446Zm1.642-.826.224-.447-.224-.113-.225.113.225.447Zm1.641.826-.224.446.007.004.008.004.21-.454Zm.021.01-.21.453.008.004.008.003.194-.46Zm.434-.324.492-.088-.492.088Zm-.314-1.75-.341-.366-.2.185.048.269.493-.089Zm1.329-1.24.341.365.006-.006.007-.006-.354-.354Zm.016-.017.354.353.007-.006.006-.007-.367-.34Zm-.19-.49-.069.495.07-.496Zm-1.835-.256-.445.23.12.23.256.035.069-.495Zm-.821-1.592-.445.23.445-.23Zm-.562 0 .444.23-.444-.23Zm-.89 1.097-1.836.255.138.99 1.836-.255-.138-.99Zm-1.852.258-.024.004.172.985.023-.004-.171-.985Zm-.04.007c-.601.126-.89.891-.39 1.358l.683-.731a.216.216 0 0 1 .055.216.197.197 0 0 1-.143.136l-.206-.979Zm-.39 1.358 1.33 1.24.682-.731-1.33-1.24-.681.731Zm1.179.786L9.324 7.53l.984.177.314-1.751-.984-.177ZM9.32 7.55l-.003.021.99.14.003-.021-.99-.14Zm-.005.04c-.07.669.643 1.059 1.18.79l-.45-.894a.178.178 0 0 1 .174.01c.047.03.1.102.09.197l-.994-.103Zm1.18.79 1.64-.826-.449-.894-1.641.826.45.893Zm1.191-.826 1.642.825.449-.893-1.642-.826-.449.894Zm1.657.833.02.01.42-.909-.021-.01-.42.909Zm.036.016c.538.226 1.242-.19 1.12-.872l-.985.177a.213.213 0 0 1 .08-.206.178.178 0 0 1 .173-.02l-.388.921Zm1.12-.872-.314-1.75-.985.176.314 1.75.985-.176Zm-.465-1.297 1.329-1.24-.682-.731-1.33 1.24.683.731Zm1.342-1.252.016-.017-.708-.706-.016.016.708.707Zm.029-.03c.458-.493.13-1.24-.488-1.326l-.138.99a.195.195 0 0 1-.15-.124.217.217 0 0 1 .043-.22l.733.68Zm-.488-1.326-1.835-.255-.138.99 1.835.256.138-.99Zm-1.46.01-.821-1.591-.889.458.821 1.592.89-.458Zm-.821-1.591a.82.82 0 0 0-1.45 0l.889.458a.19.19 0 0 1-.164.104.19.19 0 0 1-.164-.104l.889-.458Zm-1.45 0-.822 1.592.89.458.82-1.592-.888-.458Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "m11.912 3.08.613 1.188.012.021c.048.077.13.13.223.142l1.374.191-.995.929-.017.018a.288.288 0 0 0-.072.245l.234 1.309-1.226-.617-.025-.012a.327.327 0 0 0-.267.012l-1.227.617.235-1.31.003-.023a.29.29 0 0 0-.092-.239l-.996-.929 1.374-.19a.312.312 0 0 0 .236-.164l.613-1.189Z", fill: "white" })));
};
LoyaltyProgram.defaultProps = defaultIconProps;
export default LoyaltyProgram;
