import isNil from 'lodash/isNil';
import dateUtil from '../dateUtil';

const getDurationMinutes = (duration: string | number | undefined): number => {
  if (isNil(duration)) {
    return 0;
  }

  let durationObject;

  if (typeof duration === 'number') {
    durationObject = dateUtil.duration(duration, 'milliseconds');
  } else {
    durationObject = dateUtil.duration(duration);
  }

  return durationObject.asMinutes() || 0;
};

export default getDurationMinutes;
