var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import MuiTooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { variant } from 'styled-system';
import React, { useMemo, useState } from 'react';
import css from '@styled-system/css';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';
import noop from 'lodash/noop';
import { themeSelector } from '../utils';
// https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134
// https://codepen.io/Ferie/pen/vQOMmO
const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
// include the 'heartz' as a way to have a non matching MQ to help terminate the join
// https://git.io/vznFH
const query = ['(hover: none),', '(pointer: coarse),', '(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
/**
 * Not making this a reusable utility right now, will add on a top-level provider and/or a hook in Blocks
 * to make the app aware of touch. This is a temporary solution for app-wide mobile friendliness
 * of tooltips
 */
// istanbul ignore next
function useIsTouchDevice() {
    const isTouchBase = useMemo(() => {
        if ('ontouchstart' in window ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window.DocumentTouch && document instanceof window.DocumentTouch)) {
            return true;
        }
        return null;
    }, []);
    const isTouchFallback = useMediaQuery(query);
    if (isTouchBase === null)
        return isTouchFallback;
    return isTouchBase;
}
const StyledTooltip = styled((_a) => {
    var { maxWidth, variation } = _a, props = __rest(_a, ["maxWidth", "variation"]);
    return (React.createElement(MuiTooltip, Object.assign({ classes: { popper: props.className } }, props)));
})(variant({
    prop: 'variation',
    variants: {
        light: {
            '.MuiTooltip-tooltip': {
                backgroundColor: 'bg.regular',
                color: 'text.black',
                borderRadius: 'sm',
                lineHeight: '2',
                padding: '40',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0px 0px 100px rgba(29, 29, 31, 0.15)',
            },
            [themeSelector('dark')]: {
                '.MuiTooltip-tooltip': {
                    backgroundColor: 'bg.surfaceMenu',
                },
            },
            '.MuiTooltip-arrow': {
                color: 'bg.regular',
            },
        },
        dark: {},
    },
}), (props) => css({
    '.MuiTooltip-tooltip': {
        maxWidth: props.maxWidth,
    },
}));
StyledTooltip.defaultProps = {
    variation: 'dark',
};
/**
 * consciously choosing to disable coverage in the interest of time, as anyway this
 * is a material UI component that needs to be deprecated for Blocks version soon.
 */
// istanbul ignore next
function Tooltip(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    const { PopperProps, open: openFromProps } = props, mobileProps = __rest(props, ["PopperProps", "open"]);
    const isTouch = useIsTouchDevice();
    const [openLocal, setOpen] = useState(Boolean(openFromProps));
    const childrenToPass = children !== null && children !== void 0 ? children : React.createElement("div", null); // if no children, MUI will throw error
    if (!isTouch) {
        return React.createElement(StyledTooltip, Object.assign({}, props), childrenToPass);
    }
    const isControlled = typeof openFromProps !== 'undefined';
    const open = isControlled ? openFromProps : openLocal;
    const handleOpen = isControlled ? noop : () => setOpen(true);
    const handleClose = isControlled ? noop : () => setOpen(false);
    return (React.createElement(ClickAwayListener, { onClickAway: handleClose },
        React.createElement("div", null,
            React.createElement(StyledTooltip, Object.assign({ PopperProps: Object.assign(Object.assign({}, PopperProps), { disablePortal: true }), onClose: handleClose, open: open, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true }, mobileProps),
                React.createElement("div", { "data-testid": "tooltip-trigger", onClick: handleOpen }, childrenToPass)))));
}
/**
 * @deprecated
 */
export default Tooltip;
