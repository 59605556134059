import styled from 'styled-components';
import { boxShadow, borderRadius, compose, variant } from 'styled-system';
import Box, { Flex } from '../Box';
import cssWrapper from '../utils/cssWrapper';
const CardPrimaryContent = styled(Flex)(cssWrapper({
    justifyContent: 'center',
    alignItems: 'center',
}));
const CardSecondaryContent = styled(Flex)(cssWrapper({
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid',
    borderColor: 'border.lightGray',
    minHeight: '56px',
    padding: '16px 8px',
}));
const CardActions = styled(Flex)(cssWrapper({
    flexDirection: 'row-reverse',
    padding: '12px',
    position: 'relative',
    left: 0,
    top: 0,
    width: 'full',
    cursor: 'pointer',
    opacity: 0,
}));
/**
 * Card Extends [Box](/docs/box--basic-box) Component and adds `boxShadow` and `borderRadius` props
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Card = styled(Box)(compose(boxShadow, borderRadius), variant({
    prop: 'variation',
    variants: {
        info: {
            border: '1px solid',
            borderRadius: '8px',
            borderColor: 'border.lightGray',
            background: 'bg.white',
        },
    },
}), variant({
    prop: 'isHoverable',
    variants: {
        true: {
            '&:hover': {
                [`& ${CardActions}`]: {
                    opacity: 1,
                },
                [`& ${CardPrimaryContent}, & ${CardSecondaryContent}`]: {
                    opacity: 0.5,
                },
                cursor: 'pointer',
            },
        },
    },
}));
Card.CardActions = CardActions;
Card.CardPrimaryContent = CardPrimaryContent;
Card.CardSecondaryContent = CardSecondaryContent;
Card.defaultProps = {
    boxShadow: 'default',
    borderRadius: 24,
    border: '1px solid',
    borderColor: 'border.regular',
    isHoverable: false,
};
/**
 * @deprecated
 */
export default Card;
