import type {
  IAirRevalidateItineraryRequestOtherServiceInformation,
  IAirRevalidateItineraryRequestTravelerInfoSpecialServiceRequest,
  ILegFlightIndexInfo,
  SsrOsiPerTraveler,
  StoredSSR,
} from '../../types';

export const getSpecialServiceRequestsForFlights = (
  ssrOsiForm: SsrOsiPerTraveler | undefined | null,
  travelerId: string | undefined,
  flights: ILegFlightIndexInfo[],
): IAirRevalidateItineraryRequestTravelerInfoSpecialServiceRequest[] => {
  if (!travelerId) {
    return [];
  }

  const travelersSSRs = ssrOsiForm?.[travelerId]?.specialServiceRequests ?? [];
  const meals = ssrOsiForm?.[travelerId]?.mealPreferences?.filter((option) => !!option.code) ?? [];
  const ssrs: StoredSSR[] = travelersSSRs.concat(meals);
  if (!ssrs.length) {
    return [];
  }

  return flights.flatMap((_, flightIndex) =>
    ssrs.map((ssr) => ({
      code: ssr.code,
      info: ssr.info,
      flightIndex,
    })),
  );
};

export const getOtherServiceInformationForFlights = (
  ssrOsiForm: SsrOsiPerTraveler | undefined | null,
  flights: ILegFlightIndexInfo[],
): IAirRevalidateItineraryRequestOtherServiceInformation[] => {
  if (!ssrOsiForm) {
    return [];
  }

  const osiForFlight = Object.values(ssrOsiForm).flatMap((formValues) => {
    return flights.flatMap((_, flightIndex) => {
      return formValues.otherServiceInformation?.map((info) => ({ ...info, flightIndex })) ?? [];
    });
  });
  return osiForFlight;
};
