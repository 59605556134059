import { useMemo } from 'react';
import { useApplicationId } from '../../core/applicationId';
import { useLoggedInUserId } from '../../providers/AuthProvider';
import type { IUserOrgId } from '../../types';
import { Config } from '../../utils';

type MonitoringMetadata = {
  applicationId: string | undefined;
  bookingTmcId: string | undefined;
  contractingTmcId: string | undefined;
  isEmbed: boolean;
  organizationId: string | undefined;
  userId: string | undefined;
};

function extractMetadataFromUser(userOrgId: IUserOrgId | undefined) {
  const userId = userOrgId?.userId?.id;
  const organizationId = userOrgId?.organizationId?.id;
  const bookingTmcId = userOrgId?.tmcBasicInfo?.bookingTmc?.id?.id;
  const contractingTmcId = userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id;
  return {
    userId,
    organizationId,
    bookingTmcId,
    contractingTmcId,
  };
}

/**
 * Prepares additional metadata to be sent to external error/tracking services to tag events
 */
export function useMonitoringMetadata(): MonitoringMetadata {
  const user = useLoggedInUserId();
  const { applicationId } = useApplicationId();
  const isEmbed = Config.VITE_TARGET === 'embed';

  return useMemo(() => {
    return {
      ...extractMetadataFromUser(user),
      isEmbed,
      applicationId,
    };
  }, [user, isEmbed, applicationId]);
}
