import type { TOptionBase } from '../utils/select';
import { MAXIMUM_PARTIAL_VISIBLE_ITEMS_LENGTH } from './constants';
import type { TNestedSelectOptions, TDeprecatedSelectOptions } from './SelectRoot';

export type DeprecatedOptionWithStringifiedValue<TItem extends TOptionBase> = Omit<TItem, 'value'> & {
  $$__type: 'string' | 'number';
  value: string;
};

export function addDeprecatedTypeMetadata<TItem extends TOptionBase = TOptionBase>(
  options: TDeprecatedSelectOptions<TItem>,
): TDeprecatedSelectOptions<DeprecatedOptionWithStringifiedValue<TItem>> {
  if (!options.length) {
    return [];
  }
  const mapper = (arg: TItem[]) =>
    arg.map((opt) => ({ ...opt, value: String(opt.value), $$__type: typeof opt.value as 'string' | 'number' }));
  if ('title' in options[0] && 'list' in options[0]) {
    const nestedOptions = options as TNestedSelectOptions<TItem>;
    return nestedOptions.map((x) => {
      if (typeof x.title === 'string') {
        return { ...x, list: mapper(x.list) };
      }

      return {
        ...x,
        title: { ...x.title, value: String(x.title.value), $$__type: typeof x.title.value as 'string' | 'number' },
        list: mapper(x.list),
      };
    }) as TNestedSelectOptions<DeprecatedOptionWithStringifiedValue<TItem>>;
  }

  return mapper(options as TItem[]);
}

export function restoreDeprecatedOptionType<TItem extends TOptionBase = TOptionBase>({
  $$__type,
  value,
  ...rest
}: DeprecatedOptionWithStringifiedValue<TItem>): TItem {
  return { ...rest, value: $$__type === 'string' ? value : Number(value) } as unknown as TItem;
}

export function getPartialVisibleItemList<
  TItem extends TOptionBase = TOptionBase,
  TInternalOption extends DeprecatedOptionWithStringifiedValue<TItem> = DeprecatedOptionWithStringifiedValue<TItem>,
>({
  showPartialList,
  expanded,
  selectedValues,
  options,
}: {
  showPartialList: boolean;
  expanded: boolean;
  selectedValues: string | string[];
  options: TInternalOption[];
}): TInternalOption[] {
  let partialVisibleItemList = options;
  if (showPartialList && !expanded) {
    const { selectedItems, unselectedItems } = options.reduce(
      (acc, item) => {
        if (selectedValues?.includes(item?.value) || selectedValues === item?.value) {
          acc.selectedItems.push(item);
        } else {
          acc.unselectedItems.push(item);
        }

        return acc;
      },
      { selectedItems: [], unselectedItems: [] } as {
        selectedItems: TInternalOption[];
        unselectedItems: TInternalOption[];
      },
    );
    const itemsToBeSlectedFromUnSelectedItems = MAXIMUM_PARTIAL_VISIBLE_ITEMS_LENGTH - selectedItems.length;
    const unselectedItemsToShow =
      itemsToBeSlectedFromUnSelectedItems > 0 ? unselectedItems.slice(0, itemsToBeSlectedFromUnSelectedItems) : [];
    partialVisibleItemList = [...selectedItems, ...unselectedItemsToShow];
  }
  return partialVisibleItemList;
}
