import type { LogClientErrorRequest } from '../types/api/v0/logClientErrorRequest';
import type { Name as NameV1 } from '../types/api/v1/common/name';
import { NameSuffix } from '../types/api/v1/common/name';
import type { Name as NameV2 } from '../types/api/v2/obt/model/name';
import { NameSuffix as NameSuffixV2 } from '../types/api/v2/obt/model/name-suffix';
import { mapNameSuffixToString, mapNameV2SuffixToString } from '../constants';
import { joinTruthyValues } from '../utils/common';
import Config from '../utils/Config';
import { GenderEnum } from '../types/api/v1/common/gender';

export const getFirstCharFromString = (str: string): string => `${str}`.trim().slice(0, 1);

interface GetInitialsOptions {
  usePreferredName?: boolean;
}

export const getInitials = (
  nameObj?: Partial<NameV1 | NameV2> | null,
  { usePreferredName }: GetInitialsOptions = {},
): string => {
  const firstName = nameObj?.given ?? '';
  const lastName = nameObj?.family1 ?? '';

  if (usePreferredName && nameObj?.preferred) {
    return nameObj?.preferred.split(' ').slice(0, 2).map(getFirstCharFromString).join('');
  }

  return `${getFirstCharFromString(firstName)}${getFirstCharFromString(lastName)}`;
};

interface NameOptions {
  usePreferredName?: boolean;
}

export const getFullNameFromNameObject = (nameObj: NameV1, props: NameOptions = {}): string => {
  let suffix = '';
  if (nameObj.suffix && nameObj.suffix !== NameSuffix.UNRECOGNIZED) {
    // For eg - NameSuffix.JR => JR
    suffix = mapNameSuffixToString[nameObj.suffix];
  } else {
    suffix = nameObj.family2;
  }
  const lastName = suffix ? `${nameObj.family1} ${suffix}` : nameObj.family1;

  if (props.usePreferredName && nameObj.preferred) {
    return nameObj.preferred;
  }

  return joinTruthyValues({ first: nameObj.given, middle: nameObj.middle, last: lastName }, ' ').trim();
};

export const getFullNameFromNameV2Object = (nameV2Obj: NameV2, props: NameOptions = {}): string => {
  let suffix = '';
  if (nameV2Obj.suffix && nameV2Obj.suffix !== NameSuffixV2.NameSuffixUnknown) {
    // For eg - NameSuffixV2.Jr => JR
    suffix = mapNameV2SuffixToString[nameV2Obj.suffix];
  } else {
    suffix = nameV2Obj.family2 ?? '';
  }
  const lastName = suffix ? `${nameV2Obj.family1} ${suffix}` : nameV2Obj.family1;

  if (props.usePreferredName && nameV2Obj.preferred) {
    return nameV2Obj.preferred;
  }

  return joinTruthyValues({ first: nameV2Obj.given, middle: nameV2Obj.middle, last: lastName }, ' ').trim();
};

export const getFullName = (nameObj: NameV1 | null | undefined, props: NameOptions = {}): string => {
  if (nameObj) {
    return getFullNameFromNameObject(nameObj, props);
  }
  return '';
};

export const reportIssueReqParams = (
  userMessage: string,
  screenshot: string,
  traceId: string,
  url: string,
): LogClientErrorRequest => {
  const debugToolLink = traceId ? `${Config.VITE_DEBUGTOOL_BASE_URL}${traceId}` : 'No trace ID';
  const zipkinLink = traceId ? `${Config.VITE_ZIPKIN_BASE_URL}${traceId}` : 'No trace ID';
  return {
    messages: [
      { key: 'message', value: userMessage },
      { key: 'debugTool', value: debugToolLink },
      { key: 'zipkinTool', value: zipkinLink },
      { key: 'url', value: url },
    ],
    images: [screenshot],
  };
};

export const splitObjectIntoArray = (object: any): any[] =>
  Object.entries(object)
    .filter(([_key, data]) => data !== undefined)
    .map(([key, data]): any => ({ [key]: data }));

export const v1EnumToV2EnumBEProtos = <V2Enum>(v1EnumValue: number | undefined, v1EnumObject: any): V2Enum => {
  const v2EnumValue = v1EnumObject[v1EnumValue ?? 0]; // this relation comes from v1 protos generation and the v2 open api model for protos generation
  return v2EnumValue as unknown as V2Enum;
};

export const v1EnumToV2EnumGenderProtos = <UserGenderEnum>(v1EnumValue: GenderEnum | undefined): UserGenderEnum => {
  return v1EnumToV2EnumBEProtos(v1EnumValue === 0 ? GenderEnum.UNSPECIFIED : v1EnumValue, GenderEnum);
};
