import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const traveler_info_container = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.base};
    ${flex.init}
    ${flex.align.center}
    ${flex.justify.between}
    border-radius: ${palette.border.radiusRegular};
    ${paddings.x.compact}
    ${paddings.y.tight}
  `,
);
