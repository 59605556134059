import { v4 as uuidv4 } from 'uuid';
import {
  setSessionId,
  getSessionId,
  SESSION_ID_URL_SEARCH_PARAMS_KEY,
  clearSessionId,
  parseSessionIdFromURL,
  verifyUUID,
} from './common';

export const initializeSessionId = (): string => {
  const existingSessionId = getSessionId();
  if (existingSessionId) {
    return existingSessionId;
  }

  const sessionIdFromUrl = parseSessionIdFromURL();
  // Check that session-id from URL is a valid UUID via regex
  const isValidUUID = sessionIdFromUrl && verifyUUID(sessionIdFromUrl);
  if (isValidUUID) {
    setSessionId(sessionIdFromUrl);
    return sessionIdFromUrl;
  }

  const uuidSessionId = uuidv4();
  setSessionId(uuidSessionId);
  return uuidSessionId;
};

export { getSessionId, clearSessionId, SESSION_ID_URL_SEARCH_PARAMS_KEY };
