import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Change = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.5004 7.7931C25.0093 7.37931 24.2746 7.40365 23.8121 7.86612L23.7391 7.94563C23.3253 8.43674 23.3497 9.17142 23.8121 9.63388L25.8928 11.7144H14.1806L14.028 11.7168C11.4445 11.7974 9.37496 13.917 9.37496 16.52V21.5925L9.37838 21.6858C9.4261 22.3326 9.96598 22.8425 10.625 22.8425C11.3153 22.8425 11.875 22.2829 11.875 21.5925V16.52L11.8782 16.3976C11.9418 15.1811 12.9483 14.2144 14.1806 14.2144H25.8926L23.8121 16.2949L23.7391 16.3744C23.3253 16.8655 23.3497 17.6002 23.8121 18.0626C24.3003 18.5508 25.0918 18.5508 25.5799 18.0626L29.7805 13.862C30.0149 13.6348 30.1606 13.3166 30.1606 12.9644C30.1606 12.6136 30.0161 12.2966 29.7834 12.0696L25.5799 7.86612L25.5004 7.7931ZM13.9556 21.9367C14.4437 21.4485 15.2352 21.4485 15.7233 21.9366C16.1858 22.399 16.2102 23.1337 15.7965 23.6249L15.7235 23.7044L13.6425 25.7852H25.3554C26.5872 25.7852 27.5935 24.8185 27.6572 23.6021L27.6604 23.4796V18.4071C27.6604 17.7168 28.22 17.1571 28.9104 17.1571C29.5694 17.1571 30.1092 17.667 30.157 18.3138L30.1604 18.4071V23.4796C30.1604 26.0826 28.0909 28.2023 25.5079 28.2829L25.3554 28.2852H13.643L15.7234 30.366C16.1859 30.8285 16.2102 31.5632 15.7964 32.0543L15.7234 32.1338C15.261 32.5963 14.5263 32.6206 14.0352 32.2068L13.9556 32.1338L9.75012 27.9283C9.51849 27.7014 9.37476 27.3851 9.37476 27.0352C9.37476 26.6774 9.52515 26.3546 9.76618 26.1267L13.9556 21.9367Z", fill: themeEnrichedPrimaryColor })));
};
Change.defaultProps = defaultIconProps;
export default Change;
