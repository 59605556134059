import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CarouselNext = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 32 32", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.933 16L5.311 26.622 10.689 32l16-16-16-16-5.378 5.378z", fill: themeEnrichedPrimaryColor })));
};
CarouselNext.defaultProps = defaultIconProps;
export default CarouselNext;
