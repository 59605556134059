import dateUtil from '../dateUtil';

const getDateDiff = (earlier: string, later: string, dateFormat = 'YYYY-MM-DD'): number => {
  // Convert date time to date.
  const start = dateUtil(earlier, dateFormat);
  const end = dateUtil(later, dateFormat);
  return end.diff(start, 'days');
};

export default getDateDiff;
