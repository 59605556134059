const country = 'en-US';
const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const timeOptions: Intl.DateTimeFormatOptions = {
  timeZoneName: 'short',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

interface IGetDefaultLocaleDateTimeString {
  date: string;
  time: string;
}

function getDefaultLocaleDateTimeString(x: string, timeZone?: string): IGetDefaultLocaleDateTimeString;
function getDefaultLocaleDateTimeString(x: undefined, timeZone?: string): undefined;
function getDefaultLocaleDateTimeString(
  rawDate: string | undefined,
  timeZone: string | undefined = undefined,
): IGetDefaultLocaleDateTimeString | undefined {
  if (!rawDate) {
    return undefined;
  }

  const date = new Date(rawDate);

  const datePart = date.toLocaleDateString(country, { ...dateOptions, timeZone });

  const timePart = date.toLocaleTimeString(country, { ...timeOptions, timeZone });

  return {
    date: datePart,
    time: timePart,
  };
}

export default getDefaultLocaleDateTimeString;
