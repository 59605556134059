import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { ProcessedError } from 'obt-common';
import SpotnanaError from 'obt-common/api/SpotnanaError';

export interface ISnackbarErrorContext {
  snackbarError: ProcessedError | null;
  setSnackbarError: (error: ProcessedError | null) => void;
}

const SnackbarErrorContext = createContext<ISnackbarErrorContext | null>(null);

/**
 * Disabling eslint rule here to clearly mark that this component accepts
 * `children` prop only.
 *
 * Using `Record<string, never>` will cause an error while passing `children`
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export const SnackbarErrorContextProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [snackbarError, setSnackbarError] = useState<ISnackbarErrorContext['snackbarError']>(null);

  const context = useMemo<ISnackbarErrorContext>(
    () => ({
      snackbarError,
      setSnackbarError,
    }),
    [snackbarError],
  );

  return <SnackbarErrorContext.Provider value={context}>{children}</SnackbarErrorContext.Provider>;
};

export const useSnackbarErrorContext = (): ISnackbarErrorContext => {
  const context = useContext(SnackbarErrorContext);

  if (!context) {
    throw new SpotnanaError(`"useSnackbarErrorContext" should be used inside "Snackbar
    ErrorContextProvider"`);
  }

  return context;
};
