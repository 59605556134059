import { TFunction } from 'react-i18next';
import { toast } from 'src/utils/Toast';
import { LanguageModule } from 'obt-common/translations/types';
import { ToastNotificationContent, ToastNotificationType } from './ToastNotificationContent';

interface ILabelledItem {
  label: string;
}

export function translateLabelValuePairs<T extends ILabelledItem[]>(
  items: T,
  translateFunc: TFunction<keyof LanguageModule>,
): T {
  return items.map((item) => ({
    ...item,
    label: translateFunc(item.label),
  })) as T;
}

interface ILabelKeyItem {
  labelKey: string;
}

export function translateLabelKeyValuePairs<T extends ILabelKeyItem[]>(items: T, translateFunc: TFunction): T {
  return items.map((item) => ({
    ...item,
    labelKey: translateFunc(item.labelKey),
  })) as T;
}
interface ILabelledItem {
  label: string;
}

export function translateLabelValue<T extends ILabelledItem>(item: T, translateFunc: TFunction): T {
  return {
    ...item,
    label: translateFunc(item.label),
  } as T;
}

/**
 * @deprecated
 * Use regular `toast` from `@spotnana/blocks/src/Toast` instead as the default design of toast is the same as
 * ToastNotificationContent component.
 */
export const showSuccessNotification = (message: string) => {
  toast.custom(<ToastNotificationContent type={ToastNotificationType.Success} content={message} />, {
    position: 'top-right',
  });
};

export const showErrorNotification = (message: string) => {
  toast.custom(<ToastNotificationContent type={ToastNotificationType.Error} content={message} />, {
    position: 'top-right',
  });
};
