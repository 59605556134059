var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Rail = (_a) => {
    var { primaryColor, title } = _a, restProps = __rest(_a, ["primaryColor", "title"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 40 40", version: "1.1" }, restProps),
        title && React.createElement("title", null, title),
        React.createElement("g", { id: "Rail", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M25.0625,3 L14.9375,3 C10.278292,3.00168974 6.50167641,6.80832712 6.5,11.504573 L6.5,28.513719 C6.50598805,30.5580857 7.71802293,32.4028941 9.5830625,33.2063723 L8.68176875,34.1148308 C8.03984888,34.7818875 8.04790524,35.8447631 8.69986188,36.5018559 C9.35181851,37.1589487 10.4063121,37.1669939 11.0680625,36.519924 L13.9487937,33.6164628 L26.051375,33.6164628 L28.9319375,36.519924 C29.5940769,37.1645234 30.6465806,37.1553047 31.2975063,36.4992045 C31.9484321,35.8431042 31.9575781,34.7822338 31.3180625,34.1148308 L30.4167687,33.2063723 C32.2818744,32.402946 33.493983,30.5581264 33.5,28.513719 L33.5,11.504573 C33.4983236,6.80832712 29.721708,3.00168974 25.0625,3 L25.0625,3 Z M13.25,28.513719 C12.3180195,28.513719 11.5625,27.7521936 11.5625,26.8128044 C11.5625,25.8734152 12.3180195,25.1118898 13.25,25.1118898 C14.1819805,25.1118898 14.9375,25.8734152 14.9375,26.8128044 C14.93472,27.7510305 14.1808266,28.5109169 13.25,28.513719 Z M11.5625,18.3082314 L11.5625,11.504573 C11.5679678,9.62808221 13.0758085,8.1082551 14.9375,8.1027438 L25.0625,8.1027438 C26.9241915,8.1082551 28.4320322,9.62808221 28.4375,11.504573 L28.4375,18.3082314 C28.4320322,20.1847222 26.9241915,21.7045493 25.0625,21.7100606 L14.9375,21.7100606 C13.0758085,21.7045493 11.5679678,20.1847222 11.5625,18.3082314 Z M26.75,28.513719 C25.8180195,28.513719 25.0625,27.7521936 25.0625,26.8128044 C25.0625,25.8734152 25.8180195,25.1118898 26.75,25.1118898 C27.6819805,25.1118898 28.4375,25.8734152 28.4375,26.8128044 C28.43472,27.7510305 27.6808266,28.5109169 26.75,28.513719 Z", id: "Shape", fill: themeEnrichedPrimaryColor }))));
};
Rail.defaultProps = Object.assign(Object.assign({}, defaultIconProps), { title: 'Rail' });
export default Rail;
