import { css } from '@emotion/react';

export const spotnana_rich_text_editor = css`
  /* stylelint-disable */
  .spotnana-rich-text-editor.color-picker-wrapper {
    padding: 20px;
  }

  .spotnana-rich-text-editor.color-picker-basic-color {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    padding: 0;
  }

  .spotnana-rich-text-editor.color-picker-basic-color button {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    list-style-type: none;
  }

  .spotnana-rich-text-editor.color-picker-basic-color button.active {
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
  }

  .spotnana-rich-text-editor.color-picker-saturation {
    width: 100%;
    position: relative;
    margin-top: 15px;
    height: 150px;
    background-image: linear-gradient(transparent, rgb(0, 0, 0)),
      linear-gradient(to right, rgb(255, 255, 255), transparent);
    user-select: none;
  }
  .spotnana-rich-text-editor.color-picker-saturation_cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    transform: translate(-10px, -10px);
  }
  .spotnana-rich-text-editor.color-picker-hue {
    width: 100%;
    position: relative;
    margin-top: 15px;
    height: 12px;
    background-image: linear-gradient(
      to right,
      rgb(255, 0, 0),
      rgb(255, 255, 0),
      rgb(0, 255, 0),
      rgb(0, 255, 255),
      rgb(0, 0, 255),
      rgb(255, 0, 255),
      rgb(255, 0, 0)
    );
    user-select: none;
    border-radius: 12px;
  }

  .spotnana-rich-text-editor.color-picker-hue_cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgb(0, 0, 3) 0 0 0 0.5px;
    box-sizing: border-box;
    transform: translate(-10px, -4px);
  }

  .spotnana-rich-text-editor.color-picker-color {
    border: 1px solid rgb(204, 204, 204);
    margin-top: 15px;
    width: 100%;
    height: 20px;
  }
`;
