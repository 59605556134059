/* eslint-disable @typescript-eslint/ban-types */
import indexBy from 'lodash/fp/indexBy';
import type { SelectItemProps } from '@ariakit/react';
import { removeSpaceLike } from '.';
import type { TDeprecatedSelectOptions } from '../SelectRoot';
import { pipe } from './fp';

type OptionRequiredProps<TValue> = {
  /**
   * String to be rendered on the option item
   */
  label: string;

  /**
   * Allowing numbers to have easy support for enum values
   *
   * Restricting to string | number and not allowing objects as MUI Select requires referential equality
   * to match selected option from the list.
   *
   * It is better to stick to primitives for value property,
   * and instead allow any other keys on the TOptionBase for non-primitive data like objects, arrays.
   *
   */
  value: TValue;
};

/**
 * Select/Autocomplete option objects can be ANYTHING. They just need to have label, value as defined below.
 *
 * They are allowed to have as many properties as needed beyond that. There are almost no use-cases where
 * you would need only "label" and "value" for your data.
 * Ex:
 * ```js
 * const abc: TOptionBase<{ nested: boolean }> = {
    label: 'hello',
    value: 'world',
    nested: false,
  };
    ```
 */
export type TOptionBase<
  OptionData extends object = {},
  TValue extends string | number = string | number,
> = OptionRequiredProps<TValue> &
  OptionData & {
    /**
     * @deprecated
     */
    SPT__IS_PRESENTATIONAL?: true | undefined;
    /**
     * @deprecated
     */
    SPT__PARENT_ID?: string;
    /**
     * Allow data-driven styling of individual options.
     */
    sx?: React.CSSProperties;
    isHidden?: true | undefined;
    itemProps?: Pick<SelectItemProps, 'disabled' | 'aria-disabled' | 'aria-label'>;
  };

export function isNestedList<Item extends TOptionBase>(
  opts: TDeprecatedSelectOptions,
): opts is { title: string | Item; list: Item[] }[] {
  return Boolean(opts?.length && 'title' in opts[0]);
}

type TFIndexByValue = <TItem extends TOptionBase>(
  itemList: TDeprecatedSelectOptions<TItem>,
) => {
  [index: string]: TItem;
};
// using any in lodash because TFIndexByValue is strongly typed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const indexByVal = indexBy<any>((x) => x.value);
export const indexByDeprecatedOptionValue: TFIndexByValue = <TItem extends TOptionBase>(
  x: TDeprecatedSelectOptions<TItem>,
) => {
  return isNestedList(x)
    ? pipe(
        x,
        (y) =>
          y.flatMap((i) => {
            if (typeof i.title !== 'string') {
              return [i.title, ...i.list];
            }
            return i.list;
          }),
        indexByVal,
      )
    : indexByVal(x);
};

export const getOptionKey = <T extends { label: string; value: string | number }>({ label, value }: T) =>
  removeSpaceLike(`${label + value}`);

export const searchByLabel = <TItem extends TOptionBase>(search: string, item: TItem): boolean =>
  item.label.toLowerCase().includes(search?.toLowerCase());
