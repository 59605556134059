interface IAllianceAirlines {
  alliance: string;
  airline: string;
  airlineCode: string;
  ICOA: string;
}

// This mapping was provided by Vedant
export const allianceAirlines: IAllianceAirlines[] = [
  {
    alliance: 'Star Alliance',
    airline: 'Aegean Airlines',
    airlineCode: 'A3',
    ICOA: 'AEE',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Air Canada',
    airlineCode: 'AC',
    ICOA: 'ACA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Air China',
    airlineCode: 'CA',
    ICOA: 'CCA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Air India',
    airlineCode: 'AI',
    ICOA: 'AIC',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Air New Zealand',
    airlineCode: 'NZ',
    ICOA: 'ANZ',
  },
  {
    alliance: 'Star Alliance',
    airline: 'All Nippon Airways',
    airlineCode: 'NH',
    ICOA: 'ANA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Asiana Airlines',
    airlineCode: 'OZ',
    ICOA: 'AAR',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Austrian Airlines',
    airlineCode: 'OS',
    ICOA: 'AUA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Avianca',
    airlineCode: 'AV',
    ICOA: 'AVA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Brussels Airlines',
    airlineCode: 'SN',
    ICOA: 'BEL',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Copa Airlines',
    airlineCode: 'CM',
    ICOA: 'CMP',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Croatia Airlines',
    airlineCode: 'OU',
    ICOA: 'CTN',
  },
  {
    alliance: 'Star Alliance',
    airline: 'EgyptAir',
    airlineCode: 'MS',
    ICOA: 'MSR',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Ethiopian Airlines',
    airlineCode: 'ET',
    ICOA: 'ETH',
  },
  {
    alliance: 'Star Alliance',
    airline: 'EVA Air',
    airlineCode: 'BR',
    ICOA: 'EVA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'LOT Polish Airlines',
    airlineCode: 'LO',
    ICOA: 'LOT',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Lufthansa',
    airlineCode: 'LH',
    ICOA: 'DLH',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Scandinavian Airlines',
    airlineCode: 'SK',
    ICOA: 'SAS',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Shenzhen Airlines',
    airlineCode: 'ZH',
    ICOA: 'CSZ',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Singapore Airlines',
    airlineCode: 'SQ',
    ICOA: 'SIA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'South African Airways',
    airlineCode: 'SA',
    ICOA: 'SAA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Swiss International Air Lines',
    airlineCode: 'LX',
    ICOA: 'SWR',
  },
  {
    alliance: 'Star Alliance',
    airline: 'TAP Air Portugal',
    airlineCode: 'TP',
    ICOA: 'TAP',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Thai Airways International',
    airlineCode: 'TG',
    ICOA: 'THA',
  },
  {
    alliance: 'Star Alliance',
    airline: 'Turkish Airlines',
    airlineCode: 'TK',
    ICOA: 'THY',
  },
  {
    alliance: 'Star Alliance',
    airline: 'United Airlines',
    airlineCode: 'UA',
    ICOA: 'UAL',
  },
  {
    alliance: 'Oneworld',
    airline: 'American Airlines',
    airlineCode: 'AA',
    ICOA: 'AAL',
  },
  {
    alliance: 'Oneworld',
    airline: 'British Airways',
    airlineCode: 'BA',
    ICOA: 'BAW',
  },
  {
    alliance: 'Oneworld',
    airline: 'Cathay Pacific',
    airlineCode: 'CX',
    ICOA: 'CPA',
  },
  {
    alliance: 'Oneworld',
    airline: 'Qantas',
    airlineCode: 'QF',
    ICOA: 'QFA',
  },
  {
    alliance: 'Oneworld',
    airline: 'Finnair',
    airlineCode: 'AY',
    ICOA: 'FIN',
  },
  {
    alliance: 'Oneworld',
    airline: 'Iberia Airlines',
    airlineCode: 'IB',
    ICOA: 'IBE',
  },
  {
    alliance: 'Oneworld',
    airline: 'Japan Airlines',
    airlineCode: 'JL',
    ICOA: 'JAL',
  },
  {
    alliance: 'Oneworld',
    airline: 'Malaysia Airlines',
    airlineCode: 'MH',
    ICOA: 'MAS',
  },
  {
    alliance: 'Oneworld',
    airline: 'Qatar Airways',
    airlineCode: 'QR',
    ICOA: 'QTR',
  },
  {
    alliance: 'Oneworld',
    airline: 'Royal Air Maroc',
    airlineCode: 'AT',
    ICOA: 'RAM',
  },
  {
    alliance: 'Oneworld',
    airline: 'Royal Jordanian',
    airlineCode: 'RJ',
    ICOA: 'RJA',
  },
  {
    alliance: 'Oneworld',
    airline: 'S7 Airlines',
    airlineCode: 'S7',
    ICOA: 'SBI',
  },
  {
    alliance: 'Oneworld',
    airline: 'SriLankan Airlines',
    airlineCode: 'UL',
    ICOA: 'ALK',
  },
  {
    alliance: 'Oneworld',
    airline: 'Alaska Airlines',
    airlineCode: 'AS',
    ICOA: 'ASA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Aeroflot',
    airlineCode: 'SU',
    ICOA: 'AFL',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Aerolíneas Argentinas',
    airlineCode: 'AR',
    ICOA: 'ARG',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Aeroméxico',
    airlineCode: 'AM',
    ICOA: 'AMX',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Air Europa',
    airlineCode: 'UX',
    ICOA: 'AEA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Air France',
    airlineCode: 'AF',
    ICOA: 'AFR',
  },
  {
    alliance: 'SkyTeam',
    airline: 'ITA Airways',
    airlineCode: 'AZ',
    ICOA: 'AZA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'China Airlines',
    airlineCode: 'CI',
    ICOA: 'CAL',
  },
  {
    alliance: 'SkyTeam',
    airline: 'China Eastern Airlines',
    airlineCode: 'MU',
    ICOA: 'CES',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Czech Airlines',
    airlineCode: 'OK',
    ICOA: 'CSA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Delta Air Lines',
    airlineCode: 'DL',
    ICOA: 'DAL',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Garuda Indonesia',
    airlineCode: 'GA',
    ICOA: 'GIA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Kenya Airways',
    airlineCode: 'KQ',
    ICOA: 'KQA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'KLM Royal Dutch Airlines',
    airlineCode: 'KL',
    ICOA: 'KLM',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Korean Air',
    airlineCode: 'KE',
    ICOA: 'KAL',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Middle East Airlines',
    airlineCode: 'ME',
    ICOA: 'MEA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Saudia',
    airlineCode: 'SV',
    ICOA: 'SVA',
  },
  {
    alliance: 'SkyTeam',
    airline: 'TAROM',
    airlineCode: 'RO',
    ICOA: 'ROT',
  },
  {
    alliance: 'SkyTeam',
    airline: 'Vietnam Airlines',
    airlineCode: 'VN',
    ICOA: 'HVN',
  },
  {
    alliance: 'SkyTeam',
    airline: 'XiamenAir',
    airlineCode: 'MF',
    ICOA: 'CXA',
  },
  {
    alliance: 'Vanilla Alliance',
    airline: 'Air Austral',
    airlineCode: 'UU',
    ICOA: 'REU',
  },
  {
    alliance: 'Vanilla Alliance',
    airline: 'Air Madagascar',
    airlineCode: 'MD',
    ICOA: 'MDG',
  },
  {
    alliance: 'Vanilla Alliance',
    airline: 'Air Mauritius',
    airlineCode: 'MK',
    ICOA: 'MAU',
  },
  {
    alliance: 'Vanilla Alliance',
    airline: 'Air Seychelles',
    airlineCode: 'HM',
    ICOA: 'SEY',
  },
  {
    alliance: 'Vanilla Alliance',
    airline: "Int'Air Îles",
    airlineCode: 'I7',
    ICOA: 'IIA',
  },
  {
    alliance: 'U-FLY Alliance',
    airline: 'HK Express',
    airlineCode: 'UO',
    ICOA: 'HKE',
  },
  {
    alliance: 'U-FLY Alliance',
    airline: 'Lucky Air',
    airlineCode: '8L',
    ICOA: 'LKE',
  },
  {
    alliance: 'U-FLY Alliance',
    airline: 'Urumqi Air',
    airlineCode: 'UQ',
    ICOA: 'COH',
  },
  {
    alliance: 'U-FLY Alliance',
    airline: 'West Air',
    airlineCode: 'PN',
    ICOA: 'CHB',
  },
  {
    alliance: 'U-FLY Alliance',
    airline: 'Eastar Jet',
    airlineCode: 'ZE',
    ICOA: 'ESR',
  },
  {
    alliance: 'Value Alliance',
    airline: 'Cebu Pacific',
    airlineCode: '5J',
    ICOA: 'CEB',
  },
  {
    alliance: 'Value Alliance',
    airline: 'Cebgo',
    airlineCode: 'DG',
    ICOA: 'SRQ',
  },
  {
    alliance: 'Value Alliance',
    airline: 'Jeju Air',
    airlineCode: '7C',
    ICOA: 'JJA',
  },
  {
    alliance: 'Value Alliance',
    airline: 'Nok Air',
    airlineCode: 'DD',
    ICOA: 'NOK',
  },
  {
    alliance: 'Value Alliance',
    airline: 'Scoot',
    airlineCode: 'TR',
    ICOA: 'TWG',
  },
];

export const airlineAllianceMap: { [k: string]: string } = {};

export const partnerAirlines: Record<string, string[]> = {
  TN: ['AA'],
  EY: ['AA'],
  HA: ['AA'],
  '3M': ['AA'],
  '9K': ['AA'],
  FJ: ['AA'],
  '6E': ['AA'],
  CZ: ['AA'],
  G3: ['AA'],
  AA: ['TN', 'EY', 'HA', '3M', '9K', 'FJ', '6E', 'CZ', 'G3'],
};

allianceAirlines.forEach((allianceAirline: IAllianceAirlines) => {
  airlineAllianceMap[allianceAirline.airlineCode] = allianceAirline.alliance;
});
