/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CancellationPolicy1 } from './cancellation-policy1';
// May contain unused imports in some cases
// @ts-ignore
import { Cost } from './cost';
// May contain unused imports in some cases
// @ts-ignore
import { Driver } from './driver';
// May contain unused imports in some cases
// @ts-ignore
import { LimoDetails } from './limo-details';
// May contain unused imports in some cases
// @ts-ignore
import { LimoLegInfo } from './limo-leg-info';
// May contain unused imports in some cases
// @ts-ignore
import { PnrStatus } from './pnr-status';

/**
 * Limo info
 * @export
 * @interface LimoInfo
 */
export interface LimoInfo {
    /**
     * 
     * @type {CancellationPolicy1}
     * @memberof LimoInfo
     */
    'cancellationPolicy'?: CancellationPolicy1;
    /**
     * 
     * @type {Driver}
     * @memberof LimoInfo
     */
    'driver'?: Driver;
    /**
     * List of legs covered in the booking.
     * @type {Array<LimoLegInfo>}
     * @memberof LimoInfo
     */
    'legs': Array<LimoLegInfo>;
    /**
     * 
     * @type {LimoDetails}
     * @memberof LimoInfo
     */
    'limoDetails': LimoDetails;
    /**
     * Payment type.
     * @type {string}
     * @memberof LimoInfo
     */
    'paymentType'?: LimoInfoPaymentTypeEnum;
    /**
     * 
     * @type {PnrStatus}
     * @memberof LimoInfo
     */
    'pnrStatus'?: PnrStatus;
    /**
     * 
     * @type {Cost}
     * @memberof LimoInfo
     */
    'rate': Cost;
    /**
     * Limo confirmation number.
     * @type {string}
     * @memberof LimoInfo
     */
    'vendorConfirmationNumber': string;
}

export const LimoInfoPaymentTypeEnum = {
    UnknownPaymentType: 'UNKNOWN_PAYMENT_TYPE',
    PayAtVendor: 'PAY_AT_VENDOR',
    Prepaid: 'PREPAID'
} as const;

export type LimoInfoPaymentTypeEnum = typeof LimoInfoPaymentTypeEnum[keyof typeof LimoInfoPaymentTypeEnum];


