import React from 'react';
import { defaultIconProps } from './common';
const Card = ({ width, height }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 2 25 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M0 5.11381C0 3.98414 0.915781 3.06836 2.04545 3.06836H22.5C23.6297 3.06836 24.5455 3.98414 24.5455 5.11381V19.432C24.5455 20.5617 23.6297 21.4775 22.5 21.4775H2.04545C0.915781 21.4775 0 20.5617 0 19.432V5.11381Z", fill: "url(#paint0_linear_1137_48223)" }),
    React.createElement("path", { opacity: "0.4", fillRule: "evenodd", clipRule: "evenodd", d: "M8.422 8.18199C9.9691 8.18199 10.3559 7.83962 11.9582 5.70076C13.1718 4.05818 15.1564 3.07156 17.1973 3.06836H22.5C23.6297 3.06836 24.5455 3.98414 24.5455 5.11381V19.432C24.5455 20.5617 23.6297 21.4775 22.5 21.4775H19.4318V10.2274H11.0189C9.59121 10.2274 9.12497 10.8301 7.63469 12.7566C7.56773 12.8432 7.4987 12.9324 7.42742 13.0244C6.1566 14.6696 4.14508 15.3411 2.04544 15.3411L0 15.3601V8.18199H8.422Z", fill: "#656565" }),
    React.createElement("path", { opacity: "0.4", d: "M2.0459 9.61319C2.0459 9.38726 2.22905 9.2041 2.45499 9.2041H4.70499C4.93092 9.2041 5.11408 9.38726 5.11408 9.61319V10.8405C5.11408 11.0664 4.93092 11.2496 4.70499 11.2496H2.45499C2.22905 11.2496 2.0459 11.0664 2.0459 10.8405V9.61319Z", fill: "black" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_1137_48223", x1: "0.511364", y1: "20.9661", x2: "24.5455", y2: "4.09109", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#919191" }),
            React.createElement("stop", { offset: "0.286342", stopColor: "#B5B5B5" }),
            React.createElement("stop", { offset: "1", stopColor: "#D3D3D3" })))));
Card.defaultProps = defaultIconProps;
export default Card;
