/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Bed types.
 * @export
 * @enum {string}
 */
export enum BedType {
    UnknownBedType = 'UNKNOWN_BED_TYPE',
    Double = 'DOUBLE',
    Futon = 'FUTON',
    King = 'KING',
    Murphy = 'MURPHY',
    Queen = 'QUEEN',
    Sofa = 'SOFA',
    TatamiMats = 'TATAMI_MATS',
    Twin = 'TWIN',
    Single = 'SINGLE',
    Full = 'FULL',
    RunOfTheHouse = 'RUN_OF_THE_HOUSE',
    Dorm = 'DORM',
    Water = 'WATER',
    PullOut = 'PULL_OUT'
}



