type ReactRef<T> = React.RefCallback<T> | React.MutableRefObject<T>;

function assignRef<T = unknown>(ref: ReactRef<T> | null | undefined, value: T) {
  // filter out invalid refs
  if (!ref) return;

  if (typeof ref === 'function') {
    ref(value);
    return;
  }

  if ('current' in ref) {
    /**
     * The whole purpose of this function is to assign the value to the ref
     * So disabling this lint rule
     */
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
    return;
  }

  // Guard Clause
  console.error('Cannot assign value to ref', ref);
}

/**
 * Merge multiple refs into a single callback ref
 * @param refs
 * @returns
 */
export function mergeRefs<T>(...refs: (ReactRef<T> | null | undefined)[]): React.RefCallback<T> {
  return (node: T | null) => {
    refs.forEach((ref) => {
      assignRef(ref, node);
    });
  };
}
