import { Select as SelectBase, type SelectProps } from '@ariakit/react';
import clsx from 'clsx';

import { type RefObject, useRef } from 'react';
import { ellipsis } from 'polished';
import { Typography } from '../../Typography';
import { flex, margins } from '../../utils';
import { Arrow } from '../Arrow';
import { useSelectConfig } from '../SelectConfigContext';
import { ClearButton } from '../ClearButton';

export function SelectMultiValue({ placeholder }: { placeholder?: string }) {
  const {
    selectStore,
    disabled = false,
    showClearButton,
    state,
    size,
    triggerRef,
    helperText,
    labelId,
    required,
  } = useSelectConfig<'select', 'multiple'>();
  const [selectedItems] = selectStore.getSelection(selectStore.useState('value'));
  const renderedValue = selectedItems.length ? `${selectedItems[0].label}` : placeholder;
  const isPlaceholder = renderedValue === placeholder;

  const clearBtnRef = useRef<HTMLButtonElement>(null);

  const handleClear = () => {
    selectStore.clearSelection?.();
  };

  const handleClick: SelectProps['toggleOnClick'] = (event) => {
    const hasEventOriginatedInsideClearButton = !!clearBtnRef.current?.contains(event.target as HTMLButtonElement);
    return !hasEventOriginatedInsideClearButton;
  };

  const isClearButtonVisible = selectedItems.length > 0 && showClearButton;

  const descriptionProps = helperText ? { 'aria-describedby': `${labelId}-description` } : {};

  return (
    <SelectBase
      ref={triggerRef as RefObject<HTMLButtonElement>}
      disabled={disabled}
      store={selectStore}
      className={clsx('BlocksSelect-Trigger', `BlocksSelect-${size}`, {
        'is-placeholder': isPlaceholder,
        [`state-${state}`]: Boolean(state),
      })}
      toggleOnClick={handleClick}
      aria-required={required}
      aria-labelledby={labelId}
      {...descriptionProps}
    >
      <SelectMultiValueInternal placeholder={placeholder} />
      <div className={clsx({ 'Select-Actions': true, 'Select-Right': selectedItems.length <= 1 })}>
        {isClearButtonVisible && <ClearButton ref={clearBtnRef} onClick={handleClear} />}
        <Arrow store={selectStore} />
      </div>
    </SelectBase>
  );
}

export function SelectMultiValueInternal({ placeholder }: { placeholder?: string }) {
  const { selectStore, size, showClearButton } = useSelectConfig<'select', 'multiple'>();
  const [selectedItems] = selectStore.getSelection(selectStore.useState('value'));
  const renderedValue = selectedItems.length ? `${selectedItems[0].label}` : placeholder;
  return (
    <>
      <Typography
        as="span"
        variant={size === 'standard' ? 'body1' : 'body2'}
        kind={selectedItems.length ? 'medium' : 'regular'}
        style={ellipsis(showClearButton ? '65%' : '70%')}
      >
        {renderedValue}
      </Typography>
      <div className="Select-Right" css={[flex.init, flex.justify.between]}>
        {selectedItems.length > 1 && (
          <Typography variant="body1" kind="medium" color="link" css={margins.xr.point25}>{`+ ${
            selectedItems.length - 1
          } more`}</Typography>
        )}
      </div>
    </>
  );
}
