import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const ReclinerSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.725 3.924c.677 0 1.225.548 1.225 1.225v3.809l6.662 7.217h7.427l2.205 2.94a1.225 1.225 0 11-1.96 1.47l-1.47-1.96H9.612a2.45 2.45 0 01-1.8-.788L1.15 10.62A2.45 2.45 0 01.5 8.958v-3.81c0-.676.548-1.224 1.225-1.224z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.413 8.732a.804.804 0 111.185-1.087l2.736 3.126a3.063 3.063 0 002.303 1.045l5.332.003 3.978 4.973a.472.472 0 11-.746.578l-3.026-4.031a.721.721 0 00-.576-.288l-6.256-.008c-.499-.001-.976-.204-1.322-.563L6.413 8.732zm-.55-2.244a2.03 2.03 0 00-.332 3.094l3.608 3.747a3.062 3.062 0 002.202.939l6.005.007 2.876 3.83a1.697 1.697 0 102.682-2.079l-4.144-5.18a.673.673 0 00-.525-.252l-5.598-.003c-.53 0-1.033-.229-1.382-.627L8.52 6.838a2.03 2.03 0 00-2.656-.35z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M7.85 3.925a1.838 1.838 0 11-3.675 0 1.838 1.838 0 013.675 0z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.967 20.463c0 .338.274.612.612.612h6.622a.613.613 0 000-1.225H8.58a.613.613 0 00-.612.613z", fill: themeEnrichedPrimaryColor })));
};
export default ReclinerSeat;
ReclinerSeat.defaultProps = defaultIconProps;
