export type ExtendedDateStyle =
  | NonNullable<Exclude<Intl.DateTimeFormatOptions['dateStyle'], 'short'>>
  | 'full-compact'
  | 'compact'
  | 'medium-compact';
export type ExtendedTimeStyle = NonNullable<Intl.DateTimeFormatOptions['timeStyle']> | 'full-compact' | 'long-compact';
export type ExtendedDateTimeStyle = {
  dateStyle?: ExtendedDateStyle;
  timeStyle?: ExtendedTimeStyle;
  month?: ExtendedMonthStyle;
};
export type ExtendedMonthStyle = 'long' | 'short';

const extendedDateStyleMap = new Map<ExtendedDateStyle, Intl.DateTimeFormatOptions>([
  ['full', { dateStyle: 'full' }],
  ['long', { dateStyle: 'long' }],
  ['medium', { dateStyle: 'medium' }],
  [
    'full-compact',
    {
      day: 'numeric',
      month: 'short',
      weekday: 'short',
      year: 'numeric',
    },
  ],
  [
    'compact',
    {
      day: 'numeric',
      month: 'short',
      weekday: 'short',
    },
  ],
  [
    'medium-compact',
    {
      day: 'numeric',
      month: 'short',
    },
  ],
]);

const extendedTimeStyleMap = new Map<ExtendedTimeStyle, Intl.DateTimeFormatOptions>([
  ['full', { timeStyle: 'full' }],
  ['long', { timeStyle: 'long' }],
  ['medium', { timeStyle: 'medium' }],
  ['short', { timeStyle: 'short' }],
  [
    'full-compact',
    {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'long',
    },
  ],
  [
    'long-compact',
    {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    },
  ],
]);

const extendedMonthStyleMap = new Map<ExtendedMonthStyle, Intl.DateTimeFormatOptions>([
  ['long', { month: 'long' }],
  ['short', { month: 'short' }],
]);

const mapDateStyleToDateTimeFormatOptions = (dateStyle?: ExtendedDateStyle) => {
  if (!dateStyle) {
    return {};
  }
  return extendedDateStyleMap.get(dateStyle) ?? { dateStyle: 'medium' };
};

const mapTimeStyleToDateTimeFormatOptions = (timeStyle?: ExtendedTimeStyle) => {
  if (!timeStyle) {
    return {};
  }
  return extendedTimeStyleMap.get(timeStyle) ?? { timeStyle: 'medium' };
};

const mapMonthStyleToDateTimeFormatOptions = (monthStyle?: ExtendedMonthStyle) => {
  if (!monthStyle) {
    return {};
  }
  return extendedMonthStyleMap.get(monthStyle) ?? { month: 'short' };
};

export const getExtendedDateTimeFormatOptions = (extendedDateTimeStyle: ExtendedDateTimeStyle) => {
  const dateStyleValue = mapDateStyleToDateTimeFormatOptions(extendedDateTimeStyle.dateStyle);
  const timeStyleValue = mapTimeStyleToDateTimeFormatOptions(extendedDateTimeStyle.timeStyle);
  const monthStyleValue = mapMonthStyleToDateTimeFormatOptions(extendedDateTimeStyle.month);
  const extendedDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    ...dateStyleValue,
    ...timeStyleValue,
    ...monthStyleValue,
  };
  return extendedDateTimeFormatOptions;
};
