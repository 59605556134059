import appendSabreSimulatorRedirectHeader from '../../../utils/AppendSimulatorRedirectHeader';
import addRequestInterceptor from '../addRequestInterceptor';

function injectSabreSimulatorRedirectHeader() {
  return addRequestInterceptor({
    onFulfilled: async (config) => {
      const extraHeaders = await appendSabreSimulatorRedirectHeader();

      config.headers = { ...config.headers, ...extraHeaders };

      return config;
    },
  });
}

export default injectSabreSimulatorRedirectHeader;
