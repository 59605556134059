import type { IResponseData, IRequestData } from './apiTypes';

const cacheableUrls: string[] = [];
interface ICacheValue {
  result: IResponseData;
  timestamp: number;
}
export interface ICacheKey {
  url: string;
  requestBody?: IRequestData;
}

class ApiCache {
  private static cache = new Map<string, ICacheValue>(); // string, Value

  public static set = ({ url, requestBody }: ICacheKey, value: IResponseData): void => {
    if (cacheableUrls.includes(url)) {
      ApiCache.cache.set(JSON.stringify({ url, requestBody }), { result: value, timestamp: Date.now() });
    }
  };

  public static get = ({ url, requestBody }: ICacheKey): IResponseData | null => {
    if (cacheableUrls.includes(url)) {
      const stringifiedKey = JSON.stringify({ url, requestBody });
      if (ApiCache.cache.has(stringifiedKey)) {
        const timeStamp = ApiCache.cache.get(stringifiedKey)?.timestamp ?? 0;
        if (ApiCache.isTTLExpired(timeStamp)) {
          return null;
        }
      }
      return ApiCache.cache.get(stringifiedKey)?.result ?? null;
    }
    return null;
  };

  /**
   * @param timestamp
   * @param ttlMS Time to live in milliseconds.
   */
  private static isTTLExpired = (timestamp: number, ttlMS = 300000): boolean => Date.now() - timestamp > ttlMS;
}

export default ApiCache;
