import { css } from '@emotion/react';
import { themed } from '@spotnana/blocks/src/utils';

export const popover_container = themed(
  ({ palette }) => css`
    z-index: 2400;
    width: 100%;
    border: 0;
    box-shadow: ${palette.shadow.componentShadow};
    max-height: 500px;
  `,
);
