import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Basic = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M23.26 16.42C23.71 16.42 23.72 16.41 23.72 16.04V9.58001C23.72 9.27334 23.6033 9.01001 23.37 8.79001L20.97 6.50001C20.69 6.23001 20.4 6.10001 20.05 6.10001C19.7 6.10001 19.42 6.23001 19.13 6.50001L16.7 8.78001C16.4733 8.99334 16.36 9.26001 16.36 9.58001V16.02C16.36 16.4 16.36 16.39 16.82 16.39H23.26V16.42ZM20.04 8.45001C19.69 8.45001 19.4 8.16001 19.4 7.81001C19.4 7.46001 19.69 7.16001 20.04 7.16001C20.39 7.16001 20.69 7.45001 20.69 7.81001C20.69 8.17001 20.4 8.45001 20.04 8.45001ZM20.23 9.44001C20.3966 9.44001 20.48 9.52001 20.48 9.68001V9.93001C21.05 10 21.73 10.61 21.67 11.4H20.69C20.67 11.08 20.45 10.78 20.01 10.78C19.61 10.78 19.33 11.02 19.33 11.29C19.33 11.69 19.91 11.84 20.29 11.94C20.62 12.02 21.71 12.28 21.71 13.35C21.71 14.1 21.12 14.7 20.49 14.84V15.09C20.49 15.25 20.4066 15.33 20.24 15.33H19.75C19.5833 15.33 19.5 15.25 19.5 15.09V14.84C18.89 14.72 18.28 14.27 18.28 13.37H19.26C19.26 13.61 19.49 13.99 20 13.99C20.43 13.99 20.74 13.77 20.74 13.37C20.74 12.92 20.2 12.83 19.76 12.71C19.16 12.54 18.36 12.28 18.36 11.31C18.36 10.6 18.8 10.12 19.51 9.94001V9.69001C19.51 9.53001 19.5933 9.45001 19.76 9.45001H20.25L20.23 9.44001Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.44 17.93H10.67V20.34C10.67 20.7 10.38 21 10.01 21H7.1C6.74 21 6.44 20.71 6.44 20.34V17.93Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.95 13.92L5.04001 13.8L6.87 11.54L7.98 10.19C8.09 10.04 8.26001 9.94999 8.44001 9.92999H12.19C12.55 9.92999 12.85 10.22 12.85 10.59C12.85 10.93 12.58 11.22 12.24 11.25H8.83001L8.17 12.02L8.15001 12.04L6.2 14.36L5.92 14.7C5.81 14.84 5.66 14.92 5.49 14.93C5.31 14.93 5.16001 14.89 5.03001 14.77C4.77001 14.55 4.75001 14.17 4.96001 13.91L4.95 13.92Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.25 15.08V16.79C14.25 17.15 13.96 17.45 13.6 17.45H6.43C4.44 17.45 2.35 16.88 2.21 13.56L1.03 9.65C1.01 9.58 1 9.52 1 9.45V5.16C1 3.96 1.05 3 2.24 3C3.43 3 3.43 3.93 3.43 5.13V9.35L4.83 13.27L4.56 13.61C4.18 14.08 4.24 14.75 4.69 15.15C4.89 15.33 5.14 15.41 5.4 15.42H5.52C5.82 15.39 6.09 15.25 6.29 15.02L6.84 14.36H13.62C13.97 14.36 14.27 14.7 14.27 15.07L14.25 15.08Z", fill: themeEnrichedPrimaryColor })));
};
Basic.defaultProps = defaultIconProps;
export default Basic;
