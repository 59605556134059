import useMountEffect from '../../hooks/useMountEffect';
import { useScreenInitialDimensionsTelemetry } from '../hooks/useScreenDimensionsTelemetry';

export const ScreenDimensionsTelemetry = (): JSX.Element | null => {
  const trackScreenInitialDimensions = useScreenInitialDimensionsTelemetry();
  useMountEffect(() => {
    trackScreenInitialDimensions({ height: window.innerHeight, width: window.innerWidth });
  });
  return null;
};
