import type { CustomResource, CustomResourceLoader } from '../namespaces';
import { getLanguageCodeDetails } from '../utils/language';
import { getResourceForLanguage as getResourceForLanguageBcd } from './bcd';
import { getResourceForLanguage as getResourceForLanguageCwt } from './cwt';
import { getResourceForLanguage as getResourceForLanguageWalmart } from './walmart';
import { getResourceForLanguage as getResourceForLanguageQantas } from './qantas';

export enum I18N_CUSTOM_RESOURCE_KEY {
  CWT = 'cwt',
  WALMART = 'walmart',
  BCD = 'bcd',
  QANTAS = 'qantas',
}

const RESOURCE_KEY_TO_RESOURCE_LOADER_MAP: Record<I18N_CUSTOM_RESOURCE_KEY, CustomResourceLoader> = {
  [I18N_CUSTOM_RESOURCE_KEY.BCD]: getResourceForLanguageBcd,
  [I18N_CUSTOM_RESOURCE_KEY.CWT]: getResourceForLanguageCwt,
  [I18N_CUSTOM_RESOURCE_KEY.WALMART]: getResourceForLanguageWalmart,
  [I18N_CUSTOM_RESOURCE_KEY.QANTAS]: getResourceForLanguageQantas,
};

/**
 * Returns Custom Language Resources for specific keys
 * @param resourceKey
 * @param langCode
 * @returns
 */
export async function getCustomI18nResource(
  resourceKey: I18N_CUSTOM_RESOURCE_KEY,
  langCode: string,
): Promise<CustomResource> {
  const loader = RESOURCE_KEY_TO_RESOURCE_LOADER_MAP[resourceKey];
  let customResource = {} as CustomResource;

  if (loader) {
    const languageDetails = getLanguageCodeDetails(langCode);

    // lang codes in order from more specific to generic
    const codesToCheck = [
      languageDetails.languageCode, // lang + region
      languageDetails.baseLanguageCode, // lang only
      'en', // default english
    ];

    // try to check if any custom resource is available for the lang code
    for (const languageCode of codesToCheck) {
      // eslint-disable-next-line no-await-in-loop
      customResource = await loader(languageCode);
      if (Object.keys(customResource).length > 0) {
        break;
      }
    }
  }

  return customResource;
}
