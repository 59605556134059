import { endPoints } from '../endpoints';
import addResponseInterceptor from '../interceptors/addResponseInterceptor';

import { isUnauthorized } from './onUnauthorized';

/**
 * To be used as a flag for force logging out user with relevant error message incase any API call returns an auth error.
 * `entry` and `midflow` determine which error message to be used
 * */
export type DebarUserEventType = 'entry' | 'midFlow' | false;

function onDebarUser(listener: (event: DebarUserEventType) => void): ReturnType<typeof addResponseInterceptor> {
  return addResponseInterceptor({
    onFulfilled: (response) => {
      listener(false);
      return response;
    },
    onRejected: (error) => {
      if (isUnauthorized(error)) {
        listener(error.config.url === endPoints.getLoggedInUserBasicInfo ? 'entry' : 'midFlow');
      } else {
        listener(false);
      }

      return error;
    },
  });
}

export default onDebarUser;
