import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Bed = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M2.909 5v13.2h11.638v-.005H32V27h-2.909v-4.4H2.909V27H0V5zm5.819 2.933a4.4 4.4 0 11-4.365 4.4 4.384 4.384 0 014.365-4.4zm17.453 0a5.839 5.839 0 015.811 5.546L32 13.8v2.555H14.545V7.933h11.638z", fill: themeEnrichedPrimaryColor })));
};
Bed.defaultProps = defaultIconProps;
export default Bed;
