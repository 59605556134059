import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const BlockedAdjacentSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M28.78,18.4a.89.89,0,0,0-.88-.78H19.8l1.59-2.09H26A.89.89,0,0,0,26,13.74H20.88a.87.87,0,0,0-.63.34L18.1,16.91l-.47-1.38L17,13.74l-.4-1.19V7.08a2.92,2.92,0,0,0-5.84.17v5.9l0,.17.74,2.45-.86,1.14L9.22,12.55V7.08a2.92,2.92,0,0,0-5.84.17v5.9l0,.17L5,18.65v.11a5.53,5.53,0,0,0,4.39,5.18v2.59a.89.89,0,0,0,.88.78H25.23a.9.9,0,0,0,.78-.89V24.06h2a.89.89,0,0,0,.78-.88V18.4ZM12.56,7.25V7.12a1.15,1.15,0,0,1,2.29.13v5.63l0,.11.25.75.61,1.79.68,2-.09.08H14l-.45-1.49L12.89,14l-.33-1.1ZM5.15,12.94V7.12a1.15,1.15,0,0,1,2.29.13v5.52l0,.11,0,.11L9,17.54l-.1.08H6.56Zm5.27,9.33h-.2a3.74,3.74,0,0,1-3.3-2.76l0-.09H8.38a1.88,1.88,0,0,0,3.68,0h.44a5.46,5.46,0,0,0,1.39,2.87H10.42Zm.77,1.79h5.63v1.47H11.19Zm13,1.47H18.6V24.06h5.63ZM27,22.28H17.63a3.74,3.74,0,0,1-3.3-2.76l0-.09h1.48a1.88,1.88,0,0,0,3.68,0H27Z" }),
        React.createElement("line", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1.72px", x1: "22.11", y1: "5.26", x2: "25.61", y2: "8.67" }),
        React.createElement("line", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1.72px", x1: "22.15", y1: "8.71", x2: "25.56", y2: "5.21" })));
};
BlockedAdjacentSeat.defaultProps = defaultIconProps;
export default BlockedAdjacentSeat;
