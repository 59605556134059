import type { FareAmount, RateOption } from '../../../types/api/v1/obt/air/air_search_response';
import { MoneyUtil } from '../../Money';

export function getCheapestPublishedBaseFareForFareAmounts(
  totalFare: FareAmount,
  cheapestPublishedFare?: FareAmount,
): MoneyUtil {
  const { base } = totalFare;
  const cheapestPublishedBaseFare = cheapestPublishedFare?.base;

  if (!cheapestPublishedBaseFare) {
    return MoneyUtil.zeroMoneyWithOriginal(base.convertedCurrency || 'USD', base.currencyCode);
  }

  return MoneyUtil.parse(cheapestPublishedBaseFare);
}

export function getCheapestPublishedTaxFareForFareAmounts(
  totalFare: FareAmount,
  cheapestPublishedFare?: FareAmount,
): MoneyUtil {
  const { tax } = totalFare;
  const cheapestPublishedTaxFare = cheapestPublishedFare?.tax;

  if (!cheapestPublishedTaxFare) {
    return MoneyUtil.zeroMoneyWithOriginal(tax.convertedCurrency || 'USD', tax.currencyCode);
  }
  return MoneyUtil.parse(cheapestPublishedTaxFare);
}

export function getTotalCheapestPublishedFareForRateOption(rateOption: RateOption): MoneyUtil {
  const { totalFare, cheapestPublishedFare } = rateOption;
  return getCheapestPublishedBaseFareForFareAmounts(totalFare, cheapestPublishedFare).add(
    getCheapestPublishedTaxFareForFareAmounts(totalFare, cheapestPublishedFare),
  );
}
