/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of Payment Source
 * @export
 * @enum {string}
 */

export const PaymentSourceType = {
  Card: 'CARD',
  VirtualCard: 'VIRTUAL_CARD',
  RewardsProgram: 'REWARDS_PROGRAM',
  DelayedInvoicing: 'DELAYED_INVOICING',
  CustomPaymentMethod: 'CUSTOM_PAYMENT_METHOD',
  // TODO: (remove brex budget).this is no longer supported by backend
  BrexBudget: 'BREX_BUDGET',
  VendorProgramPayment: 'VENDOR_PROGRAM_PAYMENT',
} as const;

export type PaymentSourceType = (typeof PaymentSourceType)[keyof typeof PaymentSourceType];
