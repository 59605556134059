/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { PhoneNumber } from './phone-number';

/**
 * Emergency contact information.
 * @export
 * @interface EmergencyContact
 */
export interface EmergencyContact {
  /**
   * Full name of contact.
   * @type {string}
   * @memberof EmergencyContact
   */
  name: string;
  /**
   * Email address of contact.
   * @type {string}
   * @memberof EmergencyContact
   */
  email?: string;
  /**
   * Job title of contact.
   * @type {string}
   * @memberof EmergencyContact
   * @deprecated
   */
  designation?: string;
  /**
   * Relation of contact to user.
   * @type {string}
   * @memberof EmergencyContact
   */
  relation?: EmergencyContactRelationEnum;
  /**
   * Phone numbers of contact.
   * @type {Array<PhoneNumber>}
   * @memberof EmergencyContact
   */
  phoneNumbers: Array<PhoneNumber>;
  /**
   * Language preferred by user.
   * @type {string}
   * @memberof EmergencyContact
   */
  preferredLanguage?: string;
}

export const EmergencyContactRelationEnum = {
  RelationUnknown: 'RELATION_UNKNOWN',
  Spouse: 'SPOUSE',
  Parent: 'PARENT',
  Sibling: 'SIBLING',
  Child: 'CHILD',
  Friend: 'FRIEND',
  Relative: 'RELATIVE',
  Colleague: 'COLLEAGUE',
  Other: 'OTHER',
} as const;

export type EmergencyContactRelationEnum =
  (typeof EmergencyContactRelationEnum)[keyof typeof EmergencyContactRelationEnum];
