var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultIconProps } from './common';
const SeatV2 = (_a) => {
    var { primaryColor, secondaryColor } = _a, otherProps = __rest(_a, ["primaryColor", "secondaryColor"]);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, otherProps),
        React.createElement("rect", { x: "0.5", y: "0.5", width: "19", height: "19", rx: "3.5", fill: secondaryColor, stroke: secondaryColor }),
        React.createElement("path", { d: "M1 4C1 2.34315 2.34315 1 4 1H16C17.1046 1 18 1.89543 18 3V15C18 16.6569 16.6569 18 15 18H3C1.89543 18 1 17.1046 1 16V4Z", fill: primaryColor })));
};
SeatV2.defaultProps = defaultIconProps;
export default SeatV2;
