import dateUtil from '../dateUtil';

type ReturnFormat = Parameters<ReturnType<typeof dateUtil>['diff']>[1];

const getDiff = (
  earlier: string,
  later: string,
  returnFormat: ReturnFormat = 'years',
  earlierDateFormat = 'YYYY-MM-DD',
  laterDateFormat = 'YYYY-MM-DD',
  float = false,
): number => {
  // Convert date time to date.
  const start = dateUtil(earlier, earlierDateFormat);
  const end = dateUtil(later, laterDateFormat);
  return end.diff(start, returnFormat, float);
};

export default getDiff;
