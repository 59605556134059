import { useRef, useLayoutEffect } from 'react';
import onUnauthorized from '../../api/events/onUnauthorized';

function useOnApiLogout(logout: () => void): void {
  const apiRef = useRef({ logout });

  apiRef.current.logout = logout;

  useLayoutEffect(() => {
    const unsubscribe = onUnauthorized(() => apiRef.current.logout());

    return () => {
      unsubscribe();
    };
  }, []);
}

export default useOnApiLogout;
