import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const DropOff = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 16", fill: themeEnrichedPrimaryColor, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.52466 15.1229C2.13144 15.4613 1.5376 15.4438 1.16521 15.0706C0.775091 14.6797 0.775742 14.0465 1.16667 13.6564L12.1971 2.64865L5.77417 2.64865L5.68789 2.64498C5.176 2.60123 4.77417 2.17187 4.77417 1.64865C4.77417 1.09636 5.22189 0.64865 5.77417 0.64865H14.6123L14.6986 0.652321C14.8882 0.668525 15.0627 0.737636 15.2073 0.844829C15.2472 0.874336 15.2854 0.907191 15.3215 0.943394C15.5298 1.15208 15.6267 1.4298 15.6123 1.70272L15.6127 10.4871L15.609 10.5734C15.5653 11.0853 15.1359 11.4871 14.6127 11.4871L14.5264 11.4835C14.0145 11.4397 13.6127 11.0104 13.6127 10.4872L13.6121 4.06203L2.57943 15.0721L2.52466 15.1229Z", fill: themeEnrichedPrimaryColor })));
};
DropOff.defaultProps = defaultIconProps;
export default DropOff;
