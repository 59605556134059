import { rem } from 'polished';
const devices = {
    mobilePx: 420,
    tabletPx: 768,
    laptopPx: 1024,
    desktopPx: 1440,
    get tabletRem() {
        return parseFloat(rem(this.tabletPx));
    },
    get laptopRem() {
        return parseFloat(rem(this.laptopPx));
    },
    get desktopRem() {
        return parseFloat(rem(this.desktopPx));
    },
};
export default devices;
