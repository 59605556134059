import { IconButton } from '@spotnana/blocks/src/IconButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { container } from './index.styles';

// AgentAppHeader component represents the header with toggle buttons for right panels
export const AgentAppHeader: React.FC = () => {
  const { t: tt } = useTranslation('WEB');
  // React Router hooks for location and history
  const location = useLocation();
  const history = useHistory();

  // Retrieve the 'selectedRightPanel' query parameter from the URL
  const currentParams = new URLSearchParams(location.search);
  const visibleMenuItem = currentParams.has('selectedRightPanel') ? currentParams.get('selectedRightPanel') : null;

  // Handle toggle of the right panel based on the selected menu item
  const handleToggleRightPanel = (menuItem: string) => {
    // Check if the clicked menu item is already visible, then remove it; otherwise, set it
    if (visibleMenuItem !== menuItem) {
      currentParams.set('selectedRightPanel', menuItem);
    } else {
      currentParams.delete('selectedRightPanel');
    }

    // Update the URL with the modified query parameters
    history.push({
      pathname: location.pathname,
      search: `?${currentParams.toString()}`,
    });
  };

  // Render the AgentAppHeader with toggle buttons for Announcement and NotesLinks panels
  return (
    <div css={container}>
      {/* IconButton for toggling the Announcement panel */}
      <IconButton
        className={visibleMenuItem === 'announcement' ? 'active' : ''}
        icon={visibleMenuItem === 'announcement' ? 'AnnouncementFilled' : 'Announcement'}
        size="large"
        aria-label={tt('Announcements')}
        onClick={handleToggleRightPanel.bind(null, 'announcement')}
      />
      {/* IconButton for toggling the NotesLinks panel */}
      <IconButton
        className={visibleMenuItem === 'notesLinks' ? 'active' : ''}
        icon={visibleMenuItem === 'notesLinks' ? 'DocSummaryFilled' : 'DocSummary'}
        size="large"
        aria-label={tt('Notes & Links')}
        onClick={handleToggleRightPanel.bind(null, 'notesLinks')}
      />
    </div>
  );
};
