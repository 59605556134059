import { css } from '@emotion/react';
import { themed } from '@spotnana/blocks/src/utils';

export const modal_line_item = css`
  display: flex;
  padding: 4px 0;
  align-items: center;
`;

export const modal_item_key = css`
  font-weight: bold !important;
  flex-shrink: 0;
`;

export const modal_footer = css`
  display: flex;
  gap: 12px;
`;

export const copy_to_clipboard_button = css`
  flex: 1;
  padding: 12px;
  font-size: 16px;
`;

export const bullet_point = themed(
  ({ palette }) => css`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${palette.text.primary};
    margin-right: 8px;
    flex-shrink: 0;
  `,
);
