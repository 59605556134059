import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const HotelDetail = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 24 24", "data-testid": "hotel-detail-icon" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M17.985 3.938c.414 0 .75.335.75.75 0 .347-.236.639-.556.724l-.002 13.257-.007.098.714.001c.207 0 .375.224.375.5 0 .257-.145.468-.332.497l-.043.003H5.126c-.207 0-.375-.224-.375-.5 0-.256.145-.468.332-.497l.043-.003h.576l-.004-.043-.002-.056V5.409c-.315-.089-.546-.378-.546-.721 0-.396.306-.72.694-.748l.056-.002h12.085zm-1.308 1.5H7.196v13.231l-.008.098h1.768V16.78c0-.181.13-.332.3-.367l.076-.008h5.385c.18 0 .332.129.367.3l.008.075v1.987l1.592.001-.005-.043-.002-.056V5.437zm-2.335 11.717H9.707v1.603h4.634v-1.603zm-3.945-3.278v1.417H8.981v-1.417h1.416zm2.358 0v1.417h-1.417v-1.417h1.417zm2.358 0v1.417h-1.417v-1.417h1.417zm-4.716-2.06v1.417H8.981v-1.417h1.416zm2.358 0v1.417h-1.417v-1.417h1.417zm2.358 0v1.417h-1.417v-1.417h1.417zm-4.716-2.06v1.417H8.981V9.758h1.416zm2.358 0v1.417h-1.417V9.758h1.417zm2.358 0v1.417h-1.417V9.758h1.417zm-2.844-2.99l.044.069.132.268.294.043c.265.04.393.327.279.544l-.042.063-.029.032-.214.21.05.296c.046.269-.195.48-.44.432l-.066-.02-.038-.017-.263-.139-.262.139c-.24.127-.518-.036-.548-.284l-.002-.07.005-.04.05-.297-.213-.21c-.192-.188-.126-.496.095-.604l.07-.026.043-.009.293-.043.133-.268c.124-.254.465-.277.63-.07zm-2.431 0l.043.069.132.268.294.043c.266.04.394.327.28.544l-.042.063-.03.032-.214.21.051.296c.046.269-.195.48-.44.432l-.067-.02-.038-.017-.263-.139-.262.139c-.24.127-.517-.036-.548-.284l-.002-.07.005-.04.05-.297-.213-.21c-.192-.188-.125-.496.095-.604l.07-.026.043-.009.294-.043.132-.268c.125-.254.466-.277.63-.07zm4.863 0l.043.069.132.268.294.043c.266.04.394.327.28.544l-.042.063-.03.032-.214.21.051.296c.046.269-.195.48-.44.432l-.067-.02-.038-.017-.262-.139-.263.139c-.24.127-.517-.036-.548-.284l-.001-.07.004-.04.05-.297-.213-.21c-.192-.188-.125-.496.095-.604l.07-.026.043-.009.294-.043.132-.268c.125-.254.466-.277.63-.07z" })));
};
HotelDetail.defaultProps = defaultIconProps;
export default HotelDetail;
