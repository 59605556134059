import type { QueryFunction, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMutation, useQueries, useQuery } from 'react-query';

import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type { AutocompleteServiceResultItemsLimitType } from '../services/AutocompleteService';
import AutocompleteService from '../services/AutocompleteService';
import type {
  IAutocompleteSuggestion,
  IAutocompleteSuggestionCustomizer,
  IAutocompleteType,
  ISuggestion,
} from '../types/autocomplete';
import type { Latlng } from '../types/api/v1/common/latlng';
import type { AutocompleteEntitySearchRequest } from '../types/api/v2/obt/model/autocomplete-entity-search-request';
import { AutocompleteEntitySearchRequestEntitiesEnum } from '../types/api/v2/obt/model/autocomplete-entity-search-request';
import type { AutocompleteResponse } from '../types/api/v2/obt/model/autocomplete-response';
import type { SpotnanaQueryResult } from '../types/common';
import type { CompanyBasicInfo } from '../types/api/v2/obt/model/company-basic-info';
import type { AutocompleteFilter } from '../types/api/v2/obt/model/autocomplete-filter';
import type {
  AutocompleteHotel,
  PaginationRequestParams,
  PreferredVendorAutocompleteSearchRequest,
  RailThirdParty,
} from '../types';
import { AUTOCOMPLETE_QUERY_MIN_LENGTH_TO_ACTIVATE } from '../constants/autocomplete';
import type { AutocompleteRequest } from '../types/api/v1/obt/autocomplete/request';

const getAutocompleteKey = (
  query: string,
  type: string,
  numItems: AutocompleteServiceResultItemsLimitType,
  isOutsideBooking: boolean,
  organizationId?: string,
): unknown[] => [`autocomplete-${type}-query-${numItems}`, query, isOutsideBooking ? 'outside' : 'obt', organizationId];

const getPreferredHotelsAutocompleteKey = (query: string): unknown[] => ['autocomplete-preferred-hotels-query', query];

const getPreferredVendorAutocompleteKey = (req: PreferredVendorAutocompleteSearchRequest): unknown[] => [
  'autocomplete-preferred-vendors-query',
  req,
];

export const fetchAutocomplete = async (
  query: string,
  type: IAutocompleteType,
  numItems: AutocompleteServiceResultItemsLimitType,
  isOutsideBooking = false,
  /**
   * When time permits, will change the first argument from `query: string`
   * to autocompleteRequest: AutocompleteRequest`
   */
  allowMultiAirportSelection = true,
  organizationId?: string,
  thirdParty?: RailThirdParty,
  organizationLogo?: string,
  temporaryRequestArgs?: Partial<AutocompleteRequest>,
  suggestionCustomizer?: IAutocompleteSuggestionCustomizer,
): Promise<IAutocompleteSuggestion[]> => {
  const autocompleteService = new AutocompleteService(
    type,
    isOutsideBooking,
    allowMultiAirportSelection,
    organizationId,
    thirdParty,
    suggestionCustomizer,
  );
  const result = await autocompleteService.fetchItems(query, numItems, organizationLogo, temporaryRequestArgs);
  if (result) {
    return result;
  }
  throw new SpotnanaError('Cannot get Autocomplete data');
};

export const useAutocompleteQuery = (
  query: string,
  type: IAutocompleteType,
  numItems: AutocompleteServiceResultItemsLimitType,
  isOutsideBooking = false,
  allowMultiAirportSelection = false,
  organizationId?: string,
  thirdParty?: RailThirdParty,
  organizationLogo?: string,
  suggestionCustomizer?: IAutocompleteSuggestionCustomizer,
) =>
  useQuery<IAutocompleteSuggestion[], SpotnanaError>(
    getAutocompleteKey(query, type, numItems, isOutsideBooking, organizationId),
    () =>
      fetchAutocomplete(
        query,
        type,
        numItems,
        isOutsideBooking,
        allowMultiAirportSelection,
        organizationId,
        thirdParty,
        organizationLogo,
        undefined,
        suggestionCustomizer,
      ),
    {
      enabled: query.length >= AUTOCOMPLETE_QUERY_MIN_LENGTH_TO_ACTIVATE,
    },
  );
export function useAutocompleteMutation() {
  return useMutation<
    IAutocompleteSuggestion[],
    SpotnanaError,
    {
      query: string;
      type: IAutocompleteType;
      numItems: AutocompleteServiceResultItemsLimitType;
      isOutsideBooking: boolean;
      allowMultiAirportSelection: boolean;
      organizationId?: string;
      thirdParty?: RailThirdParty;
      organizationLogo?: string;
    }
  >(
    ({
      query,
      type,
      numItems,
      isOutsideBooking = false,
      allowMultiAirportSelection = false,
      organizationId,
      thirdParty,
      organizationLogo,
    }) =>
      fetchAutocomplete(
        query,
        type,
        numItems,
        isOutsideBooking,
        allowMultiAirportSelection,
        organizationId,
        thirdParty,
        organizationLogo,
      ),
  );
}

interface MultipleAutocompleteQueryProps {
  queries: string[];
  type: IAutocompleteType;
  numItems: AutocompleteServiceResultItemsLimitType;
  isOutsideBooking: boolean;
  suggestionCustomizer?: IAutocompleteSuggestionCustomizer;
}

export const useMultipleAutocompleteQuery = (
  { queries, type, numItems, isOutsideBooking, suggestionCustomizer }: MultipleAutocompleteQueryProps,
  options?: UseQueryOptions<IAutocompleteSuggestion[], SpotnanaError>,
): SpotnanaQueryResult<IAutocompleteSuggestion[]>[] =>
  useQueries(
    queries.map((query) => ({
      queryKey: getAutocompleteKey(query, type, numItems, isOutsideBooking),
      queryFn: () =>
        fetchAutocomplete(
          query,
          type,
          numItems,
          isOutsideBooking,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          suggestionCustomizer,
        ),
      enabled: options?.enabled !== undefined ? options.enabled && !!query : !!query,
    })),
  ) as SpotnanaQueryResult<IAutocompleteSuggestion[]>[];

const fetchPreferredHotelsAutocomplete = async (query: string): Promise<AutocompleteHotel[]> => {
  const data = (await api('GET', 'hotelSupplierAutocomplete', { params: { query } })) as AutocompleteResponse;

  if (data) {
    return data.hotels;
  }
  throw new SpotnanaError('Cannot get Autocomplete data');
};

export const usePreferredHotelsAutocompleteQuery = (
  query: string,
): UseQueryResult<AutocompleteHotel[], SpotnanaError> =>
  useQuery<AutocompleteHotel[], SpotnanaError>(
    getPreferredHotelsAutocompleteKey(query),
    () => fetchPreferredHotelsAutocomplete(query),
    {
      enabled: query.length >= AUTOCOMPLETE_QUERY_MIN_LENGTH_TO_ACTIVATE,
    },
  );

const fetchPreferredVendorsAutocomplete = async (
  req: PreferredVendorAutocompleteSearchRequest,
): Promise<AutocompleteResponse> => {
  try {
    const data = (await api('POST', 'preferredVendorAutocompleteSearch', { data: req })) as AutocompleteResponse;
    return data;
  } catch {
    throw new SpotnanaError('Cannot get Autocomplete data');
  }
};

export const usePreferredVendorsAutocompleteQuery = (
  req: PreferredVendorAutocompleteSearchRequest,
  enabled = true,
): UseQueryResult<AutocompleteResponse, SpotnanaError> =>
  useQuery<AutocompleteResponse, SpotnanaError>(
    getPreferredVendorAutocompleteKey(req),
    () => fetchPreferredVendorsAutocomplete(req),
    {
      enabled,
      cacheTime: 0,
    },
  );

interface FetchAutocompleteLocationResult {
  coordinates?: Latlng | undefined;
  countryCode?: string | undefined;
}

const fetchAutocompleteLocation = async (location: ISuggestion | null): Promise<FetchAutocompleteLocationResult> => {
  const result = await AutocompleteService.fetchGooglePlaces(location);
  if (result?.latlong) {
    return {
      coordinates: result.latlong,
      countryCode: result.address?.regionCode,
    };
  }
  throw new SpotnanaError('Cannot get Autocomplete location');
};

/** @deprecated migrate to {@link useFetchAutocompleteGooglePlacesMutation} instead */
export const useFetchAutocompleteLocationQuery = () =>
  useMutation((location: ISuggestion | null) => fetchAutocompleteLocation(location));

const fetchAutocompleteGooglePlacesData = async (location: ISuggestion | null) => {
  return AutocompleteService.fetchGooglePlaces(location);
};

export const FETCH_AUTOCOMPLETE_GOOGLE_PLACES_MUTATION_KEY = 'fetch-google-places';

export const useFetchAutocompleteGooglePlacesMutation = () =>
  useMutation((location: ISuggestion | null) => fetchAutocompleteGooglePlacesData(location), {
    mutationKey: [FETCH_AUTOCOMPLETE_GOOGLE_PLACES_MUTATION_KEY],
  });

const getEntitySearchAutocompleteKey = ({
  searchText,
  entities,
  filters,
  paginationParams,
}: AutocompleteEntitySearchRequest) =>
  [{ type: 'entity-search-autocomplete', searchText, entities, filters, paginationParams }] as const;

const entitySearchAutocomplete: QueryFunction<
  AutocompleteResponse,
  ReturnType<typeof getEntitySearchAutocompleteKey>
> = async ({ queryKey: [{ searchText, entities, filters, paginationParams }] }) => {
  if (searchText !== '') {
    const data = await api('POST', 'entityAutocomplete', { data: { searchText, entities, filters, paginationParams } });
    return data as AutocompleteResponse;
  }
  return { travelerBasicInfos: [] } as unknown as AutocompleteResponse;
};

export function useEntitySearchAutocompleteQuery(
  searchText: string,
  entities: Array<AutocompleteEntitySearchRequestEntitiesEnum>,
  filters?: AutocompleteFilter[],
  paginationParams?: PaginationRequestParams,
): SpotnanaQueryResult<AutocompleteResponse> {
  return useQuery(
    getEntitySearchAutocompleteKey({ searchText, entities, filters, paginationParams }),
    entitySearchAutocomplete,
    {
      enabled: !!searchText,
      cacheTime: 0,
    },
  );
}

/**
 * Custom hook to retrieve autocomplete results for traveler entity searches.
 * @param {string} searchText - The search text used to filter the results.
 * @param {AutocompleteFilter[]} filters - Optional filters to apply to the search.
 */
export function useTravelerEntitySearchAutocompleteQuery(searchText: string, filters?: AutocompleteFilter[]) {
  const result = useEntitySearchAutocompleteQuery(
    searchText,
    [AutocompleteEntitySearchRequestEntitiesEnum.Traveler],
    filters,
  );

  return {
    ...result,
    data: result?.data?.travelerBasicInfos ?? [],
  };
}

const organizationEntitySearchAutocomplete: QueryFunction<
  CompanyBasicInfo[],
  ReturnType<typeof getEntitySearchAutocompleteKey>
> = async (params) => {
  const result = await entitySearchAutocomplete(params);
  return result.companyBasicInfos ?? [];
};

export function useOrganizationEntitySearchAutocompleteQuery(
  searchText: string,
  paginationParams?: PaginationRequestParams,
  filters?: AutocompleteFilter[],
): SpotnanaQueryResult<CompanyBasicInfo[]> {
  return useQuery(
    getEntitySearchAutocompleteKey({
      searchText,
      paginationParams,
      filters,
      entities: [AutocompleteEntitySearchRequestEntitiesEnum.Organization],
    }),
    organizationEntitySearchAutocomplete,
    { enabled: !!searchText, cacheTime: 0 },
  );
}

export function useOrganizationEntitySearchAutocompleteMutation() {
  return useMutation(async ({ searchText }: { searchText: string }) => {
    const data = await api('POST', 'entityAutocomplete', {
      data: {
        searchText,
        entities: [AutocompleteEntitySearchRequestEntitiesEnum.Organization],
      },
    });
    return data as AutocompleteResponse;
  });
}
