export enum WeightUnit {
  KG = 1,
  LBS = 2,
}

export interface Weight {
  /** Unit of weight. */
  unit: WeightUnit;
  /** Amount of weight. */
  weight: number;
}
