import { EntityInfo, isOrganizationBasicInfo, isTravelerBasicInfo, isTripBasicInfo } from '../../../app/agent/types';
import EntityAutocompleteTripOption from './EntityAutocompleteTripOption';
import EntityAutocompleteCompanyOption from './EntityAutocompleteCompanyOption';
import EntityAutocompleteProfileOption from './EntityAutocompleteProfileOption';

export interface EntityAutocompleteOptionProps<T = EntityInfo> {
  option: T;
  isHighlighted: boolean;
  isCompanyTripsPage?: boolean;
  isTravelerAutocomplete?: boolean;
  onClick?: VoidFunction;
}

const EntityAutocompleteOption = ({
  option,
  isHighlighted,
  isCompanyTripsPage,
  onClick,
  isTravelerAutocomplete,
}: EntityAutocompleteOptionProps): JSX.Element => {
  if (isTravelerBasicInfo(option)) {
    return (
      <EntityAutocompleteProfileOption
        option={option}
        isHighlighted={isHighlighted}
        isCompanyTripsPage={isCompanyTripsPage}
        onClick={onClick}
        isTravelerAutocomplete={isTravelerAutocomplete}
      />
    );
  }
  if (isOrganizationBasicInfo(option)) {
    return <EntityAutocompleteCompanyOption option={option} isHighlighted={isHighlighted} />;
  }
  if (isTripBasicInfo(option)) {
    return (
      <EntityAutocompleteTripOption
        option={option}
        isHighlighted={isHighlighted}
        isCompanyTripsPage={isCompanyTripsPage}
      />
    );
  }
  return <></>;
};

export default EntityAutocompleteOption;
