import { useMemo, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@spotnana/pixel-react/dist/Box';
import Card from '@spotnana/pixel-react/dist/Card';
import ClickAwayListener from '@spotnana/pixel-react/dist/ClickAwayListener';
import Divider from '@spotnana/pixel-react/dist/Divider';
import Grid from '@spotnana/pixel-react/dist/Grid';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import { Ellipsis } from '@spotnana/blocks/src/Ellipsis';
import { Typography } from '@spotnana/blocks/src/Typography';
import css from '@styled-system/css';
import intersection from 'lodash/intersection';
import upperCase from 'lodash/upperCase';
import {
  IRoleInfoType,
  RBAC,
  TMCAgentAndAboveRoles,
  TripCategoryPath,
  EventsCategoryPath,
  getBrexPointsInfo,
  useAgentStatusAvailabilityQuery,
  useCompanyRedirectionQuery,
  useHasUserAccess,
  useLoggedInUser,
  useLoggedInUserBasicInfo,
  useLoggedInUserId,
  usePointsBalance,
  userRolesByFeature,
  useFeatureFlag,
  Config,
  useJSONFeatureFlag,
  getNameStringFromName,
  useGetMarriottBonvoyAccountInfo,
  useGetUserExpensePartnersDetails,
  usePartnerFeatures,
  TripCategoryRequest,
  useTelemetry,
  TelemetryEvents,
  createInitialsFromFullName,
  createUserNameFromFullName,
} from 'obt-common';
import { useUserPersona } from 'obt-common/hooks/user/useUserPersona';
import { TierEnum } from 'obt-common/types/api/v1/obt/common/user_org_id';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, useLocation } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';
import { IconButton } from '@spotnana/blocks/src/IconButton';

import { useAppBranding } from 'src/components/AppBrandingProvider/AppBrandingProvider';
import { useEmbedStore } from 'src/components/EmbedContext';

import { LocationDescriptor, LocationState } from 'history';
import { flex } from '@spotnana/blocks/src/utils';
import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';
import { Layout } from '@spotnana/blocks/src/Layout';
import { Avatar } from '@spotnana/blocks/src/Avatar';
import { Popover } from '@spotnana/blocks/src/Popover';
import { HideOnRouteMatch } from 'src/v2-components/HideOnRouteMatch';
import { AgentAppHeader } from 'src/app/agent/AgentAppHeader';
import { useDisableBooking } from 'src/app/shared/hooks/useDisableBooking';
import { Icon } from '@spotnana/blocks/src/Icon';
import { IncompleteUserActionName } from 'obt-common/types/userActions';
import { stringifyParams } from 'obt-common/utils/urlUtils';
import usePartnerFlags from '../../hooks/usePartnerFlags';
import { ROUTE_PATHS } from '../../routes';
import AgentStatusMenu from '../../app/agent/QueueDashboardPage/AgentStatusMenu';
import SpotnanaLogoShort from '../../v2-components/shared/SpotnanaLogoShort';
import UserAvatar from '../../v2-components/shared/UserAvatar';
import IntegrationsMenu from '../IntegrationsMenu';
import DebugToolLink from '../rings/DebugToolLink';
import HeaderRingMenuOption from '../rings/HeaderRingMenuOption';
import InternalMenu from '../rings/InternalMenu';
import RingChangeModal from '../rings/RingChangeModal';
import AnalyticsTab from '../tab/AnalyticsTab';
import { ExpenseTab } from '../tab/ExpenseTab';
import { ProgramTab } from '../tab/ProgramTab';
import TripsTab from '../tab/TripsTab';
import DebugTool from '../rings/DebugTool';
import { SwitchThemeMode } from '../SwitchThemeMode';
import { ApiClient } from '../rings/ApiClient';
import { focus_outline, header, header_nav_element, header_popover, signout_icon } from './AppHeader.styles';
import { HeaderSkipLinkButton } from './HeaderSkipLinkButton';
import HeaderAvatarButton from './HeaderAvatarButton';
import { HeaderSearchButton } from './HeaderSearchButton';
import { useIsEventsFeatureEnabled } from './useIsEventsFeatureEnabled';
import { generateExpensePartnersLinks } from './utils';
import { hasIncompleteUserAction } from '../../app/user/shared/utils';
import { HeaderSavedFareListButton } from './HeaderSavedFareListButton';
import { HeaderBanner } from './HeaderBanner/HeaderBanner';

const DrawerContainer = styled.div`
  ${spotnanaTheme.mediaQueries.belowTablet} {
    width: 100vw;
  }

  width: 320px;
`;

const Link = styled(NavLink)`
  color: ${(props): string => props.theme.colors.text.body};

  &.active {
    color: ${(props): string => props.theme.colors.text.semiBold};
    font-weight: ${(props): string => props.theme.fontWeights.semiBold.toString()};
  }
`;

const menuOptionStyles = css({
  height: 45,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 24,
  fontWeight: 'regular',
  transition: 'regular',
  color: spotnanaTheme.colors.text.black,
  ':hover': {
    fontWeight: 'semiBold',
    transition: 'regular',
  },
}) as unknown as CSSObject;

const MenuOption = styled(NavLink)(menuOptionStyles);

const StyledMenuOptionLink = styled.a(menuOptionStyles);

const StyledLink = styled.a({
  margin: 'auto',
  marginLeft: '12px',
  textWrap: 'nowrap',
});

const routesWithoutHeader = [ROUTE_PATHS.HOME, ROUTE_PATHS.LOGIN, ROUTE_PATHS.TERMS, ROUTE_PATHS.LEGAL_ENTITY];

interface IHeaderDropdownDrawerLink {
  route: string;
  /** For externally linked dropdown items */
  href?: string;
  label: string;
  roles: readonly IRoleInfoType[];
  hidden?: boolean;
  header?: number;
  testId?: string;
  location?: LocationDescriptor<LocationState>;
}
interface IHeaderDropdownLink extends IHeaderDropdownDrawerLink {
  testId: string;
}

const defaultLogoRedirectUrl = '/';
const defaultGuestLogoRedirectUrl = ROUTE_PATHS.TRIPS;
const AppHeaderInternal: React.FC = () => {
  const { data: loggedInUser, isLoading } = useLoggedInUser();
  const loggedInUserBasicInfo = useLoggedInUserBasicInfo();

  const hasAgentAccess = useHasUserAccess(TMCAgentAndAboveRoles);
  const { data: agentAvailabilityStatus } = useAgentStatusAvailabilityQuery(
    loggedInUserBasicInfo.existingUser?.userOrgId?.userId?.id as string,
    hasAgentAccess,
  );

  const loggedInUserId = useLoggedInUserId();
  const {
    features: { linkedMarriottBonvoyRequired },
  } = usePartnerFeatures();
  const { data: marriottBonvoyAccountInfo } = useGetMarriottBonvoyAccountInfo({
    userId: loggedInUserId.userId?.id as string,
    enabled:
      linkedMarriottBonvoyRequired &&
      Boolean(loggedInUserId.userId?.id) &&
      !hasIncompleteUserAction(loggedInUserBasicInfo, IncompleteUserActionName.MARRIOTT_BONVOY_LINK),
  });
  const { data: expensePartnersData, isLoading: isLoadingExpensePartners } = useGetUserExpensePartnersDetails({
    userId: loggedInUserId.userId?.id as string,
  });
  const expensePartners = useMemo(() => {
    return expensePartnersData?.expensePartnersDetails ?? [];
  }, [expensePartnersData]);
  const expensePartnersCount = expensePartners.length;
  const hasExpensePartners = Boolean(expensePartnersCount);

  const { isGuestPersona } = useUserPersona();
  const isBookingDisabled = useDisableBooking();

  const displayBookMenu = !isGuestPersona && !isBookingDisabled;
  const displayAnalyticsMenu = !isGuestPersona;

  const showAgentDesktop = loggedInUserId.tmcBasicInfo?.bookingTmc?.id?.id === loggedInUserId.organizationId?.id;

  const { data: pointsBalance } = usePointsBalance(loggedInUserId);

  const { data: redirectionInfo } = useCompanyRedirectionQuery(
    loggedInUserId.tmcBasicInfo?.contractingTmc?.id?.id ?? '',
    true,
  );

  const taasSettings = useJSONFeatureFlag('FE_CORP_TAAS_SETTINGS');
  const taasSettingFeatureEnabled = taasSettings?.enabled;

  const userName = loggedInUser?.user?.name ?? loggedInUserBasicInfo.existingUser?.name;
  const userEmail = loggedInUser?.userBusinessInfo?.email ?? loggedInUserBasicInfo.existingUser?.pemail;
  const userRoles = loggedInUserBasicInfo?.existingUser?.roleInfos.map((role) => role.type) ?? [];
  const userProfilePic = loggedInUser?.user?.profilePicture?.url ?? '';
  const isUserSeat1a = loggedInUser?.tier === TierEnum.SEAT1A;

  const hasTMCAccess = useHasUserAccess(userRolesByFeature.organizationSelector);
  const isTMC = hasTMCAccess && taasSettingFeatureEnabled;

  const impersonatedUserInfo = {
    title:
      loggedInUserBasicInfo.impersonatingUserInfo?.name?.given ??
      loggedInUserBasicInfo.impersonatingUserInfo?.email ??
      '',
    profilePic: loggedInUserBasicInfo.impersonatingUserInfo?.profilePicture?.url ?? '',
    userOrgId: loggedInUserBasicInfo.impersonatingUserInfo?.userOrgId ?? {},
  };

  const loadingHeaderData = isLoading || isLoadingExpensePartners;
  const avatarTitle: string = userName?.preferred || userName?.given || userEmail || '';

  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const avatarButtonRef = useRef<HTMLButtonElement | null>(null);
  const expandedMenuAvatarButtonRef = useRef<HTMLButtonElement | null>(null);

  const { isUserProfilePersonalDisabled, isUserProfileWorkDisabled } = usePartnerFlags();

  const [displayUserImpersonatedMessage, setUserImpersonatedMessage] = useState<boolean>(!!impersonatedUserInfo);
  const [impersonationOverlayOutsideClickCount, setImpersonationOverlayOutsideClickCount] = useState<number>(0);

  const isDebugToolLinkEnabled = useFeatureFlag('FE_INFRA_SHOW_DEBUG_LINK');
  const isCompanyTripsPageFeatureEnabled = useFeatureFlag('FE_COMPANY_TRIPS_PAGE');
  const isCompanyTripsPageV3FeatureEnabled = useFeatureFlag('FE_TMC_COMPANY_TRIPS_V3');
  const isApprovalDashboardEnabled = useFeatureFlag('FE_TRIPS_APPROVAL_DASHBOARD');
  const isExpenseMenuEnabled = useFeatureFlag('FE_CORP_APP_HEADER_EXPENSE_TAB') || false;
  const isDarkModeEnabled = useFeatureFlag('FE_INFRA_DARK_MODE');

  const isRingsEnabled = useFeatureFlag('FE_RINGS');
  const spotternetEnabled = useFeatureFlag('FE_SPOTTERNET');
  const brexPointsConfig = getBrexPointsInfo(pointsBalance);

  const { isEventsEnabled } = useIsEventsFeatureEnabled();

  const companyTripsPath = isCompanyTripsPageV3FeatureEnabled
    ? `/company-trips?${stringifyParams({ tripType: TripCategoryRequest.UPCOMING })}`
    : `${ROUTE_PATHS.COMPANY_TRIPS}/${TripCategoryPath.Upcoming}?${stringifyParams({ pageIndex: 1 })}`;

  const handleClick = (): void => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleMenuBlur = (event: Event): void => {
    // Click away listener is getting called right away when user clicks to open popper
    // Close the popper only if click area is outside the popper or button area.
    if (!event || !event.target) {
      setOpen(false);
      avatarButtonRef.current?.focus();
    }
  };
  const hideImpersonatedUserMessage = (): void => {
    setUserImpersonatedMessage(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { pathname } = useLocation();

  /** TODO (Smriti): Either update links to have a queryParams key
   * or add an onClick on Link which uses route and search params
   * to take user to that URL
   */

  // TODO: Redirect user to homepage when they access url not permitted for their role in ST-8380 (@piyush)
  // TODO: Add roles when backend confirms what roles to use to show different links
  const { t: tt } = useTranslation('WEB');
  let profileInfoPath = '/profile/personal';
  if (isUserProfilePersonalDisabled) {
    profileInfoPath = '/profile/work';
    if (isUserProfileWorkDisabled) {
      profileInfoPath = '/profile/preferences/flight';
    }
  }

  const links: IHeaderDropdownLink[] = [
    {
      label: tt('Trips'),
      route: `${ROUTE_PATHS.TRIPS}/${TripCategoryPath.Upcoming}?${stringifyParams({ pageIndex: 1 })}`,
      roles: [],
      testId: 'trips',
    },
    {
      label: tt('Approvals'),
      route: ROUTE_PATHS.APPROVAL_DASHBOARD,
      roles: [],
      testId: 'approvals',
      hidden: !isApprovalDashboardEnabled,
    },
    ...(isExpenseMenuEnabled && expensePartnersCount === 1
      ? generateExpensePartnersLinks(expensePartners, tt('Expense'))
      : []),
    {
      label: tt('My Profile'),
      route: profileInfoPath,
      location: {
        pathname: profileInfoPath,
        search: loggedInUserId ? stringifyParams({ profileId: loggedInUserId }) : '',
      },
      roles: [],
      testId: 'profile',
    },
    ...(isExpenseMenuEnabled && expensePartnersCount > 1
      ? generateExpensePartnersLinks(expensePartners, tt('Expense'))
      : []),
  ];

  const drawerLinks: IHeaderDropdownDrawerLink[] = React.useMemo(
    () => [
      {
        label: tt('Profile'),
        route: profileInfoPath,
        roles: [],
      },
      {
        label: tt('Book'),
        route: `${ROUTE_PATHS.HOME}`,
        roles: [],
        hidden: !displayBookMenu,
      },
      {
        label: tt('My trips'),
        route: `${ROUTE_PATHS.TRIPS}/${TripCategoryPath.Upcoming}?${stringifyParams({ pageIndex: 1 })}`,
        roles: [],
      },
      {
        label: tt('My Approvals'),
        route: ROUTE_PATHS.APPROVAL_DASHBOARD,
        roles: [],
        hidden: !isApprovalDashboardEnabled,
      },
      {
        label: tt('Company trips'),
        route: companyTripsPath,
        roles: userRolesByFeature.companyTripsPageRoles,
        hidden: !isCompanyTripsPageFeatureEnabled,
      },
      {
        label: tt('Company Approvals'),
        route: ROUTE_PATHS.COMPANY_APPROVAL_DASHBOARD,
        roles: userRolesByFeature.globalTmcAndCompanyRoles,
        hidden: !isApprovalDashboardEnabled,
      },
      {
        label: tt('Events'),
        id: 'events',
        route: `${ROUTE_PATHS.EVENTS}/${EventsCategoryPath.UPCOMING}`,
        roles: userRolesByFeature.eventsPageRoles,
        hidden: !isEventsEnabled,
      },
      ...(isExpenseMenuEnabled && expensePartnersCount === 1
        ? generateExpensePartnersLinks(expensePartners, tt('Expense'))
        : []),
      {
        label: tt('Support'),
        route: '/support',
        roles: userRolesByFeature.organizationSelector,
      },
      {
        label: tt('Agent'),
        route: '/agent/Desktop',
        roles: userRolesByFeature.organizationSelector,
      },
      ...(isExpenseMenuEnabled && expensePartnersCount > 1
        ? generateExpensePartnersLinks(expensePartners, tt('Expense'))
        : []),
      {
        label: tt('Safety'),
        route: '/',
        roles: userRolesByFeature.organizationSelector,
        header: 1,
      },
      {
        label: tt('Travelers'),
        route: '/dutyofcare',
        roles: userRolesByFeature.organizationSelector,
      },
      {
        label: tt('Analytics'),
        route: '/',
        roles: userRolesByFeature.organizationSelector,
        header: 1,
      },
      {
        label: tt('My reports'),
        route: '/traveler-reports',
        roles: [],
        hidden: isGuestPersona,
      },
      {
        label: tt('Company reports'),
        route: '/reports',
        roles: userRolesByFeature.reports,
      },
      {
        label: tt('Settings'),
        route: '/',
        roles: userRolesByFeature.organizationSelector,
        header: 1,
      },
      {
        label: tt('Company'),
        route: '/admin',
        roles: userRolesByFeature.organizationSelector,
      },
      {
        label: tt('Users'),
        route: '/admin/users',
        roles: userRolesByFeature.organizationSelector,
      },
      {
        label: tt('Policies'),
        route: '/admin/policies',
        roles: userRolesByFeature.organizationSelector,
      },
    ],
    [
      tt,
      profileInfoPath,
      displayBookMenu,
      isApprovalDashboardEnabled,
      companyTripsPath,
      isCompanyTripsPageFeatureEnabled,
      isEventsEnabled,
      isExpenseMenuEnabled,
      expensePartnersCount,
      expensePartners,
      isGuestPersona,
    ],
  );

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  // TODO(Bhavan): use rails and conceirge from ROUTE_PATHS once teh v2 re-architecture is done.

  const homeUrls = [ROUTE_PATHS.FLIGHTS, ROUTE_PATHS.CARS, ROUTE_PATHS.HOTELS, '/rails', '/concierge'];

  // If there is an intersection between userRoles and link.roles, then we need to show the menu item
  // If no roles are specified, it means it is default role and we would show the menu by default
  const hasAppropriateRoles = (roles: readonly IRoleInfoType[]): boolean =>
    Boolean(intersection(userRoles, roles).length);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (open) {
      expandedMenuAvatarButtonRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    if (impersonationOverlayOutsideClickCount > 2) {
      setUserImpersonatedMessage(false);
    }
  }, [impersonationOverlayOutsideClickCount]);

  const impersonationOverlayAwayClick = (): void => {
    if (impersonationOverlayOutsideClickCount < 3) {
      setImpersonationOverlayOutsideClickCount((value) => value + 1);
    }
  };

  const { trackEvent } = useTelemetry();

  const onUrlClick =
    (route: string, id?: string) =>
    (e: React.MouseEvent<HTMLAnchorElement>): void => {
      if (id === 'events') {
        trackEvent({ name: TelemetryEvents.EVENTS_NAVBAR_LINK_CLICKED, data: { link: route } });
      }
      if (route.includes(pathname)) {
        e.preventDefault();
        setOpen(false);
      }
    };

  const [showRingChangeModal, setShowRingChangeModal] = useState(false);
  const onRingChangeClick = (): void => {
    setShowRingChangeModal(true);
  };

  const { logoHeader, logoUrl, poweredBySpotnana, appHomeRoute } = useAppBranding();

  const showBrexPoints = hasAppropriateRoles(userRolesByFeature.adminPages) && brexPointsConfig.isApplicable;
  const showBackToCompany = redirectionInfo?.redirectUrl && redirectionInfo?.name;
  // Don't show expense tab to admins due to real estate concerns.
  const showExpenseTab =
    !!isExpenseMenuEnabled && hasExpensePartners && !hasAppropriateRoles(userRolesByFeature.adminPages);
  const availableBrexPoints = brexPointsConfig.availablePoints;

  const logoElement: React.ReactElement = (() => {
    const children = (
      <SpotnanaLogoShort src={logoHeader} data-testid="header-spotnana-logo" css={{ height: '2rem', width: 'auto' }} />
    );
    if (poweredBySpotnana && logoUrl) {
      return (
        <a href={logoUrl} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    }

    return <NavLink to={isGuestPersona ? defaultGuestLogoRedirectUrl : defaultLogoRedirectUrl}>{children}</NavLink>;
  })();

  if (routesWithoutHeader.includes(pathname)) {
    return null;
  }

  const list = () => (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        role="button"
        cursor="pointer"
        pb={24}
        pt={24}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingX={40}>
            <Box marginRight={10} position="relative">
              <UserAvatar
                imageSrc={userProfilePic}
                size="small"
                title={avatarTitle}
                isSeat1a={isUserSeat1a}
                agentAvailabilityStatus={agentAvailabilityStatus?.status}
              />
            </Box>
            <Ellipsis title={tt('Name')}>
              {loggedInUserBasicInfo.isImpersonationSession && tt('Logged in as ')}
              {avatarTitle}
            </Ellipsis>
          </Box>
        </Box>
        <Box paddingRight={36} onClick={closeDrawer}>
          <Icon name="CloseCross" width="12px" height="12px" />
        </Box>
      </Box>

      <Divider />
      <Box pt={12} onClick={closeDrawer}>
        {drawerLinks
          .filter(({ hidden }) => !hidden)
          .map(({ roles, route, href, label, header: isHeader, testId }) => {
            const showMenu = roles.length ? hasAppropriateRoles(roles) : true;
            const displayLabel = label.toString().toUpperCase();

            // Render external links with <a> tag.
            if (href) {
              return (
                <Box pl={24} key={href}>
                  <StyledMenuOptionLink
                    href={href}
                    target="_blank"
                    data-testid={`nav_user-content-${testId}`}
                    rel="noreferrer"
                    css={focus_outline}
                  >
                    {t(label.toString())}
                  </StyledMenuOptionLink>
                </Box>
              );
            }
            return (
              <Fragment key={label}>
                {showMenu && isHeader && (
                  <>
                    <Box py={12}>
                      <Divider />
                    </Box>
                    <Box pt="6px" pb="6px" flex="flex" alignItems="center" pl={48}>
                      <Typography variant="body3" kind="regular">
                        {upperCase(displayLabel)}
                      </Typography>
                    </Box>
                  </>
                )}
                {showMenu && !isHeader && (
                  <MenuOption
                    activeStyle={{ fontWeight: 600 }}
                    exact
                    to={route}
                    data-testid={`nav_user-content-${label.toString().toLowerCase()}`}
                    onClick={onUrlClick(route, testId)}
                  >
                    <Box pl={24}>{t(label.toString())}</Box>
                  </MenuOption>
                )}
              </Fragment>
            );
          })}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        role="button"
        cursor="pointer"
        pb={24}
        pt={24}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <MenuOption to={ROUTE_PATHS.SIGNOUT}>
              <Typography variant="body3" color="brand">
                <div
                  style={{
                    position: 'relative',
                    paddingLeft: 24,
                    fontWeight: 700,
                    fontSize: 16,
                  }}
                >
                  {tt('Sign out')}
                </div>
              </Typography>
            </MenuOption>
          </Box>
        </Box>
        <Box>
          {showBrexPoints && (
            <Box
              border="thinSolid"
              borderColor={spotnanaTheme.colors.border.lightGray2}
              bg={spotnanaTheme.colors.bg.alabaster}
              p="14px 10px"
              borderRadius="8px"
              textAlign="center"
              marginRight={36}
              whiteSpace="nowrap"
            >
              <Typography variant="body3" kind="semibold">
                {t(`${brexPointsConfig.availablePoints.toLocaleString()} points`)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );

  return (
    // TODO: Refactor header to ensure height comes from pixel also fix header popover
    <>
      <HeaderBanner />
      <header css={header}>
        <Grid.Container
          style={
            [ROUTE_PATHS.SPOTTERNET].some((item) => pathname.startsWith(item))
              ? { width: 'calc(100% - 20px)', marginRight: 20 }
              : {}
          }
        >
          <Grid.Row between="xs" middle="xs" role="navigation">
            <Grid.Col xs={1}>
              <div css={[flex.init, paddings.xl.comfortable]} style={{ position: 'relative' }}>
                <HeaderSkipLinkButton />
                {logoElement}
                {/* disabling as shimmer is causing visual glitches on slow network */}
                {/* {redirectionInfoLoading && (
                <ShimmerBox
                  ml="50px"
                  data-testid="loader"
                  width="100%"
                  border="thinSolid"
                  borderColor={spotnanaTheme.colors.border.regular}
                />
              )} */}
                {showBackToCompany && (
                  <StyledLink href={redirectionInfo?.redirectUrl ?? ''}>
                    <Typography variant="body3" kind="regular">
                      {t(`Back to ${redirectionInfo?.name}`)}
                    </Typography>
                  </StyledLink>
                )}
              </div>
            </Grid.Col>
            {/* Hide Header Links if user is not signed in */}
            {loadingHeaderData && (
              <Box display="flex">
                <Box bg="bg.divider" mx={2} width={60} height={20} />
                <Box bg="bg.divider" mx={2} width={60} height={20} />
              </Box>
            )}

            {!loadingHeaderData && avatarTitle && !['/login'].includes(pathname) && (
              <>
                <HideOnRouteMatch paths={[ROUTE_PATHS.SPOTTERNET_ALL]}>
                  <Grid.Col xs={false} md={8}>
                    <Layout.Row gap="extraSmall" css={[flex.init, flex.justify.center]}>
                      {displayBookMenu && (
                        <Layout.Col data-testid="nav_flight-search">
                          <Link
                            css={header_nav_element}
                            to={appHomeRoute}
                            isActive={(): boolean => homeUrls.some((url) => pathname?.startsWith(url))}
                          >
                            {tt('Book')}
                          </Link>
                        </Layout.Col>
                      )}

                      <Layout.Col data-testid="nav_trips-search">
                        <TripsTab />
                      </Layout.Col>

                      {displayAnalyticsMenu && (
                        <Layout.Col data-testid="nav_reports">
                          <AnalyticsTab />
                        </Layout.Col>
                      )}

                      {hasAppropriateRoles(userRolesByFeature.adminPages) && (
                        <Layout.Col data-testid="nav_program">
                          <ProgramTab isTMC={isTMC} />
                        </Layout.Col>
                      )}
                      {showExpenseTab && (
                        <Layout.Col data-testid="nav_expense">
                          <ExpenseTab details={expensePartners} />
                        </Layout.Col>
                      )}
                      <Layout.Col>
                        <Link css={header_nav_element} to="/support">
                          {tt('Support')}
                        </Link>
                      </Layout.Col>
                      {showAgentDesktop && (
                        <RBAC allowedRoles={userRolesByFeature.agentDesktop}>
                          <Layout.Col>
                            <Link
                              css={header_nav_element}
                              to={spotternetEnabled ? '/spotternet' : '/agent/queue-dashboard'}
                            >
                              {tt('Agent')}
                            </Link>
                          </Layout.Col>
                        </RBAC>
                      )}
                    </Layout.Row>
                  </Grid.Col>
                </HideOnRouteMatch>
                <Route path={ROUTE_PATHS.SPOTTERNET}>
                  <Grid.Col xs={false} md={10}>
                    <Grid.Row justifyContent="flex-end">
                      <AgentAppHeader />
                    </Grid.Row>
                  </Grid.Col>
                </Route>
              </>
            )}

            <Grid.Col>
              <Grid.Row end="xs" middle="xs" flexWrap="nowrap">
                <HeaderSavedFareListButton />
                <Grid.Col xs={2}>
                  <HeaderSearchButton />
                </Grid.Col>
                {showBrexPoints && (
                  <Grid.Col xs={false} md>
                    <Box
                      minWidth="132px"
                      border="thinSolid"
                      borderColor={spotnanaTheme.colors.border.lightGray2}
                      bg={spotnanaTheme.colors.bg.alabaster}
                      p="14px 10px"
                      borderRadius="8px"
                      textAlign="center"
                      whiteSpace="nowrap"
                    >
                      <Typography variant="body3" kind="semibold">
                        {availableBrexPoints <= 999999
                          ? t(`${availableBrexPoints.toLocaleString()} Points`)
                          : t(`${availableBrexPoints.toLocaleString()} Pts`)}
                      </Typography>
                    </Box>
                  </Grid.Col>
                )}
                {!showBackToCompany && <IntegrationsMenu />}
                <Grid.Col data-testid="nav_user-content" xs={false} md>
                  {!loadingHeaderData && avatarTitle && (
                    <Popover
                      open={open}
                      placement="top-end"
                      htmlButtonElement={
                        <HeaderAvatarButton
                          ref={avatarButtonRef}
                          avatarProps={{
                            size: 'medium',
                            src: userProfilePic,
                            alt: 'User info',
                            text: createUserNameFromFullName(loggedInUserBasicInfo.existingUser?.name),
                            customInitials: createInitialsFromFullName(loggedInUserBasicInfo.existingUser?.name),
                            color: 'primary',
                          }}
                          adornment={
                            loggedInUserBasicInfo.isImpersonationSession && (
                              <Avatar
                                alt={getNameStringFromName(loggedInUserBasicInfo.impersonatingUserInfo?.name)}
                                size="small"
                                src={impersonatedUserInfo?.profilePic}
                                text={getNameStringFromName(loggedInUserBasicInfo.impersonatingUserInfo?.name)}
                              />
                            )
                          }
                          currentUser={getNameStringFromName(loggedInUserBasicInfo.existingUser?.name)}
                          impersonationText={
                            loggedInUserBasicInfo.isImpersonationSession
                              ? tt('Impersonated by - {{name}}', {
                                  name: getNameStringFromName(loggedInUserBasicInfo.impersonatingUserInfo?.name),
                                })
                              : undefined
                          }
                          tooltip={tt('Profile and settings')}
                          isSeat1a={isUserSeat1a}
                          agentAvailabilityStatus={agentAvailabilityStatus?.status}
                          descriptionText={
                            marriottBonvoyAccountInfo?.eliteLevel
                              ? tt('Marriott Bonvoy elite level  - {{marriottBonvoyEliteLevel}},', {
                                  marriottBonvoyEliteLevel: marriottBonvoyAccountInfo.eliteLevel,
                                })
                              : undefined
                          }
                          data-testid="header-popover-button"
                          aria-expanded={open}
                          onClick={handleClick}
                        />
                      }
                      className="header-popover"
                      css={header_popover}
                      onClose={handleClose}
                      onKeyDown={(event) => {
                        if (event.key === 'Escape') {
                          handleClose();
                        }
                      }}
                      data-testid="header-popover"
                    >
                      <Box width={320} bg="bg.surfaceMenu" borderRadius="0 0 16px 16px" data-testid="header-popup">
                        <Box
                          height={69}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          paddingX={24}
                          pt={10}
                        >
                          <Typography variant="body1" kind="regular" data-testid="profile-name">
                            <Ellipsis title={avatarTitle} width="200px">
                              {loggedInUserBasicInfo.isImpersonationSession && tt('Logged in as ')}
                              {avatarTitle}
                            </Ellipsis>
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            role="button"
                            onClick={handleMenuBlur}
                            cursor="pointer"
                          >
                            <div style={{ position: 'relative' }}>
                              <HeaderAvatarButton
                                ref={expandedMenuAvatarButtonRef}
                                avatarProps={{
                                  size: 'medium',
                                  src: userProfilePic,
                                  alt: 'User info',
                                  color: 'primary',
                                  text: createUserNameFromFullName(loggedInUserBasicInfo.existingUser?.name),
                                  customInitials: createInitialsFromFullName(loggedInUserBasicInfo.existingUser?.name),
                                }}
                                adornment={
                                  loggedInUserBasicInfo.isImpersonationSession && (
                                    <Avatar
                                      alt={getNameStringFromName(loggedInUserBasicInfo.impersonatingUserInfo?.name)}
                                      size="small"
                                      src={impersonatedUserInfo?.profilePic}
                                      text={getNameStringFromName(loggedInUserBasicInfo.impersonatingUserInfo?.name)}
                                    />
                                  )
                                }
                                currentUser={getNameStringFromName(loggedInUserBasicInfo.existingUser?.name)}
                                impersonationText={
                                  loggedInUserBasicInfo.isImpersonationSession
                                    ? tt('Impersonated by - {{name}}', {
                                        name: getNameStringFromName(loggedInUserBasicInfo.impersonatingUserInfo?.name),
                                      })
                                    : undefined
                                }
                                tooltip={tt('Profile and settings')}
                                isSeat1a={isUserSeat1a}
                                agentAvailabilityStatus={agentAvailabilityStatus?.status}
                                descriptionText={
                                  marriottBonvoyAccountInfo?.eliteLevel
                                    ? tt('Marriott Bonvoy elite level  - {{marriottBonvoyEliteLevel}},', {
                                        marriottBonvoyEliteLevel: marriottBonvoyAccountInfo.eliteLevel,
                                      })
                                    : undefined
                                }
                                data-testid="header-popover-button-expanded"
                              />
                            </div>
                          </Box>
                        </Box>

                        <RBAC allowedRoles={TMCAgentAndAboveRoles}>
                          <Divider />
                          <AgentStatusMenu id={loggedInUserBasicInfo.existingUser?.userOrgId?.userId?.id as string} />
                          <Divider />
                        </RBAC>
                        {isRingsEnabled && (
                          <HeaderRingMenuOption userEmail={userEmail ?? ''} onChange={onRingChangeClick} />
                        )}
                        <RBAC allowedRoles={TMCAgentAndAboveRoles}>
                          <InternalMenu>
                            {isDebugToolLinkEnabled && <DebugToolLink />}
                            <DebugTool />
                            <ApiClient />
                          </InternalMenu>
                        </RBAC>
                        <Divider />
                        {isDarkModeEnabled && <SwitchThemeMode />}
                        {loggedInUserBasicInfo.isImpersonationSession && (
                          <Box position="relative">
                            <Card
                              bg="bg.blueGray"
                              data-testid="impersonation-message-info"
                              boxShadow="none"
                              p={3}
                              borderRadius="sm"
                              m="20px 16px"
                            >
                              <Typography variant="body3" kind="regular">
                                {tt('You have temporarily logged in as {{avatarTitle}}', { avatarTitle })}.<br />
                                {tt('When you are done')},{' '}
                                <NavLink
                                  data-testid="deimpersonation-overlay-link"
                                  to={{
                                    pathname: '/deimpersonate',
                                    search: stringifyParams({ userOrgId: impersonatedUserInfo.userOrgId ?? null }),
                                  }}
                                >
                                  <Typography variant="body3" kind="regular" color="error" as="span">
                                    {tt('switch back')}
                                  </Typography>
                                </NavLink>{' '}
                                {tt('to your account')}.
                              </Typography>
                            </Card>
                          </Box>
                        )}
                        {links.map(({ roles, route, href, label, header: isHeader, location, hidden, testId }) => {
                          const showMenu = roles.length ? hasAppropriateRoles(roles) : true;
                          if (!showMenu || hidden) {
                            return null;
                          }

                          // Render external links with <a> tag.
                          if (href) {
                            return (
                              <StyledMenuOptionLink
                                href={href}
                                key={href}
                                target="_blank"
                                data-testid={`nav_user-content-${testId}`}
                                rel="noreferrer"
                                css={focus_outline}
                              >
                                {t(label.toString())}
                              </StyledMenuOptionLink>
                            );
                          }

                          return (
                            <>
                              {isHeader && (
                                <>
                                  <Box py={12}>
                                    <Divider />
                                  </Box>
                                  <Box pt="6px" pb="6px" flex="flex" alignItems="center" pl={24}>
                                    <Typography variant="body3" kind="regular">
                                      {upperCase(label)}
                                    </Typography>
                                  </Box>
                                </>
                              )}
                              {!isHeader && (
                                <MenuOption
                                  activeStyle={{ fontWeight: 600 }}
                                  exact
                                  key={route}
                                  to={location || route}
                                  data-testid={`nav_user-content-${testId}`}
                                  onClick={onUrlClick(route)}
                                  css={focus_outline}
                                >
                                  {t(label.toString())}
                                </MenuOption>
                              )}
                            </>
                          );
                        })}
                        <MenuOption css={focus_outline} to={ROUTE_PATHS.SIGNOUT}>
                          <Box as="span" mr="8px" style={{ align: 'center' }}>
                            <Icon name="ArrowLeft" css={signout_icon} />
                          </Box>
                          <Typography color="brand" variant="body1" kind="regular">
                            {tt('Sign out')}
                          </Typography>
                        </MenuOption>
                      </Box>
                    </Popover>
                  )}
                  {isRingsEnabled && (
                    <RingChangeModal
                      userEmail={userEmail ?? ''}
                      showModal={showRingChangeModal}
                      closeModal={(): void => setShowRingChangeModal(false)}
                    />
                  )}
                </Grid.Col>
                <Grid.Col xs md={false}>
                  <IconButton icon="MenuHamburger" aria-label={tt('Open drawer')} size="xlarge" onClick={openDrawer} />
                  <Drawer open={drawerOpen} anchor="right" onClose={closeDrawer}>
                    <DrawerContainer data-testid="header-drawer">{list()}</DrawerContainer>
                  </Drawer>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
          {displayUserImpersonatedMessage && !open && (
            <ClickAwayListener onClickAway={impersonationOverlayAwayClick}>
              <Box position="relative">
                {impersonatedUserInfo.title && (
                  <>
                    <Card
                      data-testid="impersonation-message-popup"
                      position="absolute"
                      bg="bg.regular"
                      width="364px"
                      right={0}
                      bottom={-144}
                      px="20px"
                      py="24px"
                    >
                      <Typography variant="body3" kind="regular">
                        {tt('You have temporarily logged in as {{avatarTitle}}', { avatarTitle })}.<br />
                        {tt('When you are done')},{' '}
                        <NavLink
                          data-testid="deimpersonation-header-link"
                          to={{
                            pathname: '/deimpersonate',
                            search: stringifyParams({ userOrgId: impersonatedUserInfo.userOrgId ?? null }),
                          }}
                        >
                          <Typography variant="body3" as="span" color="error">
                            {tt('switch back')}
                          </Typography>
                        </NavLink>{' '}
                        {tt('to your account')}.
                      </Typography>
                    </Card>
                    <Box
                      position="absolute"
                      right={12}
                      bottom={-76}
                      cursor="pointer"
                      onClick={hideImpersonatedUserMessage}
                      data-testid="impersonation-message-popup-close-icon"
                    >
                      <Icon name="CloseCross" width="24px" height="24px" />
                    </Box>
                  </>
                )}
              </Box>
            </ClickAwayListener>
          )}
        </Grid.Container>
      </header>
    </>
  );
};

// eslint-disable-next-line no-underscore-dangle
function AppHeader_() {
  const { isEmbed } = useEmbedStore();

  return (
    <div id="sn-app-header" data-environment={Config.VITE_ENVIRONMENT}>
      {isEmbed ? undefined : <AppHeaderInternal />}
    </div>
  );
}
export const AppHeader = React.memo(AppHeader_);
