import { Button } from '@spotnana/blocks/src/Button';
import { RichTextEditor } from '@spotnana/blocks/src/RichTextEditor';
import { Typography } from '@spotnana/blocks/src/Typography';
import { useGetNote, useLoggedUserId, useUpdateNote } from 'obt-common';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notes_editor_wrapper } from '../index.styles';

// Notes component displays a rich text editor for creating and customizing important notes
const Notes = (): JSX.Element => {
  // Translation hook to get translation functions specific to 'WEB' namespace
  const { t: tt } = useTranslation('WEB');

  const { userId } = useLoggedUserId();
  // Fetch note data using useGetNote hook
  const { data } = useGetNote({ userId: userId?.id });

  // State to manage the content of the note
  const [note, updateNote] = useState(() => data?.note?.note || '');

  // Custom hook for adding a new note
  const { mutateAsync: addNote, isLoading } = useUpdateNote();

  // Effect to update the note content when data changes
  useEffect(() => {
    updateNote(data?.note?.note || '');
  }, [data]);

  // Callback to handle changes in the rich text editor content
  const onHtmlChange = useCallback((html: string) => {
    updateNote(html);
  }, []);

  // Callback to save the note
  const saveNote = useCallback(() => {
    addNote({ userId: userId?.id, request: { note } });
  }, [addNote, note, userId]);

  // Main container for the Notes component
  return (
    <div css={notes_editor_wrapper}>
      {/* Message prompting users to create and customize important notes */}
      <Typography variant="header5" kind="medium">
        {tt('Create and customise your important notes.')}
      </Typography>

      {/* RichTextEditor component for editing and formatting notes */}
      <RichTextEditor
        key={note ? 'Fetching' : 'Fetched'}
        initialHtml={note}
        inputId="notes"
        onHtmlChange={onHtmlChange}
        showAlignment
        showFontFamily
        showFontSize
        showFontStyle
        showTextBackgroundColor
        showTextForegroundColor
        showTypography
        showPreviewButton={false}
        className="NotesEditor"
      />

      {/* Button to save the notes */}
      <Button
        disabled={isLoading}
        data-testid="save-note-button"
        variant="primary"
        size="small"
        fullWidth
        onClick={saveNote}
      >
        {isLoading ? tt('Saving...') : tt('Save')}
      </Button>
    </div>
  );
};

export default Notes;
