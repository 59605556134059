import { TravelerBasicInfo } from 'obt-common';

import EntityAutocompleteOption from 'src/v1-components/shared/EntityAutocomplete/EntityAutocompleteOption';

export interface IAutocompleteOptionProps {
  travelerInfo: TravelerBasicInfo;
  active: boolean;
  onClick?: (travelerInfo: TravelerBasicInfo) => void;
}

export default function AutocompleteOption({ travelerInfo, active, onClick }: IAutocompleteOptionProps): JSX.Element {
  const onClickHandler = (): void => {
    onClick?.(travelerInfo);
  };

  return (
    <EntityAutocompleteOption
      option={travelerInfo}
      isHighlighted={active}
      isTravelerAutocomplete
      onClick={onClickHandler}
    />
  );
}
