import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const AngleFlatSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.926 7.338a1.838 1.838 0 11-3.676 0 1.838 1.838 0 013.676 0zm14.72 6.401l-4.432-1.49-5.677-1.425-2.895-1.173a.888.888 0 01.64-1.655l2.725 1.006 10.058 3.514a.647.647 0 01-.42 1.223zm-4.713-.53l-5.707-1.432-2.96-1.199a1.888 1.888 0 011.363-3.52l2.716 1.003 10.05 3.511a1.647 1.647 0 01-1.068 3.115l-4.394-1.477zm-13.72-3.12a1.25 1.25 0 10-.924 2.323l7.008 2.787a1.25 1.25 0 00.924-2.323l-7.009-2.787zm9.525 3.408a1.25 1.25 0 10-.715 2.396l6.578 1.962a1.25 1.25 0 00.715-2.395l-6.578-1.963zm7.429 3.6a1 1 0 01.997-1.002l2.095-.007a1 1 0 11.007 2l-2.096.007a1 1 0 01-1.003-.997zM1.195 13.54a.5.5 0 00-.39.92l8.245 3.498c.035.015.07.026.107.033l1.245.25a.5.5 0 10.196-.981l-1.2-.24-8.203-3.48z", fill: themeEnrichedPrimaryColor })));
};
export default AngleFlatSeat;
AngleFlatSeat.defaultProps = defaultIconProps;
