/* eslint-disable */
import { ThirdPartySource } from '../../../v1/obt/supplier/third_party_info';
import { TripId } from '../../../v1/obt/trip/trip_common';
import { UserOrgId } from '../../../v1/obt/common/user_org_id';
import { GossiperCapturePaymentMessage } from '../../../v1/obt/profile/payments/capture';
import { Money } from '../../common/money';
import { Weight } from '../common/weight';

export interface SourceInfo {
  /**
   *  The third party source for data
   */
  source: ThirdPartySource;
  /**
   *  The API description saved in debug tool, this will be used to uniquely identify source logs
   */
  sourceDescription: string;
  /**
   *  ID that can be used to locate the object in source logs
   */
  sourceId: string;
}

export interface PassengerAge {
  years: number;
}

/**
 *  Represents flight number like LX147 where LX would be airline and
 *  147 as num.
 */
export interface FlightNumber {
  airline: string;
  num: string;
}

export interface PassengerAge {
  years: number;
}

export interface FareCategory {
  ngsCategory: FareCategoryNGSFareCategory | undefined;
  cabinViewCategory: FareCategoryCabinViewFareCategory | undefined;
}

export interface SearchId {
  /**
   *  The workflow ID of the search
   */
  workflowId: string;
  /**
   *  The trace IDs of search request for each leg.
   */
  legSearchIds: string[];
  travelFusion?: SearchIdTravelFusion | undefined;
  /**
   *  The userOrgIds of the travellers in the corresponding search request.
   */
  userOrgIds: UserOrgId[];
  selectedItineraryTraceId: string;
  /**
   *  indicates whether the search is for exchange/modify shopping
   */
  isExchange: boolean;
}

export interface SearchIdTravelFusion {
  routingId: string;
}

/**
 *  Represents the point of sale of a third party like Sabre.
 */
export interface POS {
  /**
   *  The pseudo city code associated with the GDS.
   */
  pcc: string;
}

/**
 *  In air search for a round trip, the air search flow will be as follows:
 *  1. The first request will contain the search request parameters including the origin destination,
 *     timings, sorters, filters, etc. Bases on these search parameters, we will make a search
 *     request to all third party services (for eg: Sabre), map their response to AirSearchResponse
 *     proto and store it in S3. From this saved AirSearchResponse, we will extract the first leg
 *     options, deduplicate them and send as first response. Each leg option will contain a
 *     RateOptionId, this RateOptionId will contain the indices of all the itineraries and itinerary
 *     groups in the saved AirSearchResponse which contains that leg option as first leg.
 *  2. A leg will be selected from the leg options sent in the first response. The second request
 *     will contain the RateOptionId of that selected leg. Using the rate option Id, we will extract
 *     all the itineraries which contain that selected leg, extract their second leg, deduplicate
 *     them, and send them in the second response.
 *  4. The user will select one leg from the second leg options and the entire itinerary will be
 *     selected.
 */
export interface RateOptionId {
  legIds: RateOptionIdLegId[];
}

export interface RateOptionIdLegId {
  anchorSearchIds: RateOptionIdLegIdAnchorSearchId[];
}

export interface RateOptionIdLegIdItineraryId {
  itineraryIdx: number;
  rateOptionId: string;
  /**
   *  The source of this itinerary
   */
  source: ThirdPartySource;
  /**
   *  The point of sale, where this itinerary was shopped from.
   */
  pos?: POS;
}

export interface RateOptionIdLegIdItineraryGroupId {
  itineraryGroupIdx: number;
  legOptionIds: RateOptionIdLegIdItineraryGroupIdLegOptionId[];
  /**
   *  The source of this itinerary group
   */
  source: ThirdPartySource;
  /**
   *  The point of sale, where this leg option was shopped from.
   */
  pos?: POS;
}

export interface RateOptionIdLegIdItineraryGroupIdLegOptionId {
  legOptionIdx: number;
  rateOptionId: string;
}

export interface RateOptionIdLegIdAnchorSearchId {
  /**
   *  In anchor search we will have multiple saved air search response, anchor_search_id will
   *  uniquely determine the search response in which these itineraries are present
   */
  anchorSearchId: string;
  itineraryIds: RateOptionIdLegIdItineraryId[];
  itineraryGroupIds: RateOptionIdLegIdItineraryGroupId[];
  source: ThirdPartySource;
}

export interface BaggageId {
  /**
   *  Unique id identifying this luggage option.
   */
  baggageId: string;
  travelFusion?: BaggageIdTravelFusion | undefined;
}

export interface BaggageIdTravelFusion {
  /**
   *  Index number corresponding to luggage options
   */
  baggageIndex: string;
}

export interface AncillaryResponseId {
  searchId: string;
  rateOptionId: string;
  ancillaryTraceId: string;
}

export interface PnrId {
  /**
   *  The workflow ID of air search flow
   */
  workflowId: string;
  /**
   *  The Spotnana's PNR ID
   */
  pnrId: string;
  /**
   *  The BookingId sent as part of create pnr request.
   */
  bookingId: string;
  /**
   *  TripId sent as part of create pnr request.
   */
  tripId?: TripId;
  /**
   *  Request containing info to deduct amount from user's card through stripe.
   */
  captureRequest?: GossiperCapturePaymentMessage;
}

export interface BookingId {
  workflowId: string;
  revalidateTraceId: string;
  travelFusion?: BookingIdTravelFusion | undefined;
  fareLogix?: BookingIdFareLogix | undefined;
  qbr?: BookingIdQbr;
}

export interface BookingIdQbr {
  /**
   * Cart Id of Qantas business rewards
   *
   * @deprecated
   */
  cartId: string;
  /** @deprecated */
  airOfferId: string;
  /** Order id created in initiate booking request */
  orderId: string;
}

export interface BookingIdTravelFusion {
  referenceId: string;
}

export interface BookingIdFareLogix {
  referenceId: string;
}

export interface Co2EmissionDetail {
  /** This is the estimated C02 per passenger for selected flight and seating class in tonnes. */
  emissionValue: number;
  /** This is the average estimated C02 per passenger for same route in tonnes. */
  averageEmissionValue: number;
  /** This is the total distance covered by the flight in kilometres. */
  flightDistanceKm: number;
  /** This stores if the emission values are approximate(fallback) true => approximate */
  isApproximate: boolean;
  /** The emission value with units. */
  weight?: Weight;
  /**
   * The cost associated with the emission. This will be calculated based on the
   * carbon cost configured by a company.
   */
  cost?: Money;
}

export enum CabinEnum {
  UNKNOWN_CABIN = 0,
  ECONOMY = 1,
  PREMIUM_ECONOMY = 2,
  BUSINESS = 3,
  FIRST = 4,
  UNRECOGNIZED = -1,
}

export type Cabin = 0 | 1 | 2 | 3 | 4 | -1;

export enum PassengerTypeEnum {
  UNKNOWN_PASSENGER_TYPE = 0,
  ADULT = 1,
  CHILD = 2,
  INFANT = 3,
  INFANT_ON_LAP = 4,
  YOUTH = 5,
  SENIOR = 6,
  UNRECOGNIZED = -1,
}

export type PassengerType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | -1;

export enum AllianceEnum {
  UNKNOWN_ALLIANCE = 0,
  STAR_ALLIANCE = 1,
  ONEWORLD = 2,
  SKYTEAM = 3,
  VANILLA_ALLIANCE = 4,
  U_FLY_ALLIANCE = 5,
  VALUE_ALLIANCE = 6,
  UNRECOGNIZED = -1,
}

export type Alliance = 0 | 1 | 2 | 3 | 4 | 5 | 6 | -1;

export enum MealTypeEnum {
  UNKNOWN_MEAL = 0,
  AVML = 1,
  BLML = 2,
  CHML = 3,
  DBML = 4,
  FPML = 5,
  GFML = 6,
  HNML = 7,
  KSML = 8,
  LCML = 9,
  LFML = 10,
  LSML = 11,
  MOML = 12,
  NLML = 13,
  NSML = 14,
  PFML = 15,
  RVML = 16,
  SFML = 17,
  SPML = 18,
  VGML = 19,
  VLML = 20,
  VOML = 21,
  UNRECOGNIZED = -1,
}

export type MealType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | -1;

export enum FareCategoryNGSFareCategory {
  UNKNOWN_NGS_CATEGORY = 0,
  BASE = 1,
  STANDARD = 2,
  ENHANCED = 3,
  PREMIUM = 4,
  LUXURY = 5,
  ULTRA_LUXURY = 6,
  UNRECOGNIZED = -1,
}

export enum FareCategoryCabinViewFareCategory {
  UNKNOWN_CABIN_CATEGORY = 0,
  BASIC = 1,
  ECONOMY = 2,
  PREMIUM_ECONOMY = 3,
  BUSINESS = 4,
  FIRST = 5,
  ECONOMY_PLUS = 6,
  UNRECOGNIZED = -1,
}

/** Details related to an airport. */
export interface AirportInfo {
  /** The airport code. Eg: WRA. */
  airportCode: string;
  /** The airport name. Eg: Warder Airport. */
  airportName: string;
  /** Airport city name. Eg: Werder */
  cityName: string;
  /** Airport country name. Eg: Ethiopia */
  countryName: string;
  /** Airport 2 letter IATA country code. Eg: ET */
  countryCode: string;
  /** Airport time zone name. Eg: Africa/AddisAbaba */
  zoneName: string;
  /** Airport state code. Eg: CA */
  stateCode: string;
}

/** Details needed to refer to a flight in an air pnr */
export interface FlightIdentifier {
  /** flight index of the referred flight */
  flightIndex: number;
  /** leg index to which the above flight belongs */
  legIndex: number;
}
