/* eslint-disable */
import type { DoubleRange, MoneyRange } from '../../../v1/common/range';
import type { Length } from '../../../v1/common/length';
import type { OccupancyDateParams, HotelAmenityType, HotelPropertyType } from '../../../v1/obt/hotel/hotel_common';
import type { NonProfileUserInfo, UserOrgId } from '../../../v1/obt/common/user_org_id';
import type { CorporateInfo } from '../../../v1/obt/policy/policy_common';
import type { PostalAddress } from '../../../v1/common/postal_address';
import type { Latlng } from '../../../v1/common/latlng';
import type { Preference } from '../../../v1/obt/profile/preferences';
import { PrepaidQualifier } from './hotel_details_response';

export interface Filters {
  /**
   * Range of star ratings for hotels.
   *
   * @deprecated
   */
  starRating?: DoubleRange;
  price?: MoneyRange;
  amenities?: HotelAmenityType[];
  propertyTypes?: HotelPropertyType[];
  chainCodes?: string[];
  radius?: Length;
  /** List of discrete star rating values of hotels to be filtered. */
  starRatings?: number[];
  /** Hotel name query. If hotel name has "query" as substring, hotel will be returned. */
  nameQuery?: string;
  /** Filter hotels eligible for loyalty points. */
  loyalty?: boolean;
  /** Filter to show sold out hotels. */
  showUnavailable?: boolean;
  /** Filter for prepaid qualifier */
  prepaidQualifier?: PrepaidQualifier;
  /** Filter for pay by points */
  payByPoints?: boolean;
  /** Filter for cancellation self modify */
  modifiableOnly?: boolean;
  /** Filter for cancellation refund only */
  refundableOnly?: boolean;
  rateTypes?: FiltersRateType[];
  /** Filter for preferred hotels */
  preferredOnly: boolean;
  /** Get specific hotels provided in search params only */
  specificHotelSearch?: boolean;
  /** Sub Chain / Brand codes of hotels to be filtered. */
  brandCodes?: string[];
}

export enum FiltersRateType {
  UNKNOWN_RATE_TYPE = 0,
  /** REGULAR - Includes RAC TMC Company Rates */
  REGULAR = 1,
  AAA = 2,
  SENIOR = 3,
  GOVERNMENT = 4,
  MILITARY = 5,
  MEMBER = 6,
  UNRECOGNIZED = -1,
}

export interface SortOptions {
  sortBy: SortOptionsSortBy;
  sortOrder: SortOptionsSortOrder;
}

export enum SortOptionsSortBy {
  PRICE = 0,
  DISTANCE = 1,
  SABRE_RATING = 2,
  UNRECOGNIZED = -1,
}

export enum SortOptionsSortOrder {
  ASCENDING = 0,
  DESCENDING = 1,
  UNRECOGNIZED = -1,
}

export interface SearchParams {
  occupancyDates?: OccupancyDateParams;
  airport?: string;
  address?: PostalAddress;
  coordinates?: Latlng;
  hotelCode?: string;
  hotelCodes?: SearchParamsMultiHotelCodes;
}

/** Proto message for multiple hotel codes. */
export interface SearchParamsMultiHotelCodes {
  hotelCodes: string[];
}

export interface HotelSearchRequest {
  userOrgId?: UserOrgId;
  searchParams?: SearchParams;
  /** @deprecated */
  currencyCode?: string;
  sortOptions?: SortOptions[];
  filters?: Filters;
  paginationParams?: HotelSearchRequestPaginationParams;
  /** List of preferred hotels. */
  preferences?: Preference[];
  /** This contains details about all the custom fields */
  corporateInfo?: CorporateInfo;
  /**
   * User info about the non-profile user. For UI only.
   * Note: we shouldn't populate the user org id object.
   */
  nonProfileUserInfo?: NonProfileUserInfo;
  /** Internal ID of the trip. */
  tripId?: string;
  /** The unique identifier to maintain and track user session. */
  sessionId?: string;
}

export interface HotelSearchRequestPaginationParams {
  /**
   * The page number for which data has to be fetched. The minimum value is
   * 1.
   */
  pageIndex: number;
  /** The Key to be used for fetching the pages post the first page. */
  searchKey?: string;
}
