export const generateThemeWithCSSVars = (theme, path) => {
    const updatedTheme = Object.assign({}, theme);
    Object.entries(theme).forEach(([key, value]) => {
        const newPath = (path ? `${path}-` : '') + key;
        if (typeof value === 'object') {
            updatedTheme[key] = generateThemeWithCSSVars(value, newPath);
        }
        else {
            updatedTheme[key] = `var(--${newPath})`;
        }
    });
    return updatedTheme;
};
