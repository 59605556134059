import { Theme } from '@mui/material';
import { INJECTED_THEME_PROPERTYNAME } from '../BlocksThemeProvider/constants';
import type { TMuiThemeWithInjectedBlocksConfig } from '../BlocksThemeProvider';

export function removeSpaceLike(str: string) {
  return str.replace(/\s/g, '-');
}

export function encodeInputLabel(label: string, staticSuffix = '') {
  return removeSpaceLike(
    `${label
      .split('')
      .filter((_, idx) => !(idx % 2))
      .join('')}${label.length}-${staticSuffix}`,
  );
}

export function getBlocksThemeFromMui(theme: Theme) {
  return (theme as unknown as TMuiThemeWithInjectedBlocksConfig)[INJECTED_THEME_PROPERTYNAME];
}

export function getBlocksPaletteFromMui(theme: Theme) {
  return (theme as unknown as TMuiThemeWithInjectedBlocksConfig)[INJECTED_THEME_PROPERTYNAME]?.palette[
    theme.palette.mode
  ];
}
