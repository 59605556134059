/* eslint-disable */
import { CarExtraType as CarExtraTypeV2 } from '../../../../api/v2/obt/model/car-extra-type';
import type { ImageGroup } from '../../common/image_group';
import { Latlng } from '../../common/latlng';
import type { Money } from '../../common/money';
import type { PhoneNumber } from '../../common/phone_number';
import { PostalAddress } from '../../common/postal_address';
import { DateTimeRange } from '../../common/range';
import type { ThirdPartySource } from '../supplier/third_party_info';
import { CarType as CarTypeV2 } from '../../../../api/v2/obt/model/car-type';
import { CarExtaCarExtraTypeEnum } from '../../../../api/v2/obt/model/car-exta';

export enum CarType {
  OTHER = 0,
  MINI = 1,
  ECONOMY = 2,
  COMPACT = 3,
  MID_SIZE = 4,
  STANDARD = 5,
  FULL_SIZE = 6,
  PREMIUM = 7,
  LUXURY = 8,
  CONVERTIBLE = 9,
  MINIVAN = 10,
  SUV = 11,
  VAN = 12,
  PICKUP = 13,
  SPORTS = 14,
  SPECIAL = 15,
  RECREATIONAL_VEHICLE = 16,
  WAGON = 17,
  UNRECOGNIZED = -1,
}

// TODO: @Rajesh Remove type conversions
export const mapCarTypeV1toV2: Record<CarType, CarTypeV2> = {
  [CarType.OTHER]: CarTypeV2.Other,
  [CarType.MINI]: CarTypeV2.Mini,
  [CarType.ECONOMY]: CarTypeV2.Economy,
  [CarType.COMPACT]: CarTypeV2.Compact,
  [CarType.MID_SIZE]: CarTypeV2.MidSize,
  [CarType.STANDARD]: CarTypeV2.Standard,
  [CarType.FULL_SIZE]: CarTypeV2.FullSize,
  [CarType.PREMIUM]: CarTypeV2.Premium,
  [CarType.LUXURY]: CarTypeV2.Luxury,
  [CarType.CONVERTIBLE]: CarTypeV2.Convertible,
  [CarType.MINIVAN]: CarTypeV2.Minivan,
  [CarType.SUV]: CarTypeV2.Suv,
  [CarType.VAN]: CarTypeV2.Van,
  [CarType.PICKUP]: CarTypeV2.Pickup,
  [CarType.SPORTS]: CarTypeV2.Sports,
  [CarType.SPECIAL]: CarTypeV2.Special,
  [CarType.RECREATIONAL_VEHICLE]: CarTypeV2.RecreationalVehicle,
  [CarType.WAGON]: CarTypeV2.Wagon,
  [CarType.UNRECOGNIZED]: CarTypeV2.Other,
};

export enum CarExtraType {
  UNKNOWN_TYPE = 0,
  RENTER_AGE = 1,
  ADDITIONAL_DRIVER = 2,
  BABY_STROLLER = 3,
  CHILD_SEAT_BOOSTER = 4,
  BICYCLE_RACK = 5,
  CARGO_BARRIER_BACK = 6,
  CARGO_BARRIER_FRONT = 7,
  BOOSTER_SEAT_UP_TO_AGE_12 = 8,
  COLLISION_DAMAGE_WAIVER = 9,
  CHILD_SEAT_UP_TO_AGE_3 = 10,
  CHILD_SEAT_INFANT = 11,
  SATELLITE_RADIO = 12,
  CHILD_SEAT_TODDLER = 13,
  REAR_SEAT_DVD_PLAYER = 14,
  FLAG_HOLDER = 15,
  HAND_CONTROL_LEFT = 16,
  HAND_CONTROL_RIGHT = 17,
  MOTORCYCLE_HELMET = 18,
  SECURITY_DEVICES = 19,
  JERRY_CAN = 20,
  LOSS_DAMAGE_WAIVER = 21,
  LUGGAGE_ROOF_CASE = 22,
  LUGGAGE_TRAILER = 23,
  LUGGAGE_RACK = 24,
  NAVIGATIONAL_SYSTEM = 25,
  NAVIGATIONAL_SYSTEM_BUILT_IN = 26,
  PERSONAL_ACCIDENT_INSURANCE = 27,
  MOBILE_PHONE = 28,
  ROAD_CONGESTION_PAYMENT_SCHEME = 29,
  SEAT_BELT_EXTENSION = 30,
  SNOW_BOARD_RACKS = 31,
  SKI_BOX = 32,
  SKI_RACK_ONLY = 33,
  SKI_RACK_OR_BOX = 34,
  SKI_EQUIPPED_VEHICLE = 35,
  SNOW_CHAINS = 36,
  SNOW_PACKS = 37,
  SNOW_TIRES = 38,
  SPINNER_KNOB = 39,
  SURF_RACK = 40,
  SCOOTER_TOP_CASE = 41,
  SNOW_WINTER_TIRES = 42,
  TOLL_PAYMENT_TAG_PASS = 43,
  SPARE_TIRE = 44,
  WHEEL_CHAIR_ACCESS_RAMP = 45,
  WHEEL_CHAIR = 46,
  WIFI_ACCESS = 47,
  UNRECOGNIZED = -1,
}

export const mapCarExtraTypeV1toV2: Partial<Record<CarExtraType, CarExtraTypeV2>> = {
  [CarExtraType.BICYCLE_RACK]: CarExtraTypeV2.BicycleRack,
  [CarExtraType.CHILD_SEAT_BOOSTER]: CarExtraTypeV2.ChildSeatBooster,
  [CarExtraType.CHILD_SEAT_INFANT]: CarExtraTypeV2.ChildSeatInfant,
  [CarExtraType.CHILD_SEAT_TODDLER]: CarExtraTypeV2.ChildSeatToddler,
  [CarExtraType.SATELLITE_RADIO]: CarExtraTypeV2.SatelliteRadio,
  [CarExtraType.LUGGAGE_RACK]: CarExtraTypeV2.LuggageRack,
  [CarExtraType.NAVIGATIONAL_SYSTEM]: CarExtraTypeV2.NavigationalSystem,
  [CarExtraType.SKI_EQUIPPED_VEHICLE]: CarExtraTypeV2.SkiEquippedVehicle,
  [CarExtraType.SNOW_CHAINS]: CarExtraTypeV2.SnowChains,
  [CarExtraType.WHEEL_CHAIR_ACCESS_RAMP]: CarExtraTypeV2.WheelChairAccessRamp,
};

export const mapCarExtraTypeV2toV1 = {
  [CarExtaCarExtraTypeEnum.UnknownType]: CarExtraType.UNKNOWN_TYPE,
  [CarExtaCarExtraTypeEnum.RenterAge]: CarExtraType.RENTER_AGE,
  [CarExtaCarExtraTypeEnum.AdditionalDriver]: CarExtraType.ADDITIONAL_DRIVER,
  [CarExtaCarExtraTypeEnum.BabyStroller]: CarExtraType.BABY_STROLLER,
  [CarExtaCarExtraTypeEnum.ChildSeatBooster]: CarExtraType.CHILD_SEAT_BOOSTER,
  [CarExtaCarExtraTypeEnum.BicycleRack]: CarExtraType.BICYCLE_RACK,
  [CarExtaCarExtraTypeEnum.CargoBarrierBack]: CarExtraType.CARGO_BARRIER_BACK,
  [CarExtaCarExtraTypeEnum.CargoBarrierFront]: CarExtraType.CARGO_BARRIER_FRONT,
  [CarExtaCarExtraTypeEnum.BoosterSeatUpToAge12]: CarExtraType.BOOSTER_SEAT_UP_TO_AGE_12,
  [CarExtaCarExtraTypeEnum.CollisionDamageWaiver]: CarExtraType.COLLISION_DAMAGE_WAIVER,
  [CarExtaCarExtraTypeEnum.ChildSeatUpToAge3]: CarExtraType.CHILD_SEAT_UP_TO_AGE_3,
  [CarExtaCarExtraTypeEnum.ChildSeatInfant]: CarExtraType.CHILD_SEAT_INFANT,
  [CarExtaCarExtraTypeEnum.SatelliteRadio]: CarExtraType.SATELLITE_RADIO,
  [CarExtaCarExtraTypeEnum.ChildSeatToddler]: CarExtraType.CHILD_SEAT_TODDLER,
  [CarExtaCarExtraTypeEnum.RearSeatDvdPlayer]: CarExtraType.REAR_SEAT_DVD_PLAYER,
  [CarExtaCarExtraTypeEnum.FlagHolder]: CarExtraType.FLAG_HOLDER,
  [CarExtaCarExtraTypeEnum.HandControlLeft]: CarExtraType.HAND_CONTROL_LEFT,
  [CarExtaCarExtraTypeEnum.HandControlRight]: CarExtraType.HAND_CONTROL_RIGHT,
  [CarExtaCarExtraTypeEnum.MotorcycleHelmet]: CarExtraType.MOTORCYCLE_HELMET,
  [CarExtaCarExtraTypeEnum.SecurityDevices]: CarExtraType.SECURITY_DEVICES,
  [CarExtaCarExtraTypeEnum.JerryCan]: CarExtraType.JERRY_CAN,
  [CarExtaCarExtraTypeEnum.LossDamageWaiver]: CarExtraType.LOSS_DAMAGE_WAIVER,
  [CarExtaCarExtraTypeEnum.LuggageRoofCase]: CarExtraType.LUGGAGE_ROOF_CASE,
  [CarExtaCarExtraTypeEnum.LuggageTrailer]: CarExtraType.LUGGAGE_TRAILER,
  [CarExtaCarExtraTypeEnum.LuggageRack]: CarExtraType.LUGGAGE_RACK,
  [CarExtaCarExtraTypeEnum.NavigationalSystem]: CarExtraType.NAVIGATIONAL_SYSTEM,
  [CarExtaCarExtraTypeEnum.NavigationalSystemBuiltIn]: CarExtraType.NAVIGATIONAL_SYSTEM_BUILT_IN,
  [CarExtaCarExtraTypeEnum.PersonalAccidentInsurance]: CarExtraType.PERSONAL_ACCIDENT_INSURANCE,
  [CarExtaCarExtraTypeEnum.MobilePhone]: CarExtraType.MOBILE_PHONE,
  [CarExtaCarExtraTypeEnum.RoadCongestionPaymentScheme]: CarExtraType.ROAD_CONGESTION_PAYMENT_SCHEME,
  [CarExtaCarExtraTypeEnum.SeatBeltExtension]: CarExtraType.SEAT_BELT_EXTENSION,
  [CarExtaCarExtraTypeEnum.SnowBoardRacks]: CarExtraType.SNOW_BOARD_RACKS,
  [CarExtaCarExtraTypeEnum.SkiBox]: CarExtraType.SKI_BOX,
  [CarExtaCarExtraTypeEnum.SkiRackOnly]: CarExtraType.SKI_RACK_ONLY,
  [CarExtaCarExtraTypeEnum.SkiRackOrBox]: CarExtraType.SKI_RACK_OR_BOX,
  [CarExtaCarExtraTypeEnum.SkiEquippedVehicle]: CarExtraType.SKI_EQUIPPED_VEHICLE,
  [CarExtaCarExtraTypeEnum.SnowChains]: CarExtraType.SNOW_CHAINS,
  [CarExtaCarExtraTypeEnum.SnowPacks]: CarExtraType.SNOW_PACKS,
  [CarExtaCarExtraTypeEnum.SnowTires]: CarExtraType.SNOW_TIRES,
  [CarExtaCarExtraTypeEnum.SpinnerKnob]: CarExtraType.SPINNER_KNOB,
  [CarExtaCarExtraTypeEnum.SurfRack]: CarExtraType.SURF_RACK,
  [CarExtaCarExtraTypeEnum.ScooterTopCase]: CarExtraType.SCOOTER_TOP_CASE,
  [CarExtaCarExtraTypeEnum.SnowWinterTires]: CarExtraType.SNOW_WINTER_TIRES,
  [CarExtaCarExtraTypeEnum.TollPaymentTagPass]: CarExtraType.TOLL_PAYMENT_TAG_PASS,
  [CarExtaCarExtraTypeEnum.SpareTire]: CarExtraType.SPARE_TIRE,
  [CarExtaCarExtraTypeEnum.WheelChairAccessRamp]: CarExtraType.WHEEL_CHAIR_ACCESS_RAMP,
  [CarExtaCarExtraTypeEnum.WheelChair]: CarExtraType.WHEEL_CHAIR,
  [CarExtaCarExtraTypeEnum.WifiAccess]: CarExtraType.WIFI_ACCESS,
};

export enum Transmission {
  UNKNOWN_TRANSMISSION = 0,
  MANUAL_UNSPECIFIED_DRIVE = 1,
  MANUAL_4WD = 2,
  MANUAL_AWD = 3,
  AUTO_UNSPECIFIED_DRIVE = 4,
  AUTO_4WD = 5,
  AUTO_AWD = 6,
  UNRECOGNIZED = -1,
}

export enum CounterLocation {
  UNKNOWN_COUNTER = 0,
  NON_AIRPORT_LOCATION = 1,
  IN_TERMINAL = 2,
  OFF_AIRPORT_RENTAL_SHUTTLE = 3,
  IN_TERMINAL_RENTAL_SHUTTLE = 4,
  ON_AIRPORT_RENTAL_SHUTTLE = 5,
  ON_AIRPORT_AIRPORT_SHUTTLE = 6,
  CALL_FOR_SHUTTLE = 7,
  TWO_SHUTTLES_AIRPORT_AND_RENTAL = 8,
  UNRECOGNIZED = -1,
}

export enum ElectricVehicle {
  UNKNOWN_EV = 0,
  YES = 1,
  NO = 2,
  UNRECOGNIZED = -1,
}

export enum EngineType {
  UNKNOWN_ENGINE = 0,
  PETROL = 1,
  DIESEL = 2,
  ELECTRIC = 3,
  CNG = 4,
  HYBRID = 5,
  HYDROGEN = 6,
  MULTI_FUEL = 7,
  ETHANOL = 8,
  UNRECOGNIZED = -1,
}

export enum AirConditioning {
  UNKNOWN_AC = 0,
  AC_AVAILABLE = 1,
  AC_NOT_AVAILABLE = 2,
  UNRECOGNIZED = -1,
}

export enum FareType {
  PUBLISHED = 0,
  CORPORATE = 1,
  SPOTNANA = 2,
  UNRECOGNIZED = -1,
}

export interface CarExtra {
  /** Type of car extra. */
  type: CarExtraType;
  /** Unit of the rate. */
  rateUnit: CarExtraRateUnit;
  /** Amount of the rate. */
  amount?: Money;
  /** Description of the car extra. */
  text: string;
}

export enum CarExtraRateUnit {
  UNKNOWN_RATE_UNIT = 0,
  DAILY = 1,
  WEEKEND = 2,
  WEEKLY = 3,
  MONTHLY = 4,
  BUNDLED = 5,
  TOTAL = 6,
  UNRECOGNIZED = -1,
}
export interface Vendor {
  /** Vendor code. */
  code: string;
  /** Name of the vendor. */
  name: string;
  /** Vendor email address. */
  email?: string;
  /** Vendor phone number. */
  phone?: PhoneNumber;
}

export interface Amenities {
  /** Number of seat belts in the car. */
  numSeatBelts: number;
  /** Number of large bags that can fit in the car. */
  numLargeBags: number;
  /** Number of small bags that can fit in the car. */
  numSmallBags: number;
  /** Number of seats in the car. */
  numSeats: number;
  /** Number of doors in the car. */
  numDoors: number;
}

/** The CO2 emission details for car. */
export interface CarCo2EmissionDetail {
  /** CO2 emission value in kgs for car. */
  co2EmissionValue: number;
}

export interface CarSpec {
  /** Size and type of the car. */
  type: CarType;
  /** Transmission type of the car, automatic or manual and 4 wheel drive. */
  transmission: Transmission;
  /** Amenities present in the car. */
  amenities?: Amenities;
  /** Car images. */
  imageGroups: ImageGroup[];
  /** Display name of the car. */
  displayName: string;
  /** Fuel type of the car. */
  engineType: EngineType;
  /** Whether the car has air conditioning. */
  airConditioning: AirConditioning;
  /** Car Model Name. */
  modelName: string;
}

/**
 * This message encapsulates whatever we need for booking the car. We should never
 * send this directly to the client. We should always send the serialized string
 * version of this structure.
 */
export interface CarId {
  /** Supplier car code. */
  carCode: string;
  /** Supplier rate code. */
  rateCode: string;
  /** Vendor code of the car. */
  vendorCode: string;
  /** Pickup location details. */
  pickup?: CarIdLocationCode;
  /** Drop off location details. */
  dropOff?: CarIdLocationCode;
  /** Third party supplier for the booking. */
  supplier: ThirdPartySource;
}

export interface CarIdLocationCode {
  /** IATA airport code. */
  locationCode: string;
  /**
   * If a non-airport code is used in the request, then the values in this
   * field will consist of 1 alphabet and 1-3 numeric characters e.g. C001.
   */
  extendedLocationCode: string;
}

/**
 * Vendor details for Limo
 * Note: Separated from Car Vendor Info to avoid unnecessary CAR API testing
 * when making changes to Limo details, which are subject to future updates.
 */
export interface LimoVendorInfo {
  /** Vendor code. */
  code: string;
  /** Name of the vendor. */
  name: string;
  /** Vendor email address. */
  email: string;
}

export interface OperatingSchedule {
  /** List of open, close timings for the vendor */
  interval: DateTimeRange[];
  /** Specifies day of the week for which schedule is valid. */
  dayOfWeek: string;
}

export interface ContactInfo {
  /** Phone number of the vendor */
  phone: string[];
  /** Fax of the vendor */
  fax: string[];
  /** Phone number of the vendor */
  email: string[];
  /** Emergency phone of the vendor */
  emergencyPhone: string[];
}

export interface Location {
  /** Describes where the physical car rental pickup/drop off counter is located. */
  counterLocation: CounterLocation;
  /** Address of the car rental location. */
  address?: PostalAddress;
  /** Coordinates of the car rental location. */
  coordinates?: Latlng;
  /** Operating schedule of the car rental location */
  operatingSchedule?: OperatingSchedule;
  /** Contact info of the rental location */
  contactInfo?: ContactInfo;
  /** Operating schedule of the car rental location */
  weeklyOperatingSchedule?: OperatingSchedule[];
}
