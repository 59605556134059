/* eslint-disable */
import type { Empty } from '../../../google/protobuf/empty';
import type { TravelType } from '../../common/tmc';

export enum EntityType {
  ENTITY_TYPE_UNKNOWN = 0,
  PNR = 1,
  TRIP = 2,
  COMPANY = 3,
  AIR_ITINERARY = 4,
  UNRECOGNIZED = -1,
}

export enum DocumentType {
  DOCUMENT_TYPE_UNKNOWN = 0,
  BOARDING_PASS = 1,
  INVOICE = 2,
  VISA = 3,
  OTHERS = 4,
  CONFIRMATION = 5,
  MISCELLANEOUS = 6,
  UNRECOGNIZED = -1,
}

export interface FlightMetadata {
  /** Unique identifier of the flight. */
  flightId: string;
}

export interface LegMetadata {
  /** Unique identifier of the leg. */
  legId: string;
}

export interface InvoiceMetadata {
  /** Unique identifier of the invoice */
  invoiceNumber: string;
}

export interface PnrMetadata {
  /** Flight metadata against which document is associated. */
  flightMetadata?: FlightMetadata | undefined;
  /** Leg metadata against which document is associated. */
  legMetadata?: LegMetadata | undefined;
  /** Metadata associated with an invoice document.
   * This is also an indicator that the invoice is auto generated (and not user uploaded) by backoffice.
   */
  invoiceMetadata?: InvoiceMetadata;
}

export interface EntityMetadata {
  /** Metadata when document is associated to pnr entity. */
  pnrMetadata?: PnrMetadata | undefined;
  /** Applicable travel segment type, e.g. AIR, HOTEL etc. */
  travelType: TravelType;
}

export interface DocumentMetadata {
  /** Type of document like boarding pass, visa etc. */
  documentType: DocumentType;
  /** Entity type against which the document is to uploaded. */
  entityType: EntityType;
  /** Entity Id for the given entity type. */
  entityId: string;
  /** Metadata for associated entity of document. */
  entityMetadata?: EntityMetadata;
  /** Document name */
  name: string;
  /** Content type of the document. */
  contentType: string;
}

export interface Document {
  /** Object store location of the document. */
  url: string;
  /** Unique identifier of the document */
  documentId: string;
  /** Metadata related to document */
  documentMetadata: DocumentMetadata;
}

export interface UploadDocumentRequest {
  /** Contents of the document to be uploaded. */
  document: Uint8Array;
  /** Metadata related to document */
  documentMetadata?: DocumentMetadata;
  /** Unique identifier of the document */
  documentId: string;
}

export interface UploadDocumentResponse {
  /** Unique identifier of the document */
  documentId: string;
}

export interface GetDocumentRequest {
  /** Unique identifier of the document */
  documentId: string;
}

export interface GetDocumentResponse {
  /** Response containing a document. */
  document?: Document;
}

export interface ListDocumentRequest {
  /** Entity type against which the document is to be fetched. */
  entityType: EntityType;
  /** Entity Id for the given entity type. */
  entityId: string;
}

export interface ListDocumentResponse {
  /** Response containing list of documents. */
  document: Document[];
}

export interface DeleteDocumentRequest {
  /** Unique identifier of the document */
  documentId: string;
}

export interface DocumentService {
  /** Upload a document for a given entity. */
  UploadDocument(request: UploadDocumentRequest): Promise<UploadDocumentResponse>;
  /** Gets the document for a given document id. */
  GetDocument(request: GetDocumentRequest): Promise<GetDocumentResponse>;
  /** Lists the documents for a given entity type and entity id. */
  ListDocument(request: ListDocumentRequest): Promise<ListDocumentResponse>;
  /** Deletes the document for a given document id. */
  DeleteDocument(request: DeleteDocumentRequest): Promise<Empty>;
}
