import { themed } from '@spotnana/blocks/src/utils/styling/themed';
import { flex } from '@spotnana/blocks/src/utils';
import { css } from '@emotion/react';

export const support_menu_style = themed(
  ({ zIndex }) => css`
    z-index: ${zIndex.modal - 1};
    ${flex.init}
    ${flex.align.center}
    position: fixed;
    right: 32px;
    bottom: 32px;
  `,
);

// zIndex.modal value is 2300 which payment modal is using.
// When payment modal is launched, we want it to cover support menu icon
// to avoid traveler selector dropdown in Twilio Chat blocked by payment dialog.
