import { createContext, useContext } from 'react';
import type { ErrorTelemetry, EventTelemetry, SurfaceTelemetry, UserTelemetry } from '../types/telemetryTypes';

export type ITelemetryContext = {
  trackUser: (data: UserTelemetry) => Promise<void>;
  trackEvent: (data: EventTelemetry) => Promise<void>;
  trackError: (data: ErrorTelemetry) => Promise<void>;
  trackSurface: (data: SurfaceTelemetry) => Promise<void>;
};

export const TelemetryContext = createContext<ITelemetryContext>({} as ITelemetryContext);

export function useTelemetry(): ITelemetryContext {
  const context = useContext(TelemetryContext);
  return context;
}
