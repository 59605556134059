import type { ColorSystemOptions } from '@mui/material/styles';
import { InternalBlocksTheme } from '../blocksTheme';
// import { lightColorPalette } from '../ux-specs/color';

/**
 * Overrides for MUIs default pallette
 */
export const makeMuiPalette: (mode: 'dark' | 'light', theme: InternalBlocksTheme) => ColorSystemOptions['palette'] = (
  mode,
  theme,
) => ({
  /**
   * Default MUI primary:
   *  "main": "#1976d2",
   *  "light": "#42a5f5",
   *  "dark": "#1565c0",
   *  "contrastText": "#fff",
   *  "mainChannel": "25 118 210",
   *  "lightChannel": "66 165 245",
   *  "darkChannel": "21 101 192",
   *  "contrastTextChannel": "255 255 255"
   */
  primary: {
    main: theme.palette[mode].bg.brand,
    dark: theme.palette[mode].bg.brandHover,
    light: theme.palette[mode].uxSpec.grey20,
    contrastText: theme.palette[mode].surface.base,
  },
  /**
   * Default MUI secondary, error, warning, info, success all have same shape as MUI primary shape above
   */
  secondary: {
    main: theme.palette[mode].surface.base,
    contrastText: theme.palette[mode].text.brand,
    // border: lightColorPalette.grey20,
  },
  error: {
    main: theme.palette[mode].uxSpec.error,
  },
  info: {
    main: theme.palette[mode].uxSpec.linkBorder,
    // background: lightColorPalette.infoBackground,
  },
  warning: {
    main: theme.palette[mode].uxSpec.warning,
  },
  success: {
    main: theme.palette[mode].uxSpec.success,
  },
  grey: {
    50: undefined,
    100: theme.palette[mode].uxSpec.grey10,
    200: theme.palette[mode].uxSpec.grey20,
    300: theme.palette[mode].uxSpec.grey30,
    400: theme.palette[mode].uxSpec.grey40,
    500: theme.palette[mode].uxSpec.grey50,
    600: theme.palette[mode].uxSpec.grey60,
    /**
     * No longer a need to override with undefined as consumers are using a different theme shape
     * not to be deleted so that we have transparent insight into MUI default shape
     * */
    // 700: undefined,
    // 800: undefined,
    // 900: undefined,
    // A100: undefined,
    // A200: undefined,
    // A400: undefined,
    // A700: undefined,
  },
  background: { paper: theme.palette[mode].bg.disabled, default: theme.palette[mode].surface.base },
  text: {
    primary: theme.palette[mode].text.primary,
    secondary: theme.palette[mode].text.secondary,
    disabled: theme.palette[mode].text.disabled,
  },
});
