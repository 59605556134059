import { AutocompleteOptionType, TAutocompleteOption, AutocompleteSelectionModes } from './Autocomplete.types';

/**
 * Note to anyone wondering what these are and where its used.
 * These are helper utils used to pass the right controlledValue for Autocomplete Component
 * These functions are currently used in Async Autocomplete.
 * For different use cases, view the test file
 * Any doubts contact me - ajose@spotnana.com
 */

/**
 * This function applies selection mode to the options returned from the Autocomplete Component.
 * Autocomplete component requires all options in its value ie. PARENT, CHILD etc.
 * When a selection mode is set, eg. LEAF, then the Component using Autocomplete just requires CHILD and STANDALONE.
 * This function just filters out options based on type.
 */
export function getRevisedSelectedOptions<T extends object>(
  newSelectedOptions: TAutocompleteOption<T>[],
  type: AutocompleteSelectionModes,
) {
  if (type === AutocompleteSelectionModes.SINGLE) {
    // This case enforces the single return value
    if (newSelectedOptions.length > 0) {
      return [newSelectedOptions[0]];
    }
    return newSelectedOptions;
  }
  if (type === AutocompleteSelectionModes.LEAF) {
    return newSelectedOptions.filter(
      (selectedOption) =>
        selectedOption.type === AutocompleteOptionType.CHILD ||
        selectedOption.type === AutocompleteOptionType.STANDALONE,
    );
  }
  // @Aviral, can you complete this in the future? This is just for the completeness of the functionality in case its needed in the future
  if (type === AutocompleteSelectionModes.ROOT) {
    return newSelectedOptions.filter(
      (selectedOption) =>
        selectedOption.type === AutocompleteOptionType.PARENT ||
        selectedOption.type === AutocompleteOptionType.STANDALONE,
    );
  }
  return newSelectedOptions;
}

/* 
Generates a map of parent to number of children for each parent in a list of TAutocompleteOptions
*/
function getParentChildrenCounts<T extends object>(options: TAutocompleteOption<T>[]): Record<string, number> {
  const parentChildrenCount: Record<string, number> = {};
  options.forEach((option) => {
    if (option.type !== AutocompleteOptionType.CHILD || !option.parentValue) {
      return;
    }
    const parentValue = String(option.parentValue);
    if (parentValue in parentChildrenCount) {
      parentChildrenCount[parentValue] += 1;
    } else {
      parentChildrenCount[parentValue] = 1;
    }
  });
  return parentChildrenCount;
}

/**
 * Saturation: Add in options that arent present in the controlled value.
 * Autocomplete component expects controlled value to contain all related options.
 * ie. If there is a parent selected, all of its children should also be in the controlled value.
 * Or if all children are selected, parent option should be in the controlled value
 * With selection modes, the controlled value is going to be a subset of what Autocomplete Component requires.
 * This function prepopulates that missing options from available Options.
 *
 * Use Case:
 *
 * Say for Airport Autocomplete, we have selected Buffalo's 2 airports say A and B. Controlled value from parent would be [A,B] since selection mode is LEAF.
 * Saturation would add in Buffalo so that the controlled value passed on to the Autocomplete component will be [Buffalo, A, B]
 */
export function saturateControlledValue<T extends object>(
  selectedOptions: TAutocompleteOption<T>[],
  availableOptions: TAutocompleteOption<T>[],
  type: AutocompleteSelectionModes,
) {
  if (type === AutocompleteSelectionModes.SINGLE) {
    /* Single Select doesnt need saturation. This case is written for understanding the code and functions similar to ALL
    We consider [] to be unselected state and [Element] as the selected State
    */
    if (selectedOptions.length > 0) {
      return [selectedOptions[0]];
    }
    return selectedOptions;
  }
  if (type === AutocompleteSelectionModes.LEAF) {
    // Get children per parent in availableOptions
    const parentChildrenCount = getParentChildrenCounts(availableOptions);
    // Get children per parent in selectedOptions
    const selectedParentChildrenCount = getParentChildrenCounts(selectedOptions);
    // Now check which selectedParentChildren count matches the available options count
    const parentOptionsToBeAdded = availableOptions.filter((option) => {
      if (option.type !== AutocompleteOptionType.PARENT) {
        return false;
      }

      if (
        selectedParentChildrenCount[option.value] &&
        selectedParentChildrenCount[option.value] === parentChildrenCount[option.value]
      ) {
        return true;
      }
      return false;
    });
    // Add the parents which are meant to be selected here.
    return [...parentOptionsToBeAdded, ...selectedOptions];
  }
  // @Aviral, can you complete this in the future? This is just for the completeness of the functionality in case its needed in the future
  if (type === AutocompleteSelectionModes.ROOT) {
    const parentOptionValues = selectedOptions
      .filter((option) => option.type === AutocompleteOptionType.PARENT)
      .map((option) => String(option.value));
    const childrenOptionsToBeAdded = availableOptions.filter((option) => {
      if (option.type !== AutocompleteOptionType.CHILD) {
        return false;
      }
      if (parentOptionValues.includes(String(option.parentValue))) {
        return true;
      }
      return false;
    });
    return [...childrenOptionsToBeAdded, ...selectedOptions];
  }
  return selectedOptions;
}
