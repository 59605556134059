/* eslint-disable no-restricted-syntax */
import type { SessionStorage } from '../types/sessionStorage';

export const webSessionStorage: SessionStorage = {
  getItem(key) {
    try {
      return window.sessionStorage.getItem(key);
    } catch {
      return null;
    }
  },

  setItem(key, value) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch {
      // do nothing
    }
  },

  removeItem(key) {
    try {
      window.sessionStorage.removeItem(key);
    } catch {
      // do nothing;
    }
  },

  clear() {
    try {
      window.sessionStorage.clear();
    } catch {
      // do nothing;
    }
  },

  getAllItems() {
    try {
      return window.sessionStorage;
    } catch {
      return {};
    }
  },
};
