import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@spotnana/pixel-react/dist/Box';
import * as Icons from '@spotnana/pixel-react/dist/Icons';
import IndeterminateLoader from '@spotnana/pixel-react/dist/IndeterminateLoader';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { AgentTranslationKeys, useEntitySearchAutocompleteQuery, useFeatureFlag } from 'obt-common';
import {
  AutocompleteEntitySearchRequestEntitiesEnum,
  PaginationRequestParams,
  TripBasicInfo,
} from 'obt-common/types/api/v2/obt/model';

import { useHistory, useLocation } from 'react-router';
import { useBlocksTheme } from '@spotnana/blocks/src/utils/styling/useBlocksTheme';
import { parseParams, stringifyParams } from 'obt-common/utils/urlUtils';
import ClickableBox from '../ClickableBox';
import Autocomplete, { AutocompleteProps } from '../../../v2-components/shared/Autocomplete';
import { EntityInfo, isTravelerBasicInfo, isTripBasicInfo } from '../../../app/agent/types';
import EntityAutocompleteOption from './EntityAutocompleteOption';

type EntityAutocompleteProps = AutocompleteProps<EntityInfo>;

const { LOADING, NO_RESULTS_FOUND, SEARCH } = AgentTranslationKeys;

const EntityAutocompleteLoading = (): JSX.Element => {
  const { t } = useTranslation('AGENT');
  return (
    <Box>
      <Box padding="32px" textAlign="center" position="relative">
        <Typography fontWeight="semiBold" as="span">
          {t(LOADING)}
        </Typography>
      </Box>
      <IndeterminateLoader />
    </Box>
  );
};

const EntityAutocompleteNoResults = (): JSX.Element => {
  const { t } = useTranslation('AGENT');
  return (
    <Box padding="32px" textAlign="center" position="relative">
      <Typography fontWeight="semiBold" as="span">
        {t(NO_RESULTS_FOUND)}
      </Typography>
    </Box>
  );
};

const EntityAutocomplete = ({
  active,
  onClose,
  isCompanyTripsPage = false,
  selectedOption,
}: {
  active?: boolean;
  onClose?: VoidFunction;
  isCompanyTripsPage?: boolean;
  selectedOption?: EntityInfo | null;
}): JSX.Element => {
  const { search } = useLocation();
  const { tripType, searchText: searchTextFromUrl } = parseParams(search);

  const [focussed, setFocussed] = useState(false);

  const [searchText, setSearchText] = useState(searchTextFromUrl ?? '');

  useEffect(() => {
    if (searchTextFromUrl) {
      setSearchText(searchTextFromUrl ?? '');
    }
  }, [searchTextFromUrl]);

  const { palette } = useBlocksTheme();
  const isTripSearchEnabled = useFeatureFlag('FE_EVENTS_ENTITY_AUTOCOMPLETE_TRIPS');
  const [value, setValue] = useState<EntityInfo | null>(isCompanyTripsPage && selectedOption ? selectedOption : null);
  const entities: AutocompleteEntitySearchRequestEntitiesEnum[] = [
    AutocompleteEntitySearchRequestEntitiesEnum.Traveler,
    AutocompleteEntitySearchRequestEntitiesEnum.Pnr,
    AutocompleteEntitySearchRequestEntitiesEnum.Trip,
  ];
  let paginationParams: PaginationRequestParams | undefined;
  const filters = undefined;
  if (!isCompanyTripsPage) {
    entities.push(AutocompleteEntitySearchRequestEntitiesEnum.Organization);
  }
  if (isCompanyTripsPage) {
    paginationParams = {
      pageNumber: 0,
      pageSize: 100,
    };
  }

  const { data: options, isLoading } = useEntitySearchAutocompleteQuery(
    searchText,
    entities,
    filters,
    paginationParams,
  );

  const autocompleteOptions: Array<EntityInfo> = [
    ...(options?.travelerBasicInfos ?? []),
    ...(options?.organizationBasicInfos ?? []),
    ...(isTripSearchEnabled ? mergeTripData(options?.tripBasicInfos, options?.trips) : options?.tripBasicInfos ?? []),
  ];

  const getOptionLabel = (entity: EntityInfo): string => {
    if (isTravelerBasicInfo(entity)) {
      return entity.fullName;
    }
    if (isTripBasicInfo(entity)) {
      return entity.tripName ?? '';
    }
    return entity.orgName;
  };

  const { t } = useTranslation('AGENT');
  const { t: tt } = useTranslation('WEB');
  const history = useHistory();

  const label = isCompanyTripsPage ? tt('Search by name, email or trip ID/PNR ID') : t(SEARCH);

  const isCompanyTripsPageV3FeatureEnabled = useFeatureFlag('FE_TMC_COMPANY_TRIPS_V3');
  const isNewCompanyTripPage = isCompanyTripsPage && isCompanyTripsPageV3FeatureEnabled;
  const clearInput = (): void => {
    setSearchText('');
    setValue(null);
    if (isNewCompanyTripPage) {
      history.push({
        pathname: '/company-trips',
        search: stringifyParams({
          tripType,
        }),
      });
    } else if (isCompanyTripsPage && selectedOption) {
      history.push({
        pathname: '/company-trips',
        search: stringifyParams({
          applyUserFilter: false,
        }),
      });
    }
  };

  const getContainerBoxProps: EntityAutocompleteProps['getContainerBoxProps'] = () => ({
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    right: 0,
    boxShadow: 'none',
    ...(isCompanyTripsPage && { width: '100%' }),
  });

  const getInputStylingProps: EntityAutocompleteProps['getInputStylingProps'] = (popupOpen: boolean) => ({
    containerStyles: {
      backgroundColor: 'bg.white',
      border: focussed ? `1px solid ${palette.border.active}` : '1px solid border.regular',
      boxShadow: popupOpen ? '0 3px 15px rgba(179, 191, 231, 0.15)' : undefined,
      marginRight: 0,
      alignSelf: 'flex-end',
      marginBottom: popupOpen ? '16px' : undefined,
    },
    hideLabel: true,
    iconLeft: <Icons.Search width={24} height={24} primaryColor="#333" />,
    iconRight: searchText ? (
      <ClickableBox p="2px" onClick={clearInput}>
        <Icons.Close width={12} height={12} primaryColor="#333" />
      </ClickableBox>
    ) : null,
    label,
    placeholder: label,
    variation: 'small' as const,
  });

  const getPopoverProps: EntityAutocompleteProps['getPopoverProps'] = (popupOpen: boolean) => ({
    style: {
      marginTop: '-1rem',
    },
    anchorElement: null,
  });

  const onInputChange: EntityAutocompleteProps['onInputChange'] = (_event, updatedValue): void => {
    setSearchText(updatedValue);
  };

  return (
    <Box height="36px" width="100%">
      <Autocomplete<EntityInfo>
        data-testid="entity-search-autocomplete"
        getContainerBoxProps={getContainerBoxProps}
        getInputStylingProps={getInputStylingProps}
        getPopoverProps={getPopoverProps}
        getOptionLabel={getOptionLabel}
        id="entity-search-autocomplete"
        inputValue={searchText}
        loading={isLoading}
        onInputChange={onInputChange}
        onSelection={setValue}
        options={autocompleteOptions}
        renderLoadingState={EntityAutocompleteLoading}
        renderNoResultsState={EntityAutocompleteNoResults}
        renderOption={EntityAutocompleteOption}
        value={value}
        isCompanyTripsPage={isCompanyTripsPage}
        active={active}
        onDeactivate={onClose}
        onActivate={makeSetFocussedTrue(setFocussed)}
      />
    </Box>
  );
};

// to mitigate sonar cov issue
export function makeSetFocussedTrue(fn: (value: boolean) => void) {
  return () => fn(true);
}

export default EntityAutocomplete;

export function mergeTripData(
  tripBasicInfos: TripBasicInfo[] | undefined /* istanbul ignore next: default case */ = [],
  trips: TripBasicInfo[] | undefined /* istanbul ignore next: default case */ = [],
) {
  const tripBasicInfosMap = new Map(tripBasicInfos.map((trip) => [trip.tripId, trip]));

  const tripsMergedWithTripBasicInfos = trips.map((tripInfo) => {
    const tripBasicInfo = tripBasicInfosMap.get(tripInfo.tripId);
    tripBasicInfosMap.delete(tripInfo.tripId);
    return {
      ...tripInfo,
      ...tripBasicInfo,
    };
  });

  return [...Array.from(tripBasicInfosMap.values()), ...tripsMergedWithTripBasicInfos];
}
