/* istanbul ignore file */

// direct export of MUI component
// https://mui.com/material-ui/react-grid2/

import Grid2, { Grid2Props } from '@mui/material/Unstable_Grid2';
import { Spacings, getFoundationVar } from '../GlobalStyles/globalStylesUtils';

type AllowedNumberConfig = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

type BreakpointArg = AllowedNumberConfig | 'grow' | 'fitContent';

type AllowedSpacing = '0.25' | '0.5' | '0.75' | '1' | '1.25' | '1.5' | '1.75' | '2';

type AllowedGaps = Spacings extends `spacing-${infer Size}` ? Size : never;

type TLayoutInternalProps = {
  mobile?: BreakpointArg;
  mobileOffset?: AllowedNumberConfig;
  desktop?: BreakpointArg;
  desktopOffset?: AllowedNumberConfig;
  large?: BreakpointArg;
  largeOffset?: AllowedNumberConfig;
  tablet?: BreakpointArg;
  tabletOffset?: AllowedNumberConfig;
  /**
   * @deprecated
   * Please use pixelSpacing instead
   */
  spacing?: AllowedSpacing;
  /**
   * @deprecated
   * Please use pixelRowSpacing instead
   */
  rowSpacing?: AllowedSpacing;
  /**
   * @deprecated
   * Please use pixelColumnSpacing instead
   */
  columnSpacing?: AllowedSpacing;
  gap?: AllowedGaps;
  rowGap?: AllowedGaps;
  columnGap?: AllowedGaps;
} & Pick<Grid2Props, 'children' | 'container'> &
  React.HTMLAttributes<HTMLDivElement>;

type TLayoutProps = Omit<TLayoutInternalProps, 'container'>;

const LayoutInternal: React.FC<TLayoutInternalProps> = ({
  children,
  container,
  desktop,
  desktopOffset,
  large,
  largeOffset,
  mobile,
  mobileOffset,
  spacing,
  tablet,
  tabletOffset,
  rowSpacing,
  columnSpacing,
  gap,
  rowGap,
  columnGap,
  ...restProps
}) => {
  const finalSpacing = getFinalSpacing(gap, spacing);
  const finalRowSpacing = getFinalSpacing(rowGap, rowSpacing);
  const finalColumnSpacing = getFinalSpacing(columnGap, columnSpacing);

  return (
    <Grid2
      container={container}
      rowSpacing={finalRowSpacing}
      columnSpacing={finalColumnSpacing}
      spacing={finalSpacing}
      mobile={mobile ? breakpointArgToMui(mobile) : undefined}
      mobileOffset={mobileOffset ? Number(mobileOffset) : undefined}
      tablet={tablet ? breakpointArgToMui(tablet) : undefined}
      tabletOffset={tabletOffset ? Number(tabletOffset) : undefined}
      desktop={desktop ? breakpointArgToMui(desktop) : undefined}
      desktopOffset={desktopOffset ? Number(desktopOffset) : undefined}
      large={large ? breakpointArgToMui(large) : undefined}
      largeOffset={largeOffset ? Number(largeOffset) : undefined}
      {...restProps}
    >
      {children}
    </Grid2>
  );
};

export type TLayoutRowProps = Omit<TLayoutProps, 'container'>;
export type TLayoutColProps = Omit<TLayoutProps, 'container' | 'spacing'>;

export const Layout = {
  Row: (props: TLayoutRowProps) => <LayoutInternal {...props} container />,
  Col: (props: TLayoutColProps) => <LayoutInternal {...props} />,
};

function breakpointArgToMui(arg: BreakpointArg) {
  // eslint-disable-next-line no-nested-ternary
  return arg === 'fitContent' ? 'auto' : arg === 'grow' ? true : Number(arg);
}

function getFinalSpacing(gap?: AllowedGaps, spacing?: AllowedSpacing): string | undefined {
  if (gap !== undefined) {
    return `var(${getFoundationVar(`spacing-${gap}`)})`;
  }
  if (spacing !== undefined) {
    return `${spacing}rem`;
  }
  return undefined;
}
