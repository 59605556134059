import { css } from '@emotion/react';
import { themed } from '../utils/styling/themed';

export const popover_styles = themed(
  ({ palette: { inputs, border, surface } }) => css`
    z-index: 50;
    outline: none;
    display: flex;
    max-height: max-content;
    flex-direction: column;
    overflow: auto;
    overscroll-behavior: contain;
    border: 1px solid ${border.medium};
    border-radius: ${border.radiusRegular};
    background-color: ${surface.base};

    &:focus-visible {
      border: 1px solid ${inputs.borderFocus};
      outline: 1px solid ${inputs.borderFocus};
    }

    &.has-header {
      max-height: 30rem;
    }
  `,
);
