import { PropsWithChildren, FC } from 'react';

import { adornment_wrapper_left_control, adornment_wrapper_right_control } from './slots.styles';
import { clsx } from '../utils';

interface AdornmentWrapperProps {
  variant: 'left' | 'right';
  className?: string;
}

const adornmentWrapperVariants = {
  left: adornment_wrapper_left_control,
  right: adornment_wrapper_right_control,
};

export const AdornmentWrapper: FC<PropsWithChildren<AdornmentWrapperProps>> = ({ className, children, variant }) => {
  return (
    <div className={clsx(className, `BlocksInput-AdornmentWrapper-${variant}`)} css={adornmentWrapperVariants[variant]}>
      {children}
    </div>
  );
};
