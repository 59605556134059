/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FlightCoupon
 */
export interface FlightCoupon {
    /**
     * Index of leg to which this coupon belongs
     * @type {number}
     * @memberof FlightCoupon
     */
    legIdx?: number;
    /**
     * Index of flight in it\'s leg to which this coupon belongs
     * @type {number}
     * @memberof FlightCoupon
     */
    flightIdx?: number;
    /**
     * Flight status
     * @type {string}
     * @memberof FlightCoupon
     */
    status?: FlightCouponStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FlightCouponStatusEnum {
    Unknown = 'UNKNOWN',
    AirportControl = 'AIRPORT_CONTROL',
    Lifted = 'LIFTED',
    CheckedIn = 'CHECKED_IN',
    Exchanged = 'EXCHANGED',
    Flown = 'FLOWN',
    NotFlown = 'NOT_FLOWN',
    Refunded = 'REFUNDED',
    Voided = 'VOIDED',
    Printed = 'PRINTED',
    IrregularOperations = 'IRREGULAR_OPERATIONS',
    PrintExchange = 'PRINT_EXCHANGE',
    PaperTicket = 'PAPER_TICKET',
    Suspended = 'SUSPENDED'
}



