import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Warning2 = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 20 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.23642 17.2061C0.811107 17.2061 -0.0692706 15.6512 0.664048 14.429L8.42753 1.48987C9.13974 0.302855 10.8601 0.302855 11.5723 1.48987L19.3358 14.429C20.0691 15.6512 19.1887 17.2061 17.7634 17.2061H2.23642ZM9.9999 10.7882C9.49354 10.7882 9.08305 10.3777 9.08305 9.87136V6.81522C9.08305 6.30886 9.49354 5.89838 9.9999 5.89838C10.5063 5.89838 10.9167 6.30886 10.9167 6.81522V9.87136C10.9167 10.3777 10.5063 10.7882 9.9999 10.7882ZM9.99273 14.15C10.3371 14.15 10.6281 14.0335 10.8658 13.8006C11.1035 13.5676 11.2224 13.279 11.2224 12.9347C11.2224 12.5903 11.1059 12.2993 10.873 12.0616C10.64 11.8239 10.3514 11.7051 10.0071 11.7051C9.66272 11.7051 9.37169 11.8215 9.13399 12.0544C8.89629 12.2874 8.77744 12.576 8.77744 12.9203C8.77744 13.2647 8.8939 13.5557 9.12683 13.7934C9.35977 14.0311 9.64841 14.15 9.99273 14.15Z", fill: themeEnrichedPrimaryColor })));
};
Warning2.defaultProps = defaultIconProps;
export default Warning2;
