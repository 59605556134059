// Generated using yarn generate command
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { clsx } from '../utils';
import { ROOT_CLASS, bgVariants, borderRadiusStyles, borders, shadows } from './Surface.styles';

export type TSurfaceProps = {
  variant: 'base' | 'background' | 'menu' | 'background-hover';
  shadow?: 'card' | 'component' | 'scroll-top' | 'scroll-bottom';
  border?: 'none' | 'light' | 'medium' | 'dark';
  borderRadius?: 'flat' | 'small' | 'standard' | 'outer' | 'full';
} & HTMLAttributes<HTMLDivElement>;

export const Surface = forwardRef<HTMLDivElement, TSurfaceProps>(function Surface(props, ref) {
  const { children, className, variant, shadow, border = 'none', borderRadius = 'flat', ...otherProps } = props;

  const classes = clsx(
    // variant
    `${ROOT_CLASS}-variant-${variant}`,
    // border
    `${ROOT_CLASS}-border-${border}`,
    `${ROOT_CLASS}-borderRadius-${borderRadius}`,
    // shadow
    !!shadow && `${ROOT_CLASS}-shadow-${shadow}`,
    // final class override
    className,
  );

  return (
    <div
      ref={ref}
      css={[bgVariants, borders, borderRadiusStyles, shadows]}
      className={classes}
      data-testid="blocks-Surface"
      {...otherProps}
    >
      {children}
    </div>
  );
});
