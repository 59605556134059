import { SelectStore, SelectArrow } from '@ariakit/react';
import clsx from 'clsx';
import { Icon } from '../Icon';

const makeArrowIcon = (open: boolean) => () =>
  <Icon className={clsx('BlocksSelect-Arrow')} name={open ? ('ChevronUp' as const) : ('ChevronDown' as const)} />;

export function Arrow({ store }: { store: SelectStore<string | string[]> }) {
  const open = store.useState('open');

  return <SelectArrow render={makeArrowIcon(open)} />;
}
