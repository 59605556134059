import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CovidVaccine = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 128 128" },
        React.createElement("path", { d: "M72 47.2V80C72 84.4 68.4 88 64 88C59.6 88 56 84.4 56 80V44H71.2C71.2 43.6 71.2 43.2 71.2 42.4C71.2 40 71.6 38 72.8 36H48V80C48 88.8 55.2 96 64 96C72.8 96 80 88.8 80 80V53.6C76.4 52.8 73.6 50.4 72 47.2Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M74 60.4H66C64.8 60.4 63.6 59.2 63.6 58C63.6 56.8 64.8 55.6 66 55.6H74C75.2 55.6 76.4 56.8 76.4 58C76.4 59.2 75.2 60.4 74 60.4Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M68 88H60V104H68V88Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M76 20H52C50.8 20 50 20.8 50 22C50 23.2 50.8 24 52 24H62V40H66V24H76C77.2 24 78 23.2 78 22C78 20.8 77.2 20 76 20Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M74 76.4H66C64.8 76.4 63.6 75.2 63.6 74C63.6 72.8 64.8 71.6 66 71.6H74C75.2 71.6 76.4 72.8 76.4 74C76.4 75.2 75.2 76.4 74 76.4Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M82.4 27.2C76.4 27.2 70.8 30.8 68.4 36C67.6 38 66.8 40 66.8 42.4C66.8 42.8 66.8 43.2 66.8 44C67.6 48 69.2 51.2 72 54C74 56 76.8 57.6 80 58C80.8 58 81.6 58.4 82.4 58.4C90.8 58.4 98 51.6 98 42.8C98 34 91.2 27.2 82.4 27.2ZM82.4 54C81.6 54 80.8 53.9999 80 53.5999C76.4 52.7999 73.6 50.4 72 47.2C71.6 46 71.2 45.2 71.2 44C71.2 43.6 71.2 43.2 71.2 42.4C71.2 40 71.6 38 72.8 36C74.8 32.8 78.4 31.2 82.4 31.2C88.8 31.2 94 36.4 94 42.8C94 48.8 88.8 54 82.4 54Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M82.8 48.8L78.4 44.4C77.6 43.6 77.6 42 78.4 41.2C79.2 40.4 80.8 40.4 81.6 41.2L82.4 42L86 38.4C86.8 37.6 88.4 37.6 89.2 38.4C90 39.2 90 40.8 89.2 41.6L82.8 48.8Z", fill: themeEnrichedPrimaryColor })));
};
CovidVaccine.defaultProps = defaultIconProps;
export default CovidVaccine;
