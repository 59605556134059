import { ExpensePartnerDetail } from '@spotnana/types/openapi/models/expense-partner-detail';

export const generateExpensePartnersLinks = (expensePartnersDetails: ExpensePartnerDetail[], expenseLabel: string) => {
  const expensePartnersCount = expensePartnersDetails.length ?? 0;

  if (!expensePartnersCount) {
    return [];
  }

  const expenseOption = {
    label: expenseLabel,
    route: '/',
    href: expensePartnersDetails[0].url ?? '',
    roles: [],
    testId: 'expense',
    hidden: !expensePartnersCount,
  };

  if (expensePartnersCount === 1) {
    return [expenseOption];
  }

  const links = expensePartnersDetails.map((partner) => ({
    label: partner.displayName,
    route: '/',
    href: partner.url,
    roles: [],
    testId: `expense-${partner.partner}`,
  }));

  return [{ ...expenseOption, href: undefined, header: 1 }, ...links];
};
