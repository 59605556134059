import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Office = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { "data-testid": "office-svg-icon", width: width, height: height, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.188 6.5v36.548H5.67l-.15.006a2 2 0 00.15 3.994h36.686l.149-.005a2 2 0 00-.15-3.995h-1.882V6.5l-.006-.15a2 2 0 00-1.994-1.85H9.188l-.15.005A2 2 0 007.188 6.5zm29.285 2v34.548h-3.004V33.73a1 1 0 00-1-1H15.53a1 1 0 00-1 1v9.318h-3.342V8.5h25.285zm-5.005 34.548H16.53V34.73h14.94v8.318z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.47 14.719a1 1 0 01.116 1.993l-.116.007H15.53a1 1 0 01-.116-1.993l.116-.007H32.47zm0 5.14a1 1 0 01.116 1.994l-.116.006H15.53a1 1 0 01-.116-1.993l.116-.006H32.47zm1 6.141a1 1 0 00-1-1H15.53l-.116.007A1 1 0 0015.53 27H32.47l.116-.007A1 1 0 0033.47 26z", fill: themeEnrichedPrimaryColor })));
};
Office.defaultProps = defaultIconProps;
export default Office;
