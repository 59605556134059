import type { FareAmount, RateOption } from '../../../types/api/v1/obt/air/air_search_response';
import { MoneyUtil } from '../../Money';

export function getLegCheapestPublishedBaseFareForFareAmounts(
  totalLegFare?: FareAmount,
  totalCheapestLegFare?: FareAmount,
): MoneyUtil {
  const base = totalLegFare?.base;
  const legCheapestPublishedBaseFare = totalCheapestLegFare?.base;

  if (!legCheapestPublishedBaseFare && base) {
    return MoneyUtil.zeroMoneyWithOriginal(base.convertedCurrency || 'USD', base.currencyCode);
  }

  return MoneyUtil.parse(legCheapestPublishedBaseFare);
}

export function getLegCheapestPublishedTaxFareForFareAmounts(
  totalLegFare?: FareAmount,
  totalCheapestLegFare?: FareAmount,
): MoneyUtil {
  const tax = totalLegFare?.tax;
  const cheapestPublishedTaxFare = totalCheapestLegFare?.tax;

  if (!cheapestPublishedTaxFare && tax) {
    return MoneyUtil.zeroMoneyWithOriginal(tax.convertedCurrency || 'USD', tax.currencyCode);
  }
  return MoneyUtil.parse(cheapestPublishedTaxFare);
}

export function getTotalLegCheapestPublishedFareForRateOption(rateOption: RateOption): MoneyUtil {
  const { totalLegFare, totalCheapestLegFare } = rateOption.paxInfo[0].fareComponents[0].legInfo[0];
  return getLegCheapestPublishedBaseFareForFareAmounts(totalLegFare, totalCheapestLegFare).add(
    getLegCheapestPublishedTaxFareForFareAmounts(totalLegFare, totalCheapestLegFare),
  );
}
