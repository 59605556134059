import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Cost = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.742 32.368v1.723h-.955v-1.73c-1.755-.085-3.153-.56-4.195-1.425-1.041-.865-1.572-2.145-1.592-3.84h3.535c.035.651.252 1.162.652 1.532.4.37.934.593 1.6.668V25.94l-1.133-.269c-1.318-.303-2.358-.794-3.121-1.473-.764-.678-1.143-1.597-1.138-2.755-.005-.895.222-1.684.679-2.368.457-.683 1.092-1.23 1.905-1.64.813-.41 1.75-.65 2.808-.72V15h.955v1.708c1.113.044 2.079.272 2.897.682.818.41 1.451.966 1.901 1.667.45.7.68 1.511.69 2.43h-3.535c-.044-.496-.23-.894-.559-1.192-.328-.299-.793-.475-1.394-.53v3.162l1.021.231c1.507.333 2.661.878 3.464 1.633.803.756 1.207 1.743 1.212 2.961-.005 1.402-.508 2.508-1.51 3.319-1.002.81-2.398 1.242-4.187 1.297zM22.62 22.111c.281.236.67.428 1.168.578v-2.901c-.523.074-.914.237-1.175.488a1.246 1.246 0 00-.399.914c-.01.378.126.685.407.92zm2.12 7.2v-3.14c.667.18 1.158.39 1.474.634.315.244.476.557.48.94-.004.422-.177.774-.518 1.055-.34.28-.819.451-1.435.51z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.5 2.5v2.56a18.924 18.924 0 0111.935 5.505A18.929 18.929 0 0142.974 23H45.5a1.5 1.5 0 110 3h-2.605a18.922 18.922 0 01-5.46 11.435A18.924 18.924 0 0125.5 42.941V45.5a1.5 1.5 0 01-3 0v-2.56a18.924 18.924 0 01-11.934-5.505c-3.2-3.2-5.02-7.259-5.46-11.435H2.5a1.5 1.5 0 110-3h2.527a18.928 18.928 0 015.54-12.435A18.924 18.924 0 0122.5 5.06V2.5a1.5 1.5 0 013 0zm9.238 32.238c5.858-5.858 5.858-15.356 0-21.214-5.858-5.857-15.355-5.857-21.213 0-5.858 5.858-5.858 15.356 0 21.214 5.858 5.858 15.355 5.858 21.213 0z", fill: themeEnrichedPrimaryColor })));
};
Cost.defaultProps = defaultIconProps;
export default Cost;
