import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const RightUp = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M10.5 7.462L7.774 4.735 2.009 10.5 0 8.491 5.765 2.726 3.038 0 10.5 0z", transform: "translate(6.75 6.75)" })));
};
RightUp.defaultProps = defaultIconProps;
export default RightUp;
