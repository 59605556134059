import { useBlocksTheme } from '@spotnana/blocks/src/utils/styling/useBlocksTheme';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import { AgentAvailabilityStatus, TaskAgentStatus } from 'obt-common';

export const availabilityStatusColorsMap: { [key in AgentAvailabilityStatus]?: string } = {
  [AgentAvailabilityStatus.OnBreak]: spotnanaTheme.colors.text.gold,
  [AgentAvailabilityStatus.Available]: spotnanaTheme.colors.ui.success,
  [AgentAvailabilityStatus.Offline]: spotnanaTheme.colors.ui.error,
};

export const useTaskStatusColors = () => {
  const { palette } = useBlocksTheme();
  const taskStatusStyleMap: Record<TaskAgentStatus, { baseColor?: string; bgColor?: string; timeColor?: string }> = {
    UNKNOWN: {},
    OPEN: {
      baseColor: palette.bg.success,
      bgColor: palette.bg.successSubtle,
      timeColor: palette.surface.base,
    },
    ON_HOLD: {
      baseColor: palette.bg.error,
      bgColor: palette.bg.warningSubtle,
      timeColor: palette.surface.base,
    },
    ACTIVE: {
      baseColor: palette.surface.base,
      bgColor: palette.bg.success,
      timeColor: palette.text.success,
    },
    SCHEDULED: {
      baseColor: palette.text.focus,
      bgColor: palette.bg.infoSubtle,
      timeColor: palette.text.inverse,
    },
    COMPLETED: {
      baseColor: palette.text.secondary,
      bgColor: palette.surface.background,
    },
  };
  return taskStatusStyleMap;
};
