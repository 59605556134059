import type { ITraveler } from '../../types/traveler';
import { IAirPassengerTypeEnum } from '../../types/flight';
import type {
  IAirSearchFormPaxInfo,
  IGetAncillarySeatRequestParamsReturn,
  IUseAirSeatmapAncillaryQueryWithRegistrarProps,
  IGetAirAncillariesRequestInput,
} from '../../types/flight';

/**
 * Returns true if for any pax, userId exists (i.e. traveler is selected), but loyalty is not initialized
 */
function getIfAnyPassengerInvalid(passengers: IAirSearchFormPaxInfo[]) {
  return passengers.some((pax) => !!pax.userId && !pax.isLoyaltyInitialized);
}

function getTravelerForUserId(userId: string, travelersResponse: (ITraveler | undefined)[]): ITraveler | undefined {
  return travelersResponse.find((t) => t?.userOrgId.userId?.id === userId);
}

function getIfAllPassengersSelected(
  passengers: IAirSearchFormPaxInfo[],
  travelersResponse: (ITraveler | undefined)[],
): boolean {
  return passengers.every((pax) => {
    const { userId, isLoyaltyInitialized } = pax;

    const hasUserIdAndLoyalty = !!userId && !!isLoyaltyInitialized;
    if (!hasUserIdAndLoyalty) {
      return false;
    }

    const traveler = getTravelerForUserId(userId, travelersResponse);
    return !!traveler;
  });
}

export function getSeatAncillaryRequestParams({
  searchId,
  rateOptionId,
  passengers,
  travelersResponse,
  registrarUserResponse,
}: IUseAirSeatmapAncillaryQueryWithRegistrarProps): IGetAncillarySeatRequestParamsReturn {
  const isAnyPassengerInvalid = getIfAnyPassengerInvalid(passengers);
  const areAllPassengersSelected = getIfAllPassengersSelected(passengers, travelersResponse);

  if (passengers.length < 1 || isAnyPassengerInvalid) {
    return {
      requestParams: undefined,
      isAnyPassengerInvalid,
    };
  }

  let requestParams: IGetAirAncillariesRequestInput | undefined;

  if (areAllPassengersSelected) {
    const passengersWithUserOrgId = passengers
      .map((pax) => {
        // Typecasting to `as string` and `as ITraveler` is added because these
        // are already checked above as part of `getIfAllPassengersSelected`.
        const userId = pax.userId as string;
        const traveler = getTravelerForUserId(userId, travelersResponse) as ITraveler;

        return {
          ...pax,
          userOrgId: traveler.userOrgId,
        };
      })
      .filter((pax) => pax.paxType !== IAirPassengerTypeEnum.INFANT_ON_LAP);

    requestParams = {
      searchId,
      rateOptionId,
      passengers: passengersWithUserOrgId,
    };
  } else if (registrarUserResponse) {
    // For adhoc booking, only pass paxType as ADULT and nonProfileUserInfo
    // using registrar
    const passengersWithBookingOwner = [
      {
        ...passengers[0],
        bookingOwner: registrarUserResponse,
      },
    ];

    requestParams = {
      searchId,
      rateOptionId,
      passengers: passengersWithBookingOwner,
    };
  }

  return { requestParams, isAnyPassengerInvalid };
}
