/* eslint-disable @typescript-eslint/ban-ts-comment */
declare global {
  interface Window {
    env: any;
    // temporary addition of vite env variables from CI into a different object, needs to be removed and put in env object soon
    venv: any;
  }
}

export const env: Record<string, string | undefined> = {
  // @ts-ignore
  ...(process.env || {}),
  // @ts-ignore
  ...((typeof window !== 'undefined' && window?.env) || {}),
  // @ts-ignore
  ...((typeof window !== 'undefined' && window?.venv) || {}),
};
