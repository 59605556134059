/* eslint-disable @typescript-eslint/no-explicit-any */
const SvgTypeUnderline = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    className="type-underline_svg__bi type-underline_svg__bi-type-underline"
    {...props}
  >
    <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z" />
  </svg>
);
export default SvgTypeUnderline;
