import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Phone = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 11 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M5.99739 3.97259L6.32004 2.62818C6.5615 1.62212 5.69388 0.697726 4.67456 0.875C3.35241 1.10494 2.25907 2.05728 1.91466 3.35433C0.91201 7.13038 0.835263 9.48435 1.85086 13.0392C2.22193 14.338 3.3437 15.2685 4.67456 15.5C5.69388 15.6773 6.5615 14.7529 6.32004 13.7468L5.99588 12.3961C5.82575 11.6873 5.19184 11.1875 4.46287 11.1875C4.09353 11.1875 3.77218 10.9306 3.6981 10.5688C3.3098 8.67239 3.29655 7.63185 3.69167 5.79803C3.76893 5.43946 4.08961 5.1875 4.4564 5.1875C5.18917 5.1875 5.82638 4.68512 5.99739 3.97259Z", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.125" }),
        React.createElement("path", { d: "M4.5 0.875L3.75 3.5", stroke: themeEnrichedPrimaryColor, strokeWidth: "0.375", strokeLinecap: "round" }),
        React.createElement("path", { d: "M3.75 12.875L4.5 15.5", stroke: themeEnrichedPrimaryColor, strokeWidth: "0.375", strokeLinecap: "round" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.25508 5.26393C8.52736 5.41351 8.62683 5.75549 8.47726 6.02777C8.0819 6.74747 7.87474 7.55537 7.875 8.37651C7.87526 9.19765 8.08294 10.0054 8.47875 10.7249C8.6285 10.9971 8.52924 11.3391 8.25706 11.4888C7.98487 11.6386 7.64283 11.5393 7.49308 11.2672C7.00592 10.3817 6.75032 9.3875 6.75 8.37687C6.74968 7.36623 7.00465 6.37189 7.49124 5.48611C7.64082 5.21383 7.9828 5.11436 8.25508 5.26393Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.6904 4.54225C10.9627 4.69183 11.0621 5.03381 10.9126 5.30609C10.3956 6.24723 10.1247 7.30372 10.125 8.37752C10.1253 9.45132 10.3969 10.5076 10.9145 11.4485C11.0643 11.7206 10.965 12.0627 10.6928 12.2124C10.4206 12.3622 10.0786 12.2629 9.92885 11.9907C9.3199 10.8839 9.0004 9.64117 9 8.37788C8.9996 7.11458 9.31831 5.87166 9.92655 4.76443C10.0761 4.49215 10.4181 4.39268 10.6904 4.54225Z", fill: themeEnrichedPrimaryColor })));
};
Phone.defaultProps = defaultIconProps;
export default Phone;
