/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The deck level of the rail.
 * @export
 * @enum {string}
 */

export const DeckLevel = {
  UpperDeck: 'UPPER_DECK',
  LowerDeck: 'LOWER_DECK',
} as const;

export type DeckLevel = (typeof DeckLevel)[keyof typeof DeckLevel];
