// This is required for any component using react-dates to work properly
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import defaultTheme from '../utils/themes';
import defaultGlobalStyles from './defaultGlobalStyles';
import { CustomizationProvider } from '../CustomizationProvider';
const HOC = ({ theme = defaultTheme, globalStyles = defaultGlobalStyles, config, children, }) => {
    const GlobalStyle = createGlobalStyle(globalStyles);
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(CustomizationProvider, { config: config },
            React.createElement(GlobalStyle, null),
            children)));
};
/**
 * @deprecated
 */
export default HOC;
