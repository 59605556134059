import type { PaxInfo } from '../../../types/api/v1/obt/air/air_search_response';
import { MoneyUtil } from '../../Money';

export function getBaseLegFareForPaxInfo(paxInfo: PaxInfo[], legIndex = 0): MoneyUtil {
  const base = paxInfo[0].fareComponents[0].legInfo[legIndex].totalLegFare?.base;
  return MoneyUtil.parse(base);
}

export function getTaxLegFareForPaxInfo(paxInfo: PaxInfo[], legIndex = 0): MoneyUtil {
  const tax = paxInfo[0].fareComponents[0].legInfo[legIndex].totalLegFare?.tax;
  return MoneyUtil.parse(tax);
}

export function getTotalLegFareForPaxInfo(paxInfo: PaxInfo[], legIndex = 0) {
  return getBaseLegFareForPaxInfo(paxInfo, legIndex).add(getTaxLegFareForPaxInfo(paxInfo, legIndex));
}
