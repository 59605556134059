import dateUtil from '../dateUtil';
import { dateFormats } from '../../constants/common';

const convertDateFormat = (date: string, dateFormat1: string, dateFormat2: string): string => {
  let formatFrom: string | string[] = dateFormat1;

  switch (dateFormat1) {
    case dateFormats.ISO:
      formatFrom = [dateFormats.ISO, dateFormats.ISO_WO_SECONDS, dateFormats.ISO_SHORT];
      break;
    case dateFormats.DAY_DATE_YEAR:
      formatFrom = [dateFormats.DAY_DATE_YEAR, dateFormats.DAY_DATE_YEAR_WO_DAY];
      break;
    default:
      break;
  }

  return dateUtil(date, formatFrom).format(dateFormat2);
};

export default convertDateFormat;
