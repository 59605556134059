import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const EconomyPlus = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.42999 17.92H11.66V20.33C11.66 20.69 11.37 20.99 11 20.99H8.09C7.73 20.99 7.42999 20.7 7.42999 20.33V17.92Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.93999 13.91L6.02999 13.79L7.85999 11.53L8.96999 10.18C9.07999 10.03 9.24999 9.94 9.42999 9.92H13.18C13.54 9.92 13.84 10.21 13.84 10.58C13.84 10.92 13.57 11.21 13.23 11.24H9.81999L9.15999 12.01L9.13999 12.03L7.18999 14.35L6.90999 14.69C6.79999 14.83 6.64999 14.91 6.47999 14.92C6.29999 14.92 6.15 14.88 6.02 14.76C5.76 14.54 5.74 14.16 5.95 13.9L5.93999 13.91Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.25 15.07V16.78C15.25 17.14 14.96 17.44 14.6 17.44H7.43C5.44 17.44 3.35 16.87 3.21 13.55L2.03 9.64C2.01 9.57 2 9.51 2 9.44V5.16C2 3.96 2.05001 3 3.24001 3C4.43001 3 4.43 3.93 4.43 5.13V9.35L5.83 13.27L5.56001 13.61C5.18001 14.08 5.24 14.75 5.69 15.15C5.89 15.33 6.14 15.41 6.4 15.42H6.52C6.82 15.39 7.09 15.25 7.29 15.02L7.84 14.36H14.62C14.97 14.36 15.27 14.7 15.27 15.07H15.25Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.83 15.87C20.8 15.49 20.48 15.18 20.09 15.18C19.7 15.18 19.35 15.51 19.35 15.92V17.17H18.05C17.66 17.2 17.36 17.52 17.36 17.91C17.36 18.3 17.69 18.65 18.1 18.65H19.35V19.95C19.38 20.33 19.7 20.64 20.09 20.64C20.48 20.64 20.83 20.31 20.83 19.9V18.65H22.1301C22.5201 18.62 22.82 18.3 22.82 17.91C22.82 17.52 22.49 17.17 22.08 17.17H20.83V15.87Z", fill: themeEnrichedPrimaryColor })));
};
EconomyPlus.defaultProps = defaultIconProps;
export default EconomyPlus;
