import { shouldShowFareChangeAlert } from '../../../utils/Flights';
import { MoneyUtil } from '../../../utils/Money';
import type { SuccessRevalidateItineraryEvent } from '../types';

export default (context: any, event: any): boolean => {
  const typedContext = context as { oldTotalFare: MoneyUtil };

  const oldRevalidateFare = typedContext.oldTotalFare;
  const typedEvent = event as SuccessRevalidateItineraryEvent;
  const newRevalidateFare = MoneyUtil.parse(typedEvent.data.totalFare);
  return shouldShowFareChangeAlert({ newRevalidateFare, oldRevalidateFare });
};
