import first from 'lodash/first';
import { PnrStatusV1 } from '../constants/common';
import type {
  ITripPnr,
  ITripFlightDetail,
  ITripFlightCabinInfo,
  TripOption,
  IUserFacingStatusType,
  getFeatureVisibilityStateProps,
  IAirPnrCard,
} from '../types/trip';
import { TripCategoryPath, PnrCreationOrigin, PnrBookingHistoryBookingStatus } from '../types/trip';
import { MoneyUtil } from './Money';
import getLayoverInfo from './Flights/getLayoverInfo';
import {
  IUserFacingStatusEnum,
  cancelViaSupportBannerTextsForAgent,
  cancelViaSupportBannerTextsForTraveler,
  exchangeViaSupportBannerTextsForAgent,
  exchangeViaSupportBannerTextsForTraveler,
} from '../constants';
import type {
  ListTripSummariesResponsePnrSummary,
  ListTripsResponseTripInfo,
} from '../types/api/v1/obt/trip/list_trips';
import type { AirCancellationNotSupportedReason, WrapWithId } from '../types';
import { OBTFeatureActionActionTypeEnum, OBTFeaturePreferencesPreferenceVisibilityEnum } from '../types';
import type { AirPnrTravelerInfo, CarPnrTravelerInfo, HotelPnrTravelerInfo, Pnr } from '../types/api/v1/obt/pnr/pnr';
import type { AirSearchResponse } from '../types/api/v1/obt/air/air_search_response';
import { getDurationMinutes, minutesToDurationString } from '../date-utils';
import type { ExchangeNotSupportedReason } from '../types/api/v2/obt/model/exchange-not-supported-reason';
import type { ICommonI18nMessage } from '../translations/defineMessage';
import { defineCommonMessage } from '../translations/defineMessage';
import type { TravelerBasicDetails } from '../telemetry/types/tripsEvent';

export function isHotelModified(pnr: ITripPnr): boolean {
  /** used to get the current status of hotel booking on trips pages */
  const bookingStatusPaymentDetails = first(pnr.bookingHistory)?.bookingInfo?.status;
  /** isHotelModified is true if the booking status of the hotel booking is UPDATED */
  return bookingStatusPaymentDetails === PnrBookingHistoryBookingStatus.UPDATED;
}

export function isShellPnr(pnr: ITripPnr): boolean {
  return pnr.createdVia === PnrCreationOrigin.SHELL;
}

// for the shell pnr CONFIRMED status is also pending
export function isShellPnrPendingByStatus(status: PnrStatusV1): boolean {
  return [PnrStatusV1.CONFIRMED, PnrStatusV1.PENDING].includes(status);
}

export function isShellPnrPending(pnr: ITripPnr): boolean {
  return isShellPnrPendingByStatus(pnr.status);
}

export function isVoidedPnrStatus(pnr: ITripPnr): boolean {
  return pnr.status === PnrStatusV1.VOIDED;
}

export function isTripPastOrCancelled(status: TripCategoryPath): boolean {
  return [TripCategoryPath.Past, TripCategoryPath.Cancelled].includes(status);
}

export const getMerchantFeeFromPnr = (pnr: ITripPnr): MoneyUtil =>
  MoneyUtil.parse(pnr.air?.air?.itineraries[0].rateOptions[0].merchantFee);

export const getLayoverFromTripFlightDetails = (prior: ITripFlightDetail, later: ITripFlightDetail) => {
  const priorInfo = {
    arrivalAirport: prior.arrivalAirport,
    arrivalAirportName: prior.arrivalAirportName,
    arrivalDateTime: prior.arrivalDateTime,
  };

  const laterInfo = {
    departureAirport: later.departureAirport,
    departureAirportName: later.departureAirportName,
    departureDateTime: later.departureDateTime,
  };

  return getLayoverInfo(priorInfo, laterInfo);
};

export const getIsMixedCabin = (flightDetails: ITripFlightCabinInfo[]): boolean => {
  const cabinSet = new Set();
  flightDetails.forEach((flightInfo) => cabinSet.add(flightInfo.cabinType));
  return cabinSet.size > 1;
};

export function tripToOption(trip: ListTripsResponseTripInfo): TripOption;
export function tripToOption(trip: undefined): null;
export function tripToOption(trip: ListTripsResponseTripInfo | undefined): TripOption | null {
  if (!trip) return null;

  return {
    value: trip.tripId,
    label: trip.name,
    startDate: trip.startDate,
    tripMetadata: trip.tripMetadata,
  };
}

export function generateId(): string {
  return Math.random().toString().split('.')[1];
}

export function wrapWithId<T>(value: T): WrapWithId<T> {
  return {
    value,
    id: generateId(),
  };
}

export const getAllLegsEligibleForExchange = (
  allLegsWithSameTicketNum: number[],
  flightPnr: Pnr,
  legIndicesInSearchResponse: number[],
): number[] => {
  const allowedStatusesForExchange = [
    IUserFacingStatusEnum.ACTIVE_STATUS,
    IUserFacingStatusEnum.COMPLETED_STATUS,
    IUserFacingStatusEnum.CONFIRMED_STATUS,
    IUserFacingStatusEnum.SCHEDULE_CHANGE_STATUS,
  ];

  return allLegsWithSameTicketNum.filter((legIndex) => {
    const matchingLegInsidePnr = flightPnr.air?.legInfo.find(
      ({ legIndex: currentLegIndex }) => currentLegIndex === legIndex,
    );
    if (!matchingLegInsidePnr) {
      return false;
    }
    const isStatusAllowed = allowedStatusesForExchange.includes(matchingLegInsidePnr.legStatus);
    if (!isStatusAllowed) {
      return false;
    }
    return legIndicesInSearchResponse.includes(legIndex);
  });
};

export const awaitsForHoldBookingConfirmation = ({ status, air }: ITripPnr) => {
  const holdDateTimeDeadline = air?.holdInfo?.holdDateTimeDeadline;

  return (
    status === PnrStatusV1.BOOKING_ON_HOLD &&
    holdDateTimeDeadline !== undefined &&
    holdDateTimeDeadline.iso8601 > new Date().toISOString()
  );
};

export function getIsTripMaybeActive(tripStatus: IUserFacingStatusType): boolean {
  return [
    IUserFacingStatusEnum.ACTIVE_STATUS,
    IUserFacingStatusEnum.CONFIRMED_STATUS,
    IUserFacingStatusEnum.PENDING_STATUS,
    IUserFacingStatusEnum.UNKNOWN_STATUS,
    IUserFacingStatusEnum.UNRECOGNIZED,
    IUserFacingStatusEnum.VOIDED_STATUS,
  ].includes(tripStatus);
}

export const pnrSummariesHaveHoldBookingPnr = (pnrSummaries: ListTripSummariesResponsePnrSummary[]) =>
  pnrSummaries.some(({ pnrStatus }) => pnrStatus === PnrStatusV1.BOOKING_ON_HOLD);

export const allFlightsInGivenPnr = (airSearchResponse: AirSearchResponse) => {
  const flightsData = airSearchResponse.data.flights;
  return flightsData.map((flightData) => {
    return {
      departureAirport: flightData.origin.airport,
      arrivalAirport: flightData.destination.airport,
      duration: minutesToDurationString(getDurationMinutes(flightData.duration.iso8601)),
      airlineInfo: `${flightData.marketing.airline} ${flightData.marketing.num}`,
      marketing: flightData.marketing.airline,
    };
  });
};

export const getExchangeViaSupportBannerText = (
  exchangeNotSuportedReasons: ExchangeNotSupportedReason[],
  isAgent: boolean,
): ICommonI18nMessage<{}> => {
  const exchangeNotSupportedReason = exchangeNotSuportedReasons[0];

  const exchangeBannerText = isAgent
    ? exchangeViaSupportBannerTextsForAgent[exchangeNotSupportedReason]
    : exchangeViaSupportBannerTextsForTraveler[exchangeNotSupportedReason];

  return (
    exchangeBannerText ||
    defineCommonMessage('Online flight modification is not available for this ticket. Please contact support.')
  );
};

export const getCancelViaSupportBannerText = (
  cancellationNotSuportedReasons: AirCancellationNotSupportedReason[],
  isAgent: boolean,
): string => {
  const cancelNotSupportedReason = cancellationNotSuportedReasons[0];

  const cancelBannerText = isAgent
    ? cancelViaSupportBannerTextsForAgent[cancelNotSupportedReason]
    : cancelViaSupportBannerTextsForTraveler[cancelNotSupportedReason];

  return cancelBannerText;
};

export const getFeatureVisibilityState = ({
  features,
  obtFeatureTypeEnum,
  defaultVisibility = OBTFeaturePreferencesPreferenceVisibilityEnum.Enabled,
}: getFeatureVisibilityStateProps): OBTFeaturePreferencesPreferenceVisibilityEnum | undefined =>
  features?.find(({ obtFeatureType }) => obtFeatureType === obtFeatureTypeEnum)?.obtFeaturePreferences[0]?.preference
    ?.visibility ?? defaultVisibility;

export const getFeatureActionType = ({
  features,
  obtFeatureTypeEnum,
}: getFeatureVisibilityStateProps): OBTFeatureActionActionTypeEnum | undefined =>
  features?.find(({ obtFeatureType }) => obtFeatureType === obtFeatureTypeEnum)?.obtFeaturePreferences[0]?.preference
    ?.action?.actionType ?? OBTFeatureActionActionTypeEnum.SelfServe;

export const getTravelerBasicDetails = (
  travelerInfos: HotelPnrTravelerInfo[] | AirPnrTravelerInfo[] | CarPnrTravelerInfo[],
) => {
  return travelerInfos
    .map((travelerInfo) => {
      return {
        email: travelerInfo.traveler?.userBusinessInfo?.email,
        name: travelerInfo.traveler?.user?.name,
        userOrgId: travelerInfo.traveler?.userOrgId,
      };
    })
    .filter((travelerInfo): travelerInfo is TravelerBasicDetails => !!travelerInfo);
};

export const getZeroMoneyWithOriginalCurrency = (pnrDetails: IAirPnrCard) => {
  const currency = pnrDetails.baseFare.getCurrency();
  const originalCurrency = pnrDetails.baseFare.getOriginalCurrency();
  return MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency);
};
