/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Preferred hotel amenity.
 * @export
 * @enum {string}
 */

export const HotelPrefAmenity = {
  Parking: 'PARKING',
  FreeParking: 'FREE_PARKING',
  FreeBreakfast: 'FREE_BREAKFAST',
  Pool: 'POOL',
  Wifi: 'WIFI',
  FitnessCenter: 'FITNESS_CENTER',
  FamilyFriendly: 'FAMILY_FRIENDLY',
  Reception: 'RECEPTION',
  Spa: 'SPA',
  Restaurant: 'RESTAURANT',
  Bar: 'BAR',
  Transportation: 'TRANSPORTATION',
  PetFriendly: 'PET_FRIENDLY',
  BusinessCenter: 'BUSINESS_CENTER',
  AirConditioning: 'AIR_CONDITIONING',
  BeachAccess: 'BEACH_ACCESS',
  LaundryServices: 'LAUNDRY_SERVICES',
  RoomService: 'ROOM_SERVICE',
  Accessible: 'ACCESSIBLE',
} as const;

export type HotelPrefAmenity = (typeof HotelPrefAmenity)[keyof typeof HotelPrefAmenity];
