import { SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@spotnana/blocks/src/Button';
import { Link } from '@spotnana/blocks/src/Link';
import { Typography } from '@spotnana/blocks/src/Typography';
import { flex, margins } from '@spotnana/blocks/src/utils';
import { useFeatureFlag } from 'obt-common';
import { useLoggedInUserBasicInfo } from 'obt-common/providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import useSupportWidget from 'src/hooks/useSupportWidget';
import { LottieAnimation } from 'src/v1-components/shared/lottie-animations/LottieAnimation';

import {
  animation,
  content_text_container,
  feedback_button,
  header_container,
  link,
  prev_animation,
  prev_animation_container,
  prev_subtitle_text,
  subtitle_text,
  title_text,
} from './styles';

export interface IProps {
  onTalkToAgent: (event: SyntheticEvent) => void;
  showFeedbackButton: boolean;
  onFeedback: () => void;
}

function PopperStepSupportHome({ onTalkToAgent, showFeedbackButton, onFeedback }: IProps) {
  const { t: tt } = useTranslation('WEB');
  const history = useHistory();
  const loggedInUserBasicInfo = useLoggedInUserBasicInfo();
  const { isNoChatConfigured, isFreshchatEnabled } = useSupportWidget();
  const isAgentHelpCenterEnabled = useFeatureFlag('FE_AGENT_HELP_CENTER');
  const isSpotbotEnabled = useFeatureFlag('FE_TMC_SPOTBOT');

  function onViewHelpcenter() {
    const supportUrl = 'https://spotnana.freshdesk.com/support/home';
    window.open(supportUrl, '_blank', 'noopener,noreferrer');
  }

  function onChatWithSpotbot() {
    history.push('/platform/chat-assistant');
  }

  function previousHelpCenterRender() {
    return (
      <>
        <div css={prev_animation_container}>
          <LottieAnimation animation="supportAgent" lottieCss={prev_animation} loop />
        </div>
        <div css={content_text_container}>
          <Typography variant="header4" css={title_text}>
            {tt('Our $t(travel consultant_plural) are here for you 24/7')}
          </Typography>
          <Typography variant="body2" css={prev_subtitle_text} color="primary">
            <Trans t={tt}>
              Please keep your <b>Trip ID</b> ready when you call us for quick assistance.
            </Trans>
          </Typography>

          {!isNoChatConfigured && !loggedInUserBasicInfo.isImpersonationSession && (
            <Button variant="primary" size="medium" onClick={onTalkToAgent}>
              {tt('Talk to a $t(consultant)')}
            </Button>
          )}

          {showFeedbackButton && (
            <Button variant="outline" size="medium" onClick={onFeedback} css={feedback_button}>
              {tt('Submit feedback')}
            </Button>
          )}
        </div>
      </>
    );
  }

  function newAgentHelpCenterRender() {
    return (
      <>
        <div css={header_container}>
          <Typography variant="header4" css={title_text}>
            {tt('Need help with something?')}
          </Typography>

          {isFreshchatEnabled && (
            <Typography variant="body2" css={subtitle_text} data-testid="quick-answer" className="quick-help-text">
              <Trans t={tt}>Find quick answers to your questions in our Help Center.</Trans>
            </Typography>
          )}

          <div css={[flex.init]}>
            {isFreshchatEnabled && (
              <Button variant="primary" size="medium" onClick={onViewHelpcenter} data-testid="view-help-btn">
                {tt('View Help center')}
              </Button>
            )}

            {isSpotbotEnabled && (
              <Button
                variant="outline"
                size="medium"
                onClick={onChatWithSpotbot}
                css={margins.xl.half}
                data-testid="chat-with-spotbot"
              >
                {tt('Ask AI')}
              </Button>
            )}
          </div>
        </div>
        <div css={content_text_container}>
          <div className="text-animate-block">
            <div className="text">
              <Typography variant="header4" css={title_text}>
                {tt('Reach out for 24/7 support')}
              </Typography>
              <Typography variant="body2" css={subtitle_text}>
                <Trans t={tt}>Provide your Trip ID to the agents for quicker assistance.</Trans>
              </Typography>
            </div>
            <div className="animate">
              <LottieAnimation animation="supportAgent" lottieCss={animation} loop />
            </div>
          </div>

          {!isNoChatConfigured && !loggedInUserBasicInfo.isImpersonationSession && (
            <Button variant="outline" size="medium" onClick={onTalkToAgent}>
              {tt('Talk to an agent')}
            </Button>
          )}

          {showFeedbackButton && (
            <Typography variant="body2" className="link-positioner">
              <Link onClick={onFeedback} css={link} href="#noanchor">
                {tt('Report an error')}
              </Link>
            </Typography>
          )}
        </div>
      </>
    );
  }

  if (isAgentHelpCenterEnabled) {
    return newAgentHelpCenterRender();
  }
  return previousHelpCenterRender();
}

export { PopperStepSupportHome };
