import dateUtil from '../dateUtil';
import getDurationMinutes from '../getDurationMinutes';

const getDateTimeDiff = (laterDateTime: string, earlierDateTime: string, dateFormat?: string): number => {
  const start = dateUtil(earlierDateTime, dateFormat);
  const end = dateUtil(laterDateTime, dateFormat);
  return getDurationMinutes(end.diff(start));
};

export default getDateTimeDiff;
