import { ILabelValuePair } from 'obt-common';
import { useGetAirlines } from 'obt-common/api/hooks/v2/air';
import { Loader } from 'src/v2-components/flights/shared/Loader';
import {
  getAirlinesInfoCompleteMapFromAirlinesInfo,
  getAirlinesNameMapFromAirlinesInfo,
  getAirlinesSelectOptionsFromAirlinesInfo,
} from 'obt-common/utils/airlinesInfo';
import { IAirlinesContext } from './types';
import { AirlinesContext } from './context';

export const AirlinesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const airlinesQuery = useGetAirlines({
    params: {
      search: {
        includeInactive: false,
      },
    },
  });

  const { data: airlinesData, isFetching: isFetchingAirlines } = airlinesQuery;

  if (isFetchingAirlines || !airlinesData) {
    return <Loader />;
  }

  const airlinesNameMap = getAirlinesNameMapFromAirlinesInfo(airlinesData);
  const airlinesInfoCompleteMap = getAirlinesInfoCompleteMapFromAirlinesInfo(airlinesData);

  const airlinesSelectOptions: ILabelValuePair[] = getAirlinesSelectOptionsFromAirlinesInfo(airlinesData);

  const airlinesContext: IAirlinesContext = {
    airlinesInfoCompleteMap,
    airlinesNameMap,
    airlinesSelectOptions,
  };

  return <AirlinesContext.Provider value={airlinesContext}>{children}</AirlinesContext.Provider>;
};
