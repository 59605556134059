import { RBAC } from 'obt-common/components/RBAC/RBAC';
import { RoleInfoTypeEnum } from 'obt-common/types/api/v1/obt/profile/role/roles_info';
import { OrganizationBasicInfo } from 'obt-common/types/api/v1/obt/common/organization';
import { FC } from 'react';
import { Typography } from '@spotnana/blocks/src/Typography/Typography';
import { Ellipsis } from '@spotnana/blocks/src/Ellipsis/Ellipsis';
import { COMPANY_NAME_MAX_WIDTH, traveler_company_logo, traveler_picker_company_info } from './styles';

const COMPANY_NAME_LOGO_VISIBILITY_ALLOWED_ROLES = [
  RoleInfoTypeEnum.GLOBAL_ADMIN,
  RoleInfoTypeEnum.GLOBAL_AGENT,
  RoleInfoTypeEnum.TMC_ADMIN,
  RoleInfoTypeEnum.TMC_AGENT,
];

interface IProps {
  company: OrganizationBasicInfo | null;
}

const CompanyLabel: FC<IProps> = ({ company }) => {
  if (!company) {
    return null;
  }

  const { organizationLogo, name } = company;

  return (
    <RBAC allowedRoles={COMPANY_NAME_LOGO_VISIBILITY_ALLOWED_ROLES}>
      {organizationLogo?.url && <img css={traveler_company_logo} src={organizationLogo?.url} alt="" />}

      {name && (
        <Typography css={traveler_picker_company_info} variant="body3" color="secondary" as="span">
          <Ellipsis title={name} width={COMPANY_NAME_MAX_WIDTH}>
            {name}
          </Ellipsis>
        </Typography>
      )}
    </RBAC>
  );
};

export default CompanyLabel;
