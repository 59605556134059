import { FareComponent } from '../types/api/v1/common/money';
import { ItemType } from '../types/api/v1/obt/pnr/payment';
import { FareComponent as V2FareComponent } from '../types/api/v2/obt/model/fare-component';
import { ItemType as V2ItemType } from '../types/api/v2/obt/model/item-type';

export const V2ToV1ItemTypeMapper = {
  [V2ItemType.ServiceFee]: ItemType.SERVICE_FEE,
  [V2ItemType.TravelTicket]: ItemType.TRAVEL_TICKET,
  [V2ItemType.Seat]: ItemType.SEAT,
  [V2ItemType.Baggage]: ItemType.BAGGAGE,
  [V2ItemType.EarlyBird]: ItemType.EARLY_BIRD,
};

export const V2ToV1IFareComponentMapper = {
  [V2FareComponent.Base]: FareComponent.BASE,
  [V2FareComponent.Tax]: FareComponent.TAX,
};

export const RAZORPAY_REDIRECT_EVENT = {
  TYPE: 'RAZORPAY_REDIRECT',
};

export const RAZORPAY_REDIRECT_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export enum ConfirmCardSaveEnum {
  INITIALIZE = 'INITIALIZE',
}

export interface RazorpaySuccessData {
  paymentId: string;
  orderId: string;
  signature: string;
}

export interface RazorpayErrorData {
  code: string;
  description: string;
  source: string;
  step: string;
  reason: string;
  metadata: { payment_id: string; order_id: string };
}

export type RazorpayResponse = RazorpaySuccessData | RazorpayErrorData;

export interface TrackCreditCardCreationData {
  cardType?: string;
  company?: string;
  currency?: string;
  type?: string;
  applicableTo?: number[];
  accessType?: string;
  label?: string;
}

export type RazorPayAuthData = { open: true; paymentAuthUrl: string } | { open: false };

export const defaultRazorPayErrorMsg: RazorpayErrorData = {
  code: 'PAYMENT_DECLINED',
  source: 'bank',
  step: 'payment',
  reason: 'declined',
  metadata: { payment_id: '', order_id: '' },
  description:
    "Your payment didn't go through as it was declined by the bank. Try another payment method or contact your bank.",
};
