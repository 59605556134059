import first from 'lodash/first';
import type { Uta } from '../../types/api/v1/obt/air/routehappy';
import { utaTypes } from '../../constants/flights';
import type { UtaProps } from '../../types/flight';

type DisplayTextUTA = string | { carryOn?: string; checkedBaggage?: string; additionalCheckedBaggage?: string };

export const getDisplayTextUTA = (uta: Uta): DisplayTextUTA => {
  switch (first(Object.keys(uta)) as UtaProps) {
    case utaTypes.advanceChange:
      return uta?.advanceChange?.summary?.description ?? '';
    case utaTypes.cancellation:
      return uta?.cancellation?.summary?.description ?? '';
    case utaTypes.sameDayChange:
      return uta?.sameDayChange?.summary?.description ?? '';
    case utaTypes.baggage: {
      const baggage: DisplayTextUTA = {};
      // TODO: Handle possible additional carry on
      baggage.carryOn = first(uta?.baggage?.carryOn)?.policy?.summary?.description;
      if (uta?.baggage?.checkedBag) {
        if (uta.baggage.checkedBag[0]) {
          baggage.checkedBaggage = uta.baggage.checkedBag[0]?.policy?.summary?.description;
        }
        if (uta.baggage.checkedBag[1]) {
          baggage.additionalCheckedBaggage = uta.baggage.checkedBag[1]?.policy?.summary?.description;
        }
      }
      return baggage;
    }
    case utaTypes.seatSelection:
      return uta?.seatSelection?.summary?.description ?? '';
    case utaTypes.seatType:
      return uta?.seatType?.summary?.description ?? '';
    case utaTypes.upgradeEligibility:
      return uta?.upgradeEligibility?.summary?.description ?? '';
    case utaTypes.checkInPriority:
      return uta?.checkInPriority?.summary?.description ?? '';
    case utaTypes.boardingPriority:
      return uta?.boardingPriority?.summary?.description ?? '';
    case utaTypes.loungeAccess:
      return uta?.loungeAccess?.summary?.description ?? '';
    case utaTypes.overheadBag:
      return uta?.overheadBag?.summary?.description ?? '';
    case utaTypes.security:
      return uta?.security?.summary?.description ?? '';
    default:
      return '';
  }
};
