/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Loyalty Info details.
 * @export
 * @interface LoyaltyInfo
 */
export interface LoyaltyInfo {
  /**
   *
   * @type {Array<string>}
   * @memberof LoyaltyInfo
   */
  appliedTo?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof LoyaltyInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof LoyaltyInfo
   */
  issuedBy: string;
  /**
   *
   * @type {string}
   * @memberof LoyaltyInfo
   */
  type: LoyaltyInfoTypeEnum;
}

export const LoyaltyInfoTypeEnum = {
  UnknownType: 'UNKNOWN_TYPE',
  Air: 'AIR',
  Hotel: 'HOTEL',
  Car: 'CAR',
  Rail: 'RAIL',
} as const;

export type LoyaltyInfoTypeEnum = (typeof LoyaltyInfoTypeEnum)[keyof typeof LoyaltyInfoTypeEnum];
