/* istanbul ignore file */

// direct export of MUI component
// https://mui.com/material-ui/react-container/

import MuiContainer, { ContainerProps } from '@mui/material/Container';

export type TContainerProps = ContainerProps;

export const Container: React.FC<ContainerProps> = MuiContainer;
