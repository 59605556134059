import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Refund = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "evenodd", d: "M19.838 6.5a1 1 0 01.998.924l.003.075.003 3.766a1 1 0 01-1.997.076l-.003-.075-.002-.94h-5.681v.94a1 1 0 01-1.998.073l-.003-.074.001-.938H8.5a.502.502 0 00-.497.442L8 10.828v12.098a.5.5 0 00.442.496l.058.004h15a.5.5 0 00.497-.442l.003-.058V10.828a.5.5 0 00-.442-.497l-.058-.003h-.842a1 1 0 01-.075-1.998l.075-.002h.842a2.5 2.5 0 012.498 2.391l.002.109v12.098a2.5 2.5 0 01-2.392 2.497l-.108.003h-15a2.501 2.501 0 01-2.498-2.392L6 22.926V10.828A2.5 2.5 0 018.392 8.33l.108-.002 2.66-.001.001-.828a1.001 1.001 0 011.998-.073l.002.075-.001.826h5.679V7.5a1 1 0 01.999-1.001zm-3.495 5.91v.853l.234.015c.46.042.864.15 1.215.326.409.205.725.483.95.834.225.35.34.755.345 1.215H17.32l-.022-.143a.839.839 0 00-.258-.454c-.164-.149-.396-.237-.697-.264v1.58l.51.116.245.06c.635.169 1.131.421 1.488.757.401.378.603.871.606 1.48-.003.701-.254 1.254-.755 1.66-.501.405-1.2.62-2.094.648v.862h-.477v-.866l-.258-.018c-.758-.073-1.37-.304-1.84-.694-.52-.432-.786-1.072-.796-1.92h1.768l.013.135c.037.262.141.473.313.631.2.185.467.297.8.334v-1.678l-.567-.134-.24-.061c-.547-.154-.987-.379-1.32-.676-.382-.339-.572-.798-.57-1.377a2.067 2.067 0 01.34-1.184c.229-.342.546-.615.953-.82a3.579 3.579 0 011.404-.36v-.858h.477zm0 5.585v1.57c.308-.03.548-.116.718-.256a.669.669 0 00.259-.528.582.582 0 00-.24-.47c-.158-.121-.404-.227-.737-.316zm-.477-3.192l-.149.028a.93.93 0 00-.439.216.624.624 0 00-.199.457c-.005.189.063.342.203.46s.335.215.584.29v-1.451z" })));
};
Refund.defaultProps = defaultIconProps;
export default Refund;
