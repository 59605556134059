import { V2TripDetailsResponseManager } from 'obt-common';
import useTripDetails from './useTripDetails';
import useQueryParamSelector from './useQueryParamSelector';

interface UrlState {
  tripId: string | undefined;
}

const useIsAddingToEmptyTrip = () => {
  const tripId = useQueryParamSelector<UrlState, 'tripId'>('tripId');
  const { data: tripData, isLoading: isTripDataLoading } = useTripDetails(tripId);
  const primaryTravelerFromTrip = tripData
    ? // eslint-disable-next-line no-restricted-syntax
      V2TripDetailsResponseManager.getPrimaryTravelerFromTrip(tripData)
    : undefined;

  const isAddingToEmptyTrip = !primaryTravelerFromTrip;
  return { isAddingToEmptyTrip, isTripDataLoading, tripId };
};

export default useIsAddingToEmptyTrip;
