import first from 'lodash/first';
import unionBy from 'lodash/unionBy';
import memoize from 'lodash/memoize';
import { getPaymentSourceCardInfo } from '../utils/getPaymentSourceCardInfo/getPaymentSourceCardInfo';
import type {
  AllowedFopRule,
  BookingPaymentSource,
  IFindPaymentRuleReturn,
  IFopRuleAndPaymentRule,
  IGetPointSelectorPaymentDetailsReturn,
  IGetSelectedPaymentDetailsProps,
  IGetSliderInfoReturnType,
  IPointsSelectorConfig,
  ListBookingPaymentSourcesResponse,
  PaymentItemInfo,
  PaymentRule,
  PointSlidersProps,
} from '../types';
import { PaymentSourceType } from '../types';
import { RewardsProgramType } from '../types/api/v2/obt/model/rewards-program-type';
import { MoneyUtil } from '../utils/Money';
import type { BookingPaymentDetails, SelectedFoP, SelectedPayment } from '../types/api/v1/obt/pnr/payment';
import type { ItemType } from '../types/api/v2/obt/model/item-type';
import type { PaymentItem } from '../types/api/v2/obt/model/payment-item';
import { getV1PaymentItemsFromV2 } from '../utils/payment/getV1PaymentItemsFromV2';

export default class ListBookingPaymentSourcesResponseManager {
  private readonly response: ListBookingPaymentSourcesResponse;

  constructor(response: ListBookingPaymentSourcesResponse) {
    this.response = response;
  }

  public findQantasRewardSource() {
    const listBookingPaymentSourcesResponse = this.response;
    return listBookingPaymentSourcesResponse.bookingPaymentSources.find(
      (paymentSource) =>
        paymentSource?.type === PaymentSourceType.RewardsProgram &&
        paymentSource?.metadata &&
        'rewardsProgramMetadata' in paymentSource?.metadata &&
        paymentSource?.metadata?.rewardsProgramMetadata &&
        paymentSource?.metadata?.rewardsProgramMetadata.type === RewardsProgramType.QantasPoints,
    );
  }

  public findQantasTravelFundSource() {
    const listBookingPaymentSourcesResponse = this.response;
    return listBookingPaymentSourcesResponse.bookingPaymentSources.find(
      (bookingPaymentSource) =>
        bookingPaymentSource?.metadata &&
        'customPaymentMethodMetadata' in bookingPaymentSource?.metadata &&
        bookingPaymentSource.metadata.customPaymentMethodMetadata &&
        'qantasTravelFundMetadata' in bookingPaymentSource.metadata.customPaymentMethodMetadata,
    );
  }

  public isFopUsable(selectedPaymentSourceId: string | undefined): boolean {
    if (!selectedPaymentSourceId) {
      return false;
    }
    const listBookingPaymentSourcesResponse = this.response;
    return !!listBookingPaymentSourcesResponse.allowedPaymentDetails?.[0].allowedFopRules?.some((allowedFopRule) =>
      allowedFopRule.paymentRules?.some((paymentRule) =>
        paymentRule.paymentSourceIds?.includes(selectedPaymentSourceId),
      ),
    );
  }

  public getTravelFundBalance(zeroMoney: MoneyUtil): MoneyUtil {
    const travelFundSource = this.findQantasTravelFundSource();
    if (travelFundSource) {
      if (
        travelFundSource?.metadata &&
        'customPaymentMethodMetadata' in travelFundSource?.metadata &&
        travelFundSource.metadata.customPaymentMethodMetadata &&
        'qantasTravelFundMetadata' in travelFundSource.metadata.customPaymentMethodMetadata
      ) {
        return MoneyUtil.convertV2MoneyToMoneyUtil(
          travelFundSource.metadata?.customPaymentMethodMetadata?.qantasTravelFundMetadata?.fundBalance,
        );
      }
    }
    return zeroMoney;
  }

  public getQantasPointsBalance(): number {
    const qantasBookingSource = this.findQantasRewardSource();

    if (qantasBookingSource) {
      if (
        qantasBookingSource?.metadata &&
        'rewardsProgramMetadata' in qantasBookingSource?.metadata &&
        qantasBookingSource?.metadata?.rewardsProgramMetadata
      ) {
        return qantasBookingSource?.metadata?.rewardsProgramMetadata?.pointsBalance?.amount || 0;
      }
    }
    return 0;
  }

  // eslint-disable-next-line no-underscore-dangle
  private _getBookingPaymentSourceById(id: string): BookingPaymentSource | null {
    return this.response.bookingPaymentSources.find((source) => source.id === id) || null;
  }

  // eslint-disable-next-line no-underscore-dangle
  getBookingPaymentSourceById = memoize(this._getBookingPaymentSourceById);

  public findPaymentRule(
    predicate: (fopRule: AllowedFopRule, rule: PaymentRule) => boolean,
  ): IFindPaymentRuleReturn | null {
    let fopRuleIndex = -1;
    let paymentRuleIndex = -1;
    let selectedPaymentRule: PaymentRule | undefined;
    const allowedFopRules = this.response.allowedPaymentDetails?.[0].allowedFopRules || [];

    for (let fopIndex = 0; fopIndex < allowedFopRules.length; fopIndex += 1) {
      const fopRule = allowedFopRules[fopIndex];
      const paymentRules = fopRule.paymentRules || [];

      for (let paymentIndex = 0; paymentIndex < paymentRules.length; paymentIndex += 1) {
        const paymentRule = paymentRules[paymentIndex];
        const isMatching = predicate(fopRule, paymentRule);

        if (isMatching) {
          fopRuleIndex = fopIndex;
          paymentRuleIndex = paymentIndex;
          selectedPaymentRule = paymentRule;
          break;
        }
      }

      if (fopRuleIndex > -1) {
        break;
      }
    }

    if (selectedPaymentRule) {
      return { paymentRule: selectedPaymentRule, fopRuleIndex, paymentRuleIndex };
    }

    return null;
  }

  public static getMergedPaymentItems(paymentItemAmounts: PaymentItemInfo[]): PaymentItem[] {
    const paymentItems = paymentItemAmounts.flatMap((item) => item.paymentItems);
    return unionBy(paymentItems, (item) => item.itemType || '');
  }

  public getDefaultBookingPaymentDetails(isAirlineFopEnabled = false): BookingPaymentDetails {
    const allowedFopRules = this.response.allowedPaymentDetails?.[0].allowedFopRules || [];

    const selectedFops: SelectedFoP[] = allowedFopRules.map((fopRule) => {
      const { paymentRules, paymentItems } = fopRule;
      const allPaymentRules = paymentRules || [];

      const selectedPayments: SelectedPayment[] = allPaymentRules.map((paymentRule) => {
        const { paymentSplitDetails } = paymentRule;
        const paymentSourceIds = paymentRule.paymentSourceIds || [];

        const defaultSelectedPayment: SelectedPayment = {
          paymentSourceId: '',
        };

        if (!paymentSplitDetails) {
          return defaultSelectedPayment;
        }

        // Only min-max based amounts are supported currently.
        if (!('minMaxAmount' in paymentSplitDetails)) {
          return defaultSelectedPayment;
        }

        const minAmount = paymentSplitDetails.minMaxAmount?.minLimit;
        const maxAmount = paymentSplitDetails.minMaxAmount?.maxLimit;
        if (!minAmount || !maxAmount) {
          return defaultSelectedPayment;
        }

        // Slider based amounts are not supported currently.
        if (minAmount.amount !== maxAmount.amount) {
          return defaultSelectedPayment;
        }

        const amount = MoneyUtil.convertV2MoneyToMoneyUtil(minAmount).toMoneyType();

        const eligiblePaymentSourceId = paymentSourceIds.find((id) => {
          const paymentSource = this.getBookingPaymentSourceById(id);
          const isUnusable = isAirlineFopEnabled && this.getIsFopDisabled(id);
          if (!paymentSource || isUnusable) {
            return false;
          }
          const paymentSourceInfo = getPaymentSourceCardInfo(paymentSource);
          if (paymentSourceInfo.isDisabled) {
            return false;
          }
          return true;
        });

        return {
          paymentSourceId: eligiblePaymentSourceId || '',
          amount,
          cvv: '',
          postPaymentRedirectionUrl: '',
        };
      });

      return {
        paymentItems: getV1PaymentItemsFromV2(paymentItems || []),
        selectedPayments,
      };
    });

    return {
      bookingTravelerPaymentDetails: [{ selectedFops }],
    };
  }

  public getPaymentSourceType = (paymentSourceId: string) => {
    return this.response.bookingPaymentSources.find((paymentSource) => paymentSource.id === paymentSourceId)?.type;
  };

  public getFopRuleAndPaymentRuleByPaymentSourceId = (
    paymentSourceId: string | undefined,
  ): IFopRuleAndPaymentRule | undefined => {
    if (!paymentSourceId) return undefined;

    const allowedFopRules = this.response.allowedPaymentDetails?.[0]?.allowedFopRules;

    const foundRule = (allowedFopRules || []).find((fopRule) => {
      const paymentRule = fopRule.paymentRules?.find((rule) => rule.paymentSourceIds?.includes(paymentSourceId));
      return paymentRule !== undefined;
    });

    if (foundRule) {
      const paymentRule = foundRule.paymentRules?.find((rule) => rule.paymentSourceIds?.includes(paymentSourceId));
      return {
        paymentRule,
        fopRule: foundRule,
      };
    }

    return undefined;
  };

  public getIsFopDisabled = (paymentSourceId: string) => {
    return Boolean(
      this.getFopRuleAndPaymentRuleByPaymentSourceId(paymentSourceId)?.paymentRule?.unusablePaymentSources?.some(
        (unusableSource) => unusableSource.id === paymentSourceId,
      ),
    );
  };

  public getFopRuleIndexForItemType(itemType: ItemType): number {
    const paymentDetails = this.response.allowedPaymentDetails?.[0];
    const fopRules = paymentDetails?.allowedFopRules || [];
    const fopRuleIndex = fopRules.findIndex((rule) => rule.paymentItems?.some((item) => item.itemType === itemType));
    return fopRuleIndex ?? -1;
  }

  public findAmadeusPaymentSource() {
    const listBookingPaymentSourcesResponse = this.response;
    return listBookingPaymentSourcesResponse.bookingPaymentSources.find(
      (bookingPaymentSource) =>
        bookingPaymentSource?.metadata &&
        'customPaymentMethodMetadata' in bookingPaymentSource?.metadata &&
        bookingPaymentSource.metadata.customPaymentMethodMetadata &&
        'amadeusCheckoutMetadata' in bookingPaymentSource.metadata.customPaymentMethodMetadata &&
        bookingPaymentSource.metadata.customPaymentMethodMetadata.amadeusCheckoutMetadata?.paymentPageId,
    );
  }

  public getSliderInfoForLoyalty(pointsUsed: number): IGetSliderInfoReturnType {
    const qantasPointSource = this.findQantasRewardSource();

    const qantasPaymentRule = this.getFopRuleAndPaymentRuleByPaymentSourceId(qantasPointSource?.id);

    if (qantasPaymentRule?.paymentRule) {
      if (qantasPaymentRule?.paymentRule?.paymentSplitDetails) {
        const { paymentSplitDetails: qantasPaymentSplitDetails } = qantasPaymentRule?.paymentRule;
        if (
          qantasPaymentSplitDetails &&
          'splitAmount' in qantasPaymentSplitDetails &&
          qantasPaymentSplitDetails?.splitAmount?.amount
        ) {
          const steps = qantasPaymentSplitDetails?.splitAmount?.amount;
          const sliderSelection = steps.findIndex(
            (selectedAmount) =>
              MoneyUtil.convertV2MoneyToMoneyUtil(selectedAmount).getQantasPoints()?.amount === pointsUsed,
          );

          const sliderRanks = steps.length - 1;

          return {
            sliderSelection,
            sliderRanks,
          };
        }
      }
    }

    return {
      sliderSelection: 0,
      sliderRanks: 0,
    };
  }

  /**
   * @deprecated qantasPointSelectorConfigDeprecated is deprecated. Use qantasPointSelectorConfig instead.
   */
  public qantasPointSelectorConfigDeprecated(totalPoints: number): IPointsSelectorConfig {
    let steps: number[] = [];
    let config = {
      isHidden: true,
      minLimit: 0,
      maxLimit: 0,
      disablePointSelection: true,
      steps,
      insufficientPointsToBook: false,
    };

    const { allowedPaymentDetails } = this.response;
    const qantasPointsSource = this.findQantasRewardSource();

    if (!qantasPointsSource) {
      return config;
    }

    allowedPaymentDetails?.forEach((paymentDetail) =>
      paymentDetail.allowedFopRules?.forEach((allowedFopRule) =>
        allowedFopRule.paymentRules?.forEach((paymentRule) => {
          const paymentRuleWithQantasPoints = paymentRule.paymentSourceIds?.includes(qantasPointsSource.id);

          if (paymentRuleWithQantasPoints) {
            const { paymentSplitDetails } = paymentRule;

            let minPoints = 0;
            let maxPoints = 0;

            if (
              paymentSplitDetails &&
              'splitAmount' in paymentSplitDetails &&
              paymentSplitDetails.splitAmount?.amount
            ) {
              steps = paymentSplitDetails.splitAmount.amount.map(
                (item) => MoneyUtil.convertV2MoneyToMoneyUtil(item).getQantasPoints()?.amount ?? 0,
              );

              maxPoints = Math.max(...steps);
              minPoints = Math.min(...steps);

              if (totalPoints < maxPoints) {
                // select steps which are less than or equal to totalPoints
                steps = steps.filter((step) => step <= totalPoints);
              }

              minPoints = Math.min(...steps);
              maxPoints = Math.max(...steps);
            }

            if (paymentSplitDetails && 'minMaxAmount' in paymentSplitDetails) {
              minPoints =
                MoneyUtil.convertV2MoneyToMoneyUtil(paymentSplitDetails?.minMaxAmount?.minLimit).getQantasPoints()
                  ?.amount ?? 0;
              maxPoints =
                MoneyUtil.convertV2MoneyToMoneyUtil(paymentSplitDetails?.minMaxAmount?.maxLimit).getQantasPoints()
                  ?.amount ?? 0;
              steps = [];
            }

            const minPointsGreaterThanBalance = minPoints > totalPoints;
            const disablePointSelection = maxPoints === minPoints;
            const isHidden = minPointsGreaterThanBalance;
            config = {
              isHidden,
              minLimit: minPoints,
              maxLimit: maxPoints,
              disablePointSelection,
              steps,
              insufficientPointsToBook: minPointsGreaterThanBalance,
            };
          }
        }),
      ),
    );
    return config;
  }

  public qantasPointSelectorConfig(totalPoints: number): IPointsSelectorConfig {
    let steps: number[] = [];
    let config = {
      isHidden: true,
      minLimit: 0,
      maxLimit: 0,
      disablePointSelection: true,
      steps,
      insufficientPointsToBook: false,
    };

    const { allowedPaymentDetails } = this.response;
    const qantasPointsSource = this.findQantasRewardSource();

    if (!qantasPointsSource) {
      return config;
    }

    allowedPaymentDetails?.forEach((paymentDetail) =>
      paymentDetail.allowedFopRules?.forEach((allowedFopRule) =>
        allowedFopRule.paymentRules?.forEach((paymentRule) => {
          const paymentRuleWithQantasPoints = paymentRule.paymentSourceIds?.includes(qantasPointsSource.id);

          if (paymentRuleWithQantasPoints) {
            const { paymentSplitDetails } = paymentRule;

            let minPoints = 0;
            let maxPoints = 0;

            if (
              paymentSplitDetails &&
              'splitAmount' in paymentSplitDetails &&
              paymentSplitDetails.splitAmount?.amount
            ) {
              steps = paymentSplitDetails.splitAmount.amount.map(
                (item) => MoneyUtil.convertV2MoneyToMoneyUtil(item).getQantasPoints()?.amount ?? 0,
              );

              maxPoints = Math.max(...steps);
              minPoints = Math.min(...steps);

              if (totalPoints < maxPoints) {
                // select steps which are less than or equal to totalPoints
                steps = steps.filter((step) => step <= totalPoints);
              }

              minPoints = Math.min(...steps);
              maxPoints = Math.max(...steps);
            }

            if (paymentSplitDetails && 'minMaxAmount' in paymentSplitDetails) {
              minPoints =
                MoneyUtil.convertV2MoneyToMoneyUtil(paymentSplitDetails?.minMaxAmount?.minLimit).getQantasPoints()
                  ?.amount ?? 0;
              maxPoints =
                MoneyUtil.convertV2MoneyToMoneyUtil(paymentSplitDetails?.minMaxAmount?.maxLimit).getQantasPoints()
                  ?.amount ?? 0;
              steps = [];
            }

            const minPointsGreaterThanBalance = minPoints > totalPoints;
            const disablePointSelection = maxPoints === minPoints;
            const isHidden = minPointsGreaterThanBalance;
            config = {
              isHidden,
              minLimit: minPoints,
              maxLimit: maxPoints,
              disablePointSelection,
              steps: steps.filter((step) => step !== 0),
              insufficientPointsToBook: minPointsGreaterThanBalance,
            };
          }
        }),
      ),
    );
    return config;
  }

  public isCrfFare(): boolean {
    const totalPoints = this.getQantasPointsBalance();
    const qantasPointsConfig = this.qantasPointSelectorConfig(totalPoints);
    return qantasPointsConfig.minLimit > 0;
  }

  public getMopCashAndPointsCashEquivalent(selectedPoints: number, zeroMoney: MoneyUtil) {
    let cashToBePaid = zeroMoney;
    let pointsCashEquivalent = zeroMoney;
    const qantasPointSource = this.findQantasRewardSource();

    const qantasPaymentRule = this.getFopRuleAndPaymentRuleByPaymentSourceId(qantasPointSource?.id);

    if (qantasPaymentRule?.paymentRule) {
      const { paymentSplitDetails: qantasPaymentSplitDetails } = qantasPaymentRule.paymentRule;
      if (
        qantasPaymentSplitDetails &&
        'splitAmount' in qantasPaymentSplitDetails &&
        qantasPaymentSplitDetails?.splitAmount?.amount
      ) {
        const splitAmountStepIndex = qantasPaymentSplitDetails?.splitAmount?.amount.findIndex(
          (selectedAmount) =>
            MoneyUtil.convertV2MoneyToMoneyUtil(selectedAmount).getQantasPoints()?.amount === selectedPoints,
        );

        pointsCashEquivalent = MoneyUtil.convertV2MoneyToMoneyUtil(
          qantasPaymentSplitDetails?.splitAmount?.amount?.[splitAmountStepIndex],
        );

        const nonQantasPaymentRule = qantasPaymentRule?.fopRule?.paymentRules?.find(
          (paymentRule) => !paymentRule.paymentSourceIds?.includes(qantasPointSource?.id ?? ''),
        );

        if (nonQantasPaymentRule) {
          const { paymentSplitDetails: nonQantasPaymentSplitDetails } = nonQantasPaymentRule;
          if (nonQantasPaymentSplitDetails && 'splitAmount' in nonQantasPaymentSplitDetails) {
            if (nonQantasPaymentSplitDetails?.splitAmount?.amount?.[splitAmountStepIndex]) {
              cashToBePaid = MoneyUtil.convertV2MoneyToMoneyUtil(
                nonQantasPaymentSplitDetails.splitAmount.amount[splitAmountStepIndex],
              );
            }
          }
        }
        return {
          cashToBePaid,
          pointsCashEquivalent,
        };
      }
    }
    return null;
  }

  public getPointSelectorPaymentDetails(selectedPoints: number): IGetPointSelectorPaymentDetailsReturn {
    const { allowedPaymentDetails } = this.response;

    const qantasPointsSource = this.findQantasRewardSource();

    const paymentRules = first(first(allowedPaymentDetails)?.allowedFopRules)?.paymentRules;

    const paymentRuleWithQantasPoints = paymentRules?.find((paymentRule) =>
      paymentRule.paymentSourceIds?.includes(qantasPointsSource?.id ?? ''),
    );

    let selectedPayments: IGetPointSelectorPaymentDetailsReturn = {
      paymentSourceIds: [],
      paymentSourceTypes: [],
      numSelectedPoints: undefined,
    };

    if (!paymentRuleWithQantasPoints?.paymentSplitDetails) {
      return selectedPayments;
    }

    const { paymentSplitDetails } = paymentRuleWithQantasPoints;

    // Case, when single mode of payment available
    if (paymentSplitDetails && 'minMaxAmount' in paymentRuleWithQantasPoints.paymentSplitDetails) {
      return {
        paymentSourceIds: [first(paymentRuleWithQantasPoints.paymentSourceIds) ?? ''],
        paymentSourceTypes: [PaymentSourceType.RewardsProgram],
      };
    }

    // Case, when split payment available
    if (paymentSplitDetails && 'splitAmount' in paymentSplitDetails) {
      const paymentRuleWithoutQantasPoints = paymentRules?.find(
        (paymentRule) => !paymentRule.paymentSourceIds?.includes(qantasPointsSource?.id ?? ''),
      );

      selectedPayments = {
        paymentSourceIds: [first(paymentRuleWithQantasPoints.paymentSourceIds) ?? ''],
        paymentSourceTypes: [PaymentSourceType.RewardsProgram],
        numSelectedPoints: selectedPoints,
      };

      const nonQantasPaymentSourceType = this.getPaymentSourceType(
        first(paymentRuleWithoutQantasPoints?.paymentSourceIds) ?? '',
      );

      const nonQantasPaymentSourceIds = first(paymentRuleWithoutQantasPoints?.paymentSourceIds);

      if (nonQantasPaymentSourceType && nonQantasPaymentSourceIds) {
        selectedPayments.paymentSourceTypes.push(nonQantasPaymentSourceType);
        selectedPayments.paymentSourceIds.push(nonQantasPaymentSourceIds);
      }
    }
    return selectedPayments;
  }

  public hasAmadeusCustomCards(paymentSourceIds: string[] | undefined) {
    return (
      this.response.bookingPaymentSources?.filter(
        (bookingPaymentSource) =>
          bookingPaymentSource?.metadata &&
          'customPaymentMethodMetadata' in bookingPaymentSource?.metadata &&
          bookingPaymentSource.metadata.customPaymentMethodMetadata &&
          'amadeusCheckoutMetadata' in bookingPaymentSource.metadata.customPaymentMethodMetadata &&
          bookingPaymentSource.metadata.customPaymentMethodMetadata.amadeusCheckoutMetadata?.paymentPageId &&
          paymentSourceIds?.includes(bookingPaymentSource?.id),
      ).length > 0
    );
  }

  public hasQantasPaymentIds(paymentSourceIds: string[] | undefined) {
    return (
      this.response.bookingPaymentSources?.filter(
        (paymentSource) =>
          paymentSource?.type === PaymentSourceType.RewardsProgram &&
          paymentSource?.metadata &&
          'rewardsProgramMetadata' in paymentSource?.metadata &&
          paymentSource?.metadata?.rewardsProgramMetadata &&
          paymentSource?.metadata?.rewardsProgramMetadata.type === RewardsProgramType.QantasPoints &&
          paymentSourceIds?.includes(paymentSource?.id),
      ).length > 0
    );
  }

  public getSelectedPayments = ({
    numSelectedPoints,
    paymentSourceIds,
  }: IGetSelectedPaymentDetailsProps): SelectedPayment[] => {
    const selectedPayments: SelectedPayment[] = [];
    const listBookingPaymentSourcesResponse = this.response;

    const paymentRules = first(
      first(listBookingPaymentSourcesResponse.allowedPaymentDetails)?.allowedFopRules,
    )?.paymentRules;

    const rewardPaymentSourceId = paymentSourceIds?.find(
      (selectedPaymentSourceId) =>
        this.getPaymentSourceType(selectedPaymentSourceId) === PaymentSourceType.RewardsProgram,
    );

    const qantasPaymentRule = paymentRules?.find((paymentRule) =>
      paymentRule.paymentSourceIds?.includes(rewardPaymentSourceId ?? ''),
    );

    let stepIndex = 0;

    if (qantasPaymentRule?.paymentSplitDetails && 'splitAmount' in qantasPaymentRule.paymentSplitDetails) {
      const selectedPoints = numSelectedPoints !== undefined ? numSelectedPoints : 0;

      stepIndex =
        qantasPaymentRule.paymentSplitDetails.splitAmount?.amount?.findIndex(
          (selectedAmount) =>
            MoneyUtil.convertV2MoneyToMoneyUtil(selectedAmount).getQantasPoints()?.amount === selectedPoints,
        ) ?? 0;
    }

    paymentRules?.forEach((paymentRule) => {
      const paymentRuleSourceId = paymentSourceIds?.find((selectedPaymentSourceId) =>
        paymentRule?.paymentSourceIds?.includes(selectedPaymentSourceId),
      );

      if (paymentRuleSourceId) {
        if (paymentRule?.paymentSplitDetails && 'splitAmount' in paymentRule.paymentSplitDetails) {
          const amount = paymentRule.paymentSplitDetails?.splitAmount?.amount?.[stepIndex];
          const amountValue = amount ? MoneyUtil.convertV2MoneyToMoneyV1(amount) : undefined;
          if (amountValue && !MoneyUtil.parse(amountValue).isAmountZero()) {
            selectedPayments.push({
              amount: amountValue,
              paymentSourceId: paymentRuleSourceId,
            });
          }
        }

        if (paymentRule?.paymentSplitDetails && 'minMaxAmount' in paymentRule.paymentSplitDetails) {
          const amount = paymentRule.paymentSplitDetails?.minMaxAmount?.maxLimit;
          if (amount) {
            selectedPayments.push({
              amount: MoneyUtil.convertV2MoneyToMoneyV1(amount),
              paymentSourceId: paymentRuleSourceId,
            });
          }
        }
      }
    });

    return selectedPayments;
  };

  public getPointSliderData = ({ numSelectedPoints, totalPoints, zeroMoney }: PointSlidersProps) => {
    const listBookingPaymentSourcesResponse = this.response;

    const paymentRules =
      listBookingPaymentSourcesResponse.allowedPaymentDetails?.[0]?.allowedFopRules?.[0]?.paymentRules;

    let cashToBePaid = zeroMoney;
    let pointsUsed = numSelectedPoints ?? 0;
    let remainingPointsBalance = totalPoints;
    let pointsCashEquivalent = zeroMoney;

    const qantasPointSource = this.findQantasRewardSource();

    const qantasPaymentRule = this.getFopRuleAndPaymentRuleByPaymentSourceId(qantasPointSource?.id);

    if (qantasPaymentRule?.paymentRule) {
      if (qantasPaymentRule?.paymentRule) {
        const { paymentSplitDetails: qantasPaymentSplitDetails } = qantasPaymentRule.paymentRule;

        // If split payment done
        // Check if qantas Payment Details present
        if (
          qantasPaymentSplitDetails &&
          'splitAmount' in qantasPaymentSplitDetails &&
          qantasPaymentSplitDetails?.splitAmount?.amount
        ) {
          const splitAmountStepIndex = qantasPaymentSplitDetails?.splitAmount?.amount.findIndex(
            (selectedAmount) =>
              MoneyUtil.convertV2MoneyToMoneyUtil(selectedAmount).getQantasPoints()?.amount === pointsUsed,
          );

          remainingPointsBalance = totalPoints - pointsUsed;

          pointsCashEquivalent = MoneyUtil.convertV2MoneyToMoneyUtil(
            qantasPaymentSplitDetails?.splitAmount?.amount?.[splitAmountStepIndex],
          );

          const nonQantasPaymentRule = qantasPaymentRule?.fopRule?.paymentRules?.find(
            (paymentRule) => !paymentRule.paymentSourceIds?.includes(qantasPointSource?.id ?? ''),
          );
          // Check if non qantas Payment Details present
          if (nonQantasPaymentRule) {
            const { paymentSplitDetails: nonQantasPaymentSplitDetails } = nonQantasPaymentRule;
            if (nonQantasPaymentSplitDetails && 'splitAmount' in nonQantasPaymentSplitDetails) {
              // if the points balance is less than minimum points splitAmountStepIndex would be -1
              if (nonQantasPaymentSplitDetails?.splitAmount?.amount?.[splitAmountStepIndex]) {
                cashToBePaid = MoneyUtil.convertV2MoneyToMoneyUtil(
                  nonQantasPaymentSplitDetails.splitAmount.amount[splitAmountStepIndex],
                );
              } else {
                cashToBePaid = MoneyUtil.convertV2MoneyToMoneyUtil(
                  first(nonQantasPaymentSplitDetails.splitAmount?.amount),
                );
              }
            }
          }

          return {
            pointsUsed,
            remainingPointsBalance,
            cashToBePaid,
            pointsCashEquivalent,
          };
        }

        if (
          qantasPaymentSplitDetails &&
          'minMaxAmount' in qantasPaymentSplitDetails &&
          qantasPaymentSplitDetails.minMaxAmount?.minLimit
        ) {
          pointsUsed =
            MoneyUtil.convertV2MoneyToMoneyUtil(qantasPaymentSplitDetails.minMaxAmount.maxLimit).getQantasPoints()
              ?.amount ?? 0;
          cashToBePaid = MoneyUtil.convertV2MoneyToMoneyUtil(qantasPaymentSplitDetails.minMaxAmount.maxLimit);
          pointsCashEquivalent = MoneyUtil.convertV2MoneyToMoneyUtil(qantasPaymentSplitDetails.minMaxAmount.maxLimit);
        }
      }
    } else if (
      paymentRules &&
      paymentRules[0] &&
      paymentRules[0].paymentSplitDetails &&
      'minMaxAmount' in paymentRules[0]?.paymentSplitDetails &&
      paymentRules[0].paymentSplitDetails?.minMaxAmount?.minLimit
    ) {
      pointsUsed =
        MoneyUtil.convertV2MoneyToMoneyUtil(paymentRules[0].paymentSplitDetails.minMaxAmount.maxLimit).getQantasPoints()
          ?.amount ?? 0;
      cashToBePaid = MoneyUtil.convertV2MoneyToMoneyUtil(paymentRules[0].paymentSplitDetails.minMaxAmount.maxLimit);
      pointsCashEquivalent = zeroMoney;
    }
    return {
      pointsUsed,
      remainingPointsBalance,
      cashToBePaid,
      pointsCashEquivalent,
    };
  };
}
