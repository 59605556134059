import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Building = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { width: "28", height: "28", rx: "8", fill: "#F7F9FB" }),
        React.createElement("path", { d: "M19.5 19H19V17.2501H16.875C16.668 17.2501 16.5 17.0821 16.5 16.8751C16.5 16.6681 16.668 16.5001 16.875 16.5001H19V15.2502H16.875C16.668 15.2502 16.5 15.0822 16.5 14.8752C16.5 14.6682 16.668 14.5002 16.875 14.5002H19V13.3753C19 12.8923 18.608 12.5003 18.125 12.5003H15.5V19H15V8.37548C15 8.11699 14.7435 7.935 14.499 8.022L9.5805 9.77892C9.2325 9.90292 9 10.2329 9 10.6029V12.0003H11.625C11.832 12.0003 12 12.1683 12 12.3753C12 12.5823 11.832 12.7503 11.625 12.7503H9V14.0002H11.625C11.832 14.0002 12 14.1682 12 14.3752C12 14.5822 11.832 14.7502 11.625 14.7502H9V19H8.5C8.224 19 8 19.2235 8 19.5C8 19.7765 8.224 20 8.5 20H19.5C19.776 20 20 19.7765 20 19.5C20 19.2235 19.776 19 19.5 19Z", fill: themeEnrichedPrimaryColor })));
};
Building.defaultProps = defaultIconProps;
export default Building;
