import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Passport = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("rect", { strokeMiterlimit: "10", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x: "9.01", y: "5.99", width: "13.98", height: "20.02", rx: "1.36" }),
        React.createElement("line", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x1: "11.7", y1: "22.57", x2: "20.32", y2: "22.57" }),
        React.createElement("circle", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", cx: "16", cy: "13.82", r: "4.32" }),
        React.createElement("ellipse", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", cx: "16", cy: "13.82", rx: "1.56", ry: "4.32" }),
        React.createElement("line", { strokeLinecap: "round", strokeLinejoin: "round", fill: "none", stroke: themeEnrichedPrimaryColor, strokeWidth: "1.72px", x1: "11.68", y1: "13.82", x2: "20.32", y2: "13.82" })));
};
Passport.defaultProps = defaultIconProps;
export default Passport;
