import { css } from '@emotion/react';
import { breakpoints, flex, margins, themed } from '@spotnana/blocks/src/utils';
import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const side_drawer = themed(
  ({ palette }) => css`
    .MuiDialogContent-root.BlocksModal-content {
      position: relative;
      box-shadow: ${palette.shadow.scrollTopShadow};
    }

    &.BlocksModal-root .MuiDialog-paperFullScreen {
      ${margins.y.m0}
      border-radius: 0 !important;
    }

    .BlocksModal-header {
      ${flex.init}
      ${flex.justify.center}
    ${paddings.x.extraCozy}
    ${paddings.y.cozy}
    }

    ${breakpoints.from('tablet')} {
      .MuiDialog-container {
        margin: 0;
        position: absolute;
        right: 0;
        max-width: 568px;
      }

      .MuiPaper-root {
        height: 100%;
        min-height: 100%;
        border-radius: 0 !important;
        ${margins.x.m0}
        ${margins.y.m0}
      }
    }
  `,
);
