import { ServiceContentEnum, FileUploadErrorsEnum } from '../../types/concierge';

export const servicesContent = {
  [ServiceContentEnum.COMPLEX_TRIP]: [
    'Required Services',
    'Dates',
    'Cities',
    'Budget',
    'Links to specific travel site',
  ],
  [ServiceContentEnum.AIRPORT_TRANSFER]: [
    'Pick-up and drop-off location',
    'Pick-up and drop-off date',
    'Pick-up and drop-off time',
    'No. of passengers',
    'Please confirm the lead passenger and mobile number',
  ],
  [ServiceContentEnum.OTHERS]: ['Feel free to share details with us so that we can help with your travel needs.'],
};
export const placeHolderContent = {
  [ServiceContentEnum.COMPLEX_TRIP]: 'Share details of your complex trip requirements here...',
  [ServiceContentEnum.AIRPORT_TRANSFER]: 'Add details for your airport transport request here...',
  [ServiceContentEnum.OTHERS]: 'Please describe what you need...',
};
export const FileConfig = {
  fileSize: 7340032,
  noOfFiles: 3,
  // eslint-disable-next-line no-restricted-syntax
  fileType: '.jpeg,.jpg,.png',
};
export const UploadErrors = {
  [FileUploadErrorsEnum.SIZE_ERROR]: 'Maximum 7MB files can be uploaded',
  [FileUploadErrorsEnum.FORMAT_ERROR]: 'Only JPEG,JPG and PNG supported',
  [FileUploadErrorsEnum.FILE_LIMIT_ERROR]: 'Maximum 3 files can be uploaded',
};
