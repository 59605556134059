import type { IAirRevalidateItineraryRequest } from '../../types';

export const getPaymentSourceIdsForAirRevalidateKey = (fareBreakupRequest: IAirRevalidateItineraryRequest | null) => {
  const paymentSourceIds: Set<string> = new Set();
  if (fareBreakupRequest?.bookingPaymentDetails?.bookingTravelerPaymentDetails) {
    fareBreakupRequest?.bookingPaymentDetails?.bookingTravelerPaymentDetails.forEach((paymentDetails) =>
      paymentDetails.selectedFops.forEach((fop) =>
        fop.selectedPayments.forEach((selectedPayment) => {
          paymentSourceIds.add(selectedPayment.paymentSourceId);
        }),
      ),
    );
  }
  // bookingCharges and bookingPaymentDetails dont occur together
  if (fareBreakupRequest?.bookingCharges) {
    fareBreakupRequest?.bookingCharges?.forEach((bookingCharge) => {
      if (bookingCharge.selectedPaymentSource?.paymentSourceId) {
        paymentSourceIds.add(bookingCharge.selectedPaymentSource?.paymentSourceId);
      }
    });
  }
  return [...paymentSourceIds];
};
