import { PropsWithChildren } from 'react';
import { globalTranslationKeys } from 'obt-common';
import { Flex } from '@spotnana/pixel-react/dist/Box';
import cssWrapper from '@spotnana/pixel-react/dist/utils/cssWrapper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Popover } from '@spotnana/blocks/src/Popover';
import { themed } from '@spotnana/blocks/src/utils';
import { css } from '@emotion/react';

const menu_list_item = themed(
  ({ palette }) => css`
    display: block;
    left: -260px;
    top: 0;
    width: 260px;
    border-radius: 8px 0 0 8px;
  `,
);

const popover_menu = themed(
  ({ palette }) => css`
    top: -10px;
    right: 16px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: ${palette.shadow.componentShadow};
    background-color: ${palette.surface.menu};
  `,
);

const trigger_button = themed(
  ({ fontWeight }) => css`
    :hover {
      font-weight: ${fontWeight.w600};
    }
  `,
);

const FlexStyled = styled(Flex)(
  cssWrapper({
    position: 'relative',
    height: '42px',
    fontWeight: 'regular',
    transition: 'regular',
    padding: '0 24px 0 20px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderLeft: '4px solid',
    borderColor: 'primary',
    ':hover': {
      fontWeight: 'semiBold',
      transition: 'regular',
    },
  }),
);

const InternalMenu = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const { INTERNAL_SUB_MENU } = globalTranslationKeys;
  const { t } = useTranslation();
  return (
    <FlexStyled>
      <Popover
        htmlButtonElement={
          <Typography variant="body1" css={trigger_button}>
            {t(INTERNAL_SUB_MENU)}
          </Typography>
        }
        placement="left-start"
        css={popover_menu}
      >
        <div className="internal-sub-menu-list" css={menu_list_item}>
          {children}
        </div>
      </Popover>
    </FlexStyled>
  );
};

export default InternalMenu;
