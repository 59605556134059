import { Config } from 'obt-common';
import { ROUTE_PATHS } from './routes';

const { href, origin } = window.location;
const isHeadlessRoute = href.split('?')[0].endsWith(ROUTE_PATHS.HEADLESS_AUTH);
const loginIdentifier = '?code=';
const isLoggingIn = href.includes(loginIdentifier);

const redirectURL = (() => {
  if (isHeadlessRoute) {
    // Get the redirect url including basepath while preserving the ending slash
    return href.slice(0, href.indexOf(ROUTE_PATHS.HEADLESS_AUTH) + 1);
  }
  if (isLoggingIn) {
    return href.slice(0, href.indexOf(loginIdentifier));
  }
  return `${origin}/`;
})();

const amplifyConfig = {
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: Config.VITE_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: Config.VITE_COGNITO_CLIENT_ID,
  oauth: {
    domain: Config.VITE_COGNITO_DOMAIN,
    // The scopes here and redirect urls should match what is configured in the user pool in AWS Cognito.
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: redirectURL,
    redirectSignOut: redirectURL,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default amplifyConfig;
