import { css } from '@emotion/react';
import { paddings } from '../utils/styling/utilityTokens';

export const child_padding = css`
  ${paddings.xl.comfortable}
`;
export const title_padding = css`
  ${paddings.x.comfortable}
  ${paddings.yt.tight}
`;
export const option_list_padding = css`
  .BlocksSelect-OptionListWrapper {
    ${paddings.x.none}
    ${paddings.y.tight}
  }
`;
