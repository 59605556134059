import { timeFormats } from '../constants/common';
import type { DateTimeOffsetWithZone } from '../types/api/v2/obt/model/date-time-offset-with-zone';
import dateUtil from './dateUtil';

const getTimezoneAwareFormattedDateTimePeriod = (dateTime?: DateTimeOffsetWithZone): string => {
  try {
    if (!dateTime) {
      return '';
    }
    if (!dateTime.zoneId) {
      return dateUtil.parseZone(dateTime.iso8601).format(timeFormats.HR12);
    }
    return dateUtil(dateTime.iso8601).tz(dateTime.zoneId).format(timeFormats.HR12);
  } catch {
    return '';
  }
};

export default getTimezoneAwareFormattedDateTimePeriod;
