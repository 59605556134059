import { useMemo } from 'react';
import defaults from 'lodash/defaults';
import { FeatureConfigNameEnum } from '../types/api/v2/obt/model/feature-config';
import { useLoggedInUser } from '../providers/AuthProvider';
import { useFetchCompanyBasicInfo } from '../queries/admin/index';
import type { AddressCustomizationRule } from '../types/addressCustomization';

export interface IGeoCustomizationConfig {
  showFlags: boolean;
  addressCustomizations: AddressCustomizationRule[];
  restrictedCountries: string[];
}

interface IUseGeoCustomizationResult {
  geoCustomization: IGeoCustomizationConfig;
  isLoading: boolean;
}

/**
 * This config should be set in a way to ensure non-jarring transitions while data is being fetched
 */
const LOADING_CUSTOMIZATION_CONFIG: IGeoCustomizationConfig = {
  // initially keep the flags hidden till data is loading, so that transition is from false -> true only
  showFlags: false,
  addressCustomizations: [],
  restrictedCountries: [],
};

export const DEFAULT_CUSTOMIZATION_CONFIG: IGeoCustomizationConfig = {
  showFlags: true,
  addressCustomizations: [],
  restrictedCountries: [],
};

/**
 * Hook to get the Geo Customization configuration for the company
 * @returns
 */
export function useGeoCustomization({ enabled = true } = {}): IUseGeoCustomizationResult {
  // TODO: Consume using usePartnerFeatures when it has generic type support for feature values
  const { data: loggedInUser } = useLoggedInUser();
  const { data: basicinfo, isLoading } = useFetchCompanyBasicInfo(
    loggedInUser?.userOrgId?.organizationId?.id,
    enabled,
    undefined,
  );

  const features = basicinfo?.features;

  const geoCustomization = useMemo(() => {
    // during loading state, return a loading config
    if (isLoading) {
      return LOADING_CUSTOMIZATION_CONFIG;
    }

    const customizationFeature = features?.find((feature) => feature.name === FeatureConfigNameEnum.GeoCustomizations);

    // If the feature is not enabled, return the default configuration
    if (!customizationFeature) {
      return DEFAULT_CUSTOMIZATION_CONFIG;
    }

    // try parsing the customization config
    try {
      const parsedConfig = JSON.parse(customizationFeature.value ?? '') as Partial<IGeoCustomizationConfig>;
      return defaults({}, parsedConfig, DEFAULT_CUSTOMIZATION_CONFIG);
    } catch (error) {
      console.error('Invalid Customization JSON:', error);
      return DEFAULT_CUSTOMIZATION_CONFIG;
    }
  }, [features, isLoading]);

  return {
    geoCustomization,
    isLoading,
  };
}
