import { css } from '@emotion/react';
import { themed } from '../../utils';

export const icon_style = themed(
  ({ palette }) => css`
    background-size: contain;
    height: 16px;
    width: 16px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
    color: ${palette.text.primary};
  `,
);

export const icon_dropdown = themed(
  ({ palette }) => css`
    display: flex;
    width: 20px;
    height: 18px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: ${palette.text.primary};
  `,
);
