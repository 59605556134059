import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Copy = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 27 26", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.075 4.875H6.025C5.6662 4.875 5.375 5.1662 5.375 5.525V16.575C5.375 16.9338 5.6662 17.225 6.025 17.225H17.075C17.4338 17.225 17.725 16.9338 17.725 16.575V5.525C17.725 5.1662 17.4338 4.875 17.075 4.875ZM16.425 15.925H6.675V6.175H16.425V15.925ZM20.325 19.825V18.2H21.625V20.475C21.625 20.8338 21.3338 21.125 20.975 21.125H18.7V19.825H20.325ZM13.7015 19.825H17.1985V21.125H13.7015V19.825ZM10.575 18.2V19.825H12.2V21.125H9.925C9.5662 21.125 9.275 20.8338 9.275 20.475V18.2H10.575ZM20.975 8.775H18.7V10.075H20.325V11.7H21.625V9.425C21.625 9.0662 21.3338 8.775 20.975 8.775ZM21.625 16.6985V13.2015H20.325V16.6985H21.625Z", fill: themeEnrichedPrimaryColor })));
};
Copy.defaultProps = defaultIconProps;
export default Copy;
