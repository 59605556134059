import React, { useRef, useEffect, useState, PropsWithChildren } from 'react';
import { TTooltipProps, Tooltip } from '../Tooltip';
import { ellipsis_style } from './Ellipsis.styles';

export type TEllipsisProps = PropsWithChildren<{
  title: React.ReactNode;
  width?: number | string;
  tooltipPlacement?: TTooltipProps['placement'];
  dataTestId?: string;
}>;

export const Ellipsis: React.FC<TEllipsisProps> = ({
  title,
  width,
  tooltipPlacement = 'top',
  children,
}: TEllipsisProps) => {
  const [showTooltip, setShowToolTip] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current && wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth && width) {
      setShowToolTip(true);
    } else {
      setShowToolTip(false);
    }
  }, [width]);

  return (
    <Tooltip title={showTooltip ? title : ''} placement={tooltipPlacement} variant="label">
      <span ref={wrapperRef} css={ellipsis_style(width)} data-testid={title}>
        {children}
      </span>
    </Tooltip>
  );
};
