import { CompanyOnboardingStep } from '@spotnana/types/openapi/models/company-onboarding-step';
import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const OnboardingPage = lazy(() => import('./OnboardingPage'));

export interface RouteMap {
  [key: string]: {
    id: CompanyOnboardingStep;
    key: string;
    path: string;
    page: string;
  };
}

const routes = ['overview', 'legal-entities', 'offices', 'travelers', 'policies', 'payment', 'summary'];

export const onboardingRoutesMap = routes.reduce<RouteMap>((acc, route) => {
  const key = route.toUpperCase().replace('-', '_');
  acc[key] = {
    id: key as CompanyOnboardingStep,
    key: `onboarding-${route}`,
    path: `/onboarding/${route}`,
    page: route,
  };
  return acc;
}, {});

export const onboardingRoutes: IRouteConfig[] = [
  {
    key: 'onboarding',
    path: '/onboarding/:page(overview|legal-entities|offices|travelers|policies|payment|summary)?',
    component: OnboardingPage,
  },
];
