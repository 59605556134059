import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { addBreadcrumb } from '../utils/errorTracking';

export const useAddBreadcrumbsOnUrlChange = () => {
  const { pathname, search: searchPath } = useLocation();
  useEffect(() => {
    addBreadcrumb({
      message: 'Url changed',
      category: 'app',
      data: { url: window.location.href },
    });
  }, [pathname, searchPath]);
};
