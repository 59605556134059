import { useState, useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { INTERVAL_THROTTLE_IN_MILLISECONDS, ONE_SECOND_IN_MILLISECONDS } from '../constansts';

interface TimeProps {
  getRemainingTime: () => number;
}

export function TimeInSeconds({ getRemainingTime }: TimeProps): ReactElement | null {
  const [remaining, setRemaining] = useState(() => Math.ceil(getRemainingTime() / ONE_SECOND_IN_MILLISECONDS));

  const { t: tt } = useTranslation('WEB');

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / ONE_SECOND_IN_MILLISECONDS));
    }, INTERVAL_THROTTLE_IN_MILLISECONDS);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  return tt('{{count}} seconds', { count: remaining }) as ReactElement | null;
}
