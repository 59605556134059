var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import css from '@styled-system/css';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSecureImageURL } from '../utils';
import { ImageContainer } from './styles';
var ImageLoadStatus;
(function (ImageLoadStatus) {
    ImageLoadStatus[ImageLoadStatus["LOADED"] = 0] = "LOADED";
    ImageLoadStatus[ImageLoadStatus["ERROR"] = 1] = "ERROR";
    ImageLoadStatus[ImageLoadStatus["LOADING"] = 2] = "LOADING";
})(ImageLoadStatus || (ImageLoadStatus = {}));
const HiddenImage = styled.img({
    visibility: 'hidden',
});
const StyledImage = styled.img(({ width, height }) => css({
    width,
    height,
}));
const ImageComp = (_a) => {
    var { fallback, containerStyles, containerCssStyles, src, alt, width, height, 'data-testid': dataTestId, loaderType = 'circle' } = _a, rest = __rest(_a, ["fallback", "containerStyles", "containerCssStyles", "src", "alt", "width", "height", 'data-testid', "loaderType"]);
    const [imageStatus, setImageStatus] = useState(src ? ImageLoadStatus.LOADING : null);
    const onLoad = () => setImageStatus(ImageLoadStatus.LOADED);
    const onError = () => {
        // Adding the console error so that we become aware of images that needs to be added
        // eslint-disable-next-line no-console
        console.error(`Issue with loading image: ${src}`);
        setImageStatus(ImageLoadStatus.ERROR);
    };
    useEffect(() => {
        if (src) {
            setImageStatus(ImageLoadStatus.LOADING);
            /**
             * onload fires immediately after browser loads the object for the first time
             * The below code is needed when browser renders imageA, then renders imageB, and again renders image A
             * In this case, since imageA was already loaded by the browser previously, onload doesn't trigger
             * And thus, imageStatus always remains LOADING. So creating a new instance of image ensures we mitigate this
             */
            const image = new Image();
            image.src = getSecureImageURL(src);
            image.onload = onLoad;
            image.onerror = onError;
        }
        else {
            setImageStatus(ImageLoadStatus.ERROR);
        }
        return () => {
            setImageStatus(null);
        };
    }, 
    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [src]);
    return (React.createElement(ImageContainer, { width: width, height: height, backgroundColor: imageStatus === ImageLoadStatus.LOADING ? 'bg.paleGray' : 'bg.transparent', borderRadius: loaderType === 'circle' ? 'circle' : 'sm', alignItems: "center", justifyContent: "center", display: "flex", "data-testid": `img-container-${dataTestId}`, style: containerStyles, cssStyles: containerCssStyles },
        imageStatus === ImageLoadStatus.ERROR && fallback,
        imageStatus === ImageLoadStatus.LOADED && (React.createElement(StyledImage, Object.assign({}, rest, { src: getSecureImageURL(src), alt: alt, width: width, height: height, "data-testid": dataTestId }))),
        imageStatus === ImageLoadStatus.LOADING && (
        /**
         * onLoad and onError here are useful for unit testing
         */
        React.createElement(HiddenImage, Object.assign({}, rest, { alt: alt, width: width, height: height, "data-testid": `img-loading-${dataTestId}`, onLoad: onLoad, onError: onError })))));
};
/**
 * @deprecated
 */
export default ImageComp;
