import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const ContactUser = ({ primaryColor }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: "14", height: "18", viewBox: "0 0 14 18", fill: themeEnrichedPrimaryColor, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M8.66683 0.666626H2.00016C1.0835 0.666626 0.333496 1.41663 0.333496 2.33329V15.6666C0.333496 16.5833 1.0835 17.3333 2.00016 17.3333H12.0002C12.9168 17.3333 13.6668 16.5833 13.6668 15.6666V5.66663L8.66683 0.666626ZM7.00016 7.33329C7.91683 7.33329 8.66683 8.08329 8.66683 8.99996C8.66683 9.91663 7.91683 10.6666 7.00016 10.6666C6.0835 10.6666 5.3335 9.91663 5.3335 8.99996C5.3335 8.08329 6.0835 7.33329 7.00016 7.33329ZM10.3335 14H3.66683V13.525C3.66683 12.85 4.06683 12.25 4.6835 11.9833C5.39183 11.675 6.17516 11.5 7.00016 11.5C7.82516 11.5 8.60849 11.675 9.31683 11.9833C9.93349 12.25 10.3335 12.85 10.3335 13.525V14Z" })));
};
ContactUser.defaultProps = defaultIconProps;
export default ContactUser;
