function join(path, key) {
    if (!path) {
        return key;
    }
    return `${path}-${key}`;
}
function generateVariables(path, object) {
    let styles = '';
    Object.entries(object).forEach(([key, value]) => {
        const newPath = join(path, key);
        styles += typeof value === 'object' ? generateVariables(newPath, value) : `--${newPath}: ${value};`;
    });
    return styles;
}
function getCSSVarsFromTheme(theme) {
    return generateVariables('', theme);
}
export default function getThemeStyles(theme) {
    return `:root {${Object.values(theme).map(getCSSVarsFromTheme).join('')}}`;
}
