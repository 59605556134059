/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Location of the message. The value will be extracted on runtime.
 * @export
 * @enum {string}
 */

export const SiteMessageLocation = {
  MainPage: 'MAIN_PAGE',
  SearchResultPage: 'SEARCH_RESULT_PAGE',
  CheckoutPage: 'CHECKOUT_PAGE',
  TripsPage: 'TRIPS_PAGE',
  ItineraryEmail: 'ITINERARY_EMAIL',
  InvoiceEmail: 'INVOICE_EMAIL',
  SearchPage: 'SEARCH_PAGE',
  SupportPage: 'SUPPORT_PAGE',
} as const;

export type SiteMessageLocation = (typeof SiteMessageLocation)[keyof typeof SiteMessageLocation];
