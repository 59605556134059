import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Search = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.5002 5.75C17.7804 5.75 21.25 9.2197 21.25 13.5C21.25 15.356 20.5976 17.0596 19.5097 18.394C19.8068 18.3636 20.1135 18.4397 20.3677 18.6239L20.4573 18.6954L20.5184 18.7522L25.2593 23.467C25.748 23.9547 25.7488 24.7461 25.2611 25.2348C24.819 25.6778 24.1228 25.7221 23.6245 25.3498L23.5547 25.2937L23.4937 25.2369L18.7527 20.5221C18.4759 20.2458 18.3556 19.8721 18.392 19.5111C17.0581 20.5982 15.3553 21.25 13.5002 21.25C9.21994 21.25 5.75 17.7802 5.75 13.5C5.75 9.2198 9.21994 5.75 13.5002 5.75ZM13.5003 7.52066C16.8026 7.52066 19.4795 10.1976 19.4795 13.5C19.4795 16.8024 16.8026 19.4794 13.5003 19.4794C10.1979 19.4794 7.52067 16.8023 7.52067 13.5C7.52067 10.1977 10.1979 7.52066 13.5003 7.52066Z", fill: themeEnrichedPrimaryColor })));
};
Search.defaultProps = defaultIconProps;
export default Search;
