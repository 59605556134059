/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Specifies the length or distance.
 * @export
 * @interface Length
 */
export interface Length {
    /**
     * Distance from search point.
     * @type {number}
     * @memberof Length
     */
    length: number;
    /**
     * Unit of measure.
     * @type {string}
     * @memberof Length
     */
    unit: LengthUnit;
}

/**
    * @export
    * @enum {string}
    */
export enum LengthUnitEnum {
    UnknownUnit = 'UNKNOWN_UNIT',
    Km = 'KM',
    Mile = 'MILE'
}

export type LengthUnit = 0 | 1 | 2 | -1;



