/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The preference of coach.
 * @export
 * @enum {string}
 */

export const CoachPref = {
  Smoking: 'SMOKING',
  NonSmoking: 'NON_SMOKING',
  PetsAllowed: 'PETS_ALLOWED',
  Restaurant: 'RESTAURANT',
  Quiet: 'QUIET',
} as const;

export type CoachPref = (typeof CoachPref)[keyof typeof CoachPref];
