import dateUtil from '../dateUtil';
import { dateFormats } from '../../constants/common';
import getDurationDays from '../getDurationDays';

const getPastDateFromDuration = (durationStr: string, dateString: string, dateFormat: string): string => {
  const durationDays = getDurationDays(durationStr);
  return dateUtil(dateString, dateFormat).subtract(durationDays, 'd').format(dateFormats.DATE_YEAR);
};

export default getPastDateFromDuration;
