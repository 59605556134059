/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Fare component eligible for this fop rule
 * @export
 * @enum {string}
 */

export const FareComponent = {
  Base: 'BASE',
  Tax: 'TAX',
} as const;

export type FareComponent = (typeof FareComponent)[keyof typeof FareComponent];
