import { css, keyframes } from '@emotion/react';
import { parseToRgb } from 'polished';
import { themed } from '../utils';
import { paddings, spacings } from '../utils/styling/utilityTokens';

const createLoadingAnimation = (color: string) => {
  const { red, green, blue } = parseToRgb(color);
  const rgba = (alpha: number) => `rgba(${red}, ${green}, ${blue}, ${alpha})`;

  return keyframes`
    0%  {box-shadow: 20px 0 ${rgba(1)}, -20px 0 ${rgba(0.2)}; background: ${rgba(1)} }
    33% {box-shadow: 20px 0 ${rgba(1)}, -20px 0 ${rgba(0.2)}; background: ${rgba(0.2)}}
    66% {box-shadow: 20px 0 ${rgba(0.2)}, -20px 0 ${rgba(1)}; background: ${rgba(0.2)}}
    100%{box-shadow: 20px 0 ${rgba(0.2)}, -20px 0 ${rgba(1)}; background: ${rgba(1)} }
  `;
};

export const button_base = themed(
  ({ palette, typography }) => css`
    &.edge-placement-left {
      margin-left: -8px;
    }
    &.edge-placement-right {
      margin-right: -8px;
    }
    &.MuiButton-root {
      height: auto;
      width: auto;
    }
    &.MuiButtonBase-root {
      &.MuiButton-sizeSmall {
        ${spacings.extraSmall}
      }
      &.MuiButton-sizeLarge,
      &.MuiButton-sizeMedium {
        ${spacings.small}
      }
    }

    &.BlocksButton-fullWidth {
      width: 100%;
    }

    & > .MuiCircularProgress-root {
      color: ${palette.icon.brand};
    }

    &.MuiButton-outlined {
      border: 1px solid ${palette.border.light};

      &:hover {
        border: 1px solid ${palette.border.brand};
      }
    }

    &.MuiButton-text {
      &:hover {
        background-color: ${palette.surface.background};
      }
    }

    &:focus-visible {
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: 1px;
    }

    &.MuiButton-sizeLarge {
      min-width: 6rem;
      ${paddings.x.cozy}
      ${paddings.y.compact}
      height: 48px;

      ${css(typography.body1.default)}
      ${css(typography.body1.medium)}
      & > .MuiCircularProgress-root {
        width: 15px;
        height: 15px;
      }
    }

    &.MuiButton-sizeMedium {
      min-width: 5rem;
      ${paddings.x.comfortable}
      ${paddings.y.buttonMedium}
      height: 40px;

      ${css(typography.body2.default)}
      ${css(typography.body2.medium)}
      & > .MuiCircularProgress-root {
        width: 12px;
        height: 12px;
      }
    }

    &.MuiButton-sizeSmall {
      min-width: 4rem;
      ${paddings.x.compact}
      ${paddings.y.buttonSmall}
      height: 32px;

      ${css(typography.body2.default)}
      ${css(typography.body2.medium)}
      & > .MuiCircularProgress-root {
        width: 12px;
        height: 12px;
      }
    }

    & > .BlocksButton-loading-content {
      width: 10px;
      aspect-ratio: 1;
      border-radius: 50%;
      animation: ${createLoadingAnimation(palette.text.secondary)} 1s infinite linear;
    }

    &.Mui-disabled {
      cursor: not-allowed;
      pointer-events: all;
      text-decoration: none;
    }
  `,
);

export const button_with_icon = css`
  &.BlocksButton-loading {
    & > .BlocksButton-content {
      margin-left: -4px;
    }
  }
  &.MuiButton-sizeLarge {
    & > .BlocksButton-Icon {
      font-size: 24px;
    }
  }

  &.MuiButton-sizeMedium {
    & > .BlocksButton-Icon {
      font-size: 20px;
    }
  }

  &.MuiButton-sizeSmall {
    & > .BlocksButton-Icon {
      font-size: 16px;
    }
    &.BlocksButton-loading {
      & > .BlocksButton-content {
        margin-left: 0px;
      }
    }
  }
`;

export const button_regular = themed(
  ({ palette }) => css`
    &.Mui-disabled {
      color: ${palette.text.disabled};
      background: none;

      &:hover {
        background: none;
        text-decoration: none;
      }

      &:focus-visible {
        background: none;
      }

      & > .BlocksButton-Icon {
        color: ${palette.icon.disabled};
      }

      &.MuiButton-contained,
      &.MuiButton-outlined {
        background-color: ${palette.bg.buttonDisabled};
        border: 1px solid ${palette.border.medium};
      }

      & > .BlocksButton-content {
        text-decoration: none;
      }
    }

    &.BlocksButton-selected {
      &.MuiButton-contained,
      &.MuiButton-outlined {
        background: ${palette.bg.successSubtle};
        border: 1px solid ${palette.border.successSubtle};
      }
      &.MuiButton-text {
        background: none;
      }

      color: ${palette.text.success};

      & > .BlocksButton-Icon {
        color: ${palette.icon.success};
      }
    }
  `,
);

const button_outline = ({
  textColor,
  borderColor,
  hoverBorderColor,
}: {
  textColor: string;
  borderColor: string;
  hoverBorderColor: string;
}) =>
  themed(
    ({ palette }) => css`
      &.BlocksButton-secondary,
      &.BlocksButton-outline {
        color: ${textColor};
        background-color: ${palette.surface.base};
        border: 1px solid ${borderColor};

        &:hover {
          border: 1px solid ${hoverBorderColor};
          background-color: ${palette.surface.background};
        }

        & > .BlocksButton-Icon {
          color: ${textColor};
        }
      }

      &.BlocksButton-ghost {
        color: ${textColor};
        background-color: transparent;
        ${paddings.x.tight}
        border: none;

        &:hover {
          background-color: transparent;
          text-decoration: underline;
          text-underline-position: under;
          border: none;
        }

        & > .BlocksButton-Icon {
          color: ${textColor};
        }

        &.BlocksButton-underline > .BlocksButton-content {
          text-decoration: underline;
          text-underline-position: under;
        }

        &.BlocksButton-underline > .BlocksButton-disabled-content {
          text-decoration: none;
        }
      }

      &.BlocksButton-secondary&.BlocksButton-selected {
        border: 1px solid ${palette.border.successSubtle};
        color: ${palette.text.success};

        & > .BlocksButton-Icon {
          color: ${palette.text.success};
        }
      }

      &.BlocksButton-ghost&.BlocksButton-selected {
        color: ${palette.text.success};

        & > .BlocksButton-Icon {
          color: ${palette.text.success};
        }
      }

      &.BlocksButton-disabled-content {
        text-decoration: none;
      }
    `,
  );

export const button_primary = themed(
  ({ palette }, { compose }) => css`
    background-color: ${palette.bg.brand};
    color: ${palette.text.buttonLabel};

    &:hover {
      background-color: ${palette.bg.brandHover};
    }

    & > .BlocksButton-Icon {
      color: ${palette.text.buttonLabel};
    }

    & > .MuiTouchRipple-root {
      color: ${palette.bg.brandSubtle};
    }

    &.BlocksButton-secondary {
      min-width: 64px;
    }

    &.BlocksButton-loading {
      background-color: ${palette.bg.brand} !important;
      border: 1px solid ${palette.bg.brand} !important;
    }

    & > .BlocksButton-loading-content {
      animation: ${createLoadingAnimation(palette.icon.buttonIcon)} 1s infinite linear;
    }

    ${compose(
      button_outline({
        textColor: palette.text.brand,
        borderColor: palette.border.light,
        hoverBorderColor: palette.border.brand,
      }),
    )}
  `,
);

export const button_secondary = themed(
  ({ palette }) => css`
    min-width: 64px;
    background-color: ${palette.surface.base};
    color: ${palette.text.primary};
    border: 1px solid ${palette.border.light};

    &:hover {
      background-color: ${palette.surface.background};
      border: 1px solid ${palette.border.hover};
    }

    & > .BlocksButton-Icon {
      color: ${palette.icon.primary};
    }

    & > .MuiTouchRipple-root {
      color: ${palette.bg.hover};
    }
  `,
);

export const button_outline_primary = themed(
  ({ palette }, { compose }) => css`
    ${compose(
      button_outline({
        textColor: palette.text.brand,
        borderColor: palette.border.brand,
        hoverBorderColor: palette.border.brand,
      }),
    )}
  `,
);

export const button_outline_error = themed(
  ({ palette }, { compose }) => css`
    ${compose(
      button_outline({
        textColor: palette.text.error,
        borderColor: palette.border.error,
        hoverBorderColor: palette.border.error,
      }),
    )}
  `,
);

export const button_ghost_blue = themed(
  ({ palette }, { compose }) => css`
    ${compose(
      button_outline({
        textColor: palette.text.link,
        borderColor: palette.border.light,
        hoverBorderColor: palette.surface.background,
      }),
    )}
  `,
);

export const button_ghost_gray = themed(
  ({ palette }, { compose }) => css`
    color: ${palette.text.secondary};
    & > .BlocksButton-Icon {
      color: ${palette.icon.secondary};
    }

    & > .MuiTouchRipple-root {
      color: ${palette.bg.hover};
    }

    & > .BlocksButton-loading-content,
    .BlocksButton-selected-content {
      text-decoration: unset;
    }

    ${compose(
      button_outline({
        textColor: palette.text.secondary,
        borderColor: palette.border.light,
        hoverBorderColor: palette.surface.background,
      }),
    )}
  `,
);
