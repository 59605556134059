/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserListDisplaySortOption
 */
export interface UserListDisplaySortOption {
    /**
     * The column on which the sorting should be applied
     * @type {string}
     * @memberof UserListDisplaySortOption
     */
    sortBy: UserListDisplaySortOptionSortByEnum;
    /**
     * Indicates whether results should be sorted in ascending or descending order
     * @type {string}
     * @memberof UserListDisplaySortOption
     */
    sortOrder: UserListDisplaySortOptionSortOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserListDisplaySortOptionSortByEnum {
    Name = 'NAME'
}
/**
    * @export
    * @enum {string}
    */
export enum UserListDisplaySortOptionSortOrderEnum {
    Ascending = 'ASCENDING',
    Descending = 'DESCENDING'
}



