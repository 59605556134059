import { useColorScheme } from '@spotnana/blocks/src/utils/hooks';
import { useFeatureFlag } from 'obt-common/feature-gating/hooks';
import { useLayoutEffect } from 'react';
import { useEmbedStore } from 'src/components/EmbedContext';

/**
 * This component initializes the theme mode based on the current environment.
 * @returns
 */
export const ThemeModeInitializer = () => {
  const {
    isEmbed,
    embedParams: { theme: embedTheme = 'light' },
  } = useEmbedStore();
  const isDarkModeEnabled = useFeatureFlag('FE_INFRA_DARK_MODE');
  const { mode: currentMode, setMode } = useColorScheme();

  const targetMode = (() => {
    // if dark mode is not enabled, always use light mode
    if (!isDarkModeEnabled) {
      return 'light';
    }
    // if in embed mode, use the theme from embed params
    if (isEmbed) {
      return embedTheme;
    }

    // default to current mode, with fallback to `light` if not set
    return currentMode ?? 'light';
  })();

  useLayoutEffect(() => {
    if (currentMode !== targetMode) {
      setMode(targetMode);
    }
  }, [currentMode, targetMode, setMode]);

  return null;
};
