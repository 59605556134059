import { forwardRef } from 'react';
import type { FieldValues, Control, RefCallBack } from 'rhf-v7';
import { useFormState } from 'rhf-v7';
import { Input, TInputProps } from '../Input';

type TInputFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  control: Control<TFieldValues>;
} & TInputProps;
function InputFieldInternal<TFieldValues extends FieldValues = FieldValues>(
  { control, ...props }: TInputFieldProps<TFieldValues>,
  ref: RefCallBack | React.ForwardedRef<HTMLInputElement>,
) {
  const { errors, defaultValues } = useFormState({ control });
  const fieldError = errors[props.name as keyof TFieldValues]?.message as string;
  const defaultValue = defaultValues?.[props.name as string];
  return (
    <Input
      state={fieldError ? 'error' : undefined}
      defaultValue={defaultValue}
      helperText={fieldError}
      ref={ref}
      {...props}
    />
  );
}

export const InputField = forwardRef(InputFieldInternal) as <TFieldValues extends FieldValues = FieldValues>(
  props: TInputFieldProps<TFieldValues> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof InputFieldInternal>;
