import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import SpotnanaError from 'obt-common/api/SpotnanaError';

export interface ISiteMessagingContext {
  ancillaryResponseId: string | null;
  setAncillaryResponseId: (ancillaryResponseId: string | null) => void;
}

// Storage for app context needed for site messaging targeting.
export const SiteMessagingContext = createContext<ISiteMessagingContext | null>(null);

/**
 * Disabling eslint rule here to clearly mark that this component accepts
 * `children` prop only.
 *
 * Using `Record<string, never>` will cause an error while passing `children`
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export const SiteMessagingContextProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [ancillaryResponseId, setAncillaryResponseId] = useState<ISiteMessagingContext['ancillaryResponseId']>(null);

  const context = useMemo<ISiteMessagingContext>(
    () => ({
      ancillaryResponseId,
      setAncillaryResponseId,
    }),
    [ancillaryResponseId],
  );

  return <SiteMessagingContext.Provider value={context}>{children}</SiteMessagingContext.Provider>;
};

export const useSiteMessagingContext = (): ISiteMessagingContext => {
  const context = useContext(SiteMessagingContext);

  if (!context) {
    throw new SpotnanaError(`"useSiteMessagingContext" should be used inside "SiteMessagingContextProvider"`);
  }

  return context;
};
