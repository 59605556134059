import type { QueryKey, UseQueryOptions } from 'react-query';
import { useMutation, useQueries, useQuery } from 'react-query';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type { UserId } from '../types/api/v1/common/user_id';
import type { Traveler } from '../types/api/v1/obt/common/traveler';
import type { UserOrgId } from '../types/api/v1/obt/common/user_org_id';
import type { ReadUserDefinedEntitiesResponse } from '../types/api/v1/obt/policy/policy_service';
import type { CreateTravelerResponse } from '../types/api/v1/obt/profile/create_traveler_response';
import type { ReadRelativesResponse } from '../types/api/v1/obt/profile/profile_services';
import type { ReadTravelerResponse } from '../types/api/v1/obt/profile/read_traveler_response';
import type { SearchTravelerRequest } from '../types/api/v1/obt/profile/search_traveler_request';
import type { SearchTravelerResponse } from '../types/api/v1/obt/profile/search_traveler_response';
import { UpdateTravelerRequestTypeEnum } from '../types/api/v1/obt/profile/update_traveler_request';
import type { UpdateTravelerRequest } from '../types/api/v1/obt/profile/update_traveler_request';
import type { SpotnanaQueryMutationResult, SpotnanaQueryResult } from '../types/common';
import type { ITravelPreferences, IUserOrgId } from '../types/traveler';
import { GenderEnum } from '../types/api/v1/common/gender';
import { removeEmptyValuesFromObject } from '../utils/common';
import sentryLogger from '../utils/logger';

import { invalidateReadEmployee, invalidateReadOrganization } from './admin';
import { defaultQueryClient } from './defaultQueryClient';

const travelerSearchKey = (searchTravelerRequest: SearchTravelerRequest): unknown[] => [
  'traveler-search',
  searchTravelerRequest,
];

const travelerRelativeKey = (userOrgId: UserOrgId): unknown[] => ['traveler-relative', userOrgId];

const fetchTravelersList = async (searchRequest: SearchTravelerRequest): Promise<SearchTravelerResponse> => {
  const data = await api('POST', 'searchTraveler', {
    data: searchRequest,
  });
  return data as SearchTravelerResponse;
};

const travelerPreSearchKey = (travelerId?: IUserOrgId): unknown[] => ['traveler-pre-search-questions', travelerId];

const travelerPreferencesKey = (userId: string): unknown[] => ['traveler-preferences', userId];

export const invalidateTravelPreference = (userId: string) => {
  defaultQueryClient.invalidateQueries(travelerPreferencesKey(userId));
};

const fetchTravelerPreferences = async (userId: string): Promise<ITravelPreferences> => {
  if (!userId) {
    throw new SpotnanaError('No traveler id passed to fetch traveler preferences');
  }
  const res = await api(
    'GET',
    'userBaseUrl',
    {
      urlParam: `/${userId}/travel-preferences`,
    },
    { allowParallelRequests: true },
  );
  return res as ITravelPreferences;
};

export const useTravelerPreferences = (
  userId: string,
  options?: UseQueryOptions<ITravelPreferences, SpotnanaError>,
): SpotnanaQueryResult<ITravelPreferences> =>
  useQuery<ITravelPreferences, SpotnanaError>(
    travelerPreferencesKey(userId),
    () => fetchTravelerPreferences(userId),
    options,
  );

export const useMultipleTravelerPreferences = (
  userIds: (string | undefined)[],
  enabled = true,
): SpotnanaQueryResult<ITravelPreferences>[] =>
  useQueries(
    userIds.map((userId) => ({
      queryKey: userId && travelerPreferencesKey(userId),
      queryFn: () => userId && fetchTravelerPreferences(userId),
      enabled,
    })),
  ) as SpotnanaQueryResult<ITravelPreferences>[];

export const invalidateTravelerPreferences = (userId: string): void => {
  defaultQueryClient.invalidateQueries(travelerPreferencesKey(userId));
};

const fetchTravelerPreSearchQuestions = async (travelerId?: IUserOrgId): Promise<ReadUserDefinedEntitiesResponse> => {
  if (!travelerId) {
    throw new SpotnanaError('No traveler id passed to fetch pre-search questions');
  }

  const data = (await api('POST', 'preSearchQuestions', {
    data: { userOrgId: removeEmptyValuesFromObject(travelerId) },
  })) as ReadUserDefinedEntitiesResponse;

  return {
    ...data,
    userDefinedEntities: data.userDefinedEntities.filter((entity) => !!entity.preDefinedQuestion?.preSearch),
  };
};

export const useTravelerPreSearchQuestionsQuery = (
  travelerId?: UserOrgId,
): SpotnanaQueryResult<ReadUserDefinedEntitiesResponse> =>
  useQuery<ReadUserDefinedEntitiesResponse, SpotnanaError>(
    travelerPreSearchKey(travelerId),
    () => fetchTravelerPreSearchQuestions(travelerId),
    {
      enabled: !!travelerId,
    },
  );

export const useTravelerSearch = (
  searchTravelerRequest: SearchTravelerRequest,
): SpotnanaQueryResult<SearchTravelerResponse> =>
  useQuery<SearchTravelerResponse, SpotnanaError>(
    travelerSearchKey(searchTravelerRequest),
    () => fetchTravelersList(searchTravelerRequest),
    {
      enabled: !!searchTravelerRequest.email && searchTravelerRequest.email.length > 2,
      cacheTime: 0,
      staleTime: 1000 * 60 * 5,
    },
  );

const createTraveler = async (traveler: Traveler): Promise<UserOrgId | undefined> => {
  const { userOrgId } = (await api(
    'POST',
    'createTraveler',
    {
      data: { traveler },
    },
    { allowParallelRequests: true },
  )) as CreateTravelerResponse;
  return userOrgId;
};

export const useTravelerCreateMutation = (): SpotnanaQueryMutationResult<UserOrgId | undefined, Traveler> =>
  useMutation((traveler: Traveler) => createTraveler(traveler), {
    onSuccess: (userOrgId) => {
      if (userOrgId) {
        invalidateReadEmployee(userOrgId);
        invalidateReadOrganization(userOrgId.organizationId ?? { id: '' });
      }
    },
  });

const readTraveler = async (userOrgId: UserOrgId | undefined): Promise<Traveler> => {
  const readTravelerResponse = (await api('POST', 'readTraveler', {
    data: { userOrgId: removeEmptyValuesFromObject(userOrgId) },
  })) as ReadTravelerResponse;
  if (readTravelerResponse.traveler) {
    return readTravelerResponse.traveler;
  }
  throw new Error('No traveler found');
};

// ToDo: there is a duplication version: profileDetailsKey. we need to consider having only 1 useQuery for 1 endpoint
const travelerReadKey = (userOrgId: UserOrgId | undefined): ['read-traveler', UserId | undefined] => [
  'read-traveler',
  userOrgId?.userId,
];

export const useTravelerReadQuery = (
  userOrgId: UserOrgId | undefined,
  options?: UseQueryOptions<Traveler, SpotnanaError, Traveler, QueryKey>,
): SpotnanaQueryResult<Traveler> =>
  useQuery<Traveler, SpotnanaError>(travelerReadKey(userOrgId), () => readTraveler(userOrgId), options);

export const invalidateTravelerRelativesResponses = (userOrgId: UserOrgId): Promise<void> =>
  defaultQueryClient.invalidateQueries(travelerRelativeKey(userOrgId));

const readTravelerRelatives = async (userOrgId: UserOrgId): Promise<ReadRelativesResponse> => {
  const data = await api('POST', 'readRelative', { data: { userOrgId: removeEmptyValuesFromObject(userOrgId) } });
  return data as ReadRelativesResponse;
};

export const useTravelerRelatives = (
  userOrgId: UserOrgId,
  options?: UseQueryOptions<ReadRelativesResponse, SpotnanaError>,
): SpotnanaQueryResult<ReadRelativesResponse> =>
  useQuery<ReadRelativesResponse, SpotnanaError>(
    travelerRelativeKey(userOrgId),
    () => readTravelerRelatives(userOrgId),
    options,
  );

export const invalidateReadTraveler = (userOrgId: IUserOrgId): void => {
  defaultQueryClient.invalidateQueries(travelerReadKey(userOrgId));
};

const updateTravelerInternal = async (updateTravelerRequest: UpdateTravelerRequest): Promise<void> => {
  const updatedGender = updateTravelerRequest?.traveler?.user?.gender;
  const updatingGender = updateTravelerRequest?.type?.includes(UpdateTravelerRequestTypeEnum.GENDER);
  // Log when traveler profile updates are setting gender to null, undefined, or UNKNOWN.
  if (updatingGender && updatedGender === GenderEnum.UNKNOWN) {
    sentryLogger.error(new Error(`Invalid Request to update traveler gender to ${updatedGender}`));
  }

  await api(
    'POST',
    'travelerUpdate',
    {
      data: updateTravelerRequest,
    },
    { allowParallelRequests: true },
  );
};

export const useTravelerUpdateMutation = (): SpotnanaQueryMutationResult<void, UpdateTravelerRequest> =>
  useMutation((requestBody: UpdateTravelerRequest) => updateTravelerInternal(requestBody), {
    onSuccess: (_response, request) => {
      if (request.traveler.userOrgId) {
        invalidateReadTraveler(request.traveler.userOrgId);
        invalidateReadEmployee(request.userOrgId);
      }
    },
  });
