import type { FareAmount, PaxInfo } from '../../../types/api/v1/obt/air/air_search_response';
import { PassengerTypeEnum } from '../../../constants';
import { getPaxInfoIndex } from './getPaxInfoIndex';
import { getBaseFareForFareAmount, getTaxFareForFareAmount } from './getTotalFareForFareAmount';
import { getPaxBaseFareForPaxInfo, getPaxTaxFareForPaxInfo } from './getPaxFaresForPaxInfo';

export function getBasePrimaryTravelerFareForPaxInfo(totalFare: FareAmount, paxInfo: PaxInfo[]) {
  const paxNumber = getPaxInfoIndex({ paxAge: undefined, paxInfo, paxType: PassengerTypeEnum.ADULT });
  // If paxType is Unrecognized / Unknown then, send Total Fare
  const isInvalidPaxNumber = paxNumber === -1;
  if (isInvalidPaxNumber) {
    return getBaseFareForFareAmount(totalFare);
  }

  return getPaxBaseFareForPaxInfo(paxNumber, paxInfo);
}

export function getTaxPrimaryTravelerFareForPaxInfo(totalFare: FareAmount, paxInfo: PaxInfo[]) {
  const paxNumber = getPaxInfoIndex({ paxAge: undefined, paxInfo, paxType: PassengerTypeEnum.ADULT });
  // If paxType is Unrecognized / Unknown then, send Total Fare
  const isInvalidPaxNumber = paxNumber === -1;
  if (isInvalidPaxNumber) {
    return getTaxFareForFareAmount(totalFare);
  }

  return getPaxTaxFareForPaxInfo(paxNumber, paxInfo);
}

export function getPrimaryTravelerFareForPaxInfo(totalFare: FareAmount, paxInfo: PaxInfo[]) {
  return getBasePrimaryTravelerFareForPaxInfo(totalFare, paxInfo).add(
    getTaxPrimaryTravelerFareForPaxInfo(totalFare, paxInfo),
  );
}
