/* eslint-disable */
import { AirPref } from './air_preference';
import { RailCard } from '../../../v1/common/card';
import { RailPref } from './rail_preference';
import { HotelPref } from './hotel_preference';
import { CarPref } from './car_preference';
import { LoyaltyInfoTypeEnum as LoyaltyInfoTypeEnumV2 } from '../../../../api/v2/obt/model';

export interface LoyaltyInfo {
  type: LoyaltyInfoType;
  id: string;
  issuedBy: string;
  appliedTo: string[];
}

export interface TravelPref {
  airPref?: AirPref;
  /** ISO4217 currency code of traveler's preferred currency. */
  preferredCurrency?: string;
  /** Rail Cards for the traveler. */
  railCards: RailCard[];
  /** Rail preference for the traveler. */
  railPref?: RailPref;
  /** Hotel preference for the traveler. */
  hotelPref?: HotelPref;
  /** Car preference for the traveler. */
  carPref?: CarPref;
}

export interface TravelerPersonalInfo {
  loyaltyInfos: LoyaltyInfo[];
  travelPref?: TravelPref;
  /** For UI to Hide Loyalty Section in OBT for a given user. */
  blockLoyalty: boolean;
}

export enum LoyaltyInfoTypeEnum {
  UNKNOWN_TYPE = 0,
  AIR = 1,
  HOTEL = 2,
  CAR = 3,
  RAIL = 4,
  LIMO = 5,
  MISC = 6,
  UNRECOGNIZED = -1,
}

export type LoyaltyInfoType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | -1;

export const mapLoyaltyInfoTypeEnumV1toV2: Record<LoyaltyInfoTypeEnum, LoyaltyInfoTypeEnumV2> = {
  [LoyaltyInfoTypeEnum.UNKNOWN_TYPE]: LoyaltyInfoTypeEnumV2.UnknownType,
  [LoyaltyInfoTypeEnum.AIR]: LoyaltyInfoTypeEnumV2.Air,
  [LoyaltyInfoTypeEnum.HOTEL]: LoyaltyInfoTypeEnumV2.Hotel,
  [LoyaltyInfoTypeEnum.CAR]: LoyaltyInfoTypeEnumV2.Car,
  [LoyaltyInfoTypeEnum.RAIL]: LoyaltyInfoTypeEnumV2.Rail,
  [LoyaltyInfoTypeEnum.LIMO]: LoyaltyInfoTypeEnumV2.UnknownType,
  [LoyaltyInfoTypeEnum.MISC]: LoyaltyInfoTypeEnumV2.UnknownType,
  [LoyaltyInfoTypeEnum.UNRECOGNIZED]: LoyaltyInfoTypeEnumV2.UnknownType,
};
