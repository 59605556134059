import {
  getUserTierForDisplay,
  useFeatureFlag,
  useLoggedInUserBasicInfo,
  usePrimaryTraveler,
  useSupportConfigForCurrentOrganization,
  useUserAuthConfigQuery,
} from 'obt-common';
import { isGenesysAuthCloudConfigValid } from 'obt-common/utils/genesys/isGenesysAuthCloudConfigValid';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import noop from 'lodash/noop';
import { verifyGenesysChatIsEnabled } from 'obt-common/utils/genesys/verifyGenesysChatIsEnabled';
import { useTranslation } from 'react-i18next';

import { EmbedEventTypes, useEmbedStore } from '../components/EmbedContext';
import { useGenesysChat } from '../v2-components/shared/ChatInitializer/GenesysChatProvider';

type SupportWidgetAPI = {
  openChatWidget: (message?: { replyText: string }) => void;
  hideChatWidget: () => void;
  onClose: (callback: () => void) => void;
};

const useGenesysWidget = (): SupportWidgetAPI => {
  const { t: tt } = useTranslation('WEB');
  const genesysPlugin = useGenesysChat();
  const { data: traveler } = usePrimaryTraveler();

  return useMemo(() => {
    const isLoaded = !!genesysPlugin && !!traveler;

    return {
      openChatWidget: () => {
        if (!isLoaded) return;

        genesysPlugin.command('WebChat.open', {
          form: {
            autoSubmit: true,
            firstname: traveler.user?.name?.given,
            lastname: traveler.user?.name?.family1,
            email: traveler.user?.email,
            subject: tt('Talk to a $t(consultant)'),
          },
        });
      },

      hideChatWidget: () => {
        if (!isLoaded) return;

        genesysPlugin.command('WebChat.close');
      },

      onClose: (callback) => {
        if (!isLoaded) return;

        genesysPlugin.subscribe('WebChat.closed', callback);
      },
    };
  }, [genesysPlugin, traveler, tt]);
};

const useEmbedWidget = (): SupportWidgetAPI => {
  const { reportEvent } = useEmbedStore();

  return useMemo(
    () => ({
      openChatWidget: () => {
        reportEvent({
          type: EmbedEventTypes.CHAT_WIDGET,
          payload: {
            eventType: 'open',
          },
        });
      },

      hideChatWidget: () => {
        reportEvent({
          type: EmbedEventTypes.CHAT_WIDGET,
          payload: {
            eventType: 'hide',
          },
        });
      },

      onClose: noop,
    }),
    [reportEvent],
  );
};

const useFreshchatWidget = (): SupportWidgetAPI => {
  const { data: traveler } = usePrimaryTraveler();

  return useMemo(() => {
    return {
      openChatWidget: (message) => {
        if (!window.fcWidget) return;

        // These are set in src/v2-components/shared/ChatInitializer/InitializeFreshchat.tsx
        const baseUserProperties = window.fcWidget.user.baseProperties;
        window.fcWidget.user.setProperties({
          ...baseUserProperties,
          Selected_Traveler_Email: traveler?.userBusinessInfo?.email,
          Selected_Traveler_Tier: getUserTierForDisplay(traveler?.tier ?? 0),
        });

        window.fcWidget.open(message);
      },

      hideChatWidget: () => {
        if (!window.fcWidget) return;

        window.fcWidget.close();
      },

      logout: async () => {
        if (!window.fcWidget) return;

        window.fcWidget.destroy();

        try {
          // https://developers.freshchat.com/web-sdk/#messenger-events
          await window.fcWidget.user.clear();
        } catch (e) {
          // do nothing
        }
      },

      onClose: (callback) => {
        if (!window.fcWidget) return;

        window.fcWidget.on('widget:closed', callback);
      },
    };
  }, [traveler]);
};

const useZendeskChatWidget = (): SupportWidgetAPI => {
  return useMemo(() => {
    return {
      openChatWidget: (message) => {
        if (!window.zdWidget?.open) return;

        window.zdWidget.open();
        window.zdWidget.setPredefinedMessage(message?.replyText);
      },

      hideChatWidget: () => {
        if (!window.zdWidget?.close) return;

        window.zdWidget.close();
      },

      onClose: (callback) => {
        if (!window.zdWidget?.on) return;

        window.zdWidget.on('widget:closed', callback);
      },
    };
  }, []);
};

export const useWidgetLogout = (): (() => Promise<void>) => {
  const { isEmbed, reportEvent } = useEmbedStore();

  return async () => {
    if (isEmbed) {
      reportEvent({
        type: EmbedEventTypes.CHAT_WIDGET,
        payload: {
          eventType: 'logout',
        },
      });

      return;
    }

    if (window.Genesys) {
      window.Genesys('command', 'Auth.logout');
      window.genesysAuthCode = '';
    }

    if (window.zdWidget) {
      try {
        window.zdWidget.destroy();
      } catch (e) {
        // do nothing
      }
      return;
    }

    if (window.Twilio) {
      window.Twilio.clear(); // remove token and conversation in localstorage
    }

    if (window.fcWidget) {
      try {
        window.fcWidget.destroy();
        // https://developers.freshchat.com/web-sdk/#messenger-events
        await window.fcWidget.user.clear();
      } catch (e) {
        // do nothing
      }
    }
  };
};

type UseSupportWidget = () => SupportWidgetAPI & {
  isLoaded: boolean;
  isWidgetLoaded: boolean;
  isZendeskWidget: boolean;
  isNoChatConfigured: boolean;
  isFreshchatEnabled: boolean;
  isTwilioEnabled: boolean;
  isGenesysCloudEnabled: boolean;
};
export type WidgetType = 'embed' | 'genesys' | 'freshchat' | 'zendesk' | 'genesysCloud' | 'twilio';

const useSupportWidget: UseSupportWidget = () => {
  const history = useHistory();
  const { isEmbed } = useEmbedStore();

  const { isLoading: isLoadingSupportConfig, data: supportConfig } = useSupportConfigForCurrentOrganization();
  const loggedInUserBasicInfo = useLoggedInUserBasicInfo();
  const { data: authConfig } = useUserAuthConfigQuery(loggedInUserBasicInfo.existingUser?.userOrgId?.userId?.id);
  const { authProviderType } = authConfig?.authProviders?.[0] || {};

  const isFreshchatEnabled = (supportConfig?.freshdeskConfig &&
    !!supportConfig.freshdeskConfig.enabled === true) as boolean;
  const isGenesysEnabled = verifyGenesysChatIsEnabled(supportConfig);
  const isFreshchatWidgetLoaded = window.fcWidget !== undefined;
  const isZendeskWidgetEnabled = !!supportConfig?.zendeskConfig?.enabled === true;
  /**
   * A feature flag has been introduced as a temporary fix to enable Twilio chat at the organization level.
   * If the flag is enabled, Twilio chat will be displayed regardless of the support configuration response.
   * Otherwise, we will rely on the support configuration to determine which chat widget to show.
   */
  const isTwilioEnabled =
    useFeatureFlag('FE_ENABLE_TWILIO_CHAT') === true || !!supportConfig?.twilioChatConfig?.enabled === true;
  const isGenesysCloudEnabled = isGenesysAuthCloudConfigValid(supportConfig, authProviderType);
  const embedWidget = useEmbedWidget();
  const genesysWidget = useGenesysWidget();
  const freshchatWidget = useFreshchatWidget();
  const zendeskWidget = useZendeskChatWidget();

  const getWidgetType = useCallback((): WidgetType | undefined => {
    if (isTwilioEnabled) {
      return 'twilio';
    }
    if (isEmbed) {
      return 'embed';
    }

    if (isZendeskWidgetEnabled) {
      return 'zendesk';
    }

    if (isGenesysEnabled) {
      return 'genesys';
    }

    if (isFreshchatEnabled) {
      return 'freshchat';
    }

    if (isGenesysCloudEnabled) {
      return 'genesysCloud';
    }

    return undefined;
  }, [isEmbed, isGenesysEnabled, isFreshchatEnabled, isZendeskWidgetEnabled, isGenesysCloudEnabled, isTwilioEnabled]);

  return useMemo(() => {
    const widgetType = getWidgetType();
    const isLoaded = !isLoadingSupportConfig || widgetType === 'embed';
    const isWidgetLoaded = widgetType === 'freshchat' ? isFreshchatWidgetLoaded : widgetType !== 'genesysCloud';
    const isZendeskWidget = widgetType === 'zendesk';
    const isNoChatConfigured = !['zendesk', 'genesys', 'freshchat', 'genesysCloud', 'twilio'].includes(
      widgetType as string,
    );

    return {
      isTwilioEnabled,
      isFreshchatEnabled,
      isNoChatConfigured,
      isLoaded,
      isWidgetLoaded,
      isZendeskWidget,
      isGenesysCloudEnabled,
      openChatWidget: (message) => {
        if (!isLoaded) return;
        switch (widgetType) {
          /* istanbul ignore next: Sonarqube can not calculate the correct coverage for this case */
          case 'twilio':
            if (window.Twilio) {
              window.Twilio.show();
            }
            return;
          case 'embed':
            embedWidget.openChatWidget();
            return;
          case 'genesys':
            genesysWidget.openChatWidget();
            return;
          case 'genesysCloud':
            if (window.Genesys) {
              window.Genesys('command', 'Messenger.open');
            }
            return;
          case 'freshchat':
            freshchatWidget.openChatWidget(message);
            return;
          case 'zendesk':
            zendeskWidget.openChatWidget(message);
            return;
          default:
            history.push('/support?request-support=true');
        }
      },

      hideChatWidget: () => {
        if (!isLoaded) return;

        switch (widgetType) {
          /* istanbul ignore next: Sonarqube can not calculate the correct coverage for this case */
          case 'twilio':
            if (window.Twilio) {
              window.Twilio.hide();
            }
            return;
          case 'embed':
            embedWidget.hideChatWidget();
            return;

          case 'genesys':
            genesysWidget.hideChatWidget();
            return;

          case 'genesysCloud':
            if (window.Genesys) {
              window.Genesys('command', 'Messenger.close');
            }
            return;
          case 'freshchat':
            freshchatWidget.hideChatWidget();
            return;
          case 'zendesk':
            zendeskWidget.hideChatWidget();
        }
      },

      onClose: (callback) => {
        if (!isLoaded) return;

        switch (widgetType) {
          /* istanbul ignore next: Sonarqube can not calculate the correct coverage for this case */
          case 'twilio':
            if (window.Twilio) {
              window.Twilio.hide();
            }
            return;
          case 'genesys':
            genesysWidget.onClose(callback);
            return;
          case 'genesysCloud':
            if (window.Genesys) {
              window.Genesys('command', 'Messenger.close');
            }
            return;
          case 'zendesk':
            zendeskWidget.onClose(callback);
            return;

          case 'freshchat':
            freshchatWidget.onClose(callback);
        }
      },
    };
  }, [
    getWidgetType,
    isFreshchatEnabled,
    isLoadingSupportConfig,
    isFreshchatWidgetLoaded,
    embedWidget,
    genesysWidget,
    freshchatWidget,
    zendeskWidget,
    history,
    isTwilioEnabled,
    isGenesysCloudEnabled,
  ]);
};

export default useSupportWidget;
