import { OrganizationBasicInfo, TravelerBasicInfo, TripBasicInfo } from 'obt-common';

export type EntityInfo = OrganizationBasicInfo | TravelerBasicInfo | TripBasicInfo;

export function isOrganizationBasicInfo(entityInfo: EntityInfo): entityInfo is OrganizationBasicInfo {
  return 'orgName' in entityInfo;
}

export function isTravelerBasicInfo(entityInfo: EntityInfo): entityInfo is TravelerBasicInfo {
  return 'fullName' in entityInfo;
}

export function isTripBasicInfo(entityInfo: EntityInfo): entityInfo is TripBasicInfo {
  return 'tripId' in entityInfo;
}
