import { useState } from 'react';
import { useHasUserAccess, userRolesByFeature } from 'obt-common';
import useSupportWidget from 'src/hooks/useSupportWidget';
import { PopperStepSupportHome } from '../PopperStepSupportHome/PopperStepSupportHome';
import { PopperStepSelectTraveler } from '../PopperStepSelectTraveler/PopperStepSelectTraveler';
import { content_container, popper_step_content, popper_steps_container, popper_steps_container_step2 } from './styles';

interface IProps {
  onTalkToAgent: () => void;
  showFeedbackButton: boolean;
  onFeedback: () => void;
}

enum PopperStep {
  SUPPORT_HOME = 'SUPPORT_HOME',
  SELECT_TRAVELER = 'SELECT_TRAVELER',
}

function PopperContent({ onTalkToAgent, showFeedbackButton, onFeedback }: IProps) {
  const { isTwilioEnabled, isGenesysCloudEnabled } = useSupportWidget();
  const [popperState, setPopperState] = useState(PopperStep.SUPPORT_HOME);
  const hasAccessToSelectTraveler = useHasUserAccess(userRolesByFeature.travelerSelector);

  function handleBack() {
    setPopperState(PopperStep.SUPPORT_HOME);
  }

  function handleTalkToSupportFromHomePage() {
    if (isTwilioEnabled || isGenesysCloudEnabled) {
      onTalkToAgent();
      return;
    }
    if (hasAccessToSelectTraveler) {
      setPopperState(PopperStep.SELECT_TRAVELER);
    } else {
      onTalkToAgent();
    }
  }

  return (
    <div css={content_container}>
      <div
        css={[
          popper_steps_container,
          popperState === PopperStep.SELECT_TRAVELER ? popper_steps_container_step2 : undefined,
        ]}
      >
        <div css={popper_step_content}>
          <PopperStepSupportHome
            onTalkToAgent={handleTalkToSupportFromHomePage}
            showFeedbackButton={showFeedbackButton}
            onFeedback={onFeedback}
          />
        </div>

        <div css={popper_step_content}>
          <PopperStepSelectTraveler onTalkToAgent={onTalkToAgent} onBack={handleBack} />
        </div>
      </div>
    </div>
  );
}

export { PopperContent };
