import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Tube = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 15 13", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.15234 8.44434C2.97019 10.7584 5.17714 12.4165 7.77149 12.4165C10.3658 12.4165 12.5729 10.7584 13.3906 8.44434H10.8237C10.1742 9.44064 9.0497 10.0994 7.77144 10.0994C6.49317 10.0994 5.36872 9.44064 4.71915 8.44434H2.15234Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.3906 4.47216C12.5728 2.1581 10.3658 0.5 7.77149 0.5C5.17714 0.5 2.97014 2.1581 2.15234 4.47216H4.71927C5.36878 3.47585 6.49329 2.81707 7.77155 2.81707C9.04981 2.81707 10.1743 3.47585 10.8238 4.47216H13.3906Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.819336 5.13403H14.7221V7.78224H0.819336V5.13403Z", fill: themeEnrichedPrimaryColor })));
};
Tube.defaultProps = defaultIconProps;
export default Tube;
