/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Elite level description of Marriott Bonvoy member
 * @export
 * @enum {string}
 */

export const EliteLevel = {
    Member: 'MEMBER',
    Silver: 'SILVER',
    Gold: 'GOLD',
    Platinum: 'PLATINUM',
    Titanium: 'TITANIUM',
    Ambassador: 'AMBASSADOR'
} as const;

export type EliteLevel = typeof EliteLevel[keyof typeof EliteLevel];



