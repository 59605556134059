import { FunctionInterpolation, Theme } from '@emotion/react';
import clsx from 'clsx';
import MuiChip, { ChipProps } from '@mui/material/Chip';
import { forwardRef, useId } from 'react';
import { RemoveMuiProps } from '../types';
import {
  label_base,
  preferred_label_base,
  warning_label,
  subtle_label,
  error_label,
  success_label,
  clickable_label,
  info_label,
} from './Label.styles';
import { Typography } from '../Typography';
import { IconName } from '../Icon/types';
import { Icon } from '../Icon';

type TLabelSize = 'small' | 'medium' | 'large';
type TLabelVariant = 'info' | 'subtle' | 'error' | 'success' | 'warning';

export type TLabelBaseProps = RemoveMuiProps<
  ChipProps,
  'onDelete' | 'color' | 'label' | 'avatar' | 'icon' | 'children' | 'deleteIcon' | 'tabIndex'
> & {
  label: string;
  size?: TLabelSize;
  iconName?: IconName;
  variant?: TLabelVariant;
  filled?: boolean;
};

const colorConfigClassesMap: Record<TLabelVariant, FunctionInterpolation<Theme>> = {
  warning: warning_label,
  info: info_label,
  subtle: subtle_label,
  error: error_label,
  success: success_label,
};
const getLabelColorConfigClass = (color: TLabelVariant): FunctionInterpolation<Theme> => {
  return colorConfigClassesMap[color];
};

const LabelBase = forwardRef<HTMLDivElement, TLabelBaseProps>((props, ref) => {
  const { className, variant = 'info', filled = true, size = 'large', label, iconName, ...restProps } = props;
  const labelId = useId();
  if (iconName) {
    return (
      <MuiChip
        ref={ref}
        avatar={undefined}
        icon={<Icon name={iconName} />}
        className={clsx(
          className,
          `BlocksLabel-root-${size}`,
          `BlocksLabel-${variant}`,
          filled ? '' : 'BlocksLabel-withoutBackground',
        )}
        css={[label_base, getLabelColorConfigClass(variant)]}
        data-testid="blocks-label"
        label={
          <Typography variant={size === 'large' ? 'body2' : 'body3'} kind="medium" as="span" id={labelId}>
            {label}
          </Typography>
        }
        {...restProps}
        aria-describedby={labelId}
        onDelete={undefined}
      />
    );
  }
  return (
    <MuiChip
      ref={ref}
      avatar={undefined}
      icon={undefined}
      className={clsx(
        className,
        `BlocksLabel-root-${size}`,
        'BlocksLabel-withoutIcon',
        filled ? '' : 'BlocksLabel-withoutBackground',
      )}
      css={[label_base, getLabelColorConfigClass(variant)]}
      data-testid="blocks-label"
      label={
        <Typography variant={size === 'large' ? 'body2' : 'body3'} kind="medium" as="span" id={labelId}>
          {label}
        </Typography>
      }
      {...restProps}
      aria-describedby={labelId}
      onDelete={undefined}
    />
  );
});

/**
 * Label component implementation
 * Label is not clickable and to avoid misuse of the component removing 'clickable' | 'onClick' props
 */
export type TLabelProps = Omit<TLabelBaseProps, 'clickable' | 'onClick'>;
export const Label = (props: TLabelProps) => {
  return <LabelBase {...props} clickable={false} />;
};

/**
 * As of now preferred label does not have any variant, in future we may add,
 */
type TPreferredLabelProps = Omit<TLabelProps, 'iconName' | 'filled' | 'variant'>;
export const PreferredLabel = ({ className, size = 'medium', label, ...restProps }: TPreferredLabelProps) => {
  return (
    <div className="BlocksPreferredLabel-root" css={[preferred_label_base]}>
      <div className={`BlocksPreferredLabel-notch-${size}`} />
      <LabelBase
        className="Preferred-Label"
        variant="info"
        filled
        size={size}
        iconName={undefined}
        label={label}
        {...restProps}
      />
    </div>
  );
};

/**
 * As of now clickable label is only allowed for out of policy due to design limitation
 * and should not be used for any other use case without consulting UX and Blocks code owners.
 *
 * Limiting the variant to 'error' as of now, In future if all approve can enable for all variants.
 */
export type TClickableLabelProps = Omit<TLabelBaseProps, 'clickable'>;

export const ClickableLabel = forwardRef<HTMLDivElement, TClickableLabelProps>(
  ({ onClick, variant, ...restProps }, ref) => {
    return (
      <LabelBase
        ref={ref}
        css={[clickable_label]}
        {...restProps}
        variant={variant || 'error'}
        clickable
        onClick={onClick}
      />
    );
  },
);

ClickableLabel.displayName = 'ClickableLabel';
