import { StorageKeys } from '../../types/storage';
import { fetchStorageValue } from '../../hooks/useStorage';
import { getFromMemoryCacheTmcId, saveInMemoryCacheTmcId } from './inMemoryUrlCache';
import { extractTmcIdFromUrl } from './extractTmcIdFromUrl';
import { setTmcId } from './setTmcId';

interface Params {
  tmcIdFromUrl: string | null;
  tmcIdFromStorage: string | null;
}

async function processTmcIds({ tmcIdFromStorage, tmcIdFromUrl }: Params): Promise<string | null | undefined> {
  // On every `getTmcId()` invoking app attempt to
  // save the tmcId from the URL in memory
  if (!getFromMemoryCacheTmcId() && tmcIdFromUrl) {
    saveInMemoryCacheTmcId(tmcIdFromUrl);
  }

  // When the tmcId is not saved in the storage
  // but was memoized from the URL, save it in the storage
  const initialtmcIdFromUrl = getFromMemoryCacheTmcId();

  if (!tmcIdFromStorage && initialtmcIdFromUrl) {
    await setTmcId(initialtmcIdFromUrl).catch(() => null);
  }

  return tmcIdFromStorage || initialtmcIdFromUrl;
}

export async function getTmcId(): Promise<string | undefined | null> {
  const tmcIdFromUrl = extractTmcIdFromUrl();
  const tmcIdFromStorage = await fetchStorageValue<string>(StorageKeys.TMC_ID).catch(() => null);
  return processTmcIds({ tmcIdFromUrl, tmcIdFromStorage });
}
