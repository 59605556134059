/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Category details of the Special Service Request (SSR).
 * @export
 * @interface Category
 */
export interface Category {
  /**
   * Enum of the type of the category.
   * @type {string}
   * @memberof Category
   */
  type?: CategoryTypeEnum;
  /**
   * Description of the category.
   * @type {string}
   * @memberof Category
   */
  description?: string;
}

export const CategoryTypeEnum = {
  Bassinet: 'BASSINET',
  SpecialAssistanceWheelchair: 'SPECIAL_ASSISTANCE_WHEELCHAIR',
  Baggage: 'BAGGAGE',
  UnaccompaniedMinor: 'UNACCOMPANIED_MINOR',
  Pets: 'PETS',
  MeetAndAssist: 'MEET_AND_ASSIST',
  Others: 'OTHERS',
  Meal: 'MEAL',
} as const;

export type CategoryTypeEnum = (typeof CategoryTypeEnum)[keyof typeof CategoryTypeEnum];
