import { css } from '@emotion/react';
import { themed } from '../utils/styling/themed';
import { margins } from '../utils/styling/utilityClasses';
import { cornerRadii, paddings } from '../utils/styling/utilityTokens';

export const base = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.inverse};
    box-shadow: ${palette.shadow.componentShadow};
    ${cornerRadii.modalStandard}
    width: auto;
  `,
);

export const toast_base = (duration: number) =>
  themed(
    ({ palette, typography }) => css`
      background-color: ${palette.surface.inverse};
      ${paddings.y.comfortable}
      ${paddings.x.comfortable}
      position: relative;
      min-width: 320px;
      max-width: 790px;
      overflow: hidden;

      border: none;
      ${cornerRadii.modalStandard}

      .MuiAlertTitle-root {
        color: ${palette.text.inverse};
        ${typography.body1.default}
        ${typography.body1.semibold}
      /** Override Mui's default margin-top: -2px **/
      ${margins.y.m0}
      }

      .BlocksToast-message {
        color: ${palette.text.inverse};
        ${typography.body1.default}
      }

      .MuiAlert-icon {
        display: inline-block;
        ${paddings.y.none}
      }

      .MuiAlert-action {
        ${paddings.yt.none}
      }

      .MuiAlert-message {
        ${paddings.y.none}
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 4px;
        animation: shrinkBorder ${duration}ms linear forwards;
      }
      &:hover::before {
        animation-play-state: paused;
      }
      @keyframes shrinkBorder {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }
    `,
  );

export const fade_in = css`
  animation: toast-fadein 0.1s ease-out forwards;

  @keyframes toast-fadein {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const fade_out = css`
  animation: toast-fadeout 0.1s ease-in forwards;

  @keyframes toast-fadeout {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0;
    }
  }
`;

export const warning_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.warningInverse};
    }

    &::before {
      background-color: ${palette.border.warningInverse};
    }
  `,
);

export const error_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.errorInverse};
    }

    &::before {
      background-color: ${palette.border.errorInverse};
    }
  `,
);

export const success_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.successInverse};
    }

    &::before {
      background-color: ${palette.border.successInverse};
    }
  `,
);

export const info_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.infoLinkInverse};
    }

    &::before {
      background-color: ${palette.border.infoInverse};
    }
  `,
);

export const subtle_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.subtleInverse};
    }

    .BlocksToast-icon {
      animation: toast-spin 1s linear infinite;

      @keyframes toast-spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &::before {
      background-color: ${palette.border.inverseSubtle};
    }
  `,
);
