import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Typography from '@spotnana/pixel-react/dist/Typography';
import * as Icons from '@spotnana/pixel-react/dist/Icons';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';

interface INoResultsProps {
  message: string;
}

export default function NoResults({ message }: INoResultsProps): JSX.Element {
  return (
    <Box overflow="hidden" maxWidth="full" position="relative">
      <Flex textAlign="center" py={16} flexDirection="column" alignItems="center">
        <Flex
          borderRadius="sm"
          width={28}
          height={28}
          my={3}
          bg="bg.paleGray"
          alignItems="center"
          justifyContent="center"
          data-testid="select-traveler-v2-no-results-icon"
        >
          <Icons.User primaryColor={spotnanaTheme.colors.text.lightBlueGray} />
        </Flex>
        <Typography variation="body" data-testid="select-traveler-v2-no-results-text">
          <strong>{message}</strong>
        </Typography>
      </Flex>
    </Box>
  );
}
