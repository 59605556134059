import axios from 'axios';
import addRequestInterceptor from '../addRequestInterceptor';
import { publicEndpoints, publicEndpointsRegex } from '../../endpoints';
import { INVALID_AUTH_TOKEN } from '../../../constants';

function injectAuthorizationHeader(getAuthToken: () => Promise<string>) {
  return addRequestInterceptor({
    shouldIgnoreError: false,
    onFulfilled: async (config) => {
      const endpointOnly = config.url?.split('?')[0] ?? '';

      if (
        config.url &&
        (publicEndpoints.includes(endpointOnly) ||
          publicEndpointsRegex.some((endpointRegex) => endpointRegex.test(config.url as string)))
      ) {
        return config;
      }

      const token = await getAuthToken();

      if (!token) {
        // Special edge case where token can be empty inside the app
        // if user manually removes the auth token from the localstorage data that cognito is
        // using the token can be empty
        // if the user does the above and redirects to a different page or reload the same page
        // he/she will be logged out obviously.
        // else if the user does the above and tries to save some data to server, the token is
        // empty string and BE throws 4XX
        // the if check of token makes sure token is nonempty string and if empty string, we
        // will stop FE from sending request , nothing happens
        // there might be other special cases where token can be empty in FE that we are unaware
        // of at the moment, may be logging out in one tab and trying to send a request from another
        // tab, the user is logged out on the other tab as well, but he/she ll still have the UI from
        // before logging out, they can still try to save the changes which will still have empty string
        // as auth token, we aren't force logging out the tabs and devices real time as of now
        throw new axios.Cancel(INVALID_AUTH_TOKEN);
      }

      config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
  });
}

export default injectAuthorizationHeader;
