import type { AuthProviderType } from '../../types/api/v2/obt/model/auth-provider-type';
import type { SupportConfig } from '../../types/api/v2/obt/model/support-config';

export function isGenesysAuthCloudConfigValid(
  supportConfig: SupportConfig | undefined,
  authProviderType: AuthProviderType | undefined,
) {
  if (!supportConfig?.genesysCloudConfig) {
    return false;
  }

  const { genesysCloudConfig: config } = supportConfig;
  const { deploymentId, environment, genesysScriptUrl, enabled } = config;
  if (!deploymentId || !environment || !genesysScriptUrl || !enabled || authProviderType !== 'AZURE') {
    return false;
  }

  return true;
}
