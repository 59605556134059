import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const modal_footer_contaienr = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.one}
    ${spacings.small}
    background-color: ${palette.surface.background}
  `,
);
