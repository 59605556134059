import styled from 'styled-components';
import { space, color, layout, flexbox, compose, verticalAlign, textAlign, position, background, backgroundImage, shadow, fontSize, border, } from 'styled-system';
export function makeBox(component) {
    return styled(component)(compose(space, color, layout, flexbox, border, textAlign, position, background, shadow, backgroundImage, fontSize, verticalAlign), ({ transform, cursor, whiteSpace, textOverflow, pointerEvents }) => ({
        transform: transform || undefined,
        cursor: cursor !== null && cursor !== void 0 ? cursor : 'inherit',
        whiteSpace: whiteSpace !== null && whiteSpace !== void 0 ? whiteSpace : undefined,
        textOverflow: textOverflow !== null && textOverflow !== void 0 ? textOverflow : undefined,
        pointerEvents: pointerEvents !== null && pointerEvents !== void 0 ? pointerEvents : undefined,
    }));
}
/**
 * @deprecated Each time a Box component usage is added to the codebase, my cat breaks a glass.
 */
const Box = makeBox(styled.div({
    boxSizing: 'border-box',
    minWidth: 0,
}, ({ transition, theme: { transitions } }) => {
    var _a;
    return ({
        transition: transition ? (_a = transitions[transition]) !== null && _a !== void 0 ? _a : transition : undefined,
    });
}));
/**
 * @deprecated Each time a Flex component usage is added to the codebase, an angel loses its wings.
 */
export const Flex = styled(Box)({ display: 'flex' });
/**
 * @deprecated
 */
export default Box;
