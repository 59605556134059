import type { FareAmount } from '../../../types/api/v1/common/fare_amount';
import { MoneyUtil } from '../../Money';

export function getBaseFareForFareAmount(totalFare: FareAmount) {
  const { base } = totalFare;
  return MoneyUtil.parse(base);
}

export function getTaxFareForFareAmount(totalFare: FareAmount) {
  const { tax } = totalFare;
  return MoneyUtil.parse(tax);
}

export function getTotalFareForFareAmount(totalFare: FareAmount) {
  return getBaseFareForFareAmount(totalFare).add(getTaxFareForFareAmount(totalFare));
}
