import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Legal = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.205 6.875c0-1.161.941-2.103 2.102-2.103h13.666V.568H14.307A6.307 6.307 0 008 6.875v26.28a6.307 6.307 0 006.307 6.307H28.5V48l4.73-4.73L37.96 48v-9.588a7.873 7.873 0 003.154-6.308 7.865 7.865 0 00-2.665-5.91c.024-.128.037-.261.037-.397v-8.41a2.102 2.102 0 10-4.205 0v6.903A7.884 7.884 0 0026 35.258H14.308a2.102 2.102 0 01-2.102-2.103V6.875zm16.294 25.23a4.73 4.73 0 117.094 4.098l-.787.455v3.728l-1.577-1.576-1.577 1.576v-3.728l-.787-.455a4.728 4.728 0 01-2.366-4.099zm9.986-18.923H27.973V.568l10.512 12.614zM16.272 24.821l-.111.004a.888.888 0 00-.026 1.765l.112.007h9.237l.111-.004a.888.888 0 00.025-1.765l-.111-.007h-9.237zm0-4.931H32.03l.111.007a.888.888 0 01-.025 1.765l-.111.005h-15.76l-.11-.008a.888.888 0 01.025-1.765l.11-.004zm0-4.931l-.111.005a.888.888 0 00-.026 1.764l.112.007 15.759.001.11-.005a.888.888 0 00.026-1.765l-.111-.006-15.76-.001zm0-4.931h7.278l.111.007a.888.888 0 01-.025 1.765l-.111.005h-7.278l-.111-.008a.888.888 0 01.025-1.764l.11-.005z", fill: themeEnrichedPrimaryColor })));
};
Legal.defaultProps = defaultIconProps;
export default Legal;
