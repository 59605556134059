import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const CheckoutCom3DS2RedirectPage = lazy(() => import('./CheckoutCom3DS2RedirectPage'));

export const paymentsRoutes: IRouteConfig[] = [
  {
    key: 'payments-3ds2',
    path: '/payments/3ds2',
    component: CheckoutCom3DS2RedirectPage,
    isPublicRoute: true,
  },
];
