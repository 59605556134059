import type { PropsWithChildren } from 'react';
import { useContext } from 'react';
import * as React from 'react';

import type { TRailSeatReservationAndPreferencesContext } from './types';
import SpotnanaError from '../../api/SpotnanaError';

const RailSeatReservationAndPreferencesContext = React.createContext<TRailSeatReservationAndPreferencesContext | null>(
  null,
);

export const RailSeatReservationAndPreferencesContextProvider: React.FC<
  PropsWithChildren<{ contextValue: TRailSeatReservationAndPreferencesContext }>
> = ({ children, contextValue }) => {
  return (
    <RailSeatReservationAndPreferencesContext.Provider value={contextValue}>
      {children}
    </RailSeatReservationAndPreferencesContext.Provider>
  );
};

export function useRailSeatReservationAndPreferencesContext() {
  const context = useContext(RailSeatReservationAndPreferencesContext);

  if (!context) {
    throw new SpotnanaError(
      '`useRailSeatReservationAndPreferencesContext` should be used inside the component tree wrapped with `RailSeatReservationAndPreferencesContextProvider`!',
    );
  }

  return context;
}

export { useRailSeatReservationAndPreferencesState } from './useRailSeatReservationAndPreferencesState';
export * from './selectors';
