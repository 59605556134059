import { dateUtil } from 'obt-common/date-utils';
import { Fare } from 'obt-common';

export const getExpiredFares = (fares: Fare[]): Fare[] => {
  const today = dateUtil();
  return fares.filter((fare) => {
    const { segments } = fare;
    const isSegmentBeforeToday = segments.some((segment) => {
      const segmentDate = dateUtil(segment.date);
      return segmentDate.isBefore(today, 'day');
    });
    return !!isSegmentBeforeToday;
  });
};
