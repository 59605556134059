import React, { createContext, useContext, useMemo } from 'react';
import { useTripFeaturesQuery } from '../../queries/trip';
import type { AppliedTripFeatures } from '../../types/api/v2/obt/model/applied-trip-features';
import { useFeatureFlag } from '../../feature-gating/hooks';

interface TripFeaturesContextProps {
  tripId: string;
  loader?: React.ReactNode;
}

interface TripFeaturesContextData {
  tripFeaturesData: AppliedTripFeatures | undefined;
  isLoading: boolean;
}
export const TripFeaturesContext = createContext<TripFeaturesContextData | null>(null);

export const TripFeaturesProvider: React.FC<React.PropsWithChildren<TripFeaturesContextProps>> = ({
  tripId,
  children,
  loader = null,
}) => {
  const tripFeaturesEnabled = useFeatureFlag('FE_COMMON_TRIP_FEATURES');

  const { data: tripFeaturesData, isLoading } = useTripFeaturesQuery({
    tripId,
    options: { enabled: tripFeaturesEnabled && !!tripId },
  });

  const contextValue = useMemo(() => {
    return {
      tripFeaturesData,
      isLoading,
    };
  }, [tripFeaturesData, isLoading]);

  const renderContent = () => {
    if (isLoading && loader) {
      return loader;
    }
    return children;
  };

  return <TripFeaturesContext.Provider value={contextValue}>{renderContent()}</TripFeaturesContext.Provider>;
};

export const useTripFeaturesData = () => {
  const tripFeaturesData = useContext(TripFeaturesContext);
  if (!tripFeaturesData) {
    throw new Error('useTripFeaturesData must be used within a TripFeaturesProvider');
  }
  return tripFeaturesData;
};
