import { lazy } from 'react';

import { IRouteConfig } from 'src/app/types/routes';

const CompanyReportsPage = lazy(() => import('./reports/CompanyReportsPage'));
const ScheduledReportsPage = lazy(() => import('./reports/ReportSchedulesPage'));
const TravelerReportsPage = lazy(() => import('./reports/TravelerReportsPage'));
const DutyOfCarePage = lazy(() => import('./DutyOfCarePage'));

export enum ANALYTICS_ROUTES {
  // AppHeader doesn't have a notion of exact routes so can't put this under /reports/schedules yet.
  REPORT_SCHEDULES = '/report-schedules',
  REPORTS = '/reports',
  TRAVELER_REPORTS = '/traveler-reports',
  DUTY_OF_CARE = '/dutyofcare',
}

export const analyticsRoutes: IRouteConfig[] = [
  {
    key: 'report-schedules',
    path: ANALYTICS_ROUTES.REPORT_SCHEDULES,
    component: ScheduledReportsPage,
  },
  {
    key: 'reports',
    path: ANALYTICS_ROUTES.REPORTS,
    component: CompanyReportsPage,
  },
  {
    key: 'traveler-reports',
    path: ANALYTICS_ROUTES.TRAVELER_REPORTS,
    component: TravelerReportsPage,
  },
  {
    key: 'duty-of-care',
    path: ANALYTICS_ROUTES.DUTY_OF_CARE,
    component: DutyOfCarePage,
    exact: true,
  },
];
