/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState, useLayoutEffect } from 'react';
const useHeight = ({ on = true /* no value means on */ } = {}) => {
    const ref = useRef();
    const [height, set] = useState(0);
    const heightRef = useRef(height);
    const [resizeObeserver] = useState(() => new ResizeObserver((_packet) => {
        if (ref.current && heightRef.current !== ref.current.offsetHeight) {
            heightRef.current = ref.current.offsetHeight;
            set(ref.current.offsetHeight);
        }
    }));
    useLayoutEffect(() => {
        if (on && ref.current) {
            set(ref.current.offsetHeight);
            resizeObeserver.observe(ref.current, {});
        }
        return () => resizeObeserver.disconnect();
    }, [on, resizeObeserver]);
    return [ref, height];
};
export default useHeight;
