/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { DateTimeLocal } from './date-time-local';
// May contain unused imports in some cases
// @ts-ignore
import { DateTimeOffset } from './date-time-offset';
// May contain unused imports in some cases
// @ts-ignore
import { Money } from './money';

/**
 * Cancellation policy info.
 * @export
 * @interface CancellationPolicy1
 */
export interface CancellationPolicy1 {
  /**
   * Indicates whether the booking is refundable or not.
   * @type {string}
   * @memberof CancellationPolicy1
   */
  policy: CancellationPolicy1PolicyEnum;
  /**
   *
   * @type {DateTimeLocal}
   * @memberof CancellationPolicy1
   */
  deadline?: DateTimeLocal;
  /**
   *
   * @type {DateTimeOffset}
   * @memberof CancellationPolicy1
   */
  deadlineUtc?: DateTimeOffset;
  /**
   *
   * @type {Money}
   * @memberof CancellationPolicy1
   */
  amount?: Money;
}

export const CancellationPolicy1PolicyEnum = {
  Unknown: 'UNKNOWN',
  NonRefundable: 'NON_REFUNDABLE',
  FreeCancellationUntil: 'FREE_CANCELLATION_UNTIL',
  PartiallyRefundable: 'PARTIALLY_REFUNDABLE',
} as const;

export type CancellationPolicy1PolicyEnum =
  (typeof CancellationPolicy1PolicyEnum)[keyof typeof CancellationPolicy1PolicyEnum];
