import { Modal } from '@spotnana/blocks/src/Modal';
import { useTranslation } from 'react-i18next';
import { TravelerPickerOptionPrefix } from 'src/app/shared/components/GroupBookingTravelerSelector/TravelerPickerOption/TravelerPickerOptionPrefix';
import { TravelerAutocomplete } from 'src/app/shared/components/TravelerAutocomplete/TravelerAutocomplete';
import { EntityAutocompleteProfileOptionDescription } from 'src/v1-components/shared/EntityAutocomplete/EntityAutocompleteProfileOptionDescription';
import { TravelerBasicInfo } from 'obt-common';
import { Button } from '@spotnana/blocks/src/Button';
import { Icon } from '@spotnana/blocks/src/Icon';
import { margins } from '@spotnana/blocks/src/utils';
import { AddMeButton } from './AddMeButton';
import { icon_style } from './index.styles';

interface IProps {
  open?: boolean;
  header?: string;
  onAddMe?: VoidFunction;
  registerNewTravelerText?: string;
  showRegisterTravelerButton?: boolean;
  onSelect: (selectionValue: TravelerBasicInfo | null) => void;
  onClose: VoidFunction;
  onRegisterNewTraveler?: VoidFunction;
}

export const TravelerAutocompleteModal = ({
  open,
  header,
  registerNewTravelerText,
  onAddMe,
  onRegisterNewTraveler,
  onSelect,
  onClose,
}: IProps) => {
  const { t: tt } = useTranslation('WEB');
  return (
    <Modal open={!!open} header={{ title: header ?? tt('Change primary traveler') }} onClose={onClose}>
      <TravelerAutocomplete
        onTravelerSelect={(value: TravelerBasicInfo | null) => {
          onSelect(value);
          onClose();
        }}
        startAdornment={<Icon css={icon_style} name="UserTravelerAdd" />}
        endAdornment={onAddMe ? <AddMeButton onClick={onAddMe} /> : undefined}
        slots={{
          ItemPrefix: TravelerPickerOptionPrefix,
          ItemDescription: EntityAutocompleteProfileOptionDescription,
        }}
      />
      {onRegisterNewTraveler && (
        <Button
          variant="ghost"
          color="blue"
          onClick={() => {
            onRegisterNewTraveler?.();
            onClose();
          }}
          startIcon="AddPlusCircle"
          css={margins.yt.m2}
        >
          {registerNewTravelerText ?? tt('Register new traveler')}
        </Button>
      )}
    </Modal>
  );
};
