import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { I18N_CUSTOM_RESOURCE_KEY } from '../../translations/customResources';
import { getCustomI18nResource } from '../../translations/customResources';
import Config from '../../utils/Config';
import { useLoggedInUser } from '../../providers';
import type { Traveler } from '../../types/api/v1/obt/common/traveler';

/**
 * Returns the I18n Custom Resource Key for a user
 * @returns
 */
const getResourceKey = (user: Traveler | undefined) => {
  const orgId = user?.userOrgId.organizationId?.id;
  const contractingTmcId = user?.userOrgId.tmcBasicInfo?.contractingTmc?.id?.id;

  // list of orgIds we need to match against, in heirarchy
  // if orgId matches, then go with that, else match with contractingTmcId
  const orderedIdsToCheck = [orgId, contractingTmcId];

  try {
    const orgToResourceMap = JSON.parse((Config.VITE_I18N_ORG_RESOURCE_KEY_MAP ?? '{}') as string) as Record<
      string,
      I18N_CUSTOM_RESOURCE_KEY
    >;
    const resourceKeyMatch = orderedIdsToCheck.find((id) => id && Boolean(orgToResourceMap[id]));
    return resourceKeyMatch ? orgToResourceMap[resourceKeyMatch] : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * Loads Custom I18n Resources based on current orgId
 */
export function useCustomI18nResources() {
  const user = useLoggedInUser();
  const { i18n } = useTranslation();

  const { language } = i18n;

  // extract resource key based on org id
  const resourceKey = useMemo(() => getResourceKey(user.data), [user.data]);

  // load custom resources based on resource key
  useEffect(() => {
    async function loadCustomContent() {
      if (resourceKey) {
        const customResources = await getCustomI18nResource(resourceKey, language);

        // load available namespaces
        Object.entries(customResources).forEach(([namespace, resources]) => {
          i18n.addResourceBundle(language, namespace, resources, false, true);
        });
      } else {
        // reset i18n resources to remove any custom content that was previously added
        i18n.reloadResources([language]);
      }
    }

    loadCustomContent();
  }, [resourceKey, language, i18n]);
}
