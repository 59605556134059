import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Policy = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 24 24" },
        React.createElement("path", { d: "M8.618 7.773h3.333a.795.795 0 1 0 0-1.59H8.618a.795.795 0 1 0 0 1.59ZM6.974 11.31h6.62a.795.795 0 1 0 0-1.59h-6.62a.795.795 0 1 0 0 1.59ZM9.053 13.257H6.974a.795.795 0 1 0 0 1.59h2.079a.795.795 0 1 0 0-1.59Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M21.657 14.429a.797.797 0 0 0-.445-.754l-3.642-1.778V5.362A2.364 2.364 0 0 0 15.208 3H8.2c-.633 0-1.228.246-1.676.695l-2.83 2.839A2.377 2.377 0 0 0 3 8.2v9.473c0 1.306 1.06 2.37 2.362 2.37h8.715c.715.929 1.574 1.552 2.534 1.907a.796.796 0 0 0 .55 0c2.198-.811 3.465-2.874 4.078-5.153.362-1.296.416-2.326.418-2.37ZM5.362 18.453a.776.776 0 0 1-.771-.78V8.202c0-.2.083-.397.229-.543l2.83-2.839a.774.774 0 0 1 .551-.228h7.007c.425 0 .771.345.771.77v6.548l-3.441 1.773a.793.793 0 0 0-.43.752c.06 1.111.46 2.809 1.017 4.019H5.362Zm14.343-2.075c-.436 1.621-1.293 3.264-2.819 3.967-1.945-.896-2.841-3.37-3.136-5.498l3.034-1.564 3.232 1.578c-.05.37-.142.91-.311 1.517Z", fill: themeEnrichedPrimaryColor })));
};
Policy.defaultProps = defaultIconProps;
export default Policy;
