import { FC } from 'react';
import { AirSelectedItineraryResponseManager, localisedOriginalCurrencyValue, localizationKeys } from 'obt-common';
import { Typography } from '@spotnana/blocks/src/Typography';
import { useTranslation } from 'react-i18next';
import { fare_footer_container } from './styles';

interface FareFooterProps {
  airSelectedItineraryResponseManager: AirSelectedItineraryResponseManager;
}

const { LOCALIZED_CURRENCY } = localizationKeys;

export const FareFooter: FC<FareFooterProps> = ({ airSelectedItineraryResponseManager }) => {
  const { t: tt } = useTranslation('WEB');
  const { t: globalTranslate } = useTranslation();
  const totalAmount = airSelectedItineraryResponseManager.GetTotalFare(0);
  return (
    <div css={fare_footer_container}>
      <Typography variant="body2">{tt('Total amount')}</Typography>
      <Typography variant="body2" kind="semibold">
        {globalTranslate(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalAmount))}
      </Typography>
    </div>
  );
};
