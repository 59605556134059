import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CarSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M7.455 6.5l2.048.107c1.025.054 1.948.629 2.44 1.519.189.342.298.564.352.736l.523 1.681c.128.41.092.83-.071 1.192.433.264.815.61 1.119 1.03.192.266.346.554.456.857l.051.152 1.79 5.743c.159-.015.32-.022.481-.022h5.494c1.857 0 3.362 1.485 3.362 3.318v1.79c0 .495-.407.897-.909.897H12.378c-.393 0-.728-.246-.855-.59-.024-.042-.045-.085-.063-.131l-.023-.067-2.185-7.303-1.336-4.785c-.013-.047-.022-.093-.027-.14l-.022-.066-1.335-4.786c-.165-.59.304-1.165.923-1.132zm14.683 14.789h-5.494c-1.52 0-2.805.997-3.218 2.366l-.015.051h10.272v-.893c0-.814-.647-1.479-1.46-1.522l-.085-.002zm-11.923-8.401l-.306.042-.022.005L11 16.919l1.417 4.736c.508-.708 1.194-1.283 1.994-1.661l-1.775-5.695c-.053-.172-.137-.338-.25-.494-.467-.647-1.328-.984-2.171-.917zM8.618 8.356l.79 2.83.218-.03c.136-.024.273-.042.41-.054l1.014-.135-.495-1.587c-.01-.022-.04-.085-.089-.177l-.119-.22c-.189-.343-.544-.564-.938-.585l-.79-.042z" })));
};
CarSeat.defaultProps = defaultIconProps;
export default CarSeat;
