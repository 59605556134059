import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Chat = ({ width, height, primaryColor }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 12 12", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.6609 4.54163C11.2008 4.54163 11.6432 4.96605 11.6721 5.49885L11.6736 5.55434V10.9077C11.6736 11.2892 11.4623 11.6349 11.1226 11.8091C10.9781 11.883 10.82 11.921 10.6604 11.921C10.4729 11.921 10.2892 11.8687 10.1285 11.7692L10.0693 11.7296L8.27975 10.4416C8.27154 10.4356 8.26261 10.4317 8.25309 10.4297L8.2384 10.4282H3.80373C3.26383 10.4282 2.82141 10.0038 2.79252 9.47097L2.79102 9.41548L2.79102 8.28808L3.73281 7.61025L3.73284 9.41559C3.73284 9.44807 3.755 9.47565 3.78494 9.48393L3.80373 9.48648H8.2384C8.42815 9.48648 8.61105 9.53867 8.77106 9.63786L8.82996 9.67725L10.6194 10.9652C10.6461 10.9844 10.6694 10.9831 10.6931 10.9709C10.7123 10.961 10.7255 10.9474 10.7301 10.9256L10.7318 10.9077V5.55434C10.7318 5.51536 10.6999 5.48345 10.6609 5.48345L9.32896 5.48372C9.33372 5.45214 9.33701 5.42009 9.33877 5.38764L9.34027 5.33215L9.3401 4.54163H10.6609Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.16774 1.12385C0.196634 0.591046 0.639051 0.166626 1.17895 0.166626H8.03612L8.09161 0.168127C8.62441 0.197022 9.04883 0.639438 9.04883 1.17934V5.91548L9.04733 5.97097C9.01843 6.50377 8.57602 6.92819 8.03612 6.92819H3.60144L3.58675 6.92969C3.57724 6.93168 3.5683 6.93565 3.5601 6.94156L1.77051 8.22965L1.71136 8.26917C1.55066 8.36869 1.36699 8.42096 1.17949 8.42096C1.01983 8.42096 0.861695 8.38297 0.717226 8.30905C0.377579 8.13493 0.166239 7.7892 0.166239 7.40771V1.17934L0.16774 1.12385ZM8.03608 1.10848H1.17892C1.13994 1.10848 1.10803 1.14039 1.10803 1.17937V7.40774L1.10976 7.42565C1.11429 7.44741 1.12756 7.46105 1.14674 7.4709C1.17044 7.48316 1.1937 7.48446 1.2204 7.46524L3.00985 6.17728L3.06875 6.13789C3.22876 6.0387 3.41166 5.98651 3.60141 5.98651H8.03608L8.05487 5.98397C8.08481 5.97568 8.10697 5.94811 8.10697 5.91562V1.17937L8.10443 1.16058C8.09614 1.13064 8.06857 1.10848 8.03608 1.10848ZM5.30588 3.54745C5.30588 3.84668 5.54845 4.08925 5.84767 4.08925C6.1469 4.08925 6.38947 3.84668 6.38947 3.54745C6.38947 3.24823 6.1469 3.00566 5.84767 3.00566C5.54845 3.00566 5.30588 3.24823 5.30588 3.54745ZM2.85238 3.54745C2.85238 3.84668 3.09495 4.08925 3.39418 4.08925C3.6934 4.08925 3.93597 3.84668 3.93597 3.54745C3.93597 3.24823 3.6934 3.00566 3.39418 3.00566C3.09495 3.00566 2.85238 3.24823 2.85238 3.54745Z", fill: themeEnrichedPrimaryColor })));
};
Chat.defaultProps = defaultIconProps;
export default Chat;
