import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CheckCircle = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.916 4.584a7.41 7.41 0 00-8.347 6.34 7.412 7.412 0 1014.436-1.152.75.75 0 00-1.435.437 5.912 5.912 0 11-1.378-2.36.75.75 0 001.087-1.034 7.381 7.381 0 00-4.363-2.231zm3.36 4.632a.75.75 0 00-1.06.008l-4.266 4.332-2.165-2.2-.048-.044a.75.75 0 00-1.021 1.097l2.557 2.597.05.047a.95.95 0 001.294-.036l4.668-4.74.043-.049a.75.75 0 00-.052-1.012z", fill: themeEnrichedPrimaryColor })));
};
CheckCircle.defaultProps = defaultIconProps;
export default CheckCircle;
