/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Flight cabin
 * @export
 * @enum {string}
 */
export const Cabin = {
  UnknownCabin: 'UNKNOWN_CABIN',
  Economy: 'ECONOMY',
  PremiumEconomy: 'PREMIUM_ECONOMY',
  Business: 'BUSINESS',
  First: 'FIRST',
} as const;

export type Cabin = (typeof Cabin)[keyof typeof Cabin];
