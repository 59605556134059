import type { CountryCode, PhoneNumber } from 'libphonenumber-js';
import { parsePhoneNumberFromString, AsYouType, parseIncompletePhoneNumber } from 'libphonenumber-js';
import * as internalAPIErrors from '../../constants/internalAPIErrors';
import type { PhoneNumber as V2PhoneNumber } from '../../types/api/v2/obt/model/phone-number';
import type { PhoneNumber as V1PhoneNumber } from '../../types/api/v1/common/phone_number';
import { mapPhoneTypeFromV1ToV2, mapPhoneSourceCodeFromV1ToV2 } from '../../constants/common';

export function mapPhoneNumberFromV1ToV2(x: undefined): undefined;
export function mapPhoneNumberFromV1ToV2(x: V1PhoneNumber): V2PhoneNumber;
export function mapPhoneNumberFromV1ToV2(phone?: V1PhoneNumber): V2PhoneNumber | undefined {
  if (!phone) {
    return undefined;
  }
  return {
    ...phone,
    countryCodeSource: mapPhoneSourceCodeFromV1ToV2[phone.countryCodeSource],
    type: mapPhoneTypeFromV1ToV2[phone.type],
  };
}

class PhoneFormatter {
  readonly phone: PhoneNumber | undefined;

  readonly rawPhoneNumber: string;

  constructor(phoneNumber: string, countryCode: string) {
    this.phone = phoneNumber ? parsePhoneNumberFromString(phoneNumber, countryCode as CountryCode) : undefined;
    this.rawPhoneNumber = phoneNumber;
  }

  public formatInternational(): string {
    return this.phone?.formatInternational() ?? this.rawPhoneNumber;
  }

  public formatNational(): string {
    return this.phone?.formatNational() ?? this.rawPhoneNumber;
  }

  static formatAsYouType(phoneNumber: string, countryCode: string): string {
    return new AsYouType((countryCode as CountryCode) ?? '').input(phoneNumber) ?? phoneNumber;
  }

  static unformatNumber(phoneNumber: string): string {
    return parseIncompletePhoneNumber(phoneNumber);
  }

  static validatePhoneNumber(phoneNumber: string, countryCode?: CountryCode): string {
    const phone = phoneNumber ? parsePhoneNumberFromString(phoneNumber, countryCode as CountryCode) : undefined;

    if (!phone || !phone.isPossible() || !phone.isValid()) {
      return internalAPIErrors.CommonErrors.INVALID_PHONE_NUMBER_PATTERN;
    }
    return '';
  }

  static dashedPhoneNumber(phoneNumber?: string, countryCode?: number): string {
    if (!phoneNumber || !countryCode) return '';
    return `+${countryCode}-${phoneNumber}`;
  }

  static phoneNumberWithoutDash(phoneNumber: string, countryCode?: number): string {
    if (!phoneNumber || !countryCode) return '';
    return `+${countryCode}${phoneNumber}`;
  }

  static phoneNumberWithSpace(phoneNumber: string, countryCode?: number): string {
    if (!phoneNumber || !countryCode) return '';
    return `+${countryCode} ${phoneNumber}`;
  }
}

export default PhoneFormatter;
