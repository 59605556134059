import { useCallback } from 'react';
import type { TOptions } from 'i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import type { ICommonI18nMessage } from './defineMessage';

type OptionsWithValues<T> = T extends ICommonI18nMessage<infer TValues>
  ? TOptions<{ values: TValues }>
  : TOptions<{ values?: never }>;

interface Result extends Omit<UseTranslationResponse<'COMMON'>, 't'> {
  t: <T extends ICommonI18nMessage<any>>(commonMgs: T, options?: OptionsWithValues<T>) => string;
}

export function useCommonTranslation(): Result {
  const trans = useTranslation('COMMON');
  return {
    ...trans,
    t: useCallback(
      ({ message }, options): string => {
        if (!options?.values && (message as string)?.includes('{{')) {
          console.warn(`[useCommonTranslation]: Missing values for message: "${message}"`);
        }

        return trans.t(message as string, options?.values);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [trans.t],
    ),
  };
}
