import { StorageKeys } from '../../types/storage';
import { saveStorageValue } from '../../hooks/useStorage';
import { emitTmcIdChange } from './eventemitter';

export async function setTmcId(tmcId: string): Promise<void> {
  if (!tmcId) {
    // Prevent setting empty tmcId
    return;
  }

  await saveStorageValue(StorageKeys.TMC_ID, tmcId).finally(() => {
    emitTmcIdChange(tmcId);
  });
}
