import * as Sentry from '@sentry/react';
import { useMonitoringMetadata } from 'obt-common/hooks/monitoring/useMonitoringMetadata';
import { useEffect } from 'react';

/**
 * Enhances Monitoring by injecting additional meta data while reporting events
 */
const useEnhancedMonitoring = (): void => {
  const monitoringMetadata = useMonitoringMetadata();

  useEffect(() => {
    Sentry.setTags(monitoringMetadata);
  }, [monitoringMetadata]);
};

export { useEnhancedMonitoring };
