import type { Moment } from 'moment';
import { getDiff, dateUtil, getFutureDate, isSameOrBefore } from '../../date-utils';
import { validateEmailAddress } from '../../validations/traveler';
import { emptyName } from '../../constants/traveler';
import TravelerDetailsResponseManager from '../../services/TravelerDetailsResponseManager';
import type { PassengerType } from '../../types/api/v1/obt/air/air_common';
import { PassengerTypeEnum } from '../../types/api/v1/obt/air/air_common';
import type { ScreenTypeEnum } from '../../types/common';
import type {
  IBasicTravelerInfoError,
  IdentityDocs,
  INationalDoc,
  IPassport,
  IPointsConfig,
  ITraveler,
  ITravelerCheckoutInfoV2,
  ITravelerPersonalInfo,
} from '../../types/traveler';
import { IGenderEnum, IPersonaEnum } from '../../types/traveler';
import { validateTravelerCheckoutDetails } from '../checkout';
import { getTravelerDocMap } from '../Flights';
import { dateFormats } from '../../constants';
import type { PointsBalance } from '../../types/api/v2/obt/model/points-balance';
import type { Persona } from '../../types/api/v1/obt/common/user_org_id';
import type { PointsType } from '../../types/payment';

export const getTravelerIdentityDocs = (traveler: ITraveler): IdentityDocs => {
  const travelerDetailsResponseManager = new TravelerDetailsResponseManager(traveler);
  const passports: IPassport[] = travelerDetailsResponseManager.GetPassports();
  const nationalDocs: INationalDoc[] = travelerDetailsResponseManager.GetNationalDoc();
  const travelDocMap = getTravelerDocMap(traveler.user?.identityDocs);
  return {
    redressNumber: travelDocMap.has('redressNumber')
      ? traveler.user?.identityDocs[travelDocMap.get('redressNumber')].redressNumber ?? ''
      : '',
    knownTravelerNumber: travelDocMap.has('knownTravelerNumber')
      ? traveler.user?.identityDocs[travelDocMap.get('knownTravelerNumber')].knownTravelerNumber ?? ''
      : '',
    passports,
    nationalDocs,
    ...(travelDocMap.has('ktn') ? { ktn: traveler.user?.identityDocs[travelDocMap.get('ktn')].ktn } : {}),
    ...(travelDocMap.has('redress')
      ? { redress: traveler.user?.identityDocs[travelDocMap.get('redress')].redress }
      : {}),
  };
};

export const getTravelerBasicInfo = (
  traveler: ITraveler,
  checkoutType: keyof typeof ScreenTypeEnum,
  shouldValidatePersonalEmail = false,
  ignoreErrors: Array<keyof Pick<IBasicTravelerInfoError, 'phoneError'>> = [],
  isTravelerBookingNameEnabled = false,
): {
  travelerDetail: ITravelerCheckoutInfoV2;
  travelerErrorState: IBasicTravelerInfoError;
  hasTravelerBasicInfo: boolean;
  personalInfo: ITravelerPersonalInfo;
} => {
  const identityDocs = getTravelerIdentityDocs(traveler);
  const travelerDetail = {
    name: traveler.user?.name ?? { ...emptyName },
    travelerName: traveler.user?.travelerName,
    dob: traveler.user?.dob?.iso8601 ?? '',
    contactInfo: traveler.user?.phoneNumbers ?? [],
    gender: traveler.user?.gender ?? IGenderEnum.UNSPECIFIED,
    profilePictureUrl: traveler.user?.profilePicture?.url ?? '',
    nationality: traveler.user?.nationality ?? '',
    ...identityDocs,
  };

  const { travelerErrorState, hasTravelerBasicInfo } = validateTravelerCheckoutDetails(
    travelerDetail,
    checkoutType,
    ignoreErrors,
    isTravelerBookingNameEnabled,
  );
  const errors: IBasicTravelerInfoError = {
    ...travelerErrorState,
  };

  const personalInfo = new TravelerDetailsResponseManager(traveler).GetPersonalInfo();

  if (shouldValidatePersonalEmail) {
    const emailError = validateEmailAddress(personalInfo.contactInfo.email || '');

    if (emailError) {
      errors.emailError = emailError;
    } else {
      errors.emailError = '';
    }
  }

  return {
    personalInfo,
    travelerDetail,
    travelerErrorState: errors,
    hasTravelerBasicInfo: shouldValidatePersonalEmail
      ? hasTravelerBasicInfo && !errors.emailError
      : hasTravelerBasicInfo,
  };
};

export const getPaxTypeByDateOfBirth = (dob: string): PassengerType => {
  // eslint-disable-next-line no-restricted-syntax
  const ageInYears = getDiff(dob, dateUtil().format('YYYY-MM-DD'));

  if (ageInYears < 2) {
    return PassengerTypeEnum.INFANT;
  }
  if (ageInYears < 18 && ageInYears >= 2) {
    return PassengerTypeEnum.CHILD;
  }
  return PassengerTypeEnum.ADULT;
};

export const isPassportExpiringInNMonths = (passport: IPassport, months: number): boolean => {
  const expiryNotifyDate = getFutureDate(months, 'months', dateFormats.LONG_DATE_REVERSE_FORMAT);

  return isSameOrBefore(passport.expiryDate?.iso8601 ?? '', expiryNotifyDate, dateFormats.LONG_DATE_REVERSE_FORMAT);
};

export const getPassportExpiringInNMonths = (passports: IPassport[], months: number): IPassport[] =>
  passports.filter((passport) => isPassportExpiringInNMonths(passport, months));

export const getBrexPointsInfo = (pointsBalance: PointsBalance[] | undefined): IPointsConfig => {
  if (pointsBalance && pointsBalance.length > 0) {
    const brexPoints = pointsBalance.find((pointBalance) => pointBalance.pointType === 'BREX_POINT_TYPE');
    if (brexPoints) {
      return {
        isApplicable: true,
        availablePoints: brexPoints.amount,
      };
    }
  }
  return {
    isApplicable: false,
    availablePoints: 0,
  };
};

export const getPointsBalance = (pointsBalance: PointsBalance[] | undefined, pointsType: PointsType): number => {
  if (pointsBalance && pointsBalance.length > 0) {
    const pointsData = pointsBalance.find((pointBalance) => pointBalance.pointType === pointsType);
    if (pointsData) {
      return pointsData.amount;
    }
  }
  return 0;
};

export const isProfilePersonal = (persona?: Persona): boolean => IPersonaEnum.PERSONAL === persona;

export const isPersonalTravelEditable = (persona?: Persona): boolean =>
  [IPersonaEnum.EMPLOYEE, IPersonaEnum.PERSONAL].includes(persona ?? IPersonaEnum.UNRECOGNIZED);

export const dateUtilLongReverseFormat = (date: Moment | null): string => {
  // as a part of dates localization feature, only certain dateStyles are allowed, .format is not allowed (same for #103)
  // reference slack thread https://spotnana.slack.com/archives/C019LG3E53Q/p1683797578989369?thread_ts=1683722108.656839&cid=C019LG3E53Q
  // eslint-disable-next-line no-restricted-syntax
  return date?.format(dateFormats.LONG_DATE_REVERSE_FORMAT) ?? '';
};
