import { ROUTE_PATHS } from 'src/routes';
import { useTranslation } from 'react-i18next';
import { LinkButton } from 'src/app/shared/components/LinkButton/LinkButton';
import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';

const styles = themed(
  ({ palette }) => css`
    width: 100%;
    ${flex.init}
    ${flex.justify.start}
  padding-left: 1.5rem !important;
    color: ${palette.text.primary} !important;
  `,
);

export function ApiClient() {
  const { t: tt } = useTranslation('WEB');
  return (
    <LinkButton to={ROUTE_PATHS.API_CLIENT} target="_blank" variant="ghost" css={styles}>
      {tt('API client')}
    </LinkButton>
  );
}
