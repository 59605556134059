import React, { createContext, useContext, ReactElement, useMemo } from 'react';

export interface IConfigValues {
  showFlags: boolean;
}

export interface IConfigContext {
  config: IConfigValues | undefined;
}

const ConfigContext = createContext<IConfigValues | undefined>(undefined);

export const CustomizationProvider = ({ config, children }: React.PropsWithChildren<IConfigContext>): ReactElement => {
  const value = useMemo(() => config, [config]);

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useCustomizationConfig = (): IConfigValues => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('`useConfig` hook must be used within a `ConfigProvider` component');
  }
  return context;
};
