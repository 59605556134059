import Box from '@spotnana/pixel-react/dist/Box';
import { CSSObject } from 'styled-components';

const DotSeperator = ({
  color = 'bg.greyBlue',
  style = undefined,
  size = 4,
  gap = 4,
  display,
  inline = false,
}: {
  color?: string;
  style?: CSSObject;
  size?: number;
  gap?: number;
  display?: any;
  inline?: boolean;
}): JSX.Element => (
  <Box
    as={inline ? 'span' : 'div'}
    verticalAlign="middle"
    width={size}
    height={size}
    borderRadius={size}
    display={display}
    backgroundColor={color}
    style={{ marginLeft: gap, marginRight: gap, ...style }}
  />
);

export default DotSeperator;
