import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useScript } from '@spotnana/pixel-react/dist/hooks/useScript';
import { getRawColorFromCssVariableWithFallback } from '@spotnana/pixel-react/dist/utils';
import {
  useFetchCompanySupportConfig,
  useApplicationQuery,
  useLoggedInUser,
  useLoggedInUserId,
  getUserTierForDisplay,
  useFetchCompanyBasicInfo,
  CompanyRole,
  DEFAULT_SPOTNANA_PARTNER_NAME,
  useApplicationId,
  roleTypeToRoleNameMap,
  useLoggedInUserBasicInfo,
} from 'obt-common';

import { useEmbedStore } from '../../../components/EmbedContext';

declare global {
  interface Window {
    fcWidget: any;
  }
}

function InitializeFreshchat(): JSX.Element {
  const theme = useTheme();
  const { data: userInfo, isLoading: isFetchingLoggedInUser } = useLoggedInUser();
  const userBasicInfo = useLoggedInUserBasicInfo();
  const userOrgId = userInfo?.userOrgId;

  const { data: partnerInfo, isLoading: isPartnerInfoLoading } = useFetchCompanyBasicInfo(
    userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id,
    true,
    'partnerIndicator',
  );
  const loggedInUserId = useLoggedInUserId();
  const { isLoading: isLoadingSupportConfig, data: supportConfigResponse } = useFetchCompanySupportConfig(
    loggedInUserId.tmcBasicInfo?.bookingTmc?.id?.id ?? '',
  );

  const { applicationId, isApplicationIdLoading } = useApplicationId();
  const { data: applicationResponse, isLoading: applicationQueryLoading } = useApplicationQuery(applicationId);
  const isApplicationLoading = isApplicationIdLoading && applicationQueryLoading;

  const shouldLoadFreshChatScript =
    !isLoadingSupportConfig &&
    (supportConfigResponse?.supportConfig?.freshdeskConfig?.enabled ||
      !supportConfigResponse?.supportConfig?.freshdeskConfig);

  const isScriptLoaded = useScript(
    shouldLoadFreshChatScript ? 'https://wchat.freshchat.com/js/widget.js' : '',
    'Freshdesk Messaging-js-sdk',
  );

  const { isEmbed } = useEmbedStore();

  const themePrimary = getRawColorFromCssVariableWithFallback(theme.colors.primary, '');
  const appName = applicationId ? applicationResponse?.name : null;

  const isLoading = Boolean(
    !shouldLoadFreshChatScript ||
      !isScriptLoaded ||
      isFetchingLoggedInUser ||
      isEmbed ||
      isPartnerInfoLoading ||
      isApplicationLoading,
  );

  useEffect(() => {
    if (!userInfo || isLoading) {
      return;
    }

    if (!window.fcWidget) {
      return;
    }
    if (supportConfigResponse?.supportConfig.freshdeskConfig?.enabled === true) {
      const token = supportConfigResponse?.supportConfig.freshdeskConfig?.credentials?.webCredentials?.appKey;
      const host = `https://${supportConfigResponse?.supportConfig.freshdeskConfig?.credentials?.webCredentials?.domain}`;
      const siteId =
        supportConfigResponse?.supportConfig.freshdeskConfig?.credentials?.webCredentials?.appId || undefined;
      window.fcWidget.init({
        token,
        host,
        siteId,
        config: {
          headerProperty: {
            hideChatButton: true,
            backgroundColor: themePrimary,
          },
          cssNames: {
            open: 'custom_fc_open',
            expanded: 'custom_fc_expanded',
          },
        },
      });
    } else {
      return;
    }

    // To set unique user id in your system when it is available
    const userId = userInfo.userOrgId?.userId || '';
    window.fcWidget.setExternalId(userId);

    const roles = userBasicInfo?.existingUser?.roleInfos ?? [];

    // https://developers.freshchat.com/web-sdk/#user-properties
    const freshChatWidgetProperties: Record<string, unknown> = {
      firstName: userInfo.user?.name?.given ?? '',
      lastName: userInfo.user?.name?.family1 ?? '',
      email: userInfo.userBusinessInfo?.email ?? '',
      tier: getUserTierForDisplay(userInfo.tier ?? 0),
      role: roles[0]?.type ? roleTypeToRoleNameMap[roles[0].type] : '',
    };

    const partner = partnerInfo?.name;

    if (appName) {
      freshChatWidgetProperties.partner = appName;
    } else if (partner && partnerInfo.companyRoles?.includes(CompanyRole.PartnerTmc)) {
      freshChatWidgetProperties.partner = partner;
    } else {
      freshChatWidgetProperties.partner = DEFAULT_SPOTNANA_PARTNER_NAME;
    }

    window.fcWidget.user.setProperties(freshChatWidgetProperties);

    window.fcWidget.user.baseProperties = freshChatWidgetProperties;

    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, appName, userInfo, partnerInfo, userInfo, themePrimary]);

  return <></>;
}

export default InitializeFreshchat;
