import { Icon } from '@spotnana/blocks/src/Icon/Icon';
import isNull from 'lodash/isNull';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography/Typography';
import { useWeightTranslator } from 'obt-common/hooks/common/useWeightTranslator';
import {
  CARBON_EMISSION_DELTA_THRESHOLD,
  ICarbonEmissionsConfig,
  OBTFeatureConfigObtFeatureTypeEnum,
} from 'obt-common';
import { IOBTShopFeaturesConfig } from 'obt-common/hooks/useOBTShopFeatures';
import { getFeatureVisibility } from 'obt-common/hooks/Flights/useGetAirSearchResultsFeatures';
import { TotalCo2EmissionDeltaInPercentage } from 'src/app/flights/shared/CarbonEmissions/TotalCo2EmissionDeltaInPercentage';
import {
  carbon_emission_icon,
  emission_container,
  icon_error,
  icon_secondary,
  icon_success,
  label_container,
} from './styles';

interface IProps {
  totalCo2Emission: ICarbonEmissionsConfig;
  airSearchResultsFeatures: IOBTShopFeaturesConfig;
}

const Co2EmissionLabel = ({ totalCo2Emission, airSearchResultsFeatures }: IProps) => {
  const { t: tt } = useTranslation('WEB');
  const weight = useWeightTranslator();
  const { totalCo2EmissionDeltaInPercentage } = totalCo2Emission;
  const isNegativeTotalCo2Delta =
    !!totalCo2EmissionDeltaInPercentage && totalCo2EmissionDeltaInPercentage < -CARBON_EMISSION_DELTA_THRESHOLD;
  const isPositiveTotalCo2Delta =
    !!totalCo2EmissionDeltaInPercentage && totalCo2EmissionDeltaInPercentage > CARBON_EMISSION_DELTA_THRESHOLD;

  let iconColor = icon_secondary;
  if (isNegativeTotalCo2Delta) {
    iconColor = icon_success;
  } else if (isPositiveTotalCo2Delta) {
    iconColor = icon_error;
  }

  const co2EmissionNotSupported = getFeatureVisibility(
    airSearchResultsFeatures,
    OBTFeatureConfigObtFeatureTypeEnum.Co2EmissionNotSupported,
  );

  if (co2EmissionNotSupported) {
    return null;
  }

  const showPercentageValue = getFeatureVisibility(
    airSearchResultsFeatures,
    OBTFeatureConfigObtFeatureTypeEnum.Co2EmissionPercentage,
  );

  const renderEmissionsContent = () => {
    if (showPercentageValue) {
      return !isNull(totalCo2EmissionDeltaInPercentage) ? (
        <>
          <Icon name="Co2Emissions" css={[carbon_emission_icon, iconColor]} />
          <TotalCo2EmissionDeltaInPercentage
            totalCo2EmissionDeltaInPercentage={totalCo2EmissionDeltaInPercentage}
            showDeltaIcon={false}
            textVariant="body2"
          />
        </>
      ) : null;
    }

    return (
      <Trans
        t={tt}
        values={{
          totalEmissions: Math.round(totalCo2Emission.totalEmissions.weight),
          weightUnitString: weight(totalCo2Emission.totalEmissions.unit),
        }}
      >
        <Icon name="Co2Emissions" css={[carbon_emission_icon, iconColor]} />
        <Typography variant="body2" kind="medium" color={isNegativeTotalCo2Delta ? 'success' : 'secondary'}>
          From {'{{totalEmissions}}'} {'{{weightUnitString}}'} CO<sub>2</sub>
        </Typography>
      </Trans>
    );
  };

  return (
    <div css={label_container}>
      <div css={emission_container} data-testId="co2-emissions-container">
        {renderEmissionsContent()}
      </div>
    </div>
  );
};

export { Co2EmissionLabel };
