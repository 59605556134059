import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Grid from '@spotnana/pixel-react/dist/Grid';
import { globalTranslationKeys, useFeatureFlag, usePartnerFeatures } from 'obt-common';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Icon } from '@spotnana/blocks/src/Icon';
import usePartnerFlags from '../../hooks/usePartnerFlags';
import ProgramSection from './TabSection';
import { TabPopover, POPOVER_WIDTH } from './TabPopover';

const programUrlsMap: Record<string, string> = {
  company: '/admin/company',
  tmc: '/admin/tmc',
  users: '/admin/users',
  policy: '/admin/policies',
  configs: '/admin/configurations',
};

const { SETTINGS, COMPANY, TMC_SETTING, USERS, POLICIES } = globalTranslationKeys;

const settingsUrl = [programUrlsMap.company, programUrlsMap.users];

interface IProgramOptions {
  onClose?: VoidFunction;
  isTMC: boolean;
  isProgramUsersEnabled: boolean;
}

const ProgramOptions = ({ onClose, isTMC, isProgramUsersEnabled }: IProgramOptions): JSX.Element => {
  const { t } = useTranslation();
  const { t: tt } = useTranslation('WEB');
  const { pathname } = useLocation();
  const { features } = usePartnerFeatures();
  const isConfigurationsEnabled = useFeatureFlag('FE_TMC_API_CONFIGURATIONS');
  const { menuItemPolicy, hideCompanySettingMenu, hideUserSettingMenu } = features;
  const menuGroups = [
    {
      name: 'settings',
      children: [
        {
          title: t(SETTINGS),
          urls: settingsUrl,
          isInBeta: false,
          options: [
            {
              getIcon: (color: string): React.ReactNode => (
                <Icon name="Company" color={color} height="24px" width="24px" />
              ),
              content: COMPANY,
              path: programUrlsMap.company,
              enableFeature: !hideCompanySettingMenu,
            },
            {
              getIcon: (color: string): React.ReactNode => (
                <Icon name="OfficeBuilding" color={color} height="24px" width="24px" />
              ),
              content: TMC_SETTING,
              path: programUrlsMap.tmc,
              enableFeature: isTMC,
            },
            {
              getIcon: (color: string): React.ReactNode => (
                <Icon name="UsersTravelers" color={color} height="24px" width="24px" />
              ),
              content: USERS,
              path: programUrlsMap.users,
              enableFeature: isProgramUsersEnabled && !hideUserSettingMenu,
            },
            {
              getIcon: (color: string): React.ReactNode => (
                <Icon name="PolicyDocument" color={color} height="24px" width="24px" />
              ),
              content: POLICIES,
              path: programUrlsMap.policy,
              enableFeature: menuItemPolicy,
            },
            {
              getIcon: (color: string): React.ReactNode => (
                <Icon name="Settings" color={color} height="24px" width="24px" />
              ),
              content: tt('Configurations'),
              path: programUrlsMap.configs,
              enableFeature: isConfigurationsEnabled,
            },
          ],
        },
      ],
    },
  ];

  const availableMenuGroups = menuGroups.filter((group) =>
    group.children.some((menu) => menu.options.some((opt) => opt.enableFeature)),
  );

  return (
    <Box mx="-8px" width={POPOVER_WIDTH} data-testid="tooltip-program_content">
      <Grid.Row spacing={0}>
        {availableMenuGroups.map((group, groupIndex) => (
          <Grid.Col key={group.name} xs={12 / availableMenuGroups.length}>
            <Box position="relative" pb={24} height="full">
              {group.children.map((menuSection) => (
                <Flex key={menuSection.title}>
                  <ProgramSection
                    title={menuSection.title}
                    isActive={menuSection.urls.some((url) => pathname.includes(url))}
                    options={menuSection.options.filter((option) => option.enableFeature)}
                    isInBeta={menuSection.isInBeta}
                    onClick={onClose}
                  />
                </Flex>
              ))}
              {groupIndex !== availableMenuGroups.length - 1 && (
                <Box position="absolute" width="1px" bg="bg.lightBlueGrey" top="24px" right="0" bottom="24px" />
              )}
            </Box>
          </Grid.Col>
        ))}
      </Grid.Row>
    </Box>
  );
};

interface IProgramNavigation {
  isTMC?: boolean;
}

export const ProgramTab: React.FC<IProgramNavigation> = ({ isTMC = false }: IProgramNavigation) => {
  const { t: tt } = useTranslation('WEB');
  const { pathname } = useLocation();
  const { isProgramUsersDisabled } = usePartnerFlags();
  const isProgramTabActive = Object.values(programUrlsMap).some((url) => pathname.includes(url));

  return (
    <TabPopover active={isProgramTabActive} label={tt('Program')}>
      <ProgramOptions isTMC={isTMC} isProgramUsersEnabled={!isProgramUsersDisabled} />
    </TabPopover>
  );
};
