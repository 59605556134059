// Function to retrieve the favicon URL based on the provided link
export const getFavIconUrl = (url: string): string => {
  let favIconUrl = '';
  try {
    // Parse the URL to extract the root domain for the favicon
    const parsedUrl = new URL(url);
    const domainParts = parsedUrl.hostname.split('.');
    const rootDomain = domainParts.slice(-2).join('.');
    // Construct the favicon URL using the root domain
    favIconUrl = `http://www.google.com/s2/favicons?sz=32&domain=${rootDomain}`;
  } catch (e) {
    // Log any errors encountered while parsing the URL
    console.error(e);
  }
  // Return the constructed favicon URL
  return favIconUrl;
};
