/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Properly formatted phone number.
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
  /**
   * two digit country code
   * @type {number}
   * @memberof PhoneNumber
   */
  countryCode?: number;
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  countryCodeSource?: PhoneNumberCountryCodeSourceEnum;
  /**
   * phone number extension
   * @type {string}
   * @memberof PhoneNumber
   */
  extension?: string;
  /**
   * ISO alpha-3 code
   * @type {string}
   * @memberof PhoneNumber
   */
  isoCountryCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof PhoneNumber
   */
  italianLeadingZero?: boolean;
  /**
   *
   * @type {number}
   * @memberof PhoneNumber
   */
  nationalNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PhoneNumber
   */
  numberOfLeadingZeros?: number;
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  preferredDomesticCarrierCode?: string;
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  rawInput?: string;
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  type?: PhoneNumberTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PhoneNumberCountryCodeSourceEnum {
  Unspecified = 'UNSPECIFIED',
  FromNumberWithPlusSign = 'FROM_NUMBER_WITH_PLUS_SIGN',
  FromNumberWithIdd = 'FROM_NUMBER_WITH_IDD',
  FromNumberWithoutPlusSign = 'FROM_NUMBER_WITHOUT_PLUS_SIGN',
  FromDefaultCountry = 'FROM_DEFAULT_COUNTRY',
}
/**
 * @export
 * @enum {string}
 */
export enum PhoneNumberTypeEnum {
  UnknownType = 'UNKNOWN_TYPE',
  Mobile = 'MOBILE',
  Landline = 'LANDLINE',
}
