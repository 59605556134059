/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Entity type against which the document is to uploaded.
 * @export
 * @enum {string}
 */

export const EntityType = {
  Trip: 'TRIP',
  Pnr: 'PNR',
  Itinerary: 'AIR_ITINERARY',
  Event: 'EVENT',
  Company: 'COMPANY',
} as const;

export type EntityType = (typeof EntityType)[keyof typeof EntityType];
