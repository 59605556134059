import { useFeatureFlag } from 'obt-common';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { ROUTE_PATHS } from 'src/routes';
import AnnouncementsVertical from '../Spotternet/Components/Announcements/AnnouncementsVertical';
import NotesLinks from '../Spotternet/NotesLinks';
import { panel_container, right_panel } from './index.styles';

// Define supported views for the right panel
export const SupportedViews = {
  ANNOUNCEMENT: 'announcement',
  NOTES_LINKS: 'notesLinks',
};

// Function to get the page content based on the selected type
export const getPageContent = (type: string): JSX.Element | null => {
  switch (type) {
    case SupportedViews.ANNOUNCEMENT:
      return <AnnouncementsVertical />;
    case SupportedViews.NOTES_LINKS:
      return <NotesLinks />;
    default:
      return null;
  }
};

const RightPanel = () => {
  // Get the current location using react-router's useLocation hook
  const location = useLocation();

  // Extract the selected right panel component from the query parameter
  const searchParams = new URLSearchParams(location.search);
  const selectedPanelComponent = searchParams.has('selectedRightPanel') ? searchParams.get('selectedRightPanel') : null;

  // Check if the right panel is expanded
  const expanded = !!selectedPanelComponent;

  // Check if the feature flag for Spotternet is enabled
  const spotternetEnabled = useFeatureFlag('FE_SPOTTERNET');

  // Initialize an array to store supported panels
  const supportedPanels = [];

  // If Spotternet is enabled, add supported views to the array
  if (spotternetEnabled && location.pathname.startsWith(ROUTE_PATHS.SPOTTERNET)) {
    supportedPanels.push(SupportedViews.ANNOUNCEMENT, SupportedViews.NOTES_LINKS);
  }

  // Check if the selected panel component is one of the supported views
  const isRightPanelVisible = expanded && supportedPanels.includes(selectedPanelComponent);

  // Use useEffect to adjust the body overflow based on the visibility of the right panel
  useEffect(() => {
    document.body.style.overflow = isRightPanelVisible ? 'hidden' : 'auto';
  }, [isRightPanelVisible]);

  // If the right panel is not visible, return null
  if (!isRightPanelVisible) {
    return null;
  }

  // Render the right panel with the selected content
  return (
    <div data-testid="right-panel-wrapper" css={panel_container}>
      <div css={right_panel}>{getPageContent(selectedPanelComponent)}</div>
    </div>
  );
};

export default RightPanel;
