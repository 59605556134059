import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const User = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 13 13" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "evenodd", d: "M7.44 5.409c.96 0 1.753.736 1.842 1.674l-.01-.002c.008.06.01.12.01.18v3.628c0 .613-.498 1.111-1.11 1.111H6.025l-.07-.003.002.003H3.811c-.613 0-1.111-.498-1.111-1.11V7.26c0-.058.003-.116.008-.173-.002-.005-.005-.005-.008-.004.09-.938.882-1.674 1.843-1.674zM5.996 0c1.349 0 2.446 1.098 2.446 2.447 0 1.35-1.097 2.447-2.446 2.447-1.35 0-2.447-1.098-2.447-2.447C3.549 1.097 4.646 0 5.996 0z" })));
};
User.defaultProps = defaultIconProps;
export default User;
