import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Aircraft = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M12.038 9.5l-.07.002a1.146 1.146 0 00-.985 1.596l2.19 5.039H8.745l-1.971-1.963a1.353 1.353 0 00-.961-.404h-1.43a1.39 1.39 0 00-1.347 1.705l.922 3.72A4.338 4.338 0 008.16 22.5h18.497c1.566-.04 2.665-1.496 2.257-2.976l-.036-.122a4.64 4.64 0 00-4.415-3.265H20.89l-4.896-5.987c-.361-.417-.824-.65-1.353-.65h-2.602zm3.237 7.155l-2.356-5.422h1.721c.008 0 .01.001.042.039l5.138 6.283c.163.2.406.315.663.315h3.98c1.297 0 2.442.872 2.797 2.124.103.376-.188.762-.625.773H8.16l-.106-.002a2.616 2.616 0 01-2.428-1.991l-.811-3.271h.847l1.945 1.937.067.06c.286.23.618.37.996.37h5.817a.867.867 0 00.788-1.215z", fill: themeEnrichedPrimaryColor })));
};
Aircraft.defaultProps = defaultIconProps;
export default Aircraft;
