import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';

export const container = css`
  ${flex.init}
  ${flex.align.center}
`;

export const caret_up = themed(
  ({ palette }) => css`
    height: 1rem;
    width: 1rem;
    color: ${palette.icon.error};
  `,
);

export const caret_down = themed(
  ({ palette }) => css`
    height: 1rem;
    width: 1rem;
    color: ${palette.icon.success};
  `,
);
