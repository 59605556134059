import type { RateOption } from '../types/api/v1/obt/air/air_search_response';

export const mergeRateOptionRight = (rateOption: RateOption, routeHappyRateOption: RateOption) => {
  // eslint-disable-next-line no-param-reassign
  rateOption.paxInfo[0].fareComponents[0].legInfo = rateOption.paxInfo[0].fareComponents[0].legInfo.map(
    (legData, legIndex) => {
      const updatedLegData = { ...legData };
      updatedLegData.utaIndices =
        routeHappyRateOption.paxInfo[0]?.fareComponents[0]?.legInfo[legIndex]?.utaIndices ?? [];
      updatedLegData.flightInfo = updatedLegData.flightInfo.map((flightData, flightIndex) => {
        const updatedFlightData = { ...flightData };
        updatedFlightData.amenitiesIndices =
          routeHappyRateOption.paxInfo[0]?.fareComponents[0]?.legInfo[legIndex]?.flightInfo[
            flightIndex
          ]?.amenitiesIndices;
        updatedFlightData.upaIndices =
          routeHappyRateOption.paxInfo[0]?.fareComponents[0]?.legInfo[legIndex]?.flightInfo[flightIndex]?.upaIndices;
        return updatedFlightData;
      });
      return updatedLegData;
    },
  );
};
