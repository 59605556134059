import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Email = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 19 15", fill: themeEnrichedPrimaryColor, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.5594 1.0369H2.30079C1.39355 1.0369 0.655553 1.7749 0.655553 2.68214V12.5535C0.655553 13.4607 1.39355 14.1987 2.30079 14.1987H16.5594C17.4666 14.1987 18.2046 13.4607 18.2046 12.5535V2.68214C18.2046 1.7749 17.4666 1.0369 16.5594 1.0369ZM16.5594 2.13371C16.6339 2.13371 16.7048 2.14917 16.7696 2.17617L9.4301 8.53737L2.09056 2.17617C2.15536 2.14921 2.22624 2.13371 2.30075 2.13371H16.5594ZM16.5594 13.1019H2.30079C1.99821 13.1019 1.75237 12.8561 1.75237 12.5535V3.33497L9.07073 9.67755C9.17411 9.76697 9.3021 9.81145 9.4301 9.81145C9.55809 9.81145 9.68608 9.76701 9.78946 9.67755L17.1078 3.33497V12.5535C17.1078 12.8561 16.862 13.1019 16.5594 13.1019Z", fill: "#687591" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.381348 2.68214C0.381348 1.62346 1.24211 0.762695 2.30079 0.762695H16.5594C17.6181 0.762695 18.4788 1.62346 18.4788 2.68214V12.5535C18.4788 13.6122 17.6181 14.4729 16.5594 14.4729H2.30079C1.24211 14.4729 0.381348 13.6122 0.381348 12.5535V2.68214ZM2.30079 1.31111C1.54499 1.31111 0.929757 1.92634 0.929757 2.68214V12.5535C0.929757 13.3093 1.54499 13.9245 2.30079 13.9245H16.5594C17.3152 13.9245 17.9304 13.3093 17.9304 12.5535V2.68214C17.9304 1.92634 17.3152 1.31111 16.5594 1.31111H2.30079ZM2.77662 2.40792L9.4301 8.17451L16.0835 2.40792H2.77662ZM1.98523 1.923C2.08124 1.88306 2.18782 1.85951 2.30075 1.85951H16.5594C16.6723 1.85951 16.7789 1.88298 16.8751 1.92305L17.2838 2.09335L9.4301 8.90023L1.57616 2.0932L1.98523 1.923ZM1.47816 2.73447L9.25012 9.47017C9.25015 9.47019 9.25009 9.47014 9.25012 9.47017C9.30198 9.51499 9.36577 9.53724 9.4301 9.53724C9.49451 9.53724 9.55821 9.51505 9.61003 9.4702L17.382 2.73447V12.5535C17.382 13.0075 17.0134 13.3761 16.5594 13.3761H2.30079C1.84678 13.3761 1.47816 13.0075 1.47816 12.5535V2.73447ZM2.02657 3.93547V12.5535C2.02657 12.7046 2.14963 12.8277 2.30079 12.8277H16.5594C16.7105 12.8277 16.8336 12.7047 16.8336 12.5535C16.8336 12.5535 16.8336 12.5535 16.8336 12.5535V3.93547L9.96905 9.88477C9.81411 10.0188 9.62167 10.0857 9.4301 10.0857C9.23847 10.0857 9.04623 10.0189 8.89135 9.88494L2.02657 3.93547Z", fill: "#687591" })));
};
Email.defaultProps = defaultIconProps;
export default Email;
