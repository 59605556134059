import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Department = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.942 5a7.595 7.595 0 00-6.008 12.238 10.524 10.524 0 00-5.935 9.472 2 2 0 104 0 6.523 6.523 0 016.524-6.523H15.251l.288.007c1.82.079 3.496.908 4.668 2.275.246.287.555.486.889.597a9.234 9.234 0 002.371 5.214c-4.743 1.962-8.08 6.636-8.08 12.088a2 2 0 004 0 9.075 9.075 0 019.075-9.076H31.924l.268.004A9.076 9.076 0 0141 40.368a2 2 0 004 0c0-5.39-3.26-10.018-7.917-12.019a9.269 9.269 0 00-6.775-15.594 9.271 9.271 0 00-8.55 5.683c-.367-.288-.75-.55-1.15-.787A7.594 7.594 0 0014.942 5zm.038 11.187a3.594 3.594 0 10-.076 0h.076zm20.596 5.837a5.269 5.269 0 01-5.213 5.268h-.113a5.268 5.268 0 01.057-10.537 5.27 5.27 0 015.269 5.269z", fill: themeEnrichedPrimaryColor })));
};
Department.defaultProps = defaultIconProps;
export default Department;
