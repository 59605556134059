// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as React from 'react';
import type { PropsWithChildren, FC } from 'react';
import { createContext, useContext, useMemo, useCallback } from 'react';
import type { ITraveler, IUserOrgId } from '../types/traveler';

import type { SpotnanaQueryResult } from '../types/common';
import { StorageKeys } from '../types/storage';
import { useLoggedInUserId } from './AuthProvider';
import { invalidateReadProfile, useProfileReadQuery } from '../queries/profile';
import useStorage from '../hooks/useStorage';
import { removeEmptyValuesFromObject } from '../utils';
import useCallbackWithoutDeps from '../hooks/useCallbackWithoutDeps';

interface IPrimaryTravelerContext {
  primaryTravelerId: IUserOrgId;
  setPrimaryTravelerId: (userOrgId: IUserOrgId | null) => void;
  invalidateAndRefetchPrimaryTravelerData?: () => void;
}
interface ISecondaryTravelerContext {
  secondaryTravelerIds: IUserOrgId[];
  addSecondaryTravelerId: (userOrgId: IUserOrgId) => void;
  removeSecondaryTravelerId: (userOrgId: IUserOrgId) => void;
}

type ITravelerContext = IPrimaryTravelerContext & ISecondaryTravelerContext;

const TravelerContext = createContext<ITravelerContext | null>(null);

export const { Consumer: TravelerContextConsumer } = TravelerContext;

// This provider makes sure that it always returns a travelerId to it's children.
// It returns loggedInUserId if travelerId is not available from storage. Hence
// it expects that it will always get loggedInUserId from AuthProvider. It relies
// on imperative global logout method to clear travelerId from storage. It
// doesn't listen on loggedInUserId going null, to clear it from here.

export const TravelerProvider: FC<
  PropsWithChildren<{
    registrarUserOrgId?: IUserOrgId;
    isFetchingRegistrarId?: boolean;
  }>
> = ({ registrarUserOrgId, isFetchingRegistrarId, children }) => {
  const loggedInUserId = useLoggedInUserId();

  const {
    data: primaryTravelerIdInLocalStorage,
    setData: setPrimaryTravelerId,
    isFetching: isFetchingPrimaryTravelerIds,
  } = useStorage<IUserOrgId>(StorageKeys.PRIMARY_TRAVELER_ID);

  const {
    data: secondaryTravelerIdsInLocalStorage,
    setData: setSecondaryTravelerIds,
    isFetching: isFetchingSecondaryTravelerIds,
  } = useStorage<IUserOrgId[]>(StorageKeys.SECONDARY_TRAVELER_IDS, []);

  const changePrimaryTravelerId = useCallbackWithoutDeps((userOrgId: IUserOrgId | null): void => {
    setPrimaryTravelerId(removeEmptyValuesFromObject(userOrgId));
    setSecondaryTravelerIds([]);
  });

  const addSecondaryTravelerId = useCallback(
    (userOrgId: IUserOrgId): void => {
      const storedList = secondaryTravelerIdsInLocalStorage || [];
      const updatedList = [...storedList, userOrgId];
      setSecondaryTravelerIds(updatedList);
    },
    [secondaryTravelerIdsInLocalStorage, setSecondaryTravelerIds],
  );

  const removeSecondaryTravelerId = useCallback(
    (userOrgId: IUserOrgId): void => {
      const newStoredList = (secondaryTravelerIdsInLocalStorage || []).filter(
        (storedUserId) => userOrgId.userId?.id !== storedUserId.userId?.id,
      );
      setSecondaryTravelerIds(newStoredList);
    },
    [secondaryTravelerIdsInLocalStorage, setSecondaryTravelerIds],
  );

  const values = useMemo(
    () => ({
      primaryTravelerId: registrarUserOrgId || primaryTravelerIdInLocalStorage || loggedInUserId,
      setPrimaryTravelerId: changePrimaryTravelerId,
      secondaryTravelerIds: secondaryTravelerIdsInLocalStorage || [],
      addSecondaryTravelerId,
      removeSecondaryTravelerId,
    }),
    [
      registrarUserOrgId,
      primaryTravelerIdInLocalStorage,
      loggedInUserId,
      changePrimaryTravelerId,
      secondaryTravelerIdsInLocalStorage,
      addSecondaryTravelerId,
      removeSecondaryTravelerId,
    ],
  );

  if (isFetchingPrimaryTravelerIds || isFetchingSecondaryTravelerIds || isFetchingRegistrarId) {
    return null;
  }

  return <TravelerContext.Provider value={values}>{children}</TravelerContext.Provider>;
};

TravelerProvider.displayName = 'TravelerProvider';

// This hook guarantees to return travelerId always. Because of this, it should
// only be used in logged-in flow. Outside of login flow, the context itself
// won't be initialized, so that it throws error during development.
function useTravelerContext(): ITravelerContext {
  const context = useContext(TravelerContext);
  if (!context) {
    throw new Error('`useTraveler` hook must be used within a `TravelerProvider` component');
  }
  return context;
}

export function usePrimaryTravelerId(): IPrimaryTravelerContext {
  const context = useTravelerContext();
  const { primaryTravelerId, setPrimaryTravelerId } = context;
  return { primaryTravelerId, setPrimaryTravelerId };
}

// Helper hook which wraps useProfileReadQuery and gives all user info.
export function usePrimaryTraveler(): SpotnanaQueryResult<ITraveler> & IPrimaryTravelerContext {
  const context = usePrimaryTravelerId();
  const { primaryTravelerId } = context;

  const profileInfo = useProfileReadQuery(primaryTravelerId);
  const invalidateAndRefetchPrimaryTravelerData = () => {
    invalidateReadProfile(primaryTravelerId);
  };

  return { ...profileInfo, ...context, invalidateAndRefetchPrimaryTravelerData };
}

export function useSecondaryTravelerIds(): ISecondaryTravelerContext {
  const context = useTravelerContext();
  const { secondaryTravelerIds, addSecondaryTravelerId, removeSecondaryTravelerId } = context;
  return { secondaryTravelerIds, addSecondaryTravelerId, removeSecondaryTravelerId };
}

export function useTravelerIds(): IUserOrgId[] {
  const { primaryTravelerId } = usePrimaryTravelerId();

  const { secondaryTravelerIds } = useSecondaryTravelerIds();

  const travelersUserIds = useMemo<IUserOrgId[]>(
    () => [primaryTravelerId, ...secondaryTravelerIds],
    [primaryTravelerId, secondaryTravelerIds],
  );

  return travelersUserIds;
}
