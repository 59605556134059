import type { IAirSpecialServiceRequestsResponse, ITravelPreferences, SSROption } from '../../types';
import { MealPrefInclMealPrefsEnum, SpecialServiceRequestCategoryEnum } from '../../types';

export const NO_PREFERENCE_OPTION: SSROption = {
  value: MealPrefInclMealPrefsEnum.UnknownMeal,
  code: '',
  label: 'No preference',
};

export function getPreferredMealFromPreferences(
  mealOptions: SSROption[],
  preferences?: ITravelPreferences,
): SSROption | null | undefined {
  const preferredMeals: string[] | undefined = preferences?.preferences?.airPref?.mealPref?.inclMealPrefs;
  if (!preferredMeals) {
    return NO_PREFERENCE_OPTION;
  }
  return mealOptions.find((option) => preferredMeals.includes(option.code));
}

export function convertToOptions(
  ssrs: NonNullable<IAirSpecialServiceRequestsResponse['specialServiceRequests']>,
): SSROption[] {
  const options = ssrs
    .filter((ssr) => ssr.category.type === SpecialServiceRequestCategoryEnum.Meal)
    .map((ssr) => ({
      value: ssr.code,
      code: ssr.code,
      label: ssr.subCategory,
      hasEditableTextField: ssr.isFreeTextAllowed,
      isEditableTextFieldRequired: ssr.isFreeTextMandatory,
    }));
  return [NO_PREFERENCE_OPTION, ...options];
}
