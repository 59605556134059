import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Location = ({ primaryColor, secondaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    const themeEnrichedSecondaryColor = get(theme, `colors.${secondaryColor}`, secondaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { "data-name": "Exclusion 1", d: "M16 32a1.963 1.963 0 01-1.407-.6C11.316 28.078 3.708 19.577 3.708 12.465a12.472 12.472 0 013.6-8.814 12.173 12.173 0 0117.384 0 12.472 12.472 0 013.6 8.814c0 7.112-7.612 15.613-10.885 18.938a1.966 1.966 0 01-1.407.6zm0-24.456a5.464 5.464 0 105.378 5.463A5.464 5.464 0 0016 7.544z", fill: themeEnrichedPrimaryColor }),
        React.createElement("circle", { cx: 6, cy: 6, r: 6, transform: "translate(10 7)", fill: themeEnrichedSecondaryColor !== null && themeEnrichedSecondaryColor !== void 0 ? themeEnrichedSecondaryColor : 'none' })));
};
Location.defaultProps = defaultIconProps;
export default Location;
