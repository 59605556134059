import { SerializedStyles, css } from '@emotion/react';
import { themed } from '../utils/styling';
import { blocksPalette } from '../BlocksThemeProvider/ui-specs/blocksPalette';
import { BlocksTheme } from '../types';

const makeColorStyles = (palette: BlocksTheme['palette']) =>
  Object.keys(blocksPalette.light.text).reduce((styles, currentColorName) => {
    const name = currentColorName as keyof typeof blocksPalette.light.text;
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
    (styles as any)[`&.BlocksTypography.color-${currentColorName}`] = { color: palette.text[name] };
    return styles;
  }, {} as SerializedStyles);

// TODO: Remove typecastings in below css
export const typography_css = themed(
  ({ typography, palette }) => css`
    margin: 0;
    padding: 0;

    &.BlocksTypography-underline {
      ${css(typography.underline)}
    }

    ${makeColorStyles(palette)}

    &.BlocksTypography-display {
      ${css(typography.display.default)}

      &.MetaVariant-bold {
        ${css(typography.display.bold)}
      }
    }

    &.BlocksTypography-header1 {
      ${css(typography.header1.default)}

      &.MetaVariant-bold {
        ${css(typography.header1.bold)}
      }
    }

    &.BlocksTypography-header2 {
      ${css(typography.header2.default)}

      &.MetaVariant-regular {
        ${css(typography.header2.regular)}
      }
      &.MetaVariant-bold {
        ${css(typography.header2.bold)}
      }
    }

    &.BlocksTypography-header3 {
      ${css(typography.header3.default)}

      &.MetaVariant-regular {
        ${css(typography.header3.regular)}
      }
      &.MetaVariant-medium {
        ${css(typography.header3.medium)}
      }
      &.MetaVariant-bold {
        ${css(typography.header3.bold)}
      }
    }

    &.BlocksTypography-header4 {
      ${css(typography.header4.default)}

      &.MetaVariant-regular {
        ${css(typography.header4.regular)}
      }
      &.MetaVariant-medium {
        ${css(typography.header4.medium)}
      }
      &.MetaVariant-strikeout {
        ${css(typography.header4.strikeout as unknown as TemplateStringsArray)}
      }
      &.MetaVariant-bold {
        ${css(typography.header4.bold)}
      }
    }

    &.BlocksTypography-header5 {
      ${css(typography.header5.default)}

      &.MetaVariant-regular {
        ${css(typography.header5.regular)}
      }
      &.MetaVariant-medium {
        ${css(typography.header5.medium)}
      }
      &.MetaVariant-strikeout {
        ${css(typography.header5.strikeout as unknown as TemplateStringsArray)}
      }
      &.MetaVariant-bold {
        ${css(typography.header5.bold)}
      }
    }

    &.BlocksTypography-body1 {
      ${css(typography.body1.default)}
      /**
      * TODO: Add isParagraph prop to typography component and then
      * re-renable these styles. The assumption that every p tag needs
      * to have paragraph spacing was not correct.
      */
      /* &:is(p), */
    /* &.is-para { */
        /* margin-bottom: ${typography.body1.paraSpacing}; */
      /* } */
      &:is(li),
      &.is-list-item {
        margin-bottom: ${typography.body1.listSpacing};
      }

      &.MetaVariant-medium {
        ${css(typography.body1.medium)}
      }
      &.MetaVariant-semibold {
        ${css(typography.body1.semibold)}
      }
      &.MetaVariant-strikeout {
        ${css(typography.body1.strikeout as unknown as TemplateStringsArray)}
      }
      &.MetaVariant-bold {
        ${css(typography.body1.bold)}
      }
    }

    &.BlocksTypography-body2 {
      ${css(typography.body2.default)}

      /* &:is(p), */
      /* &.is-para { */
        /* margin-bottom: ${typography.body2.paraSpacing}; */
      /* } */
      &:is(li),
      /* provision of giving class identifier in case using a list item without li tag */
      &.is-list-item {
        margin-bottom: ${typography.body2.listSpacing};
      }

      &.MetaVariant-medium {
        ${css(typography.body2.medium)}
      }
      &.MetaVariant-semibold {
        ${css(typography.body2.semibold)}
      }
      &.MetaVariant-strikeout {
        ${css(typography.body2.strikeout as unknown as TemplateStringsArray)}
      }
      &.MetaVariant-bold {
        ${css(typography.body2.bold)}
      }
    }

    &.BlocksTypography-body3 {
      ${css(typography.body3.default)}

      /* &:is(p), */
    /* &.is-para { */
        /* margin-bottom: ${typography.body3.paraSpacing}; */
      /* } */
      &:is(li),
      &.is-list-item {
        margin-bottom: ${typography.body3.listSpacing};
      }

      &.MetaVariant-medium {
        ${css(typography.body3.medium)}
      }
      &.MetaVariant-semibold {
        ${css(typography.body3.semibold)}
      }
      &.MetaVariant-strikeout {
        ${css(typography.body3.strikeout as unknown as TemplateStringsArray)}
      }
      &.MetaVariant-bold {
        ${css(typography.body3.bold)}
      }
    }
  `,
);
