import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { useStorage, StorageKeys, IUserOrgId } from 'obt-common';

const NotFound: React.FC = () => {
  const { isFetching: isFetchingPrimaryTravelerId } = useStorage<IUserOrgId>(StorageKeys.PRIMARY_TRAVELER_ID);
  const { isFetching: isFetchingLoggedInUserId } = useStorage<IUserOrgId>(StorageKeys.LOGGED_IN_USER_ID);

  if (isFetchingPrimaryTravelerId || isFetchingLoggedInUserId) {
    return <></>;
  }

  return <Redirect to="/" />;
};

export default NotFound;
