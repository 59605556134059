import useAutocomplete, {
  AutocompleteHighlightChangeReason as HighlightChangeReason,
  UseAutocompleteProps,
} from '@material-ui/core/useAutocomplete';
import isEqual from 'lodash/isEqual';
import { CSSProperties, ReactChild, RefObject, useLayoutEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@spotnana/pixel-react/dist/Box';
import ExpansionPanel from '@spotnana/pixel-react/dist/ExpansionPanel';
import Input, { InputProps } from '@spotnana/pixel-react/dist/Input';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { MAX_LENGTH_AUTOCOMPLETE } from 'obt-common';
import { Popover } from '@spotnana/blocks/src/Popover';
import { popover_container } from './Autocomplete.styles';

type LibHookProps<OptionType> = UseAutocompleteProps<OptionType, false, false, false>;

type UseAutocompleteInputProps = {
  id: string;
  value: string;
  onChange: () => {};
  onFocus: () => {};
  onBlur: () => {};
};

export interface AutocompleteProps<OptionType> {
  'data-testid': string;
  errorMessage?: string;
  getContainerBoxProps: (popupOpen: boolean) => Parameters<typeof Box>[0];
  getInputStylingProps: (popupOpen: boolean) => Omit<InputProps, keyof UseAutocompleteInputProps>;
  getPopoverProps: (popupOpen: boolean) => Partial<Parameters<typeof Popover>[0]>;
  outerContainerStyles?: CSSProperties;
  getOptionLabel: (option: OptionType) => string;
  groupBy?: (option: OptionType) => string;
  id: string;
  inputValue: string;
  loading: boolean;
  onInputChange: LibHookProps<OptionType>['onInputChange'];
  onSelection: (value: OptionType | null) => void;
  options: Array<OptionType>;
  renderLoadingState: () => JSX.Element;
  renderNoResultsState: () => JSX.Element;
  renderOption: ({
    option,
    isHighlighted,
    isCompanyTripsPage,
  }: {
    option: OptionType;
    isHighlighted: boolean;
    isCompanyTripsPage?: boolean;
  }) => JSX.Element;
  renderOptionGroupHeader?: ({ headerTitle }: { headerTitle: string }) => JSX.Element;
  value?: OptionType | null;
  active?: boolean;
  onActivate?: LibHookProps<OptionType>['onOpen'];
  onDeactivate?: LibHookProps<OptionType>['onClose'];
  renderInput?: <T extends InputProps>(props: T) => JSX.Element;
  containerRef?: RefObject<HTMLDivElement>;
  footer?: ReactChild;
  shouldStripBorderWhenActive?: boolean;
  listHeader?: JSX.Element;
  listFooter?: JSX.Element;
  isCompanyTripsPage?: boolean;
  inputContainerWidth?: string;
}

const Autocomplete = <OptionType extends {}>({
  'data-testid': dataTestId,
  errorMessage,
  getContainerBoxProps,
  getInputStylingProps,
  getPopoverProps,
  outerContainerStyles,
  getOptionLabel,
  groupBy,
  id,
  inputValue,
  loading,
  onInputChange,
  onSelection,
  options,
  renderLoadingState: LoadingComponent,
  renderNoResultsState: NoResultsComponent,
  renderOption,
  renderOptionGroupHeader,
  value,
  active,
  onActivate,
  onDeactivate,
  renderInput,
  containerRef,
  footer,
  shouldStripBorderWhenActive = true,
  listHeader,
  listFooter,
  isCompanyTripsPage = false,
  inputContainerWidth,
}: AutocompleteProps<OptionType>): JSX.Element => {
  const [highlightChangeReason, setHighlightChangeReason] = useState<HighlightChangeReason>('auto');
  const [highlightedOption, setHighlightedOption] = useState<OptionType>();

  const handleHighlightChange: LibHookProps<OptionType>['onHighlightChange'] = (_e, option, reason): void => {
    if (option) {
      setHighlightedOption(option);
      setHighlightChangeReason(reason);
    }
  };

  const handleChange: LibHookProps<OptionType>['onChange'] = (_event, updatedValue) => {
    onSelection(updatedValue);
  };

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, popupOpen, groupedOptions } = useAutocomplete<
    OptionType,
    false,
    false,
    false
  >({
    autoHighlight: true,
    blurOnSelect: true,
    clearOnBlur: false,
    filterOptions: (x) => x,
    getOptionLabel,
    getOptionSelected: isEqual,
    groupBy,
    id,
    inputValue,
    onChange: handleChange,
    onHighlightChange: handleHighlightChange,
    onInputChange,
    openOnFocus: true,
    options,
    selectOnFocus: true,
    value,
    open: active,
    onOpen: onActivate,
    onClose: onDeactivate,
  });

  const popperAnchorRef = useRef<HTMLDivElement>(null);
  const defaultContainerRef = useRef<HTMLDivElement>(null);
  const highlightedOptionRef = useRef<HTMLLIElement>(null);

  const popoverRef = containerRef ?? defaultContainerRef;
  const { t: tt } = useTranslation('WEB');

  useLayoutEffect(() => {
    if (highlightChangeReason === 'keyboard') {
      highlightedOptionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [highlightedOption, highlightChangeReason]);

  const onKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onKeyPress={onKeyPress} ref={popperAnchorRef} style={{ position: 'relative', ...outerContainerStyles }}>
      <Box
        position="absolute"
        top={popupOpen ? -2 : 0}
        width={
          inputContainerWidth || {
            xs: popupOpen ? 264 : '100%',
            sm: popupOpen ? 500 : '100%',
            md: popupOpen ? Math.max((popperAnchorRef.current?.clientWidth ?? 0) + 4, 400) : '100%',
          }
        }
        boxShadow={popupOpen ? 'overlay' : 'none'}
        borderRadius="sm"
        zIndex={popupOpen ? 2 : 'auto'}
        {...getContainerBoxProps(popupOpen)}
        {...getRootProps()}
        data-testid={dataTestId}
        ref={popoverRef}
      >
        {renderInput ? (
          renderInput({
            naked: shouldStripBorderWhenActive && popupOpen,
            error: !!errorMessage,
            ...getInputStylingProps(popupOpen),
            ...(getInputProps() as UseAutocompleteInputProps),
          })
        ) : (
          <Input
            data-testid="autocomplete-input"
            naked={shouldStripBorderWhenActive && popupOpen}
            error={!!errorMessage}
            style={{ cursor: 'pointer' }}
            maxLength={MAX_LENGTH_AUTOCOMPLETE}
            shouldStripHtml={false}
            {...getInputStylingProps(popupOpen)}
            {...(getInputProps() as UseAutocompleteInputProps)}
          />
        )}

        {popupOpen && (
          <Popover
            isOpen={popupOpen}
            hideOnInteractOutside={false}
            css={popover_container}
            sameWidth
            data-testid={`popup_${dataTestId}`}
            anchorElement={popoverRef?.current ?? null}
            htmlButtonElement={undefined}
            {...getPopoverProps(popupOpen)}
          >
            {loading && <LoadingComponent />}

            {/* We check input length to ensure that we are not showing no results found prematurely  */}
            {!loading && inputValue.length > 0 && groupedOptions.length === 0 && <NoResultsComponent />}

            {!loading && groupedOptions.length > 0 && (
              <>
                {listHeader}
                {/* // TODO: Fix label to make it human readable */}
                <Box as="ul" {...getListboxProps()} aria-relevant="all" aria-label={tt('autocomplete-listbox')}>
                  {/** TODO: Remove any after updating to the next TS release (4.2)
                   * https://github.com/microsoft/TypeScript/issues/36390 */}
                  {(groupedOptions as any[]).map((groupedOption, groupIndex) =>
                    groupBy && renderOptionGroupHeader ? (
                      <Box as="li" key={groupedOption.group} data-testid="autocomplete-group-heading">
                        {renderOptionGroupHeader({ headerTitle: groupedOption.group })}
                        <Box as="ul">
                          {groupedOption.options.map((option: OptionType, optionIndex: number) => (
                            // eslint-disable-next-line react/jsx-key
                            <Box
                              ref={isEqual(highlightedOption, option) ? highlightedOptionRef : undefined}
                              as="li"
                              data-testid={`autocomplete-option_li-${groupedOption.index + optionIndex}`}
                              {...getOptionProps({ option, index: groupedOption.index + optionIndex })}
                            >
                              {renderOption({
                                option,
                                isHighlighted: isEqual(highlightedOption, option),
                                isCompanyTripsPage,
                              })}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        key={groupedOption.group}
                        ref={isEqual(highlightedOption, groupedOption) ? highlightedOptionRef : undefined}
                        as="li"
                        aria-label={groupedOption.name}
                        data-testid={`autocomplete-option_li-${groupIndex}`}
                        {...getOptionProps({ option: groupedOption, index: groupIndex })}
                      >
                        {renderOption({
                          option: groupedOption,
                          isHighlighted: isEqual(highlightedOption, groupedOption),
                          isCompanyTripsPage,
                        })}
                      </Box>
                    ),
                  )}
                </Box>
              </>
            )}

            {!loading && groupedOptions.length > 0 && listFooter}
          </Popover>
        )}

        {!loading && footer}
      </Box>
      <ExpansionPanel expand={!popupOpen && errorMessage !== ''}>
        <Box pt={1}>
          <Typography variation="label" color={spotnanaTheme.colors.error}>
            {errorMessage}
          </Typography>
        </Box>
      </ExpansionPanel>
    </div>
  );
};

export default Autocomplete;
