import { SelectStore, Combobox } from '@ariakit/react';
import clsx from 'clsx';

import { forwardRef } from 'react';
import { TOptionBase } from '../../utils/select';
import { pseudo_input_content } from '../SelectRoot.styles';
import { Icon } from '../../Icon';
import { flex, margins } from '../../utils';

import { AdornmentWrapper, InputBase } from '../../Input';
import type { TInputStates } from '../../Input';

import { IconName } from '../../Icon/types';
import { useSelectConfig } from '../SelectConfigContext';
import { SelectMultiValueInternal } from './multiValueRenderers';

interface ComboboxTriggerProps {
  hasHeader?: boolean;
  icon?: IconName;
  className?: string;
  state?: TInputStates;
}

export const ComboboxTrigger = forwardRef<HTMLElement, ComboboxTriggerProps>(
  ({ hasHeader, icon, className, state }: ComboboxTriggerProps, ref) => {
    const {
      comboboxStore,
      variant,
      selectStore,
      placeholder,
      labelId,
      helperText,
      disabled = false,
      size,
      triggerRef,
      required,
    } = useSelectConfig<'combobox'>();
    const inputVal = comboboxStore.useState('value');
    const selectValue = (selectStore as SelectStore).useState('value');
    const isUserTyping = comboboxStore.useState('open');
    const focus = isUserTyping;
    const isSelectEmpty = !selectValue || selectValue.length === 0;
    const valueToRender = (() => {
      if (isUserTyping) {
        // always show the user-typed value when the combobox is open,
        // ignoring whatever is in the select
        return inputVal;
      }

      /**
       * If dropdown is closed
       */
      if (variant === 'multiple') {
        // multiple select rendering is handled by endAdornment as a UI tactic where SelectMultiValueInternal "covers" the input
        // thus, we don't need to render anything here
        return '';
      }
      return (selectStore.getSelection(selectValue) as TOptionBase | null)?.label ?? inputVal;
    })();

    // TODO: Evaluate how to solve this a11y issue differently in the future
    // useEffect(() => {
    //   /**
    //    * Fixes issue where no item is announced on opening the dropdown
    //    */
    //   if (isUserTyping && !comboboxStore.getState().activeId) {
    //     comboboxStore.move(comboboxStore.first());
    //   }
    // }, [comboboxStore, isUserTyping, selectValue]);

    const descriptionProps = helperText ? { 'aria-describedby': `${labelId}-description` } : {};

    return (
      <Combobox
        ref={triggerRef as React.RefObject<HTMLInputElement>}
        disabled={disabled}
        store={comboboxStore}
        render={(props: Omit<React.HTMLAttributes<HTMLElement>, 'color'>) => (
          <div className={`BlocksInput-${size}`}>
            <InputBase
              inputProps={{
                ...props,
                ...descriptionProps,
                value: valueToRender,
                'aria-labelledby': labelId,
                'aria-required': required,
              }}
              css={[margins.yt.m0, { caretColor: !isUserTyping ? 'transparent' : undefined }]}
              placeholder={isSelectEmpty || isUserTyping || focus ? placeholder : undefined}
              {...{
                endAdornment: variant === 'multiple' && !focus && (
                  <div
                    css={[pseudo_input_content, flex.justify.between]}
                    className={clsx({ 'is-hidden': isUserTyping })}
                  >
                    <SelectMultiValueInternal placeholder={undefined} />
                  </div>
                ),
                startAdornment: icon ? (
                  <AdornmentWrapper variant="left">
                    <Icon name={icon} />
                  </AdornmentWrapper>
                ) : undefined,
              }}
              fullWidth
              className={clsx(props.className, className, 'BlocksSelect-SearchField', 'ExternalField', {
                'is-under-header': hasHeader,
              })}
              value={valueToRender}
              state={state}
              ref={ref}
            />
          </div>
        )}
      />
    );
  },
);
