/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reason why exchange is not supported via OBT. - EXCHANGE_NOT_SUPPORTED_REASON_UNKNOWN: We were not able to figure out the reason why exchange was not supported. - FLIGHT_CHECKED_IN: Any one segment of the current ticket is checked-in. - NON_EXCHANGEABLE: Ticket is non-exchangeable. - AUTOMATED_EXCHANGE_NOT_SUPPORTED: Automated exchange via API is not supported for the ticket. - MULTI_PAX_EXCHANGE_NOT_SUPPORTED: Multi-pax exchange is not supported via OBT. - EXCHANGE_NOT_SUPPORTED_FOR_MARKUP_FARES: Exchange for markup fares not supported via OBT. - REWARDS_EXCHANGE_NOT_ENABLED: Automated exchange disabled for rewards. - FARE_RULES_UNKNOWN: Correct fare rules unknown. - ALL_FLIGHTS_USED: All the flights in the current ticket is flown. - MISSING_PQ: The PQ for the ticket we are tying to exchange is no longer present in the PNR. - AIRLINE_TICKET: The ticket is an airline ticket.  - AIRLINE_CONTROL: The PNR is under airline control. - SOURCE_NOT_IMPLEMENTED: Exchange is not implemented for the source of the given PNR.
 * @export
 * @enum {string}
 */

export const ExchangeNotSupportedReason = {
  ExchangeNotSupportedReasonUnknown: 'EXCHANGE_NOT_SUPPORTED_REASON_UNKNOWN',
  FlightCheckedIn: 'FLIGHT_CHECKED_IN',
  NonExchangeable: 'NON_EXCHANGEABLE',
  AutomatedExchangeNotSupported: 'AUTOMATED_EXCHANGE_NOT_SUPPORTED',
  MultiPaxExchangeNotSupported: 'MULTI_PAX_EXCHANGE_NOT_SUPPORTED',
  ExchangeNotSupportedForMarkupFares: 'EXCHANGE_NOT_SUPPORTED_FOR_MARKUP_FARES',
  RewardsExchangeNotEnabled: 'REWARDS_EXCHANGE_NOT_ENABLED',
  FareRulesUnknown: 'FARE_RULES_UNKNOWN',
  AllFlightsUsed: 'ALL_FLIGHTS_USED',
  MissingPq: 'MISSING_PQ',
  AirlineTicket: 'AIRLINE_TICKET',
  AirlineControl: 'AIRLINE_CONTROL',
  SourceNotImplemented: 'SOURCE_NOT_IMPLEMENTED',
  ScheduleChangePending: 'SCHEDULE_CHANGE_PENDING',
  ApprovalPending: 'APPROVAL_PENDING',
  ApprovalDenied: 'APPROVAL_DENIED',
} as const;

export type ExchangeNotSupportedReason = (typeof ExchangeNotSupportedReason)[keyof typeof ExchangeNotSupportedReason];
