import type { EntityAnswer } from '@spotnana/types/openapi/models/entity-answer';

export const getDisplayPreBookAnswer = (preBookAnswer: EntityAnswer) => {
  return (
    preBookAnswer.userInput ||
    preBookAnswer.answers
      ?.map((answer) => {
        let answerDisplayText = '';
        if (answer.item) {
          answerDisplayText = answer.item;
        }
        if (answer.description) {
          answerDisplayText = `${answerDisplayText} - ${answer.description}`;
        }
        if (answer.value) {
          if (Number.isNaN(answer.value) || answer.value === 'true' || answer.value === 'false') {
            answerDisplayText = `${answerDisplayText} (${answer.value})`;
          } else {
            answerDisplayText = `${answerDisplayText} (${answer.value}%)`;
          }
        }
        return answerDisplayText;
      })
      .join(', ') ||
    '-'
  );
};
