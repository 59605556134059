export { getFirstFocusableIn } from '@ariakit/core/utils/focus';
/**
 * A higher order function that can take a prefix and/or postfix to add to a string. By default it always adds '...' to every given string.
 * This makes sure that Apple Voiceover etc are able to give semantic pauses between words even in cases where they might be 2 spans in the same div.
 *
 * The intention is to make sure that the screenreader reads things naturally and doesn't confuse vision impaired
 * people by reading things in ways a human would not.
 *
 * To that effect, the function it returns takes a second argument called "isAbbreviation" which lets the screenreader pronounce "AMS" as "A.  M.  S"
 * instead of saying "Ams" as a single word.
 *
 * @param pre prefix word that only screenreader will read, for example "Flight code: "
 * @param post postfix word that only screenreader will read, for example ", "
 *
 */

export function addVocalHints(pre: string | null = null, post = '...') {
  return (s: string, isAbbreviation?: boolean): JSX.Element => (
    <>
      <span className="only-screenreader">{pre}</span>
      {isAbbreviation &&
        s
          .split('')
          .join('-')
          .split('')
          .map((char, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <span className={char === '-' ? 'only-screenreader' : undefined} key={char + idx}>
              {char}
            </span>
          ))}
      {!isAbbreviation && s}
      <span className="only-screenreader">{post}</span>
    </>
  );
}
