import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import useHeight from './UseHeight';
const ExpansionPanel = ({ expand, containerStyles, wrapperStyles, children, keepChildrenMountedOnCollpase = false, 'data-testid': dataTestId, }) => {
    const [heightRef, height] = useHeight();
    const [isFullyExpanded, setIsFullyExpanded] = useState(false);
    const slideInStyles = useSpring({
        from: { opacity: 0, height: 0 },
        to: {
            opacity: expand ? 1 : 0,
            height: expand ? height : 0,
        },
        onFrame: (frame) => {
            if (height > 0 && frame.height === height && !isFullyExpanded) {
                setIsFullyExpanded(true);
            }
            else if (frame.height !== height && isFullyExpanded) {
                setIsFullyExpanded(false);
            }
        },
    });
    return (React.createElement(animated.div, { style: Object.assign(Object.assign(Object.assign({}, slideInStyles), { overflowY: keepChildrenMountedOnCollpase && !isFullyExpanded ? 'hidden' : undefined }), containerStyles) },
        React.createElement("div", { style: wrapperStyles, ref: heightRef, "data-testid": dataTestId, "aria-expanded": expand && isFullyExpanded }, expand || keepChildrenMountedOnCollpase ? children : null)));
};
/**
 * @deprecated
 */
export default ExpansionPanel;
