import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const languageChangedHandler = (lang: string) => {
  // set document language
  document.documentElement.setAttribute('lang', lang);
};

/**
 * Sync i18n language with document language attribute
 */
export function useI18nDocumentLangSync() {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.on('languageChanged', languageChangedHandler);

    // remove event listener on unmount
    return () => i18n.off('languageChanged', languageChangedHandler);
  }, [i18n]);
}
