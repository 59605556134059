import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Flatbed = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.33553 13.1255C4.17444 12.4972 3.55307 12.0906 2.90443 12.2068C2.2249 12.3286 1.77278 12.9782 1.89458 13.6578L4.24479 26.7697L4.26634 26.8674C4.40944 27.4123 4.90352 27.7992 5.47518 27.7992H36.2136L36.3069 27.7957C36.9537 27.748 37.4636 27.2081 37.4636 26.5492L37.4602 26.4559C37.4125 25.8091 36.8726 25.2992 36.2136 25.2992L6.52059 25.2991L4.35536 13.2167L4.33553 13.1255ZM15.5773 12.1873C16.1462 12.1873 16.6387 12.5704 16.7843 13.1119L16.8063 13.209L17.3279 16.0198L31.2796 16.7417L31.5012 16.3329C32.3527 14.748 32.9839 13.9749 34.2131 13.5124C36.291 12.7306 38.0033 14.3441 37.685 16.4722L37.1292 21.2073C37.1087 21.387 37.0896 21.5557 37.0718 21.7133C36.9002 23.2378 36.0422 24.0551 34.5059 24.234L34.172 24.2639C33.4626 24.3153 32.9328 24.2814 30.1466 24.0687C30.1233 24.0669 30.1002 24.0645 30.0774 24.0615L30.0573 24.0623H13.1003C9.12949 24.0623 7.01621 21.761 6.34367 17.6058L6.3096 17.3854L5.797 13.6052C5.6995 12.8862 6.23113 12.243 6.94196 12.1907L7.03566 12.1873H15.5773ZM16.2148 18.4658L31.8311 19.2736C32.2913 19.3276 32.7636 19.1225 33.0303 18.7061C33.1611 18.5018 33.2652 18.3245 33.4128 18.0554L33.7845 17.3666C34.3142 16.4005 34.6366 16.0242 35.0936 15.8523L35.1657 15.8243C35.1828 15.8172 35.1935 15.812 35.2033 15.8033L35.2068 15.8123C35.2212 15.8618 35.233 15.9688 35.2075 16.1396L35.1351 16.746C34.8883 18.819 34.707 20.372 34.5875 21.4337L34.5685 21.6262C34.5559 21.7135 34.5292 21.7297 34.4041 21.74L34.0859 21.7631C33.6869 21.795 33.3436 21.7922 32.3012 21.7212L30.3369 21.576C30.2969 21.5729 30.2572 21.5718 30.2179 21.5725C30.1653 21.5657 30.1117 21.5623 30.0573 21.5623H13.1003L12.9372 21.5605C10.411 21.5048 9.24197 20.1353 8.78489 17.0351L8.46668 14.6873H14.5379L15.0504 17.4457L15.0729 17.5444C15.2129 18.0623 15.6715 18.4376 16.2148 18.4658Z", fill: themeEnrichedPrimaryColor })));
};
Flatbed.defaultProps = defaultIconProps;
export default Flatbed;
