/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * What type of travel booking it is.
 * @export
 * @enum {string}
 */

export const BookingType = {
  Air: 'AIR',
  Hotel: 'HOTEL',
  Car: 'CAR',
  Rail: 'RAIL',
} as const;

export type BookingType = (typeof BookingType)[keyof typeof BookingType];
