/* eslint-disable */
import { UserOrgId } from '../../../v1/obt/common/user_org_id';
import { Name } from '../../../v1/common/name';
import { Gender } from '../../../v1/common/gender';
import { DateTime } from '../../../v1/common/date_time';
import { Title } from '../../../v1/obt/common/title';
import { PhoneNumber } from '../../../v1/common/phone_number';

export enum Relation {
  UNKNOWN_RELATION = 0,
  SPOUSE = 1,
  CHILD = 2,
  FRIEND = 3,
  PARENT = 4,
  OTHER = 5,
  UNRECOGNIZED = -1,
}

/** TODO (ST-11931): Create common proto to use in Relative and Personal User. */
export interface Relative {
  /** Not present while creating */
  userOrgId?: UserOrgId;
  name?: Name;
  relativeOf?: RelativeRelativeOf;
  /** Relation to the relative_of. */
  relation: Relation;
  /** This field is optional */
  email: string;
  gender: Gender;
  dob?: DateTime;
  title: Title;
  phoneNumbers: PhoneNumber[];
  nationality?: string;
  preferredLanguage: string;
}

export interface RelativeRelativeOf {
  userOrgId?: UserOrgId;
  email: string;
  name?: Name;
}
