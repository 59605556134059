// Generated using yarn generate command
import { css } from '@emotion/react';
import { themed } from '../utils';
import { cornerRadii } from '../utils/styling/utilityTokens';

export const ROOT_CLASS = 'BlocksSurface';

export const bgVariants = themed(({ palette }) => {
  const baseSelector = `&.${ROOT_CLASS}-variant`;
  return css`
    ${baseSelector}-base {
      background-color: ${palette.surface.base};
    }

    ${baseSelector}-background {
      background-color: ${palette.surface.background};
    }

    ${baseSelector}-menu {
      background-color: ${palette.surface.menu};
    }

    ${baseSelector}-background-hover {
      background-color: ${palette.bg.hover};
    }
  `;
});

export const borders = themed(({ palette }) => {
  const baseBorderSelector = `&.${ROOT_CLASS}-border`;
  return css`
    ${baseBorderSelector}-none {
      border: none;
    }

    ${baseBorderSelector}-light {
      border: 1px solid ${palette.border.light};
    }

    ${baseBorderSelector}-medium {
      border: 1px solid ${palette.border.medium};
    }

    ${baseBorderSelector}-dark {
      border: 1px solid ${palette.border.dark};
    }
  `;
});

const baseBorderRadiusSelector = `&.${ROOT_CLASS}-borderRadius`;
export const borderRadiusStyles = css`
  ${baseBorderRadiusSelector}-flat {
    border-radius: 0;
  }

  ${baseBorderRadiusSelector}-outer {
    ${cornerRadii.outer}
  }

  ${baseBorderRadiusSelector}-standard {
    ${cornerRadii.standard}
  }

  ${baseBorderRadiusSelector}-small {
    ${cornerRadii.small}
  }

  ${baseBorderRadiusSelector}-full {
    ${cornerRadii.full}
  }
`;

export const shadows = themed(({ palette }) => {
  const baseSelector = `&.${ROOT_CLASS}-shadow`;
  return css`
    ${baseSelector}-card {
      box-shadow: ${palette.shadow.cardShadow};
    }

    ${baseSelector}-component {
      box-shadow: ${palette.shadow.componentShadow};
    }

    ${baseSelector}-scroll-top {
      box-shadow: ${palette.shadow.scrollTopShadow};
    }

    ${baseSelector}-scroll-bottom {
      box-shadow: ${palette.shadow.scrollBottomShadow};
    }
  `;
});
