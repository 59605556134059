import type { Document as DocumentV1 } from './api/v1/obt/document/document_service';
import { DocumentType as DocumentTypeV1, EntityType as EntityTypeV1 } from './api/v1/obt/document/document_service';

import { DocumentType as DocumentTypeV2 } from './api/v2/obt/model/document-type';
import type { Document as DocumentV2 } from './api/v2/obt/model/document';
import { EntityType as EntityTypeV2 } from './api/v2/obt/model/entity-type';
import { TravelTypeV2 } from './api/v2/obt/model';
import { travelTypeV1ToV2 } from './api/v2/obt/model/travel-type';

export const documentTypeV1ToV2Map: Record<DocumentTypeV1, DocumentTypeV2> = {
  [DocumentTypeV1.DOCUMENT_TYPE_UNKNOWN]: DocumentTypeV2.Others,
  [DocumentTypeV1.BOARDING_PASS]: DocumentTypeV2.BoardingPass,
  [DocumentTypeV1.INVOICE]: DocumentTypeV2.Invoice,
  [DocumentTypeV1.VISA]: DocumentTypeV2.Visa,
  [DocumentTypeV1.CONFIRMATION]: DocumentTypeV2.Confirmation,
  [DocumentTypeV1.MISCELLANEOUS]: DocumentTypeV2.Miscellaneous,
  [DocumentTypeV1.OTHERS]: DocumentTypeV2.Others,
  [DocumentTypeV1.UNRECOGNIZED]: DocumentTypeV2.Others,
};

export const documentEntityTypeV1ToV2 = (v1: EntityTypeV1 | undefined) =>
  v1 === EntityTypeV1.PNR ? EntityTypeV2.Pnr : EntityTypeV2.Trip;

export const documentV1ToV2Mapper = (document: DocumentV1): DocumentV2 => {
  return {
    ...document,
    documentMetadata: {
      ...document.documentMetadata,
      entityMetadata: {
        ...(document.documentMetadata?.entityMetadata ?? {}),
        travelType: document.documentMetadata?.entityMetadata?.travelType
          ? travelTypeV1ToV2(document.documentMetadata.entityMetadata.travelType)
          : TravelTypeV2.All,
        invoiceMetadata: document.documentMetadata.entityMetadata?.pnrMetadata?.invoiceMetadata || undefined,
      },
      documentType: document.documentMetadata
        ? documentTypeV1ToV2Map[document.documentMetadata.documentType]
        : DocumentTypeV2.Others,
      entityType: documentEntityTypeV1ToV2(document.documentMetadata?.entityType),
    },
  };
};

export type { DocumentV1, DocumentV2 };
export { EntityTypeV1, DocumentTypeV1, EntityTypeV2, DocumentTypeV2 };
