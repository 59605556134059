import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
import React, { forwardRef, useMemo } from 'react';
import sample from 'lodash/sample';
import { RemoveMuiProps } from '../types';
import {
  avatarColorClassName,
  avatarIconClassName,
  avatarRootClassName,
  avatarSizeClassName,
  avatarTextClassName,
  avatar_badge,
  avatar_base_style,
  avatar_container,
  colorVariants,
  randomColorVariants,
} from './Avatar.styles';
import { clsx } from '../utils/classNames';
import { Typography, TypoVariants } from '../Typography';
import { Icon } from '../Icon';

type IconName = Parameters<typeof Icon>['0']['name'];

export type TAvatarSizes = 'small' | 'medium' | 'large' | 'xlarge';
export type TTextAvatarColorVariants = (typeof colorVariants)[number] | 'random';

export type TAvatarBaseProps = {
  /**
   * Size of the avatar
   */
  size?: TAvatarSizes;
  className?: string;
  /** Alt text for screen reader */
  alt: string;
  badge?: React.ReactNode;
} & RemoveMuiProps<AvatarProps, 'alt' | 'imgProps' | 'srcSet' | 'src' | 'children'>;

export type TAvatarInitialProps = TAvatarBaseProps & {
  text: string;
  initialsLength?: number;
  customInitials?: string;
  color?: TTextAvatarColorVariants;
};
export type TAvatarWithImageProps = TAvatarBaseProps & { src: string };

export type TIconAvatarProps = TAvatarBaseProps & { iconName: IconName };

export type TAvatarProps = TAvatarInitialProps | TAvatarWithImageProps | TIconAvatarProps;

type TTextAvatarProps = {
  /** Text to be shown in avatar, initials are generated from this */
  text: string;
  /** Custom title to be announced by the screen reader */
  alt: string;
  /** Length of initials */
  initialsLength: number;
  /** Custom initials */
  customInitials?: string;
  /** Avatar Size */
  size: TAvatarSizes;
  /**
   * Avatar Color Variant
   * If set to random, it will randomly pick a color (except primary variant)
   * */
  color: TTextAvatarColorVariants;
};

const getInitialsFromText = (text: string, initialsLength: number) => {
  if (text.length <= initialsLength) {
    return text.toUpperCase();
  }
  return text
    .split(/\s+/)
    .slice(0, initialsLength)
    .map((item) => item?.[0]?.toUpperCase() || '')
    .join('');
};

const avatarSizeToTypographyVariantMap: Record<TAvatarSizes, TypoVariants> = {
  small: 'body3',
  medium: 'body2',
  large: 'body1',
  xlarge: 'body1',
};

/**
 * Renders TextAvatar
 * @returns
 */
const TextAvatar = ({ color, initialsLength, size, text, alt, customInitials }: TTextAvatarProps) => {
  const initials = useMemo(
    () => customInitials ?? getInitialsFromText(text, initialsLength),
    [text, initialsLength, customInitials],
  );

  // select avatar color
  const avatarColor = useMemo(() => {
    return color === 'random' ? sample(randomColorVariants) : color;
  }, [color]);

  return (
    <Typography
      className={clsx(avatarTextClassName, `${avatarColorClassName}-${avatarColor}`)}
      role="img"
      as="abbr"
      title={text}
      aria-label={alt}
      variant={avatarSizeToTypographyVariantMap[size]}
      kind="semibold"
    >
      {initials}
    </Typography>
  );
};

/**
 * Returns the Initials Length to be used
 * @param size
 * @param suppliedInitialsLength
 * @returns
 */
const getInitialsLength = (size: TAvatarProps['size'], suppliedInitialsLength?: number): number => {
  if (suppliedInitialsLength !== undefined) {
    return suppliedInitialsLength;
  }

  // for small size, always default to length of `one`
  return size === 'small' ? 1 : 2;
};

/**
 * Avatar Implementation for Blocks
 */
export const Avatar = forwardRef<HTMLDivElement, TAvatarProps>(function Avatar(props: TAvatarProps, ref) {
  const { className, badge, size = 'small', ...allProps } = props;

  let itemProps: Partial<AvatarProps> = {};

  if ('text' in allProps) {
    const { color = 'random', text, alt, initialsLength, customInitials, ...rest } = allProps;
    itemProps = {
      ...rest,
      children: (
        <TextAvatar
          color={color}
          size={size}
          text={text}
          initialsLength={getInitialsLength(size, initialsLength)}
          customInitials={customInitials}
          alt={alt}
        />
      ),
    };
  } else if ('iconName' in allProps) {
    const { iconName, alt, ...rest } = allProps;
    itemProps = {
      ...rest,
      children: <Icon name={iconName} variant="standalone" title={alt} />,
    };
  } else {
    const { alt, src, ...rest } = allProps;
    itemProps = { ...rest, alt, src };
  }

  if (badge) {
    return (
      <div className="BlocksAvatar-container" css={avatar_container}>
        <MuiAvatar
          ref={ref}
          css={avatar_base_style}
          className={clsx(
            avatarRootClassName,
            `${avatarSizeClassName}-${size}`,
            'iconName' in allProps && avatarIconClassName,
            className,
          )}
          {...itemProps}
        />
        <div css={avatar_badge}>{badge}</div>
      </div>
    );
  }

  return (
    <MuiAvatar
      ref={ref}
      css={avatar_base_style}
      className={clsx(
        avatarRootClassName,
        `${avatarSizeClassName}-${size}`,
        'iconName' in allProps && avatarIconClassName,
        className,
      )}
      {...itemProps}
    />
  );
});
