import { getDurationMinutes, getDateDiff } from '../../date-utils';
import type { ILayoverInfo } from '../../types/flight';
import type { DataFlightHiddenStop } from '../../types/api/v1/obt/air/air_search_response';

export default function getLayoverInformationFromHiddenStop(hiddenStop: DataFlightHiddenStop): ILayoverInfo {
  const { airport, arrivalDateTime, departureDateTime, duration } = hiddenStop;

  return {
    layoverDuration: duration ? Math.abs(getDurationMinutes(duration.iso8601 || '')) : 0,
    layoverAirportCode: airport?.airport || '',
    layoverAirportName: airport?.airportName || '',
    transfer: '',
    overnight:
      !!arrivalDateTime?.iso8601 && !!departureDateTime?.iso8601
        ? getDateDiff(arrivalDateTime.iso8601, departureDateTime.iso8601) >= 1
        : false,
  };
}
