/* eslint-disable */
import { UserId } from '../../../v1/common/user_id';
import { OrganizationId } from '../../../v1/obt/common/user_org_id';
import { Office, Department, LegalEntityId, CostCenter, Grade } from '../../../v1/obt/common/organization';
import { PhoneNumber } from '../../../v1/common/phone_number';
import { UserBasicInfo } from '../../../v1/obt/common/user_basic_info';

/**
 * This model is used on the traveler read/write level;
 * organization read/write uses a CompanySpecifiedAttributeLegalEntity.
 */
export interface CompanySpecifiedAttribute {
  /** field name string identifier. */
  fixedColumnName: string;
  /** field value. */
  value: string;
}

export interface UserBusinessInfo {
  id?: UserId;
  organizationId?: OrganizationId;
  email: string;
  office?: Office;
  managerId?: UserId;
  /**
   *  This describes the type of the worker in a given work assignment, e.g. permanent employee,
   *  fixed term employee, apprentice, vendor employee, volunteer, apprentice, independent
   *  contractor, etc.
   */
  designation?: string;
  phoneNumbers: PhoneNumber[];
  employeeId?: string;
  department?: Department;
  version?: number;
  legalEntityId?: LegalEntityId;
  userCostCenter?: CostCenter;
  grade?: Grade;
  managerBasicInfo?: UserBasicInfo;
  /** Email configuration for this user. */
  emailConfiguration?: EmailConfiguration;
  /** Accounting Code, CountryCode, WorkerType */
  accountingCode?: string;
  countryCode?: string;
  workerType?: UserBusinessInfoWorkerType;
  companySpecifiedAttributes?: CompanySpecifiedAttribute[];
}

export enum UserBusinessInfoWorkerType {
  WORKER_TYPE_UNKNOWN = 0,
  EMPLOYEE = 1,
  CONTINGENT = 2,
  SEASONAL = 3,
  INTERN = 4,
  UNRECOGNIZED = -1,
}

export interface UserBusinessInfoApprovalFrom {
  /**
   *  Tis would be equal to the key set in the corporate policy like "Cost Center head",
   *  "DManager", etc.
   */
  positionKey: string;
  userId?: UserId;
}

export interface EmailConfiguration {
  /** Email preference for confirmation emails. */
  confirmationEmailsPreference?: EmailConfigurationEmailPreference;
  /** Email preference for flight stats emails. */
  flightStatsEmailsPreference?: EmailConfigurationEmailPreference;
}
export interface EmailConfigurationEmailPreference {
  /** Whether to get the emails or not. */
  emailsDisabled: boolean;
  /**
   * Email addresses where these types of emails should be cced. If 'emails_disabled' is set to
   * true, then these emails will be added to 'To'.
   */
  ccEmails: string[];
}
