/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Types of pre-checkout questions. USER_DEFINED_QUESTION the default question type for all pre checkout questions which have been created from UI. OOP_REASON_CODE is kept separate so that existing OOP flow doesn\'t break.
 * @export
 * @enum {string}
 */

export const PreCheckoutQuestionType = {
  UnknownCheckoutQuestionType: 'UNKNOWN_CHECKOUT_QUESTION_TYPE',
  UserDefinedQuestion: 'USER_DEFINED_QUESTION',
  OopReasonCode: 'OOP_REASON_CODE',
} as const;

export type PreCheckoutQuestionType = (typeof PreCheckoutQuestionType)[keyof typeof PreCheckoutQuestionType];
