import type { DateTimeOffsetWithZone } from '../types/api/v2/obt/model/date-time-offset-with-zone';
import dateUtil from './dateUtil';

export const getTimezoneAwareFormattedDateTime = (
  dateTime: DateTimeOffsetWithZone | undefined,
  dateTimeFormat: string,
  fallbackFormat?: string,
): string => {
  try {
    if (!dateTime) {
      return '';
    }
    if (!dateTime.zoneId) {
      return dateUtil.parseZone(dateTime.iso8601).format(fallbackFormat ?? dateTimeFormat);
    }
    return dateUtil(dateTime.iso8601).tz(dateTime.zoneId).format(dateTimeFormat);
  } catch {
    return '';
  }
};

export default getTimezoneAwareFormattedDateTime;
