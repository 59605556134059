/**
 * This is a workaround for the issue where the escape keydown event is not being stopped from propagating to the parent element.
 * This causes issues if the aria-kit component is inside another escapable context like Modal
 *
 * This utility stops the propagation, but keeps the default behavior true
 * @param event
 * @returns
 */
export const fixPopoverEscapeKeyDown = (event: KeyboardEvent) => {
  event.stopPropagation();
  return true;
};
