import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const DirectionUp = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 12 16", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.653 6.77388C12.0946 6.33645 12.1147 5.63673 11.7132 5.17547L11.653 5.11125L6.84231 0.346625C6.81322 0.317814 6.78285 0.290666 6.75133 0.265232L6.68511 0.215473L6.61402 0.169464C6.56389 0.139367 6.51144 0.112906 6.45699 0.0903751L6.35503 0.0538048L6.26903 0.0305773L6.20057 0.0167439L6.14517 0.00873669L6.11159 0.00513829C6.07454 0.00174175 6.03735 0 6 0L6.08848 0.00322406C6.05926 0.00108713 6.02975 0 6 0C5.64075 0 5.32035 0.157408 5.10335 0.405425L0.347009 5.11125C-0.11567 5.5695 -0.11567 6.31563 0.347009 6.77388C0.788658 7.2113 1.49514 7.23118 1.96086 6.83352L2.0257 6.77388L4.8165 4.01L4.82551 14.825C4.82551 15.45 5.31581 15.9562 5.93095 15.9973L6.01186 16C6.64286 16 7.154 15.5144 7.1955 14.9051L7.19822 14.825L7.189 4.0155L9.9743 6.77388C10.4159 7.2113 11.1224 7.23118 11.5882 6.83352L11.653 6.77388Z", fill: themeEnrichedPrimaryColor })));
};
DirectionUp.defaultProps = defaultIconProps;
export default DirectionUp;
