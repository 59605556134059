import { css } from '@emotion/react';
import { flex, margins } from '@spotnana/blocks/src/utils';

export const button_container = css`
  ${flex.init}
  ${flex.justify.center}

  > div {
    width: 49%;
  }
`;

export const country_select = css`
  ${margins.y.m1point5}

  .BlocksSelect-Popover {
    max-height: 400px;
  }
`;
