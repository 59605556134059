import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessedError, flightInvalidLoyaltyErrorCode, qbrPassengerNameTooLong } from 'obt-common';

type GetErrorMessageByCodeFnType = (errorDetails: Pick<ProcessedError, 'errorCode' | 'errorParameters'>) => string;

export const useGetErrorTitleByCode = (): GetErrorMessageByCodeFnType => {
  const { t: tt } = useTranslation('WEB');

  return useCallback<GetErrorMessageByCodeFnType>(
    ({ errorCode }) => {
      switch (errorCode) {
        case qbrPassengerNameTooLong:
          return tt('Character limit exceeded');
        case flightInvalidLoyaltyErrorCode:
          return tt('Invalid loyalty information');
        default:
          return tt('Error');
      }
    },
    [tt],
  );
};
