import { initReactI18next } from 'react-i18next';
import type { InitOptions } from 'i18next';

import { baseTranslationInitOptions, i18n, LanguageDetector, LazyLoadPlugin } from 'obt-common';
import TranslationNamespaces, { WebTranslationNamespaces } from 'obt-common/translations/namespaces';

export const initOptions: InitOptions = {
  ...baseTranslationInitOptions,
  fallbackNS: TranslationNamespaces.COMMON,
  defaultNS: TranslationNamespaces.WEB,
  ns: WebTranslationNamespaces,
  load: 'currentOnly',
  react: {
    ...baseTranslationInitOptions.react,
    useSuspense: true,
  },
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(LazyLoadPlugin).use(LanguageDetector).use(initReactI18next).init(initOptions);

export { i18n };

/**
 * @deprecated
 * Use translate function via `useTranslate` hook
 */
export const translate = i18n.t.bind(i18n);
