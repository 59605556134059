import i18n from '../translations';

export function isMissingTranslation(errorCode: string): boolean {
  if (!errorCode) {
    return false;
  }

  const existsInErrors = i18n.exists(errorCode, { ns: 'ERRORS' });
  const existsInApiErrors = i18n.exists(errorCode, { ns: 'API_ERRORS' });
  const existsInBackendErrors = i18n.exists(errorCode, { ns: 'BACKEND_ERRORS' });

  return !existsInErrors && !existsInApiErrors && !existsInBackendErrors;
}
