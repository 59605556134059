import Box from '@spotnana/pixel-react/dist/Box';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import Typography from '@spotnana/pixel-react/dist/Typography';
import noop from 'lodash/noop';
import { logger, StorageKeys, onApiTraceId, storage } from 'obt-common';
import { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EmbedContext, EmbedEventTypes } from '../components/EmbedContext';
import { EmbedContextProps } from '../components/EmbedContext/EmbedContext';

const text = 'Our team has been notified! Try reloading the page, if the error persists please contact Support.';

interface IProps extends WithTranslation {
  fallback?: ReactNode;
  children?: ReactNode;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<IProps>, IState> {
  unsubscribeTraceId: () => void;

  lastTraceId: string;

  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
    this.lastTraceId = '';
    this.unsubscribeTraceId = noop;
  }

  static getDerivedStateFromError(_error: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount(): void {
    this.unsubscribeTraceId = onApiTraceId(({ traceId }) => {
      this.lastTraceId = traceId;
    });
  }

  /* istanbul ignore next */
  componentDidCatch(error: Error, _info: ErrorInfo): void {
    logger.error(error, this.lastTraceId);

    /* istanbul ignore next */
    (async () => {
      try {
        if (error.message.includes('Failed to fetch dynamically imported module')) {
          let lastReloadDate: Date | undefined = new Date(
            String(await storage.getItem(StorageKeys.APP_EXPIRED_RELOAD_TIME)),
          );
          // validate localstorage value
          if (isNaN(lastReloadDate.getTime())) {
            lastReloadDate = undefined;
          }
          const lastReloadTime = lastReloadDate?.getTime();
          const currentTime = new Date().getTime();

          if (
            // if we don't have a last reload time, we can safely reload
            !lastReloadTime ||
            /**
             * if we have a last reload time in local storage, we can reload under right conditions to avoid infinite reloading if user's internet is dead
             */
            (lastReloadTime && currentTime - lastReloadTime > 5 * 60 * 1000)
          ) {
            await storage.setItem(StorageKeys.APP_EXPIRED_RELOAD_TIME, new Date().toISOString());
            window.location.reload();
          }
        }
        /**
         * Defensive programming to ensure the app reload feature doesn't cause any issues.
         */
      } catch (e) {
        console.error(e);
      }
    })();

    (this.context as EmbedContextProps).reportEvent({
      type: EmbedEventTypes.UNEXPECTED_ERROR,
      payload: {
        errorMessage: error?.message,
        errorStack: error?.stack,
      },
    });

    this.setState({ hasError: true });
  }

  componentWillUnmount(): void {
    this.unsubscribeTraceId();
  }

  render(): JSX.Element {
    const { t, children } = this.props;
    const defaultFallback = (
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderColor={spotnanaTheme.colors.errorBorder}
        p="10%"
        bg="bg.regular"
      >
        <Typography variation="h2" mb="32px">
          {t('SOMETHING_WENT_WRONG')}...
        </Typography>
        {text}
      </Box>
    );

    const { fallback = defaultFallback } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <>{fallback}</>;
    }
    return <>{children}</>;
  }
}

ErrorBoundary.contextType = EmbedContext;
export default withTranslation()(ErrorBoundary);
