import { V2ToV1ItemTypeMapper, V2ToV1IFareComponentMapper } from '../../constants';
import type { PaymentItem as PaymentItemV1 } from '../../types/api/v1/obt/pnr/payment';
import type { PaymentItem } from '../../types/api/v2/obt/model/payment-item';

function getV1PaymentItemsFromV2(paymentItems: PaymentItem[]): PaymentItemV1[] {
  return paymentItems
    .map((paymentItem) => {
      if (paymentItem?.itemType && paymentItem?.fareComponent) {
        return {
          itemType: V2ToV1ItemTypeMapper[paymentItem.itemType],
          fareComponent: paymentItem.fareComponent.map(
            (selectedFareComponent) => V2ToV1IFareComponentMapper[selectedFareComponent],
          ),
        };
      }
      return null;
    })
    .filter((paymentItem): paymentItem is PaymentItemV1 => !!paymentItem);
}

export { getV1PaymentItemsFromV2 };
