/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { RailSeatPrice } from './rail-seat-price';

/**
 * Rail layout item.
 * @export
 * @interface RailLayoutItem
 */
export interface RailLayoutItem {
  /**
   * Seat availability information.
   * @type {string}
   * @memberof RailLayoutItem
   */
  'availability'?: RailLayoutItemAvailabilityEnum;
  /**
   * Column number of the layout item.
   * @type {number}
   * @memberof RailLayoutItem
   */
  'column': number;
  /**
   * Direction of the layout item.
   * @type {string}
   * @memberof RailLayoutItem
   */
  'direction'?: RailLayoutItemDirectionEnum;
  /**
   * Number of rows the layout item spans.
   * @type {number}
   * @memberof RailLayoutItem
   */
  'length': number;
  /**
   * Row number of the layout item.
   * @type {number}
   * @memberof RailLayoutItem
   */
  'row': number;
  /**
   * Seat number.
   * @type {string}
   * @memberof RailLayoutItem
   */
  'seatNumber'?: string;
  /**
   * List of prices for this seat.
   * @type {Array<RailSeatPrice>}
   * @memberof RailLayoutItem
   */
  'seatPrices'?: Array<RailSeatPrice>;
  /**
   * Layout item type.
   * @type {string}
   * @memberof RailLayoutItem
   */
  'type': RailLayoutItemTypeEnum;
  /**
   * Number of columns the layout item spans.
   * @type {number}
   * @memberof RailLayoutItem
   */
  'width': number;
}

export const RailLayoutItemAvailabilityEnum = {
  Available: 'AVAILABLE',
  Occupied: 'OCCUPIED',
  Unavailable: 'UNAVAILABLE'
} as const;

export type RailLayoutItemAvailabilityEnum = typeof RailLayoutItemAvailabilityEnum[keyof typeof RailLayoutItemAvailabilityEnum];
export const RailLayoutItemDirectionEnum = {
  Top: 'TOP',
  Bottom: 'BOTTOM',
  Left: 'LEFT',
  Right: 'RIGHT'
} as const;

export type RailLayoutItemDirectionEnum = typeof RailLayoutItemDirectionEnum[keyof typeof RailLayoutItemDirectionEnum];
export const RailLayoutItemTypeEnum = {
  Seat: 'SEAT',
  Table: 'TABLE',
  LuggageCompartment: 'LUGGAGE_COMPARTMENT',
  Toilet: 'TOILET',
  StairsUp: 'STAIRS_UP',
  StairsDown: 'STAIRS_DOWN'
} as const;

export type RailLayoutItemTypeEnum = typeof RailLayoutItemTypeEnum[keyof typeof RailLayoutItemTypeEnum];

