import type { GetAgentPrivateNoteResponse } from '@spotnana/types/openapi/models/get-agent-private-note-response';
import type { UpdateAgentPrivateNoteRequest } from '@spotnana/types/openapi/models/update-agent-private-note-request';
import type { UpdateAgentPrivateNoteResponse } from '@spotnana/types/openapi/models/update-agent-private-note-response';
import { useMutation, useQuery } from 'react-query';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import { defaultQueryClient } from './defaultQueryClient';

const listNotesKey = ['agentDesktopAgents', 'getNote'];

// Fetches a single note
const getNote = async (userId: string | undefined): Promise<GetAgentPrivateNoteResponse | null> => {
  try {
    // Check if userId is provided
    if (!userId) {
      return Promise.resolve(null);
    }
    // Make an API call to retrieve a note by noteId
    const data = await api('GET', 'agentDesktopAgents', {
      urlParam: `/${userId}/notes/private`,
    });
    return data as GetAgentPrivateNoteResponse;
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using getNote with React Query
export const useGetNote = ({ userId }: { userId: string | undefined }) => {
  return useQuery(listNotesKey, () => getNote(userId));
};

// Update a new note
const updateNote = async ({
  userId,
  request,
}: {
  userId: string | undefined;
  request: UpdateAgentPrivateNoteRequest;
}): Promise<UpdateAgentPrivateNoteResponse | null> => {
  // Check if userId is provided
  if (!userId) {
    return Promise.resolve(null);
  }
  try {
    // Make an API call to update a note
    const data = await api('POST', 'agentDesktopAgents', {
      urlParam: `/${userId}/notes/private`,
      data: request,
    });
    return data as UpdateAgentPrivateNoteResponse;
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using updateNote with React Query
export const useUpdateNote = () => {
  return useMutation(updateNote, {
    onSuccess: () => {
      // Invalidate the cache for listLinks query when a link is successfully updated
      defaultQueryClient.invalidateQueries(listNotesKey);
    },
  });
};
