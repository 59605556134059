import { SerializedStyles, css } from '@emotion/react';
import { themed } from '../utils/styling';
import { cornerRadii } from '../utils/styling/utilityTokens';
import { blocksPalette } from '../BlocksThemeProvider/ui-specs/blocksPalette';
import { BlocksTheme } from '../types';

type LinkMixinColorConfig = {
  base: string;
  hover: string;
  active: string;
  visited: string;
};

const makeColorStyles = (palette: BlocksTheme['palette']) =>
  Object.keys(blocksPalette.light.text).reduce((styles, currentColorName) => {
    const name = currentColorName as keyof typeof blocksPalette.light.text;
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
    (styles as any)[`&.color-${currentColorName}`] = {
      color: palette.text[name],
      textDecorationColor: palette.text[name],
      ':hover': { color: palette.text[name], textDecorationColor: palette.text[name] },
      ':active': { color: palette.text[name], textDecorationColor: palette.text[name] },
      ':visited': { color: palette.text[name], textDecorationColor: palette.text[name] },
    };
    return styles;
  }, {} as SerializedStyles);

const LinkStyle = ({ base, hover, active, visited }: LinkMixinColorConfig) => css`
  color: ${base};
  text-decoration-color: ${base};
  &:hover {
    color: ${hover};
    text-decoration-color: ${hover};
  }
  &:active {
    color: ${active};
    text-decoration-color: ${active};
  }
  &:visited {
    color: ${visited};
    text-decoration-color: ${visited};
  }
`;

export const linkBase = themed(
  ({ palette, fontWeight }) => css`
    font-size: inherit;
    font-weight: ${fontWeight.w500};
    cursor: pointer;

    &:focus-visible {
      ${cornerRadii.small}
      outline-offset: 2px;
      outline: 2px solid ${palette.border.keyboardFocus};
    }

    &.Link-standard,
    &.Link-active {
      ${LinkStyle({
        base: palette.text.link,
        hover: palette.text.linkHover,
        active: palette.text.linkHover,
        visited: palette.text.linkHover,
      })}
    }
    &.Link-strong {
      font-weight: ${fontWeight.w600};
    }

    ${makeColorStyles(palette)}

    &.color-inherit {
      color: inherit;
      text-decoration-color: inherit;
      &:hover,
      &:active,
      &:visited {
        color: inherit;
        text-decoration-color: inherit;
      }
    }
  `,
);
