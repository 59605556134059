import { css } from '@emotion/react';
import { breakpoints, margins, themed } from '@spotnana/blocks/src/utils';

export const dot = themed(
  ({ palette }) => css`
    flex-shrink: 0;
    &.dot-root {
      ${margins.x.half}
      align-self: center;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${palette.text.disabled};
      &.hide-below-tablet {
        ${margins.x.half}
        display: none;
        ${breakpoints.from('tablet')} {
          display: inline-block;
        }
      }
      &.hide-below-desktop {
        ${margins.x.half}
        display: none;
        ${breakpoints.from('desktop')} {
          display: inline-block;
        }
      }
      &.hide-below-large {
        ${margins.x.half}
        display: none;
        ${breakpoints.from('large')} {
          display: inline-block;
        }
      }
    }
  `,
);
