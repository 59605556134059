import noop from 'lodash/noop';
import { useState, useCallback, useLayoutEffect } from 'react';

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export interface DimensionInit {
  width: null;
  height: null;
  top: null;
  left: null;
  x: null;
  y: null;
  right: null;
  bottom: null;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type UseDimensionsHook<Elem extends HTMLElement> = [
  (node: Elem) => void,
  DimensionInit | DimensionObject,
  Elem | null,
];

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
  recalcOn?: 'resize' | 'scroll' | 'both' | 'none';
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    top: 'x' in rect ? rect.x : (rect as any).top,
    left: 'y' in rect ? rect.y : (rect as any).left,
    x: 'x' in rect ? rect.x : (rect as any).left,
    y: 'y' in rect ? rect.y : (rect as any).top,
    /* eslint-enable @typescript-eslint/no-explicit-any */
    right: rect.right,
    bottom: rect.bottom,
  };
}

export function useDimensions<Elem extends HTMLElement>(
  { recalcOn = 'none' }: UseDimensionsArgs = {},
  deps?: React.DependencyList,
): UseDimensionsHook<Elem> {
  const [dimensions, setDimensions] = useState<DimensionObject | DimensionInit>({
    width: null,
    height: null,
    top: null,
    left: null,
    bottom: null,
    right: null,
    x: null,
    y: null,
  });

  const [node, setNode] = useState<Elem | null>(null);

  const ref = useCallback((someNode: Elem) => {
    setNode(someNode);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => setDimensions(getDimensionObject(node));
      window.requestAnimationFrame(measure);

      const debouncedMeasure = () => {
        let timeout: number | undefined;
        if (timeout) {
          window.cancelAnimationFrame(timeout);
        }
        timeout = window.requestAnimationFrame(measure);
      };

      if (recalcOn === 'resize') {
        window.addEventListener('resize', debouncedMeasure);
      } else if (recalcOn === 'scroll') {
        window.addEventListener('scroll', debouncedMeasure);
      } else if (recalcOn === 'both') {
        window.addEventListener('resize', debouncedMeasure);
        window.addEventListener('scroll', debouncedMeasure);
      }

      return () => {
        if (recalcOn === 'resize') {
          window.removeEventListener('resize', debouncedMeasure);
        } else if (recalcOn === 'scroll') {
          window.removeEventListener('scroll', debouncedMeasure);
        } else if (recalcOn === 'both') {
          window.removeEventListener('resize', debouncedMeasure);
          window.removeEventListener('scroll', debouncedMeasure);
        }
      };
    }
    return noop;
  }, [
    node,
    recalcOn,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...(deps || []),
  ]);

  return [ref, dimensions, node];
}
