import type { ZIndex } from '@mui/material/styles/zIndex';

/**
 * Create a MUI zIndex spec object
 * @param overrides - Partial ZIndex object to override the default values
 * @returns ZIndex object
 */
export function makeMuiZIndexSpec(overrides: Partial<ZIndex> = {}): ZIndex {
  return {
    mobileStepper: 2000,
    fab: 2050,
    speedDial: 2050,
    appBar: 2100,
    drawer: 2200,
    modal: 2300,
    snackbar: 2400,
    tooltip: 2500,
    ...overrides,
  };
}
