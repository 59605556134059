import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Poi2 = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 13 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.55187 0.583008C10.1698 0.583008 13.0789 3.62711 12.9324 7.15544C12.8329 9.55035 11.7171 11.7508 9.93692 13.7186C9.2936 14.4298 8.60618 15.0608 7.91809 15.6064L7.73245 15.7516C7.41793 15.9942 7.1656 16.1711 6.99853 16.2787C6.88034 16.3593 6.74283 16.4059 6.6018 16.4149C6.47083 16.4233 6.33683 16.3992 6.21245 16.34C6.17427 16.3219 6.1374 16.3007 6.10227 16.2765L6.02683 16.2273C5.82699 16.0939 5.53793 15.8857 5.18587 15.6065C4.49787 15.061 3.81052 14.4301 3.16728 13.7191C2.15448 12.5995 1.3461 11.3857 0.820959 10.0845C0.726382 9.85133 0.640784 9.6128 0.568077 9.38121C0.301576 8.578 0.166085 7.82534 0.166085 7.12257C0.166085 7.10293 0.1668 7.08346 0.168206 7.06417C0.0750549 3.57479 2.96517 0.583008 6.55187 0.583008ZM1.7502 7.20077C1.76017 7.70371 1.86595 8.26507 2.07469 8.89447C2.13852 9.09751 2.20973 9.29594 2.28872 9.4907C2.73831 10.6046 3.44533 11.6663 4.34145 12.6569C4.92217 13.2988 5.54621 13.8716 6.16959 14.3659C6.30364 14.4722 6.4315 14.5699 6.55187 14.6587C6.67229 14.5698 6.80023 14.4721 6.93437 14.3657C7.55783 13.8713 8.18196 13.2985 8.76275 12.6564C10.3164 10.939 11.2685 9.06138 11.3504 7.08972C11.4597 4.45949 9.26818 2.16634 6.55187 2.16634C3.83555 2.16634 1.64405 4.45949 1.75329 7.08972C1.75485 7.1273 1.75376 7.16437 1.7502 7.20077ZM4.59258 4.81445C3.51047 5.896 3.51047 7.65057 4.59272 8.73283L4.67801 8.81439C5.78407 9.81762 7.46535 9.77873 8.51125 8.73283C9.59306 7.65046 9.59306 5.89611 8.51125 4.8143C7.429 3.73205 5.67442 3.73205 4.59258 4.81445ZM7.39166 5.93389C7.85519 6.39741 7.85519 7.14948 7.39152 7.61338C6.94348 8.06143 6.22277 8.0781 5.75583 7.65471L5.69856 7.59982C5.24844 7.14937 5.24844 6.39752 5.71217 5.93403C6.15739 5.48857 6.8681 5.47076 7.33465 5.88045L7.39166 5.93389Z", fill: themeEnrichedPrimaryColor })));
};
Poi2.defaultProps = defaultIconProps;
export default Poi2;
