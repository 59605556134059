import { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { saveStorageValue, fetchStorageValue, StorageKeys, useAuth } from 'obt-common';

import { Modal, useModalState } from '@spotnana/blocks/src/Modal';
import { Typography } from '@spotnana/blocks/src/Typography';
import { NOTIFICATION_BEFORE_IDLE_MINUTES, ONE_MINUTE_IN_MILLISECONDS } from '../constansts';
import { modal } from '../SessionTimeoutModal.styles';
import { preserveURLForRedirectOnLogin } from '../utils';

interface AbsoluteSessionTimeoutModalContainerProps {
  absoluteSessionTimeoutMin: number;
}

export function AbsoluteSessionTimeoutModalContainer({
  absoluteSessionTimeoutMin,
}: AbsoluteSessionTimeoutModalContainerProps): ReactElement | null {
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const [remainingMinutes, setRemainingMinutes] = useState<number>(NOTIFICATION_BEFORE_IDLE_MINUTES);
  const { t: tt } = useTranslation('WEB');
  const { closeModal, openModal, open: isModalOpen } = useModalState();
  const { logout } = useAuth();

  useEffect(() => {
    const interval = setInterval(async () => {
      let sessionTimeoutStartTime = await fetchStorageValue<string>(
        StorageKeys.ABSOLUTE_SESSION_TIMEOUT_START_TIME,
      ).catch(() => null);

      // Set the session timeout start time if it is not set yet (after login or updating company settings)
      if (!sessionTimeoutStartTime) {
        sessionTimeoutStartTime = new Date().toISOString();
        await saveStorageValue<string>(StorageKeys.ABSOLUTE_SESSION_TIMEOUT_START_TIME, sessionTimeoutStartTime).catch(
          (e) => console.error('Error saving session timeout start time', e),
        );
      }

      const nowDate = new Date();
      const sessionTimeoutStartDate = new Date(sessionTimeoutStartTime);
      const diffMilliseconds = nowDate.getTime() - sessionTimeoutStartDate.getTime();
      const isSessionExpired = diffMilliseconds >= absoluteSessionTimeoutMin * ONE_MINUTE_IN_MILLISECONDS;
      const shouldShowNotification =
        diffMilliseconds >=
          (absoluteSessionTimeoutMin - NOTIFICATION_BEFORE_IDLE_MINUTES) * ONE_MINUTE_IN_MILLISECONDS &&
        !isNotificationShown;

      if (isSessionExpired) {
        preserveURLForRedirectOnLogin();
        logout();
      } else if (shouldShowNotification) {
        setIsNotificationShown(true);
        openModal();
      }

      setRemainingMinutes(
        Math.floor(
          (absoluteSessionTimeoutMin * ONE_MINUTE_IN_MILLISECONDS - diffMilliseconds) / ONE_MINUTE_IN_MILLISECONDS,
        ),
      );
    }, NOTIFICATION_BEFORE_IDLE_MINUTES);

    return () => {
      clearInterval(interval);
    };
  }, [absoluteSessionTimeoutMin, isNotificationShown, logout, openModal]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      css={modal}
      open
      hideCloseButton
      variant="medium"
      data-testId="modal_absolute-session-timeout"
      header={{ title: tt('Session timeout') }}
      footer={{ primaryAction: { text: tt('Continue'), onClick: closeModal } }}
    >
      <Typography variant="body1">
        <Trans
          t={tt}
          values={{
            minutes: remainingMinutes > 1 ? remainingMinutes : 1,
            minutesWord: remainingMinutes > 1 ? tt('minutes') : tt('minute'),
          }}
        >
          {
            'Your session is about to expire. You will be automatically logged out in {{minutes}} {{minutesWord}}. Please save any unfinished changes.'
          }
        </Trans>
      </Typography>
    </Modal>
  );
}
