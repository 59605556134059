import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { OrganizationBasicInfo, OrganizationNode } from '../../types/api/v1/obt/common/organization';
import type {
  ReadOrganizationRequest,
  ReadOrganizationResponse,
} from '../../types/api/v1/obt/profile/organization_service';
import type { OrganizationId } from '../../types/api/v1/obt/common/user_org_id';
import SpotnanaError from '../../api/SpotnanaError';
import api from '../../api';
import type { ListPaymentInstructionsRequest, ListPaymentInstructionsResponse, SpotnanaQueryResult } from '../../types';
import { defaultQueryClient } from '../defaultQueryClient';

export const fetchOrganizationDetail = async (organizationId?: OrganizationId): Promise<OrganizationNode> => {
  if (!organizationId) {
    throw new SpotnanaError('No organization id passed to fetch details of');
  }

  const readOrganizationRequest: ReadOrganizationRequest = { organizationId };

  const data = await api('POST', 'readOrganization', {
    data: readOrganizationRequest,
  });
  const result = data as ReadOrganizationResponse;
  if (result.organizationNode) {
    return result.organizationNode;
  }
  throw new SpotnanaError('Organization node does not exist for ReadOrganizationResponse');
};

const organizationDetailKey = (organizationId?: OrganizationId): unknown[] => ['read-organization', organizationId];

export const useOrganizationReadQuery = (
  organizationId?: OrganizationId,
  isEnabled = true,
): SpotnanaQueryResult<OrganizationNode> =>
  useQuery<OrganizationNode, SpotnanaError>(
    organizationDetailKey(organizationId),
    () => fetchOrganizationDetail(organizationId),
    {
      enabled: !!organizationId && isEnabled,
    },
  );

export const invalidateReadOrganization = (organizationId: OrganizationId): void => {
  defaultQueryClient.invalidateQueries(organizationDetailKey(organizationId));
};

const fetchOrganizationBasicDetail = async (organizationId?: OrganizationId): Promise<OrganizationBasicInfo> => {
  const readOrganizationRequest: ReadOrganizationRequest = { organizationId };

  const data = await api('POST', 'readOrganizationBasicInfo', {
    data: readOrganizationRequest,
  });
  const result = data as OrganizationBasicInfo;
  if (result) {
    return result;
  }
  throw new SpotnanaError('Organization does not exist for OrganizationBasicInfo');
};

const organizationBasicDetailKey = (organizationId?: OrganizationId): unknown[] => [
  'read-organization-basic-info',
  organizationId,
];

export const useOrganizationReadBasicInfoQuery = (
  organizationId?: OrganizationId,
): SpotnanaQueryResult<OrganizationBasicInfo> =>
  useQuery<OrganizationBasicInfo, SpotnanaError>(
    organizationBasicDetailKey(organizationId),
    () => fetchOrganizationBasicDetail(organizationId),
    {
      enabled: !!organizationId,
    },
  );

export const invalidateOrganizationReadBasicInfo = (organizationId?: OrganizationId): void => {
  defaultQueryClient.invalidateQueries(organizationBasicDetailKey(organizationId));
};

const VirualCardsInstructionsListKey = (req: ListPaymentInstructionsRequest): unknown[] => [
  'virtual-cards-instructions-list',
  req,
];

const fetchVirtualCardsInstructionsList = async (
  req: ListPaymentInstructionsRequest,
): Promise<ListPaymentInstructionsResponse> => {
  const data = await api('POST', 'paymentSources', {
    urlParam: `/virtual-cards/payment-instructions/list`,
    data: req,
  });
  return data as ListPaymentInstructionsResponse;
};
export const usePaymentSourceVirtualCardsInstructionsList = (
  req: ListPaymentInstructionsRequest,
  options?: UseQueryOptions<ListPaymentInstructionsResponse, SpotnanaError>,
): SpotnanaQueryResult<ListPaymentInstructionsResponse> =>
  useQuery<ListPaymentInstructionsResponse, SpotnanaError>(
    VirualCardsInstructionsListKey(req),
    () => fetchVirtualCardsInstructionsList(req),
    options,
  );
