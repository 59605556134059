import type { IAirSearchFormPaxInfo } from '../../types/flight';
import { IAirPassengerTypeEnum } from '../../types/flight';
import type { ITraveler, IUserOrgId } from '../../types/traveler';

export function getAirSeatMapAncillaryRequestParams(
  passengers: IAirSearchFormPaxInfo[],
  travelersResponse: ITraveler[],
) {
  const disabled =
    passengers.length < 1 || travelersResponse.length < 1 || passengers.length !== travelersResponse.length;

  const passengersWithUserOrgId = passengers
    .map((pax, paxIndex) => ({
      ...pax,
      userOrgId: travelersResponse[paxIndex]?.userOrgId as IUserOrgId,
    }))
    .filter((pax) => pax.paxType !== IAirPassengerTypeEnum.INFANT_ON_LAP);

  return { passengersWithUserOrgId, disabled };
}
