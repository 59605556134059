/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
import { HotelRateAssuranceMetadata } from './hotel-rate-assurance-metadata';

/**
 * Rate Assurance Rebooking information along with pnr references and savings
 * @export
 * @interface RebookReference
 */
export interface RebookReference {
  /**
   * Backward reference to the cancelled PNRs (if any) after rebooking.
   * @type {Array<string>}
   * @memberof RebookReference
   */
  cancelledPnrIds?: Array<string>;
  /**
   * Forward reference to the rebooked PNR (if any) after cancellation.
   * @type {string}
   * @memberof RebookReference
   */
  rebookedPnrId?: string;
  /**
   *
   * @type {HotelRateAssuranceMetadata}
   * @memberof RebookReference
   */
  hotelRateAssuranceMetadata?: HotelRateAssuranceMetadata;
  /**
   * Indicates the reason for rebooking.
   * @type {string}
   * @memberof RebookReference
   */
  rebookType?: RebookReferenceRebookTypeEnum;
}

export const RebookReferenceRebookTypeEnum = {
  RateAssurance: 'RATE_ASSURANCE',
  Modify: 'MODIFY',
} as const;

export type RebookReferenceRebookTypeEnum =
  (typeof RebookReferenceRebookTypeEnum)[keyof typeof RebookReferenceRebookTypeEnum];
