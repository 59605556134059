/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Fare preferences.
 * @export
 * @interface FarePref
 */
export interface FarePref {
    /**
     * 
     * @type {string}
     * @memberof FarePref
     */
    fairType?: FarePrefFairTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof FarePref
     */
    fareTypes: Array<FarePrefFareTypesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum FarePrefFairTypeEnum {
    UnknownType = 'UNKNOWN_TYPE',
    Cheapest = 'CHEAPEST',
    FullyRefundable = 'FULLY_REFUNDABLE',
    Editable = 'EDITABLE',
    Changeable = 'CHANGEABLE',
    Refundable = 'REFUNDABLE',
    Unrecognized = 'UNRECOGNIZED'
}
/**
    * @export
    * @enum {string}
    */
export enum FarePrefFareTypesEnum {
    UnknownType = 'UNKNOWN_TYPE',
    Cheapest = 'CHEAPEST',
    FullyRefundable = 'FULLY_REFUNDABLE',
    Editable = 'EDITABLE',
    Changeable = 'CHANGEABLE',
    Refundable = 'REFUNDABLE',
    Unrecognized = 'UNRECOGNIZED'
}



