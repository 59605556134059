/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Type of passenger
 * @export
 * @enum {string}
 */

export const PassengerType = {
    UnknownPassengerType: 'UNKNOWN_PASSENGER_TYPE',
    Adult: 'ADULT',
    Child: 'CHILD',
    Infant: 'INFANT',
    InfantOnLap: 'INFANT_ON_LAP',
    Youth: 'YOUTH',
    Senior: 'SENIOR',
    Teen: 'TEEN'
} as const;

export type PassengerType = typeof PassengerType[keyof typeof PassengerType];



