import type { MoneyUtil } from './Money';
import { FareTypeEnum } from '../types/api/v1/obt/air/air_search_response';

export const getIsFareNegotiated = (fare: MoneyUtil, fareType: FareTypeEnum): boolean => {
  if (fare.isNegative()) {
    return false;
  }

  const isCorporateRate = fareType === FareTypeEnum.CORPORATE;
  const isPrivate = fareType === FareTypeEnum.PRIVATE;

  return isCorporateRate || isPrivate;
};

export const getIsFareCorporateNegotiated = (fare: MoneyUtil, fareType: FareTypeEnum): boolean => {
  if (fare.isNegative()) {
    return false;
  }

  return fareType === FareTypeEnum.CORPORATE;
};

export const getIsNegotiatedSavingsEligible = (negotiatedSavings: MoneyUtil): boolean =>
  negotiatedSavings.getAmount() >= 1;
