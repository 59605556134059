var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const InfoFilled = (_a) => {
    var { primaryColor, secondaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 10 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { d: "M4.99979 0C2.23873 0 0 2.23873 0 4.99979C0 7.76085 2.23873 10 4.99979 10C7.76085 10 10 7.76085 10 4.99979C10 2.23873 7.76085 0 4.99979 0ZM6.04063 7.74899C5.78328 7.85058 5.57841 7.92762 5.42476 7.98095C5.27153 8.03429 5.09333 8.06095 4.89058 8.06095C4.57905 8.06095 4.33651 7.98476 4.16381 7.8328C3.99111 7.68085 3.90518 7.48825 3.90518 7.25418C3.90518 7.16317 3.91153 7.07005 3.92423 6.97524C3.93735 6.88042 3.9581 6.77376 3.98645 6.65397L4.30857 5.51619C4.33693 5.40698 4.36148 5.30328 4.38095 5.20677C4.40042 5.10942 4.40974 5.02011 4.40974 4.93884C4.40974 4.79407 4.37968 4.69249 4.32 4.63534C4.25947 4.5782 4.14561 4.55026 3.97587 4.55026C3.89291 4.55026 3.80741 4.56254 3.71979 4.58836C3.63302 4.61503 3.55767 4.63915 3.49587 4.66286L3.58095 4.31238C3.79175 4.22645 3.99365 4.1528 4.18624 4.09185C4.37884 4.03005 4.56085 3.99958 4.73228 3.99958C5.04169 3.99958 5.28042 4.07492 5.44847 4.22392C5.61566 4.37333 5.69989 4.56762 5.69989 4.80635C5.69989 4.85587 5.69397 4.94307 5.68254 5.06751C5.67111 5.19238 5.64952 5.30624 5.6182 5.41079L5.29778 6.54519C5.27153 6.63619 5.24825 6.74032 5.22709 6.85672C5.20635 6.97312 5.19619 7.06201 5.19619 7.12169C5.19619 7.27238 5.22963 7.37524 5.29735 7.42984C5.36423 7.48444 5.48148 7.51196 5.64741 7.51196C5.72571 7.51196 5.81333 7.49799 5.91238 7.4709C6.01058 7.44381 6.08169 7.41968 6.12656 7.39894L6.04063 7.74899ZM5.98392 3.14455C5.8345 3.28339 5.6546 3.3528 5.44423 3.3528C5.23429 3.3528 5.05312 3.28339 4.90243 3.14455C4.75259 3.00571 4.67683 2.83683 4.67683 2.63958C4.67683 2.44275 4.75302 2.27344 4.90243 2.13333C5.05312 1.9928 5.23429 1.92296 5.44423 1.92296C5.6546 1.92296 5.83492 1.9928 5.98392 2.13333C6.13333 2.27344 6.20825 2.44275 6.20825 2.63958C6.20825 2.83725 6.13333 3.00571 5.98392 3.14455Z", fill: themeEnrichedPrimaryColor })));
};
InfoFilled.defaultProps = defaultIconProps;
export default InfoFilled;
