// Generated using yarn generate command
import MuiSwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';
import { useTranslation } from 'react-i18next';
import { RemoveMuiProps } from '../types';
import { SwipeableDrawerHeader, SwipeableDrawerHeaderConfig } from './SwipeableDrawerHeader';
import {
  children_container,
  drawer_close_button,
  drawer_close_button_container,
  padding_over_footer,
  rounded_drawer,
  swipeable_drawer,
} from './SwipeableDrawer.styles';
import { SwipeableDrawerFooter, SwipeableDrawerFooterConfig } from './SwipeableDrawerFooter';
import { IconButton } from '../IconButton';
import { clsx } from '../utils';

export type SwipeableDrawerActionConfig = {
  text: string;
  onClick: VoidFunction;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

export type TSwipeableDrawerProps = RemoveMuiProps<SwipeableDrawerProps, 'onClose'> & {
  onClose: () => void;
  headerConfig?: Omit<SwipeableDrawerHeaderConfig, 'showCloseButton'>;
  footerConfig?: SwipeableDrawerFooterConfig;
  kind?: 'square' | 'rounded';
  showCloseButton?: boolean;
};

export const SwipeableDrawer: React.FC<TSwipeableDrawerProps> = ({
  headerConfig,
  footerConfig,
  kind = 'square',
  showCloseButton,
  onClose,
  ...props
}) => {
  const { t: tt } = useTranslation('WEB');

  return (
    <MuiSwipeableDrawer css={[swipeable_drawer, kind === 'rounded' && rounded_drawer]} {...props} onClose={onClose}>
      {showCloseButton && (
        <div css={drawer_close_button_container}>
          <IconButton
            icon="CloseCross"
            size="small"
            tooltip={tt('Close')}
            onClick={onClose}
            css={drawer_close_button}
          />
        </div>
      )}
      {!!headerConfig && <SwipeableDrawerHeader {...headerConfig} showCloseButton={showCloseButton} />}
      <div
        css={[children_container, !!footerConfig && padding_over_footer]}
        className={clsx('BlocksDrawerChildren-container')}
      >
        {props.children}
      </div>
      {!!footerConfig && <SwipeableDrawerFooter {...footerConfig} />}
    </MuiSwipeableDrawer>
  );
};
