import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Download = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 14", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM7 12C9.76142 12 12 9.76142 12 7C12 4.23858 9.76142 2 7 2C4.23858 2 2 4.23858 2 7C2 9.76142 4.23858 12 7 12Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M10 7L5.5 9.59808L5.5 4.40192L10 7Z", fill: themeEnrichedPrimaryColor })));
};
Download.defaultProps = defaultIconProps;
export default Download;
