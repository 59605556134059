/**
 * READ ONLY FILE
 * These are base typography constants provided by UX
 * All values for typography should refer these variables.
 * Do not add or delete entries in this file without UX authorization.
 *
 */

/**
 * The "rem" values in Figma files can be ignored, we can develop just looking at pixel values.
 * Our responsive sizing system maps the rem values 1:1 with pixel values when viewed at 1440px screen width.
 *
 * For good DX, we have named the values that have 1:1 mapping with pixel values at 1440px screen size.
 * (for ex. at 1440px, 1rem = 16px so remFontSize.s48 equals 3rem. 3 * 16 = 48)
 *
 * This makes it very easy for developers to look at Figma's pixel values and automatically apply the right
 * rem units to their code without any manual calculations, avoiding any confusion.
 *
 * What does this mean for you?
 * If you see "28px" font size in Figma, just use it as `font-size: ${remFontSize.s28};` in your css
 * and forget about units & responsiveness. We got it covered ;)
 *
 */

/**
 * Manually
 */
export const remFontSize = Object.freeze({
  /**
   * 0.75rem
   */
  s12: '0.75rem',
  /**
   * 0.875rem
   */
  s14: '0.875rem',
  /**
   * 1rem
   */
  s16: '1rem',
  /**
   * 1.125rem
   */
  s18: '1.125rem',
  /**
   * 1.25rem
   */
  s20: '1.25rem',
  /**
   * 1.5rem
   */
  s24: '1.5rem',
  /**
   * 1.75rem
   */
  s28: '1.75rem',
  /**
   * 2rem
   */
  s32: '2rem',
  /**
   * 3rem
   */
  s48: '3rem',
});

export const remLineHeight = Object.freeze({
  /**
   * 1rem
   */
  h16: '1rem',
  /**
   * 1.25rem
   */
  h20: '1.25rem',
  /**
   * 1.375rem
   */
  h22: '1.375rem',
  /**
   * 1.5rem
   */
  h24: '1.5rem',
  /**
   * 1.75rem
   */
  h28: '1.75rem',
  /**
   * 1.875rem
   */
  h30: '1.875rem',
  /**
   * 2.25rem
   */
  h36: '2.25rem',
  /**
   * 2.5rem
   */
  h40: '2.5rem',
  /**
   * 3.75rem
   */
  h60: '3.75rem',
});

export const fontWeight = Object.freeze({
  w400: 400,
  w500: 500,
  w600: 600,
  w700: 700,
});

export const paraSpacingRem = '1rem' as const;

export const listSpacing = Object.freeze({
  s4: '0.25rem',
  s8: '0.5rem',
});

export const letterSpacingEm = Object.freeze({
  s1: '-0.01em',
  s2: '-0.02em',
  s3: '-0.03em',
});

export const fontFamily = [
  /** Primary Font */
  'Inter',
  /** Fallback Fonts for Compatibility */
  'Roboto',
  'sans-serif',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'Ubuntu',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
] as const;
