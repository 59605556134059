var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Hotel = (_a) => {
    var { primaryColor, secondaryColor, title } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "title"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 40 40", version: "1.1" }, restProps),
        title && React.createElement("title", { "data-testid": "icon-title" }, title),
        React.createElement("g", { id: "Hotel", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M5.27274819,7.5 L5.27274819,22.5 L18.3635876,22.5 L18.363,22.494 L38,22.494 L38,32.5 L34.7272518,32.5 L34.7272518,27.5 L5.27274819,27.5 L5.27274819,32.5 L2,32.5 L2,7.5 L5.27274819,7.5 Z M11.8181679,10.8333594 C14.5263714,10.8333594 16.7272518,13.075 16.7272518,15.8333594 C16.7272518,18.5917187 14.5263714,20.8333594 11.8181679,20.8333594 C9.10996433,20.8333594 6.90908394,18.5917187 6.90908394,15.8333594 C6.90908394,13.075 9.10996433,10.8333594 11.8181679,10.8333594 Z M31.4545036,10.8333594 C34.9939433,10.8333594 37.8750458,13.6911178 37.9960436,17.2658695 L38,17.5 L38,20.405 L18.363,20.405 L18.3635876,10.8333594 L31.4545036,10.8333594 Z", id: "Combined-Shape", fill: themeEnrichedPrimaryColor }))));
};
Hotel.defaultProps = Object.assign(Object.assign({}, defaultIconProps), { title: 'Hotel' });
export default Hotel;
