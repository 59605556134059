import Config from 'obt-common/utils/Config';
import { init, FullStory } from '@fullstory/browser';

/**
 * Installs FullStory
 * @param isEmbed
 * @returns
 */
export function installFullStory(isEmbed: boolean): Promise<void> {
  const orgId = Config.VITE_FULL_STORY_ORG_KEY ?? '';
  return new Promise((resolve) => {
    // No orgKey, skip FullStory Initialization
    if (!orgId) {
      resolve();
      return;
    }

    const devMode = Config.VITE_ENVIRONMENT === 'development';

    // This is required to enable FullStory to work in iFrames (like embed App)
    // eslint-disable-next-line no-underscore-dangle
    window._fs_is_outer_script = true;

    // initialize FullStory
    init({
      orgId,
      devMode,

      // For Embed Apps, we want to ensure
      // 1. Sessions are captured post login, if replays are allowed
      // 2. Even if there is a Full-Story on the parent site, we want our app to be de-coupled from that
      startCaptureManually: isEmbed,
      recordOnlyThisIFrame: isEmbed,
    });

    FullStory('observe', {
      type: 'start',
      callback: () => resolve(),
    });
  });
}
