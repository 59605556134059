var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Check = (_a) => {
    var { primaryColor, secondaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 19 14", fill: themeEnrichedPrimaryColor, xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { d: "M16.2234 1.19523C16.831 0.578092 17.8239 0.570395 18.4411 1.17804C19.0257 1.75371 19.0634 2.67513 18.5491 3.29527L18.4583 3.39572L8.6981 13.308C7.95037 14.0443 6.76719 14.0674 5.99212 13.3838L5.88904 13.2863L0.541568 7.85524C-0.0660794 7.2381 -0.0583826 6.24521 0.558759 5.63756C1.14342 5.06189 2.06532 5.0385 2.6774 5.56237L2.77644 5.65475L7.30367 10.2524L16.2234 1.19523Z" })));
};
Check.defaultProps = defaultIconProps;
export default Check;
