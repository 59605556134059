/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TierEnum as TierV1 } from '../../../v1/obt/common/user_org_id';

/**
 * Tier of User
 * @export
 * @enum {string}
 */

export const Tier = {
  Basic: 'BASIC',
  Seat1A: 'SEAT1A',
} as const;

export type Tier = (typeof Tier)[keyof typeof Tier];

export const tierV1ToV2Mapper: Record<TierV1, Tier> = {
  [TierV1.BASIC]: Tier.Basic,
  [TierV1.SEAT1A]: Tier.Seat1A,
  [TierV1.UNRECOGNIZED]: Tier.Basic,
};
