import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Safety = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 20 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M10.8659 13.801C10.6282 14.0339 10.3372 14.1504 9.99286 14.1504C9.64853 14.1504 9.35989 14.0315 9.12695 13.7938C8.89402 13.5561 8.77756 13.2651 8.77756 12.9207C8.77756 12.5764 8.89641 12.2878 9.13411 12.0548C9.37181 11.8219 9.66284 11.7054 10.0072 11.7054C10.3515 11.7054 10.6401 11.8243 10.8731 12.062C11.106 12.2997 11.2225 12.5907 11.2225 12.9351C11.2225 13.2794 11.1036 13.568 10.8659 13.801Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M9.08318 9.87176C9.08318 10.3781 9.49366 10.7886 10 10.7886C10.5064 10.7886 10.9169 10.3781 10.9169 9.87176V6.81562C10.9169 6.30926 10.5064 5.89877 10 5.89877C9.49366 5.89877 9.08318 6.30926 9.08318 6.81562V9.87176Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.23654 17.2065C0.811229 17.2065 -0.0691485 15.6516 0.66417 14.4294L8.42765 1.49027C9.13986 0.303252 10.8602 0.303252 11.5724 1.49027L19.3359 14.4294C20.0692 15.6516 19.1888 17.2065 17.7635 17.2065H2.23654ZM17.7677 15.3728L10 2.43515L2.23232 15.3728H17.7677Z", fill: themeEnrichedPrimaryColor })));
};
Safety.defaultProps = defaultIconProps;
export default Safety;
