import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const PaymentMethod = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 12", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.75 0.375H13.25C14.4926 0.375 15.5 1.38241 15.5 2.625V4.73433V9.318C15.5 10.5606 14.4926 11.568 13.25 11.568H2.75C1.50741 11.568 0.5 10.5606 0.5 9.318V4.73433V2.625C0.5 1.38241 1.50741 0.375 2.75 0.375ZM14 2.625V3.98433H2V2.625C2 2.21084 2.33584 1.875 2.75 1.875H13.25C13.6642 1.875 14 2.21084 14 2.625ZM14 5.48433H2V9.318C2 9.73216 2.33584 10.068 2.75 10.068H13.25C13.6642 10.068 14 9.73216 14 9.318V5.48433ZM7.41305 7.831C7.41305 7.62389 7.24516 7.456 7.03805 7.456H4.0058L3.96207 7.45852C3.77557 7.48018 3.6308 7.63868 3.6308 7.831C3.6308 8.0381 3.7987 8.206 4.0058 8.206H7.03805L7.08179 8.20347C7.26829 8.18181 7.41305 8.02331 7.41305 7.831Z", fill: themeEnrichedPrimaryColor })));
};
PaymentMethod.defaultProps = defaultIconProps;
export default PaymentMethod;
