import React from 'react';
import { defaultIconProps } from './common';
const TravelPolicy = ({ width, height }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 0 70 70", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { opacity: "0.75", cx: "35", cy: "35", r: "35", fill: "#D8E1E7" }),
    React.createElement("mask", { id: "mask0", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "70", height: "70" },
        React.createElement("circle", { cx: "35", cy: "35", r: "35", fill: "#D8E1E7" })),
    React.createElement("g", { mask: "url(#mask0)" },
        React.createElement("path", { d: "M11.6238 44.2999L9.76337 51.8956H61.2366L59.3761 44.2999H11.6238Z", fill: "#6082A8" }),
        React.createElement("rect", { x: "14.5994", y: "18.7538", width: "44.8372", height: "56.6126", transform: "rotate(2.14914 14.5994 18.7538)", fill: "#BFC8CE" }),
        React.createElement("rect", { x: "11.8513", y: "20.872", width: "45.1802", height: "56.2242", transform: "rotate(-1.29025 11.8513 20.872)", fill: "white" }),
        React.createElement("path", { d: "M18.055 28.7663L50.175 28.0429", stroke: "#BFC8CE" }),
        React.createElement("path", { d: "M48.0087 20.1028V20.1028C48.039 18.9744 48.9899 18.0909 50.1174 18.1436L51.1903 18.1937C52.8923 18.2731 54.2036 19.7241 54.1111 21.4254L53.8357 26.4892C53.7626 27.8335 52.6202 28.868 51.2753 28.8079V28.8079C49.9 28.7465 48.8442 27.5661 48.9359 26.1925L49.194 22.3251C49.2462 21.5437 49.9149 20.9482 50.6971 20.9867V20.9867C51.5078 21.0265 52.1243 21.73 52.0574 22.539L51.8987 24.4584", 
            // eslint-disable-next-line no-restricted-syntax
            stroke: "#D33B47", strokeWidth: "1.1" }),
        React.createElement("path", { d: "M18.1451 32.7812L50.2651 32.0578", stroke: "#BFC8CE" }),
        React.createElement("path", { d: "M18.2355 36.7963L50.3555 36.0729", stroke: "#BFC8CE" }),
        React.createElement("path", { d: "M18.414 44.7128L50.534 43.9894", stroke: "#BFC8CE" }),
        React.createElement("path", { d: "M18.5005 48.5623L50.6205 47.8388", stroke: "#BFC8CE" }),
        React.createElement("rect", { x: "9.76337", y: "51.8957", width: "51.4732", height: "54.6247", fill: "#88B9DA" }))));
TravelPolicy.defaultProps = defaultIconProps;
export default TravelPolicy;
