import { extractOrgIdFromUrl } from './extractOrgIdFromUrl';

// 1. Memoize the orgId from the URL on js execution
let initialOrgIdFromUrl: string | null = extractOrgIdFromUrl();

export function saveInMemoryCacheOrgId(orgId: string): void {
  initialOrgIdFromUrl = orgId;
}

export function removeFromMemoryCacheOrgId(): void {
  initialOrgIdFromUrl = null;
}

export function getFromMemoryCacheOrgId(): string | null {
  return initialOrgIdFromUrl;
}
