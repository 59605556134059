import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
import SpotnanaTheme from '../utils/themes';
const PriorityHigh = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 25 23", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.5902 12.9272L12.0003 9.26325L7.41038 12.9272L6.00042 11.7992L12.0003 6.99925L18.0001 11.7992L16.5902 12.9272Z", fill: themeEnrichedPrimaryColor })));
};
PriorityHigh.defaultProps = Object.assign(Object.assign({}, defaultIconProps), { primaryColor: SpotnanaTheme.colors.text.secondary });
export default PriorityHigh;
