import useUrlStateSelector from './useUrlStateSelector';

export default function useQueryParamSelector<TUrlState extends object, TKey extends keyof TUrlState = keyof TUrlState>(
  selector: [TKey] | TKey,
): TUrlState[TKey];

export default function useQueryParamSelector<
  TUrlState extends object,
  TKey1 extends keyof TUrlState = keyof TUrlState,
  TKey2 extends keyof TUrlState[TKey1] = keyof TUrlState[TKey1],
>(selector: [TKey1, TKey2]): TUrlState[TKey1][TKey2];

export default function useQueryParamSelector<
  TUrlState extends object,
  TKey1 extends keyof TUrlState = keyof TUrlState,
  TKey2 extends keyof TUrlState[TKey1] = keyof TUrlState[TKey1],
  TKey3 extends keyof TUrlState[TKey1][TKey2] = keyof TUrlState[TKey1][TKey2],
>(selector: [TKey1, TKey2, TKey3]): TUrlState[TKey1][TKey2][TKey3];

export default function useQueryParamSelector<
  TUrlState extends object,
  TKey1 extends keyof TUrlState = keyof TUrlState,
  TKey2 extends keyof TUrlState[TKey1] = keyof TUrlState[TKey1],
  TKey3 extends keyof TUrlState[TKey1][TKey2] = keyof TUrlState[TKey1][TKey2],
  TKey4 extends keyof TUrlState[TKey1][TKey2][TKey3] = keyof TUrlState[TKey1][TKey2][TKey3],
>(selector: [TKey1, TKey2, TKey3, TKey4]): TUrlState[TKey1][TKey2][TKey3][TKey4];

export default function useQueryParamSelector<TUrlState extends object, TSelectorResult>(
  selector: (state: TUrlState) => TSelectorResult,
): TSelectorResult;

/**
 * Typesafe hook to select a value from the URL query params.
 *
 * Example:
 * ```tsx
 * type UrlState = { myKey: string, myObj: {myNestedKey: number } }
 *
 * useQueryParamSelector<UrlState,'myKey'>('myKey')   // string
 * useQueryParamSelector<UrlState,'myKey'>(['myKey']) // string
 * useQueryParamSelector<UrlState,'myObj','myNestedKey'>(['myObj','myNestedKey']) // number
 *
 * useQueryParamSelector<UrlState,'myObj.myNestedKey'>('myObj.myNestedKey') // ❌ Error
 * ```
 *
 * @param selector - Either a string or an array of strings representing the path to the value in the URL query params.
 */
export default function useQueryParamSelector(selector: any): any {
  return useUrlStateSelector(selector);
}
