import type { EntityAnswer, Question, QuestionOptionListOption } from './api/v1/obt/policy/user_defined_entity';

export enum CustomFieldQuestionType {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  MULTI_SELECT_WITH_PERCENTAGE = 'MULTI_SELECT_WITH_PERCENTAGE',
}

export interface CustomFieldsOptionProps {
  label: string;
  description: string;
  isSelected: boolean;
  toggleSelection: () => void;
  percentage?: string;
  handlePercentageChange?: (input: string) => void;
  questionType?: CustomFieldQuestionType;
}

export interface CustomFieldsAdhocOptionProps<FormMethods>
  extends Omit<CustomFieldsOptionProps, 'label' | 'description'> {
  optionFormMethods: FormMethods;
}

export enum CustomFieldValidationError {
  INVALID_TOTAL_PERCENTAGE = 'INVALID_TOTAL_PERCENTAGE',
  MISSING_PERCENTAGE = 'MISSING_PERCENTAGE',
  OUT_OF_BOUNDS_PERCENTAGE = 'OUT_OF_BOUNDS_PERCENTAGE',
}

export interface UseCustomFieldInputReturnType {
  data: CustomFieldsOptionProps[];
  total: number;
  validate: () => CustomFieldsInputValidationResult;
}

export type SelectedOptionsInfo = {
  [displayCode: string]: { value: string | boolean; description: string };
};

export type CustomFieldsEntityAnswerType = {
  [entityId: string]: EntityAnswer;
};

export type CustomFieldsErrorType = {
  [entityId: string]: boolean;
};

export type CustomFieldsInputValidationResult =
  | {
      isValid: true;
    }
  | {
      isValid: false;
      errors: CustomFieldValidationError[];
    };

export interface UseCustomFieldInputParameters {
  options: Omit<QuestionOptionListOption, 'optionType'>[];
  selectedOptions: SelectedOptionsInfo;
  onUpdateSelectedOptions: (selectedOptions: SelectedOptionsInfo) => void;
  questionType: CustomFieldQuestionType;
}

export interface UseCustomFieldManualInputParameters {
  selectedOptions: SelectedOptionsInfo;
  onUpdateSelectedOptions: (selectedOptions: SelectedOptionsInfo) => void;
  questionType: CustomFieldQuestionType;
}

export interface getQuestionTypeProps {
  question?: Question;
  preloadSelectedOptions?: EntityAnswer;
}
