var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
const ClickAwayListener = (_a) => {
    var { children, ignoreBlurEventOnPreventedClickOutside = false, onClickAway } = _a, restProps = __rest(_a, ["children", "ignoreBlurEventOnPreventedClickOutside", "onClickAway"]);
    useEffect(() => {
        var _a;
        const handleClickOutside = (event) => {
            const isNotClickedInside = event.target && !event.target.closest('#clickAwayListener-wrapper');
            const isNotPrevented = !ignoreBlurEventOnPreventedClickOutside || !event.defaultPrevented;
            if (isNotClickedInside && isNotPrevented) {
                onClickAway(event);
            }
        };
        const root = (_a = document.getElementById('root')) !== null && _a !== void 0 ? _a : document;
        root === null || root === void 0 ? void 0 : root.addEventListener('click', handleClickOutside);
        return () => {
            root === null || root === void 0 ? void 0 : root.removeEventListener('click', handleClickOutside);
        };
    }, [onClickAway, ignoreBlurEventOnPreventedClickOutside]);
    return (React.createElement("div", Object.assign({ id: "clickAwayListener-wrapper" }, restProps), children));
};
/**
 * @deprecated
 */
export default ClickAwayListener;
