var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Calendar = (_a) => {
    var { primaryColor, width, height, secondaryColor } = _a, restProps = __rest(_a, ["primaryColor", "width", "height", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 24 24", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M14.878 4.875c.396 0 .72.305.749.693l.002.056.002 2.824c0 .415-.335.75-.75.751-.395 0-.719-.305-.748-.693l-.002-.056v-.705H9.869v.705c0 .414-.337.75-.75.75-.396-.001-.72-.307-.748-.696l-.002-.056v-.703H6.375c-.192 0-.35.146-.372.332L6 8.121v9.073c0 .193.145.351.331.373l.044.002h11.25c.192 0 .35-.144.372-.33l.003-.045V8.121c0-.193-.145-.351-.331-.373l-.044-.002h-.631c-.415 0-.75-.336-.75-.75 0-.396.305-.72.694-.748l.056-.002h.631c1.008 0 1.83.796 1.873 1.793l.002.082v9.073c0 1.009-.796 1.831-1.794 1.874l-.081.001H6.375c-1.008 0-1.83-.796-1.873-1.793l-.002-.082V8.121c0-1.009.796-1.83 1.794-1.873l.081-.002 1.995-.001.001-.62c0-.415.336-.75.75-.75.396 0 .72.306.748.695l.002.056-.001.619h4.259v-.62c0-.414.335-.75.75-.75z" })));
};
Calendar.defaultProps = defaultIconProps;
export default Calendar;
