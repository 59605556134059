import Typography from '@spotnana/pixel-react/dist/Typography';
import {
  CompanyBasicInfo,
  getProfileDisplayText,
  ITraveler,
  RBAC,
  TravelerBasicInfo,
  travelerPersonalTranslationKeys,
  useFetchCompanyBasicInfo,
  useHasUserAccess,
  useJSONFeatureFlag,
  useLoggedInUserBasicInfo,
  usePartnerFeatures,
  userRolesByFeature,
  createUserNameFromFullName,
  useFeatureFlag,
} from 'obt-common';
import { TierEnum } from 'obt-common/types/api/v1/obt/common/user_org_id';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteProps } from 'src/v2-components/shared/Autocomplete';
import { flex } from '@spotnana/blocks/src/utils/styling';
import { AutocompleteFilter } from 'obt-common/types/api/v2/obt/model/autocomplete-filter';
import { AutocompleteExpandDirections } from '../../../constants/autocomplete';
import SelectTraveler from '../../../v2-components/shared/selectTraveler';
import CurrentTraveler from '../CurrentTraveler';

export interface ITravelerSelectorProps {
  dataTestId?: string;
  disabled?: boolean;
  error?: string;
  loading: boolean;
  selectedProfile?: ITraveler;
  addMe?: () => void;
  expandDirection?: AutocompleteExpandDirections;
  relativePositioning?: boolean;
  displayCurrentTraveler?: boolean;
  onSelect: (selectionValue: TravelerBasicInfo | null) => void;
  onClear?: VoidFunction;
  openRegisterModal?: () => void;
  showChangeTravelerText?: boolean;
  showRegisterTravelerOnFooter?: boolean;
  outerContainerStyles?: AutocompleteProps<TravelerBasicInfo>['outerContainerStyles'];
  clickAwayListernerStyles?: CSSProperties;
  inputContainerWidth?: string;
  className?: string;
  showCloseIcon?: boolean;
  searchFilters?: AutocompleteFilter[];
  subtextRenderer?: (
    companyDataLoading: boolean,
    companyData?: CompanyBasicInfo,
    travelerEmail?: string,
  ) => JSX.Element;
  showInModal?: boolean;
  additionalTravelerCount?: number;
}

const TravelerSelector = ({
  dataTestId,
  disabled,
  error,
  loading,
  selectedProfile,
  addMe,
  displayCurrentTraveler = true,
  expandDirection,
  onSelect,
  onClear,
  openRegisterModal,
  showChangeTravelerText,
  showRegisterTravelerOnFooter,
  outerContainerStyles,
  clickAwayListernerStyles,
  inputContainerWidth,
  className,
  showCloseIcon = true,
  subtextRenderer,
  searchFilters,
  showInModal = false,
  additionalTravelerCount,
}: ITravelerSelectorProps): JSX.Element => {
  const { t } = useTranslation('PROFILE');
  const shouldUsePreferredName = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');
  const { CHANGE_TRAVELER } = travelerPersonalTranslationKeys;

  const travelerName = selectedProfile?.user?.name;
  const travelerEmail = selectedProfile?.userBusinessInfo?.email ?? '';
  const travelerPicture = selectedProfile?.user?.profilePicture;
  const displayName = shouldUsePreferredName
    ? createUserNameFromFullName(travelerName, travelerEmail)
    : getProfileDisplayText(travelerName, travelerEmail, { usePreferredName: true });

  const [showTravelerSearchBox, setShowTravelerSearchBox] = useState<boolean>(false);
  const partnerIndicatorAccess = useHasUserAccess(userRolesByFeature.partnerIndicator);
  const { data: companyData, isLoading: companyDataLoading } = useFetchCompanyBasicInfo(
    selectedProfile?.userOrgId?.organizationId?.id,
    partnerIndicatorAccess,
    partnerIndicatorAccess ? 'partnerIndicator' : 'partnerIndicatorRoleBased',
  );

  const personalBookingVariables = useJSONFeatureFlag('FE_COMMON_PERSONAL_BOOKING');

  const { existingUser } = useLoggedInUserBasicInfo();

  const { features } = usePartnerFeatures();
  const { travelerSelection: isTravelerSelectionEnabled } = features;
  if (!existingUser) {
    return <></>;
  }

  const { roleInfos = [] } = existingUser;

  const handleChangeTraveler = (): void => {
    setTimeout(() => {
      setShowTravelerSearchBox(true);
      onClear?.();
    }, 0);
  };

  const handleAddMeClick = async (): Promise<void> => {
    if (addMe) {
      addMe();
    }
    setShowTravelerSearchBox(false);
  };

  return (
    <div css={flex.init} className={className}>
      {!showTravelerSearchBox && displayCurrentTraveler && (
        <CurrentTraveler
          loading={loading}
          currentTraveler={displayName}
          profilePicture={travelerPicture}
          companyLoading={companyDataLoading}
          companyLogo={companyData?.companyLogo}
          companyName={companyData?.name}
          onClear={handleChangeTraveler}
          disabled={disabled || (!personalBookingVariables?.enable_personal_relative && roleInfos.length === 0)}
          isSeat1a={selectedProfile?.tier === TierEnum.SEAT1A}
          showCloseIcon={showCloseIcon}
          subtextRenderer={
            subtextRenderer ? () => subtextRenderer(companyDataLoading, companyData, travelerEmail) : undefined
          }
          additionalTravelerCount={additionalTravelerCount}
        />
      )}
      {(showTravelerSearchBox || !displayCurrentTraveler) && (
        <SelectTraveler
          dataTestId={dataTestId}
          onSelect={onSelect}
          onClose={(): void => {
            setShowTravelerSearchBox(false);
          }}
          onAddMe={addMe && handleAddMeClick}
          expandDirection={expandDirection}
          error={error}
          openRegisterModal={openRegisterModal}
          showRegisterTravelerOnFooter={showRegisterTravelerOnFooter}
          outerContainerStyles={outerContainerStyles}
          clickAwayListernerStyles={clickAwayListernerStyles}
          inputContainerWidth={inputContainerWidth}
          searchFilters={searchFilters}
          showAutocompleteModal={showInModal && (showTravelerSearchBox || !displayCurrentTraveler)}
        />
      )}
      {isTravelerSelectionEnabled && showChangeTravelerText && !showTravelerSearchBox && (
        <RBAC allowedRoles={[...userRolesByFeature.adminPages]}>
          <Typography
            color="primary"
            fontWeight="semiBold"
            my="auto"
            ml="16px"
            style={{ cursor: 'pointer' }}
            onClick={handleChangeTraveler}
          >
            {t(CHANGE_TRAVELER)}
          </Typography>
        </RBAC>
      )}
    </div>
  );
};

export default TravelerSelector;
