import { css } from '@emotion/react';

import { flex, themed } from '../../utils';

export const autocomplete_item_wrapper = themed(
  ({ palette: { text, bg, border, surface } }) => css`
    .BlocksAutocomplete-SelectItem {
      display: flex;
      cursor: pointer;
      border-radius: ${border.radiusRegular};
      align-items: center;
      padding: 0.75rem 1rem;
      outline: none !important;

      &[aria-disabled='true'] {
        opacity: 0.5;
      }

      &[data-active-item] {
        background-color: ${surface.background};
      }

      &[data-focus-visible] {
        outline: solid 2px ${border.keyboardFocus} !important;
        outline-offset: -2px;
      }

      .ItemPrefixGraphic-icon {
        align-self: flex-start;
      }

      &.SelectionSingle:not(.WithRadio).BlocksSelect-checked {
        background-color: ${bg.infoSubtle};
        color: ${text.focus};
      }

      .AutocompleteItemContent {
        pointer-events: none;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 12px;
      }

      .AutocompleteItemContent-text {
        width: 100%;
        ${flex.init}
        ${flex.column}
        white-space:nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .AutocompleteItemContent-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .AutocompleteItemContent-prefix {
        ${flex.init}
        ${flex.align.center}
        gap:12px;
      }
      .AutocompleteItemContent-suffix {
        ${flex.init}
        ${flex.align.center}
        gap:12px;
      }
    }
  `,
);
