/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of seat of the rail.
 * @export
 * @enum {string}
 */

export const SeatPrefType = {
  SleeperBed: 'SLEEPER_BED',
  Normal: 'NORMAL',
  TableSeat: 'TABLE_SEAT',
} as const;

export type SeatPrefType = (typeof SeatPrefType)[keyof typeof SeatPrefType];
