import { CardOwnershipLabelEnum } from '../../types/api/v2/obt/model/card';
import { RewardsProgramType } from '../../types/api/v2/obt/model/rewards-program-type';
import type { RewardsProgramMetadata } from '../../types/api/v2/obt/model/rewards-program-metadata';
import type { AirlineProgramMetadata, Card, VendorProgramPaymentDescriptor } from '../../types';
import { CreditCardAccessType, CardCompany } from '../../types';
import type { BrexBudgetMetadata } from '../../types/api/v2/obt/model/brex-budget-metadata';
import type { CardMetadata } from '../../types/api/v2/obt/model/card-metadata';
import type { VirtualCardMetadata } from '../../types/api/v2/obt/model/virtual-card-metadata';
import { getCarVendorLogo, getFullAssetPath, getPaymentCardLogo, maskCardNumber } from '../common';
import { getExpiryDate } from './getExpiryDate';

const UATP_CARD_LOGO = getFullAssetPath('/assets/images/card-companies/uatp', 'png');

export interface PaymentSourceMetadata {
  cardTitle: string;
  cardLogo: string;
  cardCompany: string;
  maskedCardNumber: string;
  cardExpireDate: string;
  paymentCategory?: PaymentCategoryEnum;
  budgetBalance?: string;
  cardType?: string;
}

export enum PaymentCategoryEnum {
  PERSONAL_CARD = 'PERSONAL_CARD',
  CENTRAL_CARD = 'CENTRAL_CARD',
  REWARDS_CARD = 'REWARDS_CARD',
  VIRTUAL_CARD = 'VIRTUAL_CARD',
  DIRECT_BILLING = 'DIRECT_BILLING',
}

export const extractCardMetadata = (metadata: CardMetadata, accesType: CreditCardAccessType): PaymentSourceMetadata => {
  const { number, label, name, company = CardCompany.None, ownershipLabel } = metadata.card;
  const cardNumber = number || '';
  const cardTitle = label || name || '';
  const cardLogo = getPaymentCardLogo(company);
  const cardExpireDate = getExpiryDate(metadata.card);
  const maskedCardNumber = maskCardNumber(cardNumber);
  const paymentCategory =
    accesType === CreditCardAccessType.Personal ? PaymentCategoryEnum.PERSONAL_CARD : PaymentCategoryEnum.CENTRAL_CARD;
  const cardType = ownershipLabel || '';

  return {
    cardTitle,
    cardCompany: company,
    cardLogo,
    cardExpireDate,
    maskedCardNumber,
    paymentCategory,
    cardType,
  };
};

export const extractVirtualCardMetadata = (metadata: VirtualCardMetadata): PaymentSourceMetadata => {
  const { cardLabel, company } = metadata;
  const cardTitle = cardLabel ?? '';
  const cardCompany = company;
  const cardLogo = getPaymentCardLogo(cardCompany);
  const cardExpireDate = 'XX/XX';
  const maskedCardNumber = maskCardNumber('**** **** **** ****');
  const cardType = CardOwnershipLabelEnum.Central;

  return {
    cardTitle,
    cardCompany,
    cardLogo,
    cardExpireDate,
    maskedCardNumber,
    paymentCategory: PaymentCategoryEnum.VIRTUAL_CARD,
    cardType,
  };
};

export const extractBrexBudgetPaymentMetadata = (metadata: BrexBudgetMetadata): PaymentSourceMetadata => {
  const cardTitle = metadata.budgetName ?? '';
  const cardCompany = CardCompany.Brex;
  const maskedCardNumber = '';
  const cardLogo = getPaymentCardLogo('brex');
  const budgetBalance = metadata.budgetRemainingBalanceFormatted ?? '';
  const cardExpireDate = 'XX/XX';

  return {
    cardTitle,
    cardCompany,
    cardLogo,
    maskedCardNumber,
    budgetBalance,
    cardExpireDate,
  };
};

export const extractRewardsProgramMetadata = (metadata: RewardsProgramMetadata): PaymentSourceMetadata => {
  /**
   * Type can either be BrexPoints or Qantas, if not BrexPoints we return Qantas metadata
   */
  const isBrexPoints = metadata.type === RewardsProgramType.BrexPoints;
  const cardTitle = isBrexPoints ? 'Brex' : 'Qantas';
  const cardCompany = CardCompany.None;
  const maskedCardNumber = '';
  const cardLogo = isBrexPoints ? getPaymentCardLogo('brex') : getPaymentCardLogo('qantas');
  const budgetBalance = metadata.pointsBalance?.amount ? metadata.pointsBalance.amount.toString() : '';
  const cardExpireDate = 'XX/XX';

  return {
    cardTitle,
    cardCompany,
    cardLogo,
    maskedCardNumber,
    budgetBalance,
    cardExpireDate,
  };
};

const getRewardsCardMetadata = (rewardsCard: Card, isUatpCard: boolean): PaymentSourceMetadata => {
  const cardTitle = rewardsCard.label || rewardsCard.name || '';
  const cardCompany = rewardsCard.company?.toString() ?? '';
  const cardLogo = isUatpCard ? UATP_CARD_LOGO : getPaymentCardLogo(cardCompany);
  const maskedCardNumber = maskCardNumber(rewardsCard.number);
  const cardExpireDate = getExpiryDate(rewardsCard);
  const cardType = CardOwnershipLabelEnum.Central;

  return {
    cardTitle,
    cardCompany,
    cardLogo,
    maskedCardNumber,
    cardExpireDate,
    paymentCategory: PaymentCategoryEnum.REWARDS_CARD,
    cardType,
  };
};

export const extractAirlineProgramMetadata = (
  airlineProgramMetadata: AirlineProgramMetadata | undefined,
): PaymentSourceMetadata => {
  let card: Card | undefined;
  const isUatpCard = !!(airlineProgramMetadata && 'uatpMetadata' in airlineProgramMetadata);
  const isUappCard = !!(airlineProgramMetadata && 'uaPassPlusMetadata' in airlineProgramMetadata);
  if (isUappCard) {
    card = airlineProgramMetadata.uaPassPlusMetadata?.uatpInfo?.card;
  }

  if (isUatpCard) {
    card = airlineProgramMetadata.uatpMetadata?.card;
  }

  if (!card) {
    return {
      cardTitle: '',
      cardCompany: '',
      cardExpireDate: '',
      cardLogo: '',
      maskedCardNumber: '',
    };
  }

  return getRewardsCardMetadata(card, isUatpCard);
};

export const extractDirectBillingMetadata = (metadata: VendorProgramPaymentDescriptor): PaymentSourceMetadata => {
  const vendorTitle = metadata.vendorInfo?.vendorName;
  const cardTitle = vendorTitle ?? '';
  const cardCompany = metadata.vendorInfo?.vendorName ?? '';
  const cardLogo = getCarVendorLogo(metadata.vendorInfo?.vendorId || '');
  const cardExpireDate = 'XX/XX';
  let maskedCardNumber = '';

  if (metadata.vendorProgramPaymentMetadata && 'directBilling' in metadata.vendorProgramPaymentMetadata) {
    maskedCardNumber = maskCardNumber(
      metadata.vendorProgramPaymentMetadata.directBilling?.directBillingCode ?? '**** **** **** ****',
      { split: false },
    );
  }

  return {
    cardTitle,
    cardCompany,
    cardLogo,
    maskedCardNumber,
    cardExpireDate,
    paymentCategory: PaymentCategoryEnum.DIRECT_BILLING,
  };
};
