/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { DateModel } from './date-model';

/**
 * National Document Id details.
 * @export
 * @interface NationalDoc
 */
export interface NationalDoc {
  /**
   * Unique id identifying the national document.
   * @type {string}
   * @memberof NationalDoc
   */
  docId: string;
  /**
   * IS0 2 letter country code of the country issuing this id.
   * @type {string}
   * @memberof NationalDoc
   */
  issueCountry: string;
  /**
   *
   * @type {DateModel}
   * @memberof NationalDoc
   */
  issuedDate?: DateModel;
  /**
   *
   * @type {DateModel}
   * @memberof NationalDoc
   */
  expiryDate?: DateModel;
  /**
   *
   * @type {string}
   * @memberof NationalDoc
   */
  type?: NationalDocTypeEnum;
}

export const NationalDocTypeEnum = {
  Dni: 'DNI',
  Nie: 'NIE',
} as const;

export type NationalDocTypeEnum = (typeof NationalDocTypeEnum)[keyof typeof NationalDocTypeEnum];
