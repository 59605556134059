import { useGetEventPreference, useHasUserAccess, userRolesByFeature } from 'obt-common';
import type { RoleInfoType } from 'obt-common/types/api/v1/obt/profile/role/roles_info';

export function usePermissionBasedEventRoles() {
  return userRolesByFeature.eventsPageRolesWithAgentAndAdmin as RoleInfoType[];
}

export function useRoleBasedEventAccess() {
  const hasEventsPageAccess = useHasUserAccess(usePermissionBasedEventRoles());
  return { hasEventsPageAccess };
}

export function useIsEventsFeatureEnabled() {
  const { hasEventsPageAccess } = useRoleBasedEventAccess();

  const { data: eventPreference, isLoading: isEventPrefLoading } = useGetEventPreference();
  const isEventPreferenceEnabled = eventPreference?.eventEnabled;
  return {
    isEventsEnabled: hasEventsPageAccess && isEventPreferenceEnabled,
    isLoading: isEventPrefLoading,
  };
}
