import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Mobile = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 22", fill: themeEnrichedPrimaryColor, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.7818 0.952637H2.9724C2.38633 0.953297 1.82424 1.18706 1.41014 1.6023C0.996077 2.01751 0.763352 2.58066 0.762695 3.16741V19.4552C0.763353 20.0419 0.996076 20.6046 1.41014 21.0199C1.82424 21.4351 2.38633 21.6689 2.9724 21.6695H11.7823C12.3683 21.6689 12.93 21.4351 13.3441 21.0199C13.7582 20.6046 13.9909 20.0415 13.9915 19.4547V3.16699C13.9909 2.58025 13.7582 2.01751 13.3441 1.6023C12.93 1.18706 12.3679 0.953297 11.7818 0.952637ZM2.97282 2.43759L3.82518 2.43759L4.07972 3.89055L4.08058 3.89486C4.1141 4.06293 4.20471 4.21438 4.33723 4.32331C4.46977 4.43226 4.63601 4.49191 4.8077 4.49191H9.94654C10.1182 4.49191 10.2845 4.43226 10.417 4.32331C10.5495 4.21438 10.6401 4.06293 10.6737 3.89486L10.6745 3.89055L10.9291 2.43759H11.7815C11.9739 2.43783 12.1586 2.51458 12.295 2.65135C12.4315 2.78817 12.5084 2.9739 12.5086 3.16783V19.4543C12.5084 19.6483 12.4315 19.834 12.295 19.9708C12.1586 20.1076 11.9739 20.1843 11.7814 20.1846H2.97282C2.78035 20.1843 2.59562 20.1076 2.45921 19.9708C2.32279 19.834 2.24586 19.6482 2.24562 19.4543L2.24562 3.16773C2.24586 2.97383 2.32279 2.78815 2.45921 2.65135C2.5956 2.51458 2.78039 2.43783 2.97282 2.43759ZM9.8659 2.47523L9.72338 3.21821C9.67827 3.45336 9.47255 3.62337 9.23311 3.62337H5.52121C5.28177 3.62337 5.07605 3.45336 5.03094 3.21821L4.88842 2.47523H9.8659Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M5.88595 18.3735H3.68359C3.58623 18.3735 3.49287 18.4123 3.42403 18.4813C3.3552 18.5504 3.31652 18.644 3.31652 18.7416C3.31652 18.8392 3.3552 18.9328 3.42403 19.0019C3.49287 19.0709 3.58623 19.1097 3.68359 19.1097H5.88595C5.9833 19.1097 6.07666 19.0709 6.1455 19.0019C6.21433 18.9328 6.25301 18.8392 6.25301 18.7416C6.25301 18.644 6.21433 18.5504 6.1455 18.4813C6.07666 18.4123 5.9833 18.3735 5.88595 18.3735Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.2473 18.3051C3.36289 18.1892 3.51981 18.1239 3.68359 18.1239H5.88595C6.04972 18.1239 6.20664 18.1892 6.32224 18.3051C6.43781 18.421 6.50261 18.578 6.50261 18.7416C6.50261 18.9052 6.43781 19.0622 6.32224 19.1781C6.20664 19.294 6.04972 19.3593 5.88595 19.3593H3.68359C3.51981 19.3593 3.36289 19.294 3.2473 19.1781C3.13173 19.0622 3.06692 18.9052 3.06692 18.7416C3.06692 18.578 3.13173 18.421 3.2473 18.3051ZM3.68359 18.6231C3.65266 18.6231 3.62285 18.6354 3.60077 18.6576C3.57867 18.6798 3.56613 18.71 3.56613 18.7416C3.56613 18.7732 3.57867 18.8035 3.60077 18.8256C3.62285 18.8478 3.65266 18.8601 3.68359 18.8601H5.88595C5.91688 18.8601 5.94668 18.8478 5.96876 18.8256C5.99086 18.8035 6.00341 18.7732 6.00341 18.7416C6.00341 18.71 5.99086 18.6798 5.96876 18.6576C5.94668 18.6354 5.91688 18.6231 5.88595 18.6231H3.68359Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.06537 18.3735H7.69831C7.60096 18.3735 7.50759 18.4123 7.43875 18.4813C7.36992 18.5504 7.33124 18.644 7.33124 18.7416C7.33124 18.8392 7.36992 18.9328 7.43875 19.0019C7.50759 19.0709 7.60096 19.1097 7.69831 19.1097H8.06537C8.16272 19.1097 8.25608 19.0709 8.32492 19.0019C8.39375 18.9328 8.43243 18.8392 8.43243 18.7416C8.43243 18.644 8.39375 18.5504 8.32492 18.4813C8.25608 18.4123 8.16272 18.3735 8.06537 18.3735Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.26202 18.3051C7.37761 18.1892 7.53453 18.1239 7.69831 18.1239H8.06537C8.22914 18.1239 8.38606 18.1892 8.50165 18.3051C8.61722 18.421 8.68203 18.578 8.68203 18.7416C8.68203 18.9052 8.61722 19.0622 8.50165 19.1781C8.38606 19.294 8.22914 19.3593 8.06537 19.3593H7.69831C7.53453 19.3593 7.37761 19.294 7.26202 19.1781C7.14645 19.0622 7.08164 18.9052 7.08164 18.7416C7.08164 18.578 7.14645 18.421 7.26202 18.3051ZM7.69831 18.6231C7.66738 18.6231 7.63757 18.6354 7.61549 18.6576C7.59339 18.6798 7.58085 18.71 7.58085 18.7416C7.58085 18.7732 7.59339 18.8035 7.61549 18.8256C7.63757 18.8478 7.66738 18.8601 7.69831 18.8601H8.06537C8.0963 18.8601 8.1261 18.8478 8.14818 18.8256C8.17028 18.8035 8.18283 18.7732 8.18283 18.7416C8.18283 18.71 8.17028 18.6798 8.14818 18.6576C8.1261 18.6354 8.0963 18.6231 8.06537 18.6231H7.69831Z", fill: themeEnrichedPrimaryColor })));
};
Mobile.defaultProps = defaultIconProps;
export default Mobile;
