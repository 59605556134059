/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PnrStatusV1 } from '../../../v1/obt/pnr/pnr';

/**
 * Status of PNR
 * @export
 * @enum {string}
 */

export const PnrStatus = {
  Unknown: 'UNKNOWN',
  Initiated: 'INITIATED',
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  GroupBookingOnRequest: 'GROUP_BOOKING_ON_REQUEST',
  Waitlisted: 'WAITLISTED',
  Pending: 'PENDING',
  AirlineUpgrade: 'AIRLINE_UPGRADE',
  WaitlistConfirmed: 'WAITLIST_CONFIRMED',
  BookingDeniedContactSupport: 'BOOKING_DENIED_CONTACT_SUPPORT',
  NoShow: 'NO_SHOW',
  ContactSupport: 'CONTACT_SUPPORT',
  StatusChangedContactSupport: 'STATUS_CHANGED_CONTACT_SUPPORT',
  ScheduleChange: 'SCHEDULE_CHANGE',
  SegmentRequest: 'SEGMENT_REQUEST',
  ScheduleChangeWaitlistedBooking: 'SCHEDULE_CHANGE_WAITLISTED_BOOKING',
  RequestPending: 'REQUEST_PENDING',
  WaitlistedNotConfirmed: 'WAITLISTED_NOT_CONFIRMED',
  ScheduleChangeNotConfirmed: 'SCHEDULE_CHANGE_NOT_CONFIRMED',
  ScheduleChangePendingStatus: 'SCHEDULE_CHANGE_PENDING_STATUS',
  MisConnection: 'MIS_CONNECTION',
  Requested: 'REQUESTED',
  Ticketed: 'TICKETED',
  Voided: 'VOIDED',
  CancelledByVendor: 'CANCELLED_BY_VENDOR',
  CancellationInProgress: 'CANCELLATION_IN_PROGRESS',
  Reinstated: 'REINSTATED',
  BookingOnHold: 'BOOKING_ON_HOLD',
  AirlineControl: 'AIRLINE_CONTROL',
  Modified: 'MODIFIED',
} as const;

export type PnrStatus = (typeof PnrStatus)[keyof typeof PnrStatus];

export const pnrStatusV1ToV2Map: Record<PnrStatusV1, PnrStatus> = {
  [PnrStatusV1.UNKNOWN]: PnrStatus.Unknown,
  [PnrStatusV1.INITIATED]: PnrStatus.Initiated,
  [PnrStatusV1.CANCELLED]: PnrStatus.Cancelled,
  [PnrStatusV1.CONFIRMED]: PnrStatus.Confirmed,
  [PnrStatusV1.GROUP_BOOKING_ON_REQUEST]: PnrStatus.GroupBookingOnRequest,
  [PnrStatusV1.WAITLISTED]: PnrStatus.Waitlisted,
  [PnrStatusV1.PENDING]: PnrStatus.Pending,
  [PnrStatusV1.AIRLINE_UPGRADE]: PnrStatus.AirlineUpgrade,
  [PnrStatusV1.WAITLIST_CONFIRMED]: PnrStatus.WaitlistConfirmed,
  [PnrStatusV1.BOOKING_DENIED_CONTACT_SUPPORT]: PnrStatus.BookingDeniedContactSupport,

  [PnrStatusV1.NO_SHOW]: PnrStatus.NoShow,
  [PnrStatusV1.CONTACT_SUPPORT]: PnrStatus.ContactSupport,
  [PnrStatusV1.STATUS_CHANGED_CONTACT_SUPPORT]: PnrStatus.StatusChangedContactSupport,
  [PnrStatusV1.SCHEDULE_CHANGE]: PnrStatus.ScheduleChange,
  [PnrStatusV1.SEGMENT_REQUEST]: PnrStatus.SegmentRequest,
  [PnrStatusV1.SCHEDULE_CHANGE_WAITLISTED_BOOKING]: PnrStatus.ScheduleChangeWaitlistedBooking,
  [PnrStatusV1.REQUEST_PENDING]: PnrStatus.RequestPending,
  [PnrStatusV1.WAITLISTED_NOT_CONFIRMED]: PnrStatus.WaitlistedNotConfirmed,
  [PnrStatusV1.SCHEDULE_CHANGE_NOT_CONFIRMED]: PnrStatus.ScheduleChangeNotConfirmed,
  [PnrStatusV1.SCHEDULE_CHANGE_PENDING_STATUS]: PnrStatus.ScheduleChangePendingStatus,

  [PnrStatusV1.MIS_CONNECTION]: PnrStatus.MisConnection,
  [PnrStatusV1.REQUESTED]: PnrStatus.Requested,
  [PnrStatusV1.TICKETED]: PnrStatus.Ticketed,
  [PnrStatusV1.VOIDED]: PnrStatus.Voided,
  [PnrStatusV1.CANCELLED_BY_VENDOR]: PnrStatus.CancelledByVendor,
  [PnrStatusV1.CANCELLATION_IN_PROGRESS]: PnrStatus.CancellationInProgress,
  [PnrStatusV1.REINSTATED]: PnrStatus.Reinstated,
  [PnrStatusV1.BOOKING_ON_HOLD]: PnrStatus.BookingOnHold,
  [PnrStatusV1.AIRLINE_CONTROL]: PnrStatus.AirlineControl,

  [PnrStatusV1.UNRECOGNIZED]: PnrStatus.Unknown,
};
