import styled, { keyframes } from 'styled-components';
const load = keyframes `
  from { transform: translate(-100%) }
  to { transform: translate(100%) }
`;
const IndeterminateLoader = styled.div.attrs({ role: 'progressbar' }) `
  width: 100%;
  height: 2px;
  opacity: 0.8;
  background-color: ${(props) => props.theme.colors.inputFocus};
  animation: ${load} 1s linear infinite;
`;
export default IndeterminateLoader;
