import type { Airport, PreferredLocationLabel } from './api/v2/obt/model';

export enum AirLocationTypeEnum {
  CITY = 'CITY',
  CITY_GROUP = 'CITY_GROUP',
  CITY_AIRPORT = 'CITY_AIRPORT',
  AIRPORT = 'AIRPORT',
}

export interface IAirSuggestion {
  type: AirLocationTypeEnum | PreferredLocationLabel | null;
  code: string;
  name?: string;
  place?: string;
  city?: string;
  countryCode?: string;
  data?: Airport[];
  groupAirports?: Airport[];
}

export interface IFlightSearchSegmentState<T> {
  origin: T;
  destination: T;
  date: string;
}
