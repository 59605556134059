import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import type { AxiosRequestConfig } from 'axios';
import addRequestInterceptor from '../api/interceptors/addRequestInterceptor';
import { DEFAULT_PREFERRED_CURRENCY } from '../utils/Money/constants';
import { TRAVELER_INFO_HEADER_KEY } from '../constants';
import { usePrimaryTravelerId } from '../providers/TravelerProvider';
import type { TravelerInfo } from '../types/api/v1/obt/common/traveler_info';
import { fetchTravelerPreferredCurrency } from '../queries/travelerPreferredCurrency';
import { logger } from '../utils';
import { endPoints } from '../api/endpoints';

/**
 * This is the axios interceptor unsubscribe function extracted to the module level.
 * This is to mitigate the issue with multiple embedded-app stacks,
 * each rendering its own TravelerPreferredCurrencyProvider and thus having several same interceptors (causign race conditions).
 * useLayoutEffect in the component
 *   1. calls this function (hence removing the current interceptor)
 *   2. injects a new interceptor belonging to this component (hence stack)
 *   3. re-assigns new interceptor's unsubscribe function to this variable.
 * The next triggered useLayoutEffect (either from the same stack or a different one) will do the same steps above.
 */
let interceptorUnsubscribeFn: undefined | (() => void);

const shouldSkipInjectionForUrl = (requestUrl: string | undefined) => {
  const skipForUrls = [endPoints.getCustomFieldsDetails, endPoints.readTraveler];
  return skipForUrls.some((url) => requestUrl?.includes(url));
};

type UseInjectTravelerHeaderProps = {
  brexJwtToken?: string;
  reportBrexBudgetEmbedError?: (brexError: any) => void;
  onUpdate: (newPreferredCurrency: string) => void;
};
const useOnTravelerPreferredCurrencyUpdate = ({
  brexJwtToken,
  reportBrexBudgetEmbedError,
  onUpdate,
}: UseInjectTravelerHeaderProps) => {
  const { primaryTravelerId } = usePrimaryTravelerId();

  const methods = { onUpdate, reportBrexBudgetEmbedError };
  const methodsRef = useRef(methods);
  methodsRef.current = methods;

  const fetchPreferredCurrencyRequest = useMemo(async () => {
    try {
      const preferredCurrency = await fetchTravelerPreferredCurrency({
        primaryTravelerId,
        brexJwtToken,
        reportBrexBudgetEmbedError: methodsRef.current.reportBrexBudgetEmbedError,
      });

      methodsRef.current.onUpdate(preferredCurrency);
      return {
        primaryTravelerId,
        preferredCurrency,
      };
    } catch (error) {
      logger.error(
        new Error(
          `travelerPreferredCurrencyFetchError: primaryTravelerId - ${primaryTravelerId}, brexJwtToken - ${brexJwtToken}`,
          { cause: error },
        ),
      );

      methodsRef.current.onUpdate(DEFAULT_PREFERRED_CURRENCY);
      return {
        primaryTravelerId,
        preferredCurrency: DEFAULT_PREFERRED_CURRENCY,
      };
    }
  }, [primaryTravelerId, brexJwtToken]);

  const injectTravelerInfoHeader = useCallback(
    async (config: AxiosRequestConfig) => {
      if (shouldSkipInjectionForUrl(config.url)) {
        return config;
      }

      const { primaryTravelerId: userOrgId, preferredCurrency } = await fetchPreferredCurrencyRequest;
      const travelerInfo: TravelerInfo = {
        userOrgId,
        preferences: { preferredCurrency },
      };

      // eslint-disable-next-line no-param-reassign
      config.headers[TRAVELER_INFO_HEADER_KEY] = JSON.stringify(travelerInfo);
      return config;
    },
    [fetchPreferredCurrencyRequest],
  );

  useLayoutEffect(() => {
    if (interceptorUnsubscribeFn) {
      interceptorUnsubscribeFn();
      interceptorUnsubscribeFn = undefined;
    }

    interceptorUnsubscribeFn = addRequestInterceptor({
      onFulfilled: injectTravelerInfoHeader,
    });
  }, [injectTravelerInfoHeader]);
};

export default useOnTravelerPreferredCurrencyUpdate;
