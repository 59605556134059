/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The expense partner name as enum.
 * @export
 * @enum {string}
 */

export const ExpensePartner = {
    Expensify: 'EXPENSIFY',
    EmburseChromeriver: 'EMBURSE_CHROMERIVER',
    Brex: 'BREX'
} as const;

export type ExpensePartner = typeof ExpensePartner[keyof typeof ExpensePartner];



