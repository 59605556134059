// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as React from 'react';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useCallback, useMemo, useState } from 'react';

import { MoneyUtil } from '../utils';
import { DEFAULT_PREFERRED_CURRENCY } from '../utils/Money/constants';
import useOnTravelerPreferredCurrencyUpdate from '../hooks/useOnTravelerPreferredCurrencyUpdate';

type ITravelerPreferredCurrencyContext = {
  preferredCurrency: string;
};

export const TravelerPreferredCurrencyContext = createContext<ITravelerPreferredCurrencyContext | null>(null);

export const { Consumer: TravelerPreferredCurrencyContextConsumer } = TravelerPreferredCurrencyContext;

type TravelerPreferredCurrencyProviderProps = PropsWithChildren<{
  brexJwtToken?: string;
  reportBrexBudgetEmbedError?: (error: any) => void;
}>;

export const TravelerPreferredCurrencyProvider = ({
  children,
  brexJwtToken,
  reportBrexBudgetEmbedError,
}: TravelerPreferredCurrencyProviderProps) => {
  const [preferredCurrency, setPreferredCurrency] = useState<string>(DEFAULT_PREFERRED_CURRENCY);

  const updatePreferredCurrency = useCallback(
    (newPreferredCurrency: string) => {
      MoneyUtil.setPreferredCurrency(newPreferredCurrency);
      setPreferredCurrency(newPreferredCurrency);
    },
    [setPreferredCurrency],
  );

  useOnTravelerPreferredCurrencyUpdate({ brexJwtToken, reportBrexBudgetEmbedError, onUpdate: updatePreferredCurrency });

  const providerValue = useMemo(() => ({ preferredCurrency }), [preferredCurrency]);

  return (
    <TravelerPreferredCurrencyContext.Provider value={providerValue}>
      {children}
    </TravelerPreferredCurrencyContext.Provider>
  );
};

TravelerPreferredCurrencyProvider.displayName = 'TravelerPreferredCurrencyProvider';

export function useTravelerPreferredCurrency(): ITravelerPreferredCurrencyContext['preferredCurrency'] {
  const context = useContext(TravelerPreferredCurrencyContext);
  if (!context) {
    throw new Error(
      '`useTravelerPreferredCurrencyContext` hook must be used within a `TravelerPreferredCurrencyProvider` component',
    );
  }

  return context.preferredCurrency;
}
