import { css } from '@emotion/react';
import { flex } from '@spotnana/blocks/src/utils/styling/utilityClasses';

export const traveler_picker_company_info = css`
  ${flex.init}
`;

export const traveler_company_logo = css`
  height: 1.5rem;
  width: 1.5rem;
`;

/**
 * The company name is used to render as an autocomplete option
 * hence a max width is set
 */
export const COMPANY_NAME_MAX_WIDTH = 200;
