/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of Rewards Program
 * @export
 * @enum {string}
 */

export const RewardsProgramType = {
  BrexPoints: 'BREX_POINTS',
  QantasPoints: 'QANTAS_POINTS',
} as const;

export type RewardsProgramType = (typeof RewardsProgramType)[keyof typeof RewardsProgramType];
