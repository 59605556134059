import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Economy = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.43 17.93H14.66V20.34C14.66 20.7 14.37 21 14 21H11.09C10.73 21 10.43 20.71 10.43 20.34V17.93Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.94 13.92L9.03 13.8L10.86 11.54L11.97 10.19C12.08 10.04 12.25 9.94999 12.43 9.92999H16.18C16.54 9.92999 16.84 10.22 16.84 10.59C16.84 10.93 16.57 11.22 16.23 11.25H12.82L12.16 12.02L12.14 12.04L10.19 14.36L9.91001 14.7C9.80001 14.84 9.65 14.92 9.48 14.93C9.3 14.93 9.14999 14.89 9.01999 14.77C8.75999 14.55 8.74 14.17 8.95 13.91L8.94 13.92Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.25 15.08V16.79C18.25 17.15 17.96 17.45 17.6 17.45H10.43C8.43999 17.45 6.34999 16.88 6.20999 13.56L5.03 9.65C5.01 9.58 5 9.52 5 9.45V5.16C5 3.96 5.04999 3 6.23999 3C7.42999 3 7.42999 3.93 7.42999 5.13V9.35L8.83 13.27L8.56 13.61C8.18 14.08 8.24 14.75 8.69 15.15C8.89 15.33 9.13999 15.41 9.39999 15.42H9.51999C9.81999 15.39 10.09 15.25 10.29 15.02L10.84 14.36H17.62C17.97 14.36 18.27 14.7 18.27 15.07L18.25 15.08Z", fill: themeEnrichedPrimaryColor })));
};
Economy.defaultProps = defaultIconProps;
export default Economy;
