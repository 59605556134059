import dateUtil from '../dateUtil';

const getDurationDays = (duration: string | number): number => {
  let durationObject;
  if (typeof duration === 'number') {
    durationObject = dateUtil.duration(duration, 'milliseconds');
  } else {
    durationObject = dateUtil.duration(duration);
  }

  return durationObject.asDays() || 0;
};

export default getDurationDays;
