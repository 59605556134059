import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const First = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.33 7.2H10.55C10.72 6.53 11.34 6.03 12.07 6.03H12.82C13.55 6.03 14.16 6.53 14.33 7.2Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M14.39 7.73V9.32999C14.39 10.21 13.69 10.91 12.82 10.91H12.07C11.2 10.91 10.5 10.21 10.5 9.32999V7.73H14.39Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.20001 13.08V15.07H6.08002C5.60002 15.07 5.21002 14.62 5.21002 14.07C5.21002 13.52 5.60002 13.08 6.08002 13.08H8.20001Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M18.96 14.07C18.96 14.62 18.57 15.07 18.09 15.07H8.73999V13.08H18.09C18.57 13.08 18.96 13.53 18.96 14.07Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.23001 19.52C8.23001 19.52 8.21001 19.55 8.20001 19.56V19.52H8.23001Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M23.06 5.56V18.04C23.06 19.44 21.92 20.59 20.51 20.59H8.35999C8.49999 20.44 8.59999 20.27 8.65999 20.07C8.70999 19.93 8.73999 19.78 8.73999 19.62V15.6H17.73C18.42 15.6 18.98 16.24 18.98 17.04V18.63C18.98 18.97 18.88 19.67 18.71 19.91H20.51C21.32 19.91 22.32 18.86 22.32 18.04V5.56C22.32 4.74 21.6 3.77 20.51 3.77H8.73999V3.68C8.73999 3.31 8.58999 3.00999 8.35999 3.00999H20.51C21.92 3.00999 23.06 4.15 23.06 5.56Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.20001 4.03V4.07H8.23001C8.23001 4.07 8.21001 4.04 8.20001 4.03ZM8.20001 19.52V19.56C8.20001 19.56 8.22001 19.54 8.23001 19.52H8.20001Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M22.33 6.03V7.2H19.94C20.11 6.52 20.73 6.02 21.45 6.02H22.2C22.2 6.02 22.28 6.02 22.33 6.02V6.03Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M22.33 7.73V10.9C22.33 10.9 22.25 10.9 22.2 10.9H21.45C20.59 10.9 19.88 10.19 19.88 9.32V7.71H22.33V7.73Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.23001 19.52C8.23001 19.52 8.21001 19.55 8.20001 19.56V19.52H8.23001Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.23001 4.07H8.20001V4.03C8.20001 4.03 8.22001 4.06 8.23001 4.07Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.23001 19.52C8.23001 19.52 8.21001 19.55 8.20001 19.56V19.52H8.23001Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.2 3.98V19.62C8.2 19.78 8.16 19.94 8.08 20.07C7.92 20.38 7.6 20.59 7.23 20.59H2.55C1.15 20.59 0 19.44 0 18.04V5.56C0 4.15 1.15 3.00999 2.55 3.00999H7.33C7.82 3.05999 8.2 3.48 8.2 3.98Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M7.22998 3C7.22998 3 7.29999 2.99999 7.32999 3.00999H7.22998V3Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.23001 4.07H8.20001V4.03C8.20001 4.03 8.22001 4.06 8.23001 4.07Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M8.23001 19.52C8.23001 19.52 8.21001 19.55 8.20001 19.56V19.52H8.23001Z", fill: "white" }),
        React.createElement("path", { d: "M8.23001 4.07H8.20001V4.03C8.20001 4.03 8.22001 4.06 8.23001 4.07Z", fill: "white" }),
        React.createElement("path", { d: "M6.50999 11.99C6.69225 11.99 6.83998 11.8422 6.83998 11.66C6.83998 11.4777 6.69225 11.33 6.50999 11.33C6.32774 11.33 6.17999 11.4777 6.17999 11.66C6.17999 11.8422 6.32774 11.99 6.50999 11.99Z", fill: themeEnrichedPrimaryColor, stroke: "white", strokeWidth: "0.46", strokeMiterlimit: "10" })));
};
First.defaultProps = defaultIconProps;
export default First;
