import { FC } from 'react';
import { FareListItem } from '../FareListItem';
import { fare_list_container } from './styles';
import { useFareList } from '../useFareList';
import { useAddToFareList } from '../useAddToFareList';

export const FareListContainer: FC = () => {
  const onAddToFareList = useAddToFareList();
  const fareList = useFareList();
  return (
    <div css={fare_list_container}>
      {fareList.map((list) => {
        return (
          <FareListItem
            key={list.uuid}
            onClick={onAddToFareList}
            travelerUserId={list.travelerUserId}
            fares={list.fares}
            listId={list.uuid}
          />
        );
      })}
    </div>
  );
};
