import extractApplicationIdFromUrl from './extractApplicationIdFromUrl';

// 1. Memoize the applicationId from the URL on js execution
let initialAppIdFromUrl: string | null = extractApplicationIdFromUrl();

export function saveInMemoryCache(applicationId: string): void {
  initialAppIdFromUrl = applicationId;
}

export function removeFromMemoryCache(): void {
  initialAppIdFromUrl = null;
}

export function getFromMemoryCache(): string | null {
  return initialAppIdFromUrl;
}
