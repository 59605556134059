import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import { emptyName, emptyPhoneNumber, emptyTravelerAddress, mapClientAuthenticationMethodV1toV2 } from '../constants';
import type {
  ILegalEntityName,
  IOfficeTableData,
  IOrgOverview,
  IOfficeData,
  ILegalEntityTable,
  ICostCenter,
  IDepartment,
  ILegalEntityData,
  ILegalEntityDetail,
  ICompanyEmergencyContact,
  ICompanyAuthentication,
} from '../types/admin';
import type {
  CostCenterId,
  DepartmentId,
  LegalEntity,
  LegalEntityId,
  LegalEntityNode,
  OfficeId,
  OrganizationNode,
} from '../types/api/v1/obt/common/organization';
import type { OrganizationId } from '../types/api/v1/obt/common/user_org_id';
import type { IOffice } from '../types/traveler';

export default class ReadOrganizationNodeResponseManager {
  constructor(readonly organizationNode: OrganizationNode) {
    if (!organizationNode) {
      throw new Error('Invalid organization data passed to ReadOrganizationNodeResponseManager');
    }
  }

  public GetOrganizationOverview(): IOrgOverview {
    const org = this.organizationNode.organization;
    return {
      companyName: org?.name ?? '',
      emailDomains: org?.emailDomains ?? [],
      billingCurrency: org?.billingCurrency ?? '',
      emergencyContacts: this.GetEmergencyContactList(),
      ccEmails: org?.ccEmails ?? [],
      organizationLogo: org?.organizationLogo,
      isFake: org?.isFake ?? false,
    };
  }

  public GetOrganizationId(): OrganizationId | null {
    return this.organizationNode.organization?.id ?? null;
  }

  /**
   * @deprecated
   * Office data for v1/organization/read will be removed in future
   * Please use useFetchOfficeListV2 API instead
   */
  public GetOfficesTableData(): IOfficeTableData[] {
    const legalEntityNodes = this.GetAllLegalEntitiesNode();

    const data = legalEntityNodes.reduce(
      (allOffices, { offices, legalEntity: { name: legalEntityName, id: legalEntityId } }) => {
        const listOfOffices: IOfficeTableData[] = offices.map(
          ({ id, name, address: { addressLines, regionCode, locality } }) => ({
            officeId: id ?? { id: '' },
            officeName: name,
            legalEntityId: legalEntityId ?? { id: '' },
            legalEntityName,
            address: first(addressLines) ?? '',
            city: locality,
            country: regionCode,
            countryFlag: '',
          }),
        );
        return [...allOffices, ...listOfOffices];
      },
      [] as IOfficeTableData[],
    );
    return sortBy(data, (item) => item.officeName.toUpperCase());
  }

  /**
   * @deprecated
   * Office data for v1/organization/read will be removed in future
   * Please use getOffice API instead
   */
  public GetOfficeDetails(legalEntityId: LegalEntityId, officeId: OfficeId): IOfficeData {
    const selectedLegalEntityNode = this.findLegalEntityNode(legalEntityId);
    if (selectedLegalEntityNode) {
      const selectedOffice = selectedLegalEntityNode.offices.find((office) => office.id?.id === officeId.id);

      if (!selectedOffice) throw new Error('Invalid office id passed');

      return {
        details: {
          name: selectedOffice?.name ?? '',
          legalEntityId: legalEntityId.id,
          taxId: selectedOffice.taxId,
          ...(selectedOffice?.latlng && { latlng: selectedOffice?.latlng }),
        },
        address: selectedOffice?.address ?? cloneDeep(emptyTravelerAddress),
        isLegalEntityEditable: true,
      };
    }
    throw new Error('Invalid legal entity id passed');
  }

  /**
   * @deprecated
   * Office data for v1/organization/read will be removed in future
   * Please use getOffice API instead
   */
  public GetOffice(officeId: OfficeId): IOffice | undefined {
    const allOffices = this.GetAllOfficeList();
    return allOffices.find((office) => office.id?.id === officeId.id);
  }

  /**
   * @deprecated
   * Office data for v1/organization/read will be removed in future
   * Please use useFetchOfficeListV2 API instead
   */
  private GetAllOfficeList(): IOffice[] {
    const legalEntityNodes = this.GetAllLegalEntitiesNode();
    return legalEntityNodes.reduce((allOffices, { offices }) => [...allOffices, ...offices], [] as IOffice[]);
  }

  public GetAllLegalEntitiesList(): ILegalEntityName[] {
    const legalEntityNodes = this.GetAllLegalEntitiesNode();
    const legalEntityList = legalEntityNodes.map(({ legalEntity: { id, name } }) => ({
      id: id ?? { id: '' },
      name,
    }));
    return sortBy(legalEntityList, (item) => item.name.toUpperCase());
  }

  public GetLegalEntityTableData(): ILegalEntityTable[] {
    const legalEntityNodes = this.GetAllLegalEntitiesNode();
    const data = legalEntityNodes.map(
      ({
        legalEntity: { id, name, address, ein, billingCurrency, isDelayedInvoicingEnabled, expensePartnerConfig },
        offices,
      }) => ({
        id: id ?? { id: '' },
        name,
        addressLine1: first(address?.addressLines) ?? '',
        city: address?.locality ?? '',
        country: address?.regionCode ?? '',
        countryFlag: '',
        taxNumber: ein || '-',
        billingCurrency: billingCurrency || '-',
        officeCount: offices?.length ?? 0,
        isDelayedInvoicingEnabled,
        expensePartnerConfig,
      }),
    );
    return sortBy(data, (item) => item.name.toUpperCase());
  }

  public GetLegalEntityDetails(legalEntityId: LegalEntityId): ILegalEntityData {
    const selectedLegalEntity = this.findLegalEntityNode(legalEntityId);

    if (!selectedLegalEntity) {
      throw new Error('Invalid legal entity id passed');
    }

    const { legalEntity } = selectedLegalEntity;

    return {
      details: ReadOrganizationNodeResponseManager.GetEditLegalEntityData(legalEntity),
      address: legalEntity.address ?? cloneDeep(emptyTravelerAddress),
      phoneNumber: first(legalEntity.phoneNumbers) ?? cloneDeep(emptyPhoneNumber),
    };
  }

  static GetEditLegalEntityData({
    name,
    ein,
    dba,
    billingCurrency,
    isDelayedInvoicingEnabled,
    externalId,
    companySpecifiedAttributes,
  }: LegalEntity): ILegalEntityDetail {
    return { name, ein, dba, billingCurrency, isDelayedInvoicingEnabled, externalId, companySpecifiedAttributes };
  }

  public GetCostCenterTableData(): ICostCenter[] {
    return sortBy(this.organizationNode.costCenters, (item) => item.name.toUpperCase());
  }

  public GetDepartmentTableData(): IDepartment[] {
    return sortBy(this.organizationNode.departments, (item) => item.name.toUpperCase());
  }

  public GetAuthenticationDetails(): ICompanyAuthentication {
    const orgNode = this.organizationNode.organization;
    const clientAuthenticationMethod = first(orgNode?.authConfig?.authProviders)?.customProvider
      ?.clientAuthenticationMethod;
    const applicationId =
      first(orgNode?.authConfig?.authProviders)?.azure?.id ||
      first(orgNode?.authConfig?.authProviders)?.customProvider?.id;

    return {
      type: first(orgNode?.authConfig?.authProviders)?.authProviderType ?? null,
      userProvisionAllowed: orgNode?.isSelfSignUpEnabled ?? false,
      applicationId: applicationId ?? '',
      idleSessionTimeoutMin: orgNode?.authConfig?.idleSessionTimeoutMin ?? 0,
      requireProxy: first(orgNode?.authConfig?.authProviders)?.customProvider?.requiresProxy ?? false,
      tokenEndpoint: first(orgNode?.authConfig?.authProviders)?.customProvider?.tokenEndpoint ?? '',
      clientAuthentication: clientAuthenticationMethod
        ? mapClientAuthenticationMethodV1toV2[clientAuthenticationMethod]
        : undefined,
    };
  }

  public GetDepartment(departmentId: DepartmentId): IDepartment | undefined {
    return this.organizationNode.departments.find((department) => department.id?.id === departmentId.id);
  }

  public GetCostCenter(costCenterId: CostCenterId): ICostCenter | undefined {
    return this.organizationNode.costCenters.find((costCenter) => costCenter.id?.id === costCenterId.id);
  }

  private GetEmergencyContactList(): ICompanyEmergencyContact[] {
    const contacts = this.organizationNode.organization?.emergencyContactInfos ?? [];
    return contacts.map(({ email, name, phoneNumber, designation }) => ({
      email,
      phoneNumber: phoneNumber ?? { ...emptyPhoneNumber },
      name: name ?? { ...emptyName },
      designation: designation ?? '',
    }));
  }

  private GetAllLegalEntitiesNode(): LegalEntityNode[] {
    return this.organizationNode.legalEntityNodes ?? [];
  }

  private findLegalEntityNode(legalEntityId: LegalEntityId): LegalEntityNode | null {
    const legalEntities = this.GetAllLegalEntitiesNode();
    return legalEntities.find((entity) => entity.legalEntity?.id?.id === legalEntityId.id) ?? null;
  }
}
