/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { OBTFeatureActionMetadata } from './obtfeature-action-metadata';

/**
 * The action to initiate on user click.
 * @export
 * @interface OBTFeatureAction
 */
export interface OBTFeatureAction {
  /**
   * The action type.
   * @type {string}
   * @memberof OBTFeatureAction
   */
  actionType?: OBTFeatureActionActionTypeEnum;
  /**
   *
   * @type {OBTFeatureActionMetadata}
   * @memberof OBTFeatureAction
   */
  metadata?: OBTFeatureActionMetadata;
}

export const OBTFeatureActionActionTypeEnum = {
  SelfServe: 'SELF_SERVE',
  ContactSupport: 'CONTACT_SUPPORT',
  /** @deprecated */
  Redirect: 'REDIRECT',
  None: 'NONE',
} as const;

export type OBTFeatureActionActionTypeEnum =
  (typeof OBTFeatureActionActionTypeEnum)[keyof typeof OBTFeatureActionActionTypeEnum];
