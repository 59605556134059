import React from 'react';
import { defaultIconProps } from './common';
const RequestNoted = ({ width, height }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 0 128 108", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M120 23.4641V31.5068L67.9568 38.7854L17 31.5068V23.4641C17 17.2713 22.0092 12.2647 28.2053 12.2647H108.795C114.991 12.2446 120 17.2713 120 23.4641Z", fill: "#D6525D" }),
    React.createElement("path", { d: "M120 31.5068V86.8002C120 92.9931 114.991 97.9996 108.795 97.9996H28.2053C22.0092 97.9996 17 92.9931 17 86.8002V31.5068H120Z", fill: "#F9F6F9" }),
    React.createElement("path", { d: "M35.2865 12.2451C29.0904 12.2451 24.0813 17.2517 24.0813 23.4445V31.4872H17V23.4445C17 17.2517 22.0092 12.2451 28.2053 12.2451H35.2865Z", fill: "#99464D" }),
    React.createElement("path", { d: "M35.2865 97.9996H28.2053C22.0092 97.9996 17 92.9931 17 86.8002V31.5068H24.0813V86.8002C24.0813 92.9931 29.0904 97.9996 35.2865 97.9996Z", fill: "#D7D7D7" }),
    React.createElement("path", { d: "M102.941 12.2454V8.14361C102.941 7.07795 102.076 6.23347 101.029 6.23347C99.9834 6.23347 99.1183 7.09806 99.1183 8.14361V15.6635C99.1183 16.7292 99.9834 17.5736 101.029 17.5736C102.739 17.5736 104.148 18.961 104.148 20.6902C104.148 22.3992 102.76 23.8067 101.029 23.8067C96.5433 23.8067 92.9021 20.1674 92.9021 15.6836V8.14361C92.9021 3.65982 96.5433 0.0205078 101.029 0.0205078C105.516 0.0205078 109.157 3.65982 109.157 8.14361V12.2655", fill: "#FFE696" }),
    React.createElement("path", { d: "M102.216 23.5449C101.854 23.7058 101.452 23.7862 101.03 23.7862C96.5434 23.7862 92.8821 20.1469 92.8821 15.643V8.14321C92.8821 3.65942 96.5233 0 101.03 0C101.573 0 102.096 0.0603201 102.599 0.160854C98.8569 0.904801 96.0405 4.2023 96.0405 8.14321V15.6631C96.0405 19.4633 98.6758 22.6602 102.216 23.5449Z", fill: "#FFAE64" }),
    React.createElement("path", { d: "M81.5561 12.2454V8.14361C81.5561 7.07795 80.691 6.23347 79.6449 6.23347C78.5988 6.23347 77.7338 7.09806 77.7338 8.14361V15.6635C77.7338 16.7292 78.5988 17.5736 79.6449 17.5736C81.3549 17.5736 82.7631 18.961 82.7631 20.6902C82.7631 22.3992 81.375 23.8067 79.6449 23.8067C75.1588 23.8067 71.5176 20.1674 71.5176 15.6836V8.14361C71.5176 3.65982 75.1588 0.0205078 79.6449 0.0205078C84.1311 0.0205078 87.7723 3.65982 87.7723 8.14361V12.2655", fill: "#FFE696" }),
    React.createElement("path", { d: "M80.8318 23.5449C80.4697 23.7058 80.0674 23.7862 79.6449 23.7862C75.1588 23.7862 71.4974 20.1469 71.4974 15.643V8.14321C71.4974 3.65942 75.1387 0 79.6449 0C80.1881 0 80.7111 0.0603201 81.2141 0.160854C77.4722 0.904801 74.6558 4.2023 74.6558 8.14321V15.6631C74.6558 19.4633 77.2912 22.6602 80.8318 23.5449Z", fill: "#FFAE64" }),
    React.createElement("path", { d: "M60.1915 12.2454V8.14361C60.1915 7.07795 59.3265 6.23347 58.2804 6.23347C57.2343 6.23347 56.3693 7.09806 56.3693 8.14361V15.6635C56.3693 16.7292 57.2343 17.5736 58.2804 17.5736C59.9904 17.5736 61.3986 18.961 61.3986 20.6902C61.3986 22.3992 60.0105 23.8067 58.2804 23.8067C53.7943 23.8067 50.1531 20.1674 50.1531 15.6836V8.14361C50.1531 3.65982 53.7943 0.0205078 58.2804 0.0205078C62.7665 0.0205078 66.4078 3.65982 66.4078 8.14361V12.2655", fill: "#FFE696" }),
    React.createElement("path", { d: "M59.4674 23.5449C59.1053 23.7058 58.703 23.7862 58.2805 23.7862C53.7944 23.7862 50.1331 20.1469 50.1331 15.643V8.14321C50.1331 3.65942 53.7743 0 58.2805 0C58.8237 0 59.3467 0.0603201 59.8497 0.160854C56.1079 0.904801 53.2915 4.2023 53.2915 8.14321V15.6631C53.2915 19.4633 55.9268 22.6602 59.4674 23.5449Z", fill: "#FFAE64" }),
    React.createElement("path", { d: "M38.8071 12.245V8.14321C38.8071 7.07755 37.942 6.23307 36.8959 6.23307C35.8498 6.23307 34.9848 7.09766 34.9848 8.14321V15.6631C34.9848 16.7288 35.8498 17.5732 36.8959 17.5732C38.6059 17.5732 40.0141 18.9606 40.0141 20.6898C40.0141 22.3989 38.626 23.8063 36.8959 23.8063C32.4098 23.8063 28.7686 20.167 28.7686 15.6832V8.14321C28.7485 3.63931 32.3897 0 36.8758 0C41.3619 0 45.0031 3.63931 45.0031 8.1231V12.245", fill: "#FFE696" }),
    React.createElement("path", { d: "M38.0828 23.5449C37.7207 23.7058 37.3183 23.7862 36.8959 23.7862C32.4097 23.7862 28.7484 20.1469 28.7484 15.643V8.14321C28.7484 3.65942 32.3896 0 36.8758 0C37.4189 0 37.942 0.0603201 38.4449 0.160854C34.7232 0.884694 31.9068 4.18219 31.9068 8.14321V15.6631C31.9068 19.4633 34.5422 22.6602 38.0828 23.5449Z", fill: "#FFAE64" }),
    React.createElement("path", { d: "M87.893 54.69V77.8127C87.893 81.0901 85.2375 83.7442 81.9584 83.7442H55.7256C52.4465 83.7442 49.791 81.0901 49.791 77.8127V51.5936C49.791 48.3162 52.4465 45.6621 55.7256 45.6621H78.6994", fill: "white" }),
    React.createElement("path", { d: "M81.9583 85.2928H55.7255C51.6015 85.2928 48.2419 81.9349 48.2419 77.8131V51.5939C48.2419 47.4721 51.6015 44.1143 55.7255 44.1143H81.8175C82.6826 44.1143 83.3666 44.818 83.3666 45.6625C83.3666 46.507 82.6625 47.2107 81.8175 47.2107H55.7255C53.3115 47.2107 51.3601 49.161 51.3601 51.5738V77.8131C51.3601 80.2259 53.3115 82.1762 55.7255 82.1762H81.9583C84.3724 82.1762 86.3238 80.2259 86.3238 77.8131V49.8045C86.3238 48.9399 87.0279 48.2562 87.8728 48.2562C88.7378 48.2562 89.4218 48.96 89.4218 49.8045V77.8131C89.4419 81.955 86.0824 85.2928 81.9583 85.2928Z", fill: "black" }),
    React.createElement("path", { d: "M71.5176 76.2647C69.6668 76.2647 67.8965 75.4001 66.7498 73.9323L56.651 60.9233C54.599 58.2893 55.0818 54.5092 57.7172 52.4584C60.3525 50.4075 64.1345 50.89 66.1865 53.524L70.7129 59.3751L79.5644 43.7321C81.214 40.8367 84.8955 39.8113 87.7924 41.4399C90.6892 43.0685 91.7152 46.7682 90.0857 49.6635L76.7681 73.1884C75.7824 74.9376 73.9718 76.0837 71.9601 76.2446C71.7992 76.2647 71.6584 76.2647 71.5176 76.2647Z", fill: "#C4FDD1" }),
    React.createElement("path", { d: "M73.6902 63.1951L84.6943 43.7318C85.3381 42.6059 86.2836 41.7614 87.39 41.2386C84.5535 39.9116 81.1336 40.9571 79.5645 43.7318L70.7129 59.3748L73.6902 63.1951Z", fill: "#70E88C" }),
    React.createElement("path", { d: "M71.8797 73.9319L61.7808 60.9229C59.7289 58.2889 60.2117 54.5089 62.847 52.458C63.2092 52.1765 63.5914 51.9553 63.9736 51.7744C61.982 50.8495 59.5679 51.0304 57.7172 52.458C55.0818 54.5089 54.599 58.2889 56.651 60.9229L66.7498 73.9319C67.8965 75.4198 69.6668 76.2643 71.5175 76.2643C71.6584 76.2643 71.8193 76.2643 71.9601 76.2442C72.7045 76.1839 73.4287 76.0029 74.0925 75.6812C73.2275 75.2791 72.4631 74.696 71.8797 73.9319Z", fill: "#70E88C" }),
    React.createElement("circle", { cx: "13.5", cy: "48.5", r: "13.5", fill: "#88B9DA" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.19398 46.5878L5.56885 49.2129L10.8191 54.4632L10.8192 54.4631L11.8566 55.5005L21.3471 46.01L18.722 43.3848L11.8565 50.2503L8.19398 46.5878Z", fill: "white" }),
    React.createElement("circle", { cx: "112.369", cy: "91.6306", r: "15.6306", fill: "#ADADAD" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M112.599 83.7236C109.777 83.7236 107.49 86.0109 107.49 88.8323V94.7748L104.617 96.7841H120.358L117.707 94.7748V88.8323C117.707 86.0109 115.42 83.7236 112.599 83.7236ZM114.761 98.3549H110.234C110.234 99.5093 111.17 100.445 112.324 100.445H112.671C113.825 100.445 114.761 99.5093 114.761 98.3549Z", fill: "white" })));
RequestNoted.defaultProps = defaultIconProps;
export default RequestNoted;
