import { StorageKeys } from '../../types/storage';
import { removeStorageValue } from '../../hooks/useStorage';
import { emitTmcIdChange } from './eventemitter';
import { removeFromMemoryCacheTmcId } from './inMemoryUrlCache';

export async function removeTmcId(): Promise<void> {
  await removeStorageValue(StorageKeys.TMC_ID).finally(() => {
    removeFromMemoryCacheTmcId();
    emitTmcIdChange(undefined);
  });
}
