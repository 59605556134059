import { FC } from 'react';
import last from 'lodash/last';
import { useHistory } from 'react-router';
import { ROUTE_PATHS } from 'src/routes';
import { stringifyParams } from 'obt-common/utils/urlUtils';
import { useFares } from '../hooks/useFares';
import { useAirSelectedItinerariesResponse } from '../hooks/useAirSelectedItinerariesResponse';
import { fare_itineraries_container } from './styles';
import { getValidFares } from '../../utils/getValidFares';
import { getExpiredFares } from '../../utils/getExpiredFares';
import { FareSummaryContainer } from '../FareSummaryContainer';
import BrowseMoreOptions from '../BrowseMoreOptions';
import { useAirSearchResultsFeatures } from '../hooks/useAirSearchResultsFeatures';

interface FareItinerariesContainerProps {
  onRemoveFare: (fareRateOptionId: string) => void;
  onClose: VoidFunction;
}

export const FareItinerariesContainer: FC<FareItinerariesContainerProps> = ({ onRemoveFare, onClose }) => {
  const fares = useFares();
  const history = useHistory();

  const airSelectedItinerariesResponse = useAirSelectedItinerariesResponse();
  const airSearchResultsFeatures = useAirSearchResultsFeatures();

  const validFares = getValidFares(fares);
  const expiredFares = getExpiredFares(fares);

  const lastFare = last(validFares);

  const onNavigateToFareCheckout = (fareIndex: number) => {
    /**
     * Navigate to fare checkout button is only displayed for valid fares
     */
    const selectedFare = validFares[fareIndex];
    const searchParams = stringifyParams({ fare: selectedFare });

    onClose();
    history.push({
      pathname: ROUTE_PATHS.FLIGHTS_CHECKOUT_VALIDATE_FARE,
      search: searchParams,
    });
  };
  return (
    <div css={fare_itineraries_container}>
      {validFares.map((fare, fareIndex) => {
        return (
          <FareSummaryContainer
            fareIndex={fareIndex}
            airSearchResultsFeatures={airSearchResultsFeatures}
            onRemoveFare={onRemoveFare}
            airSelectedItinerariesResponse={airSelectedItinerariesResponse}
            isFareExpired={false}
            rateOptionId={fare.rateOptionId}
            key={fare.rateOptionId}
            onNavigateToFareCheckout={onNavigateToFareCheckout}
          />
        );
      })}

      {lastFare && <BrowseMoreOptions fare={lastFare} onClose={onClose} />}

      {expiredFares.map((fare, fareIndex) => {
        return (
          <FareSummaryContainer
            fareIndex={fareIndex}
            airSearchResultsFeatures={airSearchResultsFeatures}
            onRemoveFare={onRemoveFare}
            airSelectedItinerariesResponse={airSelectedItinerariesResponse}
            isFareExpired
            rateOptionId={fare.rateOptionId}
            key={fare.rateOptionId}
            onNavigateToFareCheckout={onNavigateToFareCheckout}
          />
        );
      })}
    </div>
  );
};
