/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prefer-destructuring */
import 'styled-components';
import { rem } from 'polished';
import { getThemeColors } from './colors';
import { getThemeFonts } from './fonts';
import { generateThemeWithCSSVars } from './utils';
import devices from './devices';
import { getThemeShadows } from './shadows';
export const fontSizes = {
    h1: rem(48),
    h2: rem(32),
    h3: rem(24),
    h4: rem(20),
    h5: rem(18),
    body: rem(16),
    small: rem(14),
    smaller: rem(13),
    label: rem(12),
    tinyLabel: rem(10),
};
export const fontWeights = {
    thin: 300,
    regular: 400,
    strong: 500,
    semiBold: 600,
};
export const transitions = {
    regular: 'all 0.2s ease-in-out',
    slower: 'all 0.4s ease-in-out',
};
export const lineHeights = {
    single: 1,
    label: '16px',
    value: '19px',
    double: 2,
    switchLabel: '24px',
};
export const letterSpacings = {
    default: 'normal',
    tracked: '0.04em',
};
export const space = [0, 4, 8, 16, 24, 32, 64, 128, 256, 512];
export const popupWidthMobile = {
    width: 340,
};
export const sizes = {
    avatar: {
        extraSmall: 16,
        '24X24': 24,
        small: 32,
        mediumSmall: 48,
        medium: 64,
        large: 128,
        extraLarge: 256,
    },
    input: {
        large: 56,
        small: 40,
        minWidth: 150,
    },
    checkbox: {
        inner: 10,
        outer: 20,
    },
    switch: {
        height: 24,
        width: 48,
        slider: 20,
    },
    full: '100%',
    hotelCards: {
        height: 400,
    },
};
const fonts = generateThemeWithCSSVars(getThemeFonts());
const colors = generateThemeWithCSSVars(getThemeColors());
const zIndices = {
    base: 0,
    overlay: 1,
    top: 2,
};
const radii = {
    xxs: 2,
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 24,
    circle: '100%',
};
const shadows = generateThemeWithCSSVars(getThemeShadows());
const borders = {
    thinSolid: '1px solid',
    thickSolid: '2px solid',
    thickSolid2: '3px solid',
    thinDashed: '1px dashed',
};
// The units mentioned in comments are decided by the `react-styled-flexboxgrid` library, do not change
const flexboxgrid = {
    gridSize: 12,
    gutterWidth: 0, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    container: {
        // substract the margins from breakpoints
        sm: devices.tabletRem - 2, // rem
        md: devices.laptopRem - 4, // rem
        lg: devices.desktopRem - 6, // rem
    },
    breakpoints: {
        xs: 0, // em
        sm: devices.tabletRem, // em
        md: devices.laptopRem, // em
        lg: devices.desktopRem, // em
    },
};
const mediaQueries = {
    belowMobile: `@media screen and (max-width: ${devices.mobilePx - 1}px)`,
    aboveMobile: `@media screen and (min-width: ${devices.mobilePx}px)`,
    belowTablet: `@media screen and (max-width: ${devices.tabletPx - 1}px)`,
    aboveTablet: `@media screen and (min-width: ${devices.tabletPx}px)`,
    belowLaptopMediaOnly: `(max-width: ${devices.laptopPx - 1}px)`,
    get belowLaptop() {
        return `@media screen and ${mediaQueries.belowLaptopMediaOnly}`;
    },
    aboveLaptop: `@media screen and (min-width: ${devices.laptopPx}px)`,
    belowDesktop: `@media screen and (max-width: ${devices.desktopPx - 1}px)`,
    aboveDesktop: `@media screen and (min-width: ${devices.desktopPx}px)`,
};
// Letting the array remain as it is to make this a non-breaking change
const breakpoints = [`${devices.tabletRem}em`, `${devices.laptopRem}em`, `${devices.desktopRem}em`];
breakpoints.xs = '0em';
breakpoints.sm = `${devices.tabletRem}em`;
breakpoints.md = `${devices.laptopRem}em`;
breakpoints.lg = `${devices.desktopRem}em`;
const theme = {
    space,
    sizes,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    breakpoints,
    mediaQueries,
    colors,
    radii,
    zIndices,
    shadows,
    borders,
    flexboxgrid,
    transitions,
    popupWidthMobile,
};
export default theme;
