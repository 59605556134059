import { Button } from '@spotnana/blocks/src/Button';
import { InputField } from '@spotnana/blocks/src/fields';
import { AgentLink } from '@spotnana/types/openapi/models/agent-link';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'rhf-v7';
import { form, form_footer } from '../index.styles';

// LinkEditForm component for editing or creating a new link
const LinkEditForm = ({
  link,
  onSubmit,
  onCancel,
  isPrivate,
}: {
  link: AgentLink | null;
  onSubmit: (link: AgentLink) => void;
  onCancel: () => void;
  isPrivate: boolean;
}): JSX.Element => {
  // Translation hook to get translation functions specific to 'WEB' namespace
  const { t: tt } = useTranslation('WEB');

  const methods = useForm<{
    title: string;
    url: string;
    isPrivate: boolean;
  }>({
    defaultValues: link || {
      title: '',
      url: '',
      isPrivate,
    },
    reValidateMode: 'onBlur',
  });
  const { register, handleSubmit, control } = methods;

  // Function to handle saving the link
  const handleSave = (updatedLink: AgentLink) => {
    // Check if there's an updated link and submit it
    updatedLink && onSubmit(updatedLink);
  };

  return (
    <>
      {/* Heading for the form */}
      <h3>{link?.id ? tt('Edit Link') : tt('New Link')}</h3>
      {/* Form section with title and URL inputs */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSave)} onReset={onCancel}>
          <div css={form}>
            <InputField label={tt('Title')} required fullWidth {...register('title')} control={control} />
            <InputField
              label={tt('Link')}
              required
              fullWidth
              {...register('url', {
                pattern: {
                  value: /^((https?|ftp):\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w- .\/?%&=#]*)?$/,
                  message: tt('Please enter a valid URL.'),
                },
              })}
              control={control}
            />
          </div>
          {/* Buttons for Cancel and Save actions */}
          <div css={form_footer}>
            {/* Cancel Button */}
            <Button type="reset" data-testid="booking-item-action" variant="outline" size="small" fullWidth>
              {tt('Cancel')}
            </Button>
            {/* Save Button */}
            <Button type="submit" data-testid="booking-item-action" variant="primary" size="small" fullWidth>
              {tt('Save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default LinkEditForm;
