import React, { useMemo } from 'react';
import { CssVarsThemeOptions, Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { makeTheme } from './theming';
import type { InternalBlocksTheme } from './blocksTheme';
import { CustomizationProvider, IConfigValues } from '../CustomizationProvider/CustomizationProvider';

export const BlocksThemeProvider = ({
  children,
  theme,
  muiOptions,
  config,
}: React.PropsWithChildren<{
  theme?: InternalBlocksTheme;
  muiOptions?: CssVarsThemeOptions;
  config?: IConfigValues;
}>): JSX.Element => {
  const muiInternalTheme = useMemo(() => makeTheme(theme, muiOptions), [theme, muiOptions]);

  return (
    <CssVarsProvider theme={muiInternalTheme}>
      <CustomizationProvider config={config}>{children}</CustomizationProvider>
    </CssVarsProvider>
  );
};
