import { useEffect } from 'react';

import { onDebarUser } from 'obt-common';

import { useDebarUserContext } from '../components/DebarUserContext';

const useDebarUserListener = (): void => {
  const { setDebarUser } = useDebarUserContext();

  useEffect(() => {
    const unsubscribe = onDebarUser(setDebarUser);

    return unsubscribe;
  }, [setDebarUser]);
};

export default useDebarUserListener;
