import { flex, margins, paddings, themed } from '@spotnana/blocks/src/utils';
import { css } from '@emotion/react';

export const container = css`
  ${paddings.x.p1point5}
  ${paddings.y.p1point5}
  ${flex.init}
  ${flex.column}
  ${flex.justify.between}

  height: 100%;
`;

export const title_container = css`
  ${flex.init}
  ${flex.column}
`;

export const subtitle_text = themed(
  ({ palette }) => css`
    ${margins.yb.m1point5}
    color: ${palette.text.brand};
  `,
);

export const bottom_container = css`
  ${flex.init}
  ${flex.column}
`;

export const back_button = css`
  ${margins.yt.m1}
`;
