import { FC } from 'react';

import { TravelerBasicInfo } from 'obt-common/types/api/v2/obt/model';
import { AutocompleteFilter } from 'obt-common/types/api/v2/obt/model/autocomplete-filter';
import { AutocompleteOptionType } from '@spotnana/blocks/src/Autocomplete/Autocomplete.types';
import { Icon } from '@spotnana/blocks/src/Icon';
import { AdornmentWrapper } from '@spotnana/blocks/src/Input';
import { traveler_picker_autocomplete } from './styles';
import { TravelerPickerOptionPrefix } from '../TravelerPickerOption/TravelerPickerOptionPrefix';
import { TravelerPickerOptionDescription } from '../TravelerPickerOption/TravelerPickerOptionDescription';
import { TravelerAutocomplete } from '../../TravelerAutocomplete/TravelerAutocomplete';

export type TOption = TravelerBasicInfo & { label: string; value: string; type: AutocompleteOptionType };

interface IProps {
  onTravelerSelect: (value: TravelerBasicInfo | null) => void;
  /**
   * Travelers from different companies cannot be in the same flight, hence
   * if this field is populated only members of the specified org ID will appear
   * in the results
   */
  orgIdToFilter: string | null;
}

/**
 *
 * This component renders an v2-components autocomplete that will only show travellers
 * as results
 */
const TravelerPicker: FC<IProps> = ({ onTravelerSelect, orgIdToFilter }) => {
  /**
   * Only passengers of the same company can travel together
   */
  const filters: AutocompleteFilter[] | undefined = orgIdToFilter
    ? [{ travelerFilter: { companyIds: [{ id: orgIdToFilter }] } }]
    : undefined;

  const handleTravelerSelection = (option: TravelerBasicInfo | null) => {
    onTravelerSelect(option);
  };

  return (
    <div css={traveler_picker_autocomplete}>
      <TravelerAutocomplete
        onTravelerSelect={handleTravelerSelection}
        filters={filters}
        startAdornment={
          <AdornmentWrapper variant="left">
            <Icon name="UserTraveler" width="24px" height="24px" />
          </AdornmentWrapper>
        }
        slots={{ ItemPrefix: TravelerPickerOptionPrefix, ItemDescription: TravelerPickerOptionDescription }}
      />
    </div>
  );
};

export default TravelerPicker;
