/**
 * Utilities for managing the interoperability between plain text and rich text as generated by the
 * RichTextEditor component.
 */
import { HTML_REGEXP } from 'obt-common';

const OPENING_TAGS = `<p class="editor-paragraph" dir="ltr"><span>`;
const CLOSING_TAGS = `</span></p>`;

export const convertPlainTextToRichText = (possiblePlainText?: string) => {
  if (!possiblePlainText) return '';

  const isHtmlString = HTML_REGEXP.test(possiblePlainText);
  if (isHtmlString) return possiblePlainText;

  // Some plain text might have new lines, so we need to wrap each line separately.
  const wrappedLines = possiblePlainText.split('\n').map((lineOfText) => `${OPENING_TAGS}${lineOfText}${CLOSING_TAGS}`);
  const richText = wrappedLines.join('');
  return richText;
};

export const convertRichTextToPlainText = (possibleRichText?: string) => {
  if (!possibleRichText) return '';

  const isHtmlString = HTML_REGEXP.test(possibleRichText);
  if (!isHtmlString) return possibleRichText;

  const plainText = possibleRichText
    .replace(/<br>/g, '\n') // Replace line breaks
    .replace(/<\/p><p[^>]*>/g, '\n') // Replace new paragraphs with line breaks
    .replace(/<[^>]*>/g, '') // Remove all HTML tags
    .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces with regular spaces
    .replace(/&lt;/g, '<') // Replace HTML entities
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');
  return plainText;
};
