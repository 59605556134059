import { Button } from '@spotnana/blocks/src/Button';
import { Typography } from '@spotnana/blocks/src/Typography';
import { AgentLink } from '@spotnana/types/openapi/models/agent-link';
import { useAddLink, useDeleteLink, useListLinks, useLoggedInUserId, useReorderLinks, useUpdateLink } from 'obt-common';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { links_wrapper, list_body, no_links_added_ctr, urls } from '../index.styles';
import LinkEditForm from './LinkEditForm';
import LinkItem from './LinkItem';

// Links component displays a list of links, allows editing and adding new links
const Links = ({ isPrivate = false }: { isPrivate?: boolean }): JSX.Element => {
  // Translation hook to get translation functions specific to 'WEB' namespace
  const { t: tt } = useTranslation('WEB');

  const userOrgId = useLoggedInUserId();
  const userId = userOrgId.userId?.id;
  const tmcId = userOrgId.tmcBasicInfo?.bookingTmc?.id?.id;

  const { mutateAsync: addLink } = useAddLink(isPrivate);
  const { mutateAsync: updateLink } = useUpdateLink(isPrivate);
  const { mutateAsync: deleteLink } = useDeleteLink(isPrivate);
  const { mutateAsync: reorderLinks } = useReorderLinks(isPrivate);

  // State to manage edit mode (whether to display edit mode or not)
  const [editMode, setEditMode] = useState(false);

  // Fetch links data using the useListLinks hook
  const { data, refetch, isLoading } = useListLinks({ userId, tmcId, isPrivate });

  // State to manage the list of links based on whether they are private or not
  const [links, setLinks] = useState(() => data?.agentLinks || []);
  // State to manage the selected link during editing
  const [selectedLink, setSelectedLink] = useState<AgentLink | null>(null);

  useEffect(() => {
    refetch();
  }, [refetch, isPrivate]);

  // useEffect to update the links when data changes
  useEffect(() => {
    setLinks(data?.agentLinks || []);
  }, [data?.agentLinks]);

  // Function to toggle edit mode
  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode);
    editMode && setSelectedLink(null);
  }, [setEditMode, setSelectedLink, editMode]);

  // Function to add a new link
  const handleSubmit = (link: AgentLink) => {
    if (link.id) {
      link && setLinks((items: Array<AgentLink>) => items.map((item) => (item.id === link.id ? link : item)));
      updateLink({ userId, tmcId, isPrivate, id: link.id, request: link });
    } else {
      link && setLinks((items: Array<AgentLink>) => [...items, link]);
      addLink({ userId, tmcId, isPrivate, request: link });
    }

    toggleEditMode();
  };

  const onDelete = useCallback(
    (link: AgentLink) => {
      link.id && deleteLink({ isPrivate, userId, tmcId, linkId: link.id });
    },
    [deleteLink, isPrivate, tmcId, userId],
  );

  const onEdit = useCallback(
    (link: AgentLink) => {
      setSelectedLink(link);
      toggleEditMode();
    },
    [setSelectedLink, toggleEditMode],
  );

  const handleDragEnd = (result: DropResult) => {
    // Reorder the items in your data structure based on the drag result
    const { source, destination } = result;
    // Check if the drag operation was successful
    if (!destination) {
      return; // Drag was cancelled or dropped outside of a droppable area
    }

    // If the draggable item was dropped in a different position, update the order
    if (source.index !== destination.index) {
      // Reorder your items array or update the state to reflect the new order
      const reorderedItems = [...links]; // Assuming items is your array of draggable items
      const [removed] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, removed);

      // Update state or trigger any necessary actions to reflect the new order
      setLinks(reorderedItems); // Assuming you're using React state
      reorderLinks({ userId, tmcId, links: reorderedItems, isPrivate }); // Assuming you're using a mutation hook
    }
  };

  const linksExists = links.length > 0 || isLoading;

  return (
    <div css={links_wrapper}>
      {!editMode && (
        <>
          {/* Display message when not in edit mode */}
          {linksExists && (
            <Typography variant="header5" kind="medium">
              {tt('Organise and store your essential links here.')}
            </Typography>
          )}
          {/* Display the list of links */}
          <div css={list_body}>
            {!linksExists && (
              <Typography variant="header5" kind="medium" css={[urls, no_links_added_ctr]}>
                {tt('Add your frequently used links here')}
              </Typography>
            )}
            {linksExists && (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="links">
                  {(provided) => (
                    <ul css={urls} {...provided.droppableProps} ref={provided.innerRef}>
                      {links.map((link: AgentLink, index: number) => (
                        <LinkItem
                          key={link.title}
                          link={link}
                          index={index}
                          onDelete={onDelete.bind(null, link)}
                          onEdit={onEdit.bind(null, link)}
                        />
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
          {/* Button to enter edit mode */}
          <Button data-testid="booking-item-action" variant="primary" size="small" fullWidth onClick={toggleEditMode}>
            {tt('New Link')}
          </Button>
        </>
      )}
      {/* Display LinkEditForm when in edit mode */}
      {editMode && (
        <LinkEditForm
          key={selectedLink?.id || 'NewLinkForm'}
          link={selectedLink}
          onCancel={toggleEditMode}
          onSubmit={handleSubmit}
          isPrivate={isPrivate}
        />
      )}
    </div>
  );
};

export default Links;
