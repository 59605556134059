import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const UnLink = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13.0011 6.00027H15.8711C17.3311 6.00027 18.6711 6.98027 18.9511 8.42027C19.2611 10.0603 18.2111 11.5303 16.7311 11.9003L18.2611 13.4303C20.0311 12.5203 21.2111 10.6103 20.9611 8.42027C20.6811 5.86027 18.3711 4.00027 15.7911 4.00027H13.0011C12.4511 4.00027 12.0011 4.45027 12.0011 5.00027C12.0011 5.55027 12.4511 6.00027 13.0011 6.00027ZM2.51109 0.510273C2.12109 0.120273 1.49109 0.120273 1.10109 0.510273C0.711094 0.900274 0.711094 1.53027 1.10109 1.92027L3.74109 4.56027C1.97109 5.47027 0.791094 7.38027 1.04109 9.57027C1.32109 12.1403 3.63109 14.0003 6.21109 14.0003H9.00109C9.5511 14.0003 10.0011 13.5503 10.0011 13.0003C10.0011 12.4503 9.5511 12.0003 9.00109 12.0003H6.13109C4.67109 12.0003 3.33109 11.0203 3.05109 9.58027C2.74109 7.94027 3.80109 6.47027 5.27109 6.10027L7.3911 8.22027C7.1611 8.41027 7.00109 8.68027 7.00109 9.00027C7.00109 9.55027 7.45109 10.0003 8.00109 10.0003H9.17109L18.0711 18.9003C18.4611 19.2903 19.0911 19.2903 19.4811 18.9003C19.8711 18.5103 19.8711 17.8803 19.4811 17.4903L2.51109 0.510273ZM13.0011 8.00027L14.7111 9.71027C14.8911 9.53027 15.0011 9.28027 15.0011 9.00027C15.0011 8.45027 14.5511 8.00027 14.0011 8.00027H13.0011Z", fill: themeEnrichedPrimaryColor })));
};
UnLink.defaultProps = defaultIconProps;
export default UnLink;
