import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FileCSV = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 25 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.242 0a2 2 0 00-2 2v18a2 2 0 002 2h14a2 2 0 002-2V2a2 2 0 00-2-2h-14zm.5 2a.5.5 0 00-.5.5v17a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-17a.5.5 0 00-.5-.5h-13z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fill: "#fff", d: "M6.242 6h2v10h-2z" }),
        React.createElement("path", { d: "M5.918 11.948c-.057.607-.281 1.081-.672 1.422-.39.339-.91.508-1.559.508-.453 0-.852-.107-1.199-.32a2.133 2.133 0 01-.797-.918c-.187-.396-.285-.856-.293-1.379v-.531c0-.537.095-1.01.286-1.418.19-.41.462-.724.816-.946a2.293 2.293 0 011.234-.332c.628 0 1.133.171 1.516.512.383.341.605.823.668 1.445h-.984c-.047-.408-.167-.703-.36-.882-.19-.183-.47-.274-.84-.274-.43 0-.76.158-.992.473-.229.312-.346.772-.351 1.379v.504c0 .614.109 1.083.328 1.406.221.323.544.484.969.484.388 0 .68-.087.874-.261.196-.175.32-.465.372-.872h.984zM9.777 12.335c0-.25-.088-.443-.265-.578-.175-.135-.491-.272-.95-.41-.458-.138-.822-.292-1.093-.461-.518-.325-.778-.75-.778-1.273 0-.459.187-.836.559-1.133.375-.297.86-.446 1.457-.446.396 0 .749.073 1.059.22.31.145.553.353.73.624.177.268.266.567.266.895h-.985c0-.297-.093-.529-.28-.696-.186-.169-.451-.254-.798-.254-.323 0-.574.07-.754.208a.691.691 0 00-.265.578c0 .208.096.382.289.523.192.138.51.274.953.406.443.13.798.28 1.066.45.269.166.465.359.59.578.125.216.188.47.188.761 0 .474-.183.852-.547 1.133-.362.279-.854.418-1.477.418-.411 0-.79-.075-1.137-.226a1.936 1.936 0 01-.804-.633 1.58 1.58 0 01-.285-.938h.988c0 .323.107.573.32.75.214.177.52.266.918.266.344 0 .602-.07.774-.207a.678.678 0 00.261-.555zM13.637 12.562l1.445-4.45h1.086L14.121 13.8h-.957l-2.039-5.687h1.082l1.43 4.449z", fill: themeEnrichedPrimaryColor })));
};
FileCSV.defaultProps = defaultIconProps;
export default FileCSV;
