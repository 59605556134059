import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import api from '../api';
import type SpotnanaError from '../api/SpotnanaError';
import { LoginState, useAuth, useIsLoggedIn, useLoggedInUserId } from '../providers/AuthProvider';
import type { WhiteLabelConfig } from '../types';

const getWhiteLabelConfigKey = (userId: string | undefined) => ['white_label_query_key', userId];

const getUnauthWhiteLabelConfigKey = (spotnanaSubdomain: string | undefined) => [
  'unauth_white_label_query_key',
  spotnanaSubdomain,
];

function fetchWhiteLabelConfig(userId: string | undefined): Promise<WhiteLabelConfig> {
  return api('GET', 'userBaseUrl', { urlParam: `/${userId}/white-label-config` }).then(
    (response: unknown) => (response as { whiteLabelConfig: WhiteLabelConfig }).whiteLabelConfig,
  );
}

export const useWhiteLabelConfig = () => {
  const { loginState } = useAuth();

  const loggedInUserId = useLoggedInUserId();

  const whiteLabelConfigQuery = useQuery<WhiteLabelConfig, SpotnanaError>({
    queryKey: getWhiteLabelConfigKey(loggedInUserId?.userId?.id),
    queryFn: () => fetchWhiteLabelConfig(loggedInUserId?.userId?.id),
    enabled: !!loggedInUserId?.userId?.id,
  });

  return {
    ...whiteLabelConfigQuery,
    isLoading: loginState === LoginState.DEFAULT || whiteLabelConfigQuery.isLoading,
  };
};

async function fetchUnauthWhiteLabelConfig(
  spotnanaSubdomain: string | undefined = '',
): Promise<WhiteLabelConfig | 'SPOTNANA_DEFAULT_CONFIG'> {
  let data: WhiteLabelConfig | 'SPOTNANA_DEFAULT_CONFIG' | undefined;
  try {
    data = (await api('GET', 'unauthWhiteLabelConfig', {
      urlParam: `?${new URLSearchParams({ spotnanaSubdomain }).toString()}`,
    })) as unknown as WhiteLabelConfig;
  } catch (e: any) {
    /**
     * we don't consider 404 as an actual error because it just means that
     * the subdomain doesn't have special unauth whitelabel config and can render normal spotnana UI
     */
    if ((e as AxiosError).response?.status === 404) {
      data = 'SPOTNANA_DEFAULT_CONFIG' as const;
    } else {
      throw e;
    }
  }

  return data as WhiteLabelConfig | 'SPOTNANA_DEFAULT_CONFIG';
}

export const useUnauthWhiteLabelConfig = (spotnanaSubdomain: string | undefined, config = { enabled: true }) => {
  const isUserLoggedIn = useIsLoggedIn();
  const whiteLabelConfigQuery = useQuery<WhiteLabelConfig | 'SPOTNANA_DEFAULT_CONFIG', SpotnanaError>({
    queryKey: getUnauthWhiteLabelConfigKey(spotnanaSubdomain),
    queryFn: () => fetchUnauthWhiteLabelConfig(spotnanaSubdomain),
    enabled: Boolean(spotnanaSubdomain) && config.enabled && !isUserLoggedIn,
  });

  return whiteLabelConfigQuery;
};
