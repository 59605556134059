import type { ComponentType } from 'react';
import * as React from 'react';

export type ProviderType = ComponentType<any> | [ComponentType<any>, { [name: string]: any }];

interface IProps {
  providers: ProviderType[];
  children: React.ReactNode;
}

export function ComposeProviders(props: IProps): JSX.Element {
  const { providers = [], children } = props;

  return (
    <>
      {providers.reduceRight((accumulatedChildren, ProviderComponent) => {
        if (Array.isArray(ProviderComponent)) {
          const [Component, componentProps] = ProviderComponent;
          return <Component {...componentProps}>{accumulatedChildren}</Component>;
        }

        return <ProviderComponent>{accumulatedChildren}</ProviderComponent>;
      }, children)}
    </>
  );
}
