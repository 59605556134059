import { useLoggedInUser, useUserAuthConfigQuery } from 'obt-common';

import { isSessionTimeoutValid } from './utils';
import { IdleSessionTimeoutModalContainer } from './components/IdleSessionTimeoutModalContainer';
import { AbsoluteSessionTimeoutModalContainer } from './components/AbsoluteSessionTimeoutModalContainer';

export function SessionTimeoutModal() {
  const { data: userInfo } = useLoggedInUser();
  const { data: authConfig } = useUserAuthConfigQuery(userInfo?.userOrgId?.userId?.id);

  const idleSessionTimeoutMin = authConfig?.idleSessionTimeoutMin ?? 0;
  const absoluteSessionTimeoutMin = authConfig?.absoluteSessionTimeoutMin ?? 0;

  const isIdleSessionTimeoutValid = isSessionTimeoutValid(idleSessionTimeoutMin);
  const isAbsoluteSessionTimeoutValid = isSessionTimeoutValid(absoluteSessionTimeoutMin);

  return (
    <>
      {/* Idle Session Timeout checks for user inactivity and extend session if user is active. */}
      {isIdleSessionTimeoutValid && <IdleSessionTimeoutModalContainer idleSessionTimeoutMin={idleSessionTimeoutMin} />}
      {/* Absolute Session Timeout logs out the user in provided period of time despite everything. */}
      {isAbsoluteSessionTimeoutValid && (
        <AbsoluteSessionTimeoutModalContainer absoluteSessionTimeoutMin={absoluteSessionTimeoutMin} />
      )}
    </>
  );
}
