import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const UploadThin = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M5.49432 16.9992C5.08159 16.9992 4.72938 16.8568 4.43772 16.572C4.14605 16.2873 4.00022 15.9449 4.00022 15.5451L3.94922 14.4989C3.94922 14.0974 4.28501 13.7719 4.69922 13.7719C5.11343 13.7719 5.44922 14.0974 5.44922 14.4989L5.50022 15.5451H14.5002V14.4989C14.5002 14.0974 14.836 13.7719 15.2502 13.7719C15.6644 13.7719 16.0002 14.0974 16.0002 14.4989V15.5451C16.0002 15.9449 15.8533 16.2873 15.5593 16.572C15.2654 16.8568 14.9121 16.9992 14.4994 16.9992H5.49432ZM10.0002 14.0909C9.586 14.0909 9.25022 13.7654 9.25022 13.3639L9.21899 5.75786L7.56274 7.36344C7.26934 7.64787 6.79365 7.64787 6.50024 7.36344C6.20684 7.07902 6.20684 6.61787 6.50024 6.33345L8.90834 3.99902C9.49412 3.43116 10.4439 3.43116 11.0297 3.99902L13.4377 6.33345C13.7311 6.61787 13.7311 7.07902 13.4377 7.36344C13.1443 7.64787 12.6686 7.64787 12.3752 7.36344L10.719 5.75786L10.7502 13.3639C10.7502 13.7654 10.4144 14.0909 10.0002 14.0909Z", fill: themeEnrichedPrimaryColor })));
};
UploadThin.defaultProps = defaultIconProps;
export default UploadThin;
