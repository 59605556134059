import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const NonRefundable = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.56002 7.84725C6.31276 7.6 6.31245 7.19572 6.56436 6.94381C6.814 6.69417 7.2183 6.68996 7.4678 6.93946L11.44 10.9026C11.6872 11.1498 11.6875 11.5541 11.4356 11.806C11.186 12.0557 10.7817 12.0599 9.94886 11.227L6.56002 7.84725Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.5278 6.93502C10.7751 6.68776 11.1794 6.68745 11.4313 6.93936C11.6809 7.189 11.6851 7.5933 11.4356 7.8428L7.47251 11.815C7.22526 12.0622 6.82098 12.0626 6.56907 11.8106C6.31943 11.561 6.31523 11.1567 6.56473 10.9072L10.5278 6.93502Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.4652 2.11562C14.3959 1.73272 14.0415 1.46259 13.6483 1.50423L13.5929 1.51218C13.21 1.58152 12.9399 1.93587 12.9815 2.32906L13.077 3.23165C12.1444 2.59742 11.0686 2.17888 9.91623 2.02129C5.8607 1.4666 2.12348 4.30498 1.56936 8.36081C1.01418 12.4161 3.85248 16.1534 7.90842 16.7076C11.9639 17.2622 15.7009 14.4243 16.2561 10.3687C16.4022 9.29539 16.314 8.22273 16.0054 7.20919C15.8847 6.81294 15.4657 6.58952 15.0694 6.71018C14.6732 6.83083 14.4498 7.24987 14.5704 7.64612C14.8163 8.45371 14.8866 9.30835 14.7699 10.1658C14.3271 13.4002 11.3464 15.6638 8.11156 15.2214C4.87646 14.7794 2.61271 11.7986 3.05553 8.56406C3.49752 5.32891 6.47831 3.06504 9.71297 3.50745C10.6518 3.63585 11.5253 3.9803 12.2791 4.50334L11.1505 4.62314L11.095 4.63109C10.7121 4.70049 10.442 5.05488 10.4837 5.44806C10.5274 5.85996 10.8967 6.15846 11.3087 6.11477L14.1054 5.81816L14.1608 5.81021C14.5437 5.74082 14.8137 5.38649 14.7721 4.99334L14.4732 2.17106L14.4652 2.11562Z", fill: themeEnrichedPrimaryColor })));
};
NonRefundable.defaultProps = defaultIconProps;
export default NonRefundable;
