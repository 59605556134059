import NProgress from 'accessible-nprogress';
import { useEffect } from 'react';
import { Config } from 'obt-common';
import { useEmbedStore } from '../EmbedContext';

/**
 * Global state as the next NProgress.start call can happen before the previous NProgress.done call
 */
let isProgressBarActive = false;

export function RouteLoadingBar(): JSX.Element | null {
  const { isEmbed } = useEmbedStore();
  /**
   * Avoid unresolved promises in Jest env
   */
  const isEmbedOrTesting = isEmbed || Config.VITE_ENVIRONMENT === 'testing';

  useEffect(() => {
    if (!isEmbedOrTesting) {
      NProgress.start();
      isProgressBarActive = true;
    }

    return () => {
      /**
       * We make the progress bar end a little later than the lazy component is loaded to the DOM
       * This is to have a good perceived UX where the loader doesn't appear and disappear too quickly,
       * which can be jarring to the user. This is especially true for components that load very quickly.
       */
      if (!isEmbedOrTesting) {
        setTimeout(() => {
          if (isProgressBarActive) {
            NProgress.done();
            isProgressBarActive = false;
          }
        }, 200);
      }
    };
  }, [isEmbedOrTesting]);

  return null;
}
