var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const LocalBar = (_a) => {
    var { primaryColor } = _a, restProps = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M12 42V39H22.5V26.8L6 9V6H42V9L25.5 26.8V39H36V42ZM14.05 13.25H33.95L38.1 9.2H9.9ZM24 24.05 31.2 16.25H16.8ZM24 24.05Z" })));
};
LocalBar.defaultProps = defaultIconProps;
export default LocalBar;
