/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CardCompany as CardCompanyV1 } from '../../../v1/common/card';

/**
 * The issuing company of card
 * @export
 * @enum {string}
 */

export const CardCompany = {
  None: 'NONE',
  Visa: 'VISA',
  Mastercard: 'MASTERCARD',
  Amex: 'AMEX',
  Discover: 'DISCOVER',
  AirTravelUatp: 'AIR_TRAVEL_UATP',
  CarteBlanche: 'CARTE_BLANCHE',
  DinersClub: 'DINERS_CLUB',
  Jcb: 'JCB',
  Brex: 'BREX',
  UnionPay: 'UNION_PAY',
} as const;

export type CardCompany = (typeof CardCompany)[keyof typeof CardCompany];

export const cardCompanyV1ToV2 = (cardCompany: CardCompanyV1): CardCompany => {
  switch (cardCompany) {
    case CardCompanyV1.VISA:
      return CardCompany.Visa;
    case CardCompanyV1.MASTERCARD:
      return CardCompany.Mastercard;
    case CardCompanyV1.AMEX:
      return CardCompany.Amex;
    case CardCompanyV1.DISCOVER:
      return CardCompany.Discover;
    case CardCompanyV1.AIR_TRAVEL_UATP:
      return CardCompany.AirTravelUatp;
    case CardCompanyV1.CARTE_BLANCHE:
      return CardCompany.CarteBlanche;
    case CardCompanyV1.DINERS_CLUB:
      return CardCompany.DinersClub;
    case CardCompanyV1.JCB:
      return CardCompany.Jcb;
    case CardCompanyV1.BREX:
      return CardCompany.Brex;
    case CardCompanyV1.UNION_PAY:
      return CardCompany.UnionPay;
    default:
      return CardCompany.None;
  }
};
