import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FourSquares = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 0a2 2 0 00-2 2v12.701a2 2 0 002 2h12.701a2 2 0 002-2V2a2 2 0 00-2-2H2zm14.701 25.298a2 2 0 00-2-2H2a2 2 0 00-2 2V38a2 2 0 002 2h12.701a2 2 0 002-2V25.298zm-4-12.597V4H4v8.701h8.701zm0 14.597V36H4v-8.702h8.701zM38 0a2 2 0 012 2v12.701a2 2 0 01-2 2H25.298a2 2 0 01-2-2V2a2 2 0 012-2H38zm2 25.298a2 2 0 00-2-2H25.298a2 2 0 00-2 2V38a2 2 0 002 2H38a2 2 0 002-2V25.298zm-4-12.597V4h-8.702v8.701H36zm0 14.597V36h-8.702v-8.702H36z", fill: themeEnrichedPrimaryColor })));
};
FourSquares.defaultProps = defaultIconProps;
export default FourSquares;
