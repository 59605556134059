/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Travel class
 * @export
 * @enum {string}
 */

export const RailTravelClass = {
  Unknown: 'UNKNOWN',
  First: 'FIRST',
  Standard: 'STANDARD',
  Business: 'BUSINESS',
  Sleeper: 'SLEEPER',
  StandardPremium: 'STANDARD_PREMIUM',
  BusinessPremium: 'BUSINESS_PREMIUM',
  Coach: 'COACH',
  Room: 'ROOM',
  Executive: 'EXECUTIVE',
} as const;

export type RailTravelClass = (typeof RailTravelClass)[keyof typeof RailTravelClass];
