import { IconButton } from '@spotnana/blocks/src/IconButton';
import { Link } from '@spotnana/blocks/src/Link';
import { AgentLink } from '@spotnana/types/openapi/models/agent-link';
import { useCallback, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { link_ctr, url } from '../index.styles';
import { getFavIconUrl } from './Utils';

// LinkItem component represents an individual link in the list
const LinkItem = ({
  link,
  index,
  onEdit,
  onDelete,
}: {
  link: AgentLink;
  index: number;
  onEdit: () => void;
  onDelete?: () => void;
}): JSX.Element => {
  const { t: tt } = useTranslation('WEB');

  const favIconUrl = useMemo(() => getFavIconUrl(link.url), [link.url]);

  // Function to handle opening the link in a new tab
  const handleDownload = useCallback(() => {
    window.open(link.url, '_blank');
  }, [link.url]);

  return (
    <Draggable key={link.id} draggableId={`link-${link.id || index}`} index={index}>
      {(provided) => (
        <li
          key={link.title}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          css={link_ctr}
        >
          <div css={url}>
            <IconButton icon="DragIndicator" tooltip={tt('Drag handle')} size="medium" />
            {/* Display favicon for the link */}
            {favIconUrl && <img src={favIconUrl} alt={tt('Website Icon')} />}
            {/* Display the link title as a clickable link */}
            <Link onClick={handleDownload}>{link.title}</Link>
          </div>
          <div>
            <IconButton icon="Edit" tooltip={tt('Edit')} size="medium" onClick={onEdit} />
            <IconButton icon="Delete" tooltip={tt('Delete')} size="medium" onClick={onDelete} />
          </div>
        </li>
      )}
    </Draggable>
  );
};

export default LinkItem;
