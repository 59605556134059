import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const InfoV2 = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.0068 19.6004C8.68554 19.6004 7.44157 19.3504 6.2749 18.8504C5.10824 18.3504 4.0874 17.6629 3.2124 16.7879C2.3374 15.9129 1.6499 14.8924 1.1499 13.7264C0.649902 12.5605 0.399902 11.3146 0.399902 9.98894C0.399902 8.66324 0.649902 7.42122 1.1499 6.26289C1.6499 5.10456 2.3374 4.08789 3.2124 3.21289C4.0874 2.33789 5.10789 1.65039 6.27385 1.15039C7.43984 0.650391 8.68567 0.400391 10.0114 0.400391C11.3371 0.400391 12.5791 0.650391 13.7374 1.15039C14.8957 1.65039 15.9124 2.33789 16.7874 3.21289C17.6624 4.08789 18.3499 5.10642 18.8499 6.26849C19.3499 7.43057 19.5999 8.67224 19.5999 9.99349C19.5999 11.3148 19.3499 12.5587 18.8499 13.7254C18.3499 14.8921 17.6624 15.9129 16.7874 16.7879C15.9124 17.6629 14.8939 18.3504 13.7318 18.8504C12.5697 19.3504 11.3281 19.6004 10.0068 19.6004ZM11.14 5.95039C11.14 6.69598 10.5624 7.30039 9.84996 7.30039C9.13751 7.30039 8.55996 6.69598 8.55996 5.95039C8.55996 5.20481 9.13751 4.60039 9.84996 4.60039C10.5624 4.60039 11.14 5.20481 11.14 5.95039ZM9.2799 10.4204H9.0999C8.60285 10.4204 8.1999 10.0175 8.1999 9.5204C8.1999 9.02334 8.60285 8.6204 9.0999 8.6204H9.6399C10.4352 8.6204 11.0799 9.26511 11.0799 10.0604V14.0204H11.2599C11.757 14.0204 12.1599 14.4233 12.1599 14.9204C12.1599 15.4175 11.757 15.8204 11.2599 15.8204H10.7199C9.92462 15.8204 9.2799 15.1757 9.2799 14.3804V10.4204Z", fill: themeEnrichedPrimaryColor })));
};
InfoV2.defaultProps = defaultIconProps;
export default InfoV2;
