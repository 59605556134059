import produce from 'immer';
import keyBy from 'lodash/keyBy';
import type { AirSearchResponse } from '../../types/api/v1/obt/air/air_search_response';
import { mergeRateOptionRight } from '../mergeRateOptionRight';

export function mergeAirSearchRouteHappyResponse(
  response: AirSearchResponse,
  routeHappyResponse: AirSearchResponse,
): AirSearchResponse {
  const allItineraries = routeHappyResponse.itineraries;
  const allRateOptions = allItineraries.flatMap((itinerary) => itinerary.rateOptions);
  const allRateOptionsById = keyBy(allRateOptions, (rateOption) => rateOption.rateOptionId);

  return produce(response, (draftResponse) => {
    draftResponse.data.amenities = routeHappyResponse.data.amenities;
    draftResponse.data.utas = routeHappyResponse.data.utas;
    draftResponse.data.upas = routeHappyResponse.data.upas;

    if (draftResponse.currSelectedItinerary) {
      draftResponse.currSelectedItinerary.rateOptions.forEach((rateOption) => {
        const routeHappyRateOption = allRateOptionsById[rateOption.rateOptionId];
        if (routeHappyRateOption) {
          mergeRateOptionRight(rateOption, routeHappyRateOption);
        }
      });
    }

    draftResponse.itineraries.forEach((itinerary) => {
      itinerary.rateOptions.forEach((rateOption) => {
        const routeHappyRateOption = allRateOptionsById[rateOption.rateOptionId];
        if (routeHappyRateOption) {
          mergeRateOptionRight(rateOption, routeHappyRateOption);
        }
      });
    });
  });
}
