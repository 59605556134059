import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Warning = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M8.93 8.929c3.904-3.905 10.236-3.905 14.14 0 3.906 3.905 3.906 10.236 0 14.142-3.904 3.905-10.236 3.905-14.14 0-3.906-3.906-3.906-10.237 0-14.142zm12.727 1.414c-3.124-3.124-8.19-3.124-11.314 0-3.124 3.124-3.124 8.19 0 11.314 3.124 3.124 8.19 3.124 11.314 0 3.124-3.125 3.124-8.19 0-11.314zM16 19.123c.523 0 .952.402.996.914l.004.086v-.014c0 .553-.448 1-1 1-.523 0-.952-.401-.996-.913l-.004-.08.008-.118c.061-.493.482-.875.992-.875zm0-8.866c.523 0 .952.402.996.913l.004.087v5.986c0 .552-.448 1-1 1-.523 0-.952-.402-.996-.914L15 17.243v-5.986c0-.552.448-1 1-1z" })));
};
Warning.defaultProps = defaultIconProps;
export default Warning;
