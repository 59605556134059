/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Third party
 * @export
 * @enum {string}
 */

export const RailThirdParty = {
  UnknownThirdParty: 'UNKNOWN_THIRD_PARTY',
  Trainline: 'TRAINLINE',
  Amtrak: 'AMTRAK'
} as const;

export type RailThirdParty = typeof RailThirdParty[keyof typeof RailThirdParty];