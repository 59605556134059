/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Rate type.
 * @export
 * @enum {string}
 */

export const CarRateComponentType = {
  UnknownType: 'UNKNOWN_TYPE',
  DropOffCharge: 'DROP_OFF_CHARGE',
  ExtraDay: 'EXTRA_DAY',
  ExtraHour: 'EXTRA_HOUR',
  BaseRate: 'BASE_RATE',
  BaseRateTotal: 'BASE_RATE_TOTAL',
  SubtotalExcludingMandatoryCharges: 'SUBTOTAL_EXCLUDING_MANDATORY_CHARGES',
  DailyChargesTotal: 'DAILY_CHARGES_TOTAL',
  HourlyChargesTotal: 'HOURLY_CHARGES_TOTAL',
  MandatoryCharges: 'MANDATORY_CHARGES',
  MandatoryChargesTotal: 'MANDATORY_CHARGES_TOTAL',
  ApproximateTotalPrice: 'APPROXIMATE_TOTAL_PRICE',
  CarExtraTotalCharge: 'CAR_EXTRA_TOTAL_CHARGE',
  ApproximateBaseFare: 'APPROXIMATE_BASE_FARE',
  TotalTax: 'TOTAL_TAX',
  PublishedBaseFare: 'PUBLISHED_BASE_FARE',
  PublishedTotalTax: 'PUBLISHED_TOTAL_TAX',
  ApproximateDailyAverageTotal: 'APPROXIMATE_DAILY_AVERAGE_TOTAL',
  ApproximateDailyAverageBase: 'APPROXIMATE_DAILY_AVERAGE_BASE',
  ApproximateDailyAverageTax: 'APPROXIMATE_DAILY_AVERAGE_TAX',
  PublishedDailyTotal: 'PUBLISHED_DAILY_TOTAL',
  PublishedDailyBaseFare: 'PUBLISHED_DAILY_BASE_FARE',
  PublishedDailyTotalTax: 'PUBLISHED_DAILY_TOTAL_TAX',
  CarModifyRateDifference: 'CAR_MODIFY_RATE_DIFFERENCE',
  CarModifyAverageRateDifference: 'CAR_MODIFY_AVERAGE_RATE_DIFFERENCE',
} as const;

export type CarRateComponentType = (typeof CarRateComponentType)[keyof typeof CarRateComponentType];
