import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Settings = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 96 96", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M41.22 8l-.298.011A4 4 0 0037.22 12l-.001 7.992-.708.282-.69.3-5.653-5.652a4 4 0 00-5.656 0l-9.59 9.587-.234.255a4 4 0 00.234 5.402l5.657 5.658-.294.681-.282.713L12 37.22a4 4 0 00-4 4v13.56l.011.298A4 4 0 0012 58.78l8-.002.285.717.294.68-5.657 5.659a4 4 0 000 5.657l9.59 9.588.254.233a4 4 0 005.402-.234l5.653-5.654.69.301.708.281L37.22 84a4 4 0 004 4h13.562l.299-.011A4 4 0 0058.782 84l-.001-7.992.711-.283.687-.3 5.655 5.654a4 4 0 005.656 0l9.588-9.589.234-.254a4 4 0 00-.233-5.402l-5.658-5.66.294-.675.282-.72L84 58.78a4 4 0 004-4V41.22l-.011-.298A4 4 0 0084 37.22l-8.003-.002-.282-.717-.294-.679 5.658-5.656a4 4 0 000-5.656l-9.589-9.588-.254-.234a4 4 0 00-5.402.233l-5.655 5.653-.687-.299-.711-.285.001-7.99a4 4 0 00-4-4H41.22zm3.999 7.998h5.562l.001 6.882a4 4 0 002.95 3.86 22.051 22.051 0 015.228 2.177l.298.156a4 4 0 004.528-.792l4.875-4.875 3.932 3.932-4.874 4.876a4 4 0 00-.636 4.826 21.79 21.79 0 012.157 5.221l.094.303a4 4 0 003.768 2.656l6.897-.002v5.56l-6.897.002a4 4 0 00-3.862 2.959 21.79 21.79 0 01-2.157 5.22l-.156.3a4 4 0 00.792 4.527l4.874 4.874-3.932 3.932-4.875-4.873a4 4 0 00-4.826-.636 22.051 22.051 0 01-5.228 2.177l-.301.095a4 4 0 00-2.649 3.765l-.001 6.878h-5.562l.001-6.878a4 4 0 00-2.95-3.86 22.106 22.106 0 01-5.231-2.178l-.299-.156a4 4 0 00-4.526.794l-4.875 4.872-3.932-3.932 4.875-4.874a4 4 0 00.637-4.826 21.71 21.71 0 01-2.156-5.218l-.094-.303A4 4 0 0022.9 50.78l-6.901-.002v-5.56l6.901.002a4 4 0 003.863-2.962 21.71 21.71 0 012.156-5.218l.156-.298a4 4 0 00-.793-4.528l-4.875-4.876 3.932-3.932 4.875 4.874a4 4 0 004.825.638 22.106 22.106 0 015.23-2.178l.302-.095a4 4 0 002.649-3.765l-.001-6.882zm2.78 16.382c-8.625 0-15.618 6.994-15.618 15.62 0 8.627 6.993 15.62 15.618 15.62 8.627 0 15.622-6.993 15.622-15.62 0-8.626-6.995-15.62-15.622-15.62zm0 8A7.621 7.621 0 1140.381 48 7.62 7.62 0 0148 40.38z", fill: themeEnrichedPrimaryColor })));
};
Settings.defaultProps = defaultIconProps;
export default Settings;
