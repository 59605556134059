/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * For ELECTRONIC_TICKET, it contains e-ticket file info.
 * @export
 * @interface ETicketMetadata
 */
export interface ETicketMetadata {
    /**
     * E-ticket file name.
     * @type {string}
     * @memberof ETicketMetadata
     */
    'description'?: string;
    /**
     * E-ticket data as bytes.
     * @type {string}
     * @memberof ETicketMetadata
     */
    'eticketData': string;
    /**
     * Type of ticket.
     * @type {string}
     * @memberof ETicketMetadata
     */
    'type': ETicketMetadataTypeEnum;
}

export const ETicketMetadataTypeEnum = {
    UnknownType: 'UNKNOWN_TYPE',
    Pdf: 'PDF',
    ApplePkPass: 'APPLE_PK_PASS',
    Html: 'HTML',
    CollectionNumber: 'COLLECTION_NUMBER'
} as const;

export type ETicketMetadataTypeEnum = typeof ETicketMetadataTypeEnum[keyof typeof ETicketMetadataTypeEnum];


