import { useCallback, useRef } from 'react';

function useCallbackWithoutDeps<T extends Function>(cb: T): T {
  const openSearchRef = useRef<T>(cb);

  openSearchRef.current = cb;

  return useCallback<T>(
    // @ts-expect-error - have to be as `T` type
    (...args: any[]) => openSearchRef.current(...args),
    [],
  );
}

export default useCallbackWithoutDeps;
