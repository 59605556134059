import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Statistics = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: themeEnrichedPrimaryColor, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.803 6a.75.75 0 00-.75-.75H5.25l-.056.002A.75.75 0 004.5 6v11.638l.002.056a.75.75 0 00.748.694h13.5l.056-.002a.75.75 0 00.694-.748v-2.394l-.002-.056a.75.75 0 00-.748-.694l-.056.002a.75.75 0 00-.694.748v1.644H6v-1.592l4.575-4.575 1.727 1.727.048.044a.75.75 0 001.013-.044L18 7.811v2.077l.002.056a.75.75 0 001.498-.056V6.011a.748.748 0 00-.75-.76h-3.888l-.056.001a.75.75 0 00-.694.748l.002.056a.75.75 0 00.748.694h2.077l-4.107 4.107-1.727-1.726-.048-.044a.75.75 0 00-1.013.044L6 13.175V6.75h3.053l.055-.002A.75.75 0 009.802 6z" })));
};
Statistics.defaultProps = defaultIconProps;
export default Statistics;
