import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const fare_header_container = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.justify.between}
    ${flex.align.center}
    background-color: ${palette.surface.base};
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom: 1px solid ${palette.border.light};
    ${paddings.y.compact}
    ${paddings.x.comfortable}
  `,
);

export const fare_header_expired = themed(
  ({ palette }) => css`
    background-color: ${palette.bg.brandSubtle};
  `,
);

export const disabled_fare_text = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.align.center}
    ${spacings.small}
    color: ${palette.bg.brand};
  `,
);

export const enabled_fare_text = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.smallest}
`;

export const option_underline = css`
  text-decoration: underline;
  text-underline-offset: 2px;
`;
