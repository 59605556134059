/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserFacingStatus as UserFacingStatusV1 } from '../../../v1/obt/pnr/pnr';

/**
 * User facing booking status
 * @export
 * @enum {string}
 */

export const UserFacingStatus = {
  UnknownStatus: 'UNKNOWN_STATUS',
  PendingStatus: 'PENDING_STATUS',
  ConfirmedStatus: 'CONFIRMED_STATUS',
  ActiveStatus: 'ACTIVE_STATUS',
  CompletedStatus: 'COMPLETED_STATUS',
  CancelledStatus: 'CANCELLED_STATUS',
  RefundedStatus: 'REFUNDED_STATUS',
  VoidedStatus: 'VOIDED_STATUS',
  ProcessingStatus: 'PROCESSING_STATUS',
  UnconfirmedStatus: 'UNCONFIRMED_STATUS',
  AirlineControlStatus: 'AIRLINE_CONTROL_STATUS',
  PaymentDeclinedStatus: 'PAYMENT_DECLINED_STATUS',
  ScheduleChangeStatus: 'SCHEDULE_CHANGE_STATUS',
  HoldStatus: 'HOLD_STATUS',
  ApprovalRequestedStatus: 'APPROVAL_REQUESTED_STATUS',
  ApprovalDeniedStatus: 'APPROVAL_DENIED_STATUS',
  CancellationInProgressStatus: 'CANCELLATION_IN_PROGRESS_STATUS',
  InoperativeStatus: 'INOPERATIVE_STATUS',
  FlightUnconfirmedStatus: 'FLIGHT_UNCONFIRMED_STATUS',
} as const;

export type UserFacingStatus = (typeof UserFacingStatus)[keyof typeof UserFacingStatus];

export const userFacingStatusV1ToV2 = (v1Status: UserFacingStatusV1) => {
  switch (v1Status) {
    case UserFacingStatusV1.PENDING_STATUS:
      return UserFacingStatus.PendingStatus;
    case UserFacingStatusV1.CONFIRMED_STATUS:
      return UserFacingStatus.ConfirmedStatus;
    case UserFacingStatusV1.ACTIVE_STATUS:
      return UserFacingStatus.ActiveStatus;
    case UserFacingStatusV1.COMPLETED_STATUS:
      return UserFacingStatus.CompletedStatus;
    case UserFacingStatusV1.CANCELLED_STATUS:
      return UserFacingStatus.CancelledStatus;
    case UserFacingStatusV1.REFUNDED_STATUS:
      return UserFacingStatus.RefundedStatus;
    case UserFacingStatusV1.VOIDED_STATUS:
      return UserFacingStatus.VoidedStatus;
    case UserFacingStatusV1.PROCESSING_STATUS:
      return UserFacingStatus.ProcessingStatus;
    case UserFacingStatusV1.UNCONFIRMED_STATUS:
      return UserFacingStatus.UnconfirmedStatus;
    case UserFacingStatusV1.AIRLINE_CONTROL_STATUS:
      return UserFacingStatus.AirlineControlStatus;
    case UserFacingStatusV1.PAYMENT_DECLINED_STATUS:
      return UserFacingStatus.PaymentDeclinedStatus;
    case UserFacingStatusV1.SCHEDULE_CHANGE_STATUS:
      return UserFacingStatus.ScheduleChangeStatus;
    case UserFacingStatusV1.HOLD_STATUS:
      return UserFacingStatus.HoldStatus;
    case UserFacingStatusV1.APPROVAL_REQUESTED_STATUS:
      return UserFacingStatus.ApprovalRequestedStatus;
    case UserFacingStatusV1.APPROVAL_DENIED_STATUS:
      return UserFacingStatus.ApprovalDeniedStatus;
    case UserFacingStatusV1.CANCELLATION_IN_PROGRESS_STATUS:
      return UserFacingStatus.CancellationInProgressStatus;
    case UserFacingStatusV1.INOPERATIVE_STATUS:
      return UserFacingStatus.InoperativeStatus;
    case UserFacingStatusV1.FLIGHT_UNCONFIRMED_STATUS:
      return UserFacingStatus.FlightUnconfirmedStatus;
    default:
      return UserFacingStatus.UnknownStatus;
  }
};
