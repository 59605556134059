/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { TimeLocal } from './time-local';

/**
 * Hotel special requests
 * @export
 * @interface HotelSpecialRequests
 */
export interface HotelSpecialRequests {
  /**
   * Room Location special request
   * @type {Array<string>}
   * @memberof HotelSpecialRequests
   */
  roomLocations?: Array<HotelSpecialRequestsRoomLocationsEnum>;
  /**
   * Room Features List
   * @type {Array<string>}
   * @memberof HotelSpecialRequests
   */
  roomFeatures?: Array<HotelSpecialRequestsRoomFeaturesEnum>;
  /**
   * Early or Late Check-in
   * @type {string}
   * @memberof HotelSpecialRequests
   */
  checkIn?: HotelSpecialRequestsCheckInEnum;
  /**
   *
   * @type {TimeLocal}
   * @memberof HotelSpecialRequests
   */
  checkInTime?: TimeLocal;
  /**
   * Attach flight number
   * @type {string}
   * @memberof HotelSpecialRequests
   */
  flightNumber?: string;
  /**
   * Free form text to describe special request
   * @type {string}
   * @memberof HotelSpecialRequests
   */
  additionalNote?: string;
  /**
   * Accessible Features List
   * @type {Array<string>}
   * @memberof HotelSpecialRequests
   */
  accessibleFeatures?: Array<HotelSpecialRequestsAccessibleFeaturesEnum>;
}

export const HotelSpecialRequestsRoomLocationsEnum = {
  HighFloor: 'HIGH_FLOOR',
  LowFloor: 'LOW_FLOOR',
} as const;

export type HotelSpecialRequestsRoomLocationsEnum =
  (typeof HotelSpecialRequestsRoomLocationsEnum)[keyof typeof HotelSpecialRequestsRoomLocationsEnum];
export const HotelSpecialRequestsRoomFeaturesEnum = {
  Crib: 'CRIB',
  RollawayBed: 'ROLLAWAY_BED',
  FeatherFreeRoom: 'FEATHER_FREE_ROOM',
  AccessibleRoom: 'ACCESSIBLE_ROOM',
  NearElevator: 'NEAR_ELEVATOR',
} as const;

export type HotelSpecialRequestsRoomFeaturesEnum =
  (typeof HotelSpecialRequestsRoomFeaturesEnum)[keyof typeof HotelSpecialRequestsRoomFeaturesEnum];
export const HotelSpecialRequestsCheckInEnum = {
  EarlyCheckIn: 'EARLY_CHECK_IN',
  LateCheckIn: 'LATE_CHECK_IN',
} as const;

export type HotelSpecialRequestsCheckInEnum =
  (typeof HotelSpecialRequestsCheckInEnum)[keyof typeof HotelSpecialRequestsCheckInEnum];
export const HotelSpecialRequestsAccessibleFeaturesEnum = {
  MobilityAccessibleRoomWithTub: 'MOBILITY_ACCESSIBLE_ROOM_WITH_TUB',
  MobilityAccessibleRoomWithRollInShower: 'MOBILITY_ACCESSIBLE_ROOM_WITH_ROLL_IN_SHOWER',
  HearingAccessibleRoom: 'HEARING_ACCESSIBLE_ROOM',
  MobilityAccessibleRoomWithTubAndRollInShower: 'MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_ROLL_IN_SHOWER',
  MobilityAccessibleRoomWithTubAndHearingAccessibleRoom:
    'MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_HEARING_ACCESSIBLE_ROOM',
  MobilityAccessibleRoomWithRollInShowerAndHearingAccessibleRoom:
    'MOBILITY_ACCESSIBLE_ROOM_WITH_ROLL_IN_SHOWER_AND_HEARING_ACCESSIBLE_ROOM',
  MobilityAccessibleRoomWithTransferShower: 'MOBILITY_ACCESSIBLE_ROOM_WITH_TRANSFER_SHOWER',
  MobilityAccessibleRoomWithTubAndTransferShower: 'MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_TRANSFER_SHOWER',
  MobilityAccessibleRoomWithTransferShowerAndHearingAccessibleRoom:
    'MOBILITY_ACCESSIBLE_ROOM_WITH_TRANSFER_SHOWER_AND_HEARING_ACCESSIBLE_ROOM',
} as const;

export type HotelSpecialRequestsAccessibleFeaturesEnum =
  (typeof HotelSpecialRequestsAccessibleFeaturesEnum)[keyof typeof HotelSpecialRequestsAccessibleFeaturesEnum];
