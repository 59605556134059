import * as React from 'react';
import Box from '@spotnana/pixel-react/dist/Box';

type DotProps = {
  small?: boolean;
  light?: boolean;
};

const Dot: React.FC<DotProps> = ({ small, light }: DotProps) => (
  <Box width={small ? 4 : 8} height={small ? 4 : 8} borderRadius={8} backgroundColor={light ? '#c4ced8' : '#1d1d1f'} />
);

export default Dot;
