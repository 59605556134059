import { FC } from 'react';
import { AirSelectedItineraryResponseManager, getFlightNumberDisplayText, UtaPolicyAssessmentType } from 'obt-common';
import { IOBTShopFeaturesConfig } from 'obt-common/hooks/useOBTShopFeatures';
import { Divider } from '@spotnana/blocks/src/Divider';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Icon } from '@spotnana/blocks/src/Icon';
import { UtaPolicy } from 'obt-common/types/api/v1/obt/air/routehappy';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import { dateUtil } from 'obt-common/date-utils';
import { localiseDate } from '@spotnana/blocks/src/utils/dates';
import FlightInfo from '../../FlightSearchResultsPage/FlightInfo';
import { success_icon, error_icon, utas_container, uta_item, date_container, flight_info_container } from './styles';

interface ItineraryLegsSummaryProps {
  airSelectedItineraryResponseManager: AirSelectedItineraryResponseManager;
  airSearchResultsFeatures: IOBTShopFeaturesConfig;
}

export const assessmentIcon = (uta?: UtaPolicy) => {
  /* istanbul ignore next: sonar coverage issue. Covered by tests */
  switch (uta?.assessmentType) {
    case UtaPolicyAssessmentType.RESTRICTION:
    case UtaPolicyAssessmentType.FEE:
      return <Icon css={error_icon} name="CloseCross" data-testid="restriction-policy" />;
    case UtaPolicyAssessmentType.BENEFIT:
      return <Icon css={success_icon} name="Check" data-testid="benefit-policy" />;
    default:
      return <Icon name="AmenityUnknown" data-testid="unknown-policy" />;
  }
};

const ItineraryLegsSummary: FC<ItineraryLegsSummaryProps> = ({
  airSelectedItineraryResponseManager,
  airSearchResultsFeatures,
}) => {
  const { t: tt } = useTranslation('WEB');
  const itineraryAllLegs = airSelectedItineraryResponseManager.GetItineraryAllLegs();

  return (
    <>
      {itineraryAllLegs.map((leg) => {
        const legSummary = airSelectedItineraryResponseManager.GetLegSummary({
          itinIndex: 0,
          legNumber: leg.legIndex,
        });

        const legFlightDetails = airSelectedItineraryResponseManager.GetLegFlightDetails(0, leg.legIndex);
        const flightNumbersDisplayText = getFlightNumberDisplayText(legFlightDetails);
        const legUtas = airSelectedItineraryResponseManager.GetLegUTAsForSelectedItinerary(leg.legNumber);
        const cancelOrChangeUtas = legUtas.filter((uta) => uta.key === 'cancellation' || uta.key === 'advanceChange');

        const isLast = leg.legIndex === itineraryAllLegs.length - 1;

        const departureDate = dateUtil(legSummary.departureDateTime).toDate();
        const localisedDepartureDate = localiseDate(departureDate, 'full-compact');

        return (
          <div key={leg.legNumber}>
            <div css={date_container}>
              <Typography variant="body1" color="primary" kind="semibold">
                {tt('Leg {{legNumber}}', { legNumber: leg.legNumber + 1 })}
              </Typography>
              <Typography variant="body2" color="secondary">
                {localisedDepartureDate}
              </Typography>
            </div>
            <FlightInfo
              airSearchResultsFeatures={airSearchResultsFeatures}
              summary={legSummary}
              onFlightDetailsClick={noop}
              showFlightDetails={false}
              flightNumbersDisplayText={flightNumbersDisplayText}
              totalCo2Emission={null}
              contentContainerCss={flight_info_container}
            />
            <div css={utas_container}>
              {cancelOrChangeUtas.map((uta) => {
                return (
                  <div key={uta.key} css={uta_item}>
                    {assessmentIcon(uta.uta.cancellation || uta.uta.advanceChange)}
                    <Typography variant="body2" color="secondary">
                      {uta.displayText.toString()}
                    </Typography>
                  </div>
                );
              })}
            </div>
            {!isLast && (
              <div>
                <Divider />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ItineraryLegsSummary;
