import * as React from 'react';
import {
  useLoggedInUserId,
  useSupportConfigForCurrentOrganization,
  useFetchZendeskChatToken,
  logger,
} from 'obt-common';

import { GetZendeskChatTokenResponse } from 'obt-common/types/api/v2/obt/model/get-zendesk-chat-token-response';
import { useEmbedStore } from '../../../components/EmbedContext';

declare global {
  interface Window {
    zdWidget: any;
    MyCWT: {
      close: () => undefined;
      init: (object: unknown) => Promise<undefined>;
      on: (event: string, cb: (data: unknown) => void) => void;
    };
    MyCWTInternal: unknown;
  }
}

interface ZendeskChatProps {
  userId: string;
  integrationId: string;
  zendeskChatToken: GetZendeskChatTokenResponse;
  fetchNewToken: () => Promise<string>;
}

function loadCwtScript() {
  /* notes: CWT customized zendesk script 
    Decided not use Spotnana useScript to load, because CWT uses a stud loader script to load a json file,
    then based on json file, load the real script and create an CWT object at window root. There are logic
    in CWT stud script to generate certain .js file name. We should avoid maintaining their script.
    The stud scirpt loader is expected to be stable. 
  */
  try {
    const script =
      // eslint-disable-next-line no-template-curly-in-string
      '!function(o,i,s,c, bucketUrl){var p,a,u,h=[],l=[];function e(){var t="You must provide a supported major version.";try{if(!c)throw new Error(t);var e,n=`${bucketUrl}`,r="mycwt";if((e="string"==typeof this.response?JSON.parse(this.response):this.response).url){var o=i.getElementsByTagName("script")[0],s=i.createElement("script");s.async=!0;var p=c.match(/([0-9]+).?([0-9]+)?.?([0-9]+)?/),a=p&&p[1];if(p&&p[3])s.src=n+r+"."+c+".min.js";else{if(!(1<=a&&e["v"+a]))throw new Error(t);s.src=e["v"+a]}o.parentNode.insertBefore(s,o)}}catch(e){e.message===t&&console.error(e)}}o[s]={init:function(){p=arguments;var t={then:function(e){return l.push({type:"t",next:e}),t},catch:function(e){return l.push({type:"c",next:e}),t}};return t},on:function(){h.push(arguments)},render:function(){a=arguments},destroy:function(){u=arguments}},o.__onWebMessengerHostReady__=function(e){if(delete o.__onWebMessengerHostReady__,o[s]=e,p)for(var t=e.init.apply(e,p),n=0;n<l.length;n++){var r=l[n];t="t"===r.type?t.then(r.next):t.catch(r.next)}a&&e.render.apply(e,a),u&&e.destroy.apply(e,u);for(n=0;n<h.length;n++)e.on.apply(e,h[n])};var t=new XMLHttpRequest;t.addEventListener("load",e),t.open("GET",`${bucketUrl}`,!0),t.responseType="json",t.send()}(window,document,"MyCWT","2", "https://cdn.worldmate.com/smooch/loader.json");';
    // eslint-disable-next-line no-eval
    eval(script);
  } catch (e) {
    logger.error(new Error('Loading CWT script error', { cause: { message: e.message } }));
  }
}

export function chatMsgScrollToViewHandler() {
  // Below logic is requested by CWT to scroll the last long message into chat view
  window.MyCWT.on('message:received', () => {
    const iFrame = document.getElementById('web-messenger-container') as HTMLIFrameElement;
    const messages =
      /* istanbul ignore next: to keep unit test not bloated */
      iFrame?.contentWindow?.document?.getElementsByClassName('left-row') || [];
    if (messages.length === 0) {
      return;
    }
    const lastMessage = messages[messages.length - 1];
    setTimeout(() => {
      lastMessage.scrollIntoView();
    });
  });
}

export function replaceEmailsWithMailto(text: string) {
  const emailRegex = /\S+@\S+\.\S+/;

  function replaceFunction(match: string) {
    return `<a href="mailto:${match}">${match}</a>`;
  }

  return text.replace(emailRegex, replaceFunction);
}

export function mailToHandler() {
  window.MyCWT.on('message:received', () => {
    setTimeout(() => {
      const texts = (
        document?.getElementById('web-messenger-container') as HTMLIFrameElement
      )?.contentWindow?.document?.getElementsByClassName('message-text');
      if (!texts || texts.length === 0) {
        return;
      }
      const lastText = texts[texts.length - 1];
      const spans = lastText.getElementsByTagName('span');
      for (const span of spans) {
        if (span?.children.length) {
          span.children[0].innerHTML = replaceEmailsWithMailto(span.children[0].innerHTML);
        }
      }
    });
  });
}

async function initZendeskWidget({ userId, zendeskChatToken, integrationId, fetchNewToken }: ZendeskChatProps) {
  async function initZendeskLocal() {
    return window.MyCWT.init({
      integrationId,
      jwt: zendeskChatToken.accessToken,
      externalId: userId,
      canUserSeeConversationList: false, // cannot start multiple conversations

      delegate: {
        beforeSend(message: { id: string; metadata: object }) {
          return { ...message, metadata: { external_user_id: userId } };
        },

        async onInvalidAuth() {
          const token = await fetchNewToken();
          return new Promise((resolve) => {
            if (token) {
              resolve(token);
            } else {
              logger.error(new Error('Could not re-authenticate zendesk smooch'));
            }
          });
        },
      },
    }).then(() => {
      window.zdWidget = window.MyCWT; // CWT custom script loaded
      chatMsgScrollToViewHandler();
      mailToHandler();
    });
  }

  try {
    if (window.MyCWT) {
      // When MyCWT object is loaded
      initZendeskLocal().catch(async (error) => {
        logger.error(new Error('Zendesk smooch init', { cause: error }));
      });
    } else {
      // When MyCWT objet is not loaded
      Object.defineProperties(window, {
        MyCWT: {
          get() {
            return window.MyCWTInternal;
          },
          set(val) {
            // Use this setter as a callback to manage init sequence
            window.MyCWTInternal = val;
            initZendeskLocal().catch(async (error) => {
              logger.error(new Error('Zendesk smooch init', { cause: error }));
            });
            // async setter is ignored, so if error, outer catch will not
            // be triggered, so have to add a catch here.
          },
        },
      });
    }
  } catch (error) {
    logger.error(new Error('Zendesk smooch init', { cause: error }));
  }
}

export function InitializeZendeskChat(): JSX.Element {
  const loggedInUserId = useLoggedInUserId();
  const { isLoading: isLoadingSupportConfig, data: supportConfig } = useSupportConfigForCurrentOrganization();

  const isZendeskChatEnabled = !isLoadingSupportConfig && !!supportConfig?.zendeskConfig?.enabled === true;
  const zendeskIntegrationId =
    isZendeskChatEnabled && supportConfig?.zendeskConfig?.integrationId
      ? supportConfig?.zendeskConfig?.integrationId
      : '';

  const {
    isLoading: isLoadingAuthToken,
    data: zendeskChatToken,
    refetch,
  } = useFetchZendeskChatToken({
    cacheTime: 0,
    enabled: isZendeskChatEnabled && !!zendeskIntegrationId,
  });

  const fetchNewToken = React.useCallback(async () => {
    try {
      const { data } = await refetch({
        throwOnError: true,
      });
      return data?.accessToken ?? '';
    } catch (error) {
      logger.error(new Error(`Couldn't refetch token`, { cause: error }));
      return '';
    }
  }, [refetch]);

  const { isEmbed } = useEmbedStore();

  const isLoading = Boolean(isLoadingSupportConfig || isLoadingAuthToken || isEmbed);

  React.useEffect(() => {
    if (!loggedInUserId.userId?.id || isLoading) {
      return;
    }
    const shouldInitZendesk = isZendeskChatEnabled && zendeskChatToken?.accessToken && !!zendeskIntegrationId;
    const shouldLoadCwtScript = shouldInitZendesk && !window.zdWidget;
    if (shouldLoadCwtScript) {
      loadCwtScript();
    }
    if (shouldInitZendesk) {
      initZendeskWidget({
        userId: loggedInUserId.userId.id,
        zendeskChatToken,
        integrationId: zendeskIntegrationId,
        fetchNewToken,
      });
    }
  }, [isLoading, loggedInUserId, isZendeskChatEnabled, zendeskChatToken, zendeskIntegrationId, fetchNewToken]);

  return <></>;
}
