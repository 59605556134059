import { useEffect } from 'react';

const useDynamicTitle = (title: string | undefined): void => {
  useEffect(() => {
    if (title !== undefined) {
      document.title = title;
    }
  }, [title]);
};

export default useDynamicTitle;
