import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from './TextInput';
import { useBlocksTheme } from '../../utils/styling/useBlocksTheme';
import { spotnana_rich_text_editor } from './ColorPicker.styles';

const WIDTH = 214;
const HEIGHT = 150;

interface ColorPickerProps {
  color: string;
  onChange?: (color: string) => void;
  allowColorSelection?: boolean;
}

export default function ColorPicker({
  color,
  onChange,
  allowColorSelection = false,
}: Readonly<ColorPickerProps>): JSX.Element {
  const { t: tt } = useTranslation('WEB');
  const theme = useBlocksTheme();
  const basicColors = Object.values(theme.palette.uxSpec);

  const [selfColor, setSelfColor] = useState(transformColor('hex', color));
  const [inputColor, setInputColor] = useState(color);
  const innerDivRef = useRef(null);

  const saturationPosition = useMemo(
    () => ({
      x: (selfColor.hsv.s / 100) * WIDTH,
      y: ((100 - selfColor.hsv.v) / 100) * HEIGHT,
    }),
    [selfColor.hsv.s, selfColor.hsv.v],
  );

  const huePosition = useMemo(
    () => ({
      x: (selfColor.hsv.h / 360) * WIDTH,
    }),
    [selfColor.hsv],
  );

  const onSetHex = (hex: string) => {
    setInputColor(hex);
    if (/^#[0-9A-Fa-f]{6}$/i.test(hex)) {
      const newColor = transformColor('hex', hex);
      setSelfColor(newColor);
    }
  };

  const onMoveSaturation = ({ x, y }: Position) => {
    const newHsv = {
      ...selfColor.hsv,
      s: (x / WIDTH) * 100,
      v: 100 - (y / HEIGHT) * 100,
    };
    const newColor = transformColor('hsv', newHsv);
    setSelfColor(newColor);
    setInputColor(newColor.hex);
  };

  const onMoveHue = ({ x }: Position) => {
    const newHsv = { ...selfColor.hsv, h: (x / WIDTH) * 360 };
    const newColor = transformColor('hsv', newHsv);

    setSelfColor(newColor);
    setInputColor(newColor.hex);
  };

  useEffect(() => {
    // Check if the RTEDropdown is actually active
    if (innerDivRef.current !== null && onChange) {
      onChange(selfColor.hex);
      setInputColor(selfColor.hex);
    }
  }, [selfColor, onChange]);

  useEffect(() => {
    if (color === undefined) return;
    const newColor = transformColor('hex', color);
    setSelfColor(newColor);
    setInputColor(newColor.hex);
  }, [color]);

  return (
    <div css={spotnana_rich_text_editor}>
      <div className="spotnana-rich-text-editor color-picker-wrapper" style={{ width: WIDTH }} ref={innerDivRef}>
        {allowColorSelection && <TextInput label={tt('Hex')} onChange={onSetHex} value={inputColor} />}
        <div className="spotnana-rich-text-editor color-picker-basic-color">
          {basicColors.map((basicColor) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              type="button"
              className={basicColor === selfColor.hex ? ' active' : ''}
              key={basicColor}
              style={{ backgroundColor: basicColor }}
              onClick={() => {
                setInputColor(basicColor);
                setSelfColor(transformColor('hex', basicColor));
              }}
            />
          ))}
        </div>
        {allowColorSelection && (
          <>
            <MoveWrapper
              className="spotnana-rich-text-editor color-picker-saturation"
              style={{
                /* stylelint-disable-next-line function-disallowed-list */
                backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)`,
              }}
              onChange={onMoveSaturation}
            >
              <div
                className="spotnana-rich-text-editor color-picker-saturation_cursor"
                style={{
                  backgroundColor: selfColor.hex,
                  left: saturationPosition.x,
                  top: saturationPosition.y,
                }}
              />
            </MoveWrapper>
            <MoveWrapper className="spotnana-rich-text-editor color-picker-hue" onChange={onMoveHue}>
              <div
                className="spotnana-rich-text-editor color-picker-hue_cursor"
                style={{
                  /* stylelint-disable-next-line function-disallowed-list */
                  backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)`,
                  left: huePosition.x,
                }}
              />
            </MoveWrapper>
            <div className="spotnana-rich-text-editor color-picker-color" style={{ backgroundColor: selfColor.hex }} />
          </>
        )}
      </div>
    </div>
  );
}

export interface Position {
  x: number;
  y: number;
}

interface MoveWrapperProps {
  className?: string;
  style?: React.CSSProperties;
  onChange: (position: Position) => void;
  children: JSX.Element;
}

function MoveWrapper({ className, onChange, style, children }: MoveWrapperProps) {
  const divRef = useRef<HTMLDivElement>(null);

  const move = (e: React.MouseEvent | MouseEvent): void => {
    if (divRef.current) {
      const { current: div } = divRef;
      const { width, height, left, top } = div.getBoundingClientRect();

      const x = clamp(e.clientX - left, width, 0);
      const y = clamp(e.clientY - top, height, 0);

      onChange({ x, y });
    }
  };

  const onMouseDown = (e: React.MouseEvent): void => {
    if (e.button !== 0) return;

    move(e);

    const onMouseMove = (_e: MouseEvent): void => {
      move(_e);
    };

    const onMouseUp = (_e: MouseEvent): void => {
      document.removeEventListener('mousemove', onMouseMove, false);
      document.removeEventListener('mouseup', onMouseUp, false);

      move(_e);
    };

    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('mouseup', onMouseUp, false);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div ref={divRef} className={className} style={style} onMouseDown={onMouseDown}>
      {children}
    </div>
  );
}

function clamp(value: number, max: number, min: number) {
  if (value > max) {
    return max;
  }

  if (value < min) {
    return min;
  }

  return value;
}

interface RGB {
  b: number;
  g: number;
  r: number;
}
interface HSV {
  h: number;
  s: number;
  v: number;
}
interface Color {
  hex: string;
  hsv: HSV;
  rgb: RGB;
}

export function toHex(value: string): string {
  if (!value.startsWith('#')) {
    const ctx = document.createElement('canvas').getContext('2d');

    if (!ctx) {
      throw new Error('2d context not supported or canvas already initialized');
    }

    ctx.fillStyle = value;

    return ctx.fillStyle;
  }

  if (value.length === 4 || value.length === 5) {
    return value
      .split('')
      .map((v, i) => (i ? v + v : '#'))
      .join('');
  }

  if (value.length === 7 || value.length === 9) {
    return value;
  }

  return '#000000';
}

const hex2rgb = (hex: string): RGB => {
  const rbgArr = (
    hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
      .substring(1)
      .match(/.{2}/g) || []
  ).map((x) => parseInt(x, 16));

  return {
    r: rbgArr[0],
    g: rbgArr[1],
    b: rbgArr[2],
  };
};

const rgb2hsv = ({ r, g, b }: RGB): HSV => {
  const normalizedR = r / 255;
  const normalizedG = g / 255;
  const normalizedB = b / 255;

  const max = Math.max(normalizedR, normalizedG, normalizedB);
  const delta = max - Math.min(normalizedR, normalizedG, normalizedB);

  let h;
  if (!delta) {
    h = 0;
  } else {
    if (max === normalizedR) {
      h = (normalizedG - normalizedB) / delta + (normalizedG < normalizedB ? 6 : 0);
    } else if (max === normalizedG) {
      h = 2 + (normalizedB - normalizedR) / delta;
    } else {
      h = 4 + (normalizedR - normalizedG) / delta;
    }
    h *= 60;
  }

  const s = max ? (delta / max) * 100 : 0;
  const v = max * 100;

  return { h, s, v };
};

const hsv2rgb = ({ h, s, v }: HSV): RGB => {
  const normalizedS = s / 100;
  const normalizedV = v / 100;

  // eslint-disable-next-line no-bitwise
  const i = ~~(h / 60);
  const f = h / 60 - i;
  const p = normalizedV * (1 - normalizedS);
  const q = normalizedV * (1 - normalizedS * f);
  const t = normalizedV * (1 - normalizedS * (1 - f));
  const index = i % 6;

  const r = Math.round([normalizedV, q, p, p, t, normalizedV][index] * 255);
  const g = Math.round([t, normalizedV, normalizedV, q, p, p][index] * 255);
  const b = Math.round([p, p, t, normalizedV, normalizedV, q][index] * 255);

  return { r, g, b };
};

const rgb2hex = ({ b, g, r }: RGB): string => {
  return `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`;
};

function transformColor<M extends keyof Color, C extends Color[M]>(format: M, color: C): Color {
  let hex: Color['hex'] = toHex('#000000');
  let rgb: Color['rgb'] = hex2rgb(hex);
  let hsv: Color['hsv'] = rgb2hsv(rgb);

  if (format === 'hex') {
    const value = color as Color['hex'];

    hex = toHex(value);
    rgb = hex2rgb(hex);
    hsv = rgb2hsv(rgb);
  } else if (format === 'rgb') {
    const value = color as Color['rgb'];

    rgb = value;
    hex = rgb2hex(rgb);
    hsv = rgb2hsv(rgb);
  } else if (format === 'hsv') {
    const value = color as Color['hsv'];

    hsv = value;
    rgb = hsv2rgb(hsv);
    hex = rgb2hex(rgb);
  }

  return { hex, hsv, rgb };
}
