import { css } from '@emotion/react';
import { flex, margins } from '@spotnana/blocks/src/utils';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const text_area = css`
  ${margins.yt.point75}
  ${margins.yb.m1point25}
`;

export const traveler_section = css`
  ${flex.init}
  ${flex.column}
  ${spacings.large}
`;
