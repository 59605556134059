import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Past = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 25" },
        React.createElement("path", { d: "M14.878 4.875a.75.75 0 01.749.694l.002.055v.621h1.996c.986 0 1.795.762 1.87 1.73l.005.146v9.073c0 1.036-.84 1.875-1.875 1.875H6.375A1.875 1.875 0 014.5 17.194V8.121c0-1.036.84-1.875 1.875-1.875H8.37l.001-.622a.749.749 0 011.498-.054l.002.056-.001.62h4.259v-.62c0-.415.335-.75.75-.75zM8.37 7.745H6.375A.376.376 0 006 8.121v9.073c0 .207.168.375.375.375h11.25a.375.375 0 00.375-.375V8.121a.375.375 0 00-.375-.375H15.63l.001.703a.75.75 0 01-1.498.057l-.002-.056-.001-.705H9.869v.705a.75.75 0 01-1.498.055l-.002-.056v-.704zm3.32 3.011a.749.749 0 01.044 1.013l-.043.048-.69.688h3.813a.75.75 0 01.056 1.499l-.056.002H11l.69.689a.75.75 0 01.043 1.013l-.043.047a.75.75 0 01-1.013.044l-.048-.044-1.97-1.969-.063-.072-.006-.008.07.08a.732.732 0 01-.127-.168l-.02-.039-.025-.058-.014-.04-.016-.06-.008-.043a.658.658 0 01-.008-.064l-.002-.039v-.02l.002-.055.003-.032-.005.088c0-.057.006-.113.019-.167l.006-.025a.746.746 0 01.026-.08l.01-.023a.724.724 0 01.108-.179l.007-.009.044-.048 1.969-1.969a.749.749 0 011.06 0z", fill: themeEnrichedPrimaryColor })));
};
Past.defaultProps = defaultIconProps;
export default Past;
