import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const container = themed(
  ({ palette }) => css`
    ${flex.init}
    ${spacings.extraSmall}
    height: 24px;
    position: relative;
    top: -8px;
    .active {
      color: ${palette.bg.brand};
    }
  `,
);
