import { css } from '@emotion/react';
import { themed } from '@spotnana/blocks/src/utils';
import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const itinerary_summary_conainer = themed(
  ({ palette }) => css`
    ${paddings.x.cozy}
    ${paddings.y.tight}
    background-color: ${palette.surface.base};
  `,
);

export const expired_fare_container = themed(
  ({ palette }) => css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 56px;
      opacity: 0.5;
      background-color: ${palette.inputs.bgDisabled};
    }
  `,
);
