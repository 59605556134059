import merge from 'lodash/merge';
import { InternalBlocksTheme } from '@spotnana/blocks/src/BlocksThemeProvider/blocksTheme';
import { BlocksPalette } from 'obt-common/types/common';

interface GenerateThemeProps {
  theme: InternalBlocksTheme;
  enableThemeOverrides: boolean;
  whitelabelConfigDriven?: Partial<BlocksPalette>;
}

export const generateTheme = (props: GenerateThemeProps) => {
  const { theme, whitelabelConfigDriven, enableThemeOverrides = false } = props;

  if (!enableThemeOverrides) {
    return theme;
  }

  const { palette } = theme;

  let overridePalette = {};
  const isOverrideConfigPresent = Object.keys(whitelabelConfigDriven || {}).length > 0;
  if (isOverrideConfigPresent) {
    overridePalette = whitelabelConfigDriven || {};
  }

  return {
    ...theme,
    palette: merge(palette, overridePalette),
  };
};
