/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Meal preferences.
 * @export
 * @interface MealPref
 */
export interface MealPref {
    /**
     * 
     * @type {Array<string>}
     * @memberof MealPref
     */
    exclMealPrefs?: Array<MealPrefExclMealPrefsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MealPref
     */
    inclMealPrefs?: Array<MealPrefInclMealPrefsEnum>;
    /**
     * 
     * @type {string}
     * @memberof MealPref
     */
    meal?: MealPrefMealEnum;
    /**
     * 
     * @type {string}
     * @memberof MealPref
     */
    specialMealDescription?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MealPrefExclMealPrefsEnum {
    UnknownMeal = 'UNKNOWN_MEAL',
    Avml = 'AVML',
    Blml = 'BLML',
    Chml = 'CHML',
    Dbml = 'DBML',
    Fpml = 'FPML',
    Gfml = 'GFML',
    Hnml = 'HNML',
    Ksml = 'KSML',
    Lcml = 'LCML',
    Lfml = 'LFML',
    Lsml = 'LSML',
    Moml = 'MOML',
    Nlml = 'NLML',
    Nsml = 'NSML',
    Pfml = 'PFML',
    Rvml = 'RVML',
    Sfml = 'SFML',
    Spml = 'SPML',
    Vgml = 'VGML',
    Vlml = 'VLML',
    Voml = 'VOML',
    Unrecognized = 'UNRECOGNIZED'
}
/**
    * @export
    * @enum {string}
    */
export enum MealPrefInclMealPrefsEnum {
    UnknownMeal = 'UNKNOWN_MEAL',
    Avml = 'AVML',
    Blml = 'BLML',
    Chml = 'CHML',
    Dbml = 'DBML',
    Fpml = 'FPML',
    Gfml = 'GFML',
    Hnml = 'HNML',
    Ksml = 'KSML',
    Lcml = 'LCML',
    Lfml = 'LFML',
    Lsml = 'LSML',
    Moml = 'MOML',
    Nlml = 'NLML',
    Nsml = 'NSML',
    Pfml = 'PFML',
    Rvml = 'RVML',
    Sfml = 'SFML',
    Spml = 'SPML',
    Vgml = 'VGML',
    Vlml = 'VLML',
    Voml = 'VOML',
    Unrecognized = 'UNRECOGNIZED'
}
/**
    * @export
    * @enum {string}
    */
export enum MealPrefMealEnum {
    UnknownMeal = 'UNKNOWN_MEAL',
    Avml = 'AVML',
    Blml = 'BLML',
    Chml = 'CHML',
    Dbml = 'DBML',
    Fpml = 'FPML',
    Gfml = 'GFML',
    Hnml = 'HNML',
    Ksml = 'KSML',
    Lcml = 'LCML',
    Lfml = 'LFML',
    Lsml = 'LSML',
    Moml = 'MOML',
    Nlml = 'NLML',
    Nsml = 'NSML',
    Pfml = 'PFML',
    Rvml = 'RVML',
    Sfml = 'SFML',
    Spml = 'SPML',
    Vgml = 'VGML',
    Vlml = 'VLML',
    Voml = 'VOML',
    Unrecognized = 'UNRECOGNIZED'
}



