import { MoneyUtil } from '../../../utils/Money';
import type { ConfirmNewRevalidateFireEvent } from '../types';

export default (context: any, event: any): boolean => {
  const typedContext = context as { oldTotalFare: MoneyUtil };
  const previousTotalFare = typedContext.oldTotalFare;
  const typedEvent = event as ConfirmNewRevalidateFireEvent;
  const newTotalFare = MoneyUtil.parse(typedEvent.data?.totalFare);

  return !previousTotalFare.isPositive() && newTotalFare.isPositive();
};
