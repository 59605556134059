var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Airplane = (_a) => {
    var { primaryColor, secondaryColor, title } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "title"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 40 40", version: "1.1", "data-testid": "airplane-icon" }, restProps),
        title && React.createElement("title", { "data-testid": "icon-title" }, title),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M35.0959293,4.90407794 C33.8728672,3.68107855 31.8837427,3.70151604 30.686118,4.94945292 L24.1307447,11.780512 L8.13318642,6.48632716 L4.7749998,9.84457549 L18.1094963,18.0548839 L11.3622481,25.0857554 L7.0353117,24.3753808 L4,27.4106918 L10.3612483,29.6387532 L12.5893102,36 L15.624622,32.964689 L14.9142471,28.6377537 L21.9451203,21.8905695 L30.1554306,35.2250004 L33.5136798,31.8667521 L28.2195562,15.8691975 L35.0504919,9.31388825 C36.2984915,8.11626385 36.318929,6.12713984 35.0959293,4.90407794 Z", id: "Path", fill: themeEnrichedPrimaryColor }))));
};
Airplane.defaultProps = Object.assign(Object.assign({}, defaultIconProps), { title: 'Flight' });
export default Airplane;
