import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const RemoveDash2 = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M12.6963 7.81055C13.0888 7.81055 13.407 8.08896 13.407 8.43241C13.407 8.75132 13.1326 9.01416 12.7792 9.05008L12.6963 9.05426H4.16797C3.77546 9.05426 3.45728 8.77585 3.45728 8.43241C3.45728 8.11349 3.73163 7.85065 4.08509 7.81473L4.16797 7.81055H12.6963Z", fill: themeEnrichedPrimaryColor })));
};
RemoveDash2.defaultProps = defaultIconProps;
export default RemoveDash2;
