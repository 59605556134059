import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { stringifyParams } from 'obt-common/utils/urlUtils';
import { OrganizationBasicInfo } from 'obt-common/types/api/v2/obt/model';

import { Avatar } from '@spotnana/blocks/src/Avatar';
import ClickableBox from '../ClickableBox';
import { EntityAutocompleteOptionProps } from './EntityAutocompleteOption';

const EntityAutocompleteCompanyOption = ({
  option,
  isHighlighted,
}: EntityAutocompleteOptionProps<OrganizationBasicInfo>): JSX.Element => {
  const history = useHistory();
  const { t: tt } = useTranslation('WEB');

  const goToCompany: MouseEventHandler<HTMLDivElement> = () => {
    history.push({
      pathname: '/admin/company/overview',
      search: stringifyParams({ organizationId: { id: option.orgId } }),
    });
  };

  return (
    <ClickableBox p="12px" borderRadius="sm" bg={isHighlighted ? 'bg.blueGray' : undefined} onClick={goToCompany}>
      <Flex style={{ wordBreak: 'break-word' }}>
        <Box px="10px">
          <Avatar size="small" alt="" text={option.orgName} />
        </Box>
        <Box px="10px">
          <Typography variation="small" fontWeight="semiBold" color="text.label" lineHeight="16px">
            {option.orgName}
          </Typography>
          <Typography variation="small" style={{ fontStyle: 'italic' }} color="text.blockedGrey" lineHeight="16px">
            - {tt('In company')}
          </Typography>
        </Box>
      </Flex>
    </ClickableBox>
  );
};

export default EntityAutocompleteCompanyOption;
