/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Document type.
 * @export
 * @enum {string}
 */

export const DocumentType = {
  BoardingPass: 'BOARDING_PASS',
  Confirmation: 'CONFIRMATION',
  Invoice: 'INVOICE',
  Visa: 'VISA',
  Miscellaneous: 'MISCELLANEOUS',
  Others: 'OTHERS',
  TaskProcessor: 'TASK_PROCESSOR',
  EventCoverImage: 'EVENT_COVER_IMAGE',
} as const;

export type DocumentType = (typeof DocumentType)[keyof typeof DocumentType];
