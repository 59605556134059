import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const AdditionalCheckedLuagguage = ({ width, height, primaryColor }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M17.3693 8.30176H12.6678C11.5916 8.30176 10.7192 9.17415 10.7192 10.2503V18.0296C10.7192 19.1058 11.5916 19.9782 12.6678 19.9782H17.3693C18.4454 19.9782 19.3178 19.1058 19.3178 18.0296V10.2503C19.3178 9.17415 18.4454 8.30176 17.3693 8.30176Z", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M13.8931 4V8.30303H16.1367V4H13.8931Z", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M13.0747 20.4001V19.9941H16.9643V20.4001", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M13.0605 4H16.9798", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M13.54 10.9883V17.2915", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M16.4482 10.9883V17.2915", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11.6502 8.30176H6.94853C5.87239 8.30176 5 9.17415 5 10.2503V18.0296C5 19.1058 5.87239 19.9782 6.94853 19.9782H11.6502C12.7262 19.9782 13.5987 19.1058 13.5987 18.0296V10.2503C13.5987 9.17415 12.7262 8.30176 11.6502 8.30176Z", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M8.17432 4V8.30303H10.4181V4H8.17432Z", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M7.354 20.4001V19.9941H11.2363V20.4001", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7.33984 4H11.2591", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7.81982 10.9883V17.2915", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M10.7192 10.9883V17.2915", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round" })));
};
AdditionalCheckedLuagguage.defaultProps = defaultIconProps;
export default AdditionalCheckedLuagguage;
