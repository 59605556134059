import styled from 'styled-components';
import { compose, height, space, width, color } from 'styled-system';
const Divider = styled.hr(({ theme: { colors } }) => ({
    minHeight: 1,
    width: '100%',
    backgroundColor: colors.surfaceDividerLight,
}), compose(width, height, space, color));
/**
 * @deprecated
 */
export default Divider;
