// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as React from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { createContext, useContext, useState } from 'react';
import type SpotnanaError from '../api/SpotnanaError';

interface IErrorContext {
  error: SpotnanaError | null;
  setError: (error: SpotnanaError) => void;
  resetError: () => void;
}

const ErrorContext = createContext<IErrorContext | null>(null);

export const ErrorProvider = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const [error, setError] = useState<SpotnanaError | null>(null);

  const resetError = (): void => {
    setError(null);
  };

  const values = {
    error,
    setError,
    resetError,
  };

  return <ErrorContext.Provider value={values}>{children}</ErrorContext.Provider>;
};

export function useError(): IErrorContext | null {
  const context = useContext(ErrorContext);
  return context;
}
