import { Condition } from './condition';
import { PolicyId, PolicyType, PolicyCategory } from './policy_common';
import { Action } from './action';
import { CustomFieldId } from './user_defined_entity';

export interface Rule {
  condition?: Condition;
  /** Actions to take if the condition is met. */
  followed: Action[];
  /** Actions to take if the condition is not met. */
  violated: Action[];
}

export interface InternalPolicyId {
  id: string;
  version: number;
}

export interface PolicyMetadata {
  name: string;
  description: string;
}

export interface PolicyInfo {
  policyId?: PolicyId;
  type: PolicyType;
  name: string;
  /** This contains the path to the policy document present in objectstore. */
  policySummaryPath: string;
  category: PolicyCategory;
}

export interface InternalPolicy {
  /** @deprecated */
  id?: InternalPolicyId;
  /** @deprecated */
  metadata?: PolicyMetadata;
  /** @deprecated */
  type: InternalPolicyType;
  /**
   * The top level predicate to determine whom this policy is applied to. For eg, All travellers
   * in Bangalore and Purpose of trip = Internal.
   */
  domain?: Condition;
  /** @deprecated */
  rules: Rule[];
  /**
   * This action is temporarily added here for the purpose of MVP. Later we
   * will integrate actions in rules instead.
   *
   * @deprecated
   */
  sendMail?: Action;
  /**
   * This rule is applied globally to the policy to describe actions to be
   * taken if the booking is out of policy. For eg: if the employee is C-level,
   * don't sent any approval email else send approval email(violated action).
   *
   * @deprecated
   */
  outOfPolicyRule?: Rule;
  /**
   * This rule is applied globally to the policy to describe actions to be
   * taken if the booking is in-policy.
   *
   * @deprecated
   */
  inPolicyRule?: Rule;
  policyId?: PolicyId;
  policyType: PolicyType;
  name: string;
  airRules: Rule[];
  hotelRules: Rule[];
  carRules: Rule[];
  version: number;
  /**
   * List of all custom fields associated with this policy.
   * It will be used during evaluation
   */
  customFieldIds: CustomFieldId[];
  /**
   * When a restrictive policy is applied, if anything is out of policy due to it,
   * other policy results won't matter.
   */
  isRestrictive: boolean;
  /** Rail rules. */
  railRules: Rule[];
  /** Category for which the policy is configured. Each category here should have a default policy. */
  category: PolicyCategory;
}

export enum InternalPolicyType {
  UNKNOWN_TYPE = 0,
  AIR = 1,
  HOTEL = 2,
  CAR = 3,
  RAIL = 4,
  UNRECOGNIZED = -1,
}
