/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the trip activity.
 * @export
 * @enum {string}
 */

export const TripActivityType = {
  BookingHistory: 'BOOKING_HISTORY',
  TripNote: 'TRIP_NOTE',
  AgentTask: 'AGENT_TASK',
  QcRunUpdate: 'QC_RUN_UPDATE',
  QcCheckUpdate: 'QC_CHECK_UPDATE',
  Unsupported: 'UNSUPPORTED',
} as const;

export type TripActivityType = (typeof TripActivityType)[keyof typeof TripActivityType];
