import type { FailRevalidateItineraryEvent, FailRevalidateItineraryForCvvEvent } from '../types';

export function getErrorCodeFromEvent(event: any) {
  const typedEvent = event as FailRevalidateItineraryEvent;
  const typedEventForCvv = event as FailRevalidateItineraryForCvvEvent;

  const cvvEventError = typedEventForCvv.data.errorCode;
  const normalEventError = typedEvent?.data?.response?.data?.errorCode;

  return cvvEventError || normalEventError;
}
