import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const City = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 12 12", version: "1.1" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "evenodd", d: "M11.5 11H11V9.25H8.875c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H11V7.25H8.875c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H11V5.375c0-.483-.392-.875-.875-.875H7.5V11H7V.375c0-.258-.256-.44-.501-.353L1.581 1.779c-.349.124-.581.454-.581.824V4h2.625c.207 0 .375.168.375.375s-.168.375-.375.375H1V6h2.625c.207 0 .375.168.375.375s-.168.375-.375.375H1V11H.5c-.276 0-.5.224-.5.5 0 .277.224.5.5.5h11c.276 0 .5-.223.5-.5 0-.276-.224-.5-.5-.5z" })));
};
City.defaultProps = defaultIconProps;
export default City;
