var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Direction = (_a) => {
    var { title, primaryColor, secondaryColor } = _a, restProps = __rest(_a, ["title", "primaryColor", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 64 64" }, restProps, { fill: themeEnrichedPrimaryColor }),
        title && React.createElement("title", null, title),
        React.createElement("g", { id: "Ico/UI/Direction", stroke: "none", strokeWidth: "1", fillRule: "evenodd" },
            React.createElement("path", { d: "M34.45225,20.6940188 C35.36875,19.7686604 36.861,19.7686604 37.7775,20.6940188 L37.7775,20.6940188 L47.30675,30.3153732 C47.3643726,30.3735528 47.4186684,30.434301 47.4695364,30.4973374 C47.491247,30.5248228 47.5127963,30.5526501 47.5337332,30.580963 C47.5458347,30.5969861 47.5575526,30.613318 47.5690532,30.6297801 C47.5933474,30.664674 47.616779,30.7000734 47.6392751,30.7361229 C47.6464664,30.7477869 47.6538245,30.7598471 47.661072,30.7719662 C47.7513623,30.9223458 47.8252928,31.0831663 47.8808729,31.2523181 C47.8844336,31.2646818 47.8884643,31.2772924 47.8923903,31.289937 C47.9068739,31.3350197 47.9195538,31.3806364 47.9308956,31.4267773 C47.9333598,31.4385433 47.9361464,31.4502305 47.9388454,31.4619408 C47.9496172,31.5070286 47.9587248,31.5527195 47.9665122,31.5988547 C47.9687903,31.61402 47.9711441,31.6287356 47.9733612,31.6434792 C47.9801497,31.6871763 47.9855597,31.7318199 47.9897234,31.7768188 C47.9965165,31.8509178 48,31.9252914 48,32 L47.9935519,31.8230455 C47.9978257,31.8814846 48,31.9404956 48,32 C48,32.7185002 47.6851835,33.3593014 47.189149,33.7932949 L37.7775,43.3059812 C36.861,44.2313396 35.36875,44.2313396 34.45225,43.3059812 C33.53575,42.3806228 33.53575,40.8739496 34.45225,39.9485912 L34.45225,39.9485912 L39.98,34.367 L18.35,34.3489867 C17.1000938,34.3489867 16.0875946,33.3683885 16.0053897,32.1381011 L16,31.9762729 C16,30.6594167 17.0575,29.6035591 18.35,29.6035591 L18.35,29.6035591 L39.969,29.622 L34.45225,24.0514088 C33.5774091,23.1681122 33.5376436,21.7551464 34.3329535,20.823698 Z", id: "Combined-Shape" }))));
};
Direction.defaultProps = defaultIconProps;
export default Direction;
