import { usePrimaryTravelerId } from 'obt-common/providers/TravelerProvider';
import { useEffect } from 'react';
import { EmbedEventTypes, useEmbedStore } from 'src/components/EmbedContext';
import usePrevious from 'ahooks/lib/usePrevious';

export const PrimaryTravelerUpdateReporter = () => {
  const { isEmbed, reportEvent } = useEmbedStore();
  const { primaryTravelerId } = usePrimaryTravelerId();
  const userId = primaryTravelerId?.userId?.id;
  const previousPrimaryTravelerId = usePrevious(userId);

  const shouldEmitTravelerUpdatedEvent = isEmbed
    ? previousPrimaryTravelerId && previousPrimaryTravelerId !== userId
    : false;

  useEffect(() => {
    if (shouldEmitTravelerUpdatedEvent) {
      reportEvent({
        type: EmbedEventTypes.PRIMARY_TRAVELER_UPDATED,
        payload: { primaryTravelerId: userId },
      });
    }
  }, [shouldEmitTravelerUpdatedEvent, reportEvent, userId]);

  return null;
};
