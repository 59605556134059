import { Typography } from '@spotnana/blocks/src/Typography/Typography';
import { Persona } from 'obt-common/types/api/v2/obt/model/persona';
import { useTranslation } from 'react-i18next';
import { getSubtext } from '../../../../v1-components/shared/AutoCompleteSubtext';

type PersonaColors = 'link' | 'error' | 'warning' | 'primary' | undefined;

const TRAVELER_PERSONA_VARIANTS: Record<Persona, PersonaColors> = {
  [Persona.Employee]: 'link',
  [Persona.Adhoc]: 'warning',
  [Persona.Guest]: 'warning',
  [Persona.Personal]: 'primary',
  [Persona.UnknownPersona]: undefined,
  [Persona.Relative]: undefined,
};

/**
 * Renders label for persona.
 * Used in rendering option in traveler picker autocomplete
 */
const PersonaLabel = ({ persona }: { persona: Persona | undefined }) => {
  const { t: tt } = useTranslation('WEB');
  const personaLabel = getSubtext({ persona, tt });

  if (!persona || !personaLabel) {
    return null;
  }

  return (
    <Typography color={TRAVELER_PERSONA_VARIANTS[persona]} variant="body3" kind="medium">
      {personaLabel}
    </Typography>
  );
};

export default PersonaLabel;
