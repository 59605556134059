import { MouseEvent, useMemo, PropsWithChildren, useCallback, AnchorHTMLAttributes } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';

import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';
import { useEmbedStore } from '../EmbedContext';

type CustomAnchorProps = {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  navigate?: Function;
  href: string;
  isDisabled?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const isModifiedEvent = (event: MouseEvent<HTMLAnchorElement>) =>
  event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;

/*
  Function that determines that event should be handled by router.
  It filters all events (like opening context menu, open link in new tab or window, etc) except left mouse button click
 */
export const shouldHandleNavigation = (event: MouseEvent<HTMLAnchorElement>) => {
  const elem = event.target as HTMLAnchorElement;

  return (
    event.button === 0 && // ignore everything but left clicks
    (!elem.target || elem.target === '_self') && // let browser handle "target=_blank" etc.
    !isModifiedEvent(event)
  );
};

export const CustomAnchor = React.forwardRef<HTMLAnchorElement, CustomAnchorProps>(
  ({ onClick, navigate, href, children, ...props }: CustomAnchorProps, forwardedRef) => {
    const { isEmbed } = useEmbedStore();
    const history = useHistory();

    const handleEmbedClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        if (onClick) {
          onClick(e);
        }

        if (!e.defaultPrevented) {
          if (navigate) {
            e.preventDefault();
            navigate();
          } else if (isEmbed || shouldHandleNavigation(e)) {
            e.preventDefault();
            history.push(href);
          }
        }
      },
      [navigate, onClick, history, href, isEmbed],
    );

    const handleKeyDown = useCallback(
      (e: any) => {
        if (e.key === 'Enter') {
          handleEmbedClick(e);
        }
      },
      [handleEmbedClick],
    );

    const anchorProps = useMemo(() => {
      if (isEmbed) {
        return { ...props };
      }

      return { ...props, href };
    }, [href, isEmbed, props]);

    return (
      <a
        {...anchorProps}
        role="button"
        tabIndex={0}
        ref={forwardedRef}
        onClick={handleEmbedClick}
        onKeyDown={handleKeyDown}
        style={{ cursor: props.isDisabled ? 'default' : 'pointer' }}
      >
        {children}
      </a>
    );
  },
);

// this approach will not work in react router dom v6
export function makeEmbeddedLink<T extends NavLinkProps | LinkProps>(Component: typeof Link) {
  return ({ children, ...props }: PropsWithChildren<T>): JSX.Element => {
    const { isEmbed } = useEmbedStore();

    let linkProps = props;
    if (isEmbed) {
      linkProps = { ...props, component: CustomAnchor };
    }

    // TODO TS fix: intentionally adding ts ignore due to complicated fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...linkProps}> {children} </Component>;
  };
}

export const CustomLink = makeEmbeddedLink<LinkProps>(Link);

export const CustomNavLink = makeEmbeddedLink<NavLinkProps>(NavLink);
