import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CarSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("rect", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", x: "5.82", y: "11.01", width: "20.36", height: "13.33", rx: "1.36" }),
        React.createElement("path", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", d: "M6.94,11H25.06a1.12,1.12,0,0,1,1.12,1.12v0a4.54,4.54,0,0,1-4.54,4.54H10.36a4.54,4.54,0,0,1-4.54-4.54v0A1.12,1.12,0,0,1,6.94,11Z" }),
        React.createElement("path", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", d: "M11.54,7.66h8.92a0,0,0,0,1,0,0V8.82A2.19,2.19,0,0,1,18.26,11H13.74a2.19,2.19,0,0,1-2.19-2.19V7.66A0,0,0,0,1,11.54,7.66Z", transform: "translate(32 18.67) rotate(-180)" }),
        React.createElement("rect", { fill: "#FFF", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", x: "14.53", y: "15.37", width: "2.94", height: "3.86", rx: "0.73" })));
};
CarSeat.defaultProps = defaultIconProps;
export default CarSeat;
