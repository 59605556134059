/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum NGSFareCategory {
    UnknownNgsCategory = 'UNKNOWN_NGS_CATEGORY',
    Base = 'BASE',
    Standard = 'STANDARD',
    Enhanced = 'ENHANCED',
    Premium = 'PREMIUM',
    Luxury = 'LUXURY',
    UltraLuxury = 'ULTRA_LUXURY'
}



