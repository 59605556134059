import { storage } from 'obt-common';
import { parseParams, stringifyParams } from 'obt-common/utils/urlUtils';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

export interface SetUrlStateType<URLStateType> {
  <TConfigOptions extends ConfigOptions>(
    data: TConfigOptions extends { overrideAllQueryParams: true } ? URLStateType : Partial<URLStateType>,
    configOptions?: TConfigOptions,
  ): void;
}

interface ConfigOptions {
  overrideAllQueryParams?: boolean;
  resetScroll?: boolean;
}

// TODO:Check this and implement sth like that:
// https://usehooks.com/useLocalStorage/

// TODO(a): Create a local map like in memory local storage.
/**
 * Use useQueryParamSelector hook instead.
 *
 * @deprecated
 */
const useUrlAndStorageState = <StorageStateType, URLStateType>(
  initialStorageState?: StorageStateType,
  initialURLState?: URLStateType,
) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  let searchParams = parseParams(search);
  let urlState = {} as URLStateType;
  if (search) {
    urlState = searchParams as URLStateType;
  } else if (initialURLState) {
    urlState = initialURLState as URLStateType;
  }

  const [loading, setLoading] = useState<boolean>(true);
  const [storageState, setStorageDataState] = useState<StorageStateType>(
    initialStorageState ?? (null as unknown as StorageStateType),
  );
  // TODO(a)
  const [errorUrlState] = useState<boolean>(false);
  // const [origPathName] = useState<string>(pathname);

  if (searchParams.storageKey) {
    storage
      .getItem(searchParams.storageKey)
      .then((storageDataStr) => {
        const storageDataOrig = storageDataStr ? JSON.parse(storageDataStr) : null;
        setStorageDataState(storageDataOrig);
      })
      .finally(() => setLoading(false));
  }

  const setStorageState = (data: StorageStateType, configOptions?: ConfigOptions): void => {
    searchParams.storageKey = uuidv4();
    storage.setItem(searchParams.storageKey, JSON.stringify(data));
    history.push({
      pathname,
      search: stringifyParams(searchParams),
      state: {
        resetScroll: configOptions?.resetScroll,
      },
    });
  };

  const setUrlState: SetUrlStateType<URLStateType> = (data, configOptions): void => {
    searchParams = configOptions?.overrideAllQueryParams ? { ...data } : { ...searchParams, ...data };
    history.push({
      pathname,
      search: stringifyParams(searchParams),
      state: {
        resetScroll: configOptions?.resetScroll,
      },
    });
  };

  const replaceUrlState = <TConfigOptions extends ConfigOptions>(
    data: TConfigOptions extends { overrideAllParams: true } ? URLStateType : Partial<URLStateType>,
    configOptions?: TConfigOptions,
  ): void => {
    searchParams = configOptions?.overrideAllQueryParams ? { ...data } : { ...searchParams, ...data };
    history.replace({
      pathname,
      search: stringifyParams(searchParams),
      state: {
        resetScroll: configOptions?.resetScroll,
      },
    });
  };

  return { loading, storageState, setStorageState, errorUrlState, urlState, setUrlState, replaceUrlState };
};

export default useUrlAndStorageState;
