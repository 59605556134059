import type { EffectCallback } from 'react';
import { useEffect } from 'react';

function useMountEffect(callback: EffectCallback): void {
  // TODO: FIX_ESLINT_VIOLATION
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
}

export default useMountEffect;
