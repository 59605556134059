/* istanbul ignore next: getter function, nothing to test */
export const getThemeShadows = () => {
    return {
        overlay: '0px 2px 10px 0px rgba(16, 24, 40, 0.08), 0px 1px 4px 0px rgba(16, 24, 40, 0.15)',
        minimal: '0 0 0 1px #E4E6E8',
        default: '0px 0px 20px 0px rgba(140, 159, 177, 0.10)',
        datePickerBorder: '-1px 0 0 0 #e4e6e8',
        filter: '8px 0 40px 0 rgba(17, 13, 25, 0.1)',
        mapShadowDark: '0 8px 24px 0 rgba(0, 0, 0, 0.3)',
        mapShadowLight: '0 8px 24px 0 rgba(0, 0, 0, 0.08)',
        mapCard: '0 12px 16px 0 rgba(0, 0, 0, 0.05)',
        highDiffusedCenter: '0px 0px 60px rgba(154, 154, 154, 0.2)',
        diffusedLower: '0px 30px 30px rgb(154 154 154 / 0.1)',
        primaryColorShadow: '0px 8px 24px rgba(217, 61, 74, 0.2)',
        info: '0px 10px 40px rgba(0, 0, 0, 0.08), 0px 24px 80px rgba(202, 203, 221, 0.2)',
        tooltip: '0px 0px 100px rgba(29, 29, 31, 0.15)',
        tooltipMinimal: '0px 2px 8px rgba(0,0,0,0.15)',
        none: 'none',
    };
};
