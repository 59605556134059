import { css } from '@emotion/react';
import { breakpoints, cssSelectors, flex, margins, themed, unit } from '../utils/styling';

type SizeConfig = {
  containerSize: number;
  mobilePadding: number;
  generalPadding: number;
};

const sizeConfig: Record<'small' | 'regular', SizeConfig> = {
  small: {
    containerSize: 20,
    mobilePadding: 6,
    generalPadding: 2,
  },
  regular: {
    containerSize: 24,
    mobilePadding: 4,
    generalPadding: 2,
  },
};

// Due to the shape of radio, there is a constant top margin diff that needs to be accounted for
const topMarginDiff = {
  mobile: 0.5,
  general: 1,
};

/**
 * This mixin Sets the size and click-area for the radio button
 * For Mobile we typically have a larger click area
 * @returns
 */
const size_mixin = (config: SizeConfig) => css`
  svg {
    font-size: ${config.containerSize}px;
  }

  /** additional click area */
  padding: ${config.mobilePadding}px;

  ${breakpoints.from('tablet')} {
    padding: ${config.generalPadding}px;
  }
`;

/**
 * This mixin Sets the size and gap for radio and label
 * For Mobile we typically have a larger click area
 * @returns
 */
const radio_label_gap_mixin = (config: SizeConfig, gap: string) => css`
  .MuiRadio-root {
    margin-top: -${config.mobilePadding - topMarginDiff.mobile}px;
    margin-right: calc(${gap} - ${config.mobilePadding}px);

    ${breakpoints.from('tablet')} {
      margin-top: -${config.generalPadding - topMarginDiff.general}px;
      margin-right: calc(${gap} - ${config.generalPadding}px);
    }
  }
`;

export const radio = themed(
  ({ palette }) => css`
    color: ${palette.border.dark};

    &.MuiRadio-root {
      border-radius: 50%;

      &:hover {
        color: ${palette.border.active};
        background: ${palette.bg.hover};
      }

      &.Mui-checked {
        color: ${palette.border.brand};
      }

      &.Mui-disabled {
        color: ${palette.border.dark};

        &.Mui-checked {
          color: ${palette.border.dark};
        }
      }
    }

    .size-regular &,
    &.size-regular {
      ${size_mixin(sizeConfig.regular)}
    }

    .size-small &,
    &.size-small {
      ${size_mixin(sizeConfig.small)}
    }

    input:focus-visible {
      /** on keyboard focus, apply styles to svg container */
      & + * {
        border-radius: 50%;
        outline: 2px solid ${palette.border.keyboardFocus};
      }

      & ~ .MuiTouchRipple-root {
        visibility: hidden;
      }
    }
  `,
);

export const radio_label = css`
  ${flex.align.start}
  user-select: none;
  vertical-align: top;

  &.Mui-disabled {
    cursor: not-allowed;
  }

  .BlocksRadio-subtext {
    display: block;
  }

  .Radio-Label {
    ${flex.init}
    ${flex.column}
  }

  &.size-regular {
    ${radio_label_gap_mixin(sizeConfig.regular, '0.75rem')}
  }

  &.size-small {
    ${radio_label_gap_mixin(sizeConfig.small, '0.5rem')}
  }
`;

export const radio_group = css`
  &${cssSelectors.not.firstOfType} {
    /* multiple groups in the same row need to have spacing as prescribed by design guide  */
    ${margins.xl.m2}
  }

  & > .BlocksRadioGroup-label {
    ${margins.yb.half}
  }

  .BlocksRadio-root {
    margin: 0 0 0 0;
  }

  &.size-regular {
    .BlocksRadio-root${cssSelectors.not.lastChild} {
      ${margins.yb.m1}
    }
  }
  &.size-small {
    .BlocksRadio-root${cssSelectors.not.lastChild} {
      ${margins.yb.point75}
    }
  }

  &.block {
    ${flex.init}
  }

  .BlocksRadioGroup-controls {
    &.grouping-column {
      /* Use default styles provided by 'radio_group' */
    }

    &.grouping-row {
      ${flex.row}

      gap: 0 ${unit(2)};
    }
  }
`;
