import 'styled-components';
import { getThemeColors } from './colors';
import { getThemeFonts } from './fonts';
import spotnanaTheme from './theme';
export default spotnanaTheme;
export * from './types';
export const getThemeOverrides = (overrides = {}) => {
    const colors = getThemeColors(overrides.colors);
    const fonts = getThemeFonts(overrides.fontFamily);
    return { colors, fonts };
};
