import { defineMessage } from '../translations/defineMessage';
import type { SSOOptionInfo } from '../types/auth';
import { AuthProviderType } from '../types/auth';

export enum AuthTranslationKeys {
  LOG_IN = 'LOG_IN',
  WELCOME_BACK_TO_SPOTNANA = 'WELCOME_BACK_TO_SPOTNANA',
  KEEPING_YOU_SAFE_AND_SECURE = 'KEEPING_YOU_SAFE_AND_SECURE',
  WHAT_IS_YOUR_CORPORATE_EMAIL_ADDRESS = 'WHAT_IS_YOUR_CORPORATE_EMAIL_ADDRESS',
  EMAIL = 'EMAIL',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_YOUR_PASSWORD = 'FORGOT_YOUR_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHECK_YOUR_EMAIL_FOR_INSTRUCTIONS = 'CHECK_YOUR_EMAIL_FOR_INSTRUCTIONS',
  RESET = 'RESET',
  NEXT_STEP = 'NEXT_STEP',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  ACCESS_DENIED = 'ACCESS_DENIED',
  CONTACT_YOUR_TRAVEL_ADMIN = 'CONTACT_YOUR_TRAVEL_ADMIN',
  EMAIL_US = 'EMAIL_US',
  TO_TRY_SPOTNANA = 'TO_TRY_SPOTNANA',
  SELECT_YOUR_LOCATION = 'SELECT_YOUR_LOCATION',
  SELECT_YOUR_DIVISION = 'SELECT_YOUR_DIVISION',
  DIVISION = 'DIVISION',
  CONTINUE = 'CONTINUE',
  CHOOSE_THE_COUNTRY_OF_YOUR_PRIMARY_OFFICE_LOCATION = 'CHOOSE_THE_COUNTRY_OF_YOUR_PRIMARY_OFFICE_LOCATION',
  CHOOSE_THE_ENTITY_YOU_WORK_FOR_FROM_THE_LIST_BELOW = 'CHOOSE_THE_ENTITY_YOU_WORK_FOR_FROM_THE_LIST_BELOW',
  YOUR_ACCOUNT_HAS_NOT_BEEN_ACTIVATED = 'YOUR_ACCOUNT_HAS_NOT_BEEN_ACTIVATED',
  CONTACT_AN_ADMINISTRATOR_TO_GET_STARTED = 'CONTACT_AN_ADMINISTRATOR_TO_GET_STARTED',
  THERE_WAS_AN_ERROR = 'THERE_WAS_AN_ERROR',
  PLEASE_CONTACT = 'PLEASE_CONTACT',
  SUPPORT = 'SUPPORT',
  YOUR_ACCOUNT_WAS_NOT_FOUND = 'YOUR_ACCOUNT_WAS_NOT_FOUND',
  PLEASE_LOG_IN_WITH_YOUR_PROFESSIONAL_EMAIL = 'PLEASE_LOG_IN_WITH_YOUR_PROFESSIONAL_EMAIL',
  PLEASE_TRY_LOGGING_IN_AGAIN = 'PLEASE_TRY_LOGGING_IN_AGAIN',
  PASSWORD_SHOULD_HAVE = 'PASSWORD_SHOULD_HAVE',
  NEW_TO_SPOTNANA = 'NEW_TO_SPOTNANA',
  CREATE_YOUR_ACCOUNT = 'CREATE_YOUR_ACCOUNT',
  ALREADY_USING_SPOTNANA = 'ALREADY_USING_SPOTNANA',
  SIGN_IN_TO_YOUR_ACCOUNT = 'SIGN_IN_TO_YOUR_ACCOUNT',
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_EXTENDED = 'SIGN_IN_EXTENDED',
  REGISTER = 'REGISTER',
  CREATE_YOUR_ACCOUNT_EXTENDED = 'CREATE_YOUR_ACCOUNT_EXTENDED',
  CREATE_YOUR_PASSWORD_INCLUDING = 'CREATE_YOUR_PASSWORD_INCLUDING',
  ENTER_VERIFICATION_CODE_CHECK_EMAIL = 'ENTER_VERIFICATION_CODE_CHECK_EMAIL',
  PLEASE_ENTER_VALID_EMAIL_ADDRESS = 'PLEASE_ENTER_VALID_EMAIL_ADDRESS',
  PASSWORDS_MATCH = 'PASSWORDS_MATCH',
  PLEASE_CLICK = 'PLEASE_CLICK',
  HERE = 'HERE',
  RESEND_EMAIL = 'RESEND_EMAIL',
  RESEND = 'RESEND',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  DIDNT_GET_THE_CODE = 'DIDNT_GET_THE_CODE',
  DIDNT_RECEIVE_THE_EMAIL_PLEASE_CLICK = 'DIDNT_RECEIVE_THE_EMAIL_PLEASE_CLICK',
  CONGRATULATIONS = 'CONGRATULATIONS',
  GOOD_TO_GO_REDIRECTED = 'GOOD_TO_GO_REDIRECTED',
  FETCHING_PROFILE_DETAILS = 'FETCHING_PROFILE_DETAILS',
  SIGNING_IN = 'SIGNING_IN',
  ARENT_REDIRECTED_FEW_SECONDS = 'ARENT_REDIRECTED_FEW_SECONDS',
  BACK_TO_LOGIN = 'BACK_TO_LOGIN',
  IS_A_PERSONAL_BOOKING = 'IS_A_PERSONAL_BOOKING',
  WANT_TO_BOOK_PERSONAL_BOOKING = 'WANT_TO_BOOK_PERSONAL_BOOKING',
  ADD_YOUR_PEROSNAL_EMAIL = 'ADD_YOUR_PEROSNAL_EMAIL',
  ADD_PERSONAL_EMAIL = 'ADD_PERSONAL_EMAIL',
  ADD_PERSONAL_EMAIL_SUBTEXT = 'ADD_PERSONAL_EMAIL_SUBTEXT',
  ADD_NOW = 'ADD_NOW',
  SUBMIT = 'SUBMIT',
  VOID_MESSAGE = 'VOID_MESSAGE',
  CANNOT_BE_REFUNDED_MESSAGE = 'CANNOT_BE_REFUNDED_MESSAGE',
}

export const ssoOptionInfos: { [key: string]: SSOOptionInfo } = {
  GOOGLE: { label: defineMessage('Continue with Google'), resourceKey: AuthProviderType.GOOGLE },
  AZURE: { label: defineMessage('Continue with Microsoft'), resourceKey: AuthProviderType.AZURE },
  OIDC: { label: defineMessage('Continue with OpenID'), resourceKey: AuthProviderType.CUSTOM },
  SAML: { label: defineMessage('Continue with SAML'), resourceKey: AuthProviderType.CUSTOM },
};

export const ssoOptionTypes = [AuthProviderType.AZURE, AuthProviderType.CUSTOM, AuthProviderType.GOOGLE];

export const REDIRECTION_NEEDED_FOR_BE = 'REDIRECTION_NEEDED_FOR_BE';

export const brexIDP = 'brex-api';
export const BREX_REWARDS_APPLICATION_ID = '752492fa-1488-4819-bde7-3c5baad0d187';

export const tokenExchange = [
  'brex-token-exchange',
  'qantas-token-exchange',
  'center-token-exchange',
  'airbase-token-exchange',
  'troop-token-exchange',
  'planned-token-exchange',
  'zoho-token-exchange',
];

export enum WelcomeErrorCodes {
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_EMAIL = 'INVALID_EMAIL',
  PERSONAL_EMAIL = 'PERSONAL_EMAIL',
  MID_FLOW_LOGOUT = 'MID_FLOW_LOGOUT',
  ACCESS_DENIED = 'ACCESS_DENIED',
  UNKNOWN = 'UNKNOWN',
  INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT',
  EMPTY_EMAIL = 'EMPTY_EMAIL',
}
