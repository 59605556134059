import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Temperature = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M16.55,21.1v-7a.55.55,0,1,0-1.1,0v7a1.1,1.1,0,1,0,1.5.4A1,1,0,0,0,16.55,21.1Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M16,8.05a2.74,2.74,0,0,0-2.74,2.74v9.45a3.23,3.23,0,0,0-.47,2.53,3.31,3.31,0,0,0,2.54,2.5,3.71,3.71,0,0,0,.67.06,3.28,3.28,0,0,0,3.29-3.28h0a3.21,3.21,0,0,0-.55-1.8V10.79A2.74,2.74,0,0,0,16,8.05Zm1.39,15.69h0a2.2,2.2,0,0,1-3.09-.3,2.36,2.36,0,0,1-.44-.9,2.21,2.21,0,0,1,.38-1.8.51.51,0,0,0,.11-.33V10.79a1.65,1.65,0,0,1,3.3,0v9.62a.57.57,0,0,0,.11.33A2.18,2.18,0,0,1,17.39,23.74Z", fill: themeEnrichedPrimaryColor })));
};
Temperature.defaultProps = defaultIconProps;
export default Temperature;
