import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { cornerRadii, paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

const HEADER_HEIGHT = '64px';

export const header = themed(
  ({ palette }) => css`
    ${paddings.y.compact}
    border-bottom: 1px solid ${palette.border.light};
    position: relative;
    background-color: ${palette.surface.base};
    height: ${HEADER_HEIGHT};
    transition: all 0.2s ease-in-out;
    /*
      ideally there should never be such zIndex hacks, but because
      there already is one, we need to increase it as route-based components
      have higher z-index than this header due to more zIndex hacks
    */
    z-index: 7;
  `,
);

export const skip_link = themed(
  ({ palette }) => css`
    position: absolute;
    z-index: 8;
    left: 15%;
    top: 0;
    background-color: ${palette.surface.background};
    height: ${HEADER_HEIGHT};
    width: 170px;
    transform: translateX(-1000%);
    color: ${palette.text.link};
    text-decoration: underline;
    ${paddings.x.comfortable}
    ${paddings.y.comfortable} 
    :focus {
      transform: translateX(-20%);
    }
    :visited {
      text-decoration-color: ${palette.text.focus} !important;
    }
  `,
);

export const header_nav_element = themed(
  ({ palette, typography }) => css`
    ${flex.init}
    ${flex.align.center}
    ${spacings.extraSmall}
    cursor: pointer;
    position: relative;
    color: ${palette.text.secondary};
    background-color: ${palette.surface.base};
    ${typography.body1.default}
    ${paddings.x.compact}
    ${paddings.y.tight}
    ${cornerRadii.buttonSmall}
    &.active {
      background-color: ${palette.surface.background};
      color: ${palette.text.primary};
      ${typography.body1.semibold}
      text-decoration: none;
    }
    :hover,
    :focus-visible {
      color: ${palette.text.primary};
      text-decoration: underline;
    }
  `,
);

export const header_button_element = css`
  &[aria-expanded='true'] {
    svg {
      transform: rotateZ(180deg);
    }
  }
`;

export const header_popover = themed(
  ({ palette, zIndex }) => css`
    max-height: fit-content;
    top: -63px;
    left: 24px;
    box-shadow: ${palette.shadow.componentShadow};
    background-color: ${palette.surface.menu};
    z-index: ${zIndex.appBar};
  `,
);

export const focus_outline = themed(
  ({ palette }) => css`
    :focus-visible {
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: -2px;
    }
  `,
);

export const outline_ring = css`
  border-radius: 100%;
  border: 2px solid;
`;

export const signout_icon = themed(
  ({ palette, fontSize }) => css`
    color: ${palette.text.brand};
    font-size: ${fontSize.s18};
    transform: translateY(1px);
  `,
);
