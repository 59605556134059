// Generated using yarn generate command
import { css } from '@emotion/react';
import { themed } from '../utils';

export const divider_horizontal = themed(
  ({ palette }) => css`
    width: 100%;
    height: 1px;
    margin: 0.5rem 0;
    background-color: ${palette.border.light};

    &.no-margin {
      margin: 0;
    }
  `,
);

export const divider_vertical = themed(
  ({ palette }) => css`
    width: 1px;
    height: 100%;
    margin: 0 0.5rem;
    background-color: ${palette.border.light};

    &.no-margin {
      margin: 0;
    }
  `,
);
