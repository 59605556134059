import { IUserFacingStatusEnum } from '../constants';
import type { EntityAnswer } from '../types/api/v1/obt/policy/user_defined_entity';
import type { ListTripsResponse, ListTripsResponseTripInfo } from '../types/api/v1/obt/trip/list_trips';
import { ScreenTypeEnum } from '../types/common';
import type { IBasicTravelerInfoError, ITravelerCheckoutInfo } from '../types/traveler';
import { IGenderEnum } from '../types/traveler';
import {
  validateFirstName,
  validateKnownTravelerNumber,
  validateLastName,
  validateMiddleName,
  validateSuffixName,
  validateRedressNumber,
  validateKTN,
  validateRedress,
} from '../validations/traveler';

export interface IValidateTravelerCheckoutDetails {
  hasTravelerBasicInfo: boolean;
  travelerErrorState: Omit<IBasicTravelerInfoError, 'emailError'>;
}

interface IGetPreBookAnswerProps {
  isCaseCodesApplicable: boolean;
  customFieldsAnswers: EntityAnswer[];
  caseCodeAnswer: EntityAnswer | null;
}

export const getPreBookAnswers = ({
  isCaseCodesApplicable,
  customFieldsAnswers,
  caseCodeAnswer,
}: IGetPreBookAnswerProps): EntityAnswer[] => {
  if (customFieldsAnswers.length) {
    return customFieldsAnswers;
  }
  if (isCaseCodesApplicable && caseCodeAnswer) {
    return [caseCodeAnswer];
  }
  return [];
};

export const validateTravelerCheckoutDetails = (
  travelerBasicDetail: ITravelerCheckoutInfo,
  checkoutType: keyof typeof ScreenTypeEnum,
  ignoreErrors: Array<keyof Pick<IValidateTravelerCheckoutDetails['travelerErrorState'], 'phoneError'>> = [],
  isTravelerBookingNameEnabled = false,
): IValidateTravelerCheckoutDetails => {
  const isBookingNameDisplayed = checkoutType !== ScreenTypeEnum.PROFILE && isTravelerBookingNameEnabled;
  const displayedTravelerName = isBookingNameDisplayed
    ? travelerBasicDetail?.travelerName ?? travelerBasicDetail?.name
    : travelerBasicDetail?.name;
  const isSpecialCharInNameAllowed = isTravelerBookingNameEnabled && checkoutType === ScreenTypeEnum.PROFILE;
  const firstNameError = validateFirstName(displayedTravelerName.given ?? '', isSpecialCharInNameAllowed);
  const middleNameError = validateMiddleName(displayedTravelerName.middle ?? '', isSpecialCharInNameAllowed);
  const lastNameError = validateLastName(displayedTravelerName.family1 ?? '', isSpecialCharInNameAllowed);
  const suffixNameError = validateSuffixName(displayedTravelerName.family2 ?? '', isSpecialCharInNameAllowed);

  // Profile screen has Optional Gender field
  const genderError =
    checkoutType === ScreenTypeEnum.PROFILE || travelerBasicDetail.gender !== IGenderEnum.UNKNOWN
      ? ''
      : 'Please select gender';
  const redressNumberError = validateRedressNumber(travelerBasicDetail?.redressNumber ?? '');
  const knownTravelerNumberError = validateKnownTravelerNumber(travelerBasicDetail?.knownTravelerNumber ?? '');

  const ktnError = validateKTN(travelerBasicDetail?.ktn);
  const redressError = validateRedress(travelerBasicDetail?.redress);

  const dobError =
    checkoutType === ScreenTypeEnum.PROFILE || !!travelerBasicDetail.dob ? '' : 'Please select date of birth';
  // Profile screen has Optional Phone field
  const phoneError =
    checkoutType === ScreenTypeEnum.PROFILE ||
    ignoreErrors.includes('phoneError') ||
    travelerBasicDetail.contactInfo.length
      ? ''
      : 'Please add mobile number';

  const hasTravelerBasicInfo = ((): boolean => {
    if (firstNameError || lastNameError || middleNameError || suffixNameError) {
      return false;
    }

    // Temporarily not applicable for Car checkout page
    if (checkoutType !== ScreenTypeEnum.CAR) {
      if (phoneError) {
        return false;
      }
    }

    // applicable for Profile screen and Air checkout page
    if (checkoutType === ScreenTypeEnum.PROFILE || checkoutType === ScreenTypeEnum.AIR) {
      if (dobError || genderError || redressNumberError || knownTravelerNumberError || redressError || ktnError) {
        return false;
      }
    }

    return true;
  })();

  const travelerErrorState = {
    firstNameError,
    middleNameError,
    lastNameError,
    genderError,
    suffixNameError,
    dobError,
    phoneError,
    redressNumberError,
    knownTravelerNumberError,
    ktnError,
    redressError,
  };

  return { hasTravelerBasicInfo, travelerErrorState };
};

export const getActiveTrips = (allTrips: ListTripsResponse | undefined): ListTripsResponseTripInfo[] => {
  if (!allTrips || !allTrips.trips) {
    return [];
  }

  const completedTrips = allTrips.trips.filter((trip) => {
    const status = trip.tripMetadata?.tripStatus;

    return !!status?.toString() && status !== IUserFacingStatusEnum.COMPLETED_STATUS;
  });

  return completedTrips;
};
