import { cssVar } from 'polished';
import spotnanaTheme from './themes';
export default spotnanaTheme;
/**
 * Since image URLs have env names appended to them, environments which
 * do not have https:// (dev, testing) can just use their own URL without
 * any replacement
 */
export const getSecureImageURL = (httpUrl) => httpUrl.startsWith('http://localhost:')
    ? httpUrl
    : httpUrl.replace('http://', 'https://images.weserv.nl/?url=http://');
/* istanbul ignore next */
export const getElementWidthFromText = (str, fontSize) => {
    var _a;
    const element = (_a = document.querySelector('#pixel-text-width-util')) !== null && _a !== void 0 ? _a : (() => {
        const textWidthUtilElement = document.createElement('span');
        textWidthUtilElement.id = 'pixel-text-width-util';
        textWidthUtilElement.style.position = 'absolute';
        textWidthUtilElement.style.opacity = '0';
        textWidthUtilElement.style.fontSize = fontSize !== null && fontSize !== void 0 ? fontSize : '1rem';
        document.body.appendChild(textWidthUtilElement);
        return textWidthUtilElement;
    })();
    element.innerText = str;
    return element.offsetWidth;
};
export const getRawColorFromCssVariable = (spotnanaCssVar) => String(cssVar(spotnanaCssVar.slice(4, -1), true));
export const getRawColorFromCssVariableWithFallback = (spotnanaCssVar, fallback) => {
    try {
        return getRawColorFromCssVariable(spotnanaCssVar);
    }
    catch (e) {
        return fallback;
    }
};
export const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const text = doc.body.textContent || '';
    return text.replace(/\n/g, '');
};
export const themeSelector = (mode) => {
    return `[data-mui-color-scheme='${mode}'] &`;
};
