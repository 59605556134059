import { SelectItem, ComboboxItem, SelectItemCheck } from '@ariakit/react';
import clsx from 'clsx';

import { TOptionBase } from '../../utils/select';
import { Icon } from '../../Icon';
import { DeprecatedOptionWithStringifiedValue, restoreDeprecatedOptionType } from '../helpers';
import { Radio } from '../../Radio';
import { margins } from '../../utils';
import { useSelectConfig } from '../SelectConfigContext';
import { SelectRootItemContent } from './SelectRootItemContent';
import { ItemStylingProps } from './types';

export function SelectSingleItem<TItem extends TOptionBase>({
  option,
  className,
  style,
  itemRef,
  ...rest
}: {
  option: DeprecatedOptionWithStringifiedValue<TItem>;
} & ItemStylingProps) {
  const {
    selectStore,
    kind,
    onItemClick,
    setValueOnClick,
    slots: { ItemDescription, ItemPrefix, ItemSuffix },
  } = useSelectConfig<'select', 'single', TItem>();
  const value = selectStore.useState('value');
  const showRadio = kind === 'persist';
  const checked = Boolean(value === option.value);

  return (
    <SelectItem
      key={option.value}
      hideOnClick={!showRadio}
      id={String(option.value)}
      ref={itemRef}
      {...rest}
      className={clsx(
        'BlocksSelect-SelectItem SelectionSingle',
        {
          WithRadio: showRadio,
          'BlocksSelect-checked': checked,
        },
        className,
      )}
      value={String(option.value)}
      data-value={String(option.value)}
      data-checked={checked}
      onClick={() => {
        onItemClick?.(restoreDeprecatedOptionType<TItem>(option));
      }}
      setValueOnClick={setValueOnClick}
      style={option.isHidden ? { display: 'none' } : style}
      {...option.itemProps}
    >
      <SelectRootItemContent
        text={option.label}
        prefixAction={
          showRadio && <Radio value="" size="small" checked={checked} tabIndex={-1} style={{ pointerEvents: 'none' }} />
        }
        checkmark={
          !showRadio &&
          checked && (
            <SelectItemCheck className="SelectItemCheck">
              <Icon name="Check" height={20} width={20} />
            </SelectItemCheck>
          )
        }
        prefixContent={ItemPrefix && <ItemPrefix item={option} />}
        suffixContent={ItemSuffix && <ItemSuffix item={option} />}
        description={ItemDescription && <ItemDescription item={option} />}
      />
    </SelectItem>
  );
}

export function ComboboxSingleItem<TItem extends TOptionBase>({
  option,
  className,
  style,
  itemRef,
  ...rest
}: {
  option: DeprecatedOptionWithStringifiedValue<TItem>;
} & ItemStylingProps) {
  const {
    selectStore,
    kind,
    onItemClick,
    setValueOnClick,
    slots: { ItemPrefix, ItemSuffix, ItemDescription },
  } = useSelectConfig<'select', 'single', TItem>();
  const value = selectStore.useState('value');
  const showRadio = kind === 'persist';
  const checked = Boolean(value === option.value);
  return (
    <ComboboxItem
      key={option.value}
      id={String(option.value)}
      focusOnHover={false}
      className={clsx(
        'BlocksSelect-SelectItem SelectionSingle',
        {
          WithRadio: showRadio,
          'BlocksSelect-checked': checked,
        },
        className,
      )}
      style={option.isHidden ? { display: 'none' } : style}
      {...option.itemProps}
      // even though this is a copy-paste of SelectItem render, it's better pasted than convoluted with conditional prop changes
      // a simple prop change in this configuration usually breaks accessibility in combobox search
      render={
        <SelectItem
          key={option.value}
          value={String(option.value)}
          data-value={String(option.value)}
          data-checked={checked}
          ref={itemRef}
          {...rest}
          hideOnClick={!showRadio}
          onClick={() => {
            onItemClick?.(restoreDeprecatedOptionType<TItem>(option));
          }}
          setValueOnClick={setValueOnClick}
        >
          <SelectRootItemContent
            text={option.label}
            prefixAction={
              showRadio && (
                <Radio value="" size="small" checked={checked} tabIndex={-1} style={{ pointerEvents: 'none' }} />
              )
            }
            checkmark={
              !showRadio && (
                <SelectItemCheck className="SelectItemCheck" css={margins.xl.half} checked={checked}>
                  <Icon name="Check" height={20} width={20} />
                </SelectItemCheck>
              )
            }
            prefixContent={ItemPrefix && <ItemPrefix item={option} />}
            suffixContent={ItemSuffix && <ItemSuffix item={option} />}
            description={ItemDescription && <ItemDescription item={option} />}
          />
        </SelectItem>
      }
    />
  );
}
