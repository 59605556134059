import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import useAppAvailability from './hooks/useAppAvailability';

declare global {
  interface Window {
    AppMounted: string;
  }
}

const STATUS_PAGE_URL = 'https://status.spotnana.com';
const SUPPORT_EMAIL = 'support@spotnana.com';

const Container = styled.div`
  font-family: 'Inter', sans-serif;
  max-width: 520px;
  margin: auto;
  padding: 24px;
  margin-top: 120px;
`;

const Heading1 = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const Heading2 = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Heading3 = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const PhoneNumber = styled.div`
  margin-bottom: 6px;
`;

interface IProps {
  children?: React.ReactNode;
}

const PHONE_NUMBERS = {
  general: [
    {
      country: 'United States',
      number: '+1 866 296 7768',
    },
    {
      country: 'United Kingdom',
      number: '+44 800 031 8077',
    },
  ],
};

const MaintenancePage = (props: IProps): JSX.Element | null => {
  const { t: tt } = useTranslation('WEB');
  const { children } = props;

  const { data, isLoading } = useAppAvailability();

  useEffect(() => {
    /**
     * Hide the HTML-based spinner UI once, if maintainance page is blocking UI
     */
    if (!isLoading && !data?.isAvailable) {
      try {
        window.dispatchEvent(new Event(window.AppMounted));
      } catch (e) {
        console.error(e);
      }
    }
  }, [isLoading, data]);

  if (isLoading) {
    return null;
  }

  if (!data || data?.isAvailable) {
    return children as JSX.Element;
  }

  return (
    <Container>
      <Heading1>{tt("We'll be back soon!")}</Heading1>
      <p>
        {tt(
          'Sorry for the inconvenience as we are performing planned maintenance at the moment. If its urgent please reach out to our customer support via phone or email, otherwise we’ll be back online shortly!',
        )}
      </p>
      <p>{tt('— The Spotnana Team')}</p>
      <p>
        {tt('For updates, go to ')}
        <a href={STATUS_PAGE_URL}>{STATUS_PAGE_URL}</a>
      </p>
      <p>
        {tt('Expecting to be back by')} <b>{data?.time}</b>
      </p>

      <Heading2>{tt('Need assistance?')}</Heading2>
      <Heading3>{tt('Phone')}</Heading3>

      {PHONE_NUMBERS.general.map((phone) => (
        <PhoneNumber key={phone.number}>
          {`${phone.country}:`} {phone.number}
        </PhoneNumber>
      ))}

      <Heading3>{tt('Email')}</Heading3>
      <a href={SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>
    </Container>
  );
};

export default MaintenancePage;
