// Generated using yarn generate command
import { css } from '@emotion/react';
import { themed, margins, flex } from '../utils/styling';
import { cornerRadii, paddings, spacings } from '../utils/styling/utilityTokens';

export const label_base = themed(
  ({ typography }) => css`
    border: none;
    cursor: auto;
    ${spacings.extraSmall}
    ${cornerRadii.small}
    .MuiChip-label {
      ${paddings.x.none}
    }
    .MuiChip-icon {
      height: 16px;
      width: 16px;
      ${margins.x.m0}
    }
    &.BlocksLabel-root-small {
      height: 20px;
      ${paddings.x.tight}
      ${paddings.y.extremeTight}
      ${typography.body3.default}
    }
    &.BlocksLabel-root-medium {
      height: 24px;
      ${paddings.x.tight}
      ${paddings.y.superTight}
      ${typography.body3.default}
    }
    &.BlocksLabel-root-large {
      height: 32px;
      ${paddings.x.tight}
      ${paddings.y.tight}
      ${typography.body2.default}
    }
    &.BlocksLabel-withoutBackground {
      background: none;
      ${paddings.x.none}
    }
  `,
);

export const subtle_label = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.background};
    .MuiChip-icon {
      color: ${palette.icon.primary};
    }
    .MuiChip-label {
      > span.BlocksTypography {
        color: ${palette.text.primary};
      }
    }
  `,
);

export const success_label = themed(
  ({ palette }) => css`
    background-color: ${palette.bg.successSubtle};
    .MuiChip-icon {
      color: ${palette.icon.success};
    }
    .MuiChip-label {
      > span.BlocksTypography {
        color: ${palette.text.success};
      }
    }
  `,
);

export const warning_label = themed(
  ({ palette }) => css`
    background-color: ${palette.bg.warningSubtle};
    .MuiChip-icon {
      color: ${palette.icon.warning};
    }
    .MuiChip-label {
      > span.BlocksTypography {
        color: ${palette.text.warning};
      }
    }
  `,
);

export const error_label = themed(
  ({ palette }) => css`
    background-color: ${palette.bg.errorSubtle};
    .MuiChip-icon {
      color: ${palette.icon.error};
    }
    .MuiChip-label {
      > span.BlocksTypography {
        color: ${palette.text.error};
      }
    }
  `,
);

export const info_label = themed(
  ({ palette }) => css`
    background-color: ${palette.bg.infoSubtle};
    .MuiChip-icon {
      color: ${palette.icon.infoLink};
    }
    .MuiChip-label {
      > span.BlocksTypography {
        color: ${palette.text.link};
      }
    }
  `,
);

export const preferred_label_base = themed(
  ({ palette }) => css`
    ${flex.init}
    .BlocksPreferredLabel-notch-small {
      background-color: ${palette.bg.info};
      width: 4px;
      height: 28px;
      border-radius: ${palette.border.radiusXSmall} 0px 0px ${palette.border.radiusXSmall};
    }
    .BlocksPreferredLabel-notch-medium {
      background-color: ${palette.bg.info};
      width: 4px;
      height: 40px;
      border-radius: ${palette.border.radiusSmall} 0 0 ${palette.border.radiusSmall};
    }
    .BlocksLabel-root-small {
      background-color: ${palette.bg.info};
      .MuiChip-label {
        > span.BlocksTypography {
          color: ${palette.text.inverse};
        }
      }
      border-radius: 0px ${palette.border.radiusXSmall} ${palette.border.radiusXSmall} 0px !important;
      padding: 4px 8px 4px 4px;
    }
    .BlocksLabel-root-medium {
      .MuiChip-label {
        > span.BlocksTypography {
          color: ${palette.text.inverse};
        }
      }
      background-color: ${palette.bg.info};
      border-radius: 0px ${palette.border.radiusSmall} ${palette.border.radiusSmall} 0px !important;
      padding: 6px 8px 6px 4px;
    }
  `,
);

export const clickable_label = themed(
  ({ palette }, { compose }) => css`
    cursor: pointer;
    .MuiTouchRipple-root {
      display: none;
    }
    &.BlocksLabel-error {
      &:focus-visible,
      &:hover {
        ${compose(error_label)}
      }
    }

    &:focus {
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: 1px;
    }
  `,
);
