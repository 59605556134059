import { REQUEST_HEADERS } from '../../../constants/common';
import { fetchStorageValue } from '../../../hooks/useStorage';
import { StorageKeys } from '../../../types/storage';
import addRequestInterceptor from '../addRequestInterceptor';

export function injectOrgIdHeader() {
  return addRequestInterceptor({
    onFulfilled: async (config) => {
      const orgId = await fetchStorageValue<string>(StorageKeys.ORG_ID).catch(() => null);

      if (orgId) {
        config.headers[REQUEST_HEADERS.X_ORG_ID] = orgId;
      }

      return config;
    },
  });
}
