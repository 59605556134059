import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const RailETicket = ({ primaryColor }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.94208 2.25H18.1829C18.7312 2.25061 19.257 2.46641 19.6443 2.84974C20.0317 3.23305 20.2494 3.75254 20.25 4.29421V19.3304C20.2494 19.8721 20.0317 20.392 19.6443 20.7753C19.257 21.1586 18.7316 21.3744 18.1833 21.375H9.94208C9.39384 21.3744 8.86803 21.1586 8.48066 20.7753C8.09332 20.392 7.87561 19.8725 7.875 19.3308V18.7712C7.875 18.3881 8.18554 18.0776 8.5686 18.0776C8.95167 18.0776 9.26221 18.3881 9.26221 18.7712V19.33C9.26244 19.509 9.3344 19.6805 9.46202 19.8068C9.58962 19.9331 9.76243 20.004 9.94247 20.0042H18.1825C18.3626 20.004 18.5354 19.9331 18.663 19.8068C18.7906 19.6805 18.8626 19.5091 18.8628 19.33V4.29498C18.8626 4.11595 18.7906 3.94449 18.663 3.81818C18.5354 3.69193 18.3626 3.62107 18.1826 3.62085H17.3852L17.1471 4.96217L17.1463 4.96614C17.1149 5.1213 17.0302 5.26111 16.9062 5.36167C16.7822 5.46225 16.6267 5.51732 16.4661 5.51732H11.6589C11.4983 5.51732 11.3428 5.46225 11.2188 5.36167C11.0948 5.26111 11.0101 5.1213 10.9787 4.96614L10.9779 4.96217L10.7398 3.62085L9.94247 3.62085C9.76246 3.62107 9.5896 3.69193 9.46202 3.81818C9.3344 3.94447 9.26244 4.11589 9.26221 4.29489V5.07364C9.26221 5.4567 8.95167 5.76724 8.5686 5.76724C8.18554 5.76724 7.875 5.4567 7.875 5.07364V4.29459C7.87561 3.75293 8.09332 3.23305 8.48066 2.84974C8.86803 2.46641 9.39384 2.25061 9.94208 2.25ZM16.2573 4.34148L16.3906 3.65559H11.7344L11.8678 4.34148C11.91 4.55857 12.1024 4.71551 12.3264 4.71551H15.7987C16.0227 4.71551 16.2151 4.55857 16.2573 4.34148Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M11.4109 7.61621C11.7316 7.61621 11.9915 7.8752 11.9915 8.19467C11.9915 8.49962 11.7547 8.74946 11.4543 8.77155L11.4109 8.77313L4.85438 8.77291V10.1002L4.88612 10.1095C5.6759 10.3542 6.25483 11.0755 6.284 11.9357L6.28515 12.0039C6.28515 12.8942 5.69673 13.6474 4.88615 13.8986L4.85438 13.9077V15.193L11.4123 15.1949C11.7184 15.1949 11.9691 15.4309 11.9913 15.7302L11.9929 15.7734C11.9929 16.0783 11.756 16.3281 11.4556 16.3502L11.4123 16.3518L4.51955 16.3502C4.07977 16.3502 3.71991 16.0079 3.69476 15.5757L3.69336 15.5274V13.4093C3.69336 13.1043 3.93021 12.8545 4.23063 12.8324L4.27396 12.8308H4.29399C4.7525 12.8308 5.12395 12.4607 5.12395 12.0039C5.12395 11.5637 4.77853 11.2038 4.34277 11.1787L4.29399 11.1773H4.27396C3.96788 11.1773 3.71712 10.9413 3.69495 10.642L3.69336 10.5988V8.43911C3.69336 8.00094 4.03751 7.64268 4.47103 7.61765L4.51955 7.61625L11.4109 7.61621Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M7.76874 7.61621C7.44809 7.61621 7.18814 7.8752 7.18814 8.19467C7.18814 8.49962 7.42499 8.74946 7.72541 8.77155L7.76874 8.77313L14.3253 8.77291V10.1002L14.2936 10.1095C13.5038 10.3542 12.9249 11.0755 12.8957 11.9357L12.8945 12.0039C12.8945 12.8942 13.483 13.6474 14.2935 13.8986L14.3253 13.9077V15.193L7.76741 15.1949C7.46133 15.1949 7.21057 15.4309 7.18841 15.7302L7.18681 15.7734C7.18681 16.0783 7.42366 16.3281 7.72408 16.3502L7.76741 16.3518L14.6601 16.3502C15.0999 16.3502 15.4598 16.0079 15.4849 15.5757L15.4863 15.5274V13.4093C15.4863 13.1043 15.2495 12.8545 14.9491 12.8324L14.9057 12.8308H14.8857C14.4272 12.8308 14.0557 12.4607 14.0557 12.0039C14.0557 11.5637 14.4012 11.2038 14.8369 11.1787L14.8857 11.1773H14.9057C15.2118 11.1773 15.4626 10.9413 15.4847 10.642L15.4863 10.5988V8.43911C15.4863 8.00094 15.1422 7.64268 14.7087 7.61765L14.6601 7.61625L7.76874 7.61621Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M10.8297 10.5078C11.0889 10.5078 11.2989 10.7034 11.2989 10.9446C11.2989 11.1686 11.1178 11.3532 10.8845 11.3784L10.8297 11.3814H8.27388C8.01475 11.3814 7.80469 11.1858 7.80469 10.9446C7.80469 10.7206 7.98582 10.536 8.21916 10.5108L8.27388 10.5078H10.8297Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M10.8297 12.2549C11.0889 12.2549 11.2989 12.4504 11.2989 12.6917C11.2989 12.9157 11.1178 13.1003 10.8845 13.1255L10.8297 13.1284H8.27388C8.01475 13.1284 7.80469 12.9329 7.80469 12.6917C7.80469 12.4677 7.98582 12.2831 8.21916 12.2578L8.27388 12.2549H10.8297Z", fill: themeEnrichedPrimaryColor })));
};
RailETicket.defaultProps = defaultIconProps;
export default RailETicket;
