import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Crown = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.6178 6.69994C12.3108 6.2507 11.6278 6.26798 11.3498 6.75177L9.27266 10.3649L5.71262 7.57502L5.71251 7.57494C5.69644 7.56233 5.67983 7.55036 5.66273 7.53908L5.66267 7.53903C5.50423 7.4344 5.3181 7.39732 5.14093 7.42334C5.0899 7.43078 5.04054 7.44336 4.99339 7.46053C4.86594 7.5068 4.74948 7.58818 4.65969 7.70277C4.5496 7.84325 4.49757 8.01073 4.50009 8.17629V15.1892L4.50164 15.2736C4.5457 16.4693 5.52251 17.4264 6.72587 17.4391C6.73391 17.4393 6.74199 17.4395 6.7501 17.4395H17.2501L17.3345 17.4379C18.538 17.3935 19.5001 16.4038 19.5001 15.1895V8.16571L19.5001 8.16358C19.5 8.14601 19.4994 8.12843 19.4981 8.11087L19.4981 8.10974C19.4939 8.05275 19.4833 7.99753 19.4671 7.94479C19.4408 7.8594 19.3988 7.77729 19.3404 7.70277L19.3387 7.70084C19.3275 7.68668 19.3158 7.67293 19.3037 7.65962L19.3005 7.65589C19.0381 7.37179 18.5972 7.33231 18.2874 7.57502L14.727 10.3649L12.6503 6.75177L12.6178 6.69994ZM18.0001 9.7058L14.9706 12.0797C14.6267 12.3492 14.1308 12.2673 13.8891 11.9131L13.8578 11.8631L12 8.63093L10.1423 11.8631C9.92459 12.2419 9.43412 12.352 9.07724 12.1143L9.02944 12.0797L6.00009 9.70593V15.1892C6.00009 15.5847 6.30594 15.9085 6.6941 15.9371L6.75009 15.9392L6.7699 15.9395H17.2501C17.6455 15.9395 17.9694 15.6335 17.9981 15.2454L18.0001 15.1895V9.7058Z", fill: themeEnrichedPrimaryColor })));
};
Crown.defaultProps = defaultIconProps;
export default Crown;
