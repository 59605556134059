import type { IFlightLayoverInfo } from '../../types/flight';
import { FlightDetailsInfoType } from '../../types/flight';
import type { ITripFlightDetail } from '../../types/trip';

export const getFlightNumberDisplayText = (flightDetails: IFlightLayoverInfo[] | ITripFlightDetail[]): string => {
  return flightDetails
    .map((detail) => {
      if ('type' in detail && detail.type !== FlightDetailsInfoType.FLIGHT) {
        return '';
      }

      let data;
      if ('data' in detail) {
        data = detail.data.airline.marketing;
      } else {
        data = detail.marketing;
      }
      const marketingAirlineName = data?.airline || '';
      const marketingAirlineNumber = data?.num || '';

      return `${marketingAirlineName}${marketingAirlineNumber}`;
    })
    .filter((element) => !!element)
    .join(', ');
};
