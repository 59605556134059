import { Typography } from '@spotnana/blocks/src/Typography';

import { flex } from '@spotnana/blocks/src/utils';
import { Ellipsis } from '@spotnana/blocks/src/Ellipsis';
import CompanyLabel from 'src/app/shared/components/CompanyLabelContainer';
import TmcLabel from 'src/app/shared/components/TmcLabel';
import { TravelerBasicInfo, useFeatureFlag, useTMCListQuery } from 'obt-common';
import { useMemo } from 'react';
import { EntityAutocompleteOptionProps } from './EntityAutocompleteOption';

interface IProps {
  item: EntityAutocompleteOptionProps<TravelerBasicInfo>['option'];
}

export const EntityAutocompleteProfileOptionDescription = ({ item }: IProps) => {
  const isDisplayCompanyNameLogoEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_TMC_NAME');
  const { data: tmcList, isFetching: isFetchingTmcList } = useTMCListQuery({
    isSpotnanaAgentOrAdmin: true,
    companyIds: [item.userOrgId?.organizationId?.id as string],
    options: { enabled: isDisplayCompanyNameLogoEnabled && Boolean(item.userOrgId?.organizationId?.id) },
  });
  const userCompany = useMemo(
    () => tmcList?.find((tmc) => tmc.id.id === item.userOrgId?.organizationId?.id),
    [tmcList, item.userOrgId?.organizationId?.id],
  );
  const { persona, email, userOrgId } = item;
  const tmcBasicInfo = userOrgId?.tmcBasicInfo ?? null;
  return (
    <>
      <Typography css={flex.init} variant="body3" color="secondary">
        <Ellipsis title={email} width={180}>
          {email}
        </Ellipsis>
      </Typography>

      <TmcLabel tmcBasicInfo={tmcBasicInfo} />

      <CompanyLabel company={userCompany} persona={persona} isLoading={isFetchingTmcList} />
    </>
  );
};
