import { css } from '@emotion/react';

export const flex = {
  init: css`
    display: flex;
  `,
  row: css`
    flex-direction: row;
  `,
  rowReverse: css`
    flex-direction: row-reverse;
  `,
  column: css`
    flex-direction: column;
  `,
  columnReverse: css`
    flex-direction: column-reverse;
  `,
  zero: css`
    flex: 0;
  `,
  one: css`
    flex: 1;
  `,
  justify: {
    center: css`
      justify-content: center;
    `,
    start: css`
      justify-content: flex-start;
    `,
    end: css`
      justify-content: flex-end;
    `,
    around: css`
      justify-content: space-around;
    `,
    between: css`
      justify-content: space-between;
    `,
    initial: css`
      justify-content: initial;
    `,
    evenly: css`
      justify-content: space-evenly;
    `,
  },
  align: {
    center: css`
      align-items: center;
    `,
    start: css`
      align-items: flex-start;
    `,
    end: css`
      align-items: flex-end;
    `,
    baseline: css`
      align-items: baseline;
    `,
    initial: css`
      align-items: initial;
    `,
    stretch: css`
      align-items: 'stretch';
    `,
  },
  wrap: {
    noWrap: css`
      flex-wrap: nowrap;
    `,
    wrap: css`
      flex-wrap: wrap;
    `,
    wrapReverse: css`
      flex-wrap: wrap-reverse;
    `,
    wrapInitial: css`
      flex-wrap: initial;
    `,
  },
};

export const cssUtils = {
  disableScrollY: css`
    overflow-y: hidden;
  `,
  cursorPointer: css`
    cursor: pointer;
  `,
  clickThrough: css`
    pointer-events: none;
  `,
  zIndex: {
    max: css`
      z-index: 999999;
    `,
    zero: css`
      z-index: 0;
    `,
  },
  absoluteCover: css`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
};

/** TODO: reuse it to replace hardcoded literals  */
type UnitType = 0.25 | 0.5 | 0.75 | 0.875 | 1 | 1.25 | 1.5 | 1.75 | 2 | 2.5 | 3;
export const unit = (u: UnitType): string => `${u}rem`;

/**
 *
 *
 * These margins and paddings are currently "dummy" numbers to establish a pattern
 * they will be streamlined to add/remove items based on ONLY what the design
 * guidelines allow
 *
 *
 */

export const margins = {
  // y stands for both top & bottom, yt for top, and yb for bottom
  y: {
    m0: css`
      margin-top: 0;
      margin-bottom: 0;
    `,
    m1: css`
      margin-top: ${unit(1)};
      margin-bottom: ${unit(1)};
    `,
    m1point25: css`
      margin-top: ${unit(1.25)};
      margin-bottom: ${unit(1.25)};
    `,
    m1point5: css`
      margin-top: ${unit(1.5)};
      margin-bottom: ${unit(1.5)};
    `,
    m2: css`
      margin-top: ${unit(2)};
      margin-bottom: ${unit(2)};
    `,
    m2point5: css`
      margin-top: ${unit(2.5)};
      margin-bottom: ${unit(2.5)};
    `,
    point25: css`
      margin-top: ${unit(0.25)};
      margin-bottom: ${unit(0.25)};
    `,
    point75: css`
      margin-top: ${unit(0.75)};
      margin-bottom: ${unit(0.75)};
    `,
    half: css`
      margin-top: ${unit(0.5)};
      margin-bottom: ${unit(0.5)};
    `,
  },
  yt: {
    m0: css`
      margin-top: 0;
    `,
    mhalf: css`
      margin-top: ${unit(0.5)};
    `,
    m1: css`
      margin-top: ${unit(1)};
    `,
    m1point25: css`
      margin-top: ${unit(1.25)};
    `,
    m1point5: css`
      margin-top: 1.5rem;
    `,
    m2: css`
      margin-top: ${unit(2)};
    `,
    m2point5: css`
      margin-top: ${unit(2.5)};
    `,
    point25: css`
      margin-top: ${unit(0.25)};
    `,
    point75: css`
      margin-top: ${unit(0.75)};
    `,
    half: css`
      margin-top: ${unit(0.5)};
    `,
  },
  yb: {
    m0: css`
      margin-bottom: 0;
    `,
    m1: css`
      margin-bottom: ${unit(1)};
    `,
    m1point25: css`
      margin-bottom: ${unit(1.25)};
    `,
    m1point5: css`
      margin-bottom: ${unit(1.5)};
    `,
    m2: css`
      margin-bottom: ${unit(2)};
    `,
    m2point5: css`
      margin-bottom: ${unit(2.5)};
    `,
    point25: css`
      margin-bottom: ${unit(0.25)};
    `,
    point75: css`
      margin-bottom: ${unit(0.75)};
    `,
    half: css`
      margin-bottom: ${unit(0.5)};
    `,
  },
  // x stands for both left & right, xl for left, and xr for right
  x: {
    m0: css`
      margin-left: 0;
      margin-right: 0;
    `,
    m1: css`
      margin-left: ${unit(1)};
      margin-right: ${unit(1)};
    `,
    m1point25: css`
      margin-left: ${unit(1.25)};
      margin-right: ${unit(1.25)};
    `,
    m1point5: css`
      margin-left: ${unit(1.5)};
      margin-right: ${unit(1.5)};
    `,
    m2: css`
      margin-left: ${unit(2)};
      margin-right: ${unit(2)};
    `,
    m2point5: css`
      margin-left: ${unit(2.5)};
      margin-right: ${unit(2.5)};
    `,
    half: css`
      margin-left: ${unit(0.5)};
      margin-right: ${unit(0.5)};
    `,
    point25: css`
      margin-left: ${unit(0.25)};
      margin-right: ${unit(0.25)};
    `,
    point75: css`
      margin-left: ${unit(0.75)};
      margin-right: ${unit(0.75)};
    `,
  },
  xl: {
    m0: css`
      margin-left: 0;
    `,
    m1: css`
      margin-left: ${unit(1)};
    `,
    m1point25: css`
      margin-left: ${unit(1.25)};
    `,
    m1point5: css`
      margin-left: 1.5rem;
    `,
    m2: css`
      margin-left: ${unit(2)};
    `,
    m2point5: css`
      margin-left: ${unit(2.5)};
    `,
    half: css`
      margin-left: ${unit(0.5)};
    `,
    point25: css`
      margin-left: ${unit(0.25)};
    `,
    point75: css`
      margin-left: ${unit(0.75)};
    `,
  },
  xr: {
    m0: css`
      margin-right: 0;
    `,
    m1: css`
      margin-right: ${unit(1)};
    `,
    m1point25: css`
      margin-right: ${unit(1.25)};
    `,
    m1point5: css`
      margin-right: 1.5rem;
    `,
    m2: css`
      margin-right: ${unit(2)};
    `,
    m2point5: css`
      margin-right: ${unit(2.5)};
    `,
    point25: css`
      margin-right: ${unit(0.25)};
    `,
    half: css`
      margin-right: ${unit(0.5)};
    `,
    point75: css`
      margin-right: ${unit(0.75)};
    `,
  },
};

/**
 * Please use paddings from the utilityTokens file
 * @deprecated
 */
export const paddings = {
  // y stands for both top & bottom, yt for top, and yb for bottom
  y: {
    p0: css`
      padding-top: 0;
      padding-bottom: 0;
    `,
    p1: css`
      padding-top: ${unit(1)};
      padding-bottom: ${unit(1)};
    `,
    p1point25: css`
      padding-top: ${unit(1.25)};
      padding-bottom: ${unit(1.25)};
    `,
    p1point5: css`
      padding-top: ${unit(1.5)};
      padding-bottom: ${unit(1.5)};
    `,
    p2: css`
      padding-top: ${unit(2)};
      padding-bottom: ${unit(2)};
    `,
    p2point5: css`
      padding-top: ${unit(2.5)};
      padding-bottom: ${unit(2.5)};
    `,
    point25: css`
      padding-top: ${unit(0.25)};
      padding-bottom: ${unit(0.25)};
    `,
    point75: css`
      padding-top: ${unit(0.75)};
      padding-bottom: ${unit(0.75)};
    `,
    half: css`
      padding-top: ${unit(0.5)};
      padding-bottom: ${unit(0.5)};
    `,
  },
  yt: {
    p0: css`
      padding-top: 0;
    `,
    p1: css`
      padding-top: ${unit(1)};
    `,
    p1point25: css`
      padding-top: ${unit(1.25)};
    `,
    p1point5: css`
      padding-top: ${unit(1.5)};
    `,
    p2: css`
      padding-top: ${unit(2)};
    `,
    p2point5: css`
      padding-top: ${unit(2.5)};
    `,
    point25: css`
      padding-top: ${unit(0.25)};
    `,
    point75: css`
      padding-top: ${unit(0.75)};
    `,
    half: css`
      padding-top: ${unit(0.5)};
    `,
  },
  yb: {
    p0: css`
      padding-bottom: 0;
    `,
    p1: css`
      padding-bottom: ${unit(1)};
    `,
    p1point25: css`
      padding-bottom: ${unit(1.25)};
    `,
    p1point5: css`
      padding-bottom: 1.5rem;
    `,
    p2: css`
      padding-bottom: ${unit(2)};
    `,
    p2point5: css`
      padding-bottom: ${unit(2.5)};
    `,
    point25: css`
      padding-bottom: ${unit(0.25)};
    `,
    point75: css`
      padding-bottom: ${unit(0.75)};
    `,
    half: css`
      padding-bottom: ${unit(0.5)};
    `,
  },
  // x stands for both left & right, xl for left, and xr for right
  x: {
    p0: css`
      padding-left: 0;
      padding-right: 0;
    `,
    p1: css`
      padding-left: ${unit(1)};
      padding-right: ${unit(1)};
    `,
    p1point25: css`
      padding-left: ${unit(1.25)};
      padding-right: ${unit(1.25)};
    `,
    p1point5: css`
      padding-left: ${unit(1.5)};
      padding-right: ${unit(1.5)};
    `,
    p2: css`
      padding-left: ${unit(2)};
      padding-right: ${unit(2)};
    `,
    p2point5: css`
      padding-left: ${unit(2.5)};
      padding-right: ${unit(2.5)};
    `,
    half: css`
      padding-left: ${unit(0.5)};
      padding-right: ${unit(0.5)};
    `,
    point25: css`
      padding-left: ${unit(0.25)};
      padding-right: ${unit(0.25)};
    `,
    point75: css`
      padding-left: ${unit(0.75)};
      padding-right: ${unit(0.75)};
    `,
  },
  xl: {
    p0: css`
      padding-left: 0;
    `,
    p1: css`
      padding-left: ${unit(1)};
    `,
    p1point25: css`
      padding-left: ${unit(1.25)};
    `,
    p1point5: css`
      padding-left: ${unit(1.5)};
    `,
    p2: css`
      padding-left: ${unit(2)};
    `,
    p2point5: css`
      padding-left: ${unit(2.5)};
    `,
    half: css`
      padding-left: ${unit(0.5)};
    `,
    point25: css`
      padding-left: ${unit(0.25)};
    `,
    point75: css`
      padding-left: ${unit(0.75)};
    `,
  },
  xr: {
    p0: css`
      padding-right: 0;
    `,
    p1: css`
      padding-right: ${unit(1)};
    `,
    p1point25: css`
      padding-right: ${unit(1.25)};
    `,
    p1point5: css`
      padding-right: ${unit(1.5)};
    `,
    p2: css`
      padding-right: ${unit(2)};
    `,
    p2point5: css`
      padding-right: ${unit(2.5)};
    `,
    point25: css`
      padding-right: ${unit(0.25)};
    `,
    half: css`
      padding-right: ${unit(0.5)};
    `,
    point75: css`
      padding-right: ${unit(0.75)};
    `,
  },
};

export const themeSelector = (mode: 'light' | 'dark') => {
  return `[data-mui-color-scheme='${mode}'] &`;
};
