import { css } from '@emotion/react';
import { breakpoints, themed } from '@spotnana/blocks/src/utils/styling';

export const carbon_emission_icon = css`
  height: 1.25rem;
  width: 1.25rem;
`;

export const icon_secondary = themed(
  ({ palette }) => css`
    color: ${palette.icon.secondary};
  `,
);

export const icon_error = themed(
  ({ palette }) => css`
    color: ${palette.icon.error};
  `,
);

export const icon_success = themed(
  ({ palette }) => css`
    color: ${palette.icon.success};
  `,
);

export const emission_container = css`
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 1rem;
`;

export const label_container = css`
  display: flex;
  align-items: flex-start;
  ${breakpoints.from('tablet')} {
    margin-top: -1rem;
  }
`;
