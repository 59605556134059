import { FC } from 'react';
import { Modal } from '@spotnana/blocks/src/Modal';
import { useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Icon } from '@spotnana/blocks/src/Icon';
import { ShareItineraryFormValues } from '../../ShareItineraryModalContainer/FormProvider/types';
import { modal_secondary_background } from '../../shared/styles';
import { container, success_icon, text_container } from './styles';

interface ShareSuccessModalProps {
  open: boolean;
  onClose: VoidFunction;
  formTravelers: ShareItineraryFormValues['travelers'];
}

export const ShareSuccessModal: FC<ShareSuccessModalProps> = ({ open, onClose, formTravelers }) => {
  const { t: tt } = useTranslation('WEB');

  return (
    <Modal
      variant="medium"
      open={open}
      onClose={onClose}
      header={{ title: tt('Email Sent Successfully!') }}
      footer={{
        primaryAction: { text: tt('Done'), onClick: onClose },
      }}
      css={modal_secondary_background}
    >
      <div css={container}>
        <Icon name="CheckCircle" variant="decorative" css={success_icon} />
        <div css={text_container}>
          <Typography variant="body1" kind="semibold">
            {tt('Itinerary for {{travelerName}} has been sent to', { travelerName: formTravelers[0].fullName })}
          </Typography>
          {formTravelers.map((traveler) => (
            <Typography variant="body1" key={traveler.email}>
              {traveler.email}
            </Typography>
          ))}
        </div>
      </div>
    </Modal>
  );
};
