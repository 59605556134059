import { ComboboxItem, SelectItem, SelectItemCheck } from '@ariakit/react';
import { useSelectConfig } from '../../SelectRoot/SelectConfigContext';
import { TOptionBase, margins } from '../../utils';
import { Checkbox } from '../../Checkbox';
import { Typography } from '../../Typography';
import { addVocalHints } from '../../utils/a11y';
import { autocomplete_item_wrapper } from './AutocompleteItem.styles';
import { DeprecatedOptionWithStringifiedValue } from '../../SelectRoot/helpers';
import { TAutocompleteOption } from '../Autocomplete.types';
import { Icon } from '../../Icon';

interface IAutocompleteItemProps<TItem extends TOptionBase> {
  /**
   * Option: An element of options passed as prop to autocomplete component
   */
  option: DeprecatedOptionWithStringifiedValue<TAutocompleteOption<TItem>>;
  /**
   * checkboxPosition: Lets you decide if you want the checkbox on the left or right or not show at all
   */
  checkboxPosition?: 'left' | 'right' | 'none';

  firstOptionRef: React.RefObject<HTMLDivElement> | null;
}
export function AutocompleteItem<TItem extends TOptionBase>({
  option,
  checkboxPosition = 'left',
  firstOptionRef,
}: IAutocompleteItemProps<TItem>) {
  const {
    selectStore,
    setValueOnClick,
    labelId,
    slots: { ItemPrefix, ItemSuffix, ItemDescription },
  } = useSelectConfig<'combobox', 'multiple', TItem>();

  const valueSet = selectStore.getState().value;
  const isSelected = valueSet.includes(option.value as string);

  return (
    <div css={autocomplete_item_wrapper}>
      <ComboboxItem
        key={option.value}
        id={String(option.value)}
        focusOnHover
        hideOnClick
        value={String(option.value)}
        data-value={String(option.value)}
        data-checked={isSelected}
        ref={firstOptionRef}
        render={
          <SelectItem
            key={option.value}
            value={String(option.value)}
            data-value={String(option.value)}
            data-checked={isSelected}
            className="BlocksAutocomplete-SelectItem SelectionMulti"
            style={option.isHidden ? { display: 'none' } : undefined}
            data-testid="autocomplete-item"
            setValueOnClick={setValueOnClick}
            {...option.itemProps}
          >
            <ItemContent
              text={option.label}
              labelId={labelId}
              data-checked={isSelected}
              checkmark={
                checkboxPosition === 'none' &&
                isSelected && (
                  <SelectItemCheck css={margins.xl.half} checked={isSelected} data-testid="autocomplete-item-check">
                    <Icon name="Check" height={20} width={20} />
                  </SelectItemCheck>
                )
              }
              prefixAction={
                checkboxPosition === 'left' && (
                  <Checkbox
                    /** A11y and focus are managed by MUI to mimic `optgroup` behaviour. Focus never goes to checkboxes so it doesn't need a label */
                    aria-labelledby={undefined}
                    size="regular"
                    checked={isSelected}
                    tabIndex={-1}
                    style={{ pointerEvents: 'none' }}
                    data-testid="checkbox-left"
                  />
                )
              }
              suffixAction={
                checkboxPosition === 'right' && (
                  <Checkbox
                    /** A11y and focus are managed by MUI to mimic `optgroup` behaviour. Focus never goes to checkboxes so it doesn't need a label */
                    aria-labelledby={undefined}
                    size="regular"
                    checked={isSelected}
                    tabIndex={-1}
                    style={{ pointerEvents: 'none' }}
                    data-testid="checkbox-right"
                  />
                )
              }
              prefixContent={ItemPrefix && <ItemPrefix item={option} />}
              suffixContent={ItemSuffix && <ItemSuffix item={option} />}
              description={ItemDescription && <ItemDescription item={option} />}
            />
          </SelectItem>
        }
      />
    </div>
  );
}
interface IItemContentProps {
  /**
   * Text to be rendered
   */
  text: string;
  /**
   * Label Id for vocal hint
   */
  labelId?: string;
  /**
   * action to be rendered on the left
   */
  prefixAction?: React.ReactNode;
  /**
   * action to be rendered on the right
   */
  suffixAction?: React.ReactNode;
  /**
   * Whether to render a checkmark. Makes sense for single pickers
   */
  checkmark?: React.ReactNode;
  /**
   * Content to be rendered on the left
   */
  prefixContent?: React.ReactNode;
  /**
   * Content to be rendered on the right
   */
  suffixContent?: React.ReactNode;
  /**
   * Description component rendered below the text
   */
  description?: React.ReactNode;
}
function ItemContent({
  labelId = '',
  text,
  prefixContent,
  prefixAction,
  suffixAction,
  suffixContent,
  checkmark,
  description,
}: IItemContentProps) {
  return (
    <div className="AutocompleteItemContent">
      <div className="AutocompleteItemContent-prefix">
        {prefixAction}
        {prefixContent}
      </div>
      <div className="AutocompleteItemContent-text">
        <Typography id={labelId} variant="body1" kind="regular" className="AutocompleteItemContent-label">
          {addVocalHints()(text)}
        </Typography>
        {description}
      </div>
      {checkmark}
      <div className="AutocompleteItemContent-suffix">
        {suffixContent}
        {suffixAction}
      </div>
    </div>
  );
}
