import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const HotelPlaceholder = ({ primaryColor }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { viewBox: "0 0 303 208", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 215.69 170.54 L 211.45 170.54 L 211.45 83.54 L 215.69 83.54 C 217.314 83.562 218.353 81.818 217.56 80.4 C 217.452 80.206 217.313 80.031 217.15 79.88 C 206.929 70.37 193.595 64.906 179.64 64.51 L 179.64 53.89 L 186 53.89 C 187.632 53.885 188.646 52.115 187.826 50.704 C 187.724 50.529 187.597 50.369 187.45 50.23 C 165.6 29.73 130.05 29.73 108.2 50.23 C 107.014 51.352 107.488 53.336 109.052 53.802 C 109.246 53.86 109.447 53.889 109.65 53.89 L 116 53.89 L 116 64.54 C 102.052 64.941 88.726 70.404 78.51 79.91 C 77.316 81.011 77.762 82.992 79.313 83.475 C 79.535 83.545 79.767 83.577 80 83.57 L 84.24 83.57 L 84.24 170.57 L 80 170.57 C 78.368 170.57 77.348 172.337 78.164 173.75 C 78.543 174.406 79.243 174.81 80 174.81 L 215.69 174.81 C 217.322 174.81 218.342 173.043 217.526 171.63 C 217.147 170.974 216.447 170.57 215.69 170.57 Z M 209.88 79.33 L 179.64 79.33 L 179.64 68.81 C 190.557 69.153 201.108 72.823 209.88 79.33 Z M 180.2 49.65 L 115.5 49.65 C 134.762 35.55 160.938 35.55 180.2 49.65 Z M 116 68.81 L 116 79.33 L 85.78 79.33 C 94.547 72.827 105.09 69.157 116 68.81 Z M 88.45 83.59 L 116 83.59 L 116 170.54 L 88.45 170.54 Z M 133 170.54 L 133 166.83 C 133.006 163.314 135.854 160.466 139.37 160.46 L 156.37 160.46 C 159.886 160.466 162.734 163.314 162.74 166.83 L 162.74 170.54 Z M 166.94 170.54 L 166.94 166.83 C 166.934 160.973 162.187 156.226 156.33 156.22 L 139.33 156.22 C 133.473 156.226 128.726 160.973 128.72 166.83 L 128.72 170.54 L 120.26 170.54 L 120.26 53.89 L 175.4 53.89 L 175.4 170.54 Z M 179.66 170.54 L 179.66 83.59 L 207.23 83.59 L 207.23 170.54 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 96.94 155.69 C 95.308 155.69 94.288 157.457 95.104 158.87 C 95.92 160.283 97.96 160.283 98.776 158.87 C 98.96 158.551 99.058 158.189 99.06 157.82 C 99.066 156.645 98.115 155.69 96.94 155.69 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 107.55 155.69 C 105.918 155.69 104.898 157.457 105.714 158.87 C 106.53 160.283 108.57 160.283 109.386 158.87 C 109.57 158.551 109.668 158.189 109.67 157.82 C 109.676 156.645 108.725 155.69 107.55 155.69 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 107.55 147.21 C 109.182 147.21 110.202 145.443 109.386 144.03 C 109.007 143.374 108.307 142.97 107.55 142.97 C 105.918 142.97 104.898 144.737 105.714 146.15 C 106.093 146.806 106.793 147.21 107.55 147.21 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 96.94 147.21 C 98.572 147.21 99.592 145.443 98.776 144.03 C 98.397 143.374 97.697 142.97 96.94 142.97 C 95.308 142.97 94.288 144.737 95.104 146.15 C 95.483 146.806 96.183 147.21 96.94 147.21 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 96.94 130.24 C 95.308 130.24 94.288 132.007 95.104 133.42 C 95.92 134.833 97.96 134.833 98.776 133.42 C 98.96 133.101 99.058 132.739 99.06 132.37 C 99.066 131.195 98.115 130.24 96.94 130.24 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 107.55 130.24 C 105.918 130.24 104.898 132.007 105.714 133.42 C 106.53 134.833 108.57 134.833 109.386 133.42 C 109.57 133.101 109.668 132.739 109.67 132.37 C 109.67 131.198 108.722 130.246 107.55 130.24 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 107.52 117.52 C 105.888 117.543 104.893 119.324 105.729 120.726 C 106.565 122.127 108.605 122.098 109.401 120.674 C 109.577 120.358 109.67 120.002 109.67 119.64 C 109.676 118.455 108.705 117.498 107.52 117.52 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 96.94 117.52 C 95.308 117.52 94.288 119.287 95.104 120.7 C 95.92 122.113 97.96 122.113 98.776 120.7 C 98.962 120.378 99.06 120.012 99.06 119.64 C 99.066 118.467 98.113 117.514 96.94 117.52 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 107.55 109 C 108.717 108.989 109.656 108.037 109.65 106.87 C 109.65 105.246 107.892 104.231 106.485 105.043 C 105.832 105.42 105.43 106.116 105.43 106.87 C 105.424 108.045 106.375 109 107.55 109 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 96.94 109 C 98.109 108.995 99.051 108.039 99.04 106.87 C 99.051 105.704 98.106 104.754 96.94 104.76 C 95.763 104.743 94.8 105.693 94.8 106.87 C 94.8 108.05 95.76 109.006 96.94 109 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 107.55 96.29 C 109.182 96.29 110.202 94.523 109.386 93.11 C 108.57 91.697 106.53 91.697 105.714 93.11 C 105.528 93.432 105.43 93.798 105.43 94.17 C 105.424 95.343 106.377 96.296 107.55 96.29 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 96.94 92.05 C 95.308 92.05 94.288 93.817 95.104 95.23 C 95.92 96.643 97.96 96.643 98.776 95.23 C 98.962 94.908 99.06 94.542 99.06 94.17 C 99.066 92.997 98.113 92.044 96.94 92.05 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 198.72 155.69 C 197.088 155.69 196.068 157.457 196.884 158.87 C 197.7 160.283 199.74 160.283 200.556 158.87 C 200.74 158.551 200.838 158.189 200.84 157.82 C 200.851 156.643 199.897 155.684 198.72 155.69 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 188.14 155.69 C 186.508 155.675 185.472 157.432 186.274 158.853 C 187.077 160.274 189.117 160.293 189.946 158.887 C 190.137 158.564 190.238 158.195 190.24 157.82 C 190.246 156.653 189.307 155.701 188.14 155.69 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 198.72 143 C 197.088 143 196.068 144.767 196.884 146.18 C 197.7 147.593 199.74 147.593 200.556 146.18 C 200.742 145.858 200.84 145.492 200.84 145.12 C 200.857 143.942 199.898 142.983 198.72 143 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 188.14 143 C 186.508 142.985 185.471 144.742 186.274 146.163 C 187.077 147.584 189.117 147.603 189.946 146.197 C 190.138 145.871 190.24 145.499 190.24 145.12 C 190.251 143.952 189.308 143 188.14 143 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 188.14 130.24 C 186.508 130.225 185.472 131.982 186.274 133.403 C 187.077 134.824 189.117 134.843 189.946 133.437 C 190.137 133.114 190.238 132.745 190.24 132.37 C 190.246 131.203 189.307 130.251 188.14 130.24 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 198.72 130.24 C 197.088 130.24 196.068 132.007 196.884 133.42 C 197.7 134.833 199.74 134.833 200.556 133.42 C 200.74 133.101 200.838 132.739 200.84 132.37 C 200.851 131.193 199.897 130.234 198.72 130.24 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 188.14 117.52 C 186.508 117.505 185.471 119.262 186.274 120.683 C 187.077 122.104 189.117 122.123 189.946 120.717 C 190.138 120.391 190.24 120.019 190.24 119.64 C 190.251 118.472 189.308 117.52 188.14 117.52 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 198.72 117.52 C 197.088 117.52 196.068 119.287 196.884 120.7 C 197.7 122.113 199.74 122.113 200.556 120.7 C 200.742 120.378 200.84 120.012 200.84 119.64 C 200.857 118.462 199.898 117.503 198.72 117.52 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 198.72 104.8 C 197.554 104.794 196.609 105.744 196.62 106.91 C 196.62 108.534 198.378 109.549 199.785 108.737 C 201.192 107.925 201.192 105.895 199.785 105.083 C 199.461 104.896 199.094 104.798 198.72 104.8 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 188.14 104.8 C 186.966 104.783 186.009 105.736 186.02 106.91 C 186.02 108.534 187.778 109.549 189.185 108.737 C 189.838 108.36 190.24 107.664 190.24 106.91 C 190.246 105.746 189.304 104.8 188.14 104.8 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 198.72 92.07 C 197.088 92.07 196.068 93.837 196.884 95.25 C 197.7 96.663 199.74 96.663 200.556 95.25 C 200.742 94.928 200.84 94.562 200.84 94.19 C 200.857 93.012 199.898 92.053 198.72 92.07 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 188.14 92.07 C 186.508 92.055 185.471 93.812 186.274 95.233 C 187.077 96.654 189.117 96.673 189.946 95.267 C 190.138 94.941 190.24 94.569 190.24 94.19 C 190.251 93.022 189.308 92.07 188.14 92.07 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 129.82 61.89 C 128.649 61.884 127.696 62.829 127.69 64 L 127.69 147.24 C 127.802 148.876 129.643 149.777 131.004 148.862 C 131.549 148.494 131.895 147.896 131.94 147.24 L 131.94 64 C 131.934 62.833 130.987 61.89 129.82 61.89 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 141.83 61.89 C 140.663 61.89 139.716 62.833 139.71 64 L 139.71 147.24 C 139.822 148.876 141.663 149.777 143.024 148.862 C 143.569 148.494 143.915 147.896 143.96 147.24 L 143.96 64 C 143.954 62.829 143.001 61.884 141.83 61.89 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 153.85 61.89 C 152.683 61.89 151.736 62.833 151.73 64 L 151.73 147.24 C 151.73 148.872 153.497 149.892 154.91 149.076 C 155.566 148.697 155.97 147.997 155.97 147.24 L 155.97 64 C 155.964 62.833 155.017 61.89 153.85 61.89 Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M 165.87 61.89 C 164.703 61.89 163.756 62.833 163.75 64 L 163.75 147.24 C 163.75 148.872 165.517 149.892 166.93 149.076 C 167.586 148.697 167.99 147.997 167.99 147.24 L 167.99 64 C 167.984 62.833 167.037 61.89 165.87 61.89 Z" })));
};
HotelPlaceholder.defaultProps = defaultIconProps;
export default HotelPlaceholder;
