import type { Moment } from 'moment';
import moment from 'moment';

// Below function will return a moment with hour and minute info from originalMoment
// and other info like, date, month, year, seconds, from targetMoment.
// For example: If targetMoment = 04/12/1968, 13:30:15 and originalMoment = 10/15/1999, 04:30:45,
// then the moment returned is 04/12/1968 04:30:15
const restoreTimeFromOriginal = (targetMoment: string | Moment, originalMoment: string | Moment | null): Moment =>
  moment(targetMoment).set({ hour: moment(originalMoment).hour(), minute: moment(originalMoment).minute() });

export default restoreTimeFromOriginal;
