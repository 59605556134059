import * as Sentry from '@sentry/react';
import type { Logger } from './types';
import Config from '../Config';

const info = (message: string): void => {
  Sentry.captureMessage(message, 'log');
};

const error = (err: Error, traceId?: string): void => {
  const debugToolLink = traceId ? `${Config.VITE_DEBUGTOOL_BASE_URL}${traceId}` : 'No trace ID';
  const zipkinLink = traceId ? `${Config.VITE_ZIPKIN_BASE_URL}${traceId}` : 'No trace ID';

  Sentry.captureException(err, {
    contexts: {
      Trace: {
        'Debug Tool Link': debugToolLink,
        'Zipkin Link': zipkinLink,
      },
    },
  });
};

const logger: Logger = {
  info,
  error,
};

export default logger;
