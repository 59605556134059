import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Clock = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M16 6.557c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75-9.75-4.365-9.75-9.75 4.365-9.75 9.75-9.75zm0 2a7.75 7.75 0 100 15.5 7.75 7.75 0 000-15.5zm.001 2.943a1 1 0 01.998.925L17 12.5v3.114l3.008 3.318A1 1 0 0120 20.284l-.061.06a1 1 0 01-1.352-.007l-.06-.062-3.268-3.604a.995.995 0 01-.254-.579L15 16v-3.5a1 1 0 011-1z", fill: themeEnrichedPrimaryColor })));
};
Clock.defaultProps = defaultIconProps;
export default Clock;
