import { Image, ImageV1, usePartnerFeatures } from 'obt-common';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@spotnana/blocks/src/IconButton';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Skeleton } from '@spotnana/blocks/src/Skeleton';
import { unit } from '@spotnana/blocks/src/utils/styling';
import Ellipsis from '@spotnana/pixel-react/dist/Ellipsis';
import { IconName } from '@spotnana/blocks/src/Icon/types';
import { Label, TLabelProps } from '@spotnana/blocks/src/Label/Label';
import { UserAvatarV2, UserAvatarV2Props } from 'src/app/shared/components/UserAvatarV2';
import {
  company_line_container,
  company_logo_container,
  company_name,
  input_label,
  name_and_avatar_container,
  name_and_avatar_outer_container,
  subtext_container,
  current_traveler_container,
  name_labels,
} from './CurrentTraveler.styles';

export interface CurrentTravelerProps {
  loading: boolean;
  currentTraveler: string;
  onClear?: () => void;
  profilePicture?: ImageV1 | Image;
  companyLoading?: boolean;
  companyName?: string;
  email?: string;
  companyLogo?: Image;
  disabled?: boolean;
  isSeat1a?: boolean;
  showCloseIcon?: boolean;
  label?: string;
  travelerLabels?: TLabelProps[];
  subtextRenderer?: () => JSX.Element;
  className?: string;
  iconName?: IconName;
  inactiveUser?: boolean;
  additionalTravelerCount?: number;
}

const CurrentTraveler: React.FC<CurrentTravelerProps> = ({
  loading,
  currentTraveler,
  onClear,
  profilePicture,
  companyLoading,
  companyLogo,
  companyName,
  disabled = false,
  isSeat1a = false,
  iconName = 'Edit',
  showCloseIcon = true,
  label,
  travelerLabels,
  email,
  subtextRenderer,
  className,
  inactiveUser = false,
  additionalTravelerCount,
}: CurrentTravelerProps) => {
  const { t: tt } = useTranslation('WEB');
  const { features } = usePartnerFeatures();
  const { travelerSelection: isTravelerSelectionEnabled } = features;
  const getUserAvatar = (): JSX.Element => {
    // if loading, returns empty string, otherwise returns the value
    const loadingGuard = (value: string | undefined): string => (loading ? '' : value ?? '');

    const avatarProps: UserAvatarV2Props['avatarProps'] = {
      size: 'medium',
      alt: loadingGuard(currentTraveler),
      src: loadingGuard(profilePicture?.url),
      text: loadingGuard(profilePicture?.url ? '' : currentTraveler),
      color: 'primary',
    };

    return <UserAvatarV2 isSeat1a={isSeat1a} avatarProps={avatarProps} />;
  };

  const getUserNameLineHeight = (): number | string => {
    if (!companyLoading && companyName) {
      return 'inherit';
    }
    if (email) {
      return 'inherit';
    }
    return 2;
  };

  const getUserName = (): JSX.Element => {
    const additionalTravelerLabel =
      additionalTravelerCount && additionalTravelerCount > 0 ? ` + ${additionalTravelerCount}` : '';
    return (
      <Ellipsis
        containerStyles={{ lineHeight: getUserNameLineHeight(), display: 'flex', gap: unit(0.5) }}
        title={currentTraveler}
        dataTestId="traveler_name"
      >
        <Typography variant="body2">
          {currentTraveler}
          {additionalTravelerLabel}
        </Typography>
        {travelerLabels && travelerLabels.length > 0 && (
          <div css={name_labels}>
            {travelerLabels.map((nameLabel) => (
              <Label key={nameLabel.label} {...nameLabel} />
            ))}
          </div>
        )}
      </Ellipsis>
    );
  };

  const getSubtextContent = (): JSX.Element => {
    // If user is inactive show the inactive label
    // Company information or email will not be shown in this case
    // Don't show the inactive label if the user is still loading
    if (!loading && inactiveUser) {
      return (
        <div css={subtext_container} className={className}>
          <Typography variant="body3" color="error">
            {tt('INACTIVE USER')}
          </Typography>
        </div>
      );
    }

    if (companyLoading) {
      return (
        <div css={company_line_container} className={className}>
          <span css={company_logo_container}>
            <Skeleton variant="circular" width={14} height={14} />
          </span>
          <Skeleton variant="text" width={100} />
        </div>
      );
    }

    if (companyName) {
      return (
        <div css={company_line_container} className={className}>
          {companyLogo?.url && (
            <span css={company_logo_container}>
              <img
                data-testid="company-logo"
                src={companyLogo.url}
                width={14}
                height={14}
                alt={`${companyName} logo`}
                style={{ borderRadius: '100%' }}
              />
            </span>
          )}
          <Typography as="span" css={company_name} variant="body3" color="secondary">
            {companyName}
          </Typography>
        </div>
      );
    }

    if (email) {
      return (
        <div css={subtext_container} className={className}>
          <Typography variant="body3" color="secondary">
            {email}
          </Typography>
        </div>
      );
    }

    return <></>;
  };

  const getUserAvatarAndName = (): JSX.Element => {
    return (
      <div css={name_and_avatar_outer_container} className={className}>
        {label && <div css={input_label}>{label}</div>}
        <div css={name_and_avatar_container}>
          {getUserAvatar()}
          <div>
            {loading ? tt('Fetching') : getUserName()}
            {subtextRenderer ? subtextRenderer() : getSubtextContent()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div data-testid="box_change-traveler" className={className} css={current_traveler_container}>
      {getUserAvatarAndName()}
      {!disabled && showCloseIcon && isTravelerSelectionEnabled && (
        <IconButton icon={iconName} onClick={onClear} data-testid="remove-traveler" aria-label={tt('Edit traveler')} />
      )}
    </div>
  );
};

export default CurrentTraveler;
