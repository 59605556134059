// Generated using yarn generate command
import { css } from '@emotion/react';

import { themed } from '../utils/styling/themed';
import { cornerRadii, paddings, spacings } from '../utils/styling/utilityTokens';

export const tabs_base = themed(
  ({ palette }) => css`
    .MuiTabs-fixed {
      height: fit-content;
    }

    &.MuiTabs-root {
      min-height: fit-content;
    }
    & .MuiTabs-indicator {
      left: 0;
      bottom: 0;
      max-height: 4px;
      height: 2px;
      width: 100%;
    }
    &.BlocksTabContainer-standard {
      .MuiTabs-flexContainer {
        ${spacings.large}
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: ${palette.border.light};
        }
      }
    }
    &.BlocksTabContainer-filled,
    &.BlocksTabContainer-filledBrand {
      border: 1px solid ${palette.border.light};
      ${cornerRadii.standard}
      ${paddings.x.superTight}
      ${paddings.y.superTight}
      .MuiTabs-flexContainer {
        ${spacings.extraSmall}
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
    height: fit-content;
  `,
);

export const tabs_item_base = themed(
  ({ fontWeight, palette }) => css`
    color: ${palette.text.secondary};
    ${paddings.x.superTight}
    min-height: fit-content;
    min-width: fit-content;
    ${spacings.extraSmall}

    justify-content: flex-start;
    text-transform: none;
    .MuiTab-iconWrapper {
      height: 20px;
      width: 20px;
      color: ${palette.icon.secondary};
      margin: 0px;
    }

    :focus-visible {
      ${cornerRadii.buttonSmall}
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: -2px;
    }

    &.BlocksTabItemContainer-standard {
      font-weight: ${fontWeight.w400};
      :hover {
        color: ${palette.text.primary};
        .MuiTab-iconWrapper {
          color: ${palette.icon.primary};
        }
        :after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: ${palette.border.hover};
          z-index: 1;
        }
      }
      &.Mui-selected {
        color: ${palette.text.primary};
        font-weight: ${fontWeight.w600};
        cursor: default;
        ::after {
          background: ${palette.bg.brand};
        }
      }
    }

    &.BlocksTabItemContainer-filled,
    &.BlocksTabItemContainer-filledBrand {
      ${paddings.x.tight}
      ${cornerRadii.buttonSmall}
      :hover {
        background-color: ${palette.bg.hover};
      }
      :active {
        color: ${palette.text.primary};
        .MuiTab-iconWrapper {
          color: ${palette.icon.primary};
        }
      }
    }

    &.BlocksTabItemContainer-filled {
      &.Mui-selected {
        color: ${palette.text.inverse};
        background-color: ${palette.bg.badgeActive};
        .MuiTab-iconWrapper {
          color: ${palette.icon.inverse};
        }
      }
    }

    &.BlocksTabItemContainer-filledBrand {
      &.Mui-selected {
        color: ${palette.text.buttonLabel};
        background-color: ${palette.bg.brand};
        .MuiTab-iconWrapper {
          color: ${palette.icon.buttonIcon};
        }
      }
    }
  `,
);

export const tabs_item_small = css`
  &.BlocksTabItemContainer-standard {
    ${paddings.y.tight}
  }
  &.BlocksTabItemContainer-filled,
  &.BlocksTabItemContainer-filledBrand {
    ${paddings.y.superTight}
  }
`;

export const tabs_item_medium = css`
  &.BlocksTabItemContainer-standard {
    ${paddings.y.compact}
  }
  &.BlocksTabItemContainer-filled,
  &.BlocksTabItemContainer-filledBrand {
    ${paddings.y.tight}
  }
`;

export const tabs_item_large = themed(
  ({ typography }) => css`
    &.BlocksTabItemContainer-standard {
      ${paddings.y.comfortable}
      ${typography.body1.default}
    }
    &.BlocksTabItemContainer-filled,
    &.BlocksTabItemContainer-filledBrand {
      ${paddings.y.compact}
    }
  `,
);
