import { css } from '@emotion/react';
import { unit, themed } from '@spotnana/blocks/src/utils';

// "Overflow: visible" - Workaround to remove overflow hidden from the modal to render select inside the modal
export const bonvoy_modal = themed(
  ({ zIndex }) => css`
    z-index: ${zIndex.snackbar + 1};

    .BlocksModal-paper {
      overflow: visible;
    }

    .BlocksModal-header {
      border-radius: ${unit(2)} ${unit(2)} 0 0;
    }

    .BlocksModal-footer,
    .BlocksModal-content {
      border-radius: 0 0 ${unit(2)} ${unit(2)};
    }
  `,
);
