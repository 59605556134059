import Box from '@spotnana/pixel-react/dist/Box';
import { Modal } from '@spotnana/pixel-react/dist/Modal';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { dateFormats, dateUtils, Ring, webSessionStorage, StorageKeys, useMountEffect } from 'obt-common';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import RefreshReminder from './RefreshReminder';
import RingChangeConfirm from './RingChangeConfirm';
import RingSelect from './RingSelect';

interface IRingChangeModalProps {
  userEmail: string;
  closeModal: () => void;
  showModal: boolean;
}

interface IRingChangeModalState {
  title: string;
  step: number;
  ring: Ring | undefined;
}

interface IRefreshReminderData {
  refreshTime: string;
  ring: Ring;
}

const ModalBody = styled(Box)(({ theme }) => ({
  width: '558px',
  minHeight: '240px',
  backgroundColor: theme.colors.surface,
}));

const initialState: IRingChangeModalState = {
  title: 'Product version change',
  ring: undefined,
  step: 0,
};

const RingChangeModal = ({ userEmail, closeModal, showModal }: IRingChangeModalProps): JSX.Element => {
  const [state, setState] = useState<IRingChangeModalState>(initialState);
  const [refreshHandle, setRefreshHandle] = useState<number>(0);
  const onRingSelect = (ring: Ring): void => {
    setState({ title: ring.description ?? ring.name, step: 1, ring });
  };
  const setRefreshTimer = (data: IRefreshReminderData): void => {
    const refreshTimeObj = dateUtils.dateUtil(data.refreshTime, dateFormats.ISO);
    const currentTimeObj = dateUtils.dateUtil(new Date().toISOString(), dateFormats.ISO);
    const timerInMilliseconds = refreshTimeObj.diff(currentTimeObj);
    if (timerInMilliseconds > 5000) {
      const handle = setTimeout(() => {
        setState({
          title: 'Product version change - Refresh required',
          ring: data.ring,
          step: 2,
        });
      }, timerInMilliseconds);
      setRefreshHandle(handle as unknown as number);
    }
  };
  const onRingChangeConfirm = (proceed: boolean): void => {
    // close the modal
    closeModal();
    // if proceed then setup a timer
    if (proceed) {
      try {
        const refreshTime = dateUtils.dateUtil().add(2, 'minutes').toISOString();
        const data: IRefreshReminderData = {
          refreshTime,
          ring: state.ring ?? { id: '', name: state.title, description: state.title },
        };
        webSessionStorage.setItem(StorageKeys.PRODUCT_VERSION_CHANGE_REFRESH_TIME, JSON.stringify(data));
        if (refreshHandle > 0) {
          clearTimeout(refreshHandle);
        }
        setRefreshTimer(data);
      } catch {
        // failed to set refresh time
      }
    }
  };
  const onRefreshReminderShown = (): void => {
    setState(initialState);
  };

  // whenever modal is shown reset state
  useEffect(() => {
    if (showModal) {
      setState(initialState);
    }
  }, [showModal]);

  // Check if product version change refresh time is present
  useMountEffect(() => {
    const dataString = webSessionStorage.getItem(StorageKeys.PRODUCT_VERSION_CHANGE_REFRESH_TIME);
    if (dataString) {
      try {
        const data = JSON.parse(dataString) as IRefreshReminderData;
        if (data) {
          setRefreshTimer(data);
        }
      } catch {
        // json is corrupt - leave
      }
    }
  });

  const isModalVisible = showModal || state.step === 2;
  if (!isModalVisible) {
    return <></>;
  }
  return (
    <Modal
      visible={isModalVisible}
      onClose={closeModal}
      header={<Typography variation="h4">{state?.title}</Typography>}
      disableBackdropClick
    >
      <ModalBody>
        {state?.step === 0 && <RingSelect userEmail={userEmail} onSelect={onRingSelect} />}
        {state?.step === 1 && !!state.ring && (
          <RingChangeConfirm userEmail={userEmail} selectedRing={state.ring} onConfirm={onRingChangeConfirm} />
        )}
        {state?.step === 2 && !!state.ring && (
          <RefreshReminder selectedRing={state.ring} onConfirm={onRefreshReminderShown} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default RingChangeModal;
