import { SelectItem, ComboboxItem } from '@ariakit/react';
import clsx from 'clsx';

import { TOptionBase } from '../../utils/select';
import { DeprecatedOptionWithStringifiedValue, restoreDeprecatedOptionType } from '../helpers';
import { useSelectConfig } from '../SelectConfigContext';
import { Checkbox } from '../../Checkbox';
import { SelectRootItemContent } from './SelectRootItemContent';
import { ItemStylingProps } from './types';

export function SelectMultiItem<TItem extends TOptionBase>({
  option,
  style,
  className,
  itemRef,
  ...rest
}: {
  option: DeprecatedOptionWithStringifiedValue<TItem>;
} & ItemStylingProps) {
  const {
    selectStore,
    setValueOnClick,
    onItemClick,
    slots: { ItemDescription, ItemPrefix, ItemSuffix },
  } = useSelectConfig<'select', 'multiple', TItem>();
  const [, valueSet] = selectStore.getSelection(selectStore.useState('value'));
  const isSelected = valueSet.has(option.value);

  return (
    <SelectItem
      key={option.value}
      id={String(option.value)}
      className={clsx(
        'BlocksSelect-SelectItem SelectionMulti',
        {
          'BlocksSelect-checked': isSelected,
        },
        className,
      )}
      ref={itemRef}
      {...rest}
      value={String(option.value)}
      data-value={String(option.value)}
      data-checked={isSelected}
      onClick={() => {
        onItemClick?.(restoreDeprecatedOptionType<TItem>(option));
      }}
      setValueOnClick={setValueOnClick}
      style={option.isHidden ? { display: 'none' } : style}
      {...option.itemProps}
    >
      <SelectRootItemContent
        text={option.label}
        prefixAction={
          <Checkbox
            /** A11y and focus are managed by MUI to mimic `optgroup` behaviour. Focus never goes to checkboxes so it doesn't need a label */
            aria-labelledby={undefined}
            size="small"
            checked={isSelected}
            tabIndex={-1}
            style={{ pointerEvents: 'none' }}
          />
        }
        // checkmark={<SelectItemCheck css={margins.xl.half} checked={isSelected} />}
        prefixContent={ItemPrefix && <ItemPrefix item={option} />}
        suffixContent={ItemSuffix && <ItemSuffix item={option} />}
        description={ItemDescription && <ItemDescription item={option} />}
      />
    </SelectItem>
  );
}

export function ComboboxMultiItem<TItem extends TOptionBase>({
  option,
  style,
  className,
  itemRef,
  ...rest
}: {
  option: DeprecatedOptionWithStringifiedValue<TItem>;
} & ItemStylingProps) {
  const {
    selectStore,
    onItemClick,
    setValueOnClick,
    slots: { ItemPrefix, ItemSuffix, ItemDescription },
  } = useSelectConfig<'select', 'multiple', TItem>();

  const [, valueSet] = selectStore.getSelection(selectStore.useState('value'));
  const isSelected = valueSet.has(option.value);

  return (
    <ComboboxItem
      key={option.value}
      id={String(option.value)}
      focusOnHover={false}
      ref={itemRef}
      {...rest}
      render={
        <SelectItem
          key={option.value}
          value={String(option.value)}
          data-value={String(option.value)}
          data-checked={isSelected}
          className={clsx('BlocksSelect-SelectItem SelectionMulti', className)}
          onClick={() => {
            onItemClick?.(restoreDeprecatedOptionType<TItem>(option));
          }}
          style={option.isHidden ? { display: 'none' } : style}
          setValueOnClick={setValueOnClick}
          {...option.itemProps}
        >
          <SelectRootItemContent
            text={option.label}
            prefixAction={
              <Checkbox
                /** A11y and focus are managed by MUI to mimic `optgroup` behaviour. Focus never goes to checkboxes so it doesn't need a label */
                aria-labelledby={undefined}
                size="small"
                checked={isSelected}
                tabIndex={-1}
                style={{ pointerEvents: 'none' }}
              />
            }
            // checkmark={<SelectItemCheck css={margins.xl.half} checked={isSelected} />}
            prefixContent={ItemPrefix && <ItemPrefix item={option} />}
            suffixContent={ItemSuffix && <ItemSuffix item={option} />}
            description={ItemDescription && <ItemDescription item={option} />}
          />
        </SelectItem>
      }
    />
  );
}
