var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
const EllipsisWrapper = styled.div(({ maxWidth = '100%', position = 'relative', overflow = 'hidden', minHeight }) => ({
    display: 'inline-block',
    overflow,
    maxWidth,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    position,
    lineHeight: 1.25,
    minHeight,
}));
const Ellipsis = (_a) => {
    var { title, width, children, containerStyles, dataTestId, tooltipVariation = 'dark' } = _a, otherProps = __rest(_a, ["title", "width", "children", "containerStyles", "dataTestId", "tooltipVariation"]);
    const [showTooltip, setShowToolTip] = useState(false);
    const wrapperRef = useRef(null);
    useEffect(() => {
        if (wrapperRef.current && wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth && title) {
            setShowToolTip(true);
            return;
        }
        setShowToolTip(false);
    }, [title]);
    return (React.createElement(Tooltip, { variation: tooltipVariation, enterTouchDelay: 0, title: showTooltip ? React.createElement(React.Fragment, null, title) : '', placement: "top" },
        React.createElement(EllipsisWrapper, Object.assign({ maxWidth: width, ref: wrapperRef, style: containerStyles, "data-testid": dataTestId }, otherProps), children)));
};
/**
 * @deprecated
 */
export default Ellipsis;
