import { useState, useCallback } from 'react';

export function useDefaultDisclosure({
  defaultOpen,
  onOpen,
  onClose,
}: {
  defaultOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const [open, setOpen] = useState(defaultOpen ?? false);
  return {
    open,
    setOpen: useCallback(
      (isOpen: boolean) => {
        if (isOpen) onOpen?.();
        else onClose?.();
        setOpen(isOpen);
      },
      [onOpen, onClose],
    ),
  };
}
