import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Grid from '@spotnana/pixel-react/dist/Grid';
import { Icon } from '@spotnana/blocks/src/Icon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useFeatureFlag, useHasUserAccess, userRolesByFeature } from 'obt-common';

import TabSection from './TabSection';
import { header_nav_element } from '../AppHeader/AppHeader.styles';
import { TabPopover, POPOVER_WIDTH } from './TabPopover';
import { useIsEventsFeatureEnabled } from '../AppHeader/useIsEventsFeatureEnabled';

const INDIVIDUAL_URLS = ['/trips', '/group-trips'];
const COMPANY_URLS = ['/company-trips', '/company/approvals', '/events'];

const useTripsOptionsMenuGroups = () => {
  const { t: tt } = useTranslation('WEB');
  const { isEventsEnabled } = useIsEventsFeatureEnabled();
  const isGroupBookingEnabled = useFeatureFlag('FE_AIR_GROUP_BOOKING');
  const isApprovalDashboardEnabled = useFeatureFlag('FE_TRIPS_APPROVAL_DASHBOARD');
  const isCompanyTripsForArrangerEnabled = useFeatureFlag('FE_TMC_TRIPS_FOR_ARRANGER');
  const isCompanyAdminAndAbove = useHasUserAccess(userRolesByFeature.globalTmcAndCompanyRoles);
  const isTravelerArrangerAndAbove = useHasUserAccess(userRolesByFeature.travelerArrangerAndAbove);

  const showCompanyTrips = isCompanyTripsForArrangerEnabled ? isTravelerArrangerAndAbove : isCompanyAdminAndAbove;

  return useMemo(
    () => [
      {
        name: 'trips',
        children: [
          {
            title: tt('Individual'),
            urls: INDIVIDUAL_URLS,
            options: [
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="LuggageCarryOn" color={color} height="24px" width="24px" />
                ),
                content: tt('Trips'),
                path: INDIVIDUAL_URLS[0],
                enableFeature: true,
              },
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="UsersTravelers" color={color} height="20px" width="20px" />
                ),
                content: tt('Group Trips'),
                path: INDIVIDUAL_URLS[1],
                enableFeature: isGroupBookingEnabled && isTravelerArrangerAndAbove,
              },
            ],
            isInBeta: false,
          },
          {
            title: tt('Company'),
            urls: COMPANY_URLS,
            options: [
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="Company" color={color} height="20px" width="20px" />
                ),
                content: tt('Trips'),
                path: COMPANY_URLS[0],
                enableFeature: showCompanyTrips,
              },
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="CheckApproval" color={color} height="20px" width="20px" />
                ),
                content: tt('Approvals'),
                path: COMPANY_URLS[1],
                enableFeature: isApprovalDashboardEnabled && isCompanyAdminAndAbove,
              },
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="UserTravelerGroup" color={color} height="24px" width="24px" />
                ),
                content: tt('Events'),
                path: COMPANY_URLS[2],
                enableFeature: isEventsEnabled,
              },
            ],
            isInBeta: false, // Keep it same as Trips
          },
        ],
      },
    ],
    [
      tt,
      isGroupBookingEnabled,
      isTravelerArrangerAndAbove,
      isApprovalDashboardEnabled,
      isCompanyAdminAndAbove,
      isEventsEnabled,
      showCompanyTrips,
    ],
  );
};

const TripsOptions = ({ onClose }: { onClose?: VoidFunction }): JSX.Element => {
  const { pathname } = useLocation();
  const menuGroups = useTripsOptionsMenuGroups();
  const availableMenuGroups = menuGroups.filter((group) =>
    group.children.some((menu) => menu.options.some((opt) => opt.enableFeature)),
  );

  return (
    <Box mx="-8px" width={POPOVER_WIDTH} data-testid="tooltip-trips_content">
      <Grid.Row spacing={0}>
        {availableMenuGroups.map((group, groupIndex) => (
          <Grid.Col key={group.name} xs={Math.round(12 / availableMenuGroups.length)}>
            <Box position="relative" pb={24} height="full">
              {group.children.map((menuSection) => (
                <Flex key={menuSection.title}>
                  <TabSection
                    title={menuSection.title}
                    isActive={menuSection.urls.some((url) => pathname.startsWith(url))}
                    options={menuSection.options.filter((option) => option.enableFeature)}
                    isInBeta={menuSection.isInBeta}
                    onClick={onClose}
                  />
                </Flex>
              ))}
              {groupIndex !== availableMenuGroups.length - 1 && (
                <Box position="absolute" width="1px" bg="bg.lightBlueGrey" top="24px" right="0" bottom="24px" />
              )}
            </Box>
          </Grid.Col>
        ))}
      </Grid.Row>
    </Box>
  );
};

function TripsTab() {
  const { pathname } = useLocation();
  const { t: tt } = useTranslation('WEB');
  const isTripsTabActive = [...INDIVIDUAL_URLS, ...COMPANY_URLS].some((url) => pathname.includes(url));
  const isTravelerArrangerAndAbove = useHasUserAccess(userRolesByFeature.travelerArrangerAndAbove);

  if (isTravelerArrangerAndAbove) {
    return (
      <TabPopover active={isTripsTabActive} label={tt('Trips')}>
        <TripsOptions />
      </TabPopover>
    );
  }

  return (
    <NavLink css={header_nav_element} to="/trips">
      {tt('Trips')}
    </NavLink>
  );
}

export default TripsTab;
