import {
  Popover as AriakitPopover,
  PopoverDisclosure,
  PopoverDisclosureProps,
  PopoverProps,
  PopoverProvider,
  PopoverStoreProps,
  usePopoverStore,
} from '@ariakit/react/popover';
import { useLayoutEffect } from 'react';

import { popover_styles } from './Popover.styles';

export type TPopoverProps = {
  children: React.ReactNode;
  onClose?: () => void;
  /** As the name suggests, this MUST BE A BUTTON ELEMENT, nothing else. You can use unstyled buttons to make them look anyway you want. */
  htmlButtonElement: PopoverDisclosureProps['render'];
  anchorElement?: HTMLElement | null;

  isOpen?: boolean;
  setOpen?: (open: boolean) => void;
  placement?: PopoverStoreProps['placement'];

  popoverDisclosureProps?: Omit<PopoverDisclosureProps, 'render'>;
} & Omit<PopoverProps, 'store'>;

export const Popover: React.FC<TPopoverProps> = ({
  children,
  onClose,
  htmlButtonElement,
  isOpen,
  setOpen,
  popoverDisclosureProps,
  anchorElement,
  ...popoverProps
}: TPopoverProps) => {
  const { placement, ...restProps } = popoverProps;
  const popover = usePopoverStore({
    open: isOpen,
    defaultOpen: isOpen,
    placement: placement || 'bottom-start',
    setOpen: (open) => {
      if (popover.getState().open && !open) {
        onClose?.();
      }
      setOpen?.(open);
    },
  });

  useLayoutEffect(() => {
    if (anchorElement) popover.setAnchorElement(anchorElement);
  }, [anchorElement, popover]);

  return (
    <PopoverProvider store={popover}>
      <PopoverDisclosure render={htmlButtonElement} {...popoverDisclosureProps} />
      <AriakitPopover
        data-testid="BlocksPopover"
        store={popover}
        css={popover_styles}
        gutter={8}
        portal
        hideOnInteractOutside={() => {
          onClose?.();
          return true;
        }}
        {...restProps}
      >
        {children}
      </AriakitPopover>
    </PopoverProvider>
  );
};
