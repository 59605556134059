import { Button, TButtonProps } from '@spotnana/blocks/src/Button';
import { LinkProps } from 'react-router-dom';
import { CustomLink } from 'src/components/CustomLink';

/**
 * Use this component when you want to use our Blocks `Button` for navigation/as a hyperlink.
 * @param to
 * Same as the `to` prop in `react-router-dom`'s `Link` component.
 */
export const LinkButton = (props: TButtonProps & LinkProps) => {
  // If link button is disabled, we want to use the default button component so link button would not respond to click events
  const { disabled } = props;
  const passedProps = disabled ? props : { ...props, component: CustomLink };
  return <Button {...passedProps} />;
};
