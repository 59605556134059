var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultIconProps } from './common';
const Bassinet = (_a) => {
    var { primaryColor, secondaryColor } = _a, otherProps = __rest(_a, ["primaryColor", "secondaryColor"]);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, otherProps),
        React.createElement("path", { d: "M17.625 7.087H10.62l7.09-2.554c.213.757.315 1.636.255 2.657a.62.62 0 00-.34-.103zm-1.488-5.236L9.69 7.087h.103l7.835-2.822a5.93 5.93 0 00-1.492-2.414zM12.558 0L9.486 6.897l6.447-5.236C14.396.283 12.558 0 12.558 0zM18 11.905V7.76a.384.384 0 00-.375-.391H.375A.385.385 0 000 7.76v4.214c0 .177.16.38.354.452C.354 12.426 4.646 14 9 14c4.354 0 8.646-1.574 8.646-1.574a.592.592 0 00.354-.521z" })));
};
Bassinet.defaultProps = defaultIconProps;
export default Bassinet;
