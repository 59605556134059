import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Cancelled = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 14", fill: "none" },
        React.createElement("path", { d: "M6.9671 0C3.1255 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8451 0 6.9671 0ZM7 12.6C3.9123 12.6 1.4 10.0877 1.4 7C1.4 3.9123 3.8969 1.4 6.9671 1.4C10.0737 1.4 12.6 3.9123 12.6 7C12.6 10.0877 10.0877 12.6 7 12.6Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M6.29999 3.5H7.69999V8.4H6.29999V3.5ZM6.29999 9.1H7.69999V10.5H6.29999V9.1Z", fill: primaryColor })));
};
Cancelled.defaultProps = defaultIconProps;
export default Cancelled;
