import styled from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import React from 'react';
import Box from '../Box';
import Image from '../Image';
const StyledBox = styled(Box)(css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'circle',
    color: 'text.label',
    fontWeight: 'semiBold',
    userSelect: 'none',
}), variant({
    prop: 'size',
    variants: {
        extraSmall: {
            width: 'avatar.extraSmall',
            height: 'avatar.extraSmall',
            fontSize: '10px',
        },
        small: {
            width: 'avatar.small',
            height: 'avatar.small',
            fontSize: 'small',
        },
        mediumSmall: {
            width: 'avatar.mediumSmall',
            height: 'avatar.mediumSmall',
            fontSize: 'h3',
        },
        medium: {
            width: 'avatar.medium',
            height: 'avatar.medium',
            fontSize: 'h3',
        },
        large: {
            width: 'avatar.large',
            height: 'avatar.large',
            fontSize: 'h1',
        },
        extraLarge: {
            width: 'avatar.extraLarge',
            height: 'avatar.extraLarge',
            fontSize: 'h1',
        },
        '24X24': {
            width: 'avatar.24X24',
            height: 'avatar.24X24',
            fontSize: 'body',
        },
    },
}), variant({
    prop: 'shape',
    variants: {
        circle: {
            borderRadius: 'circle',
        },
        rounded: {
            borderRadius: 30,
        },
    },
}));
const StyledImage = styled(Image)(css({
    borderRadius: 'circle',
}), variant({
    prop: 'size',
    variants: {
        extraSmall: {
            width: 'avatar.extraSmall',
            height: 'avatar.extraSmall',
        },
        small: {
            width: 'avatar.small',
            height: 'avatar.small',
        },
        mediumSmall: {
            width: 'avatar.mediumSmall',
            height: 'avatar.mediumSmall',
        },
        medium: {
            width: 'avatar.medium',
            height: 'avatar.medium',
        },
        large: {
            width: 'avatar.large',
            height: 'avatar.large',
        },
        extraLarge: {
            width: 'avatar.extraLarge',
            height: 'avatar.extraLarge',
        },
        '24X24': {
            width: 'avatar.24X24',
            height: 'avatar.24X24',
        },
    },
}), variant({
    prop: 'shape',
    variants: {
        circle: {
            borderRadius: 'circle',
        },
        rounded: {
            borderRadius: 30,
        },
        square: {
            borderRadius: 0,
        },
    },
}));
const Avatar = ({ title, imageSrc, size = 'small', dataTestId, shape = 'circle' }) => {
    const [avatarChar = '...'] = title;
    return (React.createElement(StyledBox, { flex: "0 0 auto", size: size, shape: shape, bg: imageSrc ? 'bg.transparent' : 'bg.lightBlueGrey' },
        React.createElement(StyledImage, { fallback: React.createElement(StyledBox, { bg: "bg.lightBlueGrey", size: size, shape: shape, "data-testid": `avatar-fallback-${dataTestId}` }, avatarChar.toUpperCase()), src: imageSrc !== null && imageSrc !== void 0 ? imageSrc : '', alt: title, "data-testid": dataTestId, size: size, shape: shape })));
};
/**
 * @deprecated
 */
export default Avatar;
