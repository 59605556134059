var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Restaurant = (_a) => {
    var { primaryColor } = _a, restProps = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M35 44V28H29.25V12.75Q29.25 8.8 31.65 6.4Q34.05 4 38 4V44ZM14.25 44V25.6Q11.65 25.05 9.825 22.975Q8 20.9 8 18V4H11V18H14.25V4H17.25V18H20.5V4H23.5V18Q23.5 20.9 21.675 22.975Q19.85 25.05 17.25 25.6V44Z" })));
};
Restaurant.defaultProps = defaultIconProps;
export default Restaurant;
