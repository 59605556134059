/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Transmission types.
 * @export
 * @enum {string}
 */

export const Transmission = {
  UnknownTransmission: 'UNKNOWN_TRANSMISSION',
  ManualUnspecifiedDrive: 'MANUAL_UNSPECIFIED_DRIVE',
  Manual4Wd: 'MANUAL_4WD',
  ManualAwd: 'MANUAL_AWD',
  AutoUnspecifiedDrive: 'AUTO_UNSPECIFIED_DRIVE',
  Auto4Wd: 'AUTO_4WD',
  AutoAwd: 'AUTO_AWD',
} as const;

export type Transmission = (typeof Transmission)[keyof typeof Transmission];
