var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import get from 'lodash/get';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CaretDown = (_a) => {
    var { primaryColor, secondaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 13 8", xmlns: "http://www.w3.org/2000/svg", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M6.35168 6.35168L1.34142 1.34142C1.21543 1.21543 1.30466 1 1.48284 1H11.0479C11.2215 1 11.3127 1.20606 11.1959 1.33453L6.64109 6.3448C6.56428 6.42929 6.43243 6.43243 6.35168 6.35168Z", fill: "inherit", stroke: "inherit", strokeWidth: "2", strokeLinecap: "round" })));
};
CaretDown.defaultProps = defaultIconProps;
export default CaretDown;
