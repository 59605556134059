import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CarouselPrevious = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 32 32", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.067 16L26.689 5.378 21.311 0l-16 16 16 16 5.378-5.378z", fill: themeEnrichedPrimaryColor })));
};
CarouselPrevious.defaultProps = defaultIconProps;
export default CarouselPrevious;
