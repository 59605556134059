import { StorageKeys } from '../../types/storage';
import { saveStorageValue } from '../../hooks/useStorage';
import { emitOrgIdChange } from './eventemitter';

export async function setOrgId(orgId: string): Promise<void> {
  if (!orgId) {
    // Prevent setting empty orgId
    return;
  }

  await saveStorageValue(StorageKeys.ORG_ID, orgId).finally(() => {
    emitOrgIdChange(orgId);
  });
}
