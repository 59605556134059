import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FareIncrease = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M1.59304 9.46849C1.29976 9.76177 0.824268 9.76177 0.530992 9.46849C0.237734 9.17523 0.237714 8.69978 0.530947 8.40649L4.79335 4.14336C5.37913 3.55749 6.32894 3.55744 6.91477 4.14327L9.02101 6.24952L13.438 1.85352H11.75C11.3358 1.85352 11 1.51773 11 1.10352C11 0.689302 11.3358 0.353516 11.75 0.353516H14.5C15.3284 0.353516 16 1.02509 16 1.85352V4.60352C16 5.01773 15.6642 5.35352 15.25 5.35352C14.8358 5.35352 14.5 5.01773 14.5 4.60352V2.91652L10.0817 7.31792C9.49556 7.90179 8.54738 7.90088 7.96239 7.31589L5.85401 5.20752L1.59304 9.46849Z", fill: themeEnrichedPrimaryColor })));
};
FareIncrease.defaultProps = defaultIconProps;
export default FareIncrease;
