import { css } from '@emotion/react';

import { flex, paddings } from '../utils/styling';

export const adornment_wrapper_left_control = css`
  ${flex.init}
  ${flex.justify.center}
  ${flex.align.center}
  ${paddings.xl.half}
  ${paddings.y.half}
`;

export const adornment_wrapper_right_control = css`
  ${flex.init}
  ${flex.justify.center}
  ${flex.align.center}
  ${paddings.xr.half}
  ${paddings.y.half}
`;
