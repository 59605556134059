import { PropsWithChildren, ReactNode } from 'react';

import { Modal } from '@spotnana/blocks/src/Modal';
import { bonvoy_modal } from './BonvoyLoginModal.styles';

interface IBonvoyLoginModalProps {
  title: string;
  footer?: ReactNode;
}

export const BonvoyLoginModal = ({ title, footer, children }: PropsWithChildren<IBonvoyLoginModalProps>) => {
  return (
    <Modal
      css={bonvoy_modal}
      open
      hideBackdrop
      header={{ title }}
      disableEscapeKeyDown
      hideCloseButton
      variant="medium"
      footer={footer ? { content: footer } : undefined}
    >
      {children}
    </Modal>
  );
};
