import type { AxiosError, AxiosRequestConfig } from 'axios';

import { AXIOS_CANCELLED_CALL } from '../../constants';
import type { ErrorParameter, InternalAPIErrorMessage } from '../../types/common';
import type { ICustomAxiosRequestConfig } from '../types';

import onResponseError from './onResponseError';
import { extractAxiosErrorDetailsForCodeTranslation } from '../../utils/errors';

function isAxiosError(error: any): error is AxiosError {
  return 'config' in error;
}

function isCustomRequestConfig(config: AxiosRequestConfig): config is ICustomAxiosRequestConfig {
  return !!config && 'shouldNotShowErrorForNotFound' in config;
}

interface OnErrorEvent {
  message: string;
  errorCode: string;
  errorParameters: ErrorParameter[];
  errorResponseMessages: InternalAPIErrorMessage[];
  requestUrl: string;
  debugToolLink: string;
  originalError: AxiosError;
  errorDetail: string;
}

function onApiError(listener: (event: OnErrorEvent) => void) {
  return onResponseError((error) => {
    const errorAsAxiosError = error as AxiosError;

    if (
      errorAsAxiosError?.message === AXIOS_CANCELLED_CALL ||
      // Only exists if the error is in response
      // not if there was an error in App code itself (ex: in Axios interceptor)
      !isAxiosError(error)
    ) {
      return error;
    }

    const { errorCode, errorParameters, errorResponseMessages, debugToolLink, errorDetail } =
      extractAxiosErrorDetailsForCodeTranslation(errorAsAxiosError);
    const message = errorAsAxiosError?.response?.data?.message?.trim?.() || (error as Error)?.message?.trim?.() || '';
    const requestUrl = error.request?.responseURL || '';

    let shouldIgnoreNotFoundError = false;

    if (isCustomRequestConfig(error.config)) {
      shouldIgnoreNotFoundError = Boolean(error.config.shouldNotShowErrorForNotFound && error.response?.status === 404);
    }

    if (shouldIgnoreNotFoundError) {
      // skip error handling
    } else {
      listener({
        message,
        errorCode,
        errorParameters,
        requestUrl,
        originalError: error,
        errorResponseMessages,
        debugToolLink,
        errorDetail,
      });
    }

    return error;
  });
}

export default onApiError;
