import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Plus = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 14 14" },
        React.createElement("defs", null,
            React.createElement("filter", { id: "sp4sqsihsa" },
                React.createElement("feColorMatrix", { in: "SourceGraphic", values: "0 0 0 0 0.827451 0 0 0 0 0.231373 0 0 0 0 0.278431 0 0 0 1.000000 0" }))),
        React.createElement("g", { fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round", transform: "translate(1 -5)" },
            React.createElement("g", null,
                React.createElement("path", { stroke: themeEnrichedPrimaryColor, strokeWidth: "2", d: "M6 0L6 12M0 6L12 6", transform: "translate(0 6)" })))));
};
Plus.defaultProps = defaultIconProps;
export default Plus;
