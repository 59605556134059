import { StorageKeys } from '../../types/storage';
import { removeStorageValue } from '../../hooks/useStorage';
import { emitOrgIdChange } from './eventemitter';
import { removeFromMemoryCacheOrgId } from './inMemoryUrlCache';

export async function removeOrgId(): Promise<void> {
  await removeStorageValue(StorageKeys.ORG_ID).finally(() => {
    removeFromMemoryCacheOrgId();
    emitOrgIdChange(undefined);
  });
}
