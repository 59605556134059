import { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import copyToClipboard from 'clipboard-copy';

import { ProcessedError } from 'obt-common';

import { Button } from '@spotnana/blocks/src/Button';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Divider } from '@spotnana/blocks/src/Divider';
import { Modal } from '@spotnana/blocks/src/Modal';
import { modal_line_item, modal_item_key, bullet_point, copy_to_clipboard_button, modal_footer } from './styles';

export interface IProps {
  code: string;
  sourceErrorMessages: NonNullable<ProcessedError['sourceErrorMessages']>;
  open: boolean;
  handleClose: () => void;
}

const ViewErrorDetails: FC<IProps> = ({ code, sourceErrorMessages, open, handleClose }) => {
  const { t: tt } = useTranslation('WEB');

  const [copied, setCopied] = useState(false);

  const handleCopyToClipboardClick = useCallback(async () => {
    const error = {
      code,
      sourceErrorMessages,
    };

    await copyToClipboard(JSON.stringify(error));
    setCopied(true);
  }, [code, sourceErrorMessages, setCopied]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      header={{ title: tt('Error details') }}
      footer={{
        content: (
          <div css={modal_footer}>
            <Button
              variant="ghost"
              css={copy_to_clipboard_button}
              startIcon={copied ? 'CheckCircle' : 'Copy'}
              onClick={handleCopyToClipboardClick}
            >
              {copied ? tt('Copied') : tt('Copy to clipboard')}
            </Button>

            <Button css={copy_to_clipboard_button} onClick={handleClose}>
              {tt('Done')}
            </Button>
          </div>
        ),
      }}
    >
      <div css={modal_line_item}>
        <Typography css={modal_item_key} variant="body2">
          {tt('Error Code:')}
        </Typography>
        <Typography variant="body2">&nbsp;{code}</Typography>
      </div>

      <Divider />

      <div css={modal_line_item}>
        <Typography css={modal_item_key} variant="body2">
          {tt('Source error(s):')}
        </Typography>
      </div>

      {sourceErrorMessages.map(({ message, api }, index) => (
        <Fragment key={message}>
          <div css={modal_line_item}>
            <div css={bullet_point} />
            <div>
              <Typography variant="body2">{message}</Typography>
              {api && (
                <div>
                  <Typography variant="body2">
                    <b>{tt('API:')}</b> {api}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      ))}

      <Divider />
    </Modal>
  );
};

export default ViewErrorDetails;
