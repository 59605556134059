export enum PaymentSourceInfoType {
  TYPE_UNKNOWN = 0,
  /** CARD - Normal credit/debit card type. */
  CARD = 1,
  /** VIRTUAL_CARD - Virtual card type. */
  VIRTUAL_CARD = 2,
  /** REWARDS_PROGRAM - Rewards program type. */
  REWARDS_PROGRAM = 4,
  /** DELAYED_INVOICING - Delayed invoicing type. */
  DELAYED_INVOICING = 5,
  /** CUSTOM_PAYMENT_METHOD - Custom payment method */
  CUSTOM_PAYMENT_METHOD = 6,
  /** VENDOR_PROGRAM_PAYMENT - Vendor specific payment methods eg. -  Car Direct billing. */
  VENDOR_PROGRAM_PAYMENT = 7,
  /** UNUSED_CREDIT - Unused credit type. */
  UNUSED_CREDIT = 8,
  UNRECOGNIZED = -1,
}
