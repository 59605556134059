import { ListEventsRequestListEventTypeEnum } from '../types/api/v2/obt/model/list-events-request';

export const EVENTS_PER_PAGE = 10;

export enum EventsCategoryPath {
  UPCOMING = 'upcoming',
  PAST = 'past',
  CANCELLED = 'cancelled',
}

export const EventsPathToRequest: Record<EventsCategoryPath, ListEventsRequestListEventTypeEnum> = {
  [EventsCategoryPath.UPCOMING]: ListEventsRequestListEventTypeEnum.Upcoming,
  [EventsCategoryPath.PAST]: ListEventsRequestListEventTypeEnum.PastOrCompleted,
  [EventsCategoryPath.CANCELLED]: ListEventsRequestListEventTypeEnum.CancelledEvent,
};

export const eventsTypeUrlParams = [EventsCategoryPath.UPCOMING, EventsCategoryPath.PAST, EventsCategoryPath.CANCELLED];

export enum EventDetailsPath {
  SETTINGS = 'settings',
  PARTICIPANTS = 'participants',
}

export const eventDetailsUrlParams = [EventDetailsPath.SETTINGS, EventDetailsPath.PARTICIPANTS];

export const eventEditUrlParams = [EventDetailsPath.SETTINGS, EventDetailsPath.PARTICIPANTS];

export const DEFAULT_PAGINATION_ITEMS_LIMIT_ON_LIST = 25;
