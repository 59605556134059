import type { AirRevalidateItineraryResponse } from '../../types/api/v2/obt/model/air-revalidate-itinerary-response';
import { MoneyUtil } from '../Money';

export const getTotalFareFromV2FareBreakdown = (
  fareBreakdown: AirRevalidateItineraryResponse['fareBreakDown'],
): MoneyUtil => {
  const { base, tax } = fareBreakdown.totalFare;

  const newBaseFare = MoneyUtil.convertV2MoneyToMoneyUtil(base);
  const newTax = MoneyUtil.convertV2MoneyToMoneyUtil(tax);
  const newTotalFare = newBaseFare.add(newTax);

  return newTotalFare;
};
