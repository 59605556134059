import MuiInputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import { RemoveMuiProps } from '../types';
import { input_label } from './InputLabel.styles';

export function InputLabel({
  id,
  children,
  shrink = true,
  ...rest
}: RemoveMuiProps<InputLabelProps> & { id?: string }) {
  return (
    <MuiInputLabel variant="standard" css={input_label} shrink={shrink} id={id} {...rest}>
      {children}
    </MuiInputLabel>
  );
}
