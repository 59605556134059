import { useRef } from 'react';
import produce from 'immer';
import type { UseInfiniteQueryResult, UseMutationResult } from 'react-query';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import api from '../api';
import type SpotnanaError from '../api/SpotnanaError';
import type { SpotnanaQueryOptions, SpotnanaQueryResult } from '../types/common';
import type { AirModifyBookingRequest, AirModifyBookingResponse } from '../types';
import type { AirSearchResponse } from '../types/api/v1/obt/air/air_search_response';
import type { AirModifySearchRequest } from '../types/api/v2/obt/model/air-modify-search-request';
import { defaultQueryClient } from './defaultQueryClient';

export const airModifySearchKey = (request: AirModifySearchRequest): unknown[] => ['air-modify-search', request];
export type AirModifySearchKey = ReturnType<typeof airModifySearchKey>;
export const fetchAirModifySearchResult = async (request: AirModifySearchRequest): Promise<AirSearchResponse> => {
  const data = await api('POST', 'airModifySearch', { data: request }, { allowParallelRequests: true });
  const result = data as AirSearchResponse;

  return result;
};

export const useAirModifySearchQuery = (
  request: AirModifySearchRequest,
  options?: SpotnanaQueryOptions<AirSearchResponse, AirModifySearchKey>,
): SpotnanaQueryResult<AirSearchResponse> =>
  useQuery(airModifySearchKey(request), () => fetchAirModifySearchResult(request), options);

/*
This mutation doesnt actually mutate data.
This is done to get the mutateAsync function to call the query imperetively
*/
export const useAirModifySearchMutation = (): UseMutationResult<
  AirSearchResponse | null,
  SpotnanaError,
  AirModifySearchRequest
> => useMutation((requestBody: AirModifySearchRequest) => fetchAirModifySearchResult(requestBody));

export const useAirModifySearchInfiniteQuery = (
  request: AirModifySearchRequest,
): UseInfiniteQueryResult<AirSearchResponse, SpotnanaError> => {
  /**
   * Only initial request can be with empty `searchId: ""`!
   * Next requests:
   *  - user apply filters/sortings
   *  - getting next chunk of flights(pagination)
   * have to include `searchId` from initial response!!!
   * More info: ST-18057
   */
  const searchIdRef = useRef('');

  return useInfiniteQuery<AirSearchResponse, SpotnanaError>(
    airModifySearchKey(request),
    ({ pageParam }) => {
      const noramizedRequest: AirModifySearchRequest = produce(request, (draftRequest) => {
        if (!draftRequest.legSearchParams) {
          draftRequest.legSearchParams = {};
        }

        const { current: searchId } = searchIdRef;

        if (searchId) {
          draftRequest.legSearchParams.searchId = searchId;
        }

        draftRequest.legSearchParams.pageNumber = pageParam?.pageNumber ?? 1;
      });

      return fetchAirModifySearchResult(noramizedRequest);
    },
    {
      onSuccess: (data) => {
        const searchId = data.pages[data.pages.length - 1]?.searchId ?? '';

        searchIdRef.current = searchId;
      },
      getNextPageParam: (lastPage) => {
        const currentPageNumber = lastPage?.paginationParams?.currentPageNumber ?? 1;
        const totalPages = lastPage?.paginationParams?.totalNumPages ?? 1;

        if (currentPageNumber < totalPages) {
          return { pageNumber: currentPageNumber + 1 };
        }

        return undefined;
      },
    },
  );
};

export const airModifyPnr = async (requestBody: AirModifyBookingRequest): Promise<AirModifyBookingResponse> => {
  const data = await api('POST', 'airModifyPNR', {
    data: requestBody,
  });

  return data as AirModifyBookingResponse;
};

export const useAirExchangePnr = (): UseMutationResult<
  AirModifyBookingResponse,
  SpotnanaError,
  AirModifyBookingRequest
> => useMutation((requestBody: AirModifyBookingRequest) => airModifyPnr(requestBody));

export const invalidateAirModifySearchResponses = (): Promise<void> =>
  defaultQueryClient.invalidateQueries('air-modify-search');
