import cloneDeep from 'lodash/cloneDeep';
import qsImp from 'qs';

const qsParseConfig = {
  allowPrototypes: true,
  ignoreQueryPrefix: true,
  arrayLimit: Infinity,
  depth: 10,
};

/** Can we convert input string to number without losing any leading zeros etc
 * e.g. '098' --> Number('098') --> 98
 * Here, 098 is converted to 98 and does not retain it's leading zero
 * Hence, canConvertStringBackToNumber is false in above case
 */
export const canConvertStringBackToNumber = (value: string): boolean =>
  !Number.isNaN(+value) && String(+value) !== value;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unStringify = (data: any): any => {
  const res = cloneDeep(data);
  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (typeof data[key] === 'object' && data[key] !== null) {
        res[key] = unStringify(res[key]);
      } else if (res[key] === '') {
        // do nothing
      } else if (!Number.isNaN(+res[key])) {
        /** If leading zeros, then treat as string
         * and do not convert to Number bcoz, Number(09) -> 9
         */
        if (!canConvertStringBackToNumber(res[key])) {
          res[key] = Number(res[key]);
        }
      } else if (res[key] === 'false' || res[key] === 'true') {
        res[key] = res[key] === 'true';
      }
    }
  });
  return res;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseParams = <T = any>(data: string | Record<string, string>): T =>
  unStringify(qsImp.parse(data, qsParseConfig));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringifyParams = (data: Record<string, any>): string => qsImp.stringify(data);
