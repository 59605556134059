import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import ChevronBoldRight from '@spotnana/pixel-react/dist/Icons/ChevronBoldRight';
import CircularLoader from '@spotnana/pixel-react/dist/CircularLoader';
import Typography from '@spotnana/pixel-react/dist/Typography';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import first from 'lodash/first';
import { Ring, useRingListQuery, useUserRingListQuery } from 'obt-common';
import styled from 'styled-components';

const RingItem = styled(Flex)(({ theme }) => ({
  width: '224px',
  height: '70px',
  padding: '24px',
  flexWrap: 'wrap',
  alignItems: 'center',
  display: 'inline-flex',
  marginBottom: '20px',
  marginRight: '20px',
  justifyContent: 'space-between',
  borderRadius: '11px',
  border: `1px solid ${theme.colors.inputBorder}`,
}));

interface IRingSelectProps {
  userEmail: string;
  onSelect: (ring: Ring) => void;
}

const RingSelect = ({ userEmail, onSelect }: IRingSelectProps): JSX.Element => {
  const { isFetching, data: myRings } = useUserRingListQuery(userEmail);
  const { isFetching: isFetchingAllRings, data: allRings } = useRingListQuery();
  const currentRingId = !isFetching ? first(myRings?.rings)?.id : null;
  const onRingSelect = (ring: Ring): void => {
    if (ring.id !== currentRingId) {
      onSelect(ring);
    }
  };
  return (
    <Box width="100%" height="100%" p="40px 20px 40px 40px">
      {isFetchingAllRings && <CircularLoader size={20} />}
      {!isFetchingAllRings &&
        allRings?.rings.map((ring) => (
          <RingItem
            key={ring.id}
            cursor={currentRingId === ring.id ? '' : 'pointer'}
            onClick={(): void => onRingSelect(ring)}
            data-testid={`ring-item-${ring.id}`}
          >
            <Typography variation="small" color={currentRingId === ring.id ? 'primary' : ''}>
              {ring.description}
            </Typography>
            {currentRingId !== ring.id && <ChevronBoldRight primaryColor={spotnanaTheme.colors.primary} />}
          </RingItem>
        ))}
    </Box>
  );
};

export default RingSelect;
