import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CovidTesting = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 128 128" },
        React.createElement("path", { d: "M79.2 26C71.2 26 64.8 31.6 64 39.2C64 40 63.6 40.8 63.6 41.6C63.6 43.6 64 45.6 64.4 47.2C66 51.2 68.8 54 72.4 55.6C74.4 56.4 76.4 57.2 78.8 57.2C79.2 57.2 79.6 57.2 80.4 57.2C88.4 56.4 94.4 50 94.4 42C94.4 33.2 87.6 26 79.2 26ZM80.4 53.2C80 53.2 79.6 53.2 78.8 53.2C76.4 53.2 74 52.4 72.4 51.2C70.8 50 69.6 48.8 68.8 47.2C68 45.6 67.6 43.6 67.6 41.6C67.6 40.8 67.6 40 68 39.2C69.2 34 73.6 30.4 79.2 30.4C85.6 30.4 90.8 35.6 90.8 42C90.4 47.6 86.4 52.4 80.4 53.2Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M82.4 44.8H76.4V36.8C76.4 35.6 77.2 34.8 78.4 34.8C79.6 34.8 80.4 35.6 80.4 36.8V40.8H82.4C83.6 40.8 84.4 41.6 84.4 42.8C84.4 44 83.6 44.8 82.4 44.8Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M79.2 53.2C76.8 53.2 74.4 52.4 72.8 51.2V64C71.2 64.4 70 65.2 68.8 66.4C66.8 68.4 63.2 68.4 61.2 66.4C60 65.2 58.4 64.4 56.8 64V47.2H69.2C68 45.6 67.6 43.6 67.6 41.6C67.6 40.8 67.6 40 68 39.2H48.4V64.8V71.2V82.8C48.4 91.6 55.6 98.8 64.4 98.8C73.2 98.8 80.4 91.6 80.4 82.8V70.8V64.4V53.2C80 53.2 79.6 53.2 79.2 53.2ZM64.4 91.2C60 91.2 56.4 87.6 56.4 83.2V70C56.8 70 57.2 70.4 57.2 70.4C59.2 72 62 73.2 64.4 73.2C67.2 73.2 69.6 72.4 71.6 70.4C71.6 70.4 72 70.4 72 70V83.2C72.4 87.6 68.8 91.2 64.4 91.2Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M50.4 25.6C50.4 20.4 47.2 16 43.2 16C39.2 16 36 20.4 36 25.6C36 30 38 33.6 41.2 34.8V101.6C41.2 102.8 42 103.6 43.2 103.6C44.4 103.6 45.2 102.8 45.2 101.6V35.2C48 34 50.4 30.4 50.4 25.6ZM43.2 31.6C41.6 31.6 40 29.2 40 25.6C40 22.4 41.6 20 43.2 20C44.8 20 46.4 22.4 46.4 25.6C46.4 29.2 44.8 31.6 43.2 31.6Z", fill: themeEnrichedPrimaryColor })));
};
CovidTesting.defaultProps = defaultIconProps;
export default CovidTesting;
