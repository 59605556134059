import { useWhiteLabelConfig } from 'obt-common';
import { useScript } from '@spotnana/pixel-react/dist/hooks/useScript';

function InitializePartnerEmbedScript(): JSX.Element {
  const { data: whiteLabelConfig } = useWhiteLabelConfig();

  useScript(whiteLabelConfig?.webLinks?.appEmbedScriptUrl ?? '', 'partner-app-embed-script');
  return <></>;
}

export default InitializePartnerEmbedScript;
