import type { CarLoyaltyProgram } from '../../../types';

export const carLoyaltyPrograms: CarLoyaltyProgram[] = [
  { company: 'Enterprise', companyCode: 'ET', loyaltyProgram: 'Enterprise Plus' },
  { company: 'Hertz', companyCode: 'ZE', loyaltyProgram: 'Gold Plus Rewards' },
  { company: 'Avis', companyCode: 'ZI', loyaltyProgram: 'Avis Preferred' },
  { company: 'National', companyCode: 'ZL', loyaltyProgram: 'Emerald Club' },
  { company: 'Alamo', companyCode: 'AL', loyaltyProgram: 'More Rewards' },
  { company: 'Dollar', companyCode: 'ZR', loyaltyProgram: 'Dollar Express Rewards' },
  { company: 'Thrifty', companyCode: 'ZT', loyaltyProgram: 'Blue Chip Rewards' },
  { company: 'Europcar', companyCode: 'EP', loyaltyProgram: 'Previlege loyaltyProgram' },
  { company: 'Fox', companyCode: 'FX', loyaltyProgram: 'Fox Rewards' },
  { company: 'Sixt', companyCode: 'SX', loyaltyProgram: 'Sixt Advantage Circle' },
  { company: 'Budget', companyCode: 'ZD', loyaltyProgram: 'Fastbreak' },
];

export const carLoyaltyMap: Record<string, string> = carLoyaltyPrograms.reduce(
  (acc, car) => ({
    ...acc,
    [car.companyCode]: `${car.company} - ${car.loyaltyProgram}`,
  }),
  {},
);
