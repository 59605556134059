import { makeBlocksPalette } from './ui-specs/blocksPalette';
import { makeTypeVariantSpec, TTypeVariantConfig } from './ui-specs/typography';
import { fontWeight, remFontSize, remLineHeight, fontFamily as baseFm } from './ux-specs/typography';
import type { TUxColorSpec } from './ux-specs/color';
import { makeMuiZIndexSpec } from './ui-specs/zIndex';

/**
 * This file contains the theme exposed to developers of Blocks project, as well as consumers of Blocks project.
 *
 * MUI theme needs to stay untouched, as-is, in the Emotion theme provider or else MUI styles will break down. Intermingling our custom theme with MUI's
 * very complex theme shape is impractical as it causes a lot of ambiguity as to what properties are MUI properties and what are our own.
 *
 * Instead of conflating the two, we have decided to only override MUI's theme with changes to existing properties so as to change the default colours, etc
 * that MUI internally applies to its design system.
 * We have added this custom blocksTheme object, the shape of which is decided and implemented by us, and is not constrained by MUI spec.
 * MUI's extendTheme option lets us add anything to the MUI theme object, so we've added this as `_blocksTheme` property on the MUI object.
 *
 * This ensures that our own shape is always available in the Emotion provider, without any conflict with MUI's internal working.
 * This also lets us write easy utilities like `useTheme` and a `themed` function for emotion css function that lets developers
 * use our easy-to-use variables and utilities without manual imports, or wrangling through MUI's deeply nested theme object.
 */
export const makeBlocksTheme = ({
  typeConfig,
  colorConfig,
  fontFamily,
  zIndexConfig,
}: {
  typeConfig?: Partial<TTypeVariantConfig>;
  colorConfig?: { light: Record<keyof TUxColorSpec, string>; dark: Record<keyof TUxColorSpec, string> } | undefined;
  fontFamily?: string[];
  zIndexConfig?: Partial<ReturnType<typeof makeMuiZIndexSpec>>;
} = {}) => {
  return Object.freeze({
    typography: makeTypeVariantSpec(typeConfig),
    palette: makeBlocksPalette(colorConfig),
    /**
     * Adds remFontSize and remLineHeight for convenience of writing CSS without doing many imports
     */
    fontSize: typeConfig?.remFontSize ?? remFontSize,
    lineHeight: typeConfig?.remLineHeight ?? remLineHeight,
    fontWeight: typeConfig?.fontWeight ?? fontWeight,
    fontFamily: fontFamily ?? baseFm,
    zIndex: makeMuiZIndexSpec(zIndexConfig),
  } as const);
};

/**
 * We support multiple modes (Light, Dark etc) in blocks theme.
 * Palette has multiple mode objects {light:{}, dark:{}}
 * In actual usage, we only expose the active palette object, not both.
 * The "InternalBlocksTheme" contains all the modes, however consumers
 * will use "BlocksTheme" type where the palette is all the settings of currently selected mode.
 */
export type InternalBlocksTheme = ReturnType<typeof makeBlocksTheme>;
