import type { FunctionInterpolation, SerializedStyles, Theme } from '@emotion/react';
import type { CssVarsTheme as Cxt } from '@mui/material';
import type { InternalBlocksTheme } from '../../BlocksThemeProvider/blocksTheme';
import type { BlocksTheme } from '../../types';

import { INJECTED_THEME_PROPERTYNAME } from '../../BlocksThemeProvider/constants';

type CssVarsTheme = Cxt & { breakpoints: Cxt['breakpoints'] };
/**
 * A utility function to use `@emotion/react` with our custom theme in a type-safe way,
 * without interfering with MUI internal emotion theme object.
 */
export function themed(
  cssFunc: (
    theme: BlocksTheme,
    utils: {
      compose: (anyCssFunc: FunctionInterpolation<Theme>) => SerializedStyles;
    },
  ) => SerializedStyles,
) {
  return ((muiTheme: CssVarsTheme) => {
    const blocksTheme = (muiTheme as unknown as { [INJECTED_THEME_PROPERTYNAME]: InternalBlocksTheme })[
      INJECTED_THEME_PROPERTYNAME
    ];
    const { palette, ...rest } = blocksTheme;
    const { mode = 'light' } = muiTheme.palette;

    const compose = (otherCssFunc: FunctionInterpolation<Theme>) => otherCssFunc(muiTheme) as SerializedStyles;

    return cssFunc({ palette: palette[mode], ...rest }, { compose });
    /**
     * Workaround for emotion-jsx TS compatibility.
     */
  }) as FunctionInterpolation<Theme>;
}
