import type { CountryCallingCode, CountryCode } from 'libphonenumber-js';
import { defineMessage } from '../translations/defineMessage';

/**
 * This list has been extracted from https://countrycode.org/
 */
const countries = [
  {
    CountryName: defineMessage('Afghanistan'),
    ISO2: 'AF',
    PhoneCode: 93,
    Continent: 'Asia',
    Capital: 'Kabul',
    TimeZone: 'Asia/Kabul',
  },
  {
    CountryName: defineMessage('Albania'),
    ISO2: 'AL',
    PhoneCode: 355,
    Continent: 'Europe',
    Capital: 'Tirana',
    TimeZone: 'Europe/Tirane',
  },
  {
    CountryName: defineMessage('Algeria'),
    ISO2: 'DZ',
    PhoneCode: 213,
    Continent: 'Africa',
    Capital: 'Algiers',
    TimeZone: 'Africa/Algiers',
  },
  {
    CountryName: defineMessage('American Samoa'),
    ISO2: 'AS',
    PhoneCode: '1-684',
    Continent: 'Oceania',
    Capital: 'Pago Pago',
    TimeZone: 'Pacific/Pago_Pago',
  },
  {
    CountryName: defineMessage('Andorra'),
    ISO2: 'AD',
    PhoneCode: 376,
    Continent: 'Europe',
    Capital: 'Andorra la Vella',
    TimeZone: 'Europe/Andorra',
  },
  {
    CountryName: defineMessage('Angola'),
    ISO2: 'AO',
    PhoneCode: 244,
    Continent: 'Africa',
    Capital: 'Luanda',
    TimeZone: 'Africa/Luanda',
  },
  {
    CountryName: defineMessage('Anguilla'),
    ISO2: 'AI',
    PhoneCode: '1-264',
    Continent: 'North America',
    Capital: 'The Valley',
    TimeZone: 'America/Anguilla',
  },
  {
    CountryName: defineMessage('Antarctica'),
    ISO2: 'AQ',
    PhoneCode: 672,
    Continent: 'Antarctica',
    Capital: '',
    TimeZone: 'Antarctica/Troll',
  },
  {
    CountryName: defineMessage('Antigua and Barbuda'),
    ISO2: 'AG',
    PhoneCode: '1-268',
    Continent: 'North America',
    Capital: "St. John's",
    TimeZone: 'America/Antigua',
  },
  {
    CountryName: defineMessage('Argentina'),
    ISO2: 'AR',
    PhoneCode: 54,
    Continent: 'South America',
    Capital: 'Buenos Aires',
    TimeZone: 'America/Argentina/Buenos_Aires',
  },
  {
    CountryName: defineMessage('Armenia'),
    ISO2: 'AM',
    PhoneCode: 374,
    Continent: 'Asia',
    Capital: 'Yerevan',
    TimeZone: 'Asia/Yerevan',
  },
  {
    CountryName: defineMessage('Aruba'),
    ISO2: 'AW',
    PhoneCode: 297,
    Continent: 'North America',
    Capital: 'Oranjestad',
    TimeZone: 'America/Aruba',
  },
  {
    CountryName: defineMessage('Australia'),
    ISO2: 'AU',
    PhoneCode: 61,
    Continent: 'Oceania',
    Capital: 'Canberra',
    TimeZone: 'Australia/Sydney',
  },
  {
    CountryName: defineMessage('Austria'),
    ISO2: 'AT',
    PhoneCode: 43,
    Continent: 'Europe',
    Capital: 'Vienna',
    TimeZone: 'Europe/Vienna',
  },
  {
    CountryName: defineMessage('Azerbaijan'),
    ISO2: 'AZ',
    PhoneCode: 994,
    Continent: 'Asia',
    Capital: 'Baku',
    TimeZone: 'Asia/Baku',
  },
  {
    CountryName: defineMessage('Bahamas'),
    ISO2: 'BS',
    PhoneCode: '1-242',
    Continent: 'North America',
    Capital: 'Nassau',
    TimeZone: 'America/Nassau',
  },
  {
    CountryName: defineMessage('Bahrain'),
    ISO2: 'BH',
    PhoneCode: 973,
    Continent: 'Asia',
    Capital: 'Manama',
    TimeZone: 'Asia/Bahrain',
  },
  {
    CountryName: defineMessage('Bangladesh'),
    ISO2: 'BD',
    PhoneCode: 880,
    Continent: 'Asia',
    Capital: 'Dhaka',
    TimeZone: 'Asia/Dhaka',
  },
  {
    CountryName: defineMessage('Barbados'),
    ISO2: 'BB',
    PhoneCode: '1-246',
    Continent: 'North America',
    Capital: 'Bridgetown',
    TimeZone: 'America/Barbados',
  },
  {
    CountryName: defineMessage('Belarus'),
    ISO2: 'BY',
    PhoneCode: 375,
    Continent: 'Europe',
    Capital: 'Minsk',
    TimeZone: 'Europe/Minsk',
  },
  {
    CountryName: defineMessage('Belgium'),
    ISO2: 'BE',
    PhoneCode: 32,
    Continent: 'Europe',
    Capital: 'Brussels',
    TimeZone: 'Europe/Brussels',
  },
  {
    CountryName: defineMessage('Belize'),
    ISO2: 'BZ',
    PhoneCode: 501,
    Continent: 'North America',
    Capital: 'Belmopan',
    TimeZone: 'America/Belize',
  },
  {
    CountryName: defineMessage('Benin'),
    ISO2: 'BJ',
    PhoneCode: 229,
    Continent: 'Africa',
    Capital: 'Porto-Novo',
    TimeZone: 'Africa/Porto-Novo',
  },
  {
    CountryName: defineMessage('Bermuda'),
    ISO2: 'BM',
    PhoneCode: '1-441',
    Continent: 'North America',
    Capital: 'Hamilton',
    TimeZone: 'Atlantic/Bermuda',
  },
  {
    CountryName: defineMessage('Bhutan'),
    ISO2: 'BT',
    PhoneCode: 975,
    Continent: 'Asia',
    Capital: 'Thimphu',
    TimeZone: 'Asia/Thimphu',
  },
  {
    CountryName: defineMessage('Bolivia'),
    ISO2: 'BO',
    PhoneCode: 591,
    Continent: 'South America',
    Capital: 'Sucre',
    TimeZone: 'America/La_Paz',
  },
  {
    CountryName: defineMessage('Bosnia and Herzegovina'),
    ISO2: 'BA',
    PhoneCode: 387,
    Continent: 'Europe',
    Capital: 'Sarajevo',
    TimeZone: 'Europe/Sarajevo',
  },
  {
    CountryName: defineMessage('Botswana'),
    ISO2: 'BW',
    PhoneCode: 267,
    Continent: 'Africa',
    Capital: 'Gaborone',
    TimeZone: 'Africa/Gaborone',
  },
  {
    CountryName: defineMessage('Brazil'),
    ISO2: 'BR',
    PhoneCode: 55,
    Continent: 'South America',
    Capital: 'Brasilia',
    TimeZone: 'America/Sao_Paulo',
  },
  {
    CountryName: defineMessage('British Indian Ocean Territory'),
    ISO2: 'IO',
    PhoneCode: 246,
    Continent: 'Asia',
    Capital: 'Diego Garcia',
    TimeZone: 'Indian/Chagos',
  },
  {
    CountryName: defineMessage('British Virgin Islands'),
    ISO2: 'VG',
    PhoneCode: '1-284',
    Continent: 'North America',
    Capital: 'Road Town',
    TimeZone: 'America/Tortola',
  },
  {
    CountryName: defineMessage('Brunei'),
    ISO2: 'BN',
    PhoneCode: 673,
    Continent: 'Asia',
    Capital: 'Bandar Seri Begawan',
    TimeZone: 'Asia/Brunei',
  },
  {
    CountryName: defineMessage('Bulgaria'),
    ISO2: 'BG',
    PhoneCode: 359,
    Continent: 'Europe',
    Capital: 'Sofia',
    TimeZone: 'Europe/Sofia',
  },
  {
    CountryName: defineMessage('Burkina Faso'),
    ISO2: 'BF',
    PhoneCode: 226,
    Continent: 'Africa',
    Capital: 'Ouagadougou',
    TimeZone: 'Africa/Ouagadougou',
  },
  {
    CountryName: defineMessage('Burundi'),
    ISO2: 'BI',
    PhoneCode: 257,
    Continent: 'Africa',
    Capital: 'Bujumbura',
    TimeZone: 'Africa/Bujumbura',
  },
  {
    CountryName: defineMessage('Cambodia'),
    ISO2: 'KH',
    PhoneCode: 855,
    Continent: 'Asia',
    Capital: 'Phnom Penh',
    TimeZone: 'Asia/Phnom_Penh',
  },
  {
    CountryName: defineMessage('Cameroon'),
    ISO2: 'CM',
    PhoneCode: 237,
    Continent: 'Africa',
    Capital: 'Yaounde',
    TimeZone: 'Africa/Douala',
  },
  {
    CountryName: defineMessage('Canada'),
    ISO2: 'CA',
    PhoneCode: 1,
    Continent: 'North America',
    Capital: 'Ottawa',
    TimeZone: 'America/Toronto',
  },
  {
    CountryName: defineMessage('Cape Verde'),
    ISO2: 'CV',
    PhoneCode: 238,
    Continent: 'Africa',
    Capital: 'Praia',
    TimeZone: 'Atlantic/Cape_Verde',
  },
  {
    CountryName: defineMessage('Cayman Islands'),
    ISO2: 'KY',
    PhoneCode: '1-345',
    Continent: 'North America',
    Capital: 'George Town',
    TimeZone: 'America/Cayman',
  },
  {
    CountryName: defineMessage('Central African Republic'),
    ISO2: 'CF',
    PhoneCode: 236,
    Continent: 'Africa',
    Capital: 'Bangui',
    TimeZone: 'Africa/Bangui',
  },
  {
    CountryName: defineMessage('Chad'),
    ISO2: 'TD',
    PhoneCode: 235,
    Continent: 'Africa',
    Capital: "N'Djamena",
    TimeZone: 'Africa/Ndjamena',
  },
  {
    CountryName: defineMessage('Chile'),
    ISO2: 'CL',
    PhoneCode: 56,
    Continent: 'South America',
    Capital: 'Santiago',
    TimeZone: 'America/Santiago',
  },
  {
    CountryName: defineMessage('China'),
    ISO2: 'CN',
    PhoneCode: 86,
    Continent: 'Asia',
    Capital: 'Beijing',
    TimeZone: 'Asia/Shanghai',
  },
  {
    CountryName: defineMessage('Christmas Island'),
    ISO2: 'CX',
    PhoneCode: 61,
    Continent: 'Asia',
    Capital: 'Flying Fish Cove',
    TimeZone: 'Indian/Christmas',
  },
  {
    CountryName: defineMessage('Cocos Islands'),
    ISO2: 'CC',
    PhoneCode: 61,
    Continent: 'Asia',
    Capital: 'West Island',
    TimeZone: 'Indian/Cocos',
  },
  {
    CountryName: defineMessage('Colombia'),
    ISO2: 'CO',
    PhoneCode: 57,
    Continent: 'South America',
    Capital: 'Bogota',
    TimeZone: 'America/Bogota',
  },
  {
    CountryName: defineMessage('Comoros'),
    ISO2: 'KM',
    PhoneCode: 269,
    Continent: 'Africa',
    Capital: 'Moroni',
    TimeZone: 'Indian/Comoro',
  },
  {
    CountryName: defineMessage('Cook Islands'),
    ISO2: 'CK',
    PhoneCode: 682,
    Continent: 'Oceania',
    Capital: 'Avarua',
    TimeZone: 'Pacific/Rarotonga',
  },
  {
    CountryName: defineMessage('Costa Rica'),
    ISO2: 'CR',
    PhoneCode: 506,
    Continent: 'North America',
    Capital: 'San Jose',
    TimeZone: 'America/Costa_Rica',
  },
  {
    CountryName: defineMessage('Croatia'),
    ISO2: 'HR',
    PhoneCode: 385,
    Continent: 'Europe',
    Capital: 'Zagreb',
    TimeZone: 'Europe/Zagreb',
  },
  {
    CountryName: defineMessage('Cuba'),
    ISO2: 'CU',
    PhoneCode: 53,
    Continent: 'North America',
    Capital: 'Havana',
    TimeZone: 'America/Havana',
  },
  {
    CountryName: defineMessage('Curacao'),
    ISO2: 'CW',
    PhoneCode: 599,
    Continent: 'North America',
    Capital: 'Willemstad',
    TimeZone: 'America/Curacao',
  },
  {
    CountryName: defineMessage('Cyprus'),
    ISO2: 'CY',
    PhoneCode: 357,
    Continent: 'Europe',
    Capital: 'Nicosia',
    TimeZone: 'Asia/Nicosia',
  },
  {
    CountryName: defineMessage('Czech Republic'),
    ISO2: 'CZ',
    PhoneCode: 420,
    Continent: 'Europe',
    Capital: 'Prague',
    TimeZone: 'Europe/Prague',
  },
  {
    CountryName: defineMessage('Democratic Republic of the Congo'),
    ISO2: 'CD',
    PhoneCode: 243,
    Continent: 'Africa',
    Capital: 'Kinshasa',
    TimeZone: 'Africa/Kinshasa',
  },
  {
    CountryName: defineMessage('Denmark'),
    ISO2: 'DK',
    PhoneCode: 45,
    Continent: 'Europe',
    Capital: 'Copenhagen',
    TimeZone: 'Europe/Copenhagen',
  },
  {
    CountryName: defineMessage('Djibouti'),
    ISO2: 'DJ',
    PhoneCode: 253,
    Continent: 'Africa',
    Capital: 'Djibouti',
    TimeZone: 'Africa/Djibouti',
  },
  {
    CountryName: defineMessage('Dominica'),
    ISO2: 'DM',
    PhoneCode: '1-767',
    Continent: 'North America',
    Capital: 'Roseau',
    TimeZone: 'America/Dominica',
  },
  {
    CountryName: defineMessage('Dominican Republic'),
    ISO2: 'DO',
    PhoneCode: '1-809',
    Continent: 'North America',
    Capital: 'Santo Domingo',
    TimeZone: 'America/Santo_Domingo',
  },
  {
    CountryName: defineMessage('East Timor'),
    ISO2: 'TL',
    PhoneCode: 670,
    Continent: 'Oceania',
    Capital: 'Dili',
    TimeZone: 'Asia/Dili',
  },
  {
    CountryName: defineMessage('Ecuador'),
    ISO2: 'EC',
    PhoneCode: 593,
    Continent: 'South America',
    Capital: 'Quito',
    TimeZone: 'America/Guayaquil',
  },
  {
    CountryName: defineMessage('Egypt'),
    ISO2: 'EG',
    PhoneCode: 20,
    Continent: 'Africa',
    Capital: 'Cairo',
    TimeZone: 'Africa/Cairo',
  },
  {
    CountryName: defineMessage('El Salvador'),
    ISO2: 'SV',
    PhoneCode: 503,
    Continent: 'North America',
    Capital: 'San Salvador',
    TimeZone: 'America/El_Salvador',
  },
  {
    CountryName: defineMessage('Equatorial Guinea'),
    ISO2: 'GQ',
    PhoneCode: 240,
    Continent: 'Africa',
    Capital: 'Malabo',
    TimeZone: 'Africa/Malabo',
  },
  {
    CountryName: defineMessage('Eritrea'),
    ISO2: 'ER',
    PhoneCode: 291,
    Continent: 'Africa',
    Capital: 'Asmara',
    TimeZone: 'Africa/Asmara',
  },
  {
    CountryName: defineMessage('Estonia'),
    ISO2: 'EE',
    PhoneCode: 372,
    Continent: 'Europe',
    Capital: 'Tallinn',
    TimeZone: 'Europe/Tallinn',
  },
  {
    CountryName: defineMessage('Ethiopia'),
    ISO2: 'ET',
    PhoneCode: 251,
    Continent: 'Africa',
    Capital: 'Addis Ababa',
    TimeZone: 'Africa/Addis_Ababa',
  },
  {
    CountryName: defineMessage('Falkland Islands'),
    ISO2: 'FK',
    PhoneCode: 500,
    Continent: 'South America',
    Capital: 'Stanley',
    TimeZone: 'Atlantic/Stanley',
  },
  {
    CountryName: defineMessage('Faroe Islands'),
    ISO2: 'FO',
    PhoneCode: 298,
    Continent: 'Europe',
    Capital: 'Torshavn',
    TimeZone: 'Atlantic/Faroe',
  },
  {
    CountryName: defineMessage('Fiji'),
    ISO2: 'FJ',
    PhoneCode: 679,
    Continent: 'Oceania',
    Capital: 'Suva',
    TimeZone: 'Pacific/Fiji',
  },
  {
    CountryName: defineMessage('Finland'),
    ISO2: 'FI',
    PhoneCode: 358,
    Continent: 'Europe',
    Capital: 'Helsinki',
    TimeZone: 'Europe/Helsinki',
  },
  {
    CountryName: defineMessage('France'),
    ISO2: 'FR',
    PhoneCode: 33,
    Continent: 'Europe',
    Capital: 'Paris',
    TimeZone: 'Europe/Paris',
  },
  {
    CountryName: defineMessage('French Polynesia'),
    ISO2: 'PF',
    PhoneCode: 689,
    Continent: 'Oceania',
    Capital: 'Papeete',
    TimeZone: 'Pacific/Tahiti',
  },
  {
    CountryName: defineMessage('Gabon'),
    ISO2: 'GA',
    PhoneCode: 241,
    Continent: 'Africa',
    Capital: 'Libreville',
    TimeZone: 'Africa/Libreville',
  },
  {
    CountryName: defineMessage('Gambia'),
    ISO2: 'GM',
    PhoneCode: 220,
    Continent: 'Africa',
    Capital: 'Banjul',
    TimeZone: 'Africa/Banjul',
  },
  {
    CountryName: defineMessage('Georgia'),
    ISO2: 'GE',
    PhoneCode: 995,
    Continent: 'Asia',
    Capital: 'Tbilisi',
    TimeZone: 'Asia/Tbilisi',
  },
  {
    CountryName: defineMessage('Germany'),
    ISO2: 'DE',
    PhoneCode: 49,
    Continent: 'Europe',
    Capital: 'Berlin',
    TimeZone: 'Europe/Berlin',
  },
  {
    CountryName: defineMessage('Ghana'),
    ISO2: 'GH',
    PhoneCode: 233,
    Continent: 'Africa',
    Capital: 'Accra',
    TimeZone: 'Africa/Accra',
  },
  {
    CountryName: defineMessage('Gibraltar'),
    ISO2: 'GI',
    PhoneCode: 350,
    Continent: 'Europe',
    Capital: 'Gibraltar',
    TimeZone: 'Europe/Gibraltar',
  },
  {
    CountryName: defineMessage('Greece'),
    ISO2: 'GR',
    PhoneCode: 30,
    Continent: 'Europe',
    Capital: 'Athens',
    TimeZone: 'Europe/Athens',
  },
  {
    CountryName: defineMessage('Greenland'),
    ISO2: 'GL',
    PhoneCode: 299,
    Continent: 'North America',
    Capital: 'Nuuk',
    TimeZone: 'America/Godthab',
  },
  {
    CountryName: defineMessage('Grenada'),
    ISO2: 'GD',
    PhoneCode: '1-473',
    Continent: 'North America',
    Capital: "St. George's",
    TimeZone: 'America/Grenada',
  },
  {
    CountryName: defineMessage('Guam'),
    ISO2: 'GU',
    PhoneCode: '1-671',
    Continent: 'Oceania',
    Capital: 'Hagatna',
    TimeZone: 'Pacific/Guam',
  },
  {
    CountryName: defineMessage('Guatemala'),
    ISO2: 'GT',
    PhoneCode: 502,
    Continent: 'North America',
    Capital: 'Guatemala City',
    TimeZone: 'America/Guatemala',
  },
  {
    CountryName: defineMessage('Guernsey'),
    ISO2: 'GG',
    PhoneCode: '44-1481',
    Continent: 'Europe',
    Capital: 'St Peter Port',
    TimeZone: 'Europe/Guernsey',
  },
  {
    CountryName: defineMessage('Guinea'),
    ISO2: 'GN',
    PhoneCode: 224,
    Continent: 'Africa',
    Capital: 'Conakry',
    TimeZone: 'Africa/Conakry',
  },
  {
    CountryName: defineMessage('Guinea-Bissau'),
    ISO2: 'GW',
    PhoneCode: 245,
    Continent: 'Africa',
    Capital: 'Bissau',
    TimeZone: 'Africa/Bissau',
  },
  {
    CountryName: defineMessage('Guyana'),
    ISO2: 'GY',
    PhoneCode: 592,
    Continent: 'South America',
    Capital: 'Georgetown',
    TimeZone: 'America/Guyana',
  },
  {
    CountryName: defineMessage('Haiti'),
    ISO2: 'HT',
    PhoneCode: 509,
    Continent: 'North America',
    Capital: 'Port-au-Prince',
    TimeZone: 'America/Port-au-Prince',
  },
  {
    CountryName: defineMessage('Honduras'),
    ISO2: 'HN',
    PhoneCode: 504,
    Continent: 'North America',
    Capital: 'Tegucigalpa',
    TimeZone: 'America/Tegucigalpa',
  },
  {
    CountryName: defineMessage('Hong Kong'),
    ISO2: 'HK',
    PhoneCode: 852,
    Continent: 'Asia',
    Capital: 'Hong Kong',
    TimeZone: 'Asia/Hong_Kong',
  },
  {
    CountryName: defineMessage('Hungary'),
    ISO2: 'HU',
    PhoneCode: 36,
    Continent: 'Europe',
    Capital: 'Budapest',
    TimeZone: 'Europe/Budapest',
  },
  {
    CountryName: defineMessage('Iceland'),
    ISO2: 'IS',
    PhoneCode: 354,
    Continent: 'Europe',
    Capital: 'Reykjavik',
    TimeZone: 'Atlantic/Reykjavik',
  },
  {
    CountryName: defineMessage('India'),
    ISO2: 'IN',
    PhoneCode: 91,
    Continent: 'Asia',
    Capital: 'New Delhi',
    TimeZone: 'Asia/Kolkata',
  },
  {
    CountryName: defineMessage('Indonesia'),
    ISO2: 'ID',
    PhoneCode: 62,
    Continent: 'Asia',
    Capital: 'Jakarta',
    TimeZone: 'Asia/Jakarta',
  },
  {
    CountryName: defineMessage('Iran'),
    ISO2: 'IR',
    PhoneCode: 98,
    Continent: 'Asia',
    Capital: 'Tehran',
    TimeZone: 'Asia/Tehran',
  },
  {
    CountryName: defineMessage('Iraq'),
    ISO2: 'IQ',
    PhoneCode: 964,
    Continent: 'Asia',
    Capital: 'Baghdad',
    TimeZone: 'Asia/Baghdad',
  },
  {
    CountryName: defineMessage('Ireland'),
    ISO2: 'IE',
    PhoneCode: 353,
    Continent: 'Europe',
    Capital: 'Dublin',
    TimeZone: 'Europe/Dublin',
  },
  {
    CountryName: defineMessage('Isle of Man'),
    ISO2: 'IM',
    PhoneCode: '44-1624',
    Continent: 'Europe',
    Capital: 'Douglas, Isle of Man',
    TimeZone: 'Europe/Isle_of_Man',
  },
  {
    CountryName: defineMessage('Israel'),
    ISO2: 'IL',
    PhoneCode: 972,
    Continent: 'Asia',
    Capital: 'Jerusalem',
    TimeZone: 'Asia/Jerusalem',
  },
  {
    CountryName: defineMessage('Italy'),
    ISO2: 'IT',
    PhoneCode: 39,
    Continent: 'Europe',
    Capital: 'Rome',
    TimeZone: 'Europe/Rome',
  },
  {
    CountryName: defineMessage('Ivory Coast'),
    ISO2: 'CI',
    PhoneCode: 225,
    Continent: 'Africa',
    Capital: 'Yamoussoukro',
    TimeZone: 'Africa/Abidjan',
  },
  {
    CountryName: defineMessage('Jamaica'),
    ISO2: 'JM',
    PhoneCode: '1-876',
    Continent: 'North America',
    Capital: 'Kingston',
    TimeZone: 'America/Jamaica',
  },
  {
    CountryName: defineMessage('Japan'),
    ISO2: 'JP',
    PhoneCode: 81,
    Continent: 'Asia',
    Capital: 'Tokyo',
    TimeZone: 'Asia/Tokyo',
  },
  {
    CountryName: defineMessage('Jersey'),
    ISO2: 'JE',
    PhoneCode: '44-1534',
    Continent: 'Europe',
    Capital: 'Saint Helier',
    TimeZone: 'Europe/Jersey',
  },
  {
    CountryName: defineMessage('Jordan'),
    ISO2: 'JO',
    PhoneCode: 962,
    Continent: 'Asia',
    Capital: 'Amman',
    TimeZone: 'Asia/Amman',
  },
  {
    CountryName: defineMessage('Kazakhstan'),
    ISO2: 'KZ',
    PhoneCode: 7,
    Continent: 'Asia',
    Capital: 'Astana',
    TimeZone: 'Asia/Almaty',
  },
  {
    CountryName: defineMessage('Kenya'),
    ISO2: 'KE',
    PhoneCode: 254,
    Continent: 'Africa',
    Capital: 'Nairobi',
    TimeZone: 'Africa/Nairobi',
  },
  {
    CountryName: defineMessage('Kiribati'),
    ISO2: 'KI',
    PhoneCode: 686,
    Continent: 'Oceania',
    Capital: 'Tarawa',
    TimeZone: 'Pacific/Tarawa',
  },
  {
    CountryName: defineMessage('Kosovo'),
    ISO2: 'XK',
    PhoneCode: 383,
    Continent: 'Europe',
    Capital: 'Pristina',
    TimeZone: 'Europe/Podgorica',
  },
  {
    CountryName: defineMessage('Kuwait'),
    ISO2: 'KW',
    PhoneCode: 965,
    Continent: 'Asia',
    Capital: 'Kuwait City',
    TimeZone: 'Asia/Kuwait',
  },
  {
    CountryName: defineMessage('Kyrgyzstan'),
    ISO2: 'KG',
    PhoneCode: 996,
    Continent: 'Asia',
    Capital: 'Bishkek',
    TimeZone: 'Asia/Bishkek',
  },
  {
    CountryName: defineMessage('Laos'),
    ISO2: 'LA',
    PhoneCode: 856,
    Continent: 'Asia',
    Capital: 'Vientiane',
    TimeZone: 'Asia/Vientiane',
  },
  {
    CountryName: defineMessage('Latvia'),
    ISO2: 'LV',
    PhoneCode: 371,
    Continent: 'Europe',
    Capital: 'Riga',
    TimeZone: 'Europe/Riga',
  },
  {
    CountryName: defineMessage('Lebanon'),
    ISO2: 'LB',
    PhoneCode: 961,
    Continent: 'Asia',
    Capital: 'Beirut',
    TimeZone: 'Asia/Beirut',
  },
  {
    CountryName: defineMessage('Lesotho'),
    ISO2: 'LS',
    PhoneCode: 266,
    Continent: 'Africa',
    Capital: 'Maseru',
    TimeZone: 'Africa/Maseru',
  },
  {
    CountryName: defineMessage('Liberia'),
    ISO2: 'LR',
    PhoneCode: 231,
    Continent: 'Africa',
    Capital: 'Monrovia',
    TimeZone: 'Africa/Monrovia',
  },
  {
    CountryName: defineMessage('Libya'),
    ISO2: 'LY',
    PhoneCode: 218,
    Continent: 'Africa',
    Capital: 'Tripolis',
    TimeZone: 'Africa/Tripoli',
  },
  {
    CountryName: defineMessage('Liechtenstein'),
    ISO2: 'LI',
    PhoneCode: 423,
    Continent: 'Europe',
    Capital: 'Vaduz',
    TimeZone: 'Europe/Vaduz',
  },
  {
    CountryName: defineMessage('Lithuania'),
    ISO2: 'LT',
    PhoneCode: 370,
    Continent: 'Europe',
    Capital: 'Vilnius',
    TimeZone: 'Europe/Vilnius',
  },
  {
    CountryName: defineMessage('Luxembourg'),
    ISO2: 'LU',
    PhoneCode: 352,
    Continent: 'Europe',
    Capital: 'Luxembourg',
    TimeZone: 'Europe/Luxembourg',
  },
  {
    CountryName: defineMessage('Macau'),
    ISO2: 'MO',
    PhoneCode: 853,
    Continent: 'Asia',
    Capital: 'Macao',
    TimeZone: 'Asia/Macau',
  },
  {
    CountryName: defineMessage('Macedonia'),
    ISO2: 'MK',
    PhoneCode: 389,
    Continent: 'Europe',
    Capital: 'Skopje',
    TimeZone: 'Europe/Skopje',
  },
  {
    CountryName: defineMessage('Madagascar'),
    ISO2: 'MG',
    PhoneCode: 261,
    Continent: 'Africa',
    Capital: 'Antananarivo',
    TimeZone: 'Indian/Antananarivo',
  },
  {
    CountryName: defineMessage('Malawi'),
    ISO2: 'MW',
    PhoneCode: 265,
    Continent: 'Africa',
    Capital: 'Lilongwe',
    TimeZone: 'Africa/Blantyre',
  },
  {
    CountryName: defineMessage('Malaysia'),
    ISO2: 'MY',
    PhoneCode: 60,
    Continent: 'Asia',
    Capital: 'Kuala Lumpur',
    TimeZone: 'Asia/Kuala_Lumpur',
  },
  {
    CountryName: defineMessage('Maldives'),
    ISO2: 'MV',
    PhoneCode: 960,
    Continent: 'Asia',
    Capital: 'Male',
    TimeZone: 'Indian/Maldives',
  },
  {
    CountryName: defineMessage('Mali'),
    ISO2: 'ML',
    PhoneCode: 223,
    Continent: 'Africa',
    Capital: 'Bamako',
    TimeZone: 'Africa/Bamako',
  },
  {
    CountryName: defineMessage('Malta'),
    ISO2: 'MT',
    PhoneCode: 356,
    Continent: 'Europe',
    Capital: 'Valletta',
    TimeZone: 'Europe/Malta',
  },
  {
    CountryName: defineMessage('Marshall Islands'),
    ISO2: 'MH',
    PhoneCode: 692,
    Continent: 'Oceania',
    Capital: 'Majuro',
    TimeZone: 'Pacific/Majuro',
  },
  {
    CountryName: defineMessage('Mauritania'),
    ISO2: 'MR',
    PhoneCode: 222,
    Continent: 'Africa',
    Capital: 'Nouakchott',
    TimeZone: 'Africa/Nouakchott',
  },
  {
    CountryName: defineMessage('Mauritius'),
    ISO2: 'MU',
    PhoneCode: 230,
    Continent: 'Africa',
    Capital: 'Port Louis',
    TimeZone: 'Indian/Mauritius',
  },
  {
    CountryName: defineMessage('Mayotte'),
    ISO2: 'YT',
    PhoneCode: 262,
    Continent: 'Africa',
    Capital: 'Mamoudzou',
    TimeZone: 'Indian/Mayotte',
  },
  {
    CountryName: defineMessage('Mexico'),
    ISO2: 'MX',
    PhoneCode: 52,
    Continent: 'North America',
    Capital: 'Mexico City',
    TimeZone: 'America/Mexico_City',
  },
  {
    CountryName: defineMessage('Micronesia'),
    ISO2: 'FM',
    PhoneCode: 691,
    Continent: 'Oceania',
    Capital: 'Palikir',
    TimeZone: 'Pacific/Pohnpei',
  },
  {
    CountryName: defineMessage('Moldova'),
    ISO2: 'MD',
    PhoneCode: 373,
    Continent: 'Europe',
    Capital: 'Chisinau',
    TimeZone: 'Europe/Chisinau',
  },
  {
    CountryName: defineMessage('Monaco'),
    ISO2: 'MC',
    PhoneCode: 377,
    Continent: 'Europe',
    Capital: 'Monaco',
    TimeZone: 'Europe/Monaco',
  },
  {
    CountryName: defineMessage('Mongolia'),
    ISO2: 'MN',
    PhoneCode: 976,
    Continent: 'Asia',
    Capital: 'Ulan Bator',
    TimeZone: 'Asia/Ulaanbaatar',
  },
  {
    CountryName: defineMessage('Montenegro'),
    ISO2: 'ME',
    PhoneCode: 382,
    Continent: 'Europe',
    Capital: 'Podgorica',
    TimeZone: 'Europe/Podgorica',
  },
  {
    CountryName: defineMessage('Montserrat'),
    ISO2: 'MS',
    PhoneCode: '1-664',
    Continent: 'North America',
    Capital: 'Plymouth',
    TimeZone: 'America/Montserrat',
  },
  {
    CountryName: defineMessage('Morocco'),
    ISO2: 'MA',
    PhoneCode: 212,
    Continent: 'Africa',
    Capital: 'Rabat',
    TimeZone: 'Africa/Casablanca',
  },
  {
    CountryName: defineMessage('Mozambique'),
    ISO2: 'MZ',
    PhoneCode: 258,
    Continent: 'Africa',
    Capital: 'Maputo',
    TimeZone: 'Africa/Maputo',
  },
  {
    CountryName: defineMessage('Myanmar'),
    ISO2: 'MM',
    PhoneCode: 95,
    Continent: 'Asia',
    Capital: 'Nay Pyi Taw',
    TimeZone: 'Asia/Rangoon',
  },
  {
    CountryName: defineMessage('Namibia'),
    ISO2: 'NA',
    PhoneCode: 264,
    Continent: 'Africa',
    Capital: 'Windhoek',
    TimeZone: 'Africa/Windhoek',
  },
  {
    CountryName: defineMessage('Nauru'),
    ISO2: 'NR',
    PhoneCode: 674,
    Continent: 'Oceania',
    Capital: 'Yaren',
    TimeZone: 'Pacific/Nauru',
  },
  {
    CountryName: defineMessage('Nepal'),
    ISO2: 'NP',
    PhoneCode: 977,
    Continent: 'Asia',
    Capital: 'Kathmandu',
    TimeZone: 'Asia/Kathmandu',
  },
  {
    CountryName: defineMessage('Netherlands'),
    ISO2: 'NL',
    PhoneCode: 31,
    Continent: 'Europe',
    Capital: 'Amsterdam',
    TimeZone: 'Europe/Amsterdam',
  },
  {
    CountryName: defineMessage('Netherlands Antilles'),
    ISO2: 'AN',
    PhoneCode: 599,
    Continent: 'North America',
    Capital: 'Willemstad',
    TimeZone: 'America/Curacao',
  },
  {
    CountryName: defineMessage('New Caledonia'),
    ISO2: 'NC',
    PhoneCode: 687,
    Continent: 'Oceania',
    Capital: 'Noumea',
    TimeZone: 'Pacific/Noumea',
  },
  {
    CountryName: defineMessage('New Zealand'),
    ISO2: 'NZ',
    PhoneCode: 64,
    Continent: 'Oceania',
    Capital: 'Wellington',
    TimeZone: 'Pacific/Auckland',
  },
  {
    CountryName: defineMessage('Nicaragua'),
    ISO2: 'NI',
    PhoneCode: 505,
    Continent: 'North America',
    Capital: 'Managua',
    TimeZone: 'America/Managua',
  },
  {
    CountryName: defineMessage('Niger'),
    ISO2: 'NE',
    PhoneCode: 227,
    Continent: 'Africa',
    Capital: 'Niamey',
    TimeZone: 'Africa/Niamey',
  },
  {
    CountryName: defineMessage('Nigeria'),
    ISO2: 'NG',
    PhoneCode: 234,
    Continent: 'Africa',
    Capital: 'Abuja',
    TimeZone: 'Africa/Lagos',
  },
  {
    CountryName: defineMessage('Niue'),
    ISO2: 'NU',
    PhoneCode: 683,
    Continent: 'Oceania',
    Capital: 'Alofi',
    TimeZone: 'Pacific/Niue',
  },
  {
    CountryName: defineMessage('North Korea'),
    ISO2: 'KP',
    PhoneCode: 850,
    Continent: 'Asia',
    Capital: 'Pyongyang',
    TimeZone: 'Asia/Pyongyang',
  },
  {
    CountryName: defineMessage('Northern Mariana Islands'),
    ISO2: 'MP',
    PhoneCode: '1-670',
    Continent: 'Oceania',
    Capital: 'Saipan',
    TimeZone: 'Pacific/Saipan',
  },
  {
    CountryName: defineMessage('Norway'),
    ISO2: 'NO',
    PhoneCode: 47,
    Continent: 'Europe',
    Capital: 'Oslo',
    TimeZone: 'Europe/Oslo',
  },
  {
    CountryName: defineMessage('Oman'),
    ISO2: 'OM',
    PhoneCode: 968,
    Continent: 'Asia',
    Capital: 'Muscat',
    TimeZone: 'Asia/Muscat',
  },
  {
    CountryName: defineMessage('Pakistan'),
    ISO2: 'PK',
    PhoneCode: 92,
    Continent: 'Asia',
    Capital: 'Islamabad',
    TimeZone: 'Asia/Karachi',
  },
  {
    CountryName: defineMessage('Palau'),
    ISO2: 'PW',
    PhoneCode: 680,
    Continent: 'Oceania',
    Capital: 'Melekeok',
    TimeZone: 'Pacific/Palau',
  },
  {
    CountryName: defineMessage('Palestine'),
    ISO2: 'PS',
    PhoneCode: 970,
    Continent: 'Asia',
    Capital: 'East Jerusalem',
    TimeZone: 'Asia/Hebron',
  },
  {
    CountryName: defineMessage('Panama'),
    ISO2: 'PA',
    PhoneCode: 507,
    Continent: 'North America',
    Capital: 'Panama City',
    TimeZone: 'America/Panama',
  },
  {
    CountryName: defineMessage('Papua New Guinea'),
    ISO2: 'PG',
    PhoneCode: 675,
    Continent: 'Oceania',
    Capital: 'Port Moresby',
    TimeZone: 'Pacific/Port_Moresby',
  },
  {
    CountryName: defineMessage('Paraguay'),
    ISO2: 'PY',
    PhoneCode: 595,
    Continent: 'South America',
    Capital: 'Asuncion',
    TimeZone: 'America/Asuncion',
  },
  {
    CountryName: defineMessage('Peru'),
    ISO2: 'PE',
    PhoneCode: 51,
    Continent: 'South America',
    Capital: 'Lima',
    TimeZone: 'America/Lima',
  },
  {
    CountryName: defineMessage('Philippines'),
    ISO2: 'PH',
    PhoneCode: 63,
    Continent: 'Asia',
    Capital: 'Manila',
    TimeZone: 'Asia/Manila',
  },
  {
    CountryName: defineMessage('Pitcairn'),
    ISO2: 'PN',
    PhoneCode: 64,
    Continent: 'Oceania',
    Capital: 'Adamstown',
    TimeZone: 'Pacific/Pitcairn',
  },
  {
    CountryName: defineMessage('Poland'),
    ISO2: 'PL',
    PhoneCode: 48,
    Continent: 'Europe',
    Capital: 'Warsaw',
    TimeZone: 'Europe/Warsaw',
  },
  {
    CountryName: defineMessage('Portugal'),
    ISO2: 'PT',
    PhoneCode: 351,
    Continent: 'Europe',
    Capital: 'Lisbon',
    TimeZone: 'Europe/Lisbon',
  },
  {
    CountryName: defineMessage('Puerto Rico'),
    ISO2: 'PR',
    PhoneCode: '1-787',
    Continent: 'North America',
    Capital: 'San Juan',
    TimeZone: 'America/Puerto_Rico',
  },
  {
    CountryName: defineMessage('Qatar'),
    ISO2: 'QA',
    PhoneCode: 974,
    Continent: 'Asia',
    Capital: 'Doha',
    TimeZone: 'Asia/Qatar',
  },
  {
    CountryName: defineMessage('Republic of the Congo'),
    ISO2: 'CG',
    PhoneCode: 242,
    Continent: 'Africa',
    Capital: 'Brazzaville',
    TimeZone: 'Africa/Brazzaville',
  },
  {
    CountryName: defineMessage('Reunion'),
    ISO2: 'RE',
    PhoneCode: 262,
    Continent: 'Africa',
    Capital: 'Saint-Denis',
    TimeZone: 'Indian/Reunion',
  },
  {
    CountryName: defineMessage('Romania'),
    ISO2: 'RO',
    PhoneCode: 40,
    Continent: 'Europe',
    Capital: 'Bucharest',
    TimeZone: 'Europe/Bucharest',
  },
  {
    CountryName: defineMessage('Russia'),
    ISO2: 'RU',
    PhoneCode: 7,
    Continent: 'Europe',
    Capital: 'Moscow',
    TimeZone: 'Europe/Moscow',
  },
  {
    CountryName: defineMessage('Rwanda'),
    ISO2: 'RW',
    PhoneCode: 250,
    Continent: 'Africa',
    Capital: 'Kigali',
    TimeZone: 'Africa/Kigali',
  },
  {
    CountryName: defineMessage('Saint Barthelemy'),
    ISO2: 'BL',
    PhoneCode: 590,
    Continent: 'North America',
    Capital: 'Gustavia',
    TimeZone: 'America/St_Barthelemy',
  },
  {
    CountryName: defineMessage('Saint Helena'),
    ISO2: 'SH',
    PhoneCode: 290,
    Continent: 'Africa',
    Capital: 'Jamestown',
    TimeZone: 'Atlantic/St_Helena',
  },
  {
    CountryName: defineMessage('Saint Kitts and Nevis'),
    ISO2: 'KN',
    PhoneCode: '1-869',
    Continent: 'North America',
    Capital: 'Basseterre',
    TimeZone: 'America/St_Kitts',
  },
  {
    CountryName: defineMessage('Saint Lucia'),
    ISO2: 'LC',
    PhoneCode: '1-758',
    Continent: 'North America',
    Capital: 'Castries',
    TimeZone: 'America/St_Lucia',
  },
  {
    CountryName: defineMessage('Saint Martin'),
    ISO2: 'MF',
    PhoneCode: 590,
    Continent: 'North America',
    Capital: 'Marigot',
    TimeZone: 'America/Marigot',
  },
  {
    CountryName: defineMessage('Saint Pierre and Miquelon'),
    ISO2: 'PM',
    PhoneCode: 508,
    Continent: 'North America',
    Capital: 'Saint-Pierre',
    TimeZone: 'America/Miquelon',
  },
  {
    CountryName: defineMessage('Saint Vincent and the Grenadines'),
    ISO2: 'VC',
    PhoneCode: '1-784',
    Continent: 'North America',
    Capital: 'Kingstown',
    TimeZone: 'America/St_Vincent',
  },
  {
    CountryName: defineMessage('Samoa'),
    ISO2: 'WS',
    PhoneCode: 685,
    Continent: 'Oceania',
    Capital: 'Apia',
    TimeZone: 'Pacific/Apia',
  },
  {
    CountryName: defineMessage('San Marino'),
    ISO2: 'SM',
    PhoneCode: 378,
    Continent: 'Europe',
    Capital: 'San Marino',
    TimeZone: 'Europe/San_Marino',
  },
  {
    CountryName: defineMessage('Sao Tome and Principe'),
    ISO2: 'ST',
    PhoneCode: 239,
    Continent: 'Africa',
    Capital: 'Sao Tome',
    TimeZone: 'Africa/Sao_Tome',
  },
  {
    CountryName: defineMessage('Saudi Arabia'),
    ISO2: 'SA',
    PhoneCode: 966,
    Continent: 'Asia',
    Capital: 'Riyadh',
    TimeZone: 'Asia/Riyadh',
  },
  {
    CountryName: defineMessage('Senegal'),
    ISO2: 'SN',
    PhoneCode: 221,
    Continent: 'Africa',
    Capital: 'Dakar',
    TimeZone: 'Africa/Dakar',
  },
  {
    CountryName: defineMessage('Serbia'),
    ISO2: 'RS',
    PhoneCode: 381,
    Continent: 'Europe',
    Capital: 'Belgrade',
    TimeZone: 'Europe/Belgrade',
  },
  {
    CountryName: defineMessage('Seychelles'),
    ISO2: 'SC',
    PhoneCode: 248,
    Continent: 'Africa',
    Capital: 'Victoria',
    TimeZone: 'Indian/Mahe',
  },
  {
    CountryName: defineMessage('Sierra Leone'),
    ISO2: 'SL',
    PhoneCode: 232,
    Continent: 'Africa',
    Capital: 'Freetown',
    TimeZone: 'Africa/Freetown',
  },
  {
    CountryName: defineMessage('Singapore'),
    ISO2: 'SG',
    PhoneCode: 65,
    Continent: 'Asia',
    Capital: 'Singapore',
    TimeZone: 'Asia/Singapore',
  },
  {
    CountryName: defineMessage('Sint Maarten'),
    ISO2: 'SX',
    PhoneCode: '1-721',
    Continent: 'North America',
    Capital: 'Philipsburg',
    TimeZone: 'America/Lower_Princes',
  },
  {
    CountryName: defineMessage('Slovakia'),
    ISO2: 'SK',
    PhoneCode: 421,
    Continent: 'Europe',
    Capital: 'Bratislava',
    TimeZone: 'Europe/Bratislava',
  },
  {
    CountryName: defineMessage('Slovenia'),
    ISO2: 'SI',
    PhoneCode: 386,
    Continent: 'Europe',
    Capital: 'Ljubljana',
    TimeZone: 'Europe/Ljubljana',
  },
  {
    CountryName: defineMessage('Solomon Islands'),
    ISO2: 'SB',
    PhoneCode: 677,
    Continent: 'Oceania',
    Capital: 'Honiara',
    TimeZone: 'Pacific/Guadalcanal',
  },
  {
    CountryName: defineMessage('Somalia'),
    ISO2: 'SO',
    PhoneCode: 252,
    Continent: 'Africa',
    Capital: 'Mogadishu',
    TimeZone: 'Africa/Mogadishu',
  },
  {
    CountryName: defineMessage('South Africa'),
    ISO2: 'ZA',
    PhoneCode: 27,
    Continent: 'Africa',
    Capital: 'Pretoria',
    TimeZone: 'Africa/Johannesburg',
  },
  {
    CountryName: defineMessage('South Korea'),
    ISO2: 'KR',
    PhoneCode: 82,
    Continent: 'Asia',
    Capital: 'Seoul',
    TimeZone: 'Asia/Seoul',
  },
  {
    CountryName: defineMessage('South Sudan'),
    ISO2: 'SS',
    PhoneCode: 211,
    Continent: 'Africa',
    Capital: 'Juba',
    TimeZone: 'Africa/Juba',
  },
  {
    CountryName: defineMessage('Spain'),
    ISO2: 'ES',
    PhoneCode: 34,
    Continent: 'Europe',
    Capital: 'Madrid',
    TimeZone: 'Europe/Madrid',
  },
  {
    CountryName: defineMessage('Sri Lanka'),
    ISO2: 'LK',
    PhoneCode: 94,
    Continent: 'Asia',
    Capital: 'Colombo',
    TimeZone: 'Asia/Colombo',
  },
  {
    CountryName: defineMessage('Sudan'),
    ISO2: 'SD',
    PhoneCode: 249,
    Continent: 'Africa',
    Capital: 'Khartoum',
    TimeZone: 'Africa/Khartoum',
  },
  {
    CountryName: defineMessage('Suriname'),
    ISO2: 'SR',
    PhoneCode: 597,
    Continent: 'South America',
    Capital: 'Paramaribo',
    TimeZone: 'America/Paramaribo',
  },
  {
    CountryName: defineMessage('Svalbard and Jan Mayen'),
    ISO2: 'SJ',
    PhoneCode: 47,
    Continent: 'Europe',
    Capital: 'Longyearbyen',
    TimeZone: 'Arctic/Longyearbyen',
  },
  {
    CountryName: defineMessage('Swaziland'),
    ISO2: 'SZ',
    PhoneCode: 268,
    Continent: 'Africa',
    Capital: 'Mbabane',
    TimeZone: 'Africa/Mbabane',
  },
  {
    CountryName: defineMessage('Sweden'),
    ISO2: 'SE',
    PhoneCode: 46,
    Continent: 'Europe',
    Capital: 'Stockholm',
    TimeZone: 'Europe/Stockholm',
  },
  {
    CountryName: defineMessage('Switzerland'),
    ISO2: 'CH',
    PhoneCode: 41,
    Continent: 'Europe',
    Capital: 'Berne',
    TimeZone: 'Europe/Zurich',
  },
  {
    CountryName: defineMessage('Syria'),
    ISO2: 'SY',
    PhoneCode: 963,
    Continent: 'Asia',
    Capital: 'Damascus',
    TimeZone: 'Asia/Damascus',
  },
  {
    CountryName: defineMessage('Taiwan'),
    ISO2: 'TW',
    PhoneCode: 886,
    Continent: 'Asia',
    Capital: 'Taipei',
    TimeZone: 'Asia/Taipei',
  },
  {
    CountryName: defineMessage('Tajikistan'),
    ISO2: 'TJ',
    PhoneCode: 992,
    Continent: 'Asia',
    Capital: 'Dushanbe',
    TimeZone: 'Asia/Dushanbe',
  },
  {
    CountryName: defineMessage('Tanzania'),
    ISO2: 'TZ',
    PhoneCode: 255,
    Continent: 'Africa',
    Capital: 'Dodoma',
    TimeZone: 'Africa/Dar_es_Salaam',
  },
  {
    CountryName: defineMessage('Thailand'),
    ISO2: 'TH',
    PhoneCode: 66,
    Continent: 'Asia',
    Capital: 'Bangkok',
    TimeZone: 'Asia/Bangkok',
  },
  {
    CountryName: defineMessage('Togo'),
    ISO2: 'TG',
    PhoneCode: 228,
    Continent: 'Africa',
    Capital: 'Lome',
    TimeZone: 'Africa/Lome',
  },
  {
    CountryName: defineMessage('Tokelau'),
    ISO2: 'TK',
    PhoneCode: 690,
    Continent: 'Oceania',
    Capital: '',
    TimeZone: 'Pacific/Fakaofo',
  },
  {
    CountryName: defineMessage('Tonga'),
    ISO2: 'TO',
    PhoneCode: 676,
    Continent: 'Oceania',
    Capital: "Nuku'alofa",
    TimeZone: 'Pacific/Tongatapu',
  },
  {
    CountryName: defineMessage('Trinidad and Tobago'),
    ISO2: 'TT',
    PhoneCode: '1-868',
    Continent: 'North America',
    Capital: 'Port of Spain',
    TimeZone: 'America/Port_of_Spain',
  },
  {
    CountryName: defineMessage('Tunisia'),
    ISO2: 'TN',
    PhoneCode: 216,
    Continent: 'Africa',
    Capital: 'Tunis',
    TimeZone: 'Africa/Tunis',
  },
  {
    CountryName: defineMessage('Turkey'),
    ISO2: 'TR',
    PhoneCode: 90,
    Continent: 'Asia',
    Capital: 'Ankara',
    TimeZone: 'Europe/Istanbul',
  },
  {
    CountryName: defineMessage('Turkmenistan'),
    ISO2: 'TM',
    PhoneCode: 993,
    Continent: 'Asia',
    Capital: 'Ashgabat',
    TimeZone: 'Asia/Ashgabat',
  },
  {
    CountryName: defineMessage('Turks and Caicos Islands'),
    ISO2: 'TC',
    PhoneCode: '1-649',
    Continent: 'North America',
    Capital: 'Cockburn Town',
    TimeZone: 'America/Grand_Turk',
  },
  {
    CountryName: defineMessage('Tuvalu'),
    ISO2: 'TV',
    PhoneCode: 688,
    Continent: 'Oceania',
    Capital: 'Funafuti',
    TimeZone: 'Pacific/Funafuti',
  },
  {
    CountryName: defineMessage('U.S. Virgin Islands'),
    ISO2: 'VI',
    PhoneCode: '1-340',
    Continent: 'North America',
    Capital: 'Charlotte Amalie',
    TimeZone: 'America/St_Thomas',
  },
  {
    CountryName: defineMessage('Uganda'),
    ISO2: 'UG',
    PhoneCode: 256,
    Continent: 'Africa',
    Capital: 'Kampala',
    TimeZone: 'Africa/Kampala',
  },
  {
    CountryName: defineMessage('Ukraine'),
    ISO2: 'UA',
    PhoneCode: 380,
    Continent: 'Europe',
    Capital: 'Kiev',
    TimeZone: 'Europe/Kiev',
  },
  {
    CountryName: defineMessage('United Arab Emirates'),
    ISO2: 'AE',
    PhoneCode: 971,
    Continent: 'Asia',
    Capital: 'Abu Dhabi',
    TimeZone: 'Asia/Dubai',
  },
  {
    CountryName: defineMessage('United Kingdom'),
    ISO2: 'GB',
    PhoneCode: 44,
    Continent: 'Europe',
    Capital: 'London',
    TimeZone: 'Europe/London',
  },
  {
    CountryName: defineMessage('United States'),
    ISO2: 'US',
    PhoneCode: 1,
    Continent: 'North America',
    Capital: 'Washington',
    TimeZone: 'America/New_York',
  },
  {
    CountryName: defineMessage('Uruguay'),
    ISO2: 'UY',
    PhoneCode: 598,
    Continent: 'South America',
    Capital: 'Montevideo',
    TimeZone: 'America/Montevideo',
  },
  {
    CountryName: defineMessage('Uzbekistan'),
    ISO2: 'UZ',
    PhoneCode: 998,
    Continent: 'Asia',
    Capital: 'Tashkent',
    TimeZone: 'Asia/Tashkent',
  },
  {
    CountryName: defineMessage('Vanuatu'),
    ISO2: 'VU',
    PhoneCode: 678,
    Continent: 'Oceania',
    Capital: 'Port Vila',
    TimeZone: 'Pacific/Efate',
  },
  {
    CountryName: defineMessage('Vatican'),
    ISO2: 'VA',
    PhoneCode: 379,
    Continent: 'Europe',
    Capital: 'Vatican City',
    TimeZone: 'Europe/Vatican',
  },
  {
    CountryName: defineMessage('Venezuela'),
    ISO2: 'VE',
    PhoneCode: 58,
    Continent: 'South America',
    Capital: 'Caracas',
    TimeZone: 'America/Caracas',
  },
  {
    CountryName: defineMessage('Vietnam'),
    ISO2: 'VN',
    PhoneCode: 84,
    Continent: 'Asia',
    Capital: 'Hanoi',
    TimeZone: 'Asia/Ho_Chi_Minh',
  },
  {
    CountryName: defineMessage('Wallis and Futuna'),
    ISO2: 'WF',
    PhoneCode: 681,
    Continent: 'Oceania',
    Capital: 'Mata Utu',
    TimeZone: 'Pacific/Wallis',
  },
  {
    CountryName: defineMessage('Western Sahara'),
    ISO2: 'EH',
    PhoneCode: 212,
    Continent: 'Africa',
    Capital: 'El-Aaiun',
    TimeZone: 'Africa/El_Aaiun',
  },
  {
    CountryName: defineMessage('Yemen'),
    ISO2: 'YE',
    PhoneCode: 967,
    Continent: 'Asia',
    Capital: 'Sanaa',
    TimeZone: 'Asia/Aden',
  },
  {
    CountryName: defineMessage('Zambia'),
    ISO2: 'ZM',
    PhoneCode: 260,
    Continent: 'Africa',
    Capital: 'Lusaka',
    TimeZone: 'Africa/Lusaka',
  },
  {
    CountryName: defineMessage('Zimbabwe'),
    ISO2: 'ZW',
    PhoneCode: 263,
    Continent: 'Africa',
    Capital: 'Harare',
    TimeZone: 'Africa/Harare',
  },
];
/**
 * @deprecated
 * Please use hook `useAvailableCountryList` to get the list of countries instead
 */
export const countryCodeMapper = countries.map((countryDetails) => ({
  value: countryDetails.ISO2,
  label: countryDetails.CountryName,
}));

const formatPhoneCodeString = (code: string | number): string => {
  if (typeof code === 'string') {
    return code.replace('-', '');
  }
  return `${code}`;
};

/**
 * Example:
 * {
 *    IN: 'India',
 *    CN: 'China'
 * }
 */
export const countryNameMap: Record<string, string> = countries.reduce(
  (acc, { ISO2, CountryName }) => ({
    ...acc,
    [ISO2]: CountryName,
  }),
  {},
);

/**
 * Example:
 * {
 *    'IN': '91',
 *    'CN': '86
 * }
 */
const countryCallingCodeMap: Record<string, string> = countries.reduce(
  (acc, { ISO2, PhoneCode }) => ({
    ...acc,
    [ISO2]: formatPhoneCodeString(PhoneCode),
  }),
  {},
);

const countryISO2CodeMap: Record<string, string> = countries.reduce(
  (acc, { ISO2, PhoneCode }) => ({
    ...acc,
    [formatPhoneCodeString(PhoneCode)]: ISO2,
  }),
  {},
);

export const countryMapAsCountry = countries.map((country) => ({
  countryCallingCode: country.PhoneCode as CountryCallingCode,
  countryName: country.CountryName,
  countryISO2Code: country.ISO2 as CountryCode,
}));

export const countryCallingCodeList = countries.map((country) => ({
  value: country.ISO2,
  label: `${country.CountryName} (+${country.PhoneCode})`,
}));

export function generateTranslatedCountryCallingCodeList(
  tCommon: (key: string) => string,
): { label: string; value: string }[] {
  return countries.map((country) => ({
    value: country.ISO2,
    label: `${tCommon(country.CountryName)} (+${country.PhoneCode})`,
  }));
}

export const getCountryName = (code: string): string => countryNameMap[code] ?? '';

export const getCountryCallingCode = (code: string): string => countryCallingCodeMap[code] ?? '';

export const getCountryISO2Code = (code: string): string => countryISO2CodeMap[code] ?? '';

export const getCountryCodeFromName = (countryName: string): string | undefined =>
  countryCodeMapper.find(({ label }) => label === countryName)?.value;

export default countryCodeMapper;
