import { css } from '@emotion/react';
import { flex, paddings, margins } from '@spotnana/blocks/src/utils/styling';

export const theme_mode_text = css`
  ${flex.init}
  ${flex.row}
  ${flex.align.center}
  ${paddings.xl.p1point5}
  height: 45px;
  :hover {
    font-weight: 600;
  }
  .MuiFormControlLabel-root {
    ${flex.justify.between}
    width: 100%;
  }
  .MuiSwitch-root {
    ${margins.xr.m1}
  }
`;
