import { CardCompany } from '../types/api/v2/obt/model/card-company';

export const amadeusCardCompanyMap = {
  visa: CardCompany.Visa,
  mastercard: CardCompany.Mastercard,
  uatp: 'uatp',
  japancredit: CardCompany.Jcb,
  amex: CardCompany.Amex,
  diners: CardCompany.DinersClub,
  discovery: CardCompany.Discover,
  qantaspass: 'qantaspass',
};
