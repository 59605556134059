import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Outside = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 40 40" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.0014 8.19775C20.0008 9.57761 18.8635 10.6629 17.5291 11.0139C13.3757 12.1064 10.3125 15.8872 10.3125 20.3834C10.3125 25.7337 14.65 30.0709 20.0004 30.0709C25.2698 30.0709 29.5564 25.8637 29.6845 20.6249L32.1852 20.6248C32.0566 27.2445 26.6507 32.5709 20.0004 32.5709C13.2693 32.5709 7.8125 27.1144 7.8125 20.3834C7.8125 13.724 12.7133 8.32346 20.0014 8.19775Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.2472 22.8408C24.887 22.7861 25.3893 22.2494 25.3893 21.5954L25.3847 21.4875C25.33 20.8476 24.7933 20.3454 24.1393 20.3454L22.5156 20.3454L29.5088 13.3522L29.5723 13.2838C29.9959 12.7927 29.9747 12.0504 29.5088 11.5844C29.0206 11.0963 28.2292 11.0963 27.741 11.5844L20.748 18.5775L20.7479 16.954L20.7433 16.8461C20.6886 16.2062 20.1519 15.704 19.4979 15.704C18.8076 15.704 18.2479 16.2636 18.2479 16.954L18.2479 21.5699C18.2412 21.8983 18.3632 22.2288 18.6138 22.4794C18.8635 22.7292 19.1927 22.8512 19.5201 22.8454L24.1393 22.8454L24.2472 22.8408Z", fill: themeEnrichedPrimaryColor }),
        ' '));
};
Outside.defaultProps = defaultIconProps;
export default Outside;
