/* eslint-disable */

export enum TitleEnum {
  TITLE_UNKNOWN = 0,
  MR = 1,
  MS = 2,
  MRS = 3,
  MX = 4,
  MASTER = 5,
  MISS = 6,
  PROFESSOR = 7,
  CAPTAIN = 8,
  DR = 9,
  REVEREND = 10,
  HONOURABLE = 11,
  SIR = 12,
  LADY = 13,
  AMBASSADOR = 14,
  LORD = 15,
  BRIGADIER = 16,
  SENATOR = 17,
  DAME = 18,
  JUSTICE = 19,
  /** UK - Unknown or not provided */
  UK = 20,
  UNRECOGNIZED = -1,
}

export type Title = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | -1;
