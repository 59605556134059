import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const PaidChange = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7.84291 11.4068H8.47418L8.47953 10.7889C9.63776 10.7006 10.3092 10.0854 10.3118 9.15722C10.3092 8.24508 9.62171 7.76092 8.66945 7.54693L8.51163 7.50948L8.52233 6.17203C8.87809 6.25495 9.09476 6.48232 9.12686 6.81935H10.2369C10.2236 5.93396 9.56287 5.30001 8.53303 5.18766L8.53838 4.55906H7.9071L7.90175 5.18231C6.85587 5.28396 6.11759 5.91524 6.12294 6.82738C6.12027 7.6352 6.69002 8.09796 7.61554 8.31997L7.87233 8.38417L7.85895 9.7992C7.42295 9.71627 7.12871 9.44878 7.09928 9.00207H5.9785C6.00525 10.0827 6.7141 10.6873 7.84825 10.7862L7.84291 11.4068ZM8.49023 9.7992L8.50093 8.55002C8.91554 8.68109 9.14023 8.84961 9.14291 9.15454C9.14023 9.47821 8.89414 9.71895 8.49023 9.7992ZM7.88035 7.34631C7.54599 7.23129 7.30525 7.05742 7.3106 6.74981C7.3106 6.46359 7.51389 6.24158 7.89105 6.164L7.88035 7.34631Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M9.86468 12.3318C10.1274 12.0689 10.5534 12.0689 10.8162 12.3317C11.0651 12.5807 11.0782 12.9762 10.8555 13.2407L10.8162 13.2835L10.0327 14.0671L10.8162 14.8511C11.0651 15.1001 11.0782 15.4957 10.8555 15.7601L10.8162 15.8029C10.5673 16.0519 10.1718 16.065 9.90751 15.8422L9.86471 15.8029L8.60556 14.5434C8.35666 14.2944 8.34354 13.8989 8.56623 13.6344L8.60553 13.5916L9.86468 12.3318Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M15.3967 10.0949C15.7514 10.0949 16.042 10.3695 16.0676 10.7177L16.0695 10.7679V12.153C16.0695 13.5544 14.9556 14.6957 13.5653 14.739L13.4832 14.7403H10.23C9.85844 14.7403 9.55722 14.439 9.55722 14.0673C9.52705 13.6198 9.7526 13.1786 10.1798 13.3962L10.23 13.3943H13.4832C14.1462 13.3943 14.6879 12.8738 14.7222 12.2189L14.7239 12.153V10.7679C14.7239 10.3963 15.0251 10.0949 15.3967 10.0949Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M6.20482 3.66821C5.94209 3.93105 5.5161 3.93108 5.25333 3.66828C5.00439 3.41931 4.99126 3.02376 5.21396 2.75933L5.25326 2.71652L6.03678 1.93288L5.25329 1.14888C5.00438 0.899889 4.99128 0.50434 5.21399 0.239924L5.25329 0.197116C5.50221 -0.0518728 5.89765 -0.0649774 6.16199 0.157802L6.20478 0.197116L7.46393 1.45663C7.71284 1.70561 7.72595 2.10113 7.50326 2.36555L7.46397 2.40836L6.20482 3.66821Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M0.672802 5.90506C0.318113 5.90506 0.0275292 5.63052 0.00184523 5.28229L0 5.23206V3.84703C0 2.44561 1.11386 1.30435 2.50416 1.26097L2.58625 1.25969H5.83948C6.21105 1.25969 6.51228 1.561 6.51228 1.93269C6.54244 2.38023 6.31689 2.82137 5.88969 2.60384L5.83948 2.60569H2.58625C1.92326 2.60569 1.38158 3.12619 1.34732 3.78111L1.3456 3.84703V5.23206C1.3456 5.60375 1.04438 5.90506 0.672802 5.90506Z", fill: themeEnrichedPrimaryColor })));
};
PaidChange.defaultProps = defaultIconProps;
export default PaidChange;
