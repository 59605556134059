import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Document = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.33333 3.18876C3.33333 2.45235 3.93029 1.85537 4.66667 1.85537H8.16672V5.02623C8.16672 5.67059 8.68905 6.19295 9.33338 6.19295H12.6667V12.8559C12.6667 13.5923 12.0697 14.1893 11.3333 14.1893H4.66667C3.93029 14.1893 3.33333 13.5923 3.33333 12.8559V3.18876ZM11.7279 5.1929L9.16672 2.6316V5.02623C9.16672 5.11828 9.24134 5.1929 9.33338 5.1929H11.7279ZM4.66667 0.521973C3.19391 0.521973 2 1.71593 2 3.18876V12.8559C2 14.3287 3.19391 15.5227 4.66667 15.5227H11.3333C12.8061 15.5227 14 14.3287 14 12.8559V6.13171C14 5.77807 13.8595 5.43892 13.6095 5.18886L9.43096 1.01015C9.1184 0.697575 8.69448 0.521973 8.25245 0.521973H4.66667ZM4.99986 10.2297C4.83878 10.2297 4.7082 10.3606 4.7082 10.522C4.7082 10.6834 4.83878 10.8143 4.99986 10.8143H11.3332C11.4943 10.8143 11.6249 10.6834 11.6249 10.522C11.6249 10.3606 11.4943 10.2297 11.3332 10.2297H4.99986ZM4.7082 12.5219C4.7082 12.3605 4.83878 12.2296 4.99986 12.2296H11.3332C11.4943 12.2296 11.6249 12.3605 11.6249 12.5219C11.6249 12.6834 11.4943 12.8142 11.3332 12.8142H4.99986C4.83878 12.8142 4.7082 12.6834 4.7082 12.5219ZM4.99986 8.2295C4.83878 8.2295 4.7082 8.36037 4.7082 8.52181C4.7082 8.68326 4.83878 8.81413 4.99986 8.81413H11.3332C11.4943 8.81413 11.6249 8.68326 11.6249 8.52181C11.6249 8.36037 11.4943 8.2295 11.3332 8.2295H4.99986ZM4.7082 6.52166C4.7082 6.36022 4.83878 6.22934 4.99986 6.22934H7.3332C7.49428 6.22934 7.62487 6.36022 7.62487 6.52166C7.62487 6.6831 7.49428 6.81398 7.3332 6.81398H4.99986C4.83878 6.81398 4.7082 6.6831 4.7082 6.52166Z", fill: themeEnrichedPrimaryColor })));
};
Document.defaultProps = defaultIconProps;
export default Document;
