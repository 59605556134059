import Bowser from 'bowser';
import type { IExtraHeaders } from '../../getApplicationServerHeader';

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const browser = typeof window === 'undefined' ? null : Bowser.getParser(window.navigator.userAgent);

function getExtraHeaders(): IExtraHeaders {
  const defaultValue: IExtraHeaders = {
    'x-app-type': 'web',
    'x-app-platform': browser?.getOSName(true) || 'unknown',
    'x-app-version': '1.0.0',
  };
  try {
    const { extraHeaders = defaultValue } = window.ReactNativeWebView.getInjectedObjectJson();
    return extraHeaders;
  } catch (error) {
    return defaultValue;
  }
}

const extraHeaders = getExtraHeaders();

export default extraHeaders;
