enum AirErrors {
  UNKNOWN_AIR_ERROR = 'UNKNOWN_AIR_ERROR',
  INVALID_AIRPORT_CODE = 'INVALID_AIRPORT_CODE',
  SABRE_AIR_EXCHANGE_NO_CONFIRMATION = 'SABRE_AIR_EXCHANGE_NO_CONFIRMATION',
  SABRE_AIR_EXCHANGE_MULTIPLE_TICKETS_FOR_SAME_PASSENGER = 'SABRE_AIR_EXCHANGE_MULTIPLE_TICKETS_FOR_SAME_PASSENGER',
}

enum AuthorizationErrors {
  UNKNOWN_AUTHORIZATION_ERROR = 'UNKNOWN_AUTHORIZATION_ERROR',
  INVALID_USER = 'INVALID_USER',
  AUTH_MISSING = 'AUTH_MISSING',
  INVALID_ENTITY_ID = 'INVALID_ENTITY_ID',
  INVALID_PROFILE_RELATION = 'INVALID_PROFILE_RELATION',
  INVALID_ROLE_RELATION = 'INVALID_ROLE_RELATION',
  INVALID_PROFILE_SEARCH_RELATION = 'INVALID_PROFILE_SEARCH_RELATION',
  INVALID_NAMESPACE = 'INVALID_NAMESPACE',
  INVALID_TUPLE = 'INVALID_TUPLE',
  INVALID_REQUEST_TYPE = 'INVALID_REQUEST_TYPE',
  EMPTY_OBJECT_ID_LIST = 'EMPTY_OBJECT_ID_LIST',
  INVALID_ENV_ERROR = 'INVALID_ENV_ERROR',
  INVALID_SUPPLIER_TYPE = 'INVALID_SUPPLIER_TYPE',
  ACCESS_TOKEN_ABSENT = 'ACCESS_TOKEN_ABSENT',
  INVALID_AUTH_HEADER = 'INVALID_AUTH_HEADER',
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  INVALID_SSO_CONFIG = 'INVALID_SSO_CONFIG',
  USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED',
}

enum AutocompleteErrors {
  UNKNOWN_AUTOCOMPLETE_ERROR = 'UNKNOWN_AUTOCOMPLETE_ERROR',
  GOOGLE_PLACES_FETCH_ERROR = 'GOOGLE_PLACES_FETCH_ERROR',
  INVALID_REQUEST_TYPE_FOUND = 'INVALID_REQUEST_TYPE_FOUND',
}

enum AviaErrors {
  UNKNOWN_AVIA_ERROR = 'UNKNOWN_AVIA_ERROR',
  AVIA_REQUEST_INVALID_TRAVELER_COUNT = 'AVIA_REQUEST_INVALID_TRAVELER_COUNT',
  AVIA_REQUEST_MISSING_TRAVELER_NAME = 'AVIA_REQUEST_MISSING_TRAVELER_NAME',
  AVIA_REQUEST_INVALID_DATE = 'AVIA_REQUEST_INVALID_DATE',
  AVIA_REQUEST_MISSING_PASSPORT_ID = 'AVIA_REQUEST_MISSING_PASSPORT_ID',
  AVIA_REQUEST_MISSING_NATIONALITY = 'AVIA_REQUEST_MISSING_NATIONALITY',
  AVIA_BAD_REQUEST = 'AVIA_BAD_REQUEST',
  AVIA_REQUEST_MISSING_PASSPORT = 'AVIA_REQUEST_MISSING_PASSPORT',
  AVIA_REQUEST_MISSING_LEGS = 'AVIA_REQUEST_MISSING_LEGS',
  AVIA_REQUEST_MISSING_ORIGIN = 'AVIA_REQUEST_MISSING_ORIGIN',
  AVIA_REQUEST_MISSING_DESTINATION = 'AVIA_REQUEST_MISSING_DESTINATION',
  AVIA_RESPONSE_MAPPER_FAILED = 'AVIA_RESPONSE_MAPPER_FAILED',
  AVIA_BAD_RESPONSE = 'AVIA_BAD_RESPONSE',
  AVIA_REQUEST_INVALID_CABIN_TYPE = 'AVIA_REQUEST_INVALID_CABIN_TYPE',
  AVIA_REQUEST_INVALID_CABIN_CODE = 'AVIA_REQUEST_INVALID_CABIN_CODE',
  AVIA_REQUEST_INVALID_PASSENGER_CODE = 'AVIA_REQUEST_INVALID_PASSENGER_CODE',
  AVIA_REQUEST_INVALID_GENDER = 'AVIA_REQUEST_INVALID_GENDER',
  AVIA_REQUEST_MISSING_CABIN_FILTER = 'AVIA_REQUEST_MISSING_CABIN_FILTER',
  AVIA_REST_CALL_FAILURE = 'AVIA_REST_CALL_FAILURE',
}

enum CarErrors {
  UNKNOWN_CAR_ERROR = 'UNKNOWN_CAR_ERROR',
  SABRE_REQUEST_CAR_MISSING_PICKUP = 'SABRE_REQUEST_CAR_MISSING_PICKUP',
  SABRE_REQUEST_CAR_MISSING_DROP_OFF = 'SABRE_REQUEST_CAR_MISSING_DROP_OFF',
  SABRE_REQUEST_CAR_MISSING_DATE_TIME = 'SABRE_REQUEST_CAR_MISSING_DATE_TIME',
  SABRE_REQUEST_CAR_MISSING_LOCATION_DATA = 'SABRE_REQUEST_CAR_MISSING_LOCATION_DATA',
  SABRE_REQUEST_CAR_INVALID_AGE = 'SABRE_REQUEST_CAR_INVALID_AGE',
  SABRE_REQUEST_CAR_MISSING_PROFILE = 'SABRE_REQUEST_CAR_MISSING_PROFILE',
  SABRE_REQUEST_CAR_MISSING_EMPLOYEE_PROFILE = 'SABRE_REQUEST_CAR_MISSING_EMPLOYEE_PROFILE',
  SABRE_REQUEST_CAR_MISSING_PROFILE_ID = 'SABRE_REQUEST_CAR_MISSING_PROFILE_ID',
  SABRE_REQUEST_CAR_INVALID_CAR_KEY = 'SABRE_REQUEST_CAR_INVALID_CAR_KEY',
  SABRE_REQUEST_MISSING_EXTENDED_LOCATION = 'SABRE_REQUEST_MISSING_EXTENDED_LOCATION',
  SABRE_RESPONSE_CAR_MAPPER_FAILED = 'SABRE_RESPONSE_CAR_MAPPER_FAILED',
}

enum CommonErrors {
  DUPLICATE_BOOKING_FOUND = 'DUPLICATE_BOOKING_FOUND',
  INVALID_DOB = 'INVALID_DOB',
  INVALID_GENDER = 'INVALID_GENDER',
  INVALID_KNOWN_TRAVELER_NUMBER = 'INVALID_KNOWN_TRAVELER_NUMBER',
  INVALID_NAME = 'INVALID_NAME',
  INVALID_PASSPORT_NUMBER = 'INVALID_PASSPORT_NUMBER',
  INVALID_PHONE_NUMBER_PATTERN = 'INVALID_PHONE_NUMBER_PATTERN',
  INVALID_REDRESS_NUMBER = 'INVALID_REDRESS_NUMBER',
}

enum GenericErrors {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  INVALID_OPERATOR = 'INVALID_OPERATOR',
  MISSING_ERROR_CODE = 'MISSING_ERROR_CODE',
}

enum HotelErrors {
  UNKNOWN_HOTEL_ERROR = 'UNKNOWN_HOTEL_ERROR',
  HOTEL_SEARCH_REQUEST_ERROR = 'HOTEL_SEARCH_REQUEST_ERROR',
  SABRE_RESPONSE_HOTEL_MAPPER_FAILED = 'SABRE_RESPONSE_HOTEL_MAPPER_FAILED',
  SABRE_REQUEST_HOTEL_INVALID_GUARANTEE_TYPE = 'SABRE_REQUEST_HOTEL_INVALID_GUARANTEE_TYPE',
  SABRE_REQUEST_HOTEL_INVALID_BOOKING_KEY = 'SABRE_REQUEST_HOTEL_INVALID_BOOKING_KEY',
  SABRE_REQUEST_HOTEL_INVALID_PRICE_VALIDATE_KEY = 'SABRE_REQUEST_HOTEL_INVALID_PRICE_VALIDATE_KEY',
  SABRE_REQUEST_HOTEL_INVALID_DETAILS_KEY = 'SABRE_REQUEST_HOTEL_INVALID_DETAILS_KEY',
  SABRE_REQUEST_HOTEL_MISSING_CURRENCY_CODE = 'SABRE_REQUEST_HOTEL_MISSING_CURRENCY_CODE',
  SABRE_REQUEST_HOTEL_MISSING_SEARCH_PARAMETERS = 'SABRE_REQUEST_HOTEL_MISSING_SEARCH_PARAMETERS',
  SABRE_REQUEST_HOTEL_MISSING_ADULT = 'SABRE_REQUEST_HOTEL_MISSING_ADULT',
  SABRE_REQUEST_HOTEL_INVALID_OCCUPANCY_DATES = 'SABRE_REQUEST_HOTEL_INVALID_OCCUPANCY_DATES',
  SABRE_REQUEST_HOTEL_INVALID_LOCATION_SEARCH_TYPE = 'SABRE_REQUEST_HOTEL_INVALID_LOCATION_SEARCH_TYPE',
  SABRE_HOTEL_BAD_RESPONSE = 'SABRE_HOTEL_BAD_RESPONSE',
  SABRE_RESPONSE_HOTEL_PNR_MAPPER_FAILED = 'SABRE_RESPONSE_HOTEL_PNR_MAPPER_FAILED',
  SABRE_INVALID_PAGINATION_DETAILS = 'SABRE_INVALID_PAGINATION_DETAILS',
}

enum NDCErrors {
  UNKNOWN_NDC_ERROR = 'UNKNOWN_NDC_ERROR',
  NDC_BAD_RESPONSE = 'NDC_BAD_RESPONSE',
  IATA_BAD_RESPONSE = 'IATA_BAD_RESPONSE',
}

enum PaymentErrors {
  UNKNOWN_PAYMENTS_ERROR = 'UNKNOWN_PAYMENTS_ERROR',
  DELETE_PAYMENT_CARD_ERROR = 'DELETE_PAYMENT_CARD_ERROR',
  CONFIRM_PAYMENT_CARD_ERROR = 'CONFIRM_PAYMENT_CARD_ERROR',
}

enum PCCErrors {
  PCC_READ_FAILED = 'PCC_READ_FAILED',
  DAO_PCC_UPDATE_FAILED = 'DAO_PCC_UPDATE_FAILED',
  DAO_PCC_DELETE_FAILED = 'DAO_PCC_DELETE_FAILED',
  INVALID_PCC_CREDENTIAL = 'INVALID_PCC_CREDENTIAL',
  INVALID_PCC_NAME = 'INVALID_PCC_NAME',
  INVALID_PCC_SUPPLIER_TYPE = 'INVALID_PCC_SUPPLIER_TYPE',
  INVALID_PCC_TRAVEL_TYPE = 'INVALID_PCC_TRAVEL_TYPE',
  INVALID_PCC_ID = 'INVALID_PCC_ID',
  INVALID_PCC_QUEUE = 'INVALID_PCC_QUEUE',
  INVALID_PCC_CURRENCY_CODE = 'INVALID_PCC_CURRENCY_CODE',
  INVALID_PCC_TMC_ID = 'INVALID_PCC_TMC_ID',
  INVALID_PCC_LENDER_TMC_ID = 'INVALID_PCC_LENDER_TMC_ID',
}

enum PolicyErrors {
  UNKNOWN_POLICY_ERROR = 'UNKNOWN_POLICY_ERROR',
  INVALID_UPDATE_POLICY_REQUEST = 'INVALID_UPDATE_POLICY_REQUEST',
  INVALID_READ_REQUEST_TYPE_CASE = 'INVALID_READ_REQUEST_TYPE_CASE',
  INVALID_CONTEXT_OPERATION = 'INVALID_CONTEXT_OPERATION',
  INVALID_POLICY_TYPE = 'INVALID_POLICY_TYPE',
  UDE_INVALID_RESPONSE = 'UDE_INVALID_RESPONSE',
  INVALID_TRAVELER_COUNT = 'INVALID_TRAVELER_COUNT',
  INVALID_STATUS = 'INVALID_STATUS',
  INVALID_LIST_REQUEST_TYPE_CASE = 'INVALID_LIST_REQUEST_TYPE_CASE',
  INVALID_OPERATION_ORDER = 'INVALID_OPERATION_ORDER',
  MISSING_POLICY_ID = 'MISSING_POLICY_ID',
  INVALID_POLICY_ID = 'INVALID_POLICY_ID',
  INVALID_TIMESTAMP = 'INVALID_TIMESTAMP',
  INVALID_HOTEL_BOOKING_KEY = 'INVALID_HOTEL_BOOKING_KEY',
  UNSUPPORTED_OPERATION = 'UNSUPPORTED_OPERATION',
  INVALID_POLICY_COUNT = 'INVALID_POLICY_COUNT',
  POLICY_CACHE_ERROR = 'POLICY_CACHE_ERROR',
  UDE_CACHE_ERROR = 'UDE_CACHE_ERROR',
  UDE_INVALID_RESPONSE_COUNT = 'UDE_INVALID_RESPONSE_COUNT',
  MISSING_UDE_RESPONSE = 'MISSING_UDE_RESPONSE',
  MISSING_ORGANIZATION_ID = 'MISSING_ORGANIZATION_ID',
  INVALID_READ_POLICY_REQUEST_CASE = 'INVALID_READ_POLICY_REQUEST_CASE',
  INVALID_POLICY_REQUEST = 'INVALID_POLICY_REQUEST',
  UNSUPPORTED_POLICY_TYPE = 'UNSUPPORTED_POLICY_TYPE',
  UDE_INVALID_REQUEST = 'UDE_INVALID_REQUEST',
  UDE_MISSING_ORGANIZATION_ID = 'UDE_MISSING_ORGANIZATION_ID',
  INVALID_UDE_ID = 'INVALID_UDE_ID',
  INVALID_POLICY_UPDATE_REQUEST = 'INVALID_POLICY_UPDATE_REQUEST',
  DAO_POLICY_DELETE_FAILED = 'DAO_POLICY_DELETE_FAILED',
  DUPLICATE_POLICY_NAME = 'DUPLICATE_POLICY_NAME',
  DUPLICATE_POLICY_TYPE = 'DUPLICATE_POLICY_TYPE',
  POLICY_READ_FAILED = 'POLICY_READ_FAILED',
  POLICY_UPDATE_FAILED = 'POLICY_UPDATE_FAILED',
  STALE_POLICY_UPDATE_REQUEST = 'STALE_POLICY_UPDATE_REQUEST',
  TIMEZONE_NOT_FOUND_FOR_HOTEL_COORDINATES = 'TIMEZONE_NOT_FOUND_FOR_HOTEL_COORDINATES',
  POLICY_METADATA_READ_FAILED = 'POLICY_METADATA_READ_FAILED',
  INVALID_DELETE_POLICY_REQUEST = 'INVALID_DELETE_POLICY_REQUEST',
  INTERNAL_DEFAULT_POLICY_ABSENT = 'INTERNAL_DEFAULT_POLICY_ABSENT',
  DEFAULT_POLICY_ABSENT = 'DEFAULT_POLICY_ABSENT',
}

enum ProfileErrors {
  UNKNOWN_PROFILE_ERROR = 'UNKNOWN_PROFILE_ERROR',
  SABRE_REQUEST_LEGAL_ENTITY_SYNC_FAILED = 'SABRE_REQUEST_LEGAL_ENTITY_SYNC_FAILED',
  SABRE_REQUEST_INVALID_THIRD_PARTY_INFO = 'SABRE_REQUEST_INVALID_THIRD_PARTY_INFO',
  SABRE_REQUEST_TRAVELER_SYNC_FAILED = 'SABRE_REQUEST_TRAVELER_SYNC_FAILED',
  SABRE_REQUEST_ORGANIZATION_MAPPER_FAILED = 'SABRE_REQUEST_ORGANIZATION_MAPPER_FAILED',
  SABRE_REQUEST_PROFILE_MAPPER_FAILED = 'SABRE_REQUEST_PROFILE_MAPPER_FAILED',
  SABRE_RESPONSE_PROFILE_MAPPER_FAILED = 'SABRE_RESPONSE_PROFILE_MAPPER_FAILED',
  LEGAL_ENTITY_READ_ONLY = 'LEGAL_ENTITY_READ_ONLY',
  EMAIL_ADDRESS_ALREADY_EXISTS = 'EMAIL_ADDRESS_ALREADY_EXISTS',
}

enum RouteHappyErrors {
  UNKNOWN_ROUTE_HAPPY_ERROR = 'UNKNOWN_ROUTE_HAPPY_ERROR',
  RH_REQUEST_INVALID_ITINERARY_INFO_LIST = 'RH_REQUEST_INVALID_ITINERARY_INFO_LIST',
  RH_REQUEST_INVALID_CABIN_TYPE = 'RH_REQUEST_INVALID_CABIN_TYPE',
  RH_BAD_RESPONSE = 'RH_BAD_RESPONSE',
  RH_REST_CALL_FAILURE = 'RH_REST_CALL_FAILURE',
  RH_RETRY_REST_CALL_FAILURE = 'RH_RETRY_REST_CALL_FAILURE',
}

enum SabreErrors {
  UNKNOWN_SABRE_COMMON_ERROR = 'UNKNOWN_SABRE_COMMON_ERROR',
  SABRE_REQUEST_INVALID_SORT_BY = 'SABRE_REQUEST_INVALID_SORT_BY',
  SABRE_REQUEST_INVALID_LOYALTY_INFO_TYPE = 'SABRE_REQUEST_INVALID_LOYALTY_INFO_TYPE',
  SABRE_REQUEST_INVALID_CARD_TYPE = 'SABRE_REQUEST_INVALID_CARD_TYPE',
  SABRE_REQUEST_MISSING_CARD_NAME = 'SABRE_REQUEST_MISSING_CARD_NAME',
  SABRE_REST_CALL_FAILURE = 'SABRE_REST_CALL_FAILURE',
  SABRE_RETRY_REST_CALL_FAILURE = 'SABRE_RETRY_REST_CALL_FAILURE',
  SABRE_RETRY_SOAP_CALL_FAILURE = 'SABRE_RETRY_SOAP_CALL_FAILURE',
  SABRE_SOAP_CALL_FAILURE = 'SABRE_SOAP_CALL_FAILURE',
  SABRE_CANCEL_PNR_INVALID_TYPE = 'SABRE_CANCEL_PNR_INVALID_TYPE',
}

enum ShellPNRErrors {
  INVALID_SHELL_PNR_CREATE_REASON_ERROR = 'INVALID_SHELL_PNR_CREATE_REASON_ERROR',
  INVALID_MARKUP_PERCENTAGE = 'INVALID_MARKUP_PERCENTAGE',
  INVALID_MERCHANT_FEE_PERCENTAGE = 'INVALID_MERCHANT_FEE_PERCENTAGE',
  INVALID_MARKUP_COST = 'INVALID_MARKUP_COST',
  INVALID_MARKUP_PUBLISHED_FARE = 'INVALID_MARKUP_PUBLISHED_FARE',
}

enum TravelFusionErrors {
  UNKNOWN_TRAVEL_FUSION_ERROR = 'UNKNOWN_TRAVEL_FUSION_ERROR',
  TF_REST_CALL_FAILURE = 'TF_REST_CALL_FAILURE',
}

enum TripErrors {
  UNKNOWN_TRIP_ERROR = 'UNKNOWN_TRIP_ERROR',
  SABRE_BAD_PNR_RESPONSE = 'SABRE_BAD_PNR_RESPONSE',
  TF_RESPONSE_TRIP_INVALID_PNR = 'TF_RESPONSE_TRIP_INVALID_PNR',
  TF_BOOKING_UNCONFIRMED_CONTACT_SUPPORT = 'TF_BOOKING_UNCONFIRMED_CONTACT_SUPPORT',
  PNR_ID_ALREADY_EXISTS = 'PNR_ID_ALREADY_EXISTS',
  PNR_ID_NOT_FOUND = 'PNR_ID_NOT_FOUND',
  TRIP_ID_ALREADY_EXISTS = 'TRIP_ID_ALREADY_EXISTS',
  UNABLE_TO_MAP_CONTEXT_FOR_EMAIL_UPDATE_TEMPLATE = 'UNABLE_TO_MAP_CONTEXT_FOR_EMAIL_UPDATE_TEMPLATE',
}

enum RailErrors {
  TRAINLINE_REST_CALL_FAILURE = 'TRAINLINE_REST_CALL_FAILURE',
  TRAINLINE_AUTH_FAILURE = 'TRAINLINE_AUTH_FAILURE',
  TRAINLINE_INVALID_SEARCH_TYPE = 'TRAINLINE_INVALID_SEARCH_TYPE',
  TRAINLINE_EMPTY_PASSENGER_LIST = 'TRAINLINE_EMPTY_PASSENGER_LIST',
  TRAINLINE_MULTIPLE_PASSENGERS = 'TRAINLINE_MULTIPLE_PASSENGERS',
  TRAINLINE_NO_OUTWARD_JOURNEY = 'TRAINLINE_NO_OUTWARD_JOURNEY',
  TRAINLINE_NO_INWARD_JOURNEY = 'TRAINLINE_NO_INWARD_JOURNEY',
  TRAINLINE_NO_NAME_IN_TRAVELLER = 'TRAINLINE_NO_NAME_IN_TRAVELLER',
  TRAINLINE_NO_FIRST_NAME_IN_TRAVELLER = 'TRAINLINE_NO_FIRST_NAME_IN_TRAVELLER',
  TRAINLINE_INVALID_NAME = 'TRAINLINE_INVALID_NAME',
  TRAINLINE_INVALID_TRAVELLER = 'TRAINLINE_INVALID_TRAVELLER',
  TRAINLINE_INVALID_STATION = 'TRAINLINE_INVALID_STATION',
  TRAINLINE_INVALID_DATE_TIME = 'TRAINLINE_INVALID_DATE_TIME',
  TRAINLINE_MISSING_TRAVEL_CARD_DETAILS = 'TRAINLINE_MISSING_TRAVEL_CARD_DETAILS',
  UNKNOWN_RAIL_ERROR = 'UNKNOWN_RAIL_ERROR',
  INVALID_RAIL_SEARCH_KEY = 'INVALID_RAIL_SEARCH_KEY',
  INVALID_RAIL_RATE_KEY = 'INVALID_RAIL_RATE_KEY',
  INVALID_RAIL_JOURNEY_ID = 'INVALID_RAIL_JOURNEY_ID',
  EMPTY_RAIL_RATE_KEYS = 'EMPTY_RAIL_RATE_KEYS',
  INVALID_JOURNEY_DIRECTION = 'INVALID_JOURNEY_DIRECTION',
  EMPTY_JOURNEYS = 'EMPTY_JOURNEYS',
  EMPTY_RECIPIENT_ID = 'EMPTY_RECIPIENT_ID',
  EMPTY_DELIVERY_SET_URL = 'EMPTY_DELIVERY_SET_URL',
  INCORRECT_DELIVERY_OPTION = 'INCORRECT_DELIVERY_OPTION',
  EMPTY_ITINERARY_REFERENCE = 'EMPTY_ITINERARY_REFERENCE',
  TRAINLINE_MISSING_DELIVERY_LEGS = 'TRAINLINE_MISSING_DELIVERY_LEGS',
  INVALID_RAIL_DELIVERY_OPTION_KEY = 'INVALID_RAIL_DELIVERY_OPTION_KEY',
  EMPTY_DELIVERY_OPTION_DETAILS = 'EMPTY_DELIVERY_OPTION_DETAILS',
  SET_DELIVERY_OPTION_ERROR = 'SET_DELIVERY_OPTION_ERROR',
  SET_SEAT_PREFERENCE_ERROR = 'SET_SEAT_PREFERENCE_ERROR',
  INVALID_RAIL_CARD = 'INVALID_RAIL_CARD',
  TRAINLINE_MISSING_SEAT_LEGS = 'TRAINLINE_MISSING_SEAT_LEGS',
  TRAINLINE_MISSING_FARE_LEG = 'TRAINLINE_MISSING_FARE_LEG',
  INVALID_VEHICLE = 'INVALID_VEHICLE',
  INVALID_RAIL_TICKET = 'INVALID_RAIL_TICKET',
  UNKOWN_PNR_UPDATE_STATUS_FOR_EMAIL = 'UNKOWN_PNR_UPDATE_STATUS_FOR_EMAIL',
  ATTACHMENT_PARSE_FAILURE = 'ATTACHMENT_PARSE_FAILURE',
  INVALID_SEAT_RESERVATION_KEY_PROTO = 'INVALID_SEAT_RESERVATION_KEY_PROTO',
}

enum CustomFieldErrors {
  UDE_INVALID_QUESTION_OPTION = 'UDE_INVALID_QUESTION_OPTION',
  UDE_INVALID_ANSWER_RESPONSE = 'UDE_INVALID_ANSWER_RESPONSE',
  UDE_DUPLICATE_OPTIONS_FOR_QUESTION = 'UDE_DUPLICATE_OPTIONS_FOR_QUESTION',
  UDE_NO_OPTIONS_PROVIDED_FOR_QUESTION = 'UDE_NO_OPTIONS_PROVIDED_FOR_QUESTION',
  UDE_DUPLICATE_QUESTION_DISPLAY_INFO = 'UDE_DUPLICATE_QUESTION_DISPLAY_INFO',
}

export {
  AirErrors,
  AuthorizationErrors,
  AutocompleteErrors,
  AviaErrors,
  CarErrors,
  CommonErrors,
  GenericErrors,
  HotelErrors,
  NDCErrors,
  PaymentErrors,
  PCCErrors,
  PolicyErrors,
  ProfileErrors,
  RouteHappyErrors,
  SabreErrors,
  ShellPNRErrors,
  TravelFusionErrors,
  TripErrors,
  RailErrors,
  CustomFieldErrors,
};
