/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *  - DEFAULT: By default AT_LEAST_ONE cabin type is used.  - ALL: Ensures that all the segments in the itinerary has the specified cabin.  - AT_LEAST_ONE: Ensures that at least one segment in the itinerary has the specified cabin.
 * @export
 * @enum {string}
 */
export enum CabinFilterType {
    Default = 'DEFAULT',
    All = 'ALL',
    AtLeastOne = 'AT_LEAST_ONE'
}



