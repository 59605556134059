import { css } from '@emotion/react';
import { flex, paddings, themed, unit } from '../../utils';
import { paddings as newPaddings } from '../../utils/styling/utilityTokens';

export const sticky_footer = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  ${paddings.x.p1point5}
  ${paddings.y.p1}
`;

export const drawer_footer = themed(
  ({ palette }) => css`
    ${newPaddings.x.cozy}
    ${newPaddings.y.cozy}
    background-color: ${palette.surface.base};
  `,
);

export const footer_container = css`
  ${flex.init}
  ${flex.align.center}
  ${flex.justify.between}
  gap: ${unit(0.5)};
`;
