import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FourSquaresWithActiveSquare = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.29212 0H0.807705C0.370002 0 0 0.353581 0 0.807705V6.30911C0 6.74681 0.353581 7.11681 0.807705 7.11681H6.30911C6.74681 7.11681 7.11682 6.76323 7.11682 6.30911V0.807705C7.09973 0.353581 6.72972 0 6.29203 0H6.29212ZM5.50151 5.50141H1.59842V1.59832H5.50151V5.50141Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M15.1749 0H9.69052C9.25281 0 8.88281 0.353581 8.88281 0.807705V6.30911C8.88281 6.74681 9.23639 7.11681 9.69052 7.11681H15.1919C15.6296 7.11681 15.9996 6.76323 15.9996 6.30911V0.807705C15.9825 0.353581 15.629 0 15.1748 0H15.1749Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M6.29212 8.8833H0.807705C0.370002 8.8833 0 9.23688 0 9.69101V15.1924C0 15.6301 0.353581 16.0001 0.807705 16.0001H6.30911C6.74681 16.0001 7.11682 15.6465 7.11682 15.1924V9.69101C7.09973 9.2533 6.72972 8.8833 6.29203 8.8833H6.29212ZM5.50151 14.3847H1.59842V10.4816H5.50151V14.3847Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M15.1749 8.8833H9.69052C9.25281 8.8833 8.88281 9.23688 8.88281 9.69101V15.1924C8.88281 15.6301 9.23639 16.0001 9.69052 16.0001H15.1919C15.6296 16.0001 15.9996 15.6465 15.9996 15.1924V9.69101C15.9825 9.2533 15.629 8.8833 15.1748 8.8833H15.1749ZM14.3843 14.3847H10.4812V10.4816H14.3843V14.3847Z", fill: themeEnrichedPrimaryColor })));
};
FourSquaresWithActiveSquare.defaultProps = defaultIconProps;
export default FourSquaresWithActiveSquare;
