/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Availability status of agent.
 * @export
 * @enum {string}
 */

export const AgentAvailabilityStatus = {
    UnknownAvailabilityStatus: 'UNKNOWN_AVAILABILITY_STATUS',
    Available: 'AVAILABLE',
    Offline: 'OFFLINE',
    OnBreak: 'ON_BREAK'
} as const;

export type AgentAvailabilityStatus = typeof AgentAvailabilityStatus[keyof typeof AgentAvailabilityStatus];



