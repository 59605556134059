import memoize from 'lodash/memoize';

/**
 * @todo @smishra-spotnana - Update types upstream to include currencyDisplay
 * @param currencyDisplay
 * @returns
 */

function isCurrencyDiplaySupported(currencyDisplay: string | undefined): boolean {
  if (!currencyDisplay) {
    return true;
  }

  try {
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: currencyDisplay as Intl.NumberFormatOptions['currencyDisplay'],
    }).format(1000);
  } catch (e) {
    return false;
  }
  return true;
}

export default memoize(isCurrencyDiplaySupported);
