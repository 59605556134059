/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Title of the color.
 * @export
 * @enum {string}
 */

export const ColorTitle = {
    MemberColor: 'PROFILE_TIER_MEMBER_COLOR',
    SilverColor: 'PROFILE_TIER_SILVER_COLOR',
    GoldColor: 'PROFILE_TIER_GOLD_COLOR',
    PlatinumColor: 'PROFILE_TIER_PLATINUM_COLOR',
    TitaniumColor: 'PROFILE_TIER_TITANIUM_COLOR',
    AmbassadorColor: 'PROFILE_TIER_AMBASSADOR_COLOR'
} as const;

export type ColorTitle = typeof ColorTitle[keyof typeof ColorTitle];



