import { FC } from 'react';
import { Typography } from '@spotnana/blocks/src/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotnana/blocks/src/Button';
import { Icon } from '@spotnana/blocks/src/Icon';
import { IconButton } from '@spotnana/blocks/src/IconButton';
import {
  disabled_fare_text,
  enabled_fare_text,
  fare_header_container,
  fare_header_expired,
  option_underline,
} from './styles';

interface FareHeaderProps {
  fareIndex: number;
  onRemoveFare: VoidFunction;
  isFareExpired: boolean;
  onNavigateToFareCheckout: (fareIndex: number) => void;
}

export const FareHeader: FC<FareHeaderProps> = ({
  fareIndex,
  onRemoveFare,
  isFareExpired,
  onNavigateToFareCheckout,
}) => {
  const { t: tt } = useTranslation('WEB');
  return (
    <div css={isFareExpired ? [fare_header_container, fare_header_expired] : fare_header_container}>
      {isFareExpired ? (
        <div css={disabled_fare_text}>
          <Icon name="CloseCrossCircleFill" />
          <Typography variant="body2" color="error">
            {tt('This option has expired')}
          </Typography>
        </div>
      ) : (
        <div css={enabled_fare_text}>
          <Typography variant="body1" kind="semibold" css={option_underline}>
            {tt('Option {{optionNumber}}', { optionNumber: fareIndex + 1 })}
          </Typography>
          <IconButton
            icon="NewTab"
            onClick={() => onNavigateToFareCheckout(fareIndex)}
            data-testid="navigate_to_checkout_icon"
          />
        </div>
      )}
      <Button startIcon="Delete" variant="ghost" color="blue" size="small" onClick={onRemoveFare}>
        {tt('Remove')}
      </Button>
    </div>
  );
};
