import { createGlobalStyle } from 'styled-components';

const rootcss = '.cx-widget.cx-webchat.cx-theme-light';

export const StyleOverrides = createGlobalStyle`
  ${rootcss} {
    background-color: ${({ theme }) => theme.colors.bg.regular};
  };

  ${rootcss} .cx-svg-icon-tone1 {
    fill: ${({ theme }) => theme.colors.primary};
  };

  ${rootcss} .cx-btn {
    border-radius: 8px;
  };

  ${rootcss} .cx-btn-default {
    color: ${({ theme }) => theme.colors.primary};
    background: transparent;
    border: none;
  };

  ${rootcss} .cx-btn-default:hover,
  ${rootcss} .cx-btn-default:focus {
    color: ${({ theme }) => theme.colors.primaryHover};
  };

  ${rootcss} .cx-btn-primary {
    background: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary}!important;
  };

  ${rootcss} .cx-btn-primary:hover,
  ${rootcss} .cx-btn-primary:focus {
    background: ${({ theme }) => theme.colors.primaryHover};
    border-color: ${({ theme }) => theme.colors.primary}!important;
  };

  ${rootcss} .cx-input {
    padding: 8px;
    height: auto;
    border-radius: 8px;
  };

  ${rootcss} .cx-input:hover,
  ${rootcss} .cx-input:focus {
    border-color: ${({ theme }) => theme.colors.border.regularHover}!important;
  };

  ${rootcss} .cx-chat-end .cx-wrapper {
    padding: 24px;
  };
`;
