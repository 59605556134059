import { css } from '@emotion/react';

import { themed } from '../utils/styling';

export const blocksLabelStyles = themed(
  ({ palette, typography }) => css`
    color: ${palette.inputs.textLabel};
    transform: none !important;

    &.Mui-focused {
      color: ${palette.inputs.borderFocus};
    }

    .MuiInputLabel-asterisk {
      color: ${palette.text.error};
    }

    ${Object.assign(typography.recipes.label)}

    &.BlocksLabel-hidden {
      opacity: 0;
      position: absolute;
    }
  `,
);

export const input_label = blocksLabelStyles;
