var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
const MultiTraveler = (_a) => {
    var { width, height, primaryColor, secondaryColor } = _a, restProps = __rest(_a, ["width", "height", "primaryColor", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 20 22", width: width, height: height, xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { d: "m7.785 19.104-.095-.095-.13-.034C3.493 17.91.5 14.25.5 9.9.5 4.714 4.75.5 10 .5s9.5 4.214 9.5 9.4c0 4.35-2.993 8.009-7.06 9.075l-.13.034-.095.095L10 21.296l-2.215-2.192Z", fill: "#fff", stroke: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.17 4.063a2.373 2.373 0 0 0-1.878 3.824 3.289 3.289 0 0 0-1.855 2.96.625.625 0 1 0 1.25 0c0-1.126.913-2.038 2.039-2.038h.54l.09.002a2.033 2.033 0 0 1 1.459.71c.077.09.173.153.277.187.07.625.339 1.19.742 1.63a4.087 4.087 0 0 0-2.526 3.777.625.625 0 1 0 1.25 0 2.836 2.836 0 0 1 2.836-2.836h1.082l.084.001a2.836 2.836 0 0 1 2.752 2.835.625.625 0 1 0 1.25 0c0-1.684-1.019-3.13-2.474-3.756a2.897 2.897 0 1 0-4.79-3.097 3.286 3.286 0 0 0-.358-.246 2.373 2.373 0 0 0-1.77-3.954Zm.011 3.496a1.123 1.123 0 1 0-.023 0h.023Zm6.436 1.824c0 .903-.727 1.637-1.629 1.646h-.035a1.646 1.646 0 1 1 1.664-1.646Z", fill: themeEnrichedPrimaryColor })));
};
export default MultiTraveler;
