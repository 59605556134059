/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
import { CarExta } from './car-exta';
// May contain unused imports in some cases
import { CarRateComponent } from './car-rate-component';
// May contain unused imports in some cases
import { CarRateType } from './car-rate-type';
// May contain unused imports in some cases
import { CarRefundableType } from './car-refundable-type';
// May contain unused imports in some cases
import { PaymentMethod } from './payment-method';
// May contain unused imports in some cases
import { PaymentSourceType } from './payment-source-type';

/**
 * Car Rate information and breakdown
 * @export
 * @interface CarRateDetails
 */
export interface CarRateDetails {
  /**
   *
   * @type {CarRateType}
   * @memberof CarRateDetails
   */
  rateType?: CarRateType;
  /**
   *
   * @type {Array<CarRateComponent>}
   * @memberof CarRateDetails
   */
  carRateComponents?: Array<CarRateComponent>;
  /**
   *
   * @type {Array<CarExta>}
   * @memberof CarRateDetails
   */
  carExtras?: Array<CarExta>;
  /**
   *
   * @type {CarRefundableType}
   * @memberof CarRateDetails
   */
  refundable?: CarRefundableType;
  /**
   *
   * @type {string}
   * @memberof CarRateDetails
   */
  fareType?: CarRateDetailsFareTypeEnum;
  /**
   *
   * @type {PaymentMethod}
   * @memberof CarRateDetails
   */
  allowedFop?: PaymentMethod;
  /**
   * Indicates the restriction on which payment method types can be used to make the booking.
   * @type {Array<PaymentSourceType>}
   * @memberof CarRateDetails
   */
  allowedPaymentTypes?: Array<PaymentSourceType>;
}

export const CarRateDetailsFareTypeEnum = {
  Published: 'PUBLISHED',
  Corporate: 'CORPORATE',
  Spotnana: 'SPOTNANA',
  Unknown: 'UNKNOWN',
} as const;

export type CarRateDetailsFareTypeEnum = (typeof CarRateDetailsFareTypeEnum)[keyof typeof CarRateDetailsFareTypeEnum];
