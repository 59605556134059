import { FC } from 'react';
import { FareList } from 'obt-common';
import { FormProvider } from './FormProvider';
import { ShareItineraryModal } from '../ShareItineraryModal';

interface ShareItineraryModalContainerProps {
  fareList: FareList;
  open: boolean;
  onClose: VoidFunction;
}

export const ShareItineraryModalContainer: FC<ShareItineraryModalContainerProps> = ({ fareList, open, onClose }) => {
  return (
    <FormProvider fareList={fareList}>
      <ShareItineraryModal open={open} onClose={onClose} fareList={fareList} />
    </FormProvider>
  );
};
