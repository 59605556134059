import { safeJsonStringify } from 'obt-common';
import { TripIdError, BrexTokenError, AnyEmbedError, IReportError } from '@spotnana-tech/embed-helpers/src/errors';
import { TripErrors } from '@spotnana-tech/embed-helpers/errors/trip-errors';
import { EmbedEventTypes } from '@spotnana-tech/embed-helpers';
import { BrexTokenErrors } from '@spotnana-tech/embed-helpers/errors/brex-token-errors';
import useEmbedStore from './useEmbedStore';

export const getCodeFromError = (errorWithContext: AnyEmbedError) =>
  errorWithContext.error.response?.data?.errorCode || '';

export const isTripIdError = (errorWithContext: AnyEmbedError): errorWithContext is TripIdError => {
  const errorCode = getCodeFromError(errorWithContext);

  return errorCode === TripErrors.TRIP_ID_NOT_FOUND;
};

export const isBrexTokenError = (errorWithContext: AnyEmbedError): errorWithContext is BrexTokenError => {
  const errorCode = getCodeFromError(errorWithContext);

  return errorCode in BrexTokenErrors;
};

interface IEmitEmbedErrorsParams {
  source: string;
}

const useEmitEmbedErrors = (options: IEmitEmbedErrorsParams): IReportError => {
  const { reportEvent } = useEmbedStore();

  return (errorWithContext) => {
    if (isTripIdError(errorWithContext)) {
      const { context } = errorWithContext;

      reportEvent({
        type: EmbedEventTypes.INVALID_TRIP_ID,
        payload: {
          tripId: context.tripId,
        },
      });

      return;
    }

    if (isBrexTokenError(errorWithContext)) {
      const { error, context } = errorWithContext;

      reportEvent({
        type: EmbedEventTypes.INVALID_TOKEN,
        payload: {
          errorCode: error.response.data.errorCode,
          token: context.token,
          errorMessage: error.message,
          errorStack: error.stack,
          data: safeJsonStringify(error?.response?.data, ''),
          source: options.source,
        },
      });
    }

    // do nothing
  };
};

export default useEmitEmbedErrors;
