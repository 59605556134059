import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import isInt from 'lodash/isInteger';
import isString from 'lodash/isString';
import cloneDeep from 'lodash/cloneDeep';

import type {
  Money as IMoney,
  MoneyOtherCoinage,
  PaymentMethod as PaymentMethodV1,
  Money,
} from '../../types/api/v1/common/money';
import { PaymentMethodEnum } from '../../types/api/v1/common/money';
import type { Money as MoneyV2 } from '../../types/api/v2/obt/model/money';
import { PaymentV1ToV2Mapper, PaymentV2ToV1Mapper } from '../../types/api/v2/obt/model/payment-method';
import type { PaymentMethod as PaymentMethodV2 } from '../../types/api/v2/obt/model/payment-method';

import type { Currency } from './types';
import {
  Currencies,
  ConversionSupportedCurrencyCodesWithCurrencyNameAndSymbol,
  CurrencyCodesWithCurrencyNames,
} from './currencies';
import { DEFAULT_PREFERRED_CURRENCY } from './constants';

type MathRoundingFunctionNameType = 'round' | 'floor' | 'ceil';

const ALLOWED_ROUNDING_FUNCTION_TYPES: MathRoundingFunctionNameType[] = ['round', 'floor', 'ceil'];

type RoundingFunctionType = (value: number) => number;

const assertOperand = (operand: unknown | number | string): void => {
  if (isNaN(parseFloat(operand as string)) && !Number.isFinite(operand as number)) {
    throw new TypeError('Operand must be a number');
  }
};

const getCurrencyObject = (currency: string): Currency => {
  const currencyObject = Currencies[currency.toUpperCase()];
  if (!currencyObject) {
    throw new Error(`Encountered unsupported currency: ${currency}`);
  }
  return currencyObject;
};

class MoneyUtil {
  private static PREFERRED_CURRENCY = DEFAULT_PREFERRED_CURRENCY;

  /**
   * This is a private constructor and should only be called from within this class.
   * To create a new Money instance, use create().
   *
   * The created Money instances is a value object thus it is immutable.
   *
   * @param {Number} amount in the lowest denomination. For eg, for USD, it's in cents.
   * @param {String} currency
   * @returns {MoneyUtil}
   * @constructor
   */

  /**
   * currency => preferred currency (convertedCurrency from Money proto)
   *
   * original currency => local currency (currency from Money proto)
   * */

  private constructor(
    private readonly amount: number,
    private readonly currency: string,
    private readonly originalAmount = amount,
    private readonly originalCurrency = currency,
    readonly otherCoinage: MoneyOtherCoinage[] = [],
  ) {
    if (!isInt(amount)) {
      throw new TypeError('Amount must be an integer in lowest denomination.');
    }
    this.amount = amount;
    this.currency = currency;
    this.originalAmount = originalAmount;
    this.originalCurrency = originalCurrency;
    this.otherCoinage = otherCoinage;
    Object.freeze(this);
  }

  /**
   * Static public method to construct a new Money instance.
   *
   * @param amount - the actual amount. For eg 5 USD, 10.24 INR.
   * @param currency
   * @param rounder
   */
  public static create(
    amount: number,
    currency: string | Currency,
    rounder?: MathRoundingFunctionNameType | RoundingFunctionType,
    otherCoinage?: MoneyOtherCoinage[],
  ): MoneyUtil {
    const currencyObject: Currency = this.getCurrencyObj(currency);
    return new MoneyUtil(
      this.getRoundedAmount(amount, currencyObject, rounder),
      currencyObject.code,
      this.getRoundedAmount(amount, currencyObject, rounder),
      currencyObject.code,
      otherCoinage,
    );
  }

  /**
   * Static public method to construct a new Money instance given  a 'Money' type usually
   * returned from backend.
   *
   * @param money - the 'Money' type json object.
   * @param rounder
   */
  public static parse(
    money: IMoney | undefined,
    rounder?: MathRoundingFunctionNameType | RoundingFunctionType,
  ): MoneyUtil {
    // If the money type is undefined or empty, return zero money object.
    if (!money || !this.isIMoneyValid(money)) {
      return MoneyUtil.zeroMoney();
    }

    // If present, use converted amount as the main value. The 'convertedAmount' could have more
    // decimals than allowed in the 'convertedCurrency' due to the exchange rate ratio multiplication.
    // Hence, we pass a default 'rounder' for this case to convert these to appropriate decimal places.
    const currency: Currency = this.getCurrencyObj(money.currencyCode);
    const hasValidConvertedMoney = money.convertedAmount !== undefined && money.convertedCurrency;
    const convertedAmount = hasValidConvertedMoney ? money.convertedAmount! : money.amount;
    const convertedCurrency = hasValidConvertedMoney ? this.getCurrencyObj(money.convertedCurrency!) : currency;
    return new MoneyUtil(
      this.getRoundedAmount(convertedAmount, convertedCurrency, rounder ?? 'round'),
      convertedCurrency.code,
      this.getRoundedAmount(money!.amount, currency, rounder),
      currency.code,
      money.otherCoinage ?? [],
    );
  }

  public static setPreferredCurrency(currency?: string): void {
    if (currency) {
      MoneyUtil.PREFERRED_CURRENCY = currency;
    }
  }

  public static getPreferredCurrrency(): string {
    return MoneyUtil.PREFERRED_CURRENCY;
  }

  static assertSameCurrency = (left: MoneyUtil, right: MoneyUtil): void => {
    if (left.currency !== right.currency) throw new Error(`Different currencies: ${left.currency}, ${right.currency}`);
  };

  static assertSameOriginalCurrency = (left: MoneyUtil, right: MoneyUtil): void => {
    if (left.originalCurrency !== right.originalCurrency) {
      throw new Error(`Different original currencies: ${left.originalCurrency}, ${right.originalCurrency}`);
    }
  };

  static assertType = (other: MoneyUtil): void => {
    if (!(other instanceof MoneyUtil)) throw new TypeError('Instance of MoneyUtil required');
  };

  /**
   * Returns true if the two instances of Money are equal, false otherwise.
   *
   * @param {MoneyUtil} other
   * @returns {Boolean}
   */
  public equals(other: MoneyUtil): boolean {
    MoneyUtil.assertType(other);

    return this.amount === other.amount && this.currency === other.currency;
  }

  /**
   * Adds all the otherCoinage
   *@param {MoneyUtil} other
   *@returns {MoneyUtil}
   *
   */

  private addOtherCoinage(other: MoneyUtil): MoneyOtherCoinage[] {
    const currentOtherCoinage = this.getOtherCoinage();
    const otherCoinage = other.getOtherCoinage();

    const allCoinages = [...currentOtherCoinage, ...otherCoinage];

    const updatedCoinages = allCoinages
      .reduce((map, coinage) => {
        const key = coinage.coinageCode;
        const existing: MoneyOtherCoinage = map.get(key) || {
          ...coinage,
          coinageCode: key,
          amount: 0,
        };

        existing.amount += coinage.amount;
        map.set(key, existing);

        return map;
      }, new Map())
      .values();
    return [...updatedCoinages];
  }

  /**
   * subtract all the otherCoinage
   *@param {MoneyUtil} other
   *@returns {MoneyUtil}
   *
   */

  private subtractOtherCoinage(other: MoneyUtil): MoneyOtherCoinage[] {
    const currentOtherCoinage = this.getOtherCoinage();
    const otherCoinage = other.getOtherCoinage();

    const updatedCoinage = currentOtherCoinage.map((current) => {
      const otherCoinageWithSameFop = otherCoinage.find((otherCoin) => otherCoin.coinageCode === current.coinageCode);
      if (otherCoinageWithSameFop) {
        return { ...current, amount: current.amount - otherCoinageWithSameFop.amount };
      }
      return current;
    });

    return updatedCoinage;
  }

  /**
   * Adds the two objects together creating a new Money instance that holds the result of the operation.
   * This is a special 'add' function which retains and adds the corresponding 'preferredCurrency' amounts.
   * Also if the originalCurrency is not same , we do not add the other original amount
   * Use this while calculating fare breakup on checkout and trips page
   * @param {MoneyUtil} other
   * @returns {MoneyUtil}
   */
  public add(other: MoneyUtil): MoneyUtil {
    MoneyUtil.assertType(other);
    if (other.isZero()) {
      return this;
    }
    MoneyUtil.assertSameCurrency(this, other); // check if preferred currencies are same
    const updatedCoinage = this.addOtherCoinage(other);

    return new MoneyUtil(
      this.amount + other.amount,
      this.currency,
      this.originalCurrency !== other.originalCurrency
        ? this.originalAmount
        : this.originalAmount + other.originalAmount,
      this.originalCurrency,
      updatedCoinage,
    );
  }

  /**
   * Subtracts the two objects together creating a new Money instance that holds the result of the operation.
   * This is a special 'subtract' function which retains and adds the corresponding 'preferredCurrency' amounts.
   * Also if the originalCurrency is not same , we do not subtract the other original amount
   * Use this while calculating fare breakup on checkout and trips page
   * @param {MoneyUtil} other
   * @returns {MoneyUtil}
   */
  public subtract(other: MoneyUtil): MoneyUtil {
    MoneyUtil.assertType(other);
    if (other.isZero()) {
      return this;
    }
    MoneyUtil.assertSameCurrency(this, other); // check if preferred currencies are same
    const updatedCoinage = this.subtractOtherCoinage(other);

    return new MoneyUtil(
      this.amount - other.amount,
      this.currency,
      this.originalCurrency !== other.originalCurrency
        ? this.originalAmount
        : this.originalAmount - other.originalAmount,
      this.originalCurrency,
      updatedCoinage,
    );
  }

  /**
   * Multiplies the object by the multiplier returning a new Money instance that holds the result of the operation.
   *
   * @param {Number} multiplier
   * @param {Function} [fn=Math.round]
   * @returns {MoneyUtil}
   */
  public multiply(multiplier: number, fn?: Function): MoneyUtil {
    const apply = !isFunction(fn) ? Math.round : fn;
    try {
      assertOperand(multiplier);
      const amount = apply(this.amount * multiplier);
      const originalAmount = apply(this.originalAmount * multiplier);

      return new MoneyUtil(amount, this.currency, originalAmount, this.originalCurrency);
    } catch (error) {
      // TODO: resolve cycle dependency src/api/index.ts -> ... -> src/utils/Money/index.ts -> src/utils/reportEvent.ts
      // reportEvent('OPERAND_NOT_NUMBER_ERROR', {
      //   action: 'moneyMultiply',
      //   message: JSON.stringify(error.stack),
      // });
    }
    return new MoneyUtil(0, 'USD');
  }

  /**
   * Divides the object by the multiplier returning a new Money instance that holds the result of the operation.
   *
   * @param {Number} divisor
   * @param {Function} [fn=Math.round]
   * @returns {MoneyUtil}
   */
  public divide(divisor: number, fn?: Function): MoneyUtil {
    const apply = !isFunction(fn) ? Math.round : fn;
    try {
      assertOperand(divisor);
      const amount = apply(this.amount / divisor);
      const originalAmount = apply(this.originalAmount / divisor);
      return new MoneyUtil(amount, this.currency, originalAmount, this.originalCurrency);
    } catch (error) {
      // TODO: resolve cycle dependency src/api/index.ts -> ... -> src/utils/Money/index.ts -> src/utils/reportEvent.ts
      // reportEvent('OPERAND_NOT_NUMBER_ERROR', {
      //   action: 'moneyDivide',
      //   message: JSON.stringify(error.stack),
      // });
    }
    return new MoneyUtil(0, 'USD');
  }

  /**
   * Allocates fund bases on the ratios provided returing an array of objects as a product of the allocation.
   *
   * @param {Array} other
   * @returns {Array.Money}
   */
  public allocate(ratios: number[]): MoneyUtil[] {
    let remainder = this.amount;
    const results: MoneyUtil[] = [];
    let total = 0;

    ratios.forEach((ratio) => {
      total += ratio;
    });

    ratios.forEach((ratio) => {
      const share = Math.floor((this.amount * ratio) / total);
      results.push(new MoneyUtil(share, this.currency));
      remainder -= share;
    });

    for (let i = 0; remainder > 0; i += 1) {
      results[i] = new MoneyUtil(results[i].amount + 1, results[i].currency);
      remainder -= 1;
    }

    return results;
  }

  /**
   * Compares two instances of Money.
   *
   * @param {MoneyUtil} other
   * @returns {Number}
   */
  public compare(other: MoneyUtil): number {
    MoneyUtil.assertType(other);
    MoneyUtil.assertSameCurrency(this, other);

    if (this.amount === other.amount) return 0;

    return this.amount > other.amount ? 1 : -1;
  }

  /**
   * Returns min of all instances of Money.
   *
   * @param {MoneyUtil[]} allMoneys
   * @returns {Number}
   */
  public static min(firstMoney: MoneyUtil, secondMoney: MoneyUtil, ...otherMoneys: MoneyUtil[]): MoneyUtil {
    const minMoney = firstMoney.compare(secondMoney) > 0 ? secondMoney : firstMoney;

    if (otherMoneys.length === 0) {
      return minMoney;
    }

    return MoneyUtil.min(minMoney, otherMoneys[0], ...otherMoneys.slice(1));
  }

  /**
   * Checks whether the value represented by this object is greater than the other.
   *
   * @param {MoneyUtil} other
   * @returns {boolean}
   */
  public greaterThan(other: MoneyUtil): boolean {
    return this.compare(other) === 1;
  }

  /**
   * Checks whether the value represented by this object is greater or equal to the other.
   *
   * @param {MoneyUtil} other
   * @returns {boolean}
   */
  public greaterThanOrEqual(other: MoneyUtil): boolean {
    return this.compare(other) >= 0;
  }

  /**
   * Checks whether the value represented by this object is less than the other.
   *
   * @param {MoneyUtil} other
   * @returns {boolean}
   */
  public lessThan(other: MoneyUtil): boolean {
    return this.compare(other) === -1;
  }

  /**
   * Checks whether the value represented by this object is less than or equal to the other.
   *
   * @param {MoneyUtil} other
   * @returns {boolean}
   */
  public lessThanOrEqual(other: MoneyUtil): boolean {
    return this.compare(other) <= 0;
  }

  /**
   * Returns true if the money (amount and points) is zero.
   *
   * @returns {boolean}
   */
  public isZero(): boolean {
    return this.amount === 0 && this.otherCoinage.every((otherCoinage) => otherCoinage.amount === 0);
  }

  /**
   * Returns true if the amount is zero.
   *
   * @returns {boolean}
   */
  public isAmountZero(): boolean {
    return this.amount === 0;
  }

  /**
   * Returns true if the amount is positive.
   *
   * @returns {boolean}
   */
  public isPositive(): boolean {
    return this.amount > 0;
  }

  /**
   * Returns true if the amount is negative.
   *
   * @returns {boolean}
   */
  public isNegative(): boolean {
    return this.amount < 0;
  }

  /**
   * Method to construct new `MoneyUtil` object with amount/currency set to original ones,
   * since `MoneyUtil.parse(...)` method puts `moneyObj.convertedAmount` -> `amount`
   * and `moneyObj.convertedCurrency` -> `currency`.
   *
   * It's still possible to access "converted" amount/currency by using
   * `moneyUtilInstance.getOriginalAmount()` and `moneyUtilInstance.getOriginalCurrency()`
   *
   * @returns {MoneyUtil} new `MoneyUtil` instance
   *
   * @example
   * // User has "EUR" set as preferred currency
   *
   * // Money sent to backend -> { amount: 100, currencyCode: 'INR' }
   * // Money returned from backend -> { amount: 100, currencyCode: 'INR', convertedAmount: 1.13, convertedCurrency: 'EUR' }
   *
   * const parsedMoney = MoneyUtil.parse(moneyFromBackend);
   * parsedMoney.getAmount(); // -> 1.13
   * parsedMoney.getCurrency(); // -> 'EUR'
   *
   * const originalMoney = parsedMoney.getOriginal();
   * originalMoney.getAmount(); // -> 100
   * originalMoney.getCurrency(); // -> 'INR'
   */
  public getOriginal(): MoneyUtil {
    return new MoneyUtil(this.originalAmount, this.originalCurrency, this.amount, this.currency, this.otherCoinage);
  }

  /**
   * Returns the amount.
   *
   * @returns {number}
   */
  public getAmount(): number {
    return +MoneyUtil.getSanitizedAmount(this.amount, this.currency);
  }

  /**
   * Returns the display amount with appropriate decimal places.
   * For example: getAmount : will convert 5.60 --> 5.6,
   * while getDisplayAmount: will return 5.60 as 5.60
   *
   * @returns {string}
   */
  public getDisplayAmount(): string {
    return MoneyUtil.getSanitizedAmount(this.amount, this.currency);
  }

  /**
   * Returns the original display amount with appropriate decimal places.
   * For example: getAmount : will convert 5.60 --> 5.6,
   * while getDisplayAmount: will return 5.60 as 5.60
   *
   * @returns {string}
   */
  public getDisplayOriginalAmount(): string {
    return MoneyUtil.getSanitizedAmount(this.originalAmount, this.originalCurrency);
  }

  /**
   *
   *
   *  @returns {MoneyOtherCoinage[]}
   */
  private getOtherCoinage(): MoneyOtherCoinage[] {
    return this.otherCoinage;
  }

  /**
   *
   *
   *
   *  @returns {MoneyOtherCoinage|undefined}
   */

  public getBrexPoints(): MoneyOtherCoinage | undefined {
    const otherCoinages = this.getOtherCoinage();
    let brexCoinage = otherCoinages.find((otherCoinage) => otherCoinage.coinageCode === PaymentMethodEnum.BREX_POINTS);
    if (brexCoinage) {
      brexCoinage = { ...brexCoinage, amount: Math.round(brexCoinage.amount) };
    }
    return brexCoinage;
  }

  /**
   *
   *
   *
   *  @returns {MoneyOtherCoinage|undefined}
   */

  public getQantasPoints(): MoneyOtherCoinage | undefined {
    const otherCoinages = this.getOtherCoinage();
    const rewardPoints = otherCoinages.find(
      (otherCoinage) => otherCoinage.coinageCode === PaymentMethodEnum.QANTAS_POINTS,
    );
    if (!rewardPoints) {
      return undefined;
    }
    return { ...rewardPoints, amount: Math.round(rewardPoints.amount) };
  }

  /**
   * Returns the currency represented by this object.
   *
   * @returns {string}
   */
  public getCurrency(): string {
    return this.currency;
  }

  /**
   * Returns the original amount.
   *
   * @returns {number}
   */
  public getOriginalAmount(): number {
    return +MoneyUtil.getSanitizedAmount(this.originalAmount, this.originalCurrency);
  }

  /**
   * Returns the original currency represented by this object.
   *
   * @returns {string}
   */
  public getOriginalCurrency(): string {
    return this.originalCurrency;
  }

  /**
   * Returns the original full currency object
   */
  public getOriginalCurrencyInfo(): Currency {
    return getCurrencyObject(this.getOriginalCurrency());
  }

  private static getSanitizedAmount(amount: number, currency: string): string {
    const currencyObj = getCurrencyObject(currency);
    return (amount / 10 ** currencyObj.decimalDigits).toFixed(currencyObj.decimalDigits);
  }

  /**
   * Returns the full currency object
   */
  public getCurrencyInfo(): Currency {
    return getCurrencyObject(this.currency);
  }

  // Should only be used for serializing to redux.
  public toMoneyType(): IMoney {
    return {
      amount: this.getOriginalAmount(),
      currencyCode: this.getOriginalCurrency(),
      convertedAmount: this.getAmount(),
      convertedCurrency: this.getCurrency(),
      otherCoinage: this.getOtherCoinage(),
    };
  }

  public toMoneyV2Type(): MoneyV2 {
    return {
      amount: this.getOriginalAmount(),
      currencyCode: this.getOriginalCurrency(),
      convertedAmount: this.getAmount(),
      convertedCurrency: this.getCurrency(),
      otherCoinage: this.getOtherCoinage().map((coinage) => ({
        ...coinage,
        coinageCode: PaymentV1ToV2Mapper[coinage.coinageCode ?? 0] as PaymentMethodV2,
      })),
    };
  }

  /**
   * Returns the money with '0' amount, in the specified currency. If no currency
   * is provided as input, it uses the 'user's preferred currency'.
   *
   * @param currency The currency in which Money is to be returned.
   */
  public static zeroMoney(currency = MoneyUtil.getPreferredCurrrency()): MoneyUtil {
    return MoneyUtil.create(0, currency);
  }

  public static zeroMoneyWithOriginal(
    currency: string,
    originalCurrency: string,
    otherCoinage = [] as MoneyOtherCoinage[],
  ): MoneyUtil {
    return new MoneyUtil(
      0,
      currency,
      0,
      originalCurrency,
      otherCoinage.map((currentCoinage) => ({ ...currentCoinage, amount: 0 })),
    );
  }

  /* Takes BE v2 BE Money response and returns MoneyUtil
   */
  public static convertV2MoneyToMoneyUtil(moneyV2?: MoneyV2): MoneyUtil {
    if (!moneyV2) {
      return MoneyUtil.zeroMoney();
    }

    const otherCoinage =
      moneyV2.otherCoinage?.map((curr) => ({
        ...curr,
        coinageCode: PaymentV2ToV1Mapper[curr.coinageCode ?? 'PAYMENT_METHOD_UNKNOWN'] as PaymentMethodV1,
      })) ?? [];

    const moneyV1 = {
      ...moneyV2,
      otherCoinage,
    };

    return MoneyUtil.parse(moneyV1);
  }

  /* Takes BE v2 BE Money response and returns MoneyV1
   */
  public static convertV2MoneyToMoneyV1(moneyV2: MoneyV2): Money {
    const otherCoinage =
      moneyV2.otherCoinage?.map((curr) => ({
        ...curr,
        coinageCode: PaymentV2ToV1Mapper[curr.coinageCode ?? 'PAYMENT_METHOD_UNKNOWN'] as PaymentMethodV1,
      })) ?? [];

    const moneyV1 = {
      ...moneyV2,
      otherCoinage,
    };

    return moneyV1;
  }

  private static getCurrencyObj(currency: string | Currency): Currency {
    return isString(currency) ? getCurrencyObject(currency) : cloneDeep(currency);
  }

  // Returns the amount in string format, rounded to certain decimal places based on the 'rounder' provided.
  private static getRoundedAmount(
    amount: number,
    currency: Currency,
    rounder?: MathRoundingFunctionNameType | RoundingFunctionType,
  ): number {
    let rounderFunction: Function;
    if (rounder === undefined) {
      rounderFunction = Math.round;
    } else {
      if (isString(rounder) && !ALLOWED_ROUNDING_FUNCTION_TYPES.includes(rounder)) {
        throw new TypeError('Invalid parameter rounder');
      }

      if (isString(rounder)) {
        rounderFunction = Math[rounder as MathRoundingFunctionNameType];
      } else {
        rounderFunction = cloneDeep(rounder);
      }
    }

    const precisionMultiplier = 10 ** currency.decimalDigits;
    const resultAmount = amount * precisionMultiplier;
    return rounderFunction(resultAmount);
  }

  private static isIMoneyValid(money: IMoney | undefined): boolean {
    return Boolean(money?.currencyCode) || Boolean(money?.otherCoinage?.[0]?.amount);
  }
}

const PreferredCurrencySymbol = Currencies[MoneyUtil.getPreferredCurrrency()]?.symbol || Currencies.USD.symbol;

Object.assign(MoneyUtil, Currencies);

export {
  MoneyUtil,
  Currencies,
  ConversionSupportedCurrencyCodesWithCurrencyNameAndSymbol,
  CurrencyCodesWithCurrencyNames,
  PreferredCurrencySymbol,
};

export type { Currency };
