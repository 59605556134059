import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Grid from '@spotnana/pixel-react/dist/Grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useHasUserAccess, userRolesByFeature } from 'obt-common';
import { useFeatureFlag } from 'obt-common/feature-gating/hooks';

import { Icon } from '@spotnana/blocks/src/Icon';

import { ANALYTICS_ROUTES } from 'src/app/analytics/routes';

import TabSection from './TabSection';
import { header_nav_element } from '../AppHeader/AppHeader.styles';
import { POPOVER_WIDTH, TabPopover } from './TabPopover';

const REPORTS_URLS = [ANALYTICS_ROUTES.TRAVELER_REPORTS, ANALYTICS_ROUTES.REPORTS, ANALYTICS_ROUTES.REPORT_SCHEDULES];
const SAFETY_URLS = [ANALYTICS_ROUTES.DUTY_OF_CARE];

const useAnalyticsOptionsMenuGroups = () => {
  const { t: tt } = useTranslation('WEB');
  const isReportsSchedulesEnabled = useFeatureFlag('FE_REPORTS_SCHEDULES', false);

  return useMemo(
    () => [
      {
        name: 'reports',
        children: [
          {
            title: tt('Reports'),
            urls: REPORTS_URLS,
            options: [
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="ReportsStatistics" color={color} height="24px" width="24px" />
                ),
                content: tt('My reports'),
                path: REPORTS_URLS[0],
                enableFeature: true,
              },
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="DocList" color={color} height="24px" width="24px" />
                ),
                content: tt('Company reports'),
                path: REPORTS_URLS[1],
                enableFeature: true,
              },
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="Calendar" color={color} height="24px" width="24px" />
                ),
                content: tt('Schedules'),
                path: REPORTS_URLS[2],
                enableFeature: isReportsSchedulesEnabled,
              },
            ],
            isInBeta: false,
          },
          {
            title: tt('Safety'),
            urls: SAFETY_URLS,
            options: [
              {
                getIcon: (color: string): React.ReactNode => (
                  <Icon name="LuggageCarryOn" color={color} height="24px" width="24px" />
                ),
                content: tt('Travelers'),
                path: SAFETY_URLS[0],
                enableFeature: true,
              },
            ],
          },
        ],
      },
    ],
    [isReportsSchedulesEnabled, tt],
  );
};

const AnalyticsOptions = ({ onClose }: { onClose?: VoidFunction }): JSX.Element => {
  const { pathname } = useLocation();
  const menuGroups = useAnalyticsOptionsMenuGroups();
  const availableMenuGroups = menuGroups.filter((group) =>
    group.children.some((menu) => menu.options.some((opt) => opt.enableFeature)),
  );

  return (
    <Box mx="-8px" width={POPOVER_WIDTH} data-testid="tooltip-analytics_content">
      <Grid.Row spacing={0}>
        {availableMenuGroups.map((group, groupIndex) => (
          <Grid.Col key={group.name} xs={Math.round(12 / availableMenuGroups.length)}>
            <Box position="relative" pb={24} height="full">
              {group.children.map((menuSection) => (
                <Flex key={menuSection.title}>
                  <TabSection
                    title={menuSection.title}
                    isActive={menuSection.urls.some((url) => pathname.includes(url))}
                    options={menuSection.options.filter((option) => option.enableFeature)}
                    isInBeta={menuSection.isInBeta}
                    onClick={onClose}
                  />
                </Flex>
              ))}
              {groupIndex !== availableMenuGroups.length - 1 && (
                <Box position="absolute" width="1px" bg="bg.lightBlueGrey" top="24px" right="0" bottom="24px" />
              )}
            </Box>
          </Grid.Col>
        ))}
      </Grid.Row>
    </Box>
  );
};

function AnalyticsTab() {
  const hasCompanyReportsAccess = useHasUserAccess(userRolesByFeature.reports);
  const { t: tt } = useTranslation('WEB');
  const { pathname } = useLocation();
  const isAnalyticsTabActive = [...REPORTS_URLS, ...SAFETY_URLS].some((url) => pathname.includes(url));

  if (hasCompanyReportsAccess) {
    return (
      <TabPopover active={isAnalyticsTabActive} label={tt('Analytics')}>
        <AnalyticsOptions />
      </TabPopover>
    );
  }

  return (
    <NavLink css={header_nav_element} to="/traveler-reports">
      {tt('Analytics')}
    </NavLink>
  );
}

export default AnalyticsTab;
