import api from '../api';
import type { AirRetryPaymentRequest } from '../types/api/v2/obt/model/air-retry-payment-request';
import type { AirRetryPaymentResponse } from '../types/api/v2/obt/model/air-retry-payment-response';

/**
 * /v2/air/pnrs/:pnrId/retry-payment
 */
export const retryPayment = (pnrId: string, query: AirRetryPaymentRequest): Promise<AirRetryPaymentResponse> =>
  api('POST', 'airRetryPayment', {
    urlParam: `/${pnrId}/retry-payment`,
    data: query,
  }) as Promise<AirRetryPaymentResponse>;
