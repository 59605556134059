import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
import SpotnanaTheme from '../utils/themes';
const PriorityMedium = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 25 23", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7.41201 9.27197L12.0019 12.936L16.5918 9.27197L18.0018 10.4L12.0019 15.2L6.00204 10.4L7.41201 9.27197Z", fill: themeEnrichedPrimaryColor })));
};
PriorityMedium.defaultProps = Object.assign(Object.assign({}, defaultIconProps), { primaryColor: SpotnanaTheme.colors.text.secondary });
export default PriorityMedium;
