// Generated using yarn generate command
import { css } from '@emotion/react';
import { flex, margins, themed, breakpoints } from '../utils';
import { cornerRadii, paddings, spacings } from '../utils/styling/utilityTokens';

export const select_wrapper = themed(
  ({ palette: { surface, text, bg, border, inputs, icon } }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .BlocksTypography {
      color: inherit;
    }

    .BlocksSelect-Trigger {
      will-change: border;
      transition: border 0.1s ease-in-out;
      .BlocksTypography {
        margin-right: auto;
      }
      &.is-placeholder .BlocksTypography {
        color: ${text.disabled} !important;
      }

      .Select-Actions {
        color: ${icon.primary};
        ${flex.init}
        ${flex.align.center}
      }
      .Select-Right {
        margin-left: auto;
      }

      background-color: ${surface.base};
      box-shadow: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      ${cornerRadii.buttonSmall}
      border: 1px solid ${border.light};
      &:hover {
        border: 1px solid ${border.hover};
      }

      &[data-focus-visible],
      &:focus,
      &.Blocks-focused,
      &.Mui-focused,
      &.Simulate-focused {
        border: 1px solid ${border.active};
      }

      &:focus-visible {
        border: 1px solid ${border.keyboardFocus};
        outline: 1px solid ${border.keyboardFocus};
      }

      &.state-error {
        border: 1px solid ${inputs.borderError} !important;
      }

      &[aria-disabled='true'] {
        background-color: ${bg.disabled};
        .Select-Right {
          color: ${icon.disabled};
        }
      }

      &[aria-expanded='true'] {
        border: 1px solid ${border.active};
      }
    }

    .BlocksSelect-Popover:active,
    .BlocksSelect-Popover:focus {
      border: none !important;
      outline: none !important;
    }

    .BlocksSelect-standard {
      height: 48px;
      ${paddings.x.tight}
      ${paddings.y.tight}
      ${spacings.small}
      .BlocksTypography {
        ${paddings.x.superTight}
        ${paddings.y.superTight}
      }
      .BlocksSelect-Arrow {
        height: 20px;
        width: 20px;
      }
    }

    .BlocksSelect-medium {
      height: 40px;
      ${paddings.xl.tight}
      ${paddings.xr.superTight}
      ${paddings.y.superTight}
      ${spacings.extraSmall}
      .BlocksTypography {
        ${paddings.x.superTight}
        ${paddings.y.buttonSmall}
      }
      .BlocksSelect-Arrow {
        height: 16px;
        width: 16px;
        margin-right: 4px;
      }
    }

    .BlocksSelect-small {
      height: 32px;
      ${paddings.x.superTight}
      ${paddings.y.superTight}
      .BlocksTypography {
        ${paddings.x.superTight}
        ${paddings.y.extremeTight}
      }
      .BlocksSelect-Arrow {
        height: 16px;
        width: 16px;
      }
    }
  `,
);

const select_item = themed(
  ({ palette: { bg, border, surface, text }, fontWeight }) => css`
    display: flex;
    cursor: pointer;
    ${cornerRadii.buttonSmall}
    align-items: center;
    ${paddings.x.compact}
    outline: none !important;

    &[aria-disabled='true'] {
      opacity: 0.5;
    }

    &[data-active-item] {
      background-color: ${surface.background};
    }

    .ItemPrefixGraphic-icon {
      align-self: flex-start;
    }

    &.BlocksSelect-checked .BlocksMenuItemContent-text .BlocksTypography {
      font-weight: ${fontWeight.w500};
    }

    &.SelectionSingle:not(.WithRadio).BlocksSelect-checked {
      background-color: ${surface.background};
      color: ${text.focus};
    }

    :hover {
      background-color: ${bg.hover} !important;
    }

    &[data-focus-visible='true'] {
      outline: 2px solid ${border.keyboardFocus} !important;
      outline-offset: -2px;
    }

    .BlocksMenuItemContent {
      width: 100%;
      pointer-events: none;
      ${flex.init}
      ${spacings.medium}
      .MuiRadio-root {
        ${paddings.x.none}
        ${paddings.y.none}
      }
      .MuiCheckbox-root {
        ${margins.yt.m0}
        ${paddings.x.none}
        ${paddings.y.extremeTight}
      }
      .MuiAvatar-root {
        ${margins.xr.m0}
      }
    }

    .BlocksMenuItemContent-text {
      width: 100%;
      .BlocksMenuItemContent-toprow {
        ${flex.init}
        ${flex.align.start}
        ${flex.row}
        ${spacings.medium}
        .SelectItemCheck {
          height: 20px !important;
          width: 20px !important;
        }
      }
    }
  `,
);

export const items_list_wrapper = themed(
  ({ palette, palette: { bg, surface, text }, typography }, { compose }) => css`
    &.BlocksSelect-List.is-in-place {
      outline: none;
    }

    /**
     * OLD STYLES, will be deleted if no regressions reported
     */

    /* .BlocksSelect-ListHeader {
      ${paddings.x.compact}
      ${paddings.y.tight}

      .BlocksSelect-header {
        ${paddings.yt.superTight}
      }
    }

    .BlocksSelect-OptionListWrapper {
      ${paddings.x.none}
      ${paddings.y.none}
      > .BlocksSelect-Group {
        &:not(:first-of-type) {
          ${margins.yt.m1point5}
        }
        .ChildWithPadding > div {
          ${paddings.xl.compact}
        }
      }
      .BlocksSelect-SelectItem {
        ${compose(select_item)}
        ${paddings.y.tight}

        &[data-active-item] {
          background-color: ${palette.surface.background};
        }
      }
    } */

    /**
     * HEADER AND FOOTER STYLES
     */

    .BlocksSelect-ListHeader {
      top: 0;
      ${paddings.x.comfortable}
      ${paddings.yt.comfortable}
      ${paddings.yb.tight}

      .BlocksSelect-header {
        ${flex.init}
        ${flex.align.center}
        ${paddings.yb.tight}
      }
    }

    .BlocksSelect-footer {
      ${paddings.x.tight}
      ${paddings.yt.tight}
      ${paddings.yb.compact}
    }

    /*
      * LIST PERIPHERALS STYLES
    */

    .BlocksSelect-EmptyContent,
    .BlocksSelect-ListLoader {
      ${paddings.x.compact}
      ${paddings.y.compact}
        color: ${text.secondary};
    }

    .BottomLoader {
      width: 50%;
      height: 2px;
      background-color: ${bg.brand};
      position: absolute;
      bottom: 0;
      animation: sliding 2s infinite linear;
      @keyframes sliding {
        0% {
          transform: translateX(-200%);
        }
        25% {
          transform: translateX(-100%);
        }
        50% {
          transform: translateX(0);
        }
        75% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(200%);
        }
      }
    }

    /**
     * LIST CONTENT AND ITEM STYLES
     */

    .BlocksSelect-OptionListWrapper {
      ${paddings.x.superTight}
      ${paddings.y.superTight}
      background-color: ${surface.menu};
      .BlocksSelect-ListPrefix {
        ${paddings.x.compact}
        ${paddings.yt.comfortable}
        ${paddings.yb.tight}
        color: ${text.secondary}
      }

      > .BlocksSelect-Group {
        &:not(:first-of-type) {
          ${margins.yt.m1point5}
        }
        .ChildWithPadding > div {
          ${paddings.xl.compact}
        }
      }
      .BlocksSelect-SelectItem {
        ${compose(select_item)}
      }

      &.option-list-size-standard {
        .BlocksSelect-ListPrefix {
          ${typography.body2.default}
          ${typography.body2.medium}
        }

        .BlocksSelect-SelectItem {
          ${typography.body1.default}
          ${paddings.y.compact}
        }

        /* For standard sized menus, lineHeight=24px, so we need to ensure size of prefix radio/checkbox is also 24px.
         Radio/Checkbox have size of 20px, so we need to add 2px padding above and below. Avatar has size 24px so not
         needed for them
      */
        .MuiRadio-root,
        .MuiCheckbox-root {
          padding-top: 2px !important;
          padding-bottom: 2px !important;
        }
      }

      &.option-list-size-medium,
      &.option-list-size-small {
        .BlocksSelect-ListPrefix {
          ${typography.body3.default}
          ${typography.body3.medium}
        }
        .BlocksSelect-SelectItem {
          ${typography.body2.default}
          ${paddings.y.tight}
        }
      }
    }
  `,
);

export const popover_wrapper = themed(
  ({ palette: { border, shadow, surface } }) => css`
    min-width: 20rem;
    z-index: 50;
    outline: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    box-shadow: ${shadow.componentShadow};
    border-radius: ${border.radiusRegular};
    background-color: ${surface.menu};

    /* In mobile select is rendered as drawer, no need for max-height in mobile */
    ${breakpoints.from('tablet')} {
      .ItemListWrapper {
        max-height: clamp(300px, 40vh, 450px);
        overflow: auto;
        overscroll-behavior: contain;
      }
    }
  `,
);

export const select_list_header = themed(
  () => css`
    ${flex.init}
    ${flex.justify.between}
    position: relative;
    width: 100%;

    .BlocksButton-ghost {
      width: 1.25rem;
      height: 1.25rem;
    }
  `,
);

export const select_list_footer = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.align.center}
    padding: 0.75rem 1rem;
    width: 100%;
    background-color: ${palette.surface.menu};
  `,
);

export const pseudo_input_content = css`
  position: absolute;
  /* left: 0.75rem; */
  /* opacity: 0; */
  pointer-events: none;
  width: 100%;
  /* color: red; */
  height: 100%;
  padding: 0.75rem 0.75rem;
  display: flex;
  align-items: center;

  &.is-hidden {
    opacity: 0;
    display: none;
  }
`;

export const autocomplete_loading_multiple = themed(
  ({ palette }) => css`
    color: ${palette.border.dark};
    ${margins.xr.half}
  `,
);

export const autocomplete_loading_single = themed(
  ({ palette }) => css`
    position: absolute;
    right: 0.75rem;
    color: ${palette.border.dark};
  `,
);

export const swipeable_drawer = themed(
  ({ palette }) => css`
    .BlocksSelect-ListHeader {
      box-shadow: ${palette.shadow.scrollTopShadow};
      ${margins.yb.half}
      ${paddings.y.comfortable}
      ${paddings.x.extraCozy}
      .BlocksSelect-header {
        position: relative;
        ${paddings.yt.none}
        ${paddings.x.none}
      }
      .BlocksIconButton-standard {
        top: -6px;
      }
    }

    .MuiPaper-root {
      min-height: 80vh;
      max-height: 80vh;
    }
    z-index: 2350; /* 2300 is the z-index of the modal packages/blocks/src/BlocksThemeProvider/theming.ts */
  `,
);
