import { DeprecatedOptionWithStringifiedValue } from '@spotnana/blocks/src/SelectRoot/helpers';
import { Tier } from '@spotnana/types/openapi/models/tier';
import UserAvatar from 'src/v2-components/shared/UserAvatar';
import { TOption } from '../TravelerPicker';

export function TravelerPickerOptionPrefix({ item }: { item: DeprecatedOptionWithStringifiedValue<TOption> }) {
  const { tier, profilePicture, fullName } = item;
  return (
    <UserAvatar isSeat1a={tier === Tier.SEAT1A} imageSrc={profilePicture?.url} size="small" title={fullName || ''} />
  );
}
