import addRequestInterceptor from '../addRequestInterceptor';
import { getSessionId } from '../../../utils/xSessionId';
import { REQUEST_HEADERS } from '../../../constants/common';

function injectXSessionIdHeader() {
  return addRequestInterceptor({
    onFulfilled: async (config) => {
      const xSessionId = getSessionId();

      if (xSessionId) {
        config.headers[REQUEST_HEADERS.X_SESSION_ID] = xSessionId;
      }

      return config;
    },
  });
}

export default injectXSessionIdHeader;
