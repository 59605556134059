/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Persona of the user
 * @export
 * @enum {string}
 */

export const Persona = {
  UnknownPersona: 'UNKNOWN_PERSONA',
  Employee: 'EMPLOYEE',
  Guest: 'GUEST',
  Personal: 'PERSONAL',
  Relative: 'RELATIVE',
  Adhoc: 'ADHOC',
} as const;

export type Persona = (typeof Persona)[keyof typeof Persona];
