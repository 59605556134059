import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const SuiteSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.222 3.833c.675 0 1.222.547 1.222 1.222V10.2l3.727 5.856h7.86l2.812 3.514a1.222 1.222 0 11-1.909 1.527L13.857 18.5H7.17a2.444 2.444 0 01-2.062-1.133l-3.727-5.855A2.444 2.444 0 011 10.199V5.055c0-.675.547-1.222 1.222-1.222z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.5 20.944c0 .337.274.61.611.61h6.607a.611.611 0 100-1.221H7.11a.611.611 0 00-.611.61zM23 4.444c0-1.013-.82-1.834-1.833-1.834H18.11c-1.012 0-1.833.821-1.833 1.834V8.72a.611.611 0 101.222 0V4.444c0-.338.274-.611.611-.611h3.056c.337 0 .61.273.61.61v15.278a.611.611 0 01-.61.611h-1.834a.611.611 0 100 1.223h1.834c1.012 0 1.833-.821 1.833-1.834V4.444zm-1.833 7.333a.611.611 0 00-.611-.611h-.611a.611.611 0 000 1.222h.61a.611.611 0 00.612-.611z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.98 8.851a.66.66 0 111.14-.66l1.036 1.914a3.055 3.055 0 002.686 1.602l4.972.003 3.97 4.96a.47.47 0 11-.745.577l-3.02-4.021a.72.72 0 00-.574-.288l-6.6-.008a.611.611 0 01-.518-.29L5.98 8.851zm-.165-2.083a1.882 1.882 0 00-.874 2.726l2.347 3.79c.333.539.922.867 1.556.868l6.349.008 2.869 3.821a1.693 1.693 0 102.676-2.074l-4.134-5.167a.672.672 0 00-.525-.252l-5.236-.003c-.673 0-1.291-.37-1.612-.961L8.196 7.609a1.882 1.882 0 00-2.381-.84z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M7.834 3.833a1.333 1.333 0 11-2.667 0 1.333 1.333 0 012.667 0z", fill: themeEnrichedPrimaryColor, stroke: "#000" })));
};
export default SuiteSeat;
SuiteSeat.defaultProps = defaultIconProps;
