import styled from 'styled-components';
import { variant } from 'styled-system';
const ButtonAddon = styled('span')({
    // Needed to fix extra space below the icon
    display: 'inline-flex',
    position: 'relative',
}, variant({
    prop: 'variation',
    variants: {
        left: { marginRight: '1rem' },
        right: { marginLeft: '1rem' },
    },
}));
ButtonAddon.defaultProps = {
    variation: 'left',
};
export default ButtonAddon;
