import { css } from '@emotion/react';

import { paddings, flex, breakpoints, margins, themed } from '@spotnana/blocks/src/utils';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const footer_container = css`
  ${flex.init}
  ${flex.column}
  ${flex.justify.center}
  ${flex.align.center}
  ${breakpoints.from('tablet')} {
    ${flex.row}
    ${flex.justify.between}
  }
`;

export const footer_left_section = css`
  ${flex.init}
  ${flex.column}
    ${flex.justify.center}
    ${flex.align.center}
    ${margins.xl.half}
    ${breakpoints.from('tablet')} {
    ${flex.align.start}
  }
  ${breakpoints.from('desktop')} {
    ${flex.row}
    ${flex.align.center}
  }
`;

export const footer_text = css`
  display: inline;
  ${paddings.xr.p1point5}
`;

export const footer_links = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.extraLarge}
`;

export const copyright_section = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.align.center}

  .Logo {
      display: inline-flex;
      &:focus-visible {
        outline: none;
        img {
          outline: solid 2px ${palette.border.keyboardFocus};
          outline-offset: 2px;
        }
      }
    }
  `,
);
