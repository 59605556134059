import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const SeatLayout = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("rect", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", x: "18.53", y: "18.53", width: "5.07", height: "5.07", rx: "0.93" }),
        React.createElement("rect", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", x: "8.4", y: "18.53", width: "5.07", height: "5.07", rx: "0.93" }),
        React.createElement("rect", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", x: "18.53", y: "8.38", width: "5.07", height: "5.07", rx: "0.93" }),
        React.createElement("rect", { fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px", x: "8.4", y: "8.38", width: "5.07", height: "5.07", rx: "0.93" })));
};
SeatLayout.defaultProps = defaultIconProps;
export default SeatLayout;
