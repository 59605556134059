/* eslint-disable react/jsx-no-useless-fragment */
import type { PropsWithChildren } from 'react';

import * as React from 'react';
import { useLoggedInUserBasicInfo } from '../../providers/AuthProvider';
import type { RoleInfoType } from '../../types/api/v1/obt/profile/role/roles_info';
import { RoleInfoTypeEnum } from '../../types/api/v1/obt/profile/role/roles_info';
import type { RBACPredicate } from './RBACPredicates';

/*
Examples:

- API Signature 1: Multiple roles in an array will be considered as an OR check. If the user is having both  `GLOBAL_ADMIN`  , `GLOBAL_AGENT` or either of these role then it will going to render the `Component` , else it will going to render the `FallbackComponent`
<RBAC allowedRoles={[GLOBAL_ADMIN, GLOBAL_AGENT]} fallback={<FallbackComponentA/>}>
  <ComponentA/>
</RBAC>

- API Signature 2: Here the `GLOBAL_ADMIN` and `GLOBAL_AGENT` will be considered as an AND check. If user is having both roles then only it will render the `ComponentB` else it will going to render either `FallbackComponentA` or `FallbackComponentB` depending on the missing role. If user is not having `GLOBAL_ADMIN` role then it will render `FallbackComponentA` . If user is having `GLOBAL_ADMIN` role but not having `GLOBAL_AGENT` then it will render `FallbackComponentB`
<RBAC allowedRoles={[GLOBAL_ADMIN]} fallback={<FallbackComponentA msg={'Test msg'}/>}>
  <RBAC allowedRoles={[GLOBAL_AGENT]} fallback={<FallbackComponentB extraProp={'Something'}/>}>
    <ComponentB/>
  </RBAC>
</RBAC>

- API Signature 3: ComponentA will get rendered if the user is having (GLOBAL_ADMIN && (COMPANY_ADMIN || COMPANY_TRAVEL_ARRANGER)) and ComponentB will get rendered if user is having (GLOBAL_ADMIN && GLOBAL_AGENT)
<RBAC allowedRoles={[GLOBAL_ADMIN]} fallback={<FallbackComponentA msg={'Test msg'}/>}>
  <RBAC allowedRoles={[COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER]}>
    <ComponentA/>
  </RBAC>
  <RBAC allowedRoles={[GLOBAL_AGENT]} fallback={<FallbackComponentB extraProp={'Something'}/>}>
    <ComponentB/>
  </RBAC>
</RBAC>
*/

export interface IRBACProps {
  allowedRoles: readonly RoleInfoType[];
  secondaryPredicate?: RBACPredicate;
  condition?: 'AND' | 'OR';
  fallback?: React.ReactNode;
}

export const useHasUserAccess = (
  allowedRoles: readonly RoleInfoType[],
  secondaryPredicate?: RBACPredicate,
  condition: 'AND' | 'OR' = 'AND',
): boolean => {
  const userBasicInfo = useLoggedInUserBasicInfo();
  const roles = userBasicInfo?.existingUser?.roleInfos ?? [];

  if (!allowedRoles.length) {
    return true;
  }

  let loggedInUserRoleInfoTypes = roles.map((role) => role.type);
  if (!loggedInUserRoleInfoTypes.length) {
    loggedInUserRoleInfoTypes = [RoleInfoTypeEnum.UNKNOWN_TYPE, RoleInfoTypeEnum.UNRECOGNIZED];
  }
  // Decide based on allowedRoles
  const areUserRolesSufficient = allowedRoles.some((allowedRole) => loggedInUserRoleInfoTypes.includes(allowedRole));
  // APPLY SECONDARY PREDICATE AS PER CONDITION. NOTE: OR CAN BE USED TO OVERRIDE ROLE BASED ACCESS
  if (secondaryPredicate) {
    if (condition === 'AND') {
      return areUserRolesSufficient && secondaryPredicate(userBasicInfo);
    }
    return areUserRolesSufficient || secondaryPredicate(userBasicInfo);
  }
  return areUserRolesSufficient;
};

/**
 * TODO: Upgrade RBAC to Rules Based Access Component. More details captured in this Jira:
 * https://spotnana.atlassian.net/browse/ST-21572
 */
export const RBAC: React.FC<PropsWithChildren<IRBACProps>> = ({
  allowedRoles,
  secondaryPredicate = undefined,
  condition = 'AND',
  children,
  fallback,
}: PropsWithChildren<IRBACProps>) => {
  const hasAccess = useHasUserAccess(allowedRoles, secondaryPredicate, condition);
  if (!hasAccess) {
    if (fallback) {
      return <>{fallback}</>;
    }
    return <></>;
  }
  return <>{children}</>;
};
