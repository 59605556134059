import { css } from '@emotion/react';
import { flex } from '@spotnana/blocks/src/utils/styling/utilityClasses';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const name_logo_persona_container = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.small}
  width: 300px;
`;

export const name_logo_container = css`
  ${flex.init}
  ${flex.row}
  ${flex.align.center}
  ${spacings.small}
  position: relative;
  top: -2px;
`;

export const traveler_picker_company_info = css`
  ${flex.init}
`;

export const traveler_persona_loader = css`
  width: 100px;
  height: 2rem;
`;

export const traveler_company_logo = css`
  height: 1.5rem;
  width: 1.5rem;
`;
