import { extractTmcIdFromUrl } from './extractTmcIdFromUrl';

// 1. Memoize the tmcId from the URL on js execution
let initialTmcIdFromUrl: string | null = extractTmcIdFromUrl();

export function saveInMemoryCacheTmcId(tmcId: string): void {
  initialTmcIdFromUrl = tmcId;
}

export function removeFromMemoryCacheTmcId(): void {
  initialTmcIdFromUrl = null;
}

export function getFromMemoryCacheTmcId(): string | null {
  return initialTmcIdFromUrl;
}
