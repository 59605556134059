import { SelectStore, ComboboxStore, SelectList, ComboboxList, SelectPopover, ComboboxListProps } from '@ariakit/react';

import { cloneElement, useRef } from 'react';
import { useClickOutside } from '@react-hookz/web/cjs/useClickOutside';
import { popover_wrapper, items_list_wrapper, select_wrapper, swipeable_drawer } from '../SelectRoot.styles';
import { useSelectConfig } from '../SelectConfigContext';
import { breakpoints, useMediaQuery } from '../../utils';
import { SwipeableDrawer } from '../../SwipeableDrawer';
import { fixPopoverEscapeKeyDown } from '../../utils/ariaKitFixes';

export function SelectSearchListWrapper<T extends SelectStore>({
  children,
  renderInPlace,
  selectStore,
  comboboxStore,
  ...rest
}: React.PropsWithChildren<
  {
    renderInPlace?: boolean;
    selectStore?: T;
    comboboxStore: NonNullable<ComboboxStore & { placeholder?: string }>;
  } & Omit<ComboboxListProps, 'store'>
>) {
  const isMobile = !useMediaQuery(breakpoints.from('tablet'));
  const open = selectStore?.useState().open;
  const { portal, sameWidth, popoverProps, containerRef } = useSelectConfig();
  const popoverRef = useRef<HTMLDivElement>(null);

  // Although this feature is natively supported via `hideOnInteractOutside` prop, it is not working as expected.
  // So we manually attach a listener for the same
  useClickOutside(popoverRef, (event) => {
    if (!containerRef?.current?.contains(event.target as Node)) {
      comboboxStore.setOpen(false);
    }
  });

  if (!selectStore) {
    return null;
  }

  const comboboxList = (
    <ComboboxList
      render={(props) => <SelectList className="BlocksSelect-List" store={selectStore} autoFocus={false} {...props} />}
      store={comboboxStore}
      css={items_list_wrapper}
      {...rest}
    >
      {children}
    </ComboboxList>
  );

  if (renderInPlace) {
    return cloneElement(comboboxList, {
      className: `${(comboboxList.props as { className: string }).className} is-in-place`,
      alwaysVisible: true,
    });
  }

  if (isMobile) {
    return (
      <SwipeableDrawer
        onClose={() => selectStore?.setOpen(false)}
        onOpen={() => selectStore?.setOpen(true)}
        anchor="bottom"
        kind="rounded"
        open={open}
        css={[select_wrapper, swipeable_drawer]}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <SelectList store={selectStore} className="has-header" css={popover_wrapper}>
          {comboboxList}
        </SelectList>
      </SwipeableDrawer>
    );
  }

  return (
    <SelectPopover
      ref={popoverRef}
      store={selectStore}
      gutter={8}
      sameWidth={sameWidth}
      className="BlocksSelect-Popover has-header"
      portal={portal}
      css={popover_wrapper}
      hideOnEscape={fixPopoverEscapeKeyDown}
      {...popoverProps}
    >
      {comboboxList}
    </SelectPopover>
  );
}
