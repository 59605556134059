import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Unmask = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13.9815 7.67826C13.4264 6.98479 12.8004 6.35122 12.1136 5.78787C10.6667 4.60282 9.25698 4.00195 7.92205 4.00195C7.74405 4.00288 7.56626 4.01464 7.38969 4.03719C7.32063 4.04609 7.254 4.06853 7.19363 4.10321C7.13326 4.1379 7.08033 4.18416 7.03787 4.23933C6.99513 4.29439 6.96378 4.35739 6.94562 4.42467C6.92747 4.49196 6.92289 4.56218 6.93214 4.63126C6.94091 4.70034 6.9633 4.767 6.998 4.82737C7.03271 4.88775 7.07904 4.94065 7.13433 4.983C7.18969 5.02585 7.25302 5.05726 7.32064 5.07541C7.38826 5.09355 7.45881 5.09807 7.52819 5.08871C7.65887 5.06941 7.79061 5.05806 7.92267 5.05471C10.1109 5.05471 12.2038 7.23132 12.8728 7.9991C12.5694 8.3373 12.2482 8.65921 11.9107 8.96346C11.8066 9.05593 11.7433 9.18583 11.7346 9.32481C11.7259 9.46379 11.7726 9.60056 11.8644 9.70528L11.8693 9.71084C11.9184 9.76386 11.9779 9.80621 12.044 9.83524C12.1102 9.86428 12.1817 9.87938 12.2539 9.8796H12.2619C12.3923 9.88167 12.5186 9.83442 12.6156 9.74731C13.1025 9.30982 13.5571 8.83775 13.9759 8.33477C14.0516 8.24238 14.0934 8.1269 14.0944 8.00749C14.0954 7.88809 14.0555 7.77192 13.9815 7.67826Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M7.06803 6.40159L4.82419 4.15451C4.72497 4.05557 4.59056 4 4.45042 4C4.31029 4 4.17587 4.05557 4.07666 4.15451C3.97754 4.25362 3.92187 4.38804 3.92187 4.5282C3.92187 4.66835 3.97754 4.80277 4.07666 4.90188L4.4631 5.28825C3.50191 5.9706 2.62955 6.77009 1.86621 7.66824C1.79099 7.76195 1.75 7.87851 1.75 7.99866C1.75 8.11881 1.79099 8.23537 1.86621 8.32908C1.89403 8.36555 4.83408 11.998 7.92562 11.998C8.81694 11.9704 9.68819 11.7266 10.4644 11.2877L11.0209 11.844C11.0694 11.8931 11.1272 11.932 11.1909 11.9586C11.2545 11.9853 11.3228 11.9991 11.3918 11.9992C11.5323 11.9992 11.6669 11.9436 11.7665 11.8447C11.8157 11.7956 11.8547 11.7374 11.8813 11.6733C11.9079 11.6091 11.9216 11.5404 11.9216 11.471C11.9216 11.4015 11.9079 11.3328 11.8813 11.2687C11.8547 11.2046 11.8157 11.1463 11.7665 11.0973L7.06803 6.40159ZM9.67728 10.5001C9.12984 10.7709 8.53106 10.9222 7.92067 10.944C5.93405 10.944 3.84603 8.93303 2.98287 7.99896C3.63841 7.27564 4.37372 6.62883 5.17477 6.07086C5.18899 6.05974 5.20321 6.04799 5.21681 6.03563L5.99403 6.81268C5.77508 7.16895 5.65929 7.57897 5.65952 7.99711C5.66018 8.59697 5.89881 9.17208 6.32306 9.59625C6.74732 10.0204 7.32254 10.259 7.92253 10.2596C8.34061 10.2602 8.75059 10.1444 9.10658 9.92521L9.67728 10.5001ZM6.78299 7.60333L8.32257 9.14259C8.04185 9.24015 7.73505 9.23107 7.46061 9.11707C7.18616 9.00308 6.96324 8.79213 6.83431 8.52441C6.76549 8.38183 6.72553 8.22707 6.71672 8.069C6.70792 7.91093 6.73044 7.75268 6.78299 7.60333Z", fill: themeEnrichedPrimaryColor })));
};
Unmask.defaultProps = defaultIconProps;
export default Unmask;
