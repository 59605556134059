var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Breakfast = (_a) => {
    var { primaryColor, width, height } = _a, otherProps = __rest(_a, ["primaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 24 24" }, otherProps),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "nonzero", d: "M17.478 15.063c.395 0 .719.306.747.694l.003.056v.249a2.624 2.624 0 01-2.535 2.623l-.09.002h-7.18A2.624 2.624 0 015.8 16.152l-.002-.09v-.245a.75.75 0 011.498-.056l.002.056v.245c0 .599.469 1.089 1.06 1.123l.065.002h7.18c.599 0 1.088-.469 1.123-1.06l.002-.065v-.249a.75.75 0 01.75-.75zm.638-1.474a.375.375 0 01.044.747l-.044.003H5.92a.375.375 0 01-.044-.748l.044-.002h12.195zm-2.055-2.456c.506 0 .994.186 1.374.525.106.095.228.166.36.212a.51.51 0 01.349.476.505.505 0 01-.415.496l-.078.007a.499.499 0 01-.16-.025 2.058 2.058 0 01-.721-.42 1.068 1.068 0 00-.71-.264l-.127.007a1.05 1.05 0 00-.581.257 2.067 2.067 0 01-1.186.518l-.189.008c-.506 0-.994-.186-1.374-.525a1.061 1.061 0 00-.709-.265l-.127.007a1.05 1.05 0 00-.582.257 2.067 2.067 0 01-1.185.518l-.19.008c-.505 0-.994-.186-1.374-.525a1.062 1.062 0 00-.709-.266l-.127.008c-.211.024-.416.11-.582.258-.211.189-.457.33-.72.419l-.081.02-.08.005a.503.503 0 01-.493-.503.51.51 0 01.35-.476c.13-.046.253-.117.359-.212a2.062 2.062 0 012.749 0c.198.177.453.266.709.266l.127-.008c.212-.024.417-.11.582-.258a2.062 2.062 0 012.748 0c.199.177.454.266.71.266l.127-.008c.211-.024.416-.11.581-.258.38-.339.869-.525 1.375-.525zm-3.084-6.008a5.382 5.382 0 015.38 5.264v.117H5.722a5.38 5.38 0 015.381-5.38h1.874zm0 1.5h-1.874c-1.58 0-2.941.946-3.546 2.302l-.034.079h9.034l-.029-.067a3.88 3.88 0 00-3.446-2.312l-.105-.002z" }),
            React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M17.326 10.204h-.01l.01.052v-.052z" }))));
};
Breakfast.defaultProps = defaultIconProps;
export default Breakfast;
