import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Eco = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M11.677 3.653A10.664 10.664 0 0 0 6.618 14.14a5.29 5.29 0 0 0 4.306 4.4l.348.059v1.9h1.486v-9.2h-1.486v2.867c0 2.8 0 2.86-.088 2.86a4.238 4.238 0 0 1-1.73-.786c-2.66-2.034-1.495-7.974 2.088-10.654.493-.37.455-.363.755-.135a9.156 9.156 0 0 1 3.7 7.368 3.92 3.92 0 0 1-2.335 3.95l-.254.109-.012.8a7.777 7.777 0 0 0 .009.8 8.189 8.189 0 0 0 1.2-.459c2.456-1.262 3.413-4.023 2.629-7.585a10.613 10.613 0 0 0-4.731-6.686c-.532-.327-.462-.319-.825-.1", fill: themeEnrichedPrimaryColor, fillRule: "evenodd" })));
};
Eco.defaultProps = defaultIconProps;
export default Eco;
