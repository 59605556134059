import { useState, useEffect, lazy, Suspense } from 'react';

const Lottie = lazy(() => import('lottie-react'));

interface IProps {
  animation: 'supportAgent' | 'hotelLoading' | 'flightLoading' | 'mobileAppRedirecting';
  loop?: boolean;
  lottieCss?: any;
}

function LottieAnimation({ animation, loop, lottieCss }: IProps) {
  const [animationData, setAnimationData] = useState<object | null>(null);

  useEffect(() => {
    async function loadAnimationData() {
      let animationModule;
      if (animation === 'hotelLoading') {
        animationModule = await import('./hotelLoadingAnimation.json');
      } else if (animation === 'flightLoading') {
        animationModule = await import('./flightLoadingAnimation.json');
      } else if (animation === 'supportAgent') {
        animationModule = await import('./supportAgentAnimation.json');
      } else if (animation === 'mobileAppRedirecting') {
        animationModule = await import('./mobileAppRedirectingAnimation.json');
      }

      if (animationModule) {
        setAnimationData(animationModule.default);
      }
    }

    loadAnimationData();
  }, [animation]);

  if (!animationData) return null;

  return (
    <Suspense fallback={null}>
      <Lottie animationData={animationData} css={lottieCss} loop={loop} />
    </Suspense>
  );
}

export { LottieAnimation };
