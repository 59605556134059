import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import first from 'lodash/first';

import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { stringifyParams } from 'obt-common/utils/urlUtils';
import { TripBasicInfo } from 'obt-common/types/api/v2/obt/model';

import { Avatar } from '@spotnana/blocks/src/Avatar';
import { option_container } from './EntityAutocompleteOptions.styles';
import { EntityAutocompleteOptionProps } from './EntityAutocompleteOption';

const EntityAutocompleteTripOption = ({
  option,
  isCompanyTripsPage = false,
}: EntityAutocompleteOptionProps<TripBasicInfo>): JSX.Element => {
  const history = useHistory();
  const { t: tt } = useTranslation('WEB');

  const goToTrip: MouseEventHandler<HTMLButtonElement> = () => {
    history.push({
      pathname: `/trips/${option.tripId}`,
      search: stringifyParams({
        userId: first(option?.userOrgIds),
        redirectFrom: isCompanyTripsPage ? 'company-trips' : 'trips',
      }),
    });
  };

  return (
    <button type="button" onClick={goToTrip} css={option_container}>
      <Flex style={{ wordBreak: 'break-word' }}>
        <Box px="10px">
          <Avatar color="primary" size="small" alt="" text={option.tripName ?? ''} />
        </Box>
        <Box px="10px">
          <Box mb="4px">
            <Typography variation="small" fontWeight="semiBold" color="text.label" lineHeight="16px">
              {option.tripName}
            </Typography>
            <Typography variation="small" style={{ fontStyle: 'italic' }} color="text.blockedGrey" lineHeight="16px">
              - {tt('In trips')}
            </Typography>
          </Box>
          {option.pnrId && (
            <Typography variation="label" lineHeight="16px" as="p">
              {tt('PNR ID')}: {option.pnrId} / {tt('Source')} {tt('PNR ID')}: {option.sourcePnrId}
            </Typography>
          )}
        </Box>
      </Flex>
    </button>
  );
};

export default EntityAutocompleteTripOption;
