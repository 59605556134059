import { StorageKeys } from '../../types/storage';
import { removeStorageValue } from '../../hooks/useStorage';
import { emitApplicationIdChange } from './eventemitter';
import { removeFromMemoryCache } from './inMemoryUrlCache';

async function removeApplicationId(): Promise<void> {
  await removeStorageValue(StorageKeys.APPLICATION_ID).finally(() => {
    removeFromMemoryCache();
    emitApplicationIdChange(undefined);
  });
}

export default removeApplicationId;
