import { Icon } from '@spotnana/blocks/src/Icon';
import { Popover } from '@spotnana/blocks/src/Popover';
import { useState, cloneElement, useRef, useEffect, useCallback } from 'react';
import { themed } from '@spotnana/blocks/src/utils';
import { css } from '@emotion/react';
import { header_button_element, header_nav_element } from '../AppHeader/AppHeader.styles';

export const POPOVER_WIDTH = 224;

const popover = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.menu};
  `,
);

interface IProps {
  children: React.ReactElement;
  active: boolean;
  label: string;
}

type OpenState = {
  mouseOpen: boolean;
  keyboardOpen: boolean;
  mouseClose: boolean;
  keyboardClose: boolean;
};

/**
 * Expected behaviour from this component is -
 * 1. When the button is hovered, the popover should open. No focus outline should be shown.
 * 2. When the button is unhovered, or mouse moves out of popover, it should close. No focus outline should be shown.
 * 3. If the button is focused via keyboard and Enter key is pressed, the popover should open. Focus outline should be shown.
 * 4. If the popover is open and Escape key is pressed, the popover should close. Focus outline should be shown on button.
 */
export const TabPopover = ({ children, active, label }: IProps) => {
  const [open, setOpen] = useState<OpenState>({
    mouseOpen: false,
    keyboardOpen: false,
    mouseClose: false,
    keyboardClose: false,
  });
  const ref = useRef<HTMLButtonElement>(null);

  const onMouseEnter = () => {
    /**
     * If we open the popover via keyboard and then bring mouse inside popover, we do not want
     * the focus to disappear. Hence, we are setting mouseOpen to true only if popover is not opened via keyboard already.
     */
    if (open.keyboardOpen === false) {
      setOpen({ mouseOpen: true, keyboardOpen: false, mouseClose: false, keyboardClose: false });
    }
  };
  const onKeyboardOpen = () => {
    setOpen({ mouseOpen: false, keyboardOpen: true, mouseClose: false, keyboardClose: false });
  };

  const onClose = () => {
    if (open.mouseOpen) {
      setOpen({ mouseOpen: false, keyboardOpen: false, mouseClose: true, keyboardClose: false });
    } else {
      setOpen({ mouseOpen: false, keyboardOpen: false, mouseClose: false, keyboardClose: true });
    }
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    /**
     * While we are closing the popover via keyboard, we do not want focus border around the button in the case
     * where popover was opened via mouse and closed via keyboard
     */
    if (event.key === 'Escape') {
      setOpen({ mouseOpen: false, keyboardOpen: false, mouseClose: false, keyboardClose: false });
    }
  }, []);

  /**
   * When we hover over the button, nothing gets focused. So, when we press the escape key, the keyboard event
   * is not triggered and popover is not closed. So, we need to add the event listener to the document and not the button.
   */
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Popover
      isOpen={open.mouseOpen || open.keyboardOpen}
      htmlButtonElement={
        <button
          className={active ? 'active' : undefined}
          css={[header_nav_element, header_button_element]}
          type="button"
          onClick={() => {
            if (!open.mouseOpen) {
              onMouseEnter();
            } else {
              onClose();
            }
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onClose}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onKeyboardOpen();
            }
            if (event.key === 'Escape') {
              onClose();
            }
          }}
          aria-expanded={open.mouseOpen || open.keyboardOpen}
          ref={ref}
        >
          {label}
          <Icon name="ChevronDown" />
          {/* This div adds a space between the button and the popover so that
           when we move mouse from button to popover, the popover does not close */}
          {(open.keyboardOpen || open.mouseOpen) && (
            <div style={{ position: 'absolute', width: POPOVER_WIDTH, height: '12px', left: 0, bottom: '-12px' }} />
          )}
        </button>
      }
      placement="bottom-start"
      onClose={onClose}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onClose}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      }}
      autoFocusOnShow={open.keyboardOpen}
      autoFocusOnHide={open.keyboardClose}
      data-testid={`${label.toLowerCase()}-tab-popover`}
      css={popover}
    >
      {cloneElement(children, { onClose })}
    </Popover>
  );
};
