import { ReactElement } from 'react';
import {
  createUserOrgIdUsingUserId,
  ITraveler,
  IUserOrgId,
  useReadMultipleProfilesWithUserOrgIdQuery,
  FareList,
} from 'obt-common';
import { Loader } from 'src/v2-components/flights/shared/Loader';
import PreloadErrorModal from 'src/app/flights/checkout/shared/components/PreloadErrorModal/PreloadErrorModal';
import { ISavedFareListAllApiResponses } from '../SavedFareListApiProvider/types';

interface IProps {
  children: (props: Pick<ISavedFareListAllApiResponses, 'travelersResponse'>) => ReactElement;
  fareList: FareList[];
  onClose: VoidFunction;
}

export function PreloadContainerSavedFareList({ children, fareList, onClose }: IProps) {
  const userOrgIds = fareList
    .map((f) => createUserOrgIdUsingUserId(f.travelerUserId))
    .filter((t): t is IUserOrgId => !!t);

  const travelers = useReadMultipleProfilesWithUserOrgIdQuery(userOrgIds);

  const areAllTravelersFetched = travelers.every((t) => t.data);
  const travelersResponse = areAllTravelersFetched
    ? travelers.map((t) => t.data).filter((t): t is ITraveler => !!t)
    : undefined;

  const allQueries = [...travelers];

  const firstQueryWithError = allQueries.find((query) => query.error);

  if (firstQueryWithError?.error) {
    if (firstQueryWithError.isFetching) {
      return <Loader />;
    }

    return (
      <PreloadErrorModal
        error={firstQueryWithError.error}
        refetch={{ enabled: true, onRefetch: firstQueryWithError.refetch }}
        onBack={onClose}
      />
    );
  }

  if (travelersResponse) {
    return children({
      travelersResponse,
    });
  }

  return <Loader />;
}
