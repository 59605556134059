var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
const SingleTraveler = (_a) => {
    var { width, height, primaryColor, secondaryColor } = _a, restProps = __rest(_a, ["width", "height", "primaryColor", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 20 22", width: width, height: height, xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { d: "m7.785 19.104-.095-.095-.13-.034C3.493 17.91.5 14.25.5 9.9.5 4.714 4.75.5 10 .5s9.5 4.214 9.5 9.4c0 4.35-2.993 8.009-7.06 9.075l-.13.034-.095.095L10 21.296l-2.215-2.192Z", fill: "#fff", stroke: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 4.656a3.276 3.276 0 0 0-2.21 5.695 4.7 4.7 0 0 0-3.134 4.43.562.562 0 1 0 1.125 0c0-1.973 1.6-3.572 3.572-3.572H10.733a3.573 3.573 0 0 1 3.486 3.572.563.563 0 0 0 1.125 0 4.7 4.7 0 0 0-3.133-4.43A3.276 3.276 0 0 0 10 4.657Zm.022 5.428a2.151 2.151 0 1 0-.044 0h.044Z", fill: themeEnrichedPrimaryColor })));
};
export default SingleTraveler;
