var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultIconProps } from './common';
const FlightTakeOff = (_a) => {
    var { width, height } = _a, props = __rest(_a, ["width", "height"]);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "m7.746 4.231-.05.017c-.533.21-.759.966-.402 1.493l2.441 3.534-3.102.985-1.734-1.128a.865.865 0 0 0-.746-.108L3.15 9.34c-.619.197-.936 1.005-.637 1.66l1.315 2.764c.686 1.44 2.143 2.147 3.54 1.703l12.96-4.114c1.09-.38 1.598-1.787 1.046-2.878l-.047-.089c-.773-1.41-2.264-2.074-3.681-1.624l-2.505.795-4.508-3.688c-.328-.252-.694-.335-1.064-.218l-1.824.579Zm3.556 4.99L8.675 5.418l1.207-.383c.005-.001.006 0 .036.022l4.73 3.87a.55.55 0 0 0 .522.105l2.789-.885c.908-.289 1.867.152 2.341 1.072.14.277.006.65-.298.756l-12.946 4.11-.074.022c-.82.22-1.659-.207-2.06-1.049l-1.157-2.43.594-.188 1.711 1.113.058.033c.242.12.5.158.765.074l4.076-1.294c.434-.138.61-.743.333-1.145ZM3.6 19.68c0-.398.282-.72.63-.72h15.54c.348 0 .63.322.63.72 0 .397-.282.72-.63.72H4.23c-.348 0-.63-.323-.63-.72Z" })));
};
FlightTakeOff.defaultProps = defaultIconProps;
export default FlightTakeOff;
