import type { PaxInfo } from '../../../types/api/v1/obt/air/air_search_response';
import { MoneyUtil } from '../../Money';

export function getLegBaseFarePerPaxForPaxInfo(paxInfo: PaxInfo[]): MoneyUtil {
  const baseLegFarePerPax = paxInfo[0].fareComponents[0].legInfo[0].legFarePerPax?.base;
  return MoneyUtil.parse(baseLegFarePerPax);
}

export function getLegTaxFarePerPaxForPaxInfo(paxInfo: PaxInfo[]): MoneyUtil {
  const taxLegFarePerPax = paxInfo[0].fareComponents[0].legInfo[0].legFarePerPax?.tax;
  return MoneyUtil.parse(taxLegFarePerPax);
}

export function getLegFarePerPaxForPaxInfo(paxInfo: PaxInfo[]): MoneyUtil {
  return getLegBaseFarePerPaxForPaxInfo(paxInfo).add(getLegTaxFarePerPaxForPaxInfo(paxInfo));
}
