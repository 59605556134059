import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Caution = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 -2 17 18", "data-testid": "caution-icon" },
        React.createElement("path", { fill: "none", stroke: themeEnrichedPrimaryColor, d: "M9.81916 1.38678C9.25192 0.338798 7.74808 0.3388 7.18084 1.38678L1.5521 11.786C1.01114 12.7854 1.73482 14 2.87127 14H14.1287C15.2652 14 15.9889 12.7854 15.4479 11.786L9.81916 1.38678Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M8.50052 3.79356C8.07745 3.79355 7.73025 4.11845 7.69487 4.53236L7.69189 4.60213L7.69161 9.44253C7.69159 9.8891 8.0536 10.2511 8.50017 10.2511C8.92324 10.2512 9.27044 9.92626 9.30582 9.51235L9.3088 9.44259L9.30908 4.60219C9.3091 4.15561 8.94709 3.79358 8.50052 3.79356Z" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M8.50052 10.9629C8.07745 10.9629 7.73025 11.2878 7.69486 11.7017L7.69189 11.7715L7.69161 11.7603C7.69159 12.2069 8.0536 12.5689 8.50017 12.5689C8.92324 12.5689 9.27044 12.244 9.30583 11.8301L9.3088 11.7604L9.30908 11.7715C9.3091 11.3249 8.94709 10.9629 8.50052 10.9629Z" })));
};
Caution.defaultProps = defaultIconProps;
export default Caution;
