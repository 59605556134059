import { useMutation, useQuery } from 'react-query';

import type { Employee } from '../../types/api/v1/obt/common/employee';
import type {
  CreateEmployeeRequest,
  CreateEmployeeResponse,
  UpdateEmployeeRequest,
  DeleteUserRequest,
  ReadEmployeeRequest,
  ReadEmployeeResponse,
} from '../../types/api/v1/obt/profile/profile_services';
import type { UpdateUserRolesRequest } from '../../types/api/v1/obt/profile/role/role_services';
import type {
  IUserOrgId,
  SpotnanaQueryMutationResult,
  SpotnanaQueryResult,
  ListUsersRequest,
  ListUsersResponse,
} from '../../types';
import { removeEmptyValuesFromObject } from '../../utils/common';
import { DEFAULT_USER_LIST_PAGE_INDEX } from '../../constants/admin';
import api from '../../api';
import SpotnanaError from '../../api/SpotnanaError';
import { defaultQueryClient } from '../defaultQueryClient';

import { invalidateReadOrganization } from './organization';
import type { UserInfo } from '../../types/api/v2/obt/model/user-info';

export const getListEmployeeKey = (data: object | undefined = undefined): [string, object] | [string] => {
  const listEmployeeKey = 'list-employee';
  return data ? [listEmployeeKey, data] : [listEmployeeKey];
};

const fetchUserList = async (request: ListUsersRequest): Promise<ListUsersResponse> => {
  try {
    const result = await api('POST', 'listEmployees', {
      data: request,
    });
    return result as ListUsersResponse;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useFetchUserList = (
  request: ListUsersRequest,
  options?: { enabled: boolean },
): SpotnanaQueryResult<ListUsersResponse> =>
  useQuery<ListUsersResponse, SpotnanaError>(
    getListEmployeeKey({ ...request, pageNumber: request.pageNumber ?? DEFAULT_USER_LIST_PAGE_INDEX }),
    () => fetchUserList(request),
    {
      ...options,
      keepPreviousData: true,
    },
  );

export const useFetchAllUserListQuery: () => {
  fetchRecords: (listUsersRequest: ListUsersRequest) => Promise<ListUsersResponse['userInfos'] | undefined>;
} = (): {
  fetchRecords: (listUsersRequest: ListUsersRequest) => Promise<ListUsersResponse['userInfos'] | undefined>;
} => {
  const { mutateAsync } = useMutation((request: ListUsersRequest) => fetchUserList(request));

  const fetchRecords = async (listUsersRequest: ListUsersRequest) => {
    try {
      const userInfos: UserInfo[] = [];
      let responsePages = 1;
      let currentPageNumber = 1;

      while (currentPageNumber <= responsePages) {
        // we will require to disable the no-await-in-loop here
        // the future calls will depend on response of current call
        // eslint-disable-next-line no-await-in-loop
        const data = await mutateAsync({
          ...listUsersRequest,
          pageNumber: currentPageNumber,
        });
        if (data.numPages) {
          responsePages = data.numPages ?? 1;
        }
        userInfos.push(...(data.userInfos ?? []));
        currentPageNumber += 1;
      }
      return userInfos;
    } catch (error) {
      return undefined;
    }
  };
  return {
    fetchRecords,
  };
};

export const invalidateUserList = (): void => {
  defaultQueryClient.invalidateQueries(getListEmployeeKey());
};

const fetchEmployeeDetails = async (userOrgId: IUserOrgId): Promise<ReadEmployeeResponse> => {
  const readEmployeeRequest: ReadEmployeeRequest = { userOrgId: removeEmptyValuesFromObject(userOrgId) };
  const employeeDetailsResponse = await api('POST', 'readEmployee', {
    data: readEmployeeRequest,
  });
  return employeeDetailsResponse as ReadEmployeeResponse;
};
const employeeDetailsKey = (userOrgId: IUserOrgId): unknown[] => ['read-employee', userOrgId?.userId];
export const useEmployeeReadQuery = (
  userOrgId: IUserOrgId,
  options?: { enabled: boolean },
): SpotnanaQueryResult<ReadEmployeeResponse> =>
  useQuery<ReadEmployeeResponse, SpotnanaError>(
    employeeDetailsKey(userOrgId),
    () => fetchEmployeeDetails(userOrgId),
    options,
  );
export const invalidateReadEmployee = (userOrgId: IUserOrgId): void => {
  defaultQueryClient.invalidateQueries(employeeDetailsKey(userOrgId));
};

const addEmployeeInternal = async (employee: Employee): Promise<IUserOrgId> => {
  const createEmployeeRequest: CreateEmployeeRequest = { employee };

  const data = await api('POST', 'addEmployee', {
    data: createEmployeeRequest,
  });
  const result = data as CreateEmployeeResponse;
  if (result.userOrgId) {
    return result.userOrgId;
  }
  throw new SpotnanaError('UserOrgId was not returned after addEmployee was a success');
};
export const useEmployeeAddQuery = (): SpotnanaQueryMutationResult<IUserOrgId, Employee> =>
  useMutation((employee: Employee) => addEmployeeInternal(employee), {
    onSuccess: (userOrgId) => {
      if (userOrgId) {
        invalidateReadEmployee(userOrgId);
        invalidateReadOrganization(userOrgId.organizationId ?? { id: '' });
      }
    },
  });

const updateEmployeeInternal = async (updateEmployeeRequest: UpdateEmployeeRequest): Promise<void> => {
  await api('POST', 'updateEmployee', {
    data: updateEmployeeRequest,
  });
};
export const useEmployeeUpdateQuery = (): SpotnanaQueryMutationResult<void, UpdateEmployeeRequest> =>
  useMutation((requestBody: UpdateEmployeeRequest) => updateEmployeeInternal(requestBody), {
    onSuccess: (_data, requestBody) => {
      if (requestBody.employee?.userBasicInfo?.userOrgId) {
        invalidateReadEmployee(requestBody.employee.userBasicInfo.userOrgId);
      }
    },
  });

const updateEmployeeRole = async (updateEmployeeRoleRequest: UpdateUserRolesRequest): Promise<void> => {
  await api('POST', 'updateRole', {
    data: updateEmployeeRoleRequest,
  });
};
export const useEmployeeRoleUpdateQuery = (): SpotnanaQueryMutationResult<void, UpdateUserRolesRequest> =>
  useMutation((requestBody: UpdateUserRolesRequest) => updateEmployeeRole(requestBody), {
    onSuccess: (_data, requestBody) => {
      if (requestBody?.userOrgId) {
        invalidateReadEmployee(requestBody.userOrgId);
      }
    },
  });

const deleteEmployeeInternal = async (userOrgId: IUserOrgId): Promise<void> => {
  const deleteEmployeeRequest: DeleteUserRequest = {
    userOrgId: removeEmptyValuesFromObject(userOrgId),
  };

  await api('POST', 'deleteEmployee', {
    data: deleteEmployeeRequest,
  });
};
export const useEmployeeDeleteQuery = (): SpotnanaQueryMutationResult<void, IUserOrgId> =>
  useMutation((userOrgId: IUserOrgId) => deleteEmployeeInternal(userOrgId), {
    onSuccess: (_data, userOrgId) => {
      invalidateReadOrganization(userOrgId.organizationId ?? { id: '' });
    },
  });
