import { EditorToolbarCustomizationProps } from './types';

export const DEFAULT_EDITOR_CUSTOMIZATION_PROPS: Required<EditorToolbarCustomizationProps> = {
  showTypography: true,
  showFontFamily: true,
  showFontSize: true,
  showFontStyle: true,
  showTextForegroundColor: true,
  showTextBackgroundColor: true,
  showAlignment: true,
};
