import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const PremiumEconomy = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.94 13.91L7.02999 13.79L8.86 11.53L9.97 10.18C10.08 10.03 10.25 9.94 10.43 9.92H14.18C14.54 9.92 14.84 10.21 14.84 10.58C14.84 10.92 14.57 11.21 14.23 11.24H10.82L10.16 12.01L10.14 12.03L8.19 14.35L7.91 14.69C7.8 14.83 7.64999 14.91 7.47999 14.92C7.29999 14.92 7.15 14.88 7.02 14.76C6.76 14.54 6.73999 14.16 6.94999 13.9L6.94 13.91Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.25 14.83V16.78C16.25 17.14 15.96 17.44 15.6 17.44H8.43001C6.44001 17.44 4.35001 16.87 4.21001 13.55L3.03 9.64C3.01 9.57 3 9.51 3 9.44V5.16C3 3.96 3.23 3 4.41 3C5.59 3 5.91 3.75 5.91 4.69L5.94 9.35L7.3 12.64L6.55 13.62C6.17 14.09 6.23001 14.76 6.68001 15.16C6.88001 15.34 7.13 15.42 7.39 15.43H7.50999C7.80999 15.4 8.08 15.26 8.28 15.03L9.12 14.06H15.61C15.96 14.06 16.26 14.47 16.26 14.84L16.25 14.83Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M6.47 4.35001H6.97C7.31 4.35001 7.58 4.62001 7.58 4.96001V6.77C7.58 7.11 7.31 7.38 6.97 7.38H6.47V4.35001Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M19.7323 19.7516L16.1651 16.6L15.6023 17.237L19.1695 20.3886L19.7323 19.7516Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M19.7985 18.3661L18.7803 19.5611C18.6657 19.6957 18.6819 19.8976 18.8164 20.0123L18.9762 20.1484C19.1108 20.2631 19.3128 20.2469 19.4274 20.1124L20.4455 18.9173C20.5601 18.7828 20.544 18.5808 20.4095 18.4662L20.2496 18.33C20.1151 18.2154 19.9131 18.2315 19.7985 18.3661Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.01 21.36H9.62C9.21 21.36 8.85001 21.09 8.74001 20.7L7.94 18H13.71L12.91 20.7C12.79 21.09 12.43 21.36 12.03 21.36H12.01Z", fill: themeEnrichedPrimaryColor })));
};
PremiumEconomy.defaultProps = defaultIconProps;
export default PremiumEconomy;
