import type React from 'react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useStorage from '../hooks/useStorage';
import type { UrlEncodingKey } from '../types/storage';
import { StorageKeys } from '../types/storage';

interface IProps {
  children: React.ReactElement | null;
}

export const UrlEncodingKeyProvider: React.FC<IProps> = ({ children }: IProps) => {
  const {
    data: urlEncodingKey,
    setData: setUrlEncodingKey,
    isFetching,
  } = useStorage<UrlEncodingKey>(StorageKeys.URL_ENCODING_KEY);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (!urlEncodingKey) {
      const newUrlEncodingKey = uuidv4();
      setUrlEncodingKey(newUrlEncodingKey);
    }
  }, [isFetching, setUrlEncodingKey, urlEncodingKey]);

  if (!urlEncodingKey) {
    return null;
  }

  return children;
};
