import { onApiTraceId } from 'obt-common';
import { DEBUG_TOOL_LINK_QUEUE_SIZE } from 'obt-common/api/events/onApiTraceId';

const initialTraceId = {
  traceId: '',
  /** @deprecated */
  debugToolLink: '',
  zipkinLink: '',
  debugToolLinkQueue: new Array(DEBUG_TOOL_LINK_QUEUE_SIZE).fill({ apiURL: '', didAPIErrorOut: false, link: '' }),
};

const ref = {
  current: initialTraceId,
};

/**
 * Use a global ref to store the last request trace info
 * never mind the component is unmounted or not
 */
onApiTraceId((event) => {
  ref.current = event;
});

const getTraceInfo = () => {
  const { current: lastReqTraceInfo } = ref;

  return { ...lastReqTraceInfo };
};

function useApiTraceInfo() {
  return { getTraceInfo };
}

export default useApiTraceInfo;
