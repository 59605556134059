import { TFunction, useTranslation } from 'react-i18next';
import { Persona } from 'obt-common/types/api/v2/obt/model/persona';

interface IProps extends Omit<IGetSubtextProps, 'tt'> {
  companyName?: string;
}

interface IGetSubtextProps {
  refPersonName?: string;
  persona?: Persona;
  relation?: string;
  tt: TFunction<'WEB'>;
}

export const getSubtext = ({ refPersonName, persona, relation, tt }: IGetSubtextProps): string => {
  if (persona === Persona.Employee) {
    return tt('Employee');
  }
  if (persona === Persona.Adhoc) {
    return tt('Guest');
  }
  if (persona === Persona.Guest) {
    return tt('Company Guest');
  }
  if (persona === Persona.Personal) {
    return tt('Personal');
  }

  return '';
};

const AutoCompleteSubtext = ({ refPersonName, persona, relation }: IProps) => {
  const { t: tt } = useTranslation('WEB');
  const subText = getSubtext({ refPersonName, persona, relation, tt });
  return subText ? <>{subText.toUpperCase()}</> : null;
};

export default AutoCompleteSubtext;
