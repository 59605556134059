const METERS_IN_MILE = 1609.344;
const KM_IN_MILE = 1.609344;

export function metersToMiles(meters: number): number {
  return meters / METERS_IN_MILE;
}

export function milesToMeters(miles: number): number {
  return miles * METERS_IN_MILE;
}

export function kmToMiles(km: number): number {
  return km / KM_IN_MILE;
}

export function milesToKm(miles: number): number {
  return miles * KM_IN_MILE;
}
