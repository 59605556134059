import type { Moment } from 'moment';
import moment from 'moment';
import { dateFormats, timeFormats } from '../constants/common';

const getDateTime = (date: string, time: string): Moment => {
  const newTime = moment(time).format(timeFormats.HR24);
  const dateTime = moment(`${date} ${newTime}`, dateFormats.LONG_DATE_REVERSE_HR24_TIME);
  return dateTime;
};

export default getDateTime;
