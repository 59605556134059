var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Taxi = (_a) => {
    var { primaryColor, width, height } = _a, rest = __rest(_a, ["primaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 14 13", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, rest),
        React.createElement("path", { d: "M9.61708 0.78172C9.58594 0.340965 9.23963 0 8.82295 0H5.17554C4.75885 0 4.41255 0.340965 4.38141 0.78172L4.33594 1.66653H9.6625L9.61708 0.78172Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M2.59092 5.84211L3.10975 3.2392H10.795L11.3139 5.84211H12.4456L11.8159 2.88379C11.7237 2.47211 11.3849 2.17542 10.9869 2.15625H3.01443C2.61767 2.17079 2.28195 2.47145 2.20099 2.88379L1.57129 5.84211H2.59092Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M13.2984 6.33496H0.717522C0.320142 6.34752 0.00310926 6.69113 0 7.11338V10.0565C0.00871996 10.4761 0.32263 10.8164 0.717522 10.8349H1.19587V12.2219C1.20459 12.6415 1.5185 12.9818 1.91339 13.0003H3.01396C3.41134 12.9878 3.72837 12.6442 3.73148 12.2219V10.8349H10.2689V12.2219C10.277 12.6415 10.5909 12.9818 10.9864 13.0003H12.0864C12.4837 12.9878 12.8014 12.6442 12.8039 12.2219V10.8349H13.2822C13.6796 10.8223 13.9973 10.4787 13.9998 10.0565V7.11338C14.0047 6.91118 13.9331 6.71558 13.8017 6.56888C13.6703 6.42284 13.4884 6.33892 13.2984 6.33496H13.2984ZM2.43957 9.58272C2.04468 9.58999 1.6853 9.34285 1.5296 8.95761C1.37389 8.57304 1.45299 8.12633 1.73015 7.82766C2.0067 7.52898 2.4265 7.43713 2.79212 7.59572C3.15773 7.75497 3.39629 8.13228 3.39629 8.55122C3.39629 9.11421 2.97025 9.57347 2.43959 9.58272H2.43957ZM11.5119 9.58272C11.117 9.58999 10.7576 9.34285 10.6019 8.95761C10.4462 8.57304 10.5253 8.12633 10.8025 7.82766C11.0797 7.52898 11.4988 7.43713 11.8645 7.59572C12.2301 7.75497 12.4686 8.13228 12.4686 8.55122C12.4711 8.82281 12.3721 9.08381 12.1927 9.27743C12.0127 9.4717 11.7679 9.5814 11.5119 9.58272H11.5119Z", fill: themeEnrichedPrimaryColor })));
};
Taxi.defaultProps = defaultIconProps;
export default Taxi;
