import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Traveler = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "evenodd", clipRule: "evenodd", d: "M8.00025 0.875122C5.58766 0.875122 3.63187 2.83091 3.63187 5.2435C3.63187 6.52007 4.17945 7.66875 5.05254 8.46746C2.61911 9.32675 0.875488 11.6472 0.875488 14.375C0.875488 14.7892 1.21127 15.125 1.62549 15.125C2.0397 15.125 2.37549 14.7892 2.37549 14.375C2.37549 11.7442 4.5079 9.61172 7.13836 9.61172H7.96312C7.97548 9.61182 7.98786 9.61187 8.00025 9.61187C8.01264 9.61187 8.02502 9.61182 8.03738 9.61172H8.86224L8.97796 9.6131C11.5551 9.67455 13.6255 11.783 13.6255 14.375C13.6255 14.7892 13.9613 15.125 14.3755 15.125C14.7897 15.125 15.1255 14.7892 15.1255 14.375C15.1255 11.6472 13.3817 9.32676 10.9481 8.46746C11.8211 7.66877 12.3686 6.52011 12.3686 5.2435C12.3686 2.83079 10.4131 0.875122 8.00025 0.875122ZM8.03034 8.11172C9.60087 8.09558 10.8686 6.81776 10.8686 5.2435C10.8686 3.65919 9.58464 2.37512 8.00025 2.37512C6.41609 2.37512 5.13187 3.65934 5.13187 5.2435C5.13187 6.81762 6.39986 8.09558 7.97016 8.11172H8.03034Z" })));
};
Traveler.defaultProps = defaultIconProps;
export default Traveler;
