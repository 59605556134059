import { color, compose, lineHeight, space, textAlign, verticalAlign, variant, fontWeight, } from 'styled-system';
import styled from 'styled-components';
import theme from '../utils/themes/theme';
const variationsTagsMaps = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    body: 'p',
    small: 'span',
    smaller: 'span',
    label: 'span',
    tinyLabel: 'span',
};
const Typography = styled.div.attrs(({ as, variation, color }) => ({
    as: as !== null && as !== void 0 ? as : variationsTagsMaps[variation],
    color: color !== null && color !== void 0 ? color : theme.colors.text.black,
}))({ margin: 0 }, ({ textTransform, whiteSpace }) => ({
    textTransform,
    whiteSpace,
}), variant({
    prop: 'variation',
    variants: {
        h1: { fontSize: 'h1', fontWeight: 'semiBold' },
        h2: { fontSize: 'h2', fontWeight: 'semiBold' },
        h3: { fontSize: 'h3', fontWeight: 'semiBold' },
        h4: { fontSize: 'h4', fontWeight: 'semiBold' },
        h5: { fontSize: 'h5', fontWeight: 'semiBold' },
        body: { fontSize: 'body', fontWeight: 'regular' },
        small: { fontSize: 'small', fontWeight: 'regular' },
        smaller: { fontSize: 'smaller', fontWeight: 'regular' },
        label: { fontSize: 'label', fontWeight: 'regular' },
        tinyLabel: { fontSize: 'tinyLabel', fontWeight: 'regular' },
    },
}), compose(space, color, textAlign, lineHeight, fontWeight, verticalAlign));
/**
 * @deprecated
 */
export default Typography;
