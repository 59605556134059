/* eslint-disable */
import type { DateTime } from '../../common/date_time';
import type { Passenger, RailThirdParty, SearchType } from './rail_common';
import type { PreSearchAnswers } from '../policy/user_defined_entity';

export interface RailSearchRequestCorporateInfo {
  preSearchAnswers?: PreSearchAnswers;
}

/**
 * Proto for Rail search request. This will return results for outward journey (origin to destination)
 * only.
 */
export interface RailSearchOutwardRequest {
  /** This is a list of passengers for future proofing. */
  passengers: Passenger[];
  searchType: SearchType;
  /** Outward journey definition (from origin to destination). */
  outwardJourney: RailSearchOutwardRequestJourneySearchDefinition;
  /** Inward journey definition (from destination to origin). */
  inwardJourney?: RailSearchOutwardRequestJourneySearchDefinition;
  /** This contains details about all the custom fields */
  corporateInfo?: RailSearchRequestCorporateInfo;
  /** The rail third party to be used for the search */
  thirdParty: RailThirdParty;
  /** Unique Id for the trip. */
  tripId: string;
}

/** Search parameters for journey. */
export interface RailSearchOutwardRequestJourneySearchDefinition {
  type?: RailSearchOutwardRequestJourneySearchDefinitionJourneySearchType;
  /** Date and time for search. */
  time: DateTime;
  /** Origin station for the journey. */
  origin: RailSearchOutwardRequestJourneySearchDefinitionLocation;
  /** Destination station of journey. */
  destination: RailSearchOutwardRequestJourneySearchDefinitionLocation;
  /** List of stations to be avoided */
  avoid?: RailSearchOutwardRequestJourneySearchDefinitionLocation[];
  /** List of stations to be go via */
  via?: RailSearchOutwardRequestJourneySearchDefinitionLocation[];
}

/**
 * Enum representing, if we need to show trains arriving before the time provided or after the
 * time provided.
 */
export enum RailSearchOutwardRequestJourneySearchDefinitionJourneySearchType {
  DEPARTS_AFTER = 0,
  ARRIVES_BEFORE = 1,
  UNRECOGNIZED = -1,
}

export interface RailSearchOutwardRequestJourneySearchDefinitionLocation {
  /** Code for station. */
  station?: string;
}

/** Search request for inward journey. */
export interface RailSearchInwardRequest {
  /** Search id of selected outward journey. */
  outwardSearchKey: string;
  /**
   * List of selected outward alternatives. One alternative from each section must
   * be selected.
   */
  outwardRateKeys: string[];
}

/** Request for next and previous page. */
export interface RailSearchNavigationRequest {
  /** Search key from search response. */
  searchKey: string;
}
