import { css, keyframes } from '@emotion/react';
import { themed } from '@spotnana/blocks/src/utils';

const load = keyframes`
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
`;

export const loading_bar = themed(
  ({ palette }) => css`
    width: 100%;
    height: 2px;
    opacity: 0.8;
    background-color: ${palette.bg.brand};
    animation: ${load} 1s linear infinite;
  `,
);

export const traveler_picker_loading = css`
  padding: 2rem;
  text-align: center;
  position: relative;
`;
