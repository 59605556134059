import { CardCompany } from '../types/api/v2/obt/model/card-company';

export enum VGSFormErrorMessages {
  IS_REQUIRED = 'IS_REQUIRED',
  INVALID_EXPIRATION_DATE = 'INVALID_EXPIRATION_DATE',
  INVALID_CARD_STATE_CODE = 'INVALID_CARD_STATE_CODE',
  INVALID_CARD_NUMBER = 'INVALID_CARD_NUMBER',
  INVALID_SECURITY_CODE = 'INVALID_SECURITY_CODE',
  INVALID_ZIP_POSTAL_CODE = 'INVALID_ZIP_POSTAL_CODE',
  VALUE_DOESNT_MATCH_PATTERN = 'VALUE_DOESNT_MATCH_PATTERN',
  INVALID_SSN = 'INVALID_SSN',
  FAILED_COMPARISON = 'FAILED_COMPARISON',
}

// https://www.verygoodsecurity.com/docs/vgs-collect/js/integration#available-validations-types
export const vgsFormErrorTranslationKeys = {
  'is required': VGSFormErrorMessages.IS_REQUIRED,
  'is not a valid expiration date': VGSFormErrorMessages.INVALID_EXPIRATION_DATE,
  'is not a valid card number': VGSFormErrorMessages.INVALID_CARD_NUMBER,
  'is not a valid security code': VGSFormErrorMessages.INVALID_SECURITY_CODE,
  'is not a valid US zip/postal code': VGSFormErrorMessages.INVALID_ZIP_POSTAL_CODE,
  "the value doesn't match a pattern": VGSFormErrorMessages.VALUE_DOESNT_MATCH_PATTERN,
  'is not a valid ssn': VGSFormErrorMessages.INVALID_SSN,
  'comparison failed': VGSFormErrorMessages.FAILED_COMPARISON,
} as const;

export const VgsCardCompanyTypeV2 = {
  visa: CardCompany.Visa,
  dinersclub: CardCompany.DinersClub,
  jcb: CardCompany.Jcb,
  mastercard: CardCompany.Mastercard,
  amex: CardCompany.Amex,
  discover: CardCompany.Discover,
  none: CardCompany.None,
  uatp: CardCompany.AirTravelUatp,
  unionpay: CardCompany.UnionPay,
} as const;

export const VgsCardCompanyTypeV2Key = Object.keys(VgsCardCompanyTypeV2).reduce((acc, key) => {
  const typedKey = key as keyof typeof VgsCardCompanyTypeV2;
  acc[typedKey] = typedKey;
  return acc;
}, {} as Record<keyof typeof VgsCardCompanyTypeV2, keyof typeof VgsCardCompanyTypeV2>);
