import { PropsWithChildren } from 'react';
import useEmbedStore from './useEmbedStore';

type IfEmbedProps = { not?: boolean };

const IfEmbed = ({ not = false, children }: PropsWithChildren<IfEmbedProps>): JSX.Element => {
  const { isEmbed } = useEmbedStore();
  return <>{Boolean(isEmbed) === !not ? children : undefined}</>;
};

export default IfEmbed;
