var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Eye = (_a) => {
    var { primaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M10 13.1658C10.8764 13.1658 11.6215 12.859 12.2352 12.2452C12.849 11.6315 13.1558 10.8864 13.1558 10.01C13.1558 9.13357 12.849 8.38849 12.2352 7.77475C11.6215 7.16102 10.8764 6.85415 10 6.85415C9.12358 6.85415 8.3785 7.16102 7.76476 7.77475C7.15103 8.38849 6.84416 9.13357 6.84416 10.01C6.84416 10.8864 7.15103 11.6315 7.76476 12.2452C8.3785 12.859 9.12358 13.1658 10 13.1658ZM10 15.42C8.20929 15.42 6.58117 14.9286 5.11566 13.9458C3.80801 13.0679 2.80954 11.9283 2.12025 10.5269C1.95992 10.201 1.95992 9.81901 2.12025 9.49303C2.80954 8.09166 3.80801 6.95205 5.11566 6.0742C6.58117 5.09139 8.20929 4.59998 10 4.59998C11.7907 4.59998 13.4188 5.09139 14.8843 6.0742C16.192 6.95205 17.1905 8.09166 17.8797 9.49303C18.0401 9.81901 18.0401 10.201 17.8797 10.5269C17.1905 11.9283 16.192 13.0679 14.8843 13.9458C13.4188 14.9286 11.7907 15.42 10 15.42ZM10 14.0675C11.4024 14.0675 12.6984 13.7044 13.888 12.9783C15.0782 12.2515 15.9925 11.2621 16.6309 10.01C15.9925 8.75787 15.0782 7.76844 13.888 7.04169C12.6984 6.31555 11.4024 5.95248 10 5.95248C8.5976 5.95248 7.30161 6.31555 6.112 7.04169C4.9218 7.76844 4.00751 8.75787 3.36913 10.01C4.00751 11.2621 4.9218 12.2515 6.112 12.9783C7.30161 13.7044 8.5976 14.0675 10 14.0675Z", fill: themeEnrichedPrimaryColor })));
};
Eye.defaultProps = defaultIconProps;
export default Eye;
