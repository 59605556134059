/* eslint-disable */

export enum PaymentMethodEnum {
  PAYMENT_METHOD_UNKNOWN = 0,
  CREDIT_CARD = 1,
  BREX_POINTS = 2,
  CASH = 3,
  QANTAS_POINTS = 4,
  VENDOR_PROGRAM_PAYMENT = 5,
  DELAYED_INVOICING = 7,
  FLIGHT_CREDITS = 8,
  QANTAS_TRAVEL_FUND = 9,
}

/** The fare component eligible for these debit rules. */
export enum FareComponent {
  BASE = 1,
  TAX = 2,
}

export enum PaymentMethodEnumForTelemetry {
  PAYMENT_METHOD_UNKNOWN = 0,
  CREDIT_CARD = 1,
  REWARDS_POINTS = 2,
  CASH = 3,
  QANTAS_POINTS = 4,
  VENDOR_PROGRAM_PAYMENT = 5,
  AMADEUS_CHECKOUT = 6,
  DELAYED_INVOICING = 7,
  FLIGHT_CREDITS = 8,
  QANTAS_TRAVEL_FUND = 9,
}

export type PaymentMethod = 0 | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9;

/** Represents an amount of money with its currency type. */
export interface Money {
  /** The 3-letter currency code defined in ISO 4217. */
  currencyCode: string;
  /** Amount */
  amount: number;
  /**
   * Sometimes, the caller asks the callee to send money in a specified currency, for eg frontend
   * asking the backend to send money in the user preferred currency. These fields hold the
   * converted currency and amount.
   */
  convertedCurrency?: string;
  convertedAmount?: number;
  otherCoinage?: MoneyOtherCoinage[];
}

/**
 * The caller can ask the callee to send the money in other first class
 * payment citizens that are not currency codes. For example- these can be
 * cryptocurrency, reward points of various Partner TMCs etc.
 */
export interface MoneyOtherCoinage {
  /** Unique identifier for the coinage system. */
  coinageCode: PaymentMethod;
  /**
   * The amount in the particular coinage system with respect to the
   * conversion rate of default currency.
   */
  amount: number;
  /** The conversion rate from this coinage system to default currency value. */
  conversionRate: number;
  /**
   * The conversion rate from this coinage system to the preferred currency
   * value.
   */
  preferredCurrencyConversionRate: number;
}
