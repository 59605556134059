import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Walk = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 11 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7.21245 1.72543C7.21245 2.45128 6.62413 3.0396 5.89828 3.0396C5.17242 3.0396 4.58398 2.45128 4.58398 1.72543C4.58398 0.999572 5.17242 0.411133 5.89828 0.411133C6.62413 0.411133 7.21245 0.999572 7.21245 1.72543Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M9.99118 7.38076C9.626 7.31762 8.16397 7.06427 8.08243 7.03715C7.98898 7.00603 8.08243 7.03715 8.05132 6.99047C8.02043 6.9438 6.74488 4.25311 6.6204 4.11311C6.49606 3.97309 6.15379 3.61528 5.67175 3.45981C5.18959 3.30435 4.70732 3.55304 4.70732 3.55304C4.70732 3.55304 2.31208 4.92184 2.15664 4.99952C2.00096 5.0773 1.86117 5.35734 1.86117 5.35734L0.742988 7.45946C0.65135 7.57484 0.596273 7.72053 0.596273 7.8795C0.596273 8.25311 0.899243 8.55605 1.27282 8.55605C1.59225 8.55605 1.85934 8.33428 1.9303 8.03677L2.9732 6.065L3.62649 5.69958C3.57982 5.8396 2.98105 8.75573 2.98105 8.75573L2.37444 12.2087L0.248913 14.7696C0.0960682 14.912 0 15.1146 0 15.3399C0 15.7708 0.349424 16.1203 0.780244 16.1203C1.04267 16.1203 1.27444 15.9901 1.41581 15.7914C1.8052 15.3336 3.76389 13.0314 3.95296 12.8073C4.16293 12.5585 4.21732 12.4342 4.24843 12.2709C4.27955 12.1075 4.62951 10.0001 4.62951 10.0001L6.63582 12.0066V15.5447H6.63991C6.68034 15.9408 7.01486 16.2498 7.42152 16.2498C7.82804 16.2498 8.16258 15.9408 8.20278 15.5447H8.20687C8.20687 15.5447 8.20676 15.5196 8.20642 15.4761C8.20642 15.4722 8.20687 15.4682 8.20687 15.4642C8.20687 15.4592 8.20619 15.4541 8.20619 15.4489C8.20244 14.9097 8.18586 12.2253 8.20687 11.6174C8.23015 10.9409 7.98884 10.7932 7.93456 10.731C7.88006 10.6688 6.00593 8.67803 6.00593 8.67803L6.41791 6.67957C6.41791 6.67957 6.95469 7.72927 7.00897 7.84601C7.06347 7.96252 7.2346 8.1727 7.41322 8.21143C7.5923 8.25037 9.82424 8.7014 9.82424 8.7014L9.82526 8.69664C9.85797 8.70152 9.89124 8.70674 9.92542 8.70674C10.2934 8.70674 10.5918 8.40854 10.5918 8.04039C10.5919 7.69484 10.328 7.41437 9.99118 7.38076L9.99118 7.38076Z", fill: themeEnrichedPrimaryColor })));
};
Walk.defaultProps = defaultIconProps;
export default Walk;
