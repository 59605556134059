import { useHistory } from 'react-router-dom';
import useEmbedStore from 'src/components/EmbedContext/useEmbedStore';
import useLatest from 'ahooks/lib/useLatest';
import { useEffect } from 'react';
import { UserNavigationEventTypes, UserNavigationRequestEvent } from '../../components/EmbedContext/types';

/**
 * Checks if event is a user navigation event.
 * @param event
 * @returns
 */
function isUserNavigationEvent(event: any): event is UserNavigationRequestEvent {
  return event?.type === UserNavigationEventTypes.USER_NAVIGATION_REQUEST && event?.payload?.pathname;
}

/**
 * Custom React hook that listens for user navigation events in an embedded context.
 */
export function useEmbedNavigationListener() {
  const { isEmbed } = useEmbedStore();
  const history = useHistory();

  const listener = (event: MessageEvent) => {
    const { data: eventData } = event;
    if (isUserNavigationEvent(eventData)) {
      const { pathname, search = '' } = eventData.payload;
      history.push({ pathname, search });
    }
  };

  const listenerRef = useLatest(listener);

  useEffect(() => {
    const callback = (event: MessageEvent) => listenerRef.current(event);
    if (isEmbed) {
      window.addEventListener('message', callback);
    }

    // remove listener on unmount
    return () => window.removeEventListener('message', callback);
  }, [isEmbed, listenerRef]);
}
