import {
  $isTextNode,
  $createParagraphNode,
  ElementNode,
  DOMConversion,
  DOMConversionMap,
  DOMConversionOutput,
  NodeKey,
  TextNode,
  SerializedTextNode,
} from 'lexical';

export class ExtendedTextNode extends TextNode {
  // eslint-disable-next-line no-useless-constructor
  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  /* istanbul ignore next: jsdom does not support clipboard events, unable to test */
  isParentRequired(): boolean {
    return true;
  }

  /* istanbul ignore next: jsdom does not support clipboard events, unable to test */
  createParentElementNode(): ElementNode {
    return $createParagraphNode();
  }

  static getType(): string {
    return 'extended-text';
  }

  /* istanbul ignore next: jsdom does not support clipboard events, unable to test */
  static clone(node: ExtendedTextNode): ExtendedTextNode {
    // eslint-disable-next-line no-underscore-dangle
    return new ExtendedTextNode(node.__text, node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM();
    return {
      ...importers,
      code: () => ({
        conversion: patchStyleConversion(importers?.code),
        priority: 1,
      }),
      em: () => ({
        conversion: patchStyleConversion(importers?.em),
        priority: 1,
      }),
      i: () => ({
        conversion: patchStyleConversion(importers?.i),
        priority: 1,
      }),
      s: () => ({
        conversion: patchStyleConversion(importers?.s),
        priority: 1,
      }),
      span: () => ({
        conversion: patchStyleConversion(importers?.span),
        priority: 1,
      }),
      strong: () => ({
        conversion: patchStyleConversion(importers?.strong),
        priority: 1,
      }),
      sub: () => ({
        conversion: patchStyleConversion(importers?.sub),
        priority: 1,
      }),
      sup: () => ({
        conversion: patchStyleConversion(importers?.sup),
        priority: 1,
      }),
      u: () => ({
        conversion: patchStyleConversion(importers?.u),
        priority: 1,
      }),
    };
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return TextNode.importJSON(serializedNode);
  }

  exportJSON(): SerializedTextNode {
    return super.exportJSON();
  }
}

function patchStyleConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    const original = originalDOMConverter?.(node);
    if (!original) {
      return null;
    }
    const originalOutput = original.conversion(node);

    if (!originalOutput) {
      return originalOutput;
    }

    const { color, backgroundColor, fontSize, fontFamily, textDecoration } = node.style ?? {};

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? ((x) => x);
        const result = originalForChild(lexicalNode, parent);
        if ($isTextNode(result)) {
          const style = [
            backgroundColor ? `background-color: ${backgroundColor}` : null,
            color ? `color: ${color}` : null,
            fontSize ? `font-size: ${fontSize}` : null,
            fontFamily ? `font-family: ${fontFamily}` : null,
            textDecoration ? `text-decoration: ${textDecoration}` : null,
          ]
            .filter((value) => value != null)
            .join('; ');
          if (style.length) {
            return result.setStyle(style);
          }
        }
        return result;
      },
    };
  };
}
