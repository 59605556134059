import Box, { Flex, BoxProps } from '@spotnana/pixel-react/dist/Box';
import Close from '@spotnana/pixel-react/dist/Icons/Close';
import Ellipsis from '@spotnana/pixel-react/dist/Ellipsis';
import Personal from '@spotnana/pixel-react/dist/Icons/Personal';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { ImageV1, Image, usePartnerFeatures } from 'obt-common';
import { useTranslation } from 'react-i18next';
import useIsAddingToEmptyTrip from '../../../hooks/useIsAddingToEmptyTrip';
import UserAvatar from '../UserAvatar';

interface GuestTravelerChipProps {
  loading: boolean;
  isSeat1a?: boolean;
  profilePicture?: ImageV1 | Image;
  registrarTraveler: string;
  isNotTripsPage?: boolean;
  onClear?: () => void;
  disabled?: boolean;
  showCloseIcon?: boolean;
  containerStyles?: Omit<BoxProps, 'theme' | 'color'>;
}

const { colors } = spotnanaTheme;

const GuestTravelerChip = ({
  loading,
  registrarTraveler,
  profilePicture,
  onClear,
  containerStyles,
  isNotTripsPage = true,
  disabled = false,
  isSeat1a = false,
  showCloseIcon = true,
}: GuestTravelerChipProps) => {
  const { t: tt } = useTranslation('WEB');
  const { features } = usePartnerFeatures();
  const { travelerSelection: isTravelerSelectionEnabled } = features;
  const { isAddingToEmptyTrip, isTripDataLoading, tripId } = useIsAddingToEmptyTrip();
  const showCloseButtonForGuestTraveler = isAddingToEmptyTrip || !tripId || isTravelerSelectionEnabled;

  return (
    <Flex
      p={10}
      border={disabled ? 'none' : 'thinSolid'}
      borderColor={disabled ? '' : 'border.lightGray2'}
      borderRadius="md"
      alignItems="center"
      justifyContent="space-between"
      data-testid="guest_selector_search_form"
    >
      <Flex alignItems="center">
        <Box
          borderRadius="circle"
          flex="0 0 auto"
          bg="bg.lightBlueGrey"
          border="1px solid"
          borderColor={spotnanaTheme.colors.border.darkGrey}
        >
          <Personal height="28" width="30" primaryColor={spotnanaTheme.colors.label} />
        </Box>
        <Box px="8px">
          <Typography variation="small">{tt('Guest Traveler')}</Typography>
          <Flex alignItems="center">
            {isNotTripsPage && (
              <Box marginRight="4px">
                <UserAvatar
                  isSeat1a={isSeat1a}
                  size="extraSmall"
                  title={loading ? '' : registrarTraveler}
                  imageSrc={loading ? '' : profilePicture?.url}
                />
              </Box>
            )}
            <Ellipsis title={registrarTraveler} dataTestId="traveler_name">
              <Typography variation="label" color={colors.text.blockedGrey}>
                {loading ? tt('Fetching') : registrarTraveler}
              </Typography>
            </Ellipsis>
          </Flex>
        </Box>
      </Flex>
      {!disabled && showCloseIcon && showCloseButtonForGuestTraveler && !isTripDataLoading && (
        <Box
          cursor="pointer"
          role="button"
          aria-label={tt('Remove traveler')}
          data-testid="remove-guest-traveler"
          onClick={onClear}
          ml="4px"
          p="8px"
          {...containerStyles}
        >
          <Close width={12} height={12} primaryColor={colors.border.regular} />
        </Box>
      )}
    </Flex>
  );
};

export default GuestTravelerChip;
