import { css } from '@emotion/react';
import { flex, margins, paddings, unit } from '@spotnana/blocks/src/utils';
import { themed } from '@spotnana/blocks/src/utils/styling/themed';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const traveler_picker_option_container = themed(
  (theme) => css`
    display: flex;
    gap: ${unit(1)};
    ${paddings.x.p1}
    ${paddings.y.p1}
    cursor: pointer;

    &:hover {
      background-color: ${theme.palette.surface.background};
    }
  `,
);

export const traveler_avatar = css`
  display: flex;
  box-sizing: 'border-box';
  width: 1.5rem;
  justify-content: center;
  ${margins.yt.point25}
`;

export const traveler_picker_option_description_container = css`
  ${flex.init}
  ${flex.column}
  ${spacings.smallest}
`;
