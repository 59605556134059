import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const RailTravelCard = ({ primaryColor = '#687591', width = 0, height = 0 }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("mask", { id: "path-1-inside-1", fill: "white" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.8057 10.25C17.2317 12.929 14.8504 14.9375 12 14.9375C9.14961 14.9375 6.76834 12.929 6.19431 10.25H17.8057ZM17.8057 7.75H6.19431C6.76834 5.07104 9.14961 3.0625 12 3.0625C14.8504 3.0625 17.2317 5.07104 17.8057 7.75ZM20.6614 7.75C20.0549 3.50963 16.4081 0.25 12 0.25C7.59191 0.25 3.94515 3.50963 3.33861 7.75H0.75V10.25H3.33861C3.94515 14.4904 7.59191 17.75 12 17.75C16.4081 17.75 20.0549 14.4904 20.6614 10.25H23.25V7.75H20.6614Z" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.8057 10.25C17.2317 12.929 14.8504 14.9375 12 14.9375C9.14961 14.9375 6.76834 12.929 6.19431 10.25H17.8057ZM17.8057 7.75H6.19431C6.76834 5.07104 9.14961 3.0625 12 3.0625C14.8504 3.0625 17.2317 5.07104 17.8057 7.75ZM20.6614 7.75C20.0549 3.50963 16.4081 0.25 12 0.25C7.59191 0.25 3.94515 3.50963 3.33861 7.75H0.75V10.25H3.33861C3.94515 14.4904 7.59191 17.75 12 17.75C16.4081 17.75 20.0549 14.4904 20.6614 10.25H23.25V7.75H20.6614Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M17.8057 10.25L18.0013 10.2919L18.0531 10.05H17.8057V10.25ZM6.19431 10.25V10.05H5.94692L5.99875 10.2919L6.19431 10.25ZM6.19431 7.75L5.99875 7.7081L5.94692 7.95H6.19431V7.75ZM17.8057 7.75V7.95H18.0531L18.0013 7.7081L17.8057 7.75ZM20.6614 7.75L20.4634 7.77832L20.488 7.95H20.6614V7.75ZM3.33861 7.75V7.95H3.51204L3.5366 7.77832L3.33861 7.75ZM0.75 7.75V7.55H0.55V7.75H0.75ZM0.75 10.25H0.55V10.45H0.75V10.25ZM3.33861 10.25L3.5366 10.2217L3.51204 10.05H3.33861V10.25ZM20.6614 10.25V10.05H20.488L20.4634 10.2217L20.6614 10.25ZM23.25 10.25V10.45H23.45V10.25H23.25ZM23.25 7.75H23.45V7.55H23.25V7.75ZM12 15.1375C14.9466 15.1375 17.4079 13.0612 18.0013 10.2919L17.6101 10.2081C17.0554 12.7968 14.7541 14.7375 12 14.7375V15.1375ZM5.99875 10.2919C6.59213 13.0612 9.05336 15.1375 12 15.1375V14.7375C9.24587 14.7375 6.94455 12.7968 6.38987 10.2081L5.99875 10.2919ZM17.8057 10.05H6.19431V10.45H17.8057V10.05ZM6.19431 7.95H17.8057V7.55H6.19431V7.95ZM12 2.8625C9.05336 2.8625 6.59213 4.93884 5.99875 7.7081L6.38987 7.7919C6.94455 5.20324 9.24587 3.2625 12 3.2625V2.8625ZM18.0013 7.7081C17.4079 4.93884 14.9466 2.8625 12 2.8625V3.2625C14.7541 3.2625 17.0554 5.20324 17.6101 7.7919L18.0013 7.7081ZM20.8594 7.72168C20.2389 3.3842 16.509 0.05 12 0.05V0.45C16.3072 0.45 19.8708 3.63506 20.4634 7.77832L20.8594 7.72168ZM12 0.05C7.49098 0.05 3.76105 3.3842 3.14063 7.72168L3.5366 7.77832C4.12924 3.63506 7.69284 0.45 12 0.45V0.05ZM0.75 7.95H3.33861V7.55H0.75V7.95ZM0.95 10.25V7.75H0.55V10.25H0.95ZM3.33861 10.05H0.75V10.45H3.33861V10.05ZM3.14063 10.2783C3.76105 14.6158 7.49098 17.95 12 17.95V17.55C7.69284 17.55 4.12924 14.3649 3.5366 10.2217L3.14063 10.2783ZM12 17.95C16.509 17.95 20.2389 14.6158 20.8594 10.2783L20.4634 10.2217C19.8708 14.3649 16.3072 17.55 12 17.55V17.95ZM23.25 10.05H20.6614V10.45H23.25V10.05ZM23.05 7.75V10.25H23.45V7.75H23.05ZM20.6614 7.95H23.25V7.55H20.6614V7.95Z", fill: "white", mask: "url(#path-1-inside-1)" })));
};
RailTravelCard.defaultProps = defaultIconProps;
export default RailTravelCard;
