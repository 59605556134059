import { useState, useLayoutEffect, useRef } from 'react';

import { onTmcIdChange } from './eventemitter';
import { removeTmcId } from './removeTmcId';
import { setTmcId } from './setTmcId';
import { getTmcId } from './getTmcId';

interface Return {
  tmcId: string | undefined;
  setTmcId: (tmcId: string) => Promise<void>;
  removeTmcId: () => Promise<void>;
  isTmcIdLoading: boolean;
}

export function useTmcId(): Return {
  const hasChangedRef = useRef(false);
  const [isTmcIdLoading, setIsTmcIdLoading] = useState(true);
  const [tmcId, setLocalTmcId] = useState<string | undefined>();

  useLayoutEffect(() => {
    const unsubscribe = onTmcIdChange((newTmcId) => {
      setLocalTmcId(newTmcId);
      hasChangedRef.current = true;
    });
    return unsubscribe;
  }, []);

  useLayoutEffect(() => {
    getTmcId()
      .then((fetchedTmcId) => {
        if (!hasChangedRef.current) {
          // If onTmcIdChange is called before this promise callback,
          // don't overwrite local state.
          setLocalTmcId(fetchedTmcId || undefined);
        }
      })
      .finally(() => {
        setIsTmcIdLoading(false);
      });
  }, []);

  return {
    tmcId,
    setTmcId,
    removeTmcId,
    isTmcIdLoading,
  };
}
