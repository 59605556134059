import type { IAuthenticatedUserBasicInfo } from 'obt-common';
import { IncompleteUserActionName } from 'obt-common/types/userActions';

/**
 * Returns boolean flag if user has incomplete user action or not.
 *
 * @param userBasicInfo - the user info
 * @param action - the action to check
 * @returns boolean flag.
 */
export const hasIncompleteUserAction = (userBasicInfo: IAuthenticatedUserBasicInfo, action: IncompleteUserActionName) =>
  !!userBasicInfo.existingUser?.incompleteUserActions?.some((a) => a.actionName === action);
