let listeners: ((orgId: string | undefined) => void)[] = [];

export const onOrgIdChange = (callback: (orgId: string | undefined) => void) => {
  listeners.push(callback);

  return () => {
    listeners = listeners.filter((listener) => listener !== callback);
  };
};

export const emitOrgIdChange = (orgId: string | undefined): void => {
  listeners.forEach((listener) => listener(orgId));
};
