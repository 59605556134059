import { tryCatchExp } from '@spotnana/blocks/src/utils';
import { IUsePartnerFlags, useFeatureFlag } from 'obt-common';
import { useMemo } from 'react';

const usePartnerFlags = (): IUsePartnerFlags => {
  const partnerFlagsConfig = useFeatureFlag('FE_INFRA_PARTNER_FLAG');

  const parsedPartnerFlagsConfig = useMemo(
    () =>
      tryCatchExp<typeof partnerFlagsConfig>(
        () => (typeof partnerFlagsConfig === 'string' ? JSON.parse(partnerFlagsConfig) : partnerFlagsConfig),
        (e) => partnerFlagsConfig,
      ),
    [partnerFlagsConfig],
  );

  if (!parsedPartnerFlagsConfig) {
    return {
      isUserProfilePersonalDisabled: false,
      isUserProfileWorkDisabled: false,
      isCompanyLegalEntitiesDisabled: false,
      isCompanyLegalEntitiesDeleteDisabled: false,
      isCompanyLegalEntitiesNameReadOnly: false,
      isCompanyLegalEntitiesAddDisabled: false,
      isCompanyServiceFeeConfigDisabled: false,
      isCompanyDepartmentsDisabled: false,
      isCompanyOfficesDisabled: false,
      isCompanyCostCentersDisabled: false,
      isCompanyUserUploadDisabled: false,
      isCompanyTravelerDeleteDisabled: false,
      isCompanyTravelerEmailReadOnly: false,
      isCompanyTravelerAddDisabled: false,
      isCompanyAuthenticationDisabled: false,
      isCompanyGeneralDisabled: false,
      isCompanySiteMessagingDisabled: false,
      isCompanyIntegrationsDisabled: false,
      isCentralisedPaymentsDisabled: false,
      isAnalyticsDisabled: false,
      isProgramUsersDisabled: false,
      isUsersRoleDisabled: false,
    };
  }

  return parsedPartnerFlagsConfig;
};

export default usePartnerFlags;
