import { createContext, useContext } from 'react';

type TModalContext = {
  insideModal: boolean;
};

const ModalContext = createContext<TModalContext | undefined>(undefined);

export const ModalContextProvider = ModalContext.Provider;

/**
 * Hook to check if the component is rendering inside Modal
 * @returns
 */
export const useIsInsideModal = () => {
  const modalCtx = useContext(ModalContext);
  return !!modalCtx?.insideModal;
};
