import { Typography } from '@spotnana/blocks/src/Typography';
import { useTranslation } from 'react-i18next';
import { loading_bar, traveler_picker_loading } from './styles';

const TravelerPickerLoader = () => {
  const { t: tt } = useTranslation('WEB');
  return (
    <div>
      <div css={traveler_picker_loading}>
        <Typography variant="body1" kind="semibold">
          {tt('Loading...')}
        </Typography>
      </div>
      <div css={loading_bar} />
    </div>
  );
};

export default TravelerPickerLoader;
