import { useRef, useLayoutEffect } from 'react';
import injectAuthorizationHeader from '../../api/interceptors/injectAuthorizationHeader';

function useInjectAuthHeader(getAuthToken: () => Promise<string>): void {
  const apiRef = useRef({ getAuthToken });

  apiRef.current.getAuthToken = getAuthToken;

  useLayoutEffect(() => {
    const unsubscribe = injectAuthorizationHeader(() => apiRef.current.getAuthToken());

    return () => {
      unsubscribe();
    };
  }, []);
}

export default useInjectAuthHeader;
