import { css } from '@emotion/react';
import { breakpoints, flex, margins, themed } from '@spotnana/blocks/src/utils/styling';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const success_icon = themed(
  ({ palette }) => css`
    color: ${palette.icon.success};
  `,
);
export const error_icon = themed(
  ({ palette }) => css`
    color: ${palette.icon.error};
  `,
);
export const utas_container = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.large}
  ${margins.yt.m1}
`;
export const uta_item = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.small}
`;

export const date_container = css`
  ${flex.init}
  ${flex.align.center}
  ${flex.justify.between}
  ${margins.yb.half}
`;

export const flight_info_container = css`
  flex-grow: 1;
  ${margins.xl.m1}

  ${breakpoints.from('tablet')} {
    ${margins.xl.m2}
  }
`;
