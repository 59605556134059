var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const BarChart = (_a) => {
    var { primaryColor, width, height, secondaryColor } = _a, restProps = __rest(_a, ["primaryColor", "width", "height", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: width, height: height, fill: "none", viewBox: "0 0 40 40" }, restProps),
        React.createElement("rect", { width: width, height: height, fill: "transparent", rx: "8" }),
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M10.4 18.909c0-.603.438-1.091.98-1.091.54 0 .98.488.98 1.09v9.6c0 .603-.44 1.092-.98 1.092-.542 0-.98-.489-.98-1.091v-9.6zM14.71 23.272c0-.602.439-1.09.98-1.09.54 0 .98.488.98 1.09v5.237c0 .602-.44 1.09-.98 1.09-.541 0-.98-.488-.98-1.09v-5.237zM27.64 25.454c0-.602.44-1.09.98-1.09.541 0 .98.488.98 1.09v3.055c0 .602-.439 1.09-.98 1.09-.54 0-.98-.488-.98-1.09v-3.055zM19.02 11.49c0-.602.439-1.09.98-1.09.54 0 .98.488.98 1.09V28.51c0 .602-.44 1.09-.98 1.09-.541 0-.98-.488-.98-1.09V11.49zM23.33 16.29c0-.602.44-1.09.98-1.09.541 0 .98.488.98 1.09V28.51c0 .602-.439 1.09-.98 1.09-.54 0-.98-.488-.98-1.09V16.29z" })));
};
BarChart.defaultProps = defaultIconProps;
export default BarChart;
