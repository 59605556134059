import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const WalkingTraveler = ({ primaryColor, secondaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    const themeEnrichedSecondaryColor = get(theme, `colors.${secondaryColor}`, secondaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { cx: "16", cy: "16", r: "15.5", fill: "white", stroke: themeEnrichedSecondaryColor }),
        React.createElement("path", { d: "M17.4997 9.66827C18.2821 9.66827 18.9164 9.03369 18.9164 8.25089C18.9164 7.46808 18.2821 6.8335 17.4997 6.8335C16.7173 6.8335 16.083 7.46808 16.083 8.25089C16.083 9.03369 16.7173 9.66827 17.4997 9.66827Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M15.3737 15.8328L15.1237 16.3342C15.1237 16.3759 15.082 16.3759 15.082 16.4177L15.3737 15.8328Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M21.1242 20.4162C21.0825 19.9996 20.9159 19.5829 20.6659 19.208L18.6659 16.8747V15.8748L19.8325 17.1664C20.0409 17.4164 20.4159 17.4164 20.6659 17.208L20.7075 17.1664C20.9575 16.9581 20.9575 16.5831 20.7492 16.3331L18.7075 14.0832V11C18.7075 10.5416 18.3325 10.125 17.8325 10.125H17.1659C16.8742 10.125 16.5825 10.2917 16.4575 10.5C16.4159 10.5416 16.3742 10.5833 16.3325 10.6666L13.9992 15.8331C13.8742 16.1248 13.9992 16.4581 14.2909 16.6247C13.9575 17.3747 13.5409 18.208 13.4992 18.208C13.4992 18.208 13.2075 18.083 12.9159 17.958L13.2075 17.3747C13.2909 17.208 13.2075 17.0414 13.0825 16.9581C12.9159 16.8747 12.7492 16.9581 12.7075 17.083L12.3742 17.6664V17.708C12.1242 17.583 11.9575 17.4997 11.9575 17.4997C11.5409 17.2914 11.0825 17.4997 10.8742 17.9163L8.58255 22.7911C8.37422 23.2078 8.58255 23.7078 8.95755 23.8744L10.4159 24.4994C10.4575 24.4994 10.4992 24.5411 10.5409 24.5411C10.6242 24.7911 10.8325 24.9577 11.1242 24.9577C11.4575 24.9577 11.7075 24.7077 11.7075 24.3744C11.7075 24.2078 11.6659 24.0828 11.5409 23.9994L15.0825 16.4581L15.3742 15.8748L16.2909 13.8332V16.4164V16.4581L12.9575 23.7911C12.7909 24.2078 12.9575 24.6661 13.3742 24.8744L13.4992 24.9161C13.9159 25.0827 14.3742 24.9161 14.5825 24.4994L17.4575 18.208L19.0409 20.1663C19.2909 20.5412 19.3325 20.7079 19.4159 21.1245L19.3742 24.1244C19.4159 24.6244 19.8325 24.9994 20.2909 24.9994C20.8325 24.9994 21.2909 24.4994 21.2075 23.9578L21.1242 20.4162ZM13.0825 19.0413L10.9159 23.7078C10.8325 23.8744 10.6659 23.9161 10.5409 23.8744L9.41588 23.3328C9.29088 23.2495 9.20755 23.0828 9.29088 22.9578L11.4159 18.2913C11.4992 18.1663 11.6659 18.083 11.7909 18.1663L12.9159 18.708C13.0825 18.7496 13.1242 18.9163 13.0825 19.0413Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M10.2497 23.3751C10.208 23.3751 10.1663 23.3751 10.1247 23.3334C9.95801 23.2501 9.91634 23.0834 9.99967 22.9584L11.833 18.9586C11.9164 18.792 12.083 18.7503 12.208 18.8337C12.3747 18.917 12.4164 19.0836 12.333 19.2086L10.4997 23.2084C10.458 23.2917 10.3747 23.3751 10.2497 23.3751Z", fill: themeEnrichedPrimaryColor })));
};
WalkingTraveler.defaultProps = defaultIconProps;
export default WalkingTraveler;
