import { makeBlocksTheme } from '@spotnana/blocks/src/BlocksThemeProvider/blocksTheme';
import {
  TUxColorSpecKeys,
  darkColorPalette,
  lightColorPalette,
} from '@spotnana/blocks/src/BlocksThemeProvider/ux-specs/color';
import { fontFamily as defaultFontFamily } from '@spotnana/blocks/src/BlocksThemeProvider/ux-specs/typography';
import { WhiteLabelConfig } from 'obt-common/types/api/v2/obt/model/white-label-config';

interface GenerateBlocksThemeProps {
  isLoading: boolean;
  whiteLabelConfig: WhiteLabelConfig | undefined;
}

export const generateBlocksTheme = ({ isLoading, whiteLabelConfig }: GenerateBlocksThemeProps) => {
  const fontFamily = [whiteLabelConfig?.fontFamily].concat(defaultFontFamily).filter(Boolean) as string[];

  const lightColorPaletteWithOverrides: TUxColorSpecKeys = isLoading
    ? lightColorPalette
    : {
        ...lightColorPalette,
        link: whiteLabelConfig?.colors?.secondaryDark || lightColorPalette.link,
        linkHover: whiteLabelConfig?.colors?.secondaryDark || lightColorPalette.linkHover,

        brand: whiteLabelConfig?.colors?.primary || lightColorPalette.brand,
        brandHover: whiteLabelConfig?.colors?.primaryDark || lightColorPalette.brandHover,
        brandBackground: whiteLabelConfig?.colors?.primaryPale || lightColorPalette.brandBackground,
        brandBorder: whiteLabelConfig?.colors?.primaryLight || lightColorPalette.brandBorder,
      };

  /**
   * TODO: this needs to be merged with API response once we have WL config returning dark colours
   */
  const darkColorPaletteWithOverrides = darkColorPalette;

  return makeBlocksTheme({
    fontFamily,
    colorConfig: {
      light: lightColorPaletteWithOverrides,
      dark: darkColorPaletteWithOverrides,
    },
  });
};
