import { useCallback } from 'react';
import logger from '../../../utils/logger';
import { useTelemetry } from '../useTelemetry';
import { TelemetryEvents } from '../../constants';
import type { OpenSupportChatEvent } from '../../types/supportEvent';

const getSelectedEvent = (): OpenSupportChatEvent => {
  return {
    name: TelemetryEvents.OPEN_SUPPORT_CHAT,
    data: { message: 'A user has initiated a support chat with our agent.' },
  };
};

export function useOpenSupportChatTelemetry(): () => void {
  const { trackEvent } = useTelemetry();
  return useCallback(async (): Promise<void> => {
    try {
      const selectedEvent = getSelectedEvent();
      await trackEvent(selectedEvent);
    } catch (error) {
      logger.error(
        new Error('OPEN_SUPPORT_CHAT_ERROR', {
          cause: error,
        }),
      );
    }
  }, [trackEvent]);
}
