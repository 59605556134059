import type { AxiosError } from 'axios';
import { useRef } from 'react';
import type { UseInfiniteQueryResult, UseMutationResult, UseQueryResult } from 'react-query';
import { useInfiniteQuery, useMutation, useQueries, useQuery } from 'react-query';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import produce from 'immer';
import type {
  AirScheduleChangeAcceptRequest,
  AirScheduleChangeAcceptResponse,
} from '../types/api/v1/obt/air/air_schedule_change';
import { getPaymentSourceIdsForAirRevalidateKey } from '../utils/Flights/getPaymentSourceIdsForAirRevalidateKey';
import type {
  InternalAPIError,
  AirModifySearchRequest,
  IAirlineUnusedCredits,
  IUserOrgId,
  SpotnanaQueryResult,
  IAirRevalidateItineraryRequest,
  IAirRevalidateItineraryResponse,
  IAirSpecialServiceRequestsResponse,
  IAirSeatMapResponse,
  IAirAncillariesResponse,
} from '../types';
import type {
  IUseAirSeatMapQueryProps,
  IUseAirSeatmapAncillaryQueryWithRegistrarProps,
  IUseAncillaryQueryCheckoutProps,
} from '../types/flight';
import { SpotnanaError } from '../api/SpotnanaError';
import type { AirSearchRequest, AirSearchRequestLeg } from '../types/api/v1/obt/air/air_search_request';
import type { AirSearchResponse } from '../types/api/v1/obt/air/air_search_response';
import type { SpotnanaQueryMutationResult, SpotnanaQueryOptions } from '../types/common';
import type { AirAncillariesRequest, AirAncillariesResponse } from '../types/api/v1/obt/air/air_ancillaries';
import type { AirSeatMapResponse } from '../types/api/v1/obt/air/air_seat_map_response';
import type { AirRevalidateItineraryResponse } from '../types/api/v1/obt/air/air_revalidate_itinerary_response';

import type { AirCreatePnrRequest } from '../types/api/v1/obt/trip/air_create_pnr_request';
import type { AirCreatePnrResponse } from '../types/api/v1/obt/trip/air_create_pnr_response';
import type { AirSeatMapRequest, AirSeatMapRequestPaxInfo } from '../types/api/v1/obt/air/air_seat_map_request';
import type { AirRouteHappyRequest } from '../types/api/v1/obt/air/air_route_happy';
import { airModifySearchKey, fetchAirModifySearchResult } from './airExchange';
import { defaultQueryClient } from './defaultQueryClient';
import type { CheckPnrStatusResponse } from '../types/api/v1/obt/pnr/pnr';
import { getAirAncillariesRequest, getAirSeatMapRequest, removeEmptyValuesFromObject } from '../utils';
import type {
  EnrollAirlineLoyaltyEligibilityResponse,
  EnrollAirlineLoyaltyEligibilityRequest,
  EnrollAirlineLoyaltyRequest,
  EnrollAirlineLoyaltyResponse,
} from '../types/api/v1/obt/profile/profile_services';
import type { UserOrgId } from '../types/api/v1/obt/common/user_org_id';
import type { FetchAirFareRuleRequest } from '../types/api/v2/obt/model/fetch-air-fare-rule-request';
import type { FetchAirFareRuleResponse } from '../types/api/v2/obt/model/fetch-air-fare-rule-response';
import type { PassengerTypeEnum } from '../types/api/v1/obt/air/air_common';
import api from '../api';
import type { AirInitiateBookingResponse } from '../types/api/v1/obt/air/air_intitate_booking_response';
import type { AirInitiateBookingRequest } from '../types/api/v1/obt/air/air_initiate_booking_request';
import { getAirSeatMapAncillaryRequestParams } from '../utils/Flights/getAirSeatMapAncillaryRequestParams';
import { getFullName } from '../transformers/common';
import { getSeatAncillaryRequestParams } from '../utils/Flights/getSeatAncillaryRequestParams';
import type { LlfSearchRequest } from '../types/api/v1/obt/policy/policy_service';
import { invalidateTripV1andV3Details } from './trip';

const airSearchKey = (airSearchRequest: AirSearchRequest): unknown[] => ['air-search', airSearchRequest];
type AirSearchKey = ReturnType<typeof airSearchKey>;

const airRawFaresKey = (airFareRuleRequest: FetchAirFareRuleRequest): unknown[] => [
  'air-raw-fares',
  airFareRuleRequest,
];

const airSearchInifiniteKey = (airSearchRequest: AirSearchRequest): unknown[] => ['air-search', airSearchRequest];

const airRouteHappyKey = (searchId: string): unknown[] => ['air-route-happy', searchId];

const airLlfSearchKey = (llfSearchRequest: LlfSearchRequest): unknown[] => ['air-llf-search', llfSearchRequest];

type AirLlfSearchKey = ReturnType<typeof airLlfSearchKey>;

const airSelectedItinKey = (searchId: string, rateOptionId: string): unknown[] => [
  'air-selected-itin',
  searchId,
  rateOptionId,
];
type AirSelectedItinKey = ReturnType<typeof airSelectedItinKey>;

const airAncillaryKey = (
  searchId: string,
  rateOptionId: string,
  travelerName: string,
  passengerInfos: AirSeatMapRequestPaxInfo[],
): unknown[] => ['air-ancillary', searchId, rateOptionId, travelerName, passengerInfos];

const airAncillaryV2Key = (
  travelerNameKey: string,
  airAncillaryRequest: AirAncillariesRequest | undefined,
): unknown[] => ['air-ancillary-v2', travelerNameKey, airAncillaryRequest];
type AirAncillaryV2Key = ReturnType<typeof airAncillaryV2Key>;

const fetchAirRevalidateKey = (fareBreakupRequest: IAirRevalidateItineraryRequest | null): unknown[] => {
  const paymentSourceIds = getPaymentSourceIdsForAirRevalidateKey(fareBreakupRequest);
  const keyFields = omit(fareBreakupRequest, ['bookingCharges', 'bookingPaymentDetails', 'tripId', 'qcEnabled']);

  const keyFieldsWithPaymentSourceIds = {
    ...keyFields,
    paymentSourceIds,
  };

  return ['air-revalidate', keyFieldsWithPaymentSourceIds];
};

const unusedCreditsKey = (travelerId: IUserOrgId): unknown[] => ['travelerUnusedCredits', travelerId.userId?.id];

const airSeatmapKeyPrefix = 'air-seatmap';
const getAirSeatmapKey = (airSeatMapRequest: AirSeatMapRequest): unknown[] => [airSeatmapKeyPrefix, airSeatMapRequest];
type AirSeatmapKey = ReturnType<typeof getAirSeatmapKey>;

const specialServiceRequestsKey = (): unknown[] => ['special-service-requests'];

export const getAllAirlineUnusedCredits = async (
  travelerId: IUserOrgId,
): Promise<IAirlineUnusedCredits | SpotnanaError> => {
  const data = await api('POST', 'travelerUnusedCredits', {
    data: { userOrgId: removeEmptyValuesFromObject(travelerId) },
  });
  return data as IAirlineUnusedCredits;
};

const fetchAirSearchResultsInternal = async (airSearchRequest: AirSearchRequest): Promise<AirSearchResponse> => {
  const airSearchResponse = await api('POST', 'airSearch', { data: airSearchRequest }, { allowParallelRequests: true });

  if (airSearchResponse) {
    return airSearchResponse as AirSearchResponse;
  }
  throw new SpotnanaError('Cannot parse Air Search Response');
};

const fetchAirRouteHappyInternal = async (data: AirRouteHappyRequest): Promise<AirSearchResponse> => {
  const airSearchResponse = await api('POST', 'airRouteHappy', { data });
  return airSearchResponse as AirSearchResponse;
};

const fetchLlfSearch = async (data: LlfSearchRequest): Promise<AirSearchResponse> => {
  const llfSearchResponse = await api('POST', 'llfSearch', { data });
  return llfSearchResponse as AirSearchResponse;
};

const fetchAirFareRule = async (data: FetchAirFareRuleRequest): Promise<FetchAirFareRuleResponse> => {
  const airFareRulesResponse = await api('POST', 'airRawFares', { data });
  return airFareRulesResponse as FetchAirFareRuleResponse;
};

const fetchSelectedItineraryInternal = async (searchId: string, rateOptionId: string): Promise<AirSearchResponse> => {
  const airSelectedItineraryResponse = await api('POST', 'airSelectedItinerary', {
    data: { searchId, rateOptionId },
  });
  if (airSelectedItineraryResponse) {
    return airSelectedItineraryResponse as AirSearchResponse;
  }
  throw new SpotnanaError('Cannot parse Air Search Response');
};

const fetchAncillaries = async (
  searchId: string,
  rateOptionId: string,
  passengerInfos: AirSeatMapRequestPaxInfo[],
): Promise<AirAncillariesResponse> => {
  const ancillaryResponse = (await api('POST', 'airAncillaries', {
    data: { searchId, rateOptionId, passengerInfos },
  })) as AirAncillariesResponse;
  return ancillaryResponse as AirAncillariesResponse;
};

const fetchSeatMaps = async (data: AirSeatMapRequest | undefined): Promise<AirSeatMapResponse> => {
  const seatMapResponse = await api('POST', 'getAirSeatMaps', { data }, { allowParallelRequests: true });
  return seatMapResponse as AirSeatMapResponse;
};

const airInitiateBooking = async (data: AirInitiateBookingRequest): Promise<AirInitiateBookingResponse> => {
  const airInitiateBookingResponse = await api('POST', 'airInitiateBooking', { data });
  return airInitiateBookingResponse as AirInitiateBookingResponse;
};

export const invalidateAllAirSeatMapResponses = (): Promise<void> => {
  return defaultQueryClient.invalidateQueries(airSeatmapKeyPrefix);
};

export const airRevalidateItinerary = async (
  revalidateRequest: IAirRevalidateItineraryRequest,
): Promise<AirRevalidateItineraryResponse | null> => {
  try {
    const airRevalidateItenaryResponse = await api('POST', 'airRevalidateItenary', {
      data: revalidateRequest,
    });
    if (airRevalidateItenaryResponse) {
      return Promise.resolve<AirRevalidateItineraryResponse>(
        airRevalidateItenaryResponse as AirRevalidateItineraryResponse,
      );
    }

    return Promise.resolve(null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const airCreatePnr = async (requestBody: AirCreatePnrRequest): Promise<AirCreatePnrResponse> => {
  const airCreatePnrResponse = await api('POST', 'airCreatePNR', {
    data: requestBody,
  });
  return airCreatePnrResponse as AirCreatePnrResponse;
};

const fetchSpecialServiceRequests = async (): Promise<IAirSpecialServiceRequestsResponse> => {
  const data = await api('GET', 'specialServiceRequests');
  return data as IAirSpecialServiceRequestsResponse;
};

export const useAirSearchQuery = (
  airSearchRequest: AirSearchRequest | AirModifySearchRequest | null,
  options?: SpotnanaQueryOptions<AirSearchResponse, AirSearchKey>,
): SpotnanaQueryResult<AirSearchResponse, SpotnanaError> => {
  const isModifySearch = (airSearchRequest as AirModifySearchRequest | null)?.pnrId !== undefined;
  let key: unknown[] = ['disabled-air-search'];
  if (airSearchRequest) {
    if (isModifySearch) {
      key = airModifySearchKey(airSearchRequest as AirModifySearchRequest);
    } else {
      key = airSearchKey(airSearchRequest as AirSearchRequest);
    }
  }

  const fetchFunction = () => {
    if (!airSearchRequest) {
      return Promise.resolve(null as unknown as AirSearchResponse);
    }

    if (isModifySearch) {
      return fetchAirModifySearchResult(airSearchRequest as AirModifySearchRequest);
    }

    return fetchAirSearchResultsInternal(airSearchRequest as AirSearchRequest);
  };

  return useQuery(key, fetchFunction, options);
};

export const useAirSearchInfiniteQuery = (
  airSearchRequest: AirSearchRequest | null,
): UseInfiniteQueryResult<AirSearchResponse, SpotnanaError> => {
  /**
   * Only initial request can be with empty `searchId: ""` for the same legs!
   *
   * Next requests have to include `searchId` from initial response:
   *  - user apply filters/sorting
   *  - getting next chunk of flights(pagination)
   */
  const lastLegsRef = useRef<AirSearchRequestLeg[]>([]);
  const searchIdRef = useRef('');

  let key: unknown[] = ['disabled-air-search'];

  if (airSearchRequest) {
    key = airSearchInifiniteKey(airSearchRequest);
  }

  return useInfiniteQuery<AirSearchResponse, SpotnanaError>(
    key,
    ({ pageParam }) => {
      if (!airSearchRequest) {
        return Promise.resolve(null as unknown as AirSearchResponse);
      }

      const request: AirSearchRequest = produce(airSearchRequest, (draftRequest) => {
        if (draftRequest.legSearchParams) {
          draftRequest.legSearchParams.pageNumber = pageParam?.pageNumber ?? 1;

          const isSameLegs = isEqual(airSearchRequest.legs, lastLegsRef.current);
          const { current: searchId } = searchIdRef;

          if (searchId && isSameLegs) {
            draftRequest.legSearchParams.searchId = searchId;
          }
        }
      });

      lastLegsRef.current = request.legs;

      return fetchAirSearchResultsInternal(request);
    },
    {
      enabled: !!airSearchRequest,
      staleTime: 60 * 1000, // 1 minute,
      onSuccess: (data) => {
        const searchId = data.pages[data.pages.length - 1]?.searchId ?? '';

        searchIdRef.current = searchId;
      },
      getNextPageParam: (lastPage) => {
        const currentPageNumber = lastPage?.paginationParams?.currentPageNumber ?? 1;
        const totalPages = lastPage?.paginationParams?.totalNumPages ?? 1;

        if (currentPageNumber < totalPages) {
          return { pageNumber: currentPageNumber + 1 };
        }

        return undefined;
      },
    },
  );
};

export const useAirLLFSearchQuery = (
  llfSearchRequest: LlfSearchRequest,
  options?: SpotnanaQueryOptions<AirSearchResponse, AirLlfSearchKey>,
): UseQueryResult<AirSearchResponse, SpotnanaError> => {
  const queryKey = airLlfSearchKey(llfSearchRequest);
  return useQuery(queryKey, () => fetchLlfSearch(llfSearchRequest), options);
};

export const useAirRouteHappyQuery = (searchId: string): UseQueryResult<AirSearchResponse, SpotnanaError> =>
  useQuery<AirSearchResponse, SpotnanaError>(
    airRouteHappyKey(searchId),
    () => fetchAirRouteHappyInternal({ searchId }),
    {
      enabled: !!searchId,
    },
  );

export const useAirFareRuleQuery = (
  data: FetchAirFareRuleRequest,
): UseQueryResult<FetchAirFareRuleResponse, SpotnanaError> =>
  useQuery<FetchAirFareRuleResponse, SpotnanaError>(airRawFaresKey(data), () => fetchAirFareRule(data));

export const invalidateAirFareRule = (airFareRuleRequest: FetchAirFareRuleRequest): Promise<void> =>
  defaultQueryClient.invalidateQueries(airRawFaresKey(airFareRuleRequest));

export const useAirSelectedItineraryQuery = (
  searchId: string,
  rateOptionId: string,
  options?: SpotnanaQueryOptions<AirSearchResponse, AirSelectedItinKey>,
): UseQueryResult<AirSearchResponse, SpotnanaError> =>
  useQuery(
    airSelectedItinKey(searchId, rateOptionId),
    () => fetchSelectedItineraryInternal(searchId, rateOptionId),
    options,
  );

export const useMultipleAirSelectedItineraryQuery = (
  searchInfo: { searchId: string; rateOptionId: string }[],
): SpotnanaQueryResult<AirSearchResponse, SpotnanaError>[] => {
  return useQueries(
    searchInfo.map(({ searchId, rateOptionId }) => {
      return {
        queryKey: airSelectedItinKey(searchId, rateOptionId),
        queryFn: () => fetchSelectedItineraryInternal(searchId, rateOptionId),
      };
    }),
  ) as SpotnanaQueryResult<AirSearchResponse, SpotnanaError>[];
};

export const useAirAncillaries = (
  searchId: string,
  rateOptionId: string,
  passengerInfos: AirSeatMapRequestPaxInfo[],
  /**
   * travelerName is required to refetch air-ancillaries data that is related to the certain traveler's name
   */
  travelerName: string,
  enabled: boolean,
): UseQueryResult<AirAncillariesResponse, SpotnanaError> => {
  return useQuery<AirAncillariesResponse, SpotnanaError>(
    airAncillaryKey(searchId, rateOptionId, travelerName, passengerInfos),
    () => fetchAncillaries(searchId, rateOptionId, passengerInfos),
    { enabled },
  );
};

/**
 * travelerName is required to refetch air-ancillaries data that is related to
 * the certain traveler's name
 */
export const useAirAncillariesV2Query = (
  travelerNameKey: string,
  airAncillaryRequest: AirAncillariesRequest | undefined,
  options?: SpotnanaQueryOptions<AirAncillariesResponse, AirAncillaryV2Key>,
): UseQueryResult<AirAncillariesResponse, SpotnanaError> => {
  return useQuery(
    airAncillaryV2Key(travelerNameKey, airAncillaryRequest),
    () => {
      if (!airAncillaryRequest) {
        return Promise.reject();
      }
      const { searchId, rateOptionId, passengerInfos } = airAncillaryRequest;
      return fetchAncillaries(searchId, rateOptionId, passengerInfos);
    },
    options,
  );
};

export function useAirSeatMapsQuery(
  seatMapRequest: AirSeatMapRequest | undefined,
  options?: SpotnanaQueryOptions<AirSeatMapResponse, AirSeatmapKey>,
): SpotnanaQueryResult<AirSeatMapResponse> {
  const queryKey = seatMapRequest ? getAirSeatmapKey(seatMapRequest) : [];
  return useQuery(queryKey, () => fetchSeatMaps(seatMapRequest), options);
}

const airInitiateBookingKeyPrefix = 'air-initiate-booking';

export const airInitiateBookingKey = (initiateBookingRequest: AirInitiateBookingRequest): unknown[] => [
  airInitiateBookingKeyPrefix,
  initiateBookingRequest,
];

export interface IUseAirInitiateBooking {
  initiateBookingRequest: AirInitiateBookingRequest | null;
  queryOptions: SpotnanaQueryOptions<AirInitiateBookingResponse, ReturnType<typeof airInitiateBookingKey>>;
}

export const invalidateAirInitiateBooking = (): Promise<void> =>
  defaultQueryClient.invalidateQueries(airInitiateBookingKeyPrefix);

export function useAirInitiateBookingQuery({
  initiateBookingRequest,
  queryOptions,
}: IUseAirInitiateBooking): SpotnanaQueryResult<AirInitiateBookingResponse> {
  const queryKey = initiateBookingRequest ? airInitiateBookingKey(initiateBookingRequest) : [];
  return useQuery<AirInitiateBookingResponse, SpotnanaError>(
    queryKey,
    () => {
      if (!initiateBookingRequest) {
        return Promise.reject();
      }
      return airInitiateBooking(initiateBookingRequest);
    },
    { enabled: queryOptions?.enabled !== false },
  );
}

export const useAirInitiateBookingMutation = (): UseMutationResult<
  AirInitiateBookingResponse,
  SpotnanaError,
  AirInitiateBookingRequest
> =>
  useMutation((requestBody: AirInitiateBookingRequest) => airInitiateBooking(requestBody), {
    onSuccess: (data, initiateBookingRequest) => {
      defaultQueryClient.setQueryData(airInitiateBookingKey(initiateBookingRequest), data);
    },
  });

export const invalidateAirSearchResponses = (): Promise<void> => defaultQueryClient.invalidateQueries('air-search');

export const useAirCreatePnr = (): UseMutationResult<AirCreatePnrResponse, SpotnanaError, AirCreatePnrRequest> =>
  useMutation((requestBody: AirCreatePnrRequest) => airCreatePnr(requestBody));

export const useAirRevalidateItinerary = (): UseMutationResult<
  IAirRevalidateItineraryResponse | null,
  SpotnanaError,
  IAirRevalidateItineraryRequest
> => useMutation((requestBody: IAirRevalidateItineraryRequest) => airRevalidateItinerary(requestBody));

export function updateRevalidateQueryData(
  requestBody: IAirRevalidateItineraryRequest,
  response: IAirRevalidateItineraryResponse | null,
) {
  if (response) {
    defaultQueryClient.setQueryData(['air-revalidate-query', requestBody], response);
  }
}

export const checkPnrStatus = async (pnrId: string): Promise<CheckPnrStatusResponse> => {
  const pnrsStatusResponse = await api('GET', 'checkPnrStatus', {
    params: { query: pnrId },
  });
  return pnrsStatusResponse as CheckPnrStatusResponse;
};

const enrollAirlineLoyalty = async (request: EnrollAirlineLoyaltyRequest): Promise<EnrollAirlineLoyaltyResponse> => {
  try {
    const data = await api('POST', 'enrollAirlineLoyalty', {
      data: request,
    });
    return data as EnrollAirlineLoyaltyResponse;
  } catch (e) {
    const error = e as AxiosError<InternalAPIError>;
    return Promise.reject(error);
  }
};

export const useEnrollAirlineLoyaltyMutation = (): SpotnanaQueryMutationResult<
  EnrollAirlineLoyaltyResponse,
  EnrollAirlineLoyaltyRequest
> => useMutation((request) => enrollAirlineLoyalty(request));

const checkLoyaltyEnrollEligibility = async (
  request: EnrollAirlineLoyaltyEligibilityRequest,
): Promise<EnrollAirlineLoyaltyEligibilityResponse | undefined> => {
  try {
    const data = await api('POST', 'checkLoyaltyEnrollEligibility', { data: request });
    const result = data as EnrollAirlineLoyaltyEligibilityResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const checkLoyaltyEnrollEligibilityKey = (rateOptionId: string, userOrgId?: UserOrgId): unknown[] => [
  'enroll-airline-loyalty-eligibility',
  rateOptionId,
  userOrgId,
];

export const useCheckLoyaltyEnrollEligibilityQuery = (
  request: EnrollAirlineLoyaltyEligibilityRequest,
  queryOptions?: SpotnanaQueryOptions<
    EnrollAirlineLoyaltyEligibilityResponse | undefined,
    ReturnType<typeof checkLoyaltyEnrollEligibilityKey>
  >,
): SpotnanaQueryResult<EnrollAirlineLoyaltyEligibilityResponse | undefined> =>
  useQuery(
    checkLoyaltyEnrollEligibilityKey(request.rateOptionId, request?.userOrgId),
    () => checkLoyaltyEnrollEligibility(request),
    queryOptions,
  );

interface UseAirSeatMapsInlineShoppingRequest {
  searchId: string;
  rateOptionId: string;
  paxType: PassengerTypeEnum;
  userOrgId?: UserOrgId;
}

export const useAirSeatMapsInlineShopping = (
  { searchId, rateOptionId, paxType, userOrgId }: UseAirSeatMapsInlineShoppingRequest,
  enabled = true,
) =>
  useAirSeatMapsQuery(
    {
      searchId,
      rateOptionId: [rateOptionId],
      loyaltyInfo: [],
      passengerInfos: [
        {
          userOrgId,
          paxType,
          loyaltyInfo: [],
        },
      ],
    },
    { enabled },
  );

export const useGetTravelerUnusedCredits = (travelerId: IUserOrgId): SpotnanaQueryResult<IAirlineUnusedCredits> =>
  useQuery(unusedCreditsKey(travelerId), () => getAllAirlineUnusedCredits(travelerId), {
    enabled: !!travelerId,
  });

export const useSpecialServiceRequestsQuery = ({
  enabled,
}: {
  enabled?: boolean;
} = {}): UseQueryResult<IAirSpecialServiceRequestsResponse, SpotnanaError> => {
  const queryKey = specialServiceRequestsKey();
  return useQuery<IAirSpecialServiceRequestsResponse, SpotnanaError>(queryKey, () => fetchSpecialServiceRequests(), {
    enabled,
  });
};

interface IUseFetchAirRevalidateQuery {
  fetchAirRevalidateRequest: IAirRevalidateItineraryRequest | null;
  options: SpotnanaQueryOptions<IAirRevalidateItineraryResponse | null, ReturnType<typeof fetchAirRevalidateKey>>;
}
export const useFetchAirRevalidateQuery = ({
  fetchAirRevalidateRequest,
  options,
}: IUseFetchAirRevalidateQuery): SpotnanaQueryResult<IAirRevalidateItineraryResponse | null> => {
  const queryKey = fetchAirRevalidateKey(fetchAirRevalidateRequest);
  return useQuery(
    queryKey,
    () => (fetchAirRevalidateRequest ? airRevalidateItinerary(fetchAirRevalidateRequest) : Promise.resolve(null)),
    options,
  );
};

export function useAirSeatmapSeatmapPageCheckoutPageQuery(
  params: IUseAirSeatMapQueryProps,
): SpotnanaQueryResult<IAirSeatMapResponse> {
  const { passengersWithUserOrgId, disabled } = getAirSeatMapAncillaryRequestParams(
    params.passengers,
    params.travelersResponse,
  );

  const airSeatMapRequest =
    'searchId' in params
      ? getAirSeatMapRequest({
          searchId: params.searchId,
          rateOptionId: params.rateOptionId,
          passengers: passengersWithUserOrgId,
        })
      : getAirSeatMapRequest({
          searchId: '',
          rateOptionId: '',
          passengers: passengersWithUserOrgId,
          pnrId: params.pnrId,
        });
  return useAirSeatMapsQuery(airSeatMapRequest, { enabled: !disabled });
}

export function useAirAncillarySeatmapPageCheckoutPageQuery({
  searchId,
  rateOptionId,
  passengers,
  travelersResponse,
}: IUseAncillaryQueryCheckoutProps): SpotnanaQueryResult<IAirAncillariesResponse> {
  const { passengersWithUserOrgId, disabled } = getAirSeatMapAncillaryRequestParams(passengers, travelersResponse);

  const travelerName = getFullName(travelersResponse[0]?.user?.name);

  const airAncillaryRequest = getAirAncillariesRequest({ searchId, rateOptionId, passengers: passengersWithUserOrgId });
  return useAirAncillariesV2Query(travelerName, airAncillaryRequest, { enabled: !disabled });
}

export function useAirAncillaryQuery(
  props: IUseAirSeatmapAncillaryQueryWithRegistrarProps,
): SpotnanaQueryResult<IAirAncillariesResponse> {
  const { requestParams, isAnyPassengerInvalid } = getSeatAncillaryRequestParams(props);
  const airAncillaryRequest = requestParams ? getAirAncillariesRequest(requestParams) : undefined;
  const disabled = isAnyPassengerInvalid || !requestParams;
  const travelerName = getFullName(props.travelersResponse[0]?.user?.name);
  return useAirAncillariesV2Query(travelerName, airAncillaryRequest, {
    enabled: !disabled,
    keepPreviousData: true,
  });
}

const acceptPnrScheduleChange = async (
  request: AirScheduleChangeAcceptRequest,
): Promise<AirScheduleChangeAcceptResponse | SpotnanaError> => {
  const data = await api('POST', 'acceptChanges', {
    data: request,
  });

  return data as AirScheduleChangeAcceptResponse;
};

export const useAcceptPnrScheduleChange = (tripId: string) =>
  useMutation((values: AirScheduleChangeAcceptRequest) => acceptPnrScheduleChange(values), {
    onSuccess: () => {
      invalidateTripV1andV3Details(tripId);
    },
  });
