export interface IHotelLoyaltyObject {
  loyaltyProgram: string;
  loyaltyWebsite: string;
  nameOnWebsite: string;
  brandName: string;
  chainCode: string;
  masterChainCode: string;
}

const hotelLoyaltyArray: IHotelLoyaltyObject[] = [
  {
    loyaltyProgram: 'Marriott Bonvoy',
    loyaltyWebsite: 'https://www.marriott.com/loyalty.mi',
    nameOnWebsite: 'The Ritz Carlton',
    brandName: 'Ritz Carlton Hotels',
    chainCode: 'RZ',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'AC Hotels by Marriott',
    brandName: 'AC Hotels',
    chainCode: 'AR',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Autograph Collection',
    brandName: 'Autograph Collect',
    chainCode: 'AK',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Aloft Hotels',
    brandName: 'Aloft Hotels',
    chainCode: 'AL',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Bvlgari Hotels & Resorts',
    brandName: 'Bulgari Hotels And Resorts',
    chainCode: 'BG',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Courtyard by Marriott',
    brandName: 'Courtyard By Marriott',
    chainCode: 'CY',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Delta Hotels',
    brandName: 'Delta Hotels',
    chainCode: 'DE',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Design Hotels',
    brandName: 'Design Hotels',
    chainCode: 'DS',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'EDITION Hotels',
    brandName: 'EDITION',
    chainCode: 'EB',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Element by Westin',
    brandName: 'Element Hotels',
    chainCode: 'EL',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Fairfield by Marriott',
    brandName: 'Fairfield Inn',
    chainCode: 'FN',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Four Points by Sheraton',
    brandName: 'Four Points',
    chainCode: 'FP',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Gaylord Hotels',
    brandName: 'Gaylord Entertainment',
    chainCode: 'GE',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Homes & Villas by Marriott International',
    brandName: 'Marriott Hotels',
    chainCode: 'MC',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'JW Marriott Hotels',
    brandName: 'Marriott Hotels',
    chainCode: 'MC',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Le Méridien',
    brandName: 'Le Meridien',
    chainCode: 'MD',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The Luxury Collection',
    brandName: 'Luxury Collection',
    chainCode: 'LC',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Marriott Executive Apartments',
    brandName: 'Marriott ExecuStay',
    chainCode: 'EE',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Marriott Hotels & Resorts',
    brandName: 'Marriott Hotels',
    chainCode: 'MC',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Marriott Vacation Club',
    brandName: 'Marriott Vacation Club',
    chainCode: 'VC',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Protea Hotels by Marriott',
    brandName: 'Protea Hotels',
    chainCode: 'PR',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Renaissance Hotels',
    brandName: 'Renaissance Hotels',
    chainCode: 'BR',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Moxy Hotels',
    brandName: 'Moxy Hotels',
    chainCode: 'OX',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Residence Inn by Marriott',
    brandName: 'Residence Inn',
    chainCode: 'RC',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sheraton Hotels and Resorts',
    brandName: 'Sheraton Hotels',
    chainCode: 'SI',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'SpringHill Suites',
    brandName: 'Springhill Suites',
    chainCode: 'XV',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'St. Regis Hotels & Resorts',
    brandName: 'The St. Regis',
    chainCode: 'XR',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'TownePlace Suites',
    brandName: 'Towneplace Suites',
    chainCode: 'TO',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Tribute Portfolio',
    brandName: 'TRIBUTE PORTFOLIO',
    chainCode: 'TX',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'W Hotels',
    brandName: 'W Hotels',
    chainCode: 'WH',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Westin Hotels',
    brandName: 'Westin Hotels And Resorts',
    chainCode: 'WI',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Marriott Conference Centers',
    brandName: 'Marriott Conference Center',
    chainCode: 'ET',
    masterChainCode: 'EM',
  },
  {
    loyaltyProgram: 'IHG Rewards Club',
    loyaltyWebsite: 'https://partnerconnect.ihg.com/',
    nameOnWebsite: 'Six senses',
    brandName: 'Six senses Hotel Resorts',
    chainCode: 'IG',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Regent',
    brandName: 'Regent International Hotels',
    chainCode: 'RE',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'InterContinental Hotels and Resorts',
    brandName: 'InterContinental Hotels',
    chainCode: 'IC',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Kimpton',
    brandName: 'Kimpton Hotels',
    chainCode: 'KC',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hotel Indigo',
    brandName: 'Hotel Indigo',
    chainCode: 'IN',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Even hotels',
    brandName: 'Even hotels',
    chainCode: 'VN',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hualuxe',
    brandName: 'Hauluxe Hotels',
    chainCode: 'UL',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Crowne Plaza',
    brandName: 'Crowne Plaza Hotels',
    chainCode: 'CP',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'VOCO',
    brandName: 'VOCO',
    chainCode: 'VX',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Holliday Inn',
    brandName: 'Holliday Inn',
    chainCode: 'HI',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Holiday Inn express',
    brandName: 'Holliday Inn',
    chainCode: 'HI',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Holiday Inn Club Vacations',
    brandName: 'Holliday Inn',
    chainCode: 'HI',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Holiday Inn Resorts',
    brandName: 'Holliday Inn',
    chainCode: 'HI',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Avid',
    brandName: 'Avid Hotels',
    chainCode: 'VA',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Staybridge Suites',
    brandName: 'Staybridge Suites',
    chainCode: 'YZ',
    masterChainCode: '6C',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Candlewood Suites',
    brandName: 'Candlewood Suites',
    chainCode: 'YO',
    masterChainCode: '6C',
  },

  {
    loyaltyProgram: 'ALL - Accor Live Limitless',
    loyaltyWebsite: 'https://group.accor.com/en/group/who-we-are/accor-in-brief',
    nameOnWebsite: 'Raffles',
    brandName: 'Raffles Hotels and Resorts',
    chainCode: 'YR',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Banyan Tree',
    brandName: 'Banyan Tree Hotels And Resorts',
    chainCode: 'BY',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Oriental Express',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Delano',
    brandName: 'Delano',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Fairmont',
    brandName: 'Fairmont Hotels & Resorts',
    chainCode: 'FA',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'SLS',
    brandName: 'Morgans Hotel Group',
    chainCode: 'MR',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'SO/',
    brandName: 'Sofitel',
    chainCode: 'SB',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sofitel',
    brandName: 'Sofitel',
    chainCode: 'SB',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sofitel Legend',
    brandName: 'Sofitel',
    chainCode: 'SB',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'onefinestay',
    brandName: 'onefinestay',
    chainCode: '',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The House of Originals',
    brandName: 'Morgans Hotel Group',
    chainCode: 'MR',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Rixos Hotels',
    brandName: 'Rixos Hotels',
    chainCode: 'RX',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'mantis',
    brandName: '',
    chainCode: '',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Mondrian',
    brandName: 'Morgans Hotel Group',
    chainCode: 'MR',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Mgallery',
    brandName: 'Sofitel',
    chainCode: 'SB',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Art Series',
    brandName: 'Mantra Group',
    chainCode: 'MP',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Pullman',
    brandName: 'Pullman Hotels',
    chainCode: 'PU',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'swissotel',
    brandName: 'swissotel',
    chainCode: 'SL',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Angsana',
    brandName: 'Banyan Tree Hotels And Resorts',
    chainCode: 'BY',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: '25h',
    brandName: '25Hours Hotel',
    chainCode: '',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hyde',
    brandName: 'Preferred Hotels & Resorts',
    chainCode: 'PH',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Movenpick',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Grand mercure',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Peppers',
    brandName: 'Mantra Group',
    chainCode: 'MP',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The Sebel',
    brandName: 'Mantra Group',
    chainCode: 'MP',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Mantra',
    brandName: 'Mantra Group',
    chainCode: 'MP',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Novotel',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Mercure',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Adagio',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Mama Shelter',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'TRIBE',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'BreakFree',
    brandName: 'Mantra Group',
    chainCode: 'MP',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'ibis',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'ibis styles',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'ibis budget',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'jo & Joe',
    brandName: '',
    chainCode: '',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'greet',
    brandName: 'Accor Hotels',
    chainCode: 'RT',
    masterChainCode: 'RT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'hotelF1',
    brandName: '',
    chainCode: '',
    masterChainCode: 'RT',
  },

  {
    loyaltyProgram: 'Hilton Honors',
    loyaltyWebsite: 'https://www.hilton.com/en/',
    nameOnWebsite: 'Conrad Hotels & Resorts',
    brandName: 'Conrad Hotels And Resorts',
    chainCode: 'CN',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Canopy by Hilton',
    brandName: 'CANOPY BY HILTON',
    chainCode: 'PY',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Curio – A Collection by Hilton',
    brandName: 'CURIO COLLECTION',
    chainCode: 'QQ',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'DoubleTree by Hilton',
    brandName: 'Doubletree Hotels',
    chainCode: 'DT',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Embassy Suites by Hilton',
    brandName: 'Embassy Suites',
    chainCode: 'ES',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hampton Inn by Hilton',
    brandName: 'Hampton Inn',
    chainCode: 'HX',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hilton Hotels',
    brandName: 'Hilton Hotels',
    chainCode: 'HH',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hilton Garden Inn',
    brandName: 'Hilton Garden Inn',
    chainCode: 'GI',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hilton Grand Vacations',
    brandName: 'Hilton Hotels',
    chainCode: 'HH',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Home2 Suites by Hilton',
    brandName: 'Home2Suites',
    chainCode: 'HT',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Homewood Suites by Hilton',
    brandName: 'Homewood Suites By Hilton',
    chainCode: 'HG',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'LXR',
    brandName: 'LXR Hotels & Resorts',
    chainCode: 'OL',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Motto',
    brandName: 'Motto By Hilton',
    chainCode: 'UA',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Signia',
    brandName: 'Signia Hilton',
    chainCode: 'SA',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Tapestry Collection by Hilton',
    brandName: 'Tapestry by Hilton',
    chainCode: 'UP',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Tru by Hilton',
    brandName: 'Tru by Hilton',
    chainCode: 'RU',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Waldorf Astoria Hotels & Resorts',
    brandName: 'Waldorf Astoria Hotels & Resorts',
    chainCode: 'WA',
    masterChainCode: 'EH',
  },
  {
    loyaltyProgram: 'World of Hyatt',
    loyaltyWebsite: 'https://world.hyatt.com/content/gp/en/rewards/new-brands.html',
    nameOnWebsite: 'joie de vivre',
    brandName: 'Joie De Vivre Hospitality',
    chainCode: 'JV',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Destination Hotels',
    brandName: 'Destination Hotels & Resorts',
    chainCode: 'DN',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The Unbound Collection',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hyatt house',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hyatt place',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hyatt zilara/Hyatt ziva',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hyatt Regency',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Grand Hyatt',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Park Hyatt',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hyatt Centric',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Thompson Hotels',
    brandName: 'Thompson Hotels',
    chainCode: 'TM',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Alila',
    brandName: 'Alila Hotels and Resorts',
    chainCode: 'LF',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Andaz',
    brandName: 'Hyatt Hotels & Resorts',
    chainCode: 'HY',
    masterChainCode: 'HY',
  },
  {
    loyaltyProgram: 'Wyndham Rewards',
    loyaltyWebsite: 'https://www.wyndhamhotels.com/wyndham-rewards',
    nameOnWebsite: 'Wyndham Grand',
    brandName: 'Wyndham Extra Holiday',
    chainCode: 'WO',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Dolce',
    brandName: 'Dolce Hotels & Resorts by Wyndham',
    chainCode: 'DX',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Wyndham',
    brandName: 'Wyndham Hotels and Resorsts',
    chainCode: 'WY',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'TRYP',
    brandName: 'Tryp by Wymdham',
    chainCode: 'WT',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Esplendor',
    brandName: 'Esplendor & Dazzler by Wymdham',
    chainCode: 'FE',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Dazzler',
    brandName: 'Esplendor & Dazzler by Wymdham',
    chainCode: 'FE',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Trademark',
    brandName: 'Trademark by Wymdham',
    chainCode: 'TQ',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'LaQuinta',
    brandName: 'LaQuinta by Wymdham',
    chainCode: 'LQ',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Wingate',
    brandName: 'Wingate by Wymdham',
    chainCode: 'WG',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Wyndham Garden',
    brandName: 'Wyndham Hotels and Resorsts',
    chainCode: 'WY',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'AmericInn',
    brandName: 'AmericInn by Wymdham',
    chainCode: 'AA',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Ramada',
    brandName: 'Ramada by Wymdham',
    chainCode: 'RA',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Baymont',
    brandName: 'Baymont by Wymdham',
    chainCode: 'BU',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Microtel',
    brandName: 'Microtel by Wymdham',
    chainCode: 'MT',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'DaysInn',
    brandName: 'Days Inn by Wymdham',
    chainCode: 'DI',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Super8',
    brandName: 'Super 8 by Wymdham',
    chainCode: 'OZ',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Howard Johnson',
    brandName: 'Howard Johnson by Wymdham',
    chainCode: 'HJ',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Travelodge',
    brandName: 'Travelodge by Wymdham',
    chainCode: 'TL',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hawthorn',
    brandName: 'Hawthorn Suites by Wymdham',
    chainCode: 'BH',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Club Wyndham',
    brandName: 'Wyndham Extra Holiday',
    chainCode: 'WO',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Wyndham vacation rentals',
    brandName: 'Wyndham Extra Holiday',
    chainCode: 'WO',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Harrahs',
    brandName: 'Harrahs Casinos',
    chainCode: 'HR',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Nobu Hotels',
    brandName: 'Nobu Hotels',
    chainCode: 'NB',
    masterChainCode: 'WR',
  },
  {
    loyaltyProgram: 'Choice Privileges',
    loyaltyWebsite: 'https://www.choicehotels.com/',
    nameOnWebsite: 'Ascend Hotel Collection',
    brandName: 'Ascend Collection',
    chainCode: 'NZ',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Cambria hotels',
    brandName: 'Cambria Suites',
    chainCode: 'EZ',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Comfort Inn',
    brandName: 'Comfort Inn',
    chainCode: 'CI',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Comfort Suites',
    brandName: 'Comfort Suites',
    chainCode: 'CZ',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sleep Inn',
    brandName: 'Sleep Inn',
    chainCode: 'SZ',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Clarion pointe',
    brandName: 'Clarion Hotels',
    chainCode: 'CC',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Quality inn',
    brandName: 'Quality Inns And Suites',
    chainCode: 'QI',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Mainstay Suites',
    brandName: 'Mainstay Suites',
    chainCode: 'MZ',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Woodspring suites',
    brandName: 'Woodspring Suites',
    chainCode: 'WS',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Suburban',
    brandName: 'Suburban Hotels',
    chainCode: 'UB',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Econolodge',
    brandName: 'Econo Lodge',
    chainCode: 'EO',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Rodeway inn',
    brandName: 'Rodeway Inn',
    chainCode: 'RI',
    masterChainCode: 'EC',
  },
  {
    loyaltyProgram: 'Extended Perks',
    loyaltyWebsite: 'https://www.extendedstayamerica.com/about/affiliates.html',
    nameOnWebsite: 'Extended Stay America',
    brandName: 'Extended Stay America',
    chainCode: 'EA',
    masterChainCode: 'EA',
  },
  {
    loyaltyProgram: 'Sonesta Travel Pass',
    loyaltyWebsite: 'https://www.sonesta.com/travel-pass',
    nameOnWebsite: 'Royal Sonesta',
    brandName: 'Sonesta Collection',
    chainCode: 'SN',
    masterChainCode: 'SN',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'sonesta hotel and resort',
    brandName: 'Sonesta Collection',
    chainCode: 'SN',
    masterChainCode: 'SN',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'sonesta Select',
    brandName: 'Sonesta Collection',
    chainCode: 'SN',
    masterChainCode: 'SN',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sonesta es Suites',
    brandName: 'Sonesta Collection',
    chainCode: 'SN',
    masterChainCode: 'SN',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sonesta Simply Suites',
    brandName: 'Sonesta Collection',
    chainCode: 'SN',
    masterChainCode: 'SN',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sonesta Posadas Del Inca',
    brandName: 'Sonesta Collection',
    chainCode: 'SN',
    masterChainCode: 'SN',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sonesta Cruise Collection',
    brandName: 'Sonesta Collection',
    chainCode: 'SN',
    masterChainCode: 'SN',
  },
  {
    loyaltyProgram: 'Best Western Rewards',
    loyaltyWebsite: 'https://www.bestwestern.com/en_US/hotels/discover-best-western/brands.html',
    nameOnWebsite: 'GLo',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'BW Premiere Collection',
    brandName: 'BW Premier Collection',
    chainCode: 'PB',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Vib',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Best Western Plus',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Best Western Premier',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Sadie',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Best Western',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'BW SIgnature Collections',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Executive Residency',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Aiden',
    brandName: 'Best Western Intl',
    chainCode: 'BW',
    masterChainCode: 'EW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'WorldHotels Luxury',
    brandName: 'WorldHotels',
    chainCode: 'WW',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'WorldHotels Elite',
    brandName: 'WorldHotels',
    chainCode: 'WW',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'WorldHotels Distinctive',
    brandName: 'WorldHotels',
    chainCode: 'WW',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'WorldHOtels Crafted',
    brandName: 'WorldHotels',
    chainCode: 'WW',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'SureStay Plus',
    brandName: 'SureStay',
    chainCode: 'UR',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'SureStay',
    brandName: 'SureStay',
    chainCode: 'UR',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'SureStay SIngature Collection',
    brandName: 'SureStay',
    chainCode: 'UR',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'SureStay Studio',
    brandName: 'SureStay',
    chainCode: 'UR',
    masterChainCode: 'WW',
  },
  {
    loyaltyProgram: 'Radisson Rewards (Club Carlson)',
    loyaltyWebsite: 'https://www.radissonhotelgroup.com/rewards',
    nameOnWebsite: 'Radisson Collection',
    brandName: 'Radisson Hotels And Resorts',
    chainCode: 'RD',
    masterChainCode: 'CW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Radisson BLU',
    brandName: 'Radisson Hotels And Resorts',
    chainCode: 'RD',
    masterChainCode: 'CW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Radisson',
    brandName: 'Radisson Hotels And Resorts',
    chainCode: 'RD',
    masterChainCode: 'CW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'RED Radisson',
    brandName: 'Radisson Hotels And Resorts',
    chainCode: 'RD',
    masterChainCode: 'CW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Park Plaza',
    brandName: 'Park Plaza Hotels And Resorts',
    chainCode: 'PK',
    masterChainCode: 'CW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'park inn',
    brandName: 'park inn',
    chainCode: 'PD',
    masterChainCode: 'CW',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Country Inn and Suites',
    brandName: 'Country Inn Carlson',
    chainCode: 'CX',
    masterChainCode: 'CW',
  },
  {
    loyaltyProgram: 'Leaders Club',
    loyaltyWebsite: 'https://www.lhw.com/leaders-club/about',
    nameOnWebsite: 'The Leading Hotels of the World',
    brandName: 'Leading Hotels Of The World',
    chainCode: 'LW',
    masterChainCode: 'LW',
  },
  {
    loyaltyProgram: 'Omni Select Guest',
    loyaltyWebsite: 'https://www.omnihotels.com/loyalty',
    nameOnWebsite: 'Omni Hotels & Resorts',
    brandName: 'Omni Hotels',
    chainCode: 'OM',
    masterChainCode: 'OM',
  },
  {
    loyaltyProgram: 'Fan of MO',
    loyaltyWebsite: 'https://www.mandarinoriental.com/fans-of-mo/benefits-enrolment',
    nameOnWebsite: 'Mandarin Oriental',
    brandName: 'Mandarin Oriental Hotels',
    chainCode: 'MO',
    masterChainCode: 'MO',
  },
  {
    loyaltyProgram: 'Golden Circle',
    loyaltyWebsite: 'https://www.shangri-la.com/en/corporate/golden-circle/',
    nameOnWebsite: 'Shangri-La Hotels and Resorts',
    brandName: 'Shangri La Hotels',
    chainCode: 'SG',
    masterChainCode: 'SG',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Kerry Hotels',
    brandName: 'Shangri La Hotels',
    chainCode: 'SG',
    masterChainCode: 'SG',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Traders Hotels',
    brandName: 'Shangri La Hotels',
    chainCode: 'SG',
    masterChainCode: 'SG',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'jen',
    brandName: 'Shangri La Hotels',
    chainCode: 'SG',
    masterChainCode: 'SG',
  },

  {
    loyaltyProgram: 'My Millennium',
    loyaltyWebsite: 'https://www.millenniumhotels.com/en/programmes/my-millennium/introduction/',
    nameOnWebsite: 'Millennium/Copthorne Hotels',
    brandName: 'Millennium/Copthorne Hotels',
    chainCode: 'MU',
    masterChainCode: 'MU',
  },

  {
    loyaltyProgram: 'Drury Rewards',
    loyaltyWebsite: 'https://www.druryhotels.com/druryrewards',
    nameOnWebsite: 'Drury Inn & Suites',
    brandName: 'Drury Inns Inc',
    chainCode: 'DR',
    masterChainCode: 'DR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Drury Plaza Hotels',
    brandName: 'Drury Inns Inc',
    chainCode: 'DR',
    masterChainCode: 'DR',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Pear Tree Inn',
    brandName: 'Drury Inns Inc',
    chainCode: 'DR',
    masterChainCode: 'DR',
  },

  {
    loyaltyProgram: 'Jumeirah One',
    loyaltyWebsite: 'https://www.jumeirah.com/en/guest-user',
    nameOnWebsite: 'Burj Al Arab',
    brandName: 'Jumeirah',
    chainCode: 'JT',
    masterChainCode: 'JT',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Jumeirah',
    brandName: 'Jumeirah',
    chainCode: 'JT',
    masterChainCode: 'JT',
  },

  {
    loyaltyProgram: 'Discovery',
    loyaltyWebsite: 'https://www.discoveryloyalty.com/',
    nameOnWebsite: 'Anantara Hotels.Resorts.Spas',
    brandName: 'Monir Hotels',
    chainCode: 'OH',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Atura Hotels',
    brandName: 'Monir Hotels',
    chainCode: 'OH',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Avani Hotels And Resorts',
    brandName: 'Monir Hotels',
    chainCode: 'OH',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Campbell Gray Hotels',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Capella Hotels and Resorts',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Corinthia Hotels',
    brandName: 'Corinthia Hotels',
    chainCode: 'IA',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Discovery Destinations Hotels and Resorts',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Divani Collection Hotels',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The Doyle Collection',
    brandName: 'The Doyle Collection',
    chainCode: 'JD',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'elewana Collection',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Fauchon Hospitality',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'GLO Hotels',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'HA Resorts and Hotels',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Kempinski Hoteleries',
    brandName: 'Kempinski Hotels',
    chainCode: 'KI',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The Leela Palaces',
    brandName: 'Leela Hotels',
    chainCode: 'LR',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Lungrand Collection',
    brandName: 'Small Luxury Htls of the World',
    chainCode: 'LX',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Marco Polo Hotels',
    brandName: 'Marco Polo Hotels',
    chainCode: 'MH',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Oaks',
    brandName: 'Monir Hotels',
    chainCode: 'OH',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Omni Hotels and Resorts',
    brandName: 'Omni Hotels',
    chainCode: 'OM',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Outrigger Resorts',
    brandName: 'Outrigger Hotels',
    chainCode: 'OR',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Pan PAcific',
    brandName: 'Pan Pacific Hotels',
    chainCode: 'PF',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Parkroyal',
    brandName: 'ParkRoyal Hotels',
    chainCode: 'PL',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Parkroyal Collection',
    brandName: 'ParkRoyal Hotels',
    chainCode: 'PL',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'QT',
    brandName: 'QT',
    chainCode: 'QT',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Rydges',
    brandName: 'Rydges Hotel Group',
    chainCode: 'RG',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'mysk',
    brandName: 'Global Hotel Alliance',
    chainCode: 'GA',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Niccolo',
    brandName: 'Niccolo',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Nikki Beach Hotels and Resorts',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Shaza Hotels',
    brandName: 'Global Hotel Alliance',
    chainCode: 'GA',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The Residence',
    brandName: 'Residence Inn',
    chainCode: 'RC',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'The Sukhothai',
    brandName: 'Small Luxury Htls of the World',
    chainCode: 'LX',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Thon',
    brandName: 'Thon Hotels',
    chainCode: 'TF',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Tivoli',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Ultratravel Collection',
    brandName: '',
    chainCode: '',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Viceroy',
    brandName: 'Viceroy Hotel Group',
    chainCode: 'VG',
    masterChainCode: 'OH',
  },
  {
    loyaltyProgram: 'M Life Rewards',
    loyaltyWebsite: 'https://www.mgmresorts.com/en/mlife-rewards-program.html',
    nameOnWebsite: 'MGM Resorts',
    brandName: 'MGM Resorts International',
    chainCode: 'MV',
    masterChainCode: 'MV',
  },

  {
    loyaltyProgram: 'Voila Hotel Rewards',
    loyaltyWebsite: 'https://www.vhr.com/',
    nameOnWebsite: 'acra city Hotel',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Atlantica Hotel Halifax',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Capitol Plaza Hotel',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Hearthstone Hospitality',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'L.E. Hotels rewards',
    brandName: 'L. E. Hotels',
    chainCode: 'LE',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Luzeiros Hotels',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Othon',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Park Perks',
    brandName: 'Vantis Hotel Group',
    chainCode: 'LM',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Redac Hotels Reward',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Swiss International Hotels & Resort',
    brandName: 'Swiss International',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Victor Hotels',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'VillaMorra Rewards',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Voilla Direct',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Experience Hotels',
    brandName: '',
    chainCode: '',
    masterChainCode: 'LE',
  },
  {
    loyaltyProgram: 'I Prefer Rewards',
    loyaltyWebsite: 'https://preferredhotels.com/',
    nameOnWebsite: 'Preferred Hotels and Resorts',
    brandName: 'Preferred Hotels and Resorts',
    chainCode: 'PH',
    masterChainCode: 'PH',
  },
  {
    loyaltyProgram: 'SLH Invited',
    loyaltyWebsite: 'https://slh.com/about-slh',
    nameOnWebsite: 'Small Luxury Hotels',
    brandName: 'Small Luxury Htls of the World',
    chainCode: 'LX',
    masterChainCode: 'LX',
  },

  {
    loyaltyProgram: 'Barcelo Pro Rewards',
    loyaltyWebsite: 'https://www.barcelo.com/en-us/',
    nameOnWebsite: 'Barcelo Hotels',
    brandName: 'Barcelo Hotels',
    chainCode: 'BN',
    masterChainCode: 'BN',
  },

  {
    loyaltyProgram: 'CQ Rewards',
    loyaltyWebsite: 'https://clubquartershotels.com/cqrewards',
    nameOnWebsite: 'Club Quarters',
    brandName: 'Club Quarters',
    chainCode: 'CQ',
    masterChainCode: 'CQ',
  },

  {
    loyaltyProgram: 'Flavours Instant Benefit',
    loyaltyWebsite: 'https://www.flavoursbenefit.com/en-us/your-benefits/',
    nameOnWebsite: 'Golen Tulip Hotels',
    brandName: 'Golen Tulip Hotels',
    chainCode: 'GT',
    masterChainCode: 'GT',
  },

  {
    loyaltyProgram: 'Hellow Rewards',
    loyaltyWebsite: 'https://www.redlion.com/our-brands',
    nameOnWebsite: 'Red Lion Hotels Corporation',
    brandName: 'Red Lion Hotels',
    chainCode: 'RL',
    masterChainCode: 'RL',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Guest House',
    brandName: '',
    chainCode: '',
    masterChainCode: 'RL',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Knights Inn',
    brandName: '',
    chainCode: '',
    masterChainCode: 'RL',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Americas Best Value Inn',
    brandName: 'Americans Best Value',
    chainCode: 'BV',
    masterChainCode: 'RL',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Signature Inn',
    brandName: '',
    chainCode: '',
    masterChainCode: 'RL',
  },
  {
    loyaltyProgram: '',
    loyaltyWebsite: '',
    nameOnWebsite: 'Canadas Best Value Inn',
    brandName: '',
    chainCode: '',
    masterChainCode: 'RL',
  },
  {
    loyaltyProgram: 'Leonardo Advantage CLUB',
    loyaltyWebsite: 'https://www.leonardo-hotels.com/',
    nameOnWebsite: 'Leonardo Hotels',
    brandName: 'Leonardo Hotels',
    chainCode: 'LD',
    masterChainCode: 'LD',
  },
  {
    loyaltyProgram: 'MeliaRewards',
    loyaltyWebsite: 'https://www.melia.com/en/home.htm',
    nameOnWebsite: 'Melia Hotels & Resorts',
    brandName: 'Melia Hotels Intl',
    chainCode: 'ME',
    masterChainCode: 'ME',
  },
  {
    loyaltyProgram: 'Scandic Friends',
    loyaltyWebsite: 'https://www.scandichotels.com/scandic-friends',
    nameOnWebsite: 'Scandic Hotels',
    brandName: 'Scandic Hotels',
    chainCode: 'SH',
    masterChainCode: 'SH',
  },
  {
    loyaltyProgram: 'TAJ Inner Circle Rewards & Privileges',
    loyaltyWebsite: 'https://www.tajhotels.com/en-in/tajinnercircle/participating-hotels/',
    nameOnWebsite: 'Taj Innercircle',
    brandName: 'Taj Hotels',
    chainCode: 'TJ',
    masterChainCode: 'TJ',
  },
];

export const hotelLoyaltyMap: Record<string, string> = hotelLoyaltyArray
  .filter((hotelDetail) => hotelDetail.loyaltyProgram)
  .reduce(
    (acc, hotel) => ({
      ...acc,
      [hotel.masterChainCode]: hotel.loyaltyProgram,
    }),
    {},
  );
