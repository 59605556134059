/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { OBTFeatureAction } from './obtfeature-action';

/**
 * The object encapsulating the config for the given context.
 * @export
 * @interface OBTFeaturePreferencesPreference
 */
export interface OBTFeaturePreferencesPreference {
  /**
   * The visibility for the given OBT feature.
   * @type {string}
   * @memberof OBTFeaturePreferencesPreference
   */
  visibility: OBTFeaturePreferencesPreferenceVisibilityEnum;

  /**
   * Requirement type for the given OBT feature.
   * @type {string}
   * @memberof OBTFeaturePreferencesPreference
   */
  requirementType?: OBTFeaturePreferencesPreferenceRequirementTypeEnum;
  /**
   *
   * @type {OBTFeatureAction}
   * @memberof OBTFeaturePreferencesPreference
   */
  action?: OBTFeatureAction;
}

export const OBTFeaturePreferencesPreferenceVisibilityEnum = {
  Enabled: 'ENABLED',
  Disabled: 'DISABLED',
  Hidden: 'HIDDEN',
} as const;

export type OBTFeaturePreferencesPreferenceVisibilityEnum =
  (typeof OBTFeaturePreferencesPreferenceVisibilityEnum)[keyof typeof OBTFeaturePreferencesPreferenceVisibilityEnum];

export const OBTFeaturePreferencesPreferenceRequirementTypeEnum = {
  Optional: 'OPTIONAL',
  Required: 'REQUIRED',
} as const;

export type OBTFeaturePreferencesPreferenceRequirementTypeEnum =
  (typeof OBTFeaturePreferencesPreferenceRequirementTypeEnum)[keyof typeof OBTFeaturePreferencesPreferenceRequirementTypeEnum];
