import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncAutocomplete } from '@spotnana/blocks/src/AsyncAutocomplete';
import { IAsyncAutocompleteProps } from '@spotnana/blocks/src/AsyncAutocomplete/AsyncAutocomplete';
import {
  AutocompleteOptionType,
  AutocompleteSelectionModes,
} from '@spotnana/blocks/src/Autocomplete/Autocomplete.types';
import {
  createUserNameFromFullName,
  getFullNameFromNameV2Object,
  useEntitySearchAutocompleteQuery,
  useFeatureFlag,
} from 'obt-common';
import {
  AutocompleteEntitySearchRequestEntitiesEnum,
  PaginationRequestParams,
  TravelerBasicInfo,
} from 'obt-common/types/api/v2/obt/model';
import { AutocompleteFilter } from 'obt-common/types/api/v2/obt/model/autocomplete-filter';
import TravelerPickerLoader from '../GroupBookingTravelerSelector/TravelerPickerLoader';
import TravelerPickerNoResults from '../GroupBookingTravelerSelector/TravelerPickerNoResults';
import { autocomplete_wrapper_styles } from './TravelerAutocomplete.styles';

export type TOption = TravelerBasicInfo & { label: string; value: string; type: AutocompleteOptionType };

interface IProps extends Partial<IAsyncAutocompleteProps<TravelerBasicInfo>> {
  onTravelerSelect: (value: TravelerBasicInfo | null) => void;
  filters?: AutocompleteFilter[] | undefined;
}

export const TravelerAutocomplete: FC<IProps> = ({ onTravelerSelect, filters, endAdornment, ...restProps }) => {
  const { t: tt } = useTranslation('WEB');
  const [queryString, setQueryString] = useState('');
  const isAutocompletePreferredNameEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');

  const entities: AutocompleteEntitySearchRequestEntitiesEnum[] = [
    AutocompleteEntitySearchRequestEntitiesEnum.Traveler,
  ];

  let paginationParams: PaginationRequestParams | undefined;

  const { data: options, isLoading } = useEntitySearchAutocompleteQuery(
    queryString,
    entities,
    filters,
    paginationParams,
  );

  const autocompleteOptions: Array<TOption> =
    options?.travelerBasicInfos?.map((basicInfo) => {
      let label = '';

      if (basicInfo.name) {
        label = isAutocompletePreferredNameEnabled
          ? createUserNameFromFullName(basicInfo.name)
          : getFullNameFromNameV2Object(basicInfo.name);
      }

      const value = basicInfo.userOrgId?.userId?.id ?? '';

      return {
        ...basicInfo,
        label,
        value,
        type: AutocompleteOptionType.STANDALONE,
      };
    }) ?? [];

  const handleTravelerSelection = (option: TravelerBasicInfo | null) => {
    onTravelerSelect(option);
    setQueryString('');
  };

  const placeholder = tt('Search by name or email');

  return (
    <div className="autocomplete-wrapper" css={autocomplete_wrapper_styles}>
      <AsyncAutocomplete<TravelerBasicInfo>
        isLoading={isLoading}
        setSearchString={setQueryString}
        selectionMode={AutocompleteSelectionModes.SINGLE}
        loadingContent={TravelerPickerLoader()}
        emptyContent={TravelerPickerNoResults()}
        // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-no-useless-fragment
        UnfocusedInputContent={() => <></>}
        onCancel={() => {
          setQueryString('');
        }}
        popoverProps={{ fixed: true }}
        {...restProps}
        onChange={(newOption) => handleTravelerSelection(newOption[0])}
        availableOptions={autocompleteOptions}
        aria-label={placeholder}
        placeholder={placeholder}
        endAdornment={queryString.length > 0 ? undefined : endAdornment}
      />
    </div>
  );
};
