import type { MoneyUtil } from '../utils/Money';
import type { DateTime } from './api/v1/common/date_time';
import type { ImageV1 } from './api/v1/common/image_group';
import type { Latlng } from './api/v1/common/latlng';
import type { Length } from './api/v1/common/length';
import type { DateTimeRange } from './api/v1/common/range';
import type {
  Amenities,
  CarType,
  EngineType,
  Vendor,
  CarExtraType,
  CarExtra,
  OperatingSchedule,
  Location,
  ContactInfo,
} from './api/v1/obt/car/car_common';
import type {
  CarFiltersEngineTypeEnum,
  CarSearchRequest,
  CarSearchRequestV2,
  Filters,
  FiltersTransmissionTypeEnum,
  FiltersV2,
  SortOptionSortBy,
  SortOptionSortOrder,
} from './api/v1/obt/car/car_search_request';
import type { CarInfo, CarSearchResponse, RateRefundable } from './api/v1/obt/car/car_search_response';
import type { Preference, PreferredType, RewardPointsEarned } from './api/v1/obt/common/common';
import type { NonProfileUserInfo, UserOrgId } from './api/v1/obt/common/user_org_id';
import type { CorporateInfo } from './api/v1/obt/policy/policy_common';
import type { CarCreatePnrRequest } from './api/v1/obt/trip/car_create_pnr_request';
import type { CarCreatePnrResponse } from './api/v1/obt/trip/car_create_pnr_response';
import type { PaymentSourceType } from './api/v2/obt/model';
import type { ICarSuggestion } from './autocomplete';
import type { BookingFlow, SharedSearchParams, TripIdUrlParam } from './common';
import type { FareTypeEnum, IPolicyInfo } from './flight';
import type { OperatingSchedule as OperatingScheduleV2 } from './api/v2/obt/model/operating-schedule';
import type { Latlng as LatlngV2 } from './api/v2/obt/model/latlng';
import type { CarLocationContactInfo } from './api/v2/obt/model/car-location-contact-info';

export { CarType as CarTypeEnum } from './api/v1/obt/car/car_common';
export type { Vendor as ICarVendor } from './api/v1/obt/car/car_common';
export { CarExtraType as CarExtraTypeEnum } from './api/v1/obt/car/car_common';
export { FiltersTransmissionTypeEnum } from './api/v1/obt/car/car_search_request';
export { RateRefundable as CarRefundable } from './api/v1/obt/car/car_search_response';
export type { CarRateDetailsResponse } from './api/v2/obt/model/car-rate-details-response';
export type { CarRateDetailsRequest } from './api/v2/obt/model/car-rate-details-request';
export type { CarAdditionalDetail } from './api/v2/obt/model/car-additional-detail';
export type { OperatingSchedule as OperatingScheduleV2 } from './api/v2/obt/model/operating-schedule';

export interface ILocationDetails {
  fullAddress: string;
  shortAddress: string;
  coordinates?: Latlng;
  operatingTimes: DateTimeRange[];
  weeklyOperatingSchedule?: OperatingSchedule[];
  counterLocation: string;
  contactInfo?: ContactInfo | CarLocationContactInfo;
}

export interface ICarLocationDetails {
  fullAddress: string;
  shortAddress: string;
  coordinates?: LatlngV2;
  operatingTimes?: OperatingScheduleV2;
  weeklyOperatingSchedule?: OperatingScheduleV2[];
  counterLocation?: string;
  contactInfo?: CarLocationContactInfo;
}
export interface ICarRate {
  label: string;
  price: MoneyUtil;
  extraMileageCharge: MoneyUtil;
  mileageAllowance: {
    length: number;
    unit: string;
  };
}

export interface IRateCancellationPolicy {
  amount?: MoneyUtil;
  percent?: number;
  hoursBefore?: number;
  absoluteDeadline?: DateTime;
}

export interface IPolicyDetails {
  mileage: string;
  payAt: string;
  cancellation: IRateCancellationPolicy[];
  rates: ICarRate[];
  refundable?: RateRefundable;
}

export interface ICarSummary {
  displayName: string;
  modelName: string;
  thumbnail: IThumbnailImage;
  type: string;
  vendor: Vendor;
  amenities: Amenities;
  approxCost: MoneyUtil;
  publishedTotalCost: MoneyUtil;
  pickupDetails: ILocationDetails;
  dropOffDetails: ILocationDetails;
  policyDetails: IPolicyDetails;
  policyViolationMessage: string;
  policyInfo?: IPolicyInfo;
  transmission: ITransmissionType;
  pickupDistance: Length | undefined;
  dropOffDistance: Length | undefined;
  /** @deprecated use preferences instead, and remove when the migration is done for mobile */
  preferredType: PreferredType[];
  negotiatedSavings: MoneyUtil;
  fareType: FareTypeEnum;
  pickupLocation?: Location;
  dropOffLocation?: Location;
  /**
   * dailyCostMobile and publishedCostMobile to be removed once showFareIncludingTaxesAndFees filter is in place for mobile
   */
  dailyCostMobile: MoneyUtil;
  publishedDailyCostMobile: MoneyUtil;
  dailyCostIncludingTaxes: MoneyUtil;
  dailyCostExcludingTaxes: MoneyUtil;
  publishedDailyCostIncludingTaxes: MoneyUtil;
  publishedDailyCostExcludingTaxes: MoneyUtil;
  preferences: Preference[];
  engineType: EngineType;
  rewardPointsEarned: RewardPointsEarned[];
  carExtras: CarExtra[];
  carRateDifference: MoneyUtil;
  carModifyAverageRateDifference: MoneyUtil;
  co2EmissionsValue?: number | undefined;
}

export interface IThumbnailImage {
  image?: ImageV1;
  caption?: string;
}

export interface IPnrRequestCarDetails {
  carId: string;
}

export interface ICarCheckoutRequestParams extends IPnrRequestCarDetails {
  searchId: string;
}

export interface ISearchParams {
  /**
   * This is for UI to pass in case of non-profile user.
   * Note: we shouldn't populate the user org id object.
   */
  nonProfileUserInfos?: NonProfileUserInfo[];
  /**
   * Can be empty for adhoc/guest bookings
   */
  userOrgIds?: UserOrgId[];
  pickup: ICarSuggestion | null;
  dropOff: ICarSuggestion | null;
  dateTime: { from: string; to: string };
}

export interface ICarSearchFormUrlParams extends SharedSearchParams {
  fromDate?: string;
  toDate?: string;
  isPersonalTrip?: boolean;
  pickup?: ICarSuggestion | null;
  dropOff?: ICarSuggestion | null;
  returnToSameLocation?: boolean;
  pickupDropoffAirportCode?: string;
}

/**
 * @deprecated
 */
export interface ICarFilterState {
  vehicleType: CarType[];
  vendors: string[];
  capacity: string[];
  excludeTransmissions: FiltersTransmissionTypeEnum[];
  preferredOnly: boolean;
}

export interface ICarFilterStateV2 {
  vehicleType: CarType[];
  vendors: string[];
  excludeTransmissions: FiltersTransmissionTypeEnum[];
  engineType: CarFiltersEngineTypeEnum[];
  rates: [number, number];
  numPassengers: boolean;
  hasAirConditioning: boolean;
  carExtraType: CarExtraType[];
  hasUnlimitedMileage: boolean;
  preferredOnly: boolean;
  virtualPaymentOnly: boolean;
}

export interface ICarSearchErrorState {
  pickup: string;
  dropOff: string;
  from: string;
  to: string;
}

export enum ITransmissionType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  UNKNOWN = 'UNKNOWN',
}

export type ICarCreatePnrResponse = CarCreatePnrResponse;

export interface ICarSearchState {
  carSearchResponse: CarSearchResponse | null;
  loading: boolean;
  carCreatePnrResponse: CarCreatePnrResponse | null;
  carId: string;
  pickupLocation: string;
  dropOffLocation: string;
}

/**
 * Params Interface and Typeguard
 */
export interface ICarSearchParams extends ISearchParams {
  bookingFlow: BookingFlow;
  filters?: ICarFilterState;
  filtersV2?: ICarFilterStateV2;
  sortType?: SortOptionSortBy;
  corporateInfo?: CorporateInfo;
}

export const isLatlng = (data: Latlng | string): data is Latlng => (data as Latlng).latitude !== undefined;

interface CarSearchResultsFEOnlyURLParams {
  tripId?: TripIdUrlParam;
  isPreselectedTrip?: boolean;
  pickupLocation: ICarSuggestion;
  dropOffLocation: ICarSuggestion;
  isPersonalTravel?: boolean;
  utm_source?: string;
  bookingFlow: BookingFlow;
  registrarId?: string;
  userId?: string;
  searchId?: string;
  disableSearchSummary?: boolean;
  pnrId?: string;
  isCarModifyFlow?: boolean;
}

export type ICarSearchResultsUrlParams = CarSearchRequest & CarSearchResultsFEOnlyURLParams;

export type ICarSearchResultsUrlParamsV2 = CarSearchRequestV2 & CarSearchResultsFEOnlyURLParams;

export interface ICarCheckoutUrlParams extends ICarSearchResultsUrlParamsV2 {
  carId: string;
  paymentSourceId?: string;
  paymentSourceType?: PaymentSourceType;
}

export type ICarSearchResponse = CarSearchResponse;
export type ICarSearchRequest = CarSearchRequest | CarSearchRequestV2;
export type ICarCreatePnrRequest = CarCreatePnrRequest;
export type ICarType = CarType;
export type ICarEngineType = EngineType;
export type ICarExtraType = CarExtraType;
export type ICarSortOptionSortOrder = SortOptionSortOrder;
export type ICarSearchRequestFilters = Filters;
export type ICarSearchRequestFiltersV2 = FiltersV2;
export type ICarInfo = CarInfo;
export type IAmenities = Amenities;

export type ICarSearchRequestParams = Omit<ICarSearchResultsUrlParams, 'userOrgIds'>;

export interface ICarTypeKeyLabelPair {
  backendKey: CarType;
  labelKey: string;
}

export interface ICarSearchPaxInfo {
  userId: string | undefined;
}

export interface ICarSearchResultsParams extends ICarSearchResultsUrlParamsV2 {
  passengers: ICarSearchPaxInfo[];
}
