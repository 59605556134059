import type { SiteMessage } from '../types/api/v2/obt/model/site-message';
import type { ISiteMessageJsonMessage } from './types';

export const parseSiteMessage = (message: SiteMessage): ISiteMessageJsonMessage => {
  return JSON.parse(message.messageJson);
};

export const stringifySiteMessage = (message: ISiteMessageJsonMessage): string => {
  return JSON.stringify(message);
};
