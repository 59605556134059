import {
  setTmcId,
  extractTmcIdFromUrl,
  extractApplicationIdFromUrl,
  fetchStorageValue,
  StorageKeys,
  removeTmcId,
} from 'obt-common';
import { ROUTE_PATHS } from '../../routes';
import { captureException, addBreadcrumb } from '../errorTracking';

export async function collectTmcId(): Promise<void> {
  const { search, pathname } = window?.location ?? {};

  try {
    const tmcId = extractTmcIdFromUrl();
    const applicationId = extractApplicationIdFromUrl();
    const appIdFromStorage = await fetchStorageValue<string>(StorageKeys.APPLICATION_ID).catch(() => null);

    if (tmcId) {
      await setTmcId(tmcId);
    } else {
      // If appId exist in URL different from localStorage appId AND no tmcId
      // then remove existing tmcId from localStorage
      const applicationIdMismatch = applicationId && applicationId !== appIdFromStorage;

      // If there is no tmcId in the headless auth request, remove from storage
      // so it doesn't interfere with the user's subsequent backend requests.
      const isHeadlessAuth = pathname.endsWith(ROUTE_PATHS.HEADLESS_AUTH);

      if (applicationIdMismatch || isHeadlessAuth) {
        await removeTmcId();
      }
    }

    addBreadcrumb({
      message: 'Extracted tmcId from URL',
      data: { tmcId, locationSearch: search },
    });
  } catch (err) {
    captureException(err, { source: 'collectTmcId', extra: { locationSearch: search } });
  }
}
