import type { DocumentPickerResponse } from 'react-native-document-picker';
import type { UploadDocumentResponse } from '../../types/api/v1/obt/document/document_service';
import { defineCommonMessage } from '../../translations/defineMessage';
import type { ReportIssueRequest, ReportIssueRequestReportedItineraryId } from '../../types';

export const sourceTypeOptions = [
  {
    label: defineCommonMessage('Website').message,
    value: 'Website',
    description: defineCommonMessage(`e.g. booking.com, google flights...`).message,
  },
  {
    label: defineCommonMessage('Booking tool').message,
    value: 'Booking tool',
    description: defineCommonMessage('e.g. Concur, Navan...').message,
  },
];

export const bookingToolOptions = [
  'Concur',
  'Navan',
  'Egencia',
  'Travel Perk',
  'Travel Bank',
  'MakeMyTrip',
  'Other',
].map((option) => ({ label: option, value: option }));

export interface IHighFareReport {
  sourceType: string;
  link: string;
  bookingTool: string;
  seenFare: string;
  comment: string;
  screenshots: File[];
}

export interface IHighFareReportMobile {
  sourceType: string;
  link: string;
  bookingTool: string;
  seenFare: string;
  comment: string;
  screenshots: DocumentPickerResponse[];
}

export function checkIfFormComplete(highFareReport: IHighFareReport | IHighFareReportMobile): boolean {
  if (!highFareReport.seenFare) {
    return false;
  }
  if (highFareReport.sourceType === 'Website') {
    return !!highFareReport.link;
  }
  return !!highFareReport.bookingTool;
}

export function getReportHighFareRequest(
  highFareReport: IHighFareReport | IHighFareReportMobile,
  screenshotsDocuments: UploadDocumentResponse[],
  searchId: string,
  rateOptionId: string,
  currencyCode: string,
): ReportIssueRequest {
  const itineraryId: ReportIssueRequestReportedItineraryId = {
    airItineraryId: {
      searchId,
      itineraryId: rateOptionId,
    },
  };
  const expectedFare = {
    currencyCode,
    amount: parseFloat(highFareReport.seenFare),
  };
  let source;
  if (highFareReport.sourceType === 'Website') {
    source = {
      website: {
        url: highFareReport.link,
      },
    };
  } else {
    source = {
      bookingTool: {
        name: highFareReport.bookingTool,
      },
    };
  }
  return {
    itineraryId,
    issue: {
      highFareIssue: {
        expectedFare,
        source,
      },
    },
    attachments: screenshotsDocuments.map(({ documentId }) => {
      return {
        documentId,
      };
    }),
    comment: highFareReport.comment,
  };
}
