import { useTelemetry } from '../hooks/useTelemetry';
import useMountEffect from '../../hooks/useMountEffect';

export const UserIdentityTelemetry = (): JSX.Element | null => {
  const { trackUser } = useTelemetry();
  useMountEffect(() => {
    trackUser({});
  });
  return null;
};
