import { Select as SelectBase, type SelectProps } from '@ariakit/react';
import clsx from 'clsx';

import { ellipsis } from 'polished';
import { type RefObject, useRef } from 'react';
import { Typography } from '../../Typography';
import { Arrow } from '../Arrow';
import { ClearButton } from '../ClearButton';
import { useSelectConfig } from '../SelectConfigContext';

export function SelectSingleValue({ placeholder }: { placeholder?: string }) {
  const {
    selectStore,
    disabled = false,
    state,
    showClearButton,
    size,
    triggerRef,
    helperText,
    labelId,
    required,
  } = useSelectConfig<'select', 'single'>();
  const value = selectStore.getSelection(selectStore.useState('value'));
  const renderedValue = value?.label ?? placeholder;
  const isPlaceholder = renderedValue === placeholder;
  const clearBtnRef = useRef<HTMLButtonElement>(null);
  const handleClear = () => {
    selectStore.clearSelection?.();
    selectStore.setOpen(false);
  };

  const handleClick: SelectProps['toggleOnClick'] = (event) => {
    const hasEventOriginatedInsideClearButton = !!clearBtnRef.current?.contains(event.target as HTMLButtonElement);
    return !hasEventOriginatedInsideClearButton;
  };

  const isClearButtonVisible = !!value && showClearButton;
  const descriptionProps = helperText ? { 'aria-describedby': `${labelId}-description` } : {};

  return (
    <SelectBase
      ref={triggerRef as RefObject<HTMLButtonElement>}
      store={selectStore}
      disabled={disabled}
      className={clsx('BlocksSelect-Trigger', `BlocksSelect-${size}`, {
        'is-placeholder': isPlaceholder,
        [`state-${state}`]: Boolean(state),
      })}
      toggleOnClick={handleClick}
      {...descriptionProps}
      aria-required={required}
    >
      <SelectSingleValueInternal placeholder={placeholder} />
      <div className={clsx({ 'Select-Actions': true, 'Select-Right': true })}>
        {isClearButtonVisible && <ClearButton ref={clearBtnRef} onClick={handleClear} />}
        <Arrow store={selectStore} />
      </div>
    </SelectBase>
  );
}

function SelectSingleValueInternal({ placeholder, className }: { placeholder?: string; className?: string }) {
  const { selectStore, ValueRenderer, size } = useSelectConfig<'select', 'single'>();
  const value = selectStore.getSelection(selectStore.useState('value'));
  const renderedValue = value?.label ?? placeholder;

  const defaultContent = (
    <Typography
      as="span"
      variant={size === 'standard' ? 'body1' : 'body2'}
      kind={value ? 'medium' : 'regular'}
      className={className}
    >
      {renderedValue}
    </Typography>
  );

  const renderContent = () => {
    if (ValueRenderer) {
      return value ? <ValueRenderer option={value} /> : defaultContent;
    }
    return defaultContent;
  };

  return <div style={ellipsis('90%')}>{renderContent()}</div>;
}
