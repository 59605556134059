export const initLocatorUI = async (): Promise<void> => {
  try {
    if (process.env.NODE_ENV === 'development') {
      const setupLocatorUI = await import('@locator/runtime');
      setupLocatorUI.default();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Cannot initialize locator ui, error:', err);
  }
};
