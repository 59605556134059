import { useTranslation } from 'react-i18next';
import { IconButton } from '../IconButton';
import { useToastActions } from './ToastActionProvider';

interface IProps {
  onClose?: () => void;
}

export const ToastDismissButton = ({ onClose }: IProps) => {
  const { t: tt } = useTranslation('WEB');
  const { dismissToast } = useToastActions();
  return (
    <IconButton
      icon="CloseCross"
      size="medium"
      kind="inverse"
      onClick={() => {
        dismissToast();
        onClose?.();
      }}
      className="BlocksToast-actionButton"
      tooltip={tt('Close')}
    />
  );
};
