/* eslint-disable */
import type { Card, CreditCardAccess } from '../../common/card';
import type { FareAmount } from '../../common/fare_amount';
import { Money, PaymentMethod, FareComponent } from '../../common/money';
import type { PaymentGateway } from '../../payment/service';
import type { Commission } from '../common/commission';
import type { CardInfo, VendorCardInfo } from '../profile/payments/virtual_card';

/**
 * This will contain the total fare of goods along with other charges like service fee.
 * The price break down of goods should be added in PNR details, like airPnr.
 */
export interface Payment {
  /** The list of traveler indices on which this payment applies. */
  travelerIndices: number[];
  /** Total amount. */
  amount?: FareAmount;
  /** Information about form of payment to be used to charge customer. */
  fop?: PaymentFOP;
  /** Reference number for original payment made for this service. */
  paymentReference: string;
  /** Service for which this payment is made. */
  paymentType: PaymentPaymentType;
  /** Third party who is processing the payment. */
  paymentThirdParty: PaymentPaymentThirdParty;
  /** Unique identifier for this payment within the CTC/COGS object. */
  paymentId: string;
  /** The payment gateway used to make the payment. */
  paymentGateway: PaymentGateway;
  /** Account ID of the payment gateway (i.e us:1, uk:1, etc) */
  gatewayAccountId: string;
  /** Whether the above amount is issued or refunded to the customer. */
  isRefunded: boolean;
}

export enum SplitType {
  SPLIT_TYPE_UNKNOWN = 0,
  POINTS = 1,
  CASH = 2,
  UNRECOGNIZED = -1,
}

export interface SelectedPayment {
  /** ID of the payment source */
  paymentSourceId: string;
  /** Total amount to be charged for specified payment items */
  amount?: Money;
  /** CVV for the card provided by the user in case it is not already known. */
  cvv?: string;
  /** Post payment redirection url if payment source navigates user to a third party page */
  postPaymentRedirectionUrl?: string;
}

export enum ItemType {
  ITEM_TYPE_UNKNOWN = 0,
  SERVICE_FEE = 1,
  TRAVEL_TICKET = 2,
  SEAT = 3,
  BAGGAGE = 4,
  EARLY_BIRD = 5,
  UNRECOGNIZED = -1,
}

export interface PaymentItem {
  /** Type of payment item like flight ticket, hotel reservation, service fee etc. */
  itemType: ItemType;
  /** Fare component of payment item, like base, tax etc. */
  fareComponent: FareComponent[];
}

export interface SelectedFoP {
  /** Payment items eligible for selected payment rules */
  paymentItems: PaymentItem[];
  /** List of payment rules used to pay for given payment items */
  selectedPayments: SelectedPayment[];
}

export interface BookingTravelerPaymentDetails {
  /** List of different forms of payments used to pay for the booking */
  selectedFops: SelectedFoP[];
}

export interface BookingPaymentDetails {
  /** List of different forms of payments used to pay for the booking for multiple travelers */
  bookingTravelerPaymentDetails: BookingTravelerPaymentDetails[];
}

export enum PaymentPaymentType {
  UNKNOWN = 0,
  FLIGHTS = 1,
  SERVICE_FEE = 2,
  MISCELLANEOUS = 3,
  HOTELS = 4,
  CARS = 5,
  RAILS = 6,
  LIMO = 7,
  MISC = 8,
  UNRECOGNIZED = -1,
}

export enum PaymentPaymentThirdParty {
  UNKNOWN_PARTY = 0,
  STRIPE = 1,
  TFPAY = 2,
  TRAINLINE = 3,
  BREX = 4,
  UNRECOGNIZED = -1,
}

/** Form of Payment */
export interface PaymentFOP {
  /**
   * Type of payment used.
   *
   * @deprecated
   */
  type?: PaymentFOPType;
  /** The payment card to be used to charge customer. This is only set if the payment type is 'CARD'. */
  card?: Card;
  /** Additional info to be added if payment type is 'UNKNOWN'. */
  additionalInfo?: string;
  /** The method of payment used. */
  paymentMethod: PaymentMethod;
  /** Credit card access type. */
  accessType?: CreditCardAccess;
  /** Metadata related to payment. */
  paymentMetadata?: PaymentMetadata;
  /** Type of payment source. */
  paymentSourceType?: PaymentSourceType;
}

/** Type of payment used. Deprecated now, use 'payment_method' instead. */
export enum PaymentFOPType {
  UNKNOWN = 0,
  CARD = 1,
  CASH = 2,
  TFPAY = 3,
  CHEQUE = 4,
  BREX_POINTS = 5,
  QANTAS_POINTS = 6,
  UNRECOGNIZED = -1,
}

export enum PaymentSourceType {
  TYPE_UNKNOWN = 0,
  /** CARD - Normal credit/debit card type. */
  CARD = 1,
  /** VIRTUAL_CARD - Virtual card type. */
  VIRTUAL_CARD = 2,
  /** REWARDS_PROGRAM - Rewards program type. */
  REWARDS_PROGRAM = 4,
  /** DELAYED_INVOICING - Delayed invoicing type. */
  DELAYED_INVOICING = 5,
  /** CUSTOM_PAYMENT_METHOD - Custom payment method */
  CUSTOM_PAYMENT_METHOD = 6,
  /** VENDOR_PROGRAM_PAYMENT - Vendor specific payment methods eg. -  Car Direct billing. */
  VENDOR_PROGRAM_PAYMENT = 7,
  /** UNUSED_CREDIT - Unused credit type. */
  UNUSED_CREDIT = 8,
  UNRECOGNIZED = -1,
}

export interface PaymentMetadata {
  /** Metadata related to virtual card. */
  virtualCardMetadata?: PaymentMetadataVirtualCardMetadata | undefined;
  /** Metadata related to custom payment method. */
  customPaymentMethodMetadata?: PaymentMetadataCustomPaymentMethodMetadata | undefined;
  /** Metadata related to vendor program payment source. */
  vendorProgramPaymentMetadata?: PaymentMetadataVendorProgramPaymentMetadata | undefined;
}

export interface PaymentMetadataVirtualCardMetadata {
  vendorInfo?: VendorCardInfo;
  cardInfo?: CardInfo;
  /** Payment instruction id set during addition of virtual card payment source. */
  paymentInstructionId: string;
  /** Virtual cards are revealed to travellers only 24hrs before check-in. */
  shouldReveal: boolean;
}

/** Custom payment method like brex budget etc. */
export interface PaymentMetadataCustomPaymentMethodMetadata {
  brexBudgetMetadata?: PaymentMetadataCustomPaymentMethodMetadataBrexBudgetMetadata | undefined;
}

/**
 * Metadata for Brex budget
 * Bool flag is needed to determine if it was paid by budget card or personal card.
 */
export interface PaymentMetadataCustomPaymentMethodMetadataBrexBudgetMetadata {
  budgetName: string;
  paidByPersonalCard: boolean;
}

export interface PaymentMetadataVendorProgramPaymentMetadata {
  directBilling?: PaymentMetadataVendorProgramPaymentMetadataDirectBilling | undefined;
}

/** DirectBilling details required by supplier to pass on to different workflows like car-create-pnr etc. */
export interface PaymentMetadataVendorProgramPaymentMetadataDirectBilling {
  /** Unique vendor id/code. */
  vendorId: string;
  /** Direct billing code provided by the vendor. */
  directBillingCode: string;
  /** Discount code provided by the vendor. */
  corporateDiscountCode: string;
}

/** Cost of goods sold. */
export interface COGS {
  /**
   * Potentially multiple payments that spotnana will pay to vendors for this
   * booking.
   */
  payments: Payment[];
}

/** Cost to customer. */
export interface CTC {
  /** Potentially multiple payments that spotnana will charge the clients. */
  payments: Payment[];
}

/**
 * The CTC for each good/item or component such as service fee, ticket, hotel
 * etc charged to the customer.
 */
export interface ItemCTC {
  /** The list of all the charges billed to the customer for this item/good. */
  cost: ItemCTCCost[];
}

export interface ItemCTCCost {
  /** Payment id as present in the overall CTC for the PNR. */
  paymentId: string;
  /** The share of this cost in the above payment reference captured. */
  amount?: FareAmount;
  /** Whether the above amount is issued or refunded to the customer. */
  isRefunded: boolean;
}

/** The payment information required by suppliers like Sabre, NDC etc. */
export interface SupplierPaymentInfo {
  /** The pass through FoP that is required by some GDS suppliers. */
  passThroughFop?: PaymentFOP;
}

/** Payment related information for the ticket. */
export interface PaymentDetails {
  /** Total amount. */
  amount?: FareAmount;
  /**
   * Contains the id of the payment source to be used for this payment.
   * Front-end can pass just this field to identify the payment source.
   * This may not be populated for older PNRs. In that case, fop should be used.
   */
  selectedPaymentSource?: SelectedPaymentSource;
  /** Information about form of payment to be used to charge customer. */
  fop?: PaymentFOP;
  /** Whether the above amount is issued or refunded to the customer. */
  isRefunded?: boolean;
}

/** Service fee on a booking. */
export interface ServiceFee {
  /** Amount charged for the service fee. */
  fare?: FareAmount;
  /** The ticket number. */
  ticketNumber: string;
  /** Status for the associated ticket. */
  status: ServiceFeeStatus;
  /** The commission for the service fee. */
  commission?: Commission;
  /**
   * The service fee is associated to a particular traveler. The traveler_index
   * is the index of this traveler in the traveler list present in the pnr.
   */
  travelerIndex: number;
  /** FOP for the service fee. */
  paymentDetails: PaymentDetails[];
  /** Show/hide service fee to traveler */
  visibleToTraveler: boolean;
}

export enum ServiceFeeStatus {
  UNKNOWN = 0,
  ISSUED = 1,
  VOIDED = 2,
  UNRECOGNIZED = -1,
}

/**
 * Additional metadata associated with a specific module for the booking.
 * Example - for Air PNRs, a module is a ticket.
 */
export interface ModuleCTCMetadata {
  module: ModuleCTCMetadataModule[];
}

export interface ModuleCTCMetadataModule {
  /**
   * The module id uniquely identifying the item/good for which this cost is
   * charged to the customer. Example - for Air PNRs, module id is the ticket
   * number.
   */
  id: string;
  /** The CTC information for the module. */
  ctc?: ItemCTC;
}

export interface GossiperPnrMetadataUpdateEvent {
  eventMetadata?: GossiperPnrMetadataUpdateEventEventMetadata;
  /** Cost to the customer for this PNR. */
  costToCustomer?: CTC;
  /** Cost to the customer per module for this PNR. */
  moduleCtcMetadata?: ModuleCTCMetadata;
}

/** Metadata associated with this 'PNR Metadata Update Event'. */
export interface GossiperPnrMetadataUpdateEventEventMetadata {
  /** The trip id for this booking. */
  tripId: string;
  /** The PNR id for this booking. */
  pnrId: string;
}

/** The payment source selected by the user for the booking. */
export interface SelectedPaymentSource {
  /** Id of the payment source selected by the user for the booking. */
  paymentSourceId?: string;
  /** CVV for the card provided by the user in case it is not already known. */
  cvv?: string;

  postPaymentRedirectionUrl?: string;
}

export interface PaymentSourcePrePaymentInformation {
  /** ID of the source of payment */
  paymentSourceId: string;
  /** ID of the initialization request of a payment source (ex: PP ID for Amadeus Checkout initialization) */
  paymentRequestId: string;
}

export interface FareSplit {
  /** The pass through FoP required by suppliers. */
  passThroughFop?: PaymentFOP;
  /** Total amount to be charged for specified payment items */
  amount?: Money;
}

export interface ItemFop {
  /** Payment items eligible for corresponding fare splits */
  paymentItems: PaymentItem[];
  /** List of different forms of payments used to pay for given payment items */
  fareSplits: FareSplit[];
}

export interface SupplierTravelerPaymentDetails {
  /** List of different payment items and their corresponding form of payments */
  itemsFopList: ItemFop[];
}

export interface SupplierPaymentDetails {
  /** List of different payment items and their corresponding form of payments for multiple travelers */
  supplierTravelerPaymentDetails: SupplierTravelerPaymentDetails[];
}

export interface PaymentSplitDetails {
  /** Payment amount details if slider payment function is linear. */
  minMaxAmount?: MinMaxAmount | undefined;
  /** Payment amount details if slider payment function is non-linear. */
  splitAmount?: SplitAmount | undefined;
}

export interface MinMaxAmount {
  /** Minimum amount that must be charged using given payment sources. */
  minLimit?: Money;
  /** Maximum amount that could be charged using given payment sources. */
  maxLimit?: Money;
}

export interface SplitAmount {
  /** List of split amounts required for slider at checkout. */
  amount: Money[];
}

export interface SupplierPaymentResponse {
  /** List of steps payments required for showing slider at checkout. */
  splitPayments: SplitPayment[];
}

/** Represents a single step in the points slider. */
export interface SplitPayment {
  /** The amount in points to be paid at this step of the slider. */
  points?: Money;
  /** The amount in cash to be paid at this step of the slider. */
  cash?: Money;
}

export interface PaymentRule {
  /**
   * List of payment sources ids which could be used to pay for specified payment items.
   * For now, only one of them can be selected. We don't support split between these for now.
   */
  paymentSourceIds: string[];
  /** Minimum amount that must be charged using given payment sources. */
  minLimit?: Money;
  /** Maximum amount that could be charged using given payment sources. */
  maxLimit?: Money;
  /** Payment details of slider payment function. */
  paymentSplitDetails?: PaymentSplitDetails;
}

export interface Voucher {
  /** Amount in the voucher. */
  amount?: Money;
}
