import { useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Button } from '@spotnana/blocks/src/Button';
import { IconButton } from '@spotnana/blocks/src/IconButton';

import DOMPurify from 'dompurify';

import { HTML_REGEXP } from 'obt-common';
import React from 'react';
import useTruncatedElement from 'src/hooks/useTruncatedElement';
import { Link } from '@spotnana/blocks/src/Link';
import {
  banner_message,
  banner_message_title,
  banner_message_button,
  banner_message_close_icon,
  banner_message_container,
  banner_message_clamp,
  banner_message_expand,
} from './BannerMessage.styles';

interface IBannerProps {
  title?: string;
  body: string;
  learnMoreLink: string | undefined;
  onDismiss: () => void;
}

export const BannerMessage = ({ title, body, learnMoreLink, onDismiss }: IBannerProps) => {
  const { t: tt } = useTranslation('WEB');
  const ref = React.useRef(null);
  const isHtmlString = HTML_REGEXP.test(body);

  const { isTruncated, isReadingMore, setIsReadingMore } = useTruncatedElement(ref);

  return (
    <div css={banner_message_container}>
      <div css={banner_message}>
        <div>
          <div ref={ref} css={!isReadingMore ? banner_message_clamp : undefined}>
            <Typography variant="body1" color="inverse" kind="semibold" css={banner_message_title}>
              {title}
            </Typography>
            {isHtmlString ? (
              <Typography variant="body3" color="inverse">
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body, { ADD_ATTR: ['target'] }) }} />
              </Typography>
            ) : (
              <Typography variant="body3" color="inverse">
                {body}
              </Typography>
            )}
          </div>
          {isTruncated && !isReadingMore && (
            <div css={banner_message_expand}>
              <Typography variant="body3" color="inverse" underline>
                <Link color="inverse" onClick={() => setIsReadingMore(true)}>
                  {tt('Expand')}
                </Link>
              </Typography>
            </div>
          )}
        </div>
        <div>
          {learnMoreLink && (
            <a target="_blank" href={learnMoreLink} rel="noreferrer" tabIndex={-1}>
              <Button css={banner_message_button} size="small">
                {tt('Learn more')}
              </Button>
            </a>
          )}
        </div>
      </div>

      <IconButton
        kind="subtle"
        size="medium"
        icon="CloseCross"
        css={banner_message_close_icon}
        onClick={onDismiss}
        aria-label={tt('Close')}
      />
    </div>
  );
};
