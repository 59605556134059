import { CssVarsThemeOptions, experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { muiDefaultProps_Button, muiStyleOverrides_Button } from '../Button/config';
import { muiStyleOverrides_Tooltip } from '../Tooltip/Tooltip.styles';
import { getBlocksPaletteFromMui } from '../utils/general';
import { BREAKPOINT_CONFIG_NUMBER } from '../utils/styling/responsive';
import { InternalBlocksTheme, makeBlocksTheme } from './blocksTheme';
import { INJECTED_THEME_PROPERTYNAME } from './constants';
import { makeMuiPalette } from './ui-specs/muiPaletteOverrides';
import { makeMuiTypographySpec } from './ui-specs/typography';

export type Primitive = string | number | boolean | bigint | symbol | undefined | null;

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3?: true;
    display?: true;
    h6?: false;
    caption?: false;
    overline?: false;
    subtitle1?: false;
    subtitle2?: false;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
    large: true;
  }
}

export const makeMuiTheme: (blocksTheme: InternalBlocksTheme) => CssVarsThemeOptions = (blocksTheme) => ({
  cssVarPrefix: 'spotnanaBlocks-',

  breakpoints: {
    values: BREAKPOINT_CONFIG_NUMBER,
  },

  // https://mui.com/material-ui/experimental-api/css-theme-variables/customization/#components
  components: {
    MuiButton: {
      defaultProps: muiDefaultProps_Button,
      styleOverrides: muiStyleOverrides_Button,
    },
    MuiTooltip: {
      defaultProps: {
        arrow: false,
      },
      styleOverrides: muiStyleOverrides_Tooltip,
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: ({ theme }) => {
          return {
            backgroundColor: getBlocksPaletteFromMui(theme).bg.tooltip,
            borderRadius: '4px',
          };
        },
      },
    },
    MuiPaper: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: getBlocksPaletteFromMui(theme).surface.base,
            border: `1px solid ${getBlocksPaletteFromMui(theme).border.medium}`,
          };
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            padding: '0.75rem 1rem',
            '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
              background: getBlocksPaletteFromMui(theme).surface.background,
            },
            '&.Mui-selected': {
              background: 'unset',
            },
            '&.Blocks-selected': {
              background: getBlocksPaletteFromMui(theme).bg.infoSubtle,
            },
            '&.Blocks-hidden': {
              display: 'none',
            },
            ':hover, &.Mui-selected:hover': {
              backgroundColor: getBlocksPaletteFromMui(theme).surface.background,
            },
          };
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: ({ theme }) => ({
          padding: '0.75rem 1rem',
          '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
            background: getBlocksPaletteFromMui(theme).surface.background,
          },
          '&.Mui-selected, &.MuiAutocomplete-option[aria-selected="true"], &.MuiAutocomplete-option[aria-selected="true"].Mui-focused':
            {
              background: getBlocksPaletteFromMui(theme).bg.infoSubtle,
            },
          ':hover, &.Mui-selected:hover': {
            backgroundColor: getBlocksPaletteFromMui(theme).surface.background,
          },
        }),
      },
    },
  },

  // we can write any number of colour schemes, not just limited to light & dark, however it is preferred
  // to let consumers override the entire theme config than have a new colorScheme per consumer
  colorSchemes: {
    // no dark theme config yet, will be added eventually
    dark: {
      palette: makeMuiPalette('dark', blocksTheme),
    },
    light: {
      palette: makeMuiPalette('light', blocksTheme),
    },
  },
  typography: makeMuiTypographySpec(blocksTheme),
  shape: { borderRadius: 8 },

  zIndex: blocksTheme.zIndex,
});

const makeInjectedTheme = (theme: InternalBlocksTheme) =>
  Object.assign(makeMuiTheme(theme), {
    /**
     * 🚨🚨🚨
     *  WARNING: The property _blocksTheme should NEVER be renamed, it will break the entire package and all styling.
     * 🚨🚨🚨
     * This is purposely left out of CssVarsThemeOptions as it is only used in context of emotion and custom components, not with MUI
     * We are simply injecting it in JavaScript scope of emotion theme to be able to use it with useTheme hook and `@emotion/css`
     */
    [INJECTED_THEME_PROPERTYNAME]: theme,
  });

/**
 *  We let consumers extend our basic config but encapsulate it in our own factory to have control over technology, basic options, etc
 * @param blocksTheme CssVarsThemeOptions to override default spotnana config
 * @returns CssVarsTheme
 */
export const makeTheme = (
  theme: InternalBlocksTheme = makeBlocksTheme(),
  overrideOptions?: CssVarsThemeOptions,
  ignoreBase = false,
) => extendMuiTheme(deepmerge(ignoreBase ? {} : makeInjectedTheme(theme), overrideOptions));

export type TMuiThemeWithInjectedBlocksConfig = ReturnType<typeof makeInjectedTheme>;
