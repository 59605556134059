import { ThirdPartySourceEnum } from '../../types/api/v1/obt/supplier/third_party_info';

export const getItinerarySourceLabelAir = (val: ThirdPartySourceEnum, airline: string): string => {
  switch (val) {
    case ThirdPartySourceEnum.UNKNOWN_SOURCE:
    case ThirdPartySourceEnum.UNRECOGNIZED:
      return '';
    case ThirdPartySourceEnum.SABRE:
      return 'Sabre';
    case ThirdPartySourceEnum.TRAVEL_FUSION:
      return 'TF';
    case ThirdPartySourceEnum.AVIA:
      return 'AVIA';
    case ThirdPartySourceEnum.NDC:
    case ThirdPartySourceEnum.FARELOGIX_NDC:
      return airline === 'SABRE_NDC_AIRLINES' ? 'SABRE_NDC' : 'NDC';
    case ThirdPartySourceEnum.TRAINLINE:
      return 'TRNLNE';
    case ThirdPartySourceEnum.ATPCO_NDC:
      return 'ATPCO';
    case ThirdPartySourceEnum.OFFLINE:
      return 'Offline';
    case ThirdPartySourceEnum.CONNEXUS:
      return 'CNXS';
    case ThirdPartySourceEnum.ROUTEHAPPY:
      return 'RH';
    case ThirdPartySourceEnum.AMADEUS:
      return 'Amadeus';
    case ThirdPartySourceEnum.GIATA:
      return 'GIATA';
    case ThirdPartySourceEnum.QBR:
      return 'QBR';
    case ThirdPartySourceEnum.BCD:
      return 'BCD';
    case ThirdPartySourceEnum.SOUTHWEST:
      return 'Southwest';
    case ThirdPartySourceEnum.CLEARTRIP:
      return 'CT';
    case ThirdPartySourceEnum.KYTE:
      return 'Kyte';

    default:
      return '';
  }
};
