/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
// Generated using yarn generate command
import { useMemo, useCallback, useDeferredValue, useRef } from 'react';
import {
  SelectStoreProps,
  SelectStore,
  ComboboxStore,
  useDisclosureStore,
  useComboboxStore,
  SelectStoreState,
  useSelectStore,
  SelectItemProps,
} from '@ariakit/react';

import { TOptionBase, indexByDeprecatedOptionValue, isNestedList } from '../utils/select';
import { pipe } from '../utils/fp';
import type { TDeprecatedSelectOptions } from './SelectRoot';
import { SELECT_INTERNAL_DUMMY_TITLE } from './constants';
import {
  DeprecatedOptionWithStringifiedValue,
  addDeprecatedTypeMetadata,
  restoreDeprecatedOptionType,
} from './helpers';

type TAriakitSelectStoreProps<T extends string | string[]> = SelectStoreProps<T>;

type TSelectSearch<TItem extends TOptionBase> = (query: string, option: TItem, groupLabel?: string) => boolean;

export type TSearchConfig<TItem extends TOptionBase> =
  | boolean
  | { matcher: TSelectSearch<DeprecatedOptionWithStringifiedValue<TItem>> };

type TSelectStoreArgs<
  ComponentType extends 'select' | 'combobox',
  Variant extends 'single' | 'multiple',
  TItem extends TOptionBase = TOptionBase,
  Search extends TSearchConfig<TItem> = TSearchConfig<TItem>,
> = {
  componentType: ComponentType;
  variant: Variant;
  options: TDeprecatedSelectOptions<TItem>;
  onChange: (currentValue: Variant extends 'single' ? TItem : TItem[]) => void;
  onClear?: () => void;
  customSetValueOnClick?: (
    event: React.MouseEvent<HTMLElement>,
    clickedItem: TItem,
    userDidCheck: boolean,
    byValue: Record<string, TItem>,
  ) => boolean;
} & TAriakitSelectStoreProps<Variant extends 'single' ? string : string[]> & {
    search?: Search;
    searchPlaceholder?: string;
    /**
     * @deprecated
     */
    searchType?: 'LOCAL' | 'API_DRIVEN' | undefined;
  };

type TComboboxStore = ComboboxStore & { placeholder?: string };
export function useSelectStore_internal<
  ComponentType extends 'select' | 'combobox',
  Variant extends 'single' | 'multiple',
  TItem extends TOptionBase = TOptionBase,
  Search extends TSearchConfig<TItem> = TSearchConfig<TItem>,
>({
  setValue,
  onChange,
  onClear,
  variant,
  options,
  search,
  searchPlaceholder,
  searchType,
  customSetValueOnClick,
  componentType,
  ...rest
}: TSelectStoreArgs<ComponentType, Variant, TItem, Search>): {
  componentType: ComponentType;
  variant: Variant;
  selectStore: Variant extends 'single'
    ? SelectStore<string> & {
        getSelection: (value: string) => TItem | null;
      }
    : SelectStore<string[]> & {
        getSelection: (value: string[]) => [TItem[], Set<string>];
      };
  options: TDeprecatedSelectOptions<DeprecatedOptionWithStringifiedValue<TItem>>;
  searchString?: string;
  comboboxStore: ComponentType extends 'select'
    ? Search extends false | undefined
      ? undefined
      : TComboboxStore
    : TComboboxStore;
  setValueOnClick?: SelectItemProps['setValueOnClick'];
} {
  const disclosure = useDisclosureStore();
  const comboboxStore = useComboboxStore({ resetValueOnHide: true, disclosure });
  const value = comboboxStore.useState('value');
  const deferredValue = useDeferredValue(value);

  const [optionsWithTypeInfo, byValue] = useMemo(
    () =>
      pipe(
        options,
        addDeprecatedTypeMetadata,
        (processedOptions) =>
          [
            processedOptions,
            indexByDeprecatedOptionValue(processedOptions) as Record<
              string,
              DeprecatedOptionWithStringifiedValue<TItem>
            >,
          ] as const,
      ),
    [options],
  );

  const setValueChanged = useCallback<(newValue: Variant extends 'single' ? string : string[]) => void>(
    (newValue) => {
      setValue?.(newValue as SelectStoreState<Variant extends 'single' ? string : string[]>['value']);
      if (!newValue) return null;
      if (variant === 'multiple') {
        const selectedOptions = (newValue as string[]).map((x) => restoreDeprecatedOptionType<TItem>(byValue[x]));
        return (onChange as (i: TItem[]) => void)(selectedOptions);
      }
      const selectedOption = byValue[newValue as string];

      // intentionally left commented as we might want to bring this back in future based on feedback
      // comboboxStore.setValue(selectedOption.label);
      // setTimeout(() => {
      //   comboboxStore.item(newValue as string)?.element?.scrollIntoView({ block: 'nearest' });
      // }, 80);

      return (onChange as (i: TItem) => void)(restoreDeprecatedOptionType<TItem>(selectedOption));
    },
    [byValue, onChange, setValue, variant],
  ) as unknown as SelectStoreProps<Variant extends 'single' ? string : string[]>['setValue'];

  const selectStore = useSelectStore({
    disclosure,
    combobox: search ? comboboxStore : undefined,
    ...rest,
    setValue: setValueChanged,
    // disabling animation as it was asked to be removed even in existing select design, also causes functionality bug
    animated: false,
  });

  const getSelection = useCallback(
    (currentValue: Variant extends 'single' ? string : string[]) => {
      if (variant === 'single') {
        return currentValue?.length && byValue[currentValue as string]
          ? restoreDeprecatedOptionType<TItem>(byValue[currentValue as string])
          : null;
      }

      const multiSelectionItems = currentValue?.length
        ? ((currentValue as string[])
            .map((x) => {
              return byValue[x] ? restoreDeprecatedOptionType<TItem>(byValue[x]) : null;
            })
            .filter(Boolean) as TItem[])
        : ([] as TItem[]);
      const multiSelectionTuple = [multiSelectionItems, new Set(currentValue as string[])] as const;

      /* istanbul ignore if */
      // if (multiSelectionTuple[0].length !== multiSelectionTuple[1].size) {
      //   throw new Error(
      //     'Multiple selection in Select component is getting duplicate selection values, please sanitise the data or check component logic.',
      //   );
      // }
      return multiSelectionTuple;
    },
    [byValue, variant],
  ) as (
    value: Variant extends 'single' ? string : string[],
  ) => Variant extends 'single' ? TItem | null : [TItem[], Set<string>];

  const clearSelectionRef = useRef(onClear);
  clearSelectionRef.current = onClear;

  const modifiedStore = useMemo(
    () => ({
      ...selectStore,
      getSelection,
      clearSelection: () => clearSelectionRef.current?.(),
    }),
    [selectStore, getSelection, clearSelectionRef],
  );

  const filtered = useMemo(() => {
    if (!search || searchType === 'API_DRIVEN') return optionsWithTypeInfo;
    const filterer = (query: string, option: DeprecatedOptionWithStringifiedValue<TItem>, groupLabel?: string) => {
      return search === true
        ? includesLower(option.label, query) || includesLower(groupLabel, query)
        : search.matcher(query, option, groupLabel);
    };

    if (isNestedList(optionsWithTypeInfo)) {
      return optionsWithTypeInfo
        .map((group) => ({
          ...group,
          list: group.list.filter((opt) =>
            filterer(
              deferredValue,
              opt,
              group.title === SELECT_INTERNAL_DUMMY_TITLE
                ? undefined
                : typeof group.title === 'string'
                ? group.title
                : group.title.label,
            ),
          ),
        }))
        .filter((group) => group.list.length);
    }
    return optionsWithTypeInfo.filter((x) => filterer(deferredValue, x, undefined));
  }, [optionsWithTypeInfo, search, searchType, deferredValue]);

  const setValueOnClick: SelectItemProps['setValueOnClick'] = (event) => {
    if (customSetValueOnClick) {
      const clickedItem = byValue[(event.target as HTMLDivElement).getAttribute('data-value') as string];
      const isChecked = (event.target as HTMLDivElement).getAttribute('data-checked') === 'true';
      if (clickedItem) {
        return customSetValueOnClick(
          event,
          restoreDeprecatedOptionType<TItem>(clickedItem),
          !isChecked,
          // convert internal store value to original type
          pipe(
            byValue,
            (x) => Object.entries(x),
            (entries) =>
              entries.map(([key, itemWithStringifiedValue]) => [
                key,
                restoreDeprecatedOptionType<TItem>(itemWithStringifiedValue),
              ]),
            Object.fromEntries,
          ),
        );
      }
    }
    return true;
  };

  return {
    variant,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectStore: modifiedStore as unknown as any,
    options: filtered,
    ...(searchType === 'API_DRIVEN' ? { searchString: deferredValue } : {}),
    // eslint-disable-next-line no-nested-ternary
    comboboxStore: (componentType === 'select'
      ? search
        ? { ...comboboxStore, placeholder: searchPlaceholder }
        : undefined
      : { ...comboboxStore, placeholder: searchPlaceholder }) as unknown as ComponentType extends 'select'
      ? Search extends false | undefined
        ? undefined
        : TComboboxStore
      : TComboboxStore,
    componentType,
    setValueOnClick,
  };
}

function includesLower(optionLabel: string | undefined, query: string) {
  if (!optionLabel) return false;
  return optionLabel.toLowerCase().includes(query.toLowerCase());
}
