import type { IShellPnrFormDetails } from '../types/shellPnr';
import { PnrMetadataShellPnrCreateReasonType, PNRType } from '../types/api/v1/obt/pnr/pnr';
import type { IKeyLabelPair } from '../types/common';
import { ThirdPartySourceEnum } from '../types/trip';
import { globalTranslationKeys } from './common';
import { Currencies } from '../utils/Money/currencies';
import type { FareAmount } from '../types/api/v1/common/fare_amount';
import { defineMessage } from '../translations/defineMessage';

const { CAR, AIR, HOTEL, FLIGHTS, LIMO } = globalTranslationKeys;

export const DEFAULT_MARKUP_CURRENCY = Currencies.USD.code;
export const DEFAULT_MARKUP_AMOUNT = 0;
export const DEFAULT_FARE_AMOUNT: FareAmount = {
  base: { amount: DEFAULT_MARKUP_AMOUNT, currencyCode: DEFAULT_MARKUP_CURRENCY },
  tax: { amount: DEFAULT_MARKUP_AMOUNT, currencyCode: DEFAULT_MARKUP_CURRENCY },
};

export const PnrTypeToLabelKey: Record<string, string> = {
  [PNRType.AIR]: FLIGHTS,
};

export const PNRTypes: IKeyLabelPair<PNRType>[] = [
  { backendKey: PNRType.AIR, labelKey: AIR },
  { backendKey: PNRType.HOTEL, labelKey: HOTEL },
  { backendKey: PNRType.CAR, labelKey: CAR },
  { backendKey: PNRType.LIMO, labelKey: LIMO },
];

export const CreateShellPnrReasonsTypes: (IKeyLabelPair<PnrMetadataShellPnrCreateReasonType> & {
  backendKeyV2: keyof typeof PnrMetadataShellPnrCreateReasonType;
})[] = [
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.CONTENT_NOT_AVAILABLE,
    backendKeyV2: 'CONTENT_NOT_AVAILABLE',
    labelKey: defineMessage('Content'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.MULTI_PAX_BOOKING,
    backendKeyV2: 'MULTI_PAX_BOOKING',
    labelKey: defineMessage('Multi-pax booking'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.HOLD_BOOKING,
    backendKeyV2: 'HOLD_BOOKING',
    labelKey: defineMessage('Hold a booking'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.LEISURE,
    backendKeyV2: 'LEISURE',
    labelKey: defineMessage('Leisure booking'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.FUNCTIONALITY_NOT_AVAILABLE,
    backendKeyV2: 'FUNCTIONALITY_NOT_AVAILABLE',
    labelKey: defineMessage('Functionality not available'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.ISSUE_WITH_OBT,
    backendKeyV2: 'ISSUE_WITH_OBT',
    labelKey: defineMessage('Issue with OBT'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.OTHER,
    backendKeyV2: 'OTHER',
    labelKey: defineMessage('Other'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.PRICE_ASSURANCE,
    backendKeyV2: 'PRICE_ASSURANCE',
    labelKey: defineMessage('Price assurance booking'),
  },
  {
    backendKey: PnrMetadataShellPnrCreateReasonType.GROUND_TRANSPORTATION,
    backendKeyV2: 'GROUND_TRANSPORTATION',
    labelKey: defineMessage('Ground transportation'),
  },
];

export const pnrTypeSelectOptions = PNRTypes.map((pnrType) => ({
  label: pnrType.labelKey,
  value: pnrType.backendKey.toString(),
}));

export const createShellPnrReasonsTypeSelectOptions = CreateShellPnrReasonsTypes.map((reasonType) => ({
  label: reasonType.labelKey,
  value: reasonType.backendKey.toString(),
}));

export const shellPNRDetailsFormInitialState: IShellPnrFormDetails = {
  pnrType: PNRType.UNKNOWN_TYPE,
  shellPnrCreateReasonType: PnrMetadataShellPnrCreateReasonType.UNRECOGNIZED,
  outOfPolicy: false,
  approverName: '',
  createShellPnrReason: '',
  pcc: '',
  source: ThirdPartySourceEnum.SABRE,
  rateAssuranceNumber: '',
};

type PnrName = keyof typeof PnrMetadataShellPnrCreateReasonType;

export const PnrMetadataShellPnrCreateReasonKeys = Object.fromEntries(
  Object.keys(PnrMetadataShellPnrCreateReasonType).map((key) => [key, key]),
) as Record<PnrName, PnrName>;
