import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PnrFreshnessInfo } from '../../types';
import { BookingTypeEnum } from '../../types';
import { useLocalizeDateTime } from '../../translations/localizers/datetime';
import { defineMessage } from '../../translations/defineMessage';

interface Props {
  freshnessInfo: PnrFreshnessInfo;
  pnrType: BookingTypeEnum;
}

const warningMap = new Map<BookingTypeEnum, string>([
  [
    BookingTypeEnum.AIR,
    defineMessage('The booking was last updated {{date}}. Please check the carrier website for the latest details.'),
  ],
  [
    BookingTypeEnum.HOTEL,
    defineMessage('The booking was last updated {{date}}. Please check the the hotel website for the latest details.'),
  ],
  // TODO: add warnings for the other types if they differ from warningDefault
]);

const warningDefault = defineMessage(
  'The booking was last updated {{date}}. Please check the the vendor website for the latest details.',
);

const useTranslatePnrFreshnessInfo = ({ freshnessInfo, pnrType }: Props) => {
  const { t } = useTranslation('COMMON');

  const { latestVersionTime } = freshnessInfo;

  const date = useLocalizeDateTime(latestVersionTime.iso8601, { dateStyle: 'medium', timeStyle: 'medium' });

  return useMemo(() => t(warningMap.get(pnrType) ?? warningDefault, { date }), [date, pnrType, t]);
};

export default useTranslatePnrFreshnessInfo;
