import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const SearchPage = lazy(() => import('./SearchPage'));
const QueueDashboardPage = lazy(() => import('./QueueDashboardPage'));
const Spotternet = lazy(() => import('./Spotternet'));

export const agentRoutes: IRouteConfig[] = [
  {
    key: 'agent-search', // was 'agent-root'
    path: '/agent/search', // was '/agent'
    component: SearchPage,
    exact: true,
  },
  {
    key: 'agent',
    path: '/agent', // TODO: remove path later
    component: SearchPage,
    exact: true,
  },
  {
    key: 'agent-queue-dashboard', // was 'agent-desktop'
    path: '/agent/queue-dashboard', // was '/agent/desktop'
    component: QueueDashboardPage,
  },
  {
    key: 'agent-desktop',
    path: '/agent/desktop', // TODO: remove path later
    component: QueueDashboardPage,
  },
  {
    key: 'spotternet',
    path: '/spotternet',
    search: '/:selectedPage?',
    component: Spotternet,
  },
];
