import React, { useRef, useEffect } from 'react';
import { usePopper } from 'react-popper-2';
import styled from 'styled-components';
import css from '@styled-system/css';
import ClickAwayListener from '../ClickAwayListener';
import { themeSelector } from '../utils';
const PopperContainer = styled.div(css({
    boxShadow: 'filter',
    borderRadius: 'sm',
    backgroundColor: 'bg.regular',
    [themeSelector('dark')]: {
        backgroundColor: 'bg.surfaceMenu',
    },
    zIndex: 'overlay',
}));
const Popper = ({ open, anchorRef, placement = 'bottom', offset = [0, 18], children, modifiers, containerStyles, positioningStrategy = 'absolute', closeOnNotPreventedEvent = false, ignoreBlurEventOnPreventedClickOutside = false, onBlur, }) => {
    const popperRef = useRef(null);
    const { styles, attributes } = usePopper(anchorRef.current, popperRef.current, {
        placement,
        strategy: positioningStrategy,
        modifiers: [{ name: 'offset', options: { offset } }, ...(modifiers !== null && modifiers !== void 0 ? modifiers : [])],
    });
    const handleEsc = (event) => {
        if (event.key === 'Escape' && onBlur) {
            onBlur(event);
        }
    };
    const handleClick = (event) => {
        if (!event.defaultPrevented && closeOnNotPreventedEvent) {
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
        }
    };
    useEffect(() => {
        if (open) {
            window.dispatchEvent(new Event('resize'));
            document.addEventListener('keydown', handleEsc);
        }
        else {
            document.removeEventListener('keydown', handleEsc);
        }
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, 
    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]);
    return (React.createElement(PopperContainer, Object.assign({ ref: popperRef, open: open, style: Object.assign(Object.assign({}, styles.popper), containerStyles), "data-testid": "popper_container", onClick: handleClick }, attributes.popper), open && onBlur && (React.createElement(ClickAwayListener, { ignoreBlurEventOnPreventedClickOutside: ignoreBlurEventOnPreventedClickOutside, onClickAway: onBlur }, open && children))));
};
Popper.defaultProps = {
    placement: 'bottom',
    offset: [0, 18],
};
/**
 * @deprecated
 */
export default Popper;
