import { ROUTE_PATHS } from 'src/routes';
import { useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Link } from 'react-router-dom';
import { debug_tool_link_container } from './styles';

function DebugTool() {
  const { t: tt } = useTranslation('WEB');
  return (
    <Link to={ROUTE_PATHS.SUPPORT_DEBUG_TOOL} target="_blank">
      <button type="button" css={debug_tool_link_container}>
        <Typography variant="body1">{tt('Debug Tool')}</Typography>
      </button>
    </Link>
  );
}
export default DebugTool;
