import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
import SpotnanaTheme from '../utils/themes';
const PriorityLow = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7.40978 6.27197L11.9997 9.93597L16.5896 6.27197L17.9995 7.39997L11.9997 12.2L5.99982 7.39997L7.40978 6.27197Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M7.41174 11.0728L12.0016 14.7368L16.5915 11.0728L18.0015 12.2008L12.0016 17.0008L6.00177 12.2008L7.41174 11.0728Z", fill: themeEnrichedPrimaryColor })));
};
PriorityLow.defaultProps = Object.assign(Object.assign({}, defaultIconProps), { primaryColor: SpotnanaTheme.colors.text.secondary });
export default PriorityLow;
