import { useBrexBudgetInfoQuery, useBrexBudgetInfoWithoutCardQuery, CUSTOM_FIELD_TYPES } from 'obt-common';
import { useEmbedStore, useEmitEmbedErrors } from '../components/EmbedContext';

const useBrexBudgetInfo = () => {
  const { customFieldType, customFieldValue } = useEmbedStore().embedParams;

  const reportError = useEmitEmbedErrors({ source: 'useBrexBudgetInfo' });

  return useBrexBudgetInfoQuery(
    {
      brexJwtToken: customFieldValue,
    },
    {
      enabled: customFieldType === CUSTOM_FIELD_TYPES.BREX_TOKEN,
      onError: (error) => reportError({ error, context: { token: customFieldValue } }),
    },
  );
};

const useBrexBudgetInfoWithoutCard = () => {
  const { customFieldType, customFieldValue } = useEmbedStore().embedParams;

  const reportError = useEmitEmbedErrors({ source: 'useBrexBudgetInfo' });

  return useBrexBudgetInfoWithoutCardQuery(
    {
      brexJwtToken: customFieldValue,
    },
    {
      enabled: customFieldType === CUSTOM_FIELD_TYPES.BREX_TOKEN,
      onError: (error) => reportError({ error, context: { token: customFieldValue } }),
    },
  );
};

export default useBrexBudgetInfo;

export { useBrexBudgetInfoWithoutCard };
