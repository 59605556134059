import { css } from '@emotion/react';
import { flex } from '../../../utils';

export const unfocused_input_container = css`
  ${flex.init}
  ${flex.align.center}
  gap: 8px;
  ${flex.wrap.wrap}
  width:100%;
`;
