import { dateFormats } from '../constants/common';
import dateUtil from './dateUtil';

// IMPORTANT!!!
// There is an issue with dayjs timezone and hermes engine: https://github.com/iamkun/dayjs/issues/1377
// It's not working for now, without polyfills and overriding Date.getLocaleString method.
const getLocalFormattedDate = (
  date: string | undefined,
  format: string = dateFormats.DATE_AT_TIME_HR12_ZONE,
): string => {
  if (!date) {
    return '';
  }

  return dateUtil(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format);
};

export default getLocalFormattedDate;
