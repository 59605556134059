/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Platform on which the message is displayed. The value will be extracted on runtime.
 * @export
 * @enum {string}
 */

export const SiteMessagePlatform = {
  Desktop: 'DESKTOP',
  Mobile: 'MOBILE',
  Email: 'EMAIL',
} as const;

export type SiteMessagePlatform = (typeof SiteMessagePlatform)[keyof typeof SiteMessagePlatform];
