import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Poi = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, "Poi"),
        React.createElement("path", { d: "M19 12.294C19 9.374 16.758 7 14 7s-5 2.374-5 5.294C9 16.264 14 22 14 22s5-5.735 5-9.706zm-6.667 0c0-.97.75-1.765 1.667-1.765s1.667.794 1.667 1.765c0 .468-.176.917-.489 1.248-.312.33-.736.517-1.178.517-.442 0-.866-.186-1.178-.517a1.819 1.819 0 01-.489-1.248z", fill: themeEnrichedPrimaryColor, fillRule: "evenodd" })));
};
Poi.defaultProps = defaultIconProps;
export default Poi;
