import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { Modal, TModalProps } from '@spotnana/blocks/src/Modal';
import { Typography } from '@spotnana/blocks/src/Typography';
import { extractAxiosErrorDetailsForCodeTranslation } from 'obt-common';
import useGetErrorMessageByCode from 'src/hooks/useGetErrorMessageByCode';
import { useGetErrorTitleByCode } from 'src/hooks/useGetErrorTitleByCode';
import { IPreloadErrorModalProps } from './types';

function getFooterConfig(
  refetch: IPreloadErrorModalProps['refetch'],
  onBack: IPreloadErrorModalProps['onBack'],
): TModalProps['footer'] {
  if (refetch.enabled) {
    return {
      primaryAction: {
        text: 'Retry',
        onClick: refetch.onRefetch,
      },
      secondaryAction: {
        text: 'Go back',
        onClick: onBack,
      },
    };
  }

  return {
    primaryAction: {
      text: 'Go back',
      onClick: onBack,
    },
  };
}

const PreloadErrorModal: FC<IPreloadErrorModalProps> = ({ error, refetch, onBack, modalProps }) => {
  const { t: tt } = useTranslation('WEB');
  const getErrorMessageByCode = useGetErrorMessageByCode();
  const getErrorTitleByCode = useGetErrorTitleByCode();

  const axiosError = error as AxiosError;
  const { errorCode, errorParameters } = extractAxiosErrorDetailsForCodeTranslation(axiosError);
  const errorMessage = getErrorMessageByCode({ errorCode, errorParameters });

  const errorTitle = getErrorTitleByCode({ errorCode, errorParameters });

  const open = modalProps?.open ?? true;
  const hideCloseButton = modalProps?.hideCloseButton ?? true;

  return (
    <Modal
      open={open}
      hideCloseButton={hideCloseButton}
      onClose={onBack}
      header={{ title: errorTitle }}
      footer={getFooterConfig(refetch, onBack)}
      data-testId="PreloadErrorModal"
    >
      <Typography variant="body1" color="secondary" aria-label={tt('Preload error')}>
        {errorMessage}
      </Typography>
    </Modal>
  );
};

export default PreloadErrorModal;
