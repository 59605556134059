import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const DashedLine = ({ primaryColor, width }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: 2, viewBox: `0 0 ${width} 2`, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("line", { y1: 1, x2: "1000", y2: 1, stroke: themeEnrichedPrimaryColor, strokeWidth: 2, strokeDasharray: "12 10" })));
};
DashedLine.defaultProps = defaultIconProps;
export default DashedLine;
