/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reason why cancellation is not supported via OBT.
 * @export
 * @enum {string}
 */

export const AirCancellationNotSupportedReason = {
  PartialLegFlown: 'PARTIAL_LEG_FLOWN',
  ExchangedTicket: 'EXCHANGED_TICKET',
  AutomatedRefundNotSupported: 'AUTOMATED_REFUND_NOT_SUPPORTED',
  AutomatedExchangeNotSupported: 'AUTOMATED_EXCHANGE_NOT_SUPPORTED',
  NonRefundableButExchangeable: 'NON_REFUNDABLE_BUT_EXCHANGEABLE',
  NonRefundableNonExchangeable: 'NON_REFUNDABLE_NON_EXCHANGEABLE',
  FareRulesUnknown: 'FARE_RULES_UNKNOWN',
  FlightCheckedIn: 'FLIGHT_CHECKED_IN',
  FareBreakupUnknown: 'FARE_BREAKUP_UNKNOWN',
  CurrentTimeCloseToFlightDeparture: 'CURRENT_TIME_CLOSE_TO_FLIGHT_DEPARTURE',
  AllFlightsUsed: 'ALL_FLIGHTS_USED',
  NonVoidableAncillaryTicket: 'NON_VOIDABLE_ANCILLARY_TICKET',
  SupplierCancellationNotImplemented: 'SUPPLIER_CANCELLATION_NOT_IMPLEMENTED',
  OutsideBooking: 'OUTSIDE_BOOKING',
  UpgradeRequested: 'UPGRADE_REQUESTED',
} as const;

export type AirCancellationNotSupportedReason =
  (typeof AirCancellationNotSupportedReason)[keyof typeof AirCancellationNotSupportedReason];
