/* eslint-disable */
import type { CompanyId, CompanyRef } from "../../v1/common/company_id";

export enum SupplierType {
  UNKNOWN_SUPPLIER = 0,
  SABRE = 1,
  AMADEUS = 2,
  TRAVEL_FUSION = 3,
  FARELOGIX_NDC = 4,
  ATPCO_NDC = 5,
  TRAINLINE = 6,
  AVIA = 7,
  UNRECOGNIZED = -1,
}

export enum TravelType {
  UNKNOWN_TRAVEL_TYPE = 0,
  AIR = 1,
  HOTEL = 2,
  CAR = 3,
  RAIL = 4,
  LIMO = 6,
  MISC = 7,
  ALL = 5,
  UNRECOGNIZED = -1,
}

/** If a company has role TMC, all tmc related info comes in TMCInfo. */
export interface TMCInfo {
  /** TMC id */
  id?: CompanyId;
  /** TMC's primary service provider tmc information. */
  primaryServiceProviderTmc?: PrimaryServiceProviderTmc;
  /** Secondary service providers of the TMC */
  secondaryServiceProviderTmcs: SecondaryServiceProviderTmc[];
  /** Tmc Data. */
  tmcData?: TmcData;
  /**
   * Useful to identify the clients onboarded by a PARTNER_TMC.
   *
   * @deprecated
   */
  partnerTmcId?: CompanyId;
  /**
   * Any other Metadata needed for TMC
   *
   * @deprecated
   */
  metaData?: CompanyMetadata;
}

/** Data which will be needed for various aspects of TMC */
export interface TmcData {
  /** Email address, to which all the bookings will be bcced */
  bccEmailAddress: string;
}

/**
 * Any meta that might be needed in future for a company
 * For now, just the company name
 */
export interface CompanyMetadata {
  /** Name of the Company */
  name: string;
}

/**
 * Contains information about primary service provider TMC.
 * As of now just the tmc id.
 */
export interface PrimaryServiceProviderTmc {
  /** Id of the service provider TMC. */
  tmcId?: CompanyId;
}

/** Contains information about secondary service provider TMC. */
export interface SecondaryServiceProviderTmc {
  /** Id of the service provider TMC. */
  tmcId?: CompanyId;
  /** Supplier for which this service provider should be used. */
  supplier: SupplierType;
  /** Travel type for which this service provider should be used. */
  travelType: TravelType;
}

export interface TmcBasicInfo {
  /** Contracting TMC: which TMC the user/organization contracted. */
  contractingTmc?: CompanyRef;
  /** Booking TMC: the TMC which used for the bookings for the user/organization. */
  bookingTmc?: CompanyRef;
}

export interface SupplierInfo {
  supplierConfigInfos: SupplierConfigInfo[];
}

export interface SupplierConfigInfo {
  /** Supplier type */
  supplier: SupplierType;
  /** Travel type for which, this supplier should be used. */
  travelType: TravelType;
  /** TMC id to reference config. */
  tmcId?: CompanyId | undefined;
  /** Supplier config if not given tmcId. */
  supplierConfig?: SupplierConfig | undefined;
}

export interface SupplierConfig {
  pccConfig?: PccConfig | undefined;
  travelfusionConfig?: TravelfusionConfig | undefined;
  trainlineConfig?: TrainlineConfig | undefined;
  farelogixConfig?: FarelogixConfig | undefined;
}

export interface PccConfig {
  pccId: string;
}

export interface TravelfusionConfig {
  /** Travelfusion base url. */
  baseUrl: string;
  /** Travelfusion login id of the TMC. */
  loginId: string;
}

export interface TrainlineConfig {
  /** Trainline auth url. */
  authUrl: string;
  /** Trainline server base url. */
  serverBaseUrl: string;
  /** Trainline credentials. */
  credentials?: Credentials;
}

export interface FarelogixConfig {
  /** AWS secret key for farelogix config of the TMC. */
  awsSecretKey: string;
}

export interface FarelogixAirlineConfig {
  /** Airline code */
  airlineCode: string;
}

export interface Credentials {
  awsCredentials?: AwsCredentials | undefined;
  trainlineCredentials?: TrainlineCredentials | undefined;
  farelogixCredentials?: FarelogixCredentials | undefined;
}

export interface FarelogixCredentials {
  /** API End Point. */
  url: string;
  /** Ocp-Apim-Subscription-Key: Header In Request. */
  subscriptionKey: string;
  /** TMC username. */
  username: string;
  /** TMC password. */
  password: string;
  /** Farelogix PCC. */
  pseudoCity: string;
  /** Agent username. */
  agentUsername: string;
  agentPassword: string;
  /** Agency code for TMC. */
  agencyCode: string;
  /** Trace id to identify the tmc. */
  trace: string;
  /** Pricing Engine on Farelogix. */
  engineName: string;
  /** A boolean suggesting xml should be wrapped in another element or not. */
  outerBody: boolean;
  /** After payment we do multiple calls to check if the booking is confirmed. */
  delayConfirmation: number;
  /** After payment we do multiple calls to retrieve order if the booking is confirmed. */
  delayRetrieveOrder: number;
}

export interface TrainlineCredentials {
  /** Trainline client ID. */
  clientId: string;
  /** Trainline client secret. */
  clientSecret: string;
  /** Trainline audience. */
  audience: string;
}

export interface AwsCredentials {
  /** AWS secret key. */
  secretKey: string;
  /**
   * Version number of the AWS secret key to reference.
   * If not specified, latest version is fetched.
   */
  version: string;
}

/** Default booking TMC configuration for TMC. */
export interface TmcDefaultConfiguration {
  /** Default booking TMC to be used. If not set, then TMC itself will be default booking tmc. */
  defaultBookingTmcId?: CompanyId;
  /** Override for booking TMCs per country */
  countryWiseBookingTmcs: TmcDefaultConfigurationCountryWiseBookingTmcs[];
}

export interface TmcDefaultConfigurationCountryWiseBookingTmcs {
  /** Country code for which booking TMCs needs to be overriden */
  countryCode: string;
  /** Booking TMC for above country code. */
  bookingTmcId?: CompanyId;
}
