import type { UpdateUserActionRequest } from '@spotnana/types/openapi/models/update-user-action-request';
import type { UserActionUpdateResponse } from '@spotnana/types/openapi/models/user-action-update-response';
import { useMemo } from 'react';
import { type IncompleteUserAction } from '../../../types/userActions';
import { useFeatureFlag } from '../../../feature-gating';
import { useAuth } from '../../../providers/AuthProvider';
import { invalidateReadProfile } from '../../../queries/profile';
import { useUpdateUserAction } from '../../../queries/users';
import type { AuthenticatedUserBasicInfo } from '../../../types/api/v1/auth/services';
import { supportedUserActions } from '../../../constants/common';

interface UseUserActionsResult {
  /**  list of incomplete user actions */
  incompleteUserActions: IncompleteUserAction[];
  /**  method to update user actions */
  updateUserAction: (request: UpdateUserActionRequest) => Promise<UserActionUpdateResponse>;
  /**  method to refetch user actions */
  refreshActions: () => Promise<void>;
}

/**
 * Filters out unsupported user actions, and get only supported actions in the UI
 * @param AuthenticatedUserBasicInfo
 * @returns
 */
const getSupportedUserActions = (user: AuthenticatedUserBasicInfo) => {
  const incompleteUserActions = user.existingUser?.incompleteUserActions || [];
  return incompleteUserActions.filter((action) => supportedUserActions.includes(action.actionName));
};

/**
 * Manages User Actions state
 * @param featureFlag
 * @returns
 */
export function useUserActions(featureFlag: 'FE_LOGIN_ACTIONS' | 'MOB_LOGIN_ACTIONS'): UseUserActionsResult {
  const isUserActionsEnabled = useFeatureFlag(featureFlag);
  const { userBasicInfo, refetchUserBasicInfo } = useAuth();
  const userId = userBasicInfo.existingUser?.userOrgId?.userId?.id ?? '';

  const { mutateAsync: updateUserActionRequest } = useUpdateUserAction(userId);

  const incompleteUserActions = useMemo(() => {
    return isUserActionsEnabled ? getSupportedUserActions(userBasicInfo) : [];
  }, [isUserActionsEnabled, userBasicInfo]);

  return {
    incompleteUserActions,
    updateUserAction: updateUserActionRequest,
    refreshActions: async () => {
      await refetchUserBasicInfo();
      // invalidate read profile query
      if (userBasicInfo.existingUser?.userOrgId) {
        invalidateReadProfile(userBasicInfo.existingUser?.userOrgId);
      }
    },
  };
}
