/* eslint-disable */
import { Money } from '../../../v1/common/money';
import { ImageGroup } from '../../../v1/common/image_group';
import { FareAmount } from './air_search_response';

export interface Amenity {
  seat?: AmenitySeat;
  wifi?: AmenityWifi;
  power?: AmenityPower;
  entertainment?: AmenityEntertainment;
  beverage?: AmenityBeverage;
  aircraft?: AmenityAircraft;
  layout?: AmenityLayout;
  freshFood?: AmenityFreshFood;
  cleaning?: AmenityCleaning;
  mask?: AmenityMask;
  temperatureCheck?: AmenityTemperatureCheck;
  capacity?: AmenityPassengerCapacity;
  blockedAdjacentSeats?: AmenityBlockedAdjacentSeats;
  covidTesting?: AmenityCovidTesting;
  vaccine?: AmenityVaccine;
}

export interface AmenitySeat {
  displayText: string;
  type: string;
  width: string;
  legroom: string;
}

export interface AmenityWifi {
  displayText: string;
  cost: string;
}

export interface AmenityPower {
  displayText: string;
  type: string;
  cost: string;
  usbPort: string;
  powerOutlet: string;
}

export interface AmenityEntertainment {
  displayText: string;
  type: string;
  cost: string;
}

export interface AmenityBeverage {
  displayText: string;
  type: string;
  alcoholCost: string;
}

export interface AmenityAircraft {
  displayText: string;
  type: string;
  model: string;
}

export interface AmenityLayout {
  displayText: string;
  directAisleAccess: string;
}

export interface AmenityFreshFood {
  displayText: string;
  type: string;
  cost: string;
}

export interface AmenityCleaning {
  displayText: string;
  description: string;
  attrDescription: string;
}

export interface AmenityMask {
  displayText: string;
  description: string;
  attrDescription: string;
}

export interface AmenityTemperatureCheck {
  displayText: string;
  description: string;
  attrDescription: string;
}

export interface AmenityPassengerCapacity {
  displayText: string;
  description: string;
  attrDescription: string;
}

export interface AmenityBlockedAdjacentSeats {
  displayText: string;
  description: string;
  attrDescription: string;
}

export interface AmenityCovidTesting {
  displayText: string;
  description: string;
  attrDescription: string;
}

export interface AmenityVaccine {
  displayText: string;
  description: string;
  attrDescription: string;
}

export enum UtaPointsPointsType {
  POINTS_TYPE_UNKNOWN = 0,
  POINTS_TYPE_REWARDS_POINTS = 1,
  POINTS_TYPE_STATUS_CREDIT = 2,
  POINTS_TYPE_COMPANY_EARN = 3,
  UNRECOGNIZED = -1,
}

export enum UtaPointsLoyaltyProgram {
  LOYALTY_PROGRAM_UNKNOWN = 0,
  LOYALTY_PROGRAM_QANTAS = 1,
  UNRECOGNIZED = -1,
}
export interface Uta {
  baggage?: UtaBaggage;
  seatType?: UtaPolicy;
  cancellation?: UtaPolicy;
  advanceChange?: UtaPolicy;
  sameDayChange?: UtaPolicy;
  seatSelection?: UtaPolicy;
  upgradeEligibility?: UtaPolicy;
  checkInPriority?: UtaPolicy;
  boardingPriority?: UtaPolicy;
  loungeAccess?: UtaPolicy;
  rewards?: UtaPoints;
  statusCredits?: UtaPoints;
  companyEarn?: UtaPoints;
  /** Allowed overhead bag in the cabin. */
  overheadBag?: UtaPolicy;
  /** Security related info, example: Security Fast Track. */
  security?: UtaPolicy;
}

export interface UtaPolicy {
  summary?: UtaPolicySummary;
  assessmentType: UtaPolicyAssessmentType;
  fee?: Money;
  /**
   *  Contains policy value which can be used by clients to decide how this policy is displayed.
   */
  value?: UtaPolicyValue;
}

export interface UtaPoints {
  program: UtaPointsLoyaltyProgram;
  type: UtaPointsPointsType;
  policy?: UtaPolicy;
  points: number;
}

export interface UtaPolicyValue {
  seat: UtaPolicyValueSeat | undefined;
}

export interface UtaPolicySummary {
  headline: string;
  description: string;
  info: string;
}

export interface UtaBaggage {
  /** Max only 2 entries will come in the array */
  checkedBag: UtaBaggageItem[];
  /** Max only 2 entries will come in the array */
  carryOn: UtaBaggageItem[];
  personalItem: UtaBaggageItem[];
}

export enum UtaBaggageApplicability {
  UNKNOWN = 0,
  /** EACH - This represents that the info is applicable for per bag. */
  EACH = 1,
  /** TOTAL - This represents that the info is applicable for all the bags combined. */
  TOTAL = 2,
  UNRECOGNIZED = -1,
}

export interface UtaBaggageWeightLimit {
  weightLimitKg: string;
  weightLimitLbs: string;
  applicability: UtaBaggageApplicability;
}

export interface UtaBaggageFee {
  fee?: Money;
  applicability: UtaBaggageApplicability;
  fareDetails?: FareAmount;
  /** Merchant fee applicable if purchased via OBT */
  merchantFee?: Money;
}

export interface UtaBaggageItem {
  policy?: UtaPolicy;
  count: number;
  /** @deprecated */
  weightLimitKg: string;
  sizeLimitCm: string;
  /**
   * This contains weight limit details like info in pounds, lbs,
   * and whether the weight limit is for each bag or the total limit.
   * Generally there is only one weight limit per baggage but for baggage
   * details like "3 bags - 33lbs/15kgs + 51lbs/23kgs + 51lbs/23kgs US$121.68",
   * it will have 3 weight limits each bag.
   */
  weightLimit: UtaBaggageWeightLimit[];
  /**
   * This contains price info for the baggage. Generally there is only
   * one fees per baggage but for baggage details like
   * "3 bags - 100kgs total US$20+US$20+US$30", it represents that we can
   * take max of 100 kgs and price will depend on number of bags we end up taking.
   */
  fee: UtaBaggageFee[];
}

export interface Upa {
  headline: string;
  description: string;
  smallIconUrl: string;
  largeIconUrl: string;
  /**
   *  We will fill 'cta_url' in 'external_details_url'.
   */
  externalDetailsUrl: string;
  /**
   *  List of categories for this Upa.
   */
  categories: UpaCategory[];
  photo: ImageGroup[];
  video: ImageGroup[];
  tour: ImageGroup[];
}

/**  RouteHappy categorises the returned policies/rules as being 'restrictive'
 in nature or provides 'benefit' or is available for a 'fee'. It is
 analogous to a rating system.
 RouteHappy Doc: Some partners choose to display UTA data on their site as
 a comparison grid. For instance, a partner may place a green checkmark
 next to all of the UTAs that are returned with “assessment”: “benefit”
 and place a red “X” next to UTAs returned with “assessment”: “restriction”.
 UTAs returned with “assessment”: “neutral” are not inherently positive or
 negative and therefore simply do not map cleanly to either a benefit or
 a restriction.
 */
export enum UtaPolicyAssessmentType {
  UNKNOWN_TYPE = 0,
  NEUTRAL = 1,
  BENEFIT = 2,
  RESTRICTION = 3,
  FEE = 4,
  UNRECOGNIZED = -1,
}

export enum UtaPolicyValueSeat {
  UNKNOWN = 0,
  STANDARD = 1,
  RECLINER = 2,
  ANGLE_FLAT = 3,
  FLAT = 4,
  SUITE = 5,
  UNRECOGNIZED = -1,
}

export enum UpaCategoryEnum {
  UNKNOWN = 0,
  AIRCRAFT = 1,
  SEAT = 2,
  MEALS = 3,
  BEVERAGES = 4,
  ENTERTAINMENT = 5,
  POWER = 6,
  WIFI = 7,
  SERVICE_INFLIGHT = 8,
  UPGRADE = 9,
  TRANSPORT_TO_AIRPORT = 10,
  CHECK_IN = 11,
  DEPARTURE_TERMINAL = 12,
  DEPARTMENT_LOUNGE = 13,
  GATE_BOARDING = 14,
  SERVICE_DEPARTURE = 15,
  STOPOVER = 16,
  CONNECTING_EASE = 17,
  CONNECTING_TERMINAL = 18,
  CONNECTING_LOUNGE = 19,
  SERVICE_CONNECTING = 20,
  BAGGAGE_COLLECTION = 21,
  ARRIVAL_TERMINAL = 22,
  ARRIVAL_LOUNGE = 23,
  ARRIVAL_TRANSPORT = 24,
  SERVICE_ARRIVAL = 25,
  SCHEDULE_ROUTE = 26,
  LOYALITY_PROGRAM = 27,
  BRAND = 28,
  PROMOTION = 29,
  UNRECOGNIZED = -1,
}

export type UpaCategory =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | -1;
