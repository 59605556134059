import { useState } from 'react';
import * as React from 'react';
import { Flex } from '@spotnana/pixel-react/dist/Box';
import Typography from '@spotnana/pixel-react/dist/Typography';
import cssWrapper from '@spotnana/pixel-react/dist/utils/cssWrapper';
import copyToClipboard from 'clipboard-copy';
import { useTranslation } from 'react-i18next';
import { globalTranslationKeys } from 'obt-common';
import styled from 'styled-components';
import { IconButton } from '@spotnana/blocks/src/IconButton';
import useApiTraceInfo from '../../hooks/useApiTraceInfo';

const FlexStyled = styled(Flex)(
  cssWrapper({
    width: '100%',
    height: '45px',
    fontWeight: 'regular',
    transition: 'regular',
    padding: '0 24px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':hover': {
      fontWeight: 'semiBold',
      transition: 'regular',
    },
  }),
);

const DebugToolLink = (): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);
  const { getTraceInfo } = useApiTraceInfo();

  const { DEBUG_TOOL_LINK } = globalTranslationKeys;
  const { t } = useTranslation();

  const onCopyHandler = async (event: React.MouseEvent<Element, MouseEvent>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    if (!isCopied) {
      setIsCopied(true);
      await copyToClipboard(getTraceInfo().debugToolLink);
      // Need to reset button text after 2s
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  const openDebugToolLink = (): void => {
    window.open(getTraceInfo().debugToolLink);
  };

  return (
    <FlexStyled onClick={openDebugToolLink}>
      <Typography>{t(DEBUG_TOOL_LINK)}</Typography>
      <IconButton
        icon={isCopied ? 'Check' : 'Copy'}
        name="copy"
        kind={isCopied ? 'success' : 'subtle'}
        onClick={onCopyHandler}
      />
    </FlexStyled>
  );
};

export default DebugToolLink;
