import { useEffect, useRef } from 'react';
import { TAutocompleteOption } from '../../../Autocomplete/Autocomplete.types';
import { Chip } from '../../../Chip';
import { CircularProgress } from '../../../CircularProgress';
import { input_wrapper } from '../../../Input/Input.styles';
import { useSelectConfig } from '../../../SelectRoot/SelectConfigContext';
import { clsx } from '../../../utils';
import {
  autocomplete_loading,
  chips_list,
  focused_input_container,
  hidden_input,
  visible_input,
} from './FocusedInput.styles';

interface IFocusedInputProps<TValue extends object> {
  /**
   * selectedOptions: Currently selected options
   */
  selectedOptions: TAutocompleteOption<TValue>[];
  /**
   * setSelectedOptions: This is used by the chip to remove an option when cross is clicked
   */
  setSelectedOptions: (newOptions: TAutocompleteOption<TValue>[]) => void | undefined;
  /**
   * OptionChip: Component to be rendered within a chip
   */
  OptionChip?: React.FC<{ option: TAutocompleteOption<TValue> }>;
  /**
   * These are event handlers from Combobox, to be passed on to the InputBase
   */
  inputProps: Omit<React.HTMLAttributes<HTMLElement>, 'color'> & { disabled?: boolean };
  /**
   * Whether to show the loader
   */
  showLoader: boolean | undefined;
  /* visibleSearchText: The text that is visible to the user. This is different from the actual search text that is being used to fetch options */
  visibleSearchText: string;
  /* setVisibleSearchText: Sets the visible search text */
  setVisibleSearchText: (newSearchText: string) => void;
  /**
   * Ref to the first option in the dropdown
   */
  firstOptionRef?: React.RefObject<HTMLDivElement>;
}

/**
 * This component is rendered when the user focuses on the AsyncAutocomplete Component.
 * This shows a list of already selected options in AsyncAutocomplete (since its already across search it contains stuff which is not in available options)
 * User can also unselect an option here (hence the setSelectedOptions)
 *
 * A quick note on the implementation - This component has a chip list (a list of selectedOptions as a chip) and 2 input elements.
 * 2 inputs? because Combobox attaches the dropdown to the last rendered input.
 * Requirement is to show a list of chips and an input as flex-wrap. ie. the input flows alongside the list of chips.
 * Since Dropdown is rendered with the input, this would mean the dropdown would not full width and keep jumping around with the input
 *
 * To solve this, there are 2 inputs. The first one is the input that the user interacts with and is a part of the chips_list.
 * The second input has 0 height, hence hidden to the user.
 * You can experiment with height:0 in the hidden_input's css (or try setting a width) to see how it works.
 *
 */
export function FocusedInput<TValue extends object>({
  selectedOptions,
  setSelectedOptions,
  inputProps,
  OptionChip,
  showLoader,
  visibleSearchText,
  setVisibleSearchText,
  firstOptionRef,
}: IFocusedInputProps<TValue>) {
  const { comboboxStore, labelId, placeholder } = useSelectConfig<'combobox'>();
  const subInputElement = useRef<HTMLInputElement>(null);

  /**
   * Why is this useEffect needed?
   * Since we have 2 input elements, on rendering the focusedInput, we need to switch user focus to the input element that we show (not the hidden one)
   */
  useEffect(() => {
    subInputElement.current?.focus();
  }, [subInputElement]);

  return (
    <button
      aria-labelledby={labelId}
      type="button"
      placeholder={placeholder}
      className={clsx('BlocksSelect-SearchField', 'MuiInputBase-root')}
      css={[focused_input_container, input_wrapper]}
      data-testid={`${labelId}_input_focused`}
      onClick={() => {
        /**
         * When we click anywhere on the input element, we need to set focus to the visible input
         */
        subInputElement.current?.focus();
      }}
    >
      <div className="chips-list" css={chips_list}>
        {selectedOptions.map((option) => (
          <Chip
            size="small"
            label={OptionChip ? <OptionChip option={option} /> : option.label}
            key={option.value}
            onDelete={() => {
              const newOptions = selectedOptions.filter((selectedOption) => selectedOption.value !== option.value);
              setSelectedOptions?.(newOptions);
            }}
          />
        ))}

        <input
          {...inputProps}
          onChange={(e) => {
            setVisibleSearchText(e.target.value);
            inputProps.onChange?.(e);
          }}
          value={visibleSearchText}
          css={visible_input}
          placeholder={placeholder}
          ref={subInputElement}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              e.stopPropagation();
              comboboxStore.setState('open', false);
            }
            if (e.key === 'Tab') {
              comboboxStore.setState('open', false);
            }
            if (e.key === 'Backspace' && visibleSearchText === '') {
              setSelectedOptions?.(selectedOptions.slice(0, selectedOptions.length - 1));
            }
            // This fixes the broken down arrow key navigation
            if (e.key === 'ArrowDown') {
              firstOptionRef?.current?.focus();
            }
          }}
        />
        {showLoader && (
          <div css={autocomplete_loading}>
            <CircularProgress size={20} />
          </div>
        )}
      </div>
      <input {...inputProps} css={hidden_input} />
    </button>
  );
}
