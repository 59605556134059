import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import first from 'lodash/first';
import { Typography } from '@spotnana/blocks/src/Typography/Typography';
import { Dot } from 'src/v2-components/shared/Dot';
import {
  FlightBookingType,
  getBreadcrumbNameForSegmentNode,
  getTripLabelForTripType,
  getTripTypeFromSegments,
  useCommonTranslation,
  Fare,
} from 'obt-common';
import { container } from './styles';

type IProps = Pick<Fare, 'segments'>;

export const FareItemSegment: FC<IProps> = ({ segments }) => {
  const { t: tt } = useTranslation('WEB');
  const { t: tCommon } = useCommonTranslation();

  const tripType = getTripTypeFromSegments(segments);
  const tripLabel = tCommon(getTripLabelForTripType(tripType));

  const displaySegments = segments.map((segment) => {
    const { destination, origin } = segment;
    const destinationDisplayValue = getBreadcrumbNameForSegmentNode(destination);
    const originDisplayValue = getBreadcrumbNameForSegmentNode(origin);
    return tt(`{{originDisplayValue}} to {{destinationDisplayValue}}`, { originDisplayValue, destinationDisplayValue });
  });

  const segmentDisplayValue =
    tripType !== FlightBookingType.MULTI_CITY ? first(displaySegments) : displaySegments.join(', ');

  return (
    <div css={container}>
      <Dot />
      <Typography variant="body2">
        {tt(`{{tripLabel}} from {{segmentDisplayValue}}`, { tripLabel, segmentDisplayValue })}
      </Typography>
    </div>
  );
};
