import React, { useState, useEffect } from 'react';
import noop from 'lodash/noop';
export function useScript(scriptURL, scriptId, hasScriptLoadedSuccessfully = true) {
    const [isLoaded, setIsLoaded] = useState(false);
    function handledLoaded() {
        setIsLoaded(true);
    }
    useEffect(() => {
        if (document.getElementById(scriptId) && hasScriptLoadedSuccessfully) {
            handledLoaded();
            return noop;
        }
        if (!scriptURL) {
            return noop;
        }
        const scriptElement = document.createElement('script');
        scriptElement.src = scriptURL;
        scriptElement.id = scriptId;
        scriptElement.addEventListener('load', handledLoaded);
        document.head.appendChild(scriptElement);
        return () => {
            scriptElement.removeEventListener('load', handledLoaded);
        };
    }, 
    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scriptURL, hasScriptLoadedSuccessfully]);
    return isLoaded;
}
export function withScriptFactory({ scriptURL, scriptId, fallback }) {
    return function withScriptHOC(Component) {
        return function WithScriptComponent(props) {
            const isLoaded = useScript(scriptURL, scriptId);
            if (isLoaded) {
                return React.createElement(Component, Object.assign({ isScriptLoaded: true }, props));
            }
            if (fallback) {
                return fallback;
            }
            return React.createElement(Component, Object.assign({ isScriptLoaded: false }, props));
        };
    };
}
