import styled, { css, keyframes } from 'styled-components';
import Box from '../Box';
export const shimmerAnimation = keyframes `
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;
export const shimmer = css `
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmerAnimation};
  animation-timing-function: linear;
  background: ${({ theme: { colors } }) => `linear-gradient(to right, ${colors.shimmerFrom} 8%, ${colors.shimmerTo} 18%, ${colors.shimmerFrom} 33%)`};
  background-size: 800px 104px;
  position: relative;
`;
const ShimmerBox = styled(Box) `
  ${shimmer}
`;
export default ShimmerBox;
