import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const notes_editor_wrapper = css`
  ${flex.init}
  ${flex.column}
  ${spacings.large}
  .NotesEditor .spotnana-text-editor--editor__shell {
    height: calc(100vh - 262px);
    overflow: auto;
  }
`;

export const tabs_wrapper = themed(
  ({ palette }) => css`
    border-bottom: 1px solid ${palette.border.light};
    ${flex.init}
    ${flex.justify.between}
    ${flex.align.center}
    .MuiTab-root::after {
      height: 0;
    }
  `,
);

export const tab_content = css`
  ${paddings.yt.cozy}
`;

const TAB_AND_APP_HEADER_HEIGHT = 170;
export const links_wrapper = css`
  ${flex.init}
  ${flex.column}
  ${spacings.extraLarge}
  height: calc(100vh - ${TAB_AND_APP_HEADER_HEIGHT}px);
`;

export const list_body = css`
  height: 100%;
  overflow: auto;
`;

export const urls = css`
  ${flex.init}
  ${flex.column}
  ${spacings.large}
  height: 100%;
`;

export const no_links_added_ctr = css`
  ${flex.init}
  ${flex.justify.center}
  ${flex.align.center}
`;

export const link_ctr = css`
  ${flex.init}
  ${flex.align.center}
  ${flex.justify.between}
`;

export const url = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.small}
  img {
    width: 16px;
    height: 16px;
  }
`;

const FORM_HEADER_HEIGHT = 75;
export const form = css`
  ${flex.init}
  ${flex.column}
  ${spacings.extraLarge}
  height: calc(100vh - ${TAB_AND_APP_HEADER_HEIGHT + FORM_HEADER_HEIGHT}px);
`;

export const form_footer = css`
  ${flex.init}
  ${spacings.large}
`;
