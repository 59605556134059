/**
 * Exchange type
 * @export
 * @enum {string}
 */

export const RailExchangeType = {
  AmendReservation: 'AMEND_RESERVATION',
  PayTheDifference: 'PAY_THE_DIFFERENCE',
  RebookAndRefund: 'REBOOK_AND_REFUND',
} as const;

export type RailExchangeType = (typeof RailExchangeType)[keyof typeof RailExchangeType];
