import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Download = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.59286 20.3997C6.09758 20.3997 5.67494 20.2289 5.32494 19.8871C4.97494 19.5454 4.79994 19.1346 4.79994 18.6548L4.73874 17.3994C4.73874 16.9176 5.14168 16.527 5.63874 16.527C6.1358 16.527 6.53874 16.9176 6.53874 17.3994L6.59994 18.6548H17.3999V17.3994C17.3999 16.9176 17.8029 16.527 18.2999 16.527C18.797 16.527 19.1999 16.9176 19.1999 17.3994V18.6548C19.1999 19.1346 19.0236 19.5454 18.6709 19.8871C18.3182 20.2289 17.8942 20.3997 17.3989 20.3997H6.59286Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M11.0625 4.45834C11.0625 3.97649 11.4654 3.58588 11.9625 3.58588C12.4595 3.58588 12.8625 3.97649 12.8625 4.45834L12.8625 13.3261L14.85 11.3994C15.2021 11.058 15.7729 11.058 16.125 11.3994C16.4771 11.7407 16.4771 12.294 16.125 12.6353L13.2353 15.4367C12.5323 16.1181 11.3926 16.1181 10.6897 15.4367L7.79997 12.6353C7.44789 12.294 7.44789 11.7407 7.79997 11.3994C8.15205 11.058 8.72289 11.058 9.07497 11.3994L11.0625 13.3261L11.0625 4.45834Z", fill: themeEnrichedPrimaryColor })));
};
Download.defaultProps = defaultIconProps;
export default Download;
