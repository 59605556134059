/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The location of seat of the rail.
 * @export
 * @enum {string}
 */

export const SeatPrefLocation = {
  Aisle: 'AISLE',
  Window: 'WINDOW',
  Solo: 'SOLO',
} as const;

export type SeatPrefLocation = (typeof SeatPrefLocation)[keyof typeof SeatPrefLocation];
