import { forwardRef, RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import InputGroup from '@spotnana/pixel-react/dist/InputGroup';
import Input, { InputProps } from '@spotnana/pixel-react/dist/Input';
import { Button } from '@spotnana/pixel-react/dist/Button';
import { Flex } from '@spotnana/pixel-react/dist/Box';
import { globalTranslationKeys, MAX_LENGTH_AUTOCOMPLETE } from 'obt-common';

const { ADD_ME, SEARCH_BY_NAME_OR_EMAIL } = globalTranslationKeys;

export interface IAutocompleteInputProps extends InputProps {
  onAddMe?: VoidFunction;
}

const AutocompleteInput = forwardRef<HTMLInputElement, IAutocompleteInputProps>(({ onAddMe, value, ...props }, ref) => {
  const { t } = useTranslation();

  useEffect(() => {
    (ref as RefObject<HTMLInputElement>).current?.focus();
  }, [ref]);

  return (
    <Flex bg="bg.regular" borderRadius="sm" height="100%">
      <InputGroup style={{ height: '100%', alignItems: 'stretch' }}>
        <Input
          {...props}
          ref={ref}
          placeholder={t(SEARCH_BY_NAME_OR_EMAIL)}
          label={t(SEARCH_BY_NAME_OR_EMAIL)}
          value={value}
          data-testid="select-traveler-v2-autocomplete-input"
          shouldStripHtml={false}
          maxLength={MAX_LENGTH_AUTOCOMPLETE}
        />
        {!value && onAddMe && (
          <Button
            variation="tertiary"
            data-testid="select-traveler-v2-autocomplete-add-me"
            p="12px"
            style={{
              height: (ref as RefObject<HTMLInputElement>).current?.clientHeight,
              maxWidth: 80,
            }}
            onClick={onAddMe}
            /**
             * Some weird glitch with old pixel components that makes pressing enter not work
             */
            onKeyDown={(evt) => {
              if (evt.code === 'Enter') {
                onAddMe();
              }
            }}
          >
            {t(ADD_ME)}
          </Button>
        )}
      </InputGroup>
    </Flex>
  );
});

export default AutocompleteInput;
