import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const PrivacyNotice = lazy(() => import('./PrivacyNotice'));

export const privacyRoutes: IRouteConfig[] = [
  {
    key: 'privacy-notice',
    path: '/privacy-notice',
    component: PrivacyNotice,
  },
];
