// Generated using yarn generate command
import { HTMLAttributes, ReactNode } from 'react';
import MuiTab, { TabProps } from '@mui/material/Tab';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';

import { tabs_base, tabs_item_base, tabs_item_large, tabs_item_medium, tabs_item_small } from './Tabs.styles';
import { RemoveMuiProps } from '../types';
import type { IconName } from '../Icon/types';
import { Icon } from '../Icon';
import { clsx } from '../utils';

type TIconItem = {
  Icon: IconName;
  label: string;
};

const isIconItem = (value: unknown): value is TIconItem =>
  !!value && typeof value === 'object' && 'Icon' in value && 'label' in value;

export type TTabsProps<T = string> = RemoveMuiProps<TabsProps, 'value'> & {
  size?: 'small' | 'medium' | 'large';
  /**
   * Menu items that should be rendered
   */
  items: T[];
  /**
   * Selected element
   */
  value: T | undefined;
  /**
   * Tab item icon placement
   */
  iconPosition?: TabProps['iconPosition'];
  variant?: 'standard' | 'filled' | 'filledBrand';
} & Pick<HTMLAttributes<HTMLDivElement>, 'className'> &
  (T extends number | string | TIconItem
    ? {
        // Optional if T is primitive
        renderItem?: (value: T, isActive: boolean) => ReactNode;
        keyExtractor?: (value: T) => string;
      }
    : {
        // required if T is a composite
        renderItem: (value: T, isActive: boolean) => ReactNode;
        // for the composite value it is an mandatory to provide keyExtractor function
        keyExtractor: (value: T) => string;
      });

/**
 * @deprecated
 *
 * Deprecated due to unusable prop API and lack of accessibility considerations.
 */
export const Tabs = <T,>({
  size = 'small',
  className,
  items,
  value,
  iconPosition = 'start',
  variant = 'standard',
  renderItem,
  keyExtractor,
  ...rest
}: TTabsProps<T>) => {
  const activeItemKey = value && keyExtractor ? keyExtractor(value) : `${value}`;

  return (
    <MuiTabs className={clsx([className, `BlocksTabContainer-${variant}`])} css={tabs_base} value={value} {...rest}>
      {items.map((item) => {
        const iconItem = isIconItem(item);
        const key = keyExtractor?.(item) ?? (iconItem ? item.label : `${item}`);
        const isActive = activeItemKey === key;
        const renderedValue = renderItem?.(item, isActive) ?? (iconItem ? item.label : `${item}`);
        const icon = iconItem ? <Icon name={item.Icon} /> : undefined;

        return (
          <MuiTab
            key={key}
            iconPosition={iconPosition}
            css={[
              tabs_item_base,
              size === 'small' && tabs_item_small,
              size === 'medium' && tabs_item_medium,
              size === 'large' && tabs_item_large,
            ]}
            value={item}
            icon={icon}
            label={renderedValue}
            className={clsx([`BlocksTabItemContainer-${variant}`])}
            disableFocusRipple
          />
        );
      })}
    </MuiTabs>
  );
};
