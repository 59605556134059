var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const ChevronBoldRight = (_a) => {
    var { primaryColor, width, height, secondaryColor } = _a, restProps = __rest(_a, ["primaryColor", "width", "height", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 16 16" }, restProps),
        React.createElement("g", { transform: "rotate(-90 8 8)", fill: "none", fillRule: "evenodd" },
            React.createElement("rect", { width: 16, height: 16, rx: 4 }),
            React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M3.786 5.45a.977.977 0 00-.005 1.377l3.474 3.513c.462.455 1.2.45 1.653-.01l3.464-3.503a.976.976 0 00.066-1.298l-.07-.078-.076-.067a.967.967 0 00-1.3.073L8.076 8.412l-2.919-2.96a.969.969 0 00-1.298-.068l-.074.066z" }))));
};
ChevronBoldRight.defaultProps = defaultIconProps;
export default ChevronBoldRight;
