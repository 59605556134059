import { createContext, FC, useLayoutEffect, useState, useContext, PropsWithChildren } from 'react';
import { useScript } from '@spotnana/pixel-react/dist/hooks/useScript';
import { useSupportConfigForCurrentOrganization } from 'obt-common/queries/supplier';
import { verifyGenesysChatIsEnabled } from 'obt-common/utils/genesys/verifyGenesysChatIsEnabled';
import { StyleOverrides } from './index.style';

declare global {
  interface Window {
    CXBus: any;
    _genesys: any;
  }
}

const widgetBaseUrl = 'https://apps.mypurecloud.ie/widgets/9.0.006.03/';
const pluginsPath = `${widgetBaseUrl}plugins/`;
const scriptUrl = `${widgetBaseUrl}cxbus.min.js`;

const GenesysChatContext = createContext<any>(null);

export const useGenesysChat = (): any => {
  const context = useContext(GenesysChatContext);

  if (context === undefined) {
    throw new Error('useGenesysChat must be used inside GenesysChatProvider provider.');
  }

  return context;
};

const GenesysChatProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: supportConfig } = useSupportConfigForCurrentOrganization();
  const isGenesysEnabled = verifyGenesysChatIsEnabled(supportConfig);
  const isScriptLoaded = useScript(isGenesysEnabled ? scriptUrl : '', 'CXbus sdk');
  const dataURL = supportConfig?.genesysConfig?.dataUrl;

  const [plugin, setPlugin] = useState<unknown>(null);

  useLayoutEffect(() => {
    if (isScriptLoaded && !plugin) {
      const { CXBus } = window;

      CXBus.configure({ debug: false, pluginsPath });
      CXBus.loadPlugin('widgets-core')
        .done(() => CXBus.loadPlugin('webchat'))
        .done(() => {
          // eslint-disable-next-line no-underscore-dangle
          window._genesys.widgets.webchat = {
            userData: { GCTI_Chat_AsyncMode: 'true' },

            emojis: true,
            cometD: {
              enabled: false,
            },
            autoInvite: {
              enabled: false,
              timeToInviteSeconds: 5,
              inviteTimeoutSeconds: 30,
            },
            uploadsEnabled: true,

            dataURL,

            apikey: '',
            async: {
              enabled: true,
              newMessageRestoreState: 'full',
            },
          };

          CXBus.command('App.setTheme', { theme: 'light' });

          setPlugin(CXBus.registerPlugin('WebChatCustom'));
        });
    }
  }, [isScriptLoaded, plugin, setPlugin, dataURL]);

  return (
    <>
      <StyleOverrides />
      <GenesysChatContext.Provider value={plugin}>{children}</GenesysChatContext.Provider>
    </>
  );
};

export default GenesysChatProvider;
