import {
  getFullAssetPath,
  useUnauthWhiteLabelConfig,
  useIsLoggedIn,
  useWhiteLabelConfig,
  SpotnanaError,
  usePartnerFeatures,
  Config,
} from 'obt-common';
import { useLink } from 'hoofd';
import { tryCatchExp } from '@spotnana/blocks/src/utils/fp';
import { createContext, useContext, useMemo, useRef } from 'react';
import { mapTravelTypeToRoute } from 'src/v1-components/search/hooks/useAllowedTravelTypes';
import useDynamicTitle from 'src/hooks/useDynamicTitle';
import { PrivacyDisclaimer } from '@spotnana/types/openapi/models/privacy-disclaimer';
import { WhiteLabelConfig } from '@spotnana/types/openapi/models/white-label-config';
import {
  DEFAULT_EXCLUDED_DOMAINS_FOR_WHITELABELLING,
  filterInvalidTravelTypes,
  matchHostNameWithExcludedSubDomain,
} from './helpers';
import { HomeOrderConfigItem, useHomeOrder } from './useHomeOrder';

export const EXCLUDED_DOMAINS_FOR_WHITELABELLING = tryCatchExp(
  () => {
    return Config.VITE_EXCLUDED_DOMAINS_FOR_WHITELABELLING
      ? JSON.parse(Config.VITE_EXCLUDED_DOMAINS_FOR_WHITELABELLING)
      : DEFAULT_EXCLUDED_DOMAINS_FOR_WHITELABELLING;
  },
  () => {
    return DEFAULT_EXCLUDED_DOMAINS_FOR_WHITELABELLING;
  },
);

export const SPOTNANA_SUB_DOMAIN = matchHostNameWithExcludedSubDomain(
  EXCLUDED_DOMAINS_FOR_WHITELABELLING,
  window.location.hostname,
)
  ? ''
  : window.location.hostname;

type AuthPageConfigType = {
  illustrations: string;
  illustrationActionUrl?: string;
};

type ValidConfig = {
  companyName: string;
  poweredBySpotnana: boolean;

  logoHeader: string;
  logoFooter: string;
  appHomeRoute: string;
  faviconUrl: string;
  /**
   * This make the logo route to an external URL instead of application home route
   */
  logoUrl?: string | undefined;
  homeOrder: HomeOrderConfigItem[];
  isReady: boolean;
  authPageConfig: AuthPageConfigType;
  logoutRedirectUrl?: string;
  externalHomePageUrl: string;
  privacyLink?: string;
  disclaimerLink?: string;
  termsOfUseLink?: string;
  privacyDisclaimer?: PrivacyDisclaimer;
};

type TCustomisationConfigInternal = ValidConfig | 'UNDETERMINED';

// this is the default config that applies if the feature flag isn't active
const spotnanaConfig: Omit<
  Required<TCustomisationConfig>,
  | 'appHomeRoute'
  | 'logoUrl'
  | 'authPageConfig'
  | 'faviconUrl'
  | 'logoutRedirectUrl'
  | 'externalHomePageUrl'
  | 'privacyLink'
  | 'disclaimerLink'
  | 'termsOfUseLink'
  | 'privacyDisclaimer'
> & {
  appHomeRoute: string;
  logoUrl?: undefined;
  authPageConfig: AuthPageConfigType;
  faviconUrl: string;
  logoutRedirectUrl?: undefined;
  externalHomePageUrl: string;
  privacyLink?: string;
  disclaimerLink?: string;
  termsOfUseLink?: string;
  privacyDisclaimer?: PrivacyDisclaimer;
} = {
  companyName: 'Spotnana',
  poweredBySpotnana: false,
  logoHeader: getFullAssetPath('/v1-assets/images/spotnana-logo-red', 'svg'),
  logoFooter: getFullAssetPath('/v1-assets/images/spotnana-logo-red-bg', 'svg'),
  appHomeRoute: '/flights',
  authPageConfig: {
    illustrations: getFullAssetPath('/v1-assets/illustrations/login-illustration', 'png'),
  },
  isReady: true,
  homeOrder: [] as HomeOrderConfigItem[],
  faviconUrl: getFullAssetPath('/favicon', 'ico'),
  externalHomePageUrl: '',
  privacyLink: 'https://www.spotnana.com/spotnana-privacy-notice/',
  disclaimerLink: 'https://www.spotnana.com/terms',
  termsOfUseLink: 'https://www.spotnana.com/terms',
};

const defaultValue: TCustomisationConfigInternal = 'UNDETERMINED' as const;
const AppBrandingContext = createContext<TCustomisationConfigInternal>(defaultValue);

export function AppBrandingProvider({ children }: { children: React.ReactNode }) {
  const brandingConfig = useAppBrandingConfig();

  const { features, isLoading } = usePartnerFeatures();

  const { hideAir, hideHotel, hideCar, railBooking } = features;

  const homeOrder = useHomeOrder();
  const homeOrderLoading = homeOrder === 'NOT_FETCHED';
  const flagsReady = !homeOrderLoading && !isLoading;

  const defaultHomePath = useRef('/flights');

  const finalCustomisationConfig = useMemo(() => {
    const isReady = flagsReady && brandingConfig !== 'UNDETERMINED';
    /**
     * If it's undetermined (not fetched) dont do anything
     */
    if (!isReady) return 'UNDETERMINED';

    /**
     * TODO: get rid of inline confusing imperative blocks and make a function to transform feature to home route
     */
    if (railBooking) defaultHomePath.current = '/rails';
    if (!hideCar) defaultHomePath.current = '/cars';
    if (!hideHotel) defaultHomePath.current = '/hotels';
    if (!hideAir) defaultHomePath.current = '/flights';

    /**
     * This ensures we maintain status quo if feature is disabled, applying no changes to the app
     *
     * i.e. isDisabled
     */
    return {
      ...brandingConfig,
      // logo validation has to override whatever we get from API
      appHomeRoute:
        (homeOrder[0] ? mapTravelTypeToRoute[filterInvalidTravelTypes(homeOrder)[0]] : undefined) ??
        (defaultHomePath.current as string),
      homeOrder,
      isReady,
    };
  }, [flagsReady, brandingConfig, railBooking, hideHotel, hideCar, hideAir, homeOrder]);

  /**
   * We are choosing to not render the app until the unauth'd API call for logos etc
   * + harness feature flag request for global feature enabling have both been resolved.
   */
  if (finalCustomisationConfig === 'UNDETERMINED') {
    // might replace with a loading spinner
    return null;
  }

  /**
   * We are transforming because current authenticated white-label-config-api for SPOTNANA returns logoHeader and logoFooter as per required Email Template and for WEB we are using SPOTNANA logo as harcoded from our assets, hence need to transform temporarily.
   */

  const transformedFinalCustomisationConfig = transformFinalCustomisationConfig(finalCustomisationConfig);

  return (
    <AppBrandingContext.Provider value={transformedFinalCustomisationConfig}>
      <Favicon />
      {children}
    </AppBrandingContext.Provider>
  );
}

type TCustomisationConfig = Exclude<TCustomisationConfigInternal, 'UNDETERMINED'>;
export function useAppBranding() {
  // Typing to remove "UNDETERMINED" as we don't render the application in that state.
  const ctxVal = useContext(AppBrandingContext) as TCustomisationConfig;
  return ctxVal;
}

export function useAppLogos() {
  const { logoHeader, logoFooter, companyName } = useAppBranding();
  // eslint-disable-next-line no-nested-ternary
  const logoLoadingScreen =
    companyName === 'Spotnana' ? getFullAssetPath('/v1-assets/images/s-logo-big-thin', 'svg') : '';

  const imgAlt = `${companyName}`;

  return {
    logoHeader: { imgSrc: logoHeader, imgAlt },
    logoFooter: { imgSrc: logoFooter, imgAlt },
    logoLoadingScreen: { imgSrc: logoLoadingScreen, imgAlt },
  };
}

/** This is a dummy fn for development, this will be replaced with fetching real data from API */
function useAppBrandingConfig() {
  const isUserLoggedIn = useIsLoggedIn();
  const {
    data: unauthAppWhiteLabel,
    isLoading: unauthConfLoading,
    error: unauthConfigError,
  } = useUnauthWhiteLabelConfig(SPOTNANA_SUB_DOMAIN);
  const { data: authAppWhiteLabel, isLoading: authConfLoading, error: authConfigError } = useWhiteLabelConfig();
  /**
   * We deliberaly break the UI if whitelabel config fails
   */

  if (isUserLoggedIn) {
    if (authConfLoading) {
      return 'UNDETERMINED';
    }
    if (authConfigError || !authAppWhiteLabel) {
      logConfigError('authenticated', authConfigError);
      return spotnanaConfig;
    }
    return transformWhiteLabelResponse(authAppWhiteLabel);
  }

  if (unauthConfLoading) {
    return 'UNDETERMINED';
  }

  if (unauthAppWhiteLabel === 'SPOTNANA_DEFAULT_CONFIG' || unauthConfigError || !unauthAppWhiteLabel) {
    logConfigError('unauthenticated', unauthConfigError);
    return spotnanaConfig;
  }

  return transformWhiteLabelResponse(unauthAppWhiteLabel);
}

function logConfigError(type: string, error: SpotnanaError | null) {
  if (error) {
    console.warn(
      `Error fetching ${type} whitelabel config. This API call needs to resolve correctly before the app can render. This call should return 404 for SPT domain, and 200 response for whitelabelled tenants.`,
      error,
    );
  }
}

export function transformWhiteLabelResponse(
  appWhiteLabel: WhiteLabelConfig,
): Pick<
  ValidConfig,
  | 'companyName'
  | 'poweredBySpotnana'
  | 'authPageConfig'
  | 'logoFooter'
  | 'logoHeader'
  | 'logoUrl'
  | 'faviconUrl'
  | 'logoutRedirectUrl'
  | 'externalHomePageUrl'
  | 'privacyLink'
  | 'disclaimerLink'
  | 'termsOfUseLink'
  | 'privacyDisclaimer'
> {
  return {
    companyName: appWhiteLabel.companyName || spotnanaConfig.companyName,
    poweredBySpotnana: appWhiteLabel.webLinks?.poweredBySpotnana || false,
    authPageConfig: {
      illustrations: appWhiteLabel.webLinks?.illustration || spotnanaConfig.authPageConfig.illustrations,
      illustrationActionUrl: appWhiteLabel.webLinks?.illustrationActionUrl,
    },
    logoHeader: appWhiteLabel.webLinks?.logoHeader || '',
    logoFooter: appWhiteLabel.webLinks?.logoFooter || '',
    logoUrl: appWhiteLabel.webLinks?.companyWebsiteAddress,
    faviconUrl: appWhiteLabel.webLinks?.faviconUrl || spotnanaConfig.faviconUrl,
    logoutRedirectUrl: appWhiteLabel.webLinks?.logoutRedirectUrl,
    externalHomePageUrl: appWhiteLabel.routingConfig?.appHomeRoute || spotnanaConfig.externalHomePageUrl,
    privacyLink: appWhiteLabel.webLinks?.privacyPage,
    disclaimerLink: appWhiteLabel.webLinks?.disclaimerPage,
    termsOfUseLink: appWhiteLabel.webLinks?.termsOfUse,
    privacyDisclaimer: appWhiteLabel.privacyDisclaimer,
  };
}

function transformFinalCustomisationConfig(finalCustomisationConfig: ValidConfig): ValidConfig {
  const { poweredBySpotnana } = finalCustomisationConfig;
  if (poweredBySpotnana) {
    return finalCustomisationConfig;
  }
  return {
    ...finalCustomisationConfig,
    logoHeader: spotnanaConfig.logoHeader,
    logoFooter: spotnanaConfig.logoFooter,
    logoUrl: spotnanaConfig.logoUrl,
    privacyLink: spotnanaConfig.privacyLink,
    disclaimerLink: spotnanaConfig.disclaimerLink,
  };
}

function Favicon() {
  const { companyName, faviconUrl } = useAppBranding();
  useDynamicTitle(companyName);
  useLink({ rel: 'icon', href: faviconUrl });
  return null;
}
