import { useAppBranding } from 'src/components/AppBrandingProvider/AppBrandingProvider';

function SpotnanaLogoShort(
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
): JSX.Element {
  const { companyName } = useAppBranding();
  /**
   * Removed Pixel Image component from logo due to visual glitching on slow networks,
   * might progressively enhance with a fallback with less convoluted logic
   */
  return <img alt={companyName} data-testid={`${companyName}-logo-s`} {...props} />;
}

export default SpotnanaLogoShort;
