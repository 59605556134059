import type { UseMutateAsyncFunction } from 'react-query';
import type { ISubmitVgsFormResponse } from '../../types';
import type { PostPaymentVerificationInfo } from '../../types/api/v2/obt/model/post-payment-verification-info';
import type {
  AirBookingPaymentSetupId,
  AirExchangePaymentSetupId,
  AirPostBookingPaymentSetupId,
} from '../../types/flight';
import type { IVGSForm, IVGSFormInfo, IVGsSubmitOptions } from '../../types/payments/vgs';
import type { IHeaders } from '../../hooks/useGetRequestHeader';
import type { ITraveler } from '../../types/traveler';
import type { ListBookingPaymentSourcesResponse } from '../../types/api/v2/obt/model/list-booking-payment-sources-response';
import type { PaymentSetupResponse } from '../../types/api/v2/obt/model/payment-setup-response';
import type { SpotnanaError } from '../../api/SpotnanaError';
import type { PaymentSetupRequest } from '../../types/api/v2/obt/model/payment-setup-request';
import ListBookingPaymentSourcesManager from '../../services/ListBookingPaymentSourcesManager';
import { PaymentSetupResponseStatus } from '../../types/api/v2/obt/model/payment-setup-response-status';
import { getStripe } from '../../hooks/payment/stripe/stripeUtils';
import { mutatePaymentSetupViaVgs } from './mutatePaymentSetupViaVgs';
import { getPaymentSetupRequestForPaymentSourceId } from './getPaymentSetupRequestForPaymentSourceId';

interface IProps {
  vgsFormMap: Record<string, IVGSFormInfo | null>;
  requestHeaders: IHeaders;
  paymentSourceIds: string[];
  primaryTraveler: ITraveler;
  listBookingPaymentSourcesResponse: ListBookingPaymentSourcesResponse;
  mutatePaymentSetup: UseMutateAsyncFunction<PaymentSetupResponse, SpotnanaError, PaymentSetupRequest, unknown>;
  paymentSetupId: AirBookingPaymentSetupId | AirExchangePaymentSetupId | AirPostBookingPaymentSetupId | null;
  postVerificationInfo?: PostPaymentVerificationInfo | undefined;
  submitVgsForm(vgsForm: IVGSForm, path: string, options: IVGsSubmitOptions): Promise<ISubmitVgsFormResponse>;
  isMobileFlow: boolean;
}

export async function mutatePaymentSetupForAllPaymentSourceIds({
  vgsFormMap,
  requestHeaders,
  paymentSourceIds,
  primaryTraveler,
  listBookingPaymentSourcesResponse,
  mutatePaymentSetup,
  paymentSetupId,
  postVerificationInfo,
  submitVgsForm,
  isMobileFlow,
}: IProps) {
  const paymentSourcesManager = new ListBookingPaymentSourcesManager(listBookingPaymentSourcesResponse);

  const promises = paymentSourceIds.map(async (sourceId) => {
    const source = paymentSourcesManager.getBookingPaymentSourceById(sourceId);

    if (!source) {
      return null;
    }

    let paymentSetupRequest = getPaymentSetupRequestForPaymentSourceId({
      source,
      primaryTraveler,
      paymentSetupId,
    });

    if (!paymentSetupRequest) {
      return null;
    }

    const vgsForm = vgsFormMap[sourceId]?.form;
    let vgsFormProp;
    if (isMobileFlow) {
      // Added typecast for mobile as in mobile we don't have vgsForm, will be removed when add support for multiFOP in mobile
      vgsFormProp = {} as IVGSForm;
    } else if (!vgsForm) {
      return null;
    } else {
      vgsFormProp = vgsForm;
    }

    if (postVerificationInfo) {
      paymentSetupRequest = { ...paymentSetupRequest, postVerificationInfo };
    }

    const paymentSetupResponse = await mutatePaymentSetupViaVgs(
      vgsFormProp,
      paymentSetupRequest,
      requestHeaders,
      submitVgsForm,
    );

    if (
      paymentSetupResponse.status !== PaymentSetupResponseStatus.VerificationRequired ||
      paymentSetupResponse.verificationInfo?.razorPayVerificationInfo
    ) {
      return paymentSetupResponse;
    }

    const stripeCardTokens = paymentSetupResponse.verificationInfo?.stripeVerificationInfo?.stripeCardTokens;
    const stripeAccountId =
      paymentSetupResponse.verificationInfo?.stripeVerificationInfo?.stripeCardTokens?.connectedAccountId ?? '';

    const stripePK =
      paymentSetupResponse.verificationInfo?.stripeVerificationInfo?.stripeCardTokens?.publishableKey ?? '';
    const stripe = getStripe({
      stripePK,
      stripeAccount: stripeAccountId,
    });

    const stripeSDKResponse = await stripe.confirmCardSetup(stripeCardTokens?.setupIntentClientSecret, {
      payment_method: stripeCardTokens?.paymentMethodId,
    });

    if ('error' in stripeSDKResponse) {
      throw new Error('STRIPE_3D_SECURE_FAILED');
    } else {
      const updatedPaymentSetupResponse = await mutatePaymentSetup({
        ...paymentSetupRequest,
        postVerificationInfo: {
          postStripeVerificationInfo: {
            paymentMethodId: stripeCardTokens?.paymentMethodId,
          },
        },
      });
      return updatedPaymentSetupResponse;
    }
  });

  const responses = await Promise.all(promises);

  const areAllResponsesValid = responses.every((res) => !!res && res.status === PaymentSetupResponseStatus.Ok);

  // TODO - This is a temporary change to handle the case where the user needs to authenticate with RazorPay will be removed once refactor for stirpe verification also
  const isRazorPayAuthRequired = responses.every((res) => !!res && res.verificationInfo?.razorPayVerificationInfo);

  if (areAllResponsesValid || isRazorPayAuthRequired) {
    return responses;
  }

  throw responses;
}
