import type { ILayoverInfo, IFlightInfo } from '../../types/flight';
import { getDateTimeDiff, getDateDiff } from '../../date-utils';

type ArrivalInfo = Pick<IFlightInfo, 'arrivalAirport' | 'arrivalAirportName' | 'arrivalDateTime'>;

type DepartureInfo = Pick<IFlightInfo, 'departureAirport' | 'departureAirportName' | 'departureDateTime'>;

export default (prior: ArrivalInfo, later: DepartureInfo): ILayoverInfo => {
  const { arrivalAirport, arrivalDateTime } = prior;

  const { departureAirport, departureAirportName, departureDateTime } = later;

  return {
    layoverAirportCode: departureAirport,
    layoverAirportName: departureAirportName,
    layoverDuration: getDateTimeDiff(departureDateTime, arrivalDateTime),
    transfer: departureAirport === arrivalAirport ? '' : `transfer from ${arrivalAirport} to ${departureAirport}`,
    overnight: getDateDiff(arrivalDateTime, departureDateTime) >= 1,
  };
};
