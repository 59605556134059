const selectors = {
  lastChild: ':last-child',
  firstChild: ':first-child',
  firstOfType: ':first-of-type',
  lastOfType: ':last-of-type',
  /*
   * ex: col:nth-child(2n+3)
   *     col:nth-child(odd)
   */
  nthChild: (n: 'even' | 'odd' | string) => `:nth-child(${n})`,
  nthOfType: (n: 'even' | 'odd' | string) => `:nth-of-type(${n})`,
};

const negativeSelectors: typeof selectors = {
  lastChild: ':not(:last-child)',
  firstChild: ':not(:first-child)',
  firstOfType: ':not(:first-of-type)',
  lastOfType: ':not(:last-of-type)',
  /*
   * ex: col:nth-child(2n+3)
   *     col:nth-child(odd)
   */
  nthChild: (n: 'even' | 'odd' | string) => `:not(:nth-child(${n}))`,
  nthOfType: (n: 'even' | 'odd' | string) => `:not(:nth-of-type(${n}))`,
};

export const cssSelectors = {
  ...selectors,
  not: negativeSelectors,
};

export { flex, margins, paddings, unit, cssUtils } from './utilityClasses';
export { themed } from './themed';
export * from './responsive';
