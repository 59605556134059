var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Refund = (_a) => {
    var { primaryColor } = _a, restProps = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 32 32", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M23.287 6.237l.01.074.399 3.763a1 1 0 01-.815 1.09l-.074.01-3.729.396a1 1 0 01-.285-1.979l.074-.01 1.504-.16a7.882 7.882 0 00-12.297 5.414 7.883 7.883 0 1015.353-1.224 1 1 0 111.913-.582 9.85 9.85 0 01.335 4.213c-.74 5.407-5.723 9.19-11.13 8.451-5.408-.739-9.193-5.722-8.453-11.129.74-5.407 5.722-9.192 11.13-8.452 1.536.21 2.97.768 4.214 1.613l-.127-1.203a1 1 0 01.815-1.09l.074-.01c.524-.055.996.305 1.089.815zm-6.944 4.922v.854l.234.015c.46.042.864.15 1.215.326.409.205.725.483.95.834.225.35.34.755.345 1.215H17.32l-.022-.143a.839.839 0 00-.258-.454c-.164-.149-.396-.237-.697-.264v1.58l.51.116.245.06c.635.169 1.131.421 1.488.757.401.378.603.871.606 1.48-.003.701-.254 1.254-.755 1.66-.501.405-1.2.62-2.094.648v.862h-.477v-.866l-.258-.018c-.758-.073-1.37-.304-1.84-.694-.52-.432-.786-1.072-.796-1.92h1.768l.013.135c.037.262.141.473.313.631.2.185.467.297.8.334v-1.678l-.567-.134-.24-.061c-.547-.154-.987-.379-1.32-.676-.382-.339-.572-.798-.57-1.377a2.067 2.067 0 01.34-1.184c.229-.342.546-.615.953-.82a3.579 3.579 0 011.404-.36v-.858h.477zm0 5.586v1.57c.308-.03.548-.116.718-.256a.669.669 0 00.259-.528.582.582 0 00-.24-.47c-.158-.121-.404-.227-.737-.316zm-.477-3.192l-.149.028a.93.93 0 00-.439.216.624.624 0 00-.199.457c-.005.189.063.342.203.46s.335.215.584.29v-1.451z" })));
};
Refund.defaultProps = defaultIconProps;
export default Refund;
