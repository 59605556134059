/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RailThirdParty } from './rail-third-party';
import { Location } from './location';
import { SourceReferenceInfo } from './source-reference-info';

/**
 * RailStation details
 * @export
 * @interface RailStation
 */
export interface RailStation {
  /**
   * City code of the rail station
   * @type {string}
   * @memberof RailStation
   */
  cityCode: string;
  /**
   * City name of the rail station
   * @type {string}
   * @memberof RailStation
   */
  cityName?: string;
  /**
   * Continent code of the rail station
   * @type {string}
   * @memberof RailStation
   */
  continentCode?: string;
  /**
   *
   * @type {Location}
   * @memberof RailStation
   */
  location?: Location;
  /**
   * Unique code for the rail station
   * @type {string}
   * @memberof RailStation
   */
  railStationCode: string;
  /**
   * Full Name of the rail station
   * @type {string}
   * @memberof RailStation
   */
  railStationName: string;
  /**
   *
   * @type {Array<SourceReferenceInfo>}
   * @memberof RailStation
   */
  sourceReferenceInfos: Array<SourceReferenceInfo>;
  /**
   *
   * @type {string}
   * @memberof RailStation
   */
  stationType?: RailStationStationTypeEnum;
  /**
   * Time zone for the rail station.
   * @type {string}
   * @memberof RailStation
   */
  timeZone?: string;
  /**
   * Country code for the rail station.
   * @type {string}
   * @memberof RailStation
   */
  countryCode: string;
  /**
   * State code for the rail station.
   * @type {string}
   * @memberof RailStation
   */
  stateCode: string;
  /**
   * Rail inventory for the rail station.
   * @type {string}
   * @memberof RailStation
   */
  thirdParty: RailThirdParty;
}

export const RailStationStationTypeEnum = {
  Individual: 'INDIVIDUAL',
  StationGroup: 'STATION_GROUP',
  Metro: 'METRO',
} as const;

export type RailStationStationTypeEnum = typeof RailStationStationTypeEnum[keyof typeof RailStationStationTypeEnum];
