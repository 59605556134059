/* eslint-disable @typescript-eslint/no-unused-vars */
export interface ICommonI18nMessage<Values> {
  message: never;
  values: Values;
}

export type AllowedI18nValueTypes = string | number | boolean;

export type ParseValuesFromStr<T extends string, Values = {}> = T extends `${infer _Left}{{${infer Right}`
  ? Right extends `${infer ValKey}}}${infer Rest}`
    ? ParseValuesFromStr<Rest, Values & Record<ValKey, ValKey extends 'count' ? number : AllowedI18nValueTypes>>
    : Values
  : Values;

export const defineMessage = (key: string): string => key;

export const defineCommonMessage = <T extends string>(key: T): ICommonI18nMessage<ParseValuesFromStr<T>> => ({
  message: key as never,
  values: {} as ParseValuesFromStr<T>,
});
