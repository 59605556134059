var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
const MapFlightOriginEndpoint = (_a) => {
    var { width, height, primaryColor, secondaryColor } = _a, restProps = __rest(_a, ["width", "height", "primaryColor", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 18 20", width: width, height: height, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { d: "m7.044 17.336-.096-.095-.13-.034C3.181 16.243.5 12.937.5 9a8.5 8.5 0 0 1 17 0c0 3.937-2.681 7.243-6.318 8.207l-.13.034-.096.095L9 19.293l-1.956-1.957Z", fill: "#fff", stroke: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M4.25 12.5h9.5v1h-9.5v-1Zm9.785-4.68a.748.748 0 0 0-.92-.53L10.46 8 7.01 4.785l-.965.255 2.07 3.585-2.485.665-.985-.77-.725.195.91 1.58.385.665.8-.215 2.655-.71 2.175-.58 2.655-.71a.761.761 0 0 0 .535-.925Z", fill: themeEnrichedPrimaryColor })));
};
export default MapFlightOriginEndpoint;
