import Config from '../../utils/Config';
import type { ITokens } from './types';
import { toAxiosError, safeJsonParse } from './utils';

async function refreshTokens(accessToken: string, refreshToken: string): Promise<ITokens> {
  const baseUrl = Config.VITE_API_BASE_URL as string;
  const requestUrl = `${baseUrl}v2/auth/oauth-refresh`;

  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('refresh_token', refreshToken);
  urlencoded.append('access_token', accessToken);
  urlencoded.append('grant_type', 'refresh_token');

  const requestOptions = {
    method: 'POST',
    headers,
    body: urlencoded.toString(), // cast to string as React Native don't transform it automatically
  };

  const response = await fetch(requestUrl, requestOptions);

  if (!response.ok) {
    const text = await response.text();
    const likeAxiosError = toAxiosError(requestUrl, safeJsonParse(text), response);

    return Promise.reject(likeAxiosError);
  }

  const result = (await response.json()) as ITokens;
  return result;
}

export default refreshTokens;
