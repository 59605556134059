import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const RemoveDash = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 20 20" },
        React.createElement("g", { fill: themeEnrichedPrimaryColor, transform: "translate(-2 -2)" },
            React.createElement("path", { d: "M7.905 12.712c-.388 0-.705-.317-.705-.712 0-.391.314-.712.705-.712l8.19-.007c.388 0 .705.317.705.712 0 .391-.314.712-.705.712l-8.19.007z" }))));
};
RemoveDash.defaultProps = defaultIconProps;
export default RemoveDash;
