var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultIconProps } from './common';
const TriangleTag = (_a) => {
    var { primaryColor, secondaryColor } = _a, otherProps = __rest(_a, ["primaryColor", "secondaryColor"]);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" }, otherProps),
        React.createElement("path", { d: "M16 0H6a6 6 0 0 0-6 6v10l8-8 8-8Z" })));
};
TriangleTag.defaultProps = defaultIconProps;
export default TriangleTag;
