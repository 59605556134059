import {
  setApplicationId,
  extractApplicationIdFromUrl,
  extractTmcIdFromUrl,
  removeApplicationId,
  fetchStorageValue,
  StorageKeys,
} from 'obt-common';
import { captureException, addBreadcrumb } from '../errorTracking';

export async function collectApplicationId(): Promise<void> {
  const search = window?.location?.search;

  try {
    const applicationId = extractApplicationIdFromUrl();
    const tmcId = extractTmcIdFromUrl();
    const tmcIdFromStorage = await fetchStorageValue<string>(StorageKeys.TMC_ID).catch(() => null);

    // if tmcId exist in URL different from localStorage tmcId AND no applicationId
    // then remove existing applicationId from localStorage
    if (!applicationId && tmcId && tmcId !== tmcIdFromStorage) {
      await removeApplicationId();
    }
    if (applicationId) {
      await setApplicationId(applicationId);
    }

    addBreadcrumb({
      message: 'Extracted applicationId from URL',
      data: { applicationId, locationSearch: search },
    });
  } catch (err) {
    captureException(err, { source: 'collectApplicationId', extra: { locationSearch: search } });
  }
}
