import type { AxiosInterceptorManager, AxiosResponse } from 'axios';
import axios from 'axios';

type OnFulfilled = Parameters<AxiosInterceptorManager<AxiosResponse>['use']>[0];
type OnRejected = Parameters<AxiosInterceptorManager<AxiosResponse>['use']>[1];

interface ResponseInterceptorParams {
  onFulfilled?: OnFulfilled;
  onRejected?: OnRejected;
  shouldIgnoreError?: boolean;
}

type UnsubscribeFn = () => void;

/**
 * Docs: https://axios-http.com/docs/interceptors
 */
function addResponseInterceptor({
  onFulfilled = (response) => response,
  onRejected = (error) => error,
  shouldIgnoreError = true,
}: ResponseInterceptorParams): UnsubscribeFn {
  const id = axios.interceptors.response.use(
    async (response) => {
      try {
        const newResponse = await onFulfilled(response);
        return newResponse;
      } catch (err) {
        if (shouldIgnoreError) {
          return response;
        }

        throw err;
      }
    },
    async (error) => {
      try {
        const newError = await onRejected(error);

        return Promise.reject(newError);
      } catch (err) {
        if (shouldIgnoreError) {
          return Promise.reject(error);
        }

        throw err;
      }
    },
  );

  return () => {
    axios.interceptors.response.eject(id);
  };
}

export default addResponseInterceptor;
