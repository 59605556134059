import { useMutation, useQuery } from 'react-query';

import type { TierConfig } from '@spotnana/types/openapi/models/tier-config';
import api from '../../api';
import SpotnanaError from '../../api/SpotnanaError';
import type { FeatureConfigs, SpotnanaQueryMutationResult, SpotnanaQueryResult, VendorType } from '../../types';
import type { UpdateFeaturesRequest } from '../../types/api/v2/obt/model/update-features-request';
import { defaultQueryClient } from '../defaultQueryClient';
import { getPolicyPdfCompanyKey } from '../utils';

interface UpdateUserFeaturesRequest {
  userId: string;
  request: UpdateFeaturesRequest;
}

export const uploadPreferredVendorData = async (
  companyId: string,
  file: File,
  vendorType: VendorType,
): Promise<boolean> => {
  const data = new FormData();
  data.append('vendorType', vendorType);
  data.append('file', file);

  try {
    await api('POST', 'companyBaseUrl', { urlParam: `/${companyId}/vendor-preferences`, data });
    return true;
  } catch {
    return false;
  }
};

export const useUploadPreferredVendorDataMutation = (): SpotnanaQueryMutationResult<
  boolean,
  { companyId: string; file: File; vendorType: VendorType }
> => useMutation(({ companyId, file, vendorType }) => uploadPreferredVendorData(companyId, file, vendorType));

export const uploadArrangerData = async (file: File, companyId: string): Promise<boolean> => {
  const data = new FormData();
  data.append('file', file);
  try {
    await api('POST', 'companyBaseUrl', { urlParam: `/${companyId}/arrangers`, data });
    return true;
  } catch {
    return false;
  }
};

export const useUploadArrangerDataMutation = (): SpotnanaQueryMutationResult<
  boolean,
  { companyId: string; file: File }
> => useMutation(({ companyId, file }) => uploadArrangerData(file, companyId));

export const uploadUsersData = async (file: File, companyId: string): Promise<boolean> => {
  const data = new FormData();
  data.append('file', file);
  data.append('organizationId', companyId);
  try {
    await api('POST', 'userUpload', { data });
    return true;
  } catch {
    return false;
  }
};

export const useUploadUsersDataMutation = (): SpotnanaQueryMutationResult<boolean, { companyId: string; file: File }> =>
  useMutation(({ companyId, file }) => uploadUsersData(file, companyId));

export const DownloadArrangerData = async (companyId: string): Promise<File> => {
  const file = await api('GET', 'companyBaseUrl', { urlParam: `/${companyId}/arrangers` });
  return file as File;
};
export const useDownloadArrangersDataMutation = (): SpotnanaQueryMutationResult<File, { companyId: string }> =>
  useMutation(({ companyId }) => DownloadArrangerData(companyId));

interface UploadPdfProps {
  companyId: string;
  policyId: string;
  file: File;
}

export const uploadTravelPolicyPdf = async ({ file, companyId, policyId }: UploadPdfProps): Promise<void> => {
  const data = new FormData();
  data.append('file', file);
  await api('POST', 'companyBaseUrl', { urlParam: `/${companyId}/policies/${policyId}/document`, data });
};

export const useUploadTravelPolicyPdfMutation = (): SpotnanaQueryMutationResult<void, UploadPdfProps> =>
  useMutation(({ companyId, policyId, file }) => uploadTravelPolicyPdf({ file, companyId, policyId }), {
    onSuccess(_, { companyId, policyId }) {
      defaultQueryClient.invalidateQueries(getPolicyPdfCompanyKey(companyId, policyId));
    },
  });

const getUserFeaturesKey = (userId: string | undefined): string => `/v2/users/${userId}/features`;

const fetchUserFeatures = async (userId: string | undefined): Promise<FeatureConfigs> => {
  try {
    return (await api('GET', 'userBaseUrl', {
      urlParam: `/${userId}/features`,
    })) as FeatureConfigs;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useFetchUserFeatures = (userId: string | undefined, enabled = true): SpotnanaQueryResult<FeatureConfigs> =>
  useQuery<FeatureConfigs, SpotnanaError>(getUserFeaturesKey(userId), () => fetchUserFeatures(userId), {
    cacheTime: 0,
    enabled: !!userId && enabled,
  });

const updateUserFeatures = async (userId: string, request: UpdateFeaturesRequest): Promise<void> => {
  try {
    await api('PATCH', 'userBaseUrl', {
      urlParam: `/${userId}/features`,
      data: request,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useUpdateUserFeaturesMutation = (): SpotnanaQueryMutationResult<void, UpdateUserFeaturesRequest> =>
  useMutation((requestBody: UpdateUserFeaturesRequest) => updateUserFeatures(requestBody.userId, requestBody.request), {
    onSuccess: (_res, requestBody) => {
      // Invalidate user features query to fetch fresh data
      defaultQueryClient.invalidateQueries(getUserFeaturesKey(requestBody.userId));
    },
  });

const getUserTierConfigKey = (userId: string): string => `/v2/users/${userId}/tier-config`;

const getUserTierConfig = async (userId: string): Promise<TierConfig> => {
  try {
    return (await api('GET', 'userBaseUrl', {
      urlParam: `/${userId}/tier-config`,
    })) as TierConfig;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useFetchUserTierConfig = (userId: string, enabled = true): SpotnanaQueryResult<TierConfig> =>
  useQuery<TierConfig, SpotnanaError>(getUserTierConfigKey(userId), () => getUserTierConfig(userId), {
    enabled: !!userId && enabled,
  });

const getUserTierKey = (userId: string): string => `/v2/users/${userId}/tier`;

const getUserTier = async (userId: string): Promise<string> => {
  try {
    return (await api('GET', 'userBaseUrl', {
      urlParam: `/${userId}/tier`,
    })) as string;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useFetchUserTier = (userId: string, enabled = true): SpotnanaQueryResult<string> =>
  useQuery<string, SpotnanaError>(getUserTierKey(userId), () => getUserTier(userId), {
    enabled: !!userId && enabled,
  });

const updateUserTier = async (userId: string, tier: string): Promise<void> => {
  try {
    await api('PUT', 'userBaseUrl', {
      urlParam: `/${userId}/tier`,
      data: { tier },
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useUpdateUserTierMutation = (): SpotnanaQueryMutationResult<void, { userId: string; tier: string }> =>
  useMutation(({ userId, tier }) => updateUserTier(userId, tier), {
    onSuccess: (_res, { userId }) => {
      defaultQueryClient.invalidateQueries(getUserTierKey(userId));
    },
  });
