/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DateModel } from './date-model';
import { EmergencyContactInfo } from './emergency-contact-info';
import { EmergencyContact } from './emergency-contact';
import { IdentityDocument } from './identity-document';
import { Image } from './image';
import { Name } from './name';
import { PaymentInfo } from './payment-info';
import { PhoneNumber } from './phone-number';
import { PostalAddress } from './postal-address';
import { UserId } from './user-id';

/**
 * User details.
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {Array<PostalAddress>}
   * @memberof User
   */
  addresses?: Array<PostalAddress>;
  /**
   *
   * @type {DateModel}
   * @memberof User
   */
  dob?: DateModel;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {EmergencyContactInfo}
   * @memberof User
   */
  emergencyContactInfo?: EmergencyContactInfo;
  /**
   *
   * @type {EmergencyContact}
   * @memberof User
   */
  emergencyContact?: EmergencyContact;
  /**
   *
   * @type {string}
   * @memberof User
   */
  gender: UserGenderEnum;
  /**
   *
   * @type {UserId}
   * @memberof User
   */
  id?: UserId;
  /**
   *
   * @type {Array<IdentityDocument>}
   * @memberof User
   */
  identityDocs: Array<IdentityDocument>;
  /**
   *
   * @type {Name}
   * @memberof User
   */
  name?: Name;
  /**
   *
   * @type {Array<PaymentInfo>}
   * @memberof User
   */
  paymentInfos: Array<PaymentInfo>;
  /**
   *
   * @type {Array<PhoneNumber>}
   * @memberof User
   */
  phoneNumbers: Array<PhoneNumber>;
  /**
   *
   * @type {Image}
   * @memberof User
   */
  profilePicture?: Image;
  /**
   *
   * @type {string}
   * @memberof User
   */
  status: UserStatusEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  title: UserTitleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserGenderEnum {
  Unknown = 'UNKNOWN',
  Male = 'MALE',
  Female = 'FEMALE',
  Unspecified = 'UNSPECIFIED',
  Unrecognized = 'UNRECOGNIZED',
}
/**
 * @export
 * @enum {string}
 */
export enum UserStatusEnum {
  NonVerified = 'NON_VERIFIED',
  Verified = 'VERIFIED',
  Deactivated = 'DEACTIVATED',
  Unrecognized = 'UNRECOGNIZED',
}
/**
 * @export
 * @enum {string}
 */
export enum UserTitleEnum {
  TitleUnknown = 'TITLE_UNKNOWN',
  Mr = 'MR',
  Ms = 'MS',
  Mrs = 'MRS',
  Unrecognized = 'UNRECOGNIZED',
}
