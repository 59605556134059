import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Power = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M21.092 7a3.91 3.91 0 013.903 3.707l.005.201v10.184a3.91 3.91 0 01-3.707 3.903l-.201.005H10.908a3.91 3.91 0 01-3.903-3.707L7 21.092V10.908a3.91 3.91 0 013.707-3.903L10.908 7h10.184zm0 2H10.908a1.909 1.909 0 00-1.902 1.76L9 10.907v10.184c0 1.003.775 1.826 1.76 1.902l.148.006h10.184a1.909 1.909 0 001.902-1.76l.006-.148V10.908A1.908 1.908 0 0021.092 9zM16 10.5a5.5 5.5 0 110 11 5.5 5.5 0 110-11zm0 1c-2.486 0-4.5 2.014-4.5 4.5s2.014 4.5 4.5 4.5 4.5-2.014 4.5-4.5-2.014-4.5-4.5-4.5zM14.25 15a1 1 0 110 2 1 1 0 010-2zm3.5 0a1 1 0 110 2 1 1 0 010-2z", fill: themeEnrichedPrimaryColor })));
};
Power.defaultProps = defaultIconProps;
export default Power;
