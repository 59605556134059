import { Global, css } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { hideVisually } from 'polished';
import { blocksPalette } from '../BlocksThemeProvider/ui-specs/blocksPalette';
import { themeSelector } from '../utils/styling/utilityClasses';
import { getCornerRadii, getDimensions, getPaddings, getSpacings } from './globalStylesUtils';
import { useBlocksTheme } from '../utils/styling/useBlocksTheme';

/**
 * Includes styling that makes "rem" values auto-scale based on device size
 */
export function GlobalStyles() {
  return (
    <>
      <CssBaseline />
      <Global
        styles={css`
          :root {
            ${getDimensions()}
            ${getPaddings()}
            ${getSpacings()}
            ${getCornerRadii()}
          }
          html {
            /* http://meyerweb.com/eric/tools/css/reset/ 
              v2.0 | 20110126
              License: none (public domain)
            */

            body {
              font-size: 1rem;
            }

            p {
              margin: 0;
              padding: 0;
              margin-block-start: 0;
              margin-block-end: 0;
            }

            button {
              padding: 0;
              margin: 0;
              border: 0;
              background: none;
              cursor: pointer;
              font-family: unset;
            }

            /* HTML5 display-role reset for older browsers */
            article,
            aside,
            details,
            figcaption,
            figure,
            footer,
            header,
            hgroup,
            menu,
            nav,
            section {
              display: block;
            }
            ol,
            ul {
              list-style: none;
              /** remove chrome user agent styles */
              margin-block-start: 0;
              margin-block-end: 0;
              padding-inline-start: 0;
            }
            blockquote,
            q {
              quotes: none;
            }
            blockquote:before,
            blockquote:after,
            q:before,
            q:after {
              content: '';
              content: none;
            }
            table {
              border-collapse: collapse;
              border-spacing: 0;
            }

            box-sizing: border-box;

            /* Mobile */
            font-size: 14px;

            /* Tablets */
            @media (min-width: 768px) {
              font-size: 14.8px;
            }

            /* Small Desktop */
            @media (min-width: 1024px) {
              font-size: 15.4px;
            }

            /* Medium Desktop */
            @media (min-width: 1440px) {
              font-size: 16px;
            }

            /* HD & up */
            @media (min-width: 1920px) {
              font-size: 16px;
            }

            /* 4K and up */
            @media (min-width: 3000px) {
              font-size: 16.8px;
            }
          }

          .only-screenreader {
            position: absolute !important; /* Outside the DOM flow */
            height: 1px;
            width: 1px; /* Nearly collapsed */
            overflow: hidden;
            clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
            clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
          }

          .visually-hidden {
            ${hideVisually()}
          }

          .full-width {
            width: 100%;
          }

          :not(.preserve-default-colors).fill-current {
            fill: currentcolor !important;
          }

          :not(.preserve-default-colors).stroke-current {
            stroke: currentcolor !important;
          }
        `}
      />
    </>
  );
}

/**
 * Temporary component until we phase out all Pixel components and are able to apply all globalstyles confidently.
 */
export function StrictlyNecessaryBlocksGlobalStyles() {
  const { fontFamily } = useBlocksTheme();
  return (
    <Global
      styles={css`
        :root {
          ${getDimensions()}
          ${getPaddings()}
          ${getSpacings()}
          ${getCornerRadii()}
        }

        /*
          Remove user agent button styles from Chrome (not needed if using GlobalStyles as it has CSS reset)
         */
        button {
          background-color: unset;
        }

        html {
          /* Mobile */
          font-size: 14px;

          /* Tablets */
          @media (min-width: 768px) {
            font-size: 14.8px;
          }

          /* Small Desktop */
          @media (min-width: 1024px) {
            font-size: 15.4px;
          }

          /* Medium Desktop */
          @media (min-width: 1440px) {
            font-size: 16px;
          }

          /* HD & up */
          @media (min-width: 1920px) {
            font-size: 16px;
          }

          /* 4K and up */
          @media (min-width: 3000px) {
            font-size: 16.8px;
          }
        }

        body {
          font-family: ${fontFamily.join(',')};
          background-color: ${blocksPalette.light.surface.base};
          ${themeSelector('dark')} {
            background-color: ${blocksPalette.dark.surface.base};
          }
        }

        .only-screenreader {
          position: absolute !important; /* Outside the DOM flow */
          height: 1px;
          width: 1px; /* Nearly collapsed */
          overflow: hidden;
          clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
          clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
        }

        .visually-hidden {
          ${hideVisually()}
        }

        .full-width {
          width: 100%;
        }
        :not(.preserve-default-colors).fill-current {
          fill: currentcolor !important;
        }

        :not(.preserve-default-colors).stroke-current {
          stroke: currentcolor !important;
        }
      `}
    />
  );
}
