/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Car type.
 * @export
 * @enum {string}
 */

export const CarType = {
  Other: 'OTHER',
  Mini: 'MINI',
  Economy: 'ECONOMY',
  Compact: 'COMPACT',
  MidSize: 'MID_SIZE',
  Standard: 'STANDARD',
  FullSize: 'FULL_SIZE',
  Premium: 'PREMIUM',
  Luxury: 'LUXURY',
  Convertible: 'CONVERTIBLE',
  Minivan: 'MINIVAN',
  Suv: 'SUV',
  Van: 'VAN',
  Pickup: 'PICKUP',
  Sports: 'SPORTS',
  Special: 'SPECIAL',
  RecreationalVehicle: 'RECREATIONAL_VEHICLE',
  Wagon: 'WAGON',
} as const;

export type CarType = (typeof CarType)[keyof typeof CarType];
