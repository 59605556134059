import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Draft = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 14", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 7A7 7 0 110 7a7 7 0 0114 0zm-7 5A5 5 0 107 2a5 5 0 000 10z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.8 3.667c.333 0 .607.298.63.677l.003.055v2.28l1.903 2.43a.82.82 0 01-.005.99l-.039.044a.571.571 0 01-.855-.005l-.039-.045-2.067-2.64a.792.792 0 01-.162-.424l-.002-.067V4.399c0-.405.283-.732.633-.732z", fill: themeEnrichedPrimaryColor })));
};
Draft.defaultProps = defaultIconProps;
export default Draft;
