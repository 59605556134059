import type { Card, CreditCardAccess } from '../../common/card';
import { CreditCardAccessType } from '../../common/card';
/* eslint-disable */
import { DateTime, Duration } from '../../../v1/common/date_time';
import { EmergencyContactInfo } from '../../../v1/obt/common/emergency_contact_info';
import { Gender } from '../../../v1/common/gender';
import { ImageV1 } from '../../../v1/common/image_group';
import { Name } from '../../../v1/common/name';
import { PhoneNumber } from '../../../v1/common/phone_number';
import { PostalAddress } from '../../../v1/common/postal_address';
import { UserId, UserSid } from '../../../v1/common/user_id';
import { RelativeRelativeOf, Relation } from './relative';
import { TitleEnum } from './title';
import { EmergencyContact } from './emergency_contact';

export interface Passport {
  type: PassportType;
  docId: string;
  issuedDate?: DateTime;
  expiryDate?: DateTime;
  issueCountry: string;
  nationalityCountry: string;
  /** @deprecated */
  name?: Name;
  /** @deprecated */
  birthDate?: DateTime;
}

export interface NationalDoc {
  /** Unique id identifying the national document. */
  docId: string;
  /** IS0 2 letter country code of the country issuing this id. */
  issueCountry: string;
  /** Issued date of the national document. */
  issuedDate?: DateTime;
  /** Expiry date of the national document. */
  expiryDate?: DateTime;
  /** Type of document */
  type: NationalDocType;
}

export enum NationalDocType {
  TYPE_UNKNOWN = 0,
  DNI = 1,
  NIE = 2,
  UNRECOGNIZED = -1,
}

/**
 *  Represents document such visas, resident permits, alien registration card (Green Card), etc.
 */
export interface ImmigrationDocument {
  type: ImmigrationDocumentType;
  docId: string;
  issuedDate?: DateTime;
  expiryDate?: DateTime;
  issueCountry: string;
  nationalityCountry: string;
  authorizedStayDuration?: Duration;
  reentryRequirementDuration?: Duration;
}

export interface KnownTravelerNumber {
  number: string;
  issueCountry: string;
}

export interface RedressNumber {
  number: string;
  issueCountry: string;
}

export interface IdentityDocument {
  passport?: Passport;
  immigrationDoc?: ImmigrationDocument;
  /** @deprecated */
  knownTravelerNumber?: string;
  ktn?: KnownTravelerNumber;
  /** @deprecated */
  redressNumber?: string;
  redress?: RedressNumber;
  /** National document identifying a user similar to Aadhaar Card in India. */
  nationalDoc?: NationalDoc;
}

export interface PaymentInfo {
  applicableTo?: PaymentInfoApplicableTo[];
  card: Card;
  /** @deprecated (use access instead) Identifies the different ways the card can be used across an organization,  */
  accessType: CreditCardAccessType;
  access?: CreditCardAccess;
}
export const CreditCardAccessTypeEnum = CreditCardAccessType;

export interface RelativeInfo {
  relativeOf?: RelativeRelativeOf;
  relation: Relation;
}

export interface User {
  /** @deprecated */
  id?: UserId;
  email: string;
  status: UserStatus;
  title: TitleEnum;
  name?: Name;
  gender: Gender;
  dob?: DateTime;
  addresses: PostalAddress[];
  phoneNumbers: PhoneNumber[];
  identityDocs: IdentityDocument[];
  paymentInfos: PaymentInfo[];
  emergencyContactInfo?: EmergencyContactInfo;
  emergencyContact?: EmergencyContact;
  profilePicture?: ImageV1;
  sid?: UserSid;
  /**
   * When a new user is added as relative persona , these details belong
   * to the already present user which is related to the new user.
   */
  relativeInfo?: RelativeInfo;
  /** Nationality of user. */
  nationality?: string;
  /** User preferred language. */
  preferredLanguage?: string;
  /** User preferred pronoun. */
  preferredPronoun?: PreferredPronoun;
  /** Traveler name can't contain special characters, which is sanitized by user. */
  travelerName?: Name;
}

export enum PassportTypeEnum {
  UNKNOWN = 0,
  REGULAR = 1,
  UNRECOGNIZED = -1,
}

export type PassportType = 0 | 1 | -1;

export enum ImmigrationDocumentTypeEnum {
  UNKNOWN = 0,
  VISA = 1,
  UNRECOGNIZED = -1,
}

export type ImmigrationDocumentType = 0 | 1 | -1;

export enum PaymentInfoApplicableToEnum {
  UNKNOWN_APPLICABLE_TO = 0,
  AIR = 1,
  HOTEL = 2,
  RAIL = 3,
  CAR = 4,
  SERVICE_FEE = 5,
  UNRECOGNIZED = -1,
}

export type PaymentInfoApplicableTo = 0 | 1 | 2 | 3 | 4 | 5 | -1;

export enum UserStatusEnum {
  NON_VERIFIED = 0,
  VERIFIED = 1,
  DEACTIVATED = 2,
  UNRECOGNIZED = -1,
}

export type UserStatus = 0 | 1 | 2 | -1;

// TODO aviral: check if this can be deprecated in favour of common/title.ts
export enum UserTitleEnum {
  TITLE_UNKNOWN = 0,
  MR = 1,
  MS = 2,
  MRS = 3,
  MX = 4,
  MASTER = 5,
  MISS = 6,
  PROFESSOR = 7,
  CAPTAIN = 8,
  DR = 9,
  REVEREND = 10,
  HONOURABLE = 11,
  SIR = 12,
  LADY = 13,
  AMBASSADOR = 14,
  LORD = 15,
  BRIGADIER = 16,
  SENATOR = 17,
  DAME = 18,
  JUSTICE = 19,
  /** UK - Unknown or not provided */
  UK = 20,
  UNRECOGNIZED = -1,
}

export type UserTitle =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | -1;

export enum PreferredPronounEnum {
  PREFERRED_PRONOUN_UNKNOWN = 0,
  SHE_HER_HERS = 1,
  HE_HIM_HIS = 2,
  THEY_THEM_THEIRS = 3,
  UNRECOGNIZED = -1,
}

export type PreferredPronoun = 0 | 1 | 2 | 3 | -1;
