import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const ChatBubble = ({ primaryColor, secondaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    const themeEnrichedSecondaryColor = get(theme, `colors.${secondaryColor}`, secondaryColor);
    return (React.createElement("svg", { width: width, height: height, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M0 6a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v6a6 6 0 0 1-6 6H0V6Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("rect", { x: 4, y: 5, width: 16, height: 2, rx: 1, fill: themeEnrichedSecondaryColor }),
        React.createElement("rect", { x: 4, y: 11, width: 11, height: 2, rx: 1, fill: themeEnrichedSecondaryColor })));
};
export default ChatBubble;
ChatBubble.defaultProps = defaultIconProps;
