import first from 'lodash/first';
import { convertToFriendlyText, usePrevious, useTelemetry } from 'obt-common';
import { PropsWithChildren, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import routesConfig from '../../routes';
import { adminRoutesMap } from '../../v1-components/admin/routes';

interface IRouteDetails {
  key: string;
  path: string;
  search: string;
  pathRegex: string;
}

/**
 * Combine all routes. Route key is then used for surface telemetry
 */
const adminRoutes = Object.values(adminRoutesMap).map((x) => ({ ...x, search: '' }));
const routes = [...routesConfig, ...adminRoutes].map((x) => {
  const path = (x.path + (x.search ?? ''))
    .split('/')
    .map((y) => (y.startsWith(':') ? '[^/]*' : y))
    .join('/');
  return { ...x, pathRegex: path } as IRouteDetails;
});

export const SurfaceTelemetryProvider = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const { pathname } = useLocation();
  const { trackSurface } = useTelemetry();
  const previousPathname = usePrevious(pathname);
  useEffect(() => {
    if (!trackSurface) return;
    if (previousPathname === pathname) return;
    // Check if any route path matches the current path verbatim
    let routeConfig = first(routes.filter((x) => x.path === pathname));
    /**
     * If no route is exactly matched with the current path then use regex based matching.
     * Regex based matching will work assuming no two dynamic paths will have exactly same regex.
     */
    if (!routeConfig) {
      routeConfig = first(routes.filter((x) => new RegExp(`^${x.pathRegex}$`).test(pathname)));
    }
    const telemetryName = convertToFriendlyText(routeConfig?.key ?? pathname);
    trackSurface({
      name: telemetryName || 'Home', // slash (/) is Home page
    });
  }, [pathname, previousPathname, trackSurface]);
  return <>{children}</>;
};
