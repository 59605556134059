import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const CompanyEnrollmentPage = lazy(() => import('./company/CompanyEnrollmentPage'));

export const selfEnrollmentRoutes: IRouteConfig[] = [
  {
    key: 'apply',
    path: '/apply',
    component: CompanyEnrollmentPage,
    isPublicRoute: true,
  },
  {
    key: 'company-enrollment',
    path: '/enrollment/company',
    component: CompanyEnrollmentPage,
    isPublicRoute: true,
  },
];
