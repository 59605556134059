import { useLayoutEffect } from 'react';
import { Hub } from '@aws-amplify/core';
import { setSessionId, verifyUUID } from './common';

/**
 * Restores Session ID from Custom OAuthState
 */
export function RestoreSessionId() {
  // This needs to be a layout effect to not miss any events
  useLayoutEffect(() => {
    return Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'customOAuthState') {
        const { sessionId } = JSON.parse(payload.data) as Record<string, string>;
        // validate session id and restore it
        if (sessionId && verifyUUID(sessionId)) {
          setSessionId(sessionId);
        }
      }
    });
  }, []);

  return null;
}
