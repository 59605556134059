import { css } from '@emotion/react';
import { flex, themed } from '../utils/styling';
import type { BlocksTheme } from '../types';

export const avatarRootClassName = 'BlocksAvatar-root';
export const avatarSizeClassName = 'BlocksAvatar-size';
export const avatarTextClassName = 'BlocksAvatar-text';
export const avatarColorClassName = 'BlocksAvatar-color';
export const avatarIconClassName = 'BlocksAvatar-icon';

type TextAvatarColorConfig = {
  text: string;
  background: string;
  border: string;
};

type paletteToColorConfigResolver = (palette: BlocksTheme['palette']) => TextAvatarColorConfig;

export const randomColorVariants = ['warning', 'info', 'success', 'error'] as const;
export const colorVariants = ['primary', ...randomColorVariants] as const;
export const variantToColorConfigMap: Record<(typeof colorVariants)[number], paletteToColorConfigResolver> = {
  primary: (palette) => ({
    text: palette.text.link,
    background: palette.surface.background,
    border: palette.border.medium,
  }),
  warning: (palette) => ({
    text: palette.text.warning,
    background: palette.bg.warningSubtle,
    border: palette.border.warningSubtle,
  }),
  info: (palette) => ({
    text: palette.text.link,
    background: palette.bg.infoSubtle,
    border: palette.border.infoSubtle,
  }),
  success: (palette) => ({
    text: palette.text.success,
    background: palette.bg.successSubtle,
    border: palette.border.successSubtle,
  }),
  error: (palette) => ({
    text: palette.text.error,
    background: palette.bg.errorSubtle,
    border: palette.border.errorSubtle,
  }),
};

const generateColorVariantStyles = (palette: BlocksTheme['palette']) => {
  return css(
    colorVariants.map((variant) => {
      /**
       * The TextAvatar determines the color based on passed color/random variant.
       * The Color class is added at `abbr` level which is inside the root avatar,
       * so we check the presence of the nested color class (using :has) and apply style on root as well as `abbr`
       */

      const variantClassName = `${avatarColorClassName}-${variant}`;
      const colorConfig = variantToColorConfigMap[variant](palette);

      return css`
        &:has(.${variantClassName}) {
          background-color: ${colorConfig.background};
          border: solid 1px ${colorConfig.border};
        }

        .${avatarTextClassName}.${variantClassName} {
          color: ${colorConfig.text};
        }
      `;
    }),
  );
};

export const avatar_container = css`
  position: relative;
`;

export const avatar_base_style = themed(
  ({ palette }) => css`
    &.${avatarRootClassName} {
      &.${avatarIconClassName} {
        background-color: ${palette.surface.base};
        border: solid 1px ${palette.border.medium};

        svg {
          color: ${palette.icon.primary};
        }
      }

      &.${avatarSizeClassName}-small {
        width: 24px;
        height: 24px;

        svg {
          font-size: 16px;
        }
      }

      &.${avatarSizeClassName}-medium {
        width: 32px;
        height: 32px;
        svg {
          font-size: 20px;
        }
      }

      &.${avatarSizeClassName}-large {
        width: 40px;
        height: 40px;
        svg {
          font-size: 24px;
        }
      }

      &.${avatarSizeClassName}-xlarge {
        width: 48px;
        height: 48px;
        svg {
          font-size: 32px;
        }
      }

      .${avatarTextClassName} {
        text-decoration: unset;
      }

      ${generateColorVariantStyles(palette)}
    }
  `,
);

export const avatar_badge = themed(
  ({ palette }) => css`
    position: absolute;
    ${flex.init}
    right: 0;
    bottom: 0;
    border-radius: 100%;
    outline: 1px solid ${palette.surface.base};
    border: 1px solid ${palette.border.light};
  `,
);
