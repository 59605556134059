import { TFlatSelectOptions, TSelectSizes, TSelectSlots } from '@spotnana/blocks/src/SelectRoot';
import React, { useCallback, useEffect, useId, useMemo, useRef } from 'react';
import { ComboboxPopover, ComboboxProvider, SelectList, ComboboxPopoverProps } from '@ariakit/react';
import { DeprecatedOptionWithStringifiedValue, addDeprecatedTypeMetadata } from '../SelectRoot/helpers';
import { popover_wrapper, select_wrapper } from '../SelectRoot/SelectRoot.styles';
import { getRevisedSelectedOptions, saturateControlledValue } from './Autocomplete.utils';
import { useAutocompleteStore } from './Autocomplete.hooks';
import { child_padding, option_list_padding, title_padding } from './Autocomplete.styles';
import { AutocompleteOptionType, AutocompleteSelectionModes, TAutocompleteOption } from './Autocomplete.types';
import { AutocompleteItem } from './AutocompleteItem';
import { Typography } from '../Typography';
import { SelectConfigContext } from '../SelectRoot/SelectConfigContext';
import { ComboboxTrigger } from '../SelectRoot/defaultValueRenderers/ComboboxTrigger';
import { VisuallyHidden } from '../VisuallyHidden';
import { fixPopoverEscapeKeyDown } from '../utils/ariaKitFixes';

export type IAutocompleteProps<T extends object> = {
  /**
   * Value: Options selected as of now. These should be a subset of options
   */
  value: TAutocompleteOption<T>[];
  /**
   * onChange: Sets new set of options in the parent
   */
  onChange: (newValue: TAutocompleteOption<T>[]) => void;
  /**
   * options: Available options to the Autocomplete. Value should be a subset of this.
   */
  options: TAutocompleteOption<T>[];
  /**
   * placeholder: Placeholder text to be displayed in the Input
   */
  placeholder: string;
  /**
   * isLoading: To decide if loader has to be displayed
   */
  isLoading?: boolean;
  /**
   *
   */
  size?: TSelectSizes;
  /**
   * selectionMode: To pass relevant selected options back to parent. This can be ALL - All options, LEAF - Standalone and Children, ROOT - Standalone and Parents
   */
  selectionMode?: AutocompleteSelectionModes;
  /**
   * setSearchString: Sets search string in the parent
   */
  setSearchString?: (newSearchString: string) => void;
  /**
   * Slots: Parent controls for ItemPrefix, ItemSuffix, ItemDescription and Multivalue (input element)
   */
  slots: TSelectSlots<TAutocompleteOption<T>>;
  /**
   * loadingContent: Component to be rendered in the dropdown when isLoading to be true
   */
  loadingContent?: React.ReactElement;
  /**
   * emptyContent: Component to be rendered in the dropdown when no options are found
   */
  emptyContent?: React.ReactElement | string;
  /**
   * errorMessage: To render error state
   */
  error?: boolean;
  /**
   * helperText: To render below the input
   */
  helperText?: string;
  /**
   * disabled: If true, the input is disabled
   */
  disabled?: boolean;
  /**
   * minimumSearchTextLength: The minimum character length required to fire a search
   */
  minimumSearchTextLength?: number;
  /**
   * firstOptionRef: Ref to the first option in the dropdown
   */
  firstOptionRef: React.RefObject<HTMLDivElement>;
  /**
   * aria-label: Aria label for the Input component (Required for a11y)
   */
  'aria-label': string;
  /**
   * popoverProps: Props to be passed to the ComboboxPopover
   * @see https://ariakit.org/reference/combobox-popover
   */
  popoverProps?: ComboboxPopoverProps;
};

/**
 * Autocomplete Component : Takes in options and renders it for user to select multiple elements.
 */
export function Autocomplete<T extends object>({
  value,
  onChange,
  options,
  isLoading,
  setSearchString,
  selectionMode = AutocompleteSelectionModes.ALL,
  slots,
  size = 'standard',
  loadingContent,
  emptyContent,
  placeholder,
  error = false,
  helperText,
  disabled,
  minimumSearchTextLength = 3,
  firstOptionRef,
  'aria-label': ariaLabel,
  popoverProps,
}: IAutocompleteProps<T>) {
  const selectOptions = addDeprecatedTypeMetadata(options) as TFlatSelectOptions<
    DeprecatedOptionWithStringifiedValue<TAutocompleteOption<T>>
  >;
  const componentRef = useRef<HTMLInputElement>(null);
  const saturatedControlledValue = saturateControlledValue(value, options, selectionMode);

  const autocompleteStore = useAutocompleteStore({
    options,
    // What is saturation? Adding in parents whose all children are selected/children whose parents are present based on the selectionMode.
    // Why are we saturating options? value in the parent handles just the leaf nodes. We need to pass in all selected options to controlled value
    controlledValue: saturatedControlledValue,
    onChange: (newValue) => {
      const revisedSelectedOptions = getRevisedSelectedOptions(newValue, selectionMode);
      onChange(revisedSelectedOptions);
      return true;
    },
    selectionMode,
  });
  const labelId = useId();

  const autocompleteDefocuser = useCallback(
    (event: Event) => {
      if (event.target instanceof Node) {
        const userInteractionWithinComponent = componentRef.current?.contains(event.target);

        // If the user clicks outside the component, close the dropdown
        if (!userInteractionWithinComponent) {
          autocompleteStore.comboboxStore.setState('open', false);
        }
        return !userInteractionWithinComponent;
      }
      return false;
    },
    [autocompleteStore, componentRef],
  );

  // Add useEffect to add onClick listener
  useEffect(() => {
    window.addEventListener('click', autocompleteDefocuser);
    window.addEventListener('focus', autocompleteDefocuser);
    return () => {
      window.removeEventListener('click', autocompleteDefocuser);
      window.addEventListener('focus', autocompleteDefocuser);
    };
  }, [autocompleteStore, componentRef, selectionMode, autocompleteDefocuser]);

  const selectConfigContextValue = useMemo(() => {
    return {
      options: autocompleteStore.options,
      comboboxStore: autocompleteStore.comboboxStore,
      selectStore: autocompleteStore.selectStore,
      variant: 'multiple',
      slots,
      size,
      componentType: 'combobox',
      labelId,
      showLoader: isLoading,
      placeholder,
      setValueOnClick: autocompleteStore.setValueOnClick,
      state: error ? 'error' : '',
      helperText,
      disabled,
    };
  }, [
    autocompleteStore.options,
    autocompleteStore.comboboxStore,
    autocompleteStore.selectStore,
    autocompleteStore.setValueOnClick,
    slots,
    size,
    labelId,
    isLoading,
    placeholder,
    disabled,
    error,
    helperText,
  ]);
  const { MultiValue, ListFooter } = slots;
  const currentSearchText = autocompleteStore.comboboxStore.useState('value');
  return (
    <SelectConfigContext.Provider value={selectConfigContextValue}>
      <ComboboxProvider
        store={autocompleteStore.comboboxStore}
        setValue={(searchStr) => {
          React.startTransition(() => setSearchString?.(searchStr));
        }}
      >
        <div css={select_wrapper} ref={componentRef}>
          {MultiValue ? <MultiValue /> : <ComboboxTrigger />}
          <VisuallyHidden id={labelId}>{ariaLabel}</VisuallyHidden>
          {/* 
            Regarding the focus out issue:
            ComboboxPopover is responsible for onBlur handling (ie. close when focus is lost)
            and keyDown of Escape to lose focus
            But this scenario: You have selected one option and then open the input to remove that option
            OnBlur is called on clicking a Chip
            I've written onBlur and onKeyDown for the FocusedInput which has resolved the issue for now
          */}

          <ComboboxPopover
            store={autocompleteStore.comboboxStore}
            sameWidth
            gutter={1}
            className="BlocksSelect-Popover"
            data-testid="combobox-popover"
            render={(props) => (
              <SelectList className="BlocksSelect-List" store={autocompleteStore.selectStore} {...props} />
            )}
            css={[popover_wrapper, option_list_padding]}
            flip={false}
            hideOnInteractOutside={autocompleteDefocuser}
            hideOnEscape={fixPopoverEscapeKeyDown}
            {...popoverProps}
          >
            {Boolean(isLoading && loadingContent) && loadingContent}
            {!isLoading &&
              selectOptions.length === 0 &&
              emptyContent &&
              currentSearchText.length > minimumSearchTextLength &&
              emptyContent}
            {selectOptions.length !== 0 && (
              <div className="BlocksSelect-OptionListWrapper ItemListWrapper">
                {selectOptions.map((option, index) => {
                  if (option.type === AutocompleteOptionType.TITLE) {
                    return (
                      <div key={String(option.value)} css={title_padding}>
                        <Typography variant="body3" color="tertiary">
                          {option.label || option.value}
                        </Typography>
                      </div>
                    );
                  }
                  return (
                    <div
                      key={String(option.value)}
                      css={option.type === AutocompleteOptionType.CHILD ? child_padding : []}
                    >
                      <AutocompleteItem
                        key={String(option.value)}
                        option={option}
                        checkboxPosition={selectionMode === AutocompleteSelectionModes.SINGLE ? 'none' : 'right'}
                        firstOptionRef={index === 0 ? firstOptionRef : null}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {ListFooter && <ListFooter />}
          </ComboboxPopover>
        </div>
      </ComboboxProvider>
    </SelectConfigContext.Provider>
  );
}
