import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const ClassicReward = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 10C20 12.107 19.1855 14.0238 17.8541 15.4525L19.9104 19.9774C20.2153 20.6483 19.7156 21.392 19.0039 21.3914L18.8898 21.385L16.6016 21.1313L15.2269 22.667C14.7586 23.1901 13.9249 23.075 13.6032 22.4783L13.552 22.368L12.003 18.456L10.4817 22.3631C10.2273 23.0158 9.40902 23.2016 8.89788 22.7585L8.81104 22.6738L7.40412 21.1307L5.11021 21.385C4.37773 21.4663 3.8262 20.7602 4.04806 20.0838L4.0896 19.9774L6.14592 15.4525C4.81454 14.0238 4 12.107 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10ZM6 10C6 13.3137 8.68629 16 12 16C15.3137 16 18 13.3137 18 10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10ZM10.0123 12.3704C9.96045 12.6128 10.2205 12.8017 10.4349 12.6776L12.0013 11.7711L13.5677 12.6776C13.7821 12.8017 14.0421 12.6128 13.9903 12.3704L13.6224 10.651L14.9092 9.47545C15.0913 9.30905 14.9913 9.00557 14.7459 8.98015L12.9986 8.79921L12.2618 7.16814C12.1605 6.94395 11.8421 6.94395 11.7408 7.16814L11.004 8.79921L9.25673 8.98015C9.01134 9.00557 8.91127 9.30905 9.09341 9.47545L10.3802 10.651L10.0123 12.3704Z", fill: themeEnrichedPrimaryColor })));
};
ClassicReward.defaultProps = defaultIconProps;
export default ClassicReward;
