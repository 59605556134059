import type { TranslationNamespaceLoader } from '../types';

const loadNamespace: TranslationNamespaceLoader = async (namespace) => {
  let translations = {};

  switch (namespace) {
    case 'API_ERRORS': {
      translations = (await import('./api-errors')).default;
      break;
    }

    case 'BACKEND_ERRORS': {
      translations = (await import('./BACKEND_ERRORS.json')).default;
      break;
    }

    case 'COMMON': {
      const [legacy, common] = await Promise.all([import('./legacy-translations'), import('./COMMON.json')]);
      translations = {
        ...common.default,
        ...legacy.default.COMMON,
      };
      break;
    }

    case 'ERRORS': {
      translations = (await import('./errors')).default;
      break;
    }

    case 'MOBILE': {
      translations = (await import('./MOBILE.json')).default;
      break;
    }

    case 'STENCIL': {
      translations = (await import('./STENCIL.json')).default;
      break;
    }

    case 'WEB': {
      translations = (await import('./WEB.json')).default;
      break;
    }

    default: {
      const legacy = (await import('./legacy-translations')).default;
      translations = legacy[namespace];
    }
  }

  return translations;
};

export default loadNamespace;
