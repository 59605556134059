/* eslint-disable */
import { UserId } from '../../../v1/common/user_id';
import { TmcBasicInfo } from '../../../v1/common/tmc';
import { EntityId } from '../../common/entity_reference';

export interface UserOrgId {
  userId?: UserId;
  organizationId?: OrganizationId;
  /** @deprecated */
  agencyId?: string;
  /** @deprecated */
  organizationAgencyId?: OrganizationAgencyId;
  /** Tmc related basic info. */
  tmcBasicInfo?: TmcBasicInfo;
  /** Registrar user id. */
  registrarUserId?: UserId;
}

export interface NonProfileUserInfo {
  /** Legal entity related to guest. */
  legalEntityId?: EntityId;
  /** Organization related to guest. */
  organizationId?: OrganizationId;
  /** Registrar user id. */
  registrarUserId?: UserId;
}

export interface OrganizationId {
  id: string;
}

export interface CompanyId {
  id: string;
}

export interface OrganizationAgencyId {
  id: string;
}

export enum PersonaEnum {
  UNKNOWN_PERSONA = 0,
  EMPLOYEE = 1,
  GUEST = 2,
  PERSONAL = 3,
  RELATIVE = 4,
  ADHOC = 5,
  UNRECOGNIZED = -1,
}

export type Persona = 0 | 1 | 2 | 3 | 4 | 5 | -1;

export enum TierEnum {
  BASIC = 0,
  SEAT1A = 1,
  UNRECOGNIZED = -1,
}
export type TierEnumNumerals = 0 | 1 | -1;
