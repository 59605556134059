var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultIconProps } from './common';
const Seat = (_a) => {
    var { primaryColor, secondaryColor } = _a, otherProps = __rest(_a, ["primaryColor", "secondaryColor"]);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 34 36", xmlns: "http://www.w3.org/2000/svg" }, otherProps),
        React.createElement("path", { d: "M1.334 4.844c0-2.6 3.128-3.917 4.987-2.101l.59.576c5.905 5.765 15.384 5.59 21.07-.39 1.692-1.778 4.686-.581 4.686 1.872V29.5a5 5 0 0 1-5 5H6.334a5 5 0 0 1-5-5V4.844Z", strokeWidth: "2" })));
};
Seat.defaultProps = defaultIconProps;
export default Seat;
