import { useFeatureFlag } from 'obt-common/feature-gating/hooks';
import { Skeleton } from '@spotnana/blocks/src/Skeleton/Skeleton';
import { FC } from 'react';
import { Persona } from 'obt-common/types/api/v2/obt/model/persona';
import { useTranslation } from 'react-i18next';
import { OrganizationBasicInfo } from 'obt-common/types/api/v1/obt/common/organization';
import DotSeperator from 'src/v2-components/shared/DotSeparator';
import PersonaLabel from '../PersonaLabel';
import { name_logo_container, name_logo_persona_container, traveler_persona_loader } from './styles';
import { getSubtext } from '../../../../v1-components/shared/AutoCompleteSubtext';
import CompanyLabel from '../CompanyLabel';

interface IProps {
  isLoading: boolean;
  persona: Persona | undefined;
  company: OrganizationBasicInfo | undefined;
}

/**
 * Renders a label containing company logo, name, and user persona.
 * Used in rendering options in traveler picker autocomplete
 */
const CompanyLabelContainer: FC<IProps> = ({ company, isLoading, persona }) => {
  const { t: tt } = useTranslation('WEB');
  const isDisplayCompanyNameLogoEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_TMC_NAME');
  const personaLabel = getSubtext({ persona, tt });

  if (!isDisplayCompanyNameLogoEnabled) {
    return <PersonaLabel persona={persona} />;
  }

  return (
    <div css={name_logo_persona_container}>
      {isLoading ? (
        <Skeleton css={traveler_persona_loader} />
      ) : (
        <div css={name_logo_container}>
          <CompanyLabel company={company ?? null} />
          {persona && personaLabel && (
            <>
              <DotSeperator />
              <PersonaLabel persona={persona} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyLabelContainer;
