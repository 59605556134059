import { css } from '@emotion/react';
import { flex, themed } from '../../../utils';
import { paddings } from '../../../utils/styling/utilityTokens';
import { input_placeholder_text } from '../../../Input/Input.styles';

export const focused_input_container = themed(
  ({ palette }) => css`
    width: 100%;
    ${flex.init}
    ${flex.column}
    ${flex.justify.center}
    position:absolute;
    z-index: 2;
    top: 0px;
    box-sizing: border-box;
    height: 100%;

    &:has(input:focus-visible) {
      outline: solid 2px ${palette.border.keyboardFocus};
      outline-offset: -2px;
    }
  `,
);

export const chips_list = css`
  ${flex.init}
  ${flex.align.center}
  width: 100%;
  gap: 8px;

  ${flex.wrap.wrap}
  ${paddings.x.compact}
  ${paddings.y.compact}
`;

export const hidden_input = css`
  height: 0px;
  width: 100%;
`;
export const visible_input = themed(
  ({ palette, fontSize }, { compose }) => css`
    flex: 1;
    ${paddings.xr.superTight}
    ${paddings.xl.superTight}
    font-size: ${fontSize.s16};
    height: 100%;
    color: ${palette.inputs.text};
    outline: none;
    box-shadow: none;
    background: transparent;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &::placeholder {
      ${compose(input_placeholder_text)}
    }

    &:disabled {
      color: ${palette.inputs.textDisabled};
      cursor: not-allowed;
      pointer-events: all !important;
    }
  `,
);

export const autocomplete_loading = css`
  ${flex.init}
  ${flex.justify.end}
  ${flex.align.center}
  flex:1
`;

export const cancel_button = css`
  ${flex.init}
  ${flex.justify.end}
  ${flex.align.center}
  flex:1
`;
