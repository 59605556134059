import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Remove = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", version: "1.1" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, fillRule: "evenodd", d: "M7.613 7.21l.094.083L12 11.585l4.293-4.292c.36-.36.928-.388 1.32-.083l.094.083c.36.36.388.928.083 1.32l-.083.094L13.415 13l4.292 4.293.083.094c.305.392.278.96-.083 1.32l-.094.083c-.392.305-.96.278-1.32-.083L12 14.415l-4.293 4.292-.094.083c-.392.305-.96.278-1.32-.083-.39-.39-.39-1.024 0-1.414L10.585 13 6.293 8.707c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083z" })));
};
Remove.defaultProps = defaultIconProps;
export default Remove;
