import styled from 'styled-components';
import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import { Button } from '@spotnana/pixel-react/dist/Button';
import Ellipsis from '@spotnana/pixel-react/dist/Ellipsis';
import theme from '@spotnana/pixel-react/dist/utils/themes/theme';

import { css } from '@emotion/react';
import { margins, paddings, breakpoints, flex } from '@spotnana/blocks/src/utils/styling';
import { themed } from '@spotnana/blocks/src/utils/styling/themed';

export const Container = styled(Flex)`
  align-items: center;
  flex-grow: 1;
`;

export const ContentContainer = styled(Box)`
  flex-grow: 1;
`;

export const ImageFallback = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const FlightStopContainer = styled(Box)`
  flex: 1;
`;

export const AirportCode = styled(Box)`
  color: ${theme.colors.text.label};
`;

export const AirportName = styled(Ellipsis)`
  display: block;
  color: ${theme.colors.text.label};
`;

const Separator = styled(Box)`
  flex: 1;
  height: 2px;
  background-color: ${theme.colors.bg.lightBlueGrey};
  position: relative;
`;

export const SeparatorLeft = styled(Separator)`
  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: ${theme.colors.bg.lightBlueGrey};
  }
`;

export const SeparatorRight = styled(Separator)`
  &:after {
    content: '';
    position: absolute;
    top: -2px;
    right: 0px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: ${theme.colors.bg.lightBlueGrey};
  }
`;

export const MultiStopsInfo = styled(Box)`
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.text.label};
  padding: 0px 16px;
  text-align: center;
`;

export const DotSeparatorContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  min-width: 4px;
`;

export const FlightNumberInfo = styled(Box)`
  color: ${theme.colors.text.label};
  margin-right: 8px;
  white-space: nowrap;
`;

export const AirlineInfo = styled(Ellipsis)`
  display: block;
  color: ${theme.colors.text.label};
  margin-right: 32px;
`;

export const DetailsButton = styled(Button)`
  width: unset;
  margin: -8px;
`;

export const content_container = css`
  flex-grow: 1;
  ${margins.x.m1}

  ${breakpoints.from('tablet')} {
    ${margins.x.m2}
  }
`;

export const flight_time_typography = css`
  white-space: nowrap;
  line-height: 30px;
`;

export const flight_time_container_left = css`
  text-align: left;
`;

export const flight_time_container_right = css`
  text-align: right;
`;

export const flight_airport_change = themed(
  ({ palette }) =>
    css`
      color: ${palette.text.brand};
    `,
);

export const leg_duration_typography = css`
  ${margins.x.m1}
`;

export const leg_stops_typography = css`
  ${paddings.x.p1}
  color: ${theme.colors.text.label};
  text-align: center;
`;

export const flight_stop_container = css`
  ${flex.init}
  ${flex.column}
  gap: 4px;
`;

export const airline_info = css`
  min-width: 0;
`;
