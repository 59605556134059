/* eslint-disable @typescript-eslint/no-explicit-any */

import errorMessages from '../constants/errorMessage';

export enum ValidationStatus {
  VALID,
  INVALID,
  NOT_STARTED,
}

export const validateRequiredField = (field: string | number): boolean => {
  const data = typeof field === 'string' ? field.trim() : field;
  return Boolean(data);
};

export const validMinLength = (field: string, length: number): boolean => field.length >= length;

export const validMaxLength = (field: string, length: number): boolean => field.length <= length;

export const isArrayNotEmpty = (array: any[]): boolean => array.length !== 0;

export function isPositiveNumber(value: any): value is number {
  return typeof value === 'number' && value >= 0;
}

export const isValidForm = (error: any): boolean =>
  Object.values(error).every((element) => {
    if (typeof element === 'string') return !element;
    if (Array.isArray(element)) {
      const nonEmpty = element.filter((el) => Boolean(el));
      return nonEmpty.length === 0;
    }
    if (typeof element === 'object' && element !== null) {
      return isValidForm(element);
    }
    return true;
  });

export const hasDuplicateValuesInArray = (array: string[], allowCaseSensitive = false): boolean => {
  const updatedArray = allowCaseSensitive ? array : array.map((arrayItem) => arrayItem.toLowerCase());
  // Converting to a set to remove all duplicates
  const set = new Set(updatedArray);
  return set.size !== array.length;
};

// TODO (shashank): Consider dealing with other problematic characters
// (e.g. zero width chars) that can end up in the input (say, due to being
// copied from some other place)
export const validateLeadingTrailingWhitespace = (value: string): boolean => value.trim() === value;

export const validateMultipleConsecutiveWhitespace = (value: string): boolean => /\s{2,}/g.test(value);

export const isAlphaNumericSpace = (field: string): boolean => /^[a-z0-9 ]+$/i.test(field);
export const isAlphaNumeric = (field: string): boolean => /^[a-z0-9]+$/i.test(field);
export const isNumeric = (field: string): boolean => /^\d+$/i.test(field);

/**
 * This function does a whitespace validation on all properties passed into the state
 * If everything is valid, it returns null otherwise return the error object
 */
export const whitespaceValidation = <T extends object>(state: T): Record<keyof T, string> | null => {
  const whiteSpaceErrors = Object.keys(state).reduce((acc, key): Record<keyof T, string> => {
    // TODO: (Piyush) - Need to check how to improve TS for state so that T signifies Record<string, any>

    const prop = (state as Record<string, string>)[key];
    const message =
      typeof prop !== 'string' || validateLeadingTrailingWhitespace((state as Record<string, string>)[key])
        ? ''
        : errorMessages.common.LEADING_TRAILING_WHITESPACE;
    return { ...acc, [key]: message };
  }, {} as Record<keyof T, string>);
  return isValidForm(whiteSpaceErrors) ? null : whiteSpaceErrors;
};

export const isValidEmail = (email: string): boolean => {
  // Regex taken from: https://emailregex.com/ (JS section)
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email.trim());
};

export const doesPatternMatch = (inputText: string, pattern: RegExp): boolean => pattern.test(inputText);
