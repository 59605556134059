import type { ICommonI18nMessage } from '../translations/defineMessage';
import { defineCommonMessage } from '../translations/defineMessage';
import { AgentAvailabilityStatus } from '../types/api/v2/obt/model/agent-availability-status';
import { Status } from '../types/api/v2/obt/model/status';

// TODO: This is for v1. Remove when removing v1 code
export enum AgentBucketFilterKeys {
  TOTAL = 'Total',
  ASSIGNED_TO_ME = 'ASSIGNED TO ME',
  URGENT = 'URGENT',
  HIGH_PRIORITY = 'HIGH',
  MEDIUM_PRIORITY = 'MEDIUM',
  LOW_PRIORITY = 'LOW',
  NA = 'NA',
}

export enum AgentTaskStatusFilterKeys {
  TODO = 'todo',
  COMPLETED = 'completed',
}

export enum QueueDashboardTableKeys {
  TASK = 'task',
  AGENT = 'agent',
}

export enum TaskBucketFilterKeys {
  TOTAL = 'Total',
  ASSIGNED_TO_ME = 'ASSIGNED TO ME',
  UNASSIGNED_PNR = 'UNASSIGNED',
}

export enum AgentStatusBucketFilterKeys {
  ACTIVE = 'Active agents',
  IDLE = 'Idle agents',
  OFFLINE = 'Offline agents',
}

export const agentStatusBucketToAvailabilityStatusMapper: Record<AgentStatusBucketFilterKeys, AgentAvailabilityStatus> =
  {
    [AgentStatusBucketFilterKeys.ACTIVE]: AgentAvailabilityStatus.Available,
    [AgentStatusBucketFilterKeys.IDLE]: AgentAvailabilityStatus.OnBreak,
    [AgentStatusBucketFilterKeys.OFFLINE]: AgentAvailabilityStatus.Offline,
  };

export const agentStatusBucketToLabelMapper: Record<AgentStatusBucketFilterKeys, ICommonI18nMessage<object>> = {
  [AgentStatusBucketFilterKeys.ACTIVE]: defineCommonMessage('Active agents'),
  [AgentStatusBucketFilterKeys.IDLE]: defineCommonMessage('Idle agents'),
  [AgentStatusBucketFilterKeys.OFFLINE]: defineCommonMessage('Offline agents'),
};

export const taskBucketToLabelMapper: Record<TaskBucketFilterKeys, ICommonI18nMessage<object>> = {
  [TaskBucketFilterKeys.TOTAL]: defineCommonMessage('Total'),
  [TaskBucketFilterKeys.ASSIGNED_TO_ME]: defineCommonMessage('Assigned to me'),
  [TaskBucketFilterKeys.UNASSIGNED_PNR]: defineCommonMessage('Unassigned PNR'),
};

export const taskStatusLabelMap: Record<Status, ICommonI18nMessage<object>> = {
  UNKNOWN: '' as unknown as ICommonI18nMessage<object>,
  OPEN: defineCommonMessage('Open'),
  ON_HOLD: defineCommonMessage('Pending'),
  ACTIVE: defineCommonMessage('Active'),
  SCHEDULED: defineCommonMessage('Scheduled'),
  COMPLETED: defineCommonMessage('Completed'),
};

export const tableStatusToTaskStatus = {
  [AgentTaskStatusFilterKeys.TODO]: [Status.Active, Status.Open, Status.OnHold, Status.Scheduled],
  [AgentTaskStatusFilterKeys.COMPLETED]: [Status.Completed],
};
