import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Notes = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: themeEnrichedPrimaryColor, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.323 6.229a.75.75 0 01.055 1.498l-.055.002H8.682a.75.75 0 01-.056-1.498l.056-.002h9.64zm0 5.081a.75.75 0 01.055 1.498l-.055.002H8.682a.75.75 0 01-.056-1.498l.056-.002h9.64zm.75 5.831a.75.75 0 00-.75-.75H8.682l-.056.002a.75.75 0 00.056 1.498h9.64l.056-.002a.75.75 0 00.695-.748z" }),
        React.createElement("circle", { cx: 5.438, cy: 6.974, r: 0.802 }),
        React.createElement("circle", { cx: 5.438, cy: 12, r: 0.802 }),
        React.createElement("circle", { cx: 5.438, cy: 17.145, r: 0.802 })));
};
Notes.defaultProps = defaultIconProps;
export default Notes;
