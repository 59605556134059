import type {
  AirBookingPaymentSetupId,
  AirExchangePaymentSetupId,
  AirPostBookingPaymentSetupId,
} from '../../types/flight';
import type { BookingPaymentSource } from '../../types/api/v2/obt/model/booking-payment-source';
import type { PaymentSetupRequest } from '../../types/api/v2/obt/model/payment-setup-request';
import { PaymentSourceType } from '../../types/api/v2/obt/model/payment-source-type';
import type { ITraveler } from '../../types/traveler';

interface IProps {
  source: BookingPaymentSource;
  primaryTraveler: ITraveler;
  paymentSetupId: AirBookingPaymentSetupId | AirExchangePaymentSetupId | AirPostBookingPaymentSetupId | null;
}

export function getPaymentSetupRequestForPaymentSourceId({
  source,
  primaryTraveler,
  paymentSetupId,
}: IProps): PaymentSetupRequest | null {
  if (
    source.type !== PaymentSourceType.Card ||
    !source.metadata ||
    !paymentSetupId ||
    !('cardMetadata' in source.metadata)
  ) {
    return null;
  }

  if (!primaryTraveler.userOrgId.userId) {
    return null;
  }

  let itineraryInfo = { airItineraryId: { ancillaryResponseId: '' } };
  let pnrId;

  if ('ancillaryResponseId' in paymentSetupId && 'pnrId' in paymentSetupId) {
    itineraryInfo = {
      airItineraryId: { ancillaryResponseId: paymentSetupId.ancillaryResponseId },
    };
    pnrId = paymentSetupId.pnrId;
  } else if ('ancillaryResponseId' in paymentSetupId && !('pnrId' in paymentSetupId)) {
    itineraryInfo = {
      airItineraryId: { ancillaryResponseId: paymentSetupId.ancillaryResponseId },
    };
    pnrId = undefined;
  } else if (!('ancillaryResponseId' in paymentSetupId) && 'pnrId' in paymentSetupId) {
    pnrId = (paymentSetupId as AirPostBookingPaymentSetupId).pnrId;
  }

  const setupRequest: PaymentSetupRequest = {
    itineraryInfo,
    card: source.metadata.cardMetadata.card,
    userId: primaryTraveler.userOrgId.userId,
    pnrId,
  };

  return setupRequest;
}
