import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { SpotnanaError, DebarUserEventType } from 'obt-common';

interface IDebarUserContext {
  debarUser: DebarUserEventType;
  setDebarUser: (event: DebarUserEventType) => void;
}

const DebarUserContext = createContext<IDebarUserContext | null>(null);

/**
 * Disabling eslint rule here to clearly mark that this component accepts
 * `children` prop only.
 *
 * Using `Record<string, never>` will cause an error while passing `children`
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export const DebarUserContextProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [debarUser, setDebarUser] = useState<DebarUserEventType>(false);

  const context = useMemo<IDebarUserContext>(
    () => ({
      debarUser,
      setDebarUser,
    }),
    [debarUser],
  );

  return <DebarUserContext.Provider value={context}>{children}</DebarUserContext.Provider>;
};

export const useDebarUserContext = (): IDebarUserContext => {
  const context = useContext(DebarUserContext);

  if (!context) {
    throw new SpotnanaError('"useDebarUserContext" should be used inside "DebarUserContextProvider"!');
  }

  return context;
};
