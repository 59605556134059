import { useGetUserPersonalInfo, useIsLoggedIn, useLoggedInUserId } from 'obt-common';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useApplyAndFetchUserPreferredLanguageToApp = (id?: string) => {
  const { i18n } = useTranslation();
  const isUserLoggedIn = useIsLoggedIn();
  const data = useLoggedInUserId();
  const userId = id || data?.userId?.id || '';
  const { data: personalInfo, isLoading: isPersonalInfoLoading } = useGetUserPersonalInfo(userId, Boolean(userId));

  useEffect(() => {
    if (isUserLoggedIn && userId && !isPersonalInfoLoading) {
      const preferredLanguage = personalInfo?.preferredLanguage;
      if (preferredLanguage) {
        i18n.changeLanguage(preferredLanguage);
      }
    }
  }, [isPersonalInfoLoading, isUserLoggedIn, personalInfo, userId, i18n]);

  return {
    preferredLanguage: personalInfo?.preferredLanguage,
    isPersonalInfoLoading,
    personalInfo,
  };
};
