import { useLocation, Redirect } from 'react-router-dom';
import type { LocationDescriptor } from 'history';
import { HideOnRouteMatch } from 'src/v2-components/HideOnRouteMatch';
import { stringifyParams } from 'obt-common/utils/urlUtils';
import { ROUTE_PATHS } from 'src/routes';
import { useUserActions } from 'obt-common/hooks/user/userActions/useUserActions';
import { getUserActionPath } from '../shared/utils';

/**
 * Redirects to User Actions page if incomplete actions are present
 * @returns
 */
export function RedirectToUserActionPage() {
  const { incompleteUserActions } = useUserActions('FE_LOGIN_ACTIONS');
  const { pathname, search } = useLocation();

  if (!incompleteUserActions.length) {
    return null;
  }

  // generate redirection params incorporating current url as redirectUrl
  const redirectParams: LocationDescriptor = {
    pathname: getUserActionPath(incompleteUserActions[0]),
    search: stringifyParams({ redirectUrl: `${pathname}${search}` }),
  };

  return (
    <HideOnRouteMatch paths={[ROUTE_PATHS.HOME, ROUTE_PATHS.SIGNOUT, ROUTE_PATHS.USER_ACTION_ALL_ROUTES]}>
      <Redirect to={redirectParams} push={false} />
    </HideOnRouteMatch>
  );
}
