import addRequestInterceptor from '../addRequestInterceptor';
import extraHeaders from './extraHeaders';

function injectAppHeaders() {
  return addRequestInterceptor({
    onFulfilled: (config) => {
      config.headers = {
        ...config.headers,
        ...extraHeaders,
      };

      return config;
    },
  });
}

export default injectAppHeaders;
