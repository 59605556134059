import { createContext, PropsWithChildren, ReactElement, useContext, useEffect } from 'react';

import CircularLoader from '@spotnana/pixel-react/dist/CircularLoader';
import { useAuth, useWhiteLabelConfig, WhiteLabelConfig } from 'obt-common';

import baseAmplifyConfig from 'src/amplify.config';
import { white_label_config_loader } from './WhiteLabelConfigProvider.styles';

const WhiteLabelConfigContext = createContext<WhiteLabelConfig | null>(null);

export const WhiteLabelConfigProvider = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const { reconfigureAuth } = useAuth();

  const { isLoading, data: whiteLabelConfig, isError } = useWhiteLabelConfig();

  useEffect(() => {
    if (whiteLabelConfig) {
      const { origin, search } = window.location;
      const whiteLabeledRedirectURL =
        origin + (whiteLabelConfig.clientRoutingBasePath ? `${whiteLabelConfig.clientRoutingBasePath}/` : '');
      const redirectURL = whiteLabelConfig.clientRoutingBasePath
        ? whiteLabeledRedirectURL
        : baseAmplifyConfig.oauth.redirectSignIn;

      const redirectSignOutURL = whiteLabelConfig.webLinks?.logoutRedirectUrl || redirectURL;

      const amplifyConfigOverride = {
        oauth: {
          redirectSignIn: redirectURL,
          redirectSignOut: redirectSignOutURL,
          responseType: 'code',
        },
      };

      // Don't reconfigure in case of headless auth flow
      // since we want to remain on current path
      // while doing force logout (to ensure we login with updated user)
      const urlParams = new URLSearchParams(search);
      if (!urlParams.has('idp')) {
        reconfigureAuth(amplifyConfigOverride);
      }
    }
  }, [reconfigureAuth, whiteLabelConfig]);

  if (isLoading || isError) {
    return (
      <div css={white_label_config_loader}>
        <CircularLoader data-testid="whiteLabel-loader" />
      </div>
    );
  }

  return (
    <WhiteLabelConfigContext.Provider value={whiteLabelConfig ?? null}>{children}</WhiteLabelConfigContext.Provider>
  );
};

/**
 * @returns {WhiteLabelConfig | null} Value returned is null if user is not logged in
 */
export function useSyncWhiteLabelConfig(): WhiteLabelConfig | null {
  const context = useContext(WhiteLabelConfigContext);
  if (context === undefined) {
    throw new Error('`useSyncWhiteLabelConfig` hook must be used within a `WhiteLabelConfigProvider` component');
  }
  return context;
}
