import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const LieFlatSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M.45 14.451c0 .639.518 1.156 1.157 1.156h8.673a1.156 1.156 0 100-2.312H1.607c-.64 0-1.157.517-1.157 1.156zM1.028 17.342c0-.32.26-.578.579-.578h19.659a.578.578 0 110 1.156H1.606a.578.578 0 01-.578-.578z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.858 14.451c0 .639.518 1.156 1.157 1.156h9.251a1.156 1.156 0 000-2.312h-9.251c-.639 0-1.157.517-1.157 1.156zM8.757 8.309a2.891 2.891 0 00-3.103 2.883v.984l16.768-.01v-1.239c0-.909-.701-1.663-1.607-1.73L8.757 8.309zm-1.938 2.71a1.735 1.735 0 011.853-1.557l12.058.888a.578.578 0 01.536.577v.083l-14.447.009z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M5.076 10.43a1.735 1.735 0 11-3.47 0 1.735 1.735 0 013.47 0z", fill: themeEnrichedPrimaryColor })));
};
export default LieFlatSeat;
LieFlatSeat.defaultProps = defaultIconProps;
