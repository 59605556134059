import { css } from '@emotion/react';
import { flex } from '@spotnana/blocks/src/utils';

export const header_container = css`
  ${flex.init}
  ${flex.align.center}
  ${flex.justify.between}
`;

export const container = css`
  ${flex.init}
  ${flex.one}
  ${flex.justify.center}
`;
