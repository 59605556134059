import { Typography } from '../../Typography';
import { flex } from '../../utils';
import { addVocalHints } from '../../utils/a11y';
import { spacings } from '../../utils/styling/utilityTokens';
import { useSelectConfig } from '../SelectConfigContext';

export function SelectRootItemContent({
  labelId = '',
  text,
  prefixContent,
  prefixAction,
  suffixContent,
  checkmark,
  description,
}: {
  text: string;
  labelId?: string;
  prefixAction?: React.ReactNode;
  checkmark?: React.ReactNode;
  prefixContent?: React.ReactNode;
  suffixContent?: React.ReactNode;
  description?: React.ReactNode;
}) {
  const { size } = useSelectConfig();
  return (
    <div className="BlocksMenuItemContent">
      <div className="BlocksMenuItemContent-text">
        <div className="BlocksMenuItemContent-toprow">
          {prefixAction}
          {prefixContent}
          <div>
            <Typography id={labelId} variant={size === 'standard' ? 'body1' : 'body2'} kind="regular">
              {addVocalHints()(text)}
            </Typography>
            {description}
          </div>
          <div css={[flex.init, spacings.medium]} style={{ marginLeft: 'auto' }}>
            {checkmark}
            {suffixContent}
          </div>
        </div>
      </div>
    </div>
  );
}
