/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of payment item eligible for this fop rule
 * @export
 * @enum {string}
 */

export const ItemType = {
  ServiceFee: 'SERVICE_FEE',
  TravelTicket: 'TRAVEL_TICKET',
  Seat: 'SEAT',
  Baggage: 'BAGGAGE',
  EarlyBird: 'EARLY_BIRD',
} as const;

export type ItemType = (typeof ItemType)[keyof typeof ItemType];
