import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const PassengerCapacity = ({ primaryColor = '#838FA7', width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.55279 16.8937L6.62417 16.8307L16.4842 16.8937C16.4842 16.8937 16.4842 16.8937 16.4842 16.8937C17.5501 15.6848 18.137 14.131 18.137 12.5185C18.137 8.86072 15.1768 5.9 11.5185 5.9C7.86069 5.9 4.9 8.8602 4.9 12.5185C4.9 14.131 5.48699 15.6848 6.55279 16.8937ZM16.8839 13.1278C16.77 14.1328 16.3762 15.0871 15.7432 15.8815H7.29379C6.66084 15.0871 6.26706 14.1328 6.15318 13.1278H6.52778C6.86427 13.1278 7.13704 12.855 7.13704 12.5185C7.13704 12.182 6.86427 11.9093 6.52778 11.9093H6.15261C6.26539 10.8982 6.65823 9.94977 7.29344 9.15505L7.55875 9.42036C7.79667 9.65828 8.18242 9.65828 8.42036 9.42034C8.6583 9.1824 8.65831 8.79664 8.42036 8.55872C8.42036 8.55872 8.42036 8.55872 8.42036 8.55872L8.15508 8.29344C8.94975 7.65823 9.89817 7.26539 10.9093 7.15261V7.52778C10.9093 7.86427 11.182 8.13704 11.5185 8.13704C11.855 8.13704 12.1278 7.86427 12.1278 7.52778V7.15262C14.624 7.43138 16.6057 9.41342 16.8844 11.9093H16.5093C16.1728 11.9093 15.9 12.182 15.9 12.5185C15.9 12.855 16.1728 13.1278 16.5093 13.1278H16.8839ZM13.8952 9.28024L12.155 11.0204C11.9593 10.937 11.7441 10.8907 11.5185 10.8907C10.6209 10.8907 9.89074 11.6209 9.89074 12.5185C9.89074 13.4162 10.6209 14.1463 11.5185 14.1463C12.4162 14.1463 13.1463 13.4162 13.1463 12.5185C13.1463 12.2929 13.1 12.0777 13.0166 11.8821L14.7568 10.1419C14.9947 9.90394 14.9947 9.51816 14.7568 9.28024C14.5189 9.04233 14.1331 9.04229 13.8952 9.28024ZM11.5185 12.9278C11.2929 12.9278 11.1093 12.7441 11.1093 12.5185C11.1093 12.2929 11.2929 12.1093 11.5185 12.1093C11.7441 12.1093 11.9278 12.2929 11.9278 12.5185C11.9278 12.7441 11.7441 12.9278 11.5185 12.9278Z", fill: themeEnrichedPrimaryColor, stroke: themeEnrichedPrimaryColor, strokeWidth: "0.2" })));
};
PassengerCapacity.defaultProps = defaultIconProps;
export default PassengerCapacity;
