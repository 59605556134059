/* eslint-disable */
import { PaymentSourceInfoType } from '../../../v2/obt/profile/payments/payment_sources';
import type { DateTime } from '../../common/date_time';
import type { Length } from '../../common/length';
import type { Money, PaymentMethod } from '../../common/money';
import type { Commission } from '../common/commission';
import type { Preference, PreferredType, RewardPointsEarned } from '../common/common';
import type { PolicyInfo } from '../common/policy_info';
import type { CarCo2EmissionDetail, CarExtra, CarSpec, FareType, Location, Vendor } from './car_common';

export type protobufPackage = 'v1ObtCar';

export interface Rate {
  /** Type of rate. */
  type: RateRateType;
  /** List of rate components. */
  rateComponents: RateComponent[];
  /** List of cancellation policies. */
  cancellationPolicies: RateCancellationPolicy[];
  /** List of car extras. */
  carExtras: CarExtra[];
  /** Commission for this rate option. */
  commission?: Commission;
  /** Refundability of this rate option. */
  refundable: RateRefundable;
  /** Refund amount for this rate option. */
  refundAmount?: Money;
  /** Rate type of this rate option. */
  rateType: FareType;
  /**
   * List of allowed forms of payment allowed for this rate option.
   *
   * @deprecated
   */
  allowedFop: PaymentMethod[];
  /**
   * List of payment type restrictions which would be applicable to the rate,
   * If populated only the payment methods listed can be used to make the booking.
   */
  allowedPaymentTypes: PaymentSourceInfoType[];
}

export enum RateRateType {
  UNKNOWN = 0,
  GUARANTEED = 1,
  PREPAID = 2,
  UNRECOGNIZED = -1,
}

export enum RateRefundable {
  UNKNOWN_REFUNDABLE = 0,
  REFUNDABLE = 1,
  NON_REFUNDABLE = 2,
  UNRECOGNIZED = -1,
}

export interface RateComponent {
  /** Type of rate component. */
  type: RateComponentType;
  /** Amount of the rate component. */
  amount?: Money;
  /** The extra distance limit allowed for an extra hour of rental. */
  mileageAllowance?: Length;
  /** The extra charge for an extra hour of rental. */
  extraMileageCharge?: Money;
}

export enum RateComponentType {
  UNKNOWN_TYPE = 0,
  DROP_OFF_CHARGE = 1,
  EXTRA_DAY = 2,
  EXTRA_HOUR = 3,
  BASE_RATE = 4,
  BASE_RATE_TOTAL = 5,
  SUBTOTAL_EXCLUDING_MANDATORY_CHARGES = 6,
  DAILY_CHARGES_TOTAL = 7,
  HOURLY_CHARGES_TOTAL = 8,
  MANDATORY_CHARGES = 9,
  MANDATORY_CHARGES_TOTAL = 10,
  APPROXIMATE_TOTAL_PRICE = 11,
  CAR_EXTRA_TOTAL_CHARGE = 12,
  /**
   * APPROXIMATE_BASE_FARE - This field indicates the total base fare incurred.
   * This is a calculated field, not returned by Sabre thus is an
   * approximate value.
   */
  APPROXIMATE_BASE_FARE = 13,
  /**
   * TOTAL_TAX - This field indicates the total tax which will be charged.
   * This is a calculated field, not returned by Sabre thus is an
   * approximate value.
   */
  TOTAL_TAX = 14,
  /**
   * PUBLISHED_BASE_FARE - This field indicates the cheapest published base fare.
   * This field is set only if the rate is corporate.
   */
  PUBLISHED_BASE_FARE = 15,
  /**
   * PUBLISHED_TOTAL_TAX - This field indicates the tax for the corresponding published fare.
   * This field is set only if the rate is corporate.
   */
  PUBLISHED_TOTAL_TAX = 16,
  /** APPROXIMATE_DAILY_AVERAGE_TOTAL - Average daily total calculated by dividing APPROXIMATE_TOTAL_PRICE by rental duration. */
  APPROXIMATE_DAILY_AVERAGE_TOTAL = 17,
  /** APPROXIMATE_DAILY_AVERAGE_BASE - Average daily base calculated by dividing APPROXIMATE_BASE_FARE by rental duration. */
  APPROXIMATE_DAILY_AVERAGE_BASE = 18,
  /** APPROXIMATE_DAILY_AVERAGE_TAX - Average daily tax calculated by dividing TOTAL_TAX by rental duration. */
  APPROXIMATE_DAILY_AVERAGE_TAX = 19,
  /** PUBLISHED_DAILY_TOTAL - Average daily total calculated by adding PUBLISHED_DAILY_BASE_FARE and PUBLISHED_DAILY_TOTAL_TAX. */
  PUBLISHED_DAILY_TOTAL = 20,
  /** PUBLISHED_DAILY_BASE_FARE - Average daily base calculated by dividing PUBLISHED_BASE_FARE by rental duration. */
  PUBLISHED_DAILY_BASE_FARE = 21,
  /** PUBLISHED_DAILY_TOTAL_TAX - Average daily base calculated by dividing PUBLISHED_TOTAL_TAX by rental duration. */
  PUBLISHED_DAILY_TOTAL_TAX = 22,
  UNRECOGNIZED = -1,
}

export interface RateCancellationPolicy {
  /** Absolute amount levied as penalty. */
  amount?: Money | undefined;
  /** Percentage of the total amount levied as penalty. */
  percent?: number | undefined;
  /** Number of hours before pickup time before which cancellation is allowed. */
  hoursBefore?: number | undefined;
  /** Absolute deadline for cancellation. */
  absoluteDeadline?: DateTime | undefined;
}

export interface CarInfo {
  /** Serialized CarId */
  carId: string;
  /** Specifications of the car. */
  carSpec?: CarSpec;
  /** Vendor of the car. */
  vendor?: Vendor;
  /** Rate details of the car. */
  rate?: Rate;
  /** Pickup location of the car. */
  pickupLocation?: Location;
  /** DropOff location of the car. */
  dropOffLocation?: Location;
  /** Policy information about violations and approvals. */
  policyInfo?: PolicyInfo;
  /** Distance from user pickup location (Entered in search) to pickup location of the car */
  pickupDistance?: Length;
  /** Distance from user dropOff location (Entered in search) to dropOff location of the car */
  dropOffDistance?: Length;
  /**
   * If car is preferred car or not.
   *
   * @deprecated
   */
  preferredType: PreferredType[];
  /** Indicates whether car is preferred, blocked or not preferred. */
  preferences: Preference[];
  /**
   * Currently populated only in case of Qantas Cars.
   * Encapsulates Information about reward point/s earned for booking a certain car.
   */
  rewardPointsEarned: RewardPointsEarned[];
  /** The CO2 emission detail for car. */
  co2Emission?: CarCo2EmissionDetail;
}

export interface Metadata {
  /** List of available car vendors. */
  availableVendors: Vendor[];
}

export interface CarSearchResponse {
  searchId: string;
  /** List of car rental options. */
  cars: CarInfo[];
  /** Pickup time of the car rental. */
  pickup?: DateTime;
  /** Drop time of the car rental. */
  drop?: DateTime;
  /** Metadata about major car vendors. Shown by FE in vendor filters. */
  metadata?: Metadata;
  /**
   * Toggles behaviour on whether we show the fare as base amount
   * or total amount depending on service location and legalEntity.
   */
  showOnlyBaseFare: boolean;
}
