var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultIconProps } from './common';
const Currency = (_a) => {
    var { primaryColor, secondaryColor } = _a, otherProps = __rest(_a, ["primaryColor", "secondaryColor"]);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 16 16", fill: primaryColor, xmlns: "http://www.w3.org/2000/svg" }, otherProps),
        React.createElement("path", { d: "M7.448 16a.403.403 0 0 1-.297-.124.38.38 0 0 1-.123-.303V14.33c-.84-.083-1.564-.29-2.172-.622-.595-.332-1.05-.735-1.366-1.21A3.132 3.132 0 0 1 3 10.952c0-.095.035-.178.105-.249a.383.383 0 0 1 .28-.124h1.576c.14 0 .246.03.316.089.081.047.157.13.227.249.129.402.397.74.806 1.013.42.272.992.409 1.716.409.806 0 1.413-.136 1.822-.41.42-.284.63-.669.63-1.155 0-.32-.105-.586-.315-.8-.21-.213-.531-.397-.963-.55-.42-.167-1.045-.362-1.874-.587-1.378-.332-2.388-.77-3.03-1.316-.642-.545-.963-1.327-.963-2.347 0-.924.327-1.706.98-2.346.666-.64 1.57-1.031 2.715-1.174V.427c0-.13.04-.231.123-.303A.402.402 0 0 1 7.448 0h1.086c.129 0 .228.041.298.124a.38.38 0 0 1 .122.303v1.262c.76.107 1.407.338 1.944.693.55.344.964.747 1.244 1.21.292.461.45.912.473 1.35a.365.365 0 0 1-.368.374h-1.664c-.245 0-.414-.107-.508-.32-.07-.38-.303-.694-.7-.943-.386-.26-.87-.39-1.454-.39-.642 0-1.15.124-1.523.373-.362.248-.543.61-.543 1.084 0 .32.087.587.262.8.187.213.479.403.876.569.409.166.987.344 1.734.533 1.039.237 1.862.504 2.47.8.618.297 1.073.67 1.365 1.12.292.45.438 1.025.438 1.725 0 1.03-.368 1.866-1.103 2.506-.724.64-1.705 1.025-2.943 1.156v1.244a.38.38 0 0 1-.122.303.368.368 0 0 1-.298.124H7.448Z" })));
};
Currency.defaultProps = defaultIconProps;
export default Currency;
