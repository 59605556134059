import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Mask = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M6.22,20.48v.07A1.44,1.44,0,0,0,7.39,22l4.4.84a22.92,22.92,0,0,0,8.42,0l4.4-.84a1.44,1.44,0,0,0,1.17-1.41v-.07a4.08,4.08,0,0,0,3.51-4v-.88a4.07,4.07,0,0,0-3.51-4v-.07A1.45,1.45,0,0,0,24.61,10l-4.4-.83a22.08,22.08,0,0,0-8.42,0L7.39,10a1.45,1.45,0,0,0-1.17,1.42v.07a4.07,4.07,0,0,0-3.51,4v.88A4.08,4.08,0,0,0,6.22,20.48Zm-2.39-4v-.88a3,3,0,0,1,2.39-2.89v6.66A3,3,0,0,1,3.83,16.44Zm3.52,4.11V11.46a.31.31,0,0,1,.25-.31l4.4-.84a21.24,21.24,0,0,1,8,0l4.4.84a.31.31,0,0,1,.25.31v9.08a.31.31,0,0,1-.25.31l-4.4.84a21.52,21.52,0,0,1-8,0l-4.4-.84A.31.31,0,0,1,7.35,20.55Zm18.43-1.22V12.67a3,3,0,0,1,2.39,2.89v.88A3,3,0,0,1,25.78,19.33Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M9.42,16.57H22.58a.57.57,0,0,0,.57-.57.56.56,0,0,0-.57-.56H9.42a.56.56,0,0,0-.57.56A.57.57,0,0,0,9.42,16.57Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M9.42,13.93H22.58a.56.56,0,0,0,.57-.56.57.57,0,0,0-.57-.57H9.42a.57.57,0,0,0-.57.57A.56.56,0,0,0,9.42,13.93Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M9.42,19.2H22.58a.57.57,0,1,0,0-1.13H9.42a.57.57,0,1,0,0,1.13Z", fill: themeEnrichedPrimaryColor })));
};
Mask.defaultProps = defaultIconProps;
export default Mask;
