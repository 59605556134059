import type { TAllowedSearchTabs } from 'src/v1-components/search/hooks/useAllowedTravelTypes';

export const filterInvalidTravelTypes = (
  travelTypeConfig: {
    name: 'FLIGHTS' | 'HOTELS' | 'CARS' | 'RAILS' | 'OTHERS' | 'LIMO';
    order: number;
    enabled: boolean;
  }[],
): TAllowedSearchTabs[] => {
  return travelTypeConfig
    .filter((eachType) => Number(eachType.order) > 0 && eachType.enabled)
    .sort((a, b) => a.order - b.order)
    .map((tripType) => tripType.name);
};

export const matchHostNameWithExcludedSubDomain = (
  EXCLUDED_DOMAINS_FOR_WHITELABELLING: string[],
  currentHostName: string,
): boolean => {
  return EXCLUDED_DOMAINS_FOR_WHITELABELLING.some((excludedDomain) => {
    const regexPattern = new RegExp(`^${excludedDomain.replace(/\*/g, '[^.]*')}$`);
    return regexPattern.test(currentHostName);
  });
};

export const DEFAULT_EXCLUDED_DOMAINS_FOR_WHITELABELLING = [
  'localhost',
  'stage-app.spotnana.com',
  'qa-app.spotnana.com',
  'testing-app.spotnana.com',
  'app.spotnana.com',
];
