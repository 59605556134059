export enum EmbedEventTypes {
  MOUNTED = 'MOUNTED',
  LOGIN_STATUS = 'LOGIN_STATUS',
  NAVIGATION = 'NAVIGATION',
  ANALYTICS = 'ANALYTICS',
  SUCCESSFUL_BOOKING = 'SUCCESSFUL_BOOKING',
  BOOKING_EXCHANGED = 'BOOKING_EXCHANGED',
  BOOKING_CANCELLED = 'BOOKING_CANCELLED',
  CHAT_WIDGET = 'CHAT_WIDGET',
  PRIMARY_TRAVELER_UPDATED = 'PRIMARY_TRAVELER_UPDATED',
  TRIP_NAME_CHANGED = 'TRIP_NAME_CHANGED',
  BACK_TO_TRIPS = 'BACK_TO_TRIPS',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_TRIP_ID = 'INVALID_TRIP_ID',
  INSUFFICIENT_ROLE_REDIRECT = 'INSUFFICIENT_ROLE_REDIRECT',
  INSUFFICIENT_ROLE_TO_ACCESS_TRAVELER = 'INSUFFICIENT_ROLE_TO_ACCESS_TRAVELER',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  SHOW_MOBILE_REDIRECT = 'SHOW_MOBILE_REDIRECT',
  API_ERROR = 'API_ERROR',
  ALERT_MESSAGE = 'ALERT_MESSAGE',
  STORAGE_ACCESS_ERROR = 'STORAGE_ACCESS_ERROR',
  REQUEST_NAVIGATION = 'REQUEST_NAVIGATION',
  POLICY_CREATED = 'POLICY_CREATED',
  POLICY_UPDATED = 'POLICY_UPDATED',
  POLICY_DELETED = 'POLICY_DELETED',
  PAYMENT_RETRIED = 'PAYMENT_RETRIED',
}
