/* eslint-disable */
import type { CompanyId } from '../../common/company_id';
import type { Money, PaymentMethod } from '../../common/money';
import type { PhoneNumber } from '../../common/phone_number';
import type { HoldRequest, HoldResponse } from '../../payment/service';
import type { PaymentDetails } from '../pnr/payment';

export enum PreferredType {
  NOT_PREFERRED = 0,
  COMPANY_PREFERRED = 1,
  SPOTTERS_CHOICE = 2,
  COMPANY_BLOCKED = 3,
  TMC_PREFERRED = 4,
  UNRECOGNIZED = -1,
}

/** Label of preferred airport and rail station. */
export enum PreferredLocationLabel {
  PREFERRED_LOCATION_LABEL_UNKNOWN = 0,
  HOME = 1,
  WORK = 2,
  OTHER_LABEL = 3,
  UNRECOGNIZED = -1,
}

export enum PaymentStrategy {
  UNKNOWN_STRATEGY = 0,
  CC_PASS_THROUGH = 1,
  CC_MERCHANT = 2,
  POINT_PASS_THROUGH = 3,
  POINT_MERCHANT = 4,
  UNRECOGNIZED = -1,
}

/** Rail Travel class. */
export enum TravelClass {
  UNKNOWN = 0,
  FIRST = 1,
  STANDARD = 2,
  BUSINESS = 3,
  SLEEPER = 4,
  STANDARD_PREMIUM = 5,
  BUSINESS_PREMIUM = 6,
  COACH = 7,
  ROOM = 8,
  EXECUTIVE = 9,
  UNRECOGNIZED = -1,
}

/** Different types of booking provided by the platform . */
export enum BookingType {
  BOOKING_TYPE_UNKNOWN = 0,
  /** AIR - Air booking. */
  AIR = 1,
  /** HOTEL - Hotel booking. */
  HOTEL = 2,
  /** CAR - Car booking. */
  CAR = 3,
  /** RAIL - Rail booking. */
  RAIL = 4,
  UNRECOGNIZED = -1,
}

export interface Preference {
  preferredType: PreferredType;
  /**
   * Reason for blocking the leg, hotel or car.
   * Populated only if PreferredType is COMPANY_BLOCKED.
   */
  blockedReason: string;
  /** label associated with the Tier of the preference stored in Profile. */
  label: string;
}

/**
 * Encapsulates the payment related information. It mainly stores the third
 * party immutable rate keys.
 */
export interface RewardPaymentInfo {
  conversionInfo: RewardPaymentInfoConversionInfo[];
}

export interface RewardPaymentInfoConversionInfo {
  /** The unique idempotent key for the transaction. */
  transactionKey: string;
  /** The rate key for the conversion rate used. */
  rateKey: string;
  /**
   * The conversion rate corresponding to the rate key. This is with respect
   * to the below currency code.
   */
  conversionRate: number;
  /** The currency code for the above conversion rate. */
  currencyCode: string;
  /** The form of payment for which the above rate is computed. */
  paymentMethod: PaymentMethod;
  /** The epoch timestamp when the rate/quote expires. */
  expiresAt: number;
}

export interface BookingRef {
  /** air booking flow related IDs */
  airBookingRef?: BookingRefAirBookingRef;
  /** Booking PCC */
  pcc: string;
  /** Booking TMC ID */
  tmcId?: CompanyId;
}

export interface BookingRefAirBookingRef {
  /** ancillary response ID for the air workflow */
  ancillaryResponseId: string;
}

/** Information for card payments */
export interface CardPaymentInfo {
  /** Unique idempotent key for transaction */
  transactionKey: string;
  /** Booking reference */
  bookingRef?: BookingRef;
}

export interface BookingEngineKey {
  /** Whether the service fee is charged by the booking engine. */
  serviceFeeCharged: boolean;
  holdInfo: BookingEngineKeyHoldInfo[];
  /**
   * The hold response when payment is held in create-pnr workflow by the
   * booking engine.
   *
   * @deprecated
   */
  holdResponse?: HoldResponse;
  /**
   * The payment key encapsulating conversion related information.
   *
   * @deprecated
   */
  paymentKey: string;
  /**
   * Request for holding the pre-ticketed amount
   *
   * @deprecated
   */
  holdRequest?: HoldRequest;
  /**
   * Payment details to be used for booking
   *
   * @deprecated
   */
  paymentDetails: PaymentDetails[];
  /**
   * Hold amount processed
   *
   * @deprecated
   */
  holdAmount?: Money;
}

export interface BookingEngineKeyHoldInfo {
  /**
   * The hold response when payment is held by the booking engine. The payment
   * could be held in multiple workflows like create-pnr, edit-pnr, exchanges
   * etc.
   */
  holdResponse?: HoldResponse;
  /** The payment key encapsulating currency conversion related information. */
  paymentKey: string;
  /** Request for holding the amount. */
  holdRequest?: HoldRequest;
  /** Payment details to be used for booking. */
  paymentDetails: PaymentDetails[];
  /** Hold amount processed. */
  holdAmount?: Money;
  /**
   * Unique identifier for the operation for which the hold was performed.
   * This also serves as an association between the hold and captures.
   */
  operationKey: string;
}

/** Proto for Payment ID in PNR. This will be stored in serialized form. */
export interface PaymentId {
  /** The unique idempotent key for the payment */
  requestId: string;
  /**
   * The seq ID of transaction with the same request_id. For example, we do
   * multiple capture / refund after hold using the same request_id, and seq_id
   * is used to differentiate them
   */
  seqId: string;
}

/** Information about the points earned on a booking */
export interface RewardPointsEarned {
  /** Type of reward points */
  rewardPointsType: RewardPointsEarnedRewardPointsType;
  /** Total points that will be credited */
  totalPointsEarned: number;
  /** The multiplier on the total amount to get the total_points_earned */
  conversionMultiplier: number;
}

export enum RewardPointsEarnedRewardPointsType {
  REWARD_POINTS_TYPE_UNKNOWN = 0,
  QBR = 1,
  QFF = 2,
  UNRECOGNIZED = -1,
}

/**
 * Booking contact can be provided in the PNR. Suppliers like Amadeus and
 * QantasHotels send all the updates and changes to booking contact as well.
 */
export interface BookingContact {
  /** Booking contact's email address */
  emailAddress: string;
  /** Booking contact's phone number */
  phoneNumber?: PhoneNumber;
}
