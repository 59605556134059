import { SerializedStyles } from '@emotion/react';
import { divider_horizontal, divider_vertical } from './Divider.styles';

type DividerProps = {
  orientation?: 'vertical' | 'horizontal';
  disableMargin?: boolean;
  customCss?: SerializedStyles;
};

export const Divider: React.FC<DividerProps> = ({ orientation, disableMargin, customCss }) => {
  if (orientation === 'vertical') {
    return <div css={[divider_vertical, customCss]} className={disableMargin ? 'no-margin' : undefined} />;
  }
  return <hr css={[divider_horizontal, customCss]} className={disableMargin ? 'no-margin' : undefined} />;
};
