/* eslint-disable */
import type { DateTime } from '../../common/date_time';

export enum AccessibleFeature {
  ACCESSIBLE_FEATURE_UNKNOWN = 0,
  MOBILITY_ACCESSIBLE_ROOM_WITH_TUB = 1,
  MOBILITY_ACCESSIBLE_ROOM_WITH_ROLL_IN_SHOWER = 2,
  HEARING_ACCESSIBLE_ROOM = 3,
  MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_ROLL_IN_SHOWER = 4,
  MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_HEARING_ACCESSIBLE_ROOM = 5,
  MOBILITY_ACCESSIBLE_ROOM_WITH_ROLL_IN_SHOWER_AND_HEARING_ACCESSIBLE_ROOM = 6,
  MOBILITY_ACCESSIBLE_ROOM_WITH_TRANSFER_SHOWER = 7,
  MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_TRANSFER_SHOWER = 8,
  MOBILITY_ACCESSIBLE_ROOM_WITH_TRANSFER_SHOWER_AND_HEARING_ACCESSIBLE_ROOM = 9,
  UNRECOGNIZED = -1,
}

export enum SupportedHotelSpecialFeatureGroupType {
  SUPPORTED_HOTEL_SPECIAL_REQUEST_GROUP_TYPE_UNKNOWN = 0,
  ACCESSIBLE_FEATURE = 1,
  UNRECOGNIZED = -1,
}

export interface HotelSpecialRequests {
  /** Room Location special request */
  roomLocations: HotelSpecialRequestsRoomLocation[];
  /** Room Features List */
  roomFeatures: HotelSpecialRequestsRoomFeature[];
  /** Early or Late Checkin */
  checkIn: HotelSpecialRequestsCheckIn;
  /** Check in time */
  checkInTime?: DateTime;
  /** Attach flight number */
  flightNumber: string;
  /** Free form text to describe special request */
  additionalNote: string;
  /** Accessible Features */
  accessibleFeatures: AccessibleFeature[];
}

export enum HotelSpecialRequestsRoomLocation {
  ROOM_LOCATION_UNKNOWN = 0,
  HIGH_FLOOR = 1,
  LOW_FLOOR = 2,
  UNRECOGNIZED = -1,
}

export enum HotelSpecialRequestsRoomFeature {
  ROOM_FEATURE_UNKNOWN = 0,
  CRIB = 1,
  ROLLAWAY_BED = 2,
  FEATHER_FREE_ROOM = 3,
  ACCESSIBLE_ROOM = 4,
  NEAR_ELEVATOR = 5,
  UNRECOGNIZED = -1,
}

export enum HotelSpecialRequestsCheckIn {
  CHECK_IN_UNKNOWN = 0,
  EARLY_CHECK_IN = 1,
  LATE_CHECK_IN = 2,
  UNRECOGNIZED = -1,
}

export interface SupportedHotelSpecialFeature {
  accessibleFeature?: AccessibleFeature | undefined;
  /** Descriptive text associated with the special request */
  description: string;
}

export interface SupportedHotelSpecialRequestGroup {
  /** Type of the group */
  groupType: SupportedHotelSpecialFeatureGroupType;
  /** Descriptive text for the group */
  groupDescription: string;
  /** List of special features as part of this special request group */
  supportedHotelSpecialFeatures: SupportedHotelSpecialFeature[];
}

export interface SupportedHotelSpecialRequests {
  /** Groups of supported special requests */
  groups: SupportedHotelSpecialRequestGroup[];
  /** Show only supported special requests */
  showOnlySupportedSpecialRequests: boolean;
}
