import { FunctionInterpolation, SerializedStyles, Theme } from '@emotion/react';
import MuiChip, { ChipProps } from '@mui/material/Chip';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';
import { Avatar, TAvatarProps, TAvatarSizes } from '../Avatar';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
import { RemoveMuiProps } from '../types';
import { noop } from '../utils';
import { useDimensions } from '../utils/useDimensions';
import {
  chip,
  error_chip,
  largeChip,
  mediumChip,
  standard_chip,
  smallChip,
  truncated_tooltip_content,
  xlargeChip,
  success_chip,
} from './Chip.styles';

type TSize = 'small' | 'medium' | 'large' | 'xlarge';
type TSubTextSize = 'xlarge';
type TColor = 'standard' | 'error' | 'success';

const sizeToAvatarSizeMap: Record<TSize, TAvatarSizes> = {
  small: 'small',
  medium: 'small',
  large: 'small',
  xlarge: 'medium',
};

const getAvatarSize = (size: TSize | TSubTextSize) => {
  return sizeToAvatarSizeMap[size];
};

type TypographyProps = Parameters<typeof Typography>[0];
const sizeToLabelTypographyMap: Record<TSize, Pick<TypographyProps, 'variant' | 'kind'>> = {
  small: { variant: 'body2' },
  medium: { variant: 'body2' },
  large: { variant: 'body2', kind: 'medium' },
  xlarge: { variant: 'body2', kind: 'medium' },
};

const getLabelTypography = (size: TSize | TSubTextSize) => {
  return sizeToLabelTypographyMap[size];
};

const sizeConfigClassMap: Record<TSize, SerializedStyles> = {
  small: smallChip,
  medium: mediumChip,
  large: largeChip,
  xlarge: xlargeChip,
};
const colorConfigClassesMap: Record<TColor, FunctionInterpolation<Theme>> = {
  standard: standard_chip,
  error: error_chip,
  success: success_chip,
};
const getChipColorConfigClass = (color: TColor): FunctionInterpolation<Theme> => {
  return colorConfigClassesMap[color];
};

const getChipSizeConfigClass = (size: TSize): SerializedStyles => {
  return sizeConfigClassMap[size];
};
export type TChipAvatarConfig = Omit<TAvatarProps, 'size'>;

export type TChipBaseProps = {
  label: ReactNode;
  tooltipTitle?: ReactNode;
  color?: TColor;
  size?: TSize;
  avatarConfig?: { alt: string } & ({ src: string } | { text: string } | { iconName: string });
} & RemoveMuiProps<ChipProps, 'color' | 'variant' | 'label' | 'avatar' | 'icon' | 'children'>;

export type TSubtextChipProps = {
  size?: TSubTextSize;
  subText: string;
} & Omit<TChipBaseProps, 'size'>;

export type TChipProps = TChipBaseProps | TSubtextChipProps;

/**
 * Checks if a given element is Horizontally Truncated
 * @param node
 * @returns
 */
const isTruncated = (node: HTMLElement | null) => {
  if (!node) {
    return false;
  }
  return node.clientWidth < node.scrollWidth;
};

const buildTooltip = (items: ReactNode[], title?: ReactNode) => {
  if (!items.length && !title) {
    return null;
  }

  let itemJsx;
  if (items.length) {
    itemJsx = (
      <div css={truncated_tooltip_content}>
        {items.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx}>{item}</div>
        ))}
      </div>
    );
  }

  return (
    <>
      {itemJsx}
      {title}
    </>
  );
};

/**
 * Chip component.
 */
export const Chip = ({
  className,
  avatarConfig,
  onDelete,
  color = 'standard',
  tooltipTitle,
  size,
  label,
  disabled,
  ...props
}: TChipProps) => {
  const [labelRef, , labelNode] = useDimensions<HTMLParagraphElement>({ recalcOn: 'resize' }, [label]);
  const [subTextRef, , subTextNode] = useDimensions<HTMLParagraphElement>({ recalcOn: 'resize' }, [
    'subText' in props ? props.subText : '',
  ]);

  const tooltipItems: ReactNode[] = [];

  // presenece of delete handler, shows the Delete Icon
  // in case of disabled, swap delete handler with NOOP
  const onDeleteCallback = useMemo(() => {
    if (onDelete) {
      return disabled ? noop : onDelete;
    }
    return undefined;
  }, [onDelete, disabled]);

  // Delete Icon is shown if on delete callback is defined.
  const deleteIcon = useMemo(
    () =>
      onDelete ? (
        // Wrapping in div, as Mui inject styles, which conflict with IconButton
        <div className="DeleteIcon" aria-hidden="true">
          <IconButton
            tabIndex={-1}
            size="small"
            kind="standard"
            tooltip=""
            data-testid="blocks-chip-deleteIcon"
            icon="CloseCross"
            disabled={disabled}
          />
        </div>
      ) : undefined,
    [onDelete, disabled],
  );

  if ('subText' in props) {
    // Chip With subtext implemetation
    const { subText, ...restProps } = props;

    // If size is not defined then default size will be medium for subtext chip
    const subtextChipSize = size || 'medium';
    // If tooltipTitle is not provided then use subText as tooltip title
    const title = tooltipTitle || subText;

    // Push Label to tooltip, if truncated
    if (isTruncated(labelNode)) {
      tooltipItems.push(label);
    }

    // Push subtext to tooltip, if it's truncated and not tooltip title
    if (title !== subText && isTruncated(subTextNode)) {
      tooltipItems.push(subText);
    }

    const labelWitSubText = (
      <>
        <Typography ref={labelRef} className="chipLabel" {...getLabelTypography(subtextChipSize)}>
          {label}
        </Typography>
        <Typography ref={subTextRef} className="chipSubtext" variant="body3">
          {subText}
        </Typography>
      </>
    );

    return (
      <Tooltip title={buildTooltip(tooltipItems, title)} variant="label">
        <MuiChip
          avatar={
            avatarConfig ? (
              <Avatar {...({ ...avatarConfig, size: getAvatarSize(subtextChipSize) } as TAvatarProps)} />
            ) : undefined
          }
          icon={undefined}
          className={clsx(className, 'BlocksChip-root')}
          css={[chip, getChipColorConfigClass(color), getChipSizeConfigClass(subtextChipSize)]}
          data-testid="blocks-chip-with-subtext"
          label={labelWitSubText}
          {...restProps}
          disabled={disabled}
          onDelete={onDeleteCallback}
          deleteIcon={deleteIcon}
        />
      </Tooltip>
    );
  }

  // Chip Without subtext implementation
  // If tooltipTitle is not provided then use label as tooltip title
  const title = tooltipTitle || label;
  // Push label to tooltip, if it's truncated and not tooltip title
  if (title !== label && isTruncated(labelNode)) {
    tooltipItems.push(label);
  }

  // If size is not defined then default size will be small for normal chip
  const chipSize = size || 'small';
  return (
    <Tooltip title={buildTooltip(tooltipItems, title)} variant="label">
      <MuiChip
        avatar={
          avatarConfig ? (
            <Avatar {...({ ...avatarConfig, size: getAvatarSize(chipSize) } as TAvatarProps)} />
          ) : undefined
        }
        icon={undefined}
        className={clsx(className, `BlocksChip-root`)}
        css={[chip, getChipColorConfigClass(color), getChipSizeConfigClass(chipSize)]}
        data-testid="blocks-chip"
        label={
          <Typography ref={labelRef} className="chipLabel" {...getLabelTypography(chipSize)}>
            {label}
          </Typography>
        }
        {...props}
        disabled={disabled}
        onDelete={onDeleteCallback}
        deleteIcon={deleteIcon}
      />
    </Tooltip>
  );
};
