var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const LoyaltyCreditCard = (_a) => {
    var { primaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { d: "M12.1751 17.25L15.5747 13.8504C15.7419 13.6832 15.8671 13.4912 15.9503 13.2744C16.0335 13.0584 16.0751 12.8252 16.0751 12.5748C16.0751 12.0916 15.9127 11.6752 15.5879 11.3256C15.2623 10.9752 14.8663 10.8 14.3999 10.8C14.0663 10.8 13.7331 10.9 13.4003 11.1C13.0667 11.3 12.6583 11.6584 12.1751 12.1752C11.6415 11.6416 11.2247 11.2792 10.9247 11.088C10.6247 10.896 10.3079 10.8 9.9743 10.8C9.4911 10.8 9.0831 10.9752 8.7503 11.3256C8.4167 11.6752 8.2499 12.0916 8.2499 12.5748C8.2499 12.8084 8.2959 13.0376 8.3879 13.2624C8.4791 13.4872 8.6079 13.6832 8.7743 13.8504L12.1751 17.25ZM13.4495 21.0744C13.0999 21.4248 12.6751 21.6 12.1751 21.6C11.6751 21.6 11.2499 21.4248 10.8995 21.0744L2.9255 13.1004C2.7583 12.9332 2.6291 12.7372 2.5379 12.5124C2.4459 12.2876 2.3999 12.0584 2.3999 11.8248V4.8C2.3999 4.3 2.5751 3.8752 2.9255 3.5256C3.2751 3.1752 3.6999 3 4.1999 3H11.2247C11.4583 3 11.6835 3.0416 11.9003 3.1248C12.1171 3.208 12.3171 3.3416 12.5003 3.5256L20.4743 11.4996C20.8247 11.85 20.9999 12.2708 20.9999 12.762C20.9999 13.254 20.8247 13.6752 20.4743 14.0256L13.4495 21.0744ZM12.1751 19.8L19.1999 12.7752L11.2247 4.8H4.1999V11.8248L12.1751 19.8ZM6.5999 8.7C7.0167 8.7 7.3707 8.554 7.6619 8.262C7.9539 7.9708 8.0999 7.6168 8.0999 7.2C8.0999 6.7832 7.9539 6.4292 7.6619 6.138C7.3707 5.846 7.0167 5.7 6.5999 5.7C6.1831 5.7 5.8291 5.846 5.5379 6.138C5.2459 6.4292 5.0999 6.7832 5.0999 7.2C5.0999 7.6168 5.2459 7.9708 5.5379 8.262C5.8291 8.554 6.1831 8.7 6.5999 8.7Z", fill: themeEnrichedPrimaryColor })));
};
LoyaltyCreditCard.defaultProps = defaultIconProps;
export default LoyaltyCreditCard;
