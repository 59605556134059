import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';

import { onApiError, ProcessedError, useHasUserAccess, userRolesByFeature } from 'obt-common';

import { EmbedEventTypes, useEmbedStore } from '../../components/EmbedContext';
import { useSnackbarErrorContext } from '../../components/SnackbarErrorContext';
import useGetErrorMessageByCode from '../useGetErrorMessageByCode';

import endPointsWithGlobalErrorSnackbarDisabled from './endPointsWithGlobalErrorSnackbarDisabled';

const extractSourceErrorMessages = (error: AxiosError<any>): ProcessedError['sourceErrorMessages'] => {
  const sourceErrorMessage = error.response?.data?.sourceErrorMessage;

  return sourceErrorMessage ? JSON.parse(sourceErrorMessage) : undefined;
};

interface ICanUserSeeErrorResult {
  originalError: AxiosError<any>;
  isCurrentUserRoleAgentOrAbove: boolean;
  currentRoute: string;
}

const canUserSeeError = ({
  originalError,
  isCurrentUserRoleAgentOrAbove,
  currentRoute,
}: ICanUserSeeErrorResult): boolean => {
  const { config, response } = originalError;
  const errorCode = response?.data?.errorCode || '';

  const endpointInfo = endPointsWithGlobalErrorSnackbarDisabled.find((currentUrl) =>
    currentUrl.canMatchPartOfEndpoint ? config.url?.includes(currentUrl.endpoint) : config.url === currentUrl.endpoint,
  );

  /**
   * This is used to prevent error from rendering on a specified route
   */
  const shouldHideErrorAtCurrentRoute = endpointInfo?.disabledRoutes?.includes(currentRoute);
  const shouldIgnoreAllErrorCodes = endpointInfo?.disabledForErrors.includes('*') === true;
  const shouldIgnoreErrorCode = endpointInfo?.disabledForErrors.includes(errorCode) === true;

  /**
   * When all error codes should be ignored from a specific endpoint at a specific route. Errors
   * will be suppressed regardless of role.
   *
   * Currently used to hide rail suggestions on Air Search when rail search fails.
   */
  const shouldHideAllErrorsAtCurrentRoute = shouldHideErrorAtCurrentRoute && shouldIgnoreAllErrorCodes;
  if (shouldHideAllErrorsAtCurrentRoute) {
    return false;
  }

  const isSeeingErrorSkippedForErrorCodes =
    shouldIgnoreAllErrorCodes || shouldIgnoreErrorCode || shouldHideErrorAtCurrentRoute;

  const isSeeingErrorSkippedForAllRoles = endpointInfo?.disabledForRoles?.includes('*') === true;

  if (isSeeingErrorSkippedForAllRoles) {
    return false;
  }

  /**
   * TODO: Confirm whether this boolean is returned with the intention that agent or above roles
   * should always see errors
   */
  return isCurrentUserRoleAgentOrAbove || !isSeeingErrorSkippedForErrorCodes;
};

interface UseAPIErrorListenerProps {
  showAPIErrorCodesFeatureEnabled: boolean;
}

const useAPIErrorListener = ({ showAPIErrorCodesFeatureEnabled }: UseAPIErrorListenerProps): void => {
  const getErrorMessageByCode = useGetErrorMessageByCode();
  const location = useLocation();

  const { setSnackbarError } = useSnackbarErrorContext();
  const { reportEvent } = useEmbedStore();
  const isCurrentUserRoleAgentOrAbove = useHasUserAccess(userRolesByFeature.adminPages);

  useEffect(() => {
    const unsubscribe = onApiError((event) => {
      const {
        errorCode,
        errorParameters,
        debugToolLink,
        requestUrl,
        originalError,
        errorResponseMessages,
        errorDetail,
      } = event;
      const errorMessage = getErrorMessageByCode({
        errorCode,
        errorParameters,
      });

      const hasPermissionToSeeError = canUserSeeError({
        originalError,
        isCurrentUserRoleAgentOrAbove,
        currentRoute: location.pathname,
      });

      if (hasPermissionToSeeError) {
        setSnackbarError({
          errorCode,
          errorParameters,
          errorMessage,
          sourceErrorMessages: extractSourceErrorMessages(originalError),
          debugToolLink,
          errorDetail,
          originalErrorMessage: errorResponseMessages?.[0]?.message,
        });
      }

      reportEvent({
        type: EmbedEventTypes.API_ERROR,
        payload: {
          errorCode: showAPIErrorCodesFeatureEnabled ? errorCode : '',
          errorMessage,
          errorDetail,
          sourceErrorMessages: extractSourceErrorMessages(originalError) ?? [],
          apiErrorMessages: errorResponseMessages ?? [],
          debugToolLink,
          request: requestUrl,
        },
      });
    });

    return unsubscribe;
  }, [
    getErrorMessageByCode,
    reportEvent,
    setSnackbarError,
    showAPIErrorCodesFeatureEnabled,
    isCurrentUserRoleAgentOrAbove,
    location,
  ]);
};

export default useAPIErrorListener;
