import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import DialPad from '@spotnana/pixel-react/dist/Icons/DialPad';
import { useModal } from '@spotnana/pixel-react/dist/Modal';
import Popper from '@spotnana/pixel-react/dist/Popper';
import Typography from '@spotnana/pixel-react/dist/Typography';
import ShimmerBox from '@spotnana/pixel-react/dist/Shimmer';
import Avatar from '@spotnana/pixel-react/dist/Avatar';
import { useCompanyRedirectionQuery, useLoggedInUser } from 'obt-common';
import { useRef } from 'react';
import styled from 'styled-components';

const StyledLink = styled.a({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
});

const IntegrationsMenu = (): JSX.Element => {
  const { toggle, visible } = useModal(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const { data: loggedInUser } = useLoggedInUser();
  const loggedInUserId = loggedInUser?.userOrgId;
  const {
    data: redirectionInfo,
    isLoading: redirectionInfoLoading,
    error: redirectionInError,
  } = useCompanyRedirectionQuery(loggedInUserId?.tmcBasicInfo?.contractingTmc?.id?.id ?? '');

  if (redirectionInError) {
    return <></>;
  }

  if (redirectionInfoLoading) {
    return (
      <ShimmerBox
        data-testid="loader"
        width={32}
        height={32}
        borderRadius="50%"
        backgroundSize="48px 48px"
        border="thinSolid"
        borderColor="border.regular"
      />
    );
  }

  if (!(redirectionInfo?.redirectUrl && redirectionInfo?.name)) {
    return <></>;
  }
  return (
    <>
      <Box data-testid="menu-icon" flex="0 0 auto" cursor="pointer" mx={2} ref={anchorRef} onClick={toggle}>
        <DialPad width={24} height={24} onClick={toggle} />
      </Box>
      <Popper
        containerStyles={{ boxShadow: 'none', border: 'thin solid #D7DFE8', borderRadius: 8, overflow: 'hidden' }}
        anchorRef={anchorRef}
        offset={[0, 4]}
        open={visible}
        onBlur={toggle}
        placement="bottom-start"
      >
        <Flex as="ul">
          <Flex as="li">
            <StyledLink data-testid="center-link" href={redirectionInfo?.redirectUrl ?? ''}>
              <Avatar title={redirectionInfo?.name ?? ''} imageSrc={redirectionInfo?.companyLogo?.url} size="24X24" />
              <Typography color="text.body" whiteSpace="nowrap" fontWeight="semiBold" ml={2} variation="body">
                {redirectionInfo?.name ?? ''}
              </Typography>
            </StyledLink>
          </Flex>
        </Flex>
      </Popper>
    </>
  );
};

export default IntegrationsMenu;
