/* eslint-disable */
import { DateTimeRange } from '../../../v1/common/range';
import { TravelType } from '../../../v2/obt/model/pccs-travel-type';

/**
 *  Contains information specific to third parties, like airline discount codes, PNR remarks, etc.
 */
export interface OrganizationThirdPartyInfo {
  /**
   *  Sabre specific info
   */
  sabreInfo?: OrganizationThirdPartyInfoSabreInfo;
  /**
   *  NDC specific info
   */
  ndcInfo?: OrganizationThirdPartyInfoNdcInfo;
  /**
   *  Boolean to indicate if sabre info is default value or values obtained from the DB.
   *  true means the data is saved manually and data is present in the DB.
   *  false means that it is default (pre-populated) value.
   */
  isDefault?: boolean;
}

export interface OrganizationThirdPartyInfoSabreInfo {
  /**
   *  Unique number used in accounting.
   */
  dkNumber: string;
  /**
   *  ID for traveler profile filter stored in Sabre profiles.
   */
  templateId: string;
  /**
   *  ID for legal entity profile filter stored in Sabre profiles.
   */
  filterId: string;
  /**
   *  Sabre specific discount codes, company level loyalties, etc.
   */
  codes?: OrganizationThirdPartyInfoSabreInfoCode[];
  /**
   *  Remarks to be stored in Sabre legal entity profiles.
   */
  profileRemarks: OrganizationThirdPartyInfoSabreInfoRemark[];
  /**
   *  Air specific PNR remarks. If hotel, car and air PNR are same, they are stored
   *  in air_pnr_remarks.
   */
  airPnrRemarks: OrganizationThirdPartyInfoSabreInfoRemark[];
  /**
   *  Hotel specific PNR remarks.
   */
  hotelPnrRemarks?: OrganizationThirdPartyInfoSabreInfoRemark[];
  /**
   *  Car specific PNR remarks.
   */
  carPnrRemarks?: OrganizationThirdPartyInfoSabreInfoRemark[];
  /**
   *  PCCs to be used for this organization.
   */
  pccs: OrganizationThirdPartyInfoSabreInfoPcc[];

  /**
   *  Info for pricing and booking corporate negotiated fares
   */
  vendorPrograms: OrganizationThirdPartyInfoSabreInfoVendorProgramInfo[];

  /** Allocated Pccs */
  allocatedPccs?: OrganizationThirdPartyInfoSabreInfoAllocatedPcc[];
}

/**
 *  Information for booking corporate negotiated fares
 */
export interface OrganizationThirdPartyInfoSabreInfoVendorProgramInfo {
  /**
   *  corporate ID / account code on which this info applies
   */
  discountCode: string;
  /**
   *  Information for UA pass plus program
   */
  uaPassPlus?: OrganizationThirdPartyInfoSabreInfoVendorProgramInfoUaPassPlus | undefined;
}

export interface OrganizationThirdPartyInfoSabreInfoVendorProgramInfoUaPassPlus {
  /**
   *  USC ID
   */
  uscId: string;
  /**
   *  ID of the card to be used for booking
   */
  cardId: string;
  /**
   *  Tour code to be added in PNR
   */
  tourCode: string;
  /**
   *  Valid ticketing date time range
   */
  ticketingValidity?: DateTimeRange;
  /**
   *  Valid travel date time range
   */
  travelValidity?: DateTimeRange;
  /**
   *  Endorsements to be added in booking
   */
  endorsements: OrganizationThirdPartyInfoSabreInfoVendorProgramInfoEndorsement[];
}

export interface OrganizationThirdPartyInfoSabreInfoVendorProgramInfoEndorsement {
  /**
   *  Endorsement text to be added
   */
  endorsementText: string;
  /**
   *  Validating airline on which this text applies. Empty if applies on all.
   */
  validatingAirline: string;
}

export interface OrganizationThirdPartyInfoSabreInfoRemark {
  /**
   *  Type of remark, eg: General, Invoice, etc.
   */
  type: string;
  /**
   *  The remark text.
   */
  text: string;
}

export interface OrganizationThirdPartyInfoSabreInfoCode {
  /**
   *  https://guides.developer.iata.org/docs/clid
   *  // Corporate client codes. air_clid4 are only for American Airlines for other airlines, air_clid3 is used
   */
  airClid3?: string;
  airClid4?: string;
  /**
   *  A Tour Code on a flight ticket is a special code arranged between the Travel Agency and
   *  the Airlines. It indicates discounts or special offers that the travel agency is given by
   *  the respective Airlines. Ref: http://resbird.com/Subscriber-Service-Bulletin/TST-indicator-change-with-manual-FT.PDF
   */
  airTourCode?: string;
  /**
   *  Air discount codes.
   */
  airAccountCode?: string;
  airCorporateId?: string;
  /**
   *  Hotel discount codes.
   */
  hotelRateCode?: string;
  /**
   *  If there is no condition on validating airline, validating_airlines should be empty
   */
  validatingAirlines: string[];
  airCtid?: string;
  /**
   * Car corporate discount codes.
   */
  carCorporateCode?: string;
  /**
   * Car vendors for car_corporate_discount_code.
   */
  carVendors: string[];
  /**
   * Rate Code Tag that will be displayed along with rates of this code on UI.
   * Travel Admins should be able to set and control these tags.
   */
  hotelRateCodeTag?: string;
  /** Indicates if the "car_corporate_code" is to be used with virtual cards only. */
  virtualPaymentsOnly?: boolean;
  vpayCarIdNumber?: string;
}

export interface OrganizationThirdPartyInfoSabreInfoPcc {
  countries: string[];
  /**
   *  Pseudo City Code
   */
  pcc: string;
}

export interface OrganizationThirdPartyInfoSabreInfoAllocatedPcc {
  travelTypes: TravelType[];
  currencyCode?: string;
  pcc: string;
}

/**
 *  NDC related information stored for an organization.
 */
export interface OrganizationThirdPartyInfoNdcInfo {
  /**
   *  The unique number assigned to an organization, by the Southwest airline.
   */
  swabizNumber: string;
}

/**
 *  The third party info for legal entity. The non-empty fields in this, overrides the third party
 *  info in organization
 */
export interface LegalEntityThirdPartyInfo {
  /**
   *  Sabre specific info.
   */
  sabreInfo?: LegalEntityThirdPartyInfoSabreInfo;
}

export interface LegalEntityThirdPartyInfoSabreInfo {
  /**
   *  Pseudo City Code
   */
  pcc: string;
  /**
   *  Unique number used in accounting.
   */
  dkNumber: string;
}

export interface ThirdPartyId {
  /**
   *  Sabre profile ID
   */
  sabreId: string;
}

export enum ThirdPartySourceEnum {
  UNKNOWN_SOURCE = 0,
  SABRE = 1,
  TRAVEL_FUSION = 2,
  /** @deprecated */
  AVIA = 3,
  /** @deprecated */
  NDC = 4,
  TRAINLINE = 5,
  /** @deprecated */
  ATPCO_NDC = 6,
  FARELOGIX_NDC = 7,
  OFFLINE = 8,
  CONNEXUS = 9,
  ROUTEHAPPY = 10,
  AMADEUS = 11,
  GIATA = 12,
  QBR = 13,
  BCD = 14,
  QANTAS_HOTELS = 15,
  /** SOUTHWEST - WN direct connect */
  SOUTHWEST = 16,
  EXPEDIA = 17,
  HOTEL_HUB = 18,
  MARRIOTT = 19,
  CLEARTRIP = 20,
  KYTE = 21,
  UNRECOGNIZED = -1,
}

export type ThirdPartySource =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | -1;
