import { storage } from '../Storage';
import { StorageKeys } from '../../types/storage';

const appendSimulatorRedirectHeader = async () => {
  const shouldRedirect = (await storage.getItem(StorageKeys.X_EXTERNAL_API_FORWARD)) === 'true';
  if (!shouldRedirect) {
    return {};
  }

  return { 'X-External-Api-Forward': (await storage.getItem(StorageKeys.X_EXTERNAL_API_FORWARD_TOKEN)) ?? '' };
};
export default appendSimulatorRedirectHeader;
