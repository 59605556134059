import type { ICommonI18nMessage } from '../translations/defineMessage';
import type { MoneyUtil } from '../utils/Money';
import type { DateTime } from './api/v1/common/date_time';
import type { Latlng } from './api/v1/common/latlng';
import type { Length } from './api/v1/common/length';
import type { PostalAddress } from './api/v1/common/postal_address';
import type { Preference, RewardPointsEarned } from './api/v1/obt/common/common';
import { PreferredType } from './api/v1/obt/common/common';
import type {
  AccessibleFeature,
  HotelSpecialRequestsCheckIn,
  HotelSpecialRequestsRoomFeature,
  HotelSpecialRequestsRoomLocation,
} from './api/v1/obt/common/hotel_special_requests';
import type { UserOrgId } from './api/v1/obt/common/user_org_id';
import type {
  Description,
  HotelAmenity,
  HotelSpecContactInfo,
  HotelSpecStarRatingType,
  OccupancyDateParamsOccupancy,
  PromotionalOffer,
  Rate,
  RateStatistics,
} from './api/v1/obt/hotel/hotel_common';
import {
  DescriptionType,
  HotelAmenityType,
  RateStatisticsPolicyType,
  RateTypeV1,
} from './api/v1/obt/hotel/hotel_common';
import type { HotelDetailsRequest } from './api/v1/obt/hotel/hotel_details_request';
import type {
  CancellationPolicy,
  CancellationPolicyTerm,
  HotelDetailsResponse,
  PrepaidQualifier,
  RateOption,
  RateOptionBedInfo,
  Room,
  RoomAmenity,
  RoomView,
} from './api/v1/obt/hotel/hotel_details_response';
import {
  AdditionalDetailAdditionalDetailType,
  RoomAmenityRoomAmenityTypeEnum,
} from './api/v1/obt/hotel/hotel_details_response';
import type { HotelPriceCheckRequest } from './api/v1/obt/hotel/hotel_price_check_request';
import type { HotelPriceCheckResponse } from './api/v1/obt/hotel/hotel_price_check_response';
import type { Filters, HotelSearchRequest, SortOptionsSortBy } from './api/v1/obt/hotel/hotel_search_request';
import type { HotelCreatePnrResponse } from './api/v1/obt/trip/hotel_create_pnr_response';
import type { HotelBrand } from './api/v2/obt/model/hotel-brand';
import type { HotelParentChain } from './api/v2/obt/model/hotel-parent-chain';
import type { IHotelSuggestion } from './autocomplete';
import type { BookingFlowEnum, ILength, SharedSearchParams, TripIdUrlParam } from './common';
import type { IPolicyInfo, PassengerAge, SortOptionSortOrderEnum } from './flight';

export { BedTypeEnum, RateInfoRateSource, RoomViewTypeEnum } from './api/v1/obt/hotel/hotel_details_response';
export { HotelRateConditionsNotAllowedPropsType as IHotelRateConditionsNotAllowedPropsType } from './api/v1/obt/policy/policy_rules';
export type { HotelCreatePnrRequest } from './api/v1/obt/trip/hotel_create_pnr_request';
export {
  AdditionalDetailAdditionalDetailType,
  PreferredType,
  RateStatisticsPolicyType,
  RateTypeV1,
  RoomAmenityRoomAmenityTypeEnum,
};
export type { HotelBrand, HotelParentChain, HotelSpecContactInfo, RateStatistics };
export interface ISliderImage {
  original: string;
  thumbnail: string;
  caption: string;
}

export interface IHotelOverView {
  address: PostalAddress;
  contact: HotelSpecContactInfo;
  shortDescription: string;
  sliderImages: ISliderImage[];
}

export interface IHotelsSearchFormState {
  from: string;
  to: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
  adults: number;
  children: number;
}

export type IHotelsSearchFormStateAction = {
  type: string;
  payload: {
    key: string;
    value: string | number;
  };
};

export enum HotelRateCodeTypeEnum {
  REGULAR = 'REGULAR',
  AAA = 'AAA',
  SENIOR = 'SENIOR',
  GOV_OR_MIL = 'GOV_OR_MIL',
  MEMBER = 'MEMBER',
}

export interface IHotelsFilterState {
  rates: [number, number];
  brands: string[];
  amenities: string[];
  propertyTypes?: string[];
  rating: number[];
  nameQuery: string;
  showUnavailable: boolean;
  radius?: Length;
  prepaidQualifier: PrepaidQualifier;
  payByPoints: boolean;
  /** @deprecated */
  modifiableOnly?: boolean;
  refundableOnly?: boolean;
  /** @deprecated */
  rateTypes?: string[];
  rateType?: string;
  preferredOnly: boolean;
  /** Sub Chain / Brand codes of hotels to be filtered. */
  brandCodes: string[];
}

export type ITravelers = {
  adults: number;
  children: number;
};

interface HotelSearchFlowParams extends SharedSearchParams {
  isPersonalTravel?: boolean;
}

export interface IHotelSearchRequestParams extends HotelSearchFlowParams {
  userOrgId?: UserOrgId;
  from: string;
  to: string;
  location: IHotelSuggestion;
  travelers: ITravelers[];
  filters?: IHotelsFilterState;
  rateCode?: HotelSearchRateCode;
  sortType?: SortOptionsSortBy;
  sortOrder?: SortOptionSortOrderEnum;
}

export interface IMobileHotelSearchRequestRooms {
  travelers: ITravelers[];
}

export interface IMobileHotelSearchRequestParams extends Omit<IHotelSearchRequestParams, 'userOrgId' | 'travelers'> {
  rooms: IMobileHotelSearchRequestRooms[];
  passengers: Array<{ userId: string | undefined }>;
}

export type IHotelSearchResultsUrlParams = HotelSearchRequest &
  IHotelSuggestion & {
    mapZoom?: number;
    utm_source?: string;
    rateCode?: HotelSearchRateCode;
    disableSearchSummary?: boolean;
  } & HotelSearchFlowParams;
export type IHotelCheckoutRequestParams = HotelPriceCheckRequest;
export type IHotelCheckoutRequestUrlParams = HotelDetailsRequest;
export type IHotelPriceCheckResponse = HotelPriceCheckResponse;
export type IHotelCreatePnrResponse = HotelCreatePnrResponse;

export interface IHotelSearchFormUrlParams extends HotelSearchFlowParams {
  fromDate?: string;
  toDate?: string;
  isPersonalTrip?: boolean;
  rateCode?: HotelSearchRateCode;
  location?: IHotelSuggestion;
  travelers?: ITravelers[];
  disableSearch?: boolean;
  maxTravelers?: number;
}

export type IHotelSearchErrorState = {
  location: string;
  request?: string;
  to?: string;
  from?: string;
  // add other fields if they aren't pre-filled
};

export interface MandatoryFeesBreakdown {
  title: ICommonI18nMessage<unknown>;
  amount: MoneyUtil;
  feeInclusions: string[];
}

export interface MandatoryFees {
  total: MoneyUtil;
  breakdown: MandatoryFeesBreakdown[];
}
export interface IHotelSummary {
  id: string;
  title: string;
  rating: number;
  ratingType: HotelSpecStarRatingType;
  amenities: string[];
  avgNightlyRate: MoneyUtil;
  position: { lat: number; lng: number };
  imageUrl: string;
  totalAmount: MoneyUtil;
  /** @deprecated */
  policyViolationMessage?: string;
  policyInfo?: IPolicyInfo;
  distance?: Length;
  address?: PostalAddress;
  totalNights: number;
  starRatings?: number[];
  chainCode?: string;
  chainName?: string;
  coordinates?: Latlng;
  /** @deprecated use preferences instead, and remove when the migration is done for mobile */
  preferredType: PreferredType[];
  hasNegotiatedRates?: boolean;
  averageNightlyFare?: Rate | undefined;
  preferences: Preference[];
  isRefundable?: boolean;
  promotionalOffers: PromotionalOffer[] | undefined;
  rewardPointsEarned: RewardPointsEarned[] | undefined;
  contactInfo: HotelSpecContactInfo | undefined;
  co2EmissionsValue?: number;
  mandatoryFees?: MandatoryFees;
  numberOfTimesBooked?: number;
  sourceHotelId?: string;
}

export interface IDateWiseBreakup {
  dateString: string;
  base: MoneyUtil;
  tax: MoneyUtil;
  fees: MoneyUtil;
  mandatoryFeesBreakdown: MandatoryFeesBreakdown[];
  total: MoneyUtil;
  salesTax?: MoneyUtil;
}

export interface IOccupancy {
  checkInDate: string;
  checkOutDate: string;
  checkInTime: string;
  checkOutTime: string;
  checkInDateTime: string;
  checkOutDateTime: string;
}

export type IHotelSearchRequest = HotelSearchRequest;
export type IHotelSearchRequestFilters = Filters;
export type IHotelAmenity = HotelAmenity;
export type IRoomAmenity = RoomAmenity;
export type IHotelDescription = Description;
export type IRoom = Room;
export type IRateOption = RateOption;
export type IRateOptionRoomView = RoomView;
export type ICancellationPolicy = CancellationPolicy;
export type ICancellationPolicyTerm = CancellationPolicyTerm;
export type IHotelDetailsRequest = HotelDetailsRequest;
export type IHotelDetailsResponse = HotelDetailsResponse;
export const IDescriptionTypeEnum = DescriptionType;
export const IHotelAmenityTypeEnum = HotelAmenityType;

export interface IHotelDetailsParams extends Pick<IHotelDetailsRequest, 'hotelDetailsKey'>, HotelSearchFlowParams {
  location?: string;
  distance?: ILength;
  searchParams: Omit<IHotelSearchResultsUrlParams, keyof HotelSearchFlowParams>;
  showFareIncludingTaxesAndFeesInitialValue: boolean;
}

export interface ICancellationTerm {
  deadline: DateTime;
  description: string;
  /** Amount to be penalized if cancelled after deadline */
  amount: MoneyUtil;
}

export type AvailableHotelChainsFilterOption = {
  code: string;
  name: string;
};

export type AvailableHotelBrandsFilterOption = {
  brandCode: string;
};
export type HotelChainCode =
  | '6C'
  | 'HY'
  | 'EC'
  | 'EH'
  | 'EM'
  | 'NH'
  | 'RR'
  | 'TC'
  | 'WR'
  | 'RD'
  | 'RT'
  | 'WW'
  | 'PV'
  | 'GA';

export enum HotelSearchRadiusMilesEnum {
  ONE = 1,
  THREE = 3,
  FIVE = 5,
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50,
}

export enum HotelSearchRadiusKmsEnum {
  TWO = 2,
  FIVE = 5,
  EIGHT = 8,
  FIFTEEN = 15,
  THIRTY = 30,
  EIGHTY = 80,
}

export interface RateOptionFilters {
  eligibleForLoyalty: boolean;
  freeCancellation: boolean;
  qualifiedRate: boolean;
  prepaidRoomsOnly: boolean;
  payByPointsOnly: boolean;
  isModifiable: boolean;
}

/** @deprecated */
export interface IHotelDetailFilter {
  eligibleForLoyalty: boolean;
  showOutOfPolicy: boolean;
  rates: [number, number];
  inclusionsTypes: string[];
  bedTypes: string[];
  refundableOnly: boolean;
  rateTypes: string[];
  payByPoints: boolean;
  prepaidQualifier: PrepaidQualifier;
}

export interface IHotelDetailFilterV2 {
  payByPoints: boolean;
  showOutOfPolicy: boolean;
  refundable: boolean;
  eligibleForLoyalty: boolean;
  showConditional: boolean;
  prepaid: boolean;
  payAtProperty: boolean;
  complimentaryBreakfast: boolean;
  accessible: boolean;
}

export interface DetailsFiltersWithShowTaxesToggle extends IHotelDetailFilterV2 {
  showFareIncludingTaxesAndFees: boolean;
}

/** @deprecated */
export interface DetailsFiltersWithShowTaxesToggleV1 extends IHotelDetailFilter {
  showFareIncludingTaxesAndFees: boolean;
}

export interface RoomSummary {
  title: string;
  images: ISliderImage[];
  bedInfo: RateOptionBedInfo[];
  viewInfo: RoomView | undefined;
  /**
   * @deprecated
   * Use roomLevelAmenities, since it contains more information
   * */
  amenities: RoomAmenityRoomAmenityTypeEnum[];
  roomLevelAmenities: RoomAmenity[];
}

export interface RoomRateOptionSummary {
  averageNightlyPrice: MoneyUtil;
  cancellationTerms: ICancellationTerm[];
  dateWiseFare: IDateWiseBreakup[];
  description: string;
  hasComplimentaryBreakfastIncluded: boolean;
  isBookingDotComRate: boolean;
  isEligibleForLoyalty: boolean;
  isExpediaRate: boolean;
  isPrepaidRoom: boolean;
  /**
   * @deprecated
   * Since integration with Marriott we now have 3 valid values for
   * refundability information in certain cases (Details page). Valid
   * values being: `TRUE`, `FALSE` and `UNKNOWN`
   * We can keep using it on non details page but its preferred to be
   * explicit and correctly handle all 3 states of `currentlyRefundable`
   * data from the raw rate option data itself
   * */
  isRefundable: boolean;
  isRoomMemberOnlyRate: boolean;
  policyInfo?: IPolicyInfo;
  policyViolationMessage: string | undefined;
  priceValidateKey: string;
  publishedNightlyRate: MoneyUtil;
  publishedRate: MoneyUtil;
  ratePlanName: string;
  rateType: RateTypeV1;
  rateTag: string;
  totalDays: number;
  totalPrice: MoneyUtil;
  rateDifference: MoneyUtil;
  averageNightlyRate?: Rate;
  showOnlyBaseFare: boolean;
  preferences: Preference[];
  promotionalOffers: PromotionalOffer[];
  rewardPointsEarned: RewardPointsEarned[];
  mandatoryFees: MandatoryFees;
  isRateVPayEnabled: boolean;
  isDepositRequired: boolean;
}

export interface ReservationAmountBreakDown {
  base: MoneyUtil;
  taxesAndFees: MoneyUtil;
  fees: MoneyUtil;
  mandatoryFees: MandatoryFees;
  avgNightlyRateInfo: {
    amount: MoneyUtil;
    noOfNights: number;
  };
  total: MoneyUtil;
  dueNow: MoneyUtil;
  dueLater: MoneyUtil;
}

/** @deprecated */
export interface HotelModifyDetailsURLParamsOld extends HotelModifyDetailsURLParams {
  priceValidateKey: string;
}

export interface HotelModifyDetailsURLParams {
  occupancyDetails: {
    checkInDate: string;
    checkOutDate: string;
  };
  originalReservation: {
    occupancyDetails: {
      occupancy: OccupancyDateParamsOccupancy[];
      checkInDate: string;
      checkOutDate: string;
      checkInTime: string;
      checkOutTime: string;
    };
  };
  totalPrice: string;
  pnrId: string;
  tripId: TripIdUrlParam;
  bookingFlow: BookingFlowEnum;
  registrarId?: string;
  userId?: string;
}

export interface AdditionalOccupants {
  paxType: string;
  /**
   * Only applicable to children
   */
  paxAge?: PassengerAge;
}
export enum HotelSearchRateCode {
  REGULAR = 'REGULAR',
  AAA = 'AAA',
  SENIOR = 'SENIOR',
  GOV_OR_MIL = 'GOV_OR_MIL',
  MEMBERS_ONLY = 'MEMBERS_ONLY',
}

export type HotelSpecialRequestFieldType = 'radio' | 'checkbox' | 'multiselect' | 'select' | 'textarea';
export type HotelSpecialRequestFieldConfig = {
  type: HotelSpecialRequestFieldType;
  options?: {
    label: string;
    value:
      | HotelSpecialRequestsRoomLocation
      | HotelSpecialRequestsRoomFeature
      | HotelSpecialRequestsCheckIn
      | AccessibleFeature;
  }[];
  label?: string;
  placeholder?: string;
  characterLimit?: number;
  required?: boolean;
};

export interface HotelSpecialRequestConfig {
  title: string;
  /*
   * Field could later be extended into a fields array.
   * At the time of making this, there can only be one field in a single special request.
   */
  field: HotelSpecialRequestFieldConfig;
}
