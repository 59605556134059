import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const AuthCodeLoginPage = lazy(() => import('./AuthCodeLoginPage'));

export const authRoutes: IRouteConfig[] = [
  {
    key: 'auth-code-login',
    path: '/auth/code',
    component: AuthCodeLoginPage,
    isPublicRoute: true,
  },
];
