import { FC } from 'react';
import { Modal } from '@spotnana/blocks/src/Modal';
import { useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography';
import { modal_secondary_background } from '../shared/styles';

export enum DeleteFaresModalType {
  SINGLE_FARE = 'SINGLE_FARE',
  FARE_LIST = 'FARE_LIST',
}
interface DeleteFaresModalProps {
  open: boolean;
  numberOfFares: number;
  travelerName: string;
  onClose: VoidFunction;
  onDeleteSingleFare: VoidFunction;
  onDeleteFareList: VoidFunction;
  type: DeleteFaresModalType;
}

export const DeleteFaresModal: FC<DeleteFaresModalProps> = ({
  open,
  onClose,
  onDeleteSingleFare,
  onDeleteFareList,
  type,
  travelerName,
  numberOfFares,
}) => {
  const { t: tt } = useTranslation('WEB');

  const isSingleFareVariant = type === DeleteFaresModalType.SINGLE_FARE;

  const primaryActionClick = isSingleFareVariant ? onDeleteSingleFare : onDeleteFareList;

  const description = isSingleFareVariant
    ? tt('Are you sure you want to delete this fare?')
    : tt(
        'Are you sure you want to delete the Itinerary for {{travelerName}}? This will delete {{numberOfFares}} option(s) you have saved.',
        { travelerName, numberOfFares },
      );

  const headerTitle = isSingleFareVariant ? tt('Delete fare') : tt('Delete itinerary');

  return (
    <Modal
      open={open}
      onClose={onClose}
      header={{ title: headerTitle }}
      variant="medium"
      footer={{
        primaryAction: {
          onClick: primaryActionClick,
          text: tt('Delete'),
        },
        secondaryAction: {
          onClick: onClose,
          text: tt('Cancel'),
        },
      }}
      css={modal_secondary_background}
    >
      <Typography variant="body1" color="secondary">
        {description}
      </Typography>
    </Modal>
  );
};
