/* eslint-disable */

/**
 *  Proto to specify length and distance.
 */
export interface Length {
  unit: LengthUnit;
  length: number;
}

export enum LengthUnitEnum {
  UNKNOWN_UNIT = 0,
  KM = 1,
  MILE = 2,
  UNRECOGNIZED = -1,
}

export type LengthUnit = 0 | 1 | 2 | -1;
