import { flex, margins, paddings, themed, unit } from '@spotnana/blocks/src/utils';
import { css } from '@emotion/react';

export const prev_animation_container = themed(
  ({ palette }) => css`
    height: 200px;
    background-color: ${palette.surface.background};
  `,
);

export const prev_animation = css`
  height: 200px;
`;

export const prev_subtitle_text = css`
  ${margins.yb.m1point5}
`;

export const header_container = themed(
  ({ palette }) => css`
    ${paddings.x.p1point5}
    ${paddings.y.p1point5}
    background-color: ${palette.surface.background};
    .quick-help-text {
      ${margins.yb.m1}
    }
  `,
);

export const animation = css`
  height: 150px;
  width: 78%;
`;

export const content_text_container = css`
  ${paddings.x.p1point5}
  ${paddings.y.p1point5}
  ${flex.init}
  ${flex.column}

  // below are for new agent help center, not affecting existing style
  .link-positioner {
    ${flex.init}
    ${flex.column}
    ${flex.align.center}
  }
  .text-animate-block {
    ${flex.init}
    ${flex.row}
    gap: ${unit(2)};
    .text {
      width: 50%;
    }
    .animate {
      ${flex.init}
      ${flex.row}
      ${flex.justify.end}
    }
  }
`;

export const title_text = css`
  ${margins.yb.half}
`;

export const subtitle_text = themed(
  ({ palette, fontSize }) => css`
    ${margins.yb.m1point5}
    color: ${palette.text.tertiary} !important;
    ${fontSize.s14}
  `,
);

export const feedback_button = css`
  ${margins.yt.m1}
`;

export const link = css`
  ${margins.yt.m1point25}
  text-decoration: none;
`;
