import { css } from '@emotion/react';
import { lightColorPalette } from '@spotnana/blocks/src/BlocksThemeProvider/ux-specs/color';
import { themed } from '@spotnana/blocks/src/utils';
import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

const APP_HEADER_HEIGHT = 60;

export const panel_container = css`
  width: 100%;
  height: calc(100vh - ${APP_HEADER_HEIGHT}px);
  position: absolute;
  top: 0;
  background: ${lightColorPalette.overlay};
`;

export const right_panel = themed(
  ({ palette }) => css`
    width: 550px;
    height: 100%;
    position: absolute;
    right: 0;
    background: ${palette.surface.base};
    ${paddings.x.cozy}
    ${paddings.yt.compact}
  `,
);
