/* eslint-disable */

export enum GenderEnum {
  UNKNOWN = 0,
  MALE = 1,
  FEMALE = 2,
  UNSPECIFIED = 3,
  UNDISCLOSED = 4,
  UNRECOGNIZED = -1,
}

export type Gender = 0 | 1 | 2 | 3 | 4 | -1;
