import { SVGProps, createContext, useContext, useId } from 'react';
import { IconName } from './types';

const Ctx = createContext<string>('');

type IconBaseProps = Omit<SVGProps<SVGSVGElement>, 'name' | 'aria-hidden' | 'title' | 'role' | 'children'> & {
  name: IconName;
};

/**
 * Icons to be used for Decorative purpose with no standalone context
 */
type DecorativeIconProps = {
  variant?: 'decorative';
};

/**
 * Icons which have contextual meaning and can be used as standalone
 */
type StandaloneIconProps = {
  variant?: 'standalone';
  title: string;
};

type VariantProps = DecorativeIconProps | StandaloneIconProps;
export type TIconProps = IconBaseProps & VariantProps;

const getIconConfig = (props: TIconProps, id: string) => {
  const { name, variant = 'decorative', ...otherProps } = props;

  if (variant === 'standalone' && 'title' in otherProps) {
    const { title, ...rest } = otherProps;
    return {
      name,
      title: <title id={id}>{title}</title>,
      additionalProps: {
        ...rest,
        role: 'img',
        'aria-labelledby': id,
      },
    };
  }

  // for decorative icons
  return {
    name,
    title: null,
    additionalProps: {
      ...otherProps,
      'aria-hidden': true,
    },
  };
};

/**
 * Icon component to render Sprite based SVG icons
 * @param props
 * @returns
 */
export function Icon(props: TIconProps) {
  const url = useContext(Ctx);

  // id used for A11y purpose
  const id = useId();

  const { name, title, additionalProps } = getIconConfig(props, id);

  return (
    <svg
      width="1em"
      height="1em"
      {...additionalProps}
      // temporarily commenting preserveDefaultColor work due to Qantas demo bug, will revert once proper solution is found
      // className={clsx({ 'preserve-default-colors': preserveDefaultColor }, props.className)}
    >
      {title}
      <use xlinkHref={`${url}#${name}`} />
    </svg>
  );
}

/**
 * We can either choose to render the svg on the page like <Sprite />, in which case an empty string as url makes the Icons work.
 * However, when we don't want to render it as a React component and instead fetch as image resource, we can pass the url of the
 * sprite image to the provider, like "/assets/sprite.svg" to make the icons pick the right URL.
 */
export function SpriteProvider({ url, children }: { url: string; children: React.ReactNode }) {
  return <Ctx.Provider value={url}>{children}</Ctx.Provider>;
}
