/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum SeatType {
    Unknown = 'UNKNOWN',
    Flat = 'FLAT',
    Recliner = 'RECLINER',
    Skycouch = 'SKYCOUCH',
    PrivateSuite = 'PRIVATE_SUITE'
}



