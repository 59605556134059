import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CarryOnBaggage = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: `0 0 ${width} ${height}`, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.8382 0C14.4574 0 15.7769 1.28279 15.8361 2.88753L15.8382 3V3.10561H17.7136C19.6469 3.10561 21.2136 4.67233 21.2136 6.60561V14.5001C21.2136 16.433 19.6468 18.0001 17.7136 18.0001H4.28565C2.3525 18.0001 0.785645 16.433 0.785645 14.5001V6.60561C0.785645 4.67233 2.35236 3.10561 4.28565 3.10561H6.16116V3C6.16116 1.3808 7.44394 0.0612141 9.04869 0.00206924L9.16116 0H12.8382ZM13.8382 3V3.10561H8.16116V3L8.1639 2.92537C8.20207 2.40794 8.63398 2 9.16116 2H12.8382L12.9128 2.00274C13.4302 2.04092 13.8382 2.47282 13.8382 3ZM17.7136 5.10561H4.28564C3.45693 5.10561 2.78564 5.77689 2.78564 6.60561V8.15427L10.9992 9.88349L19.2136 8.13094V6.60561C19.2136 5.77689 18.5424 5.10561 17.7136 5.10561ZM19.2136 9.15343L11.104 10.8835C11.0584 10.8932 11.0119 10.8965 10.9656 10.8933L10.8967 10.8838L2.78564 9.17618V14.5001C2.78564 15.3285 3.45713 16.0001 4.28564 16.0001H17.7136C18.5422 16.0001 19.2136 15.3285 19.2136 14.5001V9.15343Z", fill: themeEnrichedPrimaryColor })));
};
CarryOnBaggage.defaultProps = defaultIconProps;
export default CarryOnBaggage;
