import { DeprecatedOptionWithStringifiedValue } from '@spotnana/blocks/src/SelectRoot/helpers';
import { Typography } from '@spotnana/blocks/src/Typography';

import { useFeatureFlag } from 'obt-common/feature-gating/hooks';
import { useTMCListQuery } from 'obt-common/queries/admin/organizations';
import { useMemo } from 'react';
import CompanyLabel from 'src/app/shared/components/CompanyLabelContainer';
import { TOption } from '../TravelerPicker';
import { traveler_picker_option_description_container } from './styles';
import TmcLabel from '../../TmcLabel';

export function TravelerPickerOptionDescription({ item }: { item: DeprecatedOptionWithStringifiedValue<TOption> }) {
  const isDisplayCompanyNameLogoEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_TMC_NAME');

  const { email, persona, userOrgId } = item;

  const companyId = userOrgId?.organizationId?.id ?? '';
  const tmcBasicInfo = userOrgId?.tmcBasicInfo ?? null;

  const { data: tmcList, isFetching: isFetchingTmcList } = useTMCListQuery({
    isSpotnanaAgentOrAdmin: true,
    companyIds: [companyId],
    options: { enabled: isDisplayCompanyNameLogoEnabled && !!companyId },
  });

  const userCompany = useMemo(() => tmcList?.find((tmc) => tmc.id.id === companyId), [tmcList, companyId]);

  return (
    <div css={traveler_picker_option_description_container}>
      <Typography variant="body3" color="secondary">
        {email}
      </Typography>

      <TmcLabel tmcBasicInfo={tmcBasicInfo} />

      <CompanyLabel company={userCompany} persona={persona} isLoading={isFetchingTmcList} />
    </div>
  );
}
