import { ROUTE_PATHS } from './routes';

const routesWithoutFooter = [
  ROUTE_PATHS.HOME,
  ROUTE_PATHS.LOGIN,
  ROUTE_PATHS.COMPANY_ENROLLMENT,
  ROUTE_PATHS.APPLY,
  ROUTE_PATHS.MOBILE_ADD_PAYMENT_CARD,
  ROUTE_PATHS.MOBILE_CHECKOUT_AIR,
  ROUTE_PATHS.MOBILE_CHECKOUT_HOTEL,
  ROUTE_PATHS.MOBILE_PDF_PAGE,
  ROUTE_PATHS.ONBOARDING,
];

export default routesWithoutFooter;
