import React, { createContext, useContext, useMemo } from 'react';
const ConfigContext = createContext(undefined);
export const CustomizationProvider = ({ config, children }) => {
    const value = useMemo(() => config, [config]);
    return React.createElement(ConfigContext.Provider, { value: value }, children);
};
export const useCustomizationConfig = () => {
    const context = useContext(ConfigContext);
    if (context === undefined) {
        throw new Error('`useConfig` hook must be used within a `ConfigProvider` component');
    }
    return context;
};
