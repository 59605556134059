import { FC } from 'react';
import { Modal } from '@spotnana/blocks/src/Modal';
import { useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography';
import { modal_secondary_background } from '../shared/styles';

export enum DeleteFaresModalType {
  SINGLE_FARE = 'SINGLE_FARE',
  FARE_LIST = 'FARE_LIST',
}
interface DeleteFaresModalProps {
  open: boolean;
  onClose: VoidFunction;
  onDeleteAllSavedItineraries: VoidFunction;
}

export const DeleteAllSavedItinerariesModal: FC<DeleteFaresModalProps> = ({
  open,
  onClose,
  onDeleteAllSavedItineraries,
}) => {
  const { t: tt } = useTranslation('WEB');

  const onConfirmDelete = () => {
    onDeleteAllSavedItineraries();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      header={{ title: tt('Delete all saved itineraries') }}
      variant="medium"
      footer={{
        primaryAction: {
          onClick: onConfirmDelete,
          text: tt('Delete All'),
        },
        secondaryAction: {
          onClick: onClose,
          text: tt('Cancel'),
        },
      }}
      css={modal_secondary_background}
    >
      <Typography variant="body1" color="secondary">
        {tt('This will delete all saved itineraries.')}
      </Typography>
    </Modal>
  );
};
