/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TravelType as TravelTypeV1 } from '../../../v1/common/tmc';

/**
 * Travel Type
 * @export
 * @enum {string}
 */

export const TravelType = {
  Air: 'AIR',
  Hotel: 'HOTEL',
  Car: 'CAR',
  Rail: 'RAIL',
  Limo: 'LIMO',
  Misc: 'MISC',
  All: 'ALL',
} as const;

export type TravelType = (typeof TravelType)[keyof typeof TravelType];

export const travelTypeV1ToV2 = (v1: TravelTypeV1): TravelType => {
  switch (v1) {
    case TravelTypeV1.AIR:
      return TravelType.Air;
    case TravelTypeV1.HOTEL:
      return TravelType.Hotel;
    case TravelTypeV1.CAR:
      return TravelType.Car;
    case TravelTypeV1.RAIL:
      return TravelType.Rail;
    case TravelTypeV1.LIMO:
      return TravelType.Limo;
    case TravelTypeV1.MISC:
      return TravelType.Misc;
    default:
      return TravelType.All;
  }
};
