/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Payment method.
 * @export
 * @interface HotelPayment
 */
export interface HotelPayment {
    /**
     * Payment type.
     * @type {string}
     * @memberof HotelPayment
     */
    paymentType: HotelPaymentPaymentTypeEnum;
    /**
     * Payment method, if payment type is OTHER.
     * @type {string}
     * @memberof HotelPayment
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum HotelPaymentPaymentTypeEnum {
    Unknown = 'UNKNOWN',
    PayAtHotel = 'PAY_AT_HOTEL',
    Prepaid = 'PREPAID',
    Other = 'OTHER'
}



