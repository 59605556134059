import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Personal = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 24 24" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M12 4.875c2.413 0 4.368 1.956 4.368 4.368 0 1.277-.547 2.426-1.42 3.225 2.433.859 4.177 3.18 4.177 5.907 0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-2.592-2.07-4.7-4.647-4.762l-.116-.001h-1.724c-2.63 0-4.763 2.132-4.763 4.763 0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-2.728 1.744-5.049 4.178-5.908-.874-.798-1.421-1.947-1.421-3.224 0-2.412 1.955-4.368 4.368-4.368zm0 1.5c-1.584 0-2.868 1.284-2.868 2.868 0 1.585 1.284 2.869 2.868 2.869 1.584 0 2.868-1.284 2.868-2.869 0-1.584-1.284-2.868-2.868-2.868z" })));
};
Personal.defaultProps = defaultIconProps;
export default Personal;
