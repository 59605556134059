import first from 'lodash/first';
import intersection from 'lodash/intersection';
import type { ReadEmployeeResponse } from '../types/api/v1/obt/profile/profile_services';
import type { RoleInfoType } from '../types/api/v1/obt/profile/role/roles_info';
import { RoleInfoTypeEnum } from '../types/api/v1/obt/profile/role/roles_info';
import { roleFilters, roleOptions, roleOptionTranslationKeys } from '../constants/admin/role';
import type { RoleOptionType, UserManagementRoleInfo, INewUserData, RoleFilterType } from '../types/admin';
import { CustomRoleInfoType, NewUserTypeEnum } from '../types/admin';
import type { ListRolesResponse } from '../types/api/v1/obt/profile/role/role_services';

import { emptyName, emptyPhoneNumber } from '../constants/traveler';
import type { UserBasicInfo } from '../types/api/v1/obt/common/user_basic_info';
import { TitleEnum } from '../types/api/v1/obt/common/title';
import { UserBusinessInfoWorkerType } from '../types/api/v1/obt/common/user_business_info';
import { getProfileEccKeyFromString } from '../constants/admin/ecc';

export default class ReadEmployeeResponseManager {
  constructor(readonly response: ReadEmployeeResponse) {
    if (!response) {
      throw new Error('Invalid ReadEmployeeResponse passed to ReadEmployeeResponseManager');
    }
  }

  private modifiedTravelersData = (travelerData: UserBasicInfo[] | undefined): UserBasicInfo[] => {
    if (travelerData) {
      return travelerData.map((traveler) => {
        const travelerCopy = { ...traveler };
        delete travelerCopy.persona;
        return travelerCopy;
      });
    }
    return [];
  };

  private GetTravelerArrangerRoleObject(): UserManagementRoleInfo {
    return {
      value: CustomRoleInfoType.ARRANGER,
      label: roleOptionTranslationKeys.TRAVEL_ARRANGER_LABEL,
      travelArrangerFor:
        this.modifiedTravelersData(first(this.response.employee?.roleInfos)?.travelArranger?.userBasicInfos) ?? [],
      manageFor: {
        label: roleOptionTranslationKeys.INDIVIDUALS_LABEL,
        value: 'individuals',
      },
    };
  }

  private GetCompanyTravelerArrangerRoleObject(): UserManagementRoleInfo {
    return {
      value: CustomRoleInfoType.ARRANGER,
      label: roleOptionTranslationKeys.TRAVEL_ARRANGER_LABEL,
      travelArrangerFor: first(this.response.employee?.roleInfos)?.travelArranger?.userBasicInfos,
      manageFor: {
        label: roleOptionTranslationKeys.EVERYONE_LABEL,
        value: 'everyone',
      },
    };
  }

  public GetEmployeeData(): INewUserData {
    const { userBasicInfo, userBusinessInfo, tier } = this.response.employee;

    const employeeDetailsWithoutTitle: INewUserData['employeeDetails'] = {
      title: userBasicInfo?.title ?? TitleEnum.MR,
      name: userBasicInfo?.name ?? { ...emptyName },
      email: userBusinessInfo?.email ?? '',
      phoneNumber: first(userBusinessInfo?.phoneNumbers) ?? { ...emptyPhoneNumber },
      designation: userBusinessInfo?.designation ?? '',
      employeeId: userBusinessInfo?.employeeId ?? '',
      manager: userBusinessInfo?.managerBasicInfo ?? null,
      tier,
      workerType: userBusinessInfo?.workerType ?? UserBusinessInfoWorkerType.WORKER_TYPE_UNKNOWN,
      accountingCode: userBusinessInfo?.accountingCode ?? '',
    };

    const eccRaw = userBusinessInfo?.companySpecifiedAttributes?.find((v) => v.fixedColumnName === 'ECC')?.value;
    const ecc = eccRaw ? getProfileEccKeyFromString(eccRaw) : '';

    return {
      employeeDetails: employeeDetailsWithoutTitle as INewUserData['employeeDetails'],
      employeeCompanyDetails: {
        legalEntityId: userBusinessInfo?.legalEntityId?.id ?? '',
        officeId: userBusinessInfo?.office?.id?.id ?? '',
        departmentId: userBusinessInfo?.department?.id?.id ?? '',
        costCenterId: userBusinessInfo?.userCostCenter?.id?.id ?? '',
        countryCode: userBusinessInfo?.countryCode ?? '',
        ecc: ecc ?? '',
      },
      newUserType: NewUserTypeEnum.EMPLOYEE,
    };
  }

  public GetEmployeeRole(userRole: RoleInfoType | null): UserManagementRoleInfo {
    switch (userRole) {
      case RoleInfoTypeEnum.TRAVEL_ARRANGER:
        return this.GetTravelerArrangerRoleObject();
      case RoleInfoTypeEnum.COMPANY_TRAVEL_ARRANGER:
        return this.GetCompanyTravelerArrangerRoleObject();
      case RoleInfoTypeEnum.COMPANY_ADMIN:
        return {
          value: CustomRoleInfoType.COMPANY_ADMIN,
          label: roleOptionTranslationKeys.COMPANY_ADMIN_LABEL,
        };
      case RoleInfoTypeEnum.GLOBAL_AGENT:
        return {
          value: CustomRoleInfoType.SPOTNANA_AGENT,
          label: roleOptionTranslationKeys.SPOTNANA_AGENT_LABEL,
        };
      case RoleInfoTypeEnum.GLOBAL_ADMIN:
        return {
          value: CustomRoleInfoType.SPOTNANA_ADMIN,
          label: roleOptionTranslationKeys.SPOTNANA_ADMIN_LABEL,
        };
      case RoleInfoTypeEnum.TMC_AGENT:
        return {
          value: CustomRoleInfoType.TMC_AGENT,
          label: roleOptionTranslationKeys.TMC_AGENT_LABEL,
        };
      case RoleInfoTypeEnum.TMC_ADMIN:
        return {
          value: CustomRoleInfoType.TMC_ADMIN,
          label: roleOptionTranslationKeys.TMC_ADMIN_LABEL,
        };
      default:
        return {
          value: CustomRoleInfoType.TRAVELER,
          label: roleOptionTranslationKeys.TRAVELER_LABEL,
        };
    }
  }

  public GetEmployeeRoles(): UserManagementRoleInfo[] {
    // if roles info is present then format role info for each given role
    // otherwise send default role info
    if (this.response.employee?.roleInfos?.length > 0) {
      return this.response.employee.roleInfos.map((x) => this.GetEmployeeRole(x.type));
    }
    return [this.GetEmployeeRole(RoleInfoTypeEnum.UNRECOGNIZED)];
  }

  static GetRolesList(roleListResponse: ListRolesResponse): RoleOptionType[] {
    return roleOptions.filter((role) => {
      if (role.value === CustomRoleInfoType.TRAVELER) return true;

      return Boolean(intersection(role.backEndValue ?? [], roleListResponse?.roleTypes ?? []).length);
    });
  }

  static GetRoleFilters = (roleListResponse: ListRolesResponse): RoleFilterType[] => {
    return roleFilters.filter((role) => {
      if (role.value === 'TRAVELER') return true;

      return roleListResponse.roleTypes.includes(role.backEndValue);
    });
  };
}
