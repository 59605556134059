import first from 'lodash/first';
import { PaymentSourceInfoType } from '../types/api/v2/obt/profile/payments/payment_sources';
import type { Location } from '../types/api/v1/obt/car/car_common';
import type { CarInfo } from '../types/api/v1/obt/car/car_search_response';
import { RateComponentType, RateRateType } from '../types/api/v1/obt/car/car_search_response';
import { CarType, CounterLocation, Transmission } from '../types/api/v1/obt/car/car_common';
import { CarExtraTypeEnum, CarRefundable, ITransmissionType } from '../types/car';
import type {
  ICarSearchResponse,
  ICarSummary,
  ILocationDetails,
  IPnrRequestCarDetails,
  IPolicyDetails,
  IThumbnailImage,
  ICarInfo,
  ICarRate,
  ICarLocationDetails,
} from '../types/car';
import { MoneyUtil } from '../utils/Money';
import {
  underScoreToSpace,
  getLocationFullAddress,
  getLocationShortAddress,
  getLocationFullAddressV2,
  getLocationShortAddressV2,
} from '../utils/common';
import { LengthUnitEnum } from '../types/api/v1/common/length';
import { carExtrasLabelMap, carFareTypeToCommonFareType, dateFormats } from '../constants';
import { FareTypeEnum } from '../types/api/v1/obt/air/air_search_response';
import { getDiff } from '../date-utils';
import SpotnanaError from '../api/SpotnanaError';
import type { CarItineraryId } from '../types/api/v2/obt/model/car-itinerary-id';
import { getVendorPreferences } from '../utils/policies';
import type { PolicyInfo } from '../types/api/v1/obt/common/policy_info';
import { PolicyInfoApprovalType } from '../types/api/v1/obt/common/policy_info';
import { defineCommonMessage, defineMessage } from '../translations/defineMessage';
import type { CarLocationDetails } from '../types/api/v2/obt/model/car-location-details';

export default class CarSearchResponseManager {
  readonly numberOfCars: number;

  constructor(readonly data: ICarSearchResponse) {
    if (!data) {
      throw new Error('Invalid data passed to CarSearchResponseManager');
    }
    this.numberOfCars = this.data.cars.length;
  }

  public GetCars(showOutofPolicy = true): CarInfo[] {
    return this.data.cars?.filter((carInfo) => (showOutofPolicy ? true : !carInfo?.policyInfo?.violationMessage));
  }

  public GetPnrRequestCarDetails(carIndex: number): IPnrRequestCarDetails {
    if (carIndex >= this.numberOfCars) {
      throw new Error('Invalid car index passed to GetPnrRequestCarDetails');
    }
    return { carId: this.getCarId(carIndex) };
  }

  public GetCarItineraryId(carIndex: number): CarItineraryId {
    return { ...this.GetPnrRequestCarDetails(carIndex), searchId: this.data.searchId };
  }

  public GetSearchId(): string {
    return this.data.searchId;
  }

  public GetAvailableCarVendors(): Record<string, string> | undefined {
    if (!this.data.metadata?.availableVendors) {
      return undefined;
    }

    const carVendors: Record<string, string> = {};
    this.data.metadata?.availableVendors?.forEach((vendor) => {
      carVendors[vendor.code] = vendor.name;
    });
    return carVendors;
  }

  public GetCarSummary(carId: string): ICarSummary {
    const selectedCar = first(this.data.cars.filter((car) => car.carId === carId));
    const showOnlyBaseFare = this.getShowOnlyBaseFare();

    if (!selectedCar) {
      throw new Error('Invalid car id passed to GetCarSummary');
    }

    return {
      displayName: selectedCar.carSpec?.displayName ?? '',
      modelName: selectedCar.carSpec?.modelName ?? '',
      thumbnail: this.getThumbnailImage(carId),
      type: selectedCar.carSpec ? `${CarType[selectedCar.carSpec?.type]}` : '',
      vendor: selectedCar.vendor ?? { code: '', name: '', email: '' },
      amenities: selectedCar?.carSpec?.amenities ?? {
        numSeatBelts: 0,
        numLargeBags: 0,
        numSmallBags: 0,
        numSeats: 0,
        numDoors: 0,
      },
      carExtras: selectedCar.rate?.carExtras ?? [],
      approxCost: this.GetApproximateTotalCost(carId),
      pickupDetails: CarSearchResponseManager.getLocationDetails(selectedCar.pickupLocation),
      dropOffDetails: CarSearchResponseManager.getLocationDetails(selectedCar.dropOffLocation),
      policyDetails: this.getPolicyDetails(carId),
      policyViolationMessage: selectedCar?.policyInfo?.violationMessage ?? '',
      policyInfo: selectedCar?.policyInfo,
      transmission: this.getTransmission(carId),
      pickupDistance: selectedCar.pickupDistance,
      dropOffDistance: selectedCar.dropOffDistance,
      preferredType: getVendorPreferences(selectedCar.preferredType, selectedCar.preferences),
      publishedTotalCost: this.GetPublishedTotalCost(carId),
      negotiatedSavings: this.GetPublishedSavingsCost(carId),
      fareType: this.GetFareType(carId),
      pickupLocation: selectedCar.pickupLocation,
      dropOffLocation: selectedCar.dropOffLocation,
      /**
       * dailyCostMobile and publishedCostMobile to be removed once showFareIncludingTaxesAndFees filter is in place for mobile
       */
      dailyCostMobile: this.GetCarDailyFare(carId, showOnlyBaseFare),
      publishedDailyCostMobile: this.GetCarDailyPublishedFare(carId, showOnlyBaseFare),
      dailyCostIncludingTaxes: this.GetCarDailyFare(carId, false),
      dailyCostExcludingTaxes: this.GetCarDailyFare(carId, true),
      publishedDailyCostIncludingTaxes: this.GetCarDailyPublishedFare(carId, false),
      publishedDailyCostExcludingTaxes: this.GetCarDailyPublishedFare(carId, true),
      preferences: selectedCar.preferences ?? [],
      engineType: selectedCar?.carSpec?.engineType ?? -1,
      rewardPointsEarned: selectedCar.rewardPointsEarned ?? [],
      carRateDifference: MoneyUtil.zeroMoney(),
      carModifyAverageRateDifference: MoneyUtil.zeroMoney(),
    };
  }

  public GetSearchDateTime(): { pickup: string; drop: string } {
    const pickup = this.data.pickup?.iso8601 ?? '';
    const drop = this.data.drop?.iso8601 ?? '';
    return { pickup, drop };
  }

  public GetApproximateTotalCost(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);
    const amount = car?.rate?.rateComponents.find(
      (component) => component.type === RateComponentType.APPROXIMATE_TOTAL_PRICE,
    )?.amount;
    return MoneyUtil.parse(amount);
  }

  private GetPublishedBaseCost(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);

    return MoneyUtil.parse(
      car?.rate?.rateComponents.find((component) => component.type === RateComponentType.PUBLISHED_BASE_FARE)?.amount,
    );
  }

  // TODO: Do not round down the fare
  public GetCarDailyFare(carId: string, excludeTaxes: boolean): MoneyUtil {
    const dailyCost = this.GetDailyCost(carId, excludeTaxes);
    const approxCost = this.GetApproximateTotalCost(carId);
    const totalNumberOfDays = this.GetCarBookingDaysCount();
    if (dailyCost.isZero()) {
      if (!totalNumberOfDays) return approxCost;
      const carDailyCost = Math.floor(approxCost.getAmount() / totalNumberOfDays);
      return MoneyUtil.create(carDailyCost, approxCost.getCurrency());
    }
    return dailyCost;
  }

  public GetCarDailyPublishedFare(carId: string, excludeTaxes: boolean): MoneyUtil {
    const dailyPublishedCost = this.GetPublishedDailyCost(carId, excludeTaxes);
    const publishedTotalCost = this.GetPublishedTotalCost(carId);
    const totalNumberOfDays = this.GetCarBookingDaysCount();
    if (dailyPublishedCost.isZero()) {
      if (!totalNumberOfDays) return publishedTotalCost;
      const carDailyCost = Math.floor(publishedTotalCost.getAmount() / totalNumberOfDays);
      return MoneyUtil.create(carDailyCost, publishedTotalCost.getCurrency());
    }
    return dailyPublishedCost;
  }

  private GetPublishedDailyCost(carId: string, excludeTaxes: boolean): MoneyUtil {
    const car = this.getSelectedCar(carId);
    return MoneyUtil.parse(
      car?.rate?.rateComponents.find((component) =>
        excludeTaxes
          ? component.type === RateComponentType.PUBLISHED_DAILY_BASE_FARE
          : component.type === RateComponentType.PUBLISHED_DAILY_TOTAL,
      )?.amount,
    );
  }

  private GetDailyCost(carId: string, excludeTaxes: boolean): MoneyUtil {
    const car = this.getSelectedCar(carId);
    return MoneyUtil.parse(
      car?.rate?.rateComponents.find((component) =>
        excludeTaxes
          ? component.type === RateComponentType.APPROXIMATE_DAILY_AVERAGE_BASE
          : component.type === RateComponentType.APPROXIMATE_DAILY_AVERAGE_TOTAL,
      )?.amount,
    );
  }

  private GetPublishedTaxCost(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);
    return MoneyUtil.parse(
      car?.rate?.rateComponents.find((component) => component.type === RateComponentType.PUBLISHED_TOTAL_TAX)?.amount,
    );
  }

  public GetPublishedTotalCost(carId: string): MoneyUtil {
    return this.GetPublishedBaseCost(carId).add(this.GetPublishedTaxCost(carId));
  }

  public GetPublishedSavingsCost(carId: string): MoneyUtil {
    const publishedCost = this.GetPublishedTotalCost(carId);
    const approximatePrice = this.GetApproximateTotalCost(carId);

    return publishedCost.isPositive()
      ? publishedCost.subtract(approximatePrice)
      : MoneyUtil.create(0, approximatePrice.getCurrency());
  }

  public GetFareType(carId: string): FareTypeEnum {
    const car = this.getSelectedCar(carId);

    return carFareTypeToCommonFareType[car?.rate?.rateType ?? FareTypeEnum.UNRECOGNIZED];
  }

  public GetCarRates(carId: string): ICarRate[] {
    const car = this.getSelectedCar(carId);
    const rates =
      car?.rate?.rateComponents.map((component) => ({
        label: underScoreToSpace(RateComponentType[component.type]),
        price: MoneyUtil.parse(component?.amount),
        extraMileageCharge: MoneyUtil.parse(component?.extraMileageCharge),
        mileageAllowance: {
          length: component?.mileageAllowance?.length ?? -1,
          unit: LengthUnitEnum[component?.mileageAllowance?.unit ?? -1],
        },
      })) ?? [];
    return rates;
  }

  public GetCarRatesV2(carId: string): Record<string, ICarRate> {
    const car = this.getSelectedCar(carId);
    const rates: Record<string, ICarRate> = {};
    car?.rate?.rateComponents.forEach((component) => {
      rates[component.type] = {
        label: underScoreToSpace(RateComponentType[component.type]),
        price: MoneyUtil.parse(component?.amount),
        extraMileageCharge: MoneyUtil.parse(component?.extraMileageCharge),
        mileageAllowance: {
          length: component?.mileageAllowance?.length ?? -1,
          unit: LengthUnitEnum[component?.mileageAllowance?.unit ?? -1],
        },
      };
    });
    return rates;
  }

  public GetCarExtraRates(carId: string): Record<string, ICarRate> {
    const car = this.getSelectedCar(carId);
    const rates: Record<string, ICarRate> = {};
    car?.rate?.carExtras.forEach((component) => {
      rates[component.type] = {
        label: carExtrasLabelMap[component.type] ?? carExtrasLabelMap[CarExtraTypeEnum.UNKNOWN_TYPE],
        price: MoneyUtil.parse(component?.amount),
        extraMileageCharge: MoneyUtil.zeroMoney(),
        mileageAllowance: {
          length: -1,
          unit: LengthUnitEnum[-1],
        },
      };
    });
    return rates;
  }

  public GetCarBookingDaysCount(): number {
    const { pickup, drop } = this.data;
    return CarSearchResponseManager.GetCarBookingDaysCount(pickup?.iso8601, drop?.iso8601);
  }

  static GetCarBookingDaysCount(pickupIso8601?: string, dropIso8601?: string): number {
    if (!pickupIso8601 || !dropIso8601) {
      throw new SpotnanaError('Both pickup and drop date-times should be available!');
    }

    return Math.ceil(
      getDiff(pickupIso8601, dropIso8601, 'days', dateFormats.ISO_WO_SECONDS, dateFormats.ISO_WO_SECONDS, true),
    );
  }

  static getLocationDetails(location?: Location): ILocationDetails {
    if (location) {
      return {
        fullAddress: getLocationFullAddress(location?.address),
        shortAddress: getLocationShortAddress(location?.address),
        coordinates: location?.coordinates,
        operatingTimes: location?.operatingSchedule?.interval ?? [],
        // TODO: Fix this unnecessary typecast to string
        counterLocation: `${CounterLocation[location?.counterLocation]}`,
        weeklyOperatingSchedule: location?.weeklyOperatingSchedule ?? undefined,
        contactInfo: location.contactInfo ?? undefined,
      };
    }
    return {
      fullAddress: '',
      shortAddress: '',
      operatingTimes: [],
      counterLocation: '',
      weeklyOperatingSchedule: undefined,
      contactInfo: undefined,
    };
  }

  static getLocationDetailsV2(location?: CarLocationDetails): ICarLocationDetails {
    if (location) {
      return {
        fullAddress: getLocationFullAddressV2(location?.address),
        shortAddress: getLocationShortAddressV2(location?.address),
        coordinates: location?.coordinates,
        operatingTimes: location?.operatingSchedule ?? undefined,
        counterLocation: location?.counterLocation ?? undefined,
        weeklyOperatingSchedule: location?.weeklyOperatingSchedule ?? undefined,
        contactInfo: location?.contactInfo ?? undefined,
      };
    }
    return {
      fullAddress: '',
      shortAddress: '',
      operatingTimes: undefined,
      counterLocation: '',
      weeklyOperatingSchedule: undefined,
      contactInfo: undefined,
    };
  }

  private getSelectedCar(carId: string): ICarInfo | undefined {
    return first(this.data.cars.filter((car) => car.carId === carId));
  }

  private getThumbnailImage(carId: string): IThumbnailImage {
    const car = this.getSelectedCar(carId);
    const imageGroup = first(car?.carSpec?.imageGroups);
    const image = first(imageGroup?.images);
    return { image, caption: imageGroup?.caption };
  }

  private getPolicyDetails(carId: string): IPolicyDetails {
    const car = this.getSelectedCar(carId);
    const firstCarRateComponent = first(car?.rate?.rateComponents);
    const mileageCharge = firstCarRateComponent?.extraMileageCharge ?? { amount: 0, currencyCode: '' };
    const mileage = mileageCharge.amount === 0 ? defineMessage('Unlimited mileage') : '';
    const payPolicy = car?.rate?.type ?? RateRateType.UNRECOGNIZED;
    let payAt = '';
    switch (payPolicy) {
      case RateRateType.PREPAID:
        payAt = defineMessage('Prepaid');
        break;
      case RateRateType.GUARANTEED:
        payAt = defineMessage('Pay at pick-up');
        break;
      case RateRateType.UNKNOWN:
        payAt = defineMessage('Pay at pick-up');
        break;
      case RateRateType.UNRECOGNIZED:
        payAt = defineMessage('NA');
        break;
      default:
        break;
    }
    const rates = this.GetCarRates(carId);
    const cancellationPolicies = car?.rate?.cancellationPolicies ?? [];
    const refundable = car?.rate?.refundable;
    const cancellation = cancellationPolicies.map((policy) => ({
      amount: MoneyUtil.parse(policy.amount),
      percent: policy.percent,
      hoursBefore: policy.hoursBefore,
      absoluteDeadline: policy.absoluteDeadline,
    }));
    return {
      mileage,
      payAt,
      cancellation,
      rates,
      refundable,
    };
  }

  private getTransmission(carId: string): ITransmissionType {
    const car = this.getSelectedCar(carId);
    const transmission = car?.carSpec?.transmission ?? Transmission.UNRECOGNIZED;
    switch (transmission) {
      case Transmission.AUTO_UNSPECIFIED_DRIVE:
      case Transmission.AUTO_4WD:
      case Transmission.AUTO_AWD:
        return ITransmissionType.AUTO;
      case Transmission.MANUAL_4WD:
      case Transmission.MANUAL_AWD:
      case Transmission.MANUAL_UNSPECIFIED_DRIVE:
        return ITransmissionType.MANUAL;
      default:
        break;
    }
    return ITransmissionType.UNKNOWN;
  }

  private getCarId(carIndex: number): string {
    return this.data.cars[carIndex].carId;
  }

  public getShowOnlyBaseFare(): boolean {
    return this.data.showOnlyBaseFare ?? false;
  }

  static isSoftApprovalRequired = (policyInfo: PolicyInfo | undefined): boolean =>
    policyInfo?.approvalType === PolicyInfoApprovalType.SOFT_APPROVAL;

  static isHardApprovalRequired = (policyInfo: PolicyInfo | undefined): boolean =>
    policyInfo?.approvalType === PolicyInfoApprovalType.HARD_APPROVAL;

  public getRentalInclusions(carId: string) {
    const car = this.getSelectedCar(carId);

    const payPolicy = car?.rate?.type ?? RateRateType.UNRECOGNIZED;
    const isPayAtPickup = [RateRateType.GUARANTEED, RateRateType.UNKNOWN].includes(payPolicy);

    const firstCarRateComponent = first(car?.rate?.rateComponents);
    const mileageCharge = firstCarRateComponent?.extraMileageCharge ?? { amount: 0, currencyCode: '' };
    const isUnlimitedMileageIncluded = mileageCharge.amount === 0;

    const isRefundable = car?.rate?.refundable === CarRefundable.REFUNDABLE;

    // const isInsuranceIncluded = ??;

    return [
      { type: 'PAY_AT_PICKUP', value: isPayAtPickup, label: defineCommonMessage('Pay at pick-up') },
      { type: 'UNLIMITED_MILEAGE', value: isUnlimitedMileageIncluded, label: defineCommonMessage('Unlimited mileage') },
      { type: 'REFUNDABLE', value: isRefundable, label: defineCommonMessage('Free cancellation') },
    ] as const;
  }

  public IsVirtualCardRate(carId: string): boolean {
    const car = this.getSelectedCar(carId);
    return car?.rate?.allowedPaymentTypes?.includes(PaymentSourceInfoType.VIRTUAL_CARD) ?? false;
  }

  public GetCarCO2EmissionValue(carId: string): number {
    const car = this.getSelectedCar(carId);
    return car?.co2Emission?.co2EmissionValue ?? 0;
  }
}
