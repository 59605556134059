import { Priority } from '../types/api/v2/obt/model/priority';
import { ThirdPartySource } from '../types/api/v2/obt/model/third-party-source';
import type { IKeyLabelPair } from '../types/common';
import { ThirdPartySourceEnum } from '../types/trip';

import { AgentBucketFilterKeys } from './agentDesktop';

export const thirdPartySourcesOptions: IKeyLabelPair<ThirdPartySourceEnum>[] = [
  { backendKey: ThirdPartySourceEnum.SABRE, labelKey: 'SABRE' },
];

export enum AgentTranslationKeys {
  SEARCH = 'SEARCH',
  LOADING = 'LOADING',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
  GO_TO_TRIPS = 'GO_TO_TRIPS',
  IN_TRIPS = 'IN_TRIPS',
  SOURCE = 'SOURCE',
  IN_COMPANY = 'IN_COMPANY',
  COPY_LINK = 'COPY_LINK',
  SHARE = 'SHARE',
  COPIED_SUCCESSFULLY = 'COPIED_SUCCESSFULLY',
  SELECT_TRAVELER = 'SELECT_TRAVELER',
  SELECT_TRAVELER_TO_CONTINUE = 'SELECT_TRAVELER_TO_CONTINUE',
  CHANGE_TRAVELER = 'CHANGE_TRAVELER',
  BOOKING_LINK_SHARED = 'BOOKING_LINK_SHARED',
  SELECT_TRIP_PURPOSE = 'SELECT_TRIP_PURPOSE',
  REASON = 'REASON',
  PNR_TYPE = 'PNR_TYPE',
  APPROVED_NAME = 'APPROVED_NAME',
  REASON_TYPE_ERROR = 'REASON_TYPE_ERROR',
  PNR_TYPE_ERROR = 'PNR_TYPE_ERROR',
  ADD_MORE_DETAILS = 'ADD_MORE_DETAILS',
  TOTAL_TRAVELERS = 'TOTAL_TRAVELERS',
  PASSENGER_TYPE = 'PASSENGER_TYPE',
  AGENT_DESKTOP = 'AGENT_DESKTOP',
  AGENT_DESKTOP_SUBTITLE = 'AGENT_DESKTOP_SUBTITLE',
  TYPE = 'TYPE',
  PRIORITY = 'PRIORITY',
  TRANSACTION_DATE = 'TRANSACTION_DATE',
  TRAVEL_START = 'TRAVEL_START',
  TRANSACTION_DATE_SORT = 'TRANSACTION_DATE_SORT',
  TRAVEL_START_SORT = 'TRAVEL_START_SORT',
  TRAVELER_INFO = 'TRAVELER_INFO',
  TRIP_INFO = 'TRIP_INFO',
  GROUP = 'GROUP',
  TASK = 'TASK',
  ORGANIZATION = 'ORGANIZATION',
  ARRANGER = 'ARRANGER',
  ARRANGER_INFO = 'ARRANGER_INFO',
  REFERENCE_ID = 'REFERENCE_ID',
  ASSIGNEE = 'ASSIGNEE',
  STATUS = 'STATUS',
  PCC = 'PCC',
  SORT = 'SORT',
  COPY = 'COPY',
  TO_DO = 'TO_DO',
  COMPLETED = 'COMPLETED',
  TOTAL_QUEUE = 'TOTAL_QUEUE',
  PNR_TOTAL_QUEUE = 'PNR_TOTAL_QUEUE',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  URGENT_PRIORITY = 'URGENT_PRIORITY',
  HIGH_PRIORITY = 'HIGH_PRIORITY',
  MEDIUM_PRIORITY = 'MEDIUM_PRIORITY',
  LOW_PRIORITY = 'LOW_PRIORITY',
  TYPE_COMMENT = 'TYPE_COMMENT',
  QUEUE_NUMBER = 'QUEUE_NUMBER',
  MOST_RECENT = 'MOST_RECENT',
  LEAST_RECENT = 'LEAST_RECENT',
  ACTIVE_FILTER = 'ACTIVE_FILTER',
  RESET = 'RESET',
  NA = 'NA',
}

export const thirdPartySourcesMapper: Record<ThirdPartySource, ThirdPartySourceEnum> = {
  [ThirdPartySource.UnknownSource]: ThirdPartySourceEnum.UNKNOWN_SOURCE,
  [ThirdPartySource.Sabre]: ThirdPartySourceEnum.SABRE,
  [ThirdPartySource.TravelFusion]: ThirdPartySourceEnum.TRAVEL_FUSION,
  [ThirdPartySource.Ndc]: ThirdPartySourceEnum.NDC,
  [ThirdPartySource.FarelogixNdc]: ThirdPartySourceEnum.FARELOGIX_NDC,
  [ThirdPartySource.Offline]: ThirdPartySourceEnum.OFFLINE,
  [ThirdPartySource.Routehappy]: ThirdPartySourceEnum.ROUTEHAPPY,
  [ThirdPartySource.AtpcoNdc]: ThirdPartySourceEnum.ATPCO_NDC,
  [ThirdPartySource.Connexus]: ThirdPartySourceEnum.CONNEXUS,
  [ThirdPartySource.Avia]: ThirdPartySourceEnum.AVIA,
  [ThirdPartySource.Trainline]: ThirdPartySourceEnum.TRAINLINE,
  [ThirdPartySource.Southwest]: ThirdPartySourceEnum.SOUTHWEST,
};

export const agentTasksStatsBase = {
  [AgentBucketFilterKeys.TOTAL]: {
    labelKey: AgentTranslationKeys.TOTAL_QUEUE,
    priority: undefined,
  },
  [AgentBucketFilterKeys.ASSIGNED_TO_ME]: {
    labelKey: AgentTranslationKeys.ASSIGNED_TO_ME,
    priority: undefined,
  },
  [AgentBucketFilterKeys.URGENT]: {
    labelKey: AgentTranslationKeys.URGENT_PRIORITY,
    priority: Priority.Urgent,
  },
  [AgentBucketFilterKeys.HIGH_PRIORITY]: {
    labelKey: AgentTranslationKeys.HIGH_PRIORITY,
    priority: Priority.High,
  },
  [AgentBucketFilterKeys.MEDIUM_PRIORITY]: {
    labelKey: AgentTranslationKeys.MEDIUM_PRIORITY,
    priority: Priority.Medium,
  },
  [AgentBucketFilterKeys.LOW_PRIORITY]: {
    labelKey: AgentTranslationKeys.LOW_PRIORITY,
    priority: Priority.Low,
  },
  [AgentBucketFilterKeys.NA]: {
    labelKey: AgentTranslationKeys.NA,
    priority: Priority.Na,
  },
};

export const AGENT_TASKS_TABLE_SIZE = 20;
export const NUM_TMC_AGENTS_ADMINS = 2000;
export const MAX_LOADED_AGENTS_NUM = 10000;
