import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CheckBoxSquared = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_541_11146)" },
            React.createElement("rect", { x: "4", y: "4", width: "16", height: "16", rx: "4", fill: themeEnrichedPrimaryColor }),
            React.createElement("path", { d: "M8.64968 11.3767C8.28079 11.0338 7.69931 11.0502 7.35091 11.4132C7.00251 11.7762 7.01912 12.3485 7.38801 12.6914L10.1443 15.2532C10.5132 15.596 11.0946 15.5797 11.443 15.2166L16.6493 9.79165C16.9977 9.42861 16.9811 8.85636 16.6122 8.51349C16.2433 8.17062 15.6618 8.18697 15.3134 8.55001L10.738 13.3177L8.64968 11.3767Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_541_11146" },
                React.createElement("rect", { width: "16", height: "16", fill: "white", transform: "translate(4 4)" })))));
};
CheckBoxSquared.defaultProps = defaultIconProps;
export default CheckBoxSquared;
