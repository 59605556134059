/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Indicates the source of creation of PNR, like SHELL, OBT, etc.
 * @export
 * @enum {string}
 */

export const CreatedVia = {
  Offline: 'OFFLINE',
  Obt: 'OBT',
  Shell: 'SHELL',
  Passive: 'PASSIVE',
  Api: 'API',
} as const;

export type CreatedVia = (typeof CreatedVia)[keyof typeof CreatedVia];
