import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LoginState, StorageKeys, tokenExchange, useAuth, webSessionStorage } from 'obt-common';

import { parseParams, stringifyParams } from 'obt-common/utils/urlUtils';
import Routes, { ROUTE_PATHS } from '../routes';

const publicRoutes = Routes.filter((route) => route.isPublicRoute === true).map((route) => route.path);

export function useThirdPartyAuthFlow() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const { idp, ...paramsWithoutIDP } = parseParams(search);

  const searchWithoutIDP = stringifyParams(paramsWithoutIDP);

  const { loginState, logout, reconfigureAuth, ssoLogin } = useAuth();

  useEffect(() => {
    const isHeadlessAuthFlow = pathname.endsWith(ROUTE_PATHS.HEADLESS_AUTH);
    // If URL has an idp query parameter & we are not on a public URL (except Headless Auth Flow) and if we are not in token exchange flow,
    // consider it to be 3rd party initiated auth flow
    if (idp && !tokenExchange.includes(idp) && (!publicRoutes.includes(pathname) || isHeadlessAuthFlow)) {
      // Persist IDP value in session storage so that we can restart flow after logout
      webSessionStorage.setItem(StorageKeys.THIRD_PARTY_AUTH_IDP, idp);

      // Then logout if logged-in
      if (loginState === LoginState.LOGGED_IN) {
        // Before logging out, preserve the URL to redirect the User post login
        if (!isHeadlessAuthFlow) {
          webSessionStorage.setItem(
            StorageKeys.AUTH_REDIRECT_URL_KEY,
            stringifyParams({ pathname, search: searchWithoutIDP }),
          );
        }
        // Finally logout, but keep user context in storage since we'll
        // need the headers to complete login.
        // If we are in headless auth flow, we don't want to preserve user context.
        // This can be revisited in the future to be more specific to the case where
        // the caller is not setting both tmcId and orgId in the params, or removed completely
        // if we no longer rely on both params for user context.
        logout(!isHeadlessAuthFlow /** preserveUserContext */);
      }
    }
  }, [idp, loginState, logout, pathname, searchWithoutIDP]);

  useEffect(() => {
    const headlessAuthRedirectUrl = webSessionStorage.getItem(StorageKeys.HEADLESS_AUTH_REDIRECT_URL_KEY);
    const thirdPartyAuthIDP = webSessionStorage.getItem(StorageKeys.THIRD_PARTY_AUTH_IDP);
    if (thirdPartyAuthIDP) {
      if (loginState === LoginState.AUTHENTICATING) {
        webSessionStorage.removeItem(StorageKeys.THIRD_PARTY_AUTH_IDP);
      }

      if (loginState === LoginState.LOGGED_OUT) {
        if (headlessAuthRedirectUrl) {
          const { href } = window.location;
          const redirectSignIn = href.replace(new RegExp(`${ROUTE_PATHS.HEADLESS_AUTH}.*`), '/');
          reconfigureAuth({ oauth: { redirectSignIn } });
        }

        // Timeout to ensure user is properly logged out i.e all cookies on cognito domain is cleared before we try to login again
        setTimeout(() => {
          ssoLogin(thirdPartyAuthIDP);
        }, 1000);
      }
    }
  }, [history, loginState, logout, reconfigureAuth, ssoLogin]);
}
