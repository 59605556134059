import type { PolicyUserGroup } from '../../types/api/v1/obt/policy/policy';
import type { IPolicyState } from '../../types';
import { PolicyType } from '../../types/api/v1/obt/policy/policy_common';

export function getPolicyUserGroups(policy: IPolicyState): PolicyUserGroup[] {
  const { usersGroups } = policy;
  if (policy.type === PolicyType.DEFAULT || !usersGroups) {
    return [];
  }
  // Form autopolulates the first user group with empty values, so we need to remove it if nothing is set
  if (usersGroups.length === 1) {
    const firstPolicy = usersGroups[0];
    const isUserGroupsEmpty = (Object.keys(firstPolicy) as (keyof PolicyUserGroup)[]).every(
      (key) => firstPolicy[key].length === 0,
    );
    if (isUserGroupsEmpty) {
      return [];
    }
  }
  return usersGroups;
}
