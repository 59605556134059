var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Info = (_a) => {
    var { primaryColor, secondaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.69686 13.3034C-0.232287 10.3747 -0.232287 5.62591 2.69686 2.69676C5.62563 -0.232005 10.3744 -0.232005 13.3031 2.69676C16.2319 5.62594 16.2319 10.3747 13.3031 13.3034C10.3744 16.2322 5.62563 16.2322 2.69686 13.3034ZM12.2425 12.2428C14.5855 9.89977 14.5855 6.10074 12.2425 3.75738C9.89951 1.41444 6.10051 1.41444 3.75753 3.75742C1.41418 6.10077 1.41418 9.89974 3.75749 12.2427C6.10051 14.5857 9.89951 14.5857 12.2425 12.2428ZM7.99963 12.3074C8.39204 12.3074 8.71408 12.006 8.7469 11.6221L8.74966 11.5574L8.74992 7.06775C8.74994 6.65354 8.41416 6.31774 7.99995 6.31773C7.60754 6.31771 7.2855 6.61907 7.25268 7.00299L7.24992 7.0677L7.24966 11.5573C7.24964 11.9716 7.58542 12.3074 7.99963 12.3074ZM7.99963 5.65756C8.39204 5.65758 8.71408 5.35622 8.7469 4.9723L8.74966 4.90759L8.74992 4.91795C8.74994 4.50373 8.41417 4.16794 7.99995 4.16792C7.60754 4.1679 7.2855 4.46926 7.25268 4.85318L7.24992 4.91789L7.24966 4.90753C7.24964 5.32175 7.58542 5.65755 7.99963 5.65756Z" })));
};
Info.defaultProps = defaultIconProps;
export default Info;
