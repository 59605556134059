import { useState, useLayoutEffect, useRef } from 'react';

import { onApplicationIdChange } from './eventemitter';
import removeApplicationId from './removeApplicationId';
import setApplicationId from './setApplicationId';
import getApplicationId from './getApplicationId';

interface Return {
  applicationId: string | undefined;
  setApplicationId: (applicationId: string) => Promise<void>;
  removeApplicationId: () => Promise<void>;
  isApplicationIdLoading: boolean;
}

function useApplicationId(): Return {
  const hasChangedRef = useRef(false);
  const [isApplicationIdLoading, setIsApplicationIdLoading] = useState(true);
  const [applicationId, setAppId] = useState<string | undefined>();

  useLayoutEffect(() => {
    const unsubscribe = onApplicationIdChange((newAppId) => {
      setAppId(newAppId);
      hasChangedRef.current = true;
    });
    return unsubscribe;
  }, []);

  useLayoutEffect(() => {
    getApplicationId()
      .then((applicationId) => {
        if (!hasChangedRef.current) {
          // If onApplicationIdChange is called before this promise callback,
          // don't overwrite local state.
          setAppId(applicationId || undefined);
        }
      })
      .finally(() => {
        setIsApplicationIdLoading(false);
      });
  }, []);

  return {
    applicationId,
    setApplicationId,
    removeApplicationId,
    isApplicationIdLoading,
  };
}

export default useApplicationId;
