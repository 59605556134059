import { type TAvatarProps, Avatar } from '@spotnana/blocks/src/Avatar/Avatar';
import { checkForSeat1aRestrictions, getFullAssetPath, RBAC, useFeatureFlag, userRolesByFeature } from 'obt-common';
import { useTranslation } from 'react-i18next';
import { seat1a_badge } from './index.styles';

export type UserAvatarV2Props = {
  isSeat1a?: boolean;
  avatarProps: TAvatarProps;
};

/**
 * Renders the UserAvatarV2 component
 * @returns
 */
export function UserAvatarV2({ isSeat1a = false, avatarProps }: UserAvatarV2Props) {
  const { t: tt } = useTranslation('WEB');
  const isConfigureSeat1AEnabled = !!useFeatureFlag('FE_CORP_CONFIGURE_SEAT1A');

  const getAvatar = (overrides: Partial<TAvatarProps> = {}) => {
    return <Avatar {...avatarProps} {...overrides} />;
  };

  if (isSeat1a) {
    // For Seat1A we need to show a badge on the avatar
    const seat1ABadge = isConfigureSeat1AEnabled ? undefined : (
      <img
        css={seat1a_badge}
        src={getFullAssetPath('/v1-assets/images/seat1a-logo', 'svg')}
        alt={`Seat 1A ${tt('User')}`}
        title={`Seat 1A ${tt('User')}`}
      />
    );
    return (
      <RBAC
        allowedRoles={userRolesByFeature.seat1aView}
        secondaryPredicate={checkForSeat1aRestrictions()}
        fallback={getAvatar()}
      >
        {getAvatar({ badge: seat1ABadge })}
      </RBAC>
    );
  }

  return getAvatar();
}
