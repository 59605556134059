import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Users = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 36 36" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.9056 7.3125C10.5467 7.3125 8.63392 9.22524 8.63392 11.5841C8.63392 12.568 8.9667 13.4743 9.52582 14.1966C7.54977 15.1555 6.1875 17.1811 6.1875 19.5248C6.1875 20.1461 6.69118 20.6498 7.3125 20.6498C7.93382 20.6498 8.4375 20.1461 8.4375 19.5248C8.4375 17.4984 10.0804 15.8556 12.1073 15.8556H12.8745L12.9056 15.8557L12.9366 15.8556L13.079 15.8556L13.079 15.8556L13.2413 15.8592C14.2652 15.9039 15.2075 16.3699 15.8671 17.1392C16.0052 17.3003 16.179 17.4127 16.3668 17.4748C16.4926 18.5995 16.9761 19.6159 17.7009 20.4079C15.0327 21.5116 13.1555 24.1403 13.1555 27.2075C13.1555 27.8288 13.6591 28.3325 14.2805 28.3325C14.9018 28.3325 15.4055 27.8288 15.4055 27.2075C15.4055 24.3879 17.6908 22.1023 20.5102 22.1023H21.5057L21.548 22.1024L21.5903 22.1023H22.4581L22.6086 22.1044C25.358 22.1841 27.5628 24.4384 27.5628 27.2075C27.5628 27.8288 28.0665 28.3325 28.6878 28.3325C29.3091 28.3325 29.8128 27.8288 29.8128 27.2075C29.8128 24.1757 27.9784 21.5723 25.3591 20.4466C26.2293 19.515 26.7618 18.264 26.7618 16.8886C26.7618 14.009 24.4274 11.6748 21.548 11.6748C19.3833 11.6748 17.5269 12.9937 16.7385 14.8719C16.5327 14.7098 16.3168 14.5619 16.0921 14.4289C16.767 13.6735 17.1772 12.6767 17.1772 11.5841C17.1772 9.22508 15.2648 7.3125 12.9056 7.3125ZM12.9271 13.6056C14.0338 13.5941 14.9272 12.6934 14.9272 11.5841C14.9272 10.4677 14.0221 9.5625 12.9056 9.5625C11.7893 9.5625 10.8839 10.4679 10.8839 11.5841C10.8839 12.6932 11.7777 13.5941 12.884 13.6056H12.9271ZM24.5118 16.8886C24.5118 18.5149 23.202 19.8352 21.5799 19.8523H21.5161C19.8937 19.8352 18.5842 18.5151 18.5842 16.8886C18.5842 15.2515 19.9109 13.9248 21.548 13.9248C23.1848 13.9248 24.5118 15.2517 24.5118 16.8886Z", fill: themeEnrichedPrimaryColor })));
};
Users.defaultProps = defaultIconProps;
export default Users;
