interface CountryCurrencyMapping {
  countryCode: string;
  code: string;
}

// got from here: https://gist.github.com/HarishChaudhari/4680482
export const countryCurrencyMapping: CountryCurrencyMapping[] = [
  {
    countryCode: 'NZ',
    code: 'NZD',
  },
  {
    countryCode: 'CK',
    code: 'NZD',
  },
  {
    countryCode: 'NU',
    code: 'NZD',
  },
  {
    countryCode: 'PN',
    code: 'NZD',
  },
  {
    countryCode: 'TK',
    code: 'NZD',
  },
  {
    countryCode: 'AU',
    code: 'AUD',
  },
  {
    countryCode: 'CX',
    code: 'AUD',
  },
  {
    countryCode: 'CC',
    code: 'AUD',
  },
  {
    countryCode: 'HM',
    code: 'AUD',
  },
  {
    countryCode: 'KI',
    code: 'AUD',
  },
  {
    countryCode: 'NR',
    code: 'AUD',
  },
  {
    countryCode: 'NF',
    code: 'AUD',
  },
  {
    countryCode: 'TV',
    code: 'AUD',
  },
  {
    countryCode: 'AS',
    code: 'EUR',
  },
  {
    countryCode: 'AD',
    code: 'EUR',
  },
  {
    countryCode: 'AT',
    code: 'EUR',
  },
  {
    countryCode: 'BE',
    code: 'EUR',
  },
  {
    countryCode: 'FI',
    code: 'EUR',
  },
  {
    countryCode: 'FR',
    code: 'EUR',
  },
  {
    countryCode: 'GF',
    code: 'EUR',
  },
  {
    countryCode: 'TF',
    code: 'EUR',
  },
  {
    countryCode: 'DE',
    code: 'EUR',
  },
  {
    countryCode: 'GR',
    code: 'EUR',
  },
  {
    countryCode: 'GP',
    code: 'EUR',
  },
  {
    countryCode: 'IE',
    code: 'EUR',
  },
  {
    countryCode: 'IT',
    code: 'EUR',
  },
  {
    countryCode: 'LU',
    code: 'EUR',
  },
  {
    countryCode: 'MQ',
    code: 'EUR',
  },
  {
    countryCode: 'YT',
    code: 'EUR',
  },
  {
    countryCode: 'MC',
    code: 'EUR',
  },
  {
    countryCode: 'NL',
    code: 'EUR',
  },
  {
    countryCode: 'PT',
    code: 'EUR',
  },
  {
    countryCode: 'RE',
    code: 'EUR',
  },
  {
    countryCode: 'WS',
    code: 'EUR',
  },
  {
    countryCode: 'SM',
    code: 'EUR',
  },
  {
    countryCode: 'SI',
    code: 'EUR',
  },
  {
    countryCode: 'ES',
    code: 'EUR',
  },
  {
    countryCode: 'VA',
    code: 'EUR',
  },
  {
    countryCode: 'GB',
    code: 'GBP',
  },
  {
    countryCode: 'US',
    code: 'USD',
  },
  {
    countryCode: 'HK',
    code: 'HKD',
  },
  {
    countryCode: 'CA',
    code: 'CAD',
  },
  {
    countryCode: 'JP',
    code: 'JPY',
  },
  {
    countryCode: 'AF',
    code: 'AFN',
  },
  {
    countryCode: 'AL',
    code: 'ALL',
  },
  {
    countryCode: 'DZ',
    code: 'DZD',
  },
  {
    countryCode: 'AI',
    code: 'XCD',
  },
  {
    countryCode: 'AG',
    code: 'XCD',
  },
  {
    countryCode: 'DM',
    code: 'XCD',
  },
  {
    countryCode: 'GD',
    code: 'XCD',
  },
  {
    countryCode: 'MS',
    code: 'XCD',
  },
  {
    countryCode: 'KN',
    code: 'XCD',
  },
  {
    countryCode: 'LC',
    code: 'XCD',
  },
  {
    countryCode: 'VC',
    code: 'XCD',
  },
  {
    countryCode: 'AR',
    code: 'ARS',
  },
  {
    countryCode: 'AM',
    code: 'AMD',
  },
  {
    countryCode: 'AW',
    code: 'ANG',
  },
  {
    countryCode: 'AN',
    code: 'ANG',
  },
  {
    countryCode: 'AZ',
    code: 'AZN',
  },
  {
    countryCode: 'BS',
    code: 'BSD',
  },
  {
    countryCode: 'BH',
    code: 'BHD',
  },
  {
    countryCode: 'BD',
    code: 'BDT',
  },
  {
    countryCode: 'BB',
    code: 'BBD',
  },
  {
    countryCode: 'BY',
    code: 'BYR',
  },
  {
    countryCode: 'BZ',
    code: 'BZD',
  },
  {
    countryCode: 'BJ',
    code: 'XOF',
  },
  {
    countryCode: 'BF',
    code: 'XOF',
  },
  {
    countryCode: 'GW',
    code: 'XOF',
  },
  {
    countryCode: 'CI',
    code: 'XOF',
  },
  {
    countryCode: 'ML',
    code: 'XOF',
  },
  {
    countryCode: 'NE',
    code: 'XOF',
  },
  {
    countryCode: 'SN',
    code: 'XOF',
  },
  {
    countryCode: 'TG',
    code: 'XOF',
  },
  {
    countryCode: 'BM',
    code: 'BMD',
  },
  {
    countryCode: 'BT',
    code: 'INR',
  },
  {
    countryCode: 'IN',
    code: 'INR',
  },
  {
    countryCode: 'BO',
    code: 'BOB',
  },
  {
    countryCode: 'BW',
    code: 'BWP',
  },
  {
    countryCode: 'BV',
    code: 'NOK',
  },
  {
    countryCode: 'NO',
    code: 'NOK',
  },
  {
    countryCode: 'SJ',
    code: 'NOK',
  },
  {
    countryCode: 'BR',
    code: 'BRL',
  },
  {
    countryCode: 'BN',
    code: 'BND',
  },
  {
    countryCode: 'BG',
    code: 'BGN',
  },
  {
    countryCode: 'BI',
    code: 'BIF',
  },
  {
    countryCode: 'KH',
    code: 'KHR',
  },
  {
    countryCode: 'CM',
    code: 'XAF',
  },
  {
    countryCode: 'CF',
    code: 'XAF',
  },
  {
    countryCode: 'TD',
    code: 'XAF',
  },
  {
    countryCode: 'CG',
    code: 'XAF',
  },
  {
    countryCode: 'GQ',
    code: 'XAF',
  },
  {
    countryCode: 'GA',
    code: 'XAF',
  },
  {
    countryCode: 'CV',
    code: 'CVE',
  },
  {
    countryCode: 'KY',
    code: 'KYD',
  },
  {
    countryCode: 'CL',
    code: 'CLP',
  },
  {
    countryCode: 'CN',
    code: 'CNY',
  },
  {
    countryCode: 'CO',
    code: 'COP',
  },
  {
    countryCode: 'KM',
    code: 'KMF',
  },
  {
    countryCode: 'CD',
    code: 'CDF',
  },
  {
    countryCode: 'CR',
    code: 'CRC',
  },
  {
    countryCode: 'HR',
    code: 'HRK',
  },
  {
    countryCode: 'CU',
    code: 'CUP',
  },
  {
    countryCode: 'CY',
    code: 'CYP',
  },
  {
    countryCode: 'CZ',
    code: 'CZK',
  },
  {
    countryCode: 'DK',
    code: 'DKK',
  },
  {
    countryCode: 'FO',
    code: 'DKK',
  },
  {
    countryCode: 'GL',
    code: 'DKK',
  },
  {
    countryCode: 'DJ',
    code: 'DJF',
  },
  {
    countryCode: 'DO',
    code: 'DOP',
  },
  {
    countryCode: 'TP',
    code: 'IDR',
  },
  {
    countryCode: 'ID',
    code: 'IDR',
  },
  {
    countryCode: 'EC',
    code: 'ECS',
  },
  {
    countryCode: 'EG',
    code: 'EGP',
  },
  {
    countryCode: 'SV',
    code: 'SVC',
  },
  {
    countryCode: 'ER',
    code: 'ETB',
  },
  {
    countryCode: 'ET',
    code: 'ETB',
  },
  {
    countryCode: 'EE',
    code: 'EEK',
  },
  {
    countryCode: 'FK',
    code: 'FKP',
  },
  {
    countryCode: 'FJ',
    code: 'FJD',
  },
  {
    countryCode: 'PF',
    code: 'XPF',
  },
  {
    countryCode: 'NC',
    code: 'XPF',
  },
  {
    countryCode: 'WF',
    code: 'XPF',
  },
  {
    countryCode: 'GM',
    code: 'GMD',
  },
  {
    countryCode: 'GE',
    code: 'GEL',
  },
  {
    countryCode: 'GI',
    code: 'GIP',
  },
  {
    countryCode: 'GT',
    code: 'GTQ',
  },
  {
    countryCode: 'GN',
    code: 'GNF',
  },
  {
    countryCode: 'GY',
    code: 'GYD',
  },
  {
    countryCode: 'HT',
    code: 'HTG',
  },
  {
    countryCode: 'HN',
    code: 'HNL',
  },
  {
    countryCode: 'HU',
    code: 'HUF',
  },
  {
    countryCode: 'IS',
    code: 'ISK',
  },
  {
    countryCode: 'IR',
    code: 'IRR',
  },
  {
    countryCode: 'IQ',
    code: 'IQD',
  },
  {
    countryCode: 'IL',
    code: 'ILS',
  },
  {
    countryCode: 'JM',
    code: 'JMD',
  },
  {
    countryCode: 'JO',
    code: 'JOD',
  },
  {
    countryCode: 'KZ',
    code: 'KZT',
  },
  {
    countryCode: 'KE',
    code: 'KES',
  },
  {
    countryCode: 'KP',
    code: 'KPW',
  },
  {
    countryCode: 'KR',
    code: 'KRW',
  },
  {
    countryCode: 'KW',
    code: 'KWD',
  },
  {
    countryCode: 'KG',
    code: 'KGS',
  },
  {
    countryCode: 'LA',
    code: 'LAK',
  },
  {
    countryCode: 'LV',
    code: 'LVL',
  },
  {
    countryCode: 'LB',
    code: 'LBP',
  },
  {
    countryCode: 'LS',
    code: 'LSL',
  },
  {
    countryCode: 'LR',
    code: 'LRD',
  },
  {
    countryCode: 'LY',
    code: 'LYD',
  },
  {
    countryCode: 'LI',
    code: 'CHF',
  },
  {
    countryCode: 'CH',
    code: 'CHF',
  },
  {
    countryCode: 'LT',
    code: 'LTL',
  },
  {
    countryCode: 'MO',
    code: 'MOP',
  },
  {
    countryCode: 'MK',
    code: 'MKD',
  },
  {
    countryCode: 'MG',
    code: 'MGA',
  },
  {
    countryCode: 'MW',
    code: 'MWK',
  },
  {
    countryCode: 'MY',
    code: 'MYR',
  },
  {
    countryCode: 'MV',
    code: 'MVR',
  },
  {
    countryCode: 'MT',
    code: 'MTL',
  },
  {
    countryCode: 'MR',
    code: 'MRO',
  },
  {
    countryCode: 'MU',
    code: 'MUR',
  },
  {
    countryCode: 'MX',
    code: 'MXN',
  },
  {
    countryCode: 'MD',
    code: 'MDL',
  },
  {
    countryCode: 'MN',
    code: 'MNT',
  },
  {
    countryCode: 'MA',
    code: 'MAD',
  },
  {
    countryCode: 'EH',
    code: 'MAD',
  },
  {
    countryCode: 'MZ',
    code: 'MZN',
  },
  {
    countryCode: 'MM',
    code: 'MMK',
  },
  {
    countryCode: 'NA',
    code: 'NAD',
  },
  {
    countryCode: 'NP',
    code: 'NPR',
  },
  {
    countryCode: 'NI',
    code: 'NIO',
  },
  {
    countryCode: 'NG',
    code: 'NGN',
  },
  {
    countryCode: 'OM',
    code: 'OMR',
  },
  {
    countryCode: 'PK',
    code: 'PKR',
  },
  {
    countryCode: 'PA',
    code: 'PAB',
  },
  {
    countryCode: 'PG',
    code: 'PGK',
  },
  {
    countryCode: 'PY',
    code: 'PYG',
  },
  {
    countryCode: 'PE',
    code: 'PEN',
  },
  {
    countryCode: 'PH',
    code: 'PHP',
  },
  {
    countryCode: 'PL',
    code: 'PLN',
  },
  {
    countryCode: 'QA',
    code: 'QAR',
  },
  {
    countryCode: 'RO',
    code: 'RON',
  },
  {
    countryCode: 'RU',
    code: 'RUB',
  },
  {
    countryCode: 'RW',
    code: 'RWF',
  },
  {
    countryCode: 'ST',
    code: 'STD',
  },
  {
    countryCode: 'SA',
    code: 'SAR',
  },
  {
    countryCode: 'SC',
    code: 'SCR',
  },
  {
    countryCode: 'SL',
    code: 'SLL',
  },
  {
    countryCode: 'SG',
    code: 'SGD',
  },
  {
    countryCode: 'SK',
    code: 'SKK',
  },
  {
    countryCode: 'SB',
    code: 'SBD',
  },
  {
    countryCode: 'SO',
    code: 'SOS',
  },
  {
    countryCode: 'ZA',
    code: 'ZAR',
  },
  {
    countryCode: 'LK',
    code: 'LKR',
  },
  {
    countryCode: 'SD',
    code: 'SDG',
  },
  {
    countryCode: 'SR',
    code: 'SRD',
  },
  {
    countryCode: 'SZ',
    code: 'SZL',
  },
  {
    countryCode: 'SE',
    code: 'SEK',
  },
  {
    countryCode: 'SY',
    code: 'SYP',
  },
  {
    countryCode: 'TW',
    code: 'TWD',
  },
  {
    countryCode: 'TJ',
    code: 'TJS',
  },
  {
    countryCode: 'TZ',
    code: 'TZS',
  },
  {
    countryCode: 'TH',
    code: 'THB',
  },
  {
    countryCode: 'TO',
    code: 'TOP',
  },
  {
    countryCode: 'TT',
    code: 'TTD',
  },
  {
    countryCode: 'TN',
    code: 'TND',
  },
  {
    countryCode: 'TR',
    code: 'TRY',
  },
  {
    countryCode: 'TM',
    code: 'TMT',
  },
  {
    countryCode: 'UG',
    code: 'UGX',
  },
  {
    countryCode: 'UA',
    code: 'UAH',
  },
  {
    countryCode: 'AE',
    code: 'AED',
  },
  {
    countryCode: 'UY',
    code: 'UYU',
  },
  {
    countryCode: 'UZ',
    code: 'UZS',
  },
  {
    countryCode: 'VU',
    code: 'VUV',
  },
  {
    countryCode: 'VE',
    code: 'VEF',
  },
  {
    countryCode: 'VN',
    code: 'VND',
  },
  {
    countryCode: 'YE',
    code: 'YER',
  },
  {
    countryCode: 'ZM',
    code: 'ZMK',
  },
  {
    countryCode: 'ZW',
    code: 'ZWD',
  },
  {
    countryCode: 'AX',
    code: 'EUR',
  },
  {
    countryCode: 'AO',
    code: 'AOA',
  },
  {
    countryCode: 'AQ',
    code: 'AQD',
  },
  {
    countryCode: 'BA',
    code: 'BAM',
  },
  {
    countryCode: 'CD',
    code: 'CDF',
  },
  {
    countryCode: 'GH',
    code: 'GHS',
  },
  {
    countryCode: 'GG',
    code: 'GGP',
  },
  {
    countryCode: 'LA',
    code: 'LAK',
  },
  {
    countryCode: 'MO',
    code: 'MOP',
  },
  {
    countryCode: 'ME',
    code: 'EUR',
  },
  {
    countryCode: 'PS',
    code: 'JOD',
  },
  {
    countryCode: 'BL',
    code: 'EUR',
  },
  {
    countryCode: 'MF',
    code: 'ANG',
  },
  {
    countryCode: 'PM',
    code: 'EUR',
  },
  {
    countryCode: 'RS',
    code: 'RSD',
  },
  {
    countryCode: 'CN',
    code: 'RMB',
  },
];

export const currencyToCountryMap = countryCurrencyMapping.reduce(
  (acc, item) => ({
    ...acc,
    [item.code]: item.countryCode,
  }),
  {} as Record<string, string>,
);
