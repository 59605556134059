import { createUserNameFromFullName } from '../utils';
import type { IUserListItem, ListUsersResponse } from '../types';

export default class UserListResponseManager {
  static getUsersFromResponse = (
    response: ListUsersResponse | undefined,
    usePreferredName?: boolean,
  ): IUserListItem[] => {
    if (!response) {
      return [];
    }

    const { userInfos } = response;

    return (
      userInfos?.map((user) => {
        const basicUserInfo = user.userBasicInfo;
        const userOrgId = basicUserInfo?.userOrgId;
        const name = basicUserInfo?.name;

        return {
          department: user.department,
          userOrgId,
          fullname: createUserNameFromFullName(name, undefined, {
            usePreferredName,
            useLegalPreferredName: true,
          }),
          persona: basicUserInfo.persona,
          office: user.office,
          email: user.userBasicInfo?.email || '',
          title: user.title,
          role: user.role,
          tier: basicUserInfo.tier,
        } as IUserListItem;
      }) || []
    );
  };
}
