import { Button } from '@spotnana/blocks/src/Button';
import { Typography } from '@spotnana/blocks/src/Typography';
import { AgentAnnouncement } from '@spotnana/types/openapi/models/agent-announcement';
import { AgentAnnouncementStatus } from '@spotnana/types/openapi/models/agent-announcement-status';
import { useListAnnouncements } from 'obt-common';
import { UserBasicInfo } from 'obt-common/types/api/v1/obt/common/user_basic_info';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AnnouncementCard from './Announcement';
import {
  announcements,
  announcements_ctr,
  announcements_header,
  no_announcements_ctr,
} from './AnnouncementsVertical.styles';

// AnnouncementsVertical component displays a vertical list of recent announcements
const AnnouncementsVertical = (): JSX.Element => {
  // Translation hook to get translation functions
  const { t: tt } = useTranslation('WEB');

  // Fetch announcements data using the useListAnnouncements hook
  const { data, isLoading } = useListAnnouncements({
    paginationParams: { pageNumber: 1, pageSize: 5 },
    filter: { statuses: [AgentAnnouncementStatus.Active, AgentAnnouncementStatus.Pinned] },
  });

  const announcementsExists = (data?.announcements && data?.announcements?.length > 0) || isLoading;

  return (
    // Container for the AnnouncementsVertical component
    <div css={announcements_ctr}>
      {/* Announcement Section */}
      <div>
        {/* Header Section */}
        <div css={announcements_header}>
          {/* Title of the Announcements Section */}
          <Typography variant="header4" kind="semibold">
            {tt('Recent announcements')}
          </Typography>
        </div>
        {/* Vertical List of Announcements */}
        <div css={announcements} className="Vertical">
          {!announcementsExists && (
            <Typography variant="header5" kind="medium" css={no_announcements_ctr}>
              {tt('No new announcements lately')}
            </Typography>
          )}
          {/* Map through the announcements and render each AnnouncementCard */}
          {data?.announcements.map(
            (announcement: AgentAnnouncement) =>
              announcement.createdBy &&
              announcement.updatedAt && (
                <AnnouncementCard
                  key={announcement.id}
                  title={announcement.content.title}
                  description={announcement.content.body}
                  author={announcement.createdBy as UserBasicInfo}
                  documentLink={announcement.content.link}
                  updatedAt={announcement.updatedAt}
                  layout="vertical"
                />
              ),
          )}
        </div>
      </div>
      {/* "View All" Button */}
      <Link to="/spotternet/announcements">
        <Button data-testid="booking-item-action" variant="outline" size="small" fullWidth>
          {tt('View all')}
        </Button>
      </Link>
    </div>
  );
};

export default AnnouncementsVertical;
