import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Misc = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 4", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M8.66797 1.99967C8.66797 1.08301 7.91797 0.333008 7.0013 0.333008C6.08464 0.333008 5.33464 1.08301 5.33464 1.99967C5.33464 2.91634 6.08464 3.66634 7.0013 3.66634C7.91797 3.66634 8.66797 2.91634 8.66797 1.99967ZM13.668 1.99967C13.668 1.08301 12.918 0.333008 12.0013 0.333008C11.0846 0.333008 10.3346 1.08301 10.3346 1.99967C10.3346 2.91634 11.0846 3.66634 12.0013 3.66634C12.918 3.66634 13.668 2.91634 13.668 1.99967ZM3.66797 1.99967C3.66797 1.08301 2.91797 0.333008 2.00131 0.333008C1.08464 0.333008 0.334641 1.08301 0.334641 1.99967C0.334641 2.91634 1.08464 3.66634 2.00131 3.66634C2.91797 3.66634 3.66797 2.91634 3.66797 1.99967Z", fill: themeEnrichedPrimaryColor })));
};
Misc.defaultProps = defaultIconProps;
export default Misc;
