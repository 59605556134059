import type { AirlineInfoResponse } from '@spotnana/types/openapi/models';
import uniqWith from 'lodash/uniqWith';
import type { ILabelValuePair } from '../types';
import type { IAirlinesInfoCompleteMap, IAirlinesNameMap } from '../types/Flights/airlinesInfo';

export const getAirlinesNameMapFromAirlinesInfo = (airlinesInfo: AirlineInfoResponse): IAirlinesNameMap => {
  return airlinesInfo.airlineInfo.reduce((acc, airline) => {
    acc[airline.airlineCode] = airline.airlineName;
    return acc;
  }, {} as IAirlinesNameMap);
};

export const getAirlinesInfoCompleteMapFromAirlinesInfo = (
  airlinesInfo: AirlineInfoResponse,
): IAirlinesInfoCompleteMap => {
  return airlinesInfo.airlineInfo.reduce((acc, airline) => {
    acc[airline.airlineCode] = airline;
    return acc;
  }, {} as IAirlinesInfoCompleteMap);
};

export const getAirlinesSelectOptionsFromAirlinesInfo = (airlinesInfo: AirlineInfoResponse): ILabelValuePair[] => {
  return uniqWith(
    airlinesInfo.airlineInfo.map((airline) => ({
      value: airline.airlineCode,
      label: airline.airlineName,
    })),
    (curr, next) => curr.label === next.label || curr.value === next.value,
  );
};
