import { Config } from '../../utils';

export const getBuildAttributes = () => {
  const isEmbed = Config.VITE_TARGET === 'embed';

  return {
    platform: 'web',
    appType: isEmbed ? 'embedded' : 'standalone',
  };
};
