import { MealType, Cabin, Alliance } from '../air/air_common';
import { PreferredLocationLabel } from './common';

export interface AirPref {
  homeAirport: string;
  airlinePrefs: AirlinePref[];
  farePref?: FarePref;
  seatLocationPrefs: SeatLocationPref[];
  seatAmenityPref?: SeatAmenityPref;
  mealPref?: AirPrefMealPref;
  alliancePref?: AlliancePref;
  /**
   *  Wrapper Types implies checking whether it is set or not before using
   */
  numStopPref?: NumStopsPref;
  preferredAirports?: PreferredAirport[];
}

export interface AirPrefMealPref {
  meal?: MealType; // deprecated
  specialMealDescription?: string;
  inclMealPrefs?: MealType[];
  exclMealPrefs?: MealType[];
}

export interface NumStopsPref {
  numOfStops?: number;
}

export interface SeatAmenityPref {
  seatAmenityTypes: SeatAmenityPrefAirSeatAmenityType[];
}

export interface FarePref {
  fairType?: FarePrefFareType; // deprecated
  fareTypes: FarePrefFareType[];
}

export interface SeatLocationPref {
  /**
   *  Time below which duration will be considered short
   */
  maxFlightDuration?: number; // deprecated
  position: SeatLocationPrefPosition;
  cabin?: Cabin; // deprecated
  maxFlightDurationInHours: number;
  isBulkHeadPref: boolean;
  cabins: Cabin[];
}

export interface AlliancePref {
  alliances: Alliance[];
}

export interface AirlinePref {
  flightType: AirlinePrefFlightType;
  alliance?: Alliance; // deprecated
  airlines: string[];
}

export enum SeatAmenityPrefAirSeatAmenityTypeEnum {
  UNKNOWN_AIR_SEAT_AMENITY_TYPE = 0,
  FLAT_BED = 1,
  WIFI = 2,
  IN_SEAT_POWER = 3,
  UNRECOGNIZED = -1,
}

export type SeatAmenityPrefAirSeatAmenityType = 0 | 1 | 2 | 3 | -1;

export enum FarePrefFareTypeEnum {
  UNKNOWN_TYPE = 0,
  CHEAPEST = 1,
  FULLY_REFUNDABLE = 2,
  EDITABLE = 3,
  CHANGEABLE = 4,
  REFUNDABLE = 5,
  UNRECOGNIZED = -1,
}

export type FarePrefFareType = 0 | 1 | 2 | 3 | 4 | 5 | -1;

export enum SeatLocationPrefPositionEnum {
  UNKNOWN_POSITION = 0,
  AISLE = 1,
  WINDOW = 2,
  AISLE_OR_WINDOW = 3,
  UNRECOGNIZED = -1,
}

export type SeatLocationPrefPosition = 0 | 1 | 2 | 3 | -1;

export enum AirlinePrefFlightTypeEnum {
  UNKNOWN_FLIGHT_TYPE = 0,
  DOMESTIC = 1,
  INTERNATIONAL = 2,
  ALL = 3,
  UNRECOGNIZED = -1,
}

export type AirlinePrefFlightType = 0 | 1 | 2 | 3 | -1;

export interface PreferredAirport {
  /** Name of airport. */
  airportName: string;
  /** IATA code of airport, e.g. SFO. */
  airportCode: string;
  /** Label of preferred airport, e.g. Home, Work. */
  label: PreferredLocationLabel;
}
