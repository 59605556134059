/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Commission1 } from './commission1';
import { DateTimeLocal } from './date-time-local';
import { ExchangeInfo } from './exchange-info';
import { FareAmount } from './fare-amount';
import { FlightCoupon } from './flight-coupon';
import { LLFInfo } from './llfinfo';
import { PaymentDetails } from './payment-details';
import { PreBookAnswers } from './pre-book-answers';
import { RefundInfo } from './refund-info';
import { TicketAncillary } from './ticket-ancillary';
import { TicketRefundPolicy } from './ticket-refund-policy';
import { PnrTaxBreakdown } from './pnr-tax-breakdown';
import { TicketExchangePolicy } from './ticket-exchange-policy';

/**
 * Air ticket info
 * @export
 * @interface Ticket
 */
export interface Ticket {
  /**
   * Ticket number
   * @type {string}
   * @memberof Ticket
   */
  ticketNumber: string;
  /**
   * Ticket type
   * @type {string}
   * @memberof Ticket
   */
  ticketType?: TicketTicketTypeEnum;
  /**
   *
   * @type {DateTimeLocal}
   * @memberof Ticket
   */
  issuedDateTime?: DateTimeLocal;
  /**
   * Ticket status
   * @type {string}
   * @memberof Ticket
   */
  status?: TicketStatusEnum;
  /**
   *
   * @type {FareAmount}
   * @memberof Ticket
   */
  amount?: FareAmount;
  /**
   * List of flight coupons in this ticket
   * @type {Array<FlightCoupon>}
   * @memberof Ticket
   */
  flightCoupons?: Array<FlightCoupon>;
  /**
   * List of ancillaries in this ticket
   * @type {Array<TicketAncillary>}
   * @memberof Ticket
   */
  ancillaries?: Array<TicketAncillary>;
  /**
   * 2 letter IATA airline code for the validating/ticketing airline
   * @type {string}
   * @memberof Ticket
   */
  validatingAirlineCode?: string;
  /**
   *
   * @type {ExchangeInfo}
   * @memberof Ticket
   */
  exchangeInfo?: ExchangeInfo;
  /**
   *
   * @type {TicketRefundPolicy}
   * @memberof Ticket
   */
  refundPolicy?: TicketRefundPolicy;
  /**
   *
   * @type {TicketExchangePolicy}
   * @memberof Ticket
   */
  exchangePolicy?: TicketExchangePolicy;
  /**
   *
   * @type {RefundInfo}
   * @memberof Ticket
   */
  refundInfo?: RefundInfo;
  /**
   *
   * @type {LLFInfo}
   * @memberof Ticket
   */
  llfInfo?: LLFInfo;
  /**
   *
   * @type {PnrTaxBreakdown}
   * @memberof Ticket
   */
  taxBreakdown?: PnrTaxBreakdown;
  /**
   *
   * @type {Commission1}
   * @memberof Ticket
   */
  commission?: Commission1;
  /**
   * Iata number
   * @type {string}
   * @memberof Ticket
   */
  iataNumber?: string;
  /**
   * Fare calculation line
   * @type {string}
   * @memberof Ticket
   */
  fareCalculation?: string;
  /**
   *
   * @type {DateTimeLocal}
   * @memberof Ticket
   */
  updateDateTime?: DateTimeLocal;
  /**
   * List of payment details for this ticket
   * @type {Array<PaymentDetails>}
   * @memberof Ticket
   */
  paymentDetails?: Array<PaymentDetails>;
  /**
   * Ticket settlement
   * @type {string}
   * @memberof Ticket
   */
  ticketSettlement?: TicketTicketSettlementEnum;
  /**
   * cancellation id sent by the supplier.
   * @type {string}
   * @memberof Ticket
   */
  vendorCancellationId?: string;
  /**
   *
   * @type {PreBookAnswers}
   * @memberof Ticket
   */
  preBookAnswers?: PreBookAnswers;
}

/**
 * @export
 * @enum {string}
 */
export enum TicketTicketTypeEnum {
  UnknownTicketType = 'UNKNOWN_TICKET_TYPE',
  Flight = 'FLIGHT',
  Ancillary = 'ANCILLARY',
  Mixed = 'MIXED',
}
/**
 * @export
 * @enum {string}
 */
export enum TicketStatusEnum {
  Unknown = 'UNKNOWN',
  Issued = 'ISSUED',
  Voided = 'VOIDED',
  Refunded = 'REFUNDED',
  Exchanged = 'EXCHANGED',
}
/**
 * @export
 * @enum {string}
 */
export enum TicketTicketSettlementEnum {
  UnknownTicketSettlement = 'UNKNOWN_TICKET_SETTLEMENT',
  ArcTicket = 'ARC_TICKET',
  BspTicket = 'BSP_TICKET',
  NonArcBspTicket = 'NON_ARC_BSP_TICKET',
}
