import { forwardRef } from 'react';
import type { FieldValues, Control, RefCallBack } from 'rhf-v7';
import { useFormState } from 'rhf-v7';
import { Input, TInputProps } from '../Input';

type TTextAreaFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  control: Control<TFieldValues>;
} & TInputProps;
function TextAreaFieldInternal<TFieldValues extends FieldValues = FieldValues>(
  { control, ...props }: TTextAreaFieldProps<TFieldValues>,
  ref: RefCallBack | React.ForwardedRef<HTMLInputElement>,
) {
  const { errors } = useFormState({ control });
  const fieldError = errors[props.name as keyof TFieldValues]?.message as string;
  return <Input state={fieldError ? 'error' : undefined} helperText={fieldError} ref={ref} multiline {...props} />;
}

export const TextAreaField = forwardRef(TextAreaFieldInternal) as <TFieldValues extends FieldValues = FieldValues>(
  props: TTextAreaFieldProps<TFieldValues> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof TextAreaFieldInternal>;
