/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Room options and stay preference.
 * @export
 * @interface RoomPreference
 */
export interface RoomPreference {
  /**
   * Whether or not mobility accessible room, tub.
   * @type {boolean}
   * @memberof RoomPreference
   */
  'isMobilityAccessible'?: boolean;
  /**
   * The number of bed in the room.
   * @type {string}
   * @memberof RoomPreference
   */
  'bedCount'?: RoomPreferenceBedCountTypeEnum;
  /**
   * Single selection of type of room.
   * @type {string}
   * @memberof RoomPreference
   */
  'roomType'?: RoomPreferenceRoomTypeEnum;
  /**
   * Single selection of the most import fact.
   * @type {string}
   * @memberof RoomPreference
   */
  'mostImportantFact'?: RoomPreferenceMostImportantFactEnum;
  /**
   * Location of the hotel room
   * @type {string}
   * @memberof RoomPreference
   */
  'roomLocation'?: RoomPreferenceRoomLocationEnum;
  /**
   * The type of pillow in hotel room.
   * @type {string}
   * @memberof RoomPreference
   */
  'pillowType'?: RoomPreferencePillowTypeEnum;
  /**
   * 
   * @type {Array<string>}
   * @memberof RoomPreference
   */
  'roomAmenityPrefs'?: Array<RoomPreferenceRoomAmenityPrefsEnum>;
}

export const RoomPreferenceBedCountTypeEnum = {
  OneBed: 'ONE_BED',
  TwoBeds: 'TWO_BEDS'
} as const;

export type RoomPreferenceBedCountTypeEnum = typeof RoomPreferenceBedCountTypeEnum[keyof typeof RoomPreferenceBedCountTypeEnum];
export const RoomPreferenceRoomTypeEnum = {
  Smoking: 'SMOKING',
  NonSmoking: 'NON_SMOKING'
} as const;

export type RoomPreferenceRoomTypeEnum = typeof RoomPreferenceRoomTypeEnum[keyof typeof RoomPreferenceRoomTypeEnum];
export const RoomPreferenceMostImportantFactEnum = {
  RoomType: 'ROOM_TYPE',
  BedCount: 'BED_COUNT',
  RoomLocation: 'ROOM_LOCATION'
} as const;

export type RoomPreferenceMostImportantFactEnum = typeof RoomPreferenceMostImportantFactEnum[keyof typeof RoomPreferenceMostImportantFactEnum];
export const RoomPreferenceRoomLocationEnum = {
  HighFloor: 'HIGH_FLOOR',
  LowFloor: 'LOW_FLOOR',
  NearElevator: 'NEAR_ELEVATOR'
} as const;

export type RoomPreferenceRoomLocationEnum = typeof RoomPreferenceRoomLocationEnum[keyof typeof RoomPreferenceRoomLocationEnum];
export const RoomPreferencePillowTypeEnum = {
  Foam: 'FOAM',
  ExtraFoam: 'EXTRA_FOAM',
  ExtraFeather: 'EXTRA_FEATHER'
} as const;

export type RoomPreferencePillowTypeEnum = typeof RoomPreferencePillowTypeEnum[keyof typeof RoomPreferencePillowTypeEnum];
export const RoomPreferenceRoomAmenityPrefsEnum = {
  FeatherFreeRoom: 'FEATHER_FREE_ROOM',
  ExtraTowels: 'EXTRA_TOWELS',
  Refrigerator: 'REFRIGERATOR'
} as const;

export type RoomPreferenceRoomAmenityPrefsEnum = typeof RoomPreferenceRoomAmenityPrefsEnum[keyof typeof RoomPreferenceRoomAmenityPrefsEnum];