import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const DoubleBed = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 79 79", fill: "none" },
        React.createElement("path", { d: "M76.531 53.078h-1.234v-9.875a4.944 4.944 0 00-4.938-4.937V17.28a4.944 4.944 0 00-4.937-4.937H13.578a4.944 4.944 0 00-4.937 4.937v20.985a4.944 4.944 0 00-4.938 4.937v9.875H2.47a1.234 1.234 0 00-1.235 1.234v4.938a1.234 1.234 0 001.235 1.234h1.234v4.938a1.234 1.234 0 001.235 1.234H8.64a1.232 1.232 0 001.21-.992l1.036-5.18h57.226l1.037 5.18a1.233 1.233 0 001.21.992h3.703a1.234 1.234 0 001.234-1.234v-4.938h1.234a1.235 1.235 0 001.235-1.234v-4.938a1.234 1.234 0 00-1.235-1.234zM11.11 17.281a2.472 2.472 0 012.47-2.468h51.843a2.472 2.472 0 012.469 2.468v20.985h-2.47v-4.938a4.944 4.944 0 00-4.937-4.937H45.672a4.944 4.944 0 00-4.938 4.937v4.938h-2.468v-4.938a4.944 4.944 0 00-4.938-4.937H18.516a4.944 4.944 0 00-4.938 4.937v4.938H11.11V17.28zm51.844 16.047v4.938h-19.75v-4.938a2.472 2.472 0 012.469-2.469h14.812a2.472 2.472 0 012.47 2.47zm-27.156 0v4.938h-19.75v-4.938a2.472 2.472 0 012.469-2.469h14.812a2.471 2.471 0 012.469 2.47zM6.172 43.203a2.472 2.472 0 012.469-2.469h61.718a2.472 2.472 0 012.47 2.47v9.874H6.171v-9.875zm1.456 20.984H6.172v-3.703h2.197l-.74 3.703zm65.2 0h-1.456l-.741-3.703h2.197v3.703zm2.469-6.171H3.703v-2.47h71.594v2.47z", fill: themeEnrichedPrimaryColor })));
};
DoubleBed.defaultProps = defaultIconProps;
export default DoubleBed;
