import { css } from '@emotion/react';
import { flex } from '../../utils';
import { paddings } from '../../utils/styling/utilityTokens';

export const sticky_header = css`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  ${paddings.x.comfortable}
  ${paddings.y.comfortable}
`;

export const header_container = css`
  ${flex.init}
  ${flex.align.center}
  ${flex.justify.between}
`;
