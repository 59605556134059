import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Policy = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 24 24" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M5.865 19.667v-.016c-1.265-.14-2.257-1.195-2.301-2.486l-.002-.09V15.85c0-.363.28-.66.636-.69l.056-.002h1.549l.062-8.808c0-1.06.833-1.933 1.885-2.01l.151-.006h10.323c1.042.098 1.873.96 1.914 2.021l.002.083v2.252c0 .363-.28.66-.635.69l-.057.002h-2.132v7.695c0 1.37-1.067 2.494-2.414 2.585l-.088.004h-.02l.001.002h-8.93zm6.27-3.127h-7.19v.536c0 .62.47 1.132 1.073 1.2l.067.006.068.001 6.28.001c-.177-.336-.283-.716-.297-1.12l-.002-.088v-.535zM16.06 5.717H7.902c-.328 0-.6.237-.646.544l-.007.094-.063 8.803h5.64c.363 0 .66.28.69.635l.002.057v1.227c0 .665.542 1.207 1.207 1.207.643 0 1.17-.505 1.206-1.139l.002-.068V6.436c0-.252.045-.495.127-.72zm-1.788 7.85l.04.002c.155.02.276.153.276.314 0 .14-.091.26-.219.302l-.067.014-.04.001H8.642l-.04-.002c-.156-.02-.277-.153-.277-.315 0-.14.092-.26.22-.301l.066-.014.04-.001h5.622zm0-2.017l.04.002c.155.02.276.153.276.315 0 .14-.091.26-.219.301l-.067.014-.04.001H8.642l-.04-.002c-.156-.02-.277-.153-.277-.314 0-.14.092-.26.22-.302l.066-.014.04-.001h5.622zm0-2.017l.04.002c.155.02.276.153.276.315 0 .14-.091.26-.219.301l-.067.014-.04.002H8.642l-.04-.003c-.156-.02-.277-.153-.277-.314 0-.14.092-.26.22-.302l.066-.014.04-.001h5.622zm3.764-4.19c-.563 0-1.029.427-1.088.975l-.007.12v1.935h2.19V6.437l-.001-.065c-.034-.574-.511-1.03-1.094-1.03zm-6.79 2.173l.04.002c.156.02.277.153.277.315 0 .14-.092.26-.22.301l-.066.014-.04.002H8.641l-.04-.003c-.156-.02-.277-.153-.277-.314 0-.14.092-.26.22-.302l.066-.014.04-.001h2.596z" })));
};
Policy.defaultProps = defaultIconProps;
export default Policy;
