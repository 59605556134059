import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Pending = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 14", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 7A7 7 0 110 7a7 7 0 0114 0zm-7 5A5 5 0 107 2a5 5 0 000 10z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M7 10.333a3.333 3.333 0 000-6.667v6.667z", fill: themeEnrichedPrimaryColor })));
};
Pending.defaultProps = defaultIconProps;
export default Pending;
