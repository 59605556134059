import isSameOrBefore from './isSameOrBefore';
import getTodayDateTimeInTimezone from './getTodayDateTimeInTimezone';

const isPastTimeWithTimezone = (date: string, format: string, timezone: string): boolean => {
  const isValid = Boolean(date && timezone && format);

  if (!isValid) return false;

  const today = getTodayDateTimeInTimezone(timezone, format);

  // TODO: This returns true when comparing the same day, which is not expected
  // because it's not actually past today according to this function. Use
  // isBefore instead but verify any potential regression
  return isSameOrBefore(date, today, format);
};

export default isPastTimeWithTimezone;
