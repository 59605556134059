var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const AdditionalLuggage = (_a) => {
    var { primaryColor, width, height, secondaryColor } = _a, restProps = __rest(_a, ["primaryColor", "width", "height", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", height: height, width: width, viewBox: "0 0 32 32", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 6.333a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 .374 1.4v5.623h.27a3.5 3.5 0 0 1 3.5 3.5V24a3.5 3.5 0 0 1-3.447 3.5v.041a1 1 0 0 1-1.997.075l-.003-.075V27.5h-4v.041a1 1 0 0 1-1.997.075l-.003-.075V27.5A3.5 3.5 0 0 1 7 24v-7.894a3.5 3.5 0 0 1 3.447-3.5V7.019A.75.75 0 0 1 10 6.333Zm7.124.75v5.523h-5.677V7.083h5.677Zm1.27 7.523H10.5a1.5 1.5 0 0 0-1.5 1.5V24a1.5 1.5 0 0 0 1.5 1.5h7.894a1.5 1.5 0 0 0 1.5-1.5v-7.894a1.5 1.5 0 0 0-1.5-1.5Zm-.623 5.943a.5.5 0 0 0-.058-.996h-6.532l-.058.003a.5.5 0 0 0 .058.997h6.532l.058-.004Zm.442 2.504a.5.5 0 0 1-.442.496l-.058.004h-6.532a.5.5 0 0 1-.058-.997l.058-.003h6.532a.5.5 0 0 1 .5.5Zm-.442-5.504a.5.5 0 0 0-.058-.996h-6.532l-.058.003a.5.5 0 0 0 .058.997h6.532l.058-.004Zm3.893-7.72a.75.75 0 0 1 .75-.75h2.079V7a.75.75 0 0 1 1.5 0v2.078h2.078a.75.75 0 0 1 0 1.5h-2.078v2.079a.75.75 0 1 1-1.5 0v-2.079h-2.079a.75.75 0 0 1-.75-.75Z" })));
};
AdditionalLuggage.defaultProps = defaultIconProps;
export default AdditionalLuggage;
