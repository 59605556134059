import produce from 'immer';
import first from 'lodash/first';
import invert from 'lodash/invert';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';

import { emptyPolicy, emptyEmployeePolicyState, emptyNonEmployeePolicyState } from '../../constants/admin';
import { getProfileEccKeyFromString } from '../../constants/admin/ecc';
import { countryCodeMapper } from '../../constants/countryList';
import { emptyName, emptyPhoneNumber, emptyTravelerAddress } from '../../constants/traveler';
import ReadOrganizationNodeResponseManager from '../../services/ReadOrganizationNodeResponseManager';
import type {
  CreatePccRequest,
  IAutocompleteSuggestion,
  ICabin,
  ICarEngineType,
  ICarType,
  IOffice,
  IPostalAddress,
  ISearchTravelerResponseTravelerSearchResult,
  ITraveler,
  IUserOrgId,
  ListUsersRequest,
  ReadPccResponse,
  SupplierType,
  Tier,
  UpdatePccRequest,
} from '../../types';
import type {
  CarCode,
  CustomizedEmployeeRoleType,
  FeatureFlagConfiguration,
  FlightTicketsChangeablePropsType,
  FlightTicketsRefundablePropsType,
  IApproverInfo,
  ICompanyAuthentication,
  ICostCenter,
  IDepartment,
  IEmployee,
  IHotelCancellationPropsType,
  ILegalEntity,
  ILegalEntityId,
  INewUserData,
  IOrganization,
  IOrganizationId,
  IOrganizationNode,
  IOrgOverview,
  IPccDataState,
  IPolicy,
  IPolicyLinkingState,
  IPolicyState,
  IPolicyUserGroup,
  IPolicyUserGroupConditionItem,
  IReasonCode,
  ISabreInfo,
  ISelectWithLabelsDataItem,
  IThirdPartyDetails,
  SplitPaymentInfoConfig,
  UserListFilters,
  VPayCarIdNumber,
} from '../../types/admin';
import {
  CustomRoleInfoType,
  LowestLogisticalFarePropsCarrierType,
  NewUserTypeEnum,
  PolicyTypeEnum,
  policyUserGroupConditionTypeKeysEnum,
} from '../../types/admin';
import type { Cabin } from '../../types/api/v1/obt/air/air_common';
import type { Employee } from '../../types/api/v1/obt/common/employee';
import type { Organization, OrganizationNode } from '../../types/api/v1/obt/common/organization';
import type { TravelerPersonalInfo, TravelPref } from '../../types/api/v1/obt/common/traveler_personal_info';
import type { User } from '../../types/api/v1/obt/common/user';
import type { UserBusinessInfo } from '../../types/api/v1/obt/common/user_business_info';
import { PersonaEnum } from '../../types/api/v1/obt/common/user_org_id';
import { InternalPolicyType } from '../../types/api/v1/obt/policy/internal_policy';
import type {
  PolicyApprovalInfo,
  PolicyHotelMedianRate,
  PolicyLowestLogicalFare,
  PolicyOopReasonCodes,
  PolicyUniversalRule,
} from '../../types/api/v1/obt/policy/policy';
import { PolicyApprovalInfoApprovalProcessType } from '../../types/api/v1/obt/policy/policy';
import { PolicyCategory, PolicyRuleType } from '../../types/api/v1/obt/policy/policy_common';
import { MaxHotelBookingPricePropsType } from '../../types/api/v1/obt/policy/policy_rules';
import type {
  HighestFlightCabinByDuration,
  HighestFlightCabinByDurationProps,
  HighestFlightCabinByDurationPropsHighestCabinForDuration,
  HighestFlightCabinByDurationPropsHighestCabinForMileage,
  HotelMedianRateProps,
  MaxFlightBookingPriceByDurationProps,
  MaxRailBookingPriceByDurationProps,
  OvernightTimeParams,
  TimeLocal,
} from '../../types/api/v1/obt/policy/policy_rules';
import type { CreatePolicyRequest, UpdatePolicyRequest } from '../../types/api/v1/obt/policy/policy_service';
import {
  CustomFieldType,
  QuestionFormat,
  UserDefinedEntitySource,
} from '../../types/api/v1/obt/policy/user_defined_entity';
import type { UpdateEmployeeRequest, UpdateEmployeeRequestType } from '../../types/api/v1/obt/profile/profile_services';
import { UpdateEmployeeRequestTypeEnum } from '../../types/api/v1/obt/profile/profile_services';
import type { RoleInfo } from '../../types/api/v1/obt/profile/role/roles_info';
import { RoleInfoTypeEnum } from '../../types/api/v1/obt/profile/role/roles_info';
import type { UpdateUserRolesRequest } from '../../types/api/v1/obt/profile/role/role_services';
import type { TravelClass } from '../../types/api/v1/obt/rail/rail_common';
import type {
  OrganizationThirdPartyInfo,
  OrganizationThirdPartyInfoSabreInfoCode,
  OrganizationThirdPartyInfoSabreInfoPcc,
} from '../../types/api/v1/obt/supplier/third_party_info';
import type { LegalEntityCreateRequest } from '../../types/api/v2/obt/model/legal-entity-create-request';
import type { PccCountry } from '../../types/api/v2/obt/model/pcc-country';
import type { PccCurrency } from '../../types/api/v2/obt/model/pcc-currency';
import type { TravelType } from '../../types/api/v2/obt/model/travel-type';
import {
  UserListDisplaySortOptionSortByEnum,
  UserListDisplaySortOptionSortOrderEnum,
} from '../../types/api/v2/obt/model/user-list-display-sort-option';
import { ConversionSupportedCurrencyCodesWithCurrencyNameAndSymbol, removeEmptyValuesFromObject } from '../../utils';
import { mapPhoneNumberFromV1ToV2 } from '../../utils/Phone';
import { Persona } from '../../types/api/v2/obt/model/persona';
import { TravelRegionType } from '../../types/api/v1/obt/common/travel_region';
import { GenderEnum } from '../../types/api/v1/common/gender';
import { getPolicyUserGroups } from './getPolicyUserGroups';

export const getUpdateOrganizationRequest = (
  { companyName, billingCurrency, emailDomains, emergencyContacts, ccEmails, organizationLogo, isFake }: IOrgOverview,
  organizationData: OrganizationNode,
): Organization => {
  const { organization } = organizationData;
  if (organization) {
    return {
      ...organization,
      name: companyName,
      billingCurrency,
      emailDomains,
      emergencyContactInfos: emergencyContacts,
      ccEmails,
      organizationLogo,
      isFake,
    };
  }
  return {
    name: companyName,
    billingCurrency,
    emailDomains,
    phoneNumbers: [],
    emergencyContactInfos: emergencyContacts,
    ccEmails,
    organizationLogo,
    isFake,
  };
};

export const getThirdPartyCodeObject = (
  airClid3: string[][],
  airClid4: string[],
  airTourCode: string[][],
  hotelRateCode: string[],
  airCtid: string[][],
  carCodes: CarCode[],
  vPayCarIdNumbers: VPayCarIdNumber[],
): OrganizationThirdPartyInfoSabreInfoCode[] => {
  const codes: OrganizationThirdPartyInfoSabreInfoCode[] = [];
  airClid3?.forEach((code) => {
    if (code[0]) {
      codes.push({
        airClid3: code[0],
        validatingAirlines: [code[1]],
        carVendors: [],
      });
    }
  });

  airClid4?.forEach((code) => {
    if (code) {
      codes.push({
        airClid4: code,
        validatingAirlines: [],
        carVendors: [],
      });
    }
  });

  airTourCode?.forEach((code) => {
    if (code[0]) {
      codes.push({
        airTourCode: code[0],
        validatingAirlines: [code[1]],
        carVendors: [],
      });
    }
  });

  hotelRateCode?.forEach((code) => {
    if (code) {
      codes.push({
        hotelRateCode: code,
        validatingAirlines: [],
        carVendors: [],
      });
    }
  });

  airCtid?.forEach((code) => {
    if (code[0]) {
      codes.push({
        airCtid: code[0],
        validatingAirlines: [code[1]],
        carVendors: [],
      });
    }
  });

  carCodes?.forEach((code) => {
    const { vendorCode, vendorName, isVirtualPayOnly } = code;

    if (vendorCode) {
      codes.push({
        carCorporateCode: vendorCode,
        validatingAirlines: [],
        carVendors: [vendorName],
        virtualPaymentsOnly: isVirtualPayOnly,
      });
    }
  });

  vPayCarIdNumbers?.forEach((code) => {
    const { vPayIdNumber, vendorName, isVirtualPayOnly } = code;

    if (vPayIdNumber) {
      codes.push({
        validatingAirlines: [],
        carVendors: [vendorName],
        vpayCarIdNumber: vPayIdNumber,
        virtualPaymentsOnly: isVirtualPayOnly,
      });
    }
  });

  return codes;
};

function getBasePolicyApprovalInfo(policy: IPolicyState) {
  return {
    designatedApprovers: policy.policyApproverIsManager ? [] : policy.policyApprover,
    isManagerApprover: policy.policyApproverIsManager,
    isEmployeeLevelDesignatedApprover: policy.policyApproverIsEmployeeLevel,
    shouldNotifyManager: policy.shouldNotifyManager,
    defaultApprovers: policy.policyApproverIsManager ? policy.policyDefaultApprover : [],
  };
}

export const getUpdateThirdPartyRequest = ({
  general,
  pnrRemarks,
  l1Remarks,
  airClid3,
  airClid4,
  airTourCode,
  hotelRateCode,
  airCtid,
  carCodes,
  vPayCarIdNumbers,
}: IThirdPartyDetails): OrganizationThirdPartyInfo => {
  const pcc: OrganizationThirdPartyInfoSabreInfoPcc = {
    countries: [],
    pcc: general.pcc,
  };
  const sabreInfo: ISabreInfo = {
    dkNumber: general.dkNumber,
    templateId: general.templateId,
    filterId: general.filterId,
    pccs: pcc.pcc === '' ? [] : [pcc],
    profileRemarks: l1Remarks
      .filter((remarks) => Boolean(remarks))
      .map((remarks) => ({
        type: '',
        text: remarks,
      })),
    airPnrRemarks: pnrRemarks.filter((remarks) => Boolean(remarks)),
    codes: getThirdPartyCodeObject(airClid3, airClid4, airTourCode, hotelRateCode, airCtid, carCodes, vPayCarIdNumbers),
    vendorPrograms: [],
  };
  return { sabreInfo };
};

interface IPolicyHasChangesParams {
  originalPolicy: IPolicyState | null;
  updatedPolicy: IPolicyState | null;
  featureFlagsConfig?: FeatureFlagConfiguration;
}

export const policyHasChanges = ({
  originalPolicy,
  updatedPolicy,
  featureFlagsConfig = {},
}: IPolicyHasChangesParams): boolean => {
  const {
    hotelMedianRatesFeatureEnabled = false,
    isFlightBookingWindowEnabled = false,
    isPolicyAirDomIntlSplitEnabled = false,
    hotelMedianRatesFeatureEnabledV3 = false,
  } = featureFlagsConfig;

  if (!originalPolicy || !updatedPolicy) {
    return false;
  }
  const isNameUnchanged = originalPolicy.name.trim() === updatedPolicy.name.trim();
  const isParentPolicyChanged = originalPolicy.parentPolicyId === updatedPolicy.parentPolicyId;
  const isCurrencyUnchanged = originalPolicy.currency === updatedPolicy.currency;

  const isAllowedAirAddonsUnchanged = isEqual(originalPolicy.allowedAirAddons, updatedPolicy.allowedAirAddons);

  const isInPolicyApprovalForAirUnchanged =
    isPolicyAirDomIntlSplitEnabled ||
    originalPolicy.inPolicyApprovalTypeForAir === updatedPolicy.inPolicyApprovalTypeForAir;
  const isOutOfPolicyApprovalForAirUnchanged =
    isPolicyAirDomIntlSplitEnabled ||
    originalPolicy.outOfPolicyApprovalTypeForAir === updatedPolicy.outOfPolicyApprovalTypeForAir;

  const isInPolicyApprovalForDomesticAirUnchanged =
    !isPolicyAirDomIntlSplitEnabled ||
    originalPolicy.inPolicyApprovalTypeForDomesticAir === updatedPolicy.inPolicyApprovalTypeForDomesticAir;
  const isInPolicyApprovalForInternationalAirUnchanged =
    !isPolicyAirDomIntlSplitEnabled ||
    originalPolicy.inPolicyApprovalTypeForInternationalAir === updatedPolicy.inPolicyApprovalTypeForInternationalAir;
  const isOutOfPolicyApprovalForDomesticAirUnchanged =
    !isPolicyAirDomIntlSplitEnabled ||
    originalPolicy.outOfPolicyApprovalTypeForDomesticAir === updatedPolicy.outOfPolicyApprovalTypeForDomesticAir;
  const isOutOfPolicyApprovalForInternationalAirUnchanged =
    !isPolicyAirDomIntlSplitEnabled ||
    originalPolicy.outOfPolicyApprovalTypeForInternationalAir ===
      updatedPolicy.outOfPolicyApprovalTypeForInternationalAir;

  const isInPolicyApprovalForCarUnchanged =
    originalPolicy.inPolicyApprovalTypeForCar === updatedPolicy.inPolicyApprovalTypeForCar;
  const isOutOfPolicyApprovalForCarUnchanged =
    originalPolicy.outOfPolicyApprovalTypeForCar === updatedPolicy.outOfPolicyApprovalTypeForCar;
  const isInPolicyApprovalForHotelUnchanged =
    originalPolicy.inPolicyApprovalTypeForHotel === updatedPolicy.inPolicyApprovalTypeForHotel;
  const isOutOfPolicyApprovalForHotelUnchanged =
    originalPolicy.outOfPolicyApprovalTypeForHotel === updatedPolicy.outOfPolicyApprovalTypeForHotel;
  const isInPolicyApprovalForRailUnchanged =
    originalPolicy.inPolicyApprovalTypeForRail === updatedPolicy.inPolicyApprovalTypeForRail;
  const isOutOfPolicyApprovalForRailUnchanged =
    originalPolicy.outOfPolicyApprovalTypeForRail === updatedPolicy.outOfPolicyApprovalTypeForRail;

  const isApproverUnchanged =
    isEqual(originalPolicy.policyApprover, updatedPolicy.policyApprover) &&
    originalPolicy.policyApproverIsManager === updatedPolicy.policyApproverIsManager;

  const isApproverIsEmployeeLevelUnchanged =
    originalPolicy.policyApproverIsEmployeeLevel === updatedPolicy.policyApproverIsEmployeeLevel;

  const isShouldNotifyManagerUnchanged = originalPolicy.shouldNotifyManager === updatedPolicy.shouldNotifyManager;

  const isDefaultApproverUnchanged =
    isEqual(originalPolicy.policyDefaultApprover, updatedPolicy.policyDefaultApprover) &&
    originalPolicy.policyApproverIsManager === updatedPolicy.policyApproverIsManager;
  // LLF fields
  const llfLayoverDurationInHoursDomesticUnchanged =
    originalPolicy.llfLayoverDurationInHoursDomestic === updatedPolicy.llfLayoverDurationInHoursDomestic;

  const llfLayoverDurationInHoursInternationalUnchanged =
    originalPolicy.llfLayoverDurationInHoursInternational === updatedPolicy.llfLayoverDurationInHoursInternational;

  const llfLayoverDurationInMinutesDomesticUnchanged =
    originalPolicy.llfMaxLayoverDurationMinutesDomestic === updatedPolicy.llfMaxLayoverDurationMinutesDomestic;

  const llfLayoverDurationInMinutesInternationalUnchanged =
    originalPolicy.llfMaxLayoverDurationMinutesInternational ===
    updatedPolicy.llfMaxLayoverDurationMinutesInternational;

  const llfNoOfStopsUnchanged = originalPolicy.llfMaxNumberOfStops === updatedPolicy.llfMaxNumberOfStops;

  const llfFlightTimeWindowDomesticUnchanged =
    originalPolicy.llfFlightTimeWindowDomestic === updatedPolicy.llfFlightTimeWindowDomestic;

  const llfFlightTimeWindowInternationalUnchanged =
    originalPolicy.llfFlightTimeWindowInternational === updatedPolicy.llfFlightTimeWindowInternational;

  const llfFlightTimeWindowMinutesDomesticUnchanged =
    originalPolicy.llfFlightTimeWindowMinutesDomestic === updatedPolicy.llfFlightTimeWindowMinutesDomestic;
  const llfFlightTimeWindowMinutesInternationalUnchanged =
    originalPolicy.llfFlightTimeWindowMinutesInternational === updatedPolicy.llfFlightTimeWindowMinutesInternational;

  const llfAirportConnectionUnchanged =
    originalPolicy.llfAirportConnectionChange === updatedPolicy.llfAirportConnectionChange;

  const llfCarrierTypeUnchanged = originalPolicy.llfCarrierType === updatedPolicy.llfCarrierType;

  const llfCarrierAirlinesUnchanged = isEqual(originalPolicy.llfCarrierAirlines, updatedPolicy.llfCarrierAirlines);

  const llfMixedCabinItinerariesUnchanged =
    originalPolicy.llfRejectMixCabinFlights === updatedPolicy.llfRejectMixCabinFlights;

  const arellfFieldsUnchanged =
    llfLayoverDurationInHoursDomesticUnchanged &&
    llfLayoverDurationInHoursInternationalUnchanged &&
    llfNoOfStopsUnchanged &&
    llfFlightTimeWindowDomesticUnchanged &&
    llfFlightTimeWindowInternationalUnchanged &&
    llfAirportConnectionUnchanged &&
    llfCarrierTypeUnchanged &&
    llfCarrierAirlinesUnchanged &&
    llfLayoverDurationInMinutesDomesticUnchanged &&
    llfLayoverDurationInMinutesInternationalUnchanged &&
    llfFlightTimeWindowMinutesDomesticUnchanged &&
    llfFlightTimeWindowMinutesInternationalUnchanged &&
    llfMixedCabinItinerariesUnchanged;

  const userGroupsUnchanged = JSON.stringify(originalPolicy.usersGroups) === JSON.stringify(updatedPolicy.usersGroups);

  // car customizations checks
  const isCarMaxPriceUnchanged = originalPolicy.maxCarPricePerNumberOfDays === updatedPolicy.maxCarPricePerNumberOfDays;
  const isAllowedCarTypesUnchanged = isEqual(originalPolicy.allowedCarTypes, updatedPolicy.allowedCarTypes);
  const isCarEngineNotAllowedTypesUnchanged = isEqual(
    originalPolicy.notAllowedCarEngineTypes,
    updatedPolicy.notAllowedCarEngineTypes,
  );
  const isCarReasonCodesUnchanged = isEqual(originalPolicy.carReasonCodes, updatedPolicy.carReasonCodes);
  const isCarCaptureReasonCodeUnchanged = isEqual(
    originalPolicy.carCaptureReasonCode,
    updatedPolicy.carCaptureReasonCode,
  );
  const isNotAllowedCarTypesUnchanged = isEqual(originalPolicy.notAllowedCarTypes, updatedPolicy.notAllowedCarTypes);
  const isNotAllowedCarTypeReasonUnchanged = isEqual(
    originalPolicy.notAllowedCarTypeReason,
    updatedPolicy.notAllowedCarTypeReason,
  );

  const areCarCustomizationsUnchanged =
    isCarMaxPriceUnchanged &&
    isAllowedCarTypesUnchanged &&
    isCarEngineNotAllowedTypesUnchanged &&
    isCarReasonCodesUnchanged &&
    isCarCaptureReasonCodeUnchanged &&
    isNotAllowedCarTypesUnchanged &&
    isNotAllowedCarTypeReasonUnchanged;

  // hotel customizations checks
  const isHotelMaxPriceUnchanged =
    originalPolicy.maxHotelPriceByLocationDefaultPriceAmount ===
      updatedPolicy.maxHotelPriceByLocationDefaultPriceAmount &&
    isEqual(originalPolicy.maxHotelPriceByLocationCustomizations, updatedPolicy.maxHotelPriceByLocationCustomizations);
  const isHotelBookingWindowUnchanged = originalPolicy.hotelBookingWindow === updatedPolicy.hotelBookingWindow;

  const isHotelCancellationUnchanged = originalPolicy.hotelCancellationPolicy === updatedPolicy.hotelCancellationPolicy;

  const isHotelRatingUnchanged = isEqual(originalPolicy.hotelExperience, updatedPolicy.hotelExperience);

  const isHotelTaxAndFeesUnchanged =
    originalPolicy.maxHotelPriceTaxInclusion === updatedPolicy.maxHotelPriceTaxInclusion;

  const isHotelReasonCodesUnchanged = isEqual(originalPolicy.hotelReasonCodes, updatedPolicy.hotelReasonCodes);

  const isHotelCaptureReasonCodeUnchanged = isEqual(
    originalPolicy.hotelCaptureReasonCode,
    updatedPolicy.hotelCaptureReasonCode,
  );

  const isNotAllowedHotelTypesUnchanged = isEqual(
    originalPolicy.notAllowedHotelTypes,
    updatedPolicy.notAllowedHotelTypes,
  );

  const isNotAllowedHotelReasonUnchanged = isEqual(
    originalPolicy.notAllowedHotelReason,
    updatedPolicy.notAllowedHotelReason,
  );

  const isLowestFarePerHotelPropertyUnchanged = isEqual(
    originalPolicy.lowestFarePerHotelPropertyProps,
    updatedPolicy.lowestFarePerHotelPropertyProps,
  );

  const isMedianRateNotChanged =
    originalPolicy.hotelMedianDistance === updatedPolicy.hotelMedianDistance &&
    originalPolicy.hotelMedianDistanceUnit === updatedPolicy.hotelMedianDistanceUnit &&
    originalPolicy.hotelMedianMaxRate === updatedPolicy.hotelMedianMaxRate &&
    originalPolicy.hotelMedianMinRate === updatedPolicy.hotelMedianMinRate &&
    originalPolicy.hotelMedianEnabled === updatedPolicy.hotelMedianEnabled &&
    (!hotelMedianRatesFeatureEnabledV3 ||
      (hotelMedianRatesFeatureEnabledV3 &&
        isEqual(originalPolicy.maxHotelBookingPriceProps, updatedPolicy.maxHotelBookingPriceProps)));

  const isRestrictedKeywordsChanged = isEqual(
    originalPolicy.hotelRestrictByKeywords,
    updatedPolicy.hotelRestrictByKeywords,
  );

  const areHotelCustomizationsUnchanged =
    isHotelMaxPriceUnchanged &&
    isHotelBookingWindowUnchanged &&
    isHotelCancellationUnchanged &&
    isHotelRatingUnchanged &&
    isHotelReasonCodesUnchanged &&
    isHotelCaptureReasonCodeUnchanged &&
    isHotelTaxAndFeesUnchanged &&
    isNotAllowedHotelTypesUnchanged &&
    isNotAllowedHotelReasonUnchanged &&
    isMedianRateNotChanged &&
    isLowestFarePerHotelPropertyUnchanged &&
    isRestrictedKeywordsChanged;

  // flight customizations checks
  const isFlightMaxPriceByDurationUnchanged =
    !hotelMedianRatesFeatureEnabled ||
    isEqual(originalPolicy.maxFlightBookingPriceByDuration, updatedPolicy.maxFlightBookingPriceByDuration);

  const isFlightCabinUpgradeUnchanged = originalPolicy.flightCabinUpgrade === updatedPolicy.flightCabinUpgrade;

  const isFlightBookingWindowUnchanged = originalPolicy.flightBookingWindow === updatedPolicy.flightBookingWindow;

  const isFlightBookingWindowDomesticUnchanged =
    !isFlightBookingWindowEnabled ||
    originalPolicy.flightBookingWindowDomestic === updatedPolicy.flightBookingWindowDomestic;
  const isFlightBookingWindowInternationalUnchanged =
    !isFlightBookingWindowEnabled ||
    originalPolicy.flightBookingWindowInternational === updatedPolicy.flightBookingWindowInternational;

  const isCo2EmissionPerPassengerPerKmUnchanged =
    originalPolicy.co2EmissionPerPassengerPerKm === updatedPolicy.co2EmissionPerPassengerPerKm;

  const isFlightRefundableTicketsUnchanged =
    originalPolicy.flightRefundableTickets === updatedPolicy.flightRefundableTickets;

  const isFlightChangeableTicketsUnchanged =
    originalPolicy.flightChangeableTickets === updatedPolicy.flightChangeableTickets;

  const isFlightMaxDomesticCabinUnchanged = isEqual(
    originalPolicy.highestFlightCabinByDurationDomestic,
    updatedPolicy.highestFlightCabinByDurationDomestic,
  );

  const isFlightMaxInternationalCabinUnchanged = isEqual(
    originalPolicy.highestFlightCabinByDurationInternational,
    updatedPolicy.highestFlightCabinByDurationInternational,
  );

  const isFlightBookingPriceDomesticUnchanged = isEqual(
    originalPolicy.maxFlightBookingPriceDomestic,
    updatedPolicy.maxFlightBookingPriceDomestic,
  );

  const isFlightBookingPriceInternationalUnchanged = isEqual(
    originalPolicy.maxFlightBookingPriceInternational,
    updatedPolicy.maxFlightBookingPriceInternational,
  );

  const isFlightMaxOvernightCabinUnchanged =
    originalPolicy.highestFlightCabinOvernight === updatedPolicy.highestFlightCabinOvernight;

  const isFlightOvernightTimeStartUnchanged = isEqual(
    originalPolicy.overNightFlightTimeStart,
    updatedPolicy.overNightFlightTimeStart,
  );

  const isFlightOvernightTimeEndUnchanged = isEqual(
    originalPolicy.overNightFlightTimeEnd,
    updatedPolicy.overNightFlightTimeEnd,
  );

  const isFlightOvernightIncludeLayoverUnchanged = isEqual(
    originalPolicy.overNightFlightIncludeLayover,
    updatedPolicy.overNightFlightIncludeLayover,
  );

  const isFlightOvernightOverlapDurationUnchanged = isEqual(
    originalPolicy.overNightFlightTimeOverlapMins,
    updatedPolicy.overNightFlightTimeOverlapMins,
  );

  const isOvernightTimeParamsUnchanged =
    updatedPolicy.highestFlightCabinOvernight === '-1' ||
    (isFlightOvernightTimeEndUnchanged &&
      isFlightOvernightTimeStartUnchanged &&
      isFlightOvernightIncludeLayoverUnchanged &&
      isFlightOvernightOverlapDurationUnchanged);

  const isFlightReasonCodesUnchanged = isEqual(originalPolicy.flightReasonCodes, updatedPolicy.flightReasonCodes);

  const isFlightCaptureReasonCodeUnchanged = isEqual(
    originalPolicy.flightCaptureReasonCode,
    updatedPolicy.flightCaptureReasonCode,
  );

  const isFlightEconomyFaresDomesticUnchanged = isEqual(
    originalPolicy.economyFaresDomesticDisallowed,
    updatedPolicy.economyFaresDomesticDisallowed,
  );

  const isFlightEconomyFaresDomesticReasonUnchanged = isEqual(
    originalPolicy.economyFaresDomesticReason,
    updatedPolicy.economyFaresDomesticReason,
  );

  const isFlightEconomyFaresInternationalUnchanged = isEqual(
    originalPolicy.economyFaresInternationalDisallowed,
    updatedPolicy.economyFaresInternationalDisallowed,
  );

  const isFlightEconomyFaresInternationalReasonUnchanged = isEqual(
    originalPolicy.economyFaresInternationalReason,
    updatedPolicy.economyFaresInternationalReason,
  );

  const isFlightCabinClassNotAllowedDomesticUnchanged = isEqual(
    originalPolicy.cabinClassNotAllowedDomestic,
    updatedPolicy.cabinClassNotAllowedDomestic,
  );

  const isFlightCabinClassNotAllowedDomesticReasonUnchanged = isEqual(
    originalPolicy.cabinClassNotAllowedDomesticReason,
    updatedPolicy.cabinClassNotAllowedDomesticReason,
  );

  const isFlightCabinClassNotAllowedInternationalUnchanged = isEqual(
    originalPolicy.cabinClassNotAllowedInternational,
    updatedPolicy.cabinClassNotAllowedInternational,
  );

  const isFlightCabinClassNotAllowedInternationalReasonUnchanged = isEqual(
    originalPolicy.cabinClassNotAllowedInternationalReason,
    updatedPolicy.cabinClassNotAllowedInternationalReason,
  );

  const areFlightCustomizationsUnchanged =
    isFlightMaxPriceByDurationUnchanged &&
    isFlightCabinUpgradeUnchanged &&
    isFlightBookingWindowUnchanged &&
    isCo2EmissionPerPassengerPerKmUnchanged &&
    isFlightRefundableTicketsUnchanged &&
    isFlightChangeableTicketsUnchanged &&
    isFlightMaxDomesticCabinUnchanged &&
    isFlightMaxInternationalCabinUnchanged &&
    isFlightMaxOvernightCabinUnchanged &&
    isOvernightTimeParamsUnchanged &&
    isFlightBookingPriceDomesticUnchanged &&
    isFlightBookingPriceInternationalUnchanged &&
    isFlightEconomyFaresDomesticUnchanged &&
    isFlightEconomyFaresInternationalUnchanged &&
    isFlightCabinClassNotAllowedDomesticUnchanged &&
    isFlightCabinClassNotAllowedInternationalUnchanged &&
    isFlightReasonCodesUnchanged &&
    isFlightCaptureReasonCodeUnchanged &&
    isFlightEconomyFaresDomesticReasonUnchanged &&
    isFlightEconomyFaresInternationalReasonUnchanged &&
    isFlightCabinClassNotAllowedDomesticReasonUnchanged &&
    isFlightCabinClassNotAllowedInternationalReasonUnchanged &&
    isFlightBookingWindowDomesticUnchanged &&
    isFlightBookingWindowInternationalUnchanged &&
    isAllowedAirAddonsUnchanged;

  // rail customizations
  const isRailCaptureReasonCodeUnchanged = originalPolicy.railCaptureReasonCode === updatedPolicy.railCaptureReasonCode;
  const isRailReasonCodesUnchanged = isEqual(originalPolicy.railReasonCodes, updatedPolicy.railReasonCodes);
  const isRailHighestClassUnchanged = isEqual(originalPolicy.railHighestClass, updatedPolicy.railHighestClass);
  const isRailMaxPriceUnchanged = isEqual(originalPolicy.railMaxPrice, updatedPolicy.railMaxPrice);
  const isRailBookingWindowUnchanged = originalPolicy.railBookingWindow === updatedPolicy.railBookingWindow;

  const areRailsCustomizationUnchanged =
    isRailHighestClassUnchanged &&
    isRailMaxPriceUnchanged &&
    isRailBookingWindowUnchanged &&
    isRailCaptureReasonCodeUnchanged &&
    isRailReasonCodesUnchanged;

  if (
    isNameUnchanged &&
    isCurrencyUnchanged &&
    isApproverUnchanged &&
    isApproverIsEmployeeLevelUnchanged &&
    isShouldNotifyManagerUnchanged &&
    isDefaultApproverUnchanged &&
    isInPolicyApprovalForAirUnchanged &&
    isInPolicyApprovalForDomesticAirUnchanged &&
    isInPolicyApprovalForInternationalAirUnchanged &&
    isOutOfPolicyApprovalForDomesticAirUnchanged &&
    isOutOfPolicyApprovalForInternationalAirUnchanged &&
    isOutOfPolicyApprovalForAirUnchanged &&
    isInPolicyApprovalForCarUnchanged &&
    isInPolicyApprovalForHotelUnchanged &&
    isInPolicyApprovalForRailUnchanged &&
    isOutOfPolicyApprovalForCarUnchanged &&
    isOutOfPolicyApprovalForHotelUnchanged &&
    isOutOfPolicyApprovalForRailUnchanged &&
    areCarCustomizationsUnchanged &&
    areHotelCustomizationsUnchanged &&
    areFlightCustomizationsUnchanged &&
    areRailsCustomizationUnchanged &&
    userGroupsUnchanged &&
    arellfFieldsUnchanged &&
    isParentPolicyChanged
  ) {
    return false;
  }
  return true;
};

export const getConditionListStateFromUserGroupData = (
  conditions: IPolicyUserGroup,
): IPolicyUserGroupConditionItem[] => {
  const conditionalArray: IPolicyUserGroupConditionItem[] = [];
  Object.entries(conditions).forEach(([key, value]) => {
    if (value.length) {
      conditionalArray.push({
        key: uniqueId('condition-'),
        type: key as policyUserGroupConditionTypeKeysEnum,
        data: value,
      });
    }
  });
  if (!conditionalArray.length) {
    conditionalArray.push({ key: uniqueId('condition-'), type: 'default', data: [] });
  }
  return conditionalArray;
};

const buildReasonCodesPolicy = (
  reasonCodes: IReasonCode[],
  displayInfo: string,
  isDisabled: boolean,
  isLinked: boolean,
): PolicyOopReasonCodes =>
  isLinked
    ? { isLinked }
    : {
        isLinked: undefined,
        props: {
          reasonCodesUde: [
            {
              items: reasonCodes.map((reasonCode) => ({
                itemCode: reasonCode.id,
                itemValue: reasonCode.code,
              })),
              displayInfo,
              questionFormat: QuestionFormat.RADIO_BUTTON,
              customFieldType: CustomFieldType.QUESTION,
              isRequired: true,
              isDisabled,
              source: UserDefinedEntitySource.MANUAL,
              includeInItin: false,
              showCustomFieldIdOnUi: false,
              customFieldLocations: [],
            },
          ],
        },
      };

const formatPolicy = (
  rule: PolicyUniversalRule,
  splitPaymentInfoConfig?: SplitPaymentInfoConfig,
): PolicyUniversalRule => {
  const newRule: PolicyUniversalRule = {
    ruleType: rule.ruleType,
  };

  if (rule.isLinked) {
    newRule.isLinked = true;
  } else {
    const prevent = rule.preventBooking?.prevent ?? false;
    const reason = rule.preventBooking?.reason ?? '';

    if (splitPaymentInfoConfig) {
      newRule.splitPaymentInfo = {
        shouldSplitPayment: splitPaymentInfoConfig.ancillaryShouldSplit,
        chargeTotalFareOnPersonalFop: splitPaymentInfoConfig.ancillaryPersonalFop,
      };
    } else {
      newRule.preventBooking = {
        prevent,
        reason: prevent ? reason : '',
      };
    }

    newRule.props = rule.props;
  }

  return newRule;
};

const getRailMaxPriceDurationProps = (
  updatedPolicy: Pick<IPolicyState, 'railMaxPrice' | 'currency'>,
): MaxRailBookingPriceByDurationProps => {
  return {
    maxPriceForDurationList:
      updatedPolicy.railMaxPrice.length === 1 && typeof updatedPolicy.railMaxPrice[0].value === 'undefined'
        ? []
        : updatedPolicy.railMaxPrice
            ?.map((item, index) => ({
              maxPrice: item.value?.amount
                ? {
                    ...item.value,
                    currencyCode: updatedPolicy.currency,
                  }
                : undefined,
              durationRange: {
                max: updatedPolicy.railMaxPrice.length - 1 === index ? 0 : item.max,
                min: item.min,
              },
            }))
            .filter((item) => typeof item.maxPrice !== 'undefined'),
  };
};

const getFlightMaxPriceDurationProps = (
  updatedPolicy: Pick<IPolicyState, 'maxFlightBookingPriceByDuration' | 'currency'>,
): MaxFlightBookingPriceByDurationProps => {
  return {
    maxPriceForDurationList:
      updatedPolicy.maxFlightBookingPriceByDuration.length === 1
        ? [
            {
              maxPrice: {
                amount: Number(updatedPolicy.maxFlightBookingPriceByDuration[0].value),
                currencyCode: updatedPolicy.currency,
                convertedAmount: Number(updatedPolicy.maxFlightBookingPriceByDuration[0].value),
                convertedCurrency: '',
              },
              durationInMinutes: 0,
              durationRange: { min: 0, max: 0 },
            },
          ]
        : updatedPolicy.maxFlightBookingPriceByDuration
            .filter(({ value }) => value !== '')
            .map(({ value, min, max }, custIndex, custArr) => ({
              maxPrice: {
                amount: Number(value),
                currencyCode: updatedPolicy.currency,
                convertedAmount: Number(value),
                convertedCurrency: '',
              },
              // deprecated, to be removed once types are accordingly updated on BE,
              // durationInMinutes:0 makes no difference here
              durationInMinutes: 0,
              durationRange: {
                min,
                max: custIndex === custArr.length - 1 && max === 1800 ? 0 : max,
              },
            })),
  };
};

export const getAutocompleteSuggestionOptionFromPostalAddress = (address: IPostalAddress): IAutocompleteSuggestion => {
  // there might be a case when user picks country so in that there is no
  // - administrativeArea
  // - locality
  // - sublocality
  // - regionCode
  // in that case take address.addressLines
  const name =
    Boolean(address.administrativeArea) ||
    Boolean(address.locality) ||
    Boolean(address.sublocality) ||
    Boolean(address.regionCode)
      ? address.administrativeArea
      : address.addressLines?.join(', ') ?? '';

  const result = {
    type: 'CITY',
    name,
    location: address.locality,
    state: '',
    country: address.regionCode,
    data: address.sublocality,
    coordinates: { latitude: 0, longitude: 0 },
    googlePlaceId: '',
    stationReferenceId: '',
    timeZone: '',
    sessionToken: '',
  };

  return result;
};

export const SearchTravelerSearchResultToITraveler = (
  response: ISearchTravelerResponseTravelerSearchResult,
): IApproverInfo => ({ name: response.name, userOrgId: response.id, profilePicture: response.profilePicture });

interface IPolicyMessages {
  airOopQuestion: string;
  hotelOopQuestion: string;
  carOopQuestion: string;

  railOopQuestion: string;
}

const getLlfFromPolicyState = (
  isLinked: boolean,
  updatedPolicy: IPolicyState,
  isLlfIncrementFeatureEnabled?: boolean,
  isMixedCabinItinerariesEnabled?: boolean,
): PolicyLowestLogicalFare => {
  return {
    props: isLinked
      ? undefined
      : {
          maxLayoverDurationInHoursDomestic: updatedPolicy.llfLayoverDurationInHoursDomestic ?? undefined,
          maxLayoverDurationInHoursInternational: updatedPolicy.llfLayoverDurationInHoursInternational ?? undefined,
          maxNumberOfStops: updatedPolicy.llfMaxNumberOfStops,
          flightTimeWindowInHoursDomestic: updatedPolicy.llfFlightTimeWindowDomestic ?? undefined,
          flightTimeWindowInHoursInternational: updatedPolicy.llfFlightTimeWindowInternational ?? undefined,
          ...(isLlfIncrementFeatureEnabled && {
            maxLayoverDurationMinutesDomestic: updatedPolicy.llfMaxLayoverDurationMinutesDomestic ?? undefined,
          }),
          ...(isLlfIncrementFeatureEnabled && {
            maxLayoverDurationMinutesInternational:
              updatedPolicy.llfMaxLayoverDurationMinutesInternational ?? undefined,
          }),
          ...(isLlfIncrementFeatureEnabled && {
            flightTimeWindowMinutesDomestic: updatedPolicy.llfFlightTimeWindowMinutesDomestic ?? undefined,
          }),
          ...(isLlfIncrementFeatureEnabled && {
            flightTimeWindowMinutesInternational: updatedPolicy.llfFlightTimeWindowMinutesInternational ?? undefined,
          }),
          airportConnectionChanges: updatedPolicy.llfAirportConnectionChange,
          carrier:
            updatedPolicy.llfCarrierType !== undefined
              ? {
                  type: updatedPolicy.llfCarrierType,
                  airlines:
                    updatedPolicy.llfCarrierType !== LowestLogisticalFarePropsCarrierType.ANY &&
                    updatedPolicy.llfCarrierAirlines.length
                      ? updatedPolicy.llfCarrierAirlines
                      : [],
                }
              : {},
          rejectMixCabinFlights: isMixedCabinItinerariesEnabled
            ? updatedPolicy.llfRejectMixCabinFlights ?? false
            : false,
        },
    isLinked: isLinked ? true : undefined,
  };
};

const getHotelMedianRateProps = (
  policy: Pick<
    IPolicyState,
    | 'hotelMedianDistance'
    | 'hotelMedianDistanceUnit'
    | 'hotelMedianMaxRate'
    | 'hotelMedianMinRate'
    | 'hotelMedianEnabled'
  >,
): HotelMedianRateProps | undefined => {
  if (
    policy.hotelMedianEnabled &&
    typeof policy.hotelMedianDistance === 'number' &&
    policy.hotelMedianDistanceUnit &&
    typeof policy.hotelMedianMaxRate === 'number' &&
    typeof policy.hotelMedianMinRate === 'number'
  ) {
    return {
      searchRadius: {
        length: policy.hotelMedianDistance,
        unit: policy.hotelMedianDistanceUnit,
      },
      ratingRange: {
        max: policy.hotelMedianMaxRate,
        min: policy.hotelMedianMinRate,
      },
    };
  }

  return undefined;
};

export const getHotelMedianRateNightly = (policy: IPolicyState): PolicyHotelMedianRate | undefined => {
  // if default policy then allow saving of median configuration
  // non default policies CANNOT SET hotel median based policies as of today
  if (policy.type === PolicyTypeEnum.DEFAULT) {
    // update the new settings for hotel median policy
    const hotelMedianProps = getHotelMedianRateProps(policy);
    if (hotelMedianProps) {
      return {
        props: hotelMedianProps,
      };
    }
  }
  return undefined;
};

/**
 * Retrieves an array of travel types (InternalPolicyType) that match a given approval type.
 *
 * This function iterates over the `approvalTypesByTravelType` map, checking each travel type
 * against the specified `approvalType`. If the approval type of a travel type matches
 * the given `approvalType`, that travel type is added to the resulting array.
 *
 * Example:
 *  Input: approvalTypesByTravelType = { AIR: SOFT_APPROVAL, CAR: HARD_APPROVAL },
 *         approvalType = SOFT_APPROVAL
 *  Output: [AIR]
 */
function getTravelTypesForApprovalType(
  approvalTypesByTravelType: PolicyApprovalProcessTypeMap,
  approvalType: PolicyApprovalInfoApprovalProcessType,
): InternalPolicyType[] {
  const travelTypes: InternalPolicyType[] = [];

  Object.keys(approvalTypesByTravelType).forEach((key) => {
    const travelType = key as unknown as InternalPolicyType;
    if (approvalTypesByTravelType[travelType] === approvalType) {
      travelTypes.push(travelType);
    }
  });

  return travelTypes;
}

type PolicyApprovalProcessTypeMap = {
  [key in InternalPolicyType]?: PolicyApprovalInfoApprovalProcessType;
};

interface IGetPolicyApprovalInfosParams {
  policy: IPolicyState;
  policyApprovalProcessTypes: PolicyApprovalProcessTypeMap;
}

function getPolicyApprovalInfos({
  policy,
  policyApprovalProcessTypes,
}: IGetPolicyApprovalInfosParams): PolicyApprovalInfo[] {
  const inPolicyApprovalInfos: PolicyApprovalInfo[] = [];

  const addPolicyApprovalInfo = (approvalProcessType: PolicyApprovalInfoApprovalProcessType) => {
    const travelTypes = getTravelTypesForApprovalType(policyApprovalProcessTypes, approvalProcessType);
    if (travelTypes.length > 0) {
      inPolicyApprovalInfos.push({
        ...getBasePolicyApprovalInfo(policy),
        approvalProcessType,
        travelTypes,
        approvalConditions: [],
      });
    }
  };

  [
    PolicyApprovalInfoApprovalProcessType.HARD_APPROVAL,
    PolicyApprovalInfoApprovalProcessType.SOFT_APPROVAL,
    PolicyApprovalInfoApprovalProcessType.PASSIVE_APPROVAL,
    PolicyApprovalInfoApprovalProcessType.PREVENT_BOOKING,
    PolicyApprovalInfoApprovalProcessType.NONE,
  ].forEach(addPolicyApprovalInfo);

  return inPolicyApprovalInfos;
}

function getAirSeparatedTravelTypePolicyApprovalInfos(policy: IPolicyState, isInPolicy: boolean): PolicyApprovalInfo[] {
  const baseAirPolicyApprovalInfo = {
    ...getBasePolicyApprovalInfo(policy),
    travelTypes: [InternalPolicyType.AIR],
  };

  return [
    {
      ...baseAirPolicyApprovalInfo,
      approvalProcessType: isInPolicy
        ? policy.inPolicyApprovalTypeForDomesticAir
        : policy.outOfPolicyApprovalTypeForDomesticAir,
      approvalConditions: [
        {
          approvalRules: [
            {
              travelRegionRule: {
                travelRegionType: TravelRegionType.DOMESTIC,
              },
            },
          ],
        },
      ],
    },
    {
      ...baseAirPolicyApprovalInfo,
      approvalProcessType: isInPolicy
        ? policy.inPolicyApprovalTypeForInternationalAir
        : policy.outOfPolicyApprovalTypeForInternationalAir,
      approvalConditions: [
        {
          approvalRules: [
            {
              travelRegionRule: {
                travelRegionType: TravelRegionType.INTERNATIONAL,
              },
            },
          ],
        },
      ],
    },
  ];
}

interface IGetInOrOutOfPolicyApprovalInfosParams {
  policy: IPolicyState;
}

function getInPolicyApprovalInfos({ policy }: IGetInOrOutOfPolicyApprovalInfosParams): PolicyApprovalInfo[] {
  const inPolicyApprovalProcessTypes: PolicyApprovalProcessTypeMap = {
    [InternalPolicyType.AIR]: policy.inPolicyApprovalTypeForAir,
    [InternalPolicyType.CAR]: policy.inPolicyApprovalTypeForCar,
    [InternalPolicyType.HOTEL]: policy.inPolicyApprovalTypeForHotel,
    [InternalPolicyType.RAIL]: policy.inPolicyApprovalTypeForRail,
  };
  return getPolicyApprovalInfos({ policy, policyApprovalProcessTypes: inPolicyApprovalProcessTypes });
}

function getOutOfPolicyApprovalInfos({ policy }: IGetInOrOutOfPolicyApprovalInfosParams): PolicyApprovalInfo[] {
  const outOfPolicyApprovalProcessTypes: PolicyApprovalProcessTypeMap = {
    [InternalPolicyType.AIR]: policy.outOfPolicyApprovalTypeForAir,
    [InternalPolicyType.CAR]: policy.outOfPolicyApprovalTypeForCar,
    [InternalPolicyType.HOTEL]: policy.outOfPolicyApprovalTypeForHotel,
    [InternalPolicyType.RAIL]: policy.outOfPolicyApprovalTypeForRail,
  };
  return getPolicyApprovalInfos({ policy, policyApprovalProcessTypes: outOfPolicyApprovalProcessTypes });
}

function getCustomAirInPolicyApprovalInfos({ policy }: IGetInOrOutOfPolicyApprovalInfosParams): PolicyApprovalInfo[] {
  let inPolicyApprovalInfos = [];

  inPolicyApprovalInfos = getAirSeparatedTravelTypePolicyApprovalInfos(policy, true);

  const otherTravelTypesProcessTypes: PolicyApprovalProcessTypeMap = {
    [InternalPolicyType.CAR]: policy.inPolicyApprovalTypeForCar,
    [InternalPolicyType.HOTEL]: policy.inPolicyApprovalTypeForHotel,
    [InternalPolicyType.RAIL]: policy.inPolicyApprovalTypeForRail,
  };

  inPolicyApprovalInfos.push(
    ...getPolicyApprovalInfos({
      policy,
      policyApprovalProcessTypes: otherTravelTypesProcessTypes,
    }),
  );

  return inPolicyApprovalInfos;
}

function getCustomAirOutOfPolicyApprovalInfos({
  policy,
}: IGetInOrOutOfPolicyApprovalInfosParams): PolicyApprovalInfo[] {
  let outOfPolicyApprovalInfos = [];

  outOfPolicyApprovalInfos = getAirSeparatedTravelTypePolicyApprovalInfos(policy, false);

  const otherTravelTypesProcessTypes: PolicyApprovalProcessTypeMap = {
    [InternalPolicyType.CAR]: policy.outOfPolicyApprovalTypeForCar,
    [InternalPolicyType.HOTEL]: policy.outOfPolicyApprovalTypeForHotel,
    [InternalPolicyType.RAIL]: policy.outOfPolicyApprovalTypeForRail,
  };

  outOfPolicyApprovalInfos.push(
    ...getPolicyApprovalInfos({
      policy,
      policyApprovalProcessTypes: otherTravelTypesProcessTypes,
    }),
  );

  return outOfPolicyApprovalInfos;
}

export interface HighestFlightCabinCustomizationItem {
  min: number;
  max: number;
  value: string;
}

export const getHighestFlightCabinByDuration = (
  items: HighestFlightCabinCustomizationItem[],
): HighestFlightCabinByDurationPropsHighestCabinForDuration[] => {
  const { length } = items;
  if (!length) {
    return [];
  }
  if (length === 1) {
    return [
      {
        cabin: Number(items[0].value) as ICabin,
        durationRange: { min: 0, max: 0 },
        durationInMinutes: 0,
      },
    ];
  }
  return items
    .filter(({ value }) => value !== '-1')
    .map(({ value, min, max }, idx) => ({
      cabin: Number(value) as ICabin,
      durationRange: { min, max: max === 1800 && idx === length - 1 ? 0 : max },
      durationInMinutes: 0,
    }));
};

export const getHighestFlightCabinByMileage = (
  items: HighestFlightCabinCustomizationItem[],
): HighestFlightCabinByDurationPropsHighestCabinForMileage[] => {
  const { length } = items;
  if (!length) {
    return [];
  }
  if (length === 1) {
    return [
      {
        cabin: Number(items[0].value) as ICabin,
        mileRange: { min: 0, max: 0 },
      },
    ];
  }
  return items
    .filter(({ value }) => value !== '-1')
    .map(({ value, min, max }) => ({
      cabin: Number(value) as ICabin,
      mileRange: { min, max },
    }));
};

export const getHighestFlightCabinProps = (
  items: HighestFlightCabinCustomizationItem[],
  mileage: boolean,
): HighestFlightCabinByDurationProps => {
  if (!items.length) {
    return { highestCabinForDurationList: [], highestCabinForMilageList: [] };
  }
  return mileage
    ? { highestCabinForDurationList: [], highestCabinForMilageList: getHighestFlightCabinByMileage(items) }
    : { highestCabinForDurationList: getHighestFlightCabinByDuration(items), highestCabinForMilageList: [] };
};

export const getHighestFlightCabin = (
  highestFlightCabinCustomizations: HighestFlightCabinCustomizationItem[] | undefined,
  splitPaymentInfoConfig: SplitPaymentInfoConfig | undefined,
  isMileage: boolean,
): HighestFlightCabinByDuration | undefined => {
  return highestFlightCabinCustomizations?.length
    ? {
        props: getHighestFlightCabinProps(highestFlightCabinCustomizations, isMileage),
        ...(splitPaymentInfoConfig && {
          splitPaymentInfo: {
            shouldSplitPayment: splitPaymentInfoConfig.cabinShouldSplit,
            chargeTotalFareOnPersonalFop: splitPaymentInfoConfig.cabinPersonalFop,
          },
        }),
      }
    : undefined;
};

interface IGetNewPolicyRequestDataParams {
  policy: IPolicyState;
  links: IPolicyLinkingState | null;
  messages: IPolicyMessages;
  featureFlagsConfig?: FeatureFlagConfiguration;
}

const getNewPolicyRequestData = ({
  policy,
  links,
  messages,
  featureFlagsConfig = {},
}: IGetNewPolicyRequestDataParams): IPolicy => {
  const {
    hotelMedianRatesFeatureEnabled = false,
    isFlightBookingWindowEnabled = false,
    isLlfIncrementFeatureEnabled = false,
    isMixedCabinItinerariesEnabled = false,
    isAncillaryUIEnabled = false,
    isPolicyAirDomIntlSplitEnabled = false,
    isHideHotelMedianRateCustomizationForNonDefaultPolicyEnabled = false,
    isPolicyCustomizeCabinByMileage = false,
    splitPaymentInfoConfig = undefined,
    isLowestFarePerHotelPropertyEnabled = false,
  } = featureFlagsConfig;
  const { policyId, ...basePolicy } = emptyPolicy;
  const updatedPolicy: IPolicy = produce(basePolicy, (draft) => {
    draft.name = policy.name.trim();
    draft.usersGroups = getPolicyUserGroups(policy);
    draft.parentPolicyId = { id: policy.parentPolicyId || '' };

    draft.category = policy.category || PolicyCategory.EMPLOYEE;

    if (links?.currency === true) {
      draft.currency = { isLinked: true };
    } else {
      draft.currency = { isoCode: policy.currency };
    }

    const isOvernightTimeParamsDefined =
      policy.overNightFlightTimeStart !== undefined &&
      policy.overNightFlightTimeEnd !== undefined &&
      policy.overNightFlightIncludeLayover !== undefined &&
      policy.overNightFlightTimeOverlapMins !== undefined;

    draft.inPolicyApprovalInfos = isPolicyAirDomIntlSplitEnabled
      ? getCustomAirInPolicyApprovalInfos({ policy })
      : getInPolicyApprovalInfos({ policy });
    draft.outOfPolicyApprovalInfos = isPolicyAirDomIntlSplitEnabled
      ? getCustomAirOutOfPolicyApprovalInfos({ policy })
      : getOutOfPolicyApprovalInfos({ policy });
    draft.inPolicyApprovalInfo = undefined;
    draft.outOfPolicyApprovalInfo = undefined;

    if (links?.flightBookingWindow === true && !isFlightBookingWindowEnabled) {
      draft.flightAdvanceBookingWindow = { isLinked: true };
    } else {
      draft.flightAdvanceBookingWindow = isFlightBookingWindowEnabled
        ? undefined
        : {
            props: policy.flightBookingWindow ? { numDaysInAdvance: policy.flightBookingWindow } : undefined,
          };
    }

    if (links?.co2EmissionPerPassengerPerKm === true) {
      draft.co2EmissionPerPassengerPerKm = { isLinked: true };
    } else {
      draft.co2EmissionPerPassengerPerKm = {
        props:
          policy.co2EmissionPerPassengerPerKm === ''
            ? undefined
            : { emission: Number(policy.co2EmissionPerPassengerPerKm) },
      };
    }

    if (links?.flightCabinUpgrade === true) {
      draft.flightCabinUpgrade = { isLinked: true };
    } else {
      draft.flightCabinUpgrade = {
        props: policy.flightCabinUpgrade
          ? {
              isAllowed: policy.flightCabinUpgrade,
            }
          : undefined,
      };
    }

    if (links?.flightRefundableTickets === true) {
      draft.flightTicketsRefundable = { isLinked: true };
    } else {
      draft.flightTicketsRefundable = {
        props: policy.flightRefundableTickets === '' ? undefined : { type: Number(policy.flightRefundableTickets) },
      };
    }

    if (links?.flightChangeableTickets === true) {
      draft.flightTicketsChangeable = { isLinked: true };
    } else {
      draft.flightTicketsChangeable = {
        props: policy.flightChangeableTickets === '' ? undefined : { type: Number(policy.flightChangeableTickets) },
      };
    }

    if (links?.maxFlightBookingPriceByDuration === true) {
      draft.maxFlightBookingPriceByDuration = { isLinked: true };
    } else {
      draft.maxFlightBookingPriceByDuration = {
        props: getFlightMaxPriceDurationProps(policy),
      };
    }

    if (links?.maxFlightBookingPriceDomestic === true) {
      draft.maxFlightBookingPriceDomestic = { isLinked: true };
    } else {
      let props;
      if (!isEmpty(policy.maxFlightBookingPriceDomestic)) {
        props = {
          ...policy.maxFlightBookingPriceDomestic,
          isTaxIncluded: true,
        };
      }

      draft.maxFlightBookingPriceDomestic = { ...(props && { props }) };
    }

    if (links?.maxFlightBookingPriceInternational === true) {
      draft.maxFlightBookingPriceInternational = { isLinked: true };
    } else {
      let props;
      if (!isEmpty(policy.maxFlightBookingPriceInternational)) {
        props = {
          ...policy.maxFlightBookingPriceInternational,
          isTaxIncluded: true,
        };
      }

      draft.maxFlightBookingPriceInternational = { ...(props && { props }) };
    }

    if (links?.highestFlightCabinByDurationDomestic === true) {
      draft.highestFlightCabinByDurationDomestic = { isLinked: true };
    } else {
      draft.highestFlightCabinByDurationDomestic = getHighestFlightCabin(
        policy.highestFlightCabinByDurationDomestic,
        splitPaymentInfoConfig,
        isPolicyCustomizeCabinByMileage,
      );
    }

    if (links?.highestFlightCabinByDurationInternational === true) {
      draft.highestFlightCabinByDurationInternational = { isLinked: true };
    } else {
      draft.highestFlightCabinByDurationInternational = getHighestFlightCabin(
        policy.highestFlightCabinByDurationInternational,
        splitPaymentInfoConfig,
        isPolicyCustomizeCabinByMileage,
      );
    }

    if (links?.highestFlightCabinOvernight === true) {
      draft.highestFlightCabinOvernight = { isLinked: true };
    } else {
      draft.highestFlightCabinOvernight = {
        props:
          policy.highestFlightCabinOvernight === '-1'
            ? undefined
            : {
                cabin: Number(policy.highestFlightCabinOvernight) as ICabin,
                overnightTimeParams: isOvernightTimeParamsDefined
                  ? ({
                      nightTimeStart: policy.overNightFlightTimeStart,
                      nightTimeEnd: policy.overNightFlightTimeEnd,
                      includeLayover: policy.overNightFlightIncludeLayover,
                      nightTimeOverlapMins: policy.overNightFlightTimeOverlapMins,
                    } as OvernightTimeParams)
                  : undefined,
              },
      };
    }

    if (links?.maxHotelPriceByLocation === true) {
      draft.maxHotelPriceByLocation = { isLinked: true };
    } else {
      draft.maxHotelPriceByLocation = {
        props:
          policy.maxHotelPriceByLocationDefaultPriceAmount === '' &&
          policy.maxHotelPriceByLocationCustomizations.every((item) => item.amount === '')
            ? undefined
            : {
                defaultMaxPrice: {
                  amount: Number(policy.maxHotelPriceByLocationDefaultPriceAmount),
                  currencyCode: policy.currency,
                },
                maxPriceInLocationList: policy.maxHotelPriceByLocationCustomizations.map((item) => ({
                  maxPrice: { amount: Number(item.amount), currencyCode: item.currency },
                  address: item.address,
                })),
              },
      };
    }

    if (links?.hotelBookingWindow === true) {
      draft.hotelAdvanceBookingWindow = { isLinked: true };
    } else {
      draft.hotelAdvanceBookingWindow = {
        props:
          policy.hotelBookingWindow === ''
            ? undefined
            : {
                numDaysInAdvance: Number(policy.hotelBookingWindow),
              },
      };
    }

    if (links?.hotelCancellationPolicy === true) {
      draft.hotelCancellation = { isLinked: true };
    } else {
      draft.hotelCancellation = {
        props: policy.hotelCancellationPolicy === '' ? undefined : { type: Number(policy.hotelCancellationPolicy) },
      };
    }

    if (links?.hotelExperience === true) {
      draft.hotelRating = { isLinked: true };
    } else {
      draft.hotelRating = {
        props: {
          ratingRange: { min: policy.hotelExperience.min, max: policy.hotelExperience.max },
        },
      };
    }

    if (links?.maxCarPricePerNumberOfDays === true) {
      draft.maxCarPricePerNumberOfDays = { isLinked: true };
    } else {
      draft.maxCarPricePerNumberOfDays = {
        props:
          policy.maxCarPricePerNumberOfDays === ''
            ? undefined
            : {
                maxPrice: {
                  amount: Number(policy.maxCarPricePerNumberOfDays),
                  currencyCode: policy.currency,
                },
                numDays: 1,
              },
      };
    }

    if (links?.allowedCarTypes === true) {
      draft.allowedCarTypes = { isLinked: true };
    } else {
      draft.allowedCarTypes = {
        props: policy.allowedCarTypes.length
          ? { carTypes: policy.allowedCarTypes.map((type) => Number(type) as unknown as ICarType) }
          : undefined,
      };
    }

    draft.rules = [
      formatPolicy({
        ruleType: PolicyRuleType.CABIN_CLASS_NOT_ALLOWED_DOMESTIC,
        preventBooking: {
          prevent: !!policy.cabinClassNotAllowedDomestic.length,
          reason: policy.cabinClassNotAllowedDomesticReason,
        },
        props: {
          cabinClassNotAllowedDomesticProps: {
            cabins: policy.cabinClassNotAllowedDomestic,
          },
        },
        isLinked: !!links?.cabinClassNotAllowedDomestic,
      }),
      formatPolicy({
        ruleType: PolicyRuleType.CABIN_CLASS_NOT_ALLOWED_INTERNATIONAL,
        preventBooking: {
          prevent: !!policy.cabinClassNotAllowedInternational.length,
          reason: policy.cabinClassNotAllowedInternationalReason,
        },
        props: {
          cabinClassNotAllowedInternationalProps: {
            cabins: policy.cabinClassNotAllowedInternational,
          },
        },
        isLinked: !!links?.cabinClassNotAllowedInternational,
      }),
      formatPolicy({
        ruleType: PolicyRuleType.CAR_TYPES_NOT_ALLOWED,
        preventBooking: {
          prevent: !!policy.notAllowedCarTypes.length,
          reason: policy.notAllowedCarTypeReason,
        },
        props: {
          carTypesNotAllowedProps: {
            carTypes: policy.notAllowedCarTypes,
          },
        },
        isLinked: !!links?.notAllowedCarTypes,
      }),
      formatPolicy({
        ruleType: PolicyRuleType.CAR_ENGINE_TYPES_NOT_ALLOWED,
        preventBooking: {
          prevent: !!policy.notAllowedCarEngineTypes.length,
          reason: '',
        },
        props: {
          carEngineTypesNotAllowedProps: {
            carEngineTypes: policy.notAllowedCarEngineTypes,
          },
        },
        isLinked: !!links?.notAllowedCarEngineTypes,
      }),
      formatPolicy({
        ruleType: PolicyRuleType.MAX_HOTEL_BOOKING_PRICE,
        preventBooking: {
          prevent: !!policy.maxHotelBookingPriceProps,
          reason: '',
        },
        props: {
          maxHotelBookingPriceProps: {
            type: policy.maxHotelBookingPriceProps?.type ?? MaxHotelBookingPricePropsType.LESS_THAN_MEDIAN,
            percentage: policy.maxHotelBookingPriceProps?.percentage,
            isTaxIncluded: !!policy.maxHotelBookingPriceProps?.isTaxIncluded,
          },
        },
        isLinked: isHideHotelMedianRateCustomizationForNonDefaultPolicyEnabled
          ? policy.type !== PolicyTypeEnum.DEFAULT
          : links?.hotelMedianRates === true,
      }),

      formatPolicy({
        ruleType: PolicyRuleType.HOTEL_RATE_CONDITIONS_NOT_ALLOWED,
        preventBooking: {
          prevent: !!policy.notAllowedHotelTypes.length,
          reason: policy.notAllowedHotelReason,
        },
        props: {
          hotelRateConditionsNotAllowedProps: {
            types: policy.notAllowedHotelTypes,
          },
        },
        isLinked: !!links?.notAllowedHotelTypes,
      }),
    ];

    draft.rules = insertBasicEconomyFareRules(policy, links, draft.rules);

    draft.airOopReasonCodes = buildReasonCodesPolicy(
      policy.flightReasonCodes ?? [],
      messages.airOopQuestion,
      !policy.flightCaptureReasonCode,
      links?.flightReasonCodes === true,
    );
    draft.carOopReasonCodes = buildReasonCodesPolicy(
      policy.carReasonCodes ?? [],
      messages.carOopQuestion,
      !policy.carCaptureReasonCode,
      links?.carReasonCodes === true,
    );

    draft.hotelOopReasonCodes = buildReasonCodesPolicy(
      policy.hotelReasonCodes ?? [],
      messages.hotelOopQuestion,
      !policy.hotelCaptureReasonCode,
      links?.hotelReasonCodes === true,
    );

    draft.rules.push(
      formatPolicy({
        ruleType: PolicyRuleType.MAX_RAIL_BOOKING_PRICE_BY_DURATION,
        props: {
          maxRailBookingPriceByDurationProps: getRailMaxPriceDurationProps(policy),
        },
        isLinked: links?.railMaxBookingPrice,
      }),
    );

    draft.rules.push(
      formatPolicy({
        ruleType: PolicyRuleType.HIGHEST_RAIL_TRAVEL_CLASS_BY_DURATION,
        props: {
          highestRailTravelClassByDurationProps: {
            highestTravelClassForDurationList:
              policy.railHighestClass
                ?.filter((item) => !!item.value)
                .map((item) => ({
                  travelClass: item.value as TravelClass,
                  durationRange: {
                    max: item.max,
                    min: item.min,
                  },
                })) ?? [],
          },
        },
        isLinked: links?.railTravelClass,
      }),
    );

    if (typeof policy.railBookingWindow === 'number') {
      draft.rules.push(
        formatPolicy({
          ruleType: PolicyRuleType.RAIL_ADVANCE_BOOKING_WINDOW,
          props: {
            railAdvanceBookingWindowProps: {
              numDaysInAdvance: policy.railBookingWindow,
            },
          },
          isLinked: links?.railBookingWindow === true,
        }),
      );

      draft.railOopReasonCodes = buildReasonCodesPolicy(
        policy.railReasonCodes ?? [],
        messages.railOopQuestion,
        !policy.railCaptureReasonCode,
        links?.railReasonCodes === true,
      );
    }

    draft.lowestLogicalFare = getLlfFromPolicyState(
      links?.llfConfigurations === true,
      policy,
      isLlfIncrementFeatureEnabled,
      isMixedCabinItinerariesEnabled,
    );

    if (hotelMedianRatesFeatureEnabled) {
      draft.hotelMedianRateNightly = getHotelMedianRateNightly(policy);
    }

    if (isFlightBookingWindowEnabled) {
      draft.rules.push(
        formatPolicy({
          ruleType: PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_DOMESTIC,
          props: {
            flightAdvanceBookingWindowDomesticProps: policy.flightBookingWindowDomestic
              ? {
                  numDaysInAdvance: policy.flightBookingWindowDomestic,
                }
              : undefined,
          },
          isLinked: links?.flightBookingWindowDomestic === true,
        }),
      );

      draft.rules.push(
        formatPolicy({
          ruleType: PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_INTERNATIONAL,
          props: {
            flightAdvanceBookingWindowInternationalProps: policy.flightBookingWindowInternational
              ? {
                  numDaysInAdvance: policy.flightBookingWindowInternational,
                }
              : undefined,
          },
          isLinked: links?.flightBookingWindowInternational === true,
        }),
      );
    }
    if (isAncillaryUIEnabled) {
      const newPolicyItem = formatPolicy(
        {
          ruleType: PolicyRuleType.ALLOWED_AIR_ADDONS,
          props: {
            allowedAirAddonsProps: {
              addons: policy.allowedAirAddons ?? [],
            },
          },
          isLinked: links?.allowedAirAddons === true,
        },
        splitPaymentInfoConfig,
      );
      draft.rules.push(newPolicyItem);
    }

    if (isLowestFarePerHotelPropertyEnabled) {
      draft.rules.push(
        formatPolicy({
          ruleType: PolicyRuleType.LOWEST_FARE_PER_HOTEL_PROPERTY,
          props: {
            lowestFarePerHotelPropertyProps: {
              onlyAllowLowestFare: policy.lowestFarePerHotelPropertyProps?.onlyAllowLowestFare ?? false,
            },
          },
          isLinked: links?.lowestFarePerHotelProperty === true,
        }),
      );
    }
  });

  return updatedPolicy;
};

export const insertBasicEconomyFareRules = (
  policy: IPolicyState,
  links: IPolicyLinkingState | null,
  rules: PolicyUniversalRule[],
): PolicyUniversalRule[] => {
  const updatedRules = [...rules];

  if (policy.economyFaresDomesticDisallowed) {
    updatedRules.push(
      formatPolicy({
        ruleType: PolicyRuleType.BASIC_ECONOMY_FARES_DOMESTIC,
        preventBooking: {
          prevent: policy.economyFaresDomesticDisallowed,
          reason: policy.economyFaresDomesticReason,
        },
        isLinked: !!links?.economyFaresDomestic,
      }),
    );
  }

  if (policy.economyFaresInternationalDisallowed) {
    updatedRules.push(
      formatPolicy({
        ruleType: PolicyRuleType.BASIC_ECONOMY_FARES_INTERNATIONAL,
        preventBooking: {
          prevent: policy.economyFaresInternationalDisallowed,
          reason: policy.economyFaresInternationalReason,
        },
        isLinked: !!links?.economyFaresInternational,
      }),
    );
  }

  return updatedRules;
};

export const getEmptyEmployeePolicyState = (name?: string): IPolicyState => ({
  ...emptyEmployeePolicyState,
  name: name ?? '',
});

export const getEmptyNonEmployeePolicyState = (name?: string): IPolicyState => ({
  ...emptyNonEmployeePolicyState,
  name: name ?? '',
});

interface IGetPolicyDiffParams {
  updatedPolicy: IPolicyState;
  originalPolicy: IPolicyState;
  updatedLinks: IPolicyLinkingState | null;
  originalLinks: IPolicyLinkingState | null;
  messages: IPolicyMessages;
  policyResponse?: IPolicy;
  featureFlagsConfig?: FeatureFlagConfiguration;
}

export const getPolicyDiff = ({
  messages,
  originalLinks,
  originalPolicy,
  updatedLinks,
  updatedPolicy,
  policyResponse,
  featureFlagsConfig = {},
}: IGetPolicyDiffParams): { types: PolicyRuleType[]; policy: IPolicy } => {
  const {
    hotelMedianRatesFeatureEnabled = false,
    isFlightBookingWindowEnabled = false,
    isLlfIncrementFeatureEnabled = false,
    isMixedCabinItinerariesEnabled = false,
    isAncillaryUIEnabled = false,
    isPolicyAirDomIntlSplitEnabled = false,
    hotelMedianRatesFeatureEnabledV3 = false,
    isHideHotelMedianRateCustomizationForNonDefaultPolicyEnabled = false,
    isPolicyCustomizeCabinByMileage = false,
    splitPaymentInfoConfig = undefined,
    isLowestFarePerHotelPropertyEnabled = false,
  } = featureFlagsConfig;
  const updateTypes: PolicyRuleType[] = [];
  const policyData: Partial<IPolicy> = {
    type: policyResponse?.type,
  };
  const policyRules: PolicyUniversalRule[] = [];

  const isLinkChanged = (key: keyof IPolicyLinkingState) => !isEqual(originalLinks?.[key], updatedLinks?.[key]);
  const isPolicyChanged = (key: keyof IPolicyState) => !isEqual(originalPolicy[key], updatedPolicy[key]);

  if (originalPolicy.name.trim() !== updatedPolicy.name.trim()) {
    policyData.name = updatedPolicy.name.trim();
    updateTypes.push(PolicyRuleType.NAME);
  }
  if (updatedPolicy.parentPolicyId && originalPolicy.parentPolicyId !== updatedPolicy.parentPolicyId) {
    policyData.parentPolicyId = { id: updatedPolicy.parentPolicyId };
    updateTypes.push(PolicyRuleType.PARENT_POLICY_ID);
  }

  if (isPolicyChanged('currency') || isLinkChanged('currency')) {
    policyData.currency = updatedLinks?.currency === true ? { isLinked: true } : { isoCode: updatedPolicy.currency };
    updateTypes.push(PolicyRuleType.CURRENCY);
  }

  if (
    isPolicyChanged('inPolicyApprovalTypeForAir') ||
    isPolicyChanged('inPolicyApprovalTypeForInternationalAir') ||
    isPolicyChanged('inPolicyApprovalTypeForDomesticAir') ||
    isPolicyChanged('inPolicyApprovalTypeForCar') ||
    isPolicyChanged('inPolicyApprovalTypeForRail') ||
    isPolicyChanged('inPolicyApprovalTypeForHotel') ||
    isPolicyChanged('outOfPolicyApprovalTypeForAir') ||
    isPolicyChanged('outOfPolicyApprovalTypeForInternationalAir') ||
    isPolicyChanged('outOfPolicyApprovalTypeForDomesticAir') ||
    isPolicyChanged('outOfPolicyApprovalTypeForCar') ||
    isPolicyChanged('outOfPolicyApprovalTypeForRail') ||
    isPolicyChanged('outOfPolicyApprovalTypeForHotel') ||
    isPolicyChanged('policyApproverIsManager') ||
    isPolicyChanged('policyApproverIsEmployeeLevel') ||
    isPolicyChanged('shouldNotifyManager') ||
    isPolicyChanged('policyApprover') ||
    isPolicyChanged('policyDefaultApprover')
  ) {
    policyData.inPolicyApprovalInfos = isPolicyAirDomIntlSplitEnabled
      ? getCustomAirInPolicyApprovalInfos({ policy: updatedPolicy })
      : getInPolicyApprovalInfos({ policy: updatedPolicy });
    policyData.outOfPolicyApprovalInfos = isPolicyAirDomIntlSplitEnabled
      ? getCustomAirOutOfPolicyApprovalInfos({ policy: updatedPolicy })
      : getOutOfPolicyApprovalInfos({ policy: updatedPolicy });
    updateTypes.push(PolicyRuleType.IN_POLICY_APPROVAL_INFOS);
    updateTypes.push(PolicyRuleType.OUT_OF_POLICY_APPROVAL_INFOS);
  }

  // car customizations
  if (isPolicyChanged('maxCarPricePerNumberOfDays') || isLinkChanged('maxCarPricePerNumberOfDays')) {
    let props;
    if (updatedPolicy.maxCarPricePerNumberOfDays !== '') {
      props = {
        numDays: 1,
        maxPrice: {
          amount: Number(updatedPolicy.maxCarPricePerNumberOfDays),
          currencyCode: updatedPolicy.currency,
        },
      };
    }
    policyData.maxCarPricePerNumberOfDays =
      updatedLinks?.maxCarPricePerNumberOfDays === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.MAX_CAR_PRICE_PER_NUMBER_OF_DAYS);
  }
  if (isPolicyChanged('allowedCarTypes') || isLinkChanged('allowedCarTypes')) {
    let props;
    if (updatedPolicy.allowedCarTypes.length !== 0) {
      props = {
        carTypes: updatedPolicy.allowedCarTypes as unknown as ICarType[],
      };
    }
    policyData.allowedCarTypes = updatedLinks?.allowedCarTypes === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.ALLOWED_CAR_TYPES);
  }

  // hotel customizations
  if (isPolicyChanged('hotelBookingWindow') || isLinkChanged('hotelBookingWindow')) {
    let props;
    if (updatedPolicy.hotelBookingWindow !== '') {
      props = {
        numDaysInAdvance: Number(updatedPolicy.hotelBookingWindow),
      };
    }
    policyData.hotelAdvanceBookingWindow = updatedLinks?.hotelBookingWindow === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.HOTEL_ADVANCE_BOOKING_WINDOW);
  }
  if (isPolicyChanged('hotelCancellationPolicy') || isLinkChanged('hotelCancellationPolicy')) {
    let props;
    if (updatedPolicy.hotelCancellationPolicy !== '') {
      props = {
        type: updatedPolicy.hotelCancellationPolicy as unknown as IHotelCancellationPropsType,
      };
    }
    policyData.hotelCancellation = updatedLinks?.hotelCancellationPolicy === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.HOTEL_CANCELLATION);
  }
  if (isPolicyChanged('hotelExperience') || isLinkChanged('hotelExperience')) {
    let props;
    if (updatedPolicy.hotelExperience.min !== undefined && updatedPolicy.hotelExperience.max !== undefined) {
      props = {
        ratingRange: {
          min: updatedPolicy.hotelExperience.min,
          max: updatedPolicy.hotelExperience.max,
        },
      };
    }
    policyData.hotelRating = updatedLinks?.hotelExperience === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.HOTEL_RATING);
  }
  const taxAndFeesChanged = originalPolicy.maxHotelPriceTaxInclusion !== updatedPolicy.maxHotelPriceTaxInclusion;
  if (
    isPolicyChanged('maxHotelPriceByLocationDefaultPriceAmount') ||
    isPolicyChanged('maxHotelPriceByLocationCustomizations') ||
    isLinkChanged('maxHotelPriceByLocation') ||
    taxAndFeesChanged
  ) {
    policyData.maxHotelPriceByLocation =
      updatedLinks?.maxHotelPriceByLocation === true
        ? { isLinked: true }
        : {
            props:
              updatedPolicy.maxHotelPriceByLocationDefaultPriceAmount === '' &&
              updatedPolicy.maxHotelPriceByLocationCustomizations.every((item) => item.amount === '')
                ? undefined
                : {
                    defaultMaxPrice:
                      updatedPolicy.maxHotelPriceByLocationDefaultPriceAmount === ''
                        ? undefined
                        : {
                            amount: Number(updatedPolicy.maxHotelPriceByLocationDefaultPriceAmount),
                            currencyCode: updatedPolicy.currency,
                            convertedAmount: 0,
                            convertedCurrency: '',
                          },
                    maxPriceInLocationList: updatedPolicy.maxHotelPriceByLocationCustomizations
                      .filter(({ amount }) => amount !== '')
                      .map(({ address, amount, currency: currencyCode }) => ({
                        address,
                        maxPrice: {
                          amount: Number(amount),
                          currencyCode,
                          convertedAmount: 0,
                          convertedCurrency: '',
                        },
                      })),
                    isTaxIncluded: updatedPolicy.maxHotelPriceTaxInclusion,
                  },
          };
    updateTypes.push(PolicyRuleType.MAX_HOTEL_PRICE_BY_LOCATION);
  }

  if (isPolicyChanged('hotelRestrictByKeywords') || isLinkChanged('hotelRestrictByKeywords')) {
    const rule: PolicyUniversalRule = formatPolicy({
      ruleType: PolicyRuleType.HOTEL_RESTRICTED_KEYWORDS,
      props: {
        hotelRestrictedKeywordsProps: {
          restrictedKeywords: updatedPolicy.hotelRestrictByKeywords ?? [],
        },
      },
      preventBooking: {
        prevent: true,
        reason: '',
      },
      isLinked: !!updatedLinks?.hotelRestrictByKeywords,
    });
    updateTypes.push(PolicyRuleType.HOTEL_RESTRICTED_KEYWORDS);
    policyRules.push(rule);
  }

  if (
    isLowestFarePerHotelPropertyEnabled &&
    (isPolicyChanged('lowestFarePerHotelPropertyProps') || isLinkChanged('lowestFarePerHotelProperty'))
  ) {
    const rule: PolicyUniversalRule = formatPolicy({
      ruleType: PolicyRuleType.LOWEST_FARE_PER_HOTEL_PROPERTY,
      props: {
        lowestFarePerHotelPropertyProps: {
          onlyAllowLowestFare: updatedPolicy.lowestFarePerHotelPropertyProps?.onlyAllowLowestFare ?? false,
        },
      },
      isLinked: !!updatedLinks?.lowestFarePerHotelProperty,
    });
    updateTypes.push(PolicyRuleType.LOWEST_FARE_PER_HOTEL_PROPERTY);
    policyRules.push(rule);
  }

  // air customizations
  if (isPolicyChanged('flightBookingWindow') || isLinkChanged('flightBookingWindow')) {
    let props;
    if (updatedPolicy.flightBookingWindow !== undefined) {
      props = {
        numDaysInAdvance: updatedPolicy.flightBookingWindow,
      };
    }
    policyData.flightAdvanceBookingWindow = updatedLinks?.flightBookingWindow === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW);
  }

  if (isFlightBookingWindowEnabled) {
    const isFlightBookingWindowDomesticChanged =
      isPolicyChanged('flightBookingWindowDomestic') || isLinkChanged('flightBookingWindowDomestic');
    const isFlightBookingWindowInternationalChanged =
      isPolicyChanged('flightBookingWindowInternational') || isLinkChanged('flightBookingWindowInternational');

    if (isFlightBookingWindowDomesticChanged) {
      if (updatedPolicy.flightBookingWindowDomestic !== undefined || !!updatedLinks?.flightBookingWindowDomestic) {
        const rule: PolicyUniversalRule = formatPolicy({
          ruleType: PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_DOMESTIC,
          props: {
            flightAdvanceBookingWindowDomesticProps: updatedPolicy.flightBookingWindowDomestic
              ? {
                  numDaysInAdvance: updatedPolicy.flightBookingWindowDomestic,
                }
              : undefined,
          },
          preventBooking: {
            prevent: false,
            reason: '',
          },
          isLinked: !!updatedLinks?.flightBookingWindowDomestic,
        });
        policyRules.push(rule);
      }
      updateTypes.push(PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_DOMESTIC);
    }

    if (isFlightBookingWindowInternationalChanged) {
      if (
        updatedPolicy.flightBookingWindowInternational !== undefined ||
        !!updatedLinks?.flightBookingWindowInternational
      ) {
        const rule: PolicyUniversalRule = formatPolicy({
          ruleType: PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_INTERNATIONAL,
          props: {
            flightAdvanceBookingWindowInternationalProps: updatedPolicy.flightBookingWindowInternational
              ? {
                  numDaysInAdvance: updatedPolicy.flightBookingWindowInternational,
                }
              : undefined,
          },
          preventBooking: {
            prevent: false,
            reason: '',
          },
          isLinked: !!updatedLinks?.flightBookingWindowInternational,
        });
        policyRules.push(rule);
      }
      updateTypes.push(PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_INTERNATIONAL);
    }

    // if flightBookingWindow is set or is linked to default policy then unset
    if (updatedPolicy.flightBookingWindow !== undefined || !!updatedLinks?.flightBookingWindow) {
      // if flight booking window is not changed set it to flightBookingWindow
      if (!isFlightBookingWindowDomesticChanged) {
        const rule: PolicyUniversalRule = formatPolicy({
          ruleType: PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_DOMESTIC,
          props: {
            flightAdvanceBookingWindowDomesticProps: updatedPolicy.flightBookingWindow
              ? {
                  numDaysInAdvance: updatedPolicy.flightBookingWindow,
                }
              : undefined,
          },
          preventBooking: {
            prevent: false,
            reason: '',
          },
          isLinked: !!updatedLinks?.flightBookingWindow,
        });
        updateTypes.push(PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_DOMESTIC);
        policyRules.push(rule);
      }

      // same for international
      if (!isFlightBookingWindowInternationalChanged) {
        const rule: PolicyUniversalRule = formatPolicy({
          ruleType: PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_INTERNATIONAL,
          props: {
            flightAdvanceBookingWindowInternationalProps: updatedPolicy.flightBookingWindow
              ? {
                  numDaysInAdvance: updatedPolicy.flightBookingWindow,
                }
              : undefined,
          },
          preventBooking: {
            prevent: false,
            reason: '',
          },
          isLinked: !!updatedLinks?.flightBookingWindow,
        });
        updateTypes.push(PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW_INTERNATIONAL);
        policyRules.push(rule);
      }

      // unset flightAdvanceBookingWindow
      policyData.flightAdvanceBookingWindow = {};
      updateTypes.push(PolicyRuleType.FLIGHT_ADVANCE_BOOKING_WINDOW);
    }
  }

  if (isPolicyChanged('co2EmissionPerPassengerPerKm') || isLinkChanged('co2EmissionPerPassengerPerKm')) {
    let props;
    if (updatedPolicy.co2EmissionPerPassengerPerKm !== '') {
      props = {
        emission: Number(updatedPolicy.co2EmissionPerPassengerPerKm),
      };
    }
    policyData.co2EmissionPerPassengerPerKm = updatedLinks?.co2EmissionPerPassengerPerKm
      ? { isLinked: true }
      : { props };
    updateTypes.push(PolicyRuleType.CO2_EMISSION_PER_PASSENGER_PER_KM);
  }

  if (isPolicyChanged('flightCabinUpgrade') || isLinkChanged('flightCabinUpgrade')) {
    let props;
    if (updatedPolicy.flightCabinUpgrade !== undefined) {
      props = {
        isAllowed: updatedPolicy.flightCabinUpgrade,
      };
    }
    policyData.flightCabinUpgrade = updatedLinks?.flightCabinUpgrade === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.FLIGHT_CABIN_UPGRADE);
  }

  if (isPolicyChanged('flightRefundableTickets') || isLinkChanged('flightRefundableTickets')) {
    let props;
    if (updatedPolicy.flightRefundableTickets !== '') {
      props = {
        type: updatedPolicy.flightRefundableTickets as unknown as FlightTicketsRefundablePropsType,
      };
    }
    policyData.flightTicketsRefundable =
      updatedLinks?.flightRefundableTickets === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.FLIGHT_TICKETS_REFUNDABLE);
  }

  if (isPolicyChanged('flightChangeableTickets') || isLinkChanged('flightChangeableTickets')) {
    let props;
    if (updatedPolicy.flightChangeableTickets !== '') {
      props = {
        type: updatedPolicy.flightChangeableTickets as unknown as FlightTicketsChangeablePropsType,
      };
    }
    policyData.flightTicketsChangeable =
      updatedLinks?.flightChangeableTickets === true ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.FLIGHT_TICKETS_CHANGEABLE);
  }

  if (
    isPolicyChanged('highestFlightCabinOvernight') ||
    isPolicyChanged('overNightFlightTimeStart') ||
    isPolicyChanged('overNightFlightTimeEnd') ||
    isPolicyChanged('overNightFlightIncludeLayover') ||
    isPolicyChanged('overNightFlightTimeOverlapMins') ||
    isLinkChanged('highestFlightCabinOvernight')
  ) {
    let props;
    if (updatedPolicy.highestFlightCabinOvernight !== '-1') {
      props = {
        cabin: Number(updatedPolicy.highestFlightCabinOvernight) as unknown as Cabin,
        overnightTimeParams: {
          nightTimeStart: updatedPolicy.overNightFlightTimeStart,
          nightTimeEnd: updatedPolicy.overNightFlightTimeEnd,
          includeLayover: updatedPolicy.overNightFlightIncludeLayover,
          nightTimeOverlapMins: updatedPolicy.overNightFlightTimeOverlapMins,
        } as OvernightTimeParams,
      };
    }
    policyData.highestFlightCabinOvernight = updatedLinks?.highestFlightCabinOvernight ? { isLinked: true } : { props };
    updateTypes.push(PolicyRuleType.HIGHEST_FLIGHT_CABIN_FOR_OVERNIGHT);
  }

  // TODO (Bhavan): Add E2E tests to validate 14274, 15695 and 15841 tickets
  if (
    isPolicyChanged('highestFlightCabinByDurationInternational') ||
    isLinkChanged('highestFlightCabinByDurationInternational')
  ) {
    let props;
    if (!updatedPolicy.highestFlightCabinByDurationInternational.every((item) => item.value === '-1')) {
      if (isPolicyCustomizeCabinByMileage) {
        props = {
          highestCabinForMilageList:
            updatedPolicy.highestFlightCabinByDurationInternational.length === 1
              ? [
                  {
                    cabin: Number(updatedPolicy.highestFlightCabinByDurationInternational[0].value) as ICabin,
                    mileRange: { min: 0, max: 0 },
                  },
                ]
              : updatedPolicy.highestFlightCabinByDurationInternational
                  .filter(({ value }) => value !== '-1')
                  .map(({ value, min, max }) => ({
                    cabin: Number(value) as ICabin,
                    mileRange: {
                      min,
                      max,
                    },
                  })),
          highestCabinForDurationList: [],
        };
      } else {
        props = {
          highestCabinForDurationList:
            updatedPolicy.highestFlightCabinByDurationInternational.length === 1
              ? [
                  {
                    cabin: Number(updatedPolicy.highestFlightCabinByDurationInternational[0].value) as ICabin,
                    durationRange: { min: 0, max: 0 },
                    durationInMinutes: 0,
                  },
                ]
              : updatedPolicy.highestFlightCabinByDurationInternational
                  .filter(({ value }) => value !== '-1')
                  .map(({ value, min, max }, custIndex, custArr) => ({
                    cabin: Number(value) as ICabin,
                    durationRange: {
                      min,
                      max: custIndex === custArr.length - 1 && max === 1800 ? 0 : max,
                    },
                    durationInMinutes: 0,
                  })),
          highestCabinForMilageList: [],
        };
      }
    }
    policyData.highestFlightCabinByDurationInternational =
      updatedLinks?.highestFlightCabinByDurationInternational === true
        ? { isLinked: true }
        : {
            props,
            ...(splitPaymentInfoConfig && {
              splitPaymentInfo: {
                shouldSplitPayment: splitPaymentInfoConfig.cabinShouldSplit,
                chargeTotalFareOnPersonalFop: splitPaymentInfoConfig.cabinPersonalFop,
              },
            }),
          };
    updateTypes.push(PolicyRuleType.HIGHEST_FLIGHT_CABIN_BY_DURATION_INTERNATIONAL);
  }

  if (
    isPolicyChanged('highestFlightCabinByDurationDomestic') ||
    isLinkChanged('highestFlightCabinByDurationDomestic')
  ) {
    let props;
    if (!updatedPolicy.highestFlightCabinByDurationDomestic.every((item) => item.value === '-1')) {
      if (isPolicyCustomizeCabinByMileage) {
        props = {
          highestCabinForMilageList:
            updatedPolicy.highestFlightCabinByDurationDomestic.length === 1
              ? [
                  {
                    cabin: Number(updatedPolicy.highestFlightCabinByDurationDomestic[0].value) as ICabin,
                    mileRange: { min: 0, max: 0 },
                  },
                ]
              : updatedPolicy.highestFlightCabinByDurationDomestic
                  .filter(({ value }) => value !== '-1')
                  .map(({ value, min, max }) => ({
                    cabin: Number(value) as ICabin,
                    mileRange: {
                      min,
                      max,
                    },
                  })),
          highestCabinForDurationList: [],
        };
      } else {
        props = {
          highestCabinForDurationList:
            updatedPolicy.highestFlightCabinByDurationDomestic.length === 1
              ? [
                  {
                    cabin: Number(updatedPolicy.highestFlightCabinByDurationDomestic[0].value) as ICabin,
                    durationRange: { min: 0, max: 0 },
                    durationInMinutes: 0,
                  },
                ]
              : updatedPolicy.highestFlightCabinByDurationDomestic
                  .filter(({ value }) => value !== '-1')
                  .map(({ value, min, max }, custIndex, custArr) => ({
                    cabin: Number(value) as ICabin,
                    durationRange: {
                      min,
                      max: custIndex === custArr.length - 1 && max === 1800 ? 0 : max,
                    },
                    durationInMinutes: 0,
                  })),
          highestCabinForMilageList: [],
        };
      }
    }
    policyData.highestFlightCabinByDurationDomestic =
      updatedLinks?.highestFlightCabinByDurationDomestic === true
        ? { isLinked: true }
        : {
            props,
            ...(splitPaymentInfoConfig && {
              splitPaymentInfo: {
                shouldSplitPayment: splitPaymentInfoConfig.cabinShouldSplit,
                chargeTotalFareOnPersonalFop: splitPaymentInfoConfig.cabinPersonalFop,
              },
            }),
          };

    updateTypes.push(PolicyRuleType.HIGHEST_FLIGHT_CABIN_BY_DURATION_DOMESTIC);
  }

  if (isPolicyChanged('maxFlightBookingPriceByDuration') || isLinkChanged('maxFlightBookingPriceByDuration')) {
    let props;
    if (!updatedPolicy.maxFlightBookingPriceByDuration.every((item) => item.value === '')) {
      props = getFlightMaxPriceDurationProps(updatedPolicy);
    }
    if (updatedLinks?.maxFlightBookingPriceByDuration === true) {
      policyData.maxFlightBookingPriceByDuration = { isLinked: true };
    } else {
      policyData.maxFlightBookingPriceByDuration = !props ? {} : { props };
    }
    updateTypes.push(PolicyRuleType.MAX_FLIGHT_BOOKING_PRICE_BY_DURATION);
  }

  if (isPolicyChanged('maxFlightBookingPriceDomestic') || isLinkChanged('maxFlightBookingPriceDomestic')) {
    let props;
    if (!isEmpty(updatedPolicy.maxFlightBookingPriceDomestic)) {
      props = {
        ...updatedPolicy.maxFlightBookingPriceDomestic,
        isTaxIncluded: true,
      };
    }
    policyData.maxFlightBookingPriceDomestic = updatedLinks?.maxFlightBookingPriceDomestic
      ? { isLinked: true }
      : { ...(props && { props }) };
    updateTypes.push(PolicyRuleType.MAX_FLIGHT_BOOKING_PRICE_DOMESTIC);
  }

  if (isPolicyChanged('maxFlightBookingPriceInternational') || isLinkChanged('maxFlightBookingPriceInternational')) {
    let props;
    if (!isEmpty(updatedPolicy.maxFlightBookingPriceInternational)) {
      props = {
        ...updatedPolicy.maxFlightBookingPriceInternational,
        isTaxIncluded: true,
      };
    }
    policyData.maxFlightBookingPriceInternational = updatedLinks?.maxFlightBookingPriceInternational
      ? { isLinked: true }
      : { ...(props && { props }) };
    updateTypes.push(PolicyRuleType.MAX_FLIGHT_BOOKING_PRICE_INTERNATIONAL);
  }

  if (
    isLinkChanged('llfConfigurations') ||
    isPolicyChanged('llfAirportConnectionChange') ||
    isPolicyChanged('llfCarrierAirlines') ||
    isPolicyChanged('llfCarrierType') ||
    isPolicyChanged('llfFlightTimeWindowDomestic') ||
    isPolicyChanged('llfFlightTimeWindowInternational') ||
    isPolicyChanged('llfLayoverDurationInHoursDomestic') ||
    isPolicyChanged('llfLayoverDurationInHoursInternational') ||
    isPolicyChanged('llfMaxNumberOfStops') ||
    isPolicyChanged('llfMaxLayoverDurationMinutesDomestic') ||
    isPolicyChanged('llfMaxLayoverDurationMinutesInternational') ||
    isPolicyChanged('llfFlightTimeWindowMinutesDomestic') ||
    isPolicyChanged('llfFlightTimeWindowMinutesInternational') ||
    isPolicyChanged('llfRejectMixCabinFlights')
  ) {
    policyData.lowestLogicalFare = getLlfFromPolicyState(
      updatedLinks?.llfConfigurations === true,
      updatedPolicy,
      isLlfIncrementFeatureEnabled,
      isMixedCabinItinerariesEnabled,
    );
    updateTypes.push(PolicyRuleType.LOWEST_LOGICAL_FARE);
  }

  if (isPolicyChanged('usersGroups')) {
    policyData.usersGroups = getPolicyUserGroups(updatedPolicy);
    updateTypes.push(PolicyRuleType.USER_GROUPS);
  }

  if (
    isPolicyChanged('economyFaresDomesticDisallowed') ||
    isPolicyChanged('economyFaresDomesticReason') ||
    isLinkChanged('economyFaresDomestic')
  ) {
    // TODO (Bhavan): Refactor Universal rule handling  Spot fixing for now,
    // as changing will effect other universal rule implementations

    let rule: PolicyUniversalRule;

    if (updatedLinks?.economyFaresDomestic) {
      rule = {
        ruleType: PolicyRuleType.BASIC_ECONOMY_FARES_DOMESTIC,
        preventBooking: undefined,
        isLinked: !!updatedLinks?.economyFaresDomestic,
      };
      policyRules.push(rule);
    } else if (updatedPolicy.economyFaresDomesticDisallowed) {
      rule = {
        ruleType: PolicyRuleType.BASIC_ECONOMY_FARES_DOMESTIC,
        preventBooking: {
          prevent: updatedPolicy.economyFaresDomesticDisallowed,
          reason: updatedPolicy.economyFaresDomesticReason,
        },
      };
      policyRules.push(rule);
    }

    updateTypes.push(PolicyRuleType.BASIC_ECONOMY_FARES_DOMESTIC);
  }

  if (
    isPolicyChanged('economyFaresInternationalDisallowed') ||
    isPolicyChanged('economyFaresInternationalReason') ||
    isLinkChanged('economyFaresInternational')
  ) {
    // TODO (Bhavan): Refactor Universal rule handling  Spot fixing for now,
    // as changing will effect other universal rule implementations
    let rule: PolicyUniversalRule;

    if (updatedLinks?.economyFaresInternational) {
      rule = {
        ruleType: PolicyRuleType.BASIC_ECONOMY_FARES_INTERNATIONAL,
        preventBooking: undefined,
        isLinked: !!updatedLinks?.economyFaresInternational,
      };
      policyRules.push(rule);
    } else if (updatedPolicy.economyFaresInternationalDisallowed) {
      rule = {
        ruleType: PolicyRuleType.BASIC_ECONOMY_FARES_INTERNATIONAL,
        preventBooking: {
          prevent: updatedPolicy.economyFaresInternationalDisallowed,
          reason: updatedPolicy.economyFaresInternationalReason,
        },
      };
      policyRules.push(rule);
    }
    updateTypes.push(PolicyRuleType.BASIC_ECONOMY_FARES_INTERNATIONAL);
  }

  if (
    isPolicyChanged('cabinClassNotAllowedDomestic') ||
    isPolicyChanged('cabinClassNotAllowedDomesticReason') ||
    isLinkChanged('cabinClassNotAllowedDomestic')
  ) {
    const rule: PolicyUniversalRule = formatPolicy({
      ruleType: PolicyRuleType.CABIN_CLASS_NOT_ALLOWED_DOMESTIC,
      preventBooking: {
        prevent: !!updatedPolicy.cabinClassNotAllowedDomestic.length,
        reason: updatedPolicy.cabinClassNotAllowedDomesticReason,
      },
      props: {
        cabinClassNotAllowedDomesticProps: {
          cabins: updatedPolicy.cabinClassNotAllowedDomestic,
        },
      },
      isLinked: !!updatedLinks?.cabinClassNotAllowedDomestic,
    });
    updateTypes.push(PolicyRuleType.CABIN_CLASS_NOT_ALLOWED_DOMESTIC);
    policyRules.push(rule);
  }

  if (
    isPolicyChanged('cabinClassNotAllowedInternational') ||
    isPolicyChanged('cabinClassNotAllowedInternationalReason') ||
    isLinkChanged('cabinClassNotAllowedInternational')
  ) {
    const rule: PolicyUniversalRule = formatPolicy({
      ruleType: PolicyRuleType.CABIN_CLASS_NOT_ALLOWED_INTERNATIONAL,
      preventBooking: {
        prevent: !!updatedPolicy.cabinClassNotAllowedInternational.length,
        reason: updatedPolicy.cabinClassNotAllowedInternationalReason,
      },
      props: {
        cabinClassNotAllowedInternationalProps: {
          cabins: updatedPolicy.cabinClassNotAllowedInternational,
        },
      },
      isLinked: !!updatedLinks?.cabinClassNotAllowedInternational,
    });
    updateTypes.push(PolicyRuleType.CABIN_CLASS_NOT_ALLOWED_INTERNATIONAL);
    policyRules.push(rule);
  }

  if (
    isPolicyChanged('notAllowedCarTypes') ||
    isPolicyChanged('notAllowedCarTypeReason') ||
    isLinkChanged('notAllowedCarTypes')
  ) {
    const rule: PolicyUniversalRule = formatPolicy({
      ruleType: PolicyRuleType.CAR_TYPES_NOT_ALLOWED,
      preventBooking: {
        prevent: !!updatedPolicy.notAllowedCarTypes.length,
        reason: updatedPolicy.notAllowedCarTypeReason,
      },
      props: {
        carTypesNotAllowedProps: {
          carTypes: updatedPolicy.notAllowedCarTypes,
        },
      },
      isLinked: !!updatedLinks?.notAllowedCarTypes,
    });
    updateTypes.push(PolicyRuleType.CAR_TYPES_NOT_ALLOWED);
    policyRules.push(rule);
  }

  if (isPolicyChanged('notAllowedCarEngineTypes') || isLinkChanged('notAllowedCarEngineTypes')) {
    const rule: PolicyUniversalRule = formatPolicy({
      ruleType: PolicyRuleType.CAR_ENGINE_TYPES_NOT_ALLOWED,
      preventBooking: {
        prevent: !!updatedPolicy.notAllowedCarEngineTypes.length,
        reason: '',
      },
      props: {
        carEngineTypesNotAllowedProps: {
          carEngineTypes: updatedPolicy.notAllowedCarEngineTypes as unknown as ICarEngineType[],
        },
      },
      isLinked: !!updatedLinks?.notAllowedCarEngineTypes,
    });
    updateTypes.push(PolicyRuleType.CAR_ENGINE_TYPES_NOT_ALLOWED);
    policyRules.push(rule);
  }

  if (
    isPolicyChanged('notAllowedHotelTypes') ||
    isPolicyChanged('notAllowedHotelReason') ||
    isLinkChanged('notAllowedHotelTypes')
  ) {
    const rule: PolicyUniversalRule = formatPolicy({
      ruleType: PolicyRuleType.HOTEL_RATE_CONDITIONS_NOT_ALLOWED,
      preventBooking: {
        prevent: !!updatedPolicy.notAllowedHotelTypes.length,
        reason: updatedPolicy.notAllowedHotelReason,
      },
      props: {
        hotelRateConditionsNotAllowedProps: {
          types: updatedPolicy.notAllowedHotelTypes,
        },
      },
      isLinked: !!updatedLinks?.notAllowedHotelTypes,
    });
    updateTypes.push(PolicyRuleType.HOTEL_RATE_CONDITIONS_NOT_ALLOWED);
    policyRules.push(rule);
  }

  if (
    isPolicyChanged('flightReasonCodes') ||
    isPolicyChanged('flightCaptureReasonCode') ||
    isLinkChanged('flightReasonCodes')
  ) {
    policyData.airOopReasonCodes = buildReasonCodesPolicy(
      updatedPolicy.flightReasonCodes,
      messages.airOopQuestion,
      !updatedPolicy.flightCaptureReasonCode,
      updatedLinks?.flightReasonCodes === true,
    );
    updateTypes.push(PolicyRuleType.AIR_OOP_REASON_CODES);
  }

  if (
    isPolicyChanged('hotelReasonCodes') ||
    isPolicyChanged('hotelCaptureReasonCode') ||
    isLinkChanged('hotelReasonCodes')
  ) {
    policyData.hotelOopReasonCodes = buildReasonCodesPolicy(
      updatedPolicy.hotelReasonCodes,
      messages.hotelOopQuestion,
      !updatedPolicy.hotelCaptureReasonCode,
      updatedLinks?.hotelReasonCodes === true,
    );
    updateTypes.push(PolicyRuleType.HOTEL_OOP_REASON_CODES);
  }

  if (isPolicyChanged('carReasonCodes') || isPolicyChanged('carCaptureReasonCode') || isLinkChanged('carReasonCodes')) {
    policyData.carOopReasonCodes = buildReasonCodesPolicy(
      updatedPolicy.carReasonCodes,
      messages.carOopQuestion,
      !updatedPolicy.carCaptureReasonCode,
      updatedLinks?.carReasonCodes === true,
    );
    updateTypes.push(PolicyRuleType.CAR_OOP_REASON_CODES);
  }

  if (
    isPolicyChanged('railReasonCodes') ||
    isPolicyChanged('railCaptureReasonCode') ||
    isLinkChanged('railReasonCodes')
  ) {
    policyData.railOopReasonCodes = buildReasonCodesPolicy(
      updatedPolicy.railReasonCodes,
      messages.railOopQuestion,
      !updatedPolicy.railCaptureReasonCode,
      updatedLinks?.railReasonCodes === true,
    );
    updateTypes.push(PolicyRuleType.RAIL_OOP_REASON_CODES);
  }

  if (isPolicyChanged('railMaxPrice') || isLinkChanged('railMaxBookingPrice')) {
    policyRules.push(
      formatPolicy({
        ruleType: PolicyRuleType.MAX_RAIL_BOOKING_PRICE_BY_DURATION,
        props: {
          maxRailBookingPriceByDurationProps: getRailMaxPriceDurationProps(updatedPolicy),
        },
        isLinked: updatedLinks?.railMaxBookingPrice,
      }),
    );
    updateTypes.push(PolicyRuleType.MAX_RAIL_BOOKING_PRICE_BY_DURATION);
  }

  if (isPolicyChanged('railHighestClass') || isLinkChanged('railTravelClass')) {
    const supportedItems = updatedPolicy.railHighestClass.filter((item) => !!item.value);

    policyRules.push(
      formatPolicy({
        ruleType: PolicyRuleType.HIGHEST_RAIL_TRAVEL_CLASS_BY_DURATION,
        props: {
          highestRailTravelClassByDurationProps: {
            highestTravelClassForDurationList: supportedItems?.map((item, index) => ({
              travelClass: item.value as TravelClass,
              durationRange: {
                max: index === supportedItems.length - 1 ? 0 : item.max,
                min: item.min,
              },
            })),
          },
        },
        isLinked: updatedLinks?.railTravelClass,
      }),
    );
    updateTypes.push(PolicyRuleType.HIGHEST_RAIL_TRAVEL_CLASS_BY_DURATION);
  }

  if (isPolicyChanged('railBookingWindow') || isLinkChanged('railBookingWindow')) {
    if (typeof updatedPolicy.railBookingWindow === 'number') {
      policyRules.push(
        formatPolicy({
          ruleType: PolicyRuleType.RAIL_ADVANCE_BOOKING_WINDOW,
          props: {
            railAdvanceBookingWindowProps: {
              numDaysInAdvance: updatedPolicy.railBookingWindow,
            },
          },
          isLinked: updatedLinks?.railBookingWindow === true,
        }),
      );
    }
    updateTypes.push(PolicyRuleType.RAIL_ADVANCE_BOOKING_WINDOW);
  }

  if (
    hotelMedianRatesFeatureEnabled &&
    (isPolicyChanged('hotelMedianDistance') ||
      isPolicyChanged('hotelMedianDistanceUnit') ||
      isPolicyChanged('hotelMedianMaxRate') ||
      isPolicyChanged('hotelMedianMinRate') ||
      isPolicyChanged('hotelMedianEnabled') ||
      isLinkChanged('hotelMedianRates'))
  ) {
    policyData.hotelMedianRateNightly = getHotelMedianRateNightly(updatedPolicy);
    updateTypes.push(PolicyRuleType.HOTEL_MEDIAN_RATE_NIGHTLY);
  }

  if (
    hotelMedianRatesFeatureEnabledV3 &&
    (isPolicyChanged('maxHotelBookingPriceProps') || isLinkChanged('hotelMedianRates'))
  ) {
    if (updatedPolicy.maxHotelBookingPriceProps) {
      policyRules.push(
        formatPolicy({
          ruleType: PolicyRuleType.MAX_HOTEL_BOOKING_PRICE,
          preventBooking: {
            prevent: !!updatedPolicy.maxHotelBookingPriceProps,
            reason: '',
          },
          props: {
            maxHotelBookingPriceProps: {
              type: updatedPolicy.maxHotelBookingPriceProps?.type,
              percentage: updatedPolicy.maxHotelBookingPriceProps?.percentage,
              isTaxIncluded: updatedPolicy.maxHotelBookingPriceProps?.isTaxIncluded,
            },
          },
          isLinked: isHideHotelMedianRateCustomizationForNonDefaultPolicyEnabled
            ? updatedPolicy.type !== PolicyTypeEnum.DEFAULT
            : updatedLinks?.hotelMedianRates === true,
        }),
      );
    }

    updateTypes.push(PolicyRuleType.MAX_HOTEL_BOOKING_PRICE);
  }

  if (isAncillaryUIEnabled) {
    if (isPolicyChanged('allowedAirAddons') || isLinkChanged('allowedAirAddons')) {
      const rule: PolicyUniversalRule = formatPolicy(
        {
          ruleType: PolicyRuleType.ALLOWED_AIR_ADDONS,
          props: {
            allowedAirAddonsProps: {
              addons: updatedPolicy.allowedAirAddons ?? [],
            },
          },
          isLinked: updatedLinks?.allowedAirAddons,
        },
        splitPaymentInfoConfig,
      );

      updateTypes.push(PolicyRuleType.ALLOWED_AIR_ADDONS);
      policyRules.push(rule);
    }
  }

  policyData.rules = policyRules;

  return {
    types: updateTypes,
    policy: policyData as IPolicy,
  };
};

export const getUpdatePolicyRequestData = (request: IGetPolicyDiffParams): UpdatePolicyRequest => {
  const { policy, types } = getPolicyDiff(request);
  return {
    policyId: { id: request.updatedPolicy.policyId },
    policy,
    types,
  };
};

interface IGetCreatePolicyRequestDataParams extends IGetNewPolicyRequestDataParams {
  organizationId: IOrganizationId;
}

export const getCreatePolicyRequestData = ({
  organizationId,
  ...rest
}: IGetCreatePolicyRequestDataParams): CreatePolicyRequest => ({
  policy: getNewPolicyRequestData(rest),
  organizationId,
});

interface OrganizationInfo extends OrganizationNode {
  workerTypes: string[];
  countryCodes: string[];
  accountingCodes: string[];
  personas: string[];
}

export const checkIfOrgDataAvailable = (
  type: string,
  data: OrganizationInfo | null,
  officeV2Length?: number,
): boolean => {
  if (!data) {
    return false;
  }
  if (
    type === policyUserGroupConditionTypeKeysEnum.USER_ORG_IDS ||
    (type === policyUserGroupConditionTypeKeysEnum.DEPARTMENTS && data.departments.length) ||
    (type === policyUserGroupConditionTypeKeysEnum.LEGAL_ENTITY_IDS && data.legalEntityNodes.length) ||
    (type === policyUserGroupConditionTypeKeysEnum.COST_CENTERS && data.costCenters.length) ||
    (type === policyUserGroupConditionTypeKeysEnum.GRADES && data.grades.length) ||
    (type === policyUserGroupConditionTypeKeysEnum.OFFICE_IDS &&
      (!!officeV2Length || data.legalEntityNodes.some((entity) => entity.offices?.length > 0))) ||
    (type === policyUserGroupConditionTypeKeysEnum.POSITION_TITLES && data.designations.length) ||
    (type === policyUserGroupConditionTypeKeysEnum.WORKER_TYPES && data.workerTypes.length) ||
    (type === policyUserGroupConditionTypeKeysEnum.COUNTRY_CODES && data.countryCodes.length) ||
    (type === policyUserGroupConditionTypeKeysEnum.ACCOUNTING_CODES && data.accountingCodes.length)
  ) {
    return true;
  }
  return false;
};

export const getUpdateCompanyAuthenticationRequest = (
  organizationData: IOrganizationNode,
  authDetails: ICompanyAuthentication,
): IOrganization => {
  if (organizationData.organization) {
    if (!authDetails.type) return organizationData.organization;
    return {
      ...organizationData.organization,
      isSelfSignUpEnabled: authDetails.userProvisionAllowed,
      authConfig: {
        authProviders: [
          {
            authProviderType: authDetails.type,
            azure: authDetails.applicationId ? { id: authDetails.applicationId } : undefined,
          },
        ],
        idleSessionTimeoutMin: authDetails.idleSessionTimeoutMin,
      },
    };
  }
  throw new Error('No organization data available to get company-setup auth request');
};

export const getCreateEmployeeRequest = (
  organizationId: IOrganizationId,
  organizationData: IOrganizationNode,
  {
    employeeCompanyDetails: { legalEntityId, officeId, departmentId, costCenterId },
    employeeDetails: { title, name, email, employeeId, phoneNumber, designation, manager, userOrgId, tier },
  }: INewUserData,
  office?: IOffice,
): Employee => {
  const organizationManager = new ReadOrganizationNodeResponseManager(organizationData);
  const employee: Employee = {
    userBasicInfo: {
      title,
      gender: GenderEnum.UNKNOWN,
      name,
      email,
      userOrgId,
      persona: PersonaEnum.EMPLOYEE,
    },
    userBusinessInfo: {
      organizationId,
      email,
      employeeId,
      legalEntityId: { id: legalEntityId },
      office: office ?? organizationManager.GetOffice({ id: officeId }),
      department: organizationManager.GetDepartment({ id: departmentId }),
      userCostCenter: organizationManager.GetCostCenter({ id: costCenterId }),
      phoneNumbers: phoneNumber.rawInput ? [{ ...phoneNumber }] : [],
      designation,
      managerBasicInfo: manager ?? undefined,
      companySpecifiedAttributes: [],
    },
    roleInfos: [],
    tier,
  };
  return employee;
};

const getUpdateEmployeeRequestType = (
  employeeInfo: INewUserData,
  updatedEmployeeInfo: INewUserData,
): UpdateEmployeeRequestType[] => {
  const employeeRequestTypeArr = [];
  if (employeeInfo.employeeDetails.designation !== updatedEmployeeInfo.employeeDetails.designation) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.DESIGNATION);
  }
  if (!isEqual(employeeInfo.employeeDetails.phoneNumber, updatedEmployeeInfo.employeeDetails.phoneNumber)) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.PHONE_NUMBERS);
  }
  if (!isEqual(employeeInfo.employeeDetails.manager, updatedEmployeeInfo.employeeDetails.manager)) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.MANAGER_ID);
  }
  if (employeeInfo.employeeCompanyDetails.departmentId !== updatedEmployeeInfo.employeeCompanyDetails.departmentId) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.DEPARTMENT);
  }
  if (employeeInfo.employeeCompanyDetails.legalEntityId !== updatedEmployeeInfo.employeeCompanyDetails.legalEntityId) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.LEGAL_ENTITY_ID);
  }
  if (employeeInfo.employeeCompanyDetails.officeId !== updatedEmployeeInfo.employeeCompanyDetails.officeId) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.OFFICE_ID);
  }
  if (employeeInfo.employeeCompanyDetails.costCenterId !== updatedEmployeeInfo.employeeCompanyDetails.costCenterId) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.COST_CENTER);
  }
  if (employeeInfo.employeeDetails.tier !== updatedEmployeeInfo.employeeDetails.tier) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.TIER);
  }
  if (employeeInfo.employeeCompanyDetails.countryCode !== updatedEmployeeInfo.employeeCompanyDetails.countryCode) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.COUNTRY_CODE);
  }
  if (employeeInfo.employeeDetails.workerType !== updatedEmployeeInfo.employeeDetails.workerType) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.WORKER_TYPE);
  }
  if (employeeInfo.employeeDetails.accountingCode !== updatedEmployeeInfo.employeeDetails.accountingCode) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.ACCOUNTING_CODE);
  }
  return employeeRequestTypeArr;
};

export const getEmployeeUpdateTypes = (
  oldEmployee: IEmployee,
  updatedEmployee: IEmployee,
): UpdateEmployeeRequestType[] => {
  const employeeRequestTypeArr = [];
  if (oldEmployee.userBasicInfo?.title !== updatedEmployee.userBasicInfo?.title) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.TITLE);
  }
  if (!isEqual(oldEmployee.userBasicInfo?.name, updatedEmployee.userBasicInfo?.name)) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.NAME);
  }
  if (oldEmployee.userBusinessInfo?.email === updatedEmployee.userBusinessInfo?.email) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.EMAIL);
  }
  if (oldEmployee.userBusinessInfo?.employeeId !== updatedEmployee.userBusinessInfo?.employeeId) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.EMPLOYEE_ID);
  }
  if (oldEmployee.userBusinessInfo?.designation !== updatedEmployee.userBusinessInfo?.designation) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.DESIGNATION);
  }
  if (!isEqual(oldEmployee.userBusinessInfo?.phoneNumbers, updatedEmployee.userBusinessInfo?.phoneNumbers)) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.PHONE_NUMBERS);
  }
  if (!isEqual(oldEmployee.userBusinessInfo?.managerId, updatedEmployee.userBusinessInfo?.managerId)) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.MANAGER_ID);
  }
  if (oldEmployee.userBusinessInfo?.department !== updatedEmployee.userBusinessInfo?.department) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.DEPARTMENT);
  }
  if (oldEmployee.userBusinessInfo?.legalEntityId !== updatedEmployee.userBusinessInfo?.legalEntityId) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.LEGAL_ENTITY_ID);
  }
  if (oldEmployee.userBusinessInfo?.office !== updatedEmployee.userBusinessInfo?.office) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.OFFICE_ID);
  }
  if (oldEmployee.userBusinessInfo?.userCostCenter !== updatedEmployee.userBusinessInfo?.userCostCenter) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.COST_CENTER);
  }
  if (oldEmployee.tier !== updatedEmployee.tier) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.TIER);
  }
  if (oldEmployee.userBusinessInfo?.grade?.id?.id !== updatedEmployee.userBusinessInfo?.grade?.id?.id) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.GRADE);
  }
  if (oldEmployee.userBusinessInfo?.countryCode !== updatedEmployee.userBusinessInfo?.countryCode) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.COUNTRY_CODE);
  }
  if (oldEmployee.userBusinessInfo?.workerType !== updatedEmployee.userBusinessInfo?.workerType) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.WORKER_TYPE);
  }
  if (oldEmployee.userBusinessInfo?.accountingCode !== updatedEmployee.userBusinessInfo?.accountingCode) {
    employeeRequestTypeArr.push(UpdateEmployeeRequestTypeEnum.ACCOUNTING_CODE);
  }

  return employeeRequestTypeArr;
};

export const getUpdateEmployeeRequest = (
  organizationId: IOrganizationId,
  organizationData: IOrganizationNode,
  roleInfos: RoleInfo[],
  employeeInfo: INewUserData,
  updatedEmployeeInfo: INewUserData,
): UpdateEmployeeRequest => {
  const employee: Employee = getCreateEmployeeRequest(organizationId, organizationData, updatedEmployeeInfo);
  return {
    employee: { ...employee, roleInfos },
    types: getUpdateEmployeeRequestType(employeeInfo, updatedEmployeeInfo),
  };
};

// TODO: Add switch/case here so that by adding new roles in the enum TS lets us know this fn needs to be updated too
// TODO: Refactor this function to get rid of these verbose if conditions
const getFormattedRolesInfo = (
  updatedRole: CustomizedEmployeeRoleType,
  organizationId: IOrganizationId,
): RoleInfo[] => {
  if (Number(updatedRole.value) === CustomRoleInfoType.ARRANGER && updatedRole.manageFor?.value === 'individuals') {
    return [
      {
        type: RoleInfoTypeEnum.TRAVEL_ARRANGER,
        travelArranger: {
          userBasicInfos: updatedRole.travelArrangerFor ?? [],
          travelerConfigs:
            updatedRole.travelArrangerFor?.map((userBasicInfo) => ({
              sendConfirmationEmail: true,
              sendFlightStatNotificationEmail: true,
              userBasicInfo,
            })) ?? [],
        },
      },
    ];
  }
  if (Number(updatedRole.value) === CustomRoleInfoType.ARRANGER && updatedRole.manageFor?.value === 'everyone') {
    return [
      {
        type: RoleInfoTypeEnum.COMPANY_TRAVEL_ARRANGER,
        companyTravelArranger: {
          organizationId,
        },
      },
    ];
  }
  if (Number(updatedRole.value) === CustomRoleInfoType.COMPANY_ADMIN) {
    return [
      {
        type: RoleInfoTypeEnum.COMPANY_ADMIN,
        companyAdmin: {
          organizationId,
        },
      },
    ];
  }
  if (Number(updatedRole.value) === CustomRoleInfoType.SPOTNANA_AGENT) {
    return [
      {
        type: RoleInfoTypeEnum.GLOBAL_AGENT,
      },
    ];
  }
  if (Number(updatedRole.value) === CustomRoleInfoType.SPOTNANA_ADMIN) {
    return [
      {
        type: RoleInfoTypeEnum.GLOBAL_ADMIN,
      },
    ];
  }
  if (Number(updatedRole.value) === CustomRoleInfoType.TMC_ADMIN) {
    return [
      {
        type: RoleInfoTypeEnum.TMC_ADMIN,
        tmcAdmin: {
          companyId: organizationId,
        },
      },
    ];
  }
  if (Number(updatedRole.value) === CustomRoleInfoType.TMC_AGENT) {
    return [
      {
        type: RoleInfoTypeEnum.TMC_AGENT,
        tmcAgent: {
          companyId: organizationId,
        },
      },
    ];
  }
  return [];
};

export const getUpdateRoleRequest = (
  organizationId: IOrganizationId,
  userOrgId: IUserOrgId,
  roleInfo: CustomizedEmployeeRoleType,
  employeeRoles: CustomizedEmployeeRoleType[],
): UpdateUserRolesRequest => ({
  userOrgId: removeEmptyValuesFromObject(userOrgId),
  assignRoles: getFormattedRolesInfo(roleInfo, organizationId),
  deleteRoles: employeeRoles.flatMap((x) => getFormattedRolesInfo(x, organizationId)),
});

export const newUserTypeToPersonaMap = {
  [NewUserTypeEnum.EMPLOYEE]: PersonaEnum.EMPLOYEE,
  [NewUserTypeEnum.GUEST]: PersonaEnum.GUEST,
  [NewUserTypeEnum.LEISURE]: PersonaEnum.PERSONAL,
};

export const mapPersonaV1toV2: Record<PersonaEnum, Persona> = {
  [PersonaEnum.ADHOC]: Persona.Adhoc,
  [PersonaEnum.EMPLOYEE]: Persona.Employee,
  [PersonaEnum.GUEST]: Persona.Guest,
  [PersonaEnum.PERSONAL]: Persona.Personal,
  [PersonaEnum.RELATIVE]: Persona.Relative,
  [PersonaEnum.UNKNOWN_PERSONA]: Persona.UnknownPersona,
  [PersonaEnum.UNRECOGNIZED]: Persona.UnknownPersona,
};

export const personaToNewUserType = invert(newUserTypeToPersonaMap);

const getNestedIdObject = <T extends { id?: { id: string } }>(id: string): T | undefined =>
  id ? ({ id: { id } } as T) : undefined;

export const getTravelerForCreationFromUserData = (
  travelerData: INewUserData,
  organizationId: UserBusinessInfo['organizationId'],
): ITraveler => {
  const isLeisureTraveler = travelerData.newUserType === NewUserTypeEnum.LEISURE;
  const isGuestTraveler = travelerData.newUserType === NewUserTypeEnum.GUEST;

  const { title, name, phoneNumber, email, tier } = travelerData.employeeDetails;
  const requiredUserDetails: Pick<User, 'title' | 'name' | 'phoneNumbers'> & Partial<Pick<User, 'email'>> = {
    title,
    name,
    phoneNumbers: [phoneNumber],
    email,
  };

  if (isGuestTraveler) {
    delete requiredUserDetails.email;
  }

  const { legalEntityId, officeId, departmentId, costCenterId } = travelerData.employeeCompanyDetails;
  const requiredCompanyRelatedDetails: Pick<
    UserBusinessInfo,
    'organizationId' | 'legalEntityId' | 'office' | 'department' | 'userCostCenter' | 'managerBasicInfo'
  > &
    Partial<Pick<UserBusinessInfo, 'email'>> = {
    organizationId,
    legalEntityId: legalEntityId ? ({ id: legalEntityId } as ILegalEntityId) : undefined,
    office: getNestedIdObject<IOffice>(officeId),
    department: getNestedIdObject<IDepartment>(departmentId),
    userCostCenter: getNestedIdObject<ICostCenter>(costCenterId),
    managerBasicInfo: travelerData.employeeDetails.manager ?? undefined,
    email,
  };

  if (isLeisureTraveler) {
    delete requiredCompanyRelatedDetails.email;
  }

  const requiredTravelPref: Pick<TravelPref, 'preferredCurrency'> | undefined =
    travelerData.newUserType === NewUserTypeEnum.LEISURE
      ? { preferredCurrency: travelerData.preferredCurrency }
      : undefined;

  return {
    user: requiredUserDetails as User,
    userBusinessInfo: (!isLeisureTraveler ? requiredCompanyRelatedDetails : undefined) as UserBusinessInfo,
    travelerPersonalInfo: (isLeisureTraveler
      ? { travelPref: requiredTravelPref as TravelPref }
      : undefined) as TravelerPersonalInfo,
    persona: newUserTypeToPersonaMap[travelerData.newUserType],
    tier,
    userOrgId: undefined as unknown as IUserOrgId,
  };
};

export const getUserDataFromTraveler = (traveler: ITraveler | undefined): INewUserData | null => {
  if (!traveler || !traveler.user) {
    return null;
  }

  const eccRaw = traveler.userBusinessInfo?.companySpecifiedAttributes?.find((v) => v.fixedColumnName === 'ECC')?.value;
  const ecc = eccRaw ? getProfileEccKeyFromString(eccRaw) : '';
  return {
    newUserType: personaToNewUserType[traveler.persona ?? PersonaEnum.GUEST] as NewUserTypeEnum,
    employeeDetails: {
      title: traveler.user.title,
      name: traveler.user.name ?? emptyName,
      phoneNumber: first(traveler.user.phoneNumbers) ?? emptyPhoneNumber,
      email: traveler.user.email,
      userOrgId: traveler.userOrgId,
      manager: traveler.userBusinessInfo?.managerBasicInfo ?? null,
      designation: traveler.userBusinessInfo?.designation ?? '',
      employeeId: traveler.userBusinessInfo?.employeeId ?? '',
      tier: traveler.tier,
    },
    employeeCompanyDetails: {
      legalEntityId: traveler.userBusinessInfo?.legalEntityId?.id ?? '',
      officeId: traveler.userBusinessInfo?.office?.id?.id ?? '',
      departmentId: traveler.userBusinessInfo?.department?.id?.id ?? '',
      costCenterId: traveler.userBusinessInfo?.userCostCenter?.id?.id ?? '',
      ecc: ecc ?? '',
    },
    preferredCurrency: traveler.travelerPersonalInfo?.travelPref?.preferredCurrency ?? '',
  };
};

export const getUserListRequest = ({
  organizationId,
  pageNumber,
  numResultsPerPage,
  sortByName,
  descOrder,
  filters,
}: {
  organizationId: string;
  pageNumber: number;
  numResultsPerPage: number;
  sortByName?: boolean | undefined;
  descOrder?: boolean | undefined;
  filters?: UserListFilters;
}): ListUsersRequest => ({
  organizationId: {
    id: organizationId,
  },
  pageNumber,
  numResultsPerPage,
  ...(sortByName
    ? {
        sort: {
          sortBy: UserListDisplaySortOptionSortByEnum.Name,
          sortOrder: descOrder
            ? UserListDisplaySortOptionSortOrderEnum.Descending
            : UserListDisplaySortOptionSortOrderEnum.Ascending,
        },
      }
    : {}),
  filters: [
    {
      ...(filters?.tier && { tier: { tiers: filters.tier as Tier[] } }),
      ...(filters?.department && { department: { departments: filters.department as string[] } }),
      ...(filters?.office && { office: { offices: filters.office as string[] } }),
      ...(filters?.role && { role: { roles: filters.role as string[] } }),
      ...(filters?.title && { title: { titles: filters.title as string[] } }),
      ...(filters?.userId && { userId: { userIds: filters.userId as string[] } }),
      ...(filters?.email && { email: { emails: filters.email as string[] } }),
      ...(filters?.persona && { persona: { personas: filters.persona as Persona[] } }),
    },
  ],
});

export const getPCCApplicableState = (
  applicableTo: IPccDataState['applicableTo'],
): { isApplicableToAir: boolean; isApplicableToHotel: boolean; isApplicableToCar: boolean } => {
  let returnValue = { isApplicableToAir: false, isApplicableToHotel: false, isApplicableToCar: false };
  if (applicableTo.includes('AIR' as TravelType)) {
    returnValue = { ...returnValue, isApplicableToAir: true };
  }
  if (applicableTo.includes('HOTEL' as TravelType)) {
    returnValue = { ...returnValue, isApplicableToHotel: true };
  }
  if (applicableTo.includes('CAR' as TravelType)) {
    returnValue = { ...returnValue, isApplicableToCar: true };
  }
  if (applicableTo.includes('ALL' as TravelType)) {
    returnValue = { ...returnValue, isApplicableToAir: true, isApplicableToHotel: true, isApplicableToCar: true };
  }
  return returnValue;
};

export const getPCCCurrencyValues = (values: ISelectWithLabelsDataItem[] = []): PccCurrency[] =>
  values.map((currency) => ({ currencyCode: currency.value, isDefault: currency.selected }));

export const getPCCCountryValues = (values: ISelectWithLabelsDataItem[] = []): PccCountry[] =>
  values.map((country) => ({ countryCode: country.value, isDefault: country.selected }));

export const getPCCCurrenciesState = (supportedCurrencies: PccCurrency[] | null = []): ISelectWithLabelsDataItem[] =>
  supportedCurrencies?.map((supportedCurrency) => {
    const currencyOption = ConversionSupportedCurrencyCodesWithCurrencyNameAndSymbol.find(
      (option) => option.value === supportedCurrency.currencyCode,
    );

    return {
      value: currencyOption?.value ?? supportedCurrency.currencyCode,
      label: currencyOption?.label ?? supportedCurrency.currencyCode,
      selected: supportedCurrency.isDefault,
    };
  }) ?? [];

export const getPCCCountriesState = (supportedCountries: PccCountry[] | null = []): ISelectWithLabelsDataItem[] =>
  supportedCountries?.map((supportedCountry) => {
    const countryOption = countryCodeMapper.find((option) => option.value === supportedCountry.countryCode);

    return {
      value: countryOption?.value ?? supportedCountry.countryCode,
      label: countryOption?.label ?? supportedCountry.countryCode,
      selected: supportedCountry.isDefault,
    };
  }) ?? [];

export const getCreatePCCRequest = (state: IPccDataState, supplier: SupplierType): CreatePccRequest => {
  const request: CreatePccRequest = {
    tmcId: {
      id: state.tmcId,
    },
    supplier,
    isDefault: state.isDefault,
    name: state.name,
    dkNumber: state.dkNumber,
    zoneId: state.zoneId,
    iataNumber: state.iata,
    credential: {
      awsSecretKey: state.awsKey ?? undefined,
    },
    travelTypes: state.applicableTo.includes('ALL' as TravelType) ? (['ALL'] as TravelType[]) : state.applicableTo,
    queues: {
      defaultPnrQueue: { number: state.primaryQueue },
      globalInputQueue: { number: state.secondaryQueue },
    },
    currencyCode: state.currencyCode,
    supportedCurrencies: getPCCCurrencyValues(state.supportedCurrencies),
    supportedCountries: getPCCCountryValues(state.supportedCountries),
    airClid3Codes: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.clid3 : [],
    airClid4Codes: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.clid4 : [],
    hotelRateCodes: getPCCApplicableState(state.applicableTo).isApplicableToHotel ? state.hotelRateCodes : [],
    airTourCodes: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.airTourCodes : [],
    allowedAirlines: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.airlines : [],
    label: state.label,
    isIpcc: state.isIpcc,
    linkedIpcc: state.linkedIpcc,
  };
  return request;
};

export const getUpdatePCCRequest = (
  originalPCCData: ReadPccResponse['pcc'] | undefined,
  state: IPccDataState,
  supplier: SupplierType,
  pccId: string,
): UpdatePccRequest => {
  const request: UpdatePccRequest = {
    pcc: {
      ...originalPCCData,
      name: state.name,
      pccId,
      tmcId: {
        id: state.tmcId,
      },
      isDefault: state.isDefault,
      supplier,
      dkNumber: state.dkNumber,
      zoneId: state.zoneId,
      iataNumber: state.iata,
      credential: {
        awsSecretKey: state.awsKey ?? undefined,
      },
      travelTypes: state.applicableTo.includes('ALL' as TravelType) ? ['ALL' as TravelType] : state.applicableTo,
      queues: {
        ...originalPCCData?.queues,
        defaultPnrQueue: { ...originalPCCData?.queues?.defaultPnrQueue, number: state.primaryQueue },
        globalInputQueue: { ...originalPCCData?.queues?.globalInputQueue, number: state.secondaryQueue },
      },
      currencyCode: state.currencyCode,
      supportedCurrencies: getPCCCurrencyValues(state.supportedCurrencies),
      supportedCountries: getPCCCountryValues(state.supportedCountries),
      airClid3Codes: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.clid3 : [],
      airClid4Codes: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.clid4 : [],
      hotelRateCodes: getPCCApplicableState(state.applicableTo).isApplicableToHotel ? state.hotelRateCodes : [],
      airTourCodes: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.airTourCodes : [],
      allowedAirlines: getPCCApplicableState(state.applicableTo).isApplicableToAir ? state.airlines : [],
      label: state.label,
      isIpcc: state.isIpcc,
      linkedIpcc: state.linkedIpcc,
    },
  };
  return request;
};

export * from './customFields';

export const getCreateLegalEntityRequestFromLegalEntity = (legalEntity: ILegalEntity): LegalEntityCreateRequest => ({
  ...legalEntity,
  address: legalEntity.address ?? emptyTravelerAddress,
  phoneNumbers: legalEntity?.phoneNumbers?.map((phoneNumber) => mapPhoneNumberFromV1ToV2(phoneNumber)) ?? [],
});

export const convertTimeLocalToDate = (time?: TimeLocal, defaultHours = 0): Date => {
  return new Date(new Date().setHours(time?.hours ?? defaultHours, time?.minutes ?? 0, 0, 0));
};
