import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { Button } from '@spotnana/pixel-react/dist/Button';
import { Typography } from '@spotnana/blocks/src/Typography';
import {
  usePrimaryTravelerId,
  IUserOrgId,
  removeEmptyValuesFromObject,
  useFeatureFlag,
  getFullAssetPath,
  createUserNameFromFullName,
} from 'obt-common';
import { TravelerBasicInfo } from 'obt-common/types/api/v2/obt/model';

import { Avatar } from '@spotnana/blocks/src/Avatar';
import { Icon } from '@spotnana/blocks/src/Icon';
import { parseParams, stringifyParams } from 'obt-common/utils/urlUtils';
import { name_trip_button_container, option_container } from './EntityAutocompleteOptions.styles';
import { EntityAutocompleteOptionProps } from './EntityAutocompleteOption';
import { EntityAutocompleteProfileOptionDescription } from './EntityAutocompleteProfileOptionDescription';

const EntityAutocompleteProfileOption = ({
  option,
  isCompanyTripsPage = false,
  isTravelerAutocomplete = false,
  onClick,
}: EntityAutocompleteOptionProps<TravelerBasicInfo>): JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();
  const { tripType } = parseParams(search);
  const usePreferredName = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');
  const isCompanyTripsPageV3FeatureEnabled = useFeatureFlag('FE_TMC_COMPANY_TRIPS_V3');
  const isConfigureSeat1AEnabled = !!useFeatureFlag('FE_CORP_CONFIGURE_SEAT1A');
  const { setPrimaryTravelerId } = usePrimaryTravelerId();

  const { t: tt } = useTranslation('WEB');

  const goToProfile: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event.target instanceof HTMLElement && event.target.innerText !== tt('Go to trips')) {
      history.push({ pathname: '/profile/personal', search: stringifyParams({ profileId: option.userOrgId }) });
    }
  };

  const goToTrips: MouseEventHandler<HTMLButtonElement> = () => {
    if (option.userOrgId) {
      if (isCompanyTripsPage) {
        const searchParamObj = isCompanyTripsPageV3FeatureEnabled
          ? {
              tripType,
              userId: option.userOrgId.userId?.id,
              searchText: option.fullName,
            }
          : {
              applyUserFilter: true,
              userId: option.userOrgId.userId,
              selectedOption: option,
            };
        history.push({
          pathname: '/company-trips',
          search: stringifyParams(searchParamObj),
        });
      } else {
        setPrimaryTravelerId(removeEmptyValuesFromObject(option.userOrgId) as IUserOrgId);
        history.push({
          pathname: '/trips',
        });
      }
    }
  };

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (onClick) {
      onClick();
    } else if (isCompanyTripsPage) {
      goToTrips(event);
    } else goToProfile(event);
  };

  const { name, fullName } = option;
  const travelerName = usePreferredName && name ? createUserNameFromFullName(name) : fullName;

  return (
    <button type="button" onClick={onClickHandler} css={option_container}>
      <Avatar
        color="primary"
        size="small"
        alt=""
        src={option.profilePicture?.url ?? ''}
        title={fullName}
        text={!option.profilePicture?.url ? fullName : undefined}
        badge={
          !isConfigureSeat1AEnabled &&
          option.tier === 'SEAT1A' && (
            <img
              src={getFullAssetPath('/v1-assets/images/seat1a-logo', 'svg')}
              alt={`Seat 1A ${tt('USER')}`}
              title={`Seat 1A ${tt('USER')}`}
              style={{
                height: '8px',
                width: '8px',
                borderRadius: '100%',
              }}
            />
          )
        }
      />
      <div css={name_trip_button_container}>
        <Typography variant="body2" kind="medium">
          {travelerName}
        </Typography>
        <EntityAutocompleteProfileOptionDescription item={option} />
      </div>
      {!isCompanyTripsPage && !isTravelerAutocomplete && (
        <Button
          variation="ghost"
          style={{ borderRadius: '32px' }}
          px="16px"
          addonBefore={<Icon fontSize={20} name="LuggageCarryOn" />}
          onClick={goToTrips}
          className="trip-btn"
        >
          {tt('Go to trips')}
        </Button>
      )}
    </button>
  );
};

export default EntityAutocompleteProfileOption;
