import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CircleChecked = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 14", fill: "none" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.94799 0.065393C4.1181 -0.458431 0.588793 2.22204 0.0655046 6.05222C-0.458796 9.88189 2.2216 13.4113 6.05189 13.9346C9.88177 14.4584 13.4108 11.7784 13.9352 7.94842C14.0732 6.9348 13.9898 5.92182 13.6984 4.96467C13.5844 4.59046 13.1887 4.37948 12.8145 4.49342C12.4403 4.60736 12.2293 5.00308 12.3432 5.37729C12.5755 6.13995 12.6419 6.94705 12.5316 7.75681C12.1135 10.8112 9.29861 12.9489 6.24373 12.5311C3.18862 12.1137 1.0508 9.29875 1.46899 6.24416C1.88639 3.189 4.70134 1.05108 7.75604 1.46888C9.02935 1.64301 10.1754 2.23798 11.0419 3.14895C11.3115 3.43238 11.7598 3.44359 12.0433 3.174C12.3267 2.90441 12.3379 2.45609 12.0683 2.17266C10.9821 1.03074 9.54272 0.283484 7.94799 0.065393ZM11.1209 4.43974C10.8421 4.16529 10.3937 4.16877 10.1192 4.4475L6.09063 8.53821L4.04589 6.46166L4.00116 6.41993C3.72471 6.18333 3.30833 6.19389 3.04427 6.45389C2.76554 6.72834 2.76206 7.17678 3.0365 7.45551L5.45171 9.90845L5.49826 9.95253C5.84832 10.2612 6.38271 10.2508 6.72043 9.91829L11.1286 5.44136L11.1697 5.39599C11.4019 5.1159 11.3849 4.69974 11.1209 4.43974Z", fill: themeEnrichedPrimaryColor })));
};
CircleChecked.defaultProps = defaultIconProps;
export default CircleChecked;
