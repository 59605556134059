/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Seat amenity preference.
 * @export
 * @interface SeatAmenityPref
 */
export interface SeatAmenityPref {
    /**
     * 
     * @type {Array<string>}
     * @memberof SeatAmenityPref
     */
    seatAmenityTypes: Array<SeatAmenityPrefSeatAmenityTypesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum SeatAmenityPrefSeatAmenityTypesEnum {
    UnknownAirSeatAmenityType = 'UNKNOWN_AIR_SEAT_AMENITY_TYPE',
    FlatBed = 'FLAT_BED',
    Wifi = 'WIFI',
    InSeatPower = 'IN_SEAT_POWER',
    Unrecognized = 'UNRECOGNIZED'
}



