import { useCallback } from 'react';
import logger from '../../utils/logger';
import { TelemetryEvents } from '../constants';
import type { ScreenInitialDimensionEvent } from '../types/screenType';
import { useTelemetry } from './useTelemetry';

type Input = { width: number; height: number };

const getSelectedEvent = (input: Input): ScreenInitialDimensionEvent => {
  const { width, height } = input;
  const width100 = Math.round(width / 100) * 100;
  const height100 = Math.round(height / 100) * 100;

  return {
    name: TelemetryEvents.SCREEN_INITIAL_DIMENSIONS,
    data: {
      width,
      height,
      width100,
      height100,
      size: `${width100}x${height100}`,
    },
  };
};

export function useScreenInitialDimensionsTelemetry(): (input: Input) => void {
  const { trackEvent } = useTelemetry();
  return useCallback(
    async (input: Input): Promise<void> => {
      try {
        const selectedEvent = getSelectedEvent(input);
        await trackEvent(selectedEvent);
      } catch (error) {
        logger.error(new Error('SCREEN_INITIAL_DIMENSIONS_ERROR', { cause: error }));
      }
    },
    [trackEvent],
  );
}
