import type { TripStatusProps } from '../constants/tripStatus';
import {
  FlightAlertStatus,
  FlightUpdateStatus,
  defaultTripStatusConfig,
  negativeStatusConfig,
  pendingStatusConfig,
  positiveTripStatusConfig,
  tripColorsForFlightAlertsMap,
  tripColorsForFlightUpdatesMap,
  tripColorsForPNRStatusMap,
  tripColorsForUserFacingStatusV1Map,
  tripColorsForUserFacingStatusV2Map,
} from '../constants/tripStatus';
import type { PnrStatusV1 } from '../types/api/v1/obt/pnr/pnr';
import {
  AirPnrAlertType,
  AirPnrLegAlertType,
  UserFacingStatus as UserFacingStatusV1,
} from '../types/api/v1/obt/pnr/pnr';
import type { ITripFlightDetail } from '../types/trip';
import type { ITripSummary } from '../types/traveler';
import { getIsTripMaybeActive } from './trips';
import { defineCommonMessage } from '../translations/defineMessage';
import { UserFacingStatus as UserFacingStatusV2 } from '../types/api/v2/obt/model/user-facing-status';

type TripStatusParams<T extends UserFacingStatusV1 | PnrStatusV1 | UserFacingStatusV2> = {
  tripStatus: T;
  flightDetails?: ITripFlightDetail[];
  tripSummary?: ITripSummary;
};

type FlightAlerts = { flightAlerts: Set<number>; flightLegAlerts: Set<number> };

const getFlightAlerts = (tripSummary: ITripSummary): FlightAlerts => {
  // Traverse all the air pnrs within the trip
  const { flightAlerts, flightLegAlerts } = tripSummary.pnrSummaries?.reduce(
    (acc, summary) => {
      const { flightAlerts: flightAlertz = [], legAlerts: legAlertz = [] } = summary.air ?? {};
      return {
        flightAlerts: new Set([...acc.flightAlerts, ...flightAlertz]),
        flightLegAlerts: new Set([...acc.flightLegAlerts, ...legAlertz]),
      };
    },
    {
      flightAlerts: new Set<number>(),
      flightLegAlerts: new Set<number>(),
    },
  );
  return { flightAlerts, flightLegAlerts };
};

const defaultReturn: TripStatusProps = {
  i18nStatus: defineCommonMessage('Pending'),
  ...pendingStatusConfig,
};

const getTripStatusLabelAndColorBase = ({ tripStatus }: TripStatusParams<UserFacingStatusV1>): TripStatusProps => {
  let { color, background, icon, borderColor } = defaultTripStatusConfig;
  let i18nStatus = defineCommonMessage('Pending');

  switch (tripStatus) {
    case UserFacingStatusV1.PAYMENT_DECLINED_STATUS:
    case UserFacingStatusV1.UNCONFIRMED_STATUS:
    case UserFacingStatusV1.SCHEDULE_CHANGE_STATUS:
    case UserFacingStatusV1.AIRLINE_CONTROL_STATUS:
    case UserFacingStatusV1.FLIGHT_UNCONFIRMED_STATUS:
    case UserFacingStatusV1.INOPERATIVE_STATUS:
      i18nStatus = defineCommonMessage('Requires attention');
      ({ color, background, borderColor, icon } = pendingStatusConfig);
      break;
    case UserFacingStatusV1.PROCESSING_STATUS:
      i18nStatus = defineCommonMessage('Processing');
      ({ color, background, borderColor, icon } = pendingStatusConfig);
      break;
    case UserFacingStatusV1.CONFIRMED_STATUS:
      i18nStatus = defineCommonMessage('Upcoming');
      ({ color, background, borderColor, icon } = positiveTripStatusConfig);
      break;
    case UserFacingStatusV1.ACTIVE_STATUS:
      i18nStatus = defineCommonMessage('In progress');
      ({ color, background, borderColor, icon } = positiveTripStatusConfig);
      break;
    // approval statuses are pnr level but because they apply to any PNR and not flight only they need to live in the tripStatus code since that's what the other pnr statuses use to render their status
    case UserFacingStatusV1.APPROVAL_DENIED_STATUS:
      i18nStatus = defineCommonMessage('Approval denied');
      ({ color, background, borderColor, icon } = negativeStatusConfig);
      break;
    case UserFacingStatusV1.APPROVAL_REQUESTED_STATUS:
      i18nStatus = defineCommonMessage('Pending approval');
      ({ color, background, borderColor, icon } = pendingStatusConfig);
      break;
    default:
      ({ i18nStatus, color, background, borderColor, icon } =
        tripColorsForUserFacingStatusV1Map.get(tripStatus) ?? defaultReturn);
      break;
  }

  return { i18nStatus, color, background, borderColor, icon };
};

const getTripStatusLabelAndColorBaseV2 = ({ tripStatus }: TripStatusParams<UserFacingStatusV2>): TripStatusProps => {
  let { color, background, icon, borderColor } = defaultTripStatusConfig;
  let i18nStatus = defineCommonMessage('Pending');

  switch (tripStatus) {
    case UserFacingStatusV2.PaymentDeclinedStatus:
    case UserFacingStatusV2.UnconfirmedStatus:
    case UserFacingStatusV2.ScheduleChangeStatus:
    case UserFacingStatusV2.AirlineControlStatus:
    case UserFacingStatusV2.FlightUnconfirmedStatus:
    case UserFacingStatusV2.InoperativeStatus:
      i18nStatus = defineCommonMessage('Requires attention');
      ({ color, background, borderColor, icon } = pendingStatusConfig);
      break;
    case UserFacingStatusV2.ProcessingStatus:
      i18nStatus = defineCommonMessage('Processing');
      ({ color, background, borderColor, icon } = pendingStatusConfig);
      break;
    case UserFacingStatusV2.ConfirmedStatus:
      i18nStatus = defineCommonMessage('Upcoming');
      ({ color, background, borderColor, icon } = positiveTripStatusConfig);
      break;
    case UserFacingStatusV2.ActiveStatus:
      i18nStatus = defineCommonMessage('In progress');
      ({ color, background, borderColor, icon } = positiveTripStatusConfig);
      break;
    // approval statuses are pnr level but because they apply to any PNR and not flight only they need to live in the tripStatus code since that's what the other pnr statuses use to render their status
    case UserFacingStatusV2.ApprovalDeniedStatus:
      i18nStatus = defineCommonMessage('Approval denied');
      ({ color, background, borderColor, icon } = negativeStatusConfig);
      break;
    case UserFacingStatusV2.ApprovalRequestedStatus:
      i18nStatus = defineCommonMessage('Pending approval');
      ({ color, background, borderColor, icon } = pendingStatusConfig);
      break;
    default:
      ({ i18nStatus, color, background, borderColor, icon } =
        tripColorsForUserFacingStatusV2Map.get(tripStatus) ?? defaultReturn);
      break;
  }

  return { i18nStatus, color, background, borderColor, icon };
};

export const getLegStatusLabelAndColor = ({ tripStatus }: TripStatusParams<UserFacingStatusV1>): TripStatusProps => {
  switch (tripStatus) {
    case UserFacingStatusV1.PAYMENT_DECLINED_STATUS:
    case UserFacingStatusV1.UNCONFIRMED_STATUS:
    case UserFacingStatusV1.SCHEDULE_CHANGE_STATUS:
    case UserFacingStatusV1.AIRLINE_CONTROL_STATUS:
    case UserFacingStatusV1.INOPERATIVE_STATUS:
    case UserFacingStatusV1.FLIGHT_UNCONFIRMED_STATUS:
      return tripColorsForUserFacingStatusV1Map.get(tripStatus) ?? defaultReturn;
    default:
      return getTripStatusLabelAndColorBase({ tripStatus });
  }
};

export const getLegStatusLabelAndColorV2 = ({ tripStatus }: TripStatusParams<UserFacingStatusV2>): TripStatusProps => {
  switch (tripStatus) {
    case UserFacingStatusV2.PaymentDeclinedStatus:
    case UserFacingStatusV2.UnconfirmedStatus:
    case UserFacingStatusV2.ScheduleChangeStatus:
    case UserFacingStatusV2.AirlineControlStatus:
    case UserFacingStatusV2.InoperativeStatus:
    case UserFacingStatusV2.FlightUnconfirmedStatus:
      return tripColorsForUserFacingStatusV2Map.get(tripStatus) ?? defaultReturn;
    default:
      return getTripStatusLabelAndColorBaseV2({ tripStatus });
  }
};

export const getTripStatusLabelAndColor = ({ tripStatus }: TripStatusParams<UserFacingStatusV1>): TripStatusProps =>
  getTripStatusLabelAndColorBase({ tripStatus }) ?? defaultReturn;

export const getTripStatusLabelAndColorV2 = ({ tripStatus }: TripStatusParams<UserFacingStatusV2>): TripStatusProps =>
  getTripStatusLabelAndColorBaseV2({ tripStatus }) ?? defaultReturn;

export const getPnrStatusProps = ({ tripStatus }: TripStatusParams<PnrStatusV1>): TripStatusProps =>
  tripColorsForPNRStatusMap.get(tripStatus) ?? defaultReturn;

// this is used on leg status level
export const getFlightStatusPropsWithUpdates = ({
  tripStatus,
  flightDetails,
}: TripStatusParams<UserFacingStatusV1>): TripStatusProps => {
  if (!flightDetails) {
    return getLegStatusLabelAndColor({ tripStatus });
  }
  const {
    isDepartureDateTimeDelayed,
    isDepartureAirportGateChanged,
    isDepartureAirportTerminalChanged,
    isFlightCancelled,
    isFlightReinstated,
  } = flightDetails?.[0] || {};

  const isTripMaybeActive = getIsTripMaybeActive(tripStatus);

  // If any within the flight details (even first one) has a connection risk, then the trip is connection risk
  const isHavingConnectionRisk = flightDetails?.some((fd) => fd.isHavingConnectionRisk);

  // To be supplemented
  const significantUpdates: Array<[FlightUpdateStatus, boolean | undefined]> = [
    [FlightUpdateStatus.DELAYED, isDepartureDateTimeDelayed],
    [FlightUpdateStatus.CANCELLED, isFlightCancelled],
    [FlightUpdateStatus.GATE_CHANGED, isDepartureAirportGateChanged],
    [FlightUpdateStatus.TERMINAL_CHANGED, isDepartureAirportTerminalChanged],
    [FlightUpdateStatus.REINSTATED, isFlightReinstated],
    [FlightUpdateStatus.CONNECTION_RISK, isHavingConnectionRisk],
  ];

  const availableUpdates = significantUpdates.filter(([, isAvailable]) => isAvailable);
  const updatesCount = availableUpdates.length;
  const hasMultipleUpdates = updatesCount > 1;
  const hasAnyUpdates = updatesCount > 0;

  // If trip is cancelled, we don't care about other updates
  if (isFlightCancelled) {
    return tripColorsForFlightUpdatesMap.get(FlightUpdateStatus.CANCELLED) ?? defaultReturn;
  }

  // If ACTIVE trip has multiple updates
  if (hasMultipleUpdates && isTripMaybeActive) {
    return {
      ...(tripColorsForFlightUpdatesMap.get(FlightUpdateStatus.MULTIPLE_UPDATES) ?? defaultReturn),
      i18nOptions: { values: { count: updatesCount } },
    };
  }

  // If ACTIVE trip has 1 update, then map to the appropriate color
  if (hasAnyUpdates && isTripMaybeActive) {
    const firstUpdate = availableUpdates[0];
    return (firstUpdate && tripColorsForFlightUpdatesMap.get(firstUpdate[0])) ?? defaultReturn;
  }

  return getLegStatusLabelAndColor({ tripStatus });
};

// this is used on trip status level
export const getFlightStatusPropsWithAlerts = ({
  tripStatus,
  tripSummary,
}: TripStatusParams<UserFacingStatusV1>): TripStatusProps => {
  if (!tripSummary) {
    return getTripStatusLabelAndColor({ tripStatus });
  }

  // Trip Summary may have flight/car/hotels/train/... pnrs inside, but only 1 type per pnr at a time.
  // So if trip doesn't contain any Air pnr -> use UserFacingStatusV1 mapping for coloring by tripStatus.
  // If any Air pnr found (or multiple) -> use it to calculate the status coloring.
  // In future if car/hotel/train/etc. statuses will be respected, the logic should be changed.
  if (!tripSummary.pnrSummaries.some((summary) => summary.air)) {
    return getTripStatusLabelAndColor({ tripStatus: tripSummary.tripStatus });
  }
  // If it's flight -> use FlightUpdateStatus mapping for coloring
  // by flightStatus together with fligthAlerts & legAlerts
  const isTripMaybeActive = getIsTripMaybeActive(tripSummary.tripStatus);

  const { flightAlerts, flightLegAlerts } = getFlightAlerts(tripSummary);

  // To be supplemented
  const significantAlerts: Array<[FlightAlertStatus, boolean | undefined]> = [
    [
      FlightAlertStatus.DELAYED,
      !flightLegAlerts.has(AirPnrLegAlertType.CONNECTION_RISK) && flightAlerts.has(AirPnrAlertType.DEPARTURE_TIME),
    ],
    [
      FlightAlertStatus.GATE_CHANGED,
      [AirPnrAlertType.DEPARTURE_GATE, AirPnrAlertType.ARRIVAL_GATE].some((gateAlert) => flightAlerts.has(gateAlert)),
    ],
    [FlightAlertStatus.CANCELLED, flightAlerts.has(AirPnrAlertType.CANCELLED)],
    [FlightAlertStatus.REINSTATED, flightAlerts.has(AirPnrAlertType.REINSTATED)],
    [FlightAlertStatus.CONNECTION_RISK, flightLegAlerts.has(AirPnrLegAlertType.CONNECTION_RISK)],
  ];

  const availableAlerts = significantAlerts.filter(([, isAvailable]) => isAvailable);
  const alertCount = availableAlerts.length;
  const hasMultipleAlerts = alertCount > 1;
  const hasAnyAlerts = alertCount > 0;

  if (hasMultipleAlerts && isTripMaybeActive) {
    return {
      ...(tripColorsForFlightAlertsMap.get(FlightAlertStatus.MULTIPLE_UPDATES) ?? defaultReturn),
      i18nOptions: { values: { count: alertCount } },
    };
  }

  if (hasAnyAlerts && (flightAlerts.size || flightLegAlerts.size)) {
    const firstAlert = availableAlerts[0];
    return (firstAlert && tripColorsForFlightAlertsMap.get(firstAlert[0])) ?? defaultReturn;
  }

  return getTripStatusLabelAndColor({ tripStatus: tripSummary.tripStatus });
};
