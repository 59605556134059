/** A pattern that matches safe  URLs. */
const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):[+]?|[^&:/?#]*(?:[/?#]|$))/gi;
/** A pattern that matches safe data URLs. */
const DATA_URL_PATTERN =
  /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i;

export const DEFAULT_LINK_URL = 'https://';

export function sanitizeUrl(url: string): string {
  const trimmedUrl = String(url).trim();
  if (trimmedUrl.match(SAFE_URL_PATTERN) || trimmedUrl.match(DATA_URL_PATTERN)) return trimmedUrl;
  return DEFAULT_LINK_URL;
}

const SCHEME_REGEX = /^(https?|mailto|ftp|tel|file|sms):/i;
const SAFE_URL_REGEX =
  /^((https?|mailto|ftp|file|sms):\/\/[^\s]+|mailto:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|tel:\+?[0-9]+)$/i;

export function validateUrl(url: string): boolean {
  if (!SCHEME_REGEX.test(url)) {
    return false;
  }
  return url === DEFAULT_LINK_URL || SAFE_URL_REGEX.test(url);
}
