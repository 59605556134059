import api from '../api';
import type { AirRevalidateItineraryResponse } from '../types/api/v1/obt/air/air_revalidate_itinerary_response';
import type { AirRetryPaymentRevalidateRequest } from '../types/api/v2/obt/model/air-retry-payment-revalidate-request';

/**
 * /v2/air/pnrs/:pnrId/retry-payment-revalidate
 */
export const retryPaymentRevalidate = (
  pnrId: string,
  query: AirRetryPaymentRevalidateRequest,
): Promise<AirRevalidateItineraryResponse> =>
  api('POST', 'airRetryPayment', {
    urlParam: `/${pnrId}/retry-payment-revalidate`,
    data: query,
  }) as Promise<AirRevalidateItineraryResponse>;
