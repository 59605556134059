import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Entertainment = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" },
        React.createElement("rect", { x: "6.18", y: "8.54", width: "19.64", height: "14.93", rx: "2.29", fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px" }),
        React.createElement("path", { d: "M14.08,16V13.84a.48.48,0,0,1,.72-.41l1.87,1.08,1.87,1.07a.49.49,0,0,1,0,.84l-1.87,1.07L14.8,18.57a.48.48,0,0,1-.72-.41Z", fill: "none", stroke: themeEnrichedPrimaryColor, strokeMiterlimit: "10", strokeWidth: "1.72px" })));
};
Entertainment.defaultProps = defaultIconProps;
export default Entertainment;
