import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type { GetApplicableObtShopFeaturesRequest } from '../types/api/v2/obt/model/obt_shop_features';
import type { OBTShopFeaturesConfig } from '../types/api/v2/obt/model/obtshop-features-config';

const readShopFeaturesApplicability = async (userId?: string): Promise<OBTShopFeaturesConfig> => {
  try {
    const result = (await api('GET', 'userBaseUrl', {
      urlParam: `/${userId}/applicable-obt-shop-features`,
    })) as OBTShopFeaturesConfig;
    return result;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

const shopFeaturesApplicableKey = (userId?: string): unknown[] => ['shopFeaturesApplicability', userId];

export const useShopFeaturesApplicability = (
  request: GetApplicableObtShopFeaturesRequest,
  options?: UseQueryOptions<OBTShopFeaturesConfig, SpotnanaError>,
): UseQueryResult<OBTShopFeaturesConfig, SpotnanaError> =>
  useQuery<OBTShopFeaturesConfig, SpotnanaError>(
    shopFeaturesApplicableKey(request?.userId),
    () => readShopFeaturesApplicability(request?.userId),
    options,
  );
