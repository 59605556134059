import { StorageKeys, webSessionStorage } from 'obt-common';
import { stringifyParams } from 'obt-common/utils/urlUtils';

export function isSessionTimeoutValid(value: unknown): boolean {
  return typeof value === 'number' && value > 0;
}

/**
 * Preserve the current URL in session storage for redirecting the user back to the same page after login.
 */
export function preserveURLForRedirectOnLogin(): void {
  const { pathname, search } = window.location;
  webSessionStorage.setItem(StorageKeys.AUTH_REDIRECT_URL_KEY, stringifyParams({ pathname, search }));
}
