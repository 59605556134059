var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CheckRoom = (_a) => {
    var { primaryColor } = _a, restProps = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M5.5 40Q4.45 40 4.1 39Q3.75 38 4.6 37.3L22.5 23.25V20.5Q22.5 19.85 22.925 19.425Q23.35 19 23.95 19Q25.65 19.05 26.825 17.875Q28 16.7 28 15Q28 13.35 26.825 12.175Q25.65 11 24 11Q22.35 11 21.175 12.175Q20 13.35 20 15H17Q17 12.1 19.05 10.05Q21.1 8 24 8Q26.9 8 28.95 10Q31 12 31 14.9Q31 17.35 29.45 19.25Q27.9 21.15 25.5 21.85V23.25L43.4 37.3Q44.25 38 43.9 39Q43.55 40 42.5 40ZM10 37H38L24 25.85Z" })));
};
CheckRoom.defaultProps = defaultIconProps;
export default CheckRoom;
