import type { DateTime } from '../../common/date_time';
import type { EntityRef } from '../common/entity_reference';

import type { TravelType } from '../common/tmc';
import type { TravelRegionType } from '../common/travel_region';
import { type TripUsageType } from '../../common/trip';
import type { UserBusinessInfoWorkerType } from '../common/user_business_info';

export enum CustomFieldType {
  QUESTION = 0,
  MEETING = 1,
  BUDGET = 2,
  BREX_TOKEN = 3,
  UNRECOGNIZED = -1,
}

export enum PreDefinedAnswers {
  UNKNOWN_CHECKOUT_ANSWER_TYPE = 0,
  OTHER = 1,
  TIMING_OR_SCHEDULING = 2,
  AIRLINE_PREFERENCE = 3,
  AIRPORT_PREFERENCE = 4,
  MILEAGE_OR_PROGRAM_UPGRADE = 5,
  NO_OTHER_OPTION_AVAILABLE = 6,
  FASTER_OPTION = 7,
  NO_ANSWER = 8,
  /** NOT_AVAILABLE - Hardcoded value to be passed as answer by app team for pre book question. */
  NOT_AVAILABLE = 9,
  UNRECOGNIZED = -1,
}

/** This represents the formats in which questions can be asked */
export enum QuestionFormat {
  /**
   * CHECKBOX - This is used if the answer is expected to be of multi-input checkbox type.
   * It contains the possible set of options, from which the user can choose multiple options.
   */
  CHECKBOX = 0,
  /**
   * RADIO_BUTTON - This variable is set if the question/answer is to be displayed in a radio-button style.
   * It contains the possible set of options, from which the user can choose only one option.
   */
  RADIO_BUTTON = 1,
  /**
   * CHECKBOX_WITH_PERCENTAGE - This is similar to checkbox, with the difference being that each option
   * having an additional input field whose values must add up to 100.
   */
  CHECKBOX_WITH_PERCENTAGE = 2,
  /** INPUT_BOX - If the answer to the question is a custom user input. */
  INPUT_BOX = 3,
  UNRECOGNIZED = -1,
}

export enum CustomFieldLocation {
  CUSTOM_FIELD_LOCATION_UNKNOWN = 0,
  /** POLICY_APPROVAL_EMAIL - Traveler's approver will receive this mail when approval for the booking is required. */
  POLICY_APPROVAL_EMAIL = 1,
  /** PNR_EMAIL - Pnr level emails. */
  PNR_EMAIL = 2,
  /** TRIP_EMAIL - Trip level emails. */
  TRIP_EMAIL = 3,
  UNRECOGNIZED = -1,
}

/**
 * User defined key value pairs. For eg:
 * key: Select the purpose of trip
 * value: List of values represented by listid like [External, Internal].
 * The user can also specify the type of input needed by the traveler like checkbox or radio button.
 * Optionally they can also choose to give an input box. Input box is also used to take traveler
 * input if they want to specify some value not covered by the list_id values.
 */
export interface UserDefinedEntity {
  // Optional while creating
  entityId?: string;
  /** The display text that the user will see. For eg: "Choose the purpose of your trip" */
  displayInfo: string;
  inputBox?: boolean;
  checkbox?: UserDefinedEntityUDList | undefined;
  radioButton?: UserDefinedEntityUDList | undefined;
  /** Set to true if this entity is being defined by Spotnana. */
  isSpotnanaDefined?: boolean;
  /** Only set if 'is_spotnana_defined' is true. */
  preDefinedQuestion?: PreDefinedQuestion;
  /** This represents the formats in which questions can be asked */
  questionFormat: QuestionFormat;
  items: UserDefinedEntityEntityItem[];
  /** Type of custom field like question or meeting or budget. */
  customFieldType: CustomFieldType;
  /** Whether its compulsory to answer the question or not. */
  isRequired: boolean;
  /**
   * Whether the question is disabled temporarily.
   * The question will not show in the checkout flow.
   */
  isDisabled: boolean;
  source: UserDefinedEntitySource;
  /** In case source type is COMPANY_CONFIG, its metadata would be present. */
  companyConfigMetadata?: UserDefinedEntityCompanyConfigMetadata;
  /** Only the users satisfying the configured conditions would be asked this custom field. */
  customFieldConfig?: CustomFieldConfig;
  /** Whether to include the custom field in the ITIN & confirmation emails. */
  includeInItin: boolean;
  /** Whether to show custom field id on UI or not */
  showCustomFieldIdOnUi: boolean;
  totalNumItems?: number;
  /** Display the custom fields in all these locations. */
  customFieldLocations: CustomFieldLocation[];
}

/** Source type for the custom field */
export enum UserDefinedEntitySource {
  /** MANUAL - Manual indicates options would be added by the user during creation / update. */
  MANUAL = 0,
  /** COMPANY_CONFIG - Company config indicates, options would be taken from company configuration. */
  COMPANY_CONFIG = 1,
  UNRECOGNIZED = -1,
}

/** User defined list of values like [External, Internal]. */
export interface UserDefinedEntityUDList {
  items: UserDefinedEntityUDListItem[];
}

export interface UserDefinedEntityUDListItem {
  itemId: number;
  displayValue: string;
}

/**
 * Each option for user defined entity will be in the form of (code, value).
 * Like for Out of Policy reasons the options will be
 * ("no-option", "NO_OTHER_OPTION_AVAILABLE")
 */
export interface UserDefinedEntityEntityItem {
  /** To be captured in db */
  itemCode: string;
  /** To show in the front end to the user */
  itemValue: string;
  /** Applicable from date */
  applicableFrom?: DateTime;
  /** Applicable till date */
  applicableTill?: DateTime;
}

/** Source COMPANY_CONFIG related metadata */
export interface UserDefinedEntityCompanyConfigMetadata {
  /**
   * This field would be used to form options. For example when its picked as COST_CENTER,
   * backend would list all cost centers of the company as options.
   */
  optionsParam: UserDefinedEntityCompanyConfigMetadataField;
}

export enum UserDefinedEntityCompanyConfigMetadataField {
  FIELD_UNKNOWN = 0,
  COST_CENTER = 1,
  LEGAL_ENTITY = 2,
  OFFICE = 3,
  DEPARTMENT = 4,
  UNRECOGNIZED = -1,
}

/** Custom field configuration to restrict it to a set of travelers & conditions. */
export interface CustomFieldConfig {
  /** Worker types for which this custom field is applicable. */
  workerTypes: UserBusinessInfoWorkerType[];
  /** Countries for which this custom field is applicable. */
  countries: string[];
  /** Legal entities for which this custom field is applicable. */
  legalEntities: EntityRef[];
  /** Departments for which this custom field is applicable. */
  departments: EntityRef[];
  /** Cost centers for which this custom field is applicable. */
  costCenters: EntityRef[];
  /** Offices for which this custom field is applicable. */
  offices: EntityRef[];
  /** Travel types for which this custom field is applicable. In UI, this field maps to booking type. */
  travelTypes: TravelType[];
  /** Travel region types for which this custom field is applicable. In UI, this field maps to travel type. */
  travelRegionTypes: TravelRegionType[];
  /** List of destination countries for which this custom field is applicable. */
  destinationCountries: string[];
  /**
   * List of trip usage types for which this custom field is applicable. If the
   * list is empty, all trip usage types are applicable.
   */
  tripUsageTypes: TripUsageType[];
  bookingInitiatorTypes: BookingInitiatorType[];
}

export interface ApplicableTo {
  /** Entity type for which the custom field is applicable. */
  entityType: ApplicableToEntityType;
  /** Entity id for which the custom field is applicable. */
  entityId: string;
}

export enum ApplicableToEntityType {
  ORGANIZATION = 0,
  UNRECOGNIZED = -1,
}

/** The message defines the format of a 'question' which can be asked to a user in any kind of workflows. */
export interface Question {
  isRequired: boolean;
  /** The unique id identifying the question. */
  entityId: string;
  /** The display text that the user will see. For eg: "Choose the purpose of your trip" */
  displayInfo: string;
  /** Set to true if the answer is expected to be a free form text input. */
  inputBox: boolean;
  /** Spotnana defined question type. */
  questionType?: PreDefinedQuestion;
  /** This 'checkbox' is set if the answer is expected to be of multi-input checkbox type. It contains the possible set of options, from which the user can choose multiple options. */
  checkbox?: QuestionOptionList | undefined;
  /** The 'radio_button' variable is set if the question/answer is to be displayed in a radio-button style. It contains the possible set of options, from which the user can choose only one option. */
  radioButton?: QuestionOptionList | undefined;
  /**
   * This is similar to checkbox, with the difference being that each option
   * having an additional input field whose values must add up to 100.
   */
  checkboxWithPercentage?: QuestionOptionList | undefined;
  /** Total number of options. */
  totalNumOptions?: number;
  travelerAccess: TravelerAccess;
  preselectedResponses?: ResponseItem[];
}

/** List of potential Options for any question. */
export interface QuestionOptionList {
  /** List of all possible options. */
  options: QuestionOptionListOption[];
}

/** An item representing a single 'Option'. */
export interface QuestionOptionListOption {
  /** The enum corresponding to the option. */
  optionType: PreDefinedAnswers;
  /** The text to be displyed to the user beside this option. */
  displayValue: string;
  /** The code which is sent in answer response. */
  displayCode: string;
}

export interface EntityAnswer {
  /** The unique id identifying the question. */
  entityId: string;
  /** The text input given by user (if any). */
  userInput?: string;
  /** The id/enum value corresponding to the option chosen by the user as answer. */
  itemIds?: number[];
  answers?: AnswerPair[];
  /** This can be either Question, Meeting or Budget. The default is Question. */
  customFieldType?: CustomFieldType;
}

/** Answer is in the format ("item", "45") or ("item2", "55") */
export interface AnswerPair {
  /**
   * Item is the option selected from the list of available choices
   * This maps to EntityItem.itemCode field.
   * Custom field's external Id will be mapped to item. (Meeting ID etc)
   */
  item: string;
  /** Value is the additional input provided while selecting one of the options */
  value?: string;
  /** Description of item. Maps to EntityItem.itemValue field. */
  description?: string;
}

export interface PreSearchQuestions {
  questions: UserDefinedEntity[];
}

export interface PreSearchAnswers {
  answers: EntityAnswer[];
  userEntitiesResponseId: string;
}

export interface PreBookingQuestions {
  questions: UserDefinedEntity[];
}

export interface PreBookingAnswers {
  answers: EntityAnswer[];
}

/** Custom Field Id contains the Type of the Custom field and its associated external Id */
export interface CustomFieldId {
  type: CustomFieldType;
  /**
   * This is the ID set by the customer from their side.
   * This id is sent from their system / DB.
   */
  externalId: string;
}

/** This message contains the type, externalId and description of the custom field. */
export interface CustomField {
  id?: CustomFieldId;
  description: string;
}

export interface PreDefinedQuestion {
  /** Spotnana defined pre search question type. */
  preSearch: PreDefinedQuestionPreSearchQuestionTypes | undefined;
  /** Spotnana defined pre checkout question type. */
  preCheckout: PreDefinedQuestionPreCheckoutQuestionTypes | undefined;
}

export enum PreDefinedQuestionPreSearchQuestionTypes {
  UNKNOWN_SEARCH_QUESTION_TYPE = 0,
  /**
   * PURPOSE_OF_TRIP - This is required to ask purpose of the trip user is going to.For example-:
   * meeting,training,interview,etc
   */
  PURPOSE_OF_TRIP = 1,
  UNRECOGNIZED = -1,
}

export enum PreDefinedQuestionPreCheckoutQuestionTypes {
  UNKNOWN_CHECKOUT_QUESTION_TYPE = 0,
  /** OOP_REASON - This is required to ask reason for booking out of policy itinerary. */
  OOP_REASON = 1,
  /**
   * LLF_NOT_CHOSEN_REASON - This is required to ask reason for not choosing lowest logical fare
   * available.
   */
  LLF_NOT_CHOSEN_REASON = 2,
  /** LEK_CASE_CODE - Custom question for client LEK. */
  LEK_CASE_CODE = 3,
  /** USER_DEFINED_QUESTION - the default question type for all pre checkout questions which have been created from UI. */
  USER_DEFINED_QUESTION = 4,
  /** OOP_REASON_CODE - OOP reason code, keeping it separate so that existing OOP flow doesnt break. */
  OOP_REASON_CODE = 5,
  /** HOTEL_PREFERENCE_REASON_CODE - Hotel preference reason codes */
  HOTEL_PREFERENCE_REASON_CODE = 6,
  /** CAR_PREFERENCE_REASON_CODE - Car preference reason codes */
  CAR_PREFERENCE_REASON_CODE = 7,
  UNRECOGNIZED = -1,
}

export interface ResponseItem {
  /**
   * The response of the custom field. It can be a text input from the user or
   * a custom field code if the input is from a radio button or checkbox.
   */
  response: string;
  /**
   * This contains additional input to the above response. It will only be used
   * for checkbox with percentage and will contain the percentage value.
   */
  value: string;
  /** Optional description for the response. */
  description: string;
}

export enum TravelerAccess {
  TRAVELER_ACCESS_UNKNOWN = 0,
  /**
   * HIDDEN - The traveler does not have read or write access on the custom field.
   * The field is completely opaque to the traveler.
   */
  HIDDEN = 1,
  /** READ_ACCESS - The traveler has read-only access on the custom field. */
  READ_ACCESS = 2,
  /** WRITE_ACCESS - The traveler has both read and write access on the custom field. */
  WRITE_ACCESS = 3,
  UNRECOGNIZED = -1,
}

export enum BookingInitiatorType {
  BOOKING_INITIATOR_TYPE_UNKNOWN = 0,
  AGENT = 1,
  NON_AGENT = 2,
  UNRECOGNIZED = -1,
}
