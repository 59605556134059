import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const container = css`
  ${flex.init}
  ${flex.column}
  ${flex.align.center}
  ${spacings.small}
`;

export const success_icon = themed(
  ({ palette }) => css`
    color: ${palette.icon.success};
    height: 3rem;
    width: 3rem;
  `,
);

export const text_container = css`
  ${flex.init}
  ${flex.column}
  ${flex.align.center}
  ${spacings.smallest}
`;
