/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Airline preferences.
 * @export
 * @interface AirlinePref
 */
export interface AirlinePref {
  /**
   *
   * @type {Array<string>}
   * @memberof AirlinePref
   */
  airlines: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AirlinePref
   */
  flightType?: AirlinePrefFlightTypeEnum;
}

export const AirlinePrefFlightTypeEnum = {
  UnknownFlightType: 'UNKNOWN_FLIGHT_TYPE',
  Domestic: 'DOMESTIC',
  International: 'INTERNATIONAL',
  All: 'ALL',
} as const;

export type AirlinePrefFlightTypeEnum = (typeof AirlinePrefFlightTypeEnum)[keyof typeof AirlinePrefFlightTypeEnum];
