import { css } from '@emotion/react';

export const spotnana_input_text = css`
  /* stylelint-disable */
  .spotnana-text-editor-input__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .spotnana-text-editor-input__label {
    display: flex;
    flex: 1;
    color: rgb(102, 102, 102);
  }

  .spotnana-text-editor-input__input {
    display: flex;
    flex: 2;
    border: 1px solid rgb(153, 153, 153);
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-radius: 5px;
    min-width: 0;
  }
`;
