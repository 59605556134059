import { StorageKeys } from '../../types/storage';
import { fetchStorageValue } from '../../hooks/useStorage';
import { getFromMemoryCache, saveInMemoryCache } from './inMemoryUrlCache';
import extractApplicationIdFromUrl from './extractApplicationIdFromUrl';

import setApplicationId from './setApplicationId';

interface Params {
  appIdFromUrl: string | null;
  appIdFromStorage: string | null;
}

async function processAppIds({ appIdFromStorage, appIdFromUrl }: Params): Promise<string | null | undefined> {
  // On every `getApplicationId()` invoking app attempt to
  // save the applicationId from the URL in memory
  if (!getFromMemoryCache() && appIdFromUrl) {
    saveInMemoryCache(appIdFromUrl);
  }

  // When the applicationId is not saved in the storage
  // but was memoized from the URL, save it in the storage
  const initialAppIdFromUrl = getFromMemoryCache();

  if (!appIdFromStorage && initialAppIdFromUrl) {
    await setApplicationId(initialAppIdFromUrl).catch(() => null);
  }

  return appIdFromStorage || initialAppIdFromUrl;
}

async function getApplicationId(): Promise<string | undefined | null> {
  const appIdFromUrl = extractApplicationIdFromUrl();
  const appIdFromStorage = await fetchStorageValue<string>(StorageKeys.APPLICATION_ID).catch(() => null);
  return processAppIds({ appIdFromUrl, appIdFromStorage });
}

export default getApplicationId;
