import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const fare_footer_container = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.justify.between}
    ${flex.align.center}
    background-color: ${palette.surface.base};
    border-top: 1px solid ${palette.border.light};
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    ${paddings.y.compact}
    ${paddings.x.cozy}
  `,
);
