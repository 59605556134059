export const SESSION_ID_URL_SEARCH_PARAMS_KEY = 'session-id';

const REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

let sessionId: string | undefined;

export const setSessionId = (value: string): void => {
  sessionId = value;
};

export const getSessionId = (): string | undefined => sessionId;

export const clearSessionId = (): void => {
  sessionId = undefined;
};

export const parseSessionIdFromURL = (url?: string): string | null => {
  try {
    const urlObj = new URL(url ?? window.location.href);
    const sessionIdFromUrl = urlObj.searchParams.get(SESSION_ID_URL_SEARCH_PARAMS_KEY);
    return sessionIdFromUrl;
  } catch {
    return null;
  }
};

export const verifyUUID = (uuid: string): boolean => {
  return REGEX.test(uuid);
};
