/* eslint-disable import/no-import-module-exports */
/* eslint-disable global-require */
import './utils/globalPolyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import {
  defaultQueryClient,
  AuthProvider,
  Config,
  ProviderType,
  ComposeProviders,
  ErrorProvider,
  tokenExchange,
  FeatureFlagProvider,
} from 'obt-common';

import { collectApplicationId } from 'src/utils/applicationId';
import { collectTmcId } from 'src/utils/tmcId';
import { RestoreSessionId } from 'obt-common/utils/xSessionId/RestoreSessionId';
import { installFullStory } from 'src/app/tracking/FullStory';
import { createRoot } from 'react-dom/client';
import { WebviewMessagingProvider } from 'src/providers/WebviewMessagingContext';
import { parseParams } from 'obt-common/utils/urlUtils';
import { SnackbarErrorContextProvider } from './components/SnackbarErrorContext';
import { DebarUserContextProvider } from './components/DebarUserContext';
import * as serviceWorker from './serviceWorker';
import amplifyConfig from './amplify.config';
import App from './App';
import { getTokensViaPostMessage } from './utils/getTokensViaPostMessage';
import SuspenseLoaderFallback from './v2-components/shared/SuspenseLoaderFallback';
import { initSentry, addBreadcrumb, captureException } from './utils/errorTracking';
import MaintenancePage from './Maintenance';
import { WhiteLabelConfigProvider } from './components/WhitelabelConfigProvider/WhitelabelConfigProvider';
import { TokenExchange } from './components/TokenExchange/TokenExchange';
import { i18n } from './translations';
import { collectOrgId } from './utils/orgId/orgId';
import { initLocatorUI } from './initLocatorUI';
import { ExternalAuthCodeProvider } from './components/ExternalAuthCodeProvider/ExternalAuthCodeProvider';

initLocatorUI();

const isEmbed = Config.VITE_TARGET === 'embed';
const urlParams = parseParams(window.location.search);
const authIdp = urlParams?.idp || '';
const isTokenExchangeIdp = tokenExchange.includes(authIdp);

if (typeof window !== 'undefined') {
  if (Config.VITE_ENABLE_AXE === 'true') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }

  // initialize tracking
  installFullStory(isEmbed);
  initSentry();

  collectApplicationId();
  collectTmcId();
  collectOrgId();
}

const tokenExchangeWorkflow = {
  initiateTokenExchange: () => getTokensViaPostMessage(authIdp),
};

const AppProviders: ProviderType[] = [
  [I18nextProvider, { i18n }],
  DebarUserContextProvider,
  SnackbarErrorContextProvider,
  [QueryClientProvider, { client: defaultQueryClient }],
  MaintenancePage,
  ExternalAuthCodeProvider,
  [
    AuthProvider,
    { amplifyConfig, Auth, disableVerifyUserSessionOnMount: isTokenExchangeIdp, tokenExchangeWorkflow, Hub },
  ],
  // Render TokenExchange component right after AuthProvider to prevent any actions/BE calls for previous session while
  // token exchange flow is initiated. Ref: ST-80581
  TokenExchange,
  ErrorProvider,
  SuspenseLoaderFallback,
  [FeatureFlagProvider, { async: true }],
  WebviewMessagingProvider,
];

const render = (): void => {
  const rootElement = document.getElementById('root');
  if (!rootElement) {
    captureException(new Error('Root element not found'), { source: 'web/src/index.tsx` render' });
    return;
  }

  const root = createRoot(rootElement, {
    onRecoverableError: (error, errorInfo) => {
      captureException(error, { source: 'web/src/index.tsx onRecoverableError', extra: { errorInfo } });
    },
    identifierPrefix: 'SN',
  });

  root.render(
    <React.Suspense fallback={null}>
      <ComposeProviders providers={AppProviders}>
        <RestoreSessionId />
        <WhiteLabelConfigProvider>
          <App />
        </WhiteLabelConfigProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ComposeProviders>
    </React.Suspense>,
  );
};

addBreadcrumb({
  message: 'App is rendering',
  category: 'app',
  data: { isEmbed, isTokenExchangeIdp, urlParams },
});
render();

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./App', render);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
