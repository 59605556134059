/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const CarRateType = {
  Unknown: 'UNKNOWN',
  Guaranteed: 'GUARANTEED',
  Prepaid: 'PREPAID',
} as const;

export type CarRateType = (typeof CarRateType)[keyof typeof CarRateType];
