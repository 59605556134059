import mapValues from 'lodash/mapValues';
import { useLoggedInUser } from '../providers/AuthProvider';
import { useFetchUserFeatures } from '../queries/admin/index';
import type { FeatureConfig } from '../types/api/v2/obt/model/feature-config';
import { FeatureNameEnum } from '../types/api/v2/obt/model/feature-name-enum';

export const FEATURE_FLAG_RECORD = {
  flightOOP: FeatureNameEnum.FlightResultsOop,
  hotelOOP: FeatureNameEnum.HotelResultsOop,
  railOOP: FeatureNameEnum.RailResultsOop,
  carOOP: FeatureNameEnum.CarsResultsOop,
  paymentAddCard: FeatureNameEnum.PaymentAddCard,
  menuItemPolicy: FeatureNameEnum.MenuItemPolicies,
  travelerSelection: FeatureNameEnum.TravelerSelection,
  personalTravel: FeatureNameEnum.FeaturePersonalTravel,
  railBooking: FeatureNameEnum.ProductObtRail,
  menuItemCompanyRemarks: FeatureNameEnum.MenuItemCompanyRemarks,
  homePageAddUser: FeatureNameEnum.HomepageAddUser,
  menuItemCustomFields: FeatureNameEnum.MenuItemCustomFields,
  paymentUnusedCredits: FeatureNameEnum.PaymentUnusedCredits,
  emailUpdateCompanyAdmin: FeatureNameEnum.EmailUpdateByCompanyAdmin,
  nonprofileBookingSearchPage: FeatureNameEnum.AdhocBookingSearchPage,
  nonprofileBookingSaveTraveler: FeatureNameEnum.AdhocBookingSaveTraveler,
  adminVirtualCardFeatureEnabled: FeatureNameEnum.AdminVirtualCard,
  centralCardServiceFeeFeatureEnabled: FeatureNameEnum.CentralCardServiceFee,
  hideSearchCriteria: FeatureNameEnum.HideSearchCriteria,
  serviceChargeConfigDisabled: FeatureNameEnum.ServiceChargeConfigDisabled,
  hideCompanySettingMenu: FeatureNameEnum.HideSettingsMenuItemCompany,
  hideUserSettingMenu: FeatureNameEnum.HideSettingsMenuItemUsers,
  hidePolicyApprovalSection: FeatureNameEnum.HidePolicyApprovalSection,
  hidePolicyCreation: FeatureNameEnum.HidePolicyCreation,
  hideSettingsText: FeatureNameEnum.HideSettingsTextFromMenu,
  hideLeftNav: FeatureNameEnum.HideLeftNav,
  hidePolicyAirCarrier: FeatureNameEnum.HidePolicyAirCarrier,
  hidePolicyAirCO2: FeatureNameEnum.HidePolicyAirCo2,
  hidePolicyHotelPriceLocationCustomisation: FeatureNameEnum.HidePolicyHotelPriceLocationCustomisation,
  hideProfilePreferencesAirlines: FeatureNameEnum.HideProfilePreferencesAirlines,
  hideProfilePreferencesAlliances: FeatureNameEnum.HideProfilePreferencesAlliances,
  hideAir: FeatureNameEnum.ProductObtAirDisabled,
  hideHotel: FeatureNameEnum.ProductObtHotelDisabled,
  hideCar: FeatureNameEnum.ProductObtCarDisabled,
  hideLimo: FeatureNameEnum.ProductObtLimoDisabled,
  hideConcierge: FeatureNameEnum.ProductObtConciergeDisabled,
  eventBookingEnabled: FeatureNameEnum.EventBookingEnabled,
  employeeLevelDesignatedApproverEnabled: FeatureNameEnum.EmployeeLevelDesignatedApproverEnabled,
  hideLeftNavEmbed: FeatureNameEnum.HideLeftNavEmbed,
  hidePolicyAirNotAllowedToBeBooked: FeatureNameEnum.HidePolicyAirNotAllowedToBeBooked,
  hideGuestBookingSetting: FeatureNameEnum.HideGuestBookingSetting,
  userCreationPersonaTypeOptions: FeatureNameEnum.UserCreationPersonaTypeOptions,
  disallowMultiCityAddFlight: FeatureNameEnum.DisallowMultiCityAddFlight,
  showIndianCardPaymentGuidelines: FeatureNameEnum.ShowIndianCardPaymentGuidelines,
};

type UserFeatureName = keyof typeof FEATURE_FLAG_RECORD;
export type UserFeatures = {
  features: Record<UserFeatureName, boolean>;
  featureValues: Record<Partial<UserFeatureName>, string> | Record<string, never>;
  isLoading: boolean;
};

export const DEFAULT_USER_FEATURES: UserFeatures['features'] = {
  flightOOP: true,
  hotelOOP: true,
  railOOP: true,
  carOOP: true,
  paymentAddCard: true,
  menuItemPolicy: true,
  travelerSelection: true,
  personalTravel: true,
  railBooking: true,
  menuItemCompanyRemarks: true,
  homePageAddUser: true,
  menuItemCustomFields: true,
  paymentUnusedCredits: true,
  emailUpdateCompanyAdmin: true,
  nonprofileBookingSearchPage: false,
  nonprofileBookingSaveTraveler: false,
  adminVirtualCardFeatureEnabled: false,
  centralCardServiceFeeFeatureEnabled: false,
  hideSearchCriteria: false,
  serviceChargeConfigDisabled: false,
  hideCompanySettingMenu: false,
  hideUserSettingMenu: false,
  hidePolicyApprovalSection: false,
  hidePolicyCreation: false,
  hideSettingsText: false,
  hideLeftNav: false,
  hidePolicyAirCarrier: false,
  hidePolicyAirCO2: false,
  hidePolicyHotelPriceLocationCustomisation: false,
  hideProfilePreferencesAirlines: false,
  hideProfilePreferencesAlliances: false,
  hideAir: false,
  hideHotel: false,
  hideCar: false,
  hideLimo: false,
  hideConcierge: false,
  eventBookingEnabled: false,
  employeeLevelDesignatedApproverEnabled: false,
  hideLeftNavEmbed: false,
  hidePolicyAirNotAllowedToBeBooked: false,
  hideGuestBookingSetting: false,
  userCreationPersonaTypeOptions: false,
  disallowMultiCityAddFlight: false,
  showIndianCardPaymentGuidelines: false,
};

const isEnabled = (features: FeatureConfig[], name: string, userFeatureName: UserFeatureName): boolean => {
  const feature = features.find((element) => element.name === name);

  return (feature?.value ? feature?.value === 'true' : DEFAULT_USER_FEATURES[userFeatureName]) ?? true;
};

const getValue = (features: FeatureConfig[], name: string): string => {
  const feature = features.find((element) => element.name === name);

  return feature?.value ?? '';
};

/**
 * Hook to fetch and process user features based on user ID or the logged-in user.
 * @param {Object} params - Parameters for the hook.
 * @param {string} [params.userId] - User ID for which features need to be fetched. Defaults to the logged-in user ID if not provided.
 * @param {boolean} [params.enabled=true] - A flag to enable or disable feature fetching.
 * @returns {UserFeatures} An object containing processed feature flags, feature values, and the loading state.
 */
const useUserFeatures = ({ userId, enabled = true }: { userId?: string; enabled?: boolean } = {}): UserFeatures => {
  const { data: loggedInUser } = useLoggedInUser();
  const { data: userFeatures, isLoading } = useFetchUserFeatures(
    userId || loggedInUser?.userOrgId?.userId?.id, // Use logged-in user ID if userId is not provided
    enabled,
  );

  const features = userFeatures?.features;
  if (!features) {
    return { features: DEFAULT_USER_FEATURES, featureValues: {}, isLoading };
  }

  return {
    features: mapValues(FEATURE_FLAG_RECORD, (featureFlag, featureFlagName) =>
      isEnabled(features, featureFlag, featureFlagName as UserFeatureName),
    ),
    featureValues: mapValues(FEATURE_FLAG_RECORD, (featureFlag) => getValue(features, featureFlag)),

    isLoading,
  };
};

export default useUserFeatures;
