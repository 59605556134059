import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const UserGroup = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 12 12" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.91797 3.88407C3.91797 2.73533 4.85252 1.80078 6.00123 1.80078C7.14995 1.80078 8.0845 2.73535 8.0845 3.88407C8.0845 4.66323 7.65447 5.34376 7.01934 5.70104C6.71809 5.87049 6.3708 5.96734 6.00123 5.96734C5.63167 5.96734 5.28438 5.87049 4.98313 5.70104C4.34802 5.34376 3.91797 4.66325 3.91797 3.88407ZM7.0608 6.39395H4.9392C3.98402 6.39395 3.20693 7.17104 3.20693 8.12621V11.6336C3.20693 11.825 3.36204 11.9801 3.55338 11.9801H8.44662C8.63796 11.9801 8.79307 11.825 8.79307 11.6336V8.12621C8.79307 7.17104 8.01598 6.39395 7.0608 6.39395ZM9.3397 7.29641H10.2677C11.2229 7.29641 12 8.0735 12 9.02865V10.9407C12 11.5138 11.5337 11.9801 10.9606 11.9801H9.42638C9.46484 11.8716 9.48598 11.7551 9.48598 11.6336V8.12619C9.48598 7.83488 9.4343 7.55539 9.3397 7.29641ZM2.6603 7.29643C2.5657 7.55541 2.51402 7.83488 2.51402 8.12621V11.6336C2.51402 11.7551 2.53516 11.8716 2.57363 11.9801H1.03936C0.466242 11.9801 0 11.5138 0 10.9408V9.0287C0 8.07352 0.777094 7.29643 1.73227 7.29643H2.6603ZM2.34316 3.74219C1.48406 3.74219 0.785156 4.44109 0.785156 5.3002C0.785156 6.1593 1.48406 6.8582 2.34316 6.8582C2.56109 6.8582 2.7686 6.81306 2.95711 6.73192C3.28303 6.5916 3.55177 6.34321 3.71805 6.03208C3.83477 5.81371 3.90117 5.56459 3.90117 5.3002C3.90117 4.44112 3.20227 3.74219 2.34316 3.74219ZM8.10156 5.3002C8.10156 4.44109 8.80047 3.74219 9.65957 3.74219C10.5187 3.74219 11.2176 4.44109 11.2176 5.3002C11.2176 6.1593 10.5187 6.8582 9.65957 6.8582C9.44165 6.8582 9.23413 6.81306 9.04562 6.73192C8.7197 6.59163 8.45097 6.34323 8.28468 6.03208C8.16796 5.81373 8.10156 5.56462 8.10156 5.3002Z", fill: themeEnrichedPrimaryColor })));
};
UserGroup.defaultProps = defaultIconProps;
export default UserGroup;
