import { css } from '@emotion/react';
import { flex, margins, themed } from '../utils/styling';
import { cornerRadii, paddings } from '../utils/styling/utilityTokens';
import { getFoundationVar } from '../GlobalStyles/globalStylesUtils';

const textOverflowStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const chip = themed(
  ({ palette }) => css`
    cursor: pointer;
    ${cornerRadii.buttonStandard}

    .MuiAvatar-root {
      margin: 0;
    }

    .MuiChip-label {
      padding: 0;
      ${flex.init}
      ${flex.column}

      .chipLabel {
        color: ${palette.text.primary};
        ${textOverflowStyles}
      }
      .chipSubtext {
        color: ${palette.text.secondary};
        ${textOverflowStyles}
      }
    }

    .DeleteIcon {
      display: inline-flex;
      flex-shrink: 0;
      margin: 0;
    }

    &.Mui-focusVisible {
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: 2px;
    }

    &.Mui-disabled {
      opacity: 1;
      cursor: not-allowed;
      pointer-events: initial;

      .chipLabel {
        color: ${palette.text.disabled};
      }
      .chipSubtext {
        color: ${palette.text.disabled};
      }

      .DeleteIcon {
        pointer-events: none;
      }
    }
  `,
);

export const smallChip = css`
  height: 28px;
  ${cornerRadii.buttonSmall}
  ${paddings.x.extremeTight}

  .MuiChip-label {
    ${paddings.x.superTight}
  }

  .DeleteIcon {
    transform: translateX(1px);
  }
`;

export const mediumChip = css`
  height: 32px;
  ${paddings.x.superTight}
  ${paddings.y.none}
  .MuiChip-label {
    ${paddings.x.buttonSmall}
  }
`;

export const largeChip = css`
  height: 40px;
  ${paddings.x.tight}
  ${paddings.y.none}
  .MuiAvatar-root {
    margin-right: var(${getFoundationVar('spacing-extraSmall')});
  }
  .MuiChip-label {
    ${paddings.x.superTight}
  }
`;

export const xlargeChip = css`
  height: 48px;
  ${paddings.x.tight}
  ${paddings.y.none}
  .MuiAvatar-root {
    margin-right: var(${getFoundationVar('spacing-extraSmall')});
  }
  .MuiChip-label {
    ${paddings.x.superTight}
  }
  .DeleteIcon {
    margin-left: var(${getFoundationVar('spacing-extraSmall')});
  }
`;

export const standard_chip = themed(
  ({ palette }) => css`
    border: 1px solid ${palette.border.light};
    background-color: ${palette.surface.base};
    &.Mui-focusVisible {
      background-color: ${palette.surface.base};
    }
  `,
);

export const error_chip = themed(
  ({ palette }) => css`
    border: 1px solid ${palette.border.errorSubtle};
    background-color: ${palette.bg.errorSubtle};
    &.Mui-focusVisible {
      background-color: ${palette.bg.errorSubtle};
    }
  `,
);

export const success_chip = themed(
  ({ palette }) => css`
    border: 1px solid ${palette.border.successSubtle};
    background-color: ${palette.bg.successSubtle};
    &.Mui-focusVisible {
      background-color: ${palette.bg.successSubtle};
    }
  `,
);

export const truncated_tooltip_content = css`
  ${flex.init}
  ${flex.column}
  ${margins.yb.point25}
`;
