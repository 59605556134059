var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Pencil = (_a) => {
    var { primaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.43 4.277L4.244 9.463a.45.45 0 00-.12.212l-.61 2.518a.45.45 0 00.543.544l.95-.231.015-.004 1.553-.377a.45.45 0 00.212-.119l5.186-5.186A1.798 1.798 0 009.43 4.277zM4.68 11.2l.368.37 1.192-.29 4.276-4.276L9.25 5.728 4.97 10.01 4.68 11.2zm6.69-6.251a.898.898 0 01-.033 1.234l-.503.503L9.569 5.41l.498-.497c.35-.35.92-.35 1.27 0l.034.036zm1.13 7.35a.45.45 0 00-.45-.45H7.317l-.034.002a.45.45 0 00.034.899h4.733l.034-.002a.45.45 0 00.416-.448z" })));
};
Pencil.defaultProps = defaultIconProps;
export default Pencil;
