import { Combobox } from '@ariakit/react';

import { useSelectConfig } from '../../SelectRoot/SelectConfigContext';

import { AutocompleteSelectionModes, TAutocompleteOption } from '../../Autocomplete/Autocomplete.types';

import { FocusedInput } from './FocusedInput';
import { UnfocusedInput } from './UnfocusedInput';
import { FocusedSingleInput } from './FocusedSingleInput';

import { focus_overlay_container } from './AsyncAutocompleteInput.styles';

/**
 * This function returns the AsyncAutocompleteInput. This is basically a MultiValue slot for the Combobox.
 *
 * Why are we writing as a function? This lets us inject selectedOptions and setSelectedOptions to the Input, instead of depending on store.useState("value")
 *
 * Why are we not using store.useState("value")? Say we use store.useState("value"). This would be selectedOptions.map(option=>option.value).
 * This does not have information like who's the parent or if the option is a parent or child.
 * If we think of getting the options back from list of values, we need options to filter from.
 * This list of options available to AsyncAutocomplete may not have options corresponding to store.useState("value")
 *
 * Also, the selected options / value with respect to the Autocomplete Options is always a subset of the actual selectedOptions, which is needed by the Input
 */
interface IGetAsyncAutocompleteInput<TValue extends object> {
  selectedOptions: TAutocompleteOption<TValue>[];
  setSelectedOptions: (newOptions: TAutocompleteOption<TValue>[]) => void;
  visibleSearchText: string;
  setVisibleSearchText: (newSearchText: string) => void;
  OptionChip?: React.FC<{ option: TAutocompleteOption<TValue> }>;
  UnfocusedInputContent?: React.FC<{ selectedOptions: TAutocompleteOption<TValue>[]; disabled: boolean }>;
  selectionMode?: AutocompleteSelectionModes;
  firstOptionRef?: React.RefObject<HTMLDivElement>;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  onCancel?: () => void;
  comboboxRef?: React.RefObject<HTMLInputElement>;
}

export function getAsyncAutocompleteInput<TValue extends object>({
  selectedOptions,
  setSelectedOptions,
  visibleSearchText,
  setVisibleSearchText,
  OptionChip,
  UnfocusedInputContent,
  selectionMode,
  firstOptionRef,
  startAdornment,
  endAdornment,
  onCancel,
  comboboxRef,
}: IGetAsyncAutocompleteInput<TValue>) {
  return () => {
    const { comboboxStore, disabled = false, showLoader } = useSelectConfig<'combobox'>();
    const isUserTyping = comboboxStore.useState('open');
    return (
      <Combobox
        ref={comboboxRef}
        disabled={disabled}
        store={comboboxStore}
        render={(props: Omit<React.HTMLAttributes<HTMLElement>, 'color'>) => {
          return (
            <div css={focus_overlay_container}>
              <UnfocusedInput
                selectedOptions={selectedOptions}
                disabled={disabled}
                inputProps={{ ...props, disabled }}
                UnfocusedInputContent={UnfocusedInputContent}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
              />
              {isUserTyping &&
                (selectionMode === AutocompleteSelectionModes.SINGLE ? (
                  <FocusedSingleInput
                    inputProps={{ ...props, disabled }}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    showLoader={showLoader}
                    onCancel={onCancel}
                  />
                ) : (
                  <FocusedInput
                    selectedOptions={selectedOptions}
                    inputProps={{ ...props, disabled }}
                    setSelectedOptions={setSelectedOptions}
                    OptionChip={OptionChip}
                    showLoader={showLoader}
                    visibleSearchText={visibleSearchText}
                    setVisibleSearchText={setVisibleSearchText}
                    firstOptionRef={firstOptionRef}
                  />
                ))}
            </div>
          );
        }}
      />
    );
  };
}
