import { css } from '@emotion/react';
import { flex, margins, themed } from '@spotnana/blocks/src/utils';
import { paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const announcements_ctr = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.column}
    border-radius: ${palette.border.radiusRegular};
    ${paddings.yt.tight}
    ${paddings.yb.extraCozy}
    background: ${palette.surface.base};
    ${flex.justify.between}
    ${spacings.large}
    height: 100%;
  `,
);

export const announcements_header = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.justify.between}
    ${flex.align.center}
    border-bottom: 1px solid ${palette.border.light};
    ${paddings.yb.comfortable}
    ${margins.yb.m1point5}
  `,
);

export const announcements = css`
  ${flex.init}
  ${flex.column}
  ${spacings.extraLarge}
  flex-grow: 1;
  overflow: scroll;
  height: calc(100vh - 210px);
`;

export const no_announcements_ctr = css`
  ${flex.init}
  ${flex.justify.center}
  ${flex.align.center}
  height: 100%;
`;
