import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const fare_list_item = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.align.center}
    border: 1px solid ${palette.border.light};
    border-radius: ${palette.border.radiusRegular};
    ${paddings.x.cozy}
    ${paddings.y.cozy}
    background-color: ${palette.surface.base};
    :hover {
      background-color: ${palette.bg.hover};
      cursor: pointer;
    }
  `,
);

export const fare_list_summary = css`
  ${flex.init}
  ${flex.column}
  ${flex.align.start}
  ${spacings.extraSmall}
  ${flex.one}
`;

export const arrow_icon = themed(
  ({ palette }) => css`
    &.MuiButton-sizeMedium.BlocksIconButton-root {
      background-color: ${palette.surface.base};
      pointer-events: none;
    }
  `,
);
