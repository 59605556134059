import type {
  AirPref,
  AirPrefMealPref,
  FarePrefFareType,
  SeatAmenityPrefAirSeatAmenityType,
  SeatLocationPrefPosition,
  SeatLocationPref,
} from './api/v1/obt/common/air_preference';
import {
  AirlinePrefFlightTypeEnum,
  FarePrefFareTypeEnum,
  SeatAmenityPrefAirSeatAmenityTypeEnum,
  SeatLocationPrefPositionEnum,
} from './api/v1/obt/common/air_preference';
import { AllianceEnum, CabinEnum, MealTypeEnum } from './api/v1/obt/air/air_common';
import type { RailPref } from './api/v1/obt/common/rail_preference';
import type { RailCard as IProfileRailCard } from './api/v1/common/card';
import { CardCompany, CardType, CreditCardAccessType } from './api/v1/common/card';
import type {
  CardConfirmRequest,
  CardCreateRequest,
  CardCreateResponse,
} from './api/v1/obt/profile/payments/credit_cards_crud';
import type {
  Country,
  Designation,
  Office,
  WorkerType,
  AccountingCode,
  PersonaBasicInfo,
} from './api/v1/obt/common/organization';
import type { Gender } from './api/v1/common/gender';
import { GenderEnum } from './api/v1/common/gender';
import type {
  HighestFlightCabinByDurationPropsHighestCabinForDuration,
  MaxFlightBookingPriceByDurationPropsMaxPriceForDuration,
} from './api/v1/obt/policy/policy_rules';
import type {
  ImmigrationDocument,
  KnownTravelerNumber,
  NationalDoc as NationalDocV1,
  Passport,
  PaymentInfo,
  PaymentInfoApplicableTo,
  PreferredPronoun,
  RedressNumber,
  UserTitle,
} from './api/v1/obt/common/user';
import {
  NationalDocType,
  PaymentInfoApplicableToEnum,
  UserTitleEnum as UserTitleEnumV1,
} from './api/v1/obt/common/user';
import type {
  ListTripSummariesResponse,
  ListTripSummariesResponseAirPnrSummary,
  ListTripSummariesResponseAirPnrSummaryLegInfo,
  ListTripSummariesResponseCarPnrSummary,
  ListTripSummariesResponseHotelPnrSummary,
  ListTripSummariesResponsePnrSummary,
  ListTripSummariesResponseTripSummary,
  ListTripsResponse,
} from './api/v1/obt/trip/list_trips';
import type { LoyaltyInfo, LoyaltyInfoType, TravelPref } from './api/v1/obt/common/traveler_personal_info';
import { LoyaltyInfoTypeEnum } from './api/v1/obt/common/traveler_personal_info';
import type {
  PNRSearchResponsePNR,
  PNRSearchResponseSegment,
  PNRSearchResponseSegmentAir,
  PNRSearchResponseSegmentCar,
  PNRSearchResponseSegmentHotel,
} from './api/v1/obt/pnr/pnr_search_response';
import type { TierEnumNumerals, UserOrgId, Persona, NonProfileUserInfo } from './api/v1/obt/common/user_org_id';
import { PersonaEnum, TierEnum } from './api/v1/obt/common/user_org_id';
import type { PhoneNumber } from './api/v1/common/phone_number';
import { PhoneNumberTypeEnum } from './api/v1/common/phone_number';
import type { PreBookQuestionRequest, PreBookQuestionResponse } from './api/v1/obt/policy/pre_search_and_book_question';
import type { RoleInfo, RoleInfoType } from './api/v1/obt/profile/role/roles_info';
import { RoleInfoTypeEnum } from './api/v1/obt/profile/role/roles_info';
import type {
  SearchTravelerResponse,
  SearchTravelerResponseTravelerSearchResult,
} from './api/v1/obt/profile/search_traveler_response';

import { AirAncillariesResponseReqdParamsForCheckoutParameterEnum } from './api/v1/obt/air/air_ancillaries';
import type { ImageV1 } from './api/v1/common/image_group';
import type { Name } from './api/v1/common/name';
import { NameSuffix } from './api/v1/common/name';
import type { PostalAddress } from './api/v1/common/postal_address';
import type { ReadTravelerResponse } from './api/v1/obt/profile/read_traveler_response';
import type { Traveler } from './api/v1/obt/common/traveler';
import { UpdateTravelerRequestTypeEnum } from './api/v1/obt/profile/update_traveler_request';
import type { UserBasicInfo } from './api/v1/obt/common/user_basic_info';
import type { UserBasicInfo as UserBasicInfoV2 } from './api/v2/obt/model/user-basic-info';
import type { UserDefinedEntity } from './api/v1/obt/policy/user_defined_entity';
import { CustomFieldType } from './api/v1/obt/policy/user_defined_entity';
import type { EmailConfiguration } from './api/v1/obt/common/user_business_info';
import type { AirPnrLegInfoTravelerRestrictions } from './api/v1/obt/pnr/pnr';
import { AirPnrLegInfoTravelerRestrictionsRestriction } from './api/v1/obt/pnr/pnr';
import type { TravelPreferences } from './api/v2/obt/model/travel-preferences';
import type { TravelPreferenceNote } from './api/v2/obt/model/travel-preference-note';
import { MealPrefInclMealPrefsEnum } from './api/v2/obt/model';
import type { HotelPref } from './api/v1/obt/common/hotel_preference';
import type { CarPref } from './api/v1/obt/common/car_preference';
import type { EmergencyContact } from './api/v1/obt/common/emergency_contact';

export type {
  Question as IQuestion,
  EntityAnswer as IEntityAnswer,
  QuestionOptionListOption as IQuestionOptionListOption,
  AnswerPair as IAnswerPair,
} from './api/v1/obt/policy/user_defined_entity';
export const CustomFieldTypeEnum = CustomFieldType;

export type { PreBookAnswersResponse as IPreBookAnswersResponse } from './api/v1/obt/policy/pre_search_and_book_question';
export type { PreBookQuestionResponseQuestionInfo } from './api/v1/obt/policy/pre_search_and_book_question';
export type ITravelerLoyaltyInfo = LoyaltyInfo;
export type ITravelerPaymentInfo = PaymentInfo;
export type ITravelerPaymentCard = PaymentInfo['card'];
export type ICardAccessType = CreditCardAccessType;
export type IUserBasicInfo = UserBasicInfo;
export type IUserBasicInfoV2 = UserBasicInfoV2;
export type ITravelerName = Name;
export type ISearchTravelerResponseTravelerSearchResult = SearchTravelerResponseTravelerSearchResult;
export type IReadTravelerResponse = ReadTravelerResponse;
export type ITraveler = Traveler;
export type UserOrgIdOrNull = UserOrgId | null;
export type IUserOrgId = UserOrgId;
export type INonProfileUserInfo = NonProfileUserInfo;
export type IRoleInfo = RoleInfo;
export type IRoleInfoType = RoleInfoType;
export type IPostalAddress = PostalAddress;
export type IPhoneNumber = PhoneNumber;
export type IPassport = Passport;
export type INationalDoc = NationalDocV1;
export type IRedressNumber = RedressNumber;
export type IKnownTravelerNumber = KnownTravelerNumber;
export type ILoyaltyInfoType = LoyaltyInfoType;
export type IOffice = Office;
export type IDesignation = Designation;
export type ICountry = Country;
export type IWorkerType = WorkerType;
export type IAccountingCode = AccountingCode;
export type IPersonaBasicInfo = PersonaBasicInfo;
export type ICardCompany = CardCompany;
export type ITravelerSearchResponse = SearchTravelerResponse;
export type IPaymentInfoApplicableTo = PaymentInfoApplicableTo;
export type ISeatAmenitiesPref = SeatAmenityPrefAirSeatAmenityType;
export type IAirPref = AirPref;
export type IRailPref = RailPref;
export type IHotelPref = HotelPref;
export type ICarPref = CarPref;
export type ITravelPref = TravelPref;
export type IAirPrefMealPref = AirPrefMealPref;
export type IFareType = FarePrefFareType;
export type ISeatPosition = SeatLocationPrefPosition;
export type ISeatLocationPref = SeatLocationPref;
export type IListTripsResponse = ListTripsResponse;
export type IListTripSummariesResponse = ListTripSummariesResponse;
export type ITripSummary = ListTripSummariesResponseTripSummary;
export type IListTripSummariesResponsePnrSummary = ListTripSummariesResponsePnrSummary;
export type IListTripSummariesResponseAirPnrSummaryLegInfo = ListTripSummariesResponseAirPnrSummaryLegInfo;
export type ICarPnrSummary = ListTripSummariesResponseCarPnrSummary;
export type IHotelPnrSummary = ListTripSummariesResponseHotelPnrSummary;
export type IFlightPnrSummary = ListTripSummariesResponseAirPnrSummary;
export type IMaxFlightBookingPriceByDurationPropsMaxPriceForDuration =
  MaxFlightBookingPriceByDurationPropsMaxPriceForDuration;
export type IHighestFlightCabinByDurationPropsHighestCabinForDuration =
  HighestFlightCabinByDurationPropsHighestCabinForDuration;
export type ICardCreateRequest = CardCreateRequest;
export type IEmailConfiguration = EmailConfiguration;
export type IPersona = Persona;
export type ITravelPreferenceNote = TravelPreferenceNote;
export const CreditCardAccessTypeEnum = CreditCardAccessType;
export const IPersonaEnum = PersonaEnum;
export const IRoleInfoTypeEnum = RoleInfoTypeEnum;
export const IGenderEnum = GenderEnum;
export const IPhoneNumberTypeEnum = PhoneNumberTypeEnum;
export const IUpdateTravelerRequestTypeEnum = UpdateTravelerRequestTypeEnum;
export const ILoyaltyInfoTypeEnum = LoyaltyInfoTypeEnum;
export const IPaymentInfoApplicableToEnum = PaymentInfoApplicableToEnum;
export const ICardCompanyEnum = CardCompany;
export const ICardTypeEnum = CardType;
export const ISeatAmenitiesPrefEnum = SeatAmenityPrefAirSeatAmenityTypeEnum;
export const IAllianceEnum = AllianceEnum;
export const IFareTypeEnum = FarePrefFareTypeEnum;
export const IAirlinePrefTypeEnum = AirlinePrefFlightTypeEnum;
export const ICabinEnum = CabinEnum;
export const ISeatPositionEnum = SeatLocationPrefPositionEnum;
export const IMealTypeEnum = MealTypeEnum;
export const IAirAncillariesResponseReqdParamsForCheckoutParameterEnum =
  AirAncillariesResponseReqdParamsForCheckoutParameterEnum;
export const IUserTitleEnumV1 = UserTitleEnumV1;
export const INameSuffix = NameSuffix;

export type ITravelerRestrictions = AirPnrLegInfoTravelerRestrictions;
export const ITravelerRestrictionsEnum = AirPnrLegInfoTravelerRestrictionsRestriction;
export { AirPnrLegInfoTravelerRestrictionsRestriction, LoyaltyInfoTypeEnum as LoyaltyInfoTypeEnumV1 };
export { NationalDocType };

// TODO: (Piyush) - Discuss with team about making these interfaces generic (esp ones with value and label)
export interface IUserTitle {
  value: UserTitle;
  label: string;
}

export interface IUserGender {
  value: Gender;
  label: string;
}

export interface IUserPronoun {
  value: PreferredPronoun;
  label: string;
}

export interface IUserSuffix {
  value: NameSuffix;
  label: string;
}

export interface IFlightPnrDetails {
  flightNum: string;
  departureAirport: string;
  departureDateTime: string;
  arrivalAirport: string;
  arrivalDateTime: string;
}

export interface IAirPnrDetails {
  leg: { flights: IFlightPnrDetails[] }[];
}

export interface IHotelPnrDetails {
  displayName: string;
  checkInDate: string;
  checkOutDate: string;
}

export interface ICarPnrDetails {
  pickup: string;
  dropoff: string;
  pickupDateTime: string;
  dropoffDateTime: string;
}

export interface IPnrDetails {
  pnrNumber: string;
  pnrStatus: string;
  creationDate: string;
  segments: PNRSearchResponseSegment[];
}

export interface ITravelerPersonalInfo {
  title: UserTitle;
  name: Name;
  bio: {
    gender: Gender;
    dob: string;
    preferredPronoun: PreferredPronoun;
  };
  contactInfo: {
    phoneNumbers: PhoneNumber[];
    email: string;
  };
  address: PostalAddress[];
  travelIDs: {
    passports: Passport[];
    /** @deprecated */
    knownTravelerNumber: string;
    ktn?: KnownTravelerNumber;
    /** @deprecated */
    redressNumber: string;
    redress?: RedressNumber;
    nationalDoc: NationalDocV1[];
  };
  emergencyContactInfo: IEmergencyContactInfo;
  emergencyContact?: EmergencyContact;
  profilePicture?: ImageV1;
  preferredLanguage?: string;
  /**
   * Tier is technically not personal info (instead Spotnana's internal classification for the traveler)
   * but to keep changes in the usages of this interface minimal and keeping in the mind the position of
   * the corresponding UI component, it has been put here. We can consider moving this field to some other
   * place if the corresponding UI and its usages deviate too much from current state.
   */
  tier?: TierEnumNumerals;
}

export interface IEmergencyContactInfo {
  email: string;
  name: Name;
  designation: string;
  phoneNumber: PhoneNumber;
  address?: PostalAddress;
}

export interface ITravelerCardInfo {
  type: string;
  company: string;
  name?: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
}

export interface ICardCompanyList {
  value: CardCompany;
  label: string;
  dividerColor: string;
}

export interface ITravelerLoyaltyInfoAll {
  air: LoyaltyInfo[];
  hotel: LoyaltyInfo[];
  car: LoyaltyInfo[];
  rail: IProfileRailCard[];
}

export interface ITravelerLoyaltyInfoV2 extends LoyaltyInfo {
  expiryDate?: string;
  cardNumber?: string;
}

export interface ITravelerLoyaltyInfos {
  air: ITravelerLoyaltyInfoV2[];
  hotel: ITravelerLoyaltyInfoV2[];
  car: ITravelerLoyaltyInfoV2[];
  rail: ITravelerLoyaltyInfoV2[];
}

export interface ITravelerInfo {
  personal: ITravelerPersonalInfo;
  loyalty: ITravelerLoyaltyInfoAll;
  paymentMethods: ITravelerPaymentInfo[];
}

export interface ITravelerCheckoutDetails {
  passports: Passport[];
  immigrationDocs: ImmigrationDocument[];
  knownTravelerNumber: string;
  redressNumber: string;
}

export type ITravelerPreSearchQuestion = UserDefinedEntity[];

export interface ITravelerCheckoutInfo {
  name: ITravelerName;
  travelerName?: ITravelerName;
  gender: Gender;
  dob: string;
  /**  @deprecated */
  redressNumber: string;
  redress?: RedressNumber;
  /**  @deprecated */
  knownTravelerNumber: string;
  ktn?: KnownTravelerNumber;
  contactInfo: IPhoneNumber[];
  profilePictureUrl: string;
  nationality?: string;
}

// TODO: Krishna : Merge this into ITravelerCheckoutInfo
export interface ITravelerCheckoutInfoV2 extends ITravelerCheckoutInfo {
  passports: IPassport[];
  nationalDocs: INationalDoc[];
}

export type IdentityDocs = {
  /**  @deprecated */
  redressNumber: string;
  /**  @deprecated */
  knownTravelerNumber: string;
  passports: Passport[];
  ktn?: KnownTravelerNumber;
  redress?: RedressNumber;
  nationalDocs: INationalDoc[];
};

export enum IdentityDocsTabLabels {
  ALL = 'ALL',
  PASSPORT = 'PASSPORT',
  NATIONAL_ID = 'NATIONAL_ID',
  KTN = 'KTN',
  REDRESS = 'REDRESS',
}

export interface IBasicTravelerInfoError {
  firstNameError: string;
  middleNameError: string;
  lastNameError: string;
  genderError: string;
  suffixNameError: string;
  dobError: string;
  phoneError: string;
  /** @deprecated */
  redressNumberError: string;
  /** @deprecated */
  knownTravelerNumberError: string;
  redressError?: string;
  ktnError?: string;
  emailError?: string;
}

export interface IProfileUserOrgId {
  profileId: IUserOrgId;
}

export interface IPointsConfig {
  isApplicable: boolean;
  availablePoints: number;
}

export type IPNRSearchResponseSegmentCar = PNRSearchResponseSegmentCar;
export type IPNRSearchResponseSegmentAir = PNRSearchResponseSegmentAir;
export type IPNRSearchResponseSegmentHotel = PNRSearchResponseSegmentHotel;
export type IPNRSearchResponseSegment = PNRSearchResponseSegment;
export type IPNRSearchResponsePNR = PNRSearchResponsePNR;
export type ICardCreateResponse = CardCreateResponse;
export type ICardConfirmRequest = CardConfirmRequest;
export type IPreBookQuestionRequest = PreBookQuestionRequest;
export type IPreBookQuestionResponse = PreBookQuestionResponse;

export const ITierEnum = TierEnum;

export type ITravelPreferences = TravelPreferences;
export const MealPreferencesEnum = MealPrefInclMealPrefsEnum;
