/* eslint-disable no-restricted-syntax */
import { useMountEffect } from 'obt-common';
import useEmbedStore from '../components/EmbedContext/useEmbedStore';
import { EmbedEventTypes } from '../components/EmbedContext/types';

const useReportStorageInaccesibleEvent = (): void => {
  const { reportEvent } = useEmbedStore();

  useMountEffect(() => {
    let isSessionStorageAccessible = false;
    let isLocalStorageAccessible = false;
    let isCookieEnabled = false;

    try {
      isSessionStorageAccessible = window.sessionStorage !== null;
      isLocalStorageAccessible = window.localStorage !== null;
      isCookieEnabled = navigator.cookieEnabled;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      reportEvent({
        type: EmbedEventTypes.STORAGE_ACCESS_ERROR,
        payload: {
          isCookieEnabled: Boolean(isCookieEnabled),
          isLocalStorageAccessible: Boolean(isLocalStorageAccessible),
          isSessionStorageAccessible: Boolean(isSessionStorageAccessible),
        },
      });
    }
  });
};

export default useReportStorageInaccesibleEvent;
