import capitalize from 'lodash/capitalize';
import type { InitOptions } from 'i18next';

import type { Length } from '../types/api/v1/common/length';
import formatDistance from '../utils/formattedDistance';
import TranslationNamespaces, { CommonTranslationNamespaces } from './namespaces';
import isCurrencyDisplaySupported from './isCurrencyDisplaySupported';

import type { ExtendedDateStyle, ExtendedTimeStyle } from './localizers/extendedDateTime';
import { getExtendedDateTimeFormatOptions } from './localizers/extendedDateTime';

type ValidFormats = 'number' | 'distance' | 'currency' | 'lowercase' | 'capitalize' | 'currencySymbol' | 'datetime';
const isValueValidForFormat = (value: any, format: ValidFormats): boolean => {
  switch (format) {
    case 'capitalize':
      return typeof value === 'string';
    case 'lowercase':
      return typeof value === 'string';
    case 'number':
      return typeof value === 'number' || typeof value === 'bigint';
    case 'distance':
      return typeof value === 'object' && 'length' in value && 'unit' in value;
    case 'currency':
      return typeof value === 'object' && 'amount' in value && 'currencyCode' in value;
    case 'currencySymbol':
      return typeof value === 'string';
    case 'datetime':
      return typeof value === 'object' && 'dateTimeValue' in value && value.dateTimeValue instanceof Date;
    default:
      return false;
  }
};

export const initOptions: InitOptions = {
  fallbackLng: 'en',
  returnEmptyString: false,
  debug: false,
  ns: CommonTranslationNamespaces,
  defaultNS: TranslationNamespaces.COMMON,
  nsSeparator: false,
  keySeparator: false,
  react: {
    transKeepBasicHtmlNodesFor: [],
  },
  /**
   * Uncommenting the below config will stop persisting the selected locale in localStorage. This
   * config needs to be inactive (commented) if we have to allow the user to change language inside
   * our app. With this config active, every time the user will refresh the page on our App, language
   * will be detected from Browser settings (window.navigator), leading to a discrepancy between
   * user expectations and the app behavior.
   *
   * This config can be uncommented if we dont want to allow the user to change the language inside our App.
   */
  detection: {
    caches: [],
  },
  returnObjects: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value: any, format?: string, lng?: string): string => {
      // If any unsupported format is passed, we return the value as-is.
      if (!format || !isValueValidForFormat(value, format as ValidFormats)) {
        return value;
      }

      const deviceLanguageLocale = lng ?? 'en';
      switch (format) {
        case 'capitalize': {
          return capitalize(value);
        }
        case 'lowercase': {
          return value.toLowerCase();
        }
        case 'number':
          // TODO: remove the eslint-disable
          // eslint-disable-next-line no-restricted-syntax
          return new Intl.NumberFormat(deviceLanguageLocale).format(value);
        case 'distance': {
          return formatDistance(value as Length, deviceLanguageLocale as string);
        }
        case 'currency': {
          const inputCurrencyDisplay = value.currencyDisplay;
          // TODO: remove the eslint-disable
          // eslint-disable-next-line no-restricted-syntax
          const currencyDisplay = isCurrencyDisplaySupported(inputCurrencyDisplay) ? inputCurrencyDisplay : undefined;

          return new Intl.NumberFormat(deviceLanguageLocale, {
            style: 'currency',
            currencyDisplay,
            currency: value.currencyCode,
            maximumFractionDigits: value.maxFractionDigits ?? undefined,
            minimumFractionDigits: value.minFractionDigits ?? 0,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - `signDisplay` available and exists in React Native env
            signDisplay: value.signDisplay,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            roundingMode: value.roundingMode,
            notation: value.notation,
          }).format(value.amount);
        }
        case 'currencySymbol': {
          const needle = new Intl.NumberFormat(deviceLanguageLocale, {
            style: 'currency',
            currency: value,
            currencyDisplay: 'symbol',
          })
            .formatToParts(0)
            .find(({ type }) => type === 'currency');

          return needle ? needle.value : value;
        }
        case 'datetime': {
          /**
           * You can pass dateStyle and timeStyle as options to format the date and time.
           * If you pass only dateStyle -> only date will be formatted.
           * If you pass only timeStyle -> only time will be formatted.
           * If you pass both dateStyle and timeStyle -> both date and time will be formatted.
           */

          const { dateTimeValue, dateStyle, timeStyle } = value as {
            dateStyle?: ExtendedDateStyle;
            timeStyle?: ExtendedTimeStyle;
            dateTimeValue: Date;
          };

          const options: Intl.DateTimeFormatOptions = getExtendedDateTimeFormatOptions({ dateStyle, timeStyle });
          return new Intl.DateTimeFormat(deviceLanguageLocale, options).format(dateTimeValue);
        }
        default:
          return value;
      }
    },
  },
};
