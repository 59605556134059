import type { IFlightInfo } from '../../types/flight';
import type { DataFlightAirport } from '../../types/api/v1/obt/air/air_search_response';
import type { DateTime } from '../../types/api/v1/common/date_time';

export default function getFlightDateTimeAirportInformation(
  departureDateTime: DateTime,
  arrivalDateTime: DateTime,
  originAirport: DataFlightAirport,
  destinationAirport: DataFlightAirport,
): Omit<
  IFlightInfo,
  | 'duration'
  | 'airline'
  | 'marketingAirlineLabel'
  | 'operatingAirlineLabel'
  | 'departureCountryCode'
  | 'arrivalCountryCode'
> {
  return {
    departureDateTime: departureDateTime.iso8601,
    arrivalDateTime: arrivalDateTime.iso8601,
    departureAirport: originAirport.airport,
    departureAirportName: originAirport.airportName,
    departureAirportCity: originAirport.cityName ?? '',
    departureAirportTerminal: originAirport.terminal ?? '',
    arrivalAirport: destinationAirport.airport,
    arrivalAirportName: destinationAirport.airportName,
    arrivalAirportCity: destinationAirport.cityName ?? '',
    arrivalAirportTerminal: destinationAirport.terminal ?? '',
  };
}
