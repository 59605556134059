import { FC } from 'react';
import { Fare } from 'obt-common';
import { FareListItinerariesApiProvider } from './FareListItinerariesApiProvider';
import { FareItinerariesContainer } from './FareItinerariesContainer';
import { FareListItinerariesPreLoadContainer } from './FareListItinerariesPreloadContainer';

interface IProps {
  fares: Fare[];
  onClose: VoidFunction;
  onRemoveFare: (fareRateOptionId: string) => void;
  itineraryOwnerId: string;
}

export const FareListItineraries: FC<IProps> = ({ fares, onClose, onRemoveFare, itineraryOwnerId }) => {
  return (
    <FareListItinerariesPreLoadContainer fares={fares} onClose={onClose} itineraryOwnerId={itineraryOwnerId}>
      {(data) => (
        <FareListItinerariesApiProvider {...data} fares={fares} itineraryOwnerId={itineraryOwnerId}>
          <FareItinerariesContainer onRemoveFare={onRemoveFare} onClose={onClose} />
        </FareListItinerariesApiProvider>
      )}
    </FareListItinerariesPreLoadContainer>
  );
};
