import { createContext } from 'react';
import { EmbedParams, EmbedEvents } from './types';

export type EmbedContextProps = {
  isEmbed: boolean;
  reportEvent: (event: EmbedEvents) => void;
  embedParams: EmbedParams;
};

const EmbedContext = createContext<EmbedContextProps>({} as EmbedContextProps);

export default EmbedContext;
