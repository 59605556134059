import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Upload = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 26", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.316 7.98678C20.8041 8.47496 20.8041 9.26641 20.3159 9.75455C19.8534 10.217 19.1187 10.2413 18.6276 9.82749L18.5481 9.75447L13.5624 4.7685V15.1969C13.5624 15.8873 13.0027 16.4469 12.3124 16.4469C11.6534 16.4469 11.1135 15.937 11.0658 15.2902L11.0624 15.1969V4.76766L6.07598 9.75447C5.61354 10.217 4.87886 10.2413 4.38772 9.82756L4.30821 9.75455C3.84573 9.2921 3.82136 8.55743 4.23512 8.06629L4.30813 7.98678L11.4281 0.866155C11.8906 0.403643 12.6253 0.379301 13.1165 0.793127L13.196 0.866155L20.316 7.98678ZM23.8744 22.4825C23.8744 24.1631 22.548 25.5337 20.8849 25.6046L20.7494 25.6075H3.875C2.19444 25.6075 0.823859 24.2812 0.752887 22.6181L0.75 22.4825V13.1388C0.75 12.4484 1.30964 11.8888 2 11.8888C2.65898 11.8888 3.19885 12.3987 3.24657 13.0455L3.25 13.1388V22.4825C3.25 22.8031 3.4912 23.0672 3.8021 23.1033L3.875 23.1075H20.7494C21.07 23.1075 21.3341 22.8663 21.3702 22.5554L21.3744 22.4825V13.1388C21.3744 12.4484 21.934 11.8888 22.6244 11.8888C23.2834 11.8888 23.8232 12.3987 23.8709 13.0455L23.8744 13.1388V22.4825Z", fill: themeEnrichedPrimaryColor })));
};
Upload.defaultProps = defaultIconProps;
export default Upload;
