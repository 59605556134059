import { matchPattern } from 'browser-extension-url-match';
import Config from 'obt-common/utils/Config';
import { tryCatchExp } from '@spotnana/blocks/src/utils/fp';

/**
 * Builds a URL Scheme Matcher object
 * @note URL Schemes match the chrome/firefox extension match patterns
 * @see https://developer.chrome.com/docs/extensions/develop/concepts/match-patterns
 * @returns
 */
function buildUrlSchemeMatcher() {
  const urlSchemes = tryCatchExp(
    () => JSON.parse(Config.VITE_EMBED_HOST_URL_PATTERNS ?? '') as string[],
    (e) => {
      console.error('Error parsing VITE_EMBED_HOST_URL_PATTERNS', e);
      return [];
    },
  );
  return matchPattern(urlSchemes).assertValid();
}

/**
 * Validates if the current embed host is allowed or not
 * @param url
 * @returns
 */
export function validateEmbedHost(url: string): boolean {
  const urlMatcher = buildUrlSchemeMatcher();
  return urlMatcher.match(url);
}
