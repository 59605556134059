import { css } from '@emotion/react';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const announcement = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.column}
    ${spacings.extraLarge}
    background: ${palette.surface.base};
    &.vertical {
      border-bottom: 1px solid ${palette.border.light};
      ${paddings.yb.cozy}
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &.horizontal {
      flex-grow: 1;
      flex-shrink: 0;
      width: 560px;
      height: 246px;
      border-radius: ${palette.border.radiusRegular};
      ${paddings.y.comfortable}
      ${paddings.x.comfortable}
      .card_body {
        .description {
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          height: clamp(1.25rem, 100%, 3.75rem);
        }
      }
    }
    .card_header {
      ${flex.init}
      ${flex.align.center}
      ${flex.justify.between}
      .author_details {
        ${flex.init}
        ${spacings.small}
        ${flex.align.center}
      }
    }
    .card_body {
      ${flex.init}
      ${flex.column}
      ${spacings.medium}
    }
  `,
);
