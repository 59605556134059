import { Icon } from '@spotnana/blocks/src/Icon';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, TypoVariants } from '@spotnana/blocks/src/Typography';
import { CARBON_EMISSION_DELTA_THRESHOLD } from 'obt-common';
import { caret_down, caret_up, container } from './styles';

interface IProps {
  totalCo2EmissionDeltaInPercentage: number;
  showDeltaIcon?: boolean;
  textVariant: TypoVariants;
}

const TotalCo2EmissionDeltaInPercentage = ({
  totalCo2EmissionDeltaInPercentage,
  showDeltaIcon = true,
  textVariant,
}: IProps) => {
  const isPositiveTotalCo2Delta = totalCo2EmissionDeltaInPercentage > CARBON_EMISSION_DELTA_THRESHOLD;
  const isNegativeTotalCo2Delta = totalCo2EmissionDeltaInPercentage < -CARBON_EMISSION_DELTA_THRESHOLD;

  const { t: tt } = useTranslation('WEB');

  if (!isNegativeTotalCo2Delta && !isPositiveTotalCo2Delta) {
    return (
      <Typography variant={textVariant} kind="medium" color="secondary">
        {tt('Average emissions')}
      </Typography>
    );
  }

  return (
    <div css={container}>
      {showDeltaIcon && (
        <Icon
          name={isPositiveTotalCo2Delta ? 'CaretUp' : 'CaretDown'}
          css={isPositiveTotalCo2Delta ? caret_up : caret_down}
        />
      )}

      {isPositiveTotalCo2Delta ? (
        <Trans t={tt} values={{ totalCo2EmissionDeltaInPercentage: Math.abs(totalCo2EmissionDeltaInPercentage) }}>
          <Typography variant={textVariant} kind="medium" color="error">
            +{'{{totalCo2EmissionDeltaInPercentage}}'}% emissions
          </Typography>
        </Trans>
      ) : (
        <Trans t={tt} values={{ totalCo2EmissionDeltaInPercentage }}>
          <Typography variant={textVariant} kind="medium" color="success">
            {'{{totalCo2EmissionDeltaInPercentage}}'}% emissions
          </Typography>
        </Trans>
      )}
    </div>
  );
};

export { TotalCo2EmissionDeltaInPercentage };
