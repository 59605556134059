import { css } from '@emotion/react';

import { themed, paddings, flex, breakpoints, margins } from '@spotnana/blocks/src/utils';

export const powered_by_spotnana_container = themed(
  ({ palette }) => css`
    background-color: transparent;
    ${flex.init}
    ${flex.row}
    ${flex.justify.center}
    ${flex.align.center}
    ${paddings.x.p1}
    ${paddings.y.point75}
    ${margins.yt.m1point5}
    width: 100%;

    ${breakpoints.from('tablet')} {
      border-radius: 24px;
      width: auto;
      ${margins.yt.m0}
    }
  `,
);

export const powered_by_spotnana_text = css`
  ${paddings.xl.half}
`;
