import { HTMLInputTypeAttribute } from 'react';
import { spotnana_input_text } from './TextInput.styles';

type Props = Readonly<{
  'data-test-id'?: string;
  label: string;
  onChange: (val: string) => void;
  placeholder?: string;
  value: string;
  type?: HTMLInputTypeAttribute;
}>;

const TextInput = ({
  label,
  value,
  onChange,
  placeholder = '',
  'data-test-id': dataTestId,
  type = 'text',
}: Props): JSX.Element => {
  return (
    <div css={spotnana_input_text}>
      <div className="spotnana-text-editor-input__wrapper">
        <label className="spotnana-text-editor-input__label" htmlFor="main-input">
          {label}
        </label>
        <input
          id="main-input"
          type={type}
          className="spotnana-text-editor-input__input"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          data-test-id={dataTestId}
        />
      </div>
    </div>
  );
};

export default TextInput;
