import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Sort = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: themeEnrichedPrimaryColor, d: "M10.372 7.5h.03c.023 0 .047.003.071.005l-.101-.005c.058 0 .115.005.17.014l.04.008c.032.007.062.015.092.024l.023.008a.752.752 0 01.076.03l.04.018c.028.014.054.028.08.044l.022.014a.951.951 0 01.096.071l.004.003.064.059 3.372 3.372a1 1 0 01-1.351 1.472l-.064-.058-1.665-1.665v12.214a1 1 0 01-1.997.075l-.002-.075-.001-12.214-1.664 1.665a.998.998 0 01-1.35.058l-.064-.058a1 1 0 01-.059-1.35l.059-.064L9.656 7.8c.03-.031.063-.06.098-.088l-.09.08a.916.916 0 01.191-.15l.03-.017a.791.791 0 01.077-.039L10 7.572c.02-.009.04-.016.06-.023l.06-.017c.022-.006.044-.01.068-.015l.037-.006a.685.685 0 01.134-.011h.014zm11.256 0a1 1 0 01.998.926l.002.074v12.213l1.665-1.664a1 1 0 011.35-.058l.064.058a1 1 0 01.058 1.351l-.058.064-3.371 3.371a1.115 1.115 0 01-.089.079l.089-.079a1.13 1.13 0 01-.13.11l-.053.035-.055.031c-.017.01-.034.018-.051.026l-.048.02a.763.763 0 01-.072.026l-.035.01a.845.845 0 01-.076.018l-.047.007c-.022.003-.044.006-.065.007l-.053.003h-.063l-.061-.005.101.005c-.053 0-.106-.004-.157-.012l-.064-.013-.062-.015-.044-.015a.813.813 0 01-.074-.029l-.04-.019a.608.608 0 01-.062-.033l-.038-.023a.972.972 0 01-.116-.087l-.05-.047-3.371-3.371a1 1 0 011.35-1.473l.064.058 1.664 1.664V8.5a1 1 0 011-1z" })));
};
Sort.defaultProps = defaultIconProps;
export default Sort;
