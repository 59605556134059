import spotnanaTheme from '../utils';
const defaultGlobalStyles = () => ({
    /* Reset */
    'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
        display: 'block',
    },
    body: {
        lineHeight: 1,
    },
    'ol, ul': {
        listStyle: 'none',
    },
    'blockquote, q': {
        quotes: 'none',
    },
    'blockquote:before, blockquote:after, q:before, q:after': {
        content: 'none',
    },
    table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
    },
    '.page': {
        flex: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    /* Pixel Specific */
    html: {
        fontSize: 16,
        color: '#1d1d1f',
        scrollBehavior: 'smooth',
    },
    '*:not(sup)': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        border: 0,
        verticalAlign: 'baseline',
    },
    code: {
        fontFamily: spotnanaTheme.fonts.codeFont,
    },
    a: {
        textDecoration: 'none',
        color: 'inherit',
    },
    '.MuiTooltip-tooltip': {
        fontSize: '14px !important',
    },
    '.MuiTooltip-popper': {
        pointerEvents: 'unset !important',
    },
    '.stick-to-right': {
        position: 'absolute',
        right: 0,
    },
    '.stick-to-left': {
        position: 'absolute',
        left: 0,
    },
    '.no-scroll': {
        overflow: 'hidden',
    },
    '.custom_fc_open': {
        right: '12px !important',
        bottom: '96px !important',
    },
    '.custom_fc_expanded': {
        right: '0 !important',
        bottom: '0 !important',
    },
});
export default defaultGlobalStyles;
