import { Flex } from '@spotnana/pixel-react/dist/Box';
import Typography from '@spotnana/pixel-react/dist/Typography';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import { useTranslation } from 'react-i18next';

type BetaProps = {
  variation: 'small' | 'large';
};

const Beta = ({ variation }: BetaProps): JSX.Element => {
  const { t: tt } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      border="thinSolid"
      borderRadius={10}
      borderColor={spotnanaTheme.colors.primary2Alpha20}
      px={variation === 'small' ? '5px' : '10px'}
      py={variation === 'small' ? '0px' : '10px'}
      ml="4px"
    >
      <Typography color={spotnanaTheme.colors.primaryEmphasis} variation="label" fontWeight="semiBold">
        {tt('Beta')}
      </Typography>
    </Flex>
  );
};

export default Beta;
