import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import type { PromotionCode } from '@spotnana/types/openapi/models/promotion-code';

import type { WaiverCodeInputValues } from '../../types/waiverCodes';
import { dateFormats, emptyName } from '../../constants';
import {
  defaultTimeRange,
  emptySegmentLocation,
  fareExpiredErrorCode,
  firstLegDefaultTimeRange,
  serverFormat,
} from '../../constants/flights';
import { convertDateFormat } from '../../date-utils';
import type { AirSelectedItineraryResponseManager } from '../../services';
import { TravelerDetailsResponseManager } from '../../services';
import type {
  IPhoneNumber,
  ITraveler,
  ITravelerCheckoutDetails,
  ITravelerCheckoutInfo,
  ITravelerPaymentInfo,
  IUserOrgId,
  PaymentMethodExtended,
  SsrOsiPerTraveler,
  TripId,
} from '../../types';
import { IGenderEnum, PaymentMethodEnum, TripBookingFlowEnum, UserAgent } from '../../types';
import type { PostPaymentVerificationInfo } from '../../types/api/v1/common/card_verification';
import type { Gender } from '../../types/api/v1/common/gender';
import type { DateTimeRange } from '../../types/api/v1/common/range';
import type { PassengerType } from '../../types/api/v1/obt/air/air_common';
import { PassengerTypeEnum } from '../../types/api/v1/obt/air/air_common';
import type {
  AirInitiateBookingRequestBookingContact,
  AirInitiateBookingRequest as AirInitiateBookingRequestV1,
} from '../../types/api/v1/obt/air/air_initiate_booking_request';
import type {
  AirRevalidateItineraryRequest,
  AirRevalidateItineraryRequestTravelerInfo,
  AirRevalidateItineraryRequestTravelerInfoSeat,
  AirUnusedCreditRedemptionRequest,
} from '../../types/api/v1/obt/air/air_revalidate_itinerary_request';
import { AirSearchRequestBookingType, SortOptionSortByEnum } from '../../types/api/v1/obt/air/air_search_request';
import type {
  AirSearchRequest,
  AirSearchRequestLeg,
  AirSearchRequestLocation,
  AirSearchRequestPaxInfo,
  SortOption,
} from '../../types/api/v1/obt/air/air_search_request';
import type { Traveler } from '../../types/api/v1/obt/common/traveler';
import type { LoyaltyInfo } from '../../types/api/v1/obt/common/traveler_personal_info';
import type { User } from '../../types/api/v1/obt/common/user';
import type { BookingPaymentDetails } from '../../types/api/v1/obt/pnr/payment';
import type { PreBookQuestionResponse } from '../../types/api/v1/obt/policy/pre_search_and_book_question';
import type { EntityAnswer } from '../../types/api/v1/obt/policy/user_defined_entity';
import type { AirCreatePnrRequest } from '../../types/api/v1/obt/trip/air_create_pnr_request';
import type { UserTripInfo } from '../../types/api/v2/obt/model/user-trip-info';
import type { IAirSuggestion } from '../../types/autocomplete';
import { AirLocationTypeEnum } from '../../types/autocomplete';
import type {
  AirCheckoutSelectedValues,
  AirCheckoutState,
  IAirSearchFormPaxInfo,
  IAirSearchUrlPaxInfo,
  ICheckoutSeat,
  IFilter,
  IFilterTimeRangeFilter,
  IFlightCheckoutFormParams,
  IFlightSearchSegmentState,
  IFlightsFilterState,
  IGetAirSearchRequestProps,
  IPaxInfo,
  ITimeRangeFilter,
} from '../../types/flight';
import {
  Config,
  SESSION_ID_URL_SEARCH_PARAMS_KEY,
  getArrayFromArrayOrNode,
  getMergedPreBookAnswers,
  getSessionId,
} from '../../utils';
import { splitObjectIntoArray } from '../common';
import { getOtherServiceInformationForFlights, getSpecialServiceRequestsForFlights } from './utils';
import type { PnrRemark } from '../../types/api/v1/obt/common/pnr_remarks';

export const getEmptySegment = (
  segment?: Partial<IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>>,
): IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]> => ({
  origin: cloneDeep(emptySegmentLocation),
  destination: cloneDeep(emptySegmentLocation),
  date: '',
  ...segment,
});

export function getRoundTripSegments(
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
  dateFormat: string = dateFormats.LONG_DATE_REVERSE,
): IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[] {
  if (segments.length < 1) {
    return [
      // TODO: Use new dateUtil
      // eslint-disable-next-line no-restricted-syntax
      { ...getEmptySegment({ date: moment().format(dateFormat) }) },
      // eslint-disable-next-line no-restricted-syntax
      { ...getEmptySegment({ date: moment().format(dateFormat) }) },
    ];
  }

  if (segments.length === 1) {
    const firstSegment = segments[0];
    return [
      getEmptySegment({ ...firstSegment }),
      getEmptySegment({
        origin: firstSegment.destination,
        destination: firstSegment.origin,
        date: firstSegment.date,
      }),
    ];
  }

  const [firstSegment, secondSegment] = segments;
  return [
    getEmptySegment({ ...firstSegment }),
    getEmptySegment({
      origin: firstSegment.destination,
      destination: firstSegment.origin,
      date: secondSegment.date,
    }),
  ];
}

export function convertSegmentsToAirSuggestionArrays(
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
): IFlightSearchSegmentState<IAirSuggestion[]>[] {
  return segments.map((segment) => {
    const origin = getArrayFromArrayOrNode(segment.origin);
    const destination = getArrayFromArrayOrNode(segment.destination);
    return { ...segment, origin, destination };
  });
}

export function getOneWayTripSegments(
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
  dateFormat: string = dateFormats.LONG_DATE_REVERSE,
): IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[] {
  if (segments.length < 1) {
    // eslint-disable-next-line no-restricted-syntax
    return [{ ...getEmptySegment({ date: moment().format(dateFormat) }) }];
  }

  const firstSegment = segments[0];
  return [getEmptySegment({ ...firstSegment })];
}

export function getMultiCityTripSegments(
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
  dateFormat: string = dateFormats.LONG_DATE_REVERSE,
): IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[] {
  if (segments.length < 1) {
    // eslint-disable-next-line no-restricted-syntax
    return [{ ...getEmptySegment({ date: moment().format(dateFormat) }) }];
  }

  return segments;
}

export const airGetOptionLabel = (option: IAirSuggestion): string => {
  // "option.name" can be empty in case Brex pre-fill segments (via url search params)
  if (!option?.name && option?.code) {
    return option.code;
  }

  const optionName = option?.name || '';

  return optionName.substring(0, optionName.indexOf(', ') !== -1 ? optionName.indexOf(', ') : undefined);
};

export const getAirSearchRequestLocation = (segmentInfo?: IAirSuggestion[]): AirSearchRequestLocation => {
  if (!segmentInfo || segmentInfo.length < 1) {
    return {};
  }

  const hasCitySegment = segmentInfo.some((s) => s.type === AirLocationTypeEnum.CITY);
  if (hasCitySegment) {
    return { city: segmentInfo[0].code };
  }
  return { multiAirports: { airportCodes: segmentInfo.map((s) => s.code) } };
};

export const getAirSearchReqLegsFromArraySegments = (
  segments: IFlightSearchSegmentState<IAirSuggestion[]>[],
): AirSearchRequestLeg[] => {
  return segments.map((segment) => {
    const origin: AirSearchRequestLocation = getAirSearchRequestLocation(segment.origin);
    const destination: AirSearchRequestLocation = getAirSearchRequestLocation(segment.destination);

    return {
      origin,
      destination,
      date: convertDateFormat(segment.date, dateFormats.LONG_DATE_REVERSE, serverFormat.DATE),
    };
  });
};

type GetCreatePnrRequestV2Params = {
  bookingId: string;
  trip: string;
  isTestBooking: boolean;
  userAgent: keyof typeof UserAgent;
  ccVerificationUrl?: string;
  initiateBookingId?: string;
  postPaymentVerificationInfo?: PostPaymentVerificationInfo;
};
export const getCreatePnrRequestV2 = ({
  bookingId,
  trip,
  isTestBooking,
  userAgent,
  ccVerificationUrl,
  initiateBookingId,
  postPaymentVerificationInfo,
}: GetCreatePnrRequestV2Params): AirCreatePnrRequest => {
  const tripId = { id: trip };
  const tripData: Pick<AirCreatePnrRequest['tripData'], 'tripId' | 'isTestBooking'> = {
    tripId,
    isTestBooking,
  };

  let ccPostVerificationUrl = '';

  if (ccVerificationUrl) {
    ccPostVerificationUrl = ccVerificationUrl;
  } else {
    const sessionId = getSessionId();
    ccPostVerificationUrl =
      userAgent === UserAgent.WEB
        ? `${Config.VITE_WEB_URL}/flights/check-pnr-status?${
            sessionId ? `${SESSION_ID_URL_SEARCH_PARAMS_KEY}=${sessionId}` : ''
          }`
        : `${Config.VITE_WEB_URL}/mobile/flights/ccPostVerification?`;
  }

  const createPnrRequest: AirCreatePnrRequest = {
    tripData: tripData as AirCreatePnrRequest['tripData'],
    bookingId,
    ccPostVerificationUrl,
    initiateBookingId,
    postPaymentVerificationInfo,
  };

  return createPnrRequest;
};

export const getDefaultTimeRanges = (segmentLength: number): ITimeRangeFilter[] => {
  const timeRanges: ITimeRangeFilter[] = Array(segmentLength).fill(firstLegDefaultTimeRange);
  return timeRanges.map((timeRange, index) => ({
    ...timeRange,
    legIndex: index,
  }));
};

export const getDefaultSeatmapCheckoutState = (totalFlights: number): ICheckoutSeat[] => {
  const temp: ICheckoutSeat[] = Array(totalFlights)
    .fill({ index: 0, seat: '', price: null })
    .map((seat, index) => ({ ...seat, index }));
  return temp;
};

export const getInitialTimeRanges = (
  segmentLength: number,
  timeRangeFilter: ITimeRangeFilter[] | undefined,
): ITimeRangeFilter[] | undefined => {
  if (!timeRangeFilter) {
    return getDefaultTimeRanges(segmentLength);
  }
  const timeRanges = [...timeRangeFilter, ...getDefaultTimeRanges(segmentLength)].slice(0, segmentLength);
  return timeRanges.map((timeRange, index) => ({
    ...timeRange,
    legIndex: index,
  }));
};

const getAirSearchRequestTimeRange = (params: ITimeRangeFilter[]): IFilterTimeRangeFilter => {
  const [MIN, MAX] = defaultTimeRange;
  const timeRange: IFilterTimeRangeFilter = { timeRanges: [] };

  const getTimeRange = (timeRanges: { min: string; max: string }): DateTimeRange | undefined =>
    timeRanges.min === MIN && timeRanges.max === MAX
      ? undefined
      : {
          min: { iso8601: timeRanges.min },
          max: timeRanges.max === MAX ? undefined : { iso8601: timeRanges.max },
        };

  timeRange.timeRanges = params.map(({ legIndex, departure, arrival }) => ({
    legIndex,
    departure: getTimeRange(departure),
    arrival: getTimeRange(arrival),
  }));

  return timeRange;
};

export const getAirSearchRequestFilters = (filter: IFlightsFilterState): IFilter => {
  const timeRange = filter.timeRange ? getAirSearchRequestTimeRange(filter.timeRange) : undefined;
  const newFilters = { ...filter, timeRange };
  return newFilters;
};

const getPaxInfoFromUrl = (passengers: IAirSearchUrlPaxInfo[]): AirSearchRequestPaxInfo[] => {
  const paxGroupedByType = groupBy(passengers, 'paxType');

  const transformedPax: AirSearchRequestPaxInfo[] = [];
  Object.keys(paxGroupedByType).forEach((paxType) => {
    const paxInfo = paxGroupedByType[paxType];
    if (+paxType !== PassengerTypeEnum.CHILD) {
      const count = paxInfo.length;
      transformedPax.push({
        paxNum: count,
        paxType: +paxType as PassengerType,
      });
    } else {
      const childrenGroupedByAge = groupBy(paxInfo, 'paxAge.years');
      Object.keys(childrenGroupedByAge).forEach((paxAge) => {
        transformedPax.push({
          paxNum: childrenGroupedByAge[paxAge].length,
          paxAge: { years: +paxAge },
          paxType: PassengerTypeEnum.CHILD,
        });
      });
    }
  });

  return transformedPax;
};

export const getPassengersWithPrimaryTravelerId = (
  paxInfo: IPaxInfo,
  primaryTravelerId: IUserOrgId,
): IAirSearchFormPaxInfo[] => {
  const paxInfos: IAirSearchFormPaxInfo[] = Object.values(cloneDeep(paxInfo))
    .flat()
    .map((p) => ({
      ...p,
      gender: null,
      dob: null,
      phone: null,
      isLoyaltyInitialized: false,
      loyalty: [],
      preBookAnswers: null,
      doNotShareEmailAndPhone: false,
    }));
  paxInfos[0].userId = primaryTravelerId.userId?.id;
  return paxInfos;
};

export const getAirSearchRequestV2 = ({
  searchId,
  lastLegRateOptionId,
  segments,
  pageSize,
  pageNumber,
  currLegNumber,
  filter,
  sortOptions,
  corporateInfo,
  passengers,
  passengerUserOrgIds,
  registrarUserOrgId,
  registrarLegalEntityId,
  legCloneInfo,
  isSuggestedFlightOptionsEnabled,
  tripId,
  tripIdInfo,
}: IGetAirSearchRequestProps): AirSearchRequest => {
  const legs = getAirSearchReqLegsFromArraySegments(segments);

  const legSearchParams = {
    searchId,
    selectedRateOptionId: lastLegRateOptionId,
    legIndex: currLegNumber ?? 0,
    pageSize,
    pageNumber,
    asyncRouteHappy: true,
    legCloneInfo,
  };

  const filters =
    filter?.length && filter[currLegNumber]
      ? (Object.assign({}, ...filter[currLegNumber]) as IFlightsFilterState)
      : ([] as IFlightsFilterState);
  let filtersArray: IFilter[] = [];
  if (filter?.length) {
    const updatedFilters = produce(filters, (draftState) => {
      if (draftState.timeRange) {
        const timeRanges = draftState.timeRange;
        draftState.timeRange = timeRanges.map((timeRange) => {
          const { departure, arrival } = timeRange;
          return {
            ...timeRange,
            departure: {
              min: Object.values(departure)[0],
              max: Object.values(departure)[1],
            },
            arrival: {
              min: Object.values(arrival)[0],
              max: Object.values(arrival)[1],
            },
          };
        });
      }
    });

    const newFilters = getAirSearchRequestFilters(updatedFilters);
    delete newFilters.cabin;
    filtersArray = splitObjectIntoArray(newFilters);
  }

  const sortOptionsArray =
    sortOptions?.length && // check if sortOptions is undefined or empty
    sortOptions[currLegNumber] && // check if leg sortOptions is undefined
    sortOptions[currLegNumber].length && // check if leg sortOptions is empty
    sortOptions[currLegNumber][0].sortBy !== SortOptionSortByEnum.UNRECOGNIZED // check if leg sortOptions sort type is unrecognized
      ? sortOptions[currLegNumber]
      : ([] as SortOption[]);

  const isAdhocBooking = passengerUserOrgIds.length < 1;
  const nonProfileBookingProps = {
    legalEntityId: registrarLegalEntityId,
    organizationId: registrarUserOrgId.organizationId,
    registrarUserId: registrarUserOrgId.userId,
  };
  const userOrgIds = passengerUserOrgIds;
  const nonProfileUserInfos = isAdhocBooking ? [nonProfileBookingProps] : undefined;

  const paxInfos = getPaxInfoFromUrl(passengers ?? []);

  const isGroupBooking =
    tripIdInfo && 'groupId' in tripIdInfo && tripIdInfo.tripBookingFlow === TripBookingFlowEnum.GROUP;
  const bookingType = isGroupBooking
    ? AirSearchRequestBookingType.GROUP_BOOKING
    : AirSearchRequestBookingType.NORMAL_BOOKING;

  const airSearchRequest: AirSearchRequest = {
    legs,
    legSearchParams,
    filter: filtersArray,
    sortOptions: sortOptionsArray,
    corporateInfo,
    paxInfos,
    userOrgIds,
    nonProfileUserInfos,
    numberOfCheapestOptions: isSuggestedFlightOptionsEnabled ? 4 : 0,
    tripId,
    bookingType,
  };

  return airSearchRequest;
};

export const getTravelerCheckoutInfo = (
  personalInfo: User | undefined,
  traveler: Traveler | null,
): ITravelerCheckoutInfo => {
  let checkoutDetail: ITravelerCheckoutDetails | undefined;
  if (traveler) {
    const travelerDetailsResponseManager = new TravelerDetailsResponseManager(traveler);
    checkoutDetail = travelerDetailsResponseManager.GetCheckoutPersonalDetails();
  }
  const initialTravelerBasicInfo = {
    name: personalInfo?.name ?? { ...emptyName },
    gender: personalInfo?.gender ?? IGenderEnum.UNSPECIFIED,
    dob: personalInfo?.dob?.iso8601 ?? '',
    redressNumber: checkoutDetail?.redressNumber ?? '',
    knownTravelerNumber: checkoutDetail?.knownTravelerNumber ?? '',
    contactInfo: personalInfo?.phoneNumbers ?? [],
    profilePictureUrl: personalInfo?.profilePicture?.url ?? '',
  };
  return initialTravelerBasicInfo;
};

// TODO: Remove after migrating to multi-pax workflow
/** @deprecated */
export const revalidateAirPriceRequestV2 = (
  isPassportNeeded: boolean,
  ancillaryResponseId: string,
  traveler: ITraveler,
  checkoutState: Pick<AirCheckoutState, 'seats' | 'baggage'>,
  airSelectedItineraryResponseManager: AirSelectedItineraryResponseManager,
  contactNumber: AirCheckoutSelectedValues['contactNumber'],
  passport: AirCheckoutSelectedValues['passport'],
  nationalDoc: AirCheckoutSelectedValues['nationalDoc'],
  paymentCard: AirCheckoutSelectedValues['paymentCard'],
  seatMapResponseId: string,
  uAPassPlusCard: ITravelerPaymentInfo | undefined,
  isDelayedInvoiceApplicable: boolean,
  attemptNo?: number,
): AirRevalidateItineraryRequest => {
  const mealRequests = { inclMealPrefs: [] };
  const { baggage, seats } = checkoutState;
  const selectedSeats: AirRevalidateItineraryRequestTravelerInfoSeat[] = [];
  const legDetails = airSelectedItineraryResponseManager.GetItineraryAllLegs();
  let seatIndex = 0;
  legDetails.forEach((legDetail, legNumber) => {
    const flightIndices = airSelectedItineraryResponseManager.GetLegFlights(legDetail.legIndex);
    flightIndices.forEach((_flightIndex, flightNumber) => {
      if (seats[seatIndex]?.seat) {
        selectedSeats.push({ legIndex: legNumber, flightIndex: flightNumber, seatNumber: seats[seatIndex].seat });
      }
      seatIndex += 1;
    });
  });

  const draftTraveler = cloneDeep(traveler);

  if (draftTraveler.user && traveler.user) {
    if (paymentCard) draftTraveler.user.paymentInfos = [paymentCard];

    if (uAPassPlusCard) {
      draftTraveler.user.paymentInfos.push(uAPassPlusCard);
    }

    if (isDelayedInvoiceApplicable) {
      /*
        user.paymentInfos is non nullable, but when there is createPnr and delayed invoice enabled,
        we don't need to send any payments into BE
      */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete draftTraveler.user.paymentInfos;
    }

    // add selected national doc
    draftTraveler.user.identityDocs = traveler.user.identityDocs.filter(
      (travelDoc) =>
        travelDoc?.redress ||
        travelDoc.knownTravelerNumber ||
        travelDoc.redress ||
        travelDoc.ktn ||
        travelDoc.passport ||
        (travelDoc.nationalDoc?.docId && travelDoc.nationalDoc?.docId === nationalDoc?.docId),
    );

    // just keep selected passport if passport is required
    if (isPassportNeeded) {
      draftTraveler.user.identityDocs = draftTraveler.user.identityDocs.filter(
        (travelDoc) =>
          travelDoc?.redressNumber ||
          travelDoc?.knownTravelerNumber ||
          travelDoc.passport?.docId === passport?.docId ||
          travelDoc.redress ||
          travelDoc.ktn,
      );
    }

    // set selected contact
    if (contactNumber) {
      draftTraveler.user.phoneNumbers = [contactNumber];
    }
  }

  // loyalty info is no longer required in revalidate request
  if (draftTraveler.travelerPersonalInfo) {
    draftTraveler.travelerPersonalInfo.loyaltyInfos = [];
    if (draftTraveler.travelerPersonalInfo.travelPref?.railCards) {
      draftTraveler.travelerPersonalInfo.travelPref.railCards = [];
    }
  }

  const travelers = [draftTraveler].map((travelerCheckoutDetails) => ({ traveler: travelerCheckoutDetails }));

  const firstTraveler = first(travelers);
  if (firstTraveler?.traveler?.travelerPersonalInfo?.travelPref?.airPref) {
    firstTraveler.traveler.travelerPersonalInfo.travelPref.airPref.mealPref = mealRequests;
  }

  const selectedBaggage = Object.values(baggage)
    .filter((currBaggage) => currBaggage.baggageId !== '')
    .map((currentBaggage) => ({
      legIndex: currentBaggage.index,
      baggageIds: [currentBaggage.baggageId],
    }));
  const revalidateItineraryRequest: AirRevalidateItineraryRequest = {
    ancillaryResponseId,
    seatMapResponseId,
    tripId: undefined as unknown as TripId,
    travelers: [
      {
        traveler: firstTraveler?.traveler as ITraveler,
        seats: selectedSeats,
        baggage: selectedBaggage,
        paxType: PassengerTypeEnum.UNKNOWN_PASSENGER_TYPE,
        unusedCreditRequests: [],
        specialServiceRequests: [],
        otherAncillaries: [],
        tripId: undefined as unknown as TripId,
      },
    ],
    attemptNo,
    otherServiceInfos: [],
    waiverCode: '',
    ticketDesignator: '',
    bookingType: AirSearchRequestBookingType.NORMAL_BOOKING,
    qcEnabled: false,
    pnrRemarks: [],
    isIntermediate: false,
  };

  return revalidateItineraryRequest;
};

export interface IAirInitiateRevalidateCommonRequestInput {
  airSelectedItineraryResponseManager: AirSelectedItineraryResponseManager;
  ancillaryResponseId: string;
  perTravellerLoyaltyInfo: LoyaltyInfo[][];
  emailAddress: string | null;
  phoneNumber: IPhoneNumber | null;
  seatMapResponseId: string;
  ssrOsiForm: SsrOsiPerTraveler | null;
  travelersData: ITraveler[];
  travelerSelections: IFlightCheckoutFormParams['passengers'];
}

export interface IAirInitiateBookingRequestInput extends IAirInitiateRevalidateCommonRequestInput {
  postPaymentRedirectionUrl: string;
}

export interface IAirRevalidateCommonRequestInput extends IAirInitiateRevalidateCommonRequestInput {
  unusedCreditRequests: AirUnusedCreditRedemptionRequest[];
  paymentSourceIds: string[];
  initiateBookingId: string;
  bookingPaymentDetails: BookingPaymentDetails | null;
  bookingType: AirSearchRequestBookingType;
  promotionCode: PromotionCode;
  userTripInfos: UserTripInfo[];
  waiverCodeValues: WaiverCodeInputValues;
  pnrRemarks: PnrRemark[];
  isIntermediate: boolean;
}

export interface IAirRevalidateAirPriceRequestInput extends IAirRevalidateCommonRequestInput {
  attemptNo: number;
  paymentMethod: PaymentMethodExtended;
  allTravelersPreBookQuestionsResponse: PreBookQuestionResponse[];
  qcEnabled: boolean;
}

export type IAirFetchRevalidateRequestInput = IAirRevalidateCommonRequestInput;

interface GetTravelersData {
  travelersData: ITraveler[];
  travelerSelections: IFlightCheckoutFormParams['passengers'];
  airSelectedItineraryResponseManager: AirSelectedItineraryResponseManager;
  perTravellerLoyaltyInfo: LoyaltyInfo[][];
  unusedCreditRequests: AirUnusedCreditRedemptionRequest[];
  ssrOsiForm: SsrOsiPerTraveler | null;
  userTripInfos?: UserTripInfo[];
  allTravelersPreBookQuestionsResponse: PreBookQuestionResponse[] | null;
}

const getTravelersData = ({
  travelersData,
  travelerSelections,
  airSelectedItineraryResponseManager,
  perTravellerLoyaltyInfo,
  unusedCreditRequests,
  ssrOsiForm,
  userTripInfos = [],
  allTravelersPreBookQuestionsResponse,
}: GetTravelersData): AirRevalidateItineraryRequestTravelerInfo[] => {
  const draftTravelers = cloneDeep(travelersData);
  const legDetails = airSelectedItineraryResponseManager.GetItineraryAllLegs();
  const seatIndicesMap: Record<string, { legIndex: number; flightIndex: number }> = {};

  let seatFlightIndex = 0;

  legDetails.forEach((legDetail, legNumber) => {
    const legFlights = airSelectedItineraryResponseManager.GetLegFlights(legDetail.legIndex);
    legFlights.forEach((_flightIndex, flightNumber) => {
      seatIndicesMap[seatFlightIndex] = { legIndex: legNumber, flightIndex: flightNumber };
      seatFlightIndex += 1;
    });
  });

  const mealRequests = { inclMealPrefs: [] };
  const travelers = draftTravelers.map((travelerCheckoutDetails, paxIndex) => {
    const draftTraveler = travelerCheckoutDetails;
    const travelerId = draftTraveler?.userOrgId?.userId?.id;

    if (draftTraveler?.travelerPersonalInfo) {
      const isLoyaltyBlocked = draftTraveler.travelerPersonalInfo?.blockLoyalty ?? false;
      draftTraveler.travelerPersonalInfo.loyaltyInfos = isLoyaltyBlocked ? [] : perTravellerLoyaltyInfo[paxIndex];
      if (draftTraveler.travelerPersonalInfo.travelPref?.railCards && isLoyaltyBlocked) {
        draftTraveler.travelerPersonalInfo.travelPref.railCards = [];
      }
    }

    if (draftTraveler?.travelerPersonalInfo?.travelPref?.airPref?.mealPref) {
      draftTraveler.travelerPersonalInfo.travelPref.airPref.mealPref = mealRequests;
    }

    if (draftTraveler?.user) {
      if (draftTraveler?.user?.phoneNumbers) {
        draftTraveler.user.phoneNumbers = draftTraveler.user.phoneNumbers.filter(
          (phoneNumber) => phoneNumber?.rawInput === travelerSelections[paxIndex]?.phone?.toString(),
        );
      }

      if (draftTraveler?.user?.identityDocs) {
        draftTraveler.user.identityDocs = draftTraveler.user.identityDocs.filter(
          (travelDoc) =>
            travelDoc?.redressNumber ||
            travelDoc?.knownTravelerNumber ||
            travelDoc?.ktn ||
            travelDoc?.redress ||
            (travelDoc.passport?.docId &&
              // ^^ added this condition because when checking for other docs travelDoc.passport?.docId is undefined
              // and if passport is not selected at checkout then travelerSelections[paxIndex]?.passport?.docId is undefined
              // making below condition always true for other documents
              travelDoc.passport?.docId === travelerSelections[paxIndex]?.passport?.docId?.toString()) ||
            (travelDoc.nationalDoc?.docId &&
              travelDoc.nationalDoc?.docId === travelerSelections[paxIndex]?.nationalDoc?.docId?.toString()),
        );
      }

      const selectedDob = { iso8601: travelerSelections[paxIndex].dob ?? draftTraveler?.user?.dob?.iso8601 ?? '' };

      draftTraveler.user.dob = selectedDob;

      draftTraveler.user.gender = travelerSelections[paxIndex].gender
        ? (Number(travelerSelections[paxIndex].gender) as Gender)
        : draftTraveler?.user?.gender;
    }

    const flights = airSelectedItineraryResponseManager.getLegFlightIndexInfo();
    const specialServiceRequests = getSpecialServiceRequestsForFlights(ssrOsiForm, travelerId, flights);

    const currentUserTripInfo = userTripInfos.find((info) => info.userId?.id === travelerId);
    /**
     * In a group trip, each user can only have 1 associated trip Id to this group
     */
    const tripIdOfTraveler = { id: currentUserTripInfo?.tripInfos?.[0]?.tripId || '' };

    /**
     * Saves the pre book answers at traveler level
     */
    let mergedPreBookAnswers = null;
    const { preBookAnswers } = travelerSelections[paxIndex];
    const preBookAnswerValues: EntityAnswer[] = preBookAnswers ? Object.values(preBookAnswers) : [];

    if (preBookAnswerValues.length > 0) {
      mergedPreBookAnswers = getMergedPreBookAnswers(
        preBookAnswerValues,
        null,
        null,
        allTravelersPreBookQuestionsResponse?.[paxIndex]?.preBookQuestionResponseId ?? '',
      );
    }

    return {
      traveler: draftTraveler,
      seats:
        travelerSelections[paxIndex]?.seat?.map(({ flightIndex, seatNumber }) => ({
          legIndex: seatIndicesMap[flightIndex].legIndex,
          flightIndex: seatIndicesMap[flightIndex].flightIndex,
          seatNumber,
        })) ?? [],
      unusedCreditRequests: paxIndex === 0 ? unusedCreditRequests ?? [] : [],
      baggage: travelerSelections[paxIndex]?.baggage ?? [],
      paxType: travelerSelections[paxIndex]?.paxType ?? PassengerTypeEnum.UNKNOWN_PASSENGER_TYPE,
      preBookAnswers: mergedPreBookAnswers,
      specialServiceRequests,
      otherAncillaries: travelerSelections[paxIndex].ancillary || [],
      doNotShareEmailAndPhone: travelerSelections[paxIndex]?.doNotShareEmailAndPhone ?? false,
      tripId: tripIdOfTraveler,
    };
  });

  return travelers;
};

export const fetchAirRevalidateRequest = ({
  ancillaryResponseId,
  travelersData,
  travelerSelections,
  seatMapResponseId,
  airSelectedItineraryResponseManager,
  perTravellerLoyaltyInfo,
  unusedCreditRequests,
  ssrOsiForm,
  paymentSourceIds,
  initiateBookingId,
  bookingPaymentDetails,
  userTripInfos,
  bookingType,
  promotionCode,
  waiverCodeValues,
  pnrRemarks,
  isIntermediate,
}: IAirFetchRevalidateRequestInput): AirRevalidateItineraryRequest => {
  const travelers = getTravelersData({
    travelersData,
    travelerSelections,
    airSelectedItineraryResponseManager,
    perTravellerLoyaltyInfo,
    unusedCreditRequests,
    ssrOsiForm,
    userTripInfos: undefined,
    allTravelersPreBookQuestionsResponse: null,
  });

  const flights = airSelectedItineraryResponseManager.getLegFlightIndexInfo();
  const otherServiceInfoForFlights = getOtherServiceInformationForFlights(ssrOsiForm, flights);

  /**
   * Primary Traveler's trip ID is sent on the itinerary level for events
   */
  const tripIdOfTraveler = { id: userTripInfos[0]?.tripInfos?.[0].tripId || '' };

  let fetchRevalidateRequest: AirRevalidateItineraryRequest = {
    ancillaryResponseId,
    seatMapResponseId,
    travelers,
    attemptNo: undefined,
    otherServiceInfos: otherServiceInfoForFlights,
    initiateBookingId,
    waiverCode: waiverCodeValues.waiverCode.value,
    ticketDesignator: waiverCodeValues.ticketDesignator.value,
    tourCodeInfo: waiverCodeValues.tourCode.value ? { tourCode: waiverCodeValues.tourCode.value } : undefined,
    bookingType,
    tripId: tripIdOfTraveler,
    promotionCode,
    qcEnabled: false,
    pnrRemarks,
    isIntermediate,
  };

  if (bookingPaymentDetails) {
    fetchRevalidateRequest = {
      ...fetchRevalidateRequest,
      bookingPaymentDetails,
    };
  } else if (paymentSourceIds.length > 0) {
    fetchRevalidateRequest = {
      ...fetchRevalidateRequest,
      bookingCharges: paymentSourceIds.map((paymentSourceId) => ({
        selectedPaymentSource: { paymentSourceId, cvv: '' },
      })),
    };
  }

  return fetchRevalidateRequest;
};

export const revalidateAirPriceRequest = ({
  ancillaryResponseId,
  travelersData,
  travelerSelections,
  seatMapResponseId,
  paymentMethod,
  airSelectedItineraryResponseManager,
  perTravellerLoyaltyInfo,
  attemptNo,
  unusedCreditRequests,
  ssrOsiForm,
  paymentSourceIds,
  initiateBookingId,
  bookingPaymentDetails,
  bookingType,
  userTripInfos,
  allTravelersPreBookQuestionsResponse,
  promotionCode,
  emailAddress,
  phoneNumber,
  qcEnabled,
  waiverCodeValues,
  pnrRemarks,
  isIntermediate,
}: IAirRevalidateAirPriceRequestInput): AirRevalidateItineraryRequest => {
  const airRevalidateRequest = fetchAirRevalidateRequest({
    ancillaryResponseId,
    travelersData,
    travelerSelections,
    seatMapResponseId,
    airSelectedItineraryResponseManager,
    perTravellerLoyaltyInfo,
    unusedCreditRequests,
    ssrOsiForm,
    paymentSourceIds,
    initiateBookingId,
    bookingPaymentDetails,
    bookingType,
    promotionCode,
    emailAddress,
    phoneNumber,
    userTripInfos,
    waiverCodeValues,
    pnrRemarks,
    isIntermediate,
  });

  const travelers = getTravelersData({
    travelersData,
    travelerSelections,
    airSelectedItineraryResponseManager,
    perTravellerLoyaltyInfo,
    unusedCreditRequests,
    ssrOsiForm,
    userTripInfos,
    allTravelersPreBookQuestionsResponse,
  });

  /**
   * Primary Traveler's trip ID is sent on the itinerary level for events
   */

  let revalidateItineraryRequest: AirRevalidateItineraryRequest = {
    ...airRevalidateRequest,
    travelers,
    attemptNo,
    qcEnabled,
  };

  if (paymentMethod === PaymentMethodEnum.BREX_POINTS) {
    revalidateItineraryRequest = {
      ...revalidateItineraryRequest,
      bookingCharges: [{ fop: { paymentMethod } }],
    };
  }

  return revalidateItineraryRequest;
};

export const getInitiateBookingRequestV1 = ({
  ancillaryResponseId,
  travelersData,
  travelerSelections,
  seatMapResponseId,
  airSelectedItineraryResponseManager,
  perTravellerLoyaltyInfo,
  ssrOsiForm,
  postPaymentRedirectionUrl,
  emailAddress,
  phoneNumber,
}: IAirInitiateBookingRequestInput): AirInitiateBookingRequestV1 => {
  const travelers = getTravelersData({
    travelersData,
    travelerSelections,
    airSelectedItineraryResponseManager,
    perTravellerLoyaltyInfo,
    unusedCreditRequests: [],
    ssrOsiForm,
    allTravelersPreBookQuestionsResponse: null,
  });

  let bookingContact: AirInitiateBookingRequestBookingContact | undefined;
  if (phoneNumber || emailAddress) {
    bookingContact = { emailAddress: '' };

    if (emailAddress) {
      bookingContact.emailAddress = emailAddress;
    }

    if (phoneNumber) {
      bookingContact.phoneNumber = phoneNumber;
    }
  }
  const initiateBookingRequest: AirInitiateBookingRequestV1 = {
    seatMapResponseId,
    ancillaryResponseId,
    bookingCharges: [
      {
        selectedPaymentSource: {
          postPaymentRedirectionUrl,
        },
      },
    ],
    travelers,
    bookingContact,
  };

  return initiateBookingRequest;
};

export const getTotalFlightPaxNum = (paxInfo: IPaxInfo): number => Object.values(paxInfo).flat().length;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFareExpiredError = (error: any): boolean => {
  const errorCode = error?.response?.data?.errorCode || error?.response?.data?.code;
  return errorCode === fareExpiredErrorCode;
};
