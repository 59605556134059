const getDateChangeIndicator = (dateChange: number): string => {
  let dateChangeIndicator = '';
  if (dateChange > 0) {
    dateChangeIndicator = `+${dateChange.toString()}`;
  } else if (dateChange < 0) {
    dateChangeIndicator = dateChange.toString();
  }
  return dateChangeIndicator;
};

export default getDateChangeIndicator;
