import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CarryOnBaggageV3 = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.627 7.416A2.25 2.25 0 0013.38 5.25H10.62l-.084.002A2.25 2.25 0 008.37 7.5v.08h-1.33a2.625 2.625 0 00-2.625 2.624v5.921a2.625 2.625 0 002.625 2.625h10.071a2.625 2.625 0 002.625-2.625v-5.92a2.625 2.625 0 00-2.625-2.626h-1.483V7.5l-.002-.084zm-1.498.163V7.5a.75.75 0 00-.694-.748l-.056-.002H10.62a.75.75 0 00-.748.694l-.002.056v.08h4.258zm-5.88 1.5h7.5v8.171h-7.5V9.08zm-.75 0h-.458c-.622 0-1.125.504-1.125 1.125v5.921c0 .621.504 1.125 1.125 1.125H7.5V9.08zm9 8.171V9.08h.613c.622 0 1.125.503 1.125 1.124v5.921c0 .621-.504 1.125-1.125 1.125H16.5z", fill: themeEnrichedPrimaryColor })));
};
CarryOnBaggageV3.defaultProps = defaultIconProps;
export default CarryOnBaggageV3;
