import type { FetchQueryOptions, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import type { AxiosError } from 'axios';

import api from '../api';
import type { InternalAPIError } from '../types';
import { CUSTOM_FIELD_TYPES } from '../constants';
import { defaultQueryClient } from './defaultQueryClient';
import type { GetCustomFieldDetailsRequest } from '../types/api/v2/obt/model/get-custom-field-details-request';
import type { CustomFieldAttribute } from '../types/api/v2/obt/model/custom-field-attribute';

const CUSTOM_FIELD_QUERY_KEY = 'custom_field_query_key';

type BrexBudgetStrAttributes =
  | 'BUDGET_NAME'
  | 'BUDGET_REMAINING_BALANCE' // eg. 4235
  | 'BUDGET_CURRENCY' // eg. USD
  | 'CARD_NUMBER'
  | 'BUDGET_REMAINING_BALANCE_FORMATTED'; // eg. $4235.00 USD
type BrexBudgetBoolAttributes = 'IS_PERSONAL_CARD_ALLOWED' | 'IS_BUDGET_MASKED';

export type BrexBudgetInfoResponse = Record<BrexBudgetStrAttributes, string> &
  Record<BrexBudgetBoolAttributes, boolean>;

type AttributeEntry =
  | { attributeName: BrexBudgetStrAttributes; attributeValue: string }
  | { attributeName: BrexBudgetBoolAttributes; attributeValue: 'true' | 'false' };

export interface CustomFieldResponse {
  attributes: Array<AttributeEntry>;
}

type CustomFieldData = Record<string, any>;

const booleanAttributes: Array<BrexBudgetBoolAttributes> = ['IS_PERSONAL_CARD_ALLOWED', 'IS_BUDGET_MASKED'];

function parseAttributes(attrs: AttributeEntry[]): BrexBudgetInfoResponse {
  return attrs.reduce((acc, { attributeName, attributeValue }) => {
    const value = booleanAttributes.includes(attributeName as BrexBudgetBoolAttributes)
      ? attributeValue === 'true'
      : attributeValue;

    return {
      ...acc,
      [attributeName]: value,
    };
  }, {} as BrexBudgetInfoResponse);
}

const getCustomFieldQueryKey = (request: GetCustomFieldDetailsRequest) => [
  CUSTOM_FIELD_QUERY_KEY,
  request.id,
  request.type,
  request.skipAttributes,
];

async function fetchCustomFieldsDetails(request: GetCustomFieldDetailsRequest): Promise<BrexBudgetInfoResponse> {
  const response = await api('POST', 'getCustomFieldsDetails', { data: request });
  return parseAttributes((response as CustomFieldResponse).attributes);
}

export const useCustomFieldQuery = (
  request: Partial<GetCustomFieldDetailsRequest>,
  options?: UseQueryOptions<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>,
) =>
  useQuery<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>({
    ...options,
    enabled: options?.enabled !== false && Boolean(request.id && request.type),
    queryKey: getCustomFieldQueryKey(request as GetCustomFieldDetailsRequest),
    queryFn: () => fetchCustomFieldsDetails(request as GetCustomFieldDetailsRequest),
  });

export const customFieldQueryFetch = async (
  request: GetCustomFieldDetailsRequest,
  fetchQueryOptions?: FetchQueryOptions<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>,
): Promise<BrexBudgetInfoResponse> => {
  return defaultQueryClient.fetchQuery<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>({
    ...fetchQueryOptions,
    queryKey: getCustomFieldQueryKey(request),
    queryFn: () => fetchCustomFieldsDetails(request),
  });
};

export interface ICustomField {
  type: string;
  value: string;
}

export interface BrexBudgetInfoRequests {
  brexJwtToken: string | undefined;
}

export const useBrexBudgetInfoQuery = (
  { brexJwtToken }: BrexBudgetInfoRequests,
  options?: UseQueryOptions<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>,
) =>
  useCustomFieldQuery(
    {
      id: brexJwtToken,
      type: CUSTOM_FIELD_TYPES.BREX_TOKEN,
    },
    { ...options, refetchOnWindowFocus: false },
  );

export const useBrexBudgetInfoWithoutCardQuery = (
  { brexJwtToken }: BrexBudgetInfoRequests,
  options?: UseQueryOptions<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>,
) =>
  useCustomFieldQuery(
    {
      id: brexJwtToken,
      type: CUSTOM_FIELD_TYPES.BREX_TOKEN,
      skipAttributes: ['CARD_NUMBER'],
    },
    { ...options, refetchOnWindowFocus: false },
  ) as UseQueryResult<Omit<BrexBudgetInfoResponse, 'CARD_NUMBER'>, AxiosError<InternalAPIError>>;

export const fetchBrexBudgetInfo = (
  { brexJwtToken }: BrexBudgetInfoRequests & { brexJwtToken: string },
  fetchQueryOptions?: FetchQueryOptions<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>,
) => customFieldQueryFetch({ id: brexJwtToken, type: CUSTOM_FIELD_TYPES.BREX_TOKEN }, fetchQueryOptions);

export const fetchBrexBudgetInfoWithoutCard = (
  { brexJwtToken }: BrexBudgetInfoRequests & { brexJwtToken: string },
  fetchQueryOptions?: FetchQueryOptions<CustomFieldData, AxiosError<InternalAPIError>, BrexBudgetInfoResponse>,
) =>
  customFieldQueryFetch(
    { id: brexJwtToken, type: CUSTOM_FIELD_TYPES.BREX_TOKEN, skipAttributes: ['CARD_NUMBER'] },
    fetchQueryOptions,
  ) as Promise<Omit<BrexBudgetInfoResponse, 'CARD_NUMBER'>>;

export type { CustomFieldAttribute };
