import type { IAuthenticatedUserBasicInfo } from '../../types';

export type RBACPredicate = (userBasicInfo: IAuthenticatedUserBasicInfo) => boolean;

/**
 * A closure over spotnanaTmcId returning a predicate that checks for further restrictions imposed on few user roles
 * @returns (userBasicInfo: IAuthenticatedUserBasicInfo): boolean
 */
const checkForSeat1aRestrictions =
  () =>
  (userBasicInfo: IAuthenticatedUserBasicInfo): boolean => {
    const { existingUser } = userBasicInfo;
    if (!existingUser) {
      return false;
    }

    return true;
  };

export { checkForSeat1aRestrictions };
