import type { Card } from '../../types';
import { getTwoDigitNumber } from '../common';

export const getExpiryDate = (card: Card) => {
  const { expiryMonth: deprecatedExpiryMonth, expiryYear: deprecatedExpiryYear, expiry } = card;
  const expiryMonth = expiry && 'expiry' in expiry ? expiry.expiry?.expiryMonth : deprecatedExpiryMonth;
  const expiryYear = expiry && 'expiry' in expiry ? expiry.expiry?.expiryYear : deprecatedExpiryYear;

  return (expiry && 'tokenizedExpiry' in expiry) || expiryMonth === undefined || expiryYear === undefined
    ? 'XX/XX'
    : `${getTwoDigitNumber(expiryMonth ?? 0)}/${getTwoDigitNumber(expiryYear ?? 0)}`;
};
