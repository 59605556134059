var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Limo = (_a) => {
    var { primaryColor } = _a, restProps = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 23 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, restProps),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.5703 4.52269L22.0791 4.52517C22.5229 4.5284 22.884 4.89213 22.884 5.33595V9.09557C22.884 9.53993 22.5225 9.90147 22.0781 9.90147L20.9063 10.0013C20.6332 10.9385 19.7673 11.6257 18.7431 11.6257C17.719 11.6257 16.8531 10.9385 16.58 10.0013L7.16999 9.90147C6.89698 10.8388 6.03111 11.5259 5.00687 11.5259C3.98262 11.5259 3.11683 10.8388 2.84375 9.90147H1.55591C1.11154 9.90147 0.75 9.53993 0.75 9.09557V6.08423C0.75 5.65594 1.08942 5.22888 1.50673 5.13217L3.2342 4.73183C3.55426 4.65765 3.97521 4.36177 4.1533 4.08568L6.12452 1.03101C6.34986 0.681761 6.85192 0.408203 7.26758 0.408203H18.1505C18.5691 0.408203 19.0589 0.693299 19.2656 1.05731L20.7014 4.01267C20.8571 4.28684 21.255 4.52043 21.5703 4.52269ZM3.88743 9.27221C3.88743 9.89042 4.38867 10.3917 5.00687 10.3917C5.62514 10.3917 6.1263 9.89042 6.1263 9.27221C6.1263 8.65395 5.62514 8.15278 5.00687 8.15278C4.3886 8.15278 3.88743 8.65395 3.88743 9.27221ZM10.4362 4.73272H5.74698C5.45247 4.73272 5.33716 4.52709 5.49059 4.27579L6.91882 1.93657C7.07225 1.68526 7.43874 1.47963 7.73317 1.47963H10.4362V4.73272ZM17.625 9.2722C17.625 9.8904 18.1262 10.3917 18.7444 10.3917C19.3627 10.3917 19.8639 9.8904 19.8639 9.2722C19.8639 8.65393 19.3626 8.15276 18.7444 8.15276C18.1262 8.15276 17.625 8.65393 17.625 9.2722ZM19.3897 4.73269H15.2812V1.4796H17.6367C17.9312 1.4796 18.2864 1.69169 18.4261 1.95089L19.6712 4.2614C19.8109 4.5206 19.6842 4.73269 19.3897 4.73269ZM14.6113 1.478H11.2969V4.73361H14.6113V1.478Z", fill: themeEnrichedPrimaryColor })));
};
Limo.defaultProps = defaultIconProps;
export default Limo;
