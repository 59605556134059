/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Name of the feature.
 * @export
 * @enum {string}
 */

export const FeatureNameEnum = {
  FlightResultsOop: 'FLIGHT_RESULTS_OOP',
  HotelResultsOop: 'HOTEL_RESULTS_OOP',
  RailResultsOop: 'RAIL_RESULTS_OOP',
  CarsResultsOop: 'CARS_RESULTS_OOP',
  PaymentAddCard: 'PAYMENT_ADD_CARD',
  MenuItemPolicies: 'MENU_ITEM_POLICIES',
  IntegrationExpensify: 'INTEGRATION_EXPENSIFY',
  TravelerSelection: 'TRAVELER_SELECTION',
  FeaturePersonalTravel: 'FEATURE_PERSONAL_TRAVEL',
  ProductObtRail: 'PRODUCT_OBT_RAIL',
  MenuItemCompanyRemarks: 'MENU_ITEM_COMPANY_REMARKS',
  HomepageAddUser: 'HOMEPAGE_ADD_USER',
  MenuItemCustomFields: 'MENU_ITEM_CUSTOM_FIELDS',
  PaymentUnusedCredits: 'PAYMENT_UNUSED_CREDITS',
  EmailUpdateByCompanyAdmin: 'EMAIL_UPDATE_BY_COMPANY_ADMIN',
  AdhocBookingSearchPage: 'ADHOC_BOOKING_SEARCH_PAGE',
  AdhocBookingSaveTraveler: 'ADHOC_BOOKING_SAVE_TRAVELER',
  AdminVirtualCard: 'ADMIN_VIRTUAL_CARD',
  CentralCardServiceFee: 'CENTRAL_CARD_SERVICE_FEE',
  ExpediaSourceEnabled: 'EXPEDIA_SOURCE_ENABLED',
  BookingComSourceEnabled: 'BOOKING_COM_SOURCE_ENABLED',
  HotelMedianEnable: 'HOTEL_MEDIAN_ENABLE',
  HideSearchCriteria: 'HIDE_SEARCH_CRITERIA',
  AdhocPaymentSource: 'ADHOC_PAYMENT_SOURCE',
  ServiceChargeConfigDisabled: 'SERVICE_CHARGE_CONFIG_DISABLED',
  HideSettingsMenuItemCompany: 'HIDE_SETTINGS_MENU_ITEM_COMPANY',
  HideSettingsMenuItemUsers: 'HIDE_SETTINGS_MENU_ITEM_USERS',
  HidePolicyApprovalSection: 'HIDE_POLICY_APPROVAL_SECTION',
  HidePolicyCreation: 'HIDE_POLICY_CREATION',
  HideSettingsTextFromMenu: 'HIDE_SETTINGS_TEXT_FROM_MENU',
  HideLeftNav: 'HIDE_LEFT_NAV',
  HidePolicyAirCarrier: 'HIDE_POLICY_AIR_CARRIER',
  HidePolicyAirCo2: 'HIDE_POLICY_AIR_CO2',
  HidePolicyHotelPriceLocationCustomisation: 'HIDE_POLICY_HOTEL_PRICE_LOCATION_CUSTOMISATION',
  HideProfilePreferencesAirlines: 'HIDE_PROFILE_PREFERENCES_AIRLINES',
  HideProfilePreferencesAlliances: 'HIDE_PROFILE_PREFERENCES_ALLIANCES',
  MoneySetConvertedCurrencyAsOriginalCurrency: 'MONEY_SET_CONVERTED_CURRENCY_AS_ORIGINAL_CURRENCY',
  ProductObtAirDisabled: 'PRODUCT_OBT_AIR_DISABLED',
  ProductObtHotelDisabled: 'PRODUCT_OBT_HOTEL_DISABLED',
  ProductObtCarDisabled: 'PRODUCT_OBT_CAR_DISABLED',
  ProductObtLimoDisabled: 'PRODUCT_OBT_LIMO_DISABLED',
  ProductObtConciergeDisabled: 'PRODUCT_OBT_CONCIERGE_DISABLED',
  EventBookingEnabled: 'EVENT_BOOKING_ENABLED',
  EmployeeLevelDesignatedApproverEnabled: 'EMPLOYEE_LEVEL_DESIGNATED_APPROVER_ENABLED',
  EnrichSearchResponseWithRewardPoints: 'ENRICH_SEARCH_RESPONSE_WITH_REWARD_POINTS',
  HideLeftNavEmbed: 'HIDE_LEFT_NAV_EMBED',
  HidePolicyAirNotAllowedToBeBooked: 'HIDE_POLICY_AIR_NOT_ALLOWED_TO_BE_BOOKED',
  HideTripFeeConfig: 'HIDE_TRIP_FEE_CONFIG',
  HideAgentFeeConfig: 'HIDE_AGENT_FEE_CONFIG',
  ShowServiceFeeTaxes: 'SHOW_SERVICE_FEE_TAXES',
  HideGuestBookingSetting: 'HIDE_GUEST_BOOKING_SETTING',
  UserCreationPersonaTypeOptions: 'USER_CREATION_PERSONA_TYPE_OPTIONS',
  DisallowMultiCityAddFlight: 'DISALLOW_MULTI_CITY_ADD_FLIGHT',
  ShowTripFeeConfig: 'SHOW_TRIP_FEE_CONFIG',
  ShowAgentFeeConfig: 'SHOW_AGENT_FEE_CONFIG',
  ProfileElementsVisibility: 'PROFILE_ELEMENTS_VISIBILITY',
  ShowIndianCardPaymentGuidelines: 'SHOW_INDIAN_CARD_PAYMENT_GUIDELINES',
} as const;

export type FeatureNameEnum = (typeof FeatureNameEnum)[keyof typeof FeatureNameEnum];
