import { useEmbedStore } from 'src/components/EmbedContext';
import { Config } from 'obt-common';
import { useAppBranding } from 'src/components/AppBrandingProvider/AppBrandingProvider';
import AppFooter from './AppFooter';

export default function Footer() {
  const { isEmbed } = useEmbedStore();
  const { poweredBySpotnana, privacyLink, termsOfUseLink } = useAppBranding();
  const showPoweredBySpotnana = isEmbed || poweredBySpotnana;

  return (
    <footer>
      <AppFooter
        showPoweredBySpotnana={showPoweredBySpotnana}
        termsOfUseLink={termsOfUseLink || ''}
        privacyLink={privacyLink || ''}
      />
      <div id="sn-app-footer" data-environment={Config.VITE_ENVIRONMENT} data-testid="sn-app-footer" />
    </footer>
  );
}
