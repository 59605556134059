import produce from 'immer';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { dateFormats } from '../../constants/common';
import {
  PassengerTypeEnum,
  airTranslationKeys,
  airlinesWithNoSeatSelectionOption,
  utaTypes,
} from '../../constants/flights';
import { convertDateFormat } from '../../date-utils';
import type { Alliance } from '../../types/api/v1/obt/air/air_common';
import { FareCategoryCabinViewFareCategory, FareCategoryNGSFareCategory } from '../../types/api/v1/obt/air/air_common';
import type { AirSeatMapRequestLoyaltyInfo } from '../../types/api/v1/obt/air/air_seat_map_request';
import type { Traveler } from '../../types/api/v1/obt/common/traveler';
import type { IdentityDocument } from '../../types/api/v1/obt/common/user';
import type { EntityAnswer } from '../../types/api/v1/obt/policy/user_defined_entity';
import { PreDefinedQuestionPreCheckoutQuestionTypes } from '../../types/api/v1/obt/policy/user_defined_entity';
import { ThirdPartySourceEnum } from '../../types/api/v1/obt/supplier/third_party_info';
import type { IAirSuggestion } from '../../types/autocomplete';
import type { IKeyLabelPairWithMetaInfo } from '../../types/common';
import { FlightBookingType } from '../../types/common';
import type {
  Carrier,
  IAirAncillariesResponse,
  IAirMultipaxSimplifiedVariables,
  IAirSearchFormPaxInfo,
  IAirSearchUrlPaxInfo,
  IBaggageOption,
  IBaggageOptions,
  IBreadcrumb,
  ICabinSectionMultiDeck,
  ICabinSectionRowsWithSeatGroup,
  IShouldShowFareChangeAlertProps,
  IFlightPolicyUtas,
  IFlightSearchSegmentState,
  IFlightUtaPolicyInfo,
  IFlightsFilterState,
  IItineraryFareInfoEnhanced,
  ILoyaltyInfo,
  ISeat,
  ISeatmapAvailability,
  ISelectedBaggagePerPassenger,
  NationalityRequirements,
  PassportRequirements,
  SpecialAndOtherUtasForAllFares,
  UtaProps,
} from '../../types/flight';
import {
  AirSeatMapLocation,
  FacilityType,
  ISeatSelectionWorkflowEnum,
  ISeatmapUnavailableReasonEnum,
  SeatMapLegendType,
  SeatSectionSeatType,
} from '../../types/flight';
import type { IPreBookQuestionResponse, ITraveler } from '../../types/traveler';
import { IAirAncillariesResponseReqdParamsForCheckoutParameterEnum } from '../../types/traveler';
import type { ITripPnr } from '../../types/trip';
import { IThirdPartySourceEnum } from '../../types/trip';

import { specialUtasKeys } from '../../constants/flights/specialUtaKeys';
import type AirAncillaryResponseManager from '../../services/AirAncillaryResponseManager';
import type AirSearchResponseManager from '../../services/AirSearchResponseManager';
import type AirSeatMapResponseManager from '../../services/AirSeatMapResponseManager';
import type { AirportInfo } from '../../types';
import type { AirAncillariesResponseAncillaryAncillaryType } from '../../types/api/v1/obt/air/air_ancillaries';
import type { AirRevalidateItineraryRequestTravelerInfoOtherAncillary } from '../../types/api/v1/obt/air/air_revalidate_itinerary_request';
import type { SortOptionSortBy } from '../../types/api/v1/obt/air/air_search_request';
import type { AirSearchResponse, MetadataAirline, PaxInfo } from '../../types/api/v1/obt/air/air_search_response';
import { MetadataFlightRestrictionsRestriction } from '../../types/api/v1/obt/air/air_search_response';
import type { Uta, UtaPolicy } from '../../types/api/v1/obt/air/routehappy';
import { PersonaEnum } from '../../types/api/v1/obt/common/user_org_id';
import type { AirPnrTravelerInfo } from '../../types/api/v1/obt/pnr/pnr';
import type { PreBookAnswersResponse } from '../../types/api/v1/obt/policy/pre_search_and_book_question';
import type { AirlineInfo } from '../../types/api/v2/obt/model/airline-info';
import { MoneyUtil } from '../Money';
import { getArrayFromArrayOrNode } from '../common';
import type { OtherAncillaryDetail } from '../../types/api/v2/obt/model/other-ancillary-detail';
import { OtherAncillaryTypeMap } from '../../constants';
import type { V2TripDetailsResponseManager } from '../../services';
import type { ICommonI18nMessage } from '../../translations/defineMessage';
import { defineCommonMessage } from '../../translations/defineMessage';
import { optionalUtasAcrossFares } from '../../constants/flights/optionalUtasAcrossFares';

export * from './cabins';
export * from './highFareReportModalUtils';
export * from './ssrOsiFormUtils';
export * from './seatmap';

export { hasClassicRewardsFare } from './hasClassicRewardsFare';
export { getAncillaryFareForPnr } from './getAncillaryFareForPnr';
export * from './getBrandAndAirlineInfoForSeatMap';
export * from './getApplicableAlliances';
export * from './getTranslateCabin';

export const shouldFetchAncillaryResponse = (
  ancillaryResponse: IAirAncillariesResponse | null,
  selectedTraveler: Traveler | null,
): boolean => {
  if (!ancillaryResponse && selectedTraveler) {
    return true;
  }
  return false;
};

export const shouldFetchSeatMapResponse = (
  ancillaryResponse: IAirAncillariesResponse | null,
  itinSource: ThirdPartySourceEnum,
  seatMapLoyaltyInfo: AirSeatMapRequestLoyaltyInfo[],
  selectedTraveler: Traveler | null,
): boolean => {
  if (seatMapLoyaltyInfo.length > 0 && selectedTraveler) {
    /* In case of Sabre, the seatmap response does not depend on ancillary response . so we return true to make seat map api call */
    if (!ancillaryResponse && itinSource === ThirdPartySourceEnum.SABRE) {
      return true;
    }

    /* In case of travel-fusion, the seatmap response depends on ancillary response.
     so we return true to make seat map api call ony if the ancillary response is available */

    if (ancillaryResponse && itinSource === ThirdPartySourceEnum.TRAVEL_FUSION) {
      return true;
    }
  }
  return false;
};

export const getTravelerDocMap = (identityDocs: IdentityDocument[] | undefined) => {
  const emptyMap = new Map();
  const travelDocMap =
    identityDocs?.reduce((identityDocMap, identityDoc, index) => {
      const identityDocKey = first(Object.keys(identityDoc));
      const mapValue = identityDocMap.get(identityDocKey);
      identityDocMap.set(identityDocKey ?? '', mapValue ? [...mapValue, index] : [index]);
      return identityDocMap;
    }, emptyMap) ?? emptyMap;
  return travelDocMap;
};

export const findIfCvvRequired = (ancillaryResponse?: IAirAncillariesResponse): boolean =>
  ancillaryResponse?.reqdParamsForCheckout.some(
    (param) => param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.CVV,
  ) ?? false;

export const findIfDobRequired = (ancillaryResponse?: IAirAncillariesResponse): boolean =>
  ancillaryResponse?.reqdParamsForCheckout.some(
    (param) => param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.DATE_OF_BIRTH,
  ) ?? false;

export const findIfGenderRequired = (ancillaryResponse?: IAirAncillariesResponse): boolean =>
  ancillaryResponse?.reqdParamsForCheckout.some(
    (param) => param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.GENDER,
  ) ?? false;

export const isLLFConfiguredForPreCheckout = (
  preBookQuestionsResponse: IPreBookQuestionResponse | null | undefined,
): boolean =>
  !!preBookQuestionsResponse &&
  preBookQuestionsResponse.questions.length > 0 &&
  preBookQuestionsResponse.questions.findIndex(
    (currentQuestion) =>
      currentQuestion?.question?.questionType?.preCheckout ===
      PreDefinedQuestionPreCheckoutQuestionTypes.LLF_NOT_CHOSEN_REASON,
  ) !== -1;

export const getTripNameFromSegments = (segments: IFlightSearchSegmentState<IAirSuggestion>[]): string => {
  const tripArrivalName = last(segments)?.destination.name ?? '';
  const tripDepartureDate = convertDateFormat(
    first(segments)?.date ?? '',
    dateFormats.LONG_DATE_REVERSE,
    dateFormats.DATE,
  );
  return `(${tripDepartureDate}) ${tripArrivalName}`;
};
export function getTranslationKeyForNgsFareType(fareType: FareCategoryNGSFareCategory): string {
  switch (fareType) {
    case FareCategoryNGSFareCategory.BASE:
      return airTranslationKeys.BASE;
    case FareCategoryNGSFareCategory.STANDARD:
      return airTranslationKeys.STANDARD;
    case FareCategoryNGSFareCategory.ENHANCED:
      return airTranslationKeys.ENHANCED;
    case FareCategoryNGSFareCategory.PREMIUM:
      return airTranslationKeys.PREMIUM;
    case FareCategoryNGSFareCategory.LUXURY:
      return airTranslationKeys.LUXURY;
    case FareCategoryNGSFareCategory.ULTRA_LUXURY:
      return airTranslationKeys.ULTRA_LUXURY;
    default:
      return '';
  }
}

export function getTranslationKeyForCabinFareType(fareType: FareCategoryCabinViewFareCategory): string {
  switch (fareType) {
    case FareCategoryCabinViewFareCategory.BASIC:
      return airTranslationKeys.BASIC;
    case FareCategoryCabinViewFareCategory.ECONOMY:
      return airTranslationKeys.ECONOMY;
    case FareCategoryCabinViewFareCategory.PREMIUM_ECONOMY:
      return airTranslationKeys.PREMIUM;
    case FareCategoryCabinViewFareCategory.BUSINESS:
      return airTranslationKeys.BUSINESS;
    case FareCategoryCabinViewFareCategory.FIRST:
      return airTranslationKeys.FIRST;
    case FareCategoryCabinViewFareCategory.ECONOMY_PLUS:
      return airTranslationKeys.ECONOMY_PLUS;
    default:
      return '';
  }
}

function setUtaForFareKey(
  fareUtasByKey: { [key: string]: IFlightUtaPolicyInfo[] },
  utaKey: string,
  fareIndex: number,
  utaInfo: UtaPolicy,
): void {
  if (!utaInfo.summary?.description) {
    return;
  }

  if (!fareUtasByKey[utaKey]) {
    // eslint-disable-next-line no-param-reassign
    fareUtasByKey[utaKey] = [];
  }

  // eslint-disable-next-line no-param-reassign
  fareUtasByKey[utaKey][fareIndex] = { key: utaKey, value: utaInfo };
}

export function getSortedUtasForFares(fares: IItineraryFareInfoEnhanced[]): IFlightPolicyUtas {
  // Creates a map from key of uta to array of Utas. Each index in array corresponds to a fare. For fares where uta doesn't exist, we will have undefined.
  const fareUtasByKey: { [key: string]: IFlightUtaPolicyInfo[] } = {};
  const allowedUtaKeys = Object.values(utaTypes);

  fares.forEach((fareInfo, fareIndex) => {
    fareInfo.passenger[0].utas.forEach((utaInfo) => {
      const utaKey = Object.keys(utaInfo)[0] as string;

      if (!allowedUtaKeys.includes(utaKey)) {
        return;
      }

      if (utaKey === 'baggage') {
        if (utaInfo.baggage?.checkedBag && utaInfo.baggage.checkedBag[0]?.policy) {
          setUtaForFareKey(fareUtasByKey, 'checkedBag', fareIndex, utaInfo.baggage.checkedBag[0].policy);
        }

        if (utaInfo.baggage?.checkedBag && utaInfo.baggage.checkedBag[1]?.policy) {
          setUtaForFareKey(fareUtasByKey, 'additionalCheckedBag', fareIndex, utaInfo.baggage.checkedBag[1].policy);
        }

        if (utaInfo.baggage?.carryOn && utaInfo.baggage.carryOn[0]?.policy) {
          setUtaForFareKey(fareUtasByKey, 'carryOn', fareIndex, utaInfo.baggage.carryOn[0].policy);
        }

        return;
      }
      setUtaForFareKey(fareUtasByKey, utaKey, fareIndex, utaInfo[utaKey as UtaProps] as UtaPolicy);
    });
  });

  // Create array of array of UTA per fare.
  const sortedFareUtas: IFlightUtaPolicyInfo[][] = [];
  const { seatType, seatSelection, advanceChange, cancellation, checkedBag, ...othersUtas } = fareUtasByKey;
  sortedFareUtas.push(seatType, seatSelection, advanceChange, cancellation, checkedBag, ...Object.values(othersUtas));
  const visibleFlightUtas = [seatType, seatSelection, advanceChange, cancellation, checkedBag].filter(
    (item) => !!item && item.length > 0,
  );
  const otherUtas = Object.values(othersUtas).filter((item) => !!item && item.length > 0);
  const allFlightUtas = [...visibleFlightUtas, ...otherUtas];
  return {
    allFlightUtaPolicyInfos: allFlightUtas,
    visibleFlightUtaPolicyInfos: visibleFlightUtas,
  };
}

export function getSpecialAndOtherUtasForAllFares(
  allFlightFaresUtaPolicyInfos: IFlightUtaPolicyInfo[][],
): SpecialAndOtherUtasForAllFares {
  const allFlightFaresSpecialUtaPolicies: IFlightUtaPolicyInfo[][] = [];
  const allFlightFaresOtherUtaPolicies: IFlightUtaPolicyInfo[][] = [];

  allFlightFaresUtaPolicyInfos.forEach((uta) => {
    const hasNonEmptyUtaPolicy = uta?.filter((utaPolicy) => !isEmpty(utaPolicy)).length > 0;
    if (hasNonEmptyUtaPolicy) {
      const isSpecialUta = uta?.filter((utaPolicy) => specialUtasKeys.includes(utaPolicy?.key as keyof Uta)).length > 0;
      const isOptionalUta =
        uta?.filter((utaPolicy) => optionalUtasAcrossFares.includes(utaPolicy?.key as keyof Uta)).length > 0;

      if (!isOptionalUta) {
        if (isSpecialUta) {
          allFlightFaresSpecialUtaPolicies.push(uta);
        } else {
          allFlightFaresOtherUtaPolicies.push(uta);
        }
      }
    }
  });

  return {
    allFlightFaresSpecialUtaPolicies,
    allFlightFaresOtherUtaPolicies,
  };
}

export function getIsOutOfPolicyAllowed(filter: IFlightsFilterState[]): boolean {
  const policyFilter = filter?.find((f) => Object.keys(f)[0] === 'policyFilter');
  if (!policyFilter) {
    return true;
  }

  return !policyFilter.policyFilter?.onlyInPolicy;
}

export function shouldShowSeparateTickets(filter: IFlightsFilterState[]): boolean {
  const multiTicketFilter = filter?.find((f) => Object.keys(f)[0] === 'multiTicketFilter');
  if (!multiTicketFilter) {
    return true;
  }

  return !multiTicketFilter.multiTicketFilter?.hideMultiTicket;
}

export function getIsFlightCreditsFilterEnabled(filter: IFlightsFilterState[]): boolean {
  const unusedCreditFilter = filter?.find((f) => Object.keys(f)[0] === 'unusedCreditFilter');
  if (!unusedCreditFilter) {
    return false;
  }

  return !!unusedCreditFilter.unusedCreditFilter?.hideWithoutCredits;
}

export function findIfPassportRequired(ancillaryResponse?: IAirAncillariesResponse): boolean {
  return (
    ancillaryResponse?.reqdParamsForCheckout.some(
      (param) =>
        param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.PASSPORT_ID ||
        param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.PASSPORT_EXPIRY_DATE ||
        param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.PASSPORT_ISSUE_COUNTRY,
    ) ?? false
  );
}

export function findIfNationalityRequired(ancillaryResponse?: IAirAncillariesResponse): boolean {
  return (
    ancillaryResponse?.reqdParamsForCheckout.some(
      (param) => param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.NATIONALITY,
    ) ?? false
  );
}

export function getAirPassportRequirements(
  ancillaryResponse: IAirAncillariesResponse | undefined,
): PassportRequirements {
  const reqdParam = ancillaryResponse?.reqdParamsForCheckout?.find?.(
    (param) =>
      param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.PASSPORT_ID ||
      param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.PASSPORT_EXPIRY_DATE ||
      param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.PASSPORT_ISSUE_COUNTRY,
  );

  if (!reqdParam) {
    return {
      isPassportRequired: false,
      perPassenger: false,
    };
  }

  return {
    isPassportRequired: true,
    perPassenger: reqdParam.perPassenger,
  };
}

export function getAirNationalityRequirements(
  ancillaryResponse: IAirAncillariesResponse | undefined,
): NationalityRequirements {
  const reqdParam = ancillaryResponse?.reqdParamsForCheckout?.find?.(
    (param) => param.parameter === IAirAncillariesResponseReqdParamsForCheckoutParameterEnum.NATIONALITY,
  );

  if (!reqdParam) {
    return {
      isNationalityRequired: false,
      perPassenger: false,
    };
  }

  return {
    isNationalityRequired: true,
    perPassenger: reqdParam.perPassenger,
  };
}

export function getAvailableAirlinesFromAirSearchResponse(response: AirSearchResponse): MetadataAirline[] {
  return sortBy(response.metadata?.availableAirlines ?? [], 'name');
}

export function getApplicableAlliancesFromAirSearchResponse(response: AirSearchResponse): Alliance[] {
  return response.metadata?.applicableAlliances ?? [];
}

export function getSegmentNodeAirportString(segmentNode: IAirSuggestion | IAirSuggestion[]): string {
  return Array.isArray(segmentNode)
    ? segmentNode
        .map((node) => node.code)
        .sort()
        .join(',')
    : segmentNode.code;
}

export function getTripTypeFromSegments(
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
): FlightBookingType {
  if (segments.length < 2) {
    return FlightBookingType.ONE_WAY;
  }

  if (segments.length > 2) {
    return FlightBookingType.MULTI_CITY;
  }

  const firstLegSummaryOrigin = getSegmentNodeAirportString(segments[0].origin);
  const firstLegSummaryDestination = getSegmentNodeAirportString(segments[0].destination);

  const secondLegSummaryOrigin = getSegmentNodeAirportString(segments[1].origin);
  const secondLegSummaryDestination = getSegmentNodeAirportString(segments[1].destination);

  if (firstLegSummaryDestination === secondLegSummaryOrigin && secondLegSummaryDestination === firstLegSummaryOrigin) {
    return FlightBookingType.ROUND_TRIP;
  }

  return FlightBookingType.MULTI_CITY;
}

export function getTripLabelForTripType(tripType: FlightBookingType): ICommonI18nMessage<object> {
  switch (tripType) {
    case FlightBookingType.ONE_WAY:
      return defineCommonMessage('One way');
    case FlightBookingType.ROUND_TRIP:
      return defineCommonMessage('Round trip');
    default:
      return defineCommonMessage('Multi-city');
  }
}

export const findSelectedSortOptionFromSortOptionList = <
  T extends IKeyLabelPairWithMetaInfo = IKeyLabelPairWithMetaInfo,
>(
  sortTypeOptionsToShow: T[],
  selectedSortType: SortOptionSortBy,
  shelfNumber: number | undefined,
): T => {
  const selectedOptions = sortTypeOptionsToShow.filter(({ backendKey }) => backendKey === selectedSortType);
  // Return the unique key found regardless of the view type
  if (selectedOptions.length === 1) {
    return selectedOptions[0];
  }
  // Otherwise find the particular Price related sort key from the shelf number in the URL
  const selectedOption = selectedOptions.find((option) => option.metaInfo?.shelfNumber === shelfNumber);
  if (selectedOption) {
    return selectedOption;
  }
  // Return the last price sort option as per the logic in BE: for shelf number greater than valid
  // shelf numbers, BE would sort the results as per the last valid price sort option
  return selectedOptions[selectedOptions.length - 1];
};

export const isAtpcoNdcItinerary = (itinSource: number): boolean => itinSource === IThirdPartySourceEnum.ATPCO_NDC;

export const isSouthwestItin = (val: ThirdPartySourceEnum): boolean => {
  return val === ThirdPartySourceEnum.SOUTHWEST;
};

export const hasJustDateChangedForSummaryForm = (
  prevSegments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
): boolean => {
  if (prevSegments.length !== segments.length) {
    return false;
  }

  const hasOnlyDateChange = segments.some((currSegment, segmentIndex) => {
    const prevSegment = prevSegments[segmentIndex];

    const currSegmentOrigin = getSegmentNodeAirportString(currSegment.origin);
    const prevSegmentOrigin = getSegmentNodeAirportString(prevSegment.origin);

    const currSegmentDestination = getSegmentNodeAirportString(currSegment.destination);
    const prevSegmentDestination = getSegmentNodeAirportString(prevSegment.destination);

    const currSegmentDate = currSegment.date;
    const prevSegmentDate = prevSegment.date;

    return (
      currSegmentOrigin === prevSegmentOrigin &&
      currSegmentDestination === prevSegmentDestination &&
      prevSegmentDate !== currSegmentDate
    );
  });

  return hasOnlyDateChange;
};

export const getPassengerSeatsForFlightIndex = (
  passengers: IAirSearchUrlPaxInfo[],
  currentSeatSelectionFlightIndex: number,
): string[] =>
  passengers.map((paxInfo) => {
    const paxSeats = paxInfo.seat || [];
    const selectedSeatForSegment = paxSeats.find(
      (seatInfo) => seatInfo.flightIndex === currentSeatSelectionFlightIndex,
    );

    return selectedSeatForSegment?.seatNumber || '';
  });

export const getSelectedBaggageForPax = (
  passengers: IAirSearchUrlPaxInfo[],
  paxIndex: number,
  baggageOptions: IBaggageOption[],
  legIndex: number,
): ISelectedBaggagePerPassenger | null => {
  const passengerBaggage = passengers[paxIndex]?.baggage;
  if (passengerBaggage && passengerBaggage.length > 0) {
    const baggageIndex = passengerBaggage.findIndex((preselectedBaggage) => preselectedBaggage.legIndex === legIndex);
    if (baggageIndex === -1) {
      return null;
    }
    const passengerSelectedBaggage = passengerBaggage[baggageIndex]?.baggageIds[0];
    const selectedBaggageOption = baggageOptions.find(
      (baggageOption) => baggageOption.baggageId === passengerSelectedBaggage,
    );
    if (!selectedBaggageOption) {
      return null;
    }
    return selectedBaggageOption;
  }
  return null;
};

export const getAllBaggageSelectedValues = (
  baggagesOptions: IBaggageOptions[],
  passengers: IAirSearchUrlPaxInfo[],
): IBaggageOptions[] =>
  baggagesOptions.map((baggageOption) => {
    const isPerPassenger = baggageOption.perPassenger;
    return {
      ...baggageOption,
      baggageInfos: baggageOption.baggageInfos.map((baggageInfo, index) => {
        const paxIndex = isPerPassenger ? index : 0;
        const selectedBaggage = getSelectedBaggageForPax(
          passengers,
          paxIndex,
          baggageInfo.baggageOptions,
          baggageOption.legIndex,
        );
        return { ...baggageInfo, selectedBaggage };
      }),
    };
  });

export const shouldShowFareChangeAlert = ({
  newRevalidateFare,
  oldRevalidateFare,
}: IShouldShowFareChangeAlertProps): boolean => {
  const priceChange = newRevalidateFare.subtract(oldRevalidateFare);
  const changeAmount = Math.abs(priceChange.getAmount());
  return changeAmount > 1;
};

export const getMergedPreBookAnswers = (
  customFieldEntityAnswer: EntityAnswer[],
  caseCodesEntityAnswer: EntityAnswer | null,
  preBookAnswers: PreBookAnswersResponse | null,
  preBookQuestionResponseId: string,
): PreBookAnswersResponse | null => {
  if (customFieldEntityAnswer.length) {
    return {
      preBookQuestionResponseId: preBookAnswers ? preBookAnswers.preBookQuestionResponseId : preBookQuestionResponseId,
      answers: preBookAnswers ? [...preBookAnswers.answers, ...customFieldEntityAnswer] : customFieldEntityAnswer,
    };
  }
  if (caseCodesEntityAnswer?.answers && caseCodesEntityAnswer.answers.length > 0) {
    return {
      preBookQuestionResponseId: preBookAnswers ? preBookAnswers.preBookQuestionResponseId : preBookQuestionResponseId,
      answers: preBookAnswers ? [...preBookAnswers.answers, caseCodesEntityAnswer] : [caseCodesEntityAnswer],
    };
  }
  return preBookAnswers;
};

export const updateSeatForPassengers = (
  existingPassengers: IAirSearchFormPaxInfo[],
  flightIndex: number,
  selectedSeats: string[],
): IAirSearchFormPaxInfo[] =>
  produce(existingPassengers, (draftPassengers) => {
    draftPassengers.forEach((passenger, passengerIndex) => {
      if (passenger.paxType === PassengerTypeEnum.INFANT_ON_LAP) {
        // eslint-disable-next-line no-param-reassign
        passenger.seat = [];
        return;
      }
      const paxSelectedSeat = selectedSeats[passengerIndex];
      let paxCurrentSeats = passenger.seat || [];
      const currentSeatIndex = paxCurrentSeats.findIndex((seat) => seat.flightIndex === flightIndex);
      if (currentSeatIndex > -1) {
        paxCurrentSeats[currentSeatIndex].seatNumber = paxSelectedSeat;
      } else {
        paxCurrentSeats.push({ seatNumber: paxSelectedSeat, flightIndex });
      }

      // Remove empty seat selections
      paxCurrentSeats = paxCurrentSeats.filter((seat) => !!seat.seatNumber);

      // eslint-disable-next-line no-param-reassign
      passenger.seat = paxCurrentSeats;
    });
  });

export const updateSelectedSeatsForPaxIndex = (
  currentSelectedSeats: string[],
  paxIndex: number,
  selectedSeatNumber: string,
): string[] =>
  produce(currentSelectedSeats, (draftSelectedSeats) => {
    // If any of the other passenger has already selected this seat, then
    // clear their selection.
    const existingPassengerIndexWithSameSeat = draftSelectedSeats.findIndex(
      (paxSeatNumber) => paxSeatNumber === selectedSeatNumber,
    );
    if (existingPassengerIndexWithSameSeat !== -1 && existingPassengerIndexWithSameSeat !== paxIndex) {
      draftSelectedSeats[existingPassengerIndexWithSameSeat] = '';
    }

    // For current passenger, remove existing selected seat and update to newly
    // selected seat. If passenger is selecting same seat, then reset their seat
    // selection.
    const paxCurrentSelectedSeat = draftSelectedSeats[paxIndex];
    draftSelectedSeats[paxIndex] = '';
    if (paxCurrentSelectedSeat !== selectedSeatNumber) {
      draftSelectedSeats[paxIndex] = selectedSeatNumber;
    }
  });

/** @deprecated */
export const getShowSeatSelectionOption = (carrier: Carrier): boolean => {
  const operatingAirlineCode = carrier.operating?.airline ?? '';
  if (airlinesWithNoSeatSelectionOption[operatingAirlineCode]) {
    return false;
  }
  return true;
};

export const getSeatMapAvailability = (
  isSeatmapAvailable: boolean,
  airSearchResponseManager: AirSearchResponseManager,
  flightSegmentIndex: number,
  seatSelectionWorkFlow: ISeatSelectionWorkflowEnum,
  itinIndex: number,
): ISeatmapAvailability => {
  const flightRestrictions = airSearchResponseManager.getFlightRestrictions(flightSegmentIndex);

  const itinSource = airSearchResponseManager.GetItinerarySource(itinIndex);
  const isSouthwest = isSouthwestItin(itinSource);

  let unavailableReason = ISeatmapUnavailableReasonEnum.Unknown;

  const isPreBookingSeatNotAllowed = flightRestrictions.includes(
    MetadataFlightRestrictionsRestriction.PRE_BOOKING_SEAT_NOT_ALLOWED,
  );
  const isPostBookingSeatNotAllowed = flightRestrictions.includes(
    MetadataFlightRestrictionsRestriction.POST_BOOKING_SEAT_NOT_ALLOWED,
  );
  const isSeatBookingNotAllowedDueToBrand = flightRestrictions.includes(
    MetadataFlightRestrictionsRestriction.SEAT_BOOKING_NOT_ALLOWED_DUE_TO_BRAND,
  );
  const isSeatBookingNotAllowedOpenSeating = flightRestrictions.includes(
    MetadataFlightRestrictionsRestriction.SEAT_BOOKING_NOT_ALLOWED_OPEN_SEATING,
  );
  const isSeatBookingNotAllowed = flightRestrictions.includes(
    MetadataFlightRestrictionsRestriction.SEAT_BOOKING_NOT_ALLOWED,
  );
  const isCodeShareSeatBookingNotAllowed = flightRestrictions.includes(
    MetadataFlightRestrictionsRestriction.SEAT_BOOKING_NOT_ALLOWED_DUE_TO_CODESHARE,
  );

  if (seatSelectionWorkFlow === ISeatSelectionWorkflowEnum.PreBooking) {
    // Pre booking checks
    if (isSouthwest || isSeatBookingNotAllowedOpenSeating) {
      unavailableReason = ISeatmapUnavailableReasonEnum.OpenSeating;
    } else if (isSeatBookingNotAllowedDueToBrand) {
      unavailableReason = ISeatmapUnavailableReasonEnum.SeatBookingNotAllowedDueToBrand;
    } else if (isSeatBookingNotAllowed) {
      unavailableReason = ISeatmapUnavailableReasonEnum.SeatBookingNotAllowed;
    } else if (isPreBookingSeatNotAllowed) {
      unavailableReason = ISeatmapUnavailableReasonEnum.PreCheckoutSelectionNotAllowed;
    } else if (isCodeShareSeatBookingNotAllowed) {
      unavailableReason = ISeatmapUnavailableReasonEnum.SeatBookingNotAllowedDueToCodeShare;
    } else if (!isSeatmapAvailable) {
      unavailableReason = ISeatmapUnavailableReasonEnum.NoSeatMap;
    }
  } else {
    // Post booking checks
    // eslint-disable-next-line
    if (isSouthwest || isSeatBookingNotAllowedOpenSeating) {
      unavailableReason = ISeatmapUnavailableReasonEnum.OpenSeating;
    } else if (isSeatBookingNotAllowedDueToBrand) {
      unavailableReason = ISeatmapUnavailableReasonEnum.SeatBookingNotAllowedDueToBrand;
    } else if (isSeatBookingNotAllowed) {
      unavailableReason = ISeatmapUnavailableReasonEnum.SeatBookingNotAllowed;
    } else if (isPostBookingSeatNotAllowed) {
      unavailableReason = ISeatmapUnavailableReasonEnum.PostCheckoutSelectionNotAllowed;
    } else if (isCodeShareSeatBookingNotAllowed) {
      unavailableReason = ISeatmapUnavailableReasonEnum.SeatBookingNotAllowedDueToCodeShare;
    } else if (!isSeatmapAvailable) {
      unavailableReason = ISeatmapUnavailableReasonEnum.NoSeatMap;
    }
  }

  if (unavailableReason === ISeatmapUnavailableReasonEnum.Unknown) {
    return { available: true };
  }

  return { available: false, unavailableReason };
};

/**
 * This function is not being used on mobile.
 */
export const getDefaultPassenger = (
  paxInfo: IAirSearchUrlPaxInfo,
  userId: string | undefined,
): IAirSearchUrlPaxInfo => ({
  paxType: paxInfo.paxType,
  paxAge: paxInfo.paxAge,
  userId,
  readOnly: paxInfo.readOnly,
});

export const getFlightIndicesWithInvalidSeats = (
  passengers: IAirSearchUrlPaxInfo[],
  airSeatMapResponseManager: AirSeatMapResponseManager,
): number[] => {
  const flightIndices = new Set<number>();

  passengers.forEach((passenger) => {
    const paxSelectedSeats = passenger.seat || [];

    if (airSeatMapResponseManager) {
      paxSelectedSeats.forEach((seatInfo) => {
        const { flightIndex, seatNumber: paxSeatNumber } = seatInfo;

        if (!paxSeatNumber) {
          return;
        }
        if (!passenger.userId) {
          return;
        }

        const isSeatAvailable = airSeatMapResponseManager?.getIsSeatAvailableForPaxAndSeatNumber(
          passenger.userId,
          flightIndex,
          paxSeatNumber,
        );

        if (!isSeatAvailable) {
          flightIndices.add(flightIndex);
        }
      });
    }
  });

  return [...flightIndices];
};

export { shouldEditSeatsBySupport, shouldEditTravelerDetailsBySupport } from './editFlightUtils';
export { default as getCombinedFareRules } from './getCombinedFareRules';
export type { RuleWithFareBasisCode, TransformedRules } from './getCombinedFareRules';
export { getFlightNumberDisplayText } from './getFlightNumberDisplayText';
export { default as getHoldBookingDeadline } from './getHoldBookingDeadline';

export const ungroupPaxInfos = (paxInfos: PaxInfo[]) => {
  const paxInfosUngrouped: PaxInfo[] = [];

  paxInfos.forEach((el) => {
    for (let i = 1; i <= el.numPax; i += 1) {
      paxInfosUngrouped.push({ ...el, numPax: 1 });
    }
  });

  return paxInfosUngrouped;
};

export const getIfDelayedInvoicingAndCorporateCardsApplicable = (
  travelers: (AirPnrTravelerInfo | ITraveler | undefined)[],
): boolean =>
  !!travelers.length &&
  travelers.every((traveler) => {
    if (!traveler) {
      return false;
    }

    if ('persona' in traveler) {
      return (
        traveler.persona === PersonaEnum.GUEST ||
        traveler.persona === PersonaEnum.EMPLOYEE ||
        traveler.persona === PersonaEnum.ADHOC
      );
    }

    if ('traveler' in traveler) {
      return (
        traveler.traveler?.persona === PersonaEnum.GUEST ||
        traveler.traveler?.persona === PersonaEnum.EMPLOYEE ||
        traveler.traveler?.persona === PersonaEnum.ADHOC
      );
    }

    return false;
  });

export const GetTotalEmissionsForPassengers = (
  totalEmissionsPerPassenger: number | null,
  countWithoutInfantSeatOnLap: number,
): null | number => {
  const totalEmission = totalEmissionsPerPassenger ? totalEmissionsPerPassenger * countWithoutInfantSeatOnLap : null;
  return totalEmission;
};

export const isAddTravelerAvailable = (
  airMultipaxSimplifiedVariables: IAirMultipaxSimplifiedVariables,
  travelerPersona: PersonaEnum,
): boolean => {
  const isPersonal = travelerPersona === PersonaEnum.PERSONAL;
  const isEnabledForLeisure = airMultipaxSimplifiedVariables.enable_multipax_leisure;

  if (isPersonal && isEnabledForLeisure) {
    return true;
  }

  const isEmployee = travelerPersona === PersonaEnum.EMPLOYEE;
  const isEnabledForEmployee = airMultipaxSimplifiedVariables.enable_multipax_employee;

  if (isEmployee && isEnabledForEmployee) {
    return true;
  }

  const isGuest = travelerPersona === PersonaEnum.GUEST;
  const isEnabledForGuest = airMultipaxSimplifiedVariables.enable_multipax_guest;

  if (isGuest && isEnabledForGuest) {
    return true;
  }

  return false;
};

export const canJoinAALoyalty = (airline: string, loyaltyInfo: ILoyaltyInfo[]): boolean =>
  airline === 'AA' && loyaltyInfo.find(({ issuedBy }) => issuedBy === 'AA') === undefined;

const FacilitySeatTypeToFacilityRowType: Record<SeatSectionSeatType, FacilityType> = {
  [SeatSectionSeatType.SEAT]: FacilityType.UNKNOWN_ROW_FACILITY,
  [SeatSectionSeatType.NO_SEAT]: FacilityType.UNKNOWN_ROW_FACILITY,
  [SeatSectionSeatType.BULKHEAD]: FacilityType.BULKHEAD,
  [SeatSectionSeatType.STAIRS]: FacilityType.STAIRS,
  [SeatSectionSeatType.TABLE]: FacilityType.TABLE,
  [SeatSectionSeatType.LAVATORY]: FacilityType.LAVATORY,
  [SeatSectionSeatType.BAR]: FacilityType.BAR,
  [SeatSectionSeatType.CLOSET]: FacilityType.CLOSET,
  [SeatSectionSeatType.AIR_PHONE]: FacilityType.AIR_PHONE,
  [SeatSectionSeatType.EXIT_DOOR]: FacilityType.EXIT_DOOR,
  [SeatSectionSeatType.EMERGENCY_EXIT]: FacilityType.EMERGENCY_EXIT,
  [SeatSectionSeatType.GALLEY]: FacilityType.GALLEY,
  [SeatSectionSeatType.LUGGAGE_STORAGE]: FacilityType.LUGGAGE_STORAGE,
  [SeatSectionSeatType.STORAGE_SPACE]: FacilityType.STORAGE_SPACE,
  [SeatSectionSeatType.UNRECOGNIZED]: FacilityType.UNRECOGNIZED,
};

export const getFacilityTypeFromSeatType = (seatType: SeatSectionSeatType): FacilityType =>
  FacilitySeatTypeToFacilityRowType[seatType];

export const getSpanMatchingFirstSeat = (seatGroupInfo: ISeat[]): number => {
  if (seatGroupInfo.length === 0) {
    return 0;
  }
  let span = 0;
  let seat = seatGroupInfo[0];
  const facilitySeatType = seat.type;
  while (seat.type === facilitySeatType) {
    span += 1;
    if (span < seatGroupInfo.length) {
      seat = seatGroupInfo[span];
    } else {
      break;
    }
  }
  return span;
};

export const isFacilitySeat = (type: SeatSectionSeatType): boolean =>
  ![SeatSectionSeatType.SEAT, SeatSectionSeatType.NO_SEAT, SeatSectionSeatType.UNRECOGNIZED].includes(type);

export const getCabinMultiDeckInfo = (cabinSections: ICabinSectionRowsWithSeatGroup[]): ICabinSectionMultiDeck => {
  const otherDeckCabinSections: ICabinSectionRowsWithSeatGroup[] = [];
  const mainDeckCabinSections: ICabinSectionRowsWithSeatGroup[] = [];

  const getMainDeckLocation = () => {
    cabinSections.forEach((cabinSection) => {
      const includesMainDeck = cabinSection.locations.includes(AirSeatMapLocation.MAIN_DECK);
      if (includesMainDeck) {
        mainDeckCabinSections.push(cabinSection);
      } else {
        otherDeckCabinSections.push(cabinSection);
      }
    });
    const lowerDeckIsMainDeck =
      otherDeckCabinSections.length > 0 && otherDeckCabinSections[0].locations.includes(AirSeatMapLocation.UPPER_DECK);
    if (lowerDeckIsMainDeck) {
      return AirSeatMapLocation.LOWER_DECK;
    }
    return AirSeatMapLocation.UPPER_DECK;
  };

  const mainDeckLocation = getMainDeckLocation();

  return {
    lowerDeckCabinSections:
      mainDeckLocation === AirSeatMapLocation.LOWER_DECK ? mainDeckCabinSections : otherDeckCabinSections,
    upperDeckCabinSections:
      mainDeckLocation === AirSeatMapLocation.UPPER_DECK ? mainDeckCabinSections : otherDeckCabinSections,
    isMultiLevel: mainDeckCabinSections.length > 0 && otherDeckCabinSections.length > 0,
    mainDeckLocation,
  };
};

export const getCabinSectionsForLocation = (
  selectedDeckLocation: AirSeatMapLocation.UPPER_DECK | AirSeatMapLocation.LOWER_DECK,
  cabinMultiDeckInfo: ICabinSectionMultiDeck,
): ICabinSectionRowsWithSeatGroup[] => {
  if (selectedDeckLocation === AirSeatMapLocation.LOWER_DECK) {
    return cabinMultiDeckInfo.lowerDeckCabinSections;
  }
  return cabinMultiDeckInfo.upperDeckCabinSections;
};

export const getSeatMapLegendTypeForSeat = (seat: ISeat): SeatMapLegendType => {
  if (!seat.isAvailable) {
    return SeatMapLegendType.UNAVAILABLE;
  }

  if (seat.isExtraLegroom) {
    return SeatMapLegendType.PREMIUM;
  }

  if (seat.isWheelchairAccessible) {
    return SeatMapLegendType.WHEELCHAIR_ACCESSIBLE;
  }

  if (seat.isPreferred) {
    return SeatMapLegendType.PREFERRED;
  }

  if (seat.isBassinet) {
    return SeatMapLegendType.BASSINET;
  }

  return SeatMapLegendType.AVAILABLE;
};

const getSeatMapLegendTypesFromCabinSectionsNoMemo = (
  cabinSections: ICabinSectionRowsWithSeatGroup[],
): Set<SeatMapLegendType> => {
  const seatTypeSet = new Set<SeatMapLegendType>();
  cabinSections.filter((cabinSection) =>
    cabinSection.rows.forEach((row) => {
      row.seatGroups.forEach((seatGroup) =>
        seatGroup.forEach((seat) => {
          seatTypeSet.add(getSeatMapLegendTypeForSeat(seat));
        }),
      );
    }),
  );
  return seatTypeSet;
};

export const getSeatMapLegendTypesFromCabinSections = memoize(getSeatMapLegendTypesFromCabinSectionsNoMemo);

const typesForFilteringBasedOnCabinSectionsContent = [
  SeatMapLegendType.PREMIUM,
  SeatMapLegendType.PREFERRED,
  SeatMapLegendType.WHEELCHAIR_ACCESSIBLE,
  SeatMapLegendType.UNAVAILABLE,
  SeatMapLegendType.BASSINET,
  SeatMapLegendType.LAVATORY,
  SeatMapLegendType.BAR,
  SeatMapLegendType.CLOSET,
  SeatMapLegendType.GALLEY,
] as const;

export const filterLegendLabelsBySeatMapLegendTypes = (
  legendLabels: Map<SeatMapLegendType, string>,
  cabinSections: ICabinSectionRowsWithSeatGroup[],
): Map<SeatMapLegendType, string> => {
  const filteredLegendLabels = new Map(legendLabels);
  const seatTypeSet = getSeatMapLegendTypesFromCabinSections(cabinSections);

  typesForFilteringBasedOnCabinSectionsContent.forEach((type) => {
    if (!seatTypeSet.has(type)) {
      filteredLegendLabels.delete(type);
    }
  });

  if (!seatTypeSet.has(SeatMapLegendType.BASSINET)) {
    filteredLegendLabels.delete(SeatMapLegendType.BASSINET);
  }

  return filteredLegendLabels;
};

export const getAirlineNameFromAirlineCode = (code: string, airlineInfo: AirlineInfo[]) => {
  return airlineInfo.find((airline) => airline.airlineCode === code)?.airlineName;
};

export const getLocationDetailsFromAirportCode = (code: string, airportInfo: AirportInfo[]) => {
  const requiredAirport = airportInfo.find((airport) => airport.airportCode === code);
  return {
    code,
    airportName: requiredAirport?.airportName ?? '',
    cityName: requiredAirport?.cityName ?? '',
    countryName: requiredAirport?.countryName ?? '',
  };
};

export function getItinSourceString(source: number): string {
  return ThirdPartySourceEnum[source] ?? '';
}

export function getAllMarketingAirlinesInGivenTicket(
  airSearchResponseManager: AirSearchResponseManager,
  flightPnr: ITripPnr,
): string[] {
  const allLegsIndexes = flightPnr.air?.legInfo?.map?.((l) => l.legIndex) || [];
  const itinerariresForAllLegIndices = airSearchResponseManager.GetItinerariesWithSameTicketNum(allLegsIndexes);

  const allAirlines = new Set<string>();

  itinerariresForAllLegIndices.forEach((itinerary) => {
    const airlinesInItinerary = itinerary.summary.airlines;
    airlinesInItinerary.forEach((airline) => {
      allAirlines.add(airline.marketing.airline);
    });
  });

  return Array.from(allAirlines.values());
}

export interface IIsAncillarySelectedForTypeLegIndexFlightIndexInput {
  selectedAncillaries: AirRevalidateItineraryRequestTravelerInfoOtherAncillary[];
  type: AirAncillariesResponseAncillaryAncillaryType;
  flightIndex: number;
  legIndex: number;
}

export function getSelectedAncillaryIndexForTypeLegIndexFlightIndex({
  selectedAncillaries,
  type,
  flightIndex,
  legIndex,
}: IIsAncillarySelectedForTypeLegIndexFlightIndexInput): number {
  return selectedAncillaries.findIndex(
    (ancillaryInfo) =>
      ancillaryInfo.type === type && ancillaryInfo.flightIndex === flightIndex && ancillaryInfo.legIndex === legIndex,
  );
}

export function isAncillarySelectedForTypeLegIndexFlightIndex({
  selectedAncillaries,
  type,
  flightIndex,
  legIndex,
}: IIsAncillarySelectedForTypeLegIndexFlightIndexInput): boolean {
  return getSelectedAncillaryIndexForTypeLegIndexFlightIndex({ selectedAncillaries, type, flightIndex, legIndex }) > -1;
}

export function toggleSelectedAncillaryForTypeLegIndexFlightIndex({
  selectedAncillaries,
  type,
  flightIndex,
  legIndex,
}: IIsAncillarySelectedForTypeLegIndexFlightIndexInput): AirRevalidateItineraryRequestTravelerInfoOtherAncillary[] {
  const isAlreadySelected = isAncillarySelectedForTypeLegIndexFlightIndex({
    selectedAncillaries,
    type,
    flightIndex,
    legIndex,
  });

  if (isAlreadySelected) {
    return selectedAncillaries.filter(
      (ancillaryInfo) =>
        !(
          ancillaryInfo.type === type &&
          ancillaryInfo.flightIndex === flightIndex &&
          ancillaryInfo.legIndex === legIndex
        ),
    );
  }

  return [...selectedAncillaries, { type, flightIndex, legIndex }];
}

/**
 * This is required because sometimes in url parsing numbers are converted to string
 */
export function sanitizeNumberTypesForAncillary(
  ancillaries: AirRevalidateItineraryRequestTravelerInfoOtherAncillary[],
): AirRevalidateItineraryRequestTravelerInfoOtherAncillary[] {
  return ancillaries.map((ancillaryInfo) => ({
    type: Number(ancillaryInfo.type),
    flightIndex: Number(ancillaryInfo.flightIndex),
    legIndex: Number(ancillaryInfo.legIndex),
  }));
}

export interface IGetOtherAncillaryPriceInput {
  type: AirAncillariesResponseAncillaryAncillaryType;
  flightIndex: number;
  legIndex: number;
  ancillaryResponseManager: AirAncillaryResponseManager;
  zeroMoney: MoneyUtil;
}

export function getOtherAncillaryPrice({
  type,
  flightIndex,
  legIndex,
  ancillaryResponseManager,
  zeroMoney,
}: IGetOtherAncillaryPriceInput): MoneyUtil {
  const ancillaryDetailsInfo = ancillaryResponseManager.getAncillaryDetailInfo(type, flightIndex, legIndex);
  if (ancillaryDetailsInfo?.fare) {
    const fareMoney = MoneyUtil.parse(ancillaryDetailsInfo.fare);
    return fareMoney.isZero() ? zeroMoney : fareMoney;
  }

  return zeroMoney;
}

interface IGetNamesOfSelectedAncillariesInput {
  selectedAncillaries: AirRevalidateItineraryRequestTravelerInfoOtherAncillary[];
  ancillaryResponseManager: AirAncillaryResponseManager;
}

export function getNamesOfSelectedAncillaries({
  selectedAncillaries,
  ancillaryResponseManager,
}: IGetNamesOfSelectedAncillariesInput): string[] {
  const selectedAncillaryTypes = selectedAncillaries.map((ancillaryInfo) => ancillaryInfo.type);
  const uniqSelectedAncillaryTypes = uniq(selectedAncillaryTypes);

  return uniqSelectedAncillaryTypes.map((ancillaryType) => {
    const ancillaryInfo = ancillaryResponseManager.getAncillaryInfoByType(ancillaryType);
    return ancillaryInfo?.displayName || '';
  });
}

export function getBreadcrumbNameForSegmentNode(segmentNode: IAirSuggestion | IAirSuggestion[]): string {
  const segmentNodeArray = getArrayFromArrayOrNode(segmentNode);
  return segmentNodeArray.map((s) => s.code).join(', ');
}

export function getBreadcrumbsForAirSegments(
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
): IBreadcrumb[] {
  return segments.map((flightSegment) => {
    const departure = getBreadcrumbNameForSegmentNode(flightSegment.origin);
    const arrival = getBreadcrumbNameForSegmentNode(flightSegment.destination);

    return { departure, arrival };
  });
}

export const updateAncillariesForPassengers = (
  existingPassengers: IAirSearchUrlPaxInfo[],
  updatedAncillary: AirRevalidateItineraryRequestTravelerInfoOtherAncillary[],
): IAirSearchUrlPaxInfo[] =>
  produce(existingPassengers, (draftPassengers) => {
    draftPassengers[0].ancillary = updatedAncillary;
  });

interface IConvertAncillaryTypeEnumInput {
  selectedAncillaries: AirRevalidateItineraryRequestTravelerInfoOtherAncillary[] | undefined;
  pnrId: string;
  tripDetailsResponseManager: V2TripDetailsResponseManager;
}

export function convertAncillaryTypeEnum({
  selectedAncillaries,
  pnrId,
  tripDetailsResponseManager,
}: IConvertAncillaryTypeEnumInput): OtherAncillaryDetail[] | undefined {
  const otherAncillaries = selectedAncillaries?.map((ancillary) => {
    return {
      ancillaryType: OtherAncillaryTypeMap[ancillary.type],
      flightIndex: tripDetailsResponseManager.getRelativeFlightIndexFromFlightIndex(
        ancillary.legIndex,
        ancillary.flightIndex,
        pnrId,
      ),
      legIndex: ancillary.legIndex,
    };
  });

  return otherAncillaries;
}
