import React from 'react';
import get from 'lodash/get';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const PendingClock = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.6595 10.2711C19.6595 15.5592 15.3727 19.846 10.0846 19.846C4.79658 19.846 0.509766 15.5592 0.509766 10.2711C0.509766 4.9831 4.79658 0.696289 10.0846 0.696289C15.3727 0.696289 19.6595 4.9831 19.6595 10.2711ZM10.0846 17.1103C13.8618 17.1103 16.9238 14.0483 16.9238 10.2711C16.9238 6.49397 13.8618 3.43196 10.0846 3.43196C6.30745 3.43196 3.24544 6.49397 3.24544 10.2711C3.24544 14.0483 6.30745 17.1103 10.0846 17.1103Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M10.085 14.8308C12.6031 14.8308 14.6444 12.7895 14.6444 10.2714C14.6444 7.75325 12.6031 5.71191 10.085 5.71191V14.8308Z", fill: themeEnrichedPrimaryColor })));
};
PendingClock.defaultProps = defaultIconProps;
export default PendingClock;
