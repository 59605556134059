/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Seat location preference.
 * @export
 * @interface SeatLocationPref
 */
export interface SeatLocationPref {
    /**
     * 
     * @type {string}
     * @memberof SeatLocationPref
     * @deprecated
     */
    cabin?: SeatLocationPrefCabinEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SeatLocationPref
     */
    cabins: Array<SeatLocationPrefCabinsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof SeatLocationPref
     */
    isBulkHeadPref: boolean;
    /**
     * 
     * @type {number}
     * @memberof SeatLocationPref
     * @deprecated
     */
    maxFlightDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof SeatLocationPref
     */
    maxFlightDurationInHours: number;
    /**
     * 
     * @type {string}
     * @memberof SeatLocationPref
     */
    position: SeatLocationPrefPositionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SeatLocationPrefCabinEnum {
    UnknownCabin = 'UNKNOWN_CABIN',
    Economy = 'ECONOMY',
    PremiumEconomy = 'PREMIUM_ECONOMY',
    Business = 'BUSINESS',
    First = 'FIRST',
    Unrecognized = 'UNRECOGNIZED'
}
/**
    * @export
    * @enum {string}
    */
export enum SeatLocationPrefCabinsEnum {
    UnknownCabin = 'UNKNOWN_CABIN',
    Economy = 'ECONOMY',
    PremiumEconomy = 'PREMIUM_ECONOMY',
    Business = 'BUSINESS',
    First = 'FIRST',
    Unrecognized = 'UNRECOGNIZED'
}
/**
    * @export
    * @enum {string}
    */
export enum SeatLocationPrefPositionEnum {
    UnknownPosition = 'UNKNOWN_POSITION',
    Aisle = 'AISLE',
    Window = 'WINDOW',
    AisleOrWindow = 'AISLE_OR_WINDOW',
    Unrecognized = 'UNRECOGNIZED'
}



