import { useCallback, useEffect, useState, Dispatch } from 'react';
import type { LexicalEditor } from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $isHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $patchStyleText, $setBlocksType } from '@lexical/selection';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  KEY_MODIFIER_COMMAND,
  COMMAND_PRIORITY_NORMAL,
} from 'lexical';
import { Global, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { IS_APPLE } from './utils/shared';

import DropDown, { DropDownItem } from './ui/DropDown';
import DropdownColorPicker from './ui/DropdownColorPicker';
import { getSelectedNode } from './utils/getSelectedNode';
import { sanitizeUrl, DEFAULT_LINK_URL } from './utils/url';
import { getLinkKeyboardHandler, getLinkAttributes } from './utils/link';
import { EditorToolbarCustomizationProps } from './types';
import {
  IconLink,
  IconListOL,
  IconListUL,
  IconTextCenter,
  IconTextJustify,
  IconTextLeft,
  IconTextParagraph,
  IconTextRight,
  IconTypeBold,
  IconTypeH1,
  IconTypeH2,
  IconTypeH3,
  IconTypeH4,
  IconTypeH5,
  IconTypeItalic,
  IconTypeStrikethrough,
  IconTypeUnderline,
} from './icons';
import { useBlocksTheme } from '../utils/styling/useBlocksTheme';
import { icon_dropdown, icon_style } from './icons/IconStyle.style';

const blockTypeToBlockName = {
  paragraph: 'Normal',
  bullet: 'Bulleted List',
  number: 'Numbered List',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
};

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['Arial', 'Arial'],
  ['Courier New', 'Courier New'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana'],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['13px', '13px'],
  ['14px', '14px'],
  ['15px', '15px'],
  ['16px', '16px'],
  ['17px', '17px'],
  ['18px', '18px'],
  ['19px', '19px'],
  ['20px', '20px'],
  ['21px', '21px'],
  ['22px', '22px'],
  ['23px', '23px'],
  ['24px', '24px'],
];

function dropDownActiveClass(active: boolean) {
  if (active) return 'active RTEDropdown-item-active';
  return '';
}

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const { t: tt } = useTranslation('WEB');
  const { typography } = useBlocksTheme();

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <>
      <Global
        styles={css({
          '.editor-paragraph': {
            ...typography.body1.default,
          },
          '.editor-h1': typography.header1.default,
          '.editor-h2': typography.header2.default,
          '.editor-h3': typography.header3.default,
          '.editor-h4': typography.header4.default,
          '.editor-h5': typography.header5.default,
        })}
      />
      <DropDown
        disabled={disabled}
        buttonClassName="toolbar-item block-controls"
        buttonIconClassName={`icon block-type ${blockType}`}
        buttonLabel={blockTypeToBlockName[blockType]}
        buttonAriaLabel={tt('Formatting options for text style')}
      >
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'paragraph')}`} onClick={formatParagraph}>
          <IconTextParagraph css={icon_dropdown} />
          <span className="text">{tt('Normal')}</span>
        </DropDownItem>
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'bullet')}`} onClick={formatBulletList}>
          <IconListUL css={icon_dropdown} />
          <span className="text">{tt('Bullet List')}</span>
        </DropDownItem>
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'number')}`} onClick={formatNumberedList}>
          <IconListOL css={icon_dropdown} />
          <span className="text">{tt('Numbered List')}</span>
        </DropDownItem>
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h1')}`} onClick={() => formatHeading('h1')}>
          <IconTypeH1 css={icon_dropdown} />
          <span className="text">{tt('Heading 1')}</span>
        </DropDownItem>
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h2')}`} onClick={() => formatHeading('h2')}>
          <IconTypeH2 css={icon_dropdown} />
          <span className="text">{tt('Heading 2')}</span>
        </DropDownItem>
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h3')}`} onClick={() => formatHeading('h3')}>
          <IconTypeH3 css={icon_dropdown} />
          <span className="text">{tt('Heading 3')}</span>
        </DropDownItem>
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h4')}`} onClick={() => formatHeading('h4')}>
          <IconTypeH4 css={icon_dropdown} />
          <span className="text">{tt('Heading 4')}</span>
        </DropDownItem>
        <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h5')}`} onClick={() => formatHeading('h5')}>
          <IconTypeH5 css={icon_dropdown} />
          <span className="text">{tt('Heading 5')}</span>
        </DropDownItem>
      </DropDown>
    </>
  );
}

function Divider(): JSX.Element {
  return <div className="divider" />;
}

const FontDropDown = ({
  editor,
  value,
  fontStyle,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  fontStyle: string;
  disabled?: boolean;
}): JSX.Element => {
  const { t: tt } = useTranslation('WEB');
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [fontStyle]: option,
          });
        }
      });
    },
    [editor, fontStyle],
  );

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={`toolbar-item ${fontStyle}`}
      buttonLabel={value}
      buttonIconClassName={fontStyle === 'font-family' ? 'icon block-type font-family' : ''}
      buttonAriaLabel={
        fontStyle === 'font-family' ? tt('Formatting options for font family') : tt('Formatting options for font size')
      }
    >
      {(fontStyle === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(([option, text]) => (
        <DropDownItem
          className={`item ${dropDownActiveClass(value === option)} ${
            fontStyle === 'font-size' ? 'fontsize-item' : ''
          }`}
          onClick={() => handleClick(option)}
          key={option}
        >
          <span className="text">{text}</span>
        </DropDownItem>
      ))}
    </DropDown>
  );
};

type ToolbarProps = {
  customizationProps: Required<EditorToolbarCustomizationProps>;
  setIsLinkEditMode: Dispatch<boolean>;
};

const Toolbar = ({
  setIsLinkEditMode,
  customizationProps: {
    showTypography,
    showFontFamily,
    showFontSize,
    showFontStyle,
    showTextForegroundColor,
    showTextBackgroundColor,
    showAlignment,
  },
}: ToolbarProps): JSX.Element => {
  const { t: tt } = useTranslation('WEB');
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [fontSize, setFontSize] = useState<string>('15px');
  const [fontColor, setFontColor] = useState<string>('#000');
  const [bgColor, setBgColor] = useState<string>('#fff');
  const [fontFamily, setFontFamily] = useState<string>('Arial');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      setIsLink($isLinkNode(parent) || $isLinkNode(node));

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = (parentList ?? element).getListType();
          setBlockType(type as keyof typeof blockTypeToBlockName);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }

      // Handle buttons
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));
      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'));
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
    );
  }, [$updateToolbar, activeEditor, editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      getLinkKeyboardHandler(activeEditor, isLink, setIsLinkEditMode),
      COMMAND_PRIORITY_NORMAL,
    );
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [activeEditor],
  );

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText],
  );

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ 'background-color': value });
    },
    [applyStyleText],
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, getLinkAttributes(sanitizeUrl(DEFAULT_LINK_URL)));
    } else {
      setIsLinkEditMode(false);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink, setIsLinkEditMode]);

  return (
    <div className="toolbar">
      {showTypography && blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown disabled={!isEditable} blockType={blockType} editor={editor} />
          <Divider />
        </>
      )}
      {showFontFamily && (
        <FontDropDown disabled={!isEditable} fontStyle="font-family" value={fontFamily} editor={editor} />
      )}
      {showFontSize && <FontDropDown disabled={!isEditable} fontStyle="font-size" value={fontSize} editor={editor} />}
      {(showFontFamily || showFontSize) && <Divider />}
      {showFontStyle && (
        <>
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
            title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
            type="button"
            aria-label={`Format text as bold. Shortcut: ${IS_APPLE ? '⌘B' : 'Ctrl+B'}`}
          >
            <IconTypeBold css={icon_style} />
          </button>
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
            title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
            type="button"
            aria-label={`Format text as italics. Shortcut: ${IS_APPLE ? '⌘I' : 'Ctrl+I'}`}
          >
            <IconTypeItalic css={icon_style} />
          </button>
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
            title={IS_APPLE ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}
            type="button"
            aria-label={`Format text to underlined. Shortcut: ${IS_APPLE ? '⌘U' : 'Ctrl+U'}`}
          >
            <IconTypeUnderline css={icon_style} />
          </button>
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}
            className={`toolbar-item spaced ${isStrikethrough ? 'active' : ''}`}
            title="Strikethrough"
            type="button"
            aria-label={tt('Format text to strikethrough')}
          >
            <IconTypeStrikethrough css={icon_style} />
          </button>
          <button
            disabled={!isEditable}
            onClick={insertLink}
            className={`toolbar-item spaced ${isLink ? 'active' : ''}`}
            aria-label={tt('Insert link')}
            title="Insert link"
            type="button"
          >
            <IconLink css={icon_style} />
          </button>
        </>
      )}
      {showTextForegroundColor && (
        <DropdownColorPicker
          disabled={!isEditable}
          buttonClassName="toolbar-item color-picker"
          buttonAriaLabel={tt('Formatting text color')}
          buttonIconClassName="icon font-color"
          color={fontColor}
          onChange={onFontColorSelect}
          title={tt('Formatting text color')}
        />
      )}
      {showTextBackgroundColor && (
        <DropdownColorPicker
          disabled={!isEditable}
          buttonClassName="toolbar-item color-picker"
          buttonAriaLabel={tt('Formatting background color')}
          buttonIconClassName="icon bg-color"
          color={bgColor}
          onChange={onBgColorSelect}
          title={tt('Formatting background color')}
        />
      )}
      {(showFontStyle || showTextForegroundColor || showTextBackgroundColor) && <Divider />}
      {showAlignment && (
        <>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
            }}
            className="toolbar-item spaced"
            aria-label={tt('Left Align')}
          >
            <IconTextLeft css={icon_style} />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
            }}
            className="toolbar-item spaced"
            aria-label={tt('Center Align')}
          >
            <IconTextCenter css={icon_style} />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
            }}
            className="toolbar-item spaced"
            aria-label={tt('Right Align')}
          >
            <IconTextRight css={icon_style} />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
            }}
            className="toolbar-item"
            aria-label={tt('Justify Align')}
          >
            <IconTextJustify css={icon_style} />
          </button>
        </>
      )}
    </div>
  );
};

export default Toolbar;
