import getDateDiff from '../getDateDiff';
import minutesToDurationString from '../minutesToDurationString';
import getDateTimeDiff from '../getDateTimeDiff';

const getDurationInDaysOrHours = (date1: string, date2: string, dateFormat?: string): string => {
  let time = '';
  const dateDiff = Math.abs(getDateDiff(date1, date2, dateFormat));
  time = `${dateDiff} day`;
  if (dateDiff === 0) {
    const dateTimeDiff = getDateTimeDiff(date2, date1, dateFormat);
    time = minutesToDurationString(dateTimeDiff);
  }
  return time;
};

export default getDurationInDaysOrHours;
