import { BookingFlowEnum } from 'obt-common';
import useUrlAndStorageState from './useUrlAndStorageState';

interface IProps {
  isAdhocBooking: boolean;
}

const useHandleToggleAdhocBooking = ({ isAdhocBooking }: IProps) => {
  const { setUrlState } = useUrlAndStorageState();

  const handleToggleAdhocBooking = (): void => {
    setUrlState(
      { bookingFlow: isAdhocBooking ? BookingFlowEnum.TRAVELER : BookingFlowEnum.ADHOC },
      { overrideAllQueryParams: false },
    );
  };

  return { handleToggleAdhocBooking };
};

export default useHandleToggleAdhocBooking;
