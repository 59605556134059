import { useEffect, useState } from 'react';
import logger from '../../utils/logger';
import { useLoggedInUser, useLoggedInUserBasicInfo, useLoggedInUserId } from '../../providers/AuthProvider';
import { RoleInfoTypeEnum } from '../../types/api/v1/obt/profile/role/roles_info';
import getApplicationId from '../../core/applicationId/getApplicationId';
import type { TelemetrySource } from '../constants';
import { TelemetryType } from '../constants';
import type { CommonTelemetry } from '../types/baseType';
import type { Traits } from '../types/optionalData';
import type { ErrorTelemetry, EventTelemetry, SurfaceTelemetry, UserTelemetry } from '../types/telemetryTypes';
import type { ITelemetryContext } from './useTelemetry';
import appType from './appType';
import { useOrganizationReadBasicInfoQuery } from '../../queries';

type QueueDataType =
  | { type: TelemetryType.User; data: UserTelemetry }
  | { type: TelemetryType.Error; data: ErrorTelemetry }
  | { type: TelemetryType.Event; data: EventTelemetry }
  | { type: TelemetryType.Surface; data: SurfaceTelemetry };

type TelemetryProperties = { [key in string]: unknown };

export type ContextOverride = {
  groupId: string | undefined;
};

export type IAnalytics = {
  identify: (id: string, traits?: Traits, context?: ContextOverride) => Promise<void | object>;
  track: (name: string, properties: object | undefined, context?: ContextOverride) => Promise<void | object>;
  source: TelemetrySource;
};

export type IBaseTelemetryProps = {
  analytics?: IAnalytics | undefined;
  maxQueueSize?: number;
  enabled?: boolean;
  error?: boolean;
};

export function useBaseTelemetryProvider({
  analytics,
  maxQueueSize = 25,
  enabled = true,
  error = false,
}: IBaseTelemetryProps): ITelemetryContext {
  const [queue, setQueue] = useState<QueueDataType[]>([]);
  const { data: userData, isLoading: isLoggedInUserDataLoading } = useLoggedInUser();
  const loggedInUserId = useLoggedInUserId();
  const userBasicInfo = useLoggedInUserBasicInfo();
  const { data: userOrgData, isLoading: isUserOrganizationInfoLoading } = useOrganizationReadBasicInfoQuery(
    userBasicInfo.existingUser?.userOrgId?.organizationId,
  );

  let roles = userBasicInfo?.existingUser?.roleInfos?.map((x) => RoleInfoTypeEnum[x.type]);
  if (roles?.length === 0) roles = ['TRAVELER'];
  const { isImpersonationSession } = userBasicInfo;

  const getContextOverride = (): ContextOverride => {
    const orgId = userBasicInfo.existingUser?.userOrgId?.organizationId?.id;
    return { groupId: orgId };
  };

  const getDataWithUserContext = async (
    type: TelemetryType,
    source: TelemetrySource,
    data?: TelemetryProperties,
  ): Promise<TelemetryProperties & CommonTelemetry> => {
    const userOrgId = userBasicInfo.existingUser?.userOrgId;

    const applicationId = await getApplicationId();

    return {
      type,
      userOrgId,
      userRoles: roles,
      category: source,
      appType, // standalone | embed
      isImpersonationSession,
      ...(applicationId ? { applicationId } : {}),
      ...data,
    };
  };

  const shouldProcess = (data: QueueDataType): boolean => {
    const isQueueFull = queue.length >= maxQueueSize;
    if (error) {
      // if error has occurred in initialization then no need to report events
      return false;
    }
    if (isQueueFull && !enabled) {
      /** Sometimes enabled flag could take sometime to get the correct value from feature flag provider
       * So, queue up events till the queue is full and if the enabled flag is not set till then stop
       * processing new events.
       */
      return false;
    }
    if (!analytics) {
      if (isQueueFull) {
        queue.shift();
      }
      queue.push(data);
      setQueue(queue);
      return false;
    }
    return true;
  };

  const getUserTraits = (): Traits => {
    const uniqueRoles = [...new Set(roles)];
    const traits: Traits = {
      role: uniqueRoles,
      email: userData?.userBusinessInfo?.email,
      officeId: userData?.userBusinessInfo?.office?.id?.id,
      departmentId: userData?.userBusinessInfo?.department?.id?.id,
      legalEntityId: userData?.userBusinessInfo?.legalEntityId?.id,
      costCenterId: userData?.userBusinessInfo?.userCostCenter?.id?.id,
      organizationName: userOrgData?.name,
    };
    return traits;
  };

  // Identify the user to analytics library
  const trackUser = async (user: UserTelemetry): Promise<void> => {
    const traits = getUserTraits();
    try {
      if (user && shouldProcess({ type: TelemetryType.User, data: user })) {
        const uniqueIdentifier = loggedInUserId?.userId?.id ?? 'anonymous';
        const contextOverride = getContextOverride();
        await analytics?.identify(uniqueIdentifier, traits, contextOverride);
      }
    } catch (e) {
      logger.error(new Error('TELEMETRY_PROVIDER_ERROR', { cause: e }));
    }
  };

  // Track surface views
  const trackSurface = async (surface: SurfaceTelemetry): Promise<void> => {
    try {
      if (shouldProcess({ type: TelemetryType.Surface, data: surface }) && analytics) {
        const context = await getDataWithUserContext(TelemetryType.Surface, analytics.source);
        const contextOverride = getContextOverride();

        await analytics.track(surface.name, context, contextOverride);
      }
    } catch (e) {
      logger.error(new Error('TELEMETRY_PROVIDER_ERROR', { cause: e }));
    }
  };

  // Track events (user actions etc.)
  const trackEvent = async (event: EventTelemetry): Promise<void> => {
    try {
      if (shouldProcess({ type: TelemetryType.Event, data: event }) && analytics) {
        const context = await getDataWithUserContext(TelemetryType.Event, analytics.source, event.data);
        const contextOverride = getContextOverride();

        await analytics.track(event.name, context, contextOverride);
      }
    } catch (e) {
      logger.error(new Error('TELEMETRY_PROVIDER_ERROR', { cause: e }));
    }
  };

  // Track errors (API errors etc.)
  const trackError = async (err: ErrorTelemetry): Promise<void> => {
    try {
      if (shouldProcess({ type: TelemetryType.Error, data: err }) && analytics) {
        const context = await getDataWithUserContext(TelemetryType.Error, analytics.source, err.data);
        const contextOverride = getContextOverride();

        await analytics.track(err.name, context, contextOverride);
      }
    } catch (e) {
      logger.error(new Error('TELEMETRY_PROVIDER_ERROR', { cause: e }));
    }
  };

  useEffect(() => {
    if (!isLoggedInUserDataLoading && !isUserOrganizationInfoLoading && analytics && queue.length > 0) {
      queue.forEach(async (x) => {
        switch (x.type) {
          case TelemetryType.User: {
            await trackUser(x.data);
            break;
          }
          case TelemetryType.Error: {
            await trackError(x.data);
            break;
          }
          case TelemetryType.Event: {
            await trackEvent(x.data);
            break;
          }
          case TelemetryType.Surface: {
            await trackSurface(x.data);
            break;
          }
          default:
        }
      });
      queue.splice(0);
      setQueue(queue);
    }
    // eslint-disable-next-line
  }, [analytics, enabled, isLoggedInUserDataLoading, isUserOrganizationInfoLoading]);

  return {
    trackUser,
    trackError,
    trackEvent,
    trackSurface,
  };
}
