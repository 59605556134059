// Generated using yarn generate command
import MuiLink, { LinkProps } from '@mui/material/Link';
import { forwardRef } from 'react';
import type { BlocksTheme, RemoveMuiProps } from '../types';
import { linkBase } from './Link.styles';
import { clsx } from '../utils/classNames';

/**
 * For Links we are only supporting only below two configs. Links should be used only in body
 */
type variant = 'standard' | 'active';
type Kind = 'default' | 'strong';

/**
 * For Links underline, color are predefined and disabled from props.
 */
export type TLinkProps = RemoveMuiProps<Omit<LinkProps, 'underline' | 'variant' | 'TypographyClasses'>> & {
  /** Default Variant is 'standard' */
  variant?: variant;
  /** Specify font weight of link. Default = 500, strong = 600 */
  kind?: Kind;
  /** Specify color of the link (text and underline), uses blocks palette text token names */
  color?: keyof BlocksTheme['palette']['text'] | 'inherit';
};

/**
 * Link implementation for Blocks design system
 */
export const Link = forwardRef<HTMLAnchorElement, TLinkProps>((props: TLinkProps, ref) => {
  const { variant = 'standard', kind = 'default', color, className = '', ...otherProps } = props;
  return (
    <MuiLink
      ref={ref}
      css={linkBase}
      className={clsx(`Link-${variant}`, `Link-${kind}`, `color-${color}`, className)}
      data-testid="Inline-Link"
      {...otherProps}
      underline={variant === 'active' ? 'always' : 'hover'}
    />
  );
});
