import { createContext, useContext, useEffect, useRef, ReactNode, useMemo, useCallback } from 'react';
import { IWebviewMessageBase, IWebviewMessageResponse, WebviewMessageActions } from 'obt-common/types/webviewMessaging';
import { v4 as uuid } from 'uuid';

type WebviewMessagingContextType = {
  sendMessage: (action: WebviewMessageActions) => Promise<IWebviewMessageResponse>;
  isRNWebView: boolean;
};

const WebviewMessagingContext = createContext<WebviewMessagingContextType | undefined>(undefined);

const WebviewMessagingProvider = ({ children }: { children: ReactNode }) => {
  const messageQueue = useRef<Record<string, { resolve: Function; reject: Function }>>({});

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (window.ReactNativeWebView) {
        try {
          const response: IWebviewMessageResponse = JSON.parse(event.data);
          const { id, action, payload } = response;

          if (messageQueue.current[id]) {
            if (!payload) {
              messageQueue.current[id].reject(
                new Error(`Invalid message received from RN webview for request: ${action} with id: ${id}`),
              );
            } else {
              messageQueue.current[id].resolve(response);
            }
            delete messageQueue.current[id];
          } else {
            console.error('Received messageId does not exist in messageQueue');
          }
        } catch (e) {
          console.error('Invalid message data', e);
        }
      }
    };

    // https://github.com/react-native-webview/react-native-webview/issues/356#issuecomment-467430141
    const webViewObject = navigator.userAgent.startsWith('AppleWebKit') ? window : document;
    webViewObject.addEventListener('message', handleMessage);

    return () => {
      webViewObject.removeEventListener('message', handleMessage);
    };
  }, []);

  const sendMessage = useCallback((action: WebviewMessageActions): Promise<IWebviewMessageResponse> => {
    return new Promise((resolve, reject) => {
      const messageId = uuid();

      messageQueue.current = {
        ...messageQueue.current,
        [messageId]: { resolve, reject },
      };

      const payload: IWebviewMessageBase = { id: messageId, action };
      window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    });
  }, []);

  const providerValue = useMemo(
    () => ({
      sendMessage,
      isRNWebView: !!window.ReactNativeWebView,
    }),
    [sendMessage],
  );

  return <WebviewMessagingContext.Provider value={providerValue}>{children}</WebviewMessagingContext.Provider>;
};

const useWebviewMessaging = () => {
  const context = useContext(WebviewMessagingContext);
  if (!context) {
    throw new Error('useWebviewMessaging must be used within a WebviewMessagingProvider');
  }
  return context;
};

export { WebviewMessagingProvider, useWebviewMessaging };
