/**
 * Returns details related to language code
 * @param fullLanguageCode
 * @returns
 */
export function getLanguageCodeDetails(fullLanguageCode: string) {
  const baseLanguageCode = fullLanguageCode.split('-')[0]; // "ja-JP" => "ja"

  return {
    baseLanguageCode,
    languageCode: fullLanguageCode,
  };
}
