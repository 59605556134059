import { FunctionInterpolation, Interpolation, Theme } from '@emotion/react';
import toastLib, { ToastOptions } from 'react-hot-toast';

import { Alert, AlertProps, AlertTitle } from '@mui/material';
import { IconName } from '../Icon/types';
import { Icon } from '../Icon';
import {
  error_toast,
  fade_in,
  fade_out,
  info_toast,
  toast_base,
  subtle_toast,
  success_toast,
  warning_toast,
  base,
} from './Toast.styles';
import { RemoveMuiProps } from '../types';
import { ToastDismissButton } from './ToastDismissButton';
import { ToastActionProvider, ToastActions } from './ToastActionProvider';

export type TToastVariant = 'warning' | 'error' | 'success' | 'info' | 'subtle';
type TToastPosition = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

export type TToastProps = RemoveMuiProps<AlertProps, 'variant' | 'color' | 'icon' | 'severity' | 'title'> & {
  variant: TToastVariant;
  title?: React.ReactNode;
  /**
   * Message to be displayed
   * @type React.ReactNode
   */
  message: React.ReactNode;
  /**
   * Icon to be displayed
   * @type IconName
   * If not provided, it will use the default icon for the variant
   */
  iconName?: IconName;
  /**
   * Show the icon or not
   * @type boolean
   * @default true
   */
  showIcon?: boolean;
  /**
   * Overrides the default position of the toast
   */
  position?: TToastPosition;
  /**
   * Duration in milliseconds
   */
  duration?: number;

  onClose?: () => void;

  customCss?: Interpolation<Theme>;
};

const colorConfigClassesMap: Record<TToastVariant, FunctionInterpolation<Theme>> = {
  warning: warning_toast,
  error: error_toast,
  success: success_toast,
  info: info_toast,
  subtle: subtle_toast,
};

export const colorConfigIconMap: Record<TToastVariant, IconName> = {
  warning: 'WarningFill',
  error: 'CloseCrossCircleFill',
  success: 'CheckCircleFill',
  info: 'InfoCircleFill',
  subtle: 'Loading',
};

const DEFAULT_DURATION = 5000; // 5 seconds

export function toast({
  variant,
  title,
  message,
  iconName,
  showIcon = true,
  position,
  duration = DEFAULT_DURATION,
  onClose,
  customCss,
  ...restProps
}: TToastProps) {
  const name = iconName ?? colorConfigIconMap[variant];
  const toastOptions: ToastOptions = {
    duration,
  };
  if (position) {
    toastOptions.position = position;
  }

  return toastLib.custom(
    (t) => {
      const actions: ToastActions = { dismissToast: () => toastLib.remove(t.id) };
      return (
        <ToastActionProvider actions={actions}>
          <div css={[base, t.visible ? fade_in : fade_out]}>
            <Alert
              variant="standard"
              css={[toast_base(duration), customCss || null, colorConfigClassesMap[variant]]}
              data-testid={`blocks-toast-${variant}`}
              aria-describedby={t.id}
              icon={
                showIcon && (
                  <Icon name={name} data-testid={`toast-icon-${variant}`} fontSize={24} className="BlocksToast-icon" />
                )
              }
              action={<ToastDismissButton onClose={onClose} />}
              {...restProps}
            >
              {title && <AlertTitle>{title}</AlertTitle>}
              <AlertTitle className="BlocksToast-message">{message}</AlertTitle>
            </Alert>
          </div>
        </ToastActionProvider>
      );
    },
    {
      ...toastOptions,
    },
  );
}

/**
 * @deprecated
 * DO NOT USE THIS. WILL BE REMOVED IN FUTURE.
 */
export const unstableBaseToast = toastLib;
