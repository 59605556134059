/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The label of preferred airport or rail station.
 * @export
 * @enum {string}
 */

export const PreferredLocationLabel = {
  Home: 'HOME',
  Work: 'WORK',
  Other: 'OTHER',
} as const;

export type PreferredLocationLabel = (typeof PreferredLocationLabel)[keyof typeof PreferredLocationLabel];
