import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const StandardSeat = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.998 21.547c0 .352.286.638.638.638h6.895a.638.638 0 000-1.276H6.636a.638.638 0 00-.638.638zM4.172 2.413c.705 0 1.276.571 1.276 1.276v2.45l1.727 10.943h5.926a1.276 1.276 0 110 2.551H7.175a2.551 2.551 0 01-2.52-2.153L2.928 6.538a2.551 2.551 0 01-.032-.398V3.689c0-.705.572-1.276 1.276-1.276z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.341 6.536a.647.647 0 011.284-.156l.827 5.864c.048.341.34.595.685.595h4.81c.927 0 1.713.681 1.844 1.599l.762 5.332a.632.632 0 01-1.25.187l-.815-5.191a.764.764 0 00-.753-.645l-5.288-.007a1.468 1.468 0 01-1.458-1.317l-.648-6.26zm.644-1.988c-1.14 0-2.03.986-1.913 2.12l.648 6.26a2.743 2.743 0 002.725 2.461l4.851.007.746 4.759a1.908 1.908 0 003.774-.566l-.762-5.331a3.138 3.138 0 00-3.107-2.695h-4.303l-.756-5.361a1.922 1.922 0 00-1.903-1.654z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M10.462 2.413a1.913 1.913 0 11-3.826 0 1.913 1.913 0 013.826 0z", fill: themeEnrichedPrimaryColor })));
};
export default StandardSeat;
StandardSeat.defaultProps = defaultIconProps;
