var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Bus = (_a) => {
    var { primaryColor, width, height } = _a, restProps = __rest(_a, ["primaryColor", "width", "height"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 14 15", fill: "none" }, restProps),
        React.createElement("path", { d: "M13.0664 3.38665V1.93509C13.0664 0.967083 12.0864 0 7.00001 0C1.91363 0 0.933633 0.968012 0.933633 1.93509V3.38665C0.42 3.38665 0 3.82211 0 4.35466V5.32267C0 5.85522 0.42 6.29068 0.933633 6.29068V11.6134C0.933633 12.1459 1.21363 12.6294 1.63363 12.8717V13.7907C1.63363 14.4684 2.14727 15 2.8 15C3.45364 15 3.96637 14.4675 3.96637 13.7907V13.064H10.0328V13.7898C10.0328 14.4675 10.5464 14.9991 11.1991 14.9991C11.8528 14.9991 12.3655 14.4665 12.3655 13.7898V12.8708C12.7855 12.6285 13.0655 12.145 13.0655 11.6124L13.0664 6.28978C13.58 6.28978 14 5.85431 14 5.32176V4.35375C14 3.82211 13.58 3.38665 13.0664 3.38665H13.0664ZM3.73364 1.45155H10.2673C10.5473 1.45155 10.7336 1.64479 10.7336 1.93509C10.7336 2.2254 10.5473 2.41864 10.2673 2.41864L3.73364 2.41954C3.45364 2.41954 3.26727 2.22631 3.26727 1.936C3.26639 1.64479 3.45364 1.45155 3.73364 1.45155V1.45155ZM2.8 12.096C2.28637 12.096 1.86637 11.6605 1.86637 11.128C1.86637 10.5954 2.28637 10.1599 2.8 10.1599C3.31364 10.1599 3.73364 10.5954 3.73364 11.128C3.73364 11.6605 3.31364 12.096 2.8 12.096ZM11.2 12.096C10.6864 12.096 10.2664 11.6605 10.2664 11.128C10.2664 10.5954 10.6864 10.1599 11.2 10.1599C11.7136 10.1599 12.1336 10.5954 12.1336 11.128C12.1336 11.6605 11.7136 12.096 11.2 12.096ZM12.1336 7.25777C12.1336 8.03162 11.5273 8.70932 10.7336 8.70932H3.26637C2.47274 8.70932 1.86637 8.03162 1.86637 7.25777V4.8382C1.86637 4.01534 2.47274 3.38665 3.26637 3.38665H10.7328C11.5264 3.38665 12.1328 4.01534 12.1328 4.8382L12.1336 7.25777Z", fill: themeEnrichedPrimaryColor })));
};
Bus.defaultProps = defaultIconProps;
export default Bus;
