/* eslint-disable */
import { CompanyId, OrganizationId } from '../../../../v1/obt/common/user_org_id';
import { UserBasicInfo } from '../../../../v1/obt/common/user_basic_info';
import { TravelerConfig } from '../../common/traveler_config';

/**
 *  The Client facing role definitions with metadata of corresponding type.
 *  In case of system level roles like SPOTNANA_ADMIN and SPOTNANA_AGENT, there should no metadata.
 */
export interface RoleInfo {
  /**
   *  Type of role
   */
  type: RoleInfoType;
  companyAdmin?: RoleInfoCompanyAdmin;
  companyTravelArranger?: RoleInfoCompanyTravelArranger;
  travelArranger?: RoleInfoTravelArranger;
  tmcAdmin?: RoleInfoTMCAdmin;
  tmcAgent?: RoleInfoTMCAgent;
}

export interface RoleInfoCompanyAdmin {
  organizationId?: OrganizationId;
}

export interface RoleInfoTMCAdmin {
  companyId?: CompanyId;
}

export interface RoleInfoTMCAgent {
  companyId?: CompanyId;
}

/**
 *  CompanyTravelArranger Metadata
 */
export interface RoleInfoCompanyTravelArranger {
  organizationId?: OrganizationId;
}

/**
 *  Arranger Metadata
 */
export interface RoleInfoTravelArranger {
  userBasicInfos: UserBasicInfo[];
  travelerConfigs: TravelerConfig[];
}

export enum RoleInfoTypeEnum {
  UNKNOWN_TYPE = 0,
  COMPANY_ADMIN = 1,
  COMPANY_TRAVEL_ARRANGER = 2,
  TRAVEL_ARRANGER = 3,
  GLOBAL_ADMIN = 4,
  GLOBAL_AGENT = 5,
  TMC_ADMIN = 12,
  TMC_AGENT = 13,
  UNRECOGNIZED = -1,
}

// Take all keys of RoleInfoTypeEnum
type RoleInfoTypeEnumKeys = keyof typeof RoleInfoTypeEnum;

// All possible values of RoleInfoTypeEnum
export type RoleInfoType = typeof RoleInfoTypeEnum[RoleInfoTypeEnumKeys];
