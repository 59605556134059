import { css } from '@emotion/react';
import { flex } from '@spotnana/blocks/src/utils';
import { paddings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const circular_progress_container = css`
  ${flex.init}
  ${flex.justify.center}
  ${paddings.y.cozy}
`;

export const button_container = css`
  ${flex.init}
  ${flex.justify.center}

  > div {
    width: 49%;
  }
`;
