import { FC } from 'react';
import { Button } from '../../Button';
import { Surface } from '../../Surface';
import { Typography } from '../../Typography';
import { SwipeableDrawerActionConfig } from '../SwipeableDrawer';
import { header_container, sticky_header } from './styles';

export interface SwipeableDrawerHeaderConfig {
  title: string;
  actionConfig?: SwipeableDrawerActionConfig;
  showCloseButton?: boolean;
}
export const SwipeableDrawerHeader: FC<SwipeableDrawerHeaderConfig> = ({
  title,
  actionConfig,
  showCloseButton,
}: SwipeableDrawerHeaderConfig) => {
  if (!title) {
    return null;
  }

  return (
    <Surface variant="base" shadow="scroll-top" css={sticky_header}>
      <div css={header_container}>
        <Typography variant="header4">{title}</Typography>
        {!!actionConfig && !showCloseButton && (
          <Button
            variant="ghost"
            size="medium"
            disabled={actionConfig.disabled}
            loading={actionConfig.loading}
            onClick={actionConfig.onClick}
          >
            {actionConfig.text}
          </Button>
        )}
      </div>
    </Surface>
  );
};
