import { Button } from '@spotnana/blocks/src/Button';
import { useTranslation } from 'react-i18next';

interface IAddMeButtonProps {
  onClick?: () => void;
}

export const AddMeButton = ({ onClick }: IAddMeButtonProps) => {
  const { t: tt } = useTranslation('WEB');
  return (
    <Button onClick={onClick} variant="ghost">
      {tt('Add me')}
    </Button>
  );
};
