import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const FileXLSX = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 25 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.242 0a2 2 0 00-2 2v18a2 2 0 002 2h14a2 2 0 002-2V2a2 2 0 00-2-2h-14zm.5 2a.5.5 0 00-.5.5v17a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-17a.5.5 0 00-.5-.5h-13z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { fill: "#fff", d: "M6.242 6h2v10h-2z" }),
        React.createElement("path", { d: "M1.23 8.08l1.281 2.13h.046l1.287-2.13h1.201l-1.792 2.909 1.82 2.909h-1.22L2.556 11.78H2.51l-1.295 2.117H0l1.838-2.91L.023 8.08H1.23zM5.873 13.898V8.08h1.054v4.934h2.562v.884H5.873zM13.566 9.679a.776.776 0 00-.335-.58c-.195-.138-.449-.207-.761-.207-.22 0-.409.033-.566.1a.837.837 0 00-.36.27.657.657 0 00-.046.704.753.753 0 00.23.227c.097.06.204.112.321.154.117.041.236.076.355.105l.546.136c.22.051.43.12.633.208.205.087.387.196.548.329.163.133.292.293.387.48.095.188.142.407.142.66 0 .34-.087.64-.262.9-.174.257-.426.46-.755.605-.328.144-.725.216-1.19.216-.453 0-.846-.07-1.18-.21a1.756 1.756 0 01-.778-.614c-.186-.269-.286-.597-.301-.983h1.037a.882.882 0 00.187.506c.11.134.253.234.43.3.177.067.376.1.596.1.23 0 .43-.034.602-.102a.977.977 0 00.41-.29.7.7 0 00.15-.437.556.556 0 00-.134-.375.978.978 0 00-.366-.25 3.527 3.527 0 00-.546-.182l-.662-.17c-.479-.124-.857-.31-1.136-.56-.276-.252-.415-.586-.415-1.003a1.5 1.5 0 01.279-.9 1.84 1.84 0 01.764-.6A2.653 2.653 0 0112.484 8c.412 0 .774.072 1.085.216.312.142.558.34.736.594.178.252.27.541.275.869h-1.014zM16.48 8.08l1.281 2.13h.046l1.287-2.13h1.201l-1.792 2.909 1.82 2.909h-1.22l-1.296-2.117h-.046l-1.295 2.117H15.25l1.838-2.91-1.815-2.908h1.207z", fill: themeEnrichedPrimaryColor })));
};
FileXLSX.defaultProps = defaultIconProps;
export default FileXLSX;
