/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Auth provider type
 * @export
 * @enum {string}
 */

export const AuthProviderType = {
  UserCredential: 'USER_CREDENTIAL',
  Google: 'GOOGLE',
  Azure: 'AZURE',
  Custom: 'CUSTOM',
  Redirect: 'REDIRECT',
} as const;

export type AuthProviderType = (typeof AuthProviderType)[keyof typeof AuthProviderType];
