/* eslint-disable i18next/no-literal-string */
import { Switch } from '@spotnana/blocks/src/Switch';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from '@spotnana/blocks/src/utils/hooks';
import { theme_mode_text } from './SwitchThemeMode.styles';

export const SwitchThemeMode = () => {
  const { mode, setMode } = useColorScheme();
  const { t: tt } = useTranslation('WEB');

  const onToggle = () => {
    setMode(mode === 'light' ? 'dark' : 'light');
  };

  return (
    <div css={theme_mode_text}>
      <Switch label={tt('Dark mode')} labelPlacement="start" checked={mode === 'dark'} onChange={onToggle} />
    </div>
  );
};
