import dateUtil from '../dateUtil';
import { dateFormats } from '../../constants/common';
import dateUtilFormat from '../dateUtilFormat';

const roundTimeWith5MinInterval = (time: string, timeZone?: string) => {
  if (!time) {
    // if time is empty '' string
    return undefined;
  }

  let datetime = dateUtil(time).set({ second: 0, millisecond: 0 });

  if (timeZone) {
    datetime = datetime.tz(timeZone);
  }

  const remainder = datetime.minute() % 5;
  const roundedTime = datetime.subtract(remainder, 'minutes');

  return dateUtilFormat(roundedTime, dateFormats.ISO);
};

export default roundTimeWith5MinInterval;
