import { PassengerTypeEnum } from '../../../constants';
import type { PassengerAge } from '../../../types';
import type { PaxInfo } from '../../../types/api/v1/obt/air/air_search_response';

interface Input {
  paxType: PassengerTypeEnum;
  paxAge: PassengerAge | undefined;
  paxInfo: PaxInfo[];
}
export function getPaxInfoIndex({ paxType, paxAge, paxInfo }: Input) {
  const isChildPassenger = paxType === PassengerTypeEnum.CHILD;

  const index = paxInfo.findIndex((info) => {
    if (info.paxType !== paxType) {
      return false;
    }

    if (isChildPassenger) {
      return info.paxAge?.years === paxAge?.years;
    }

    return true;
  });

  return index;
}
