import { css } from '@emotion/react';
import { flex } from '@spotnana/blocks/src/utils';

export const select_traveler_wrapper = css`
  width: 400px;
  position: relative;
  .AutocompleteItemContent {
    align-items: flex-start !important;
  }
`;

export const register_traveler_button = css`
  ${flex.init}
  ${flex.justify.start}
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
`;

export const icon_style = css`
  font-size: 24px;
`;
