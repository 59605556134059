var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Close = (_a) => {
    var { primaryColor, width, height, secondaryColor } = _a, restProps = __rest(_a, ["primaryColor", "width", "height", "secondaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 12 12" }, restProps),
        React.createElement("path", { d: "M1.613.21l.094.083L6 4.585 10.293.293a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094L7.415 6l4.292 4.293.083.094a1 1 0 01-.083 1.32l-.094.083a1 1 0 01-1.32-.083L6 7.415l-4.293 4.292-.094.083a1 1 0 01-1.32-1.5L4.585 6 .293 1.707a1 1 0 011.32-1.5z", fill: themeEnrichedPrimaryColor, fillRule: "evenodd" })));
};
Close.defaultProps = defaultIconProps;
export default Close;
