import { FC } from 'react';
import { FareList } from 'obt-common';
import { PreloadContainerSavedFareList } from './PreloadContainerSavedFareList';
import { SavedFareListApiProvider } from './SavedFareListApiProvider';
import { FareListContainer } from './FareListContainer';

interface IProps {
  fareList: FareList[];
  onClick: (listId: string) => void;
  onClose: VoidFunction;
}

export const SavedFareList: FC<IProps> = ({ fareList, onClick, onClose }) => {
  return (
    <PreloadContainerSavedFareList fareList={fareList} onClose={onClose}>
      {(data) => (
        <SavedFareListApiProvider {...data} onAddToFareList={onClick} fareList={fareList}>
          <FareListContainer />
        </SavedFareListApiProvider>
      )}
    </PreloadContainerSavedFareList>
  );
};
