import { css } from '@emotion/react';
import { flex, paddings } from '@spotnana/blocks/src/utils/styling';
import { fontWeight } from '@spotnana/blocks/src/BlocksThemeProvider/ux-specs/typography';

export const debug_tool_link_container = css`
  appearance: none;
  width: 100%;
  ${paddings.x.p1point5}
  ${paddings.y.point75}
  ${flex.init}
  ${flex.align.center}
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  :hover p {
    font-weight: ${fontWeight.w600};
  }
`;
