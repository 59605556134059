/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Rail Travel classes in hierarchy numbering higher to keep scope for future additions in  between.
 * @export
 * @enum {string}
 */

export const TravelClassHierarchy = {
  Unknown: 'UNKNOWN',
  Standard: 'STANDARD',
  Coach: 'COACH',
  StandardPremium: 'STANDARD_PREMIUM',
  Business: 'BUSINESS',
  BusinessPremium: 'BUSINESS_PREMIUM',
  First: 'FIRST',
  Sleeper: 'SLEEPER',
} as const;

export type TravelClassHierarchy = (typeof TravelClassHierarchy)[keyof typeof TravelClassHierarchy];
