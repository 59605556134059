import { FC } from 'react';
import { Modal, useModalState } from '@spotnana/blocks/src/Modal';
import { useTranslation } from 'react-i18next';
import { TravelerBasicInfo, FareList } from 'obt-common';
import { get, useFieldArray, useFormContext } from 'rhf-v7';
import { Typography } from '@spotnana/blocks/src/Typography';
import { TextAreaField } from '@spotnana/blocks/src/fields';
import TravelerPicker from 'src/app/shared/components/GroupBookingTravelerSelector/TravelerPicker';
import { useAirShareItineraryMutation } from 'obt-common/api/hooks/v1/flights';
import { modal_secondary_background } from '../shared/styles';
import { ShareItineraryFormValues } from '../ShareItineraryModalContainer/FormProvider/types';
import { text_area, traveler_section } from './styles';
import { TravelerItem } from './TravelerItem';
import { transformFareToAirFare } from '../utils/transformFareToAirFare';
import { ShareSuccessModal } from './ShareSuccessModal';

interface ShareItineraryModalProps {
  open: boolean;
  onClose: VoidFunction;
  fareList: FareList;
}

export const ShareItineraryModal: FC<ShareItineraryModalProps> = ({ open, onClose, fareList }) => {
  const { t: tt } = useTranslation('WEB');
  const { open: isSuccessModalOpen, openModal: openSuccessModal, closeModal: closeSuccessModal } = useModalState();

  const { fares } = fareList;
  const numberOfFares = fares.length;
  const { handleSubmit, control, formState, register, reset } = useFormContext<ShareItineraryFormValues>();

  const { fields: formTravelers, remove, append } = useFieldArray({ control, name: 'travelers' });
  const { mutateAsync: airShareItinerary, isLoading } = useAirShareItineraryMutation();

  const travelersError = get(formState.errors, 'travelers');

  const onTravelerSelect = (travelerInfo: TravelerBasicInfo | null) => {
    const travelerAlreadyAdded = formTravelers.some(
      (traveler) => traveler.userOrgId?.userId?.id === travelerInfo?.userOrgId?.userId?.id,
    );
    /* istanbul ignore next */
    if (!travelerInfo || travelerAlreadyAdded) {
      return;
    }
    append(travelerInfo);
  };

  const handleSubmitForm = async (values: ShareItineraryFormValues) => {
    /**
     * Primary email will always exist in form
     */
    const emailRecipients = values.travelers
      .map((traveler) => traveler.email)
      .filter((email): email is string => !!email);
    const messageBody = values.message;
    const fareOptions = fares.map((fare) => transformFareToAirFare(fare));

    await airShareItinerary({
      requestBody: {
        emailRecipients,
        fareOptions,
        messageBody,
      },
    });

    onClose();
    openSuccessModal();
  };

  const handleClose = () => {
    reset();
    onClose();
    closeSuccessModal();
  };

  return (
    <Modal
      variant="medium"
      open={open}
      onClose={handleClose}
      header={{ title: tt('Share itineraries ({{count}} option(s))', { count: numberOfFares }) }}
      footer={{
        primaryAction: { text: tt('Send email'), onClick: handleSubmit(handleSubmitForm), loading: isLoading },
        secondaryAction: { text: tt('Cancel'), onClick: handleClose },
      }}
      css={modal_secondary_background}
    >
      <div>
        <Typography variant="body1" kind="semibold">
          {tt('Draft your message')}
        </Typography>
        <TextAreaField
          control={control}
          label={tt('Message')}
          fullWidth
          required
          hideLabel
          minRows={6}
          maxRows={10}
          css={text_area}
          {...register('message')}
        />
        <div css={traveler_section}>
          <Typography variant="body1" kind="semibold">
            {tt('Share with')}
          </Typography>
          <TravelerPicker onTravelerSelect={onTravelerSelect} orgIdToFilter={null} />
          <Typography variant="body1" kind="semibold">
            {tt('{{numOfTravelers}} Traveller(s)', { numOfTravelers: formTravelers.length })}
          </Typography>
          {formTravelers.map((traveler, index) => {
            return <TravelerItem key={traveler.id} onRemoveTraveler={() => remove(index)} traveler={traveler} />;
          })}
          {travelersError && (
            <Typography variant="body2" color="error">
              {travelersError}
            </Typography>
          )}
        </div>
      </div>
      {formTravelers.length > 0 && (
        <ShareSuccessModal open={isSuccessModalOpen} onClose={handleClose} formTravelers={formTravelers} />
      )}
    </Modal>
  );
};
