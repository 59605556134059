import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Cleaning = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32" },
        React.createElement("path", { d: "M12,14.34a.6.6,0,0,0,.29.08H14a.54.54,0,0,0,.44-.23L15.26,13a7.19,7.19,0,0,0,.66-1.14v.35a13.56,13.56,0,0,1-1.43,6l-.32.65A7.08,7.08,0,0,0,13.43,22v4.71a1.79,1.79,0,0,0,1.79,1.79h6.23a1.79,1.79,0,0,0,1.78-1.79V21.56a13.65,13.65,0,0,0-.54-3.81l-1.64-5.61V10.56a.83.83,0,0,1,.75-.82.53.53,0,0,0,.5-.53,2.93,2.93,0,0,0-2.93-2.93H12.73a.54.54,0,0,0-.54.54V8.48a.54.54,0,0,0,.54.54h1.09a6.63,6.63,0,0,1-1,3l-1,1.56a.58.58,0,0,0-.07.41A.6.6,0,0,0,12,14.34Zm1.83-7v.58h-.58V7.36Zm1.61,11.39a14.84,14.84,0,0,0,1.53-6h3.12l1.54,5.29a12.62,12.62,0,0,1,.5,3.51v5.19a.7.7,0,0,1-.7.71H15.22a.71.71,0,0,1-.71-.71V22a6.06,6.06,0,0,1,.62-2.64ZM20,11.1v.58H17V11.1ZM20.05,10H16.59V8.48a.54.54,0,0,0-.54-.54H14.93V7.36h4.44a1.84,1.84,0,0,1,1.8,1.44A1.89,1.89,0,0,0,20.05,10Zm-6.77,3.32.46-.74A7.71,7.71,0,0,0,14.91,9h.6a6.07,6.07,0,0,1-1.13,3.36l-.69,1Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M16.46,26.88H20.2a1,1,0,0,0,1-1v-5a1,1,0,0,0-1-1H16.46a1,1,0,0,0-1,1v5A1,1,0,0,0,16.46,26.88Zm.13-1.08V21.06h3.49V25.8Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("rect", { x: "7.18", y: "3.6", width: "1.08", height: "1.26", transform: "translate(-0.4 7.62) rotate(-51.34)" }),
        React.createElement("rect", { x: "10.35", y: "6.13", width: "1.08", height: "1.26", transform: "translate(-1.19 11.04) rotate(-51.35)" }),
        React.createElement("rect", { x: "8.76", y: "4.86", width: "1.08", height: "1.26", transform: "matrix(0.63, -0.78, 0.78, 0.63, -0.8, 9.32)" }),
        React.createElement("rect", { x: "9.1", y: "7.11", width: "1.26", height: "1.08" }),
        React.createElement("rect", { x: "7.07", y: "7.11", width: "1.26", height: "1.08" }),
        React.createElement("rect", { x: "5.05", y: "7.11", width: "1.26", height: "1.08" }),
        React.createElement("rect", { x: "7.09", y: "10.53", width: "1.26", height: "1.08", transform: "translate(-5.22 7.26) rotate(-38.69)" }),
        React.createElement("rect", { x: "10.25", y: "8", width: "1.26", height: "1.08", transform: "translate(-2.95 8.67) rotate(-38.65)" }),
        React.createElement("rect", { x: "8.67", y: "9.26", width: "1.26", height: "1.08", transform: "translate(-4.09 7.97) rotate(-38.69)" }),
        React.createElement("path", { d: "M20.34,17.1a.49.49,0,0,0-.32.26.54.54,0,0,0-.05.41l.13.42a.55.55,0,0,0,.26.32.58.58,0,0,0,.26.06.39.39,0,0,0,.15,0,.52.52,0,0,0,.32-.26.53.53,0,0,0,.05-.41L21,17.46A.54.54,0,0,0,20.34,17.1Z", fill: themeEnrichedPrimaryColor }),
        React.createElement("path", { d: "M19.6,16.52a.54.54,0,0,0,.26.33.58.58,0,0,0,.26.06.39.39,0,0,0,.15,0,.52.52,0,0,0,.32-.26.55.55,0,0,0,.05-.42l-.5-1.66a.55.55,0,0,0-.26-.32.6.6,0,0,0-.42,0,.55.55,0,0,0-.36.67Z", fill: themeEnrichedPrimaryColor })));
};
Cleaning.defaultProps = defaultIconProps;
export default Cleaning;
