/* eslint-disable */
import { MoneyRange, DateTimeRange, Int32Range } from '../../../v1/common/range';
import {
  Alliance,
  Cabin,
  PassengerType,
  PassengerAge,
  FareCategory,
  FlightNumber,
} from '../../../v1/obt/air/air_common';
import { NonProfileUserInfo, UserOrgId } from '../../../v1/obt/common/user_org_id';
import { PreSearchAnswers } from '../../../v1/obt/policy/user_defined_entity';
import { Name } from '../../../v1/common/name';
import { DateTime } from '../../../v1/common/date_time';
import { DataFlight } from '../../../v1/obt/air/air_search_response';
import { AirlinePref, AirPref } from '../common/air_preference';
import { ProfileMetadata } from '../common/profile_metadata';
export interface Filter {
  cabin?: FilterCabinFilter | undefined;
  maxNumStops?: number | undefined;
  timeRange?: FilterTimeRangeFilter | undefined;
  alliance?: FilterAllianceFilter | undefined;
  fareRange?: MoneyRange | undefined;
  airlinePref?: FilterAirlinePrefsFilter | undefined;
  fareType?: FilterFareType | undefined;
  changeable?: FilterChangeableFilter | undefined;
  connectingAirports?: FilterConnectingAirportsFilter | undefined;
  seatPref?: FilterSeatPrefFilter | undefined;
  covid?: FilterCovidFilter | undefined;
  baggage?: FilterBaggageFilter | undefined;
  flightNumber?: FilterFlightNumberFilter | undefined;
  policyFilter?: FilterPolicyFilter | undefined;
  multiTicketFilter?: FilterMultiTicketFilter | undefined;
  fareCategoryFilter?: FilterFareCategoryFilter | undefined;
  /** Filter to get results which have applicable unused credits */
  unusedCreditFilter?: FilterUnusedCreditFilter | undefined;
}

export interface FilterUnusedCreditFilter {
  /** Hide itineraries on which unused credits can't be applied. */
  hideWithoutCredits: boolean;
}

export interface FilterMultiTicketFilter {
  /** If true, only single ticket itineraries. */
  hideMultiTicket: boolean;
}

export interface FilterTimeRangeFilter {
  timeRanges: FilterTimeRangeFilterTimeRange[];
}

export interface FilterTimeRangeFilterTimeRange {
  legIndex: number;
  departure?: DateTimeRange;
  arrival?: DateTimeRange;
}

export interface FilterAllianceFilter {
  alliances: Alliance[];
  /**
   *  2 letter IATA codes for the preferred airlines.
   */
  airlines: string[];
}

export interface FilterAirlinePrefsFilter {
  airlinePrefs: FilterAirlinePrefsFilterAirlinePrefs[];
}

export interface FilterAirlinePrefsFilterAirlinePrefs {
  legIndex: number;
  airline: string[];
}
export interface FilterConnectingAirportsFilter {
  legFilters: FilterConnectingAirportsFilterLegFilter[];
}
export interface FilterConnectingAirportsFilterLegFilter {
  legIndex: number;
  airportCodes: string[];
}

export interface FilterCabinFilter {
  type?: FilterCabinFilterType;
  cabin: Cabin;
}

export interface FilterFareCategoryFilter {
  /** Fare categories to return in the response */
  fareCategories: FareCategory[];
}

export interface SortOption {
  sortBy: SortOptionSortBy;
  sortOrder: SortOptionSortOrder;
  /**
   *  Only used when 'SortBy' is 'PRICE' and represents which shelf should be used for sorting. If
   *  shelf_number is 0 (default), minimum of all shelf prices is used.
   */
  shelfNumber?: number;
}

export interface AirSearchRequestPaxInfo {
  /** Type of passenger */
  paxType: PassengerType;
  /** Age of passenger. Only required if pax_type is CHILD */
  paxAge?: PassengerAge;
  /** Number of passenger of the specified type and age */
  paxNum: number;
}

/**
 *  Defines the 'AirSearchRequest', with all the required parameters and the optional filters/sorters
 *  comprising of the fields. The air-search (AirService.AirSearch) works on a 'leg-by-leg' basis,
 *  as in, say for a roundtrip search for A <-> B, the API would have to be called twice:
 *  firstly for A -> B and secondly for B -> A.
 */
export interface AirSearchRequest {
  userOrgIds?: UserOrgId[];
  legs: AirSearchRequestLeg[];
  filter: Filter[];
  sortOptions?: SortOption[];
  corporateInfo?: AirSearchRequestCorporateInfo;
  /**
   * Use this field to get flight results for individual legs.
   * When non-null, the other search parameters are ignored and the leg results are obtained from
   * the search response specified by leg_search_params.search_id.
   */
  legSearchParams?: AirSearchRequestLegSearchParams;
  /** This boolean if true returns ONLY ndc content (not aggregated results) */
  enableNdcContent?: boolean;
  paxInfos?: AirSearchRequestPaxInfo[];
  /** user defined preferences */
  preferences?: AirPref[];
  /** Profile metadata. */
  profileMetadata?: ProfileMetadata;
  /** org defined preferences */
  orgAirlinePreference?: AirlinePref;
  /**
   * This is for UI to pass in case of non-profile user.
   * Note: we shouldn't populate the user org id object.
   */
  nonProfileUserInfos?: NonProfileUserInfo[];
  /** Number of cheapest options to be returned in the response */
  numberOfCheapestOptions: number;
  /** Internal ID of the trip. */
  tripId: string;
  /** Normal booking or group booking */
  bookingType: AirSearchRequestBookingType;
}

/** The type of booking requested */
export enum AirSearchRequestBookingType {
  BOOKING_TYPE_UNKNOWN = 0,
  /** NORMAL_BOOKING - Normal booking flow which includes multi-pax */
  NORMAL_BOOKING = 1,
  /**
   * GROUP_BOOKING - Use Case - An arranger is booking an itinerary for multiple travelers.
   * The end result should be seperate PNRs for each traveler
   */
  GROUP_BOOKING = 2,
  UNRECOGNIZED = -1,
}

/** Defines the fields for the requests to be made for subsequent legs, apart from the first leg. */
export interface AirSearchRequestLegSearchParams {
  /**
   * The unique id (identifying the current workflow) returned by the backend in the air search
   * results response (AirSearchResponse.searchId) of the previous leg.
   */
  searchId: string;
  /** The unique id identifying the selected flights in the previous leg's response. */
  selectedRateOptionId: string;
  /** The index of the 'leg' for which the results are to be returned for this request. */
  legIndex: number;
  /**
   * For pagination.
   *
   * @deprecated
   */
  legRange?: Int32Range;
  /** If true, RouteHappy content will be fetched asynchronously and not returned in response. */
  asyncRouteHappy: boolean;
  /**
   * The page number for which data has to be fetched. For page number equal to
   * zero complete air search response(without pagination) will be returned.
   */
  pageNumber: number;
  /**
   * Optional parameter to specify the max number of results per page. If not set or set as 0,
   * default page size of 50 is used.
   */
  pageSize?: number;
  /** Information required to clone a leg of a PNR. */
  legCloneInfo?: AirSearchRequestLegSearchParamsLegCloneInfo;
}

/** Information needed to clone a particular leg. */
export interface AirSearchRequestLegSearchParamsLegCloneInfo {
  /** The PNR which needs to be cloned. */
  pnrId: string;
  /** The leg, which needs to be cloned. */
  pnrLegIndex: number;
}

/**
 *  The message identifying the location, comprising of either an airport or the city code.
 */
export interface AirSearchRequestLocation {
  /**
   *  The city code, identifying a set of airports within it, like NYC, LON etc.
   */
  city?: string;
  /**
   *  The airport code identifying a unique airport like JFK, EWR etc.
   */
  airport?: string;
  /** List of airports in this location. */
  multiAirports?: AirSearchRequestLocationMultiAirports | undefined;
}

/** Location with multiple airports. */
export interface AirSearchRequestLocationMultiAirports {
  /**
   * The list of 3-letter IATA codes for the airports. All airports should
   * belong to the same country.
   */
  airportCodes: string[];
}

export interface AirSearchRequestLeg {
  originS?: string;
  destinationS?: string;
  date: string;
  origin: AirSearchRequestLocation;
  destination: AirSearchRequestLocation;
}

export interface AirSearchRequestCorporateInfo {
  preSearchAnswers?: PreSearchAnswers;
}

export interface AirExchangeSearchRequest {
  /**
   *  The unique id of the trip which is to be updated.
   *  This can be accessed from the FetchTrip response.
   */
  pnr: string;
  /**
   *  The list of all legs (both updated and existing). At least, one updated leg must be present.
   */
  legs: AirExchangeSearchRequestLeg[];
  /**
   *  The list of travellers whose trips need to be updated.
   */
  travellers: AirExchangeSearchRequestTravellerInfo[];
  filters: Filter[];
  sortOptions: SortOption[];
}

export interface AirExchangeSearchRequestTravellerInfo {
  traveller?: Name;
  documentNumber: string;
}

export interface AirExchangeSearchRequestLeg {
  existingLeg?: AirExchangeSearchRequestLegExistingLeg;
  updatedLeg?: AirExchangeSearchRequestLegUpdatedLeg;
}

export interface AirExchangeSearchRequestLegUpdatedLeg {
  /**
   *  The legs in the old trip which is to be updated.
   */
  origin: string;
  destination: string;
  date?: DateTime;
}

export interface AirExchangeSearchRequestLegExistingLeg {
  origin: string;
  destination: string;
  date?: DateTime;
  flights: AirExchangeSearchRequestLegExistingLegFlightInfo[];
}

/**
 *  The legs in the old trip that remain same
 */
export interface AirExchangeSearchRequestLegExistingLegFlightInfo {
  flight?: DataFlight;
  bookingCode: string;
  reservationStatus: string;
  bookingDateTime?: DateTime;
}

export enum FilterFareTypeEnum {
  UNKNOWN = 0,
  PUBLIC = 1,
  PRIVATE = 2,
  UNRECOGNIZED = -1,
}

export type FilterFareType = 0 | 1 | 2 | -1;

export enum FilterChangeableFilter {
  NO_CHANGEABLE_FILTER = 0,
  /** CHANGEABLE_FLEXIBLE_REFUNDABLE - Changeable, but flight can be non-refundable, partially refundable or fully refundable. */
  CHANGEABLE_FLEXIBLE_REFUNDABLE = 1,
  REFUNDABLE_WITH_PENALTY = 2,
  REFUNDABLE_WITHOUT_PENALTY = 3,
  UNRECOGNIZED = -1,
}

export enum FilterCabinFilterTypeEnum {
  /** DEFAULT -  Uses default filtering. Might send non-specified cabin too in case it's cheaper or the specified cabin is not available.
   */
  DEFAULT = 0,
  /** ALL -  Ensures that all the segments in the itinerary has the specified cabin.
   */
  ALL = 1,
  /** AT_LEAST_ONE -  Ensures that at least one segment in the itinerary has the specified cabin.
   */
  AT_LEAST_ONE = 2,
  UNRECOGNIZED = -1,
}

export type FilterCabinFilterType = 0 | 1 | 2 | -1;

export enum SortOptionSortByEnum {
  PRICE = 0,
  DEPARTURE_TIME = 1,
  ARRIVAL_TIME = 2,
  DURATION = 3,
  CO2_EMISSION = 8,
  UNRECOGNIZED = -1,
}

export type SortOptionSortBy = 0 | 1 | 2 | 3 | 8 | -1;

export enum SortOptionSortOrderEnum {
  ASCENDING = 0,
  DESCENDING = 1,
  UNRECOGNIZED = -1,
}

export type SortOptionSortOrder = 0 | 1 | -1;

export interface FilterSeatPrefFilter {
  seatTypes: FilterSeatPrefFilterSeatType[];
  minPitchInch: number;
}

export enum FilterSeatPrefFilterSeatType {
  UNKNOWN = 0,
  FLAT = 1,
  RECLINER = 2,
  SKYCOUCH = 3,
  PRIVATE_SUITE = 4,
  UNRECOGNIZED = -1,
}

export interface FilterCovidFilter {
  vaccine: FilterCovidFilterPreference;
  covidTest: FilterCovidFilterPreference;
  faceMask: FilterCovidFilterPreference;
  temperatureCheck: FilterCovidFilterPreference;
  blockedAdjacentSeats: FilterCovidFilterPreference;
}

export enum FilterCovidFilterPreference {
  NO_PREFERENCE = 0,
  REQUIRED = 1,
  NOT_REQUIRED = 2,
  UNRECOGNIZED = -1,
}

export interface FilterBaggageFilter {
  /** If true only itineraries with free checked bag are returned, else no preference. */
  freeCheckedBag: boolean;
  /** If true only itineraries with free checked bag are returned, else no preference. */
  freeCarryOn: boolean;
}

export interface FilterFlightNumberFilter {
  /**
   * @deprecated Use `flightIdentifier` instead.
   * Filters itineraries where either marketing or operating carrier has this flight number.
   */
  flightNumber?: FlightNumber | undefined;
  /** Plain string which can have spaces and can be without airline name (just digits). */
  flightIdentifier?: string | undefined;
}

export interface FilterPolicyFilter {
  /** If true, only in-policy itineraries are returned. */
  onlyInPolicy: boolean;
}
