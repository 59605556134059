var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Lavatory = (_a) => {
    var { primaryColor } = _a, restProps = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M10.6 44V29H8.1V17.6Q8.1 16.15 9.175 15.075Q10.25 14 11.7 14H16.9Q18.35 14 19.425 15.075Q20.5 16.15 20.5 17.6V29H18V44ZM31.5 44V32H26.3L30.6 16.45Q30.95 15.25 31.925 14.625Q32.9 14 34.2 14Q35.5 14 36.475 14.625Q37.45 15.25 37.8 16.45L42.1 32H36.9V44ZM14.3 11.3Q12.8 11.3 11.725 10.225Q10.65 9.15 10.65 7.65Q10.65 6.15 11.725 5.075Q12.8 4 14.3 4Q15.8 4 16.875 5.075Q17.95 6.15 17.95 7.65Q17.95 9.15 16.875 10.225Q15.8 11.3 14.3 11.3ZM34.2 11.3Q32.7 11.3 31.625 10.225Q30.55 9.15 30.55 7.65Q30.55 6.15 31.625 5.075Q32.7 4 34.2 4Q35.7 4 36.775 5.075Q37.85 6.15 37.85 7.65Q37.85 9.15 36.775 10.225Q35.7 11.3 34.2 11.3Z" })));
};
Lavatory.defaultProps = defaultIconProps;
export default Lavatory;
