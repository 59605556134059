/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PaymentInstructionId } from './payment-instruction-id';

/**
 * conferma card descriptor.
 * @export
 * @interface ConfermaMetadata
 */
export interface ConfermaMetadata {
    /**
     * card pool client Id.
     * @type {string}
     * @memberof ConfermaMetadata
     */
    'cardPoolClientId': string;
    /**
     * Name/Label for visual identification of the card.
     * @type {string}
     * @memberof ConfermaMetadata
     */
    'cardLabel'?: string;
    /**
     * company/type of the card.
     * @type {string}
     * @memberof ConfermaMetadata
     */
    'company'?: ConfermaMetadataCompanyEnum;
    /**
     * List of payment instruction ids
     * @type {Array<PaymentInstructionId>}
     * @memberof ConfermaMetadata
     */
    'paymentInstructionIds'?: Array<PaymentInstructionId>;
}

export const ConfermaMetadataCompanyEnum = {
    Visa: 'VISA',
    Mastercard: 'MASTERCARD',
    Amex: 'AMEX',
    Discover: 'DISCOVER'
} as const;

export type ConfermaMetadataCompanyEnum = typeof ConfermaMetadataCompanyEnum[keyof typeof ConfermaMetadataCompanyEnum];


