/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Types of vendors supported.
 * @export
 * @enum {string}
 */

export const VendorType = {
  Car: 'CAR',
  Hotel: 'HOTEL',
  HotelChain: 'HOTEL_CHAIN',
  Airline: 'AIRLINE',
  HotelBrand: 'HOTEL_BRAND',
} as const;

export type VendorType = (typeof VendorType)[keyof typeof VendorType];
