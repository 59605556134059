import { Container } from '@spotnana/blocks/src/Container';
import { Tabs } from '@spotnana/blocks/src/TabsOld/TabsOld';
import { defineMessage } from 'obt-common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Links from './Links/Links';
import Notes from './Notes/Notes';
import { tab_content, tabs_wrapper } from './index.styles';

// Enums representing different tabs available in the component
enum TabEnums {
  NOTES = 'Notes',
  PRIVATE_LINKS = 'PrivateLinks',
  TEAM_LINKS = 'TeamLinks',
}

// Map to associate each tab enum with its corresponding label
const TabLabelsMap: Record<TabEnums, string> = {
  [TabEnums.NOTES]: defineMessage('Notes'),
  [TabEnums.PRIVATE_LINKS]: defineMessage('Private links'),
  [TabEnums.TEAM_LINKS]: defineMessage('Team links'),
};

// NotesLinks component displaying tabs for Notes, Private Links, and Team Links
const NotesLinks = (): JSX.Element => {
  // Translation hook to get translation functions specific to 'WEB' namespace
  const { t: tWeb } = useTranslation('WEB');

  // State to manage the currently selected tab
  const [selectedTab, setSelectedTab] = useState<TabEnums>(TabEnums.NOTES);

  // Function to handle tab change
  const handleTabChange = (key: TabEnums) => {
    setSelectedTab(key);
  };

  return (
    <>
      {/* Tabs component rendering tabs based on the available enums */}
      <div css={tabs_wrapper}>
        <Tabs
          items={[TabEnums.NOTES, TabEnums.PRIVATE_LINKS, TabEnums.TEAM_LINKS]}
          size="large"
          orientation="horizontal"
          value={selectedTab}
          onChange={(_, value) => handleTabChange(value as TabEnums)}
          renderItem={(v: TabEnums) => tWeb(TabLabelsMap[v])}
        />
      </div>
      {/* Container to hold the content based on the selected tab */}
      <Container>
        <div css={tab_content} key={selectedTab}>
          {/* Render content based on the selected tab */}
          {selectedTab === TabEnums.NOTES && <Notes />}
          {selectedTab === TabEnums.PRIVATE_LINKS && <Links isPrivate />}
          {selectedTab === TabEnums.TEAM_LINKS && <Links />}
        </div>
      </Container>
    </>
  );
};

export default NotesLinks;
