import { AirFare, AirSegment, Passenger, PassengerType } from '@spotnana/types/openapi/models';
import last from 'lodash/last';
import { dateFormats, Fare, IAirSuggestion, IFlightSearchSegmentState, RequiredPaxUrlInfo } from 'obt-common';
import { dateUtil } from 'obt-common/date-utils';
import { PassengerTypeEnum, PassengerType as UrlPaxType } from 'obt-common/types/api/v1/obt/air/air_common';

export const transformFareToAirFare = (fare: Fare): AirFare => {
  const { passengers: farePassengers, rateOptionId, searchId, segments, tripBookingFlow } = fare;

  const passengers = farePassengers.map((pax) => paxUrlInfoToPassenger(pax));
  const airSegments = segments.map((segment) => segmentToAirSegment(segment));
  return {
    passengers,
    rateOptionId,
    searchId,
    segments: airSegments,
    bookingFlowType: tripBookingFlow,
  };
};

const segmentToAirSegment = (segment: IFlightSearchSegmentState<IAirSuggestion>): AirSegment => {
  const { date, destination, origin } = segment;

  return {
    departureDateTime: { iso8601: dateUtil(date).format(dateFormats.ISO) },
    destination: {
      airportCode: destination.code,
      airportName: destination.name,
      cityName: destination.city,
      countryCode: destination.countryCode,
      countryName: getCountryNameFromPlaceString(destination.place),
    },
    origin: {
      airportCode: origin.code,
      airportName: origin.name,
      cityName: origin.city,
      countryCode: origin.countryCode,
      countryName: getCountryNameFromPlaceString(origin.place),
    },
  };
};

const getCountryNameFromPlaceString = (place?: string): string | undefined => {
  const placeParts = place?.split(',');
  return last(placeParts)?.trim();
};

const paxUrlInfoToPassenger = (paxUrlInfo: RequiredPaxUrlInfo): Passenger => {
  const { paxAge, paxType, readOnly, userId } = paxUrlInfo;
  return {
    paxAge: paxAge ? { numYears: paxAge.years } : undefined,
    readOnly,
    paxType: paxTypeToPassengerType(paxType),
    userId,
  };
};

const paxTypeToPassengerType = (paxType: UrlPaxType): PassengerType => {
  /* istanbul ignore next */
  switch (paxType) {
    case PassengerTypeEnum.ADULT:
      return PassengerType.Adult;
    case PassengerTypeEnum.CHILD:
      return PassengerType.Child;
    case PassengerTypeEnum.INFANT:
      return PassengerType.Infant;
    case PassengerTypeEnum.INFANT_ON_LAP:
      return PassengerType.InfantOnLap;
    case PassengerTypeEnum.YOUTH:
      return PassengerType.Youth;
    case PassengerTypeEnum.SENIOR:
      return PassengerType.Senior;
    default:
      return PassengerType.UnknownPassengerType;
  }
};
