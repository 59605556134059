import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import CircularLoader from '@spotnana/pixel-react/dist/CircularLoader';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { useUserRingListQuery } from 'obt-common';
import { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import css from '@styled-system/css';

interface IProps {
  userEmail: string;
  onChange: () => void;
}

interface IMyRingProps {
  userEmail: string;
  onFetchStatusChange: (status: boolean) => void;
}

const MenuOption = styled(Box)(
  ({ theme }) =>
    css({
      height: '54px',
      backgroundColor: theme.colors.surface2,
      padding: '12px 24px 12px 24px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'regular',
      transition: 'regular',
    }) as unknown as CSSObject,
);

const MyRing = ({ userEmail, onFetchStatusChange }: IMyRingProps): JSX.Element => {
  const { isFetching, data: myRings } = useUserRingListQuery(userEmail);
  const myRing = myRings?.rings?.[0] || null;
  useEffect(() => {
    onFetchStatusChange(isFetching);
  });

  if (isFetching) {
    return <CircularLoader size={15} />;
  }
  return (
    <Typography variation="small" lineHeight="1.5">
      {myRing?.description || myRing?.name || '-'}
    </Typography>
  );
};

const HeaderRingMenuOption = ({ userEmail, onChange }: IProps): JSX.Element => {
  const [showChangeRing, setShowChangeRing] = useState(false);
  const onRingFetched = (status: boolean): void => {
    setShowChangeRing(!status);
  };
  const onClick = (): void => {
    // show rings modal
    onChange();
  };
  return (
    <MenuOption>
      <Flex flexDirection="column">
        <Typography color="text.secondary" variation="smaller" lineHeight="1.5">
          Product Version
        </Typography>
        <MyRing userEmail={userEmail} onFetchStatusChange={onRingFetched} />
      </Flex>
      {showChangeRing && (
        <Box role="button" cursor="pointer">
          <Typography color="primary" variation="smaller" onClick={onClick}>
            change
          </Typography>
        </Box>
      )}
    </MenuOption>
  );
};

export default HeaderRingMenuOption;
