import { useFeatureFlag } from '../feature-gating';
import usePartnerFeatures from './usePartnerFeatures';
import useUserFeatures from './useUserFeatures';

interface Result {
  isAdhocBookingEnabled: boolean;
  isSaveAdhocTravelerEnabled: boolean;
}

export default function useIsAdhocBookingEnabled(): Result {
  const isUserConfigBookingForGuestEnabled = useFeatureFlag('FE_CORP_BOOK_FOR_GUEST');

  const { features: companyFeatures } = usePartnerFeatures();
  const { features: userFeatures } = useUserFeatures();

  const { nonprofileBookingSearchPage, nonprofileBookingSaveTraveler } = companyFeatures;

  return {
    isAdhocBookingEnabled: isUserConfigBookingForGuestEnabled
      ? userFeatures.nonprofileBookingSearchPage
      : nonprofileBookingSearchPage,
    isSaveAdhocTravelerEnabled: isUserConfigBookingForGuestEnabled
      ? userFeatures.nonprofileBookingSaveTraveler
      : nonprofileBookingSaveTraveler,
  };
}
