import addRequestInterceptor from '../addRequestInterceptor';
import { getImpersonationToken } from '../../../utils/common';
import { IMPERSONATION_ACCESS_TOKEN } from '../../../constants/common';

function injectImpersonationTokenHeader() {
  return addRequestInterceptor({
    onFulfilled: async (config) => {
      const impersonationToken = await getImpersonationToken();

      if (impersonationToken) {
        config.headers[IMPERSONATION_ACCESS_TOKEN] = impersonationToken;
      }

      return config;
    },
  });
}

export default injectImpersonationTokenHeader;
