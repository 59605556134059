import { css } from '@emotion/react';
import { getFoundationVar } from '../../GlobalStyles/globalStylesUtils';

/**
 * Used to specify spacings/gaps between different elements in a container.
 * For eg - if you have a button with text and label, and you need to specify the gap to be 8px, then you can just use
 * ${spacings.small}
 * If you wish to know the spacing value represented by a key, say extraSmall, then you can just open globalStylesUtils.ts to look
 * for the value there, or just open dev tools and inspect the root html tag and look for the value of the css variable.
 */
export const spacings = {
  smallest: css`
    gap: var(${getFoundationVar('spacing-smallest')});
  `,
  extraSmall: css`
    gap: var(${getFoundationVar('spacing-extraSmall')});
  `,
  small: css`
    gap: var(${getFoundationVar('spacing-small')});
  `,
  medium: css`
    gap: var(${getFoundationVar('spacing-medium')});
  `,
  large: css`
    gap: var(${getFoundationVar('spacing-large')});
  `,
  extraLarge: css`
    gap: var(${getFoundationVar('spacing-extraLarge')});
  `,
  doubleXl: css`
    gap: var(${getFoundationVar('spacing-doubleXl')});
  `,
  largest: css`
    gap: var(${getFoundationVar('spacing-largest')});
  `,
};

export const cornerRadii = {
  outer: css`
    border-radius: var(${getFoundationVar('cornerRadii-outer')});
  `,
  standard: css`
    border-radius: var(${getFoundationVar('cornerRadii-standard')});
  `,
  small: css`
    border-radius: var(${getFoundationVar('cornerRadii-small')});
  `,
  buttonStandard: css`
    border-radius: var(${getFoundationVar('cornerRadii-buttonStandard')});
  `,
  buttonSmall: css`
    border-radius: var(${getFoundationVar('cornerRadii-buttonSmall')});
  `,
  modalStandard: css`
    border-radius: var(${getFoundationVar('cornerRadii-modalStandard')});
  `,
  full: css`
    border-radius: var(${getFoundationVar('cornerRadii-full')});
  `,
  tl: {
    outer: css`
      border-top-left-radius: var(${getFoundationVar('cornerRadii-outer')});
    `,
    standard: css`
      border-top-left-radius: var(${getFoundationVar('cornerRadii-standard')});
    `,
    small: css`
      border-top-left-radius: var(${getFoundationVar('cornerRadii-small')});
    `,
    buttonStandard: css`
      border-top-left-radius: var(${getFoundationVar('cornerRadii-buttonStandard')});
    `,
    buttonSmall: css`
      border-top-left-radius: var(${getFoundationVar('cornerRadii-buttonSmall')});
    `,
    full: css`
      border-top-left-radius: var(${getFoundationVar('cornerRadii-full')});
    `,
  },
  tr: {
    outer: css`
      border-top-right-radius: var(${getFoundationVar('cornerRadii-outer')});
    `,
    standard: css`
      border-top-right-radius: var(${getFoundationVar('cornerRadii-standard')});
    `,
    small: css`
      border-top-right-radius: var(${getFoundationVar('cornerRadii-small')});
    `,
    buttonStandard: css`
      border-top-right-radius: var(${getFoundationVar('cornerRadii-buttonStandard')});
    `,
    buttonSmall: css`
      border-top-right-radius: var(${getFoundationVar('cornerRadii-buttonSmall')});
    `,
    full: css`
      border-top-right-radius: var(${getFoundationVar('cornerRadii-full')});
    `,
  },
  bl: {
    outer: css`
      border-bottom-left-radius: var(${getFoundationVar('cornerRadii-outer')});
    `,
    standard: css`
      border-bottom-left-radius: var(${getFoundationVar('cornerRadii-standard')});
    `,
    small: css`
      border-bottom-left-radius: var(${getFoundationVar('cornerRadii-small')});
    `,
    buttonStandard: css`
      border-bottom-left-radius: var(${getFoundationVar('cornerRadii-buttonStandard')});
    `,
    buttonSmall: css`
      border-bottom-left-radius: var(${getFoundationVar('cornerRadii-buttonSmall')});
    `,
    full: css`
      border-bottom-left-radius: var(${getFoundationVar('cornerRadii-full')});
    `,
  },
  br: {
    outer: css`
      border-bottom-right-radius: var(${getFoundationVar('cornerRadii-outer')});
    `,
    standard: css`
      border-bottom-right-radius: var(${getFoundationVar('cornerRadii-standard')});
    `,
    small: css`
      border-bottom-right-radius: var(${getFoundationVar('cornerRadii-small')});
    `,
    buttonStandard: css`
      border-bottom-right-radius: var(${getFoundationVar('cornerRadii-buttonStandard')});
    `,
    buttonSmall: css`
      border-bottom-right-radius: var(${getFoundationVar('cornerRadii-buttonSmall')});
    `,
    full: css`
      border-bottom-right-radius: var(${getFoundationVar('cornerRadii-full')});
    `,
  },
};

/**
 * Please Note: The following padding keys are deprecated and will be phased out in future releases. 
 * We encourage you to update your code to use the new keys provided for better consistency and maintainability.
 
 * Deprecated keys:
 * - p0, p1, p1point25, p1point5, p2, p2point5, point25, point75, half
 
 * New keys:
 * - y.superTight, y.tight, y.compact, y.comfortable, y.cozy, y.extraCozy
 * - yt.superTight, yt.tight, yt.compact, yt.comfortable, yt.cozy, yt.extraCozy
 * - yb.superTight, yb.tight, yb.compact, yb.comfortable, yb.cozy, yb.extraCozy
 * - x.superTight, x.tight, x.compact, x.comfortable, x.cozy, x.extraCozy
 * - xl.superTight, xl.tight, xl.compact, xl.comfortable, xl.cozy, xl.extraCozy
 * - xr.superTight, xr.tight, xr.compact, xr.comfortable, xr.cozy, xr.extraCozy
 
 * Newer figmas will be using the new paddings keys. If you wish to know the padding value represented by a key,
   say superTight, then you can just open globalStylesUtils.ts to look for the value there, or just open dev tools and inspect
   the root html tag and look for the value of the css variable.

 */
export const paddings = {
  // y stands for both top & bottom, yt for top, and yb for bottom
  y: {
    none: css`
      padding-top: var(${getFoundationVar('padding-none')});
      padding-bottom: var(${getFoundationVar('padding-none')});
    `,
    extremeTight: css`
      padding-top: var(${getFoundationVar('padding-extremeTight')});
      padding-bottom: var(${getFoundationVar('padding-extremeTight')});
    `,
    superTight: css`
      padding-top: var(${getFoundationVar('padding-superTight')});
      padding-bottom: var(${getFoundationVar('padding-superTight')});
    `,
    tight: css`
      padding-top: var(${getFoundationVar('padding-tight')});
      padding-bottom: var(${getFoundationVar('padding-tight')});
    `,
    compact: css`
      padding-top: var(${getFoundationVar('padding-compact')});
      padding-bottom: var(${getFoundationVar('padding-compact')});
    `,
    comfortable: css`
      padding-top: var(${getFoundationVar('padding-comfortable')});
      padding-bottom: var(${getFoundationVar('padding-comfortable')});
    `,
    cozy: css`
      padding-top: var(${getFoundationVar('padding-cozy')});
      padding-bottom: var(${getFoundationVar('padding-cozy')});
    `,
    extraCozy: css`
      padding-top: var(${getFoundationVar('padding-extraCozy')});
      padding-bottom: var(${getFoundationVar('padding-extraCozy')});
    `,
    buttonSmall: css`
      padding-top: var(${getFoundationVar('padding-buttonSmall')});
      padding-bottom: var(${getFoundationVar('padding-buttonSmall')});
    `,
    buttonMedium: css`
      padding-top: var(${getFoundationVar('padding-buttonMedium')});
      padding-bottom: var(${getFoundationVar('padding-buttonMedium')});
    `,
  },
  yt: {
    none: css`
      padding-top: var(${getFoundationVar('padding-none')});
    `,
    extremeTight: css`
      padding-top: var(${getFoundationVar('padding-extremeTight')});
    `,
    superTight: css`
      padding-top: var(${getFoundationVar('padding-superTight')});
    `,
    tight: css`
      padding-top: var(${getFoundationVar('padding-tight')});
    `,
    compact: css`
      padding-top: var(${getFoundationVar('padding-compact')});
    `,
    comfortable: css`
      padding-top: var(${getFoundationVar('padding-comfortable')});
    `,
    cozy: css`
      padding-top: var(${getFoundationVar('padding-cozy')});
    `,
    extraCozy: css`
      padding-top: var(${getFoundationVar('padding-extraCozy')});
    `,
    buttonSmall: css`
      padding-top: var(${getFoundationVar('padding-buttonSmall')});
    `,
    buttonMedium: css`
      padding-top: var(${getFoundationVar('padding-buttonMedium')});
    `,
  },
  yb: {
    none: css`
      padding-bottom: var(${getFoundationVar('padding-none')});
    `,
    extremeTight: css`
      padding-bottom: var(${getFoundationVar('padding-extremeTight')});
    `,
    superTight: css`
      padding-bottom: var(${getFoundationVar('padding-superTight')});
    `,
    tight: css`
      padding-bottom: var(${getFoundationVar('padding-tight')});
    `,
    compact: css`
      padding-bottom: var(${getFoundationVar('padding-compact')});
    `,
    comfortable: css`
      padding-bottom: var(${getFoundationVar('padding-comfortable')});
    `,
    cozy: css`
      padding-bottom: var(${getFoundationVar('padding-cozy')});
    `,
    extraCozy: css`
      padding-bottom: var(${getFoundationVar('padding-extraCozy')});
    `,
    buttonSmall: css`
      padding-bottom: var(${getFoundationVar('padding-buttonSmall')});
    `,
    buttonMedium: css`
      padding-bottom: var(${getFoundationVar('padding-buttonMedium')});
    `,
  },
  // x stands for both left & right, xl for left, and xr for right
  x: {
    none: css`
      padding-left: var(${getFoundationVar('padding-none')});
      padding-right: var(${getFoundationVar('padding-none')});
    `,
    extremeTight: css`
      padding-left: var(${getFoundationVar('padding-extremeTight')});
      padding-right: var(${getFoundationVar('padding-extremeTight')});
    `,
    superTight: css`
      padding-left: var(${getFoundationVar('padding-superTight')});
      padding-right: var(${getFoundationVar('padding-superTight')});
    `,
    tight: css`
      padding-left: var(${getFoundationVar('padding-tight')});
      padding-right: var(${getFoundationVar('padding-tight')});
    `,
    compact: css`
      padding-left: var(${getFoundationVar('padding-compact')});
      padding-right: var(${getFoundationVar('padding-compact')});
    `,
    comfortable: css`
      padding-left: var(${getFoundationVar('padding-comfortable')});
      padding-right: var(${getFoundationVar('padding-comfortable')});
    `,
    cozy: css`
      padding-left: var(${getFoundationVar('padding-cozy')});
      padding-right: var(${getFoundationVar('padding-cozy')});
    `,
    extraCozy: css`
      padding-left: var(${getFoundationVar('padding-extraCozy')});
      padding-right: var(${getFoundationVar('padding-extraCozy')});
    `,
    buttonSmall: css`
      padding-left: var(${getFoundationVar('padding-buttonSmall')});
      padding-right: var(${getFoundationVar('padding-buttonSmall')});
    `,
    buttonMedium: css`
      padding-left: var(${getFoundationVar('padding-buttonMedium')});
      padding-right: var(${getFoundationVar('padding-buttonMedium')});
    `,
  },
  xl: {
    none: css`
      padding-left: var(${getFoundationVar('padding-none')});
    `,
    extremeTight: css`
      padding-left: var(${getFoundationVar('padding-extremeTight')});
    `,
    superTight: css`
      padding-left: var(${getFoundationVar('padding-superTight')});
    `,
    tight: css`
      padding-left: var(${getFoundationVar('padding-tight')});
    `,
    compact: css`
      padding-left: var(${getFoundationVar('padding-compact')});
    `,
    comfortable: css`
      padding-left: var(${getFoundationVar('padding-comfortable')});
    `,
    cozy: css`
      padding-left: var(${getFoundationVar('padding-cozy')});
    `,
    extraCozy: css`
      padding-left: var(${getFoundationVar('padding-extraCozy')});
    `,
    buttonSmall: css`
      padding-left: var(${getFoundationVar('padding-buttonSmall')});
    `,
    buttonMedium: css`
      padding-left: var(${getFoundationVar('padding-buttonMedium')});
    `,
  },
  xr: {
    none: css`
      padding-right: var(${getFoundationVar('padding-none')});
    `,
    extremeTight: css`
      padding-right: var(${getFoundationVar('padding-extremeTight')});
    `,
    superTight: css`
      padding-right: var(${getFoundationVar('padding-superTight')});
    `,
    tight: css`
      padding-right: var(${getFoundationVar('padding-tight')});
    `,
    compact: css`
      padding-right: var(${getFoundationVar('padding-compact')});
    `,
    comfortable: css`
      padding-right: var(${getFoundationVar('padding-comfortable')});
    `,
    cozy: css`
      padding-right: var(${getFoundationVar('padding-cozy')});
    `,
    extraCozy: css`
      padding-right: var(${getFoundationVar('padding-extraCozy')});
    `,
    buttonSmall: css`
      padding-right: var(${getFoundationVar('padding-buttonSmall')});
    `,
    buttonMedium: css`
      padding-right: var(${getFoundationVar('padding-buttonMedium')});
    `,
  },
};
