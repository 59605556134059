import { useMutation, useQuery } from 'react-query';
import type {
  BulkAssignAgentRequest,
  BulkAssignGroupRequest,
  BulkUpdateStatusRequest,
} from '@spotnana/types/openapi/models';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type { IAgentTasksFilter } from '../types/agent';
import type { AssignAgentRequest } from '../types/api/v2/obt/model/assign-agent-request';
import type { AssignAgentResponse } from '../types/api/v2/obt/model/assign-agent-response';
import type { AssignGroupRequest } from '../types/api/v2/obt/model/assign-group-request';
import type { GetActivitiesResponse } from '../types/api/v2/obt/model/get-activities-response';
import type { GetTaskTypesResponse } from '../types/api/v2/obt/model/get-task-types-response';
import type { GetTaskAggregationsResponse } from '../types/api/v2/obt/model/get-tasks-aggregations-response';
import type { ListTasksRequest } from '../types/api/v2/obt/model/list-tasks-request';
import type { ListTasksResponse } from '../types/api/v2/obt/model/list-tasks-response';
import type { PostActivityRequest } from '../types/api/v2/obt/model/post-activity-request';
import type { PostActivityResponse } from '../types/api/v2/obt/model/post-activity-response';
import type { ReadAgentAvailabilityStatusResponse } from '../types/api/v2/obt/model/read-agent-availability-status-response';
import type { UpdateAgentAvailabilityStatusRequest } from '../types/api/v2/obt/model/update-agent-availability-status-request';
import type { UpdateStatusRequest } from '../types/api/v2/obt/model/update-status-request';
import type { UpdateStatusResponse } from '../types/api/v2/obt/model/update-status-response';
import type { SpotnanaQueryMutationResult, SpotnanaQueryResult } from '../types/common';
import { defaultQueryClient } from './defaultQueryClient';
import type { CreateScheduledAgentTaskRequest } from '../types/api/v2/obt/model/create-scheduled-agent-task-request';
import type { GetCompanionSupplierInfoResponse } from '../types/api/v2/obt/model/get-companion-supplier-info-response';
import type { GetTaskEventChecklistResponse } from '../types/api/v2/obt/model/get-task-event-checklist-response';
import type { UpdateTaskEventChecklistRequest } from '../types/api/v2/obt/model/update-task-event-checklist-request';
import { stringifyParams } from '../utils/urlUtils';

// Agent tasks

const getAgentTasks = async (request: ListTasksRequest): Promise<ListTasksResponse> => {
  try {
    const data = await api(
      'POST',
      'agentTasks',
      { urlParam: '/list', data: request },
      { subType: 'agentDesktopTasks' },
    );
    return data as ListTasksResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const commonAgentTaskKey = 'agentTasks';
const agentTasksKey = (request: IAgentTasksFilter): [string, IAgentTasksFilter] => [commonAgentTaskKey, request];

export const useAgentTasksQuery = (
  request: IAgentTasksFilter,
  enabled?: boolean,
): SpotnanaQueryResult<ListTasksResponse> =>
  useQuery(agentTasksKey(request), () => getAgentTasks(request), {
    keepPreviousData: true,
    enabled,
  });

export const invalidateAgentTasks = (): void => {
  defaultQueryClient.invalidateQueries(commonAgentTaskKey);
};

interface IAgentTasksRequest {
  view?: 'SEAT1A_VIEW' | 'DEFAULT';
}

// Agent tasks aggregations
const getAgentTasksAggregations = async ({
  view,
}: IAgentTasksRequest): Promise<GetTaskAggregationsResponse['aggregations'] | undefined> => {
  const queryParams = stringifyParams({
    ...(view ? { view } : {}),
  });

  try {
    const data = await api(
      'GET',
      'agentTasks',
      {
        urlParam: `/aggregations?${queryParams}`,
      },
      { subType: 'agentDesktopTasksAggregation' },
    );
    const result = data as GetTaskAggregationsResponse;
    return result.aggregations;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const agentTasksAggregationKey = 'agentTasks-aggregations';

const getAgentTasksAggregationKey = (view?: string): [string, string | undefined] => [agentTasksAggregationKey, view];

export const useAgentTasksAggregationsQuery = (
  request: IAgentTasksRequest,
): SpotnanaQueryResult<GetTaskAggregationsResponse['aggregations'] | undefined> =>
  useQuery(getAgentTasksAggregationKey(request.view), () => getAgentTasksAggregations(request));

export const invalidateAgentTasksAggregations = (): void => {
  defaultQueryClient.invalidateQueries(agentTasksAggregationKey);
};

// Agent tasks types
const getAgentTaskTypes = async (): Promise<GetTaskTypesResponse | undefined> => {
  try {
    const data = await api(
      'GET',
      'agentTasks',
      {
        urlParam: '/task-types',
      },
      { subType: 'agentDesktopTasksType' },
    );
    const result = data as GetTaskTypesResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const agentTaskTypeKey = 'agentTasks-type';
export const useAgentTasksTypeQuery = (): SpotnanaQueryResult<GetTaskTypesResponse | undefined> =>
  useQuery([agentTaskTypeKey], () => getAgentTaskTypes());

export const invalidateAgentTasksType = (): void => {
  defaultQueryClient.invalidateQueries(agentTaskTypeKey);
};

// Agent task activities and comments

const getAgentTaskActivities = async (taskId: string): Promise<GetActivitiesResponse['activities'] | undefined> => {
  try {
    const data = await api(
      'GET',
      'agentTasks',
      {
        urlParam: `/${taskId}/activities`,
      },
      { subType: 'agentDesktopTaskActivities' },
    );
    const result = data as GetActivitiesResponse;
    return result.activities;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const agentTaskActivitiesKey = 'agentTasks-activities';
const getAgentTaskActivitiesKey = (taskId: string): [string, string] => [agentTaskActivitiesKey, taskId];

export const useAgentTaskActivitiesQuery = (
  taskId: string,
): SpotnanaQueryResult<GetActivitiesResponse['activities'] | undefined> =>
  useQuery(getAgentTaskActivitiesKey(taskId), () => getAgentTaskActivities(taskId), {
    keepPreviousData: true,
  });

export const invalidateTaskActivities = (taskId: string): void => {
  defaultQueryClient.invalidateQueries([agentTaskActivitiesKey, taskId]);
};

const createAgentTaskComment = async (taskId: string, request: PostActivityRequest): Promise<PostActivityResponse> => {
  try {
    const data = await api(
      'POST',
      'agentTasks',
      {
        urlParam: `/${taskId}/activities`,
        data: request,
      },
      { subType: 'agentDesktopTaskComments' },
    );
    return data as PostActivityResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export interface IUseCreateAgentTaskCommentRequest {
  taskId: string;
  request: PostActivityRequest;
}

export const useCreateAgentTaskCommentMutationQuery = (): SpotnanaQueryMutationResult<
  PostActivityResponse,
  IUseCreateAgentTaskCommentRequest
> =>
  useMutation(({ taskId, request }: IUseCreateAgentTaskCommentRequest) => createAgentTaskComment(taskId, request), {
    onSuccess(_, { taskId }) {
      invalidateTaskActivities(taskId);
    },
  });

// Update task status

interface IUpdateTaskStatusRequest {
  taskId: string;
  request: UpdateStatusRequest;
}

const agentUpdateTaskStatusKey = 'agent-update-task-status';
const getUpdateStatusUrl = (taskId: string): string => `/${taskId}/update-status`;
const updateTaskStatus = async (
  taskId: string,
  request: UpdateStatusRequest | undefined,
): Promise<UpdateStatusResponse> => {
  try {
    const data = await api(
      'POST',
      'agentTasks',
      {
        urlParam: getUpdateStatusUrl(taskId),
        data: request,
      },
      { subType: 'agentDesktopUpdateTaskStatus' },
    );
    return data as UpdateStatusResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const invalidateUpdateTaskStatus = (taskId: string): void => {
  defaultQueryClient.invalidateQueries([agentUpdateTaskStatusKey, taskId]);
};

export const useUpdateAgentTaskStatusMutation = (): SpotnanaQueryMutationResult<
  UpdateStatusResponse,
  IUpdateTaskStatusRequest
> =>
  useMutation(({ taskId, request }: IUpdateTaskStatusRequest) => updateTaskStatus(taskId, request), {
    onSuccess(_, { taskId }) {
      invalidateUpdateTaskStatus(taskId);
    },
  });

// Assign agent request

interface IAssignAgentRequest {
  taskId: string;
  request: AssignAgentRequest;
}

const assignAgentTaskKey = 'assign-agent-task';
const getAssignAgentUrl = (taskId: string): string => `/${taskId}/assign-agent`;
const assignAgent = async (taskId: string, request: AssignAgentRequest | undefined): Promise<AssignAgentResponse> => {
  try {
    const data = await api(
      'POST',
      'agentTasks',
      {
        urlParam: getAssignAgentUrl(taskId),
        data: request,
      },
      { subType: 'agentDesktopAssignAgent' },
    );
    return data as AssignAgentResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const invalidateAssignAgent = (taskId: string): void => {
  defaultQueryClient.invalidateQueries([assignAgentTaskKey, taskId]);
};

export const useAssignAgentTaskMutation = (): SpotnanaQueryMutationResult<AssignAgentResponse, IAssignAgentRequest> =>
  useMutation(({ taskId, request }: IAssignAgentRequest) => assignAgent(taskId, request), {
    onSuccess(_, { taskId }) {
      invalidateAssignAgent(taskId);
    },
  });

// Assign group request

interface IAssignGroupRequest {
  taskId: string;
  request: AssignGroupRequest;
}

const assignGroupKey = 'assign-group';
const getAssignGroup = (taskId: string): string => `/${taskId}/assign-group`;
const assignGroup = async (taskId: string, request: AssignGroupRequest | undefined): Promise<void> => {
  try {
    await api(
      'POST',
      'agentTasks',
      {
        urlParam: getAssignGroup(taskId),
        data: request,
      },
      { subType: 'agentDesktopAssignGroup' },
    );
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const invalidateAssignGroup = (taskId: string): void => {
  defaultQueryClient.invalidateQueries([assignGroupKey, taskId]);
};

export const useAssignGroupMutation = (): SpotnanaQueryMutationResult<void, IAssignGroupRequest> =>
  useMutation(({ taskId, request }: IAssignGroupRequest) => assignGroup(taskId, request), {
    onSuccess(_, { taskId }) {
      invalidateAssignGroup(taskId);
      invalidateTaskActivities(taskId);
      invalidateAgentTasks();
    },
  });

// Agent status availability

const agentStatusAvailabilityKey = 'agent-status-availability';
const getAgentStatusAvailabilityKey = (id: string): [string, string] => [agentStatusAvailabilityKey, id];
const getAgentStatusAvailability = async (id: string): Promise<ReadAgentAvailabilityStatusResponse> => {
  try {
    const data = await api('GET', 'agent', {
      urlParam: `/${id}/availability-status`,
    });
    return data as ReadAgentAvailabilityStatusResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useAgentStatusAvailabilityQuery = (
  id: string,
  enabled: boolean,
): SpotnanaQueryResult<ReadAgentAvailabilityStatusResponse | undefined> =>
  useQuery(getAgentStatusAvailabilityKey(id), () => getAgentStatusAvailability(id), { enabled });

export const invalidateAgentStatusAvailability = (id: string): void => {
  defaultQueryClient.invalidateQueries([agentStatusAvailabilityKey, id]);
};
interface IUpdateAgentAvailabilityStatusRequest {
  id: string;
  request: UpdateAgentAvailabilityStatusRequest;
}

const updateAgentStatusAvailability = async (
  id: string,
  request: UpdateAgentAvailabilityStatusRequest,
): Promise<void> => {
  try {
    await api('PUT', 'agent', {
      urlParam: `/${id}/availability-status`,
      data: request,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useUpdateAgentStatusAvailability = (): SpotnanaQueryMutationResult<
  void,
  IUpdateAgentAvailabilityStatusRequest
> =>
  useMutation(({ id, request }: IUpdateAgentAvailabilityStatusRequest) => updateAgentStatusAvailability(id, request), {
    onSuccess(_, { id }) {
      invalidateAgentStatusAvailability(id);
    },
  });

const addAgentTask = async (request: CreateScheduledAgentTaskRequest): Promise<void> => {
  try {
    await api('POST', 'agentTasks', {
      urlParam: '/schedule',
      data: request,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useAddAgentTaskMutation = (): SpotnanaQueryMutationResult<void, CreateScheduledAgentTaskRequest> =>
  useMutation((request) => addAgentTask(request), {
    onSuccess: () => {
      invalidateAgentTasks();
    },
  });

const getSupplierByTravelerId = async (travelerId: string): Promise<GetCompanionSupplierInfoResponse> => {
  try {
    const data = await api('GET', 'agentCompanion', {
      urlParam: `/${travelerId}/supplier-info`,
    });
    const result = data as GetCompanionSupplierInfoResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useSupplierInfoQuery = (
  travelerId: string,
  enabled = true,
): SpotnanaQueryResult<GetCompanionSupplierInfoResponse | undefined> =>
  useQuery(travelerId, () => getSupplierByTravelerId(travelerId), { enabled });

const getTaskEventsChecklist = async (taskEventId: string): Promise<GetTaskEventChecklistResponse> => {
  try {
    const data = await api('GET', 'agentTaskEvents', {
      urlParam: `/${taskEventId}/checklist`,
    });
    const result = data as GetTaskEventChecklistResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useTaskEventsChecklistQuery = (
  taskEventId: string,
  enabled = true,
): SpotnanaQueryResult<GetTaskEventChecklistResponse | undefined> =>
  useQuery(taskEventId, () => getTaskEventsChecklist(taskEventId), { enabled, keepPreviousData: true });

const updateTaskEventsChecklist = async (
  taskEventId: string,
  reqData: UpdateTaskEventChecklistRequest,
): Promise<void> => {
  try {
    await api('PUT', 'agentTaskEvents', {
      urlParam: `/${taskEventId}/checklist`,
      data: reqData,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};
interface IUpdateTaskEventChecklistRequest {
  taskEventId: string;
  request: UpdateTaskEventChecklistRequest;
}
export const useUpdateTaskEventsChecklist = (): SpotnanaQueryMutationResult<void, IUpdateTaskEventChecklistRequest> =>
  useMutation(
    ({ taskEventId, request }: IUpdateTaskEventChecklistRequest) => updateTaskEventsChecklist(taskEventId, request),
    {
      onSuccess: (_, { taskEventId }) => {
        defaultQueryClient.invalidateQueries(taskEventId);
      },
    },
  );

interface IUseBulkAssignAgent {
  tmcId: string;
  request: BulkAssignAgentRequest;
}

const bulkAssignAgent = async ({ tmcId, request }: IUseBulkAssignAgent) => {
  try {
    await api('POST', 'agentQueueDashboard', {
      urlParam: `/${tmcId}/tasks/assign-agent`,
      data: request,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useBulkAssignAgent = () =>
  useMutation(({ tmcId, request }: IUseBulkAssignAgent) => bulkAssignAgent({ tmcId, request }), {
    onSuccess: (_) => {
      invalidateAgentTasks();
    },
  });

interface IUseBulkAssignGroup {
  tmcId: string;
  request: BulkAssignGroupRequest;
}

const bulkAssignGroup = async ({ tmcId, request }: IUseBulkAssignGroup) => {
  try {
    await api('POST', 'agentQueueDashboard', {
      urlParam: `/${tmcId}/tasks/assign-group`,
      data: request,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useBulkAssignGroup = () =>
  useMutation(({ tmcId, request }: IUseBulkAssignGroup) => bulkAssignGroup({ tmcId, request }), {
    onSuccess: (_) => {
      invalidateAgentTasks();
    },
  });

interface IUseBulkUpdateStatus {
  tmcId: string;
  request: BulkUpdateStatusRequest;
}

const bulkUpdateStatus = async ({ tmcId, request }: IUseBulkUpdateStatus) => {
  try {
    await api('POST', 'agentQueueDashboard', {
      urlParam: `/${tmcId}/tasks/update-status`,
      data: request,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useBulkUpdateStatus = () =>
  useMutation(({ tmcId, request }: IUseBulkUpdateStatus) => bulkUpdateStatus({ tmcId, request }), {
    onSuccess: (_) => {
      invalidateAgentTasks();
    },
  });
