var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Cancellation = (_a) => {
    var { primaryColor } = _a, restProps = __rest(_a, ["primaryColor"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 32 32", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("path", { d: "M8.93 8.929c3.904-3.905 10.236-3.905 14.14 0 3.906 3.905 3.906 10.236 0 14.142-3.904 3.905-10.236 3.905-14.14 0-3.906-3.906-3.906-10.237 0-14.142zm12.727 1.414c-3.124-3.124-8.19-3.124-11.314 0-3.124 3.124-3.124 8.19 0 11.314 3.124 3.124 8.19 3.124 11.314 0 3.124-3.125 3.124-8.19 0-11.314zm-8.368 1.538l.064.058L16 14.585l2.647-2.646c.39-.39 1.024-.39 1.414 0 .37.37.39.958.058 1.35l-.058.064L17.414 16l2.647 2.647c.39.391.39 1.024 0 1.415-.37.37-.958.39-1.35.058l-.064-.058L16 17.414l-2.646 2.647c-.391.39-1.024.39-1.415 0-.37-.37-.39-.958-.058-1.35l.058-.064L14.585 16l-2.646-2.647c-.39-.39-.39-1.023 0-1.414.37-.37.958-.39 1.35-.058z" })));
};
Cancellation.defaultProps = defaultIconProps;
export default Cancellation;
