import { css } from '@emotion/react';
import { breakpoints, paddings, themed, flex, unit } from '@spotnana/blocks/src/utils';

export const banner_message_container = themed(
  ({ palette }) => css`
    background-color: ${palette.text.linkHover};
    border-bottom: 1px solid ${palette.border.light};
    ${flex.init}
    ${flex.align.start}
    ${paddings.xl.p1point5}
    ${paddings.xr.p1}
    ${paddings.y.p1}
    ${breakpoints.from('tablet')} {
      ${paddings.y.point75}
      ${paddings.x.p2point5}
      ${flex.align.center}
      column-gap: ${unit(1)};
    }
  `,
);

export const banner_message = css`
  ${flex.init}
  ${flex.column}
  flex-grow: 1;
  row-gap: ${unit(0.75)};
  column-gap: ${unit(1)};
  ${breakpoints.from('tablet')} {
    ${flex.row}
    ${flex.justify.between}
    ${flex.align.center}
  }
`;

export const banner_message_title = css`
  margin-bottom: ${unit(0.25)};
`;

export const banner_message_close_icon = themed(
  ({ palette }) => css`
    color: ${palette.text.inverse};
  `,
);

export const banner_message_button = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.base};
    color: ${palette.text.brand};
    white-space: nowrap;
    &:hover {
      background-color: ${palette.surface.base};
      cursor: pointer;
    }
    ${breakpoints.from('tablet')} {
      align-self: center;
    }
  `,
);

export const banner_message_expand = themed(
  ({ palette }) => css`
    margin-top: ${unit(0.25)};
  `,
);

export const banner_message_clamp = css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;
