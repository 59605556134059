import { Ellipsis } from '@spotnana/blocks/src/Ellipsis/Ellipsis';
import { Typography } from '@spotnana/blocks/src/Typography/Typography';
import { RBAC } from 'obt-common/components/RBAC/RBAC';
import { useFeatureFlag } from 'obt-common/feature-gating/hooks';
import { RoleInfoTypeEnum } from 'obt-common/types/api/v1/obt/profile/role/roles_info';
import { TmcBasicInfo } from 'obt-common/types/api/v2/obt/model/tmc-basic-info';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { traveler_picker_tmc_info } from './styles';

interface IProps {
  tmcBasicInfo: TmcBasicInfo | null;
}

const TmcLabel: FC<IProps> = ({ tmcBasicInfo }) => {
  const { t: tt } = useTranslation('WEB');
  const isDisplayCompanyNameLogoEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_TMC_NAME');

  if (!tmcBasicInfo?.contractingTmc?.name) {
    return null;
  }

  const contractingTmcName = tmcBasicInfo.contractingTmc.name;

  return (
    <RBAC
      allowedRoles={
        isDisplayCompanyNameLogoEnabled ? [RoleInfoTypeEnum.GLOBAL_ADMIN, RoleInfoTypeEnum.GLOBAL_AGENT] : []
      }
    >
      {contractingTmcName && (
        <Typography
          css={traveler_picker_tmc_info}
          as="span"
          variant="body3"
          color="secondary"
          title={tt('TMC: {{tmc}}', { tmc: contractingTmcName })}
        >
          {isDisplayCompanyNameLogoEnabled ? (
            <Ellipsis title={tt('TMC: {{tmc}}', { tmc: contractingTmcName })}>
              {tt('TMC: {{tmc}}', { tmc: contractingTmcName })}
            </Ellipsis>
          ) : (
            contractingTmcName
          )}
        </Typography>
      )}
    </RBAC>
  );
};

export default TmcLabel;
