import { defineCommonMessage } from '../../translations/defineMessage';
import type { ManageForOptionsType, RoleFilterType, RoleOptionType } from '../../types/admin';
import { CustomRoleInfoType } from '../../types/admin';
import { RoleInfoTypeEnum } from '../../types/api/v1/obt/profile/role/roles_info';

export enum roleOptionTranslationKeys {
  TRAVELER_DESCRIPTION = 'TRAVELER_DESCRIPTION',
  ARRANGER_DESCRIPTION = 'ARRANGER_DESCRIPTION',
  COMPANY_ADMIN_DESCRIPTION = 'COMPANY_ADMIN_DESCRIPTION',
  SPOTNANA_AGENT__DESCRIPTION = 'SPOTNANA_AGENT__DESCRIPTION',
  SPOTNANA_ADMIN_DESCRIPTION = 'SPOTNANA_ADMIN_DESCRIPTION',
  TRAVELER_LABEL = 'TRAVELER_LABEL',
  ARRANGER_LABEL = 'ARRANGER_LABEL',
  COMPANY_ADMIN_LABEL = 'COMPANY_ADMIN_LABEL',
  GLOBAL_ADMIN_LABEL = 'GLOBAL_ADMIN_LABEL',
  COMPANY_TRAVEL_ARRANGER_LABEL = 'COMPANY_TRAVEL_ARRANGER_LABEL',
  TRAVEL_ARRANGER_LABEL = 'TRAVEL_ARRANGER_LABEL',
  SPOTNANA_AGENT_LABEL = 'SPOTNANA_AGENT_LABEL',
  SPOTNANA_ADMIN_LABEL = 'SPOTNANA_ADMIN_LABEL',
  GLOBAL_AGENT_LABEL = 'GLOBAL_AGENT_LABEL',
  EVERYONE_LABEL = 'EVERYONE_LABEL',
  INDIVIDUALS_LABEL = 'INDIVIDUALS_LABEL',
  TMC_ADMIN_LABEL = 'TMC_ADMIN_LABEL',
  TMC_AGENT_LABEL = 'TMC_AGENT_LABEL',
  TMC_ADMIN_DESCRIPTION = 'TMC_ADMIN_DESCRIPTION',
  TMC_AGENT_DESCRIPTION = 'TMC_AGENT_DESCRIPTION',
}

export const roleOptions: RoleOptionType[] = [
  {
    label: roleOptionTranslationKeys.TRAVELER_LABEL,
    description: roleOptionTranslationKeys.TRAVELER_DESCRIPTION,
    value: CustomRoleInfoType.TRAVELER,
    backEndValue: null,
    filterValue: 'TRAVELER',
  },
  {
    label: roleOptionTranslationKeys.TRAVEL_ARRANGER_LABEL,
    description: roleOptionTranslationKeys.ARRANGER_DESCRIPTION,
    value: CustomRoleInfoType.ARRANGER,
    manageFor: {
      label: roleOptionTranslationKeys.EVERYONE_LABEL,
      value: 'everyone',
    },
    backEndValue: [RoleInfoTypeEnum.COMPANY_TRAVEL_ARRANGER, RoleInfoTypeEnum.TRAVEL_ARRANGER],
    filterValue: 'TRAVEL_ARRANGER',
  },
  {
    label: roleOptionTranslationKeys.COMPANY_ADMIN_LABEL,
    description: roleOptionTranslationKeys.COMPANY_ADMIN_DESCRIPTION,
    value: CustomRoleInfoType.COMPANY_ADMIN,
    backEndValue: [RoleInfoTypeEnum.COMPANY_ADMIN],
    filterValue: 'COMPANY_ADMIN',
  },
  {
    label: roleOptionTranslationKeys.SPOTNANA_AGENT_LABEL,
    description: roleOptionTranslationKeys.SPOTNANA_AGENT__DESCRIPTION,
    value: CustomRoleInfoType.SPOTNANA_AGENT,
    backEndValue: [RoleInfoTypeEnum.GLOBAL_AGENT],
    filterValue: 'GLOBAL_AGENT',
  },
  {
    label: roleOptionTranslationKeys.SPOTNANA_ADMIN_LABEL,
    description: roleOptionTranslationKeys.SPOTNANA_ADMIN_DESCRIPTION,
    value: CustomRoleInfoType.SPOTNANA_ADMIN,
    backEndValue: [RoleInfoTypeEnum.GLOBAL_ADMIN],
    filterValue: 'GLOBAL_ADMIN',
  },
  {
    label: roleOptionTranslationKeys.TMC_ADMIN_LABEL,
    description: roleOptionTranslationKeys.TMC_ADMIN_DESCRIPTION,
    value: CustomRoleInfoType.TMC_ADMIN,
    backEndValue: [RoleInfoTypeEnum.TMC_ADMIN],
    filterValue: 'TMC_ADMIN',
  },
  {
    label: roleOptionTranslationKeys.TMC_AGENT_LABEL,
    description: roleOptionTranslationKeys.TMC_AGENT_DESCRIPTION,
    value: CustomRoleInfoType.TMC_AGENT,
    backEndValue: [RoleInfoTypeEnum.TMC_AGENT],
    filterValue: 'TMC_AGENT',
  },
];

export const manageForOptions: ManageForOptionsType[] = [
  {
    label: roleOptionTranslationKeys.EVERYONE_LABEL,
    value: 'everyone',
  },
  {
    label: roleOptionTranslationKeys.INDIVIDUALS_LABEL,
    value: 'individuals',
  },
];

export const roleFilters: RoleFilterType[] = [
  {
    label: roleOptionTranslationKeys.TRAVELER_LABEL,
    backEndValue: RoleInfoTypeEnum.UNRECOGNIZED,
    value: 'TRAVELER',
  },
  {
    label: roleOptionTranslationKeys.TRAVEL_ARRANGER_LABEL,
    backEndValue: RoleInfoTypeEnum.TRAVEL_ARRANGER,
    value: 'TRAVEL_ARRANGER',
  },
  {
    label: roleOptionTranslationKeys.COMPANY_TRAVEL_ARRANGER_LABEL,
    backEndValue: RoleInfoTypeEnum.COMPANY_TRAVEL_ARRANGER,
    value: 'COMPANY_TRAVEL_ARRANGER',
  },
  {
    label: roleOptionTranslationKeys.COMPANY_ADMIN_LABEL,
    backEndValue: RoleInfoTypeEnum.COMPANY_ADMIN,
    value: 'COMPANY_ADMIN',
  },
  {
    label: roleOptionTranslationKeys.SPOTNANA_AGENT_LABEL,
    backEndValue: RoleInfoTypeEnum.GLOBAL_AGENT,
    value: 'GLOBAL_AGENT',
  },
  {
    label: roleOptionTranslationKeys.SPOTNANA_ADMIN_LABEL,
    backEndValue: RoleInfoTypeEnum.GLOBAL_ADMIN,
    value: 'GLOBAL_ADMIN',
  },
  {
    label: roleOptionTranslationKeys.TMC_ADMIN_LABEL,
    backEndValue: RoleInfoTypeEnum.TMC_ADMIN,
    value: 'TMC_ADMIN',
  },
  {
    label: roleOptionTranslationKeys.TMC_AGENT_LABEL,
    backEndValue: RoleInfoTypeEnum.TMC_AGENT,
    value: 'TMC_AGENT',
  },
];

export const translatedRoleLabels = {
  [CustomRoleInfoType.TRAVELER]: defineCommonMessage('Traveler'),
  [CustomRoleInfoType.ARRANGER]: defineCommonMessage('Arranger'),
  [CustomRoleInfoType.COMPANY_ADMIN]: defineCommonMessage('Company Admin'),
  [CustomRoleInfoType.SPOTNANA_AGENT]: defineCommonMessage('Spotnana Agent'),
  [CustomRoleInfoType.SPOTNANA_ADMIN]: defineCommonMessage('Spotnana Admin'),
  [CustomRoleInfoType.TMC_ADMIN]: defineCommonMessage('TMC Admin'),
  [CustomRoleInfoType.TMC_AGENT]: defineCommonMessage('TMC Agent'),
};
