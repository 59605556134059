import { CheckoutContextWrapper } from 'obt-common/types/api/v2/obt/model/checkout-context-wrapper';
import { City } from 'obt-common/types/api/v2/obt/model/city';
import { SearchResultContextWrapper } from 'obt-common/types/api/v2/obt/model/search-result-context-wrapper';
import {
  IWebAirSearchUrlParams,
  IHotelSearchResultsUrlParams,
  ICarSearchResultsUrlParamsV2,
  ICarCheckoutUrlParams,
  IRailSearchUrlParams,
  IRailCheckoutUrlParams,
  BookingType,
} from 'obt-common/types';
import { Itinerary } from 'obt-common/types/api/v2/obt/model/itinerary';
import { CheckoutContext } from 'obt-common/types/api/v2/obt/model/checkout-context';

import { IHotelCheckoutUrlState } from '../../v1-components/hotels/checkout';

interface CityCountryContext {
  airportCodes?: string[];
  cities: City[];
  countries: string[];
}

type SearchParams =
  | IWebAirSearchUrlParams
  | IHotelSearchResultsUrlParams
  | ICarSearchResultsUrlParamsV2
  | IRailSearchUrlParams
  | { searchParams: IHotelSearchResultsUrlParams };

const AIRPORT_TYPES = ['AIRPORT', 'CITY_AIRPORT'];

// Returns only the city name from the full place name.
// e.g. for 'Seattle, Washington, United States' it returns 'Seattle'.
const getOnlyCityName = (fullPlaceName: string | undefined) => {
  return fullPlaceName?.split(',')[0] ?? '';
};

const getSearchResultContextFromAirSearchParams = (params?: IWebAirSearchUrlParams): CityCountryContext => {
  const airportCodes: string[] = [];
  const cities: City[] = [];
  const countries: string[] = [];

  if (!params?.segments) return { airportCodes, cities, countries };

  // Air search can have multiple segments and cities/countries so we need to
  // map over all of them.
  params.segments.forEach((segment) => {
    // Updated search api uses arrays for origin and destination.
    // Either both origin/destination use the new format (array), or both use the old format (singleton).
    if (Array.isArray(segment.origin)) {
      segment.origin.forEach((originObj) => {
        const { type, code, place: originCityName, countryCode: originCountryCode } = originObj;
        // If the selected location type is AIRPORT, send the airport code.
        if (type && AIRPORT_TYPES.includes(type) && code) {
          airportCodes.push(code);
        }
        cities.push({
          cityName: getOnlyCityName(originCityName),
          countryCode: originCountryCode ?? '',
          administrativeDivCode: '',
        });
        countries.push(originCountryCode ?? '');
      });
    }
    if (Array.isArray(segment.destination)) {
      segment.destination.forEach((destObj) => {
        const { type, code, place: destinationCityName, countryCode: destinationCountryCode } = destObj;
        if (type && AIRPORT_TYPES.includes(type) && code) {
          airportCodes.push(code);
        }
        cities.push({
          cityName: getOnlyCityName(destinationCityName),
          countryCode: destinationCountryCode ?? '',
          administrativeDivCode: '',
        });
        countries.push(destinationCountryCode ?? '');
      });
    }

    // If origin and destination are not arrays, then they are objects.
    if (
      !Array.isArray(segment.origin) &&
      !Array.isArray(segment.destination) &&
      segment.origin && // These should always be defined, but according to Sentry, sometimes they are not
      segment.destination
    ) {
      // Get the place and country code values from the segment origin and destination.
      const {
        type: originType,
        code: originCode,
        place: originCityName,
        countryCode: originCountryCode,
      } = segment.origin;
      const {
        type: destinationType,
        code: destinationCode,
        place: destinationCityName,
        countryCode: destinationCountryCode,
      } = segment.destination;

      // If the type is AIRPORT, push the code to the airportCodes array.
      if (originType && AIRPORT_TYPES.includes(originType) && originCode) {
        airportCodes.push(originCode);
      }
      if (destinationType && AIRPORT_TYPES.includes(destinationType) && destinationCode) {
        airportCodes.push(destinationCode);
      }
      // Map the values to the City type attributes and add to the cities array.
      // Set administrativeDivCode to empty string since backend needs it to be present.
      cities.push(
        { cityName: getOnlyCityName(originCityName), countryCode: originCountryCode ?? '', administrativeDivCode: '' },
        {
          cityName: getOnlyCityName(destinationCityName),
          countryCode: destinationCountryCode ?? '',
          administrativeDivCode: '',
        },
      );
      // Add the country codes to the countries array.
      countries.push(originCountryCode ?? '', destinationCountryCode ?? '');
    }
  });

  return { airportCodes, cities, countries: countries.filter((country) => country) };
};

const getSearchResultContextFromHotelSearchParams = (params?: IHotelSearchResultsUrlParams): CityCountryContext => {
  const airportCodes: string[] = [];
  if (params?.type && AIRPORT_TYPES.includes(params.type) && params?.data) {
    airportCodes.push(params.data);
  }
  const city: City = {
    cityName: params?.city ?? params?.name ?? '',
    countryCode: params?.countryCode ?? '',
    administrativeDivCode: params?.address?.administrativeArea ?? '',
  };
  const countries: string[] = params?.countryCode ? [params.countryCode] : [];

  return { airportCodes, cities: [city], countries: countries.filter((country) => country) };
};

const getSearchResultContextFromCarSearchParams = (params?: ICarSearchResultsUrlParamsV2): CityCountryContext => {
  const airportCodes: string[] = [];
  const cities: City[] = [];
  const countries: string[] = [];

  if (!params) return { airportCodes, cities, countries };

  if (
    params?.pickupLocation?.type &&
    AIRPORT_TYPES.includes(params.pickupLocation.type) &&
    params?.pickupLocation?.data
  ) {
    airportCodes.push(params.pickupLocation?.data);
  }

  if (
    params?.dropOffLocation?.type &&
    AIRPORT_TYPES.includes(params.dropOffLocation.type) &&
    params?.dropOffLocation?.data
  ) {
    airportCodes.push(params.dropOffLocation?.data);
  }

  cities.push(
    {
      cityName: params?.pickupLocation?.city ?? '',
      countryCode: params?.pickupLocation?.countryCode ?? '',
      administrativeDivCode: params?.pickupLocation?.address?.administrativeArea ?? '',
    },
    {
      cityName: params?.dropOffLocation?.city ?? '',
      countryCode: params?.dropOffLocation?.countryCode ?? '',
      administrativeDivCode: params?.dropOffLocation?.address?.administrativeArea ?? '',
    },
  );
  countries.push(params?.pickupLocation?.countryCode ?? '', params?.dropOffLocation?.countryCode ?? '');

  return { airportCodes, cities, countries: countries.filter((country) => country) };
};

const getSearchResultContextFromRailSearchParams = (params?: IRailSearchUrlParams): CityCountryContext => {
  const cities: City[] = [];
  const countries: string[] = [];

  if (!params?.segments) return { cities, countries };

  params.segments.forEach((segment) => {
    const { cityName: originCityName, countryCode: originCountryCode, stateCode: originStateCode } = segment.origin;
    const {
      cityName: destinationCityName,
      countryCode: destinationCountryCode,
      stateCode: destinationStateCode,
    } = segment.destination;
    cities.push(
      {
        cityName: originCityName ?? '',
        countryCode: originCountryCode ?? '',
        administrativeDivCode: originStateCode ?? '',
      },
      {
        cityName: destinationCityName ?? '',
        countryCode: destinationCountryCode ?? '',
        administrativeDivCode: destinationStateCode ?? '',
      },
    );
    countries.push(originCountryCode ?? '', destinationCountryCode ?? '');
  });

  return { cities, countries: countries.filter((country) => country) };
};

// Each booking type has a different way of passing location information in
// their URL, so we need to handle each case separately. CityCountryContext
// is needed for both SearchContext and CheckoutContext.
export const getCityCountryContextForBookingType = (
  bookingType: BookingType,
  params?: SearchParams,
): CityCountryContext | undefined => {
  switch (bookingType) {
    case BookingType.Air:
      return getSearchResultContextFromAirSearchParams(params as IWebAirSearchUrlParams);
    case BookingType.Hotel:
      return getSearchResultContextFromHotelSearchParams(
        params && 'searchParams' in params && params.searchParams && 'name' in params.searchParams
          ? (params.searchParams as IHotelSearchResultsUrlParams)
          : (params as IHotelSearchResultsUrlParams),
      );
    case BookingType.Car:
      return getSearchResultContextFromCarSearchParams(params as ICarSearchResultsUrlParamsV2);
    case BookingType.Rail:
      return getSearchResultContextFromRailSearchParams(params as IRailSearchUrlParams);
    default:
      return undefined;
  }
};

// Creates SearchResultContextWrapper needed for backend.
export const getSearchContextForBookingType = (
  bookingType: BookingType,
  params?: SearchParams,
): SearchResultContextWrapper | undefined => {
  return { searchResultContext: getCityCountryContextForBookingType(bookingType, params) };
};

// Handles creating the Itinerary object which is custom
// to each booking type.
export const createItineraryWrapperForBookingType = (
  bookingType: BookingType,
  params: object,
  itineraryId: string,
): Itinerary | undefined => {
  switch (bookingType) {
    case BookingType.Air:
      return { airItineraryId: { ancillaryResponseId: itineraryId ?? '' } };
    case BookingType.Hotel:
      return { hotelItineraryId: { priceValidateKey: (params as IHotelCheckoutUrlState)?.priceValidateKey ?? '' } };
    case BookingType.Car:
      return {
        carItineraryId: {
          searchId: (params as ICarCheckoutUrlParams)?.searchId ?? '',
          carId: (params as ICarCheckoutUrlParams)?.carId ?? '',
        },
      };
    case BookingType.Rail:
      return { railItineraryId: { searchKey: (params as IRailCheckoutUrlParams)?.searchKey ?? '' } };
    default:
      return undefined;
  }
};

// Assembles the different information needed
// to create CheckoutContextWrapper for backend.
export const getCheckoutContextForBookingType = (
  bookingType: BookingType,
  params: SearchParams,
  itineraryId: string,
): CheckoutContextWrapper | undefined => {
  const cities = getCityCountryContextForBookingType(bookingType, params)?.cities;
  const countries = getCityCountryContextForBookingType(bookingType, params)?.countries;
  const itineraryInfo = createItineraryWrapperForBookingType(bookingType, params, itineraryId);
  const context = { checkoutContext: {} as CheckoutContext };

  if (cities?.length && cities[0].cityName) {
    context.checkoutContext.cities = cities;
  }

  if (countries?.length) {
    context.checkoutContext.countries = countries;
  }

  if (itineraryInfo) {
    context.checkoutContext.itineraryInfo = itineraryInfo;
  }

  return context as CheckoutContextWrapper;
};
