import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CompareHorizontal = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 26.345 26.345" },
        React.createElement("g", { fill: "none" },
            React.createElement("path", { d: "M7.902 12.92l4.425 4.424L7.9 21.771m10.546-8.348l-4.427-4.428 4.424-4.424M2.003 17.344h10.324m1.692-8.349L24.347 9", stroke: themeEnrichedPrimaryColor, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2 }))));
};
CompareHorizontal.defaultProps = defaultIconProps;
export default CompareHorizontal;
