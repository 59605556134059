import { TOptionBase } from '../utils';

/**
 * These are the different types of Options supported by this component.
 * Instead of following a nested option structure (which doesnt work when we have standalone options),
 * we are sending a flat list telling if an option is parent, child or standalone
 */
export enum AutocompleteOptionType {
  /**
   * Parent: The option is a parent. Selection would lead to children's selection and unselected would unselect children.
   */
  PARENT,
  /**
   * Child: Has a property called parentValue to link it to a parent. And no, this doesn't support child of child.
   */
  CHILD,
  /**
   * Standalone: An option aside from the nesting. Use just options of this type for a regular flat select dropdown
   */
  STANDALONE,
  /**
   * Title: This is used to render a title element. Cannot be clicked. For presentation only.
   */
  TITLE,
  /**
   * Hidden: Though functionality is not implemented yet, this would render a hidden option.
   */
  HIDDEN,
}

export type TAutocompleteOption<T extends object> = TOptionBase<T> & {
  /**
   * Type of option. This can be PARENT, CHILD, STANDALONE
   */
  type: AutocompleteOptionType;
  /**
   * Value of Parent for a child. For PARENT/STANDALONE options, this has to be set as null
   */
  parentValue?: TOptionBase<T>['value'] | null;
};
/**
 * This decides how the data would be passed to the Parent Component from an autocomplete component.
 * Autocomplete Component's value should always be a subset of available options.
 * However parent might want value to just be leaf nodes (Child and Standalone) or root nodes (Parent and Standalone)
 * The saturate and reviseSelectedOptions make sure that parent component deals only with the option values as per selection mode while the Autocomplete saturates it
 */
export enum AutocompleteSelectionModes {
  /**
   * Autocomplete Component expects value to consist of CHILD and STANDALONE
   */
  LEAF,
  /**
   * Autocomplete Component expects value to consist of PARENT and STANDALONE
   */
  ROOT,
  /**
   * Autocomplete Component expects value to consist of PARENT, CHILD and STANDALONE
   */
  ALL,
  /**
   * Autocomplete Component expects value to an array of single element of CHILD, PARENT or STANDALONE.
   * This is what you use if you want a single select
   */
  SINGLE,
}
