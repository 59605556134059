import { MutableRefObject, useRef } from 'react';
import { TFlatSelectOptions, TDeprecatedSelectOptions } from '../SelectRoot';
import { useSelectStore_internal } from '../SelectRoot/useSelectStore_internal';
import { TOptionBase } from '../utils';

import { AutocompleteOptionType, AutocompleteSelectionModes, TAutocompleteOption } from './Autocomplete.types';

interface IUseAutocompleteStoreParams<TItem extends TOptionBase = TOptionBase> {
  /**
   * All available options for the select.
   *
   * Please ensure that options are not unnecessarily being sent as new references on each render.
   * Upstream components should ensure they meet basic quality requirements of only sending new
   * arrays when the options have actually been updated.
   */
  options: TFlatSelectOptions<TItem>;
  /**
   * Default checked items.
   *
   * Please note that default values are only considered on first mount.
   * Sending new arrays here will *not* change anything in the component.
   * If an empty array is sent on first render, the default state will be 0 checked items.
   * You must ensure that the component is only rendered once default items are ready.
   */
  defaultValue?: TItem[];
  /**
   * Checked value.
   *
   * Will sync the component state when value is changed. it won't call handleChange
   */
  controlledValue?: TItem[];
  onChange?: (item: TItem[]) => void;
  /** Callback when on clear is clicked  */
  onClear?: () => void;
  /**
   * In case of single select, we treat everyone as a standalone option (whether parent or child)
   * In other nested/group modes, we have logic of parent/child
   */
  selectionMode: AutocompleteSelectionModes;
}

export function useAutocompleteStore<TItem extends TOptionBase = TOptionBase>({
  options = [],
  onChange,
  onClear,
  defaultValue,
  controlledValue,
  selectionMode,
}: IUseAutocompleteStoreParams<TItem>) {
  let searchStringRef: MutableRefObject<string>;
  const storeReturn = useSelectStore_internal<'combobox', 'multiple', TItem>({
    defaultValue: defaultValue ? defaultValue.map((i) => String(i.value)) : [],
    value: controlledValue ? controlledValue.map((i) => String(i.value)) : undefined,
    variant: 'multiple' as const,
    options: options as TDeprecatedSelectOptions<TItem>,
    search: true,
    componentType: 'combobox',
    onChange: () => {
      return null;
    },
    onClear: () => {
      onClear?.();
    },
    customSetValueOnClick(_, newOptionValue) {
      const currentlySelectedValues = storeReturn.selectStore.getState().value;
      const setStoreValues = (selectedOptions: TAutocompleteOption<TItem>[]) => {
        const selectedValues = selectedOptions.map((option) => String(option.value));
        onChange?.(selectedOptions);
        storeReturn.selectStore.setValue([...new Set(selectedValues)]);
      };
      const availableOptions = options as TAutocompleteOption<TItem>[];
      const currentlySelectedOptions = availableOptions.filter((option) =>
        currentlySelectedValues.includes(String(option.value)),
      );
      const newOption = availableOptions.find(
        (option) => option.value === newOptionValue.value,
      ) as TAutocompleteOption<TItem>;

      const isNewOptionInCurrentlySelectedOptions = !!currentlySelectedOptions.find(
        (currentlySelectedOption) => currentlySelectedOption.value === newOption?.value,
      );
      // If its a single select, just set selected values as what is being clicked
      if (selectionMode === AutocompleteSelectionModes.SINGLE) {
        setStoreValues([newOption]);
        storeReturn.comboboxStore.setState('open', false);
        return false;
      }

      if (isNewOptionInCurrentlySelectedOptions) {
        // Then proceed to remove it
        if (newOption.type === AutocompleteOptionType.PARENT) {
          const newSelectedOptions = currentlySelectedOptions.filter(
            (currentlySelectedOption) =>
              currentlySelectedOption.parentValue !== newOption.value && // then remove all its children
              currentlySelectedOption.value !== newOption.value, // And itself
          );

          setStoreValues(newSelectedOptions);
        } else if (newOption.type === AutocompleteOptionType.STANDALONE) {
          // Then just remove that
          const newSelectedOptions = currentlySelectedOptions.filter(
            (currentlySelectedOption) => currentlySelectedOption.value !== newOption.value, // then just remove that
          );

          setStoreValues(newSelectedOptions);
        } else if (newOption.type === AutocompleteOptionType.CHILD) {
          const newSelectedOptions = currentlySelectedOptions.filter(
            (currentlySelectedOption) =>
              currentlySelectedOption.value !== newOption.value &&
              currentlySelectedOption.value !== newOption.parentValue, // then just remove the child and its parent
          );

          setStoreValues(newSelectedOptions);
        }
      }
      // Lets add the new item
      else if (newOption.type === AutocompleteOptionType.PARENT) {
        // Add itself and all its children
        const childrenOptions = availableOptions.filter((option) => option.parentValue === newOption.value);
        const newSelectedOptions = [...currentlySelectedOptions, ...childrenOptions, newOption];

        setStoreValues(newSelectedOptions);
      } else if (newOption.type === AutocompleteOptionType.STANDALONE) {
        // Then just remove that
        const newSelectedOptions = [...currentlySelectedOptions, newOption]; // then just add that

        setStoreValues(newSelectedOptions);
      } else if (newOption.type === AutocompleteOptionType.CHILD) {
        const newSelectedOptionsWithChild = [...currentlySelectedOptions, newOption]; // then just add that
        // Now check if for a parent all children are selected.
        const actualSiblingCount = availableOptions.filter(
          (option) => option.parentValue === newOption.parentValue,
        ).length;
        const selectedSiblingCount = newSelectedOptionsWithChild.filter(
          (option) => option.parentValue === newOption.parentValue,
        ).length;

        if (actualSiblingCount === selectedSiblingCount) {
          const parentOption = availableOptions.find((option) => option.value === newOption.parentValue);
          const newSelectedOptions = [
            ...currentlySelectedOptions,
            newOption,
            parentOption,
          ] as TAutocompleteOption<TItem>[];

          setStoreValues(newSelectedOptions);
        } else {
          const newSelectedOptions = [...currentlySelectedOptions, newOption];
          setStoreValues(newSelectedOptions);
        }
      }
      storeReturn.comboboxStore.setState('open', true);
      storeReturn.comboboxStore.setValue(searchStringRef.current);
      return false;
    },
  });

  searchStringRef = useRef(storeReturn.comboboxStore.getState().value);
  searchStringRef.current = storeReturn.comboboxStore.getState().value;

  return storeReturn;
}
