import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Business = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_4611_16352)" },
            React.createElement("path", { d: "M24 16.3216C24 16.455 23.8946 16.5599 23.7604 16.5599H14.492L14.6933 14.9303H22.6006C23.377 14.9303 24 15.5593 24 16.3216Z", fill: themeEnrichedPrimaryColor }),
            React.createElement("path", { d: "M18.7188 17.1412L17.3578 18.542C17.0895 18.8279 16.7061 18.9899 16.3131 18.9899H14.1853C14.1853 18.9899 14.1949 18.9613 14.1949 18.9518L14.4153 17.1412H18.7188Z", fill: themeEnrichedPrimaryColor }),
            React.createElement("path", { d: "M13.1885 12.8148C13.1885 12.8148 13.1502 12.8148 13.1406 12.8243C13.2365 12.8148 13.3323 12.8243 13.4186 12.8529C13.3419 12.8243 13.2652 12.8148 13.1885 12.8148Z", fill: themeEnrichedPrimaryColor }),
            React.createElement("path", { d: "M14.2427 14.4443L14.1852 14.9303L13.9839 16.5599L13.9168 17.1412L13.706 18.885C13.706 18.885 13.706 18.9518 13.6868 18.9899C13.5622 19.5807 13.1309 20 12.6421 20H4.03506C3.55583 20 3.1341 19.5902 2.99991 18.9994L0.0382534 5.73437C-0.172609 4.76236 0.459979 4.00953 1.24592 4H1.29384C1.52388 4 1.77308 4.02859 2.02228 4.08577C2.1948 4.13341 2.35774 4.19059 2.53027 4.26683C2.87531 4.42883 3.22036 4.65753 3.54624 5.0006C3.6325 5.08636 3.71876 5.17213 3.79544 5.27695C4.28426 5.85825 4.72515 6.69684 5.07979 7.85944C5.16605 8.1358 5.25231 8.39309 5.34816 8.64086C6.09576 10.623 7.17883 11.6998 8.33857 12.2811C8.48234 12.3574 8.62611 12.4145 8.76988 12.4717C8.91365 12.5289 9.06701 12.5861 9.21078 12.6242C9.36413 12.6718 9.5079 12.7099 9.65167 12.7385C10.2459 12.8719 10.8306 12.9101 11.3769 12.9101C12.0287 12.9101 12.6325 12.8529 13.1405 12.8243C13.2363 12.8148 13.3322 12.8243 13.4184 12.8529C13.4855 12.8529 13.5526 12.891 13.6101 12.9291C13.6772 12.9672 13.7347 13.0149 13.7922 13.0625C14.1373 13.358 14.329 13.8916 14.2427 14.4443Z", fill: themeEnrichedPrimaryColor })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_4611_16352" },
                React.createElement("rect", { width: "24", height: "16", fill: "white", transform: "translate(0 4)" })))));
};
Business.defaultProps = defaultIconProps;
export default Business;
