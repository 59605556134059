import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Filters = ({ primaryColor, width, height, isBold = false }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 14 11", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.29223 2.16667C7.53892 1.20821 8.40898 0.5 9.44445 0.5C10.4799 0.5 11.35 1.20821 11.5967 2.16667H12.7774C13.0844 2.16667 13.3333 2.41326 13.3333 2.72222C13.3333 3.02905 13.0847 3.27778 12.7774 3.27778H11.5967C11.35 4.23624 10.4799 4.94444 9.44445 4.94444C8.40898 4.94444 7.53892 4.23624 7.29223 3.27778H0.555942C0.248903 3.27778 0 3.03119 0 2.72222C0 2.4154 0.248594 2.16667 0.555942 2.16667H7.29223ZM1.73668 7.72222C1.98337 6.76376 2.85342 6.05556 3.88889 6.05556C4.92436 6.05556 5.79441 6.76376 6.0411 7.72222H12.7774C13.0844 7.72222 13.3333 7.96881 13.3333 8.27778C13.3333 8.5846 13.0847 8.83333 12.7774 8.83333H6.0411C5.79441 9.79179 4.92436 10.5 3.88889 10.5C2.85342 10.5 1.98337 9.79179 1.73668 8.83333H0.555942C0.248903 8.83333 0 8.58674 0 8.27778C0 7.97095 0.248594 7.72222 0.555942 7.72222H1.73668ZM3.88889 9.38889C4.50254 9.38889 5 8.89143 5 8.27778C5 7.66413 4.50254 7.16667 3.88889 7.16667C3.27524 7.16667 2.77778 7.66413 2.77778 8.27778C2.77778 8.89143 3.27524 9.38889 3.88889 9.38889ZM9.44445 3.83333C10.0581 3.83333 10.5556 3.33587 10.5556 2.72222C10.5556 2.10857 10.0581 1.61111 9.44445 1.61111C8.8308 1.61111 8.33333 2.10857 8.33333 2.72222C8.33333 3.33587 8.8308 3.83333 9.44445 3.83333Z", fill: themeEnrichedPrimaryColor, stroke: isBold ? primaryColor : undefined, strokeWidth: isBold ? 1 : undefined, fillOpacity: "0.7" })));
};
Filters.defaultProps = defaultIconProps;
export default Filters;
