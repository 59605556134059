/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Engine types.
 * @export
 * @enum {string}
 */

export const EngineType = {
  UnknownEngine: 'UNKNOWN_ENGINE',
  Petrol: 'PETROL',
  Diesel: 'DIESEL',
  Electric: 'ELECTRIC',
  Cng: 'CNG',
  Hybrid: 'HYBRID',
  Hydrogen: 'HYDROGEN',
  MultiFuel: 'MULTI_FUEL',
  Ethanol: 'ETHANOL',
} as const;

export type EngineType = (typeof EngineType)[keyof typeof EngineType];
