import { StorageKeys, useLoggedInUserId, useStorage, FareList, Fare } from 'obt-common';
import { v4 as uuidV4 } from 'uuid';
import { ShareableList, IUseShareableFareListsForLoggedInUser } from '../types';

export const useShareableFareListsForLoggedInUser = (): IUseShareableFareListsForLoggedInUser => {
  const loggedInUserId = useLoggedInUserId().userId?.id ?? '';
  const {
    data: shareableLists,
    setData: setShareableLists,
    isFetching,
  } = useStorage<ShareableList>(StorageKeys.SHAREABLE_LISTS);
  const loggedInUserFareList = shareableLists?.[loggedInUserId] ?? [];

  const addFareToFareList = (fareListUuid: string, newFare: Fare) => {
    const fareListToUpdate = loggedInUserFareList.find((fareList) => fareList.uuid === fareListUuid);

    if (!fareListToUpdate) {
      return;
    }

    const newFares = [...fareListToUpdate.fares, newFare];

    const updatedFares = loggedInUserFareList.map((fareList) => {
      if (fareList.uuid === fareListUuid) {
        return { ...fareList, fares: newFares };
      }
      return fareList;
    });

    const updatedSharableList = { ...shareableLists, [loggedInUserId]: updatedFares };

    setShareableLists(updatedSharableList);
  };

  const removeFareFromFareList = ({
    fareRateOptionId,
    fareListUuid,
  }: {
    fareRateOptionId: string;
    fareListUuid: string;
  }) => {
    const fareListIndex = loggedInUserFareList.findIndex((fareList) => fareList.uuid === fareListUuid);

    if (fareListIndex === -1) {
      return;
    }

    const fareListSelected = loggedInUserFareList[fareListIndex];

    const fareIndexToRemove = fareListSelected.fares.findIndex((fare) => fare.rateOptionId === fareRateOptionId);
    const updatedFares = fareListSelected.fares.filter((_fare, fareIndex) => fareIndexToRemove !== fareIndex);

    if (updatedFares.length === 0) {
      const updatedSharableList = {
        ...shareableLists,
        [loggedInUserId]: loggedInUserFareList.filter((fareList) => fareList.uuid !== fareListUuid),
      };
      setShareableLists(updatedSharableList);
      return;
    }

    const updatedFareList: FareList = { ...fareListSelected, fares: updatedFares };
    const updatedFareLists = loggedInUserFareList.map((fareList, listIndex) => {
      if (listIndex === fareListIndex) {
        return updatedFareList;
      }
      return fareList;
    });
    setShareableLists({ ...shareableLists, [loggedInUserId]: updatedFareLists });
  };

  const createFareListForTraveler = (travelerUserId: string, fare: Fare): string => {
    const uuid = uuidV4();
    const newFareList = { travelerUserId, fares: [fare], uuid };
    const updatedLists = {
      ...shareableLists,
      [loggedInUserId]: [...loggedInUserFareList, newFareList],
    };
    setShareableLists(updatedLists);
    return uuid;
  };

  const deleteFareList = (fareListUuid: string) => {
    const updatedList = loggedInUserFareList.filter((fareList) => fareList.uuid !== fareListUuid);
    setShareableLists({ ...shareableLists, [loggedInUserId]: updatedList });
  };

  const deleteAllSavedItineraries = () => {
    setShareableLists({ ...shareableLists, [loggedInUserId]: [] });
  };

  return {
    isFetching,
    loggedInUserFareList,
    deleteFareList,
    addFareToFareList,
    removeFareFromFareList,
    createFareListForTraveler,
    deleteAllSavedItineraries,
  };
};
