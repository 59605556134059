import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const Mileage = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 64 64", version: "1.1" },
        React.createElement("path", { d: "M32 12c11.046 0 20 8.955 20 20 0 11.046-8.954 20-20 20-11.045 0-20-8.954-20-20 0-11.045 8.955-20 20-20zm0 4c-8.836 0-16 7.164-16 16 0 8.837 7.164 16 16 16 8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16zm0 3.855a2 2 0 011.994 1.851l.005.15v6.77a3.922 3.922 0 11-4 .002v-6.773a2 2 0 012-2z", fill: themeEnrichedPrimaryColor, fillRule: "evenodd" })));
};
Mileage.defaultProps = defaultIconProps;
export default Mileage;
