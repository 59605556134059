import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const CheckInLuggage = ({ primaryColor, width, height }) => {
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.5 6.33301C11.5 5.91879 11.8358 5.58301 12.25 5.58301H12.4472H19.1242H19.25C19.6642 5.58301 20 5.91879 20 6.33301C20 6.61094 19.8488 6.85357 19.6242 6.98313V12.6058H19.8944C21.8274 12.6058 23.3944 14.1728 23.3944 16.1058V24.0002C23.3944 25.9155 21.8559 27.4716 19.9472 27.4998V27.5417C19.9472 28.0939 19.4995 28.5417 18.9472 28.5417C18.42 28.5417 17.9881 28.1337 17.9499 27.6163L17.9472 27.5417V27.5002H13.9472V27.5417C13.9472 28.0939 13.4995 28.5417 12.9472 28.5417C12.42 28.5417 11.9881 28.1337 11.9499 27.6163L11.9472 27.5417V27.4998C10.0385 27.4716 8.5 25.9155 8.5 24.0002V16.1058C8.5 14.1904 10.0385 12.6344 11.9472 12.6062V7.01937C11.6838 6.903 11.5 6.63947 11.5 6.33301ZM18.6242 7.08301V12.6058H12.9472V7.08301H18.6242ZM19.8944 14.6058H12C11.1716 14.6058 10.5 15.2773 10.5 16.1058V24.0002C10.5 24.8286 11.1716 25.5002 12 25.5002H19.8944C20.7228 25.5002 21.3944 24.8286 21.3944 24.0002V16.1058C21.3944 15.2773 20.7228 14.6058 19.8944 14.6058ZM19.2713 20.5496C19.52 20.5207 19.713 20.3094 19.713 20.053C19.713 19.7768 19.4892 19.553 19.213 19.553H12.6814L12.6231 19.5563C12.3744 19.5852 12.1814 19.7966 12.1814 20.053C12.1814 20.3291 12.4052 20.553 12.6814 20.553H19.213L19.2713 20.5496ZM19.713 23.053C19.713 23.3094 19.52 23.5207 19.2713 23.5496L19.213 23.553H12.6814C12.4052 23.553 12.1814 23.3291 12.1814 23.053C12.1814 22.7966 12.3744 22.5852 12.6231 22.5563L12.6814 22.553H19.213C19.4892 22.553 19.713 22.7768 19.713 23.053ZM19.2713 17.5496C19.52 17.5207 19.713 17.3094 19.713 17.053C19.713 16.7768 19.4892 16.553 19.213 16.553H12.6814L12.6231 16.5563C12.3744 16.5852 12.1814 16.7966 12.1814 17.053C12.1814 17.3291 12.4052 17.553 12.6814 17.553H19.213L19.2713 17.5496Z", fill: themeEnrichedPrimaryColor })));
};
CheckInLuggage.defaultProps = defaultIconProps;
export default CheckInLuggage;
