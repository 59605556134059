var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultIconProps } from './common';
const Wheelchair = (_a) => {
    var { primaryColor, secondaryColor } = _a, otherProps = __rest(_a, ["primaryColor", "secondaryColor"]);
    return (React.createElement("svg", Object.assign({ viewBox: "0 0 20 20", fill: primaryColor, xmlns: "http://www.w3.org/2000/svg" }, otherProps),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.746 2.866a.813.813 0 00-.79-.62H2.063l-.06.002a.813.813 0 00-.753.811l.002.06c.031.421.382.753.811.753h2.199l.804 5.052a4.756 4.756 0 00.938 9.416 4.756 4.756 0 004.637-3.7h2.993a2.662 2.662 0 103.266-1.5v-1.666l-.001-.095a2.642 2.642 0 00-2.64-2.548H6.697l-.17-1.072h6.14l.062-.002a.813.813 0 00-.061-1.624h-6.4l-.51-3.202-.013-.065zm10.34 11.775a1.036 1.036 0 10.002 0h-.002zm-5.361-1.626h4.55v-1.541c0-.54-.42-.98-.95-1.014l-.067-.003H9.584a4.736 4.736 0 011.14 2.558zm-4.72-2.558a3.128 3.128 0 100 6.257 3.128 3.128 0 000-6.257zm1.237 3.047a1.313 1.313 0 11-2.626 0 1.313 1.313 0 012.626 0z" })));
};
Wheelchair.defaultProps = defaultIconProps;
export default Wheelchair;
