/* eslint-disable no-restricted-syntax */
import type { Storage } from '../types/storage';
import { StorageKeys } from '../types/storage';

export const storage: Storage = {
  getItem(key) {
    try {
      const storedValue = localStorage.getItem(key);
      return Promise.resolve(storedValue);
    } catch {
      return Promise.resolve(null);
    }
  },

  setItem(key, value) {
    try {
      return Promise.resolve(localStorage.setItem(key, value));
    } catch {
      return Promise.resolve();
    }
  },

  removeItem(key) {
    try {
      return Promise.resolve(localStorage.removeItem(key));
    } catch {
      return Promise.resolve();
    }
  },

  clear() {
    try {
      // We don't want to clear the API endpoints as it is user specific.
      const apiEndpoints = localStorage.getItem(StorageKeys.API_ENDPOINTS);
      localStorage.clear();
      if (apiEndpoints) {
        localStorage.setItem(StorageKeys.API_ENDPOINTS, apiEndpoints);
      }
      return Promise.resolve();
    } catch {
      return Promise.resolve();
    }
  },

  async clearWithoutAppId() {
    try {
      const appId = localStorage.getItem(StorageKeys.APPLICATION_ID);
      const tmcId = localStorage.getItem(StorageKeys.TMC_ID);
      const orgId = localStorage.getItem(StorageKeys.ORG_ID);

      const apiEndpoints = localStorage.getItem(StorageKeys.API_ENDPOINTS);

      localStorage.clear();

      if (appId) {
        localStorage.setItem(StorageKeys.APPLICATION_ID, appId);
      }
      if (tmcId) {
        localStorage.setItem(StorageKeys.TMC_ID, tmcId);
      }
      if (orgId) {
        localStorage.setItem(StorageKeys.ORG_ID, orgId);
      }
      if (apiEndpoints) {
        localStorage.setItem(StorageKeys.API_ENDPOINTS, apiEndpoints);
      }
    } catch {
      // do nothing
    }
  },

  async getAllItems() {
    try {
      return Promise.resolve({ ...localStorage });
    } catch {
      return Promise.resolve({});
    }
  },
};
