/*
 * Disable `dayjs` import restriction
 * because this is the only place where it is allowed
 */
// eslint-disable-next-line no-restricted-imports
import type { Dayjs, ConfigType } from 'dayjs';
import dayjs from 'dayjs';
import ObjectSupportPlugin from 'dayjs/plugin/objectSupport';
import DurationPlugin from 'dayjs/plugin/duration';
import UTCPlugin from 'dayjs/plugin/utc';
import CustomParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import ISOWeekPlugin from 'dayjs/plugin/isoWeek';
import TimezonePlugin from 'dayjs/plugin/timezone';
import AdvancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import IsSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import IsSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import RelativeTime from 'dayjs/plugin/relativeTime';
import moment from 'moment';

dayjs.extend(ObjectSupportPlugin);
dayjs.extend(DurationPlugin);
dayjs.extend(UTCPlugin);
dayjs.extend(CustomParseFormatPlugin);
dayjs.extend(ISOWeekPlugin);
dayjs.extend(TimezonePlugin);
dayjs.extend(AdvancedFormatPlugin);
dayjs.extend(IsSameOrBeforePlugin);
dayjs.extend(IsSameOrAfterPlugin);
dayjs.extend(RelativeTime);

type DayJS = typeof dayjs;
interface IDateTimeConfigObject {
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
}
export interface IDateUtil extends DayJS {
  // This is a temporal solution for issue with missing ObjectSupport type definitions
  // https://github.com/iamkun/dayjs/issues/1441
  (config?: IDateTimeConfigObject | ConfigType): Dayjs;
  parseZone: typeof moment.parseZone;
}

const dateUtil: IDateUtil = dayjs as IDateUtil;

// `parseZone` functionality is not yet available in `dayjs` library,
// but it may become so in the future as there is a PR pending to fix that.
// The current decision is to use `moment.parseZone`
// Issue - https://github.com/iamkun/dayjs/issues/651
// Upcoming PR (dayjs) - https://github.com/iamkun/dayjs/pull/1674

// Problem seems to be solved: https://github.com/iamkun/dayjs/issues/651#issuecomment-1127712205 - solution mentioned here

dateUtil.parseZone = moment.parseZone;

export default dateUtil;
