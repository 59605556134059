import { useQuery } from 'react-query';

import api from '../api';
import type SpotnanaError from '../api/SpotnanaError';
import type { SpotnanaQueryOptions, SpotnanaQueryResult } from '../types/common';
import Config from '../utils/Config';
import type { IApiRequestOptions } from '../api/types';

const FIREBASE_API_KEY = 'AIzaSyCrfFe1vdtD_G1PziBWoMJa15aoPS-0MwE';

const fetchShortURLFirebase = async (longUrl: string): Promise<string> => {
  const response = await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_API_KEY}`, {
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://spotnana.page.link',
        link: longUrl,
        navigationInfo: { enableForcedRedirect: false },
        androidInfo: { androidPackageName: Config.VITE_ANDROID_BUNDLE_ID },
        iosInfo: {
          iosAppStoreId: Config.VITE_IOS_APP_STORE_ID,
          iosBundleId: Config.VITE_IOS_BUNDLE_ID,
        },
      },
      suffix: {
        option: 'SHORT',
      },
    }),
    method: 'POST',
  });
  if (response.ok) {
    const result = (await response.json()) as unknown as { shortLink: string };
    return result.shortLink;
  }
  return longUrl;
};

const createUrlShortenerKey = (longUrl: string): unknown[] => ['long-url', longUrl];

export const useUrlShortenerQuery = (longUrl: string): SpotnanaQueryResult<string> =>
  useQuery<string, SpotnanaError>(createUrlShortenerKey(longUrl), () => fetchShortURLFirebase(longUrl));

interface PolicyPdfUserProps {
  userId: string;
  policyId: string;
  defaultFallback: boolean;
  axiosRequestOptionsOuter?: IApiRequestOptions;
}

const fetchPolicyPdfUser = async ({
  userId,
  policyId,
  defaultFallback = true,
  axiosRequestOptionsOuter,
}: PolicyPdfUserProps): Promise<Blob | undefined> => {
  const data = await api(
    'GET',
    'userBaseUrl',
    {
      responseType: 'blob',
      urlParam: `/${userId}/applicable-policies/${policyId}/document?defaultFallback=${defaultFallback}`,
    },
    { ...axiosRequestOptionsOuter },
  );
  if (data) {
    return data as Blob;
  }
  return undefined;
};

export const useGetPolicyPdfUser = (
  { userId, policyId, defaultFallback, axiosRequestOptionsOuter }: PolicyPdfUserProps,
  options?: SpotnanaQueryOptions<Blob | undefined, [string, string]>,
): SpotnanaQueryResult<Blob | undefined> =>
  useQuery<Blob | undefined, SpotnanaError>(
    [userId, policyId],
    async () => fetchPolicyPdfUser({ userId, policyId, defaultFallback, axiosRequestOptionsOuter }),
    { enabled: options?.enabled !== false && !!userId && !!policyId },
  );

interface PolicyPdfCompanyProps {
  companyId: string;
  policyId: string;
  defaultFallback: boolean;
  axiosRequestOptionsOuter?: IApiRequestOptions;
}

const fetchPolicyPdfCompany = async ({
  companyId,
  policyId,
  defaultFallback,
  axiosRequestOptionsOuter,
}: PolicyPdfCompanyProps): Promise<Blob | undefined> => {
  const data = await api(
    'GET',
    'companyBaseUrl',
    {
      responseType: 'blob',
      urlParam: `/${companyId}/policies/${policyId}/document?defaultFallback=${defaultFallback}`, // if defaultFallback = false, api does not return default policy pdf
    },
    { ...axiosRequestOptionsOuter },
  );
  if (data) {
    return data as Blob;
  }
  return undefined;
};

export const getPolicyPdfCompanyKey = (companyId: string, policyId: string): unknown[] => [
  'company-pdf',
  companyId,
  policyId,
];

export const useGetPolicyPdfCompany = (
  { companyId, policyId, defaultFallback, axiosRequestOptionsOuter }: PolicyPdfCompanyProps,
  options?: SpotnanaQueryOptions<Blob | undefined, [string, string]>,
): SpotnanaQueryResult<Blob | undefined> =>
  useQuery<Blob | undefined, SpotnanaError>(
    getPolicyPdfCompanyKey(companyId, policyId),
    async () => fetchPolicyPdfCompany({ companyId, policyId, defaultFallback, axiosRequestOptionsOuter }),
    {
      enabled: options?.enabled !== false && !!companyId && !!policyId,
    },
  );
