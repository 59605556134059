/* eslint-disable */
import { TripData } from '../../../v1/obt/trip/trip_common';
import { PostPaymentVerificationInfo } from '../../common/card_verification';
import { UserOrgId } from '../common/user_org_id';
import { PreBookAnswersResponse } from '../policy/pre_search_and_book_question';

export enum HoldStatusEnum {
  // Every itinerary is in this state by default.
  NOT_REQUESTED = 1,
  // If the traveler wants to hold the ticket and not confirm the ticket, then the UI sends the itinerary with this state. Later, we store it in pnrMetadata in this state
  REQUESTED = 2,
  // When the confirmation is received from the supplier (e.g getting pnrUpdateEvent from Sabre queue) then we store it in this state
  ON_HOLD = 3,
  // When the held ticket is auto cancelled. Only ON_HOLD ticket will be cancelled
  HOLD_CANCELLED = 4,
  // When the hold ticket is confirmed by the traveller.
  TICKETED_AFTER_HOLD = 5,
}

export interface AirCreatePnrRequest {
  tripData: TripData;
  bookingId: string;
  /**
   *  The user will be directed to this URL after the credit card verification.
   */
  ccPostVerificationUrl: string;
  /**
   *  List of Pre checkout questions and the answers given to those questions by user.
   */
  preBookAnswers?: PreBookAnswersResponse;

  /**
   *  Use this config for booking on hold
   */
  holdInfo?: {
    holdStatus: HoldStatusEnum;
  };

  /** Indicates that some (card based or otherwise) verification was performed successfully by UI. */
  postPaymentVerificationInfo?: PostPaymentVerificationInfo;

  /** Indicates that some (card based or otherwise) verification was performed successfully by UI. */
  initiateBookingId?: string;

  groupBookingInfo?: AirCreatePnrRequestGroupBookingInfo;
}

/** Information related to Group Booking */
export interface AirCreatePnrRequestGroupBookingInfo {
  /** Group booking information per passenger */
  groupBookingInfoPerPax: AirCreatePnrRequestGroupBookingInfoGroupBookingInfoPerPax[];
}

export interface AirCreatePnrRequestGroupBookingInfoGroupBookingInfoPerPax {
  /** Traveler's info */
  userOrgId?: UserOrgId;
  /** Trip id which needs to be associated to this traveler's booking */
  tripData?: TripData;
  /** List of Pre checkout questions and the answers given to those questions by user. */
  preBookAnswers?: PreBookAnswersResponse;
}
