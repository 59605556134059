import type { AxiosError } from 'axios';
import type SpotnanaError from '../SpotnanaError';

import addResponseInterceptor from '../interceptors/addResponseInterceptor';

export type InterceptorError = Error | AxiosError | SpotnanaError | unknown;

function onResponseError(listener: (error: InterceptorError) => void) {
  return addResponseInterceptor({
    onRejected: (error) => {
      listener(error);

      return error;
    },
  });
}

export default onResponseError;
