import { PropsWithChildren } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

interface HideOnRouteMatchProps extends PropsWithChildren<{}> {
  paths: string[];
}

export const HideOnRouteMatch = ({ children, paths }: HideOnRouteMatchProps): JSX.Element | null => {
  const { pathname } = useLocation();
  const match = paths.some((path) => matchPath(pathname, { path })?.isExact);

  return <>{match ? null : children}</>;
};
