/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies weight unit and weight amount.
 * @export
 * @interface Weight
 */

export const WeightUnitEnum = {
  Kg: 'KG',
  Lbs: 'LBS',
} as const;

export type WeightUnitEnum = (typeof WeightUnitEnum)[keyof typeof WeightUnitEnum];

export interface Weight {
  /**
   * Amount of weight.
   * @type {number}
   * @memberof Weight
   */
  weight: number;
  /**
   * Unit of weight.
   * @type {string}
   * @memberof Weight
   */
  unit: WeightUnitEnum;
}
