import { css } from '@emotion/react';
import { unit, themed, flex } from '@spotnana/blocks/src/utils';

export const icon = css`
  font-size: ${unit(1.5)};
  margin-right: ${unit(0.5)};
`;

export const error_icon = themed(
  ({ palette }) => css`
    ${icon};
    color: ${palette.text.error};
  `,
);

export const success_icon = themed(
  ({ palette }) => css`
    ${icon};
    color: ${palette.text.success};
  `,
);

export const wrapper = themed(
  ({ palette }) => css`
    min-height: ${unit(2)};
    ${flex.init}
    ${flex.align.center}
    border-radius: ${unit(0.25)};
    padding: ${unit(1)} ${unit(0.5)};
    max-width: 25rem;
    box-shadow: ${palette.shadow.componentShadow};
  `,
);

export const error_wrapper = themed(
  ({ palette }) => css`
    background-color: ${palette.bg.errorSubtle};
    border-left: 5px solid ${palette.border.error};
  `,
);

export const success_wrapper = themed(
  ({ palette }) => css`
    background-color: ${palette.bg.successSubtle};
    border-left: 5px solid ${palette.border.success};
  `,
);
