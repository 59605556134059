import { shouldShowFareChangeAlert } from '../../../utils/Flights';
import { getTotalFareFromV2FareBreakdown } from '../../../utils/Flights/getTotalFareFromV2FareBreakdown';
import type { MoneyUtil } from '../../../utils/Money';
import type { SuccessRevalidateItineraryEventV2 } from '../types';

export default (context: any, event: any): boolean => {
  const typedContext = context as { oldTotalFare: MoneyUtil };
  const oldRevalidateFare = typedContext.oldTotalFare;
  const typedEvent = event as SuccessRevalidateItineraryEventV2;

  const fareBreakdown = typedEvent.data.fareBreakDown;

  if (!fareBreakdown) {
    return false;
  }

  const newRevalidateFare = getTotalFareFromV2FareBreakdown(fareBreakdown);
  return shouldShowFareChangeAlert({ newRevalidateFare, oldRevalidateFare });
};
