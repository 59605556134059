import type { ISectionAlternative } from '../../types';
import { AppliedPromotionPromotionType } from '../../types/api/v1/obt/rail/rail_common';

const getIsGroupSave = (alternative: ISectionAlternative | undefined): boolean => {
  if (!alternative) {
    return false;
  }
  const { fares } = alternative;
  return fares.some((alternativeFare) => {
    return alternativeFare.appliedPromotions?.some(
      (promotion) =>
        promotion.type === AppliedPromotionPromotionType.GROUP_SAVE ||
        // Unknown promotion types refer to group save promotions that are not yet mapped to the enum
        // TODO: remove after all group save promotions are mapped
        promotion.type === AppliedPromotionPromotionType.PROMOTION_TYPE_UNKNOWN,
    );
  });
};

export default getIsGroupSave;
