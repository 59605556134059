/* istanbul ignore file */
import * as React from 'react';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk';
import { useLoggedInUser } from '../../providers/AuthProvider';
import { Config } from '../../utils';
import type { BusinessInfo, ITraveler } from '../../types';
import { getBuildAttributes } from '../../providers/utils/getBuildAttributes';
import { useApplicationId } from '../../core/applicationId';
import { useUserBusinessInfo } from '../../queries/users';

// TODO: verify if any dummy target works or have to configure on Harness admin panel
const SPOTNANA_DUMMY_TARGET = {
  identifier: 'SPT_GUEST',
  name: 'GuestUser',
  attributes: {
    id: 'SPT_GUEST',
  },
  anonymous: true,
};

/** Sanitizes emails sent to harness by replacing unsupported characters with `__` */
function sanitizeEmail(email?: string): string | undefined {
  if (typeof email === 'string') {
    return email.replace(/[^A-Za-z0-9.@_-]/g, '__');
  }
  return undefined;
}

function getHarnessAttributes(user: ITraveler | undefined, businessInfo: BusinessInfo | undefined) {
  const userId = user?.userOrgId?.userId?.id;
  const organizationId = user?.userOrgId?.organizationId?.id;
  const userEmail = sanitizeEmail(user?.userBusinessInfo?.email);
  const bookingTmcId = user?.userOrgId?.tmcBasicInfo?.bookingTmc?.id?.id;
  const contractingTmcId = user?.userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id;
  const legalEntityId = businessInfo?.legalEntityRef.id;
  return {
    userId,
    organizationId,
    userEmail,
    bookingTmcId,
    contractingTmcId,
    legalEntityId,
  };
}

interface IFeatureFlagProviderProps {
  children?: React.ReactNode;
  Loader?: React.ReactNode;
  async?: boolean;
}

export function FeatureFlagProvider({
  children,
  Loader = <></>,
  async = false,
}: IFeatureFlagProviderProps): JSX.Element {
  const { data: user } = useLoggedInUser();
  const { applicationId } = useApplicationId();
  const { data: businessInfo } = useUserBusinessInfo(user?.userOrgId?.userId?.id || '');

  const { userId, userEmail, organizationId, bookingTmcId, contractingTmcId, legalEntityId } = getHarnessAttributes(
    user,
    businessInfo,
  );

  const targetProps = React.useMemo(() => {
    if (!userId) {
      return SPOTNANA_DUMMY_TARGET;
    }
    return {
      identifier: userId,
      name: userEmail || '',
      attributes: {
        ...getBuildAttributes(),
        ...(applicationId ? { applicationId } : {}),
        id: userId,
        organizationId: organizationId || '',
        bookingTmcId: bookingTmcId || '',
        contractingTmcId: contractingTmcId || '',
        legalEntityId: legalEntityId || '',
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <FFContextProvider
      async={async}
      fallback={Loader}
      apiKey={Config.VITE_HARNESS_FF_SDK as string}
      target={targetProps}
      options={{ streamEnabled: false }}
    >
      {children}
    </FFContextProvider>
  );
}
