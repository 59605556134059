import styled from 'styled-components';
import { Flex } from '@spotnana/pixel-react/dist/Box';

export const LoaderContainer = styled(Flex)`
  min-height: calc(100vh - 84px);
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
