import { useTranslation } from 'react-i18next';

import { getFullAssetPath } from 'obt-common';
import { Typography } from '@spotnana/blocks/src/Typography';
import SpotnanaLogoShort from '../shared/SpotnanaLogoShort';
import { powered_by_spotnana_text } from './PoweredBySpotnana.styles';

function PoweredBySpotnana(): JSX.Element {
  const { t: tt } = useTranslation('WEB');

  return (
    <>
      <SpotnanaLogoShort
        css={{ height: '12px' }}
        data-testid="powered-by-spotnana-logo"
        src={getFullAssetPath('/v1-assets/images/spotnana-logo-red', 'svg')}
      />
      <Typography variant="body3" color="secondary" css={powered_by_spotnana_text}>
        {tt('Powered by Spotnana')}
      </Typography>
    </>
  );
}

export default PoweredBySpotnana;
