var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useRef, useMemo, useEffect, useCallback, useId } from 'react';
import { variant } from 'styled-system';
import styled from 'styled-components';
import spotnanaTheme, { stripHtml } from '../utils';
import cssWrapper from '../utils/cssWrapper';
import { Flex } from '../Box';
import Info from '../Icons/Info';
import Tooltip from '../Tooltip';
const getBorderColor = (error, focused) => {
    if (error)
        return spotnanaTheme.colors.error;
    if (focused)
        return 'border.dark';
    return 'border.regular';
};
export const InputWrapper = styled.div(cssWrapper({
    position: 'relative',
    minWidth: 'input.minWidth',
    width: 'full',
    borderRadius: 'sm',
    display: 'inline-flex',
    overflow: 'hidden',
    alignItems: 'center',
}), variant({
    prop: 'variation',
    variants: {
        small: { height: 'input.small' },
        large: { height: 'input.large' },
    },
}), ({ naked, focused, readonly, error, ghost }) => cssWrapper({
    cursor: readonly && !ghost ? 'not-allowed' : 'pointer',
    outline: readonly || focused ? 0 : 'initial',
    border: naked ? 0 : 'thinSolid',
    borderColor: getBorderColor(error, focused),
    backgroundColor: readonly && !ghost ? 'bg.blueGray' : 'bg.regular',
    ':hover': {
        // eslint-disable-next-line no-nested-ternary
        borderColor: readonly ? undefined : focused ? 'border.dark' : 'border.regularHover',
        transition: 'border, background 0.2s ease-in-out',
    },
}), ({ cssStyles }) => cssStyles);
export const InputLabel = styled.label(cssWrapper({
    fontSize: 'label',
    position: 'absolute',
    color: 'text.label',
}), ({ isHidden, readonly, labelCssStyles, }) => isHidden
    ? {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        width: 1,
    }
    : Object.assign({ left: 16, top: 10, cursor: readonly ? 'not-allowed' : 'pointer' }, labelCssStyles));
const InputComp = styled.input(({ hasLeftIcon, hasRightIcon, readonly, variation, hideLabel, ghost }) => cssWrapper({
    border: 0,
    backgroundColor: 'bg.transparent',
    appearance: 'none',
    height: hideLabel && variation === 'large' ? 56 : 30,
    outline: 0,
    alignSelf: 'flex-end',
    width: 'full',
    paddingRight: hasRightIcon ? 0 : 16,
    paddingBottom: '8px',
    paddingLeft: hasLeftIcon ? 0 : 16,
    paddingTop: hideLabel && variation === 'large' ? 10 : 0,
    color: readonly && !ghost ? 'text.label' : 'text.black',
    fontSize: variation === 'large' ? 16 : 14,
    cursor: readonly && !ghost ? 'not-allowed' : 'default',
}), ({ inputCssStyles }) => inputCssStyles, `::placeholder: {
        color: red;
   }`);
const InputIcon = styled.span(({ variation }) => cssWrapper({
    minWidth: variation === 'large' ? 32 : 24,
    minHeight: variation === 'large' ? 32 : 24,
    padding: variation === 'large' ? 12 : '8px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'content-box',
}));
/**
 * Every Input will have a label, visually hidden in normal cases
 * but available for accessibility for users from screen readers etc.,<br/>
 * width of the input is controlled by the parent where Input is rendered in,
 * component has a min width of 120px though<br/>
 * No variant yet for inputs with right Icon<br/>
 */
const Input = React.forwardRef((_a, ref) => {
    var { id, label, value, variation, onChange, onFocus, onBlur, containerStyles, containerCssStyles, labelCssStyles, iconLeft, iconRight, naked, readonly, ghost, required, error, hideLabel, autoFocus = false, shouldStripHtml = false, infoText } = _a, rest = __rest(_a, ["id", "label", "value", "variation", "onChange", "onFocus", "onBlur", "containerStyles", "containerCssStyles", "labelCssStyles", "iconLeft", "iconRight", "naked", "readonly", "ghost", "required", "error", "hideLabel", "autoFocus", "shouldStripHtml", "infoText"]);
    const [focused, setFocused] = useState(false);
    const defaultRef = useRef(null);
    const inputRef = useMemo(() => { var _a; return (_a = ref) !== null && _a !== void 0 ? _a : defaultRef; }, [ref, defaultRef]);
    const generatedId = useId();
    const componentId = id !== null && id !== void 0 ? id : generatedId;
    const OnChange = (e) => {
        const { target } = e;
        if (shouldStripHtml && target.value) {
            target.value = stripHtml(target.value);
        }
        if (onChange)
            onChange(e);
    };
    const OnFocus = (e) => {
        setFocused(true);
        if (onFocus)
            onFocus(e);
    };
    const OnBlur = (e) => {
        setFocused(false);
        if (onBlur)
            onBlur(e);
    };
    const focusInput = useCallback(() => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [inputRef]);
    useEffect(() => {
        if (autoFocus) {
            focusInput();
        }
    }, [autoFocus, focusInput]);
    if (variation === 'large' && iconLeft && !hideLabel) {
        throw new Error('Large variation with iconleft and label both arent supported at the moment');
    }
    return (React.createElement(InputWrapper, { variation: variation, focused: focused, style: containerStyles, cssStyles: containerCssStyles, naked: naked, readonly: readonly || ghost, error: error, onClick: focusInput, onFocus: focusInput, ghost: ghost },
        React.createElement(InputLabel, { readonly: readonly || ghost, isHidden: hideLabel !== null && hideLabel !== void 0 ? hideLabel : variation === 'small', htmlFor: componentId, labelCssStyles: labelCssStyles, className: "v1-input-label" },
            React.createElement(Flex, null,
                required ? `${label} *` : label,
                infoText && (React.createElement(Tooltip, { title: infoText },
                    React.createElement(Flex, { ml: "4px", as: "span" },
                        React.createElement(Info, { width: 12, height: 12, primaryColor: spotnanaTheme.colors.text.darkGrey })))))),
        iconLeft && (React.createElement(InputIcon, { variation: variation, "data-testid": `icon-${componentId}` }, iconLeft)),
        React.createElement(InputComp, Object.assign({ variation: variation, hasLeftIcon: iconLeft !== undefined, hasRightIcon: iconRight !== undefined, hideLabel: hideLabel, 
            // type="text" needs to be added otherwise getByRole() in react-testing-library doesn't work properly
            // Would be overriden if passed in ...rest props
            // https://github.com/testing-library/react-testing-library/issues/606
            type: "text" }, rest, { id: componentId, ref: inputRef, value: value, readonly: readonly || ghost, disabled: readonly || ghost, ghost: ghost, required: required, onChange: OnChange, onFocus: OnFocus, onBlur: OnBlur })),
        iconRight && (React.createElement(InputIcon, { variation: variation, "data-testid": `icon-${componentId}` }, iconRight))));
});
/**
 * @deprecated
 */
export default Input;
