import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';

import Popper from '@spotnana/pixel-react/dist/Popper';
import { Button } from '@spotnana/pixel-react/dist/Button';
import * as Icons from '@spotnana/pixel-react/dist/Icons';
import { useOpenSupportChatTelemetry, usePrimaryTraveler } from 'obt-common';

import { useHistory, useLocation } from 'react-router';
import { IfNotEmbed } from 'src/components/EmbedContext';
import useSupportWidget from 'src/hooks/useSupportWidget';
import ClickableBox from 'src/v1-components/shared/ClickableBox';
import { useBlocksTheme } from '@spotnana/blocks/src/utils/styling/useBlocksTheme';

import { parseParams, stringifyParams } from 'obt-common/utils/urlUtils';
import { PopperContent } from './PopperContent/PopperContent';
import { useMarkerIo } from './useMarkerIo';
import { support_menu_style } from './SupportMenu.styles';

enum SupportMenuState {
  OPEN = 'OPEN',
  IN_CHAT = 'IN_CHAT',
  IN_FEEDBACK_FLOW = 'IN_FEEDBACK_FLOW',
  CLOSED = 'CLOSED',
}

const SupportMenu = (): JSX.Element => {
  const { t: tt } = useTranslation('WEB');
  const { data: primaryTraveler } = usePrimaryTraveler();
  const { marker, handleMarkerIoFormSubmit } = useMarkerIo({ primaryTraveler });

  const { palette } = useBlocksTheme();

  const trackOpenSupportChat = useOpenSupportChatTelemetry();

  const { openChatWidget, hideChatWidget, isLoaded, onClose, isWidgetLoaded, isZendeskWidget, isNoChatConfigured } =
    useSupportWidget();

  const { pathname, search } = useLocation();
  const history = useHistory();
  const urlParams = parseParams(search);

  const [supportMenuState, setSupportMenuState] = useState<SupportMenuState>(SupportMenuState.CLOSED);
  const [supportMenuElement, setSupportMenuElement] = useState<HTMLDivElement | null>(null);

  const isSupportMenuOpen = supportMenuState === SupportMenuState.OPEN;
  const isSupportMenuInChat = supportMenuState === SupportMenuState.IN_CHAT;

  function openSupportMenu(): void {
    setSupportMenuState(SupportMenuState.OPEN);
  }

  function closeSupportMenu(): void {
    setSupportMenuState(SupportMenuState.CLOSED);
  }

  function closeEverything(): void {
    closeSupportMenu();

    hideChatWidget();
  }

  function handleAgentSupportClick(): void {
    trackOpenSupportChat();

    openChatWidget();

    setSupportMenuState(SupportMenuState.IN_CHAT);
  }

  useEffect(() => {
    if (isWidgetLoaded) {
      onClose(() => setSupportMenuState(SupportMenuState.CLOSED));
    }
  }, [onClose, setSupportMenuState, isWidgetLoaded]);

  useEffect(() => {
    if (urlParams.openFreshdeskChat) {
      openSupportMenu();
    }
  }, [urlParams]);

  useEffect(() => {
    if (urlParams.openFreshdeskChat && isSupportMenuOpen) {
      history.replace({ pathname, search: stringifyParams({ ...urlParams, openFreshdeskChat: undefined }) });
    }
  }, [urlParams, pathname, history, isSupportMenuOpen]);

  useEffect(() => {
    if (window.Genesys) {
      window.Genesys('subscribe', 'Messenger.closed', () => {
        setSupportMenuState(SupportMenuState.CLOSED);
      });
    }
  }, []);

  function handleFeedbackClick(): void {
    hideChatWidget();

    setSupportMenuState(SupportMenuState.IN_FEEDBACK_FLOW);

    window.setTimeout((): void => {
      if (isSupportMenuInChat) {
        openChatWidget();

        setSupportMenuState(SupportMenuState.IN_CHAT);
      } else {
        setSupportMenuState(SupportMenuState.CLOSED);
      }
    }, 5000);

    handleMarkerIoFormSubmit();
  }

  if (!isLoaded) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (isNoChatConfigured && !marker) {
    // Hide chat bubble when no change config and no marker, e.g. in embbed app.
    // When no chat configured, but mark is avaible, bubble is still
    // visible just for submitting feedback.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const chatBubblePrimaryColor = palette.mode === 'dark' ? palette.text.primary : palette.text.inverse;
  const chatBubbleSecondaryColor = palette.text.brand;

  return (
    <IfNotEmbed>
      <div id="twilio-webchat-widget-root" />
      <div css={support_menu_style} data-testid="support-menu-component">
        {isSupportMenuInChat && marker && (
          <Button variation="secondary" py="16px" px="32px" onClick={handleFeedbackClick}>
            {tt('Report an error')}
          </Button>
        )}
        {!isZendeskWidget && (
          <ClickableBox
            ref={setSupportMenuElement}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="primary"
            width="64px"
            height="64px"
            ml="16px"
            borderRadius="32px 6px 32px 32px"
            role="button"
            data-testid="support-menu-button"
            onClick={isSupportMenuInChat || isSupportMenuOpen ? closeEverything : openSupportMenu}
          >
            <Icons.ChatBubble
              width={24}
              height={18}
              primaryColor={chatBubblePrimaryColor}
              secondaryColor={chatBubbleSecondaryColor}
            />
          </ClickableBox>
        )}
        <Popper
          open={isSupportMenuOpen}
          onBlur={isSupportMenuInChat ? noop : closeSupportMenu}
          anchorRef={{ current: supportMenuElement }}
          containerStyles={{ backgroundColor: 'transparent', boxShadow: 'none' }}
          placement="top-end"
          offset={[0, 20]}
        >
          <PopperContent
            onTalkToAgent={handleAgentSupportClick}
            showFeedbackButton={!!marker}
            onFeedback={handleFeedbackClick}
          />
        </Popper>
      </div>
    </IfNotEmbed>
  );
};

export default SupportMenu;
