export const preview_toggle_parent = {
  width: 'full',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const preview_html = {
  /* stylelint-disable color-no-hex */
  borderRadius: '8px',
  border: '1px solid #E4E5E7',
  padding: '24px',
  width: '100%',
  background: '#F7F7F8',
};
