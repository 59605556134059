import { extractOrgIdFromUrl } from 'obt-common/core/orgId/extractOrgIdFromUrl';
import { setOrgId } from 'obt-common/core/orgId/setOrgId';
import { removeOrgId } from 'obt-common/core/orgId/removeOrgId';
import { ROUTE_PATHS } from '../../routes';
import { captureException, addBreadcrumb } from '../errorTracking';

export async function collectOrgId(): Promise<void> {
  const { search, pathname } = window?.location ?? {};

  try {
    const orgId = extractOrgIdFromUrl();
    if (orgId) {
      await setOrgId(orgId);
    } else if (pathname.endsWith(ROUTE_PATHS.HEADLESS_AUTH)) {
      // If there is no orgId in the headless auth request, remove from storage
      // so it doesn't interfere with the user's subsequent backend requests.
      await removeOrgId();
    }

    addBreadcrumb({
      message: 'Extracted orgId from URL',
      data: { orgId, locationSearch: search },
    });
  } catch (err) {
    captureException(err, { source: 'collectOrgId', extra: { locationSearch: search } });
  }
}
