import { PropsWithChildren, Suspense } from 'react';

function SuspenseLoaderFallback({ children }: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Suspense
      fallback={
        /**
         * purposely using a different loader than React component CircularLoader
         * to avoid visually glitchy loader-repetition on first load of the app.
         *
         * this is of a neutral gray colour and only loads once on the entire page (first load or reload).
         * It's able to seamlessly supercede the html-based loader which shows before React loads.
         *
         * the routing-based Suspense boundary uses a different loader so this doesn't impact Suspense fallbacks everywhere.
         * */
        <div className="prefetch page-spinner">
          <div />
          <div />
          <div />
          <div />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}

export default SuspenseLoaderFallback;
